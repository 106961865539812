import { initializeApp } from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
};

const app = initializeApp(firebaseConfig);
const firebaseAuth = app.auth();
export default firebaseAuth;

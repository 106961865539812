import React from "react";
import "../styles/common/createReport.css";
import { ReactComponent as AddIcon } from "../../../../../assets/images/product/template/AddWhite.svg";
import "../styles/common/CreateReportButton.css";

export default function CreateReportButton(props) {
  const { handleAddCoa, buttonText, showAddIcon } = props;

  return (
    <div
      className="createReportContainer"
      onClick={() => handleAddCoa()}
    >
      <div className="CreateReportButton__Div">
        {showAddIcon && (<AddIcon className="CreateReportButton__AddIcon" />)}
        {buttonText}
      </div>
    </div>
  );
}

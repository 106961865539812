import React from "react";

import EVENT_SPEC_STYLE_MAP from "../constants";

import "./EventsFilter.css";

const FILTERS = [
  {
    display: "Scheduled",
    value: "scheduled",
    spec_flags: ["-2"],
  },
  {
    display: "Sampled",
    value: "sampled",
    spec_flags: ["-1"],
  },
  {
    display: "In Process",
    value: "in_process",
    spec_flags: ["0", "4"],
  },
  // {
  //   display: "No Spec",
  //   value: "no_spec",
  //   spec_flags: ["3"],
  // },
  {
    display: "In Spec",
    value: "in_spec",
    spec_flags: ["1", "5"],
  },
  {
    display: "Out of Spec",
    value: "out_of_spec",
    spec_flags: ["2", "6"],
  },
];

export default function EventsFilter({
  specFilterValue,
  setSpecFilterValue,
}) {
  /**
   * Toggles filter individually
   * @param {String} value filter
   */
  const toggleFilter = (filter) => {
    if (specFilterValue && specFilterValue === filter.value) {
      setSpecFilterValue("");
    } else {
      setSpecFilterValue(filter.value);
    }
  };

  return (
    <div className="EventsFilter__Container">
      {FILTERS.map((filter) => {
        const specValue = filter.spec_flags[0];
        const color = EVENT_SPEC_STYLE_MAP[specValue];

        return (
          <div
            key={filter.display}
            className={`EventsFilter__Filter ${specFilterValue === filter.value ? "EventsFilter__Filter--selected" : ""}`}
            style={{ "--status-color": color.color }}
            onClick={() => toggleFilter(filter)}
          >
            <span className="EventsFilter__ColoredBox" />
            <span className="EventsFilter__FilterText">{filter.display}</span>
            {specFilterValue === filter.value && (
              <span
                className="EventsFilter__Cancel"
                onClick={() => toggleFilter(filter)}
              >
                &times;
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
}

import axios from "axios";
import { getUserCredentials } from "../utils/common";
import { API_URL } from "../utils/Constant";
import { getValueOfObj } from "../utils/helpers";

export const getProducts = async (payload, signal = null) => {
  try {
    let listFromResponse = [];
    let productList = [];
    let message;

    if (payload.flag === "index") {
      const response = await axios.get(`${API_URL}/indexcard/`, {
        params: {
          index: payload.index || "",
        },
        signal,
      });
      message = response.data[0]?.message;
      listFromResponse = getValueOfObj(response, "data", "0", "result", "0", "index_list", "0", "index_list");
    } else {
      const response = await axios.get(`${API_URL}/product/`, {
        signal,
      });
      message = response.data[0]?.message;
      listFromResponse = getValueOfObj(response, "data", "0", "result", "0", "sku_list");
    }
    if (listFromResponse) {
      productList = listFromResponse.map((item) => {
        const newItem = { ...item };
        if (!newItem.tags) {
          newItem.tags = [];
        }
        return newItem;
      });
    }

    return { success: message === "Success", message: message ?? "Something went wrong.", productList };
  } catch (e) {
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/* not used */
// export const getSearchProducts = async (productSearchText) => {
//   try {
//     let response = await axios.get(`${API_URL}/productsearch/`, {
//       params: {
//         input: productSearchText,
//       },
//     });
//     const data = await response.data;
//     let message;
//     let list = [];
//     if (data && data.length > 0) {
//       response = data[0];
//       message = response.message;
//       list = (response.result
//           && response.result.length
//           && response.result[0].sku_list)
//         || [];
//     }

//     return { success: message === "Success", message: message ?? "Something went wrong.", list };
//   } catch (e) {
//     getViewAsPerStatusCode(e);
//     return { success: false, message: e.response?.data?.message || "Something went wrong." };
//   }
// };

export const createProduct = async (payload) => {
  try {
    let params = {
      category: "add",
      product_name: payload.product_name,
      item: payload.item,
      product_image: payload.image,
    };
    if (payload.flag === "index") {
      params = {
        category: "Add",
        index: payload.index,
        card_name: payload.card_name,
      };
      const response = await axios.get(`${API_URL}/indexcard/`, { params });
      const data = await response.data;
      const res = { result: {} };
      let message;

      if (data && data.length > 0) {
        const { result } = data[0];
        message = data[0].message;
        if (result && result.length > 0) {
          const outerList = result[0].index_list;
          if (outerList && outerList.length > 0) {
            const list = outerList[0].index_list;
            if (list && list.length > 0) {
              // diff with /addproduct, all cards in response, get 0
              res.result = list[0];
            }
          }
        }
      }

      return { success: message === "Success", message: message ?? "Something went wrong.", ...res };
    }
    const response = await axios.post(`${API_URL}/addproduct/`, params);
    const data = await response.data;
    // must be the last one, response sometimes 1 result sometimes 2
    return { success: data.message === "Success", ...data };
  } catch (e) {
    // console.log("error from createProduct", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const updateProduct = async (payload) => {
  try {
    let params = {
      category: "edit",
      product_name: payload.product_name,
      item: payload.item,
      product_id: payload.product_id,
      product_image: payload.product_image,
    };
    if (payload.flag === "index") {
      params = {
        category: "Edit",
        index: payload.index,
        card_name: payload.card_name,
        index_card_id: payload.index_card_id,
        card_image: payload.card_image,
      };

      const result = await axios.get(`${API_URL}/indexcard/`, { params });
      const data = await result.data;

      return { success: data[0].message === "Success", message: data[0].message, ...data };
    }
    const result = await axios.post(`${API_URL}/addproduct/`, params);
    const data = await result.data;
    return { success: data.message === "Success", ...data };
  } catch (e) {
    // console.log("error from updateProduct", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const deleteProduct = async (payload) => {
  try {
    let params = {
      product_id: payload.product_id,
    };
    if (payload.flag === "index") {
      params = {

        category: "Delete",
        index: payload.index,
        index_card_id: payload.index_card_id,
      };
      const result = await axios.get(`${API_URL}/indexcard/`, { params });
      const data = await result.data;
      return { success: data[0].message === "Success", message: data[0].message, ...data };
    }
    const result = await axios.get(`${API_URL}/product/`, { params });
    const data = await result.data;

    return { success: data[0].message === "Success", message: data[0].message, ...data };
  } catch (e) {
    // console.log("error from deleteProduct", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const searchProducts = async (payload, signal = null) => {
  try {
    let res = { productList: [], tags: {} };
    let tags = {};
    let m = 0;
    let n = 0;
    let message;

    if (payload.flag === "index") {
      const response = await axios.get(`${API_URL}/indexsearch/`, {
        params: {
          index: payload.index,
          input: payload.input,
        },
        signal,
      });
      const data = await response.data;

      if (data && data.length > 0) {
        message = data[0].message;
        const { result } = data[0];
        if (result && result.length > 0) {
          const list = result[0].index_list;

          if (list && list.length > 0) {
            for (m = 0; m < list.length; m++) {
              tags[list[m].product_id] = new Set();
              if (list[m].tags === "") {
                list[m].tags = [];
              } else if (list[m].tags && list[m].tags.length > 0) {
                for (n = 0; n < list[m].tags.length; n++) {
                  list[m].tags[n].id = `${list[m].product_id}-${list[m].tags[n].tag}`;
                  list[m].tags[n].text = list[m].tags[n].tag;
                  if (list[m].tags[n].text) {
                    tags[list[m].product_id].add(
                      list[m].tags[n].text.toLowerCase(),
                    );
                  }
                }
              }
            }

            res = {
              productList: list,
              tags,
            };
          }
        }
      }
    } else {
      const response = await axios.get(`${API_URL}/productsearch/`, {
        params: {

          input: payload.input,
        },
        signal,
      });
      const data = await response.data;

      if (data && data.length > 0) {
        message = data[0].message;
        const { result } = data[0];
        if (result && result.length > 0) {
          const list = result[0].sku_list;
          tags = {};

          if (list && list.length > 0) {
            for (m = 0; m < list.length; m++) {
              tags[list[m].product_id] = new Set();
              if (list[m].tags === "") {
                list[m].tags = [];
              } else if (list[m].tags && list[m].tags.length > 0) {
                for (n = 0; n < list[m].tags.length; n++) {
                  list[m].tags[n].id = `${list[m].product_id}-${list[m].tags[n].tag}`;
                  list[m].tags[n].text = list[m].tags[n].tag;
                  if (list[m].tags[n].text) {
                    tags[list[m].product_id].add(
                      list[m].tags[n].text.toLowerCase(),
                    );
                  }
                }
              }
            }

            res = {
              productList: list,
              tags,
            };
          }
        }
      }
    }

    return { success: message === "Success", message: message ?? "Something went wrong.", ...res };
  } catch (e) {
    // console.log("error from searchProducts", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong.", cancelled: axios.isCancel(e) };
  }
};

export const addTag = async (payload) => {
  try {
    let params = {
      category: "Add",
      tag: payload.tag,
      product_id: payload.product_id,
    };
    if (payload.flag === "index") {
      params = {

        category: "Add",
        tag: payload.tag,
        index_card_id: payload.index_card_id,
        index: payload.index,
      };
      const result = await axios.get(`${API_URL}/indexcardtag/`, { params });
      const data = result.data;

      return { success: data[0].message === "Success", message: data[0].message ?? "Something went wrong.", data };
    }
    const result = await axios.get(`${API_URL}/tagupdate/`, { params });
    const data = result.data;
    return { success: data[0].message === "Success", message: data[0].message ?? "Something went wrong.", data };
  } catch (e) {
    // console.log("error from addTag", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const deleteTag = async (payload) => {
  try {
    let params = {
      category: "Delete",
      tag: payload.tag,
      product_id: payload.product_id,
    };
    if (payload.flag === "index") {
      params = {
        category: "Delete",
        tag: payload.tag,
        index_card_id: payload.index_card_id,
        index: payload.index,
      };
      const result = await axios.get(`${API_URL}/indexcardtag/`, { params });
      const data = await result.data;

      return { success: data[0].message === "Success", message: data[0].message ?? "Something went wrong.", ...data };
    }
    const result = await axios.get(`${API_URL}/tagupdate/`, { params });
    const data = await result.data;

    return { success: data[0].message === "Success", message: data[0].message ?? "Something went wrong.", ...data };
  } catch (e) {
    // console.log("error from deleteTag", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const updateTag = async (payload) => {
  try {
    let params = {
      category: "Edit",
      tag: payload.tag,
      tag1: payload.tag1,
      product_id: payload.product_id,
    };
    let url = `${API_URL}/tagupdate/`;
    const active = payload.activeTab;

    if (active > 0) {
      url = `${API_URL}/indexcardtag/`;
      params = {
        category: "Edit",
        tag: payload.tag,
        tag1: payload.tag1,
        index_card_id: payload.product_id,
        index: payload.index,
      };
    }

    const result = await axios.get(url, { params });
    const data = await result.data;

    return { success: data[0].message === "Success", message: data[0].message ?? "Something went wrong.", ...data };
  } catch (e) {
    // console.log("error from updateTag", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const getIndex = async () => {
  try {
    const user = await getUserCredentials();
    const response = await axios.get(`${API_URL}/index/`);
    const data = await response.data;
    const res = { indices: [], company_domain: user.company_domain };
    let message;

    if (data && data.length > 0) {
      message = data[0].message;
      const { result } = data[0];
      if (result && result.length > 0) {
        const { indices } = result[0];
        if (indices && indices.length > 0) {
          for (let i = 0; i < indices.length; i++) {
            res.indices.push(indices[i].index);
          }
        }
      }
    }

    return { success: message === "Success", message: message ?? "Something went wrong.", ...res };
  } catch (e) {
    // console.log("error from getIndex", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const addIndex = async (payload) => {
  try {
    const params = {

      index: payload.index,
      category: "Add",
    };
    const response = await axios.get(`${API_URL}/addindex/`, { params });
    const data = await response.data;
    let result = "";

    if (data && data.length > 0) {
      result = data[0];
    }
    return { success: result.message === "Success", ...result };
  } catch (e) {
    // console.log("error from addIndex", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const editIndex = async (payload) => {
  try {
    const params = {

      category: "Edit",
      index: payload.index,
      new_index: payload.new_index,
    };
    const response = await axios.get(`${API_URL}/addindex/`, { params });
    const data = await response.data;
    let result = "";

    if (data && data.length > 0) {
      result = data[0];
    }
    return { success: result.message === "Success", ...result };
  } catch (e) {
    // console.log("error from editIndex", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const deleteIndex = async (payload) => {
  try {
    const params = {

      category: "Delete",
      index: payload.index,
    };
    await axios.get(`${API_URL}/addindex/`, { params });

    return { success: true };
  } catch (e) {
    // console.log("error from deleteIndex", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

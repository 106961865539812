import React from "react";
import StyledButton from "../../../Common/UIComponents/StyledButton";
import closeFilterIcon from "../../../../assets/images/product/lightBlueCancelIcon.png";
import "./SubmissionFilterButton.css";

const SubmissionFilterButton = ({
  specText,
  specSelected,
  disableFilterButton,
  handleToggleFilter,
  Icon,
}) => {
  /**
   *Toggle filter button
   */
  const onToggleFilter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleToggleFilter();
  };

  return (
    <StyledButton
    // disabled={disableFilterButton}
      type={specSelected ? "primary" : "text"}
      ghost={specSelected}
      className={`${specSelected ? "SubmissionFilterButton__SpecSelected" : "SubmissionFilterButton__SpecNotSelected"} ${disableFilterButton ? "SubmissionFilterButton__Spec--disabled" : ""}`}
      onClick={(e) => onToggleFilter(e)}
    >
      {/* <div
          className="specCategoryIcon"
          style={{ backgroundColor: specColor }}
        /> */}
      <Icon className="filterCategoryIcon" />
      <div className="filterStatusCategory">{specText}</div>
      {specSelected && (
      <img
        className="filterStatusCategoryCloseFilterIcon"
        src={closeFilterIcon}
        alt="closeFilterIcon"
        width="14px"
      />
      )}
    </StyledButton>
  );
};

export default SubmissionFilterButton;

import React from "react";

import { Button } from "antd";

import { ReactComponent as LoadingDots } from "../../../assets/images/common/LoadingDots.svg";
import "./StyledButton.css";

export default function StyledButton({ // same props as AntD Button
  block,
  className = "",
  danger,
  disabled,
  ghost, // use ghost = true and type = "primary" for ESV's secondary button styling
  href,
  htmlType,
  icon,
  loading,
  loadingDots,
  shape,
  size,
  target,
  type = "primary", // primary, default, dashed, text, link
  onClick,
  children,
  ...restProps
}) {
  return (
    <Button
      block={block}
      className={`StyledButton ${className}`}
      danger={danger}
      disabled={disabled}
      ghost={ghost}
      href={href}
      htmlType={htmlType}
      icon={icon}
      loading={loading}
      shape={shape}
      size={size}
      target={target}
      type={type}
      onClick={onClick}
      {...restProps} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
      {loadingDots && <LoadingDots className="StyledButton__LoadingDots" width={16} />}
    </Button>
  );
}

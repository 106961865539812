import { partition } from "lodash";
import React from "react";
import Scrollbar from "react-scrollbars-custom";
import TemplateBuilderDroppable from "../DragAndDrop/TemplateBuilderDroppable";
import BuilderElementDraggable from "../DragAndDrop/BuilderElementDraggable";

export default function DataFields(props) {
  // const { dataFields, showSorting, activeSort } = props;
  const {
    dataFields, activeSort, commonDataFieldsInUse, customizedDataFieldsInUse,
  } = props;

  const fieldAlreadyInUse = (field) => (field.flag === "1" && commonDataFieldsInUse.indexOf(field.value) !== -1)
      || (field.flag === "2" && customizedDataFieldsInUse.indexOf(field.value) !== -1);
  if (activeSort !== "by Type") {
    return (
      <div
        style={{
          flexGrow: 1,
          padding: "0px 5px 10px",
        }}
      >
        <Scrollbar
          id="builderComponentsScrollBar"
          disableTrackYWidthCompensation
          disableTracksWidthCompensation
        >
          <TemplateBuilderDroppable
            className="allDataFields"
            droppableId="builder_datafields"
            direction="horizontal"
            showPlaceholder={false}
            disabled
          >
            {dataFields && dataFields.map((elem, i) => (
              <BuilderElementDraggable
                key={elem.id}
                id={elem.id}
                index={i}
                type="datafield"
                value={elem.value}
                flag={elem.flag}
                jsonField={elem.json_field || ""}
                disable={fieldAlreadyInUse(elem)}
              />
            ))}
          </TemplateBuilderDroppable>
        </Scrollbar>
      </div>
    );
  }
  const [defaultFields, customFields] = partition(dataFields, ({ flag }) => flag === "1");
  return (
    <div
      className="templateBuilderDataFieldFont"
      style={{
        flexGrow: 1,
        padding: "0px 5px 10px",
      }}
    >
      <div className="templateBuilderDataFieldHeader">From reports</div>
      <Scrollbar
        id="builderComponentsScrollBar"
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
        style={{
          height: "calc(50% - 50px)",
          marginBottom: "19px",
        }}
      >
        <TemplateBuilderDroppable
          className="allDataFields"
          droppableId="builder_datafields_default"
          showPlaceholder={false}
          disabled
        >
          {defaultFields.map((elem, i) => (
            <BuilderElementDraggable
              key={elem.id}
              id={elem.id}
              index={i}
              type="datafield"
              value={elem.value}
              flag={elem.flag}
              jsonField={elem.json_field || ""}
              disable={fieldAlreadyInUse(elem)}
            />
          ))}
        </TemplateBuilderDroppable>
      </Scrollbar>
      <div className="templateBuilderDataFieldHeader__Div">
        <div className="templateBuilderDataFieldHeader">Created by you</div>
        <p className="templateBuilderDataFieldHeader__HelpingWord">Editable</p>
      </div>
      <Scrollbar
        id="builderComponentsScrollBar"
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
        style={{
          height: "calc(50% - 50px)",
          marginBottom: "19px",
        }}
      >
        <TemplateBuilderDroppable
          className="allDataFields"
          droppableId="builder_datafields_user"
          showPlaceholder={false}
          disabled
        >
          {customFields.map((elem, i) => (
            <BuilderElementDraggable
              key={elem.id}
              id={elem.id}
              index={i}
              type="datafield"
              value={elem.value}
              flag={elem.flag}
              jsonField={elem.json_field || ""}
              disable={fieldAlreadyInUse(elem)}
            />
          ))}
        </TemplateBuilderDroppable>
      </Scrollbar>
    </div>
  );
}

import React from "react";
import {
  Layout, Menu, Row, Col, Avatar,
} from "antd";
import { getUserCredentials, getTabsList } from "../../utils/common";
// import DefaultNameIcon from "../Common/DefaultNameIcon";
import DrawerNav from "./DrawerNav";
import { ReactComponent as Logo } from "../../assets/images/logo/ESVLogo.svg";
import indexIcon from "../../assets/images/indexIcon_2x.png";
import productIcon from "../../assets/images/product_2x.png";
import enviromentIcon from "../../assets/images/enviroment_2x.png";
import documentsIcon from "../../assets/images/documents_2x.png";
import backIcon from "../../assets/images/backIcon.png";
import { ReactComponent as MyAccountIcon } from "../../assets/images/myAccountIcon.svg";
import "./navbar.css";

import { AppContext } from "../../AppContext";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRoute: null,
      activeNav: null,
      loading: true,
      isSideBarStyled: true,
      navs: [],
      accountHovered: false,
      navDrawer: false,
    };
  }

  componentDidMount = async () => {
    const { history } = this.props;
    const currentRoute = history.location.pathname;
    const navBarItem = [
      {
        key: "1",
        label: "Sample Submission",
        icon: "",
        link: "/sample-submission",
        fieldKey: "sample-submission",
      },
      {
        key: "2",
        label: "Product",
        icon: productIcon,
        link: "/product/report",
        fieldKey: "product",
      },
      {
        key: "3",
        label: "Environment",
        icon: enviromentIcon,
        link: "/environment",
        fieldKey: "environment",
      },
      {
        key: "4",
        label: "Index",
        icon: indexIcon,
        link: "/index",
        fieldKey: "index",
      },
      {
        key: "5",
        label: "Documents",
        icon: documentsIcon,
        link: "/documents",
        fieldKey: "documents",
      },
    ];
    const navs = [];
    const tabsList = getTabsList();
    navBarItem.forEach((item) => {
      if (tabsList?.includes(item.fieldKey)) {
        navs.push(item);
      }
    });
    this.setState({ navs }, () => this.handleActiveRoute(currentRoute));
  }

  componentDidUpdate() {
    const { history } = this.props;
    const { currentRoute } = this.state;
    const currRoute = history.location.pathname;

    if (currRoute !== currentRoute) {
      this.handleActiveRoute(currRoute);
    }
  }

  handleActiveRoute = (currentRoute) => {
    const { navs } = this.state;
    const navName = currentRoute.split("/")[1];
    let activeNav = "";
    if (navName === "myaccount" || navName === "onboarding" || navName === "add-company") {
      activeNav = navName === "onboarding" ? "1" : navName;
    } else {
      activeNav = navs.find((item) => (item.fieldKey === navName))?.key;
    }

    this.setState({
      currentRoute,
      activeNav,
      loading: false,
    });
  };

  onBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  onNavigation = (dataLink) => {
    const { history } = this.props;
    if (dataLink && (dataLink === "/product/" || dataLink === "/env/")) {
      dataLink += "report";
    }
    history.push(dataLink, {
      reRender: new Date(),
    });
  };

  onNavigationMyAccount = () => {
    const { history } = this.props;
    const dataLink = "/myaccount/profile";
    this.setState({
      activeNav: "myaccount",
      currentRoute: dataLink,
    });
    history.push(dataLink, {
      reRender: new Date(),
    });
  };

  navigation = (navs, analytics, report, product_link, sample_sub_link) => navs.map((nav) => {
    let link = nav.link;
    if (nav.label === "Product") {
      link = `/product/${report}`;
    } else if (nav.label === "Index") {
      link = "/index";
      if (product_link) {
        link += `#${product_link}`;
      }
    } else if (nav.label === "Sample Submission" && sample_sub_link) {
      link = `/sample-submission/${sample_sub_link}`;
    }

    return ({
      id: nav.key,
      key: nav.key,
      "data-link": link,
      style: { border: "none" },
      label: (
        <span className="nav-text">{nav.label}</span>
      ),
      onClick: () => this.onNavigation(link),
    });
  });

  handleMenuMouseEnter = () => {
    this.setState({ accountHovered: true });
  }

  handleMenuMouseLeave = () => {
    this.setState({ accountHovered: false });
  }

  handleNavDrawer = () => {
    const { navDrawer } = this.state;
    this.setState({ navDrawer: !navDrawer });
  }

  render() {
    const {
      activeNav,
      loading,
      navs,
      isSideBarStyled,
      accountHovered,
      navDrawer,
    } = this.state;

    const {
      history, analytics, product_link, reports, sample_sub_link,
    } = this.props;

    const userInfo = getUserCredentials();
    const { user_name, company_name } = userInfo;
    const accountTitle = user_name ? `Hi, ${user_name}` : "Hi";

    if (loading) return null;
    return (
      <Layout.Header
        id="navbar"
        breakpoint="md"
        collapsedwidth="0"
        className="SideBarClientView"
      >
        {!activeNav && isSideBarStyled ? (
          <div className="back-btn" onClick={(event) => this.onBack(event)}>
            <img src={backIcon} alt="back_icon" className="SideBarBackIcon" />
          </div>
        ) : (
          <>
            <div className="logo" />
            {isSideBarStyled && (
            <>
              <Row className="NavigationBar-Row" wrap={false}>
                <Col xs={5} sm={4} md={3} lg={2} className="NavigationBar-Col">
                  <div className="NavigationBar-Col__First">
                    <Avatar
                      onClick={() => history.push("/")}
                      icon={<Logo className="NavBar__ESVLogo" />}
                      size={{
                        xs: 42, sm: 42, md: 42, lg: 42, xl: 48, xxl: 56,
                      }}
                      shape="square"
                      className="NavigationBar-Col__First__Avatar"
                    />
                  </div>
                </Col>
                <Col flex="auto" className="sideBarMenuWrapper NavigationBar-Col__Second">
                  <div className="NavigationBar-Col__Second__Body">
                    <Menu
                      theme="light"
                      mode="horizontal"
                      selectedKeys={[activeNav]}
                      className="NavigationBar-Col__Second__Body__Menu"
                      items={this.navigation(navs, analytics, reports, product_link, sample_sub_link)}
                    />
                  </div>
                </Col>
                <Col
                  flex="auto"
                  className="NavigationBar-Col__Third"
                >
                  {/* <img src={notification} className="icon" />
                  <img src={message} className="icon" /> */}
                  <div
                    className="NavBar__AccountContainer"
                    onMouseEnter={() => this.handleMenuMouseEnter()}
                    onMouseLeave={() => this.handleMenuMouseLeave()}
                    onClick={() => { this.handleNavDrawer(); }}
                  >
                    <div className="NavBar__AccountInfo">
                      <span className={`NavBar__AccountTitle ${accountHovered ? "NavBar__Account--hover" : ""}`} title={accountTitle}>
                        {accountTitle}
                      </span>
                      <span className="NavBar__AccountCompany" title={company_name}>
                        {company_name}
                      </span>
                    </div>
                    <MyAccountIcon className="NavBar__AccountIcon" />
                  </div>
                  <DrawerNav
                    handleNavDrawer={this.handleNavDrawer}
                    history={history}
                    navDrawer={navDrawer}
                    userInfo={userInfo}
                  />
                </Col>
              </Row>
            </>
            )}
          </>
        )}
      </Layout.Header>
    );
  }
}

NavBar.contextType = AppContext;
export default NavBar;

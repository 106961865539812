import React from "react";

import { motion } from "framer-motion/dist/framer-motion";

import { COMPOSITE_FIELD_DELIMITER, INVALID_CHARS, INVALID_CHARS_ESCAPED } from "../../Constant";
import ErrorCheckingInput from "../../ReusableComponents/ErrorCheckingInput";

// import { ReactComponent as MinusIcon } from "../../../../../assets/images/sample-submission/minusSliderIcon.svg";

export default function SampleDetailsInput({
  setFieldValue,
  //   handleDeselectField,
  //   inputValuesSavedForNext,
  inputValues,
  setInputValuesRefs,
  json_field,
  //   idx,
  //   toggleSaveForNext,
  sampleFieldsInfo,
  delimiterRegex,
  setFieldInputInvalid,
  charLimit,
  //   hideSaveForNext,
  sampleIdFields,
  compositeData,
  index,
  inputInvalid,
  showLabel,
  remainingLimit,
}) {
  const invalidRegexArr = [...INVALID_CHARS_ESCAPED];
  const isUniqueIdentifier = compositeData?.identifier && compositeData?.identifier.includes(json_field);
  const currentValue = isUniqueIdentifier ? inputValues[json_field]?.split(COMPOSITE_FIELD_DELIMITER)[index] : inputValues[json_field] ?? "";
  if (delimiterRegex && json_field !== "sample_id" && sampleIdFields.has(json_field)) {
    invalidRegexArr.push(delimiterRegex);
  }

  const onChange = (value) => {
    setFieldValue(json_field, value, index); // Call the parent function to update the value
  };

  return (
    <motion.div layout key={json_field} className="input-checkbox-row composite-sample-input">
      <motion.div className="sample-details-input">
        <ErrorCheckingInput
          ref={(input) => setInputValuesRefs(json_field, input)}
          label={sampleFieldsInfo[json_field].title_field}
          labelDivClassName={`sample-details-input-title ${!showLabel ? "hide-label" : ""}`}
          inputID={`${json_field}-input`}
          value={currentValue}
          invalidChars={invalidRegexArr}
          invalidKeys={INVALID_CHARS}
          onChange={onChange}
          onErrorChange={(inputErrorSet) => setFieldInputInvalid(json_field, inputErrorSet.size > 0)}
          charLimit={charLimit} // Pass the updated char limit for sample_type
          isUniqueIdentifier={isUniqueIdentifier}
          hasError={inputInvalid[json_field]}
          showLabel={showLabel}
          remainingLimit={remainingLimit} // Pass the remaining count of fields to display in the last row
        />
        {/* <MinusIcon
          className="sample-details-close-icon"
          title="deselect field x button"
          width="16"
          height="16"
          onClick={() => handleDeselectField(idx, json_field)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleDeselectField(idx, json_field);
            }
          }}
          tabIndex={0}
        /> */}
      </motion.div>
      {/* {!hideSaveForNext && (
        <motion.div className="sample-submission-checkbox-container">
          <input type="checkbox" checked={inputValuesSavedForNext[json_field] ?? false} onChange={() => { toggleSaveForNext(json_field); }} />
          <span className="sample-submission-checkmark" onClick={() => { toggleSaveForNext(json_field); }} />
        </motion.div>
      )} */}
    </motion.div>
  );
}

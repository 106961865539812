import React, { useEffect, useRef } from "react";

import { message } from "antd";
import axios from "axios";
// import moment from "moment-timezone";

import { version as CURRENT_VERSION } from "../../../../package.json";
import StyledButton from "../UIComponents/StyledButton";

import "./AppUpdate.css";

const ONE_MINUTE = 60000; // 1 minute in milliseconds
const POLL_INTERVAL = 1 * ONE_MINUTE; // 1 minute
const REMINDER_INTERVAL = 10 * ONE_MINUTE; // 10 minutes

/**
 * Checks if version polling is enabled.
 * @returns {boolean} True if polling is enabled, false otherwise.
 */
function isPollingEnabled() {
  return process.env.REACT_APP_ENABLE_VERSION_POLLING.toLowerCase() === "true";
}

/**
 * Fetches version data from the server.
 * Makes a GET request to `version.json` with a timestamp to prevent caching,
 * and returns the response data.
 * @returns {Promise<Object>} The response data containing version information.
 */
async function fetchVersionData() {
  const response = await axios.get(`${process.env.PUBLIC_URL}/version.json`, {
    params: { ts: new Date().getTime(), disableInterceptor: true },
    headers: { "Cache-Control": "no-store" },
  });

  return response.data;
}

/**
 * Determines whether to show a notification for a new version.
 * @param {string} newVersion - The new version number.
 * @param {boolean} showNotification - Indicates if notification should be shown.
 * @returns {boolean} True if a notification should be displayed.
 */
function shouldShowNotification(newVersion, showNotification) {
  return (newVersion !== CURRENT_VERSION && showNotification.toLowerCase() === "true");
}

/**
 * Determines whether to force the current page to refresh
 * @param {string} newVersion - The new version number.
 * @param {string} date example: 2024-11-19_03:50:59 PM
 * @returns {boolean} True if current page should be refresh immediately
 */
// function newDayForceRefresh(newVersion, date) {
//   if (newVersion === CURRENT_VERSION || date === "") return false;
//   const currDate = moment().tz("America/Los_Angeles");
//   const pushDate = moment(date, "YYYY-MM-DD_hh:mm:ss A").tz("America/Los_Angeles");
//   return currDate.isAfter(pushDate, "day");
// }

/**
 * Shows a persistent warning for a new app update with an option to set a reminder.
 * @param {function} setReminder - Function to schedule an update reminder.
 */
function displayUpdateNotification(setReminder) {
  message.warning({
    content: <AppUpdateNotification setReminder={setReminder} />,
    duration: 0,
    className: "AppUpdate__ESV",
  });
}

/**
 * AppUpdate is a React component responsible for checking if there is a new version
 * of the application available. It periodically polls a version endpoint and displays
 * a notification if a new version is detected. The user can choose to update immediately
 * or set a reminder for later. The component handles setting up and clearing of polling
 * intervals and reminder timeouts as part of its lifecycle management.
 */
export default function AppUpdate() {
  const initialIntervalRef = useRef(null);
  const reminderTimeoutRef = useRef(null);

  useEffect(() => {
    const pollVersion = async () => {
      if (!isPollingEnabled()) return;
      try {
        const { version: newVersion, showNotification } = await fetchVersionData();
        // if (newDayForceRefresh(newVersion, date)) {
        //   window.location.reload();
        //   return;
        // }
        if (shouldShowNotification(newVersion, showNotification)) {
          clearInterval(initialIntervalRef.current);
          displayUpdateNotification(setReminder); // eslint-disable-line no-use-before-define
        }
      } catch (error) {
        console.error("Failed to fetch version.json:", error);
      }
    };

    const setReminder = () => {
      message.destroy();
      clearTimeout(reminderTimeoutRef.current);
      reminderTimeoutRef.current = setTimeout(() => {
        pollVersion();
      }, REMINDER_INTERVAL);
    };

    pollVersion();
    initialIntervalRef.current = setInterval(pollVersion, POLL_INTERVAL);

    return () => {
      clearInterval(initialIntervalRef.current);
      clearTimeout(reminderTimeoutRef.current);
    };
  }, []);

  return null;
}

const AppUpdateNotification = React.memo(({ setReminder }) => (
  <div className="AppUpdateContainer">
    <div className="AppUpdate__Text">
      <span className="AppUpdate__Title">A new update for ESV is available!</span>
      <span className="AppUpdate__Body">
        You can also refresh the page at any time to update.
      </span>
    </div>
    <div className="AppUpdate__Buttons">
      <StyledButton
        ghost
        onClick={setReminder}
      >
        Remind me in 10 minutes
      </StyledButton>
      <StyledButton type="primary" onClick={() => window.location.reload()}>
        Update
      </StyledButton>
    </div>
  </div>
));

const EVENT_SPEC_STYLE_MAP = {
  "": {
    display_name: "No Result",
    color: "#26ABE1", // light blue
    rgb_values: "38, 171, 225",
    box_shadow_color: "rgba(38, 171, 225, 0.21)",
    border_color: "#A7E3fA",

    text_color: "#26ABE1",
    sample_border_color: "#EAF0F5",
    sample_bg_color: "#FFFFFF",
  },
  0: {
    display_name: "In Process",
    color: "#E1B226", // orange
    rgb_values: "225, 178, 38",
    box_shadow_color: "rgba(225, 178, 38, 0.2)",
    border_color: "#E1B226",

    text_color: "#E1B226",
    sample_border_color: "#EAF0F5",
    sample_bg_color: "#fff8e3dd",
  },
  1: {
    display_name: "In Spec",
    color: "#1EC04A", // green
    rgb_values: "30, 192, 74",
    box_shadow_color: "rgba(30, 192, 74, 0.3)",
    border_color: "#1EC04A",

    text_color: "#1EC04A",
    sample_border_color: "#EAF0F5",
    sample_bg_color: "#DCF9E4",
  },
  2: {
    display_name: "Out of Spec",
    color: "#E11903", // red
    rgb_values: "225, 25, 3",
    box_shadow_color: "rgba(225, 25, 3, 0.3)",
    border_color: "#E11903",

    text_color: "#E11903",
    sample_border_color: "#EAF0F5",
    sample_bg_color: "#FFF1F0",
  },
  3: {
    display_name: "Spec Incomplete",
    color: "#505050", // gray
    rgb_values: "80, 80, 80",
    box_shadow_color: "rgba(0, 0, 0, 0.15)",
    border_color: "#C5C5C5",

    text_color: "#505050",
    sample_border_color: "#EAF0F5",
    sample_bg_color: "#EEEEEE",
  },
  4: {
    display_name: "In Process - Retest", // retest
    color: "#E1B226", // orange
    rgb_values: "225, 178, 38",
    box_shadow_color: "rgba(225, 178, 38, 0.2)",
    border_color: "#E1B226",

    text_color: "#E1B226",
    sample_border_color: "#EAF0F5",
    sample_bg_color: "#fff8e3dd",
  },
  5: {
    display_name: "In Spec - Retest", // retest
    color: "#1EC04A", // green
    rgb_values: "30, 192, 74",
    box_shadow_color: "rgba(30, 192, 74, 0.3)",
    border_color: "#1EC04A",

    text_color: "#1EC04A",
    sample_border_color: "#EAF0F5",
    sample_bg_color: "#DCF9E4",
  },
  6: {
    display_name: "Out of Spec - Retest", // retest
    color: "#E11903", // red
    rgb_values: "225, 25, 3",
    box_shadow_color: "rgba(225, 25, 3, 0.3)",
    border_color: "#E11903",

    text_color: "#E11903",
    sample_border_color: "#EAF0F5",
    sample_bg_color: "#FFF1F0",
  },
  "-1": {
    display_name: "Sampled",
    color: "#FF9F3F", // orange
    rgb_values: "255, 159, 63",
    box_shadow_color: "rgba(255, 159, 63, 0.3)",
    border_color: "#FF9F3F",

    text_color: "#FF9F3F",
    sample_border_color: "#EAF0F5",
    sample_bg_color: "#FFF8EB",
  },
  "-2": {
    display_name: "Scheduled",
    color: "#C4D2DF", // grey
    rgb_values: "196, 210, 223",
    box_shadow_color: "rgba(196, 210, 223, 0.3)",
    border_color: "#C4D2DF",

    text_color: "#6C7E8E",
    sample_border_color: "#EAF0F5",
    sample_bg_color: "#FFFFFF",
  },
  "-3": {
    display_name: "Deleted by Lab",
    color: "#E11903", // red
    rgb_values: "225, 25, 3",
    box_shadow_color: "rgba(225, 25, 3, 0.3)",
    border_color: "#E11903",

    text_color: "#E11903",
    sample_border_color: "#EAF0F5",
    sample_bg_color: "#FFF1F0",
  },
};

export default EVENT_SPEC_STYLE_MAP;

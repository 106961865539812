import React from "react";

import { Modal } from "antd";

import StyledButton from "../../../Common/UIComponents/StyledButton";

import "./EnvConfirmModal.css";

export default function EnvConfirmModal({
  headerText,
  bodyText,
  buttonText, // [0: cancel, 1: submit, 2: submit-loading]
  buttonFunctions, // [0: cancel, 1: submit, 2: submit-loading]
  loading,
  width,
  renderExtraContent,
}) {
  return (
    <Modal
      open
      maskClosable={false}
      closable={false}
      centered
      destroyOnClose
      footer={null}
      width={width ?? "391px"}
      wrapClassName="EnvConfirmModal"
    >
      <div className="envConfirmDel">
        <div className="envConfirmDelTitle">{headerText}</div>
        <div className="envConfirmDelTitleMessage">{bodyText}</div>
        {renderExtraContent && (
          <div className="envConfirmDelTitleMessage__extra">
            {renderExtraContent}
          </div>
        )}
        <div className="envConfirmDelBtn">
          <StyledButton
            htmlType="button"
            type="primary"
            ghost
            onClick={buttonFunctions[0]}
            disabled={loading}
          // loading={loading}
          >
            {buttonText[0]}
          </StyledButton>
          {loading
            ? (
              <StyledButton htmlType="button" type="primary" onClick={() => { }} disabled loading={loading}>
                <span>{buttonText[2]}</span>
              </StyledButton>
            )
            : (
              <StyledButton htmlType="button" type="primary" onClick={buttonFunctions[1]}>
                {buttonText[1]}
              </StyledButton>
            )}

        </div>
      </div>
    </Modal>
  );
}

import React from "react";

import {
  Form,
  Input,
} from "antd";

import "../CompanyContactForm.css";
import "../../CompanyInfoForm.css";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/onboarding/clearfield_2.svg";

export default function ContactInfoSection({
  emailValidator,
  phoneValidator,
}) {
  return (
    <Form.Item>
      <div className="CompanyContactForm__ContactInfoSection">
        <div className="OnboardingFormTitle">Contact Info</div>
        <Form.Item
          name="contact_name"
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Contact Name</span>}
          rules={[
            {
              required: true, message: "",
            },
            { max: 50, message: "Contact name cannot be longer than 50 characters" },
          ]}
          className="CompanyContactForm__ContactInfoSection_Fields"
        >
          <Input placeholder="Enter Contact name" allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }} />
        </Form.Item>
        <Form.Item
          name="position"
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Position</span>}
          rules={[
            {
              required: true, message: "",
            },
          ]}
          className="CompanyContactForm__ContactInfoSection_Fields"
        >
          <Input placeholder="Enter Position" allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }} />
        </Form.Item>
        <Form.Item
          name="email"
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Email Address</span>}
          rules={[
            {
              required: true, message: "",
            },
            {
              validator: emailValidator,
            },
            { max: 500, message: "Email cannot be longer than 500 characters" },
          ]}
          className="CompanyContactForm__ContactInfoSection_Fields"
        >
          <Input type="email" placeholder="Your email address" allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }} />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Phone Number</span>}
          rules={[
            {
              validator: phoneValidator,
            },
            { max: 100, message: "Phone number cannot be longer than 100 characters" },
          ]}
          className="CompanyContactForm__ContactInfoSection_Fields"
        >
          <Input type="tel" placeholder="Enter contact number" allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }} />
        </Form.Item>
      </div>
    </Form.Item>
  );
}

import React, { useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { toast } from "react-toastify";

import TimeFromNow from "../../../Common/TimeFromNow";

import { convertUtcToLocal } from "../../../Common/utils/dateUtils";

import { getDraftSubmissionsInfo } from "../../../../actions/sampleSubmission";

import { ReactComponent as EnvironmentIcon } from "../../../../assets/images/feature-icons/EnvironmentIconFilled.svg";
import { ReactComponent as ProductIcon } from "../../../../assets/images/feature-icons/ProductIconFilled.svg";
import { ReactComponent as EditIcon } from "../../../../assets/images/sample-submission/edit.svg";
import { ReactComponent as GarbageCan } from "../../../../assets/images/sample-submission/hoveredGarbageCan.svg";

import "./DraftSubmissionCard.css";

export default function DraftSubmissionCard({
  index, submission, setDraftEditing, setShowSubmissionForm, handleDeleteDraft, setNewAbortController, clearAbortController,
}) {
  const [loading, setLoading] = useState(false);

  const handleClickDraft = async () => {
    setLoading(true);
    const newAbortController = setNewAbortController();
    const {
      submission_id,
      submission_name,
      submission_date,
      sample_category,
    } = submission;
    const { success, message, result } = await getDraftSubmissionsInfo(submission.submission_id, newAbortController.signal);
    if (newAbortController.signal.aborted) {
      setLoading(false);
      return;
    }
    if (success) {
      setDraftEditing({
        submission_id, submission_name, submission_date: convertUtcToLocal(submission_date), ...result, sample_category,
      });
      setShowSubmissionForm(true);
    } else {
      toast.error(message);
    }
    clearAbortController();
    setLoading(false);
  };

  return (
    <li className="draft-submission-card">
      <header className="draft-submission-card-header">
        <span className="draft-submission-card-submission-name" onClick={loading ? null : handleClickDraft}>
          {submission.submission_name}
          { loading ? (
            <>
              <div className="draft-submission__Loading">
                <Spin indicator={<LoadingOutlined className="draft-submission__Checkbox__Spin" spin />} />
              </div>
            </>
          )
            : (
              <EditIcon className="draft-submission-card-edit">
                <title>edit icon</title>
              </EditIcon>
            )}
        </span>
        <GarbageCan onClick={() => handleDeleteDraft(index)} className="draft-delete-icon" width="12" height="12">
          <title>delete icon</title>
        </GarbageCan>
      </header>

      <div className="draft-submission-card-subtitle">
        <span className="draft-submission-card-subtitle-num-samples">
          {submission.sample_category === "product"
            ? <ProductIcon className="draft-submissions-status-icons" />
            : <EnvironmentIcon className="draft-submissions-status-icons" />}
          {submission.num_samples}
          {" "}
          {(submission.num_samples === 0 || submission.num_samples === 1) ? "Sample Added" : "Samples Added"}
        </span>
        <span className="draft-submission-card-time">
          <TimeFromNow time={convertUtcToLocal(submission.submission_date)} dateFormat="DD MMM YYYY" />
          {submission.submitted_by && (
            <>
              {" "}
              by
              {" "}
              {submission.submitted_by}
            </>
          )}
        </span>
      </div>
    </li>
  );
}

import React, { useState } from "react";
import { Tooltip } from "antd";
import closeIcon from "../../../../../../assets/images/analytics/closeIconSmall.png";
import "./GraphPill.css";

import { colorsAndIconsArray } from "../../../Helper";

export default function GraphPill({
  data, counter, updateSelectedGraph, selected, dataIsUndefined, linkReportFields, fieldsMap, selectedGraphHasSpec,
}) {
  const { product, test } = data;

  const setSelectedGraph = () => {
    const selectedGraphObj = {
      product,
      test,
      color: colorsAndIconsArray[counter].accentColor,
    };

    updateSelectedGraph(selectedGraphObj);
  };

  const [isHovering, setIsHovering] = useState(false);
  const colorObj = colorsAndIconsArray[counter];

  let boxShadowStyle = "";
  if (selected) boxShadowStyle = `inset 0px 0px 1px 1px  ${colorObj.accentColor}`;

  const nonEmptyFields = linkReportFields.filter((json_field) => product[json_field]);

  return (
    <Tooltip
      overlayClassName="Analytics__GraphPill__Tooltip"
      arrow={false}
      placement="topLeft"
      title={(
        <span className="Analytics__GraphPill__Tooltip__Text">
          {nonEmptyFields.map((json_field) => (
            <span key={json_field}>
              <span key={`${json_field}_title_field`} className="Analytics__GraphPill__Tooltip__FieldText">{`${fieldsMap[json_field].title_field}: `}</span>
              <span key={`${json_field}_json_field`} className="Analytics__GraphPill__Tooltip__ValueText">{`${product[json_field]} `}</span>
            </span>
          ))}
          <span className="Analytics__GraphPill__Tooltip__FieldText">{" \u2022 "}</span>
          <span key="test" className="Analytics__GraphPill__Tooltip__ValueText">{` ${test}`}</span>
          {!selectedGraphHasSpec && selected && (
          <>
            <span className="Analytics__GraphPill__Tooltip__FieldText">{"  \u2022 "}</span>
            <span key="test" className="Analytics__GraphPill__Tooltip__ValueText">{" No spec found for this test"}</span>
          </>
          )}
        </span>
)}
    >
      <div
        className="analyticsGraphPillContainer"
        style={{
          backgroundColor: isHovering ? colorObj.hoverColor : colorObj.backgroundColor,
          boxShadow: boxShadowStyle,
          cursor: dataIsUndefined ? "default" : "pointer",
        }}
        onClick={dataIsUndefined ? null : setSelectedGraph}
        onMouseEnter={dataIsUndefined ? null : () => setIsHovering(true)}
        onMouseLeave={dataIsUndefined ? null : () => setIsHovering(false)}
      >
        <div className="analyticsGraphPillSubContainer">
          <img src={colorsAndIconsArray[counter].productIcon} alt="Product" className="analyticsGraphPillImage" />
          <span className="analyticsGraphPillText">
            {nonEmptyFields.map((json_field, i) => (
              <span key={json_field}>
                <span key={`${json_field}_title_field`}>{`${fieldsMap[json_field].title_field}: `}</span>
                <span key={`${json_field}_json_field`} className="Analytics__GraphPill__ValueText">{`${product[json_field]}${i < nonEmptyFields.length - 1 ? " " : ""}`}</span>
              </span>
            ))}
          </span>
        </div>
        <div
          className="analyticsGraphPillDot"
          style={{
            backgroundColor: colorsAndIconsArray[counter].accentColor,
          }}
        />
        <div className="analyticsGraphPillSubContainer">
          <img src={colorsAndIconsArray[counter].testIcon} alt="Test" className="analyticsGraphPillImage" />
          <span className="analyticsGraphPillText">{test}</span>
        </div>
        {selected && <img src={closeIcon} alt="closeIcon" />}
      </div>
    </Tooltip>
  );
}

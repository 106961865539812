import React, { useEffect, useState, useCallback } from "react";

import moment from "moment";

import CustomCalendar from "./CustomCalendar";
import WeekMonthCheckbox from "./WeekMonthCheckbox";

import "./WeekFrequencyForm.css";

export default function WeekFrequencyForm({
  allowMultiple = true,
  updateSamplingData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  weekDays,
  setWeekDays,
}) {
  const [recurrences, setRecurrences] = useState(0);
  const ref = React.useRef();

  const flatPickerOptions = {
    mode: "single",
    minDate: moment().format("MM/DD/YYYY"),
    // maxDate: moment().add(1, "year").format("MM/DD/YYYY"),
  };

  /**
   * Find no. of recurrences
   */
  const getRecurrences = useCallback(() => {
    const start = moment(startDate, "MM/DD/YYYY");
    const end = moment(endDate, "MM/DD/YYYY");
    if (start.isAfter(end)) {
      return { recurrences: 0, dates: [] };
    }
    let count = 0;
    const dates = [];
    while (start <= end) {
      if (weekDays.includes(start.format("dddd").toLowerCase())) {
        dates.push(start.format("MM/DD/YYYY"));
        count++;
      }
      start.add(1, "day");
    }
    return { recurrences: count, dates };
  }, [endDate, startDate, weekDays]);

  useEffect(() => {
    const { recurrences: recurr, dates } = getRecurrences();
    setRecurrences(recurr);
    updateSamplingData(dates, "weekly");
  }, [getRecurrences]); // eslint-disable-line

  /**
   * Handles change of week days
   * @param {string[]} value Array of selected days
   */
  const handleChange = (value) => {
    setWeekDays(value);
  };

  return (
    <div className="WeekFrequencyForm">
      <div className="Field">
        <span className="FieldTitle">Start Date</span>
        <CustomCalendar
          ref={ref}
          value={startDate}
          onChange={([date]) => {
            const momentDate = moment(date);
            const formattedDate = momentDate.format("MM/DD/YYYY");
            setStartDate(formattedDate);
            if (endDate && moment(endDate, "MM/DD/YYYY").isBefore(momentDate, "day")) {
              setEndDate(formattedDate);
            }
          }}
          options={flatPickerOptions}
        />
      </div>
      <div className="Field">
        <span className="FieldTitle">Select day of repeat</span>
        <WeekMonthCheckbox
          allowMultiple={allowMultiple}
          type="day"
          onChange={handleChange}
          selectedDay={weekDays}
        />
      </div>
      <div className="Field">
        <span className="FieldTitle">End date</span>
        <CustomCalendar
          value={endDate}
          onChange={([date]) => {
            if (startDate && moment(startDate, "MM/DD/YYYY").isAfter(moment(date), "day")) return;
            setEndDate(date);
          }}
          options={{
            ...flatPickerOptions,
            minDate: startDate,
          }}
        />
        <span className="FieldTitle WeekFrequencyForm__RecurrenceText">No of Recurrences</span>
        <div className="Recurrences">
          {recurrences}
        </div>
      </div>
    </div>
  );
}

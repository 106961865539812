import React from "react";

import { AnimatePresence } from "framer-motion/dist/framer-motion";
import Scrollbar from "react-scrollbars-custom";

import AddedSampleCard from "./AddedSampleCard";

export default function AddedSamplesList({
  sampleList,
  handleEditSample,
  handleDeleteSample,
  sampleFields,
  sampleFieldsInfo,
  sampleEditingIdx,
  sampleListScrollbar,
  setClearTestSearch,
  submissionEditingSample,
  updateSample,
  compositeRef,
}) {
  return (
    <Scrollbar ref={sampleListScrollbar}>
      <div className="added-samples-container">
        <AnimatePresence>
          {sampleList.map((sample, idx) => (
            <AddedSampleCard
              key={sample.uuid}
              sample={sample}
              idx={idx}
              handleEditSample={handleEditSample}
              handleDeleteSample={handleDeleteSample}
              sampleFields={sampleFields}
              sampleFieldsInfo={sampleFieldsInfo}
              sampleEditingIdx={sampleEditingIdx}
              setClearTestSearch={setClearTestSearch}
              submissionEditingSample={submissionEditingSample}
              updateSample={updateSample}
              compositeRef={compositeRef}
            />
          ))}
        </AnimatePresence>
      </div>
    </Scrollbar>
  );
}

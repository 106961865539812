import React from "react";
import SpecManagerInput from "./SpecManagerInput";
import SpecManagerSelect from "../../../../Product/Report/Components/SpecManager/SpecManagerSelect";
import "./SpecsQuantitative.css";

export const UNIT_OPTIONS = ["None", "CFU/g", "CFU", "mg/kg", "ppb", "ppm", "%", "g", "ml"];

export default class SpecsQuantitative extends React.Component {
  onRangeOptionClick = (optionSelected) => {
    const { data, handleDataChange } = this.props;
    handleDataChange([optionSelected, data[1], data[2]]);
  }

  onUnitOptionClick = (optionSelected) => {
    const { data, handleDataChange } = this.props;
    handleDataChange([data[0], data[1], optionSelected]);
  }

  onInputChange = (value) => {
    const { data, handleDataChange } = this.props;
    handleDataChange([data[0], value, data[2]]);
  }

  render() {
    const { data } = this.props;
    const [max, number, unit] = data;
    return (
      <div
        className="EnvSpecsQuantitativeContainer"
      >
        <SpecManagerSelect
          width="152px"
          options={["Max", "Min"]}
          optionSelected={max || "Max"}
          onSelect={this.onRangeOptionClick}
        />
        <SpecManagerInput
          placeHolder="eg: 20"
          onChange={this.onInputChange}
          value={number}
          width="72px"
          additionalClassName={(!number ? "envRangeValueMissing" : "")}
        />
        <SpecManagerSelect
          width="84px"
          optionSelected={unit || "None"}
          options={UNIT_OPTIONS}
          onSelect={this.onUnitOptionClick}
        />
      </div>
    );
  }
}

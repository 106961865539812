import React from "react";

import StyledSwitch from "../../../../Common/UIComponents/StyledSwitch";

import { ReactComponent as EditOutlineIcon } from "../../../../../assets/images/sample-submission/edit.svg";

export default function SampleCompositeToggle({
  setShowCompositeModal, compositeData, compositeToggle, setCompositeToggle, setCompositeData, setInputValues, inputValues,
}) {
  const handleCompositeToggle = () => {
    if (!compositeToggle) {
      setShowCompositeModal(true);
      setCompositeData({ sample_count: 2, unique: null, identifier: [] });
    } else {
      const processedValues = { ...inputValues };
      Object.keys(processedValues).forEach((key) => {
        if (compositeData.identifier.includes(key)) {
          processedValues[key] = "";
        }
      });
      setInputValues(processedValues);
      setCompositeToggle(false);
      setCompositeData({ sample_count: null, unique: null, identifier: [] });
    }
  };

  return (
    <div className="sample-submission-composite-toggle-container">
      <div className="sample-submission-composite-container">
        <span>Composite Sample</span>
        <StyledSwitch
          checked={compositeToggle}
          onClick={handleCompositeToggle}
          className="CompositeSwitch"
          disabled={null}
          size="small"
        />
      </div>
      {compositeToggle && (
        <div className="sample-submission-composite-sample-container">
          <span>{`Sample: ${compositeData.sample_count}`}</span>
          <div onClick={() => setShowCompositeModal(true)} className="sample-submission-composite-edit">
            <EditOutlineIcon className="sample-submission-composite-edit-icon" role="img">
              <title>Edit Icon</title>
            </EditOutlineIcon>
          </div>
        </div>
      )}
    </div>
  );
}

export const CENTERFROMTOP = 34;
export const CENTERFROMLEFT = 20;
export const PINWIDTH = 40;
export const PINHEIGHT = 52;

export const QUADRANT_STYLE_MAP = {
  0: {
    display_name: "Pink Quadrant",
    color: "#f878ff",
    rgb_values: "248, 120, 255",
    hover_color: "rgba(248, 120, 255, 0.2)",
  },
  1: {
    display_name: "Teal Quadrant",
    color: "#0093A7",
    rgb_values: "0, 147, 167",
    hover_color: "rgba(0, 147, 167, 0.2)",
  },
  2: {
    display_name: "Purple Quadrant",
    color: "#8A1CFF",
    rgb_values: "138, 28, 255",
    hover_color: "rgba(138, 28, 255, 0.2)",
  },
  3: {
    display_name: "Olive Quadrant",
    color: "#A7BB2A",
    rgb_values: "167, 187, 42",
    hover_color: "rgba(167, 187, 42, 0.2)",
  },
};

export const PINS_SPEC_STYLE_MAP = {
  invalid: {
    display_name: "-",
    color: "#aeb3b8", // light gray
    rgb_values: "rgb(174, 179, 184)",
    box_shadow_color: "rgba(174, 179, 184, 0.15)",
    border_color: "#ebf0f5",
  },
  "": {
    display_name: "No Result",
    color: "#26ABE1", // light blue
    rgb_values: "38, 171, 225",
    box_shadow_color: "rgba(38, 171, 225, 0.21)",
    border_color: "#A7E3fA",
  },
  0: {
    display_name: "In Process",
    color: "#E1B226", // yellow
    rgb_values: "225, 178, 38",
    box_shadow_color: "rgba(225, 178, 38, 0.2)",
    border_color: "#F5DA8C",
  },
  1: {
    display_name: "In Spec",
    color: "#1EC04A", // green
    rgb_values: "30, 192, 74",
    box_shadow_color: "rgba(30, 192, 74, 0.15)",
    border_color: "#B2E8B4",
  },
  2: {
    display_name: "Out of Spec",
    color: "#E63559", // red
    rgb_values: "230, 53, 89",
    box_shadow_color: "rgba(230, 53, 89, 0.3)",
    border_color: "#F3BFBF",
  },
  3: {
    display_name: "Spec Incomplete",
    color: "#505050", // gray
    rgb_values: "80, 80, 80",
    box_shadow_color: "rgba(0, 0, 0, 0.15)",
    border_color: "#C5C5C5",
  },
  4: {
    display_name: "In Process - Retest", // retest
    color: "#F98006", // orange
    rgb_values: "249, 128, 6",
    box_shadow_color: "rgba(254, 128, 1, 0.2)",
    border_color: "#FFBF80",
  },
  5: {
    display_name: "In Spec - Retest", // retest
    color: "#1EC04A", // green
    rgb_values: "30, 192, 74",
    box_shadow_color: "rgba(30, 192, 74, 0.15)",
    border_color: "#B2E8B4",
  },
  6: {
    display_name: "Out of Spec - Retest", // retest
    color: "#E63559", // red
    rgb_values: "230, 53, 89",
    box_shadow_color: "rgba(230, 53, 89, 0.3)",
    border_color: "#F3BFBF",
  },
  "-1": {
    display_name: "Sampled",
    color: "#FF9F3F", // orange
    rgb_values: "255, 159, 63",
    box_shadow_color: "rgba(255, 159, 63, 0.3)",
    border_color: "#FF9F3F",
  },
  "-2": {
    display_name: "Scheduled",
    color: "#C4D2DF", // grey
    rgb_values: "196, 210, 223",
    box_shadow_color: "rgba(196, 210, 223, 0.3)",
    border_color: "#C4D2DF",
  },
};

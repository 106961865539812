import React, { useEffect, useRef, useState } from "react";
import "./TemplateBuilderInput.css";

export default function TemplateBuilderInput({
  id,
  placeholder,
  setRef,
  maxLength,
  readOnly = false,
  disabled = false,
  defaultValue,
  value,
  isTextArea = false,
  label,
  inputClassName = "",
  hasError,
  onChange,
}) {
  const inputRef = useRef();
  const [showLabel, setShowLabel] = useState(label && (value || defaultValue));
  const [focusLabel, setFocusLabel] = useState();

  /**
   * On mount, set ref of input
   */
  useEffect(() => {
    if (setRef) {
      setRef(inputRef.current);
    }
  }, []); // eslint-disable-line

  const onChangeInternal = (val) => {
    if (label) {
      setShowLabel(val.length > 0);
    }
    if (onChange) {
      onChange(val);
    }
  };

  if (isTextArea) {
    return (
      <textarea
        className={`TemplateBuilder__Textarea ${inputClassName} ${hasError ? "TemplateBuilder__InputError" : ""}`}
        ref={inputRef}
        type="text"
        name={id}
        id={id}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        autoComplete="off"
        maxLength={maxLength}
      />
    );
  }

  return (
    <div className="TemplateBuilder__InputDiv">
      {showLabel && (
      <label
        className={`TemplateBuilder__InputLabel ${focusLabel ? "TemplateBuilder__InputLabel--focus" : ""} ${disabled ? "TemplateBuilder__InputLabel--disabled" : ""} ${hasError ? "TemplateBuilder__InputError" : ""}`}
        htmlFor={id}
      >
        {label}
      </label>
      )}
      <input
        className={`TemplateBuilder__Input${showLabel ? "--withLabel" : ""} ${disabled ? "TemplateBuilder__Input--disabled" : ""} ${hasError ? "TemplateBuilder__InputError" : ""} ${inputClassName}`}
        ref={inputRef}
        readOnly={readOnly}
        disabled={disabled}
        type="text"
        id={id}
        // value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        autoComplete="off"
        maxLength={maxLength}
        onChange={((e) => onChangeInternal(e.target.value))}
        onFocus={() => {
          if (label) {
            setFocusLabel(true);
          }
        }}
        onBlur={() => {
          if (label) {
            setFocusLabel(false);
          }
        }}
      />
    </div>

  );
}

import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import Scrollbar from "react-scrollbars-custom";
import OutsideClickHandler from "react-outside-click-handler";
import Tag from "./Tag";
import StyledButton from "../../Common/UIComponents/StyledButton";
import "./productNewCard.css";
import lightBlueAddIcon from "../../../assets/images/index/addCircleIconBlue.png";
import darkBlueAddIcon from "../../../assets/images/index/addCircleIconMidnight.png";

function ProductNewCard(props) {
  const {
    activeTab, tagsEditing,
    linkReportFields,
  } = props;
  const [saveReminderDisplay, setSaveReminderDisplay] = useState(false);
  const [inputItemName, setInputItemName] = useState("");
  const [addTagIcon, setAddTagIcon] = useState(lightBlueAddIcon);
  const [tagInput, setTagInput] = useState("");
  const [editTagIndex, setEditTagIndex] = useState(-1);
  const [editTag, setEditTag] = useState("");
  const [tagsSet, setTagsSet] = useState(new Set());
  const itemNameRef = useRef(null);
  const fieldInputsRef = useRef(Array(linkReportFields.length));
  const itemTagRef = useRef(null);
  const scrollbarHeight = "81px";

  const setFieldInputsRef = (elem, idx) => {
    fieldInputsRef.current[idx] = elem;
  };

  const handleKeyPressInputItemNameChange = (e) => {
    if (e.charCode === 13) {
      if (activeTab === "0") {
        fieldInputsRef.current[0].focus();
      } else {
        itemTagRef.current.focus();
      }
    }
  };

  const handleKeyPressInputItemNumberChange = (e, idx) => {
    if (e.charCode === 13) {
      if (idx === linkReportFields.length - 1) {
        itemTagRef.current.focus();
      } else {
        fieldInputsRef.current[idx + 1].focus();
      }
    }
  };

  const handleEditTag = (tagIndex, itemTag) => {
    setEditTag(itemTag);
    setEditTagIndex(tagIndex);
    setTagInput(itemTag);
    itemTagRef.current.value = "";
  };

  const handleCancelEditTag = () => {
    setTagInput("");
    setEditTagIndex(-1);
    setEditTag("");
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleAddTag = async () => {
    const tags = tagsSet;
    const curEditTagIndex = editTagIndex;
    let newTag = tagInput.trim().toLowerCase();
    if (!newTag) {
      toast.error("Tag cannot be empty.");
      return false;
    }
    if (tags.has(newTag) && curEditTagIndex === -1) {
      toast.error("Please add a unique tag.");
      return false;
    }
    if (editTag && editTag.toLowerCase() === newTag) {
      handleCancelEditTag();
      return true;
    }
    newTag = newTag[0].toUpperCase() + newTag.substr(1);
    // update tag in DOM and backend
    const { handleAddTag: handleAdd } = props;
    const success = await handleAdd(newTag, "new0", editTag);
    if (success) {
      if (editTag) {
        tags.delete(editTag.toLowerCase());
      }
      tags.add(newTag.toLowerCase());
      setTagsSet(tags);
      // resetting the values
      if (editTagIndex === curEditTagIndex) {
        itemTagRef.current.value = "";
        handleCancelEditTag();
      }
      return true;
    }
    toast.error("Fail to add or update the tag.");
    return false;
  };

  const handleAddTagKeyPressed = (e) => {
    if (e.charCode === 13) {
      handleAddTag();
    }
  };

  const handleDeleteTag = async (e, tag) => {
    const tags = tagsSet;
    tags.delete(tag.toLowerCase());
    setTagsSet(tags);
    // delete tag in DOM
    const { handleDeleteTag: handleDelete } = props;
    await handleDelete(tag, "new0", e);
    handleCancelEditTag();
    return true;
  };

  const handleCancelButtonClick = () => {
    setInputItemName("");
    handleCancelEditTag();
    setTagsSet(null);

    itemTagRef.current.value = "";
    itemNameRef.current.value = "";
    if (activeTab === "0") {
      fieldInputsRef.current.forEach((elem) => { elem.value = ""; });
    }
    const { onCancelAddProductClick } = props;
    onCancelAddProductClick();
  };

  const handleSaveButtonClick = async () => {
    setSaveReminderDisplay(false);
    const linkReportFieldsObj = {};
    if (activeTab === "0") {
      if (!inputItemName.trim()) {
        toast.error("Please add Item name");
        return;
      }

      for (let i = 0; i < fieldInputsRef.current.length; i++) {
        const fieldInput = fieldInputsRef.current[i];
        const value = fieldInput.value.trim();
        const { json_field, title_field } = linkReportFields[i];
        if (!value) {
          toast.error(`Please add ${title_field}`);
          return;
        }
        linkReportFieldsObj[json_field] = value;
      }
    } else if (!inputItemName.trim()) {
      toast.error("Please add Item name");
      return;
    }
    const { onSaveAdd } = props;
    if (tagInput.trim()) {
      const success = await handleAddTag();
      if (success) {
        onSaveAdd(inputItemName.trim(), linkReportFieldsObj);
      } else {
        toast.error("fail to add the tag");
      }
    } else {
      onSaveAdd(inputItemName.trim(), linkReportFieldsObj);
    }
  };

  const { cancelButton, isMostRight } = props;

  const handleClickAway = () => {
    setSaveReminderDisplay(true);
  };

  return (

    <OutsideClickHandler onOutsideClick={handleClickAway}>
      <div className="product-card" onClick={(e) => { e.stopPropagation(); setSaveReminderDisplay(false); }}>
        <div className="addNewCard">
          <div className="product-card-title">
            <div
              className="newProductCard__Title"
            >
              Add New Item Card
            </div>
          </div>
          <div className="ProductNewCard__CardBody">
            <Scrollbar className="ProductNewCard__InputsScrollbar">
              <div className="ProductNewCard__ItemName">
                <label htmlFor="name">Item Name*</label>
                <textarea
                  className="newProductCard__Body--itemName-textArea"
                  ref={itemNameRef}
                  id="name"
                  type="text"
                  maxLength="250"
                  rows="2"
                  placeholder=""
                  onKeyPress={handleKeyPressInputItemNameChange}
                  onChange={(e) => setInputItemName(e.target.value)}
                />
                <div className={`charsCount NewProductCard__Body__CharsCount${activeTab !== "0" ? "--is-last" : ""}`}>
                  {inputItemName.length}
                  /250
                </div>
              </div>
              {activeTab === "0"
              && (
                <div className="ProductNewCard__LinkedFieldInputs">
                  {linkReportFields.map((link_item, link_idx) => (
                    <div className="newProductCard__Body--itemNumber" key={link_idx}>
                      <label htmlFor="number">
                        {link_item.title_field}
                        *
                      </label>
                      <input
                        className="newProductCard__Body--itemNumber-Input"
                        type="text"
                        placeholder=""
                        id="number"
                        onKeyPress={(e) => handleKeyPressInputItemNumberChange(e, link_idx)}
                        ref={(input) => setFieldInputsRef(input, link_idx)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Scrollbar>

            <div>
              <label htmlFor="tags" className="custom-field tag">Add & edit tags</label>
              <div className="newProductCard__TabsLabelInput">
                <input
                  className="newProductCard__Body--addEditTags-Input"
                  type="text"
                  id="tags"
                  placeholder="Enter tag name"
                  onChange={handleTagInputChange}
                  onKeyPress={(e) => handleAddTagKeyPressed(e, "new0")}
                  ref={itemTagRef}
                />
                <img
                  className="newProductCard__Body--addEditTags-addTagIcon"
                  src={addTagIcon}
                  alt="addTagIcon"
                  onMouseEnter={() => setAddTagIcon(darkBlueAddIcon)}
                  onMouseLeave={() => setAddTagIcon(lightBlueAddIcon)}
                  onClick={handleAddTag}
                />
              </div>
            </div>
            <Scrollbar
              className="newProductCard__Body--Scrollbar"
              style={{
                height: scrollbarHeight,
              }}
            >
              {tagsEditing.new0
                ? tagsEditing.new0.map((itemTag, tagIndex) => (
                  <Tag
                    key={tagIndex}
                    label={itemTag}
                    isEditing={tagIndex === editTagIndex}
                    tagInput={tagInput}
                    setTagInput={setTagInput}
                    handleDoubleClickTag={() => handleEditTag(tagIndex, itemTag)}
                    handleKeyPressAddTag={handleAddTagKeyPressed}
                    handleDeleteTag={(e) => handleDeleteTag(e, itemTag)}
                    handleTagInputOutsideClick={handleAddTag}
                  />
                ))
                : null}
            </Scrollbar>

            <div
              className="newProductCard__Body--Buttons"
            >
              {cancelButton && (
                <StyledButton
                  onClick={handleCancelButtonClick}
                  type="primary"
                  ghost
                >
                  Cancel
                </StyledButton>
              )}
              <StyledButton
                onClick={handleSaveButtonClick}
                disabled={editTag !== ""}
                type="primary"
                className="editSaveBtn"
              >
                Save
              </StyledButton>
              {saveReminderDisplay && cancelButton
                && (
                <div className={isMostRight ? "saveReminderLeft" : "saveReminderRight"}>
                  <div className="triangleForReminder" />
                  <div className="saveReminder">You have unsaved changes, please save the card before proceeding</div>
                </div>
                )}
            </div>
          </div>
        </div>

      </div>
    </OutsideClickHandler>
  );
}

export default ProductNewCard;

import React, { useEffect, useState } from "react";
import "./SamplesInfo.css";
import { QRCode } from "antd";
import { isEmpty } from "lodash";

export default function SamplesInfo({ submission, customerInfo }) {
  const [qrCodeData, setQRCodeData] = useState("{}");
  const [numTests, setNumTests] = useState();

  useEffect(() => {
    if (submission && !isEmpty(customerInfo)) {
      const num_tests = submission.samples_list.reduce((numTestsSoFar, { tests_list }) => numTestsSoFar + tests_list.length, 0);
      setNumTests(num_tests);
      setQRCodeData(submission.submit_id || submission.submit_name); // if submission id is empty, this is the logged out version, use the submission name
    }
  }, [submission, customerInfo]);

  return (
    <div className="SubmissionReceiptModal__SamplesInfo">
      <table className="SubmissionReceiptModal__SamplesInfo__Table">
        <thead>
          <tr className="SamplesInfo__Table__Row">
            <th>Total Quantity of</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          <tr className="SamplesInfo__Table__Row">
            <td>Samples</td>
            <td>{submission?.num_samples}</td>
          </tr>
          <tr className="SamplesInfo__Table__Row">
            <td>Tests</td>
            <td>{numTests ?? ""}</td>
          </tr>
        </tbody>
      </table>
      <div className="SubmissionReceiptModal__SamplesInfo__QRCode" id="sample_submission_receipt_qrcode">
        <QRCode
          status={qrCodeData === "{}" ? "loading" : "active"}
          value={qrCodeData}
          type="canvas"
          size={180}
          bordered={false}
        />
      </div>
    </div>

  );
}

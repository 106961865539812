import React, {
  useCallback, useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import DragDrop from "./DragDrop";
import { uploadFileToS3 } from "../../../../../utils/helpers";
import { addMapImage } from "../../../../../actions/envAnalytics";
import { s3EsvPriv } from "../../../../../utils/aws";
import "./UploadMap.css";
import UploadMapPreview from "./UploadMapPreview";
import LoadingConfirmBtn from "./LoadingConfirmBtn";

export default function UploadMap(props) {
  const {
    handleClickCancel, toggleShowCreateEnvModal, handleUploadImage, returnFileSize,
    uploadMapStatusControl, emptyFilesOrMap,
  } = props;
  const [src, setSrc] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageTitle, setImageTitle] = useState("");
  const [imgSize, setImgSize] = useState("");
  const [imgWidthHeight, setImgWidthHeight] = useState({ width: 0, height: 0 }); // Original size of the uploaded image
  const [imgErrorMessage, setImgErrorMessage] = useState([]);
  const [disableSubmitImg, setDisableSubmitImg] = useState(false);
  const [dragging, setDragging] = useState(false); // Used to change style when dragging files
  const [loading, setLoading] = useState(false);
  const FILE_SIZE_MAX_LIMIT = 50 * 1048576; // convert 50MB to bytes

  /**
   * Return error text if the selected files do not meet requirement.
   * @param {File} file A selected file
   * @returns {Array} Return error text array
   */
  const returnValidFileErr = (file) => {
    if (file.size > FILE_SIZE_MAX_LIMIT) {
      return ["File size is too big", "Max 50 MB"];
    }
    if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
      return ["File format is not supported", "png, jpg, and jpeg only"];
    }
    return [];
  };

  /** Called when dragged file enters the DragDrop area, to change UI style of the area */
  const onDragEnter = useCallback(() => {
    setDragging(true);
  }, []);

  /** Called when dragged file leaves the DragDrop area, to change UI style of the area */
  const onDragLeave = useCallback(() => {
    setDragging(false);
  }, []);

  /**
   * A callback function to handle upload image file.
   * 1. Accpect only one image at a time, otherwise, display an error toast message and exit the function
   * 2. On loading file, set the error message status and image meta data.
   */
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setDragging(false);
    if (acceptedFiles.length + fileRejections.length > 1) {
      toast.error("Only one image is allowed");
      return;
    }
    let file;
    if (acceptedFiles.length === 0) {
      file = fileRejections[0].file;
    } else {
      file = acceptedFiles[0];
    }
    const errorMsg = returnValidFileErr(file);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      /* Get image dims */
      const image = new Image();
      image.src = reader.result;
      image.onload = () => {
        setImgWidthHeight({ width: image.width, height: image.height });
      };
      setSrc(reader.result);
      setImgErrorMessage(errorMsg);
      setImageFile(file);
      setImageTitle(file.name.replace(/\.[^/.]+$/, ""));
      setImgSize(returnFileSize(file.size));
      if (errorMsg.length !== 0) { // if there is upload error, disable the done btn and set error status
        setDisableSubmitImg(true);
        uploadMapStatusControl(3);
      } else {
        setDisableSubmitImg(false);
        uploadMapStatusControl(1);
      }
    });
    reader.readAsDataURL(file);
    emptyFilesOrMap(false);
  }, []); // eslint-disable-line

  /**
   * Set the dropzone properties: file type, maximum file size, maximum file number.
   */
  const {
    getRootProps, getInputProps,
  } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    maxSize: FILE_SIZE_MAX_LIMIT,
    multiple: false,
  });

  /**
   * make an api call to upload the aws image path to database
   * @param {*} path the aws image path
   * @returns response from the api
   */
  const apiAddMap = async (path) => {
    const { success, message } = await addMapImage({
      image: path, title: imageTitle.trim(), width: imgWidthHeight.width, height: imgWidthHeight.height,
    });
    return { success, message };
  };

  /**
   * Upload the image to the target s3 bucket
   * @param {*} file the current image uploaded from local
   * @returns the aws api response and the file path
   */
  const addAssetToAWS = async (file) => {
    if (file) {
      const fileBlob = await uploadFileToS3({
        file, folderPath: "Analytics/Maps/", fileName: `${imageTitle}_${file.name}`, type: file.type,
      }, s3EsvPriv);
      if (fileBlob) {
        return { success: true, path: fileBlob.path };
      }
    } return { success: false };
  };

  /**
   * Handle the Done button onlick, and make api calss to uplaod the image to aws and store the path to database
   * Close the modal if success, otherwise, show the error message and remain the same page
   * @returns
   */
  const handleClickDone = async () => {
    setLoading(true);
    const resp = await addAssetToAWS(imageFile);
    if (resp.success) {
      const { success, message } = await apiAddMap(resp.path);
      setLoading(false);
      if (success) {
        handleUploadImage({
          title: imageTitle.trim(), image_height: imgWidthHeight.height, image_width: imgWidthHeight.width, path: resp.path,
        });
        toggleShowCreateEnvModal();
        return;
      }
      toast.error(message || "Failed to add map title");
      return;
    }
    setLoading(false);
    toast.error("Failed to upload image");
  };

  /**
   * handle the image title input on change
   * @param {*} e
   */
  const handleChangeTitle = (e, trimTitle = false) => {
    if (trimTitle) {
      setImageTitle(imageTitle.trim());
    } else {
      setImageTitle(e.target.value);
    }
  };

  /**
   * reset all image related states, disable the done button, and reset the progress bar status
   */
  const handleDeleteImage = () => {
    setSrc(null);
    setImageFile(null);
    setImageTitle(null);
    uploadMapStatusControl(1);
    setDisableSubmitImg(false);
    emptyFilesOrMap(true);
  };

  return (
    <>
      <div className={`create-env-modal-body ${dragging ? "env-drag-drop-map-dragging" : ""}`}>
        <div className="env-upload-map">
          {src
            ? (
              <UploadMapPreview
                src={src}
                imgSize={imgSize}
                imgErrorMessage={imgErrorMessage}
                imageTitle={imageTitle}
                handleChangeTitle={handleChangeTitle}
                handleDeleteImage={handleDeleteImage}
              />
            )
            : (
              <DragDrop
                uploadTarget="Map Image"
                getInputProps={getInputProps}
                getRootProps={getRootProps}
              />
            )}
        </div>
      </div>

      <div className="UploadMap__Btns env-create-modal-map-buttons">
        <button type="button" className="UploadMap__CancelBtn" onClick={handleClickCancel}>Cancel</button>
        { loading
          ? (
            <LoadingConfirmBtn />
          )
          : (
            <button
              type="button"
              className="UploadMap__DoneBtn"
              disabled={imageFile === null || disableSubmitImg}
              onClick={handleClickDone}
            >
              Done
            </button>
          )}
      </div>
    </>
  );
}

import React from "react";
import DragIndicator from "../../../../../../assets/images/product/template/DragIndicator.png";
import "./Draggable.css";

export default function DragHandle({ dragHandleProps }) {
  return (
    <img
      src={DragIndicator}
      alt="drag indicator"
      className="Draggable__DragIcon"
      {...dragHandleProps} // eslint-disable-line
    />
  );
}

import React from "react";

import { Modal } from "antd";

import StyledButton from "../../Common/UIComponents/StyledButton";

import { ReactComponent as OnboardingClose } from "../../../assets/images/OnboardingClose.svg";
import "./AddTestModal.css";

export default function SampleExitModal({
  setExitModal,
  handleGoBackToMainPage,
  headerText,
  bodyText,
}) {
  const onCancel = () => {
    setExitModal(false);
  };
  return (
    <Modal
      open
      closable
      centered
      footer={null}
      width="448px"
      wrapClassName="ConfirmModal"
      className="ConfirmModal"
      closeIcon={<OnboardingClose />}
      onCancel={onCancel}
    >
      <div className="AddTestConfirmDel">
        <div className="AddTestConfirmDelTitle">{headerText}</div>
        <div className="AddTestConfirmDelTitleMessage">{bodyText}</div>
        <div className="AddTestConfirmDelBtn">
          <StyledButton
            type="primary"
            ghost
            onClick={() => {
              setExitModal(false);
              handleGoBackToMainPage();
            }}
            className="AddTestConfirmDelBtnCancel"
          >
            Leave
          </StyledButton>
          <StyledButton type="primary" onClick={onCancel} className="AddTestConfirmDelBtnDelete">Stay</StyledButton>
        </div>
      </div>
    </Modal>
  );
}

import S3 from "aws-sdk/clients/s3";
import { config, CognitoIdentityCredentials } from "aws-sdk/lib/core";

import { ESV_PUB_BUCKET, ESV_PRIV_BUCKET } from "./Constant";

const IdentityPoolId = process.env.REACT_APP_S3_IDENTITYPOOLID;

config.update({
  region: "us-west-2",
  credentials: new CognitoIdentityCredentials({
    IdentityPoolId,
  }),
});

export const s3EsvPub = new S3({
  apiVersion: "2006-03-01",
  bucket: ESV_PUB_BUCKET,
  params: { Bucket: ESV_PUB_BUCKET },
});

export const s3EsvPriv = new S3({
  apiVersion: "2006-03-01",
  bucket: ESV_PRIV_BUCKET,
  params: { Bucket: ESV_PRIV_BUCKET },
});

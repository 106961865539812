import React, { useEffect, useState } from "react";
import "./SignUpInput.css";

export default function SignUpInput({
  type = "text",
  valueInParent = "",
  name,
  label,
  placeholder,
  required = true,
  disabled,
  error,
  setError,
  onChange,
  handleOnBlur,
  autoComplete,
  inputClassName = "",
  updateRequiredInputsFilledOut,
  setInputValue,
  children,
}) {
  const [value, setValue] = useState(valueInParent ?? "");

  useEffect(() => {
    setValue(valueInParent);
    if (error && valueInParent) {
      setError(false);
    }
  }, [valueInParent]); // eslint-disable-line

  const onChangeInternal = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange();
    }
    if (required) {
      updateRequiredInputsFilledOut(name, e.target.value);
    }
    if (error && e.target.value) {
      setError(false);
    }
  };

  const onBlur = () => {
    if (handleOnBlur) {
      handleOnBlur(value);
    } else if (setInputValue) {
      setInputValue(name, value);
    }
  };

  return (
    <div>
      <div className="signupSubtitle">{label}</div>
      <input
        type={type}
        className={`SignUpInput ${disabled ? "SignUpInput--disabled" : ""} ${error ? "SignUpInput--error" : ""} ${inputClassName}`}
        name={name}
        value={value}
        onChange={onChangeInternal}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onBlur={onBlur}
        autoComplete={autoComplete}
      />
      {children}
    </div>
  );
}

import React from "react";
import moment from "moment";
import { convertUtcToLocal } from "../../../Common/utils/dateUtils";
import "./ReceiptModalHeader.css";

export default function ReceiptModalHeader({ submission }) {
  const formattedDate = submission?.submit_date ? moment(new Date(convertUtcToLocal(submission.submit_date))).format("MMM DD, YYYY | hh:mm a") : "-";
  return (
    <div className="SubmissionReceiptModal__Header__Container">
      <span className="SubmissionReceiptModal__Header__Title">Sample Submission Receipt</span>
      <div className="SubmissionReceiptModal__Header">
        <table className="ReceiptModalHeader__Table">
          <thead>
            <tr className="ReceiptModalHeader__Table__Row">
              <th>Submission Name</th>
              <th>Submission Date</th>
              <th>PO#</th>
            </tr>
          </thead>
          <tbody>
            <tr className="ReceiptModalHeader__Table__Row">
              <td>{submission?.submit_name || "-"}</td>
              <td>{formattedDate}</td>
              <td>{submission?.po || "-"}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="ReceiptModalHeader__Comment__Title">Comments</div>
      <div className="ReceiptModalHeader__Comment__Body">{submission?.comment || "-"}</div>
    </div>
  );
}

import React from "react";
import DateBlock from "./DateBlock";
import arrow from "../../../../../assets/images/product/grayArrowLeft.png";
import disabledArrow from "../../../../../assets/images/product/disabledGrayArrowLeft.png";

import "./DatePicker.css";

export default function DatePicker(props) {
  const {
    data, updateActiveDate, activeDate, currentSlide, totalSlides, disabled,
  } = props;

  const dataSorted = data.sort((a, b) => Date.parse(b[0]) - Date.parse(a[0]));

  return (
    <div className="datePickerContainer">
      { (currentSlide === 0 || disabled)
        ? (
          <div className="disabledArrowContainer">
            <img src={disabledArrow} alt="prevArrow" className="navigationArrow " />
          </div>
        )
        : (
          <div className="arrowContainer" onClick={() => updateActiveDate(null, null, -1)}>
            <img src={arrow} alt="prevArrow" className="navigationArrow " />
          </div>
        )}
      {dataSorted.map((item, index) => {
        if (item === null) return null;
        const date = item[0];
        let active = false;
        if (date === activeDate) active = true;
        return <DateBlock key={index} index={index} date={date} active={active} updateActiveDate={updateActiveDate} disabled={disabled} />;
      })}
      { (currentSlide === totalSlides - 1 || disabled)
        ? (
          <div className="disabledArrowContainer">
            <img src={disabledArrow} alt="nextArrow" className="navigationArrow nextArrow" />
          </div>
        )
        : (
          <div className="arrowContainer" onClick={() => updateActiveDate(null, null, 1)}>
            <img src={arrow} alt="nextArrow" className="navigationArrow nextArrow" />
          </div>
        )}
    </div>
  );
}

import React from "react";
import TemplateBuilderDroppable from "../../DragAndDrop/TemplateBuilderDroppable";
import COABuilderDraggable from "../../DragAndDrop/COABuilderDraggable";
import "./COABuilderDroppableSection.css";

export default function COABuilderDroppableSection({
  inputs,
  deleteInput,
  droppableId,
  sectionPart,
  setRef,
  dataFieldToValuesMap,
}) {
  const getDroppableStyle = (snapshot) => ({
    background: snapshot.isDraggingOver ? "#F6F8FA" : "unset",
  });

  return (
    <TemplateBuilderDroppable
      droppableId={droppableId}
      className={`COABuilderDroppableSection COABuilderDroppableSection--${sectionPart}`}
      getDroppableStyle={getDroppableStyle}
    >
      {inputs.map((input, index) => (
        <COABuilderDraggable
          key={input.id}
          input={input}
          deleteInput={(i) => deleteInput(i, sectionPart)}
          index={index}
          setRef={(i, inputElem) => setRef(sectionPart, i, inputElem)}
          dataFieldOptions={input.flag === "1" ? dataFieldToValuesMap[input.jsonField] ?? [] : undefined}
        />
      ))}
    </TemplateBuilderDroppable>
  );
}

import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
} from "antd";
import { useForm } from "antd/es/form/Form";
import MyForm from "../../MyForm";
import { validatePassword } from "../../../Common/utils/validationUtils";
import "./SignupForm.css";
import FormButtons from "./ReusableComponents/FormButtons";
import useHandleStepChange from "../useHandleStepChange";
import { checkUser } from "../../../../utils/common";

import { ReactComponent as CloseIcon } from "../../../../assets/images/onboarding/clearField.svg";
import eyeClosedIcon from "../../../../assets/images/ph_eye-closed-fill@2x.png";
import eyeOpenedIcon from "../../../../assets/images/ph_eye_opened_black_fill@2x.png";

export default function SignupForm({
  initialValues,
  email,
  company_name,
  customer_name,
  phoneValidator,
  existingUser,
  setExistingUser,
  handleSubmit,
  onBackButton,
  onFinishFailed,
  submitting,
  stepChangeInProgress,
  handleFormStepChange,
}) {
  const FORM_NAME = "signup_form";
  const [form] = useForm();
  const [checkUserLoading, setCheckUserLoading] = useState(false);

  useEffect(() => {
    async function checkUserExists(mail) {
      setCheckUserLoading(true);
      const { success, firebase_exist_status, phone_number } = await checkUser(mail);
      if (success) {
        setExistingUser(firebase_exist_status);
        if (phone_number) {
          form.setFieldValue("phone_number", phone_number);
        }
      } else {
        setExistingUser(false);
      }
      setCheckUserLoading(false);
    }
    checkUserExists(email);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  /** Hook that handles form saving + step change when step is clicked */
  useHandleStepChange(FORM_NAME, form, stepChangeInProgress, handleFormStepChange);

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    layout: "vertical",
  };

  const passwordRequirementValidator = async (_, value) => {
    if (!value || validatePassword(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Password does not meet the requirements"));
  };

  return (
    <MyForm
      {...layout}// eslint-disable-line react/jsx-props-no-spreading
      form={form}
      initialValues={{
        ...initialValues, email, company_name, existingUser, customer_name,
      }}
      requiredMark={false}
      validateTrigger={["onChange", "onBlur"]}
      name={FORM_NAME}
      onFinish={(values) => handleSubmit(FORM_NAME, values)}
      onFinishFailed={onFinishFailed}
      disabled={submitting}
      className="OnboardingForm"
    >
      <div className="SignUp__MainForm">
        {/* <Form.Item label={<span className="MyForm__SectionLabel">Final Step!</span>} /> */}
        <div className={`OnboardingFormTitle ${existingUser ? "SignupForm__Title" : ""}`}>Final Step!</div>
        {existingUser && (
        <Form.Item noStyle>
          <span className="SignupForm__ExistingUser">
            It appears that you already have login credentials with ESV. Please click on the Set up ESV button below to proceed and login using your existing credentials.
            {/* If you have forgotten your password, you can utilize the Forgot Password button on the login page to reset your password after signing up. */}
          </span>
        </Form.Item>
        )}
        <Form.Item
          name="customer_name"
          label={<span className="SignUp__LabelText">Admin Name</span>}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="company_name"
          label={<span className="SignUp__LabelText">Company Name</span>}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="email"
          label={<span className="SignUp__LabelText">Email</span>}
          rules={[
            {
              required: true, message: "",
            },
          ]}
        >
          <Input type="email" disabled />
        </Form.Item>

        <Form.Item
          name="phone_number"
          label={<span className="SignUp__LabelText">Phone Number</span>}
          rules={[
            {
              validator: phoneValidator,
            },
            { max: 100, message: "Phone number cannot be longer than 100 characters" },
          ]}
        >
          <Input type="tel" allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }} autoComplete="off" disabled={existingUser} />
        </Form.Item>

        <Form.Item
          label={<span className="SignUp__LabelText">Password</span>}
        >
          <Form.Item noStyle>
            <span className="SignupForm__PasswordDesc">Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters</span>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: !existingUser,
                message: "",
              },
              {
                validator: passwordRequirementValidator,
              },
            ]}
            className="SignupForm_PasswordItem"
          >
            <Input.Password
              placeholder={existingUser ? "" : "Enter password"}
              autoComplete="new-password"
              disabled={existingUser}
              iconRender={(visible) => (visible ? (
                <img
                  src={eyeOpenedIcon}
                  alt="eye closed icon"
                />
              ) : (
                <img
                  src={eyeClosedIcon}
                  alt="eye closed icon"
                />
              ))}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="confirm"
          label={<span className="SignUp__LabelText">Confirm Password</span>}
          dependencies={["password"]}
          rules={[
            {
              required: !existingUser,
              message: "",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Does not match inputted password"));
              },
            }),
          ]}
          className="SignupForm_PasswordConfirm"
        >
          <Input.Password
            autoComplete="new-password"
            disabled={existingUser}
            iconRender={(visible) => (visible ? (
              <img
                src={eyeOpenedIcon}
                alt="eye closed icon"
              />
            ) : (
              <img
                src={eyeClosedIcon}
                alt="eye closed icon"
              />
            ))}
          />
        </Form.Item>
      </div>
      <FormButtons
        handleBack={() => onBackButton(FORM_NAME, form.getFieldValue())}
        nextButtonText="Set up ESV"
        loading={submitting}
        disable={checkUserLoading}
      />
    </MyForm>

  );
}

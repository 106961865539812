import React, { useEffect, useState } from "react";
// import moment from "moment";
import Modal from "antd/es/modal/Modal";
import DocumentsCardView from "../../Documents/Components/DocumentsCardView";
import DocumentsListView from "../../Documents/Components/DocumentsListView";

import { getFiles, searchFiles } from "../../../actions/documents";
import { getValueOfObj } from "../../../utils/helpers";

import circleCloseIcon from "../../../assets/images/index/crossCircleWhite.png";
import searchIcon from "../../../assets/images/index/searchIcon.png";
import closeIconDarkBlue from "../../../assets/images/index/closeMidnight.png";
import closeHighlightedIcon from "../../../assets/images/index/closeHighlighed.png";
import CardViewIcon from "../../../assets/images/index/cardViewIcon.png";
import ListViewIcon from "../../../assets/images/index/listViewIcon.png";
import CardViewIconInactive from "../../../assets/images/index/cardViewIconInactive.png";
import ListViewIconInactive from "../../../assets/images/index/listViewIconInactive.png";
import StyledButton from "../../Common/UIComponents/StyledButton";

import "./DocumentAddModal.css";
import SortBySelect from "./SortBySelect";

function DocumentAddModal({
  handleCloseAddFileModal,
  handleAddFileConfirm,
}) {
  const [sortByOpSelected, setSortByOpSelected] = useState(-1);
  const [documentsList, setDocumentsList] = useState([]);
  const [sortMenuVisible, setSortMenuVisible] = useState(false);
  const [numberOfFilesSelected, setNumberOfFilesSelected] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [closeIcon, setCloseIcon] = useState(closeIconDarkBlue);
  const [cardView, setCardView] = useState(false);
  const sortReportOption = ["Name", "Most recent", "Oldest"];

  useEffect(() => {
    const apiGetFiles = async () => {
      const response = await getFiles();
      if (response && response.success) {
        if (response.result.length < 1) {
          setDocumentsList([]);
        } else {
          const results = response.result[0];
          const data = [];
          results.files.forEach((file, index) => {
            data.push({
              key: index,
              name: file.name,
              extension: file.type,
              uploadDate: file.uploaded,
              fileSize: file.size,
              file: file.file_path,
              checked: false,
              renameDisable: true,
              numberOfFilesSelected: 0,
              renameValue: file.name,
            });
          });
          setDocumentsList(data);
        }
      }
    };
    apiGetFiles();
  }, []);

  const handleSearchChange = async (e) => {
    const { value } = e.target;
    setSortByOpSelected(-1);
    setSelectAll(false);
    setNumberOfFilesSelected(0);
    const response = await searchFiles(value);
    let files = [];
    if (response && response.success) {
      files = getValueOfObj(response, "result", "0", "files") || [];
      if (files) {
        const filteredFiles = files.map((file, index) => ({
          key: index,
          name: file.name,
          extension: file.type,
          uploadDate: file.uploaded,
          fileSize: file.size,
          file: file.file_path,
          checked: false,
          renameDisable: true,
          nubOfSelectedFile: 0,
          renameValue: file.name,
        }));
        setDocumentsList(filteredFiles);
      } else {
        setDocumentsList([]);
      }
      // let results = response.result;
      // if (results.length < 1) {
      //   setDocumentsList([]);
      // } else {
      //   results = results[0];
      //   const data = results.files.map((file, index) => ({
      //     key: index,
      //     name: file.name,
      //     extension: file.type,
      //     uploadDate: moment(file.uploaded).format("lll"),
      //     fileSize: file.size,
      //     file: file.file_path,
      //     checked: false,
      //     renameDisable: true,
      //     nubOfSelectedFile: 0,
      //     renameValue: file.name,
      //   }));
      //   setDocumentsList(data);
      // }
    } else {
      setDocumentsList([]);
    }
  };

  const sortByName = () => {
    const sortedFiles = documentsList.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      } if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    setDocumentsList(sortedFiles);
    setSortMenuVisible(!sortMenuVisible);
  };

  const sortByDateCreated = (des) => {
    const sortedFiles = documentsList.sort((a, b) => new Date(a.uploadDate) - new Date(b.uploadDate));

    if (des) {
      sortedFiles.reverse();
    }

    setDocumentsList(sortedFiles);
    setSortMenuVisible(!sortMenuVisible);
  };

  const handleSortByOptionClick = (option) => {
    switch (option) {
      case "Name":
        sortByName();
        setSortByOpSelected(0);
        break;
      case "Oldest":
        sortByDateCreated(false);
        setSortByOpSelected(2);
        break;
      case "Most recent":
        sortByDateCreated(true);
        setSortByOpSelected(1);
        break;
      default:
    }
  };

  const handleAddButtonClick = () => {
    const documentsToAdd = [];
    const documents = documentsList;
    documents.forEach((document) => {
      if (document.checked === true) {
        documentsToAdd.push(document);
        document.checked = false;
      }
    });
    setDocumentsList([...documents]);
    setSelectAll(false);
    setNumberOfFilesSelected(0);
    handleAddFileConfirm(documentsToAdd);
  };

  const handleChangeView = () => {
    setCardView(!cardView);
  };

  const handleCheckboxAll = (deselectAll = true) => {
    const files = documentsList.map((document) => {
      const newDocument = { ...document, checked: !deselectAll };
      return newDocument;
    });

    setDocumentsList(files);
    setSelectAll(!deselectAll);
    setNumberOfFilesSelected(deselectAll ? 0 : files.length);
  };

  const handleCheckbox = (e, key) => {
    e.stopPropagation();
    const files = documentsList;
    let counter = 0;
    files.forEach((file) => {
      if (file.key === key) {
        file.checked = !file.checked;
      }
      if (file.checked === true) {
        // eslint-disable-next-line no-plusplus
        counter++;
      }
    });

    setNumberOfFilesSelected(counter);
    setDocumentsList(files);
    if (counter === 0) {
      setSelectAll(false);
    } else if (counter === files.length) {
      setSelectAll(true);
    }
  };

  return (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      wrapClassName="DocumentAddModal"
      onCancel={handleCloseAddFileModal}
      onConfirm={handleCloseAddFileModal}
      className="AntDDocumentAddModal"
      width="90%"
    >
      <div className="DocumentAddModal">
        <div className="DocumentAddModal__Header">
          <div className="DocumentAddModal__Header__HeaderText">
            My documents
          </div>
          <img
            src={circleCloseIcon}
            alt="closeIcon"
            height={20}
            onClick={handleCloseAddFileModal}
          />
        </div>
        <div>
          <div className="DocumentAddModal__TopBar">
            <div className="DocumentAddModal__SearchSortContainer">
              <div
                className="DocumentAddModal__SearchSortContainer__Search"
              >
                <img
                  src={searchIcon}
                  alt="searchIcon"
                />
                <input
                  type="text"
                  placeholder="Search report"
                  name="searchReportText"
                  onChange={handleSearchChange}
                />
              </div>
              <SortBySelect
                selectedOptionIdx={sortByOpSelected}
                options={sortReportOption}
                onSelect={handleSortByOptionClick}
              />
            </div>
            <div
              className="DocumentAddModal__AddFilesContainer"
            >
              {numberOfFilesSelected === 0
                ? <div>Select to add your files</div>
                : (
                  <>
                    <img
                      onClick={() => handleCheckboxAll(true)}
                      className="DocumentAddModal__AddFilesContainer__ClearButton"
                      src={closeIcon}
                      onMouseEnter={() => setCloseIcon(closeHighlightedIcon)}
                      onMouseLeave={() => setCloseIcon(closeIconDarkBlue)}
                      alt=""
                    />
                    <div
                      className="DocumentAddModal__AddFilesContainer__NumFilesSelectedText"
                    >
                      {`${numberOfFilesSelected} files selected`}
                    </div>
                  </>
                )}
              <StyledButton
                type="primary"
                onClick={handleAddButtonClick}
                disabled={numberOfFilesSelected === 0}
                className="DocumentAddModal__AddFilesContainer__AddFileButton"
              >
                Add
              </StyledButton>
            </div>
          </div>
          <div className="DocumentAddModal__TableContainer">
            {cardView && (
            <>
              <div className="docFileTableHeadCard">
                <label className="checkboxContainer">
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxAll(selectAll)}
                    checked={selectAll}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <DocumentsCardView
                files={documentsList}
                handleCheckbox={handleCheckbox}
                readOnly
              />
            </>
            )}
            {!cardView && (
            <div className="DocumentAddModal__TableContainer__ListViewContainer">
              <DocumentsListView
                files={documentsList}
                readOnly
                handleCheckbox={handleCheckbox}
                handleCheckboxAll={() => handleCheckboxAll(selectAll)}
                selectAll={selectAll}
              />
            </div>
            )}
            <div
              className="DocumentAddModal__TableContainer__ViewToggleContainer"
              onClick={handleChangeView}
            >
              <img
                alt="cardViewIcon"
                className={cardView ? "ViewToggleContainer__ViewButton"
                  : "ViewToggleContainer__ViewButton ViewToggleContainer__ViewButton--inactive"}
                src={cardView ? CardViewIcon : CardViewIconInactive}
              />
              <img
                alt="listViewIcon"
                className={!cardView ? "ViewToggleContainer__ViewButton"
                  : "ViewToggleContainer__ViewButton ViewToggleContainer__ViewButton--inactive"}
                src={!cardView ? ListViewIcon : ListViewIconInactive}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DocumentAddModal;

import React from "react";
import { Tooltip } from "antd";
import "./StyledTooltip.css";

export default function StyledTooltip(
  {
    title = "",
    placement = "top",
    showArrow = true,
    children,
    className = "",
    open = null,
  },
) {
  return (
    <Tooltip
      placement={placement}
      overlayClassName={`TooltipComponent ${className}`}
      arrow={showArrow !== false}
      title={title}
      open={open}
    >
      {children}
    </Tooltip>
  );
}

import React, { Component } from "react";
import { toast } from "react-toastify";
// import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { AES, enc } from "crypto-js";
import { AES_128_BIT_KEY } from "../../../utils/Constant";
import NoResults from "../../Common/NoResultsGIF";
import "../index.css";
import "./SignUp.css";
import pumpkinIcon from "../../../assets/images/environment/pumpkin.gif";
import cancel from "../../../assets/images/cross_health___copy.png";
import esv_logo from "../../../assets/images/logo/esv_logo_2x.png";
import loginMainIcon from "../../../assets/images/loginMainImg.svg";
import loginBgIcon from "../../../assets/images/loginBgImg.svg";

import SignUpSinglePage from "./SignUpSinglePage";

import { getCompanyName, signupAdminOrMember } from "../../../actions/signup";

import { validatePassword, validatePhoneNumber } from "../../Common/utils/validationUtils";
import { checkUser } from "../../../utils/common";

export default class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassError: false,
      showInvalidPage: false,
      invalidMessage: "This link is not valid",
      passType: "password",
      confirmedPassType: "password",
      companyName: "",
      customerId: "",
      email: "",
      userName: "",
      userPhone: "",
      password: "",
      confirmedPassword: "",
      loadingPage: true,
      loadingSubmit: false,
      isExistingUser: false,
      isRegisteredCompany: false,
      disabled: {
        companyName: false,
        customerId: false,
        email: false,
        userName: false,
        userPhone: false,
        password: false,
        confirmedPassword: false,
      },
      errors: {
        companyName: false,
        customerId: false,
        email: false,
        userName: false,
        userPhone: false,
        password: false,
        confirmedPassword: false,
      },
      requiredInputsFilledOut: {
        companyName: false,
        customerId: false,
        email: false,
        userName: false,
        password: false,
        confirmedPassword: false,
      },
      autofillApiInProgress: { customerId: false, email: false },
    };
  }

  async componentDidMount() {
    const { history, userType } = this.props;
    try {
      const encrypted = history.location.search.slice(1); // removes "?" at the beginning of string
      const decrypted = AES.decrypt(encrypted, AES_128_BIT_KEY).toString(enc.Utf8);
      // console.log("enc", encrypted);
      // console.log("dec", decrypted);
      const queryVals = new URLSearchParams(decrypted);
      if (queryVals.size || (userType === "admin" && !encrypted)) {
      // A valid admin signup link must have non-empty company name, email, customer id.
      // A valid member signup link must have non-empty company name, email, username, role. phone can be empty.
        const companyName = queryVals.get("company_name") || "";
        const customerId = queryVals.get("customer_id") || "";
        const email = queryVals.get("email_address") || "";
        let userName = queryVals.get("user_name") || "";
        let userPhone = queryVals.get("phone") || "";

        const disabled = {
          companyName: userType === "member",
          customerId: customerId.length,
          email: email.length,
          userName: false,
          userPhone: false,
          password: false,
          confirmedPassword: false,
        };
        let requiredInputsFilledOut = {
          companyName: !!companyName.length,
          customerId: !!customerId.length || userType === "member",
          email: !!email.length,
          userName: !!userName.length,
          password: false,
          confirmedPassword: false,
        };
        let isExistingUser = false;

        /* Check if user exists given user email */
        if (email) {
          const {
            success, userNotFound, status, user_name, phone_number,
          } = await checkUser(email.trim(), companyName);
          if (userType === "member" && ((success && status === "1") || userNotFound)) { // For member signup, if user already signed up or if deleted, show invalid page.
            const { invalidMessage } = this.state;
            this.setState({ showInvalidPage: true, invalidMessage: userNotFound ? invalidMessage : "You have already signed up with ESV. Please log in." });
          } else if (userType === "admin" && success) { // For admin signup, if user already signed up, autofill info and disable passwords
            userName = user_name;
            userPhone = phone_number;
            disabled.password = true;
            disabled.confirmedPassword = true;
            requiredInputsFilledOut = {
              companyName: true,
              customerId: true,
              email: true,
              userName: true,
              password: true,
              confirmedPassword: true,
            };
            isExistingUser = true;
          }
        }

        this.setState({
          companyName,
          customerId,
          email,
          userName,
          userPhone,
          disabled,
          requiredInputsFilledOut,
          isExistingUser,
        });

        if ((userType === "admin" && companyName && email && customerId)
        || (userType === "admin" && !encrypted)
        || (userType === "member" && companyName && email && userName && queryVals.get("phone") !== undefined)) {
          this.setState({ loadingPage: false });
        } else {
          this.setState({ loadingPage: false, showInvalidPage: true });
        }
      } else {
        this.setState({ loadingPage: false, showInvalidPage: true });
      }
    } catch (error) {
      console.log("Signup error", error); // eslint-disable-line
      this.setState({ loadingPage: false, showInvalidPage: true });
    }
  }

  /**
   * Set value of field in state
   * @param {String} field "password" | "confirmedPassword" | ...
   * @param {String} value
   */
  setInputValue = (field, value) => {
    this.setState({ [field]: value });
  }

  setErrors = (field, value) => {
    const { errors } = this.state;
    this.setState({ errors: { ...errors, [field]: value } });
  }

  /**
   * Fetch company name with customer id, autofill company name input
   * @param {String} customerIDVal user input for customer id
   */
  handleAutofillCompanyName = async (customerIDVal) => {
    const {
      customerId, requiredInputsFilledOut, autofillApiInProgress, isRegisteredCompany,
    } = this.state;
    if (customerIDVal !== customerId || autofillApiInProgress.customerId) {
      if (customerIDVal.trim()) {
        const { success, company_name } = await getCompanyName(customerIDVal.trim());
        if (success) {
          this.setState({
            companyName: company_name,
            customerId: customerIDVal.trim(),
            requiredInputsFilledOut: { ...requiredInputsFilledOut, companyName: true },
            isRegisteredCompany: true,
          });
        } else {
          toast.error("Customer ID not found");
          const newState = {
            customerId: customerIDVal.trim(),
            isRegisteredCompany: false,
          };
          if (isRegisteredCompany) {
            newState.companyName = "";
            newState.requiredInputsFilledOut = { ...requiredInputsFilledOut, companyName: false };
          }
          this.setState(newState);
        }
      } else {
        const newState = {
          customerId: customerIDVal.trim(),
          isRegisteredCompany: false,
        };
        if (isRegisteredCompany) {
          newState.companyName = "";
          newState.requiredInputsFilledOut = { ...requiredInputsFilledOut, companyName: false };
        }
        this.setState(newState);
      }
    }
    this.setAutofillApiInProgress("customerId", false);
  }

  /**
   * Use email to check if user exists and is signed up already,
   * if yes, autofill phone and name, disable password inputs
   * @param {String} emailVal user input for email
   */
  handleUserCheck = async (emailVal) => {
    const { email, autofillApiInProgress, requiredInputsFilledOut } = this.state;
    if (email !== emailVal || autofillApiInProgress.email) {
      const {
        success, status, user_name, phone_number,
      } = await checkUser(emailVal.trim());

      const { disabled, isExistingUser } = this.state;
      if (success && status === "1") {
        this.setState({
          userName: user_name,
          userPhone: phone_number,
          email: emailVal.trim(),
          password: "",
          confirmedPassword: "",
          isExistingUser: true,
          disabled: {
            ...disabled,
            userName: true,
            userPhone: true,
            password: true,
            confirmedPassword: true,
          },
          requiredInputsFilledOut: {
            ...requiredInputsFilledOut,
            userName: true,
            password: true,
            confirmedPassword: true,
          },
        });
      } else if (isExistingUser) { // if isExistingUser, user info was autofilled before so we clear it
        this.setState({
          userName: "",
          userPhone: "",
          email: emailVal.trim(),
          isExistingUser: false,
          disabled: {
            ...disabled,
            userName: false,
            userPhone: false,
            password: false,
            confirmedPassword: false,
          },
          requiredInputsFilledOut: {
            ...requiredInputsFilledOut,
            userName: false,
            password: false,
            confirmedPassword: false,
          },
        });
      }
    }
    this.setAutofillApiInProgress("email", false);
  }

  updateRequiredInputsFilledOut = (field, val) => {
    const { requiredInputsFilledOut } = this.state;
    this.setState({ requiredInputsFilledOut: { ...requiredInputsFilledOut, [field]: !!val } });
  }

  setAutofillApiInProgress = (field, val) => {
    const { autofillApiInProgress } = this.state;
    this.setState({ autofillApiInProgress: { ...autofillApiInProgress, [field]: val } });
  }

  showHidePassword = () => this.setState(({ passType }) => ({
    passType: passType === "text" ? "password" : "text",
  }));

  showHideConfirmedPassword = () => this.setState(({ confirmedPassType }) => ({
    confirmedPassType: confirmedPassType === "text" ? "password" : "text",
  }));

  submitSignupForm = async (e) => {
    this.setState({ loadingSubmit: true });

    // stop refresh page on submit
    e.preventDefault();

    const inputForm = document.forms[0];
    const userName = inputForm.elements.userName.value.trim();
    const phone = inputForm.elements.phone.value.trim();
    const companyName = inputForm.elements.companyName.value.trim();
    const email = inputForm.elements.email.value.trim();
    const password = inputForm.elements.password.value;
    const confirmedPassword = inputForm.elements.confirmedPassword.value;
    const customerId = inputForm.elements.customerId?.value.trim();

    // add user to DB
    const { isExistingUser } = this.state;
    const { userType } = this.props;
    if (companyName && email && (userName || (isExistingUser && userType === "admin")) && (isExistingUser || (password && confirmedPassword))) {
      if (phone && !validatePhoneNumber(phone)) {
        toast.error("Phone number is not valid.");
        this.setErrors("userPhone", true);
      } else if (!isExistingUser && !validatePassword(password)) {
        toast.error("Password does not meet the requirements.");
        this.setErrors("password", true);
      } else if (!isExistingUser && password !== confirmedPassword) {
        toast.error("Those passwords didn't match. Try again.");
      } else if (userType === "member" || customerId) {
        const params = {
          user_email: email,
          user_name: userName,
          company_name: companyName,
          password,
          contact: phone,
        };

        if (userType === "admin") {
          params.customer_id = customerId;
          params.title = "Admin";
        } else if (userType === "member") {
          // params.title = userRole === "Viewer" ? "Member" : userRole;
        }

        const { history } = this.props;
        const { success, message } = await signupAdminOrMember(params);
        if (success) {
          history.push("/signup/success");
        } else if (userType === "admin" && message === "User already exists") {
          toast.error("User already exists. Only email is needed. Please submit form again.");
          const { disabled, requiredInputsFilledOut } = this.state;
          this.setState({
            userName: "",
            userPhone: "",
            password: "",
            confirmedPassword: "",
            isExistingUser: true,
            disabled: {
              ...disabled,
              userName: true,
              userPhone: true,
              password: true,
              confirmedPassword: true,
            },
            requiredInputsFilledOut: {
              ...requiredInputsFilledOut,
              userName: true,
              password: true,
              confirmedPassword: true,
            },
          });
        } else {
          toast.error(message);
        }
      }
    } else {
      toast.error("Fill in missing required inputs");
      this.setState({
        errors: {
          customerId: userType === "admin" && !customerId,
          userName: !userName,
          companyName: !companyName,
          email: !email,
          password: !isExistingUser && !password,
          confirmedPassword: !isExistingUser && !confirmedPassword,
        },
      });
    }
    this.setState({ loadingSubmit: false });
  };

  handleGotoLogin = () => {
    const { history } = this.props;
    history.push("/login");
  }

  render() {
    const {
      showPassError,
      showInvalidPage,
      invalidMessage,
      passType,
      confirmedPassType,
      companyName,
      customerId,
      email,
      userName,
      userPhone,
      password,
      confirmedPassword,
      loadingPage,
      loadingSubmit,
      disabled,
      errors,
      requiredInputsFilledOut,
      autofillApiInProgress,
    } = this.state;

    const { userType } = this.props;

    return (
      <div className="auth-wrapper">

        <div className="logoImgWrapper">
          <img
            src={esv_logo}
            alt="logo"
            className="logoImg"
          />
        </div>

        <div className={`row signupWhiteContainer ${showInvalidPage ? "signupWhiteContainer-invalid-link-container" : ""}`}>
          {!loadingPage && showInvalidPage && (
          <>
            <NoResults image={pumpkinIcon} message={invalidMessage} />
            <button type="button" onClick={this.handleGotoLogin} className="SignUp__LoginButton">Go to Login</button>
          </>
          )}
          {!loadingPage && !showInvalidPage && (
            <>
              <img
                src={cancel}
                alt="cancel"
                className="signupcancel signup-cancel"
                onClick={this.handleGotoLogin}
              />

              <div className="signupForm">
                <SignUpSinglePage
                  userType={userType}
                  showPassError={showPassError}
                  passType={passType}
                  confirmedPassType={confirmedPassType}
                  showHidePassword={this.showHidePassword}
                  showHideConfirmedPassword={this.showHideConfirmedPassword}
                  submitSignupForm={this.submitSignupForm}
                  companyName={companyName}
                  customerId={customerId}
                  email={email}
                  userName={userName}
                  userPhone={userPhone}
                  password={password}
                  confirmedPassword={confirmedPassword}
                  loadingSubmit={loadingSubmit}
                  handleAutofillCompanyName={this.handleAutofillCompanyName}
                  handleUserCheck={this.handleUserCheck}
                  disabled={disabled}
                  setInputValue={this.setInputValue}
                  errors={errors}
                  setErrors={this.setErrors}
                  requiredInputsFilledOut={requiredInputsFilledOut}
                  updateRequiredInputsFilledOut={this.updateRequiredInputsFilledOut}
                  setAutofillApiInProgress={this.setAutofillApiInProgress}
                  autofillApiInProgress={autofillApiInProgress}
                />
              </div>

              <div className="signupImage">
                <img
                  className="signupImageCharacters"
                  src={loginMainIcon}
                  alt="esv"
                />
                <img
                  className="signupImageBubbles"
                  src={loginBgIcon}
                  alt="esv"
                />
              </div>
            </>
          )}
        </div>

      </div>
    );
  }
}

import React from "react";
import "./OnboardingWelcomePage.css";
import GroupPNG from "../../assets/images/onboarding/Group.png";
import { ReactComponent as ContactInfo } from "../../assets/images/onboarding/ContactInfo.svg";
import { ReactComponent as Customer } from "../../assets/images/onboarding/customer.svg";
import { ReactComponent as AccountCreation } from "../../assets/images/onboarding/AccountCreation.svg";
import { ReactComponent as ESVLogo } from "../../assets/images/onboarding/ESVLogo.svg";
import StyledButton from "../Common/UIComponents/StyledButton";
import OnboardingWelcomePageCard from "./OnboardingWelcomePageCard";

export default function OnboardingWelcomePage({
  setDisplay, customerName, CustomerExist, addCompany = false,
}) {
  return (
    <div className="OnboardingWelcomePage">
      <div className="OnboardingWelcomePage__Container">
        <p className="OnboardingWelcomePage__Title">
          {
            addCompany && !customerName ? "Welcome" : `Welcome, ${customerName}`
          }
        </p>
        <p className="OnboardingWelcomePage__SubTitle">
          {addCompany ? "You are 3-steps away from creating a new company" : (
            <>
              {`You are 
            ${CustomerExist ? 3 : 4}-steps away from setting up Your`}
              <span className="ESVColor1"> ES</span>
              <span className="ESVColor2">V </span>
              account
            </>
          )}
        </p>
        <div className="OnboardingWelcomePage__StepContainerOne">
          <OnboardingWelcomePageCard
            className="OnboardingWelcomePage__Customer__Svg"
            Icon={<Customer className="OnboardingWelcomePage__Image" />}
            title="Customer Info"
            description={(
              <>
                Fill up the basic
                <br />
                information of company
              </>
              )}
          />
          {(!CustomerExist || addCompany) && (
            <OnboardingWelcomePageCard
              className="OnboardingWelcomePage__Contact__Svg"
              Icon={<ContactInfo fill="#375393" className="OnboardingWelcomePage__Image" />}
              title="Contact Info"
              description="Information about contact person"
            />
          )}

        </div>
        <div className="OnboardingWelcomePage__StepContainerTwo">
          <OnboardingWelcomePageCard
            className="OnboardingWelcomePage__ESV__Svg"
            Icon={<ESVLogo fill="#375393" className="OnboardingWelcomePage__Image" />}
            title="ESV"
            description={(
              <>
                Product details and
                <br />
                Environment details
              </>
              )}
          />
          {!addCompany && (
            <OnboardingWelcomePageCard
              className="OnboardingWelcomePage__AccountCreation__Svg"
              Icon={<AccountCreation fill="#375393" className="OnboardingWelcomePage__Image" />}
              title="Account Creation"
              description="Creating your credentials"
            />
          )}
        </div>
        <StyledButton type="primary" className="Ant__SubmitBtn onBoardingBtn" onClick={() => setDisplay(false)}>
          Lets get started
        </StyledButton>
      </div>
      <div className="OnboardingWelcomePage__Welcome__Image">
        <img src={GroupPNG} alt="" />
      </div>
    </div>
  );
}

import React from "react";
import closeIcon from "../../../../../../assets/images/OnboardingClose.svg";
import { ReactComponent as Info } from "../../../../../../assets/images/sample-submission/Info.svg";
import "./InviteHeader.css";
import StyledTooltip from "../../../../../Common/UIComponents/StyledTooltip";

export default function InviteHeader({ handleShowInvite }) {
  return (
    <div className="InviteModal__HeaderContainer">
      <div className="InviteModal__HeaderText">
        External Sharing
        <StyledTooltip
          title="Allows sharing with external domains, allowing them to submit samples on your behalf."
          placement="top"
          // overlayClassName="InviteModal__Tooltip"
        >
          <Info />
        </StyledTooltip>
      </div>
      <img
        src={closeIcon}
        alt="close"
        className="InviteModal__CloseIcon"
        onClick={handleShowInvite}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import ProductContainer from "./Containers/ProductContainer/ProductContainer";
import GraphContainer from "./Containers/GraphContainer/GraphContainer";
import TestContainer from "./Containers/TestContainer/TestContainer";

import { getProductFields } from "../../../actions/reports";
import { productSearch, testSearch } from "../../../actions/productAnalytics";

import "./styles/AnalyticsStyle.css";

export default function Analytics() {
  const [linkReportFields, setLinkReportFields] = useState([]);
  const [fieldsMap, setFieldsMap] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [disabledColumns, setDisabledColumns] = useState([]);
  const [productList, setProductList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [search, setSearch] = useState("");
  const [searchByField, setSearchByField] = useState();
  const [searchTest, setSearchTest] = useState("");
  const [loadingFields, setLoadingFields] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [showDatePicker, setDatePicker] = useState(false);
  // Disables columns as the max products and tests get selected
  const detectDisabledColumns = () => {
    let disabledColumnsCloned = disabledColumns.slice();

    // Max selected products is 3
    if (selectedProducts.length === 3 && !disabledColumnsCloned.includes("product")) {
      disabledColumnsCloned.push("product");
    } else if (selectedProducts.length < 3) {
      disabledColumnsCloned = disabledColumnsCloned.filter((item) => item !== "product");
    }

    // Max selected tests is 3
    if (selectedTests.length === 3 && !disabledColumnsCloned.includes("test")) {
      disabledColumnsCloned.push("test");
    } else if (selectedTests.length < 3) {
      disabledColumnsCloned = disabledColumnsCloned.filter((item) => item !== "test");
    }

    // Max num of graphs is 4
    if (selectedProducts.length * selectedTests.length >= 3) {
      disabledColumnsCloned.push("test");
      disabledColumnsCloned.push("product");
    }

    setDisabledColumns(disabledColumnsCloned);
  };

  // componentDidMount
  useEffect(() => {
    async function getLinkReportFields() {
      setLoadingFields(true);
      const response = await getProductFields();
      if (response.success) {
        if (!response.link_report || !response.link_report.length) {
          toast.error("Missing link report field");
          setLoadingFields(false);
          return;
        }
        setFieldsMap(response.fieldsMap);
        setSearchByField(response.link_report[0]); // set default search by field to first linked field
        setLinkReportFields(response.link_report);
      } else {
        toast.error("Failed to fetch fields");
      }
      setLoadingFields(false);
    }

    getLinkReportFields();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Gets fired when selectedProducts changes
  useEffect(() => {
    // gets tests based on search and selected products
    async function getAllTests(searchVal) {
      setTestLoading(true); // used refs to prevent component re-rendering which affects the selection animation
      const payload = {
        search: searchVal || "",
        products: selectedProducts,
      };
      const response = await testSearch(payload);

      if (response.success) {
        setTestList(response.result);
      } else {
        toast.error("Failed to get Tests");
      }
      setTestLoading(false);
    }

    detectDisabledColumns();

    if (linkReportFields.length) {
      // API call to update product list
      getAllTests(searchTest);
    }

    // API call to update test list when we have a product selected
    // if (selectedProducts.length !== 0)
  }, [selectedProducts, linkReportFields]); //eslint-disable-line

  // Gets fired when selectedTests changes
  useEffect(() => {
    // API call to get a list of products corresponding to the test selected
    async function getProductsAndTests() {
      setProductLoading(true);
      const payload = {
        search: "",
        search_by: searchByField || linkReportFields[0],
        tests: selectedTests,
      };

      const response = await productSearch(payload);

      if (response.success) {
        setProductList(response.result);
      } else {
        toast.error("Failed to get products");
      }
      setProductLoading(false);
    }

    // API call to get products based on search and selected tests
    async function getAllProducts(searchVal) {
      setProductLoading(true);
      const payload = {
        search: searchVal || "",
        search_by: searchByField || linkReportFields[0],
        tests: selectedTests,
      };

      const response = await productSearch(payload);

      if (response.success) {
        setProductList(response.result);
      } else {
        toast.error("Failed to get products");
      }
      setProductLoading(false);
    }

    detectDisabledColumns();

    if (linkReportFields.length) {
      // API call to update test list
      if (selectedTests.length === 0 && selectedProducts.length !== 0) {
        getAllProducts(search);
      } else if (search !== "" && selectedProducts.length !== 0 && selectedTests.length !== 0) {
        getAllProducts(search);
      } else if (selectedProducts.length !== 0) {
        getProductsAndTests();
      } else {
        getAllProducts(search);
      }
    }

  }, [selectedTests, linkReportFields]); //eslint-disable-line

  // Function that updates the selected tests and products -- passed down to all the children
  const updateSelectedOptions = (newValue, type) => {
    if (type === "product") {
      let clonedArr = selectedProducts.slice();
      if (clonedArr.some((item) => item.product_id === newValue.product_id)) {
        clonedArr = clonedArr.filter((item) => item.product_id !== newValue.product_id);
      } else {
        clonedArr.push(newValue);
      }
      setSelectedProducts(clonedArr);
    } else if (type === "test") {
      let clonedArr = selectedTests.slice();
      if (clonedArr.includes(newValue)) {
        clonedArr = clonedArr.filter((item) => item !== newValue);
      } else {
        clonedArr.push(newValue);
      }
      setSelectedTests(clonedArr);
    }

    // if (type === "product" && clonedArr.length === 0) {
    //   setSelectedTests([]);
    //   setDisabledColumns([]);
    // }
  };

  const selectedData = {
    selectedProducts,
    selectedTests,
  };

  if (linkReportFields === "") return null;
  return (
    <div
      className="analyticsMainContainer"
    >
      <div style={{
        opacity: showDatePicker ? 0.7 : 1,
        pointerEvents: showDatePicker ? "none" : "initial",
      }}
      >
        <ProductContainer
          updateSelectedOptions={updateSelectedOptions}
          selectedData={selectedData}
          disabledColumns={disabledColumns}
          updateProductList={setProductList}
          productList={productList}
          updateSearch={setSearch}
          parentLoading={productLoading || loadingFields}
          linkReportFields={linkReportFields}
          fieldsMap={fieldsMap}
          searchByField={searchByField}
          setSearchByField={setSearchByField}
          search={search}
        />
      </div>

      <GraphContainer
        linkReportFields={linkReportFields}
        selectedData={selectedData}
        setDatePicker={setDatePicker}
        showDatePicker={showDatePicker}
        fieldsMap={fieldsMap}
      />

      <div style={{
        opacity: showDatePicker ? 0.7 : 1,
        pointerEvents: showDatePicker ? "none" : "initial",
      }}
      >
        <TestContainer
          updateSelectedOptions={updateSelectedOptions}
          selectedData={selectedData}
          disabledColumns={disabledColumns}
          updateTestList={setTestList}
          testList={testList}
          updateSearch={setSearchTest}
          parentLoading={testLoading || loadingFields}
          searchTest={searchTest}
        />
      </div>
    </div>
  );
}

import React from "react";
import { Rectangle } from "recharts";

export default function GraphTooltipCursor(props) {
  const {
    height, width, points, xAxisTicks, showBarGraph,
  } = props;

  let xVal;
  let yVal;

  // If we have props.points then line graph
  if (points !== undefined) {
    const { x, y } = points[0];
    xVal = x;
    yVal = y;
  } else { // Bar graph
    const { x, y } = props;
    xVal = x;
    yVal = y;
  }

  const lineWidth = 1;
  const widthOfXAxisTicks = width / xAxisTicks;
  return (
    <>
      <Rectangle
        fill="url(#gradientColor)"
        x={xVal - (widthOfXAxisTicks / 2)}
        y={yVal}
        width={widthOfXAxisTicks}
        height={height}
      />

      {!showBarGraph && (
        <Rectangle
          fill="#C4D2DF"
          x={xVal - (lineWidth / 2)}
          y={yVal}
          width={lineWidth}
          height={height}
        />
      )}
    </>
  );
}

import React from "react";
import Scrollbar from "react-scrollbars-custom";
import { ReactComponent as FileStatusIcon } from "../../../../../assets/images/environment/xls-file-ring.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/environment/delete.svg";
import "./UploadSwabFiles.css";
import "./SwabFileItem.css";

export default function SwabFileItem(props) {
  const {
    fileData, toggleDeleteConfirmation, returnFileSize, linkPatternFields,
  } = props;

  return (
    <li className={`uploaded-files-item ${fileData.errorMessages ? "uploaded-files-error-item" : ""}`}>
      <div className="uploaded-files-item-main">
        <div className="file-icon-and-name-container">
          <FileStatusIcon className={`file-status-${fileData.apiStatus ?? "not-submitted"}`} width={40} height={40} />
          <div>
            <p className="env-uploaded-file-text">{fileData.path}</p>
            <div className="uploaded-files-item-file-size">
              {returnFileSize(fileData.size)}
            </div>
          </div>
        </div>

        <div className={`uploaded-files-item-right ${fileData.errorMessages ? "uploaded-files-error-item-right" : ""}`}>
          {fileData.errorMessages && (
          <div className="uploaded-files-item-error-text-title-body">
            <div className="uploaded-files-item-text-title uploaded-files-item-error-text">
              {fileData.errorMessages.title}
            </div>
            <div>{fileData.errorMessages.body}</div>
          </div>
          )}

          {fileData.apiStatus && fileData.apiStatus === "success" && (
          <div className="uploaded-files-item-error-text">
            <div className="uploaded-files-item-text-title uploaded-files-item-success-text">
              Success
            </div>
          </div>
          )}
          {(fileData.apiStatus === undefined || fileData.apiStatus === "failed") && (
          <div className="uploaded-files-item-delete" onClick={() => toggleDeleteConfirmation("file-modal", true, fileData)}>
            <DeleteIcon width={7.25} height={7.25} />
          </div>
          )}
        </div>
      </div>

      {fileData?.errorMessages && (
      <div
        className="uploaded-files-item-swab-errors"
        style={{ height: `${fileData.errorMessages.numSwabs * 22 + 25}px` }}
      >
        <Scrollbar>
          <table>
            <thead>
              <tr>
                {linkPatternFields.map(({ title_field }, idx) => <th key={idx}>{title_field}</th>)}
                <th key="status">Status</th>
              </tr>
            </thead>
            <tbody>
              {fileData.errorMessages.failedSwabs?.map((swab, idx) => (
                <tr
                  key={idx}
                >
                  {linkPatternFields.map(({ json_field }, idx_td) => (
                    <td
                      key={idx_td}
                      className="uploaded-files-item-swab-error uploaded-files-item-error-text"
                    >
                      {swab[json_field]}
                    </td>
                  ))}
                  <td
                    key="message"
                    className="uploaded-files-item-swab-error uploaded-files-item-error-text"
                  >
                    {swab.message}
                  </td>
                </tr>
              ))}
              {fileData.errorMessages.successSwabs?.map(({ swab }, idx) => (
                <tr
                  key={idx}
                >
                  {linkPatternFields.map(({ json_field }, idx_td) => (
                    <td
                      key={idx_td}
                      className="uploaded-files-item-swab-error uploaded-files-item-success-text"
                    >
                      {swab[json_field]}
                    </td>
                  ))}
                  <td className="uploaded-files-item-swab-error uploaded-files-item-success-text">
                    {swab.message}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Scrollbar>
      </div>
  )}
    </li>
  );
}

import React, { Component } from "react";

export default class OutsideClick extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { flag, onCancelEditTemplate, onCancelEdit } = this.props;
    if (flag === "editIndex") {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        onCancelEdit();
      }
    } else if (flag === "editTemplate") {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        onCancelEditTemplate();
      }
    } else if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      onCancelEdit();
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    const { className, children } = this.props;
    return <div className={className} ref={this.wrapperRef}>{children}</div>;
  }
}

import React from "react";
import moment from "moment";
import PdfThumbnail from "../Product/Report/Components/Share/PdfThumbnail";

export default function ReportThumbnail(props) {
  const { blobData } = props;

  return (
    <div className="reportThumbnailContainer">
      <div className="reportThumbnailText">{moment(new Date(blobData.date)).format("DD MMM YYYY")}</div>

      <PdfThumbnail blobData={blobData} pageIndex={0} width="78px" height="88px" />
    </div>
  );
}

import React, { Component } from "react";
import "./SignUpSinglePage.css";
import { every, some } from "lodash";
import SignUpInput from "./SignUpInput";
import SignUpPasswordInput from "./SignUpPasswordInput";
import ButtonWithLoading from "../../Common/ButtonWithLoading";

export default class SignUpSinglePage extends Component {
  render() {
    const {
      userType,
      showPassError,
      passType,
      confirmedPassType,
      showHidePassword,
      showHideConfirmedPassword,
      submitSignupForm,
      companyName,
      customerId,
      email,
      userName,
      userPhone,
      password,
      confirmedPassword,
      handleAutofillCompanyName,
      handleUserCheck,
      disabled,
      setInputValue,
      loadingSubmit,
      errors,
      setErrors,
      requiredInputsFilledOut,
      updateRequiredInputsFilledOut,
      setAutofillApiInProgress,
      autofillApiInProgress,
    } = this.props;

    return (
      <div className="SignUpSinglePage">
        {loadingSubmit && (<div className="SignUpSinglePage__LoadingDiv" />)}
        <div className="signupTitle">
          Personal Info
        </div>

        <form
          className="signup-single-page-form"
          onSubmit={submitSignupForm}
        >
          {userType === "admin" && (
          <SignUpInput
            valueInParent={customerId}
            name="customerId"
            label="Genista Customer ID"
            placeholder="Genista Customer ID"
            onChange={() => setAutofillApiInProgress("customerId", true)}
            disabled={disabled.customerId}
            error={errors.customerId}
            setError={(val) => setErrors("customerId", val)}
            handleOnBlur={handleAutofillCompanyName}
            updateRequiredInputsFilledOut={updateRequiredInputsFilledOut}
          />
          )}
          <SignUpInput
            valueInParent={companyName}
            name="companyName"
            label="Company name"
            placeholder="Company name"
            disabled={disabled.companyName}
            error={errors.companyName}
            setError={(val) => setErrors("companyName", val)}
            updateRequiredInputsFilledOut={updateRequiredInputsFilledOut}
            setInputValue={setInputValue}
          />
          <SignUpInput
            type="email"
            valueInParent={email}
            name="email"
            label="Email address"
            placeholder="Email"
            onChange={() => setAutofillApiInProgress("email", true)}
            disabled={disabled.email}
            error={errors.email}
            setError={(val) => setErrors("email", val)}
            handleOnBlur={handleUserCheck}
            updateRequiredInputsFilledOut={updateRequiredInputsFilledOut}
          />
          <SignUpInput
            valueInParent={userName}
            name="userName"
            label="User name"
            placeholder="User name"
            disabled={disabled.userName}
            error={errors.userName}
            setError={(val) => setErrors("userName", val)}
            updateRequiredInputsFilledOut={updateRequiredInputsFilledOut}
            setInputValue={setInputValue}
          />
          <SignUpInput
            valueInParent={userPhone}
            name="phone"
            label="Phone"
            required={false}
            placeholder={userPhone || "Phone"}
            disabled={false}
            autoComplete="new-password"
            error={errors.userPhone}
            setError={(val) => setErrors("userPhone", val)}
            handleOnBlur={(val) => setInputValue("userPhone", val)}
          />
          <SignUpPasswordInput
            valueInParent={password}
            label="Password"
            type={passType}
            name="password"
            placeholder="Password"
            disabled={disabled.password}
            showHide={showHidePassword}
            error={errors.password}
            setError={(val) => setErrors("password", val)}
            updateRequiredInputsFilledOut={updateRequiredInputsFilledOut}
            setInputValue={setInputValue}
          />
          <SignUpPasswordInput
            valueInParent={confirmedPassword}
            label="Confirm Password"
            type={confirmedPassType}
            name="confirmedPassword"
            placeholder="Confirm"
            disabled={disabled.confirmedPassword}
            showHide={showHideConfirmedPassword}
            error={errors.confirmedPassword}
            setError={(val) => setErrors("confirmedPassword", val)}
            updateRequiredInputsFilledOut={updateRequiredInputsFilledOut}
            setInputValue={setInputValue}
          />
          <ButtonWithLoading
            defaultText="Sign Up"
            loadingText="Submitting"
            className="signup-single-page-sign-up-button"
            disable={!every(requiredInputsFilledOut) || some(autofillApiInProgress)}
            isLoading={loadingSubmit}
            typeIsSubmit
          />
          <div>
            {showPassError ? (
              <span id="user-error" className="pass-error">
                User with this account already exists.
              </span>
            ) : null}
          </div>
        </form>

      </div>
    );
  }
}

import React, { useState } from "react";
import LandingPage from "../CommonComponent/LandingPage";
import GroupPNG from "../../../../assets/images/onboarding/Group.png";
import OnboardingForm from "../CommonComponent/OnboardingForm/OnboardingForm";
import SuccessLandingPage from "../CommonComponent/SuccessLandingPage";

export default function EnvironmentLandingPage() {
  const [displayForm, setDisplayForm] = useState(false);
  const [success, setSuccess] = useState("");

  if (displayForm) {
    return <OnboardingForm formStep={2} setDisplayForm={setDisplayForm} setSuccess={setSuccess} />;
  }

  if ((success === "Environment") && !displayForm) {
    return (
      <SuccessLandingPage success={success} GroupPNG={GroupPNG} />
    );
  }

  return (
    <LandingPage
      heading={(
        <>
          Environment Testing journey
          <br />
          with us
        </>
        )}
      description={(
        <>
          Please setup your environment sample
          <br />
          submission fields to enable submitting
          <br />
          environment samples.
        </>
        )}
      image={GroupPNG}
      setDisplayForm={setDisplayForm}
    />
  );
}

import React, { Component } from "react";
import "./NoResultsGIF.css";

class NoResults extends Component {
  render() {
    const { image, message, className } = this.props;
    return (
      <div
        className={`no-results-gif-common ${className}`}
      >
        <img
          src={image}
          alt="no results"
        />
        <div
          className="no-results-gif-common__msg"
        >
          {message}
        </div>
      </div>
    );
  }
}

export default NoResults;

import React,
{
  useEffect, useRef, useState,
} from "react";

import { Input, Select } from "antd";
import moment from "moment/moment";

import DatePicker from "../../../Common/DatePicker/DatePicker";
import OutsideClick from "../../../Product/Report/OutsideClick";
import SubmissionFilterButton from "../Common/SubmissionFilterButton";

import { getTabsList } from "../../../../utils/common";

import { getSubmittedSubmissionsInfo } from "../../../../actions/sampleSubmission";

import { ReactComponent as ChevronDown } from "../../../../assets/images/common/ChevronDown.svg";
import { ReactComponent as EnvironmentIcon } from "../../../../assets/images/feature-icons/EnvironmentIconFilled.svg";
import { ReactComponent as ProductIcon } from "../../../../assets/images/feature-icons/ProductIconFilled.svg";
import SearchIcon from "../../../../assets/images/searchIconGrey.png";

import "./SubmissionsSearch.css";

const searchTypeOptions = [
  { label: "All", value: "all" },
  { label: "Submission Name", value: "submission_name" },
  { label: "Sample ID", value: "sample_id" },
];

export default function SubmissionsSearch({
  getSubmissions,
  search,
  setSearch,
  dateFilter,
  setDateFilter,
  submittedByLst,
  setSubmittedByLst,
  setSearchField,
  loadingState,
  setSubmitBy,
  setSampleFilterCategory,
  searchByField,
  setSearchByField,
}) {
  const [dateSelected, setDateSelected] = useState("");
  const [prevDateSelected, setPrevDateSelected] = useState("");
  const [submittedBySearch, setSubmittedBySearch] = useState("");
  const [, setShowFilter] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [showEnvironment, setShowEnvironment] = useState(false);
  const [filterCategory, setFilterCategory] = useState("");
  const [tabsFilter, setTabsFilter] = useState(false);
  const searchRef = useRef();

  const getDates = async () => {
    const payload = {
      page: 1,
      submission_id: "",
      search_value: search,
      search_by: searchByField,
      date_to: "",
      date_from: "",
      submittedBySearch,
      submitted_by: submittedBySearch,
    };
    const { success, dates, submitted_by_lst } = await getSubmittedSubmissionsInfo(payload);
    if (success) {
      setSubmittedByLst(submitted_by_lst);
      return dates ?? [];
    }
    return [];
  };

  /**
   * Handle the filter category
   */
  useEffect(() => {
    const tabsList = getTabsList();
    if (tabsList?.includes("product") && tabsList?.includes("environment")) {
      setTabsFilter(true);
    }
  }, []);

  /**
   * Handle search
   * @param {String} value
   * setState of search value
   */
  const handleSearch = (value) => {
    getSubmissions(1, value, searchByField, dateFilter, false, submittedBySearch, searchByField, filterCategory);
    setSearch(value);
  };

  /**
   * Handler for search box to start search when Enter is pressed
   * @param {Event} e
   */
  const handleSearchOnEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch(e.target.value);
    }
  };

  const handleSubmittedBySearch = (value) => {
    getSubmissions(1, search, searchByField, dateFilter, false, value ?? "", searchByField, filterCategory);
    setSubmittedBySearch(value ?? "");
  };
  const handleSearchByCategory = (value) => {
    let category;
    if (value === "product") {
      category = "product";
    } else if (value === "environment") {
      category = "environment";
    } else {
      category = "";
    }
    getSubmissions(1, search, searchByField, dateFilter, false, submittedBySearch, searchByField, category);
    setFilterCategory(category);
    setSampleFilterCategory(category);
  };
  /**
   * State of submittedBySearch field in parent
   */
  useEffect(() => {
    setSubmitBy(submittedBySearch);
  }, [submittedBySearch]); // eslint-disable-line

  /**
   * Convert date range in local time to utc time.
   * @param {String} startDate date in local time (2023-12-12 format)
   * @param {String} endDate date in local time (2023-12-12 format)
   */
  const convertLocalDateRangetoUTC = (startDate, endDate) => {
    if (startDate || endDate) {
      const localStart = moment(startDate).startOf("day");
      const utcStart = moment(localStart).utc().format();
      let localEnd;
      if (endDate) {
        localEnd = moment(endDate).endOf("day");
      } else {
        localEnd = moment(startDate).endOf("day");
      }
      const utcEnd = moment(localEnd).utc().format();
      return [utcStart, utcEnd];
    }
    return [startDate, endDate]; // dates are empty
  };

  /**
   * Handle filter by date
   * @param {String} startDate date in local time (2023-12-12 format)
   * @param {String} endDate date in local time (2023-12-12 format)
   */
  const handleDateFilter = (startDate, endDate) => {
    const [dateFromUTC, dateToUTC] = convertLocalDateRangetoUTC(startDate, endDate);
    const dateFilterObj = {
      dateFromUTC, dateToUTC, dateFromLocal: startDate, dateToLocal: endDate,
    };
    getSubmissions(1, search, searchByField, dateFilterObj, false, submittedBySearch, searchByField, filterCategory);
    setDateFilter({
      dateFromUTC, dateToUTC, dateFromLocal: startDate, dateToLocal: endDate,
    });
  };
  /**
 * @param {String} field
 */
  const handleSearchByChange = (field) => {
    setSearchByField(field);
    getSubmissions(1, searchRef.current.input.value ?? "", field, dateFilter, false, submittedBySearch, field, filterCategory);
    setSearch(searchRef.current.input.value);
  };
  /**
   * To handle the click outside of the search field
  */

  const handleOutsideClick = () => {
    if (!searchRef.current.input.value) {
      setShowFilter(false);
    }
  };
  /**
   * State of search field in parent
   */
  useEffect(() => {
    setSearchField(searchByField);
  }, [searchByField]); // eslint-disable-line

  const handleProductFilterToggle = () => {
    if (showEnvironment) {
      setShowProduct(true);
      setShowEnvironment(false);
    } else {
      setShowProduct(!showProduct);
    }
    handleSearchByCategory(!showProduct ? "product" : "");
  };

  const handleEnvironmentFilterToggle = () => {
    if (showProduct) {
      setShowProduct(false);
      setShowEnvironment(true);
    } else {
      setShowEnvironment(!showEnvironment);
    }
    handleSearchByCategory(!showEnvironment ? "environment" : "");
  };

  return (
    <div className="SubmissionsSearch">
      <div className="SubmissionsSearch__Filters_SearchDiv_Filters">
        <OutsideClick
          onCancelEdit={handleOutsideClick}
        >
          <div className="SubmissionSearch__Filters_SearchDiv SampleSubmission__SearchType">
            <Input
              type="search"
              onChange={(e) => (!e.target.value ? handleSearch("") : null)}
              onKeyUp={handleSearchOnEnter}
              className="SubmissionsSearch__Input"
              size="large"
              placeholder="Search"
              onFocus={() => { setShowFilter(true); }}
              ref={searchRef}
              disabled={loadingState}
              allowClear
              prefix={(
                <img src={SearchIcon} alt="Search icon" />
              )}
              addonAfter={(
                <Select
                  id="SubmissionsSearch__InputSelect"
                  popupClassName="SubmissionsSearch__InputSelect__DropdownList"
                  value={searchByField}
                  onChange={handleSearchByChange}
                  options={searchTypeOptions}
                  disabled={loadingState}
                  suffixIcon={(
                    <ChevronDown className="SubmissionsSearch__InputSelect__DropdownIcon" />
                  )}
                />
              )}
            />
            {/* <AnimatePresence>
              { showFilter
              && (
                <motion.div
                  initial={{
                    height: 0, scaleY: 0, opacity: 0, y: "-100%",
                  }}
                  animate={{
                    height: "auto", scaleY: 1, opacity: 1, y: "0", transformOrigin: "top",
                  }}
                  exit={{
                    height: 0, scaleY: 0, opacity: 0, y: "-100%",
                  }}
                  transition={{ type: "tween", ease: "linear" }}
                  className="SubmissionSearch__Filters"
                >

                  <StyledRadioGroup
                    // defaultValue="submission_search"
                    value={searchByField}
                    onChange={(e) => handleSearchByChange(e.target.value)}
                    options={[{ label: "Submission Name", value: "submission_search" }, { label: "Sample ID", value: "sample_search" }]}
                    disabled={loadingState}
                  />
                </motion.div>
              )}
            </AnimatePresence> */}
          </div>
        </OutsideClick>

        <Select
          id="SubmissionsSearch-select"
          placeholder="Submitted by"
          showSearch
          onSelect={handleSubmittedBySearch}
          options={submittedByLst?.map((value) => ({ label: value, value }))}
          rootClassName="SubmissionsSearch__SubmittedBySelect"
          popupClassName="SubmissionsSearch__SubmittedBySelect__Popup"
          allowClear
          onClear={handleSubmittedBySearch}
          disabled={loadingState}
        />

        <DatePicker
          handleSearchDate={handleDateFilter}
          getReportDates={getDates}
          value={dateSelected}
          onChange={setDateSelected}
          prevValue={prevDateSelected}
          setPrevValue={setPrevDateSelected}
          searchOnClear
          useLocalTime
          popoverPlacement="bottomLeft"
          datePickerDisplayProps={{
            displayTextPosition: "left",
            displayClassName: "SubmissionsSearch__DatePicker",
          }}
        />
      </div>
      {tabsFilter
        && (
          <div className="SubmissionSearch__Filters_Filter_Buttons">
            <SubmissionFilterButton handleToggleFilter={handleProductFilterToggle} specSelected={showProduct} specText="Product" Icon={ProductIcon} />
            <SubmissionFilterButton handleToggleFilter={handleEnvironmentFilterToggle} specSelected={showEnvironment} specText="Environment" Icon={EnvironmentIcon} />
          </div>
        )}
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import StyledTooltip from "../../../Common/UIComponents/StyledTooltip";
import { ReactComponent as ExclamationIcon } from "../../../../assets/images/signup-invite/exclamationIcon.svg";
import { validateEmail } from "../../../Common/utils/validationUtils";
import "../SignUpInvite.css";
import "./FieldInput.css";

export default function FieldInput({
  type,
  field,
  label,
  setRef,
  id,
  readOnlyVal,
  valueInParent,
  isSendingInvite,
  errorIconInnerPlacement,
  converToDBColumnName,
  ifReadOnly,
  disabled,
  errorsFromParent,
  setFieldError,
  getFieldError,
  handleOnBlur,
  handleOnChange,
}) {
  const [value, setValue] = useState(valueInParent ?? "");
  const [nonFormatError, setNonFormatError] = useState(false); // either input is empty, or value needs to be an existing field and it is not
  const [inputErrorSet, setInputErrorSet] = useState(new Set());
  const [badFormat, setBadFormat] = useState(false);
  const inputRef = useRef(null);

  const readOnly = (readOnlyVal !== undefined && readOnlyVal !== "") || ifReadOnly;

  /* When valueInParent, set error to false if not empty, update value */
  useEffect(() => {
    if (valueInParent !== undefined) { // if field value is affected by value in parent
      setValue(valueInParent);
      if (valueInParent && nonFormatError) { // if non-empty and there's an error
        setNonFormatError(false);
        if (getFieldError(field, errorsFromParent)) {
          setFieldError(field, false, type || "customer");
        }
      }
    }
    }, [valueInParent]); // eslint-disable-line

  /* When readOnlyVal changes in parent, set error to false if readOnlyVal is not empty */
  useEffect(() => {
    if (readOnlyVal !== undefined) { // if field value controlled solely by readOnlyVal coming from parent
      if (readOnlyVal && nonFormatError) { // if non-empty and there's an error
        setNonFormatError(false);
        if (getFieldError(field, errorsFromParent)) {
          setFieldError(field, false, type || "customer");
        }
      }
    }
  }, [readOnlyVal]); // eslint-disable-line

  /** When value is non-empty and isEmpty was previously set, set isEmpty to false */
  useEffect(() => {
    if (value && nonFormatError) {
      setNonFormatError(false);
      if (getFieldError(field, errorsFromParent)) {
        setFieldError(field, false, type || "customer");
      }
    }
  }, [value]); //eslint-disable-line

  /** When value changes, check if the user has inputted any invalid characters */
  useEffect(() => {
    if ((type === "prod" || type === "env" || field.includes("company_name"))) {
      const invalidRegexArr = (type === "prod" || type === "env") ? ["\\(", "\\)", "&"] : ["\\/", "\\\\"]; // escape characters - \\ - needed for parentheses in regex
      const regex = new RegExp(invalidRegexArr.join("|"), "g"); // global flag to find all matches and not just the first
      const invalidMatches = value.match(regex);
      if (invalidMatches) {
        setInputErrorSet(new Set(invalidMatches));
      } else {
        setInputErrorSet(new Set());
      }
    } else if (field.includes("email") && value) {
      const isValid = validateEmail(value);
      if (badFormat && isValid) {
        setBadFormat(false);
      }
    }
  }, [value]); //eslint-disable-line

  useEffect(() => {
    setNonFormatError(getFieldError(field, errorsFromParent));
  }, [errorsFromParent]); // eslint-disable-line

  // /** onFocus, if the error border was set from the parent
  //  * (after clicking submit the field was missing),
  //  * isEmpty will be false, so set isEmpty to true.
  //  */
  // const onFocus = () => {
  //   if (inputRef.current !== null && inputRef.current.classList.contains("signup-invite-input-error")) {
  //     setNonFormatError(true);
  //   }
  // };

  /** If user didn't input anything and leaves the field,
   * set isEmpty to true so error border is shown.
   */
  const onBlur = () => {
    const val = field === "delimeter" ? value : value.trim();
    if (!val) {
      setNonFormatError(true);
      setBadFormat(false);
    } else if (field.includes("email")) {
      setBadFormat(!validateEmail(value));
    }
    if (handleOnBlur) {
      handleOnBlur(value);
    }
  };

  /** on key down in input, check if key is parentheses, if yes, don't let user input key
   * @param {Event} e KeyDown event
   */
  const onKeyDown = (e) => {
    const invalidChars = (type === "prod" || type === "env") ? ["(", ")", "&"] : ["/", "\\"];
    if (invalidChars.includes(e.key)) {
      // console.log("prevent default");
      e.preventDefault();
    }
  };

  /** Called when input changes. If it is "Display Name" input, converToDBColumnName needs to be called to set up DB Column input accordingly */
  const onChange = (e) => {
    setValue(e.target.value);
    if (converToDBColumnName) {
      converToDBColumnName(e.target.value);
    }
    if (handleOnChange) { // when customer_id field changes, set this variable to indicate that an api call should be made on blur
      handleOnChange();
    }
  };

  return (
    <div className="signup-invite-input">
      <div className="signup-invite-input-label">
        {label && (
        <label
          htmlFor={id ?? `signup-invite-fixed-input-${label}`}
        >
          {label}
        </label>
        ) }
        {!errorIconInnerPlacement && inputErrorSet.size !== 0 && (
        <StyledTooltip
          title={(
            <div className="signup-invite-invalid-chars-tooltip-title">
              <span>Invalid input:</span>
              {Array.from(inputErrorSet).map((char, i) => (
                <li key={i} className="invalid-char">{char}</li>
              ))}
            </div>
              )}
          placement="right"
        >
          <ExclamationIcon width={13} height={13} />
        </StyledTooltip>
        )}
        {badFormat && (
        <StyledTooltip
          title={(
            <div className="signup-invite-invalid-chars-tooltip-title">
              <span>Invalid email format</span>
            </div>
              )}
          placement="right"
        >
          <ExclamationIcon width={13} height={13} />
        </StyledTooltip>
        )}
      </div>
      <div className={errorIconInnerPlacement && inputErrorSet.size !== 0 ? "FieldInputParentDiv FieldInputParentDiv--InnerErrorPlacement" : "FieldInputParentDiv"}>
        <input
        className={(readOnly || isSendingInvite || disabled)// eslint-disable-line
          ? `signup-invite-input-read-only ${nonFormatError ? "signup-invite-input-error" : ""}`
          : `${nonFormatError ? "signup-invite-input-error" : ""} ${inputErrorSet.size || badFormat ? "signup-invite-input-invalid" : ""}`}
          id={id ?? `signup-invite-fixed-input-${label}`}
          type="text"
          value={readOnly ? readOnlyVal : value}
          readOnly={readOnly || isSendingInvite}
          disabled={disabled}
          required="required"
          onChange={onChange}
          onBlur={onBlur}
          // onFocus={onFocus}
          onKeyDown={(type === "prod" || type === "env" || field.includes("company_name")) ? onKeyDown : null} // only disallow (, ), & for prod and env fields, \, / for company name
          ref={(input) => {
            setRef(field, input, type); // set ref in SignUpInvite component
            inputRef.current = input; // set ref for internal use
          }}
        />
        {errorIconInnerPlacement && inputErrorSet.size !== 0 && (
        <div className="FieldInputParentDiv__ErrorIcon">
          <StyledTooltip
            title={(
              <div className="signup-invite-invalid-chars-tooltip-title">
                <span>Invalid input:</span>
                {Array.from(inputErrorSet).map((char, i) => (
                  <li key={i} className="invalid-char">{char}</li>
                ))}
              </div>
              )}
            placement="right"
          >
            <ExclamationIcon width={12} height={12} />
          </StyledTooltip>
        </div>
        )}
      </div>
    </div>
  );
}

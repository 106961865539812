import React, { useEffect, useMemo, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ReportsTableFieldCell from "./ReportsTableFieldCell";
import ReportsTableSpecCell from "./ReportsTableSpecCell";
import ReportsTableActionsCell from "./ReportsTableActionsCell";
import SpecManager from "../Components/SpecManager/SpecManager";
// import ShareModal from "../Components/Share/ShareModal";
import SampleResultsModal from "../Components/ReportSpecs/SampleResultsModal";
import { multipleLinkReportFieldsObjToObj } from "../utils";
import NoResults from "../../../Common/NoResults";
import emptyStateIcon from "../../../../assets/images/EmptyState_V2.png";
import Loading from "../../../Common/Loading";
import ReportsTableHeader from "./ReportsTableHeader";
import ReportsTableRow from "./ReportsTableRow";
import "../../Product.css";
import "./ReportsTable.css";

export default function ReportsTable({
  wrapperClassName = "",
  fields,
  setFields,
  reports,
  linkReportJsonFields,
  searchValToHighlight,
  loading,
  loadingNewContent,
  scrollbarRef,
  scrollbarId,
  handleUpdateRowSpecFlag = () => {},
  handleFetchNextPage,
  handleDataRefreshAfterExitingSpecManager = () => {},
  enableRowSelection = false,
  onRowSelection = () => true,
  disableSampleResultsModalInteraction = false, // add specs and request retest disabled
  disableTableHeaderInteraction = false, // disable reordering and renaming
  disableActions = false, // hide report column
  setTableInstance = () => {},
}) {
  const [showSampleResultsModal, setShowSampleResultsModal] = useState(false);
  const [showModalRowIdx, setShowModalRowIdx] = useState(null);
  const [showSpecManager, setShowSpecManager] = useState(false);
  const [specManagerProduct, setSpecManagerProduct] = useState(null);
  const [specsUpdated, setSpecsUpdated] = useState(false);
  // const [showShareModal, setShowShareModal] = useState(false);
  // const [shareModalIndex, setShareModalIndex] = useState(0);

  /**
   * Open the SampleResultsModal on click of Specification cell
   * @param {Number} idx index of row
   */
  const handleShowSampleResultsModal = (idx, e) => {
    if (e) {
      e.stopPropagation();
    }
    setShowSampleResultsModal(true);
    setShowModalRowIdx(idx);
  };

  /**
   * Handle row selection. If onRowSelection returns false, revert selection.
   * @param {Row} row
   */
  const handleRowSelection = async (row) => {
    const action = row.getIsSelected() ? "deselect" : "select";
    row.toggleSelected();
    const success = await onRowSelection(row, action);
    if (success === false) { // revert selection
      row.toggleSelected();
    }
  };

  /** Helps with creating column definition types if called with a row type (doesn't do much now, but will ensure type-safety once typescript is integrated) */
  const columnHelper = createColumnHelper();

  /** By default, columns will be center aligned and visible */
  const defaultColumn = useMemo(
    () => ({
      align: "center",
      visible: true,
    }),
    [],
  );

  /** Set columns on mount. Will update when fields, linkReportJsonFields, or searchValToHighlight are updated (memoized for performance) */
  const columns = useMemo(() => [
    ...fields.map(({ json_field, title_field }, i) => columnHelper.accessor(json_field, {
      header: title_field,
      className: i < linkReportJsonFields.length ? "ReportsTable__LinkedFieldCell" : "ReportsTable__FieldCell",
      align: i < linkReportJsonFields.length ? "left" : "center",
      cell: (rowData) => (
        <ReportsTableFieldCell
          searchValToHighlight={searchValToHighlight}
          rowData={rowData}
          jsonField={json_field}
          showCheckbox={enableRowSelection && i === 0}
        />
      ),
    })),
    columnHelper.accessor("specs_flag", {
      header: "Specification",
      className: "ReportsTable__SpecCell",
      align: "left",
      onClick: handleShowSampleResultsModal,
      cell: (rowData) => (<ReportsTableSpecCell rowData={rowData} />),
    }),
    columnHelper.display({
      id: "actions",
      header: "Reports",
      className: "ReportsTable__ActionsCell",
      align: "center",
      enableHiding: true,
      visible: !disableActions, // if actions disabled, hide column
      cell: (rowData) => (<ReportsTableActionsCell item={rowData.row.original} />),
    }),
  ], [fields, linkReportJsonFields, searchValToHighlight]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Get obj of hidden column accessorKey/ids
   * @param {Array} columnsDef array of column objects
   * @returns {Object} { id or accessorKey: Boolean } false if that column is hidden
   */
  const getColumnVisibilityState = (columnsDef) => {
    const columnsArr = columnsDef.filter((col) => col.visible === false);
    const columnsObj = columnsArr.reduce((objSoFar, currColumn) => ({
      ...objSoFar,
      [currColumn.accessorKey ?? currColumn.id]: false,
    }), {});
    return columnsObj;
  };

  /** *** Initialize table instance using useReactTable hook **** */
  const table = useReactTable({
    data: reports,
    columns,
    defaultColumn,
    initialState: {
      columnVisibility: getColumnVisibilityState(columns),
    },
    enableRowSelection,
    enableHiding: true,
    getCoreRowModel: getCoreRowModel(),
  });

  /** Set table instance in parent when table updates */
  useEffect(() => {
    if (table) {
      setTableInstance(table);
    }
  }, [table]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * On exit of SampleResultsModal, handle state reset
   * and data refresh if:
   * * specs have been updated, and/or
   * * retest has been successfully requested
   * @param {String | undefined} updatedSpecFlag new spec flag if it has changed
   */
  const handleExitSampleResultsModal = (updatedSpecFlag) => {
    setShowSampleResultsModal(false);
    setShowModalRowIdx(null);

    if (updatedSpecFlag) {
      handleUpdateRowSpecFlag(showModalRowIdx, updatedSpecFlag);
    }

    if (specsUpdated) {
      handleDataRefreshAfterExitingSpecManager();
      setSpecsUpdated(false);
    }
  };

  // /**
  //  * Toggle share modal.
  //  * @param {Number} index index of row
  //  * @param {Boolean} value whether to show share modal or not
  //  */
  // const toggleShareModal = (index, value) => {
  //   setShowShareModal(value);
  //   setShareModalIndex(index);
  // };

  /**
   * Handles infinite scroll
   * @param {React.ScrollEvent} e
   */
  const handleScrollToBottom = (e) => {
    const tolerance = 3;
    const bottom = Math.abs(Math.ceil(e.contentScrollHeight - e.scrollTop) - Math.ceil(e.clientHeight)) <= tolerance;
    if (bottom) {
      handleFetchNextPage();
    }
  };

  /**
   * Handles opening the spec manager from the sample results modal.
   * @param {String} productId
   * @param {Object} product object of linked fields vals ex: {item : ""}
   * @returns {ReactNode}
   */
  const handleAddSpecsButton = (productId, product) => {
    if (productId !== undefined && productId !== "") {
      setShowSpecManager(true);
      setShowSampleResultsModal(false);
      setSpecManagerProduct(product);
    } else {
      toast.error("Failed to load spec data");
    }
  };

  /**
   * Close spec manager and open sample results modal.
   */
  const handleCloseSpecManager = () => {
    handleShowSampleResultsModal(showModalRowIdx);
    setShowSpecManager(false);
    setSpecManagerProduct(null);
  };

  if (!fields || fields.length === 0 || !linkReportJsonFields || linkReportJsonFields.length === 0) {
    return null;
  }

  const showNoResults = !loading && (!reports || reports.length === 0);

  return (
    <div
      className={`ReportsTableWrapper ${wrapperClassName}`}
    >
      {showSpecManager && (
      <SpecManager
        showSpecManager
        isEditingSpec
        fromModal
        productId={reports[showModalRowIdx]?.index_id}
        handleClosingEditSpec={handleCloseSpecManager}
        toggleSpecsUpdated={setSpecsUpdated}
        handleDataRefreshAfterExitingSpecManager={handleDataRefreshAfterExitingSpecManager}
        productIdentifierArr={multipleLinkReportFieldsObjToObj(specManagerProduct, fields, linkReportJsonFields)}
        fields={fields}
        linkReportJsonFields={linkReportJsonFields}
      />
      )}

      {/* {showShareModal && (
      <ShareModal
        toggleShareModal={toggleShareModal}
        data={reports[shareModalIndex]}
      />
      )} */}

      {showSampleResultsModal && (
      <SampleResultsModal
        showModalRowIdx={showModalRowIdx}
        reportData={showModalRowIdx !== null ? reports[showModalRowIdx] : null}
        handleExitSampleResultsModal={handleExitSampleResultsModal}
        handleAddSpecsButton={handleAddSpecsButton}
        disableInteraction={disableSampleResultsModalInteraction}
        linkReportJsonFields={linkReportJsonFields}
        fields={fields}
      />
      )}
      <Scrollbar
        ref={scrollbarRef}
        id={scrollbarId}
        disableTracksWidthCompensation
        className={`ReportsTableScrollbar ${showNoResults ? "ReportsTableScrollbar--no-results" : ""}`}
        onScrollStop={handleScrollToBottom}
      >
        <table
          className={`ReportsTable ${showNoResults ? "ReportsTable--no-results" : ""}`}
          style={{ width: table.getTotalSize() }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <ReportsTableHeader
                key={headerGroup.id}
                headerId={headerGroup.id}
                headers={headerGroup.headers}
                fields={fields}
                setFields={setFields}
                linkReportJsonFields={linkReportJsonFields}
                disableInteraction={disableTableHeaderInteraction}
              />
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <ReportsTableRow
                key={row.id}
                row={row}
                enableRowSelection={enableRowSelection}
                handleRowSelection={handleRowSelection}
              />
            ))}
          </tbody>
        </table>
        {showNoResults && (
          <NoResults image={emptyStateIcon} message="No Result Found" className="ReportsTable__NoResults" />
        )}
        {loadingNewContent && <Loading />}
      </Scrollbar>
    </div>
  );
}

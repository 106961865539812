import React from "react";

import { Scrollbar } from "react-scrollbars-custom";

import NoResultsGif from "../../../../Common/NoResultsGIF";
import EnvAnalyticsTableRow from "./EnvAnalyticsTableRow";

import pumpkinGif from "../../../../../assets/images/environment/pumpkin.gif";
import "./EnvAnalyticsTable.css";

const EnvAnalyticsTable = ({ headers, data, tableType }) => (
  <div className="EnvAnalyticsTable__SubmittedSubmissionsContainer">
    <div className="EnvAnalyticsTable__GraphContainerPlaceholderText">
      {tableType === "SubmissionTracker"
        ? "Swab Submission Tracker: Last Submitted Samples"
        : "Alert! Some Areas are not doing well"}
    </div>
    <div className="EnvAnalyticsTable__SubmissionsTableContainer">
      <Scrollbar
        className="EnvAnalyticsTable__ScrollBar"
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
      >
        {data && data.length > 0 ? (
          <table className="EnvAnalyticsTable__SubmissionsTable">
            <thead className="EnvAnalyticsTable__SubmissionsTableHeader">
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <EnvAnalyticsTableRow
                  key={index}
                  row={row}
                  headers={headers}
                  tableType={tableType}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <NoResultsGif message="No Data" image={pumpkinGif} className="EnvAnalyticsTable__NoResults" />
        )}
      </Scrollbar>
    </div>
  </div>
);

export default EnvAnalyticsTable;

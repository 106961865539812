import React from "react";
// import userProfileIcon from "../../../../../../assets/images/product/template/userProfileLightBlue.png";

export default function BuilderElement(
  {
    id,
    type,
    value,
    flag,
    // jsonField,
    disable,
    isClone = false,
    provided,
    snapshot,
  },
) {
  let inputClassName = "";
  if (type === "smallTextBox" || type === "largeTextBox") {
    inputClassName = `templateBuilderTemplateInput ${type}`;
  } else if (flag === "2") {
    inputClassName = "templateBuilderTemplateDataField templateBuilderCustomDataField";
  } else {
    inputClassName = "templateBuilderTemplateDataField";
  }

  const getStyle = (isDragging, disableDropAnimation) => {
    const styles = {
      opacity: disable ? "0.5" : "1",
      cursor: disable ? "initial" : "grab",
      // maxWidth: isDragging ? "241.64px" : "unset",
    };

    if (!isDragging) {
      styles.transform = "unset";
    }

    if (disableDropAnimation) {
      styles.transitionDuration = "0.001s";
    }

    return styles;
  };

  if (isClone) {
    return (
      <div
        // key="type"
        className={`${inputClassName} templateBuilderIsDragging`}
        style={getStyle(false)}
      >
        {value}
        {/* {flag === "2" && <img src={userProfileIcon} alt="userProfileIcon" className="customFieldImg" />} */}
      </div>
    );
  }

  return (
    <div
      // key="type"
      id={id}
      className={inputClassName}
      ref={provided.innerRef}
      {...provided.draggableProps} // eslint-disable-line
      {...provided.dragHandleProps} // eslint-disable-line
      style={{
        ...provided.draggableProps.style,
        ...getStyle(snapshot.isDragging, snapshot.isDropAnimating && snapshot.draggingOver === null),
      }}
    >
      {value}
      {/* {flag === "2" && <img src={userProfileIcon} alt="userProfileIcon" className="customFieldImg" />} */}
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";

import { toast } from "react-toastify";

import { AppContext } from "../../AppContext";
import Loading from "../Common/Loading";
import NoResultsGIF from "../Common/NoResultsGIF";

import { getUserCredentials } from "../../utils/common";

import { switchCompany, getCompanyList } from "../../actions/switchCompany";

import pumpkinGIF from "../../assets/images/environment/pumpkin.gif";

import "./companylist.css";

export default function CompanyList({ closeNavbar, navDrawer }) {
  const [companyList, setCompanyList] = useState([]);
  const { company_name } = getUserCredentials();
  const [selectedCompany, setSelectedCompany] = useState(company_name);
  const [isSwitching, setIsSwitching] = useState(false);
  const [showError, setShowError] = useState(false);
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  // Get company list on mount
  useEffect(() => {
    const apiFetchCompanies = async () => {
      setLoading(true);
      const {
        success,
        company_list,
      } = await getCompanyList();
      if (success) {
        setCompanyList(company_list);
      } else {
        setShowError(true);
      }
      setLoading(false);
    };
    if (navDrawer) apiFetchCompanies();
  }, [navDrawer]);

  /**
   * Switch to new company
   * @param {String} companyName company name
   * @param {String} userTitle user title "Admin" or "Member"
   */
  const handleSwitchCompany = async (companyName, userTitle) => {
    setIsSwitching(true);
    const prevCompany = selectedCompany;
    setSelectedCompany(companyName);
    const success = await switchCompany(companyName, userTitle, appContext);
    if (success) {
      /** Reload the window */
      window.location.reload(false);
      closeNavbar();
    } else {
      setSelectedCompany(prevCompany);
      toast.error("Unable to switch accounts");
    }
    setIsSwitching(false);
  };

  return (
    <div className="CompanyList">
      {loading && (<Loading />)}
      {companyList.length ? companyList.map(({ company_name: name, user_title }) => (
        <span
          className={`CompanyList__Button ${selectedCompany === name ? "CompanyList--active" : ""}`}
          key={name}
          onClick={selectedCompany === name || isSwitching ? null : () => handleSwitchCompany(name, user_title)}
        >
          {name}
        </span>
      )) : showError && (<NoResultsGIF image={pumpkinGIF} message="No data" className="pumpkinGIF" />) }
    </div>
  );
}

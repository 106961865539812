import React, {
  useEffect, useCallback, useState,
} from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import Scrollbar from "react-scrollbars-custom";
import DragDrop from "./DragDrop";
import "./UploadSwabFiles.css";
import LoadingConfirmBtn from "./LoadingConfirmBtn";
import { addSwab } from "../../../../../actions/envAnalytics";
import SwabFileItem from "./SwabFileItem";
import SwabFileExample from "./SwabFileExample";

export default function UploadSwabFiles(props) {
  const {
    handleClickCancel,
    handleDeleteIconClick,
    currentFile,
    proceedToUploadMap,
    returnFileSize,
    ifOnlyUploadSwabFile,
    toggleShowCreateEnvModal,
    updatePinsList,
    emptyFilesOrMap,
    openFromEmptyPage,
    openCreateEnvModalFromTab,
    clearFilterCalendarAndSelected,
    linkPatternFields,
  } = props;

  const [selectedFilesMap, setSelectedFilesMap] = useState(new Map()); // all the selected files, including the files uploaded to backend successfully, and unsuccessfully, contains file id and its info
  const [fileNameToFreq, setFileNameToFreq] = useState({});// stores file name and its frequency of selected files, including the files uploaded to backend successfully, and unsuccessfully, used to judge if a file is duplicate
  const [filesData, setFilesData] = useState({}); // stores all the parsed data from uploaded files, if it is a valid excel file. {fileID: {id, name, data}}
  const [loading, setLoading] = useState(false);
  const FILE_SIZE_MAX_LIMIT = 50 * 1048576; // convert 50MB to bytes
  const [nextfileId, setNextFileId] = useState(0); // to give each selected file a unique ID
  const [dragging, setDragging] = useState(false); // Used to change style when dragging files
  const [showSubmit, setShowSubmit] = useState(!openCreateEnvModalFromTab); // used to know either show "submit" or "next" button
  const [ifAnySwabUploaded, setIfAnySwabUploaded] = useState(false); // If any swab has been uploaded to backend successfully, we allow user to click "Next" button.

  /**
   * Return error text if the selected files do not meet requirement. Return null if meet requirements.
   * @param {File} file A selected file
   * @returns {(Object | Null)} Return error text object if there is error, otherwise return null.
   */
  const checkFrontEndFileError = (file, fileNameToFreqObj) => {
    let errorMessages = null;
    if (file.size > FILE_SIZE_MAX_LIMIT) {
      errorMessages = { title: "File size is too big", body: "Max 50 MB", type: "size" };
    } else if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && file.type !== "application/vnd.ms-excel") {
      errorMessages = { title: "File format is not supported", body: "*.xlsx and *.xls only", type: "format" };
    } else if (fileNameToFreqObj[file.path] !== undefined && fileNameToFreqObj[file.path] > 1) {
      errorMessages = { title: "Upload error", body: "This file is duplicate" };
    }

    return errorMessages;
  };

  /**
   * Called when files are selected or dropped. Parse the excel files, store metadata and content data in states.
   */
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setDragging(false);
    setShowSubmit(true);

    const selected = []; // store all selected files
    selected.push(...acceptedFiles); // acceptedFiles are the files accepted by the drop-zone library based on parameters we give: accept(format) and maxSize.
    fileRejections.forEach(({ file }) => { // fileRejections are the files rejected. fileRejections: [{errors, file}]
      selected.push(file);
    });

    const fileNameToFreqObj = { ...fileNameToFreq };
    selected.forEach((file) => {
      if (fileNameToFreqObj[file.path] !== undefined) {
        fileNameToFreqObj[file.path]++;
      } else {
        fileNameToFreqObj[file.path] = 1;
      }
    });
    setFileNameToFreq(fileNameToFreqObj);

    // Update error messages of previously added files
    selectedFilesMap.forEach((fileData, fileId) => {
      // Case 1: if a file was uploaded to backend, don't need to check error again.
      // Case 2: if a file has a valid errorMesages, and was failed to upload to backend, i.e. with "backend" type,
      // it already passed size, and format check, we will only show its previous backend error, and don't check front end error again.
      // Otherwise, should check error.
      if ((fileData.errorMessages === null && fileData.apiStatus !== "success")
         || (fileData.errorMessages !== null && fileData.errorMessages.type !== "backend")) {
        const errorMessages = checkFrontEndFileError(fileData, fileNameToFreqObj);
        selectedFilesMap.get(fileId).errorMessages = errorMessages;
      }
    });

    let id = nextfileId;
    const fileIdToData = { ...filesData };
    for (let i = 0; i < selected.length; i++) {
      const file = selected[i];
      const errorMessages = checkFrontEndFileError(file, fileNameToFreqObj);

      const fileMetaData = {
        id, name: file.name, path: file.path, type: file.type, size: file.size, errorMessages,
      };

      selectedFilesMap.set(id, fileMetaData);
      id++;

      // Only need to record the content of the files that have valid size and format in state
      if (errorMessages === null || (errorMessages.type !== "size" && errorMessages.type !== "format")) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const result = event.target.result;
          try {
            const workbook = XLSX.read(result, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
            fileIdToData[fileMetaData.id] = { id: fileMetaData.id, name: file.path, data };
          } catch (err) {
            toast.error(
              "Fail to upload. Please make sure the file is a spreadsheet",
            );
          }
        };

        reader.readAsArrayBuffer(file);
      }
    }
    setNextFileId(id);
    setSelectedFilesMap(new Map(selectedFilesMap));
    setFilesData(fileIdToData);
    emptyFilesOrMap(selected.length === 0);
  }, [nextfileId, selectedFilesMap, fileNameToFreq, filesData]);// eslint-disable-line

  /** Called when dragged file enters the DragDrop area, to change UI style of the area */
  const onDragEnter = useCallback(() => {
    setDragging(true);
  }, []);

  /** Called when dragged file leaves the DragDrop area, to change UI style of the area */
  const onDragLeave = useCallback(() => {
    setDragging(false);
  }, []);

  /** Use the drop zone library, passing acceptable file formats, and max size */
  const {
    getRootProps, getInputProps,
  } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.ms-excel": [".xls"],
    },
    maxSize: FILE_SIZE_MAX_LIMIT,
  });

  /**
   * When the delete icon is clicked, toggle the delete confirmation modal
   * @param {*} modalType the tyep of confirmation modal, either "main-modal" or "file-modal"
   * @param {*} showConfirmation boolean value to set the confirmation to be displayed
   * @param {*} file the current selected file
   */
  const toggleDeleteConfirmation = (modalType, showConfirmation, file) => {
    handleDeleteIconClick(modalType, showConfirmation, file);
  };

  /** Called when click to submit files to call backend API to save the newly added valid files */
  const handleClickSubmit = async () => {
    setLoading(true);
    // Only upload the files that were not uploaded to backend in previous rounds.
    const filesDataToUpload = Object.values(filesData).filter((fileObj) => {
      const fileId = fileObj.id;
      const status = selectedFilesMap.get(fileId).status;
      return status === undefined;
    });

    let ifAnySwabUploadedUpdate = false;

    Promise.all(
      filesDataToUpload.map(async (fileObj) => {
        const fileId = fileObj.id;
        const fileName = fileObj.name;
        const fileData = fileObj.data;
        /** Convert keys that are title fields into their corresponding json field */
        const pointsList = fileData.map((swab) => {
          const formattedSwab = { ...swab };
          linkPatternFields.forEach(({ json_field, title_field }) => {
            const val = formattedSwab[title_field];
            if (val !== undefined) {
              delete formattedSwab[title_field];
              formattedSwab[json_field] = val;
            }
          });
          return formattedSwab;
        });
        const resp = await addSwab({ pointsList });
        if (resp.message === "Success") {
          const successSwabs = resp.successSwabs;
          const failedSwabs = resp.failedSwabs;

          if (successSwabs.length === pointsList.length) {
            // if the whole file is uploaded successfully, we will remove delete feature for this file.
            selectedFilesMap.get(fileId).apiStatus = "success";
            ifAnySwabUploadedUpdate = true;
          } else if (failedSwabs.length === pointsList.length) {
            // if whole file failed to upload, we will show swab errors later
            selectedFilesMap.get(fileId).apiStatus = "failed";
            selectedFilesMap.get(fileId).errorMessages = {
              title: "Upload error", body: "All swabs failed to upload", failedSwabs, numSwabs: pointsList.length, type: "backend",
            };
          } else {
            // if part of the file is failed to upload, we will show swab warnings later, remove delete feature for this file.
            // Do not show duplicate error for the edited file if users edit the partially failed file, and reupload the edited version.
            selectedFilesMap.get(fileId).apiStatus = "partial-success";
            ifAnySwabUploadedUpdate = true;
            selectedFilesMap.get(fileId).errorMessages = {
              title: "Upload error", body: "Some swabs failed to upload", successSwabs, failedSwabs, numSwabs: pointsList.length, type: "backend",
            };
            const frequency = fileNameToFreq[fileName];
            if (frequency === 1) {
              delete fileNameToFreq[fileName];
            } else {
              fileNameToFreq[fileName]--;
            }
          }

          // already uploaded to backend, delete it from filesJson,
          // so the file won't be uploaded to backend again in next round if user adds more files to select.
          delete filesData[fileId];
        } else {
          selectedFilesMap.get(fileId).errorMessages = {
            title: "Upload error", body: "Failed to upload the file", type: "backend", apiStatus: "failed",
          };
        }
        setFilesData({ ...filesData });
        if (!ifAnySwabUploaded && ifAnySwabUploadedUpdate) setIfAnySwabUploaded(ifAnySwabUploadedUpdate);
        return Promise.resolve("envpoints/ called");
      }),
    ).then(() => {
      setSelectedFilesMap(new Map(selectedFilesMap)); // update with backend error messages
      setLoading(false);
      updatePinsList();
      clearFilterCalendarAndSelected(); // will clear filter, calendar dates, selected swab if there were any
      if (ifAnySwabUploaded || ifAnySwabUploadedUpdate) {
        setShowSubmit(false);
      }
    });
  };

  /** Called when delete a selected file */
  const handleClickDelete = (fileToDelete) => {
    // Update file name and its frequency
    if (fileNameToFreq[fileToDelete.path] === 1) {
      delete fileNameToFreq[fileToDelete.path];
    } else {
      fileNameToFreq[fileToDelete.path]--;
    }
    setFileNameToFreq({ ...fileNameToFreq });

    // Update shown selected files
    selectedFilesMap.delete(fileToDelete.id);

    let showSubmitButton = false;
    selectedFilesMap.forEach((fileData, fileId) => {
      // If there is new file selected, should show submit button
      if (fileData.apiStatus === undefined) {
        showSubmitButton = true;
      }
      // Update front end error messages of other added files:
      // Case 1: if a file was uploaded to backend, don't need to check error again.
      // Case 2: if a file has a valid errorMesages, and was failed to upload to backend, i.e. with "backend" type,
      // it already passed size, and format check, we will only show its previous backend error, and don't check front end error again.
      // Otherwise, should check error.
      if ((fileData.errorMessages === null && fileData.apiStatus !== "success")
         || (fileData.errorMessages !== null && fileData.errorMessages.type !== "backend")) {
        const errorMessages = checkFrontEndFileError(fileData, fileNameToFreq);
        selectedFilesMap.get(fileId).errorMessages = errorMessages;
      }
    });
    setSelectedFilesMap(new Map(selectedFilesMap));

    if (selectedFilesMap.size === 0 && (openFromEmptyPage || ifOnlyUploadSwabFile)) {
      showSubmitButton = true; // should not show Next, or Done, becasue uploading swab files is a must.
    }
    setShowSubmit(showSubmitButton);

    // Remove the read file content data from state record
    if (fileToDelete.errorMessages === null
      || (fileToDelete.errorMessages.type !== "size" && fileToDelete.errorMessages.type !== "format")) {
      delete filesData[fileToDelete.id];
      setFilesData({ ...filesData });
    }

    // When no file is in the shown files list, users can cancel the modal without confirmation popup.
    emptyFilesOrMap(selectedFilesMap.size === 0);
  };

  /**
   * Keep tracks of the delete file action from the CreateEnvModal component.
   * When users confirmation the delete action from the CreateEnvModal component,
   * the currentFile variable will be changed, then it will call then handleClickDelete() to delete the file
   */
  useEffect(() => {
    if (currentFile) {
      handleClickDelete(currentFile);
    } // eslint-disable-next-line
  }, [currentFile]);

  /** Disable the next button if meet these conditions
   * @returns {(boolean)}
   */
  const ifDisableSubmit = () => {
    // if no file is selected yet, disable submit
    if (selectedFilesMap.size === 0) return true;

    let ifDisabledNext = false;
    selectedFilesMap.forEach((fileData) => {
      // if any file has frontend error, disable submit button
      if (fileData.errorMessages !== null && fileData.errorMessages.type !== "backend") {
        ifDisabledNext = true;
      }
      // if any being selected file was fully failed to upload to backend
      if (fileData.apiStatus && fileData.apiStatus === "failed") {
        ifDisabledNext = true;
      }
    });

    return ifDisabledNext;
  };

  /**
   * Handle the Next button onclick.
   * 1. If it is from Upload Swab File, then back to the Analytics view.
   * 2. If it is from the + icon or from the landing empty page, then proceed to the step of upload map.
   */
  const handleClickNext = () => {
    if (ifOnlyUploadSwabFile) {
      toggleShowCreateEnvModal();
    } else {
      proceedToUploadMap();
    }
  };

  /**
   * A function to handle the Skip button onclick: proceed to the upload map step.
   */
  const handleClickSkip = () => {
    proceedToUploadMap();
  };

  /** To get a list of selected files to display */
  const getFileItems = () => [...selectedFilesMap.values()].map((fileData) => (
    <SwabFileItem
      key={fileData.id}
      fileData={fileData}
      toggleDeleteConfirmation={toggleDeleteConfirmation}
      returnFileSize={returnFileSize}
      linkPatternFields={linkPatternFields}
    />
  ));

  return (
    <>
      <div className={`create-env-modal-body ${ifOnlyUploadSwabFile ? "create-env-modal-body-only-swab" : ""}`}>
        <section className="env-upload-files">
          <div className={`env-drag-drop-files ${dragging ? "env-upload-files-dragging" : ""}`}>
            <DragDrop
              uploadTarget="your files"
              getInputProps={getInputProps}
              getRootProps={getRootProps}
            />
          </div>
          <aside className="env-uploaded-files">
            {selectedFilesMap.size === 0
              ? (
                <div className="env-uploaded-files-no-file">
                  <span>No Files Uploaded</span>
                  {linkPatternFields.length > 0
                  && (
                  <SwabFileExample
                    linkPatternFields={linkPatternFields}
                  />
                  )}
                </div>
              )
              : (
                <>
                  <h3>Uploaded Files</h3>
                  <Scrollbar className="env-uploaded-files-scroll-bar">
                    <aside className="env-uploaded-files-innter">
                      <>
                        <ul>{getFileItems()}</ul>
                      </>
                    </aside>
                  </Scrollbar>
                </>
              )}
          </aside>
        </section>
      </div>

      <div className={`UploadSwabFiles__BtnsContainer
       ${(openCreateEnvModalFromTab && showSubmit) ? "create-env-modal-buttons-optional" : ""}`}
      >
        {openCreateEnvModalFromTab && showSubmit
          && <button className="UploadSwabFiles__SkipBtn" type="button" onClick={handleClickSkip}>Skip</button>}
        <div className="UploadSwabFiles__btns">
          <button className="UploadSwabFiles__CancelBtn" type="button" onClick={handleClickCancel}>Cancel</button>
          {showSubmit && (loading
            ? (
              <LoadingConfirmBtn />
            )
            : (
              <button
                className="UploadSwabFiles__SubmitBtn"
                type="button"
                disabled={ifDisableSubmit()}
                onClick={handleClickSubmit}
              >
                Submit
              </button>
            ))}
          {!showSubmit && (
          <button type="button" disabled="" onClick={handleClickNext}>
            {ifOnlyUploadSwabFile ? "Done" : "Next"}
          </button>
          )}
        </div>
      </div>
    </>
  );
}

import React from "react";
import { ReactComponent as Arrow } from "../../../../../../assets/images/environment/arrowIcon.svg";

export default function ResultTableActions({
  currentSlide,
  totalSlides,
  handleSlideChange,
}) {
  return (
    <div className="env-report-view-report-section-results-table-actions-container">
      <div className="env-report-view-report-section-results-table-actions-buttons">
        <div
          className="env-report-view-report-section-results-table-actions-buttons-left"
          onClick={() => handleSlideChange(-1)}
        >
          <Arrow width={12} height={12} />
        </div>
        <div>{`${currentSlide + 1}/${totalSlides}`}</div>
        <div
          className="env-report-view-report-section-results-table-actions-buttons-right"
          onClick={() => handleSlideChange(1)}
        >
          <Arrow width={12} height={12} transform="rotate(180)" />
        </div>
      </div>
    </div>
  );
}

import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import DateCard from "./DateCard";
import { ReactComponent as Arrow } from "../../../../../../../assets/images/environment/whiteArrow.svg";
import "./DatesSlider.css";

export default function DatesSlider({
  reportList,
  defaultIndex,
  activeReportIdx,
  setActiveReportIdx,
}) {
  const swiperRef = useRef();
  const [disablePrevBtn, setDisablePrevBtn] = useState(true);
  const [disableNextBtn, setDisableNextBtn] = useState(true);

  /**
   * Detects if we are at the beginning or end of swiper,
   * and disables next and prev buttons accordingly.
   * @param {Swiper} swiper instance of Swiper class
   */
  function handleButtonDisable(swiper) {
    if (swiper) {
      if (reportList.length < 3) {
        setDisablePrevBtn(true);
        setDisableNextBtn(true);
      } else {
        if (swiper.isBeginning) {
          setDisablePrevBtn(true);
        } else {
          setDisablePrevBtn(false);
        }
        if (swiper.isEnd) {
          setDisableNextBtn(true);
        } else {
          setDisableNextBtn(false);
        }
      }
    }
  }

  const handleClickNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.allowSlideNext = true;
      swiperRef.current.swiper.slideNext();
    }
  };
  const handleClickPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.allowSlidePrev = true;
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <>
      <div
        className={disablePrevBtn ? "date-swiper-button-prev date-swiper-button-disabled" : "date-swiper-button-prev"}
        onClick={handleClickPrev}
      >
        <Arrow width={10} height={10} transform="rotate(-180)" />
      </div>
      <Swiper
        ref={swiperRef}
        slidesPerView="auto"
        modules={[Navigation]}
        navigation
        spaceBetween={8}
        initialSlide={defaultIndex}
        centeredSlides
        centeredSlidesBounds
        slideToClickedSlide
        slidesOffsetAfter={4}
        slidesOffsetBefore={4}
        onInit={(swiper) => handleButtonDisable(swiper)}
        onReachBeginning={(swiper) => handleButtonDisable(swiper)}
        onReachEnd={(swiper) => handleButtonDisable(swiper)}
        onToEdge={(swiper) => handleButtonDisable(swiper)}
        onClick={(swiper) => handleButtonDisable(swiper)}
        onSliderMove={(swiper) => handleButtonDisable(swiper)}
        onResize={(swiper) => handleButtonDisable(swiper)}
        onNavigationHide={(swiper) => handleButtonDisable(swiper)}
        className="env-report-view-date-slider"
      >
        {reportList.map((report, index) => (
          <SwiperSlide
            className="env-report-view-date-slide"
            key={`${report.sample_id}${index}`}
          >
            <DateCard
              report={report}
              index={index}
              activeReportIdx={activeReportIdx}
              setActiveReportIdx={setActiveReportIdx}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className={disableNextBtn ? "date-swiper-button-next date-swiper-button-disabled" : "date-swiper-button-next"}
        onClick={handleClickNext}
      >
        <Arrow width={10} height={10} />
      </div>
    </>
  );
}

import {
  isArray, mergeWith, union, zipObject,
} from "lodash";
import moment from "moment";

export const SAMPLE_SUBMISSION_MAXLENGTH = 200;
const DaysOfWeekOrder = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export const ExtraFieldValueType = {
  VALUES: "VALUES",
  OPTIONS: "OPTIONS",
  TEST_LIST: "TEST_LIST",
};

/**
 * sort dates
 * @param {Date[]} dateArr Date array, format "MM/DD/YYYY"
 * @param {Boolean} asc Ascending = true/Decending = false
 * @returns Sorted date array
 */
export const sortDates = (dateArr, asc = true) => {
  dateArr.sort((a, b) => {
    const i = moment(a, "MM/DD/YYYY").isAfter(moment(b, "MM/DD/YYYY"));
    if (!asc) {
      return i ? -1 : 1;
    }
    return i ? 1 : -1;
  });
  return dateArr;
};

/**
 * Get start date, end date and all week days starting from Sunday
 * @param {Date} date
 * @returns {Object} {startDate: ..., endDate: ..., datesInWeek: ...}
 */
export const getWeekDays = (date) => {
  const startOfWeek = moment(date, "MM/DD/YYYY").clone().startOf("week");
  const datesInWeek = [];
  for (let i = 0; i < 7; i++) {
    datesInWeek.push(startOfWeek.clone().add(i, "days").format("MM/DD/YYYY"));
  }
  return {
    datesInWeek,
    startDate: datesInWeek[0],
    endDate: datesInWeek[datesInWeek.length - 1],
  };
};

/**
 * Get recurring event date as human readable text
 * @param {Date[]} dates Array of dates
 * @param {String} frequency "weekly" | "monthly" | "one-time" | "custom"
 * @returns Human readable string of recurring event
 */
export const getRecurringEventReadableText = (dates, frequency) => {
  const result = {
    wordsToMark: [],
    text: "",
  };
  if (dates.length === 0) return result;

  switch (frequency) {
    case "weekly": {
      const endDate = dates.at(-1);
      const endDateStr = moment(endDate).format("MMM YYYY");
      const days = dates.map((d) => moment(d).format("dddd"));
      const weekdays = Array.from(new Set(days)).sort((a, b) => DaysOfWeekOrder[a] - DaysOfWeekOrder[b]);
      let weekdaystr = "";
      if (weekdays.length > 1) {
        weekdaystr = `${weekdays.slice(0, weekdays.length - 1).join(", ")} and ${weekdays.at(-1)}`;
      } else {
        weekdaystr = weekdays[0];
      }
      return {
        wordsToMark: days,
        text: `Every week on ${weekdaystr} until ${endDateStr}`,
      };
    }
    case "monthly": {
      const endDate = dates.at(-1);
      const endDateStr = moment(endDate).format("MMM YYYY");
      const ds = dates.map((d) => moment(d).format("Do"));
      const days = Array.from(new Set(ds)).sort((a, b) => {
        // Extract the numeric part of each ordinal by parsing as an integer
        const numA = parseInt(a, 10);
        const numB = parseInt(b, 10);
        // Sort based on the numeric value
        return numA - numB;
      });
      let daystr = "";
      if (days.length > 1) {
        daystr = `${days.slice(0, days.length - 1).join(", ")} and ${days.at(-1)}`;
      } else {
        daystr = days[0];
      }
      return {
        wordsToMark: days,
        text: `Every month on ${daystr} until ${endDateStr}`,
      };
    }
    case "one-time": {
      const date = dates[0];
      const d = moment(date).format("Do");
      const mY = moment(date).format("MMM YYYY");
      return {
        wordsToMark: [d],
        text: `${d} of ${mY}`,
      };
    }
    case "custom": {
      const endDate = dates.at(-1);
      const endDateStr = moment(endDate).format("MMM YYYY");
      const datelist = dates.map((d) => moment(d).format("MMM Do"));
      let datestr = "";
      if (datelist.length > 1) {
        datestr = `${datelist.slice(0, datelist.length - 1).join(", ")} and ${datelist.at(-1)}`;
      } else {
        datestr = datelist[0];
      }
      return {
        wordsToMark: datelist,
        text: `${datestr} until ${endDateStr}`,
      };
    }
    default: return result;
  }
};

/**
 * Create object with empty string values for all keys and update object
 * @param {String[]} keys Array of keys
 * @param {Object} updatedObject Updated object
 */
export const populateObjectValues = (keys, updatedObject = {}, valueToFill = "") => {
  const obj = zipObject(keys, Array(keys.length).fill(valueToFill));
  return { ...obj, ...updatedObject };
};

/**
 * Merge and updates objects having array
 * @param {*} target object
 * @param {*} source object
 */
export function mergeObjectArrays(target, source) {
  return mergeWith(target, source, (objValue, srcValue) => {
    if (isArray(objValue)) {
      return union(objValue, srcValue);
    }
    return undefined;
  });
}

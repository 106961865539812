import React from "react";
import Modal from "antd/es/modal/Modal";
import "./DeleteIndexConfirmation.css";

const DeleteIndexConfirmation = ({ onDeleteIndex, onToggleModal, indexName }) => (
  <Modal
    open
    closable={false}
    centered
    footer={null}
    onConfirm={onDeleteIndex}
    onCancel={onToggleModal}
    width="448px"
    wrapClassName="DeleteIndexConfirmation"
    className="DeleteIndexConfirmation"
  >
    <div className="docConfirmDel">
      <div className="docConfirmDelTitle DeleteIndexConfirmation--text-left">
        Delete Index &quot;
        {indexName}
        &quot;
        {" "}
      </div>
      <div className="docConfirmDelTitleMessage DeleteIndexConfirmation--text-left">All the cards stored here will also be deleted</div>
      <div className="docConfirmDelBtn">
        <button type="button" onClick={onToggleModal} className="docConfirmDelBtnCancel">Cancel</button>
        <button type="button" onClick={onDeleteIndex} className="docConfirmDelBtnDelete">Delete</button>
      </div>
    </div>
  </Modal>
);

export default DeleteIndexConfirmation;

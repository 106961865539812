import React from "react";
import Scrollbar from "react-scrollbars-custom";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import { ReactComponent as CloseIcon } from "../../../../../assets/images/sample-submission/blueCloseIcon.svg";
import "./TestList.css";

export default function TestList(props) {
  const {
    removableTests, nonRemovableTests, loading, handleRemoveAddedTest,
  } = props;

  return (
    <Scrollbar id="TestList__ScrollBar" className="TestList__SelectedListScrollbar">
      <ul className="TestList__SelectedList">
        {removableTests.map((test) => (
          <StyledTooltip
            placement="top"
            key={test}
          >
            <li className="TestList__SelectedListItem">
              {test}
              <div
                className="TestList__SelectedItemCloseIcon"
                onClick={loading ? null : () => handleRemoveAddedTest(test)}
              >
                <CloseIcon width={8} height={8} />
              </div>
            </li>
          </StyledTooltip>
        ))}
        {nonRemovableTests.map((test) => (
          <StyledTooltip
            placement="top"
            key={test}
          >
            <li className="TestList__SelectedListItem TestList__SelectedListItem--disabled">
              {test}
            </li>
          </StyledTooltip>
        ))}
      </ul>
    </Scrollbar>
  );
}

import React from "react";
import { ReactComponent as RotateIcon } from "../../../assets/images/sample-submission/rotateIcon.svg";
import SliderWithButtons from "./SliderWithButtons";
import "./ImageEditToggles.css";

export default function ImageEditToggles(props) {
  const {
    scale,
    setScale,
    handleRotateImage,
    disable,
  } = props;
  return (
    <div className={`SampleSubmission__EditImageBtns${disable ? "--disabled" : ""}`}>
      <RotateIcon
        onClick={handleRotateImage}
        className="SampleSubmission__RotateButton"
      />
      <SliderWithButtons scale={scale} setScale={setScale} />
    </div>
  );
}

import React from "react";
import eyeClosedIcon from "../../../assets/images/ph_eye-closed-fill@2x.png";
import eyeOpenedIcon from "../../../assets/images/ph_eye-opened-fill@2x.png";
import SignUpInput from "./SignUpInput";
import "./SignUpPasswordInput.css";

export default function SignUpPasswordInput({
  type,
  valueInParent,
  name,
  label,
  placeholder,
  disabled,
  showHide,
  handleOnBlur,
  error,
  setError,
  updateRequiredInputsFilledOut,
  setInputValue,
}) {
  return (
    <div className="signup-single-page-password">
      <SignUpInput
        valueInParent={valueInParent}
        type={type}
        name={name}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        setError={setError}
        required
        autoComplete="new-password"
        inputClassName="signup-password-input"
        handleOnBlur={handleOnBlur}
        updateRequiredInputsFilledOut={updateRequiredInputsFilledOut}
        setInputValue={setInputValue}
      >
        <img
          alt={type === "text" ? "hide password" : "show password"}
          src={type === "text" ? eyeOpenedIcon : eyeClosedIcon}
          onClick={showHide}
          className="signup-single-page-eye-icon"
        />
      </SignUpInput>
      {name === "password" && <div className="signup-password-require">Must contain: at least 1 number, 1 uppercase, 1 lowercase letter, at least 6 characters, no spaces.</div>}
    </div>
  );
}

import React from "react";
import Scrollbar from "react-scrollbars-custom";
import TestViewToggle from "./Components/TestViewToggle";
import "./BuilderTests.css";

export default function BuilderTests(props) {
  const {
    previewTemplate,
    enableTests,
    handleTestToggle,
    uniqueTests,
    setUniqueTests,
  } = props;

  /**
 * Handles the selection of a test item in the uniqueTests array.
 * If the selected test is not already selected or the total selected tests count is less than 5,
 * toggles the selected state of the test item and updates the uniqueTests array.
 * @param {string} test - The test to be selected or deselected.
 * @param {number} index - The index of the test item in the uniqueTests array.
 * @param {boolean} selected - The current selected state of the test item.
 */
  const handleSelectTest = (test, index, selected) => {
    const testList = JSON.parse(JSON.stringify(uniqueTests));
    const selectedTestsCount = testList.filter((item) => item.selected).length;
    const SelectedTests = testList.filter((item) => item.selected).map((item) => item.test);
    if (SelectedTests.includes(test) || selectedTestsCount < 5) {
      testList[index] = { test, selected: !selected };
      setUniqueTests(testList);
    }
  };

  const numOfSelectedTests = uniqueTests.filter((item) => item.selected).length;
  return (
    <div className={previewTemplate ? "templateBuilderTests templateBuilderDisableSection" : "templateBuilderTests"}>
      <div className="templateBuilderTestsHeader">
        Tests
        <TestViewToggle handleTestToggle={handleTestToggle} enableTests={enableTests} />
      </div>
      <div className="templateBuilderTestsSubHeader">{`Choose a maximum of 5 tests (${numOfSelectedTests})`}</div>
      <div className={enableTests ? "templateBuilderTestsScrollbar" : "templateBuilderTestsScrollbar templateBuilderTestsDisable"}>
        <Scrollbar>
          <div className="templateBuilderTestsItems">
            {uniqueTests.map(({ test, selected }, i) => <span onClick={() => handleSelectTest(test, i, selected)} className={selected ? "templateBuilderTestsItem templateBuilderTestsItem--selected" : "templateBuilderTestsItem"} key={i}>{test}</span>)}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}

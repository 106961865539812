import React, { useState } from "react";
import { Drawer } from "antd";
import "./SpecManagerDrawer.css";
import closeIcon from "../../../../../assets/images/product/crossWhite.png";
import SpecManager from "./SpecManager";

export default function SpecManagerDrawer({
  showSpecManager,
  locationId,
  handleSpecManagerToggle,
  handleDataRefreshAfterExitingSpecManager,
  fields,
  excludedField,
  fromModal,
  toggleEditSpec,
  isEditingSpec,
  identifier,
}) {
  const [valueHasBeenEdited, setValueHasBeenEdited] = useState(false); // if user has saved edits, refresh data on close
  const [specDataChanged, setSpecDataChanged] = useState(false); // if user has edited any values in edit/add spec, show modal on close
  const [exitingSpecManager, setExitingSpecManager] = useState(false);
  const [confirmExitModalDisplay, setConfirmExitModalDisplay] = useState(false);

  const handleClosingEditSpec = (fromSaveSpec = false) => {
    toggleEditSpec();
    if (valueHasBeenEdited || fromSaveSpec) {
      handleDataRefreshAfterExitingSpecManager();
      setValueHasBeenEdited(false);
    }
  };

  const confirmExitModalDisplayToggle = () => {
    setConfirmExitModalDisplay(!confirmExitModalDisplay);
    setExitingSpecManager(false);
    return !confirmExitModalDisplay;
  };

  const handleCloseSpecManager = () => {
    if (specDataChanged) {
      confirmExitModalDisplayToggle();
      setExitingSpecManager(true);
    } else if (fromModal) {
      toggleEditSpec();
    } else {
      handleSpecManagerToggle();
    }
    if (valueHasBeenEdited) {
      handleDataRefreshAfterExitingSpecManager();
      setValueHasBeenEdited(false);
    }
  };

  return (
    <Drawer
      open={showSpecManager}
      width={853}
      rootClassName="EnvSpecManagerDrawerWrapper"
      className="EnvSpecManagerDrawer"
      destroyOnClose
      title={(
        <>
          <span>Spec Manager</span>
          <img
            src={closeIcon}
            alt="close icon"
            className="EnvSpecManagerDrawer__Header__CloseIcon"
            onClick={handleCloseSpecManager}
          />
        </>
)}
    >
      <SpecManager
        valueHasBeenEdited={valueHasBeenEdited}
        setValueHasBeenEdited={setValueHasBeenEdited}
        exitingSpecManager={exitingSpecManager}
        setExitingSpecManager={setExitingSpecManager}
        specDataChanged={specDataChanged}
        setSpecDataChanged={setSpecDataChanged}
        confirmExitModalDisplay={confirmExitModalDisplay}
        setConfirmExitModalDisplay={setConfirmExitModalDisplay}
        confirmExitModalDisplayToggle={confirmExitModalDisplayToggle}
        showSpecManager={showSpecManager}
        locationId={fromModal ? locationId : ""}
        handleSpecManagerToggle={handleSpecManagerToggle}
        handleDataRefreshAfterExitingSpecManager={handleDataRefreshAfterExitingSpecManager}
        fields={fields}
        excludedField={excludedField}
        isEditingSpec={isEditingSpec}
        fromModal={fromModal}
        identifier={identifier}
        handleClosingEditSpec={handleClosingEditSpec}
      />
    </Drawer>
  );
}

import React, { useState } from "react";
import { validatePhoneNumber } from "../../../../../../../Common/utils/validationUtils";
import TemplateBuilderInput from "./TemplateBuilderInput";

export default function NumberInput({
  id, placeholder, label, setRef, defaultValue,
}) {
  const [valid, setValid] = useState(true);

  // const formatValue = (str) => {
  //   const numStr = str.split("").filter((ele) => ele >= "0" && ele <= "9").join("");
  //   return `${numStr.slice(0, 3)}-${numStr.slice(3, 6)}-${numStr.slice(6)}`;
  // };

  const onChange = (value) => {
    if (!value.trim() || validatePhoneNumber(value)) {
      setValid(true);
      return;
    }
    setValid(false);
  };

  return (
    <TemplateBuilderInput
      id={id}
      placeholder={placeholder}
      label={label}
      maxLength={25}
      onChange={onChange}
      hasError={!valid}
      setRef={setRef}
      defaultValue={defaultValue}
    />
  );
}

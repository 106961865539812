import { Modal } from "antd";
import React from "react";
import "./DeleteInvite.css";
import StyledButton from "../../../../../Common/UIComponents/StyledButton";

export default function DeleteInvite({ handleDeleteModal, handleDeleteInvite, loadingData }) {
  return (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      onCancel={() => handleDeleteModal(false)}
      width="448px"
      wrapClassName="DeleteInviteModel"
      className="DeleteInviteModelContainer"
    >
      <div className="DeleteInviteModel">
        <div
          className="DeleteInviteModelHeader"
        >
          Delete User
        </div>
        <div
          className="DeleteInviteModelText"
        >
          The invited user will not be able submit submissions. Are you sure you want to proceed?
        </div>
        <div className="DeleteInviteModelBtns">
          <StyledButton
            type="default"
            onClick={() => handleDeleteModal(false)}
            className="DeleteInviteModelCancelBtn"
          >
            Cancel
          </StyledButton>
          <StyledButton
            type="primary"
            onClick={() => handleDeleteInvite()}
            loading={loadingData}
          >
            Delete
          </StyledButton>
        </div>
      </div>
    </Modal>
  );
}

import React from "react";

import {
  View, StyleSheet, Text,
} from "@react-pdf/renderer";

export default function ReceiptPDFFooter({ FOOTER_TEXT }) {
  const styles = StyleSheet.create({
    footer: {
      display: "flex",
      fontFamily: "Roboto-400",
      flexDirection: "column",
      alignItems: "center",
      color: "#afbdca",
      fontSize: "10",
    },
    text: {
      height: "15",
    },
    line: {
      width: "100%",
      borderTop: "1 solid #e0e0e0",
      marginVertical: 10,
    },
  });

  return (
    <View style={styles.footer} fixed>
      <View style={styles.line} />
      {FOOTER_TEXT.map((text) => (
        <Text key={text} style={styles.text}>{text}</Text>
      ))}
    </View>
  );
}

import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";

import pageThumbnailPlugin from "./pageThumbnailPlugin";
// import disableScrollPlugin from "./disableScrollPlugin";

export default function PdfThumbnail(props) {
  const {
    blobData, pageIndex, height, width,
  } = props;

  const thumbnailPluginInstance = thumbnailPlugin();
  const { Cover } = thumbnailPluginInstance;

  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => pageIndex} />,
  });

  //   const disableScrollPluginInstance = disableScrollPlugin();

  return (
    <div
      style={{
        height: height || "70px",
        width: width || "64px",
        border: "1px solid #AFBDCA",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <Viewer plugins={[thumbnailPluginInstance, pageThumbnailPluginInstance]} fileUrl={blobData.url} />
      </Worker>
    </div>
  );
}

export const MemoPdfThumbnail = React.memo(PdfThumbnail);

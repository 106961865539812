import React from "react";
import "../../../Product.css";
import whiteMagnifyIcon from "../../../../../assets/images/white-magnifying-glass.png";
import "./SpecManagerSearch.css";

class SpecManagerSearch extends React.Component {
  constructor(props) {
    super(props);
    const { searchValue } = this.props;
    this.state = {
      searchValue: "" || searchValue,
    };
  }

  handleSearchChange = async (e, buttonClicked) => {
    const { searchValue } = this.state;
    const { handleSearchChange, searchField } = this.props;
    let value = "";
    if (!buttonClicked) {
      value = e.target.value;
    } else {
      value = searchValue;
    }
    this.setState({
      searchValue: value.trim() === "" ? "" : value,
    });
    if ((buttonClicked || e.key === "Enter" || value === "")) {
      await handleSearchChange(value, searchField);
    }
  }

  render() {
    const { flag, updateShowFilters } = this.props;
    const { searchValue } = this.state;
    let formControlId = "reportSearchInput";
    if (flag === "templates") {
      formControlId = "reportSearchInput2";
    } else if (flag === "analytics") {
      formControlId = "reportSearchInput3";
    }

    return (
      <div
        className="EnvSpecManagerSearchContainer"
        style={{ height: flag === "reports" || flag === "reports_spec" ? "40px" : "80px" }}
      >
        <div className={flag === "analytics" ? "searchFormAnalytics" : "searchForm"}>
          <div className="searchBar EnvSpecManagerSearchContainer__MainSection" id="specManagerSearchContainer"/* style={{ position: "relative" }} */>
            <input
              id={formControlId}
              className="specManagerSearchInput"
              placeholder="Search"
              onChange={(event) => {
                this.handleSearchChange(event, false);
              }}
              value={searchValue}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  this.handleSearchChange(event, false);
                }
              }}
              onFocus={() => updateShowFilters(true)}
            />
            <div
              className="ProdSpecManagerSearchBtn"
              onClick={(event) => {
                this.handleSearchChange(event, true);
              }}
            >
              <img
                className="specManagerSearchWhiteMagnifyIcon"
                src={whiteMagnifyIcon}
                alt="whiteMagnifyIcon"
              />

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SpecManagerSearch;

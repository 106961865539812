import React from "react";

import "../OnboardingFormLayout.css";
import StyledButton from "../../../../Common/UIComponents/StyledButton";

export default function OnboardingFormBtn({
  handleFormValidation, submitBtnValidation, history, loading,
}) {
  return (
    <div className="FormButtons OnboardingFormBtn__Button">
      <StyledButton
        className="Ant__CancelBtn"
        onClick={() => history.push("/sample-submission")}
        ghost
      >
        Cancel
      </StyledButton>
      <StyledButton
        type="primary"
        htmlType="submit"
        className="Ant__SubmitBtn"
        onClick={handleFormValidation}
        disabled={!submitBtnValidation}
        loading={loading}
      >
        Submit
      </StyledButton>
    </div>
  );
}

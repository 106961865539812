import React from "react";
import { ReactComponent as Account } from "../../../assets/images/onboarding/account.svg";
import { ReactComponent as ESV } from "../../../assets/images/onboarding/ESV.svg";
import { ReactComponent as Contact } from "../../../assets/images/onboarding/Contact Info.svg";
import { ReactComponent as CustomerInfo } from "../../../assets/images/onboarding/CustomerInfo.svg";
import { ReactComponent as ESVLogo } from "../../../assets/images/onboarding/ESVLogo.svg";
import { ReactComponent as AccountCreation } from "../../../assets/images/onboarding/AccountCreation.svg";
import { ReactComponent as ContactInfo } from "../../../assets/images/onboarding/ContactInfo.svg";

export const RenderIconOnNextClick = function renderStepsIcon({ Inx }) {
  switch (Inx) {
    case 0:
      return <CustomerInfo />;
    case 1:
      return <Contact />;
    case 2:
      return <ESV />;
    case 3:
      return <Account />;
    default:
      return <CustomerInfo />;
  }
};

export const RenderIconOnNextClickForCustomerExist = function renderStepsIcon({ Inx }) {
  switch (Inx) {
    case 0:
      return <CustomerInfo />;
    case 1:
      return <ESV />;
    case 2:
      return <Account />;
    default:
      return <CustomerInfo />;
  }
};

export const RenderIconOnPreviousClick = function renderStepsIcon({ Inx }) {
  switch (Inx) {
    case 0:
      return <CustomerInfo />;
    case 1:
      return <ContactInfo fill="#6C7E8E" />;
    case 2:
      return <ESVLogo fill="#6C7E8E" />;
    case 3:
      return <AccountCreation fill="#6C7E8E" />;
    default:
      return <CustomerInfo fill="#6C7E8E" />;
  }
};

export const RenderIconOnPreviousClickForCustomerExist = function renderStepsIcon({ Inx }) {
  switch (Inx) {
    case 0:
      return <CustomerInfo />;
    case 1:
      return <ESVLogo fill="#6C7E8E" />;
    case 2:
      return <AccountCreation fill="#6C7E8E" />;
    default:
      return <CustomerInfo fill="#6C7E8E" />;
  }
};

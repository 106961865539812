import React, { useState } from "react";
import "./EditPreviewButton.css";
import { ReactComponent as PreviewIcon } from "../../../../../../assets/images/product/template/TemplatePreviewIcon.svg";
import { ReactComponent as EditIcon } from "../../../../../../assets/images/product/template/edit.svg";

export default function EditPreviewButton({
  previewTemplate,
  handlePreviewTemplate,
  loadingPreview,
  setLoadingPreview,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const handlePreviewTemplateClick = () => {
    if (!previewTemplate) {
      setLoadingPreview(true);
    }
    handlePreviewTemplate();
  };

  return (
    <button
      type="button"
      className={`TemplateBuilder__EditPreviewButton ${loadingPreview ? "TemplateBuilder__EditPreviewButton--disabled" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={loadingPreview ? null : handlePreviewTemplateClick}
      // disabled={loadingPreview}
    >
      {isHovered && (
        <span>{previewTemplate ? "Edit" : "Preview"}</span>
      )}
      {previewTemplate ? <EditIcon className="TemplateBuilder__EditIcon" /> : <PreviewIcon />}
    </button>
  );
}

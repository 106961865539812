import React from "react";
import { ReactComponent as EditIcon } from "../../../../assets/images/sample-submission/edit.svg";

export default function SubmissionDetailsModalFooterComment({ comment, handleEditComment, allowEdit }) {
  return (
    <>
      <p className={`SubmissionDetailsModal__Footer_Comment${allowEdit ? "--edit" : ""}`} onClick={allowEdit ? handleEditComment : null}>
        <span> Comment: </span>
        {comment && comment !== "" ? comment : "-"}
        {allowEdit && (
        <EditIcon className="SubmissionDetailsModal__Footer_Comment__EditIcon">
          <title>edit icon</title>
        </EditIcon>
        )}
      </p>
    </>
  );
}

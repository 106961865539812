import React from "react";
import "../../../Env.css";
import Scrollbar from "react-scrollbars-custom";
import SpecsRange from "./SpecsRange";
import SpecsQuantitative from "./SpecsQuantitative";
import SpecsQualitative from "./SpecsQualitative";
import { ReactComponent as RangeIcon } from "../../../../../assets/images/product/RangeIcon.svg";
import { ReactComponent as QualitativeIcon } from "../../../../../assets/images/product/QualitativeIcon.svg";
import { ReactComponent as QuantitativeIcon } from "../../../../../assets/images/product/QuantitativeIcon.svg";
import SpecManagerSelect from "./SpecManagerSelect";

class EditSpecTable extends React.Component {
  // handleChooseDropDown = (optionSelected) => {
  //   this.setState({
  //     dropDownSelection: optionSelected,
  //   });
  // }
  constructor(props) {
    super(props);

    this.state = {
      newQualitative: false,
    };
  }

  handleDataChange = (indexOfRowClicked, fieldName, newFieldValue) => {
    const { dataOfSpecEditing, handleDataChange } = this.props;

    // console.log('dataOfSpecEditing, before: ', dataOfSpecEditing);
    if (fieldName === "test_type") {
      if (dataOfSpecEditing[indexOfRowClicked].test_type !== newFieldValue) {
        dataOfSpecEditing[indexOfRowClicked].test_type = newFieldValue;
        dataOfSpecEditing[indexOfRowClicked].specs = [];

        if (newFieldValue === "qualitative") {
          this.setState({
            newQualitative: true,
          });
        }
      }
    } else if (fieldName === "specs") {
      dataOfSpecEditing[indexOfRowClicked][fieldName] = newFieldValue;
      // console.log('dataOfSpecEditing, after: ', dataOfSpecEditing);
    } else {
      dataOfSpecEditing.push({ [fieldName]: newFieldValue, test_type: "", specs: ["", "", ""] });
    }

    handleDataChange(dataOfSpecEditing);
  };

  render() {
    const {
      addingSpec,
      dataOfSpecEditing,
    } = this.props;

    const { newQualitative } = this.state;

    return (
      <Scrollbar
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
        style={{
          position: "",
          width: "100%",
          height: "350px",
          minHeight: "350px",
        }}
        id="envSpecManagerEditSpecTableScrollBar"
      >
        <table className="specTable" style={{ paddingRight: "10px" }}>
          <thead>
            <tr>
              <th className="envSpecManagerEditSpecTh1">Test</th>
              <th className="envSpecManagerEditSpecTh2">Test Type</th>
              <th className="envSpecManagerEditSpecTh3">Specs</th>
            </tr>
          </thead>
          <tbody>

            {dataOfSpecEditing.map((item, i) => (
              <tr key={i}>
                {addingSpec
                  ? (
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px solid #E1E1E1",
                        borderRadius: "3px",
                      }}
                    >
                      {/* {item.Test}  */}
                      {item.test}

                    </td>
                  )
                  : (
                    <td style={{
                      textAlign: "center",
                      border: "1px solid #E1E1E1",
                      borderRadius: "3px",
                    }}
                    >
                      {item.test}
                    </td>
                  ) }
                <td
                  style={{
                    paddingLeft: "10px",
                    border: "1px solid #E1E1E1",
                    borderRadius: "3px",
                  }}
                >
                  <SpecManagerSelect
                    width="152px"
                    optionSelected={item.test_type || undefined}
                    options={[{ value: "range", icon: <RangeIcon /> },
                      { value: "quantitative", icon: <QuantitativeIcon /> },
                      { value: "qualitative", icon: <QualitativeIcon /> }]}
                    placeholder="Choose Test Type"
                    onSelect={(optionSelected) => this.handleDataChange(i, "test_type", optionSelected)}
                  />
                </td>
                <td
                  style={{
                    border: "1px solid #E1E1E1",
                    borderRadius: "3px",
                  }}
                >
                  { item.test_type === "qualitative"
                    && (
                    <SpecsQualitative
                      data={item.specs.length === 0 ? ["", "", ""] : item.specs}
                      handleDataChange={(newSpec) => this.handleDataChange(i, "specs", newSpec)}
                      newQualitative={newQualitative}
                    />
                    )}
                  {
                    item.test_type === "range"
                      && (
                      <SpecsRange
                        data={item.specs.length === 0 ? ["", "", ""] : item.specs}
                        handleDataChange={(newSpec) => this.handleDataChange(i, "specs", newSpec)}
                      />
                      )
                  }
                  { item.test_type === "quantitative"
                    && (
                    <SpecsQuantitative
                      data={item.specs.length === 0 ? ["", "", ""] : item.specs}
                      handleDataChange={(newSpec) => this.handleDataChange(i, "specs", newSpec)}
                    />
                    )}

                  {(item.test_type !== "quantitative" && item.test_type !== "qualitative"
                    && item.test_type !== "range")
                    && (
                    <div
                      style={{
                        color: "#B3BFDB",
                        width: "400px",
                        height: "48px",
                        textAlign: "center",
                        paddingTop: "15px",
                      }}
                    >
                      Please select test type to view the specs
                    </div>
                    )}
                </td>
              </tr>
            ))}

          </tbody>
        </table>
      </Scrollbar>
    );
  }
}

export default EditSpecTable;

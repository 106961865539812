import React from "react";
import { toast } from "react-toastify";
import "./SpecManager.css";
import SpecManagerSearch from "./SpecManagerSearch";
import EditSpec from "./EditSpec";
import FilterButton from "./FilterButton";
import ConfirmModal from "./ConfirmModal";
import SpecStatusItemList from "./SpecStatusItemList/SpecStatusItemList";

import {
  getEnvSpecsData,
} from "../../../../../actions/envReports";
import pendingIcon from "../../../../../assets/images/product/dark-pending.png";
import addedIcon from "../../../../../assets/images/product/addFilterMidnight.png";
import {
  parseSpecDetail, formatSpec, handleCheckUnit, rangeIsMissingValue,
} from "./Helper";

class SpecManager extends React.Component {
  constructor(props) {
    super(props);
    this.specManagerScrollbar = React.createRef();
    const {
      isEditingSpec, locationId, identifier, originalSpecData,
    } = this.props;
    this.state = {
      specFilter: "", // can be "pending" or "added"
      files: [],
      editingSpec: isEditingSpec !== undefined ? isEditingSpec : false,
      addingSpec: false,
      locationId: locationId || "",
      dataOfSpecEditing: originalSpecData || [],
      searchValue: "",
      disableBlock: true,
      nextPageLink: "",
      loadingNewContent: false,
      loadingEditSpecData: false,
      identifierFields: [], // ex: ["swab_number", "section", "zone"]
      identifier: identifier || "",
    };
  }

  componentDidMount = async () => {
    const { changeSpecManagerMode, fromModal, handleClosingEditSpec } = this.props;
    const { specFilter, searchValue, locationId } = this.state;

    if (typeof changeSpecManagerMode === "function") {
      changeSpecManagerMode();
    }

    if (fromModal) {
      const params = {
        filter: "",
        search: "",
        location_id: locationId,
        specs: "",
        page: 1,
      };

      const response = await getEnvSpecsData(params);

      if (response && response.files) {
        const data = response.files;
        data.forEach((spec) => {
          const specDetail = parseSpecDetail(spec);
          spec.test_type = specDetail.test_type;
          spec.specs = specDetail.specs;
        });
        this.setState({
          dataOfSpecEditing: data,
        });
      } else {
        toast.error("Failed to get spec data.");
        handleClosingEditSpec();
      }
    } else {
      this.getInitialData(specFilter, searchValue, locationId);
    }
  }

  getInitialData = (specFilter = "", searchValue = "", locationId = "", scrollToTop = false) => {
    // const { handleSpecManagerToggle } = this.props;
    this.setState({ disableBlock: true });
    this.apiGetSpecsData(specFilter, searchValue, locationId, scrollToTop);
    // .then((result) => {
    //   if (result && result.files) {
    //     const {
    //       nextPageLink,
    //     } = result.pages;
    //     this.setState({
    //       files: result.files,
    //       identifierFields: result.pattern.split("-"),
    //       nextPageLink,
    //       disableBlock: false,
    //     });
    //   }
    //   // handleSpecManagerToggle();
    // });
  }

  handlePageChangeScroll = () => {
    const {
      nextPageLink: reqUrl,
      specFilter,
      searchValue,
      locationId,
      loadingNewContent,
      files: currentFiles,
    } = this.state;
    if (reqUrl !== "" && !loadingNewContent) {
      this.setState({ loadingNewContent: true }, () => this.specManagerScrollbar.current.scrollToBottom());
      const page = reqUrl ? reqUrl.split("page=")[1].split("&")[0] : "1";
      const scrollToTop = false;
      this.apiGetSpecsData(specFilter, searchValue, locationId, scrollToTop, page).then((result) => {
        if (result && result.files) {
          const {
            nextPageLink,
          } = result.pages;
          this.setState({
            files: [...currentFiles, ...result.files],
            nextPageLink,
            loadingNewContent: false,
          });
        }
      });
    }
  }

  apiGetSpecsData = async (specFilter = "", searchValue = "", locationId = "", scrollToTop = false, page = "1") => {
    this.setState({ disableBlock: true });

    const payload = {
      filter: specFilter,
      search: searchValue,
      location_id: locationId,
      page,
    };
    const result = await getEnvSpecsData(payload);
    // console.log("apiGetSpecsData", result);
    if (result && result.success) {
      // console.log(result);

      const {
        nextPageLink,
      } = result.pages;
      if (!locationId) { // don't update files if loading edit spec modal (will overwrite spec files)
        this.setState({
          files: result.files,
          identifierFields: result.pattern.split("."),
          nextPageLink,
          disableBlock: false,
        });
      } this.setState({
        disableBlock: false,
      });

      if (result.files.length > 0 && scrollToTop) {
        this.specManagerScrollbar.current.scrollToTop();
      }

      return result;
    }
    // console.log("failed", result);
    toast.error("Failed to get spec data.");
    this.setState({ disableBlock: false });
    return null;
  }

  handleSearchChange = async (value) => {
    // const { searchValue } = this.state;
    // console.log("value for parent search: ", value, searchValue);
    const { specFilter, locationId, searchValue } = this.state;
    const scrollToTop = true;
    const succeeded = await this.apiGetSpecsData(specFilter, value, locationId, scrollToTop);
    this.setState({ searchValue: succeeded ? value : searchValue });
    return succeeded;
    // this.specManagerScrollbar.current.scrollToTop();
  }

  handleCloseFilterClick = (e) => {
    e.stopPropagation();
    this.handleFilterToggle("");
  }

  handleFilterToggle = async (specFilter) => {
    const { specFilter: specFilterFromState, searchValue, locationId } = this.state;
    if (specFilter === specFilterFromState) {
      specFilter = "";
    }
    const scrollToTop = true;
    const succeeded = await this.apiGetSpecsData(specFilter, searchValue, locationId, scrollToTop);
    this.setState({ specFilter: succeeded ? specFilter : specFilterFromState });
    // console.log(specFilterFromState, "new spec", specFilter);
  }

  apiEditSpecsData = async (params) => {
    const result = await getEnvSpecsData(params, true);
    if (result && result.success) {
      return result;
    }
    return null;
  }

  handleEditSpecClick = (e, i, flag) => {
    // "Added" -> "added" and.... "Pending" -> "pending"
    e.preventDefault();
    e.stopPropagation();

    const { files } = this.state;

    // if (flag === "add") {
    let locationId;
    if (flag === "pending") {
      locationId = files[i].location_id;
      this.setState({
        addingSpec: true,
        editingSpec: false,
        locationId: files[i].location_id,
        identifier: files[i].identifier,
      });
      // } else if (flag === "edit") {
    } else if (flag === "added") {
      locationId = files[i].location_id;
      this.setState({
        addingSpec: false,
        editingSpec: true,
        locationId: files[i].location_id,
        identifier: files[i].identifier,
      });
    }

    if (flag === "pending" || flag === "added") {
      this.setState({ loadingEditSpecData: true });
      this.apiGetSpecsData("", "", locationId).then((result) => {
        let dataOfSpecEditing = result.files;
        dataOfSpecEditing = dataOfSpecEditing.map((spec) => parseSpecDetail(spec));
        this.setState({
          loadingEditSpecData: false,
          dataOfSpecEditing,
        });
      });
    } else {
      toast.error(`Incorrect spec flag: ${flag}`);
    }
  }

  refreshEditSpecDataAfterEdit = () => {
    const { locationId } = this.state;
    this.setState({ loadingEditSpecData: true });
    this.apiGetSpecsData("", "", locationId).then((result) => {
      let dataOfSpecEditing = result.files;
      dataOfSpecEditing = dataOfSpecEditing.map((spec) => parseSpecDetail(spec));
      this.setState({ loadingEditSpecData: false, dataOfSpecEditing });
    });
  }

  handleDataChange = (specList) => {
    this.setState({ dataOfSpecEditing: [...specList] });
  }

  handleExitEditSpec = (exitSpecManager = false, refreshSpecManagerData = false) => {
    const {
      handleSpecManagerToggle,
      fromModal,
      handleClosingEditSpec,
      setExitingSpecManager,
      setSpecDataChanged,
      setConfirmExitModalDisplay,
    } = this.props;
    if (exitSpecManager) {
      if (fromModal) {
        handleClosingEditSpec();
      } else {
        handleSpecManagerToggle();
      }
      setConfirmExitModalDisplay(false);
      setExitingSpecManager(false);
      setSpecDataChanged(false);
    } else {
      if (refreshSpecManagerData) {
        const { specFilter, searchValue } = this.state;
        this.apiGetSpecsData(specFilter, searchValue);
      }
      this.setState({
        editingSpec: false,
        addingSpec: false,
        locationId: "",
        identifier: "",
        dataOfSpecEditing: [],
      });
      setConfirmExitModalDisplay(false);
      setExitingSpecManager(false);
      setSpecDataChanged(false);
    }
  }

  handleSaveSpec = async (isSpecCleared = false) => {
    const {
      locationId, dataOfSpecEditing,
    } = this.state;
    const { toggleSpecsUpdated, fromModal, setValueHasBeenEdited } = this.props;
    let specList = formatSpec(dataOfSpecEditing);
    if (rangeIsMissingValue(specList)) {
      toast.error("Please input all values for the range.");
      return false;
    }
    specList = handleCheckUnit(specList);

    const params = {
      location_id: locationId,
      specs: isSpecCleared ? [] : specList,
    };

    let result = false;
    await this.apiEditSpecsData(params).then((response) => {
      setValueHasBeenEdited(true);
      if (response && response.success) {
        toast.success("Spec successfully edited.");
        if (toggleSpecsUpdated) {
          toggleSpecsUpdated(true);
        }

        if (!fromModal) {
          this.refreshEditSpecDataAfterEdit();
        }

        result = true;
      } else {
        this.refreshEditSpecDataAfterEdit();
        toast.error("Failed to edit spec.");
      }
    });

    return result;
  }

  getHeader = () => {
    const { identifierFields } = this.state;
    const { fields, excludedField } = this.props;
    const fieldsCopy = [...fields];
    fieldsCopy.push(excludedField);
    const fieldMap = {};
    fieldsCopy.forEach((field) => {
      if (identifierFields.includes(field.json_field)) {
        fieldMap[field.json_field] = field.title_field;
      }
    });
    // console.log(fieldMap);
    return identifierFields.map((field) => fieldMap[field]).join(" . ");
  }

  render() {
    const {
      files,
      specFilter,
      addingSpec,
      editingSpec,
      dataOfSpecEditing,
      disableBlock,
      identifier,
      searchValue,
      loadingNewContent,
      loadingEditSpecData,
    } = this.state;
    const {
      // handleSpecManagerToggle,
      isEditingSpec,
      fromModal,
      handleClosingEditSpec,
      valueHasBeenEdited,
      setSpecDataChanged,
      exitingSpecManager,
      specDataChanged,
      confirmExitModalDisplay,
      confirmExitModalDisplayToggle,
    } = this.props;

    return (
      <div>
        <div className="EnvSpecManagerCard">
          <div>
            {addingSpec || editingSpec
              ? (
                <>
                  {addingSpec
                    && (
                      <div className="EnvSpecManagerCard__AddingSpec">
                        Adding specs here will automatically check and update the status of the spec of your reports.
                      </div>
                    )}
                  {editingSpec
                    && (
                      <div className="EnvSpecManagerCard__EditingSpec">
                        Note: Editing the specs here will impact the outcome of all the files associated with these specs.
                      </div>
                    )}
                  <div className="EnvSpecManagerCard__Separator">
                    <div className="EnvSpecManagerCard__Separator__Content" />
                  </div>
                  <EditSpec
                    fromModal={fromModal}
                    addingSpec={addingSpec}
                    identifier={identifier}
                    dataOfSpecEditing={dataOfSpecEditing}
                    confirmExitModalDisplayToggle={confirmExitModalDisplayToggle}
                    handleExitEdit={this.handleExitEditSpec}
                    handleSaveSpec={this.handleSaveSpec}
                    handleDataChange={this.handleDataChange}
                    handleSpecChanged={setSpecDataChanged}
                    valueHasBeenEdited={valueHasBeenEdited}
                    specChanged={specDataChanged}
                    editingSpec={isEditingSpec}
                    handleClosingEditSpec={handleClosingEditSpec}
                    loading={loadingEditSpecData}
                  />
                </>
              )
              : (
                <>
                  <div className="EnvSpecManagerCard__ListHeader">
                    <span className="EnvSpecManagerCard__ListHeader__Span">
                      {" "}
                      {this.getHeader()}
                    </span>
                  </div>
                  <div className="EnvSpecManagerCard__SearchAndFilter">
                    <SpecManagerSearch
                      // isEnvFilter={false}
                      flag="reports_spec"
                      searchValue={searchValue}
                      handleSearchChange={this.handleSearchChange}
                    />
                    <div className="EnvSpecManager__FiltersSection">
                      <FilterButton
                        isActive={specFilter === "added"}
                        icon={addedIcon}
                        filterText="Spec Added"
                        handleFilterToggle={this.handleFilterToggle}
                        handleCloseFilterClick={this.handleCloseFilterClick}
                        // disableBlock = {(!files || files.length === 0) && this.state.isUsingSearch }
                        disableBlock={disableBlock}
                        color="#7DCDED"
                      />
                      <div className="EnvSpecManager__FiltersSection__Separator" />
                      <FilterButton
                        isActive={specFilter === "pending"}
                        icon={pendingIcon}
                        filterText="Spec Incomplete"
                        handleFilterToggle={this.handleFilterToggle}
                        handleCloseFilterClick={this.handleCloseFilterClick}
                        // disableBlock = {(!files || files.length === 0) && this.state.isUsingSearch }
                        disableBlock={disableBlock}
                        color="#C4D2DF"
                      />
                      <div className="EnvSpecManager__FiltersSection__Separator" />
                      {/* <FilterButton
                          isActive={specFilter === "Add spec"}
                          icon={addSpecIcon}
                          filterText="Add spec"
                          handleFilterToggle={this.handleFilterToggle}
                          handleCloseFilterClick={this.handleCloseFilterClick}
                          disableBlock = {(!files || files.length === 0) && this.state.isUsingSearch }
                        /> */}
                    </div>
                  </div>
                  <div className="EnvSpecManagerCard__ItemList">

                    <SpecStatusItemList
                      searchValue={searchValue}
                      tableData={files}
                      handleEditSpecClick={this.handleEditSpecClick}
                      handlePageChangeScroll={this.handlePageChangeScroll}
                      loadingNewContent={loadingNewContent}
                      specManagerScrollbar={this.specManagerScrollbar}
                      loading={disableBlock}
                    />
                  </div>
                </>
              )}
          </div>
        </div>
        {confirmExitModalDisplay
          && (
            <ConfirmModal
              headerText="Unsaved Changes"
              bodyText="Are you sure you want to leave without saving?"
              buttonText={["Leave", "Stay"]}
              buttonFunctions={[() => this.handleExitEditSpec(exitingSpecManager), confirmExitModalDisplayToggle]}
            />
          )}
      </div>
    );
  }
}
export default SpecManager;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import COAList from "./COA/COAList";
import CreateReportButton from "./Common/CreateReportButton";
import COAReport from "./COA/COAReport";
import COALoadingPage from "./Common/COALoadingPage";
import {
  getCoas,
  getTemplates,
} from "../../../../actions/productTemplate";
import { createAndAppendIdentifierList } from "../helper";
import "./styles/coa.css";
import DataFieldsButton from "./DataFieldsButton";
import DataFieldsModal from "./DataFields/DataFieldsModal";
import Header from "./Common/Header";
import { getProductFields } from "../../../../actions/reports";

export default function COA(props) {
  const {
    handleCustomizeReportTemplate,
    handleIsCreatingCoa,
    isCreatingCoa,
  } = props;
  const [showDataFieldsModal, setShowDataFieldsModal] = useState(false);
  const [coaList, setCoaList] = useState([]);
  const [hasCoa, setHasCoa] = useState(false);
  const [hasTemplate, setHasTempalte] = useState(false);
  const [showLoadingPage, setShowLoadingPage] = useState(true);
  const [linkReportKeys, setLinkReportKeys] = useState([]);

  /**
   * Make three api calls:
   * 1. get templates list
   * 2. get coa list
   * 3. get the link_report key
   */
  const fetchTemplatesAndCoa = async () => {
    const templateResponse = await getTemplates();
    const params = { category: "fetchList" };
    const coaResponse = await getCoas(params);
    const commonFieldResp = await getProductFields();

    let curLinkReportKeys = [];
    if (commonFieldResp.success) {
      curLinkReportKeys = commonFieldResp.link_report;
      setLinkReportKeys(commonFieldResp.link_report);
    }
    if (!templateResponse || !templateResponse.success) {
      setHasTempalte(false);
    } else if (templateResponse.result.length > 0) {
      setHasTempalte(true);
    }
    if (coaResponse && coaResponse.message === "Success") {
      const result = createAndAppendIdentifierList(coaResponse, curLinkReportKeys);
      setCoaList(result);
      setHasCoa(result.length > 0);
    } else {
      toast.error("Failed to get COA list.");
      setCoaList([]);
    }
    setShowLoadingPage(false);
  };

  /**
   * If it is the COA page, then fetch different information and set the loading state.
   * If it is a "Create Custon Report" page, then turn off the loading state and no need to make any api call in the current component.
   */
  useEffect(() => {
    if (!isCreatingCoa) {
      setShowLoadingPage(true);
      fetchTemplatesAndCoa();
    } else {
      setShowLoadingPage(false);
    }
  }, []); // eslint-disable-line

  const toggleDataFieldsModal = () => {
    setShowDataFieldsModal(!showDataFieldsModal);
  };

  /**
   * A function which hanldes the on click action for the "back" icon on the "Create Custon Report" page.
   * @param {*} val
   */
  const backToChooseCoaReport = (val) => {
    setShowLoadingPage(true);
    handleIsCreatingCoa(val);
    fetchTemplatesAndCoa();
  };

  if (showLoadingPage) {
    return (
      <COALoadingPage />
    );
  }

  /**
   * Show this content if there is no templates and coa list is empty
   */
  if (!hasTemplate && !isCreatingCoa && !hasCoa) {
    return (
      <div className="coaContainer">
        <DataFieldsButton
          toggleDataFieldsModal={toggleDataFieldsModal}
          showDataFieldsModal={showDataFieldsModal}
        />
        {showDataFieldsModal && (
        <div className="dataFieldsModalContainer">
          <DataFieldsModal dataFieldCategory="data_fields" />
        </div>
        )}
        <div className="coaPlaceHolderContainer">
          <div className="coaPlaceholderText">
            Build Your templates to create customized COAs.
          </div>
        </div>
      </div>
    );
  }

  /**
   * Redicrect to the "Create Custom Report" page if the "Create Custom Report" button is clicked
   */
  if (isCreatingCoa) {
    return (
      <COAReport
        handleAddCoa={() => handleIsCreatingCoa(true)}
        isCreatingCoa={isCreatingCoa}
        backToChooseCoaReport={(val) => backToChooseCoaReport(val)}
        handleCustomizeReportTemplate={handleCustomizeReportTemplate}
        linkReportKeys={linkReportKeys}
        setLinkReportKeys={setLinkReportKeys}
      />
    );
  }

  /**
   * If the coa list is not empty, then display the list of coa.
   */
  if (hasCoa) {
    return (
      <div className="coaContainer ">
        <DataFieldsButton
          toggleDataFieldsModal={toggleDataFieldsModal}
          showDataFieldsModal={showDataFieldsModal}
        />
        {showDataFieldsModal && (
        <div className="dataFieldsModalContainer">
          <DataFieldsModal dataFieldCategory="data_fields" />
        </div>
        )}
        <div className={`${showDataFieldsModal ? "disabledCOAList" : ""}`}>
          <COAList
            handleAddCoa={() => handleIsCreatingCoa(true)}
            coaList={coaList}
            setCoaList={setCoaList}
            linkReportKeys={linkReportKeys}
          />
        </div>
      </div>

    );
  }

  /** If there is the coa list is empty and templates are not empty, show this content */
  return (
    <div className="coaContainer">
      <div className="coaContainer__EmptyCoaPage">
        <Header
          title="Create your first COA"
          showCreateReportButton={false}
        />
        <DataFieldsButton
          toggleDataFieldsModal={toggleDataFieldsModal}
          showDataFieldsModal={showDataFieldsModal}
        />
      </div>
      {showDataFieldsModal && (
        <div className="dataFieldsModalContainer">
          <DataFieldsModal dataFieldCategory="data_fields" />
        </div>
      )}
      <div className="coaPlaceHolderContainer--hasTemplate">
        <CreateReportButton buttonText="Create Custom Report" showAddIcon handleAddCoa={() => handleIsCreatingCoa(true)} />
      </div>
    </div>
  );
}

import React from "react";
import { Draggable } from "react-beautiful-dnd";
import UploadImage from "../Center/TemplateBuilderCenter/UploadImage";
import "./StatementSignatureDraggable.css";
import "./Draggable.css";
import TemplateBuilderInput from "../Center/TemplateBuilderCenter/Inputs/TemplateBuilderInput";
import DragHandle from "./DragHandle";

export default function StatementSignatureDraggable(props) {
  const {
    index,
    handleBuilderFile,
    inputs,
    sectionIndex,
    setSectionInputs,
  } = props;

  const { signature_image_url, signed_by, designation } = inputs[index];

  const setRef = (id, input) => {
    const inputsCopy = [...inputs];
    inputsCopy[index][id].ref = input;
    setSectionInputs(sectionIndex, inputs, true, null, false);
  };

  return (
    <Draggable key="statement_signature" draggableId="statement_signature" index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps} //eslint-disable-line
          className="StatementCard__SignatureContainer"
        >
          <DragHandle dragHandleProps={provided.dragHandleProps} />
          <UploadImage
            id="signature_img"
            title="Add Signature"
            handleBuilderFile={handleBuilderFile}
            imagePath={signature_image_url}
          />
          <div className="SignatureContainer__RightSection ">
            <TemplateBuilderInput
              id="signed_by"
              placeholder="Signed by"
              maxLength={250}
              defaultValue={signed_by.defaultValue}
              setRef={(input) => setRef("signed_by", input)}
            />
            <TemplateBuilderInput
              id="designation"
              placeholder="Designation"
              maxLength={250}
              defaultValue={designation.defaultValue}
              setRef={(input) => setRef("designation", input)}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
}

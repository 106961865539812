import React from "react";
import Highlighter from "react-highlight-words";
import "./ProductCard.css";
import SelectionCard from "../../CommonComponents/SelectionCard";

export default function ProductCard({
  product, type, updateSelectedOptions, disabled, active, linkReportFields, fieldsMap, search, searchByField,
}) {
  return (
    <SelectionCard
      data={product}
      type={type}
      updateSelectedOptions={updateSelectedOptions}
      disabled={disabled}
      active={active}
      animationKey={product?.product_id}
    >
      {linkReportFields.map((json_field) => (product[json_field]
        ? (
          <span key={json_field} className="Analytics__ProductCard__Text">
            <span key={`${json_field}_title_field`} className="Analytics__ProductCard__FieldText">{`${fieldsMap[json_field].title_field}: `}</span>
            {json_field === searchByField ? (
              <Highlighter
                key={`${json_field}_value`}
                className="Analytics__SelectionCard__ValueText"
                highlightClassName="Analytics__SelectionCard__ValueText--highlighted"
                searchWords={[search]}
                autoEscape
                textToHighlight={product[json_field]}
              />
            ) : <span key={`${json_field}_json_field`} className="Analytics__SelectionCard__ValueText">{product[json_field]}</span>}
          </span>
        ) : null))}
    </SelectionCard>
  );
}

import React from "react";
import "./COABuilderHeader.css";
import COABuilderImageWithText from "./COABuilderImageWithText";

export default function COABuilderHeader({ selectedTemplate, handleBuilderFile }) {
  const {
    company_name, company_address, phone_number, fax_number, logo_image_url,
  } = selectedTemplate;
  return (
    <div className={`COABuilderHeader ${!logo_image_url ? "COABuilderHeader--noImage" : ""}`}>
      <div className="COABuilderHeader__LeftContainer">
        <COABuilderImageWithText
          titleText={company_name.toUpperCase()}
          subtitleText={company_address}
          imagePath={logo_image_url}
          handleBuilderFile={handleBuilderFile}
          imageTitle="Add Company Logo"
        />
      </div>
      <div className="COABuilderHeader__NumbersContainer">
        {phone_number && (
          <div className="COABuilderHeader__NumberRow">
            <span>PHONE : </span>
            <span>{phone_number}</span>
          </div>
        )}
        {fax_number && (
          <div className="COABuilderHeader__NumberRow">
            <span>FAX : </span>
            <span>{fax_number}</span>
          </div>
        )}
      </div>
    </div>
  );
}

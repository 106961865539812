import React, {
  useCallback, useEffect, useRef, useState,
} from "react";

import { Modal } from "antd";
import {
  isEmpty, isEqual, pick, sortBy,
} from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import AddEditTestsForm from "./AddEditTestsForm";
import CreateSamplingEventModalFooter from "./CreateSamplingEventModalFooter";
import CreateSamplingEventModalTabs from "./CreateSamplingEventModalTabs";
import FrequencyForm from "./FrequencyForm";
import SelectLocationForm from "./SelectLocationForm";
import SetFrequenceForm from "./SetFrequenceForm";
import SummaryForm from "./SummaryForm";

import { ExtraFieldValueType, SAMPLE_SUBMISSION_MAXLENGTH } from "../utils";

import { getEnvironmentFields } from "../../../actions/envAnalytics";
import { getEnvSwabs, postEnvSchedule } from "../../../actions/envCalendar";

import { ReactComponent as CloseIcon } from "../../../assets/images/OnboardingClose.svg";

import "./CreateSamplingEventModal.css";

export default function CreateSamplingEventModal({
  open,
  onCancel,
  modalTitle,
  tabs,
  selectedTab,
  onTabChange,
  disableSwitchFromTab,
  locationId,
  newSamplingEventData,
  setNewSamplingEventData,
  editingData,
  isEditing,
  setEditSchedulingPayload,
  onConfirmEdit,
  showSearchbar,
  allowEmptySubmissionName = false,
  hideSubmissionInput = false,
  setHasEditEventFrequencyChanged,
}) {
  // Location form data
  const [markedLocations, setMarkedLocations] = useState(new Map());
  const searchValue = useRef("");
  const [environmentFields, setEnvironmentFields] = useState(null);
  const [locationData, setLocationData] = useState([]);
  const [extraFields, setExtraFields] = useState([]);
  const [linkPatternFields, setLinkPatternFields] = useState([]);
  const [sampleIDFields, setSampleIDFields] = useState([]);
  const [linkPatternDelimiter, setLinkPatternDelimiter] = useState(null);
  const [locationCheckboxLoading, setLocationCheckboxLoading] = useState(null); // set location id to check which row is loading

  const [extraFieldValues, setExtraFieldValues] = useState(new Map()); // Map with location_id key and {} values (extrafield: value)
  const [extraFieldOptions, setExtraFieldOptions] = useState(new Map()); // Map with location_id key and {} values (extrafield: [])
  const [testListFromSSAutoFill, setTestListFromSSAutoFill] = useState(new Map()); // // Map with location_id key and []

  const [currentEventFreq, setCurrentEventFreq] = useState({ event_dates: [], event_frequency: "" });

  // Set Frequency form data
  const [oneTimeDate, setOneTimeDate] = useState(moment().format("MM/DD/YYYY"));
  const [selectedType, setSelectedType] = useState("recurring");
  // Frequency form
  const [selectedRecurringType, setSelectedRecurringType] = useState("weekly");
  // Week frequency form
  const [startDate, setStartDate] = useState(moment().format("MM/DD/YYYY"));
  const [endDate, setEndDate] = useState(moment().add(1, "year").format("MM/DD/YYYY"));
  const [weekDays, setWeekDays] = useState([]);
  // Month frequency form
  const [dayOfMonth, setDayOfMonth] = useState([]);
  // Custom date
  const [selectedCustomDatesList, setSelectedCustomDatesList] = useState([]);
  // WeekMonthCheckbox
  const [selectedDayCheckbox, setSelectedDayCheckbox] = useState("");
  // Submit loading
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const getPointsAbortController = useRef();

  // Loading states
  const [selectLocationFormLoader, setSelectLocationFormLoader] = useState(true);

  /**
   * Calls environmentfields/ api for getting the env fields
   */
  const apiGetEnvFields = async () => {
    const result = await getEnvironmentFields();

    if (result && result.success) {
      const {
        fields, index0, delimiter, link_pattern_fields, sampleid_fields, link_pattern,
      } = result;
      const regex = new RegExp(delimiter, "g");
      const linkfields = link_pattern_fields.map((f) => f.split(regex)).flat();
      const sampleidfields = sampleid_fields.map((f) => f.split(regex)).flat();
      return {
        allFields: fields, index0, delimiter, linkPatternFields: linkfields, sampleIDFields: sampleidfields, link_pattern,
      };
    }

    return {
      allFields: [], index0: "", delimiter: "", linkPatternFields: [], sampleIDFields: [], link_pattern: "",
    };
  };

  /**
   * Calls the env_swabs/ api for locations list
   */
  const apiGetEnvSwabs = async () => {
    const newController = new AbortController();
    if (getPointsAbortController.current) {
      getPointsAbortController.current.abort();
    }
    getPointsAbortController.current = newController;

    const resp = await getEnvSwabs(searchValue.current, locationId, getPointsAbortController.current.signal);

    if (newController.signal.aborted) {
      return { cancelled: true };
    }

    if (resp && resp.success) {
      getPointsAbortController.current = null;
      return { success: resp.success, locations: resp.result };
    }
    getPointsAbortController.current = null;
    return { success: false };
  };

  /**
   * Checks if the env_swabs/ locations list has array values in locations and
   * use it's value for sample id fields, extra field values and options
   * @param {*} location Location data
   * @param {*} linkFields link pattern fields array
   * @param {*} extras extra fields array
   */
  const getFieldData = (location, linkFields, extras) => {
    const samplefields = pick(location, [...linkFields, ...extras]);
    const sampleidvalues = {};
    const extravalues = {};
    const extraoptions = {};
    Object.entries(samplefields).forEach(([fieldname, value]) => {
      if (Array.isArray(value)) {
        const val = value.length > 0 ? value[0] : "";
        sampleidvalues[fieldname] = val;
        if (extras.includes(fieldname) && value.length > 0) {
          if (value.length === 1) extravalues[fieldname] = val;
          extraoptions[fieldname] = value;
        }
      } else {
        sampleidvalues[fieldname] = value;
      }
    });
    return {
      sample_id_fields: sampleidvalues,
      extravalues,
      extraoptions,
    };
  };

  /**
   * Get locations extra field assigned values, extra field dropdown options
   * and test list using the location id
   */
  const getExtraFieldData = useCallback((type, location_id) => {
    if (type === ExtraFieldValueType.VALUES) {
      return extraFieldValues.get(location_id) ?? {};
    }
    if (type === ExtraFieldValueType.OPTIONS) {
      return extraFieldOptions.get(location_id) ?? {};
    }
    if (type === ExtraFieldValueType.TEST_LIST) {
      return testListFromSSAutoFill.get(location_id) ?? null;
    }
    return {};
  }, [extraFieldValues, extraFieldOptions, testListFromSSAutoFill]);

  /**
   * Set extra fields values, extra field options and
   * test list using location id
   */
  const updateExtraField = useCallback((type, location_id, updatedValue) => {
    if (type === ExtraFieldValueType.VALUES) {
      setExtraFieldValues((state) => {
        const copy = new Map(state);
        copy.set(location_id, updatedValue);
        return copy;
      });
    }
    if (type === ExtraFieldValueType.OPTIONS) {
      setExtraFieldOptions((state) => {
        const copy = new Map(state);
        copy.set(location_id, updatedValue);
        return copy;
      });
    }
    if (type === ExtraFieldValueType.TEST_LIST) {
      setTestListFromSSAutoFill((state) => {
        const copy = new Map(state);
        copy.set(location_id, updatedValue);
        return copy;
      });
    }
  }, []);

  /**
   * Fetch locations from env_swabs/ and sample id fields from environmentfields/ api,
   * update extra fields
   */
  const fetchLocationEnvData = async () => {
    const envFields = environmentFields;

    setSelectLocationFormLoader(true);
    const envSwabs = await apiGetEnvSwabs();
    if (!envSwabs.success) {
      setSelectLocationFormLoader(false);
      return;
    }

    // Check for extra fields
    const extras = [];
    const extrafieldnames = [];
    envFields.allFields.forEach((field) => {
      if (envFields.sampleIDFields.includes(field.json_field) && !envFields.linkPatternFields.includes(field.json_field)) {
        extras.push({ json_field: field.json_field, title_field: field.title_field });
        extrafieldnames.push(field.json_field);
      }
    });

    // Arrange linked fields in link pattern order
    const fieldMap = new Map(envFields.allFields.map((field) => [field.json_field, { json_field: field.json_field, title_field: field.title_field }]));
    const linkFields = envFields.linkPatternFields.map((field_name) => fieldMap.get(field_name));

    const sampleIDCols = envFields.allFields.filter((field) => envFields.sampleIDFields.includes(field.json_field));

    setSampleIDFields(sampleIDCols);
    setExtraFields(extras);
    setLinkPatternFields(linkFields);
    setLinkPatternDelimiter(new RegExp(envFields.delimiter, "g"));

    // Update extra fields values using array values coming from env_swabs/ api
    const updatedExtraValues = [];
    const updatedExtraOptions = [];
    const arr = envSwabs.locations.map((item) => {
      const { sample_id_fields, extravalues, extraoptions } = getFieldData(item, envFields.linkPatternFields, extrafieldnames);
      if (!isEmpty(extravalues)) {
        updatedExtraValues.push([item.location_id, extravalues]);
      }
      if (!isEmpty(extraoptions)) {
        updatedExtraOptions.push([item.location_id, extraoptions]);
      }
      return {
        ...item,
        ...sample_id_fields,
      };
    });
    setLocationData(arr);
    setExtraFieldValues(new Map(updatedExtraValues));
    setExtraFieldOptions(new Map(updatedExtraOptions));
    setSelectLocationFormLoader(false);
  };

  /**
   * Calls environmentfields/ api
   */
  useEffect(() => {
    (async () => {
      const envFields = await apiGetEnvFields();
      setEnvironmentFields(envFields);
    })();
  }, []);

  /**
   * Calls fetchLocationEnvData() initially
   */
  useEffect(() => {
    (async () => {
      if (environmentFields && environmentFields.allFields.length > 0) {
        await fetchLocationEnvData();
      }
    })();
  }, [environmentFields]); // eslint-disable-line

  /**
   * Set event date and frequency states for calendar for edit
   */
  const setEventDatesForEdit = (dates = [], frequency = "weekly") => {
    const dateList = dates.map((d) => moment(d).format("MM/DD/YYYY"));
    setSelectedType("recurring");

    if (frequency === "weekly") {
      setSelectedRecurringType("weekly");
      const start = dateList.at(0); // Let the first recurrence date as start
      const end = dateList.at(-1); // Let the last recurrence date as end
      const weeks = dateList.map((d) => moment(d, "MM/DD/YYYY").format("dddd").toLowerCase());
      setStartDate(start);
      setEndDate(end);
      setWeekDays(weeks);
      setSelectedDayCheckbox(Array.from(new Set(weeks)));
    }

    if (frequency === "monthly") {
      setSelectedRecurringType("monthly");
      const start = dateList.at(0); // Let the first recurrence date as start
      const end = dateList.at(-1); // Let the last recurrence date as end
      const days = dateList.map((d) => moment(d, "MM/DD/YYYY").date().toString().toLowerCase());
      setStartDate(start);
      setEndDate(end);
      setDayOfMonth(days);
      setSelectedDayCheckbox(Array.from(new Set(days)));
    }

    if (frequency === "custom") {
      setSelectedRecurringType("custom");
      setSelectedCustomDatesList(dateList);
    }

    if (frequency === "one-time") {
      setSelectedType("one-time");
      setOneTimeDate(dateList[0]);
    }
  };

  /**
   * When starting edit, set initial state for editing
   * Updating extra field value from sample_id_field of selected locations
   */
  useEffect(() => {
    if (isEditing && !isEmpty(editingData) && locationData.length > 0 && linkPatternDelimiter && sampleIDFields.length > 0) {
      const markSampleLocations = new Map();
      const extras = extraFields.map((e) => e.json_field);
      const samples = editingData.samples_list.map((sample) => {
        const splitted = sample.sample_id.split(linkPatternDelimiter);

        // using sample id fields
        const sampleidfields = {};
        sampleIDFields.forEach((e, i) => {
          sampleidfields[e.json_field] = splitted[i] ?? null;
        });

        const test_list = sample.test_list.map((t) => t.Test);
        const sampleData = {
          sample_id_fields: {
            ...sampleidfields,
          },
          test_list,
          location_id: sample.location_id,
        };

        // Setting extra field values using data from api
        const extravalues = pick(sampleData.sample_id_fields, extras);
        updateExtraField(ExtraFieldValueType.VALUES, sample.location_id, extravalues);

        // Setting selected location data
        markSampleLocations.set(sample.location_id, sampleData);
        return sampleData;
      });
      setMarkedLocations(markSampleLocations);

      setEventDatesForEdit(editingData.event_dates, editingData.event_frequency);
      setCurrentEventFreq({ event_dates: editingData.event_dates, event_frequency: editingData.event_frequency });
      setNewSamplingEventData({
        samples,
        schedule: {
          dates: editingData.event_dates,
          frequency: editingData.event_frequency,
        },
        event_name: editingData.submission_name,
        submission_id: editingData.submission_id,
        event_id: editingData.event_id,
        scheduled_from: editingData.scheduled_from,
        date_edit: false,
        po: "",
      });
    }
  }, [isEditing, editingData, locationData, sampleIDFields, extraFields, linkPatternFields, linkPatternDelimiter]); // eslint-disable-line

  /**
   * Set initial data when location id passed to modal
   * Set initial data for sampling modal when opened in Reports tab
   */
  useEffect(() => {
    if (locationId && locationData.length > 0 && linkPatternFields.length > 0) {
      const location = locationData[0];
      const linkFields = linkPatternFields.map((field) => field.json_field);
      const extras = extraFields.map((e) => e.json_field);

      // Setting extra field values and options if exists in env_swabs/ api
      const { sample_id_fields, extravalues, extraoptions } = getFieldData(location, linkFields, extras);
      if (!isEmpty(extravalues)) {
        updateExtraField(ExtraFieldValueType.VALUES, locationId, extravalues);
      }
      if (!isEmpty(extraoptions)) {
        updateExtraField(ExtraFieldValueType.OPTIONS, locationId, extraoptions);
      }
      const sampleData = {
        sample_id_fields,
        test_list: location.test_list,
        location_id: locationId,
      };
      const map = new Map();
      map.set(locationId, sampleData);
      setMarkedLocations(map);
    }
  }, [locationId, locationData, extraFields, linkPatternFields, updateExtraField]);

  /**
   * checks if passed dates list has dates older than today
   * @param {Date[]} datelist Dates
   */
  const hasOlderDate = (datelist) => {
    let hasOlder = false;
    datelist.forEach((date) => {
      const selectedDate = moment(date);
      const today = moment();
      if (selectedDate.isBefore(today, "day")) {
        hasOlder = true;
      }
    });
    return hasOlder;
  };

  /**
   * Disable next button
   * @returns {status: boolean, reason: string}
   */
  const disableNext = () => {
    switch (selectedTab) {
      case "Select Locations": return {
        status: markedLocations.size === 0 || locationCheckboxLoading !== null,
        reason: "Please select atleast one location",
      };
      case "Add/Edit Tests": {
        if (markedLocations.size === 0) {
          return { status: true, reason: "Please select atleast one location" };
        }
        const isTestAvailable = Array.from(markedLocations).map(([, value]) => value.test_list.length > 0);
        return {
          status: isTestAvailable.includes(false),
          reason: "Test list cannot be empty in selected samples",
        };
      }
      case "Select Frequency": {
        const hasOlder = hasOlderDate(newSamplingEventData.schedule.dates ?? []);
        if (hasOlder) {
          return {
            status: hasOlder,
            reason: "Please remove older dates",
          };
        }
        return {
          status: newSamplingEventData.schedule.dates.length === 0 || newSamplingEventData.schedule.frequency === "",
          reason: "Please select valid date and frequency",
        };
      }
      case "Summary": {
        const isEmptyName = newSamplingEventData.event_name.trim() === "";
        const hasCrossedMaxLength = newSamplingEventData.event_name.length > SAMPLE_SUBMISSION_MAXLENGTH;
        let reason = "";
        if (isEmptyName) reason = "Empty sampling event name not allowed";
        if (hasCrossedMaxLength) reason = `Submission name can have maximum ${SAMPLE_SUBMISSION_MAXLENGTH} characters`;
        return {
          status: !allowEmptySubmissionName && (isEmptyName || hasCrossedMaxLength),
          reason,
        };
      }
      default: return false;
    }
  };

  /**
   * Disable add to calendar button
   */
  const disableAddToCalendar = () => newSamplingEventData.schedule.dates.length === 0 || newSamplingEventData.schedule.frequency === "";

  /**
   * Checks if frequency has changed when editing
   * @param {*} currentFreq Current frequency
   * @param {*} newFreq New Frequency
   * @returns Boolean
   */
  const hasFrequencyChanged = (currentFreq, newFreq) => {
    if (currentFreq.event_frequency !== newFreq.event_frequency) {
      return true;
    }
    const equal = isEqual(sortBy(currentFreq.event_dates), sortBy(newFreq.event_dates));
    return !equal;
  };

  /**
   * Handle submit form
   */
  const handleSubmit = async () => {
    const samples = [];
    markedLocations.forEach((value) => {
      samples.push(value);
    });

    if (isEditing) {
      const newEventData = { ...newSamplingEventData, samples };
      setNewSamplingEventData(newEventData);
      setEditSchedulingPayload(newEventData);
      const changed = hasFrequencyChanged(
        currentEventFreq,
        { event_dates: newEventData.schedule.dates, event_frequency: newEventData.schedule.frequency },
      );
      setHasEditEventFrequencyChanged(changed);
      onConfirmEdit();
    } else {
      setIsSubmitLoading(true);

      const newEventData = { ...newSamplingEventData, samples };
      setNewSamplingEventData(newEventData);
      delete newEventData.scheduled_from;

      const response = await postEnvSchedule(newEventData);
      if (response.success) {
        onCancel();
        toast.success("Sampling Plan has been created");
      } else {
        toast.error(response.message);
      }
      setIsSubmitLoading(false);
    }
  };

  /**
   * Update frequency state and format for api payload
   * @param {Date[]} dates Dates
   * @param {"one-time" | "weekly" | "monthly" | "custom"} frequency Frequency
   */
  const updateSamplingSchedule = (dates, frequency) => {
    const updated = {
      ...newSamplingEventData,
      schedule: {
        dates: dates.map((d) => moment(d, "MM/DD/YYYY").format("YYYY-MM-DD")),
        frequency,
      },
    };
    setNewSamplingEventData(updated);
  };

  /**
   * Render form based on tabs
   * @param {string} selected Selected tab
   */
  const renderTabbedContent = (selected) => {
    switch (selected) {
      case "Select Locations": return (
        <SelectLocationForm
          fetchLocationEnvData={fetchLocationEnvData}
          searchValueRef={searchValue}
          markedLocations={markedLocations}
          setMarkedLocations={setMarkedLocations}
          locationData={locationData}
          extraFields={extraFields}
          linkPatternFields={linkPatternFields}
          showSearchbar={showSearchbar}
          selectLocationFormLoader={selectLocationFormLoader}
          isEditing={isEditing}
          locationId={locationId}
          getExtraFieldData={getExtraFieldData}
          updateExtraField={updateExtraField}
          locationCheckboxLoading={locationCheckboxLoading}
          setLocationCheckboxLoading={setLocationCheckboxLoading}
        />
      );
      case "Add/Edit Tests": return (
        <AddEditTestsForm
          markedLocations={markedLocations}
          setMarkedLocations={setMarkedLocations}
          isEditing={isEditing}
          extraFields={extraFields}
          linkPatternFields={linkPatternFields}
        />
      );
      case "Select Frequency": return (
        <SetFrequenceForm
          updateSamplingData={updateSamplingSchedule}
          oneTimeDate={oneTimeDate}
          setOneTimeDate={setOneTimeDate}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          selectedRecurringType={selectedRecurringType}
          setSelectedRecurringType={setSelectedRecurringType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          weekDays={weekDays}
          setWeekDays={setWeekDays}
          dayOfMonth={dayOfMonth}
          setDayOfMonth={setDayOfMonth}
          selectedCustomDatesList={selectedCustomDatesList}
          setSelectedCustomDatesList={setSelectedCustomDatesList}
          selectedDay={selectedDayCheckbox}
          setSelectedDay={setSelectedDayCheckbox}
        />
      );
      case "Summary": return (
        <SummaryForm
          newSamplingEventData={newSamplingEventData}
          setNewSamplingEventData={setNewSamplingEventData}
          markedLocations={markedLocations}
          linkPatternFields={linkPatternFields}
          sampleIDFields={sampleIDFields}
          hideSubmissionInput={hideSubmissionInput}
        />
      );
      default: return <></>;
    }
  };

  return (
    <Modal
      open={open}
      closable={false}
      centered
      footer={null}
      title={null}
      onCancel={onCancel}
      width="936px"
      closeIcon={<CloseIcon />}
      className="CreateSamplingEventModal"
      destroyOnClose
      maskClosable={false}
    >
      <div className="CreateSamplingEventModal__Header">
        <h4 className="CreateSamplingEventModal__HeaderTitle">{modalTitle}</h4>
        <button type="button" className="CreateSamplingEventModal__CloseButton" onClick={onCancel}>
          <CloseIcon />
        </button>
      </div>
      <div className="CreateSamplingEventModal__Content">
        {tabs && (
          <CreateSamplingEventModalTabs
            tabs={tabs}
            selectedTab={selectedTab}
            onTabChange={onTabChange}
            disableSwitchFromTab={disableSwitchFromTab}
          />
        )}
        {tabs && renderTabbedContent(selectedTab)}
        {!tabs && (
          <FrequencyForm
            updateSamplingData={updateSamplingSchedule}
            selectedRecurringType={selectedRecurringType}
            setSelectedRecurringType={setSelectedRecurringType}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            weekDays={weekDays}
            setWeekDays={setWeekDays}
            dayOfMonth={dayOfMonth}
            setDayOfMonth={setDayOfMonth}
            selectedCustomDatesList={selectedCustomDatesList}
            setSelectedCustomDatesList={setSelectedCustomDatesList}
          />
        )}
      </div>
      <CreateSamplingEventModalFooter
        tabs={tabs}
        selectedTab={selectedTab}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        onTabChange={onTabChange}
        disableNext={disableNext()}
        disableAddToCalendar={disableAddToCalendar()}
        isSubmitLoading={isSubmitLoading}
      />
    </Modal>
  );
}

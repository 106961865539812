import { sortBy, pick } from "lodash";
import moment from "moment";

import { COMPOSITE_FIELD_DELIMITER, PRODUCT_NAME_FIELDS_SET } from "./SubmissionForm/Constant";

import { convertUtcToLocal } from "../Common/utils/dateUtils";

export const MAX_PDF_CHAR_SAMPLE_ID = 1000;

/**
   * Sort selected fields so linkReportField and product name are at the top
   * @param {Array} selectedFieldsArr unsorted selected fields array
   */
export const getSortedFields = (fieldsArr = [], linkedFields = [], type = "product") => sortBy(// eslint-disable-line import/prefer-default-export
  fieldsArr,
  (json_field) => {
    /** If product, linkedFields already sorted in the order it is, need to sort the remaining fields so product name is first */
    let priority;
    const linkFieldIdx = linkedFields.indexOf(json_field);
    if (linkFieldIdx > -1) {
      priority = linkFieldIdx; // maintain linked fields order by assigning priority by index
    } else if (type === "product" && PRODUCT_NAME_FIELDS_SET.has(json_field)) { // product name has priority after all linked fields
      priority = linkedFields.length;
    } else {
      priority = linkedFields.length + 1; // lowest priority (after linked fields + product name).
    }
    return priority;
  },
);

/**
 * Limit no. of characters in text
 * @param {String} text Original text
 * @param {Number} maxLength maximum length
 * @returns trimmed text
 */
export const limitTextLength = (text, maxLength) => {
  if (!text) return "";
  if (!maxLength) return text;
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

/**
 * Formats the date to render
 * @param {*} date date string
 * @returns formatted date
 */
export const getFormattedDetailsDate = (date) => {
  if (!date || !date?.trim()) return "";
  return moment(new Date(convertUtcToLocal(date))).format("DD MMM YYYY | hh:mm a");
};

/**
 * Create sub sample id by replacing placeholder value
 * @param {string} sampleId sample id with placeholder
 * @param {object} fields fields to replace
 * @param {number} index index of splitted value
 * @returns sub sample id
 */
const createSubSampleId = (sampleId, fields, index) => {
  let id = sampleId;
  Object.keys(fields).forEach((key) => {
    const value = fields[key]?.split(COMPOSITE_FIELD_DELIMITER)[index];
    id = id.replace(`#${key}#`, value);
  });
  return id;
};

/**
 * split samples into different sample by composite sample wise
 * @param {object} sample sample
 * @param {number} counter counter
 * @returns sample splitted composite sample wise
 */
export const splitSamples = (sample, counter) => {
  const totalSamples = sample.num_composite_samples;
  if (sample.composite_fieldnames.length === 0) {
    return Array(totalSamples).fill({
      ...sample,
      sub_sample_id: sample.sample_id,
      sample_id_counter: counter,
    });
  }

  let sampleId = sample.sample_id;
  sample.composite_fieldnames.forEach((field) => {
    const value = sample[field];
    sampleId = sampleId.replace(value, `#${field}#`);
  });

  const samples = [];
  [...Array(totalSamples)].forEach((_, index) => {
    const fields = pick(sample, sample.composite_fieldnames);
    const sampleCopy = {
      ...sample,
      sub_sample_id: createSubSampleId(sampleId, fields, index),
      sample_id_counter: counter,
    };
    samples.push(sampleCopy);
  });
  return samples;
};

import React from "react";
import moment from "moment";
import { ReactComponent as StatusDotIcon } from "../../../../../../assets/images/environment/statusPingDot.svg";
import ReportViewImage from "./ReportViewImage";
import { PINS_SPEC_STYLE_MAP } from "../../Constant";

export default function ReportViewTitle({
  pin,
  imagePath,
  curReportInfo,
  setImagePath,
}) {
  const {
    color, display_name, box_shadow_color,
  } = PINS_SPEC_STYLE_MAP[pin.specs_flag] ?? PINS_SPEC_STYLE_MAP.invalid;

  return (
    <div className="env-analytics-report-view-section-title-container">
      <ReportViewImage
        imagePath={imagePath}
        locationID={pin.location_id}
        curReportInfo={curReportInfo}
        setImagePath={setImagePath}
      />
      <div className="env-analytics-report-view-section-title-info">
        <span className="env-analytics-report-view-section-title-info-header-title">
          {pin.swab_number}
        </span>
        <span className="env-analytics-report-view-section-title-info-header-date">
          {moment(pin.added_on).format("DD MMMM YYYY")}
        </span>
        <div className="env-analytics-report-view-section-title-info-header-status" style={{ backgroundColor: box_shadow_color }}>
          <StatusDotIcon width={25} height={25} fill={color} />
          <span style={{ color }}>
            {display_name}
          </span>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import PlusWhite from "../../../../../assets/images/product/template/PlusWhite.png";
import PlusBlue from "../../../../../assets/images/product/template/PlusBlue.png";
import "../styles/common/createTemplate.css";

export default function CreateTemplate(props) {
  const [icon, setIcon] = useState(PlusBlue);
  const { handleAddTemplateClick } = props;
  return (
    <div
      className="createTemplateContainer"
      onMouseEnter={() => {
        setIcon(PlusWhite);
      }}
      onMouseLeave={() => {
        setIcon(PlusBlue);
      }}
      onClick={handleAddTemplateClick}
    >
      <img
        src={icon}
        alt="plus icon"
      />
      <div>New Template</div>
    </div>
  );
}

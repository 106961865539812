import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

export default function TableHeader() {
  const styles = StyleSheet.create({
    tableHeader: {
      display: "flex",
      flexDirection: "column",
      // border: "1 solid #E1E1E1",
      // borderRadius: "6",
      // borderBottomLeftRadius: "0",
      // borderBottomRightRadius: "0",
      // borderBottom: "0",
    },
    tableHeaderRow: {
      display: "flex",
      flexDirection: "row",
      color: "#506375",
      textAlign: "center",
      minHeight: "32",
      padding: "0 75 6 75",
      borderLeft: "1 solid #E1E1E1",
      borderRight: "1 solid #E1E1E1",
    },
    rowItem: {
      display: "flex",
      flexDirection: "row",
      width: "25%", // if test method is there
      // width: "33%",
      height: "32",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#C4D2DF",
      borderRadius: "4px",
    },
    gap: {
      marginRight: "6",
    },

  });
  return (
    <View
      style={styles.tableHeaderRow}
      wrap={false}
      fixed
    >
      <View style={[styles.rowItem, styles.gap]}><Text>Test Method</Text></View>
      <View style={[styles.rowItem, styles.gap]}><Text>Test</Text></View>
      <View style={[styles.rowItem, styles.gap]}><Text>Specification</Text></View>
      <View style={styles.rowItem}><Text>Results</Text></View>
    </View>
  );
}

import React from "react";
import { ReactComponent as CheckMarkIcon } from "../../../../../assets/images/environment/checkMark.svg";
import { ReactComponent as StepCircleIcon } from "../../../../../assets/images/environment/stepCircle.svg";
import { ReactComponent as StepCircleNoFillLightBlue } from "../../../../../assets/images/environment/stepCircleNoFillLightBlue.svg";
import { ReactComponent as ErrorCicle } from "../../../../../assets/images/environment/redWhiteCircle.svg";
import { ReactComponent as ErrorExclamation } from "../../../../../assets/images/environment/exclamationMarkRedBg.svg";
import "./StepCircle.css";

export default function StepCircle(props) {
  const { number, status, openCreateEnvModalFromTab } = props;

  return (
    <div>
      {
        status === 3 ? (
          <>
            <ErrorCicle />
            <ErrorExclamation className="step-circle-error-excalmation" />
          </>
        ) : (
          <>
            { status !== 2 ? <StepCircleIcon className={status === 0 ? "step-circle-grey-out" : ""}><title>step circle</title></StepCircleIcon> : <StepCircleNoFillLightBlue />}
            { status !== 2 ? <p className={status === 0 ? "step-circle-inner-text step-circle-grey-out" : "step-circle-inner-text"}>{number}</p> : <CheckMarkIcon className="step-circle-check"><title>check mark icon</title></CheckMarkIcon>}
          </>
        )
      }
      <div className="step-circle-title">{`Upload ${number === 1 ? "Files" : "Map"}`}</div>
      {openCreateEnvModalFromTab && number === 1 && (<div className="step-circle-title-opt">Optional</div>)}
    </div>

  );
}

import React, { useState } from "react";
import "./DataFieldsEditModal.css";

export default function DataFieldsEditModal(props) {
  const {
    field, toggleShowEditModal, handleEdit, refVal,
  } = props;
  const [newField, setNewField] = useState(field);
  const handleHitEnter = (e) => {
    if (e.key === "Enter") {
      handleEdit(field, newField.trim());
    }
  };
  return (
    <div ref={refVal} className="editModal">
      <div className="editModalInputContainer">
        <input className="editModal_formInput" type="text" placeholder="Enter title" name="datafield" onKeyUp={(e) => handleHitEnter(e)} value={newField} onChange={(e) => setNewField(e.target.value)} />
      </div>
      <div className="editModalButtonContainer">
        <input type="button" value="Cancel" className="editModal_cancelButton" onClick={() => toggleShowEditModal()} />
        <input type="button" value="Save" className="editModal_saveButton" onClick={() => handleEdit(field, newField.trim())} />
      </div>

    </div>
  );
}

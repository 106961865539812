import { has, sortBy } from "lodash";

/**
   * Filter out display "0" fields. Sort linked fields to the beginning, maintain original order by assigning priority by index
   * @param {Array} link_report_fields ex: ["item", "best_by"]
   * @param {Array} fields ex: [{ "title_field": "Batch", "json_field": "lot", "display": "1", "unique": "1" }]
   * @returns {Array} filtered and sorted fields
   */
export const sortAndFilterProductReportFields = (link_report_fields, fields) => {
  const preprocessedFields = [];
  fields.forEach((elem, i) => {
    if (elem.display !== "0") { // Filter out display '0' fields
      preprocessedFields.push({
        ...elem,
        json_field: elem.json_field === "report_date" ? "received_date" : elem.json_field, // needed for older companies
        originalIdx: i, // Add the field's pre-sorting index to the object, used for reordering.
      });
    }
  });
  /** Sort linked fields to the front */
  return sortBy(
    preprocessedFields,
    ({ json_field }, i) => {
      let priority;
      const linkFieldIdx = link_report_fields.indexOf(json_field);
      if (linkFieldIdx > -1) {
        priority = linkFieldIdx;
      } else {
        priority = link_report_fields.length + i;
      }
      return priority;
    },
  );
};

/**
 *
 * @param {Object} currObj { json_file1: val1, json_field2: val2, other_field: val3, ...}
 * @param {Array} fields array of field info, linked fields sorted to the beginning [{json_field: "item", title: "Item"}, {json_field: "best_by", title: "Best By"}]
 * @param {Array} linkReportJsonFields ex: ["item", "best_by"]
 * @returns {object} { title_filed1: val1, title_filed2: val2 }
 */
export const multipleLinkReportFieldsObjToObj = (currObj, fields, linkReportJsonFields) => { // eslint-disable-line import/prefer-default-export
  const product = {};
  const linkReportFields = fields.slice(0, linkReportJsonFields.length);
  linkReportFields.forEach((field) => {
    const fieldKey = field.title_field;
    const jsonKey = field.json_field;
    if (jsonKey && has(currObj, jsonKey)) {
      product[fieldKey] = currObj[jsonKey];
    }
  });
  return product;
};

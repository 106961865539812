import React, { useState } from "react";
import LandingPage from "../CommonComponent/LandingPage";
import Onboarding_Art_Product from "../../../../assets/images/onboarding/Onboarding_Art_Product.png";
import OnboardingForm from "../CommonComponent/OnboardingForm/OnboardingForm";
import SuccessLandingPage from "../CommonComponent/SuccessLandingPage";

export default function ProductLandingPage() {
  const [displayForm, setDisplayForm] = useState(false);
  const [success, setSuccess] = useState("");

  if (displayForm) {
    return <OnboardingForm formStep={1} setDisplayForm={setDisplayForm} setSuccess={setSuccess} />;
  }

  if ((success === "Product") && !displayForm) {
    return (
      <SuccessLandingPage success={success} GroupPNG={Onboarding_Art_Product} />
    );
  }

  return (
    <LandingPage
      heading={(
        <>
          Product Testing
          <br />
          journey with us
        </>
        )}
      description={(
        <>
          Please setup your product sample submission
          <br />
          fields to enable submitting product samples.
        </>
        )}
      image={Onboarding_Art_Product}
      setDisplayForm={setDisplayForm}
    />
  );
}

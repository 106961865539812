import React, { useState } from "react";

import whiteMagnifyIcon from "../../../assets/images/white-magnifying-glass.png";

export default function SelectLocationFormSearch({
  searchValueRef,
  fetchLocationEnvData,
}) {
  const [search, setSearch] = useState(searchValueRef.current);

  const handleSearch = (value, triggerSearch = true) => {
    setSearch(value);
    if (triggerSearch || value.trim() === "") {
      searchValueRef.current = value;
      fetchLocationEnvData();
    }
  };

  return (
    <div className="SelectLocationForm__SearchBar" id="selectLocationFormSearchInputBar">
      <input
        className="searchInput"
        placeholder="Search"
        onChange={(e) => handleSearch(e.target.value, false)}
        value={search}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            handleSearch(event.target.value);
          }
        }}
      />
      <div
        className="SelectLocationForm__SearchBtn"
        onClick={() => handleSearch(search)}
      >
        <img
          className="SelectLocationForm__Img-whiteMagnifyIcon"
          src={whiteMagnifyIcon}
          alt="whiteMagnifyIcon"
        />
      </div>
    </div>
  );
}

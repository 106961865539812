import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { softHyphenate, calculateWidths } from "./pdfUtils";

// let prev = 0;
export default function TableRowSamples(props) {
  const styles = StyleSheet.create({
    row: {
      display: "flex",
      flexDirection: "row",
      color: "#505050",
      textAlign: "center",
      padding: "0 15 0 15",
      borderLeft: "1 solid #E1E1E1",
      borderRight: "1 solid #E1E1E1",
    },
    rowItem: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minHeight: "40",
      width: "15%",
      padding: "6",
      backgroundColor: "#F2F6FA",
      borderRadius: "4px",
    },
    rowItemSampleID: {
      width: "25%",
    },
    gap: {
      marginRight: "4",
    },
    rowGap: {
      paddingBottom: "4",
    },
    rowText: {
      display: "flex",
      flexDirection: "row",
      fontFamily: "Roboto-400",
      color: "#505050",
      fontSize: "10",
    },
    rowTextSampleID: {
      textAlign: "left",
    },
  });

  const { sample, isLast, uniqueTests } = props;

  /** Bottom border: 14 (padding) + 1 (border), only apply to row if it is the last in the table */
  const minPresence = isLast ? 15 : undefined;

  return (
    <View wrap={false} style={[styles.row, styles.rowGap]} minPresenceAhead={minPresence}>
      <View style={[styles.rowItem, styles.rowItemSampleID, styles.gap, { width: `${calculateWidths(uniqueTests).sampleWidth}%` }]}>
        <Text style={[styles.rowText, styles.rowTextSampleID]}>{softHyphenate(sample.sample_id)}</Text>
      </View>
      {uniqueTests.filter(({ selected }) => selected).map(({ test }, i, arr) => {
        const matchingTestResult = sample.test_results.find((result) => result.test === test);
        const isLastItem = i === arr.length - 1;
        return (
          <View key={i} style={[styles.rowItem, isLastItem ? null : styles.gap, { width: `${calculateWidths(uniqueTests).resultWidth}%` }]}>
            <Text style={styles.rowText}>
              {matchingTestResult ? softHyphenate(matchingTestResult.test_result) : "-"}
            </Text>
          </View>
        );
      })}
    </View>
  );
}

import React, { useEffect } from "react";
import "./BuilderConfirmSave.css";
import COASave from "./COASave";
import TemplateSave from "./TemplateSave";

export default function BuilderConfirmSave({
  handleSaveTemplate,
  toggleSaveModal,
  isCustomizingCoa,
  selectedTemplate,
  selectedReports,
  saveStatus,
  setSaveStatus,
  handleNavigation,
}) {
  /**
   * Set state back to default, navigate to coa list.
   */
  const handleSaveSuccess = () => {
    toggleSaveModal(true);
    setSaveStatus(null);
    handleNavigation(`${isCustomizingCoa ? "coa" : ""}builder`);
  };

  /**
   * If save was successful, wait 1 sec for the success
   * icon to show, then set state back to default
   * and navigate to coa list page.
   */
  useEffect(() => {
    if (saveStatus === "success") {
      setTimeout(handleSaveSuccess, 1000);
    }
  }, [saveStatus]); // eslint-disable-line
  return (
    <div className="BuilderConfirmSave__Container">
      <div className="BuilderConfirmSave__PopupWrapper">
        {isCustomizingCoa ? (
          <COASave
            handleSaveTemplate={handleSaveTemplate}
            toggleSaveModal={toggleSaveModal}
            selectedReports={selectedReports}
            selectedTemplate={selectedTemplate}
            setSaveStatus={setSaveStatus}
            saveStatus={saveStatus}

          />
        ) : (
          <TemplateSave
            handleSaveTemplate={handleSaveTemplate}
            selectedTemplate={selectedTemplate}
            toggleSaveModal={toggleSaveModal}
            setSaveStatus={setSaveStatus}
            saveStatus={saveStatus}
          />
        )}
      </div>
    </div>
  );
}

import React, {
  useEffect, useState, forwardRef, useRef,
} from "react";

import { AutoComplete, Input } from "antd";

import StyledTooltip from "../../../Common/UIComponents/StyledTooltip";
import MaxCharacterCount from "./MaxCharacterCount";

import { ReactComponent as DropdownArrow } from "../../../../assets/images/DropDownChevron.svg";
import { ReactComponent as ExclamationIcon } from "../../../../assets/images/sample-submission/exclamationIcon.svg";
import "./ErrorCheckingAutocomplete.css";

const ErrorCheckingAutocompleteInput = forwardRef(({
  label,
  labelDivClassName,
  inputID,
  value,
  suggestions, // list of strings
  invalidChars, // special chars are escaped
  invalidKeys, // special chars are not escaped
  onChange,
  onKeyDown,
  onErrorChange,
  onClickAway,
  onFocus,
  charLimit,
  letInputExceedCharLimit,
  handleSanitizeInputOnAutoFill,
  handleSelectOption,
  customClassName = "",
  customArrowClassName = "",
  customMaxCharClassName = "",
  customPopupClassName = "",
  placeholderText,
}, ref) => {
  const [inputErrorSet, setInputErrorSet] = useState(new Set());
  const [internalValue, setInternalValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
  const [showDropdown, setShowDropdown] = useState(false);
  const autocompleteRef = useRef();

  /** when input value changes from the parent, update value  */
  useEffect(() => {
    if (value !== undefined && value !== internalValue) {
      if (handleSanitizeInputOnAutoFill) {
        setInternalValue(handleSanitizeInputOnAutoFill(value));
      } else {
        setInternalValue(value);
      }
    }
  }, [value]); // eslint-disable-line

  /** when value changes, check if the value contains any delimiters or invalid characters  */
  useEffect(() => {
    if (ref !== undefined && ref !== null && (invalidChars && invalidChars.length > 0)) {
      const regex = new RegExp(invalidChars.join("|"), "g"); // global flag to find all matches and not just the first
      const invalidMatches = internalValue?.match(regex);
      if (invalidMatches) {
        setInputErrorSet(new Set(invalidMatches));
      } else {
        setInputErrorSet(new Set());
      }
    }
  }, [internalValue]); // eslint-disable-line

  /** when error array changes, call onErrorChange */
  useEffect(() => {
    if (onErrorChange) {
      onErrorChange(inputErrorSet);
    }
  }, [inputErrorSet.size]); // eslint-disable-line

  /**
   * Filter suggestions by input. If there is 1 exact match or no matches, display all suggestions.
   * @param {String} inputValue curr value in input
   */
  const handleFiltering = (inputValue) => {
    const newSuggestions = suggestions.filter((option) => option.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1);
    /** If no results, or there is one result that is an exact match to the input value, show all suggestions */
    if (!newSuggestions.length || (newSuggestions.length === 1 && newSuggestions[0].value === inputValue)) {
      setFilteredSuggestions(suggestions);
    } else {
      setFilteredSuggestions(newSuggestions);
    }
  };

  /**
   * When suggestions change, update filteredSuggestions
   */
  useEffect(() => {
    handleFiltering(internalValue);
  }, [suggestions]); // eslint-disable-line

  /** on change, update internal value, call onChange from props
   * @param {String} val
   */
  const onChangeInternal = (val) => {
    if (document.activeElement === autocompleteRef.current?.input && !showDropdown) {
      setShowDropdown(true);
    }
    setInternalValue(val);
    handleFiltering(val);

    if (onChange) {
      onChange(val);
    }
  };

  /** on focus, call onFocus from props, close the dropdown
   * @param {String} val
   */
  const onFocusInternal = () => {
    if (onFocus) {
      onFocus();
    }
    setShowDropdown(true);
  };

  /** on blur, update value in parent, close the dropdown
   * @param {String} val
   */
  const onBlurInternal = () => {
    if (onClickAway) {
      setInternalValue(internalValue.trim());
      onClickAway(internalValue.trim());
    }
    setShowDropdown(false);
  };

  /** On option click in autocomplete dropdown, set value, call handleSelectOption from props
   * @param {String} val value of autocomplete option
   */
  const handleSelectOptionInternal = (val) => {
    setInternalValue(val);

    if (handleSelectOption) {
      handleSelectOption(val);
    }
    setShowDropdown(false);
  };

  /** on key down in input, check if key is parentheses, if yes, don't let user input key
   * call onKeyDown from props
   * @param {Event} e KeyDown event
   */
  const onKeyDownInternal = (e) => {
    if (invalidKeys && invalidKeys.includes(e.key)) {
      e.preventDefault();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  /**
   * When clicking the arrow, focus/blur input and open/close dropdown
   */
  const onDropdownArrowClick = () => {
    if (autocompleteRef.current) {
      if (showDropdown) {
        autocompleteRef.current.blur();
      } else {
        autocompleteRef.current.focus();
        setShowDropdown(true);
      }
    }
  };

  /**
   * onClick of the input, if it is focused, show the dropdown
   */
  const onInputClick = () => {
    if (document.activeElement === autocompleteRef.current?.input && !showDropdown) {
      setShowDropdown(true);
    }
  };

  return (
    <>
      <div className={labelDivClassName}>
        <label htmlFor={inputID}>
          {label}
        </label>
        {inputErrorSet.size !== 0 && (
          <StyledTooltip
            title={(
              <div className="SampleSubmission__InvalidCharsTooltipTitle">
                <span>Invalid input:</span>
                {Array.from(inputErrorSet).map((char, i) => {
                  const charArray = char.split("");
                  return (
                    <li key={i} className="SampleSubmission__InvalidChar">
                      {
                        charArray.map((c, idx) => {
                          if (c === " ") {
                            return (<span key={idx} className="SampleSubmission__InvalidChar__SpaceHighlight">{"\u23B5"}</span>);
                          }
                          return (<span key={idx}>{c}</span>);
                        })
                      }
                    </li>
                  );
                })}
              </div>
            )}
            placement="right"
          >
            <ExclamationIcon width={13} height={13} />
          </StyledTooltip>
        )}
      </div>
      <div
        className="SampleDetailsAutocompleteInput"
      >
        <AutoComplete
          allowClear
          // filterOption={(inputValue, option) => option.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1}
          value={internalValue ?? ""}
          options={filteredSuggestions}
          open={showDropdown}
          onSelect={handleSelectOptionInternal}
          onSearch={onChangeInternal}
          onFocus={onFocusInternal}
          onBlur={onBlurInternal}
          maxLength={charLimit && !letInputExceedCharLimit ? charLimit : undefined}
          onKeyDown={onKeyDownInternal}
          notFoundContent={suggestions.length > 0 ? "Not Found" : undefined}
          className={customClassName}
          popupClassName={`SampleSubmissionDropdown ${customPopupClassName}`}
        >
          <Input
            ref={(component) => {
              ref(component?.input);
              autocompleteRef.current = component;
            }}
            placeholder={placeholderText}
            onClick={onInputClick}
            className={inputErrorSet.size ? "sample-submission-input-error" : ""}
          />
        </AutoComplete>
        {suggestions.length > 0 && (
          <DropdownArrow
            className={`AntDAutocomplete__DropdownArrow ${customArrowClassName}`}
            transform={`rotate(${showDropdown ? "180 0 -0.5" : 0})`}
            onClick={onDropdownArrowClick}
            onMouseDown={(e) => e.preventDefault()}
          />
        )}
        {charLimit && (
          <MaxCharacterCount
            customMaxCharClassName={customMaxCharClassName}
            charCount={internalValue.length}
            charLimit={charLimit}
          />
        )}
      </div>

    </>
  );
});

export default ErrorCheckingAutocompleteInput;

import React from "react";
import {
  Form, Input, Radio, Select,
} from "antd";
import { ReactComponent as Info } from "../../../../../assets/images/onboarding/Info.svg";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import EditableFields from "../../../../Onboarding/OnboardingForm/SubForms/FieldsForm/Components/EditableFields";
import StyledRadioGroup from "../../../../Common/UIComponents/StyledRadioGroup";

export default function OnboardingEnvironmentForm({
  layout, form, FORM_NAME, handleInitialValues, getJsonFieldName, handleEditableFields, setSubmitBtnValidation, envDropdownOptions,
}) {
  return (
    <Form
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...layout}
      form={form}
      requiredMark={false}
      name={FORM_NAME}
      validateTrigger={["onChange", "onBlur"]}
      initialValues={handleInitialValues("environment_info")}
      className="Onboarding__SampleSubmission__Form MyForm OnboardingForm"
    >
      <div className="productDetail">
        <div className="OnboardingFormTitle">Environment Details</div>
        <Form.Item>
          <div>
            <Form.Item
              name="environment_supplies_needed"
              label={<span className="MyForm__SectionLabel FieldsForm__LabelText">Do you need supplies for environment testing?</span>}
              rules={[
                {
                  required: true, message: "Please provide an answer",
                },
              ]}
            >
              <StyledRadioGroup className="Onboarding__RadioBtn">
                <Radio value="Yes"> Yes </Radio>
                <Radio value="No"> No </Radio>
              </StyledRadioGroup>
            </Form.Item>
            <div className="OnboardingContainer_Divider" />
            <Form.Item label={<span className="MyForm__SectionLabel FieldsForm__LabelText">Please enter the details you would like to include while submitting Environment samples</span>}>
              <Form.Item label={(
                <span className="MyForm__SectionLabel Onboarding__LabelText_Fields">
                  Environment Sample Submission Form Fields
                  <StyledTooltip
                    title="Info to track the samples you send to the lab."
                    className="MyForm__Section_Tooltip"
                  >
                    <Info />
                  </StyledTooltip>
                </span>
            )}
              >
                <Form.Item
                  name="sample_type"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="swab_number"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="zone"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="section"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <EditableFields getJsonFieldName={getJsonFieldName} onGetData={handleEditableFields} fieldLimit={2} />
                <div className="OnboardingContainer_Divider" />
                <Form.Item
                  name="environment_link"
                  label={<span className="MyForm__SectionLabel FieldsForm__LabelText">Please select the field(s) associated with the specification for your environment:</span>}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    options={envDropdownOptions}
                    placeholder="Select field(s)"
                    onChange={() => {
                      const fieldValue = form.getFieldValue("environment_link");
                      const editablefielsdValue = form.getFieldValue("editable_fields").filter((field) => field?.json_field === "");
                      if (fieldValue.length === 0 || editablefielsdValue.length > 0) {
                        setSubmitBtnValidation(false);
                      } else {
                        setSubmitBtnValidation(true);
                      }
                    }}
                  />
                </Form.Item>

              </Form.Item>
            </Form.Item>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
}

// TestPill.jsx
import React from "react";

import { Tooltip } from "antd";

import { ReactComponent as CloseIcon } from "../../../../../assets/images/environment/closeBlue.svg";

import "./GraphTestPill.css";

const GraphTestPill = ({
  testName, color, isSelected, onClick, icon, borderColor,
}) => (
  <Tooltip
    overlayClassName="EnvAnalyticsGraph__GraphPill__Tooltip"
    arrow={false}
    placement="topLeft"
    title={(
      <span className="EnvAnalyticsGraph__GraphPill__Tooltip__Text">
        <span key="test" className="EnvAnalyticsGraph__GraphPill__Tooltip__ValueText">{testName}</span>
      </span>
  )}
  >
    <div
      className={`EnvAnalyticsGraph__TestPill ${isSelected ? "selected" : ""}`}
      style={{ backgroundColor: color, border: `${isSelected ? `1px solid ${borderColor}` : "unset"}` }}
      onClick={() => onClick(testName)}
    >
      {typeof icon === "string" ? (
        <img src={icon} alt="Test" className="analyticsGraphPillImage" />
      ) : (
        <icon className="analyticsGraphPillImage" />
      )}
      <span>{testName}</span>
      {isSelected && <CloseIcon height={24} width={24} />}
    </div>
  </Tooltip>
);

export default React.memo(GraphTestPill);

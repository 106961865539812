import React from "react";
import { ReactComponent as Success } from "../../../../../../assets/images/product/template/saveCheckmarkIcon.svg";
import "./BuilderSaveSuccess.css";

export default function BuilderSaveSuccess({
  text,
  textWidth = "146px",
}) {
  return (
    <div className="BuilderSaveSuccess">
      <div className="BuilderSave__SuccessIcon__Container">
        <Success className="BuilderSave__SuccessIcon" />
      </div>
      <span style={{ width: textWidth }}>{text}</span>
    </div>
  );
}

import React, { Component } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { checkPinIsPinned } from "../pinHelper";
import { QUADRANT_STYLE_MAP } from "../Constant";
import MapTools from "./MapTools";
import ZoomDisplay from "./ZoomDisplay";
// import PinsDroppableArea from "./PinsDroppableArea";
import PinsVisibleArea from "./PinsVisibleArea";
import { calculateTransformOnImageLoad } from "./mapHelper";
import FullMapSwitch from "./FullMapSwitch";
import MapBlurb from "./MapBlurb";
import RemoveSwabBtn from "./RemoveSwabBtn";
import EnvConfirmModal from "../EnvConfirmModal";

export default class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageWidth: null,
      imageHeight: null,
      initialScale: null,
      initialX: null,
      initialY: null,
      minScale: null,
      currentZoom: 100,
      showDeletePinModal: false,
      deletedLocationID: "",
    };
    this.containerRef = React.createRef();
    this.imageRef = React.createRef();
  }

  setDeletedLocationID = (locationID) => {
    this.setState({ deletedLocationID: locationID });
  }

  updateZoom = (newRatio) => {
    this.setState({ currentZoom: Math.trunc((newRatio) * 100) });
    // console.log("zoom", Math.trunc(newRatio * 100));
  }

  updateMinScale =(minScale) => {
    const { imageWidth, imageHeight } = this.state;
    const { width: wrapperWidth, height: wrapperHeight } = this.containerRef.current.getBoundingClientRect();
    this.setState({
      minScale, initialScale: minScale, initialX: (wrapperWidth - imageWidth * minScale) / 2, initialY: (wrapperHeight - imageHeight * minScale) / 2,
    });
  }

  whenImageLoad = (setTransform) => {
    const { imageWidth: currentImageWidth, imageHeight: currentImageHeight } = this.state;
    const { selectedLocationID } = this.props;
    const { imageInfo, transformInfo } = calculateTransformOnImageLoad(this.containerRef, this.imageRef, currentImageWidth, currentImageHeight, selectedLocationID);
    if (imageInfo) {
      this.setState({ imageWidth: imageInfo.imageWidth, imageHeight: imageInfo.imageHeight });
    }
    if (transformInfo) {
      const {
        initialX, initialY, minScale, scale,
      } = transformInfo;
      setTransform(initialX, initialY, scale);
      this.setState({
        initialX, initialY, minScale, initialScale: scale,
      });
    }
  }

  onZoom = (transformRef) => {
    // console.log("zoom");
    const { initialScale } = this.state;
    // console.log(stats);
    const { scale } = transformRef.state;
    this.setState({ currentZoom: Math.trunc((scale / initialScale) * 100) });
    // console.log("zoom");
  }

  getInitialScale = () => {
    const {
      initialScale, currentScale,
    } = this.state;
    const {
      quadrantSelected,
    } = this.props;
    let result = 1;
    if (initialScale) {
      if (quadrantSelected === -1) {
        // console.log("reset scale");
        result = initialScale; // zoom out to full map
      } else if (quadrantSelected === -1) {
        // console.log("keep scale");
        result = currentScale; // keep user at zoom they are at
      } else {
        // console.log("quad scale");
        result = initialScale * 2; // zoom to quadrant
      }
      // console.log("initialscale", result);
    }
    return result;
  }

  getInitialPos = (initialScale) => {
    const {
      currentX, currentY, initialX, initialY, imageWidth, imageHeight,
    } = this.state;
    const {
      quadrantSelected,
    } = this.props;
    const initialPos = { x: null, y: null };
    if (quadrantSelected === -1) {
      // console.log("keep pos");
      initialPos.x = currentX; // keep user where they are at
      initialPos.y = currentY;
    } else if (quadrantSelected === -1 || quadrantSelected === 0) {
      // console.log("dont keep or quad 0");
      initialPos.x = initialX; // reset to initial position
      initialPos.y = initialY;
    } else if (quadrantSelected === 3) {
      // console.log("quad 3");
      initialPos.x = initialX; // pan to correct quadrant
      initialPos.y = initialY - imageHeight * initialScale;
    } else if (quadrantSelected === 1) {
      // console.log("quad 1");
      initialPos.x = initialX - imageWidth * initialScale; // pan to correct quadrant
      initialPos.y = initialY;
    } else {
      // console.log("quad 2");
      initialPos.x = initialX - imageWidth * initialScale;
      initialPos.y = initialY - imageHeight * initialScale;
    }
    return initialPos;
  }

  onInit = (transformRef) => {
    // console.log("init");
    const { scale } = transformRef.state;
    const { initialScale } = this.state;
    if (initialScale) {
      this.setState({ currentZoom: Math.trunc((scale / initialScale) * 100) });
    }
  }

  handleRemoveSwab = async () => {
    const { selectedLocationID, updatePinInfo } = this.props;
    const locationID = selectedLocationID;
    const success = await updatePinInfo("removePin", selectedLocationID);
    if (success) {
      this.setDeletedLocationID(locationID);
    }
    this.setState({ showDeletePinModal: false });
  }

  render() {
    const {
      imageWidth,
      imageHeight,
      initialScale,
      initialX,
      initialY,
      minScale,
      currentZoom,
      showDeletePinModal,
      deletedLocationID,
    } = this.state;

    const {
      imageSrc,
      selectedLocationID,
      transformWrapperRef,
      indexOfQuadrantHoverOver,
      pinMap,
      listViewDisplay,
      displayTooltipLocationID,
      isAddingNewSwab,
      isPinningSwab,
      updatePinInfo,
      endPinningSwab,
      quadrantSelected,
      draggingOverMap,
      handleSelectQuadrant,
      setSelectedLocationID,
      setDisplayTooltipLocationID,
      toggleListViewDisplay,
      isPinningNewSwab,
      togglePinningNewSwab,
      mapKey,
      isClickingQuadrant,
      clickQuadrant,
      displayNoResultFound,
      editMapTitleToggle,
      editingMapTitle,
      index0,
    } = this.props;

    // const borderColorArr = ["transparent", "rgba(229, 151, 242, 0.4)", "rgba(0, 147, 167, 0.4)", "rgba(138, 28, 255, 0.4)", "rgba(167, 187, 42, 0.4)"];

    const border = quadrantSelected > -1 ? `3px solid rgba(${QUADRANT_STYLE_MAP[quadrantSelected].rgb_values}, 0.4)` : "3px solid transparent";

    return (
      <>
        <FullMapSwitch
          toggleListViewDisplay={toggleListViewDisplay}
          listViewDisplay={listViewDisplay}
          editMapTitleToggle={editMapTitleToggle}
          editingMapTitle={editingMapTitle}
        />
        <MapBlurb
          quadrantSelected={quadrantSelected}
          selectedLocationID={selectedLocationID}
          listViewDisplay={listViewDisplay}
          pinMap={pinMap}
          deletedLocationID={deletedLocationID}
          setDeletedLocationID={this.setDeletedLocationID}
        />
        <div
          id="borderedMapContainer"
          style={{ border }}
        >
          <div
            id="unborderedMapContainer"
            ref={this.containerRef}
            style={{
              width: "100%", height: "100%", backgroundColor: "#E5E5E5", borderRadius: "8px",
            }}
          >
            {showDeletePinModal && (
              <EnvConfirmModal
                headerText="Remove Swab Confirmation"
                bodyText={`Are you sure you want to remove Swab ${pinMap.get(selectedLocationID)?.swab_number} from the map?`}
                buttonText={["Cancel", "Confirm"]}
                buttonFunctions={[() => this.setState({ showDeletePinModal: false }),
                  this.handleRemoveSwab]}
              />
            )}
            <TransformWrapper
              ref={transformWrapperRef}
              key={mapKey}
              initialScale={initialScale}
              minScale={minScale || 1}
              initialPositionX={initialX}
              initialPositionY={initialY}
              animationTime={0}
              panning={{ velocityDisabled: true }}
              zoomAnimation={{ animationTime: 10 }}
              onZoom={this.onZoom}
              onInit={this.onInit}
              doubleClick={{ disabled: true }}
              limitToBounds
            >
              {({
                zoomIn, zoomOut, resetTransform, state, setTransform,
              }) => (
                <>
                  {!displayNoResultFound && (
                  <PinsVisibleArea
                    imageRef={this.imageRef}
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                    containerRef={this.containerRef}
                    selectedLocationID={selectedLocationID}
                    indexOfQuadrantHoverOver={indexOfQuadrantHoverOver}
                    index0={index0}
                    pinMap={pinMap}
                    displayTooltipLocationID={displayTooltipLocationID}
                    isAddingNewSwab={isAddingNewSwab}
                    isPinningSwab={isPinningSwab}
                    endPinningSwab={endPinningSwab}
                    setDisplayTooltipLocationID={setDisplayTooltipLocationID}
                    listViewDisplay={listViewDisplay}
                    quadrantSelected={quadrantSelected}
                    setSelectedLocationID={setSelectedLocationID}
                    resetTransform={resetTransform}
                    setTransform={setTransform}
                    updateMinScale={this.updateMinScale}
                    handleSelectQuadrant={handleSelectQuadrant}
                    updatePinInfo={updatePinInfo}
                    state={state}
                    isPinningNewSwab={isPinningNewSwab}
                    togglePinningNewSwab={togglePinningNewSwab}
                    currentZoom={currentZoom}
                    clickQuadrant={clickQuadrant}
                    isClickingQuadrant={isClickingQuadrant}
                  />
                  )}
                  {/* {!listViewDisplay && (
                  <PinsDroppableArea
                    updatePinInfo={updatePinInfo}
                    imageRef={this.imageRef}
                    pinOnMap={pinOnMap}
                    pinnedLocations={pinnedLocations}
                    setTransform={setTransform}
                    state={state}
                    indexPinRelocating={indexPinRelocating}
                    setIndexPinRelocating={this.setIndexPinRelocating}
                  />
                  )} */}
                  <TransformComponent
                    wrapperStyle={{ borderRadius: "8px" }}
                  >
                    <img
                      alt="mainImage"
                      ref={this.imageRef}
                      onLoad={() => this.whenImageLoad(setTransform, state)}
                      src={imageSrc}
                      style={{
                        opacity: draggingOverMap ? 0.5 : 1,
                        boxShadow: "0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)",
                      }}
                    />
                  </TransformComponent>
                  <MapTools
                    handleZoomIn={zoomIn}
                    handleZoomOut={zoomOut}
                    handleReset={resetTransform}
                    refOfWrapper={transformWrapperRef}
                    listViewDisplay={listViewDisplay}
                    onZoom={this.onZoom}
                    selectedLocationID={selectedLocationID}
                    isAddingNewSwab={isAddingNewSwab}
                    pinMap={pinMap}
                  />

                </>
              )}
            </TransformWrapper>
          </div>
        </div>
        {listViewDisplay && selectedLocationID && checkPinIsPinned(pinMap.get(selectedLocationID)) ? (
          <RemoveSwabBtn onClick={() => this.setState({ showDeletePinModal: true })} />
        ) : (
          <ZoomDisplay currentZoom={currentZoom} />)}

      </>
    );
  }
}

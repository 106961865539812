import React, { useMemo } from "react";

import { ReactComponent as TrashIcon } from "../../../assets/images/sample-submission/hoveredGarbageCan.svg";

export default function SelectionListItem({
  location, selected, onClick, onDelete, extraFields, linkPatternFields,
}) {
  const data = useMemo(() => {
    const fields = [...linkPatternFields, ...extraFields];
    const values = fields.reduce((acc, field) => {
      const val = location.sample_id_fields[field.json_field];
      acc[field.title_field] = val ?? "";
      return acc;
    }, {});
    return {
      ...values,
      "Test Added": location.test_list.length,
    };
  }, [linkPatternFields, extraFields, location]);

  return (
    <li onClick={onClick} className={`AddEditTestsForm__SelectionListItem ${selected ? "AddEditTestsForm__SelectionListItem--selected" : ""} ${location.test_list.length === 0 ? "AddEditTestsForm__SelectionListItem--empty" : ""}`}>
      {Object.entries(data).map(([key, value]) => (
        <p key={key} className="AddEditTestsForm__SelectionListLine truncated-text">
          <span className="AddEditTestsForm__SelectionListTitle">{key}</span>
          {" "}
          <span className="AddEditTestsForm__SelectionListValue">{value}</span>
        </p>
      ))}
      <span className="AddEditTestsForm__SelectionListItemDelete" onClick={onDelete}>
        <TrashIcon width={10} height={10} />
      </span>
    </li>
  );
}

import React, { useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import CloseLightBlue from "../../../../../../assets/images/product/template/CloseLightBlue.png";
import { ReactComponent as Product } from "../../../../../../assets/images/product/template/ProductIconFilled.svg";
import { ReactComponent as Calendar } from "../../../../../../assets/images/product/template/CalendarIcon.svg";
import { ReactComponent as EditIcon } from "../../../../../../assets/images/sample-submission/edit.svg";
// import { ReactComponent as Checkmark } from "../../../../../../assets/images/product/template/inputCheckmark.svg";
import "./COASave.css";
import BuilderSaveSuccess from "./BuilderSaveSuccess";
import SubmitButton from "./SubmitButton";
import { tagColorMap } from "./Constant";
import { getDistinctIdentifiersForSampleList } from "../../../helper";

export default function COASave({
  handleSaveTemplate,
  toggleSaveModal,
  selectedReports,
  selectedTemplate,
  saveStatus,
  setSaveStatus,
}) {
  const { name, tags } = selectedTemplate;
  const tag = tags[0];
  const linkReportKeys = selectedReports.link_report_keys;
  const { sample_id: sampleIDArr } = selectedReports.data_fileds;

  const [isEditing, setIsEditing] = useState(true);
  const [value, setValue] = useState(sampleIDArr[0]);

  const onChange = (val) => {
    if (!val.includes("/")) {
      setValue(val);
    }
  };

  const handleCheckAndSaveTemplate = () => {
    if (value.trim()) {
      setIsEditing(false);
      setSaveStatus("loading");
      handleSaveTemplate({ name: value.trim() });
    } else {
      toast.error("COA name cannot be empty");
    }
  };

  return (
    <div className="COASave__Container">
      {
        saveStatus === null && (
        <img
          className="COASave__DeleteButton"
          onClick={() => toggleSaveModal(true)}
          src={CloseLightBlue}
          alt="close icon"
        />
        )
      }
      <div className="COASave__Header">
        {isEditing ? (
          <div className="COASave__InputParent">
            <label htmlFor="COASave__Input">
              COA Name
            </label>
            <input
              className="COASave__Input"
              id="COASave__Input"
              type="text"
              placeholder="Enter COA Name"
              value={value}
              onChange={((e) => onChange(e.target.value))}
              autoComplete="off"
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter" && isEditing) {
                  setIsEditing(false);
                }
              }}
            />
            {/* <Checkmark onClick={() => setIsEditing(false)} className="COASave__InputButton" /> */}
          </div>
        ) : (
          <>
            <span className="COASave__COAName">{value}</span>
            <EditIcon onClick={() => setIsEditing(true)} className="COASave__InputButton" />
          </>
        )}
      </div>
      <div className="COASave__Body">
        <div className="COASaveBody__ReportInfo">
          <div className="COASaveBody__ReportInfoRow">
            <Product width={18} height={18} />
            <span className="LinkedFieldsSpan">
              {getDistinctIdentifiersForSampleList(selectedReports.samples, linkReportKeys).map((identifierArr, sampleIdx) => (
                <React.Fragment key={sampleIdx}>
                  <span className="LinkedFieldsSpan">
                    {identifierArr.map((val, valIdx) => (
                      <React.Fragment key={valIdx}>
                        <span>
                          {val}
                          {valIdx === identifierArr.length - 1 && sampleIdx < selectedReports.samples.length - 1 && (",")}
                        </span>
                        {valIdx < identifierArr.length - 1 && (<span className="LinkedFieldsDelimiterDot" />)}
                      </React.Fragment>
                    ))}
                  </span>
                </React.Fragment>
              ))}
            </span>
          </div>
          <div className="COASaveBody__ReportInfoRow">
            <Calendar width={18} height={18} />
            <span>{moment(new Date()).format("MM/DD/yyyy")}</span>
          </div>
          <div
            className="COASaveBody__ReportInfoTag"
            style={{ backgroundColor: tagColorMap[tag] }}
          >
            {name}
          </div>
        </div>
        <div className="COASaveBody__Right">
          {saveStatus === "success" ? (
            <BuilderSaveSuccess text="Custom report saved" />
          ) : (
            <SubmitButton
              loading={saveStatus === "loading"}
              disabled={!value.trim()}
              text="Save Report"
              handleSaveTemplate={handleCheckAndSaveTemplate}
            />
          )}
        </div>
      </div>
    </div>
  );
}

import React, {
  useCallback, useEffect, useRef, useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import DragDrop from "./DragDrop";
import ImageEditToggles from "./ImageEditToggles";
import "./UploadImage.css";
import UploadImageEditor from "./UploadImageEditor";
import LoadingConfirmBtn from "./LoadingConfirmBtn";
import { generateRotatedImageUrl, toBlob } from "./ImageEditHelper";
import { getFileNameAndExtensionFromPath } from "../../../utils/helpers";
import StyledButton from "../UIComponents/StyledButton";

export default function UploadImage(props) {
  const {
    setShowUploadImageModal,
    setDisplayImageSrc,
    displayImageSrc,
    imageInfoRef, // { originalUrl, editedUrl, file, crop, rotate, scale }
    setImageInfoRef,
    setModalHeader,
    // imageFileType,
    editMode, // it is used to differentciate wheter current upload is a new upload (Sample Submission) or edit the current image
    updateNewSampleImage,
  } = props;
  /** File/Dropzone state */
  const [imageFile, setImageFile] = useState(null);
  const [imageTitle, setImageTitle] = useState(imageInfoRef.current.file?.name ?? "");
  const [dragging, setDragging] = useState(false); // Used to change style when dragging files
  const [fileError, setFileError] = useState(false);
  /** Image edit state */
  const [originalSrc, setOriginalSrc] = useState(imageInfoRef.current?.originalUrl ?? ""); // unmodified image src
  const [cropPreviewSrc, setCropPreviewSrc] = useState(imageInfoRef.current?.originalUrl ?? ""); // src used for crop preview, rotation is applied to this src
  const [savingImageLoadingState, setSavingImageLoadingState] = useState(false);
  const [imageEditorKey, setImageEditorKey] = useState(false);
  const [rotatingInProcess, setRotatingInProcess] = useState(false);
  const [previewEditedImage, setPreviewEditedImage] = useState(false);
  /** Set the crop, scale, and rotate to the saved parameters (user's crop/rotate/scale state from the previous edit if applicable) */
  const [scale, setScale] = useState(imageInfoRef.current?.scale ?? 1);
  const [rotate, setRotate] = useState(imageInfoRef.current?.rotate ?? 0);
  const [initialCrop, setInitialCrop] = useState(imageInfoRef.current?.crop);
  /** Refs */
  const cropObjectRef = useRef(); // store the crop parameters as they update without causing rerenders
  const cropImageRef = useRef();
  const canvasRef = useRef();

  const FILE_SIZE_MAX_LIMIT_MB = 10;
  const FILE_SIZE_MAX_LIMIT = FILE_SIZE_MAX_LIMIT_MB * 1024 * 1024; // convert MB to bytes
  const ACCEPTED_FILE_TYPES = ["image/png", "image/jpg", "image/jpeg"];

  /**
   * Return error text if the selected files do not meet requirement.
   * @param {File} file A selected file
   * @returns {Array} Return error text array
   */
  const returnValidFileErr = (file) => {
    let errors = false;
    if (file.size > FILE_SIZE_MAX_LIMIT) {
      toast.error(`File size is too big. Max ${FILE_SIZE_MAX_LIMIT_MB}MB.`);
      errors = true;
    }
    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      toast.error("File format is not supported. Only png, jpg, and jpeg are accepted.");
      errors = true;
    }
    return errors;
  };

  /** Called when dragged file enters the DragDrop area, to change UI style of the area */
  const onDragEnter = useCallback(() => {
    setDragging(true);
  }, []);

  /** Called when dragged file leaves the DragDrop area, to change UI style of the area */
  const onDragLeave = useCallback(() => {
    setDragging(false);
  }, []);

  /**
   * A callback function to handle upload image file.
   * 1. Accpect only one image at a time, otherwise, display an error toast message and exit the function
   * 2. On loading file, set the error message status and image meta data.
   */
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setDragging(false);
    if (acceptedFiles.length + fileRejections.length > 1) {
      toast.error("Only one image is allowed");
      return;
    }
    let file;
    if (acceptedFiles.length === 0) {
      file = fileRejections[0].file;
    } else {
      file = acceptedFiles[0];
    }
    const errorMsg = returnValidFileErr(file);
    if (!errorMsg) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setOriginalSrc(reader.result);
        setCropPreviewSrc(reader.result);
        setImageFile(file);
        // console.log("original size", `${file.size}B`, `${(file.size / 1048576).toFixed(2)}MB`);
        setImageTitle(file.name);
        setFileError(false);
      });
      reader.readAsDataURL(file);
    } else {
      setFileError(true);
    }
  }, []); // eslint-disable-line

  /**
   * Set the dropzone properties: file type, maximum file size, maximum file number.
   */
  const {
    getRootProps, getInputProps,
  } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    maxSize: FILE_SIZE_MAX_LIMIT,
    multiple: false,
  });

  /**
   * Handle Save button click. If there is a canvas, save canvas as a blob and set src.
   * Else, file was deleted, set src back to empty.
   */
  const handleClickSave = async () => {
    setSavingImageLoadingState(true);
    if (canvasRef.current) {
      const quality = 0.8;
      const type = "image/jpeg"; // convert all non-jpeg files to jpeg
      const editedImageBlob = await toBlob(canvasRef.current, type, quality); // hardcode to jpeg because jpeg supports compression (image is compressed when quality < 1)
      // console.log("quality", quality, "size", `${editedImageBlob.size}B`, `${(editedImageBlob.size / 1048576).toFixed(2)}MB`);

      const editedSrc = URL.createObjectURL(editedImageBlob);
      const editedFile = new File([editedImageBlob], `${getFileNameAndExtensionFromPath(imageTitle).name || "sample_image"}.jpeg`, { type });
      let success = true;
      // if it is edited from the report view, then we need to make api calls to update the image information on backend.
      if (editMode === true) {
        success = await updateNewSampleImage(editedFile);
      }
      if (success) {
        setDisplayImageSrc(editedSrc);
        setImageInfoRef({
          originalUrl: rotate === 0 ? originalSrc : cropPreviewSrc, // cropPreviewSrc is rotated
          editedUrl: editedSrc ?? "",
          file: editedFile ?? "",
          rotate,
          scale,
          crop: { ...cropObjectRef.current },
          imageWasEdited: true,
        });
        setShowUploadImageModal(false);
      }
    } else {
      let success = true;
      // if it is edited from the report view, then we need to make api calls to delete the image information on backend.
      if (editMode === true) {
        success = await updateNewSampleImage(null, true);
      }
      if (success) {
        setDisplayImageSrc("");
        setImageInfoRef({
          originalUrl: "",
          editedUrl: "",
          file: "",
          imageWasEdited: true,
        });
        setShowUploadImageModal(false);
      }
    }
    setSavingImageLoadingState(false);
  };

  /**
   * reset all image related states
   */
  const handleDeleteImage = () => {
    setOriginalSrc("");
    setCropPreviewSrc("");
    setInitialCrop({
      unit: "%",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    });
    setRotate(0);
    setScale(1);
    setPreviewEditedImage(false);
    setImageFile(null);
    setImageTitle(null);
    setFileError(false);
  };

  /** Generate rotated image url and remount the crop component */
  async function handleSetRotatedUrl() {
    let rotatedUrl;
    if (cropImageRef.current) {
      setRotatingInProcess(true);
      const quality = 0.8;
      const type = "image/jpeg"; // convert to jpeg
      rotatedUrl = await generateRotatedImageUrl(cropImageRef.current, 90, type, quality);
    }
    if (rotatedUrl) {
      setCropPreviewSrc(rotatedUrl);
      setImageEditorKey(!imageEditorKey);
    }
  }

  /**
   * When image rotates, update rotate
   */
  const handleRotateImage = async () => {
    const {
      x, y, width, height,
    } = cropObjectRef.current;
    setRotatingInProcess(true);
    let prev = rotate;
    if (rotate === 180) {
      prev = -180;
    }
    const newRotate = prev + 90;
    setRotate(newRotate);
    setInitialCrop({
      unit: "%",
      x: 100 - (height + y),
      y: x,
      width: height,
      height: width,
    });
    handleSetRotatedUrl();
  };

  /** When in the crop mode, change the modal header */
  useEffect(() => {
    if (cropPreviewSrc) {
      setModalHeader("Crop Image");
    } else {
      setModalHeader("Upload Image");
    }
  }, [cropPreviewSrc]); // eslint-disable-line

  return (
    <>
      <div className="SampleSubmission__UploadImageModalBody">
        <div className={`SampleSubmission__UploadImageArea ${dragging ? "env-drag-drop-map-dragging" : ""}`}>
          {originalSrc
            ? (
              <UploadImageEditor
                cropPreviewSrc={cropPreviewSrc}
                key={imageEditorKey}
                initialCrop={initialCrop}
                cropObjectRef={cropObjectRef}
                handleDeleteImage={handleDeleteImage}
                imageRef={cropImageRef}
                canvasRef={canvasRef}
                scale={scale}
                rotate={rotate}
                rotatingInProcess={rotatingInProcess}
                setRotatingInProcess={setRotatingInProcess}
                previewEditedImage={previewEditedImage}
              />
            )
            : (
              <DragDrop
                uploadTarget=""
                getInputProps={getInputProps}
                getRootProps={getRootProps}
              />
            )}
        </div>
      </div>
      <div className="SampleSubmission__UploadImageModalFooter">
        {originalSrc && !previewEditedImage && (<ImageEditToggles scale={scale} setScale={setScale} handleRotateImage={handleRotateImage} disable={rotatingInProcess} />)}
        <div className="SampleSubmission__UploadImage__Btns">
          <StyledButton
            type="text"
            className="SampleSubmission__UploadImage__CancelBtn"
            onClick={() => setShowUploadImageModal(false)}
          >
            Cancel
          </StyledButton>
          { savingImageLoadingState
            ? (
              <LoadingConfirmBtn text="Saving" />
            )
            : (
              <StyledButton
                type="primary"
                className="SampleSubmission__UploadImage__DoneBtn"
                disabled={(!displayImageSrc && !imageFile) || fileError}
                onClick={handleClickSave}
              >
                Save
              </StyledButton>
            )}
        </div>
      </div>

    </>
  );
}

import React, { Component } from "react";
// import PDFileICon from "../../../assets/images/PDF_FileIcon.png";
// import DocCardDots from "../../../assets/images/DocCardDots.png";
import Scrollbar from "react-scrollbars-custom";
import DocumentsCard from "./DocumentsCard";
import "./DocumentsCardView.css";

export default class DocumentsCardView extends Component {
  render() {
    const {
      files,
      readOnly,
      handleFileOptionClick,
      handleCheckbox,
      handleNameChange,
      handleNameSubmit,
      handleRenameFile,
      handleCancelRename,
      handleConfirmDelete,
      handleDownloadFile,
    } = this.props;
    return (
      <Scrollbar
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
        className="DocCardView"
      >
        <div className="DocCardWrapper">
          {files.map((file, index) => (
            <DocumentsCard
              key={index}
              readOnly={readOnly}
              file={file}
              index={index}
              handleFileOptionClick={handleFileOptionClick}
              handleCheckbox={handleCheckbox}
              handleNameChange={handleNameChange}
              handleNameSubmit={handleNameSubmit}
              handleCancelRename={handleCancelRename}
              handleRenameFile={handleRenameFile}
              handleConfirmDelete={handleConfirmDelete}
              handleDownloadFile={handleDownloadFile}
            />
          ))}
        </div>
      </Scrollbar>
    );
  }
}

import axios from "axios";
import moment from "moment";

import { API_URL } from "../utils/Constant";

/**
 * Data for calendar landing page
 * @param {*} payload date_range (optional), scheduled/sampled/inprocess/incomplete/in-spec/out-of-spec filter (optional)
 */
export const getEnvSchedule = async (startDate, endDate, specs_flag_filter = "", signal) => {
  try {
    const params = {
      date_from: startDate ?? "", // YYYY-MM-DD
      date_to: endDate ?? "", // YYYY-MM-DD
      specs_flag_filter, // "0,1,2,3,4,5,6",
    };
    const response = await axios.get(`${API_URL}/envschedule/`, { params, signal });

    if (response.data.message.toLowerCase() === "success") {
      return { success: true, result: response.data.result };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong.", canceled: e.code === "ERR_CANCELED" };
  }
};

/**
 * Env schedule data for reports
 * @param {String} location_id Location id
 */
export const getReportEnvSchedule = async (location_id, signal) => {
  try {
    const params = { location_id };
    const response = await axios.get(`${API_URL}/envschedule/`, { params, signal });

    if (response.data.message.toLowerCase() === "success") {
      return { success: true, result: response.data.result };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong.", canceled: e.code === "ERR_CANCELED" };
  }
};

/**
 * Create New Sampling Schedule
 * @param {*} payload
 * @returns
 */
export const postEnvSchedule = async (payload) => {
  try {
    const response = await axios.post(`${API_URL}/envschedule/`, { ...payload });

    if (response.data.message.toLowerCase() === "success") {
      return { success: true, result: response.data.result };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Edit New Sampling Schedule
 * @param {*} payload
 * @returns
 */
export const patchEnvSchedule = async (payload) => {
  try {
    const response = await axios.patch(`${API_URL}/envschedule/`, { ...payload });

    if (response.data.message.toLowerCase() === "success") {
      return { success: true, result: response.data.result };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Edit scheduling data using drag n drop method
 * @param {String} event_id event id
 * @param {String} submission_id submission id
 * @param {Date|String|Moment} new_schedule_date new date where event card is dropped
 * @returns
 */
export const patchDragUpdateSchedule = async (event_id, submission_id, new_schedule_date) => {
  const payload = {
    submission_id,
    event_id,
    new_schedule_date: moment(new_schedule_date).format("YYYY-MM-DD"),
    date_edit: true,
    edit_recurring: false,
    action: "calendar-edit",
  };

  try {
    const response = await axios.patch(`${API_URL}/envschedule/`, { ...payload });

    if (response.data.message.toLowerCase() === "success") {
      return { success: true, result: response.data.result };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Get sampling locaiton data for diff input value combinations
 * @param {Object} sample {item: "", time: "", ...}
 * @param {String} sample_category environment/product
 * @return  {Object} {success, message, result: { item: [], time: [], best_by: [], tests: [], ...} }
 */
export const getSamplingLocationData = async (sample, location_id, sample_category = "environment", signal) => {
  try {
    const resp = await axios.post(`${API_URL}/ssautofill/`, { sample_category, sample, location_id }, { signal });

    return {
      success: resp.data.message === "Success",
      ...resp.data,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * List swab location list
 * @param {String} search Optional search text
 * @param {String} location_id Location id
 * @param {*} signal Abort signal
 */
export const getEnvSwabs = async (search, location_id, signal) => {
  try {
    const params = { search, location_id };
    const response = await axios.get(`${API_URL}/env_swabs/`, { params, signal });
    if (response.data.message.toLowerCase() === "success") {
      return { success: true, result: response.data.result };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Delete Env Schedule
 * @param {String} event_id Event id
 * @param {Boolean} delete_recurring is deleting recurring event
 * @param {String} submission_id Submission id
 */
export const deleteEnvSchedule = async (event_id, delete_recurring, submission_id, action = "calendar-delete") => {
  try {
    const payload = {
      event_id,
      delete_recurring,
      submission_id, // !delete_recurring ? submission_id : undefined, // needed if 'delete_recurring' is false
      action,
    };
    if (!submission_id) delete payload.submission_id;
    const response = await axios.delete(`${API_URL}/envschedule/`, { data: payload });
    if (response.data.message.toLowerCase() === "success") {
      return { success: true };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Mark/Unmark sample
 * @param {String} submission_id Submission id
 * @param {String} sample_id Sample id
 * @param {String} sample_type Sample type
 * @param {String} action Mark/Unmark
 */
export const marksampled = async (submission_id, sample_id, sample_type, action = "Mark") => {
  try {
    const data = {
      submission_id,
      sample: { sample_id, sample_type },
      action,
    };
    const response = await axios.patch(`${API_URL}/marksampled/`, data);
    if (response.data.message.toLowerCase() === "success") {
      return { success: true };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const submitSamples = async (payload) => {
  try {
    const response = await axios.patch(`${API_URL}/marksampled/`, payload);
    if (response.data.message.toLowerCase() === "success") {
      return { success: true, ...response.data };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Skeleton, Spin } from "antd";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import moment from "moment";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import DatePicker from "../../Common/DatePicker/DatePicker";
import GraphComponent from "./Components/Graph/ComposedGraphComponent";
import EnvAnalyticsHeader from "./Components/Header/EnvAnalyticsHeader";
import AnalyticsTable from "./Components/Table/EnvAnalyticsTable";

import { envAnalyticsDataFetch } from "../../../actions/envAnalytics";

import { ReactComponent as CalendarIcon } from "../../../assets/images/environment/CalendarEnv.svg";

import "./EnvAnalyticsTab.css";

const headers = ["Swab ID", "Last submitted on", "No of days since last submission"];
const headers_2 = ["Swab ID", "Status", "Out of Spec Samples"];

export default function EnvAnalyticsTab() {
  const [dateSelected, setDateSelected] = useState("");
  const [analyticsData, setAnalyticsData] = useState({
    spec_details: null,
    test_details: null,
    submission_details: null,
    ratio_details: null,
  });
  const [prevDateValue, setPrevDateValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isInitial, setIsInitial] = useState(true);
  const abortControllerRef = useRef(null);

  /**
  * Function to fetch analytics data from the API
  * @param {string} params.startDate - Start date for the data range
  * @param {string} params.endDate - End date for the data range
  * @param {string} [params.category="all"] - Category filter for the data
  */
  const fetchAnalyticsData = useCallback(
    async ({
      startDate, endDate, signal,
    }) => {
      try {
        const payload = {};
        if (startDate && endDate) {
          payload.date_from = startDate;
          payload.date_to = endDate;
        }

        const response = await envAnalyticsDataFetch(payload, signal);
        if (response.success) {
          return response.result;
        }
        toast.error("Failed to load analytics specs");
        return null;
      } catch (error) {
        if (error.name !== "AbortError") {
          toast.error("Error fetching analytics data");
        }
        return null;
      }
    },
    [],
  );
  /**
 * On date change if the user does not apply selected date change it to previous date.
 * @param {*} date
 */
  const handleDateSelection = (date) => {
    if (date === "") {
      setDateSelected(prevDateValue);
    } else {
      setDateSelected(date);
    }
  };
  /**
   *
   * @returns Dates that have been tests passed to calendar and should be displayed as red dots.
   */
  const getReportDates = async () => {
    try {
      const [startDate, endDate] = dateSelected.split(" - ");
      const response = await envAnalyticsDataFetch({
        date_from: moment(startDate, "MMM D, YYYY").format("YYYY-MM-DD"),
        date_to: endDate && endDate !== "" ? moment(endDate, "MMM D, YYYY").format("YYYY-MM-DD") : moment(startDate, "MMM D, YYYY").format("YYYY-MM-DD"),
      });
      if (response.success) {
        return response.dates ?? [];
      }
      toast.error("Failed to load analytics specs");
      return null;
    } catch (error) {
      if (error.name !== "AbortError") {
        toast.error("Error fetching analytics data");
      }
      return null;
    }
  };

  /**
  * useEffect to fetch initial data on component mount
  * Fetches data for the last 30 days by default
  */
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Calculate date range for the last 30 days
        const startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        setDateSelected(`${moment(startDate).format("MMM DD, YYYY")} - ${moment(endDate).format("MMM DD, YYYY")}`);
        setPrevDateValue(`${moment(startDate).format("MMM DD, YYYY")} - ${moment(endDate).format("MMM DD, YYYY")}`);
        const sampleValues = await fetchAnalyticsData({
          startDate,
          endDate,
        });

        if (sampleValues) {
          setAnalyticsData(sampleValues);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
        setIsInitial(false);
      }
    };

    fetchData();
  }, []); // eslint-disable-line

  /**
  * Function to handle date range changes from the DatePicker component
  * It debounces the API call to avoid sending too many requests in quick succession
  * @param {string} startDate - Selected start date
  * @param {string} endDate - Selected end date
  */
  const handleDateChange = useCallback(
    debounce(async (startDate, endDate) => {
    // Format dates for comparison
      setIsLoading(true);
      const formattedStartDate = moment(startDate, "MMM D, YYYY").format("YYYY-MM-DD");
      const formattedEndDate = moment(endDate, "MMM D, YYYY").format("YYYY-MM-DD");

      // Update data only if dates are different
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      try {
        const sampleValues = await fetchAnalyticsData({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          signal: abortControllerRef.current.signal,
        });

        // Check if the fetched data is different from the current state
        if (sampleValues && !isEqual(sampleValues, analyticsData)) {
          setAnalyticsData(sampleValues);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
        // setIsInitial(false);
      }
    }, 300),
    [fetchAnalyticsData, dateSelected, analyticsData],
  );

  /**
  * This triggers the `handleDateChange` function whenever the date range is updated
  */
  const handleDateSearch = () => {
    const [startDate, endDate] = dateSelected.split(" - ");
    if (startDate && endDate) {
      handleDateChange(startDate, endDate);
    }
  };
  // useEffect(() => {
  //   const [startDate, endDate] = dateSelected.split(" - ");
  //   if (startDate && endDate) {
  //     handleDateChange(startDate, endDate);
  //   }
  // }, [dateSelected, handleDateChange]);

  /**
  * Memoized graph data to avoiding unnecessary recalculations
  */
  const graphDataMemo = useMemo(() => analyticsData.test_details, [analyticsData.test_details]);

  return (
    <Scrollbar className="EnvAnalyticsTab__MainContainer--ScrollBar">
      <div className="EnvAnalyticsTab__MainContainer">
        {(isLoading && isInitial) ? (
          <div className="EnvAnalyticsTab__SkeletonContainer">
            <Skeleton.Input width={200} marginBottom={20} active />
            <div className="EnvAnalyticsTab__SkeletonBody--Stats">
              <Skeleton.Input active block className="EnvAnalyticsTab__SkeletonBody--Item" />
            </div>
            <div className="EnvAnalyticsTab__SkeletonBody--Graphs">
              <Skeleton.Input active block className="EnvAnalyticsTab__SkeletonBody--Item" />
            </div>
          </div>
        ) : (
          <>
            <div className="EnvAnalyticsTab__Container">
              <div className="EnvAnalyticsTab__DateFilter">
                <div className="EnvAnalyticsTab__TabDate--Container">
                  { (!isLoading && !isInitial) ? (
                    <DatePicker
                      handleSearchDate={handleDateSearch}
                      value={dateSelected}
                      onChange={handleDateSelection}
                      getReportDates={getReportDates}
                      searchOnClear={false}
                      prevValue={prevDateValue}
                      setPrevValue={(val) => setPrevDateValue(val)}
                      popoverPlacement="bottomRight"
                      popoverClassName="EnvAnalyticsTabPopover"
                      disableClickAway
                      disableSingleDaySelection
                      datePickerDisplayProps={{
                        displayClassName: "EnvAnalyticsTab__DateDisplayProperties",
                        customCalendarIcon: <CalendarIcon width={18} height={18} />,
                        showText: false,
                      }}
                    />
                  ) : (
                    <>
                      <div className="EnvAnalyticsTab__Loading">
                        <Spin indicator={<LoadingOutlined className="EnvAnalyticsTab__Checkbox__Spin" spin />} />
                      </div>
                    </>
                  )}
                  <div className="EnvAnalyticsTab__DateDisplayProperties--DisplayDate">{dateSelected}</div>
                </div>
              </div>
              <div className="EnvAnalyticsTab__Headers">
                <EnvAnalyticsHeader
                  specDetailsNum={analyticsData?.spec_details?.num_out_of_spec}
                  specDetailsRetest={analyticsData?.spec_details?.num_out_of_spec_retest}
                  mainHeading="Out of Spec"
                  sampleHeading="Out of Spec Samples"
                  retestHeading="Out of Spec (Retest)"
                  textColor="#E11903"
                  borderColor="#E11903"
                  backgroundColor="rgba(225, 25, 3, 0.1)"
                />

                <EnvAnalyticsHeader
                  specDetailsNum={analyticsData?.spec_details?.num_in_spec}
                  specDetailsRetest={analyticsData?.spec_details?.num_in_spec_retest}
                  mainHeading="In Spec"
                  sampleHeading="In Spec Samples"
                  retestHeading="In Spec (Retest)"
                  textColor="#1EC04A"
                  borderColor="#1EC04A"
                  backgroundColor="rgba(30, 192, 74, 0.1)"
                />

              </div>
              <div className="EnvAnalyticsTab__Charts">
                <div className="EnvAnalyticsTab__Chart EnvAnalyticsTab__Chart_Bar">
                  <GraphComponent graphType="bar" graphData={graphDataMemo} />
                </div>
                <div className="EnvAnalyticsTab__Chart EnvAnalyticsTab__Chart_Line">
                  <GraphComponent graphType="line" graphData={graphDataMemo} />
                </div>
              </div>
            </div>
            <div className="EnvAnalyticsTab__TableContainer">
              <div className="EnvAnalyticsTab__Tables">
                <div className="EnvAnalyticsTab__Table EnvAnalyticsTab__Table_Submission">
                  <AnalyticsTable
                    headers={headers}
                    data={analyticsData.submission_details}
                    tableType="SubmissionTracker"
                  />
                </div>
                <div className="EnvAnalyticsTab__Table EnvAnalyticsTab__Table_Ratio">
                  <AnalyticsTable
                    headers={headers_2}
                    data={analyticsData.ratio_details}
                    tableType="AlertTable"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Scrollbar>
  );
}

import React from "react";

import { Table } from "antd";
import { has } from "lodash";
import { Scrollbar } from "react-scrollbars-custom";
import "./SampleSubmissionModalEditsInfo.css";

export default function SampleSubmissionModalEditsInfo({
  editedSamplePayload,
  sampleFields,
  sampleFieldsInfo,
  sampleIdFields,
}) {
  const {
    sample_id,
    new_sample_id,
    sample_type,
    new_sample_type,
    oldSample,
    newSample,
    deleted_tests,
    added_tests,
  } = editedSamplePayload;

  /** Set up Sample ID and Sample Type table */
  const fieldsTableDataSource = [
    {
      key: "Old",
    },
    {
      key: "New",
    },
  ];
  const fieldsTableColumns = [
    {
      title: "",
      dataIndex: "key",
      key: "key",
      rowScope: "row",
      width: 80,
    },
  ];

  /** Add sample id if it has changed (this is the first column) */
  if (has(editedSamplePayload, "new_sample_id")) {
    fieldsTableDataSource.forEach(({ key }, i) => {
      fieldsTableDataSource[i].sample_id = key === "Old" ? sample_id : new_sample_id;
    });
    fieldsTableColumns.push({
      title: "Sample ID",
      dataIndex: "sample_id",
      key: "sample_id",
    });
  }

  /** Add sample type if it has changed */
  if (has(editedSamplePayload, "new_sample_type")) {
    fieldsTableDataSource.forEach(({ key }, i) => {
      fieldsTableDataSource[i].sample_type = key === "Old" ? sample_type : new_sample_type;
    });
    fieldsTableColumns.push({
      title: sampleFieldsInfo.sample_type.title_field,
      dataIndex: "sample_type",
      key: "sample_type",
    });
  }

  /** Add any non-sampleId fields that have changed */
  sampleFields.forEach((json_field) => {
    if (!sampleIdFields.has(json_field) && !["sample_id", "sample_type"].includes(json_field)) {
      const oldVal = oldSample[json_field];
      const newVal = newSample[json_field];

      if (oldVal !== newVal) {
        fieldsTableDataSource.forEach(({ key }, i) => {
          fieldsTableDataSource[i][json_field] = key === "Old" ? oldVal : newVal;
        });
        fieldsTableColumns.push({
          title: sampleFieldsInfo[json_field].title_field,
          dataIndex: json_field,
          key: json_field,
        });
      }
    }
  });

  /** Add image column if it has been added/edited/deleted (for environment) */
  if (has(editedSamplePayload, "new_image_src")) {
    fieldsTableDataSource.forEach(({ key }, i) => {
      const src = editedSamplePayload[`${key === "New" ? "new_" : ""}image_src`];
      fieldsTableDataSource[i].image = src ? (
        <div className="SampleSubmissionConfirmModal__Image">
          <img src={editedSamplePayload[`${key === "New" ? "new_" : ""}image_src`]} alt={key} />
        </div>
      ) : "";
    });
    fieldsTableColumns.push({
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
    });
  }

  /** Add composite samples changes */
  const compositeTableDataSource = [];

  // Extract num_composite_samples
  const oldNumCompositeSamples = oldSample.num_composite_samples;
  const newNumCompositeSamples = newSample.num_composite_samples;

  if (oldNumCompositeSamples !== newNumCompositeSamples) {
    compositeTableDataSource.push({
      key: "Number of Composite Samples",
      old_value: oldNumCompositeSamples,
      new_value: newNumCompositeSamples,
    });
  }

  // Extract composite_fieldnames
  const oldCompositeFieldnames = [];
  const newCompositeFieldnames = [];
  oldSample.composite_fieldnames.forEach((fieldName) => {
    oldCompositeFieldnames.push(sampleFieldsInfo[fieldName].title_field ?? "");
  });
  newSample.composite_fieldnames.forEach((fieldName) => {
    newCompositeFieldnames.push(sampleFieldsInfo[fieldName].title_field ?? "");
  });
  if (oldCompositeFieldnames.join(",") !== newCompositeFieldnames.join(",")) {
    compositeTableDataSource.push({
      key: "Composite Field names",
      old_value: oldCompositeFieldnames.join(", ") !== "" ? oldCompositeFieldnames.join(", ") : "-",
      new_value: newCompositeFieldnames.join(", ") !== "" ? newCompositeFieldnames.join(", ") : "-",
    });
  }

  /** Set up Tests table */
  const testsTableDataSource = [];

  // Add added tests column if any tests have been added
  if (added_tests) {
    testsTableDataSource.push(
      <div key={`added-tests-${added_tests.join("-")}`}>
        <p className="SampleSubmissionConfirmModal__TestListTitle">Tests added</p>
        {added_tests.length > 10 ? (
          <div className="SampleSubmissionConfirmModal__TestListScrollContainer">
            <Scrollbar>
              <div className="SampleSubmissionConfirmModal__TestList">
                {added_tests.map((test, index) => (
                  <span key={`added-test-${index}`} className="SampleSubmissionConfirmModal__TestDiv--added">{test}</span>
                ))}
              </div>
            </Scrollbar>
          </div>
        ) : (
          <div className="SampleSubmissionConfirmModal__TestList">
            {added_tests.map((test, index) => (
              <span key={`added-test-${index}`} className="SampleSubmissionConfirmModal__TestDiv--added">{test}</span>
            ))}
          </div>
        )}
      </div>,
    );
  }

  // Add deleted tests column if any tests have been deleted
  if (deleted_tests) {
    testsTableDataSource.push(
      <div key={`deleted-tests-${deleted_tests.join("-")}`}>
        <p className="SampleSubmissionConfirmModal__TestListTitle">Tests deleted</p>
        {deleted_tests.length > 10 ? (
          <div className="SampleSubmissionConfirmModal__TestListScrollContainer">
            <Scrollbar>
              <div className="SampleSubmissionConfirmModal__TestList">
                {deleted_tests.map((test, index) => (
                  <span key={`deleted-test-${index}`} className="SampleSubmissionConfirmModal__TestDiv--deleted">{test}</span>
                ))}
              </div>
            </Scrollbar>
          </div>
        ) : (
          <div className="SampleSubmissionConfirmModal__TestList">
            {deleted_tests.map((test, index) => (
              <span key={`deleted-test-${index}`} className="SampleSubmissionConfirmModal__TestDiv--deleted">{test}</span>
            ))}
          </div>
        )}
      </div>,
    );
  }

  return (
    <div className="SampleSubmissionConfirmModal__EditsContainer">
      {fieldsTableColumns.length <= 1 && !testsTableDataSource.length && !compositeTableDataSource.length && <p className="sampleSubmissionConfirmDelTitleMessage">No changes to be submitted.</p>}

      {fieldsTableColumns.length > 1 && (
        <>
          <div className="SampleSubmissionConfirmModal__TitleDiv">
            <span />
            <p className="SampleSubmissionConfirmModal__Title">Sample Details</p>
          </div>
          <div className="SampleSubmissionConfirmModal__Divider" />
          <Table
            columns={fieldsTableColumns}
            dataSource={fieldsTableDataSource}
            pagination={false}
            bordered
            scroll={{ x: "100%" }}
          />
        </>
      )}
      {compositeTableDataSource.length > 0 && (
        <>
          <div className="SampleSubmissionConfirmModal__TitleDiv">
            <span />
            <p className="SampleSubmissionConfirmModal__Title">Composite Samples</p>
          </div>
          <div className="SampleSubmissionConfirmModal__Divider" />
          <Table
            columns={[
              { title: "Field", dataIndex: "key", key: "key" },
              { title: "Old Value", dataIndex: "old_value", key: "old_value" },
              { title: "New Value", dataIndex: "new_value", key: "new_value" },
            ]}
            dataSource={compositeTableDataSource}
            pagination={false}
            bordered
            scroll={{ x: "100%" }}
          />
        </>
      )}
      {testsTableDataSource.length > 0 && (
        <>
          <div className="SampleSubmissionConfirmModal__TitleDiv">
            <span />
            <p className="SampleSubmissionConfirmModal__Title">Tests</p>
          </div>
          <div className="SampleSubmissionConfirmModal__Divider" />
        </>
      )}
      {testsTableDataSource.map((item, inx) => (<span key={inx}>{item}</span>
      ))}
    </div>
  );
}

import React, { useState } from "react";

import { Dot } from "recharts";

export default function ScatterPoint(props) {
  const {
    cx, cy, updateHoveredItem, payload, fill, node,
  } = props;

  const [hovered, setHovered] = useState(false);

  const handleDotHover = (hoverState) => {
    setHovered(hoverState);
    const customPayload = {
      ...payload, y: node.y, cx, cy,
    };
    if (hoverState) {
      updateHoveredItem({ data: customPayload, type: "scatter" });
    } else {
      updateHoveredItem(null);
    }
  };

  return (
    <>
      <Dot
        cx={cx}
        cy={cy}
        stroke={(hovered ? "#cccc" : fill) ?? "#D1DCE8"}
        fill="white"
        strokeWidth={2}
        r={hovered ? 6 : 4}
        opacity={1}
        onMouseEnter={() => handleDotHover(true)}
        onMouseLeave={() => handleDotHover(false)}
      />
    </>
  );
}

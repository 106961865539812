import React from "react";
import "./ReportViewHeader.css";

export default function ReportViewHeader(props) {
  const {
    title, subTitle, subTitleColor, blockColor,
  } = props;
  return (
    <div className="env-report-view-header-section">
      <div style={{ background: blockColor }} className="env-report-view-header-block" />
      <div className="env-report-view-header-text">
        <span>{title}</span>
        <span style={{ color: subTitleColor }}>{subTitle}</span>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";

import { Flex, Row } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

import { AppContext } from "../../AppContext";
import StyledButton from "../Common/UIComponents/StyledButton";

import { switchCompany } from "../../actions/switchCompany";

import arrow from "../../assets/images/Arrow 7.png";
// import { getUserCredentials } from "../../utils/common";
import Onboarding_Art_Product from "../../assets/images/onboarding/Onboarding_Art_Product.png";

export default function AddCompanySuccessLandingPage({ newCompanyName, user_Title, currCompanyName }) {
  const [count, setCount] = useState(5);
  const appContext = useContext(AppContext);
  const [, setIsSwitching] = useState(false);
  // const { newCompanyName } = getUserCredentials();
  const [selectedCompany, setSelectedCompany] = useState(currCompanyName);
  const history = useHistory();

  const handleSwitchCompany = async (companyName, userTitle) => {
    setIsSwitching(true);
    const prevCompany = selectedCompany;
    setSelectedCompany(companyName);
    const success = await switchCompany(companyName, userTitle, appContext);
    if (success) {
      /** Reload the window */
      history.push("/sample-submission");
      window.location.reload(false);
    } else {
      setSelectedCompany(prevCompany);
      toast.error("Unable to switch accounts");
    }
    setIsSwitching(false);
  };

  useEffect(() => {
    // If count is 0, Redirect to sample submission page and make reload.
    if (count === 0) { handleSwitchCompany(newCompanyName, user_Title); }

    // Set up a timer to update the count every second
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    // Clear timer when component unmounts or when count changes
    return () => clearInterval(timer);
  }, [count, history]); // eslint-disable-line

  return (
    <Row justify="center" className="LandingPage__Div" span={24}>
      <Flex className="LandingPage">
        <div className="LandingPage__Container">
          <p className="SuccessLandingPage__Text">
            Successfully added company.
          </p>
          <p className="SuccessLandingPage__Heading">
            {`Redirecting you to ${newCompanyName} in ${count}s.`}
          </p>

          <StyledButton type="primary" className="SuccessLandingPage__Btn" onClick={() => handleSwitchCompany(newCompanyName, user_Title)}>
            New company
            <img src={arrow} alt="" className="SuccessLandingPage__BackArrow" />
          </StyledButton>
        </div>
        <div className="LandingPage__Image">
          <img src={Onboarding_Art_Product} alt="" />
        </div>
      </Flex>
    </Row>
  );
}

import React, {
  useEffect, useState, useMemo, useContext, useRef, forwardRef, useImperativeHandle,
} from "react";

import { motion, LayoutGroup } from "framer-motion/dist/framer-motion";
import { has, isEmpty, filter } from "lodash";
import debounce from "lodash/debounce";
import Highlighter from "react-highlight-words";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import { AppContext } from "../../../../../AppContext";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import SampleSubmissionContext from "../../../SampleSubmissionContext";
import AddTestModal from "../../../SampleSubmissionModal/AddTestModal";
import { CHAR_LIMITS, replicateTagRegexFirstMatch } from "../../Constant";
import PesticideSampleAutocomplete from "../../ReusableComponents/PesticideSampleAutocomplete";

import { hasFeature } from "../../../../../utils/common";

import { getTestList } from "../../../../../actions/sampleSubmission";

import { ReactComponent as PlusIcon } from "../../../../../assets/images/sample-submission/addSamplePlusIcon.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/images/sample-submission/blueCloseIcon.svg";
import { ReactComponent as ExclamationIcon } from "../../../../../assets/images/sample-submission/exclamationIcon.svg";
import { ReactComponent as MaximizeIcon } from "../../../../../assets/images/sample-submission/maximize_icon.svg";
import { ReactComponent as MinimizeIcon } from "../../../../../assets/images/sample-submission/minimize_icon.svg";
import searchIcon from "../../../../../assets/images/sample-submission/searchIcon.svg";

import "./AnalysisRequest.css";
import "../SampleDetails/SampleDetails.css";

const AnalysisRequest = forwardRef((props, ref) => {
  const {
    clearTestSearch,
    setClearTestSearch,
    testsSelected,
    setTestsSelected,
    saveTestsForNext,
    setSaveTestsForNext,
    customerDetails,
    submissionEditingSample,
    sampleID,
    disableAddSample,
    setInvalidTest,
    testInfoMap,
    pesticideSample,
    setPesticideSample,
    inputValuesRefs,
    setInputValuesRefs,
    setInvalidInputFound,
    sampleCategory,
    AddUpdateSampleButton,
    setShowExitModal,
    isMaximized,
    setIsMaximized,
    setEditingSubmittedSampleComposite,
  } = props;

  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(""); // used for highlighted text after search
  const [testSuggestions, setTestSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddTest, setIsAddTest] = useState(false);
  const [isAddTestTip, setIsAddTestTip] = useState(false);
  // const [manuallyAddingTest, setManuallyAddingTest] = useState(true);
  const testSuggestionsScrollbar = useRef();
  const { isLoggedOut, thirdParty, mrl } = useContext(SampleSubmissionContext);
  const { userTitle } = useContext(AppContext);
  const admin = userTitle === "Admin" || userTitle === "Editor";

  // Expose a method to get the current testsSelected
  useImperativeHandle(ref, () => ({
    getTestsSelected: () => testsSelected,
  }));

  /** Get test suggestions based on search input */
  const apiGetTestSuggestions = async (searchInput) => {
    const { success, message, testList } = await getTestList(searchInput, (isLoggedOut && !thirdParty) ? customerDetails.customerID : undefined, sampleCategory);
    if (success) {
      if (clearTestSearch) {
        setSearch("");
        setClearTestSearch(false);
      }
      setTestSuggestions(testList);
      setSearched(searchInput);
      if (testSuggestionsScrollbar.current) {
        testSuggestionsScrollbar.current.scrollerElement.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      toast.error(message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const sampleIDHasReplicate = replicateTagRegexFirstMatch.test(sampleID);
    const invalidTests = filter(testsSelected, (testObj) => testObj.replicated && sampleIDHasReplicate);
    setInvalidTest(invalidTests.length > 0);
  }, [testsSelected, sampleID]); // eslint-disable-line

  /** Add debouncing feature for the apiGetTestSuggestions function
   * @return {Function} apiGetTestSuggestions function with debouncing feature,
   * invoking apiGetTestSuggestions only after 500ms has passed after the last invocation.
   */
  const debouncedSearch = useMemo(() => debounce(async (searchInput) => {
    await apiGetTestSuggestions(searchInput);
  }, 500), []);  // eslint-disable-line

  /** Get test suggestions when it mounted */
  useEffect(() => {
    apiGetTestSuggestions("");
  }, []); // eslint-disable-line

  /** After user adds a new sample, saves an edited sample, cancels editing, switches the editing sample to another,
   * "clearTestSearch" will be set from false to true. We will reset the search input to empty and get all test suggestions. */
  useEffect(() => {
    if (clearTestSearch) {
      apiGetTestSuggestions("");
    }
  }, [clearTestSearch]);  // eslint-disable-line

  /** Cancel debouncing so after the component is unmounted, the debouncedSearch function will not be executed,
  and state will not be set causing an error. */
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]); // eslint-disable-line

  /** Call debounced search when search input is changed */
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  /** Search immediately after the user hits enter key */
  const handleHitEnter = (e) => {
    if (e.key === "Enter") {
      apiGetTestSuggestions(e.target.value);
    }
  };

  /**
   * Add or remove a test from the selected tests
   * @param {String} test test name or test obj
   * @param {String} action If action is "deselect", then the test will be removed.
   * Otherwise, the test is added or removed depending on if the selected test set contains that test.
   * Clear all the selected tests
   */
  const toggleTestChoice = (_test, action) => {
    const testsSelectedCopy = { ...testsSelected };
    let test = _test;
    if (typeof test === "object") {
      test = _test.name;
    }
    if (action === "deselect" || has(testsSelectedCopy, test)) {
      delete testsSelectedCopy[test];
    } else if (action === "clear") {
      setTestsSelected({});
      if (submissionEditingSample) {
        setShowExitModal(true);
      }
      return;
    } else {
      testsSelectedCopy[test] = {};
      if (typeof test === "object") {
        testsSelectedCopy[test] = { ..._test };
      } else {
        testsSelectedCopy[test] = { ...(testInfoMap.get(test) ?? {}) };
      }
      testsSelectedCopy[test].autofilled = false; // set autofilled to false because test is user-selected
    }

    setTestsSelected(testsSelectedCopy);
    if (testInfoMap.get(test).test_category === "MRL") {
      setPesticideSample("");
    }
    if (submissionEditingSample) {
      setShowExitModal(true);
    }
  };

  /**
  * Get text for test tooltip
  * @param {Array} display list of display values ex: [{ title: "Method", value: ""}]
  * @param {Boolean} replicated if test is replicated
  * @param {Boolean} sampleIDHasReplicate if sample has a replicate tag
  * @returns Text containing test method, reporting limit, turnaround time if they exist and the reason for why the button is disabled
  */
  const toolTipText = (display, replicated, sampleIDHasReplicate) => {
    const textLines = [];
    if (sampleIDHasReplicate && replicated) { // disable test
      textLines.push("Disabled: Cannot add a replicate test to a sample that is replicated");
    }
    display.forEach(({ title, value }) => {
      if (value) {
        textLines.push(`${title}: ${value}`);
      }
    });
    return textLines.join("\n");
  };

  const analysisClassName = (disableSelection, name) => {
    let className = "";
    if (disableSelection) {
      className = "disabled-test-suggestion";
    } else if (has(testsSelected, name)) {
      className = "selected-test-suggestion";
    } else {
      className = "unselected-test-suggestion";
    }
    return className;
  };

  /**
   * Updates the provided tests by adding a selectMRLType property to MRL tests.
   * The first encountered MRL test will have selectMRLType set to true, while subsequent MRL tests will have selectType set to false.
   * @param {Object} testsSelected - An object containing test objects with MRL property
   * @return {Object} The updated tests object with selectMRLType property added to MRL tests
   */
  const updatedTestsSelectedWithSelectMRLType = () => {
    let mrlFound = false;

    const updatedTests = Object.fromEntries(
      Object.entries(testsSelected).map(([key, testObj]) => {
        if (testObj.test_category === "MRL") {
          // If MRL is found and this is the first one, set selectType to true
          const selectMRLType = !mrlFound;
          mrlFound = true; // Mark that we've encountered the first MRL
          return [key, { ...testObj, selectMRLType }];
        }

        // Return the test object as is for non-MRL tests
        return [key, testObj];
      }),
    );
    return updatedTests;
  };

  /**
   * Disable pesticides tests which are not selected
   * @param {*} name Pesticide test name
   * @returns {boolean}
   */
  const disableNonSelectedPesticideTests = (name) => {
    if (isEmpty(testsSelected)) {
      return false;
    }
    const testNames = Object.keys(testsSelected);
    const addedPesticideTestName = testNames.find((test) => test.toLowerCase().includes("pesticide"));
    return addedPesticideTestName && name.toLowerCase().includes("pesticide") && addedPesticideTestName !== name && sampleCategory !== "environment" && hasFeature("special_pesticides");
  };

  let sampleIDHasReplicate = false;
  if (replicateTagRegexFirstMatch.test(sampleID)) sampleIDHasReplicate = true;

  const disableTestListMod = (!isLoggedOut || thirdParty) && userTitle !== "Admin" && userTitle !== "Editor";
  const hideSaveForNext = submissionEditingSample || disableTestListMod;
  const tooltipDisable = !!((search.length > 0 && testSuggestions.length === 0) || isAddTestTip);

  return (
    <div className="new-submission-pages-card analysis-request">
      {isAddTest && (
        <AddTestModal onCancel={() => setIsAddTest(false)} testsSelected={testsSelected} toggleTestChoice={toggleTestChoice} searchValue={search} sampleCategory={sampleCategory} />
      )}
      {disableAddSample && (<div className="SampleSubmissionForm__DisableAddSample" />)}
      <div className="new-submission-pages-title-container withBlock">
        <div className="sample-submission-title-block" />
        <span>Analysis Request</span>
        {!submissionEditingSample && (
          <StyledTooltip title={isMaximized ? "Minimize" : "Maximize"}>
            <button className="AnalysisRequest__MaximizeButton" type="button" onClick={() => setIsMaximized(!isMaximized)}>
              {isMaximized ? <MinimizeIcon /> : <MaximizeIcon />}
            </button>
          </StyledTooltip>
        )}
      </div>
      <div className="analysis-request-search-box">
        <img alt="search icon" src={searchIcon} />
        <input
          placeholder="Search in Test Directory"
          type="text"
          onChange={handleChangeSearch}
          value={search}
          onKeyUp={handleHitEnter}
          maxLength={CHAR_LIMITS.analysis_request_search}
        />
        {admin
          && (
            <div className="analysis-request-search-div">
              <StyledTooltip
                placement="right"
                title="Can’t find the Test you’re looking for?
            Add it Here"
                className="TooltipComponent_Analysis"
                open={tooltipDisable}
              >
                <button
                  type="button"
                  className="new_test_button"
                  onClick={() => { setIsAddTest(!isAddTest); }}
                  onMouseEnter={() => { setIsAddTestTip(true); }}
                  onMouseLeave={() => { setIsAddTestTip(false); }}
                // disabled={isLoading}
                >
                  <PlusIcon width={20} height={20} />
                </button>
              </StyledTooltip>
            </div>
          )}
      </div>

      <h4 className="analysis-request-smart-suggestions-header">
        Suggestions
        {disableTestListMod && (
        <StyledTooltip
          title="Only an admin or editor can add/edit tests"
          placement="top"
        >
          <ExclamationIcon className="AnalysisRequest__InfoIcon" width={14} height={14} />
        </StyledTooltip>
        )}
      </h4>
      <div className="analysis-request-smart-suggestions-container">
        {!isLoading && (
          testSuggestions.length > 0 ? (
            <Scrollbar ref={testSuggestionsScrollbar}>
              <ul className="analysis-request-smart-suggestions-list">
                {testSuggestions.map(({
                  name, replicated, display, MRL,
                }, i) => {
                  const disableTest = (sampleIDHasReplicate && replicated) || disableTestListMod || disableNonSelectedPesticideTests(name);
                  return (
                    <StyledTooltip
                      title={toolTipText(display, replicated, sampleIDHasReplicate)}
                      placement="right"
                      key={`${name}-${i}`}
                    >
                      <li
                        key={name}
                        onClick={disableTest ? null : () => toggleTestChoice({
                          name, replicated, display, MRL,
                        })}
                        className={analysisClassName(disableTest, name)}
                      >
                        <Highlighter
                          highlightClassName="searchHighlight"
                          searchWords={[searched]}
                          autoEscape
                          textToHighlight={name}
                        />
                      </li>
                    </StyledTooltip>
                  );
                })}
              </ul>
            </Scrollbar>
          ) : (
            <div className="AnalysisRequest__NoSuggestionsContainer">
              <span className="analysis-request-no-tests-found">No tests found</span>
              {/* {search.trim() && (
                <>
                  <span className="AnalysisRequest__NoTestsFoundSubHeading">
                    Click the button below if you still want to add the test
                  </span>
                  <StyledTooltip
                    title={has(testsSelected, search.trim()) ? "Test has already been added" : ""}
                    placement="right"
                  >
                    <div
                      onClick={has(testsSelected, search.trim()) ? null : () => toggleTestChoice(search.trim())}
                      className={`AnalysisRequest__UserAddedTest ${has(testsSelected, search.trim()) ? "selected-test-suggestion" : "unselected-test-suggestion"}`}
                    >
                      <span>{search}</span>
                      <AddTestIcon fill="#AFBDCA" />
                    </div>
                  </StyledTooltip>
                </>
              )} */}
            </div>
          ))}
      </div>
      <LayoutGroup>
        {/* {search && !disableTestListMod && <ManuallyAddTestSection testsSelected={testsSelected} toggleTestChoice={toggleTestChoice} />} */}
        <motion.div layout className="analysis-request-tests-divider" />
        <motion.div layout className="analysis-request-tests-selected-and-checkbox-header">
          <div className="analysis-request-tests-selected-header-div">
            <h4 className="analysis-request-tests-selected-header">{`Tests Selected (${Object.keys(testsSelected).length})`}</h4>
            <h4
              className="analysis-request-tests-selected-clear"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleTestChoice("", "clear");
              }}
            >
              Clear All
            </h4>
          </div>
          {!hideSaveForNext && (
            <div className="sample-submission-checkbox-container analysis-requested-checkbox-container">
              <input id="tests-use-in-next-checkbox" type="checkbox" checked={saveTestsForNext ?? false} onChange={() => setSaveTestsForNext(!saveTestsForNext)} />
              <span aria-hidden className="sample-submission-checkmark" onClick={() => setSaveTestsForNext(!saveTestsForNext)} />
              <label htmlFor="tests-use-in-next-checkbox">Use in Next Sample</label>
            </div>
          )}
        </motion.div>
        <motion.div layout="position" className="analysis-request-tests-selected-container">
          {!isLoading && (
          <>
            {!isEmpty(testsSelected)
              ? (
                <Scrollbar>
                  <motion.ul layoutScroll="position" className="analysis-request-tests-selected-list">
                    {Array.from(Object.entries(updatedTestsSelectedWithSelectMRLType())).map(([name, testObj]) => (
                      <motion.div layout="position" key={name}>
                        <StyledTooltip
                          title={sampleIDHasReplicate && testObj.replicated ? "Cannot add a replicate test to a replicate sample" : ""}
                          placement="top"
                        >
                          {testObj.test_category === "MRL" && testObj.selectMRLType && sampleCategory !== "environment" && (hasFeature("special_pesticides") || mrl) ? (
                            <PesticideSampleAutocomplete
                              testName={name}
                              testObj={testObj}
                              disableTestListMod={disableTestListMod}
                              toggleTestChoice={toggleTestChoice}
                              sampleIDHasReplicate={sampleIDHasReplicate}
                              pesticideSample={pesticideSample}
                              setPesticideSample={setPesticideSample}
                              inputValuesRefs={inputValuesRefs}
                              setInputValuesRefs={setInputValuesRefs}
                              setInvalidInputFound={setInvalidInputFound}
                              sampleCategory={sampleCategory}
                            />
                          ) : (
                            <li key={name} className={`analysis-request-tests-selected-list-item ${sampleIDHasReplicate && testObj.replicated ? "AnalysisRequest__InvalidTest" : ""}`}>
                              {(testObj.test_category === "MRL" && (hasFeature("special_pesticides") || mrl)) && (
                              <StyledTooltip
                                title={`Sample Type: ${pesticideSample}`}
                                placement="top"
                              >
                                <ExclamationIcon className="AnalysisRequest__InfoIcon" width={14} height={14} />
                              </StyledTooltip>
                              )}
                              {name}
                              {!disableTestListMod && (
                              <div className="AnalysisRequest__CloseIcon" onClick={() => toggleTestChoice(name, "deselect")}>
                                <CloseIcon width={8} height={8} />
                              </div>
                              )}
                            </li>
                          )}
                        </StyledTooltip>
                      </motion.div>
                    ))}
                  </motion.ul>
                </Scrollbar>
              )
              : <div className="no-tests-added">No tests added</div>}
          </>
          )}
        </motion.div>
      </LayoutGroup>
      <AddUpdateSampleButton setEditingSubmittedSampleComposite={setEditingSubmittedSampleComposite} />
    </div>
  );
});

export default AnalysisRequest;

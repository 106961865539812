import React, { useState } from "react";
import { ReactComponent as GarbageBinIcon } from "../../../../../assets/images/environment/garbageBin.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/images/sample-submission/edit.svg";
import { ReactComponent as ErrorIcon } from "../../../../../assets/images/environment/redCircleWhiteExclamation.svg";
import "./UploadMap.css";
import "./UploadMapPreview.css";

export default function UploadMapPreview(props) {
  const {
    imgErrorMessage, src, imageTitle, imgSize, handleDeleteImage, handleChangeTitle,
  } = props;

  const [editingFileName, setEditingFileName] = useState(false);

  /**
   * Toggle the image title input
   */
  const handleEditFileName = () => {
    const curVal = editingFileName;
    setEditingFileName(!curVal);
  };

  /**
   * Disable the editing image title for all outside click events
   */
  const handleOutsideClick = () => {
    handleChangeTitle(null, true);
    setEditingFileName(false);
  };

  /**
   * When Enter is onclicked and user is editing the image title, disable the edit mode
   * @param {*} e
   */
  const hanldleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      handleOutsideClick();
    }
  };

  return (
    <>
      { imgErrorMessage.length === 0
        ? (
          <img
            src={src}
            alt="selected file preview"
            className="env-upload-map-preview"
          />
        )
        : (
          <div className="env-upload-map-error">
            <p className="env-upload-map-error-title">Error Uploading</p>
            <ErrorIcon />
            <p className="env-upload-map-error-content">{imgErrorMessage[0]}</p>
            <p className="env-upload-map-error-content">{imgErrorMessage[1]}</p>
          </div>
        )}

      <div className={editingFileName ? "env-upload-map-edit-container white-bg" : "env-upload-map-edit-container"}>
        <div className="env-upload-map-title">
          <div className="env-upload-map-title-name">
            {editingFileName
              ? (
                <input
                  autoFocus
                  type="text"
                  className="env-upload-map-title-edit-input"
                  onKeyDown={(e) => hanldleEnterKeyPress(e)}
                  onBlur={handleOutsideClick}
                  value={imageTitle}
                  onChange={handleChangeTitle}
                />
              )
              : (
                <>
                  <p className="env-upload-map-title-edit-input-display">{imageTitle}</p>
                  <EditIcon className="env-upload-map-title-edit-icon" onClick={handleEditFileName} />
                </>
              )}
          </div>
          { imgErrorMessage.length === 0
            ? (<span className="env-upload-map-img-size">{imgSize}</span>)
            : (
              <div className="env-upload-map-error-card">
                <p className="env-upload-map-error-card-content">
                  {imgErrorMessage[0]}
                </p>
                <p className="env-upload-map-error-card-content">
                  {imgErrorMessage[1]}
                </p>
              </div>
            )}
        </div>
        <GarbageBinIcon onClick={handleDeleteImage} className="env-upload-map-delete" />
      </div>
    </>
  );
}

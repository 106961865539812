import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as EditIcon } from "../../../../../../../../assets/images/sample-submission/edit.svg";
import DeleteIcon from "../../../../../../../../assets/images/product/template/DragClose.png";
import { ReactComponent as Checkmark } from "../../../../../../../../assets/images/product/template/inputCheckmark.svg";
import "./COABuilderInput.css";
import "../../../DragAndDrop/COABuilderDraggable.css";

export default function COABuilderInput({
  id,
  placeholder,
  setRef,
  maxLength = 250,
  defaultValue,
  value,
  isTextArea = false,
  label,
  inputClassName = "",
  // hasError,
  onChange,
  handleDelete,
  isEditing,
  toggleEdit,
}) {
  const inputRef = useRef();
  const [isHovered, setIsHovered] = useState();

  /**
   * On mount, set ref of input
   */
  useEffect(() => {
    if (setRef) {
      setRef(inputRef.current);
    }
  }, []); // eslint-disable-line

  const onChangeInternal = (val) => {
    if (onChange) {
      onChange(val);
    }
  };

  const handleToggleEdit = () => {
    if (isEditing) {
      setIsHovered(false);
    }
    toggleEdit();
  };

  return (
    <div
      className={`COABuilder__${isTextArea ? "TextArea" : "Input"}Parent${isEditing ? "--editing" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {label && (
      <label
        className="COABuilder__InputLabel"
        htmlFor={id}
      >
        {label}
      </label>
      )}
      <div className="COABuilder__InputDiv">
        {isTextArea ? (
          <textarea
            className={`COABuilder__TextArea${isEditing ? "--editing" : ""} ${inputClassName}`}
            ref={inputRef}
            readOnly={!isEditing}
            type="text"
            id={id}
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            autoComplete="off"
            maxLength={500}
            onChange={((e) => onChangeInternal(e.target.value))}
          />
        ) : (
          <input
            className={`COABuilder__Input${isEditing ? "--editing" : ""} ${inputClassName}`}
            ref={inputRef}
            readOnly={!isEditing}
            type="text"
            id={id}
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            autoComplete="off"
            maxLength={maxLength}
            onChange={((e) => onChangeInternal(e.target.value))}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter" && isEditing) {
            //     toggleEdit();
            //   }
            // }}
          />
        )}
        {!isEditing && (<span className="COABuilder__InputSpan">{inputRef.current?.value}</span>)}
        {isEditing && (
        <Checkmark
          className={`COABuilder__${isTextArea ? "TextArea" : "Input"}__CheckmarkIcon`}
          onClick={handleToggleEdit}
        />
        )}
      </div>
      {!isEditing && (
        <>
          <EditIcon
            className="COABuilder__EditIcon"
            onClick={handleToggleEdit}
          />
          {isHovered && (
          <div
            className="COABuilder__DeleteIconContainer"
            onClick={handleDelete}
          >
            <img
              src={DeleteIcon}
              alt="close icon"
              className="COABuilder__DeleteIcon"
            />
          </div>
          )}
        </>
      )}
    </div>

  );
}

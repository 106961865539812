import React, { Component, createRef } from "react";
import Scrollbar from "react-scrollbars-custom";
import ReactCardFlip from "react-card-flip";
import {
  Grid,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { toast } from "react-toastify";
import StyledTooltip from "../Common/UIComponents/StyledTooltip";
import DeleteProductConfirmation from "../Modals/DeleteProductConfirmation";
import IndexCategories from "./Components/IndexCategories";
import Search from "./Components/Search";
import ProductCard from "./Components/ProductCard";
import ProductEditCard from "./Components/ProductEditCard";
import ProductNewCard from "./Components/ProductNewCard";
import ProductDocuments from "./Components/ProductDocuments";
import "./Index.css";

import darkPlusIcon from "../../assets/images/index/addSquareMidnight.png";
import lightPlusIcon from "../../assets/images/index/addSquareBlue.png";

import {
  getProducts,
  createProduct,
  updateProduct,
  searchProducts,
  deleteProduct,
  addTag,
  deleteTag,
  updateTag,
} from "../../actions/index";
import { getProductFields } from "../../actions/reports";
import SortBySelect from "./Components/SortBySelect";

class Index extends Component {
  state = {
    loading: true,
    activeTab: "0",
    indices: [],
    showClearSearch: false,
    sortByOpSelected: -1,
    productListIndex: "Product",
    productList: [],
    hasProduct: false,
    indexOfProdDelete: -1,
    indexOfCardInEdit: -1, // in editing card/tags
    tagsEditing: {}, // to keep the sequence when updating tags: add new card/edit card/edit tag
    editProduct: null,
    editingProduct: false,
    addingProduct: false,
    editingTagsProductId: "",
    addItemButtonIcon: lightPlusIcon,
    displayProductDetails: false,
    productDetails: {},
    linkReportFields: [],
  };

  grid = createRef();

  cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 390,
    minHeight: 390,
  })

  searchAbortController = null;

  handleScroll = (e) => {
    const { scrollTop, scrollLeft } = e;
    const gridRef = this.grid.current;
    gridRef.handleScrollEvent({ scrollTop, scrollLeft });
  };

  apiGetLinkedFields = async () => {
    const result = await getProductFields();
    if (result && result.success) {
      const { fieldsMap, link_report } = result;
      if (!link_report || link_report.length === 0) {
        toast.error("Link report field(s) missing");
        return null;
      }
      const link_report_fields = link_report.map((json_field) => fieldsMap[json_field]);
      if (link_report_fields.some((elem) => !elem)) {
        toast.error("Link report field(s) missing from fields");
        return null;
      }
      return link_report_fields;
    }
    toast.error("Failed to fetch fields");
    return null;
  };

  initComponent = async (activeTab, activeTabName, indices) => {
    const linkReportFields = await this.apiGetLinkedFields();
    if (!linkReportFields) {
      return;
    }
    const { productListIndex } = this.state;
    this.setState({
      productListIndex: activeTabName || productListIndex,
      indices,
      activeTab,
      linkReportFields,
    }, () => this.getAllProducts(activeTab));
  }

  updateIndices = (indices) => {
    const { activeTab } = this.state;
    this.setState({ productListIndex: activeTab === "0" ? "Product" : indices[activeTab - 1], indices });
  }

  updateProductList = (activeTab, indices) => {
    this.onCancelEdit();
    this.onCancelEditTagsClick();
    this.onCancelAddProductClick();
    this.setState({
      productListIndex: activeTab === "0" ? "Product" : indices[activeTab - 1], indices, activeTab, loading: true,
    }, () => this.getAllProducts(activeTab, true, true));
  }

  handleAddCardClick = (e) => {
    e.preventDefault();
    const { editingProduct, addingProduct, editingTagsProductId } = this.state;
    if (!editingProduct && !addingProduct && !editingTagsProductId) {
      this.setState({ addingProduct: true, tagsEditing: { new0: [] }, addItemButtonIcon: lightPlusIcon });
    }
  }

  onCancelAddProductClick = () => {
    this.setState({ addingProduct: false, tagsEditing: {} });
  }

  // onEditTagsClick = (editingTagsProductId, tags) => {
  onEditTagsClick = (indexOfCardInEdit, editingTagsProductId) => {
    this.onCancelAddProductClick();
    this.onCancelEdit();
    const tagsEditing = {};
    const { productList } = this.state;
    // tagsEditing[editingTagsProductId] = tags.map(tag => tag.tag);
    tagsEditing[editingTagsProductId] = productList[indexOfCardInEdit].tags.map((tag) => tag.tag);
    this.setState({ editingTagsProductId, tagsEditing, indexOfCardInEdit });
  }

  onCancelEditTagsClick = () => {
    this.setState({ editingTagsProductId: "", tagsEditing: {}, indexOfCardInEdit: -1 });
  }

  handleProductNameClick = (e, item) => {
    this.setState({
      displayProductDetails: true,
      productDetails: item,
    });
  }

  handleBackArrowClick = () => {
    this.setState({
      displayProductDetails: false,
    });
  }

  handleDeleteTag = async (tag, productId) => {
    if (tag) {
      const { activeTab, indices, tagsEditing } = this.state;
      if (productId === "new0") {
        if (tagsEditing[productId]) {
          tagsEditing[productId] = tagsEditing[productId].filter((tagText) => tagText !== tag);
        }
        this.setState({ tagsEditing });
        return true;
      }
      const params = activeTab === "0" ? { tag, product_id: productId }
        : {
          flag: "index",
          tag,
          index_card_id: productId,
          index: indices[activeTab - 1],
        };
      const success = await this.apiDeleteTag(params, productId, tag);
      return success;
    }
    return false;
  }

  apiDeleteTag = async (params, productId, tag) => {
    const result = await deleteTag(params);

    if (result && result.success) {
      toast.success("Tag deleted successfully.");
      const { productList, indexOfCardInEdit } = this.state;
      productList[indexOfCardInEdit].tags = [...result[0].result[0].tags];
      this.setState(productList);
      const { tagsEditing } = this.state;
      const index = tagsEditing[productId].indexOf(tag);
      tagsEditing[productId].splice(index, 1);
      this.setState({ tagsEditing });
      return true;
    }
    toast.error(result.message);
    return false;
  };

  handleAddTag = async (tag, productId, otag = "") => {
    const { activeTab, indices, tagsEditing } = this.state;
    // add/edit tags of new item
    if (productId === "new0") {
      if (!tagsEditing[productId]) {
        tagsEditing[productId] = [];
      }
      let indexEditing = tagsEditing[productId].indexOf(tag);
      if (indexEditing === -1) {
        if (otag) {
          indexEditing = tagsEditing[productId].indexOf(otag);
          if (indexEditing !== -1) {
            tagsEditing[productId][indexEditing] = tag;
            this.setState({ tagsEditing });
            return true;
          }
        } else {
          tagsEditing[productId].push(tag);
          this.setState({ tagsEditing });
          return true;
        }
      }
      return false;
    // add/edit tag to existing item
    }
    let params;
    if (tag && tagsEditing[productId] && tagsEditing[productId].indexOf(tag) === -1) {
      if (!otag) {
        tagsEditing[productId].push(tag);
        this.setState({ tagsEditing });
        params = activeTab === "0" ? { tag, product_id: productId }
          : {
            flag: "index",
            tag,
            index_card_id: productId,
            index: indices[activeTab - 1],
          };
        const success = await this.apiAddTag(params, productId, tag);
        return success;
      }
      const index = tagsEditing[productId].indexOf(otag);
      tagsEditing[productId][index] = tag;
      this.setState({ tagsEditing });
      params = {
        product_id: productId,
        tag: otag,
        tag1: tag,
        activeTab,
        index: indices[activeTab - 1],
      };
      const success = await this.apiUpdateTag(params, productId, tag, otag);
      return success;
    }
    return false;
  }

  apiAddTag = async (params, productId) => {
    const result = await addTag(params);

    if (result && result.success) {
      toast.success("Tag added successfully.");
      const { productList, indexOfCardInEdit } = this.state;
      productList[indexOfCardInEdit].tags = [...result.data[0].result[0].tags];
      this.setState(productList);
      // this.getAllProducts(this.state.activeTab, true, true);
      return true;
    }
    const { tagsEditing } = this.state;
    tagsEditing[productId].pop();
    this.setState({ tagsEditing });
    return false;
  };

  apiUpdateTag = async (params, productId, tag, otag) => {
    const result = await updateTag(params);
    if (result && result.success) {
      toast.success("Tag updated successfully.");
      const { productList, indexOfCardInEdit } = this.state;
      productList[indexOfCardInEdit].tags = [...result[0].result[0].tags];
      this.setState(productList);
      return true;
    }
    const { tagsEditing } = this.state;
    const index = tagsEditing[productId].indexOf(tag);
    tagsEditing[productId][index] = otag;
    this.setState({ tagsEditing });
    return false;
  }

  handleClearSearch = () => {
    const { activeTab } = this.state;
    this.getAllProducts(activeTab, true, true);
  };

  resetSearch = () => {
    const search = document.getElementsByName("searchProductText")[0];
    if (search) {
      search.value = "";
      this.setState({ showClearSearch: false });
    }
  }

  resetSortBy = () => {
    this.setState({ sortByOpSelected: -1 });
  }

  updateStateForShowingClearButton = () => {
    this.setState({ showClearSearch: true });
  }

  // triggered when search input change
  handleSearchSubmit = (value) => {
    const { activeTab, indices, showClearSearch } = this.state;
    this.setState({ showClearSearch: value !== "" });

    if (value.trim() === "" && showClearSearch) {
      this.getAllProducts(activeTab, true, true);
    } else if (value.trim()) {
      let params = {
        input: value.trim(),
      };
      if (activeTab !== "0") {
        params = {
          flag: "index",
          index: indices[activeTab - 1],
          input: value.trim(),
        };
      }
      this.apiSearchProducts(params).then((productList) => {
        if (productList !== null) {
          this.setState({
            showClearSearch: true,
            productList,
            loading: false,
          });
          this.resetSortBy();
        }
      });
    }
  };

  apiSearchProducts = async (params) => {
    const newController = new AbortController();
    /** If prev search api still in progress, abort it */
    if (this.searchAbortController) {
      this.searchAbortController.abort();
    }
    this.searchAbortController = newController;
    const result = await searchProducts(params, newController.signal);

    if (newController.signal.aborted) {
      return null;
    }
    if (result && result.success) {
      this.searchAbortController = null;
      const { productList } = result;
      if (productList.length === 0) {
        return [];
      }
      return productList;
    }
    this.searchAbortController = null;
    toast.error(result.message);
    return [];
  };

  getAllProducts = async (selectedTab, resetSearch, resetSortBy) => {
    const { indices } = this.state;
    let params = { flag: "product" };
    if (selectedTab !== "0") {
      params = {
        flag: "index",
        index: indices[selectedTab - 1],
      };
    }

    const newController = new AbortController();
    /** If prev search api still in progress, abort it */
    if (this.searchAbortController) {
      this.searchAbortController.abort();
    }
    this.searchAbortController = newController;
    const result = await getProducts(params, newController.signal);

    if (newController.signal.aborted) {
      return;
    }

    if (result && result.success) {
      const productList = result.productList || [];

      if (resetSearch) {
        this.resetSearch();
      }

      if (resetSortBy) {
        this.resetSortBy();
      }

      this.setState({
        productList,
        hasProduct: productList.length !== 0,
        loading: false,
        activeTab: selectedTab,
      });
    } else {
      toast.error(result.message);
    }
    this.searchAbortController = null;
  };

  onEdit = (indexOfProdShowMenuOp, productId) => {
    this.onCancelAddProductClick();
    this.onCancelEditTagsClick();
    const { productList } = this.state;
    const item = productList[indexOfProdShowMenuOp];
    const tagsEditing = {
      [productId]: item.tags.map((tag) => tag.tag),
    };
    this.setState({
      editProduct: item || null,
      editingProduct: true,
      tagsEditing,
      indexOfCardInEdit: indexOfProdShowMenuOp,
    });
  }

  onSaveEdit = (product, productName, linkReportFieldsObj) => {
    const { activeTab, indices } = this.state;
    const newProduct = { ...product };
    if (activeTab === "0") {
      newProduct.product_name = productName;
      newProduct.item = linkReportFieldsObj;
      newProduct.product_image = "";
    } else {
      newProduct.card_name = productName;
      newProduct.flag = "index";
      newProduct.index = indices[activeTab - 1];
      newProduct.card_image = "";
    }
    this.apiToUpdateProduct(newProduct);
    this.onCancelEdit();
  };

  onCancelEdit = () => {
    this.setState({
      editProduct: null,
      editingProduct: false,
      tagsEditing: {},
      indexOfCardInEdit: -1,
    });
  };

  onSaveAdd = async (inputItemName, linkReportFieldsObj) => {
    const { activeTab } = this.state;
    let success;
    if (activeTab !== "0") {
      success = await this.onSaveAddIndex(inputItemName);
    } else {
      success = await this.onSaveAddProduct(inputItemName, linkReportFieldsObj);
    }

    // clear the search coz search may contain item and add item is been triggered
    if (success) {
      this.getAllProducts(activeTab, true, true);
      this.onCancelAddProductClick();
    }
  };

  onSaveAddProduct = async (productName, item) => {
    const { activeTab, tagsEditing } = this.state;
    const index = "new0";
    if (!tagsEditing[index]) {
      tagsEditing[index] = [];
    }

    const params = {
      flag: "product",
      product_name: productName,
      item,
      product_image: "",
    };

    const response = await createProduct(params);

    if (response && response.success && response.result.length > 0) {
      const { product_id: productId } = response.result[0];
      const success = this.addTagsToNewItem([...tagsEditing[index]], productId, activeTab);
      return success;
    }
    toast.error(response.message);
    return false;
  };

  onSaveAddIndex = async (productName) => {
    const { activeTab, indices, tagsEditing } = this.state;
    const index = "new0";
    if (!tagsEditing[index]) {
      tagsEditing[index] = [];
    }

    const params = {
      flag: "index",
      index: indices[activeTab - 1],
      card_name: productName,
    };
    const response = await createProduct(params);

    if (response && response.success && response.result) {
      const { index_card_id: productId } = response.result;
      const success = this.addTagsToNewItem([...tagsEditing[index]], productId, activeTab, indices);
      return success;
    }
    toast.error(response.message);
    return false;
  };

  addTagsToNewItem = async (tags, productId, activeTab, indices) => {
    await this.asyncForEach(tags, async (tag) => {
      let params;
      if (activeTab === "0") {
        params = { tag, product_id: productId };
      } else {
        params = {
          flag: "index",
          tag,
          index_card_id: productId,
          index: indices[activeTab - 1],
        };
      }
      const result = await addTag(params);
      if (!result || !result.success) {
        toast.error(`Fail to add tag: ${tag}`);
      }
    });
    return true;
  }

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      // eslint-disable-next-line no-await-in-loop
      await callback(array[index], index, array);
    }
  }

  apiToUpdateProduct = async (product) => {
    const response = await updateProduct(product);
    if (response && response.success) {
      const { activeTab } = this.state;
      this.getAllProducts(activeTab, true, true);
    } else {
      toast.error(response.message);
    }
  };

  onDeleteProduct = async () => {
    const {
      activeTab, indices, indexOfProdDelete, productList,
    } = this.state;
    const dltProduct = productList[indexOfProdDelete];
    let params = {
      flag: "product",
      product_id: dltProduct.product_id,
    };
    if (activeTab !== "0") {
      params = {
        flag: "index",
        index: indices[activeTab - 1],
        index_card_id: dltProduct.index_card_id,
      };
    }

    const response = await deleteProduct(params);
    if (response.success) {
      this.getAllProducts(activeTab, true, true);
      return true;
    }
    toast.error(response.message);
    return false;
  };

  onToggleDeleteProductModal = (itemIndex) => {
    const { indexOfProdDelete } = this.state;
    if (indexOfProdDelete !== -1) {
      this.setState({ indexOfProdDelete: -1 });
    } else {
      this.setState({
        indexOfProdDelete: itemIndex,
      });
    }
  };

  handleSortByOptionClick = (option) => {
    switch (option) {
      case "Date added":
        this.sortByDateCreated();
        this.setState({ sortByOpSelected: 0 });
        break;
      case "Name":
        this.sortByName();
        this.setState({ sortByOpSelected: 1 });
        break;
      case "Most tags":
        this.sortByMostTags(false);
        this.setState({ sortByOpSelected: 2 });
        break;
      default:
    }
  }

  sortByName = () => {
    const { productList, activeTab } = this.state;

    // sort by name
    if (activeTab === "0") {
      const sortedList = productList.sort((a, b) => {
        const nameA = a.product_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.product_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      this.setState({ productList: sortedList });
    } else {
      const sortedList = productList.sort((a, b) => {
        const nameA = a.card_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.card_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      this.setState({ productList: sortedList });
    }
  }

  sortByDateCreated = () => {
    const { productList } = this.state;

    const sortedList = productList.sort((a, b) => {
      const dateA = a.created_on.split("/");
      const dateB = b.created_on.split("/");
      return new Date(dateA[2], dateA[0] - 1, dateA[1]) - new Date(dateB[2], dateB[0] - 1, dateB[1]);
    }).reverse();

    this.setState({
      productList: sortedList,
    });
  }

  sortByMostTags = () => {
    const { productList } = this.state;

    const sortedList = productList.sort((a, b) => a.tags.length - b.tags.length).reverse();

    this.setState({
      productList: sortedList,
    });
  }

  render() {
    const {
      loading,
      activeTab,
      showClearSearch,
      productList,
      indexOfProdDelete,
      tagsEditing,
      hasProduct,
      editProduct,
      editingProduct,
      addingProduct,
      editingTagsProductId,
      displayProductDetails,
      productDetails,
      productListIndex,
      sortByOpSelected,
      addItemButtonIcon,
      linkReportFields,
    } = this.state;

    const {
      onChangeTab,
    } = this.props;

    const sortProductOption = ["Date added", "Name", "Most tags"];

    return (
      <>
        {!loading && indexOfProdDelete !== -1
          && (
          <DeleteProductConfirmation
            cardName={productList[indexOfProdDelete].product_name || productList[indexOfProdDelete].card_name}
            onDeleteProduct={this.onDeleteProduct}
            onToggleModal={this.onToggleDeleteProductModal}
          />
          )}
        {displayProductDetails
          ? (
            <ProductDocuments
              product={productDetails}
              productListIndex={productListIndex}
              handleBackArrowClick={this.handleBackArrowClick}
              linkReportFields={linkReportFields}
            />
          )
          : (
            <div className="IndexPageContainer">
              <IndexCategories
                readOnly={addingProduct || editingProduct || editingTagsProductId}
                initComponent={this.initComponent}
                onChangeTab={onChangeTab}
                updateIndices={this.updateIndices}
                updateProductList={this.updateProductList}
                getAllProducts={this.getAllProducts}
              />
              {!loading && (activeTab !== "0" || linkReportFields.length > 0) // only show products for Product tab if linkReportFields non-empty
              && (
              <div className="IndexPageBackground">
                <div className="IndexPageMainContainer">
                  {hasProduct && (
                  <div className="IndexPageMainContainer__SearchContainer">
                    <Search
                      readOnly={addingProduct || editingProduct || editingTagsProductId}
                      // onChange={this.handleSearchChange}
                      handleSearchSubmit={this.handleSearchSubmit}
                      showClearSearch={showClearSearch}
                      handleClearSearch={this.handleClearSearch}
                      updateStateForShowingClearButton={this.updateStateForShowingClearButton}
                      activeTab={activeTab}
                    />
                    <SortBySelect
                      selectedOptionIdx={sortByOpSelected}
                      options={sortProductOption}
                      disabled={addingProduct || editingProduct || editingTagsProductId}
                      onSelect={this.handleSortByOptionClick}
                    />
                  </div>
                  )}

                  {hasProduct
                    ? (
                      <div className="IndexPageMainContainer__ProductCardsContainer ProductCardsContainer--hasProducts">
                        <div className="ProductCardsContainer__PaddedContainer">
                          <div className="AddProductCardBtn">
                            <StyledTooltip
                              title="Add a new Card"
                              showArrow={false}
                              placement="top"
                            >
                              <img
                                src={addItemButtonIcon}
                                alt="add product card"
                                onClick={this.handleAddCardClick}
                                onMouseEnter={() => this.setState({ addItemButtonIcon: darkPlusIcon })}
                                onFocus={() => this.setState({ addItemButtonIcon: darkPlusIcon })}
                                onMouseOut={() => this.setState({ addItemButtonIcon: lightPlusIcon })}
                                onBlur={() => this.setState({ addItemButtonIcon: lightPlusIcon })}
                              />
                            </StyledTooltip>
                          </div>
                          <AutoSizer>
                            {({ width, height }) => {
                              const columnCount = Math.max(Math.floor(window.innerWidth / 400), 1); // 400 = column max width
                              const nCard = addingProduct ? productList.length + 1 : productList.length;
                              const rowCount = Math.ceil(nCard / columnCount);
                              const singleRowHeight = 440;
                              return (
                                <div style={{ height, width }}>
                                  <Scrollbar
                                    onScroll={this.handleScroll}
                                    className="ProductCardsContainer__PaddedContainer__ScrollbarContainer"
                                  >
                                    <Grid
                                      ref={this.grid}
                                      columnCount={columnCount}
                                      columnWidth={width / columnCount}
                                      width={width}
                                      height={height - 12}
                                      rowHeight={rowCount < 2 ? singleRowHeight : this.cache.rowHeight}
                                      deferredMeasurementCache={this.cache}
                                      style={{ overflowX: false, overflowY: false }}
                                      className="IndexGrid"
                                      rowCount={rowCount}
                                      cellRenderer={({
                                        columnIndex, key, rowIndex, style, parent,
                                      }) => {
                                        const colIdx = addingProduct ? (rowIndex * columnCount + columnIndex - 1) : (rowIndex * columnCount + columnIndex);
                                        const item = productList[colIdx];

                                        return (
                                          <CellMeasurer
                                            key={key}
                                            cache={this.cache}
                                            parent={parent}
                                            columnIndex={columnIndex}
                                            rowIndex={rowIndex}
                                          >
                                            {/* eslint-disable-next-line */}
                                            {addingProduct && columnIndex === 0 && rowIndex === 0
                                              ? (
                                                <div style={{ ...style, zIndex: productList.length + 1, padding: columnIndex === columnCount - 1 ? "10px 50px 10px 10px" : "10px" }}>
                                                  <div style={{ paddingBottom: rowIndex === rowCount - 1 ? "50px" : "0px" }}>
                                                    <ProductNewCard
                                                      activeTab={activeTab}
                                                      tagsEditing={tagsEditing}
                                                      isMostRight={columnIndex === columnCount - 1}
                                                      onCancelAddProductClick={this.onCancelAddProductClick}
                                                      handleAddTag={this.handleAddTag}
                                                      handleDeleteTag={this.handleDeleteTag}
                                                      onSaveAdd={this.onSaveAdd}
                                                      linkReportFields={linkReportFields}
                                                      cancelButton
                                                    />
                                                  </div>
                                                </div>
                                              )
                                              : item === undefined
                                                ? <div style={style} />
                                                : (
                                                  <div key={key} style={{ ...style, zIndex: productList.length - colIdx, padding: columnIndex === columnCount - 1 ? "10px 50px 10px 10px" : "10px" }}>
                                                    <div style={{ paddingBottom: rowIndex === rowCount - 1 ? "50px" : "0px", height: "100%" }}>
                                                      <ReactCardFlip
                                                        isFlipped={editingProduct && (activeTab === "0"
                                                          ? item.product_id === editProduct.product_id
                                                          : item.index_card_id === editProduct.index_card_id)}
                                                        flipDirection="horizontal"
                                                        containerStyle={{ height: "100%" }}
                                                      >
                                                        <ProductCard
                                                          activeTab={activeTab}
                                                          item={item}
                                                          itemIndex={colIdx}
                                                          tagsEditing={tagsEditing}
                                                          readOnly={addingProduct || editingProduct}
                                                          editingTagsProductId={editingTagsProductId}
                                                          isMostRight={columnIndex === columnCount - 1}
                                                          handleAddTag={this.handleAddTag}
                                                          handleDeleteTag={this.handleDeleteTag}
                                                          onToggleModal={this.onToggleDeleteProductModal}
                                                          onEdit={this.onEdit}
                                                          handleProductNameClick={this.handleProductNameClick}
                                                          onEditTagsClick={this.onEditTagsClick}
                                                          onCancelEditTagsClick={this.onCancelEditTagsClick}
                                                          linkReportFields={linkReportFields}
                                                        />
                                                        { editingProduct && (activeTab === "0" ? item.product_id === editProduct.product_id : item.index_card_id === editProduct.index_card_id)
                                                  && (
                                                  <ProductEditCard
                                                    activeTab={activeTab}
                                                    item={editProduct}
                                                    tagsEditing={tagsEditing}
                                                    isMostRight={columnIndex === columnCount - 1}
                                                    onCancelEdit={this.onCancelEdit}
                                                    onSaveEdit={this.onSaveEdit}
                                                    handleAddTagParent={this.handleAddTag}
                                                    handleDeleteTagParent={this.handleDeleteTag}
                                                    linkReportFields={linkReportFields}
                                                  />
                                                  )}
                                                      </ReactCardFlip>
                                                    </div>
                                                  </div>
                                                )}
                                          </CellMeasurer>
                                        );
                                      }}
                                    />
                                  </Scrollbar>
                                </div>
                              );
                            }}
                          </AutoSizer>
                        </div>
                      </div>
                    )
                    : (
                      <div className="IndexPageMainContainer__ProductCardsContainer ProductCardsContainer--noProducts">
                        <Scrollbar
                          disableTrackYWidthCompensation
                          disableTracksWidthCompensation
                        >
                          <div className="ProductCardsContainer__NoProductsRow">
                            <div className="ProductCardsContainer__NoProductsRow__NewProductCardDiv">
                              <ProductNewCard
                                key={activeTab}
                                activeTab={activeTab}
                                linkReportFields={linkReportFields}
                                tagsEditing={tagsEditing}
                                onCancelAddProductClick={this.onCancelAddProductClick}
                                handleAddTag={this.handleAddTag}
                                handleDeleteTag={this.handleDeleteTag}
                                onSaveAdd={this.onSaveAdd}
                                cancelButton={false}
                              />
                            </div>
                          </div>
                        </Scrollbar>
                      </div>
                    )}
                </div>
              </div>
              )}
            </div>
          )}
      </>
    );
  }
}

export default Index;

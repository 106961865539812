import React from "react";
import ContentLoader from "react-content-loader";

const ListViewContentLoader = ({ amount }) => {
  const loaderArray = new Array(amount).fill(0);
  return loaderArray.map((item, i) => (
    <ContentLoader
      key={i}
      speed={2}
      width={373}
      height={88}
      viewBox="0 0 373 88"
      backgroundColor="#e5e5e5"
      foregroundColor="#f9f9f9"
    >
      {/* <rect x="0" y="0" rx="6" ry="6" width="363" height="78" /> */}
      {/* <rect x="0" y="0" rx="6" ry="6" width="373" height="2" />
      <rect x="0" y="0" rx="6" ry="6" width="2" height="88" />
      <rect x="0" y="86" rx="6" ry="6" width="373" height="2" />
      <rect x="371" y="0" rx="6" ry="6" width="2" height="88" />
      <rect x="0" y="0" rx="0" ry="0" width="8" height="88" /> */}
      <rect x="23" y="18" rx="6" ry="6" width="140" height="12" />
      <rect x="23" y="36" rx="6" ry="6" width="215" height="12" />
      <rect x="23" y="54" rx="6" ry="6" width="181" height="12" />
      <rect x="298" y="57" rx="6" ry="6" width="62" height="12" />
      <rect x="336" y="10" rx="12" ry="12" width="24" height="25" />
    </ContentLoader>
  ));
};

export default ListViewContentLoader;

/* eslint-disable */
export const checkMapClicked = (clickX, clickY) => {
  const mapWrapper = document.getElementsByClassName("react-transform-wrapper transform-component-module_wrapper__1_Fgj ")[0];
  if (mapWrapper) {
    const bounds = mapWrapper.getBoundingClientRect();
    // console.log("map bounds", bounds);
    // console.log("click", clickX, clickY);
    const {
      x, y, width: mapWidth, height: mapHeight,
    } = bounds;
    const mapX = x;
    const mapY = y;
    const result = (clickX < (mapX + mapWidth)) && (clickX > mapX) && (clickY < (mapY + mapHeight)) && (clickY > mapY);
    // console.log(result);
    return result;
  }
  return false;
};

import React, { Component } from "react";

import { Select } from "antd";
import { Slide } from "react-awesome-reveal";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

import { AppContext } from "../../AppContext";
import StyledButton from "../Common/UIComponents/StyledButton";
import LoginInput from "./Common/LoginInput";

import { s3EsvPriv } from "../../utils/aws";
import { setUserCredentials } from "../../utils/common";

import { esvSupportLogin } from "../../actions/login";

import loginBgIcon from "../../assets/images/loginBgImg.svg";
import loginMainIcon from "../../assets/images/loginMainImg.svg";
import esv_logo from "../../assets/images/logo/esv_logo_2x.png";

import "./supportLogin.css";
import "./login.css";
import "./Common/LoginInput.css";

let jsonFile = "";

class SupportLogin extends Component {
    state = {
      companyName: "",
      companyDomain: "",
      email: "",
      password: "",
      emailError: false,
      passwordError: false,
      selectError: false,
      slideOut: false,
      dropDownOptions: [],
      loginLoading: false,
    }

    passwordInput = React.createRef();

    componentDidMount = async () => {
      // Load the authentication file
      s3EsvPriv.getObject(
        { Bucket: s3EsvPriv.config.params.bucket, Key: "media/email_files/users.xlsx", ResponseCacheControl: "no-cache" },
        (error, data) => {
          const blob = new Blob([data.Body], { type: data.ContentType });
          const fr = new FileReader();

          fr.onloadend = (e) => {
            // jsonFile = JSON.parse(e.target.result);

            const workbook = XLSX.read(e.target.result, {
              type: "binary",
            });

            workbook.SheetNames.forEach((sheetName) => {
              const rowObj = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

              jsonFile = rowObj;
            });
          };

          fr.readAsBinaryString(blob);

          if (error) {
            // console.log(error);
          }
        },
      );

      // Load dropdown list options
      const response = await esvSupportLogin();
      if (response.success) {
        const dropDownOptions = [];
        response.data.result.forEach(({ company_domain, company_name: companies }) => {
          dropDownOptions.push({ label: company_domain, options: companies.map((company_name) => ({ label: company_name, value: `${company_name}${company_domain}` })) });
        });
        this.setState({
          dropDownOptions,
        });
      }
    }

    onChange = (e) => {
      const { name, value } = e.target;
      this.setState({
        [name]: value,
      });
    };

    onSubmit = async () => {
      const { history } = this.props;
      const {
        email, password, companyName, companyDomain,
      } = this.state;
      let atLeastOneError = false;

      if (!email) {
        this.setState({ emailError: true });
        atLeastOneError = true;
      }

      if (!password) {
        this.setState({ passwordError: true });
        atLeastOneError = true;
      }

      if (!companyName || !companyDomain) {
        this.setState({ selectError: true });
        atLeastOneError = true;
      }

      if (atLeastOneError) {
        return;
      }

      // Make sure drop down has something selected
      // if (companyName && companyDomain) {
      const success = this.authenticateWithFile(email, password, companyName);

      if (success) {
        this.setState({ loginLoading: true }, async () => {
          const userDetails = await this.getUserPayloadFromDomain(companyDomain, companyName);
          if (userDetails.success) {
            const data = {
              company_domain: userDetails.companyDomain,
              user_payload: userDetails.payload,
              uid: "support",
              user_title: "Admin",
              rememberMe: true,
              company_name: companyName,
            };
            setUserCredentials("local", data, this.context);

            this.setState({ slideOut: true, loginLoading: false }, () => {
              window.setTimeout(() => {
                this.setState({ email: "", password: "" });
                history.push(("/sample-submission"));
              }, 500);
            });
          } else {
            this.setState({ loginLoading: false });
            toast.error(userDetails.message);
          }
        });
      }
    }

    // API call to get the proper user payload
    getUserPayloadFromDomain = async (companyDomain, selectedCompany) => {
      const result = await esvSupportLogin(companyDomain, selectedCompany);
      let obj = {
        payload: "",
        companyDomain: "",
        message: "",
        success: result.success,
      };

      if (result.success) {
        obj = {
          payload: result.data.result.user_payload,
          companyDomain,
          message: result.data.message,
          success: result.success,
        };

        return obj;
      }
      obj.success = result.success;
      obj.message = result.data;

      return obj;
    }

    // Function to authenticate the user with data from the xlsx file instead of firebase
    authenticateWithFile = (email, pass, companyName) => {
      let validEmail = false;
      const userName = email.split("@")[0];
      const accessInfo = jsonFile.filter(({ user }) => userName.toLowerCase() === user.toLowerCase());
      if (accessInfo.length) {
        const {
          password,
          companyName: companiesAccessible,
        } = accessInfo[0];
        validEmail = true;
        if (pass === password) {
          if (companiesAccessible === "all") {
            return true;
          } if (companiesAccessible.toLowerCase().includes(companyName.toLowerCase())) {
            return true;
          }
          toast.error("Please see an admin. You do not have access to this company");
        } else {
          toast.error("Incorrect password.");
        }
      }

      if (!validEmail) {
        toast.error("Invalid email.");
      }

      return false;
    }

    onInvite = () => {
      const { history } = this.props;
      history.push("/signup/invite");
    }

    onSelect = (value, { label: company_name }) => {
      const { selectError, email } = this.state;
      if (selectError) {
        this.setState({ selectError: false });
      }
      const company_domain = value.split(company_name)[1];
      const formattedEmail = email.split("@");
      this.setState({ companyDomain: company_domain, companyName: company_name, email: `${formattedEmail[0]}@${company_domain}` });
    }

    render() {
      const {
        email,
        password,
        emailError,
        passwordError,
        selectError,
        slideOut,
        dropDownOptions,
        loginLoading,
      } = this.state;

      return (
        <Slide direction="left" triggerOnce reverse={slideOut}>
          <img src={esv_logo} alt="esv" className="logoLoginPage" />
          <div className="login-page">
            <div className="loginMainContainer">
              <div className="login-box">
                <div className="login-header">
                  Log in
                </div>

                <div className="login-header-subtitle">
                  For internal use only. Log in with your information provided by an admin.
                </div>

                <label htmlFor="login-email-input" className="Login__Input__Label">Email ID (Enter name only)</label>
                <LoginInput
                  id="login-email-inputid"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    if (emailError && e.target.value) {
                      this.setState({ emailError: false });
                    }
                    this.onChange(e);
                  }}
                  status={emailError ? "error" : undefined}
                />
                <div className="support-login-company-domain">
                  {/* form with autocomplete off needed to disable browser's autofill dropdown (it was blocking the select dropdown) */}
                  <form autoComplete="off" style={{ width: "unset", padding: "unset" }}> {/* eslint-disable-line */}
                    <label htmlFor="support-login-select" className="Login__Input__Label">Company</label>
                    <Select
                      id="support-login-select"
                      placeholder="Please select a company"
                      showSearch
                      onSelect={this.onSelect}
                      options={dropDownOptions}
                      status={selectError ? "error" : undefined}
                      rootClassName="SupportLogin__Select"
                      popupClassName="SupportLogin__Select__Popup"
                    />
                  </form>
                </div>
                <LoginInput
                  label="Password"
                  isPassword
                  id="support-login-password"
                  name="password"
                  value={password}
                  onChange={(e) => this.onChange(e)}
                  error={passwordError}
                  clearError={() => this.setState({ passwordError: false })}
                  inputRef={this.passwordInput}
                  onEnter={this.onSubmit}
                />

                <div className="support-login-login">
                  <StyledButton
                    type="primary"
                    className="Login__Button"
                    loading={loginLoading}
                    disabled={loginLoading}
                    onClick={() => this.onSubmit()}
                  >
                    Log In
                  </StyledButton>
                </div>
                {/* <div className="login-dont-have-account-container">
                  <u className="signUp-link" onClick={this.onInvite}>Invite admin</u>
                </div> */}
              </div>

              <div className="loginImage">
                <img
                  className="loginImageCharacters"
                  src={loginMainIcon}
                  alt="esv"
                />
                <img
                  className="loginImageBubbles"
                  src={loginBgIcon}
                  alt="esv"
                />
              </div>
            </div>
          </div>
        </Slide>
      );
    }
}

SupportLogin.contextType = AppContext;
export default SupportLogin;

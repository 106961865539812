import React from "react";
import SpecManagerInput from "./SpecManagerInput";
import { UNIT_OPTIONS } from "./Helper";
import "./SpecsQualitative.css";
import SpecManagerSelect from "./SpecManagerSelect";

class SpecsQualitative extends React.Component {
  constructor(props) {
    super(props);
    const { data, newQualitative } = this.props;

    let number = "";
    if (newQualitative) {
      number = "25";
    } else if (data[1]) {
      number = data[1].replace("/", "");
    }

    this.state = {
      qualNumber: number,
    };
  }

  componentDidMount() {
    const { newQualitative, data, handleDataChange } = this.props;
    const { qualNumber } = this.state;

    if (newQualitative) handleDataChange([data[0], qualNumber, data[2]]);
  }

  onInputChange = (value) => {
    const { data, handleDataChange } = this.props;
    handleDataChange([data[0], value, data[2]]);
    this.setState({
      qualNumber: value,
    });
  }

  handlePositiveChange = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    const { data, handleDataChange } = this.props;
    handleDataChange([value, data[1], data[2]]);
  }

  onDropdownChange = (value) => {
    const { data, handleDataChange } = this.props;
    handleDataChange([data[0], data[1], value]);
  }

  render() {
    const { data } = this.props;
    const { qualNumber } = this.state;
    const [max, number, unit] = data; //eslint-disable-line

    return (
      <div
        className="environmentReportSpecManagerContainer"
        id="environmentReportSpecManagerSpecsQualitative"
      >
        <div />
        <div>
          <div className="environmentReportSpecManagerSelectButtonNegative">
            Negative
          </div>
        </div>
        <SpecManagerInput
          placeHolder="eg: 27"
          onChange={this.onInputChange}
          value={qualNumber}
          width="72px"
        />
        <SpecManagerSelect
          width="114px"
          optionSelected={unit || "None"}
          options={UNIT_OPTIONS}
          onSelect={this.onDropdownChange}
        />
      </div>
    );
  }
}

export default SpecsQualitative;

import React from "react";
import {
  View, StyleSheet, Text,
} from "@react-pdf/renderer";

export default function PDFFooter() {
  // const { companyName } = props;

  const styles = StyleSheet.create({
    pageFooter: {
      position: "absolute",
      bottom: "9",
      right: "18",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    pageFooterText: {
      fontSize: "11",
      color: "#6C7E8E",
    },
    companyName: {
      textTransform: "uppercase",
    },
  });

  return (
    <View style={styles.pageFooter} fixed>
      {/* <Text style={[styles.pageFooterText, styles.companyName]}>
        {companyName || " "}
      </Text> */}
      <Text
        style={styles.pageFooterText}
        render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber}/${totalPages}`
        )}
      />
    </View>
  );
}

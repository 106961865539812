import React from "react";
import "./CheckboxInput.css";

export default function CheckboxInput(props) {
  const {
    handleCheckClicked,
    disabled,
    checked,
    index,
  } = props;

  if (disabled) {
    return (
      <label className="checkboxContainer disabled">
        <span className="checkmark" style={{ top: "-5px" }} />
      </label>
    );
  }

  return (
    <label className="checkboxContainer">
      <input
        name="single"
        type="checkbox"
        onChange={(e) => { e.stopPropagation(); handleCheckClicked(index); }}
        checked={checked ?? false}
      />
      <span className="checkmark blueCheckmark" style={{ top: "-5px" }} />
    </label>
  );
}

import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { AES } from "crypto-js";
import { AES_128_BIT_KEY } from "../../../utils/Constant";
import { inviteAdmin } from "../../../actions/signup";
import "./SignUpInvite.css";
import CustomerInfoSection from "./Components/CustomerInfoSection";
import InfoInputSection from "./Components/InfoInputSection";
import esv_logo from "../../../assets/images/logo/esv_logo_2x.png";
import InviteButton from "./Components/InviteButton";

export default function SignUpInvite({ history }) {
  const customerInfoRefs = useRef({ company_name: null, email_address: null, customer_id: null });
  const [showInfoInputSection, setShowInfoInputSection] = useState({ prod: true, env: false });
  const [isSendingInvite, setIsSendingInvite] = useState(false);

  const infoInputRefs = useRef({
    prod: {
      product_link: null,
      delimeter: null,
      sample_id_format: null,
      default_sample_id: null,
      default_sample_type: null,
      fields: [],
    },
    env: {
      location_link: null,
      delimeter: null,
      sample_id_format: null,
      default_sample_id: null,
      default_sample_type: null,
      fields: [],
    },
  });

  const [errorsFromParent, setErrorsFromParent] = useState({
    customer: {
      company_name: false,
      email_address: false,
      customer_id: false,
    },
    prod: {
      product_link: false,
      delimeter: false,
      sample_id_format: false,
      default_sample_id: false,
      default_sample_type: false,
      fields: [],
    },
    env: {
      location_link: false,
      delimeter: false,
      sample_id_format: false,
      default_sample_id: false,
      default_sample_type: false,
      fields: [],
    },
  });

  /**
   * Set ref for given customer info field
   * @param {String} field company_name, email_address, or customer_id
   * @param {HTMLInputElement} ref ref to field input
   */
  const setCustomerFieldRef = (field, ref) => {
    customerInfoRefs.current[field] = ref;
  };

  /**
   * Set ref for given product or environment fixed field
   * @param {String} field product_link/location_link, delimeter, sample_id_format, default_sample_id, default_sample_type
   * @param {HTMLInputElement} ref ref to field input
   * @param {String} type prod or env
   */
  const setFixedFieldRef = (field, ref, type) => {
    // console.log(field, ref, type);
    infoInputRefs.current[type][field] = ref;
  };

  /**
   * Set ref for custom field
   * @param {Number} idx index of custom field in prod.fields or env.fields
   * @param {String} field json_field, title_field, or display
   * @param {HTMLInputElement} ref ref to field text or checkbox input
   * @param {String} type prod or env
   */
  const setCustomFieldRef = (idx, field, ref, type) => {
    // console.log(idx, field, ref, infoInputRefs.current);
    if (idx >= infoInputRefs.current[type].fields.length) {
      infoInputRefs.current[type].fields.push({});
    }
    infoInputRefs.current[type].fields[idx][field] = ref;
  };

  /**
   * Delete custom field from prod.fields or env.fields
   * @param {Number} idx index of custom field in prod.fields or env.fields
   * @param {String} type prod or env
   */
  const deleteCustomFieldRef = (idx, type) => {
    infoInputRefs.current[type].fields.splice(idx, 1);
    if (errorsFromParent[type].fields[idx]) {
      errorsFromParent[type].fields.splice(idx, 1);
      const deepCopy = {};
      deepCopy.customer = { ...errorsFromParent.customer };
      deepCopy.prod = { ...errorsFromParent.prod };
      deepCopy.env = { ...errorsFromParent.env };
      deepCopy.prod.fields = [...errorsFromParent.prod.fields];
      deepCopy.env.fields = [...errorsFromParent.env.fields];
      setErrorsFromParent(deepCopy);
    }
  };

  /**
   * Set error value for field
   * @param {String} field any fixed or custom field
   * @param {Boolean} error true if there is an error, false otherwise
   * @param {String} type customer, prod, or env
   * @param {Number} idx if defined, index of custom field in prod.fields or env.fields
   */
  const setFieldError = (field, error, type, idx) => {
    let _type;
    if (type === "" || type === "customer") {
      _type = "customer";
    } else if (type === "environment_info" || type === "env") {
      _type = "env";
    } else {
      _type = "prod";
    }
    if (idx !== undefined) {
      if (idx >= errorsFromParent[_type].fields.length) {
        for (let index = errorsFromParent[_type].fields.length - 1; index < idx; index++) {
          errorsFromParent[_type].fields.push({});
        }
      }
      errorsFromParent[_type].fields[idx][field] = error;
    } else {
      errorsFromParent[_type][field] = error;
    }
    const deepCopy = {};
    deepCopy.customer = { ...errorsFromParent.customer };
    deepCopy.prod = { ...errorsFromParent.prod };
    deepCopy.env = { ...errorsFromParent.env };
    deepCopy.prod.fields = [...errorsFromParent.prod.fields];
    deepCopy.env.fields = [...errorsFromParent.env.fields];
    setErrorsFromParent(deepCopy);
  };

  /**
   * Get error value for field
   * @param {String} field any fixed or custom field
   * @param {String} type customer, prod, or env
   * @param {Number} idx if defined, index of custom field in prod.fields or env.fields
   */
  const getFieldError = (field, type, idx) => {
    let _type;
    if (type === "" || type === "customer") {
      _type = "customer";
    } else if (type === "environment_info" || type === "env") {
      _type = "env";
    } else {
      _type = "prod";
    }
    if (idx !== undefined) {
      if (errorsFromParent[type].fields.length && errorsFromParent[type].fields[idx] && errorsFromParent[type].fields[idx][field]) {
        return errorsFromParent[type].fields[idx][field];
      }
      return false;
    }
    return errorsFromParent[_type][field];
  };

  /**
   * Reset all fields to false for the type
   * @param {String} type "prod" or "env"
   */
  const resetInfoInputSectionErrors = (type) => {
    errorsFromParent[type] = {
      location_link: false,
      delimeter: false,
      sample_id_format: false,
      default_sample_id: false,
      default_sample_type: false,
      fields: [],
    };
    const deepCopy = {};
    deepCopy.customer = { ...errorsFromParent.customer };
    deepCopy.prod = { ...errorsFromParent.prod };
    deepCopy.env = { ...errorsFromParent.env };
    deepCopy.prod.fields = [...errorsFromParent.prod.fields];
    deepCopy.env.fields = [...errorsFromParent.env.fields];
    setErrorsFromParent(deepCopy);
  };

  /**
   * Takes in an array of ref objects and returns an array with the refs' values
   * @param {Array} customFieldsRefsArr array of {json_field: ref, title_field: ref, display: ref} objects
   * @param {String} type "product_info" or "environment_info"
   * @returns array of {json_field: val, title_field: val, display: val} objects
   */
  const getCustomFieldVals = (customFieldsRefsArr, type) => {
    let missingField = false;
    let invalidInput = false;
    let duplicate = false;
    let atLeastOneDisplayed = false;

    const fieldSet = new Set();
    const customFieldsValsArr = customFieldsRefsArr.map((customFieldRefObj, idx) => {
      const customFieldObj = {};
      Array.from(Object.keys(customFieldRefObj)).forEach((field) => {
        const ref = customFieldRefObj[field];
        if (field === "display") {
          // if (type === "product_info") {
          const selectedVals = [].filter.call(ref.options, (o) => o.selected).map((o) => o.value);
          if (!selectedVals.length) {
            customFieldObj[field] = "";
            missingField = true;
            setFieldError(field, true, type, idx);
          } else {
            const val = selectedVals.length > 1 ? "1" : selectedVals[0];
            customFieldObj[field] = val;
            if (val === "1") {
              atLeastOneDisplayed = true;
            }
          }
          // } else {
          //   customFieldObj[field] = ref.checked ? "1" : "0";
          //   if (ref.checked) {
          //     atLeastOneDisplayed = true;
          //   }
          // }
        } else {
          const val = ref.value.trim();
          if (val) {
            customFieldObj[field] = val;
            if (field === "json_field") {
              if (fieldSet.has(val)) {
                duplicate = true;
                setFieldError(field, true, type, idx);
              } else {
                if (ref.classList.contains("signup-invite-input-error")) {
                  setFieldError(field, false, type, idx);
                }
                fieldSet.add(val);
              }
            }
            if (ref.classList.contains("signup-invite-input-invalid")) {
              invalidInput = true;
            }
          } else {
            missingField = true;
            setFieldError(field, true, type, idx);
          }
        }
      });
      return customFieldObj;
    });
    return {
      missingField, invalidInput, duplicate, atLeastOneDisplayed, customFieldsValsArr, fieldSet,
    };
  };

  /**
   * Loops through ref object's key: ref pairs and adds key: val pair to payload
   * (mutates passed in payload object)
   * @param {Object} payload payload object
   * @param {Object} refs object of key: ref pairs
   * @param {String} type "" for customer info, "product_info", or "environment_info"
   * @returns true if any fields are missing
   */
  const addFieldValsToPayload = (payload, refs, type) => {
    // console.log(payload, refs, type);
    let missingField = false;
    let invalidInput = false;
    let duplicate = false;
    let atLeastOneDisplayed = false;
    let inputIsNotAField = false;

    const fieldInputSet = new Set(["product_link", "title", "default_sample_id", "default_sample_type"]);
    let customFieldSet;

    if (type) {
      if (payload[type] === undefined) {
        payload[type] = {};
      }
      const {
        missingField: customFieldMissing,
        invalidInput: customFieldInvalidInput,
        duplicate: customFieldDuplicate,
        atLeastOneDisplayed: atLeastOne,
        fieldSet,
        customFieldsValsArr,
      } = getCustomFieldVals(refs.fields, type);
      missingField = customFieldMissing || missingField;
      invalidInput = customFieldInvalidInput || invalidInput;
      duplicate = customFieldDuplicate || duplicate;
      atLeastOneDisplayed = atLeastOne || atLeastOneDisplayed;
      customFieldSet = fieldSet;
      payload[type].fields = customFieldsValsArr;
    }

    Array.from(Object.keys(refs)).forEach((field) => {
      if (field !== "fields") { // already added above (custom fields)
        const ref = refs[field];
        if (field === "product_link") {
          const val = ref.value;
          payload[type][field] = val;
          if (!val) {
            missingField = true;
            setFieldError(field, true, type);
          } else if (fieldInputSet.has(field)) {
            if (!customFieldSet?.has(val)) {
              inputIsNotAField = true;
              setFieldError(field, true, type);
            } else {
              setFieldError(field, false, type);
            }
          }
        } else {
          const val = field === "delimeter" ? ref.value : ref.value.trim();
          if (val) {
            if (type) {
              payload[type][field] = val;
              if (fieldInputSet.has(field) && !customFieldSet?.has(val)) {
                inputIsNotAField = true;
                setFieldError(field, true, type);
              } else if (getFieldError(field, type)) {
                setFieldError(field, false, type);
              }
            } else {
              payload[field] = val;
            }
            if (ref.classList.contains("signup-invite-input-invalid")) {
              invalidInput = true;
            }
          } else {
            missingField = true;
            setFieldError(field, true, type);
          }
        }
      }
    });
    return {
      missingField, invalidInput, duplicate, atLeastOneDisplayed, inputIsNotAField,
    };
  };

  /**
   * Loop through ref objects to get values and generate payload,
   * make the send invite api call
   */
  const handleSubmit = async () => {
    let anyMissing = false;
    let anyInvalid = false;
    let anyDuplicate = false;
    const atLeastOneDisplayed = { product_info: false, environment_info: false };
    let inputNotAField = false; // check that inputs that need to be an existing field are an existing field (i.e. link, default_sample_id, etc.)

    /** Check if either prod or env is filled out */
    if (!(showInfoInputSection.prod || showInfoInputSection.env)) {
      toast.error("Please select Product or Environment");
      return;
    }

    /** Loop through refs and build the payload object (addFieldValsToPayload mutates the payload obj) */
    const payload = {};
    const refsToLoopThrough = [{ refs: customerInfoRefs.current, type: "" }];
    if (showInfoInputSection.prod) {
      refsToLoopThrough.push({ refs: infoInputRefs.current.prod, type: "product_info" });
    }
    if (showInfoInputSection.env) {
      refsToLoopThrough.push({ refs: infoInputRefs.current.env, type: "environment_info" });
    }
    refsToLoopThrough.forEach(({ refs, type }) => {
      const {
        missingField, invalidInput, duplicate, atLeastOneDisplayed: atLeastOne, inputIsNotAField,
      } = addFieldValsToPayload(payload, refs, type);
      anyMissing = missingField || anyMissing;
      anyInvalid = invalidInput || anyInvalid;
      anyDuplicate = duplicate || anyDuplicate;
      inputNotAField = inputIsNotAField || inputNotAField;
      if (type && atLeastOne) {
        atLeastOneDisplayed[type] = atLeastOne;
      }
    });

    /** Build the invite url */
    const { company_name, customer_id, email_address } = payload;
    const encodedUrl = `company_name=${encodeURIComponent(company_name)}&customer_id=${encodeURIComponent(customer_id)}&email_address=${encodeURIComponent(email_address)}`;
    const encryptedUrl = AES.encrypt(encodedUrl, AES_128_BIT_KEY).toString();
    payload.url = `${window.location.origin}/signup?${encryptedUrl}`;

    /** Check for any errors, if none, make api call */
    if (anyMissing && (anyInvalid || inputNotAField || anyDuplicate)) {
      toast.error("Missing required fields and invalid inputs");
    } else if (anyMissing) {
      toast.error("Missing required fields");
    } else if (payload.product_info && !atLeastOneDisplayed.product_info) {
      toast.error("At least one 'Product' field must be shown in both Sample Submission and Product");
    } else if (payload.environment_info && !atLeastOneDisplayed.environment_info) {
      toast.error("At least one 'Environment' field must be shown in both Sample Submission and Environment");
    } else if (inputNotAField) {
      toast.error(`Spec Link${showInfoInputSection.env ? ", Swab Title Field," : ""} and Default Sample ID/Type must be existing DB Column names`, { autoClose: 8000 });
    } else if (anyDuplicate) {
      toast.error("Duplicate fields not allowed");
    } else if (anyInvalid) {
      toast.error("Fix invalid inputs");
    } else {
      setIsSendingInvite(true);
      const { success, message } = await inviteAdmin(payload);
      // console.log("payload", payload);
      // const { success, message } = { success: false, message: "testing" };
      setIsSendingInvite(false);
      if (success) {
        history.push("/signup/invite/success");
      } else {
        toast.error(message);
      }
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="logoImgWrapper">
        <img
          src={esv_logo}
          alt="logo"
          className="logoImg"
        />
      </div>
      <div className={`signUpInviteFormContainer ${(showInfoInputSection.prod && showInfoInputSection.env) ? "signUpInviteFormContainer-add-double-height" : ""} ${(showInfoInputSection.prod || showInfoInputSection.env) ? "signUpInviteFormContainer-add-hight" : ""}`}>
        <form className="signUpInviteFormContainer__Form">
          <CustomerInfoSection
            setCustomerFieldRef={setCustomerFieldRef}
            showInfoInputSection={showInfoInputSection}
            setShowInfoInputSection={setShowInfoInputSection}
            isSendingInvite={isSendingInvite}
            errorsFromParent={errorsFromParent}
            setFieldError={setFieldError}
            getFieldError={getFieldError}
            resetInfoInputSectionErrors={resetInfoInputSectionErrors}
          />

          {(showInfoInputSection.prod || showInfoInputSection.env) && (
          <div className={`signup-invite-prod-env-info-container ${showInfoInputSection.prod && showInfoInputSection.env ? "signup-invite-prod-env-info-container-center" : ""}`}>
            {showInfoInputSection.prod && (
            <InfoInputSection
              type="prod"
              setFixedFieldRef={setFixedFieldRef}
              setCustomFieldRef={setCustomFieldRef}
              deleteCustomFieldRef={deleteCustomFieldRef}
              isSendingInvite={isSendingInvite}
              errorsFromParent={errorsFromParent}
              setFieldError={setFieldError}
              getFieldError={getFieldError}
            />
            )}
            {showInfoInputSection.env && (
            <InfoInputSection
              type="env"
              setFixedFieldRef={setFixedFieldRef}
              setCustomFieldRef={setCustomFieldRef}
              deleteCustomFieldRef={deleteCustomFieldRef}
              isSendingInvite={isSendingInvite}
              errorsFromParent={errorsFromParent}
              setFieldError={setFieldError}
              getFieldError={getFieldError}
            />
            )}
          </div>
          )}
        </form>

        <InviteButton isSendingInvite={isSendingInvite} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
}

import React from "react";
import Highlighter from "react-highlight-words";
import { Skeleton } from "antd";
import SkeletonButton from "antd/es/skeleton/Button";
import StyledButton from "../../../../../Common/UIComponents/StyledButton";
import pendingIcon from "../../../../../../assets/images/product/plusMidnight.png";
import "./SpecStatusItem.css";

export default class SpecStatusItem extends React.Component {
  render() {
    const {
      item,
      index,
      handleEditSpecClick,
      loadingNewContent,
      loading,
      searchValue,
    } = this.props;

    if (loading && !loadingNewContent) {
      return (
        <tr key={index}>
          <td>
            <div className="envSpecTableRow">
              <Skeleton active paragraph={false} title={{ width: 120 }} />
              <SkeletonButton active width={120} />
            </div>
          </td>
        </tr>
      );
    }

    return (
      <tr key={index}>
        <td>
          <div className="envSpecTableRow">
            <div className="envSpecTableRowLeftCol">
              <Highlighter
                highlightClassName="searchHighlight"
                searchWords={[searchValue]}
                autoEscape
                textToHighlight={item.identifier}
              />
            </div>
            <div className="envSpecTableRowRightCol">
              {(item.status !== "added" && item.status !== "pending") && <div> </div>}
              { item.status === "added"
                && (
                <StyledButton
                  className={loading ? "envSpecEditBtn envSpecStateBtn buttonDisableSection"
                    : "envSpecEditBtn envSpecStateBtn"}
                  onClick={(e) => handleEditSpecClick(e, index, "added")}
                >
                  <img
                    className="envSpecAdded"
                    alt="addedIcon"
                  />
                  Spec Added
                </StyledButton>
                )}
              { item.status === "pending"
                && (
                <StyledButton
                  className={loading ? "envSpecAddBtn envSpecStateBtn buttonDisableSection"
                    : "envSpecAddBtn envSpecStateBtn"}
                  onClick={(e) => handleEditSpecClick(e, index, "pending")}
                >
                  <div>
                    <img
                      className="envSpecPending"
                      src={pendingIcon}
                      alt="pendingIcon"
                    />
                    Add Specs
                  </div>
                </StyledButton>
                )}
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

import React from "react";
import StyledButton from "../../../../Common/UIComponents/StyledButton";
import closeFilterIcon from "../../../../../assets/images/product/lightBlueCancelIcon.png";
import "./FilterButton.css";

export default class FilterButton extends React.Component {
  parseFilter = () => {
    const { handleFilterToggle, filterText } = this.props;

    let parsedText = filterText.split(" ")[1];

    if (parsedText === "Added") {
      parsedText = "Added";
    } else {
      parsedText = "Pending";
    }
    handleFilterToggle(parsedText.toLowerCase());
  }

  render() {
    const {
      isActive,
      filterText,
      // handleCloseFilterClick,
      disable,
      icon,
      alt,
    } = this.props;

    return (
      <StyledButton
        type={isActive ? "primary" : "text"}
        ghost={isActive}
        disabled={disable}
        className={`productReportSpecManagerFilterButton${isActive ? "--active" : ""}`}
        onClick={this.parseFilter}
      >
        <div className="specManagerFilterTile">
          <img
            src={icon}
            alt={alt}
            className="specManagerFilterButtonIcon"
          />
          <div className="specManagerFilterText">{filterText}</div>
          {isActive && (
            <img
              className="productReportSpecManagerFilterButtonCancelIcon"
              src={closeFilterIcon}
              alt="closeFilterIcon"
              width="14px"
            />
          )}
        </div>
      </StyledButton>
    );
  }
}

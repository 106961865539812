import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { partition } from "lodash";
import Highlighter from "react-highlight-words";
import moment from "moment";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { checkMapClicked } from "./helper";

import whiteMap from "../../../../../assets/images/environment/whiteMap.svg";
import pinIcon from "../../../../../assets/images/environment/pinIconDefault.svg";
import pinIconHover from "../../../../../assets/images/environment/pinIconHover.svg";
import deleteIcon from "../../../../../assets/images/environment/WhiteCloseBlueBackground.svg";
import { checkPinIsPinned } from "../pinHelper";
import "./PinDetailBlock.css";

const PinDetailBlock = ({
  pin,
  locationID,
  quadrantSelected,
  indexOfQuadrantHoverOver,
  isAddingNewSwab,
  isSearching,
  isLocatingSwab,
  updatePinInfo,
  handleCheckbox,
  isDragging,
  selectedLocationID,
  setSelectedLocationID,
  endPinningSwab,
  toggleTooltip,
  setDisplayTooltipLocationID,
  clickQuadrant,
  isClickingQuadrant,
  transformWrapperRef,
  displayFields,
  isPinningNewSwab,
  isPinningSwab,
  imageSrc,
  loading,
  searchResultSelected,
  toggleReportView,
  showReportView,
  index0,
  forceReportViewRerender,
  ...props
}) => {
  const [hoveringPinIcon, setHoveringPinIcon] = useState(false);
  // const [showQuadrantsSelection, setShowQuadrantsSelection] = useState(false); // It is true when clicking the pin icon to show quadrants choices.
  // const [isPinningSwab, setIsPinningSwab] = useState(false); // When we are pining swab on a quadrant, other deatil blocks should be grayed out.
  const pinDetailBlockRef = useRef(null);
  const quadrantChoicesRef = useRef([]);
  const unpinnedIconRef = useRef(null);
  const quadrantClassName = ["TopLeft", "TopRight", "BottomRight", "BottomLeft"];

  useEffect(() => {
    if (selectedLocationID === locationID && pinDetailBlockRef.current) { // When we switch to the selecting quadrants UI, selectedLocationID = locationID and pinDetailBlockRef node is gone
      pinDetailBlockRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }

    if (selectedLocationID === "" && isClickingQuadrant) { // After a swab is pinned on the map, the grayed out effect will be removed.
      clickQuadrant();
      setHoveringPinIcon(false);
    }
  }, [selectedLocationID]); //eslint-disable-line

  const selectQuadrant = (quadrant, resetTheZoom = true) => {
    props.handleHoverQuadrant(-1);
    props.handleSelectQuadrant(quadrant, resetTheZoom);
    // setIsPinningSwab(true);

    // props.handleSelectQuadrant(quadrant, keepCurrentTransform);
  };

  const handleHoverQuadrant = (indexHoverOver) => {
    if (quadrantSelected === -1) {
      props.handleHoverQuadrant(indexHoverOver);
      // Gray out all quadrant choices except the one being hovered
      for (let quadrantIdx = 0; quadrantIdx < 4; quadrantIdx++) {
        if (quadrantIdx !== indexHoverOver) {
          quadrantChoicesRef.current[quadrantIdx].classList.add("quadrant-grayed-out-filter");
        }
      }
    }
  };

  const handleMouseLeaveQuadrant = (indexHoverOver) => {
    if (quadrantSelected === -1) {
      props.handleHoverQuadrant(-1);
    }
    for (let quadrantIdx = 0; quadrantIdx < 4; quadrantIdx++) {
      if (quadrantIdx !== indexHoverOver) {
        quadrantChoicesRef.current[quadrantIdx].classList.remove("quadrant-grayed-out-filter");
      }
    }
  };

  const handlePinIconClickAway = (e, deleteIconClicked = false) => {
    if (deleteIconClicked) {
      setSelectedLocationID("");
      setHoveringPinIcon(false);
      selectQuadrant(-1);
    }

    // if ((isClickingQuadrant && !deleteIconClicked) || isPinningNewSwab) { // disable click away function while selecting a quadrant to pin
    //   return;
    // }

    // setDisplayTooltipLocationID("");
    if (quadrantSelected > -1 && !isPinningNewSwab && !isPinningSwab) {
      if (checkPinIsPinned(pin) || checkMapClicked(e.clientX, e.clientY)) {
        // keep quadrant zoomed in unless cancelling pinning a swab
        // if (!isPinningSwab) {
        //   setSelectedLocationID("");
        // }
        selectQuadrant(-1, false);
      } else {
        if (deleteIconClicked) {
          setSelectedLocationID("");
          setHoveringPinIcon(false);
        }
        selectQuadrant(-1);
      }
    }
  };

  const handlePinIconClick = (e, quadrant) => {
    // console.log(transformWrapperRef.current);
    // console.log(pin, isPinningNewSwab);
    e.stopPropagation();
    if (isSearching || isPinningNewSwab) {
      return;
    }
    if (!imageSrc) {
      toast.error("Must upload map image first");
      return;
    }
    if (!loading && imageSrc === "Not Found") {
      toast.error("Map image must be loaded");
      return;
    }

    if (locationID === selectedLocationID) { // if already selected
      setSelectedLocationID("");
      setDisplayTooltipLocationID("");
      selectQuadrant(-1);
    } else {
      // console.log("piniconclick");
      // setShowQuadrantsSelection(true);
      toggleTooltip();
      setDisplayTooltipLocationID(locationID);
      setSelectedLocationID(locationID);
      setHoveringPinIcon(true);
      if (checkPinIsPinned(pin)) {
        selectQuadrant(quadrant, false);
      } else selectQuadrant(-1);
      // endPinningSwab();
    }
  };

  /**
   * Handles toggling report view
   */
  const handlePinDetailBlockClick = () => {
    if (locationID !== selectedLocationID) {
      forceReportViewRerender();
      setSelectedLocationID(locationID);
    } else if (showReportView) {
      setSelectedLocationID("");
      setDisplayTooltipLocationID("");
      toggleReportView();
    }
    if (quadrantSelected > -1) {
      selectQuadrant(-1);
    }
    if (!showReportView) {
      toggleReportView();
    }
  };

  const getColorTagClassName = () => {
    const flag = pin.specs_flag.toString();
    if (flag === "1" || flag === "5") {
      return "in-spec-color-tag";
    }
    return (flag === "2" || flag === "6") ? "out-of-spec-color-tag" : "transparent-color-tag";
  };

  if (locationID === selectedLocationID && !checkPinIsPinned(pin) && !isPinningNewSwab && !showReportView) { // if currently pinning swab
    return (
      <ClickAwayListener onClickAway={showReportView ? null : handlePinIconClickAway}>
        <div className="selecting-quadrants">
          <div className={`pin-detail-block-status-color-tag ${getColorTagClassName()}`} />
          <div className="PinDetailBlock__QuadrantsSelectBody pinInfoBlock">
            <div className="PinDetailBlock__QuadrantsHeader">
              <div className="selecting-quadrants-heading">
                {"Select a quadrant to pin Swab "}
                <span className={hoveringPinIcon ? "hovering-pin-icon" : ""}>{pin.swab_number}</span>
                {" to the map"}
              </div>
              <img
                alt="delete"
                src={deleteIcon}
                className="PinDetailBlock__DeleteIcon"
                onClick={(e) => {
                  handlePinIconClickAway(e, true);
                  if (isClickingQuadrant) {
                    clickQuadrant();
                  }
                }}
              />
            </div>
            <div className="PinDetailBlock__Quadrants">
              {quadrantClassName.map((className, indexOfQuadrant) => (
                <div
                  key={indexOfQuadrant}
                  className={`PinDetailBlock__Quadrant PinDetailBlock__Quadrant${className} 
                    ${quadrantSelected === indexOfQuadrant ? "PinDetailBlock__Quadrant--selected" : ""}`}
                  onClick={() => {
                    selectQuadrant(indexOfQuadrant);
                    if (!isClickingQuadrant) {
                      clickQuadrant();
                    }
                  }}
                  onMouseEnter={() => handleHoverQuadrant(indexOfQuadrant)}
                  onMouseLeave={handleMouseLeaveQuadrant}
                  ref={(el) => {
                    quadrantChoicesRef.current[indexOfQuadrant] = el;
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </ClickAwayListener>
    );
  }
  const [fixedField, infoFields] = partition(displayFields, (field) => field.json_field === index0);

  return (
    <ClickAwayListener onClickAway={locationID === selectedLocationID ? handlePinIconClickAway : () => { setHoveringPinIcon(false); }}>
      <div className="pinDetailBlock" ref={pinDetailBlockRef}>
        <div className={`pin-detail-block-status-color-tag ${getColorTagClassName()}`} />
        <div
          className={`PinDetailBlock__Body pinInfoBlock ${locationID === selectedLocationID ? "PinDetailBlock__Body--selected" : ""}`}
          onClick={handlePinDetailBlockClick}
        >
          <div className="PinDetailBlock__Content">
            <div className="pinBasicInfo">
              <div
                className={`pinDetailBlock-heading ${hoveringPinIcon ? "hovering-pin-icon" : ""}`}
              >
                <span>{`${fixedField[0].title_field}: `}</span>
                <Highlighter
                  highlightClassName="PinDetailBlock__SearchHighlight"
                  searchWords={[searchResultSelected]}
                  autoEscape
                  textToHighlight={pin[fixedField[0].json_field]}
                />
                {/* <span>
                  {pin.swab_number}
                </span> */}
              </div>
              <div className="PinDetailBlock__InfoFields">
                {infoFields && infoFields.map((field, idx) => (
                  <span key={idx}>
                    <span className="label">{`${field.title_field}: `}</span>
                    <Highlighter
                      highlightClassName="PinDetailBlock__SearchHighlight"
                      searchWords={[searchResultSelected]}
                      autoEscape
                      textToHighlight={pin[field.json_field] ?? ""}
                    />
                    {/* <span>{pin[field.json_field]}</span> */}
                  </span>
                ))}
              </div>
              {/* <div className="swab-description">
                <span className="label">Area Description: </span>
                <span>{pin.description}</span>
              </div> */}
            </div>
            <div className="pin-detail-block-icon-and-date">
              {(pin.quadrant !== undefined && pin.quadrant !== null && pin.quadrant >= 0)
                ? (
                  <div
                    // className={showReportView ? `${quadrantClassName[pin.quadrant]} pinnedIcon grey-out` : `${quadrantClassName[pin.quadrant]} pinnedIcon`}
                    className={`PinDetailBlock__PinnedIcon PinDetailBlock__PinnedIcon--quadrant${quadrantClassName[pin.quadrant]} 
                      ${showReportView ? "PinDetailBlock__PinnedIcon--grayOut" : ""} 
                      ${isSearching || showReportView ? "PinDetailBlock__PinnedIcon--cursor-auto" : ""}`}
                    onClick={showReportView ? null : (e) => handlePinIconClick(e, pin.quadrant)}
                    onMouseEnter={showReportView ? null : () => setHoveringPinIcon(true)}
                    onMouseOut={showReportView ? null : () => setHoveringPinIcon(false)}
                    onBlur={() => {}}
                  >
                    <img
                      alt="pinIcon"
                      src={whiteMap}
                      className="PinDetailBlock__PinnedIconImg"
                      onMouseEnter={showReportView ? null : () => setHoveringPinIcon(true)}
                      onMouseOut={showReportView ? null : () => setHoveringPinIcon(false)}
                      onBlur={() => {}}
                    />
                  </div>
                )
                : (
                  <div
                    className={`PinDetailBlock__UnpinnedIcon
                      ${hoveringPinIcon ? "PinDetailBlock__UnpinnedIcon--hoveringColor-blue" : ""} 
                      ${showReportView ? "PinDetailBlock__UnpinnedIcon--grayOut" : ""} 
                      ${isSearching || showReportView ? "PinDetailBlock__UnpinnedIcon--cursor-auto" : ""}
                      `}
                    ref={unpinnedIconRef}
                    onClick={showReportView ? null : (e) => handlePinIconClick(e, pin.quadrant)}
                    onMouseEnter={showReportView ? null : (e) => {
                      toggleTooltip(e.target.getBoundingClientRect());
                      setHoveringPinIcon(true);
                    }}
                    onMouseOut={showReportView ? null : () => {
                      toggleTooltip();
                      setHoveringPinIcon(false);
                    }}
                    onBlur={showReportView ? null : () => toggleTooltip(false)}
                  >
                    <img
                      className="PinDetailBlock__UnpinnedIconImg"
                      alt="pinIcon"
                      src={hoveringPinIcon ? pinIconHover : pinIcon}
                      onMouseEnter={showReportView ? null : () => {
                        toggleTooltip(unpinnedIconRef.current.getBoundingClientRect());
                        setHoveringPinIcon(true);
                      }}
                      onMouseOut={showReportView ? null : () => {
                        toggleTooltip();
                        setHoveringPinIcon(false);
                      }}
                      onBlur={showReportView ? null : () => toggleTooltip(false)}
                    />
                  </div>
                )}
              <div className="pin-detail-block-date">
                {moment(pin.added_on).format("DD MMM YYYY")}
              </div>
            </div>
          </div>
        </div>
        {(isAddingNewSwab || (locationID !== selectedLocationID && isClickingQuadrant)) && (
        <div className="pin-detail-block-grayed-out" />
        )}
      </div>
    </ClickAwayListener>
  );
};

const shouldNotUpdate = (prevProps, nextProps) => {
  if ((prevProps.selectedLocationID !== prevProps.locationID && nextProps.selectedLocationID === nextProps.locationID) // if pin is selected
  || (prevProps.selectedLocationID === prevProps.locationID && nextProps.selectedLocationID !== nextProps.locationID) // if pin is deselected
  || (prevProps.isPinningNewSwab !== nextProps.isPinningNewSwab) // if pinning state has changed
  || (prevProps.isPinningSwab !== nextProps.isPinningSwab) // if pinning state has changed
  || (prevProps.isClickingQuadrant !== nextProps.isClickingQuadrant) // grey out/not grey out when pinning
  || (prevProps.isAddingNewSwab !== nextProps.isAddingNewSwab) // grey out/not grey out when toggling create new swab
  || (prevProps.imageSrc !== nextProps.imageSrc) // image has been uploaded
  || (prevProps.showReportView !== nextProps.showReportView) // if report view has changed
  || (prevProps.quadrantSelected !== nextProps.quadrantSelected) // if selected quadrant has changed
  || (prevProps.searchResultSelected !== nextProps.searchResultSelected) // update highlighting
  ) {
    return false;
  }
  return true;
};

export default React.memo(PinDetailBlock, shouldNotUpdate);

import React, { Component } from "react";
import "./index.css";
import "./StatusPage.css";
import { Result, Button } from "antd";
import esv_logo from "../../assets/images/logo/esv_logo_2x.png";

export default class StatusPage extends Component {
  onGotoLogin = () => {
    const { history } = this.props;
    history.push("/login");
  };

  render() {
    const {
      status,
      title,
      subtitle,
      showLoginButton,
    } = this.props;

    return (
      <div className="auth-wrapper login-page">
        <div className="logoImgWrapper">
          <img
            src={esv_logo}
            alt="logo"
            className="logoImg"
          />
        </div>
        <div
          className="auth-inner signup-success"
        >
          <Result
            status={status}
            title={title}
            subTitle={subtitle}
            extra={showLoginButton ? [
              <Button
                type="primary"
                key="console"
                onClick={this.onGotoLogin}
                className="go-to-login-btn"
              >
                Go to Login
              </Button>,
            ] : []}
          />
        </div>
      </div>
    );
  }
}

import React from "react";
import { ReactComponent as LoadingDots } from "../../../assets/images/common/LoadingDots.svg";
import "./LoadingConfirmBtn.css";

export default function LoadingConfimBtn({ text }) {
  return (
    <button type="button" onClick={() => {}} className="CommonLoadingBtn">
      {text && <span>{text}</span>}
      <LoadingDots width={16} />
    </button>
  );
}

import axios from "axios";
import { API_URL } from "../utils/Constant";

/**
 * Get list of files for company.
 * If pId is empty, get all files for the company (Documents).
 * If not empty, get list of files for specified product (Product Documents).
 * @param {String} pId If coming from Index, this is the product_id of selected product, otherwise empty.
 * @returns {Object} { success, message, result: [{ files: [{name, type, size, uploaded, file_path},...] }] }
 */
export const getFiles = async (pId = "") => {
  try {
    const params = {
      product_id: pId,
    };
    const response = await axios.get(`${API_URL}/getdocuments/`, { params });
    const data = await response.data;
    return { success: true, ...data };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Upload a document for company or product
 * @param {Array} payload [{ file, name, size, type, uploaded }, ...]
 * @param {String} category "Add", "Edit", or "Delete"
 * @param {String} pId product_id of product for which user is uploading a document
 * @returns {Object} { success, message, result: [{ files: [{name, type, size, uploaded, file_path},...] }] }
 */
export const postFiles = async (payload, category, pId = "") => {
  try {
    const response = await axios.post(`${API_URL}/adddocument/`, {
      category,
      product_id: pId,
      files: payload,
    });
    const data = await response.data;
    return { success: true, ...data };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Search documents for a company
 * @param {String} payload search value
 * @returns {Object} { success, message, result: [{ company_doamin, files: [{name, type, size, uploaded, file_path},...] }] }
 */
export const searchFiles = async (payload) => {
  try {
    const params = {
      name: payload || "",
    };
    const response = await axios.get(`${API_URL}/documentsearch/`, { params });
    const data = await response.data;
    return { success: true, ...data };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

import React from "react";
import { Switch } from "antd";
import "./TestViewToggle.css";

export default function TestViewToggle({ handleTestToggle, enableTests }) {
  return (
    <Switch
      className="TemplateBuilder__TestViewToggle"
      checkedChildren="Test View"
      unCheckedChildren="Test View"
      defaultChecked={enableTests}
      onClick={() => handleTestToggle(!enableTests)}
    />
  );
}

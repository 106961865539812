import React from "react";
import DetailsContent from "./DetailsContent";
import SectionContent from "./SectionContent";
import StatementCardContent from "./StatementCardContent";
// import TestReportContent from "./TestReportContent";
// import chevronDown from "../../../../../../assets/images/product/template/ChevronDownBlue.png";
// import chevronUp from "../../../../../../assets/images/product/template/ChevronUpBlue.png";
// import lockIcon from "../../../../../assets/images/product/lockIconMidnight.png";
import lockIcon from "../../../../../../../assets/images/product/template/LockIconGrey.png";
// import dragIconMidnight from "../../../../../assets/images/product/dragIndicatorMidnight.png";
import "./builderSection.css";

export default function BuilderSection(props) {
  const {
    handleSectionSelect,
    section,
    sectionIndex,
    dataFields,
    sectionRefs,
    handleBuilderFile,
    handleTemplateDataFieldsChange,
    setSectionInputs,
  } = props;

  // const [isExpanded, setIsExpanded] = useState(section.expanded);

  // const toggleSection = () => {
  //   setIsExpanded(!isExpanded);
  // };

  const addRef = (element) => {
    sectionRefs.current[section.title] = element;
  };

  // if (section.active && !isExpanded) {
  //   setIsExpanded(true);
  // }
  let sectionCardClassName = "TemplateBuilder__SectionCard";
  if (section.active) {
    sectionCardClassName += " inEdit";
  }
  // if (!isExpanded) {
  //   sectionCardClassName += " cardHeaderCollapsed";
  // }
  return (
    // <div ref={section.ref} className={section.active ? "sectionCard inEdit" : "sectionCard"}>
    <div
      ref={section.ref}
      className={sectionCardClassName}
      onClick={() => {
        handleSectionSelect(section);
      }}
    >
      <div
        // onClick={toggleSection}
        className="TemplateBuilder__SectionHeader"
      >
        <div className="TemplateBuilder__SectionHeader__Left">
          <img
            // src={section.title.startsWith("New") ? dragIconMidnight : lockIcon}
            src={lockIcon}
            className="TemplateBuilder__SectionHeader__LockIcon"
            alt="modeIcon"
          />
          <div
            className="TemplateBuilder__SectionHeader__Title"
          >
            <span>{section.title}</span>
            <div className="sectionCardUnderline" />
          </div>
          {/* {section.active && <div />} */}
        </div>
        {/* <img
          src={isExpanded ? chevronUp : chevronDown}
          className="TemplateBuilder__SectionHeader__Chevron"
          alt="expandIcon"
        /> */}
      </div>
      <div
        className="builderSectionShow"
        ref={(element) => addRef(element)}
      >
        {section.title === "My details"
          && (
          <DetailsContent
            handleBuilderFile={handleBuilderFile}
            setSectionInputs={setSectionInputs}
            sectionIndex={sectionIndex}
            inputs={section.inputs}
          />
          )}
        {section.title.startsWith("New")
          && (
            <SectionContent
              dataFields={dataFields}
              sectionIndex={sectionIndex}
              inputs={section.inputs}
              setSectionInputs={setSectionInputs}
              handleTemplateDataFieldsChange={handleTemplateDataFieldsChange}
            />
          )}
        {/* {section.title === "Test reports"
          && <TestReportContent />} */}
        {section.title === "Statement card"
          && (
          <StatementCardContent
            handleBuilderFile={handleBuilderFile}
            inputs={section.inputs}
            sectionIndex={sectionIndex}
            setSectionInputs={setSectionInputs}
          />
          )}
      </div>
    </div>
  );
}

/* eslint-disable no-nested-ternary */
// import moment from "moment";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import ResultTableActions from "./ResultTableActions";
import ResultTable from "./ResultTable";
import "./ResultTableCarousel.css";

export default function ResultTableCarousel({
  specsData,
  // activeDate,
}) {
  const [currentSlide, setCurrentSlide] = useState(0);

  if (!specsData) {
    return (<div className="env-report-view-report-section-results-table-container" />);
  }

  const sortedSpecData = Object.entries(specsData).sort((a, b) => Date.parse(b[0]) - Date.parse(a[0]));

  const handleSlideChange = (change = 0) => {
    const newValue = currentSlide + change;
    if (newValue < sortedSpecData.length && newValue >= 0) {
      setCurrentSlide(currentSlide + change);
    }
  };

  if (sortedSpecData && sortedSpecData.length > 0) {
    return (
      <div className="env-report-view-report-section-results-table-container">
        <Carousel
          showIndicators={false}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          selectedItem={currentSlide}
          swipeable={sortedSpecData.length > 1}
        >
          {sortedSpecData.length > 0 && sortedSpecData.map(([date, data], idx) => (
            <ResultTable
              key={idx}
              date={date}
              data={data}
            />
          ))}
        </Carousel>
        {sortedSpecData.length > 1 && (
          <ResultTableActions
            currentSlide={currentSlide}
            totalSlides={sortedSpecData.length}
            handleSlideChange={handleSlideChange}
          />
        )}
      </div>
    );
  } return (
    <div className="env-report-view-report-section-results-table-container" />
  );
}

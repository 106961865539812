import React from "react";
import Modal from "antd/es/modal/Modal";
import "./TemplateDeleteModal.css";

export default function TemplateDeleteModal({ toggleShowDeleteModal, handleDeleteTemplate, open }) {
  return (
    <Modal
      open={open}
      destroyOnClose
      closable={false}
      centered
      footer={null}
      onCancel={toggleShowDeleteModal}
      width="368px"
      height="200px"
      wrapClassName="TemplateDeleteModal"
      className="TemplateDeleteModal"
    >
      <div className="deleteModal">
        <div className="deleteModalSpanContainer">
          <span className="deleteModalHeader"> Delete report </span>
          <span className="deleteModalBody"> Are you sure you want to delete this report? </span>
        </div>
        <div className="deleteModalButtonContainer">
          <div className="deleteModalButton deleteModalCancel" onClick={toggleShowDeleteModal}> Cancel </div>
          <div className="deleteModalButton deleteModalDelete" onClick={() => { toggleShowDeleteModal(); handleDeleteTemplate(); }}> Delete </div>
        </div>

      </div>
    </Modal>
  );
}

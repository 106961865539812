import React, { useEffect, useState } from "react";

import { InputNumber } from "antd";

import { ReactComponent as StepperAdd } from "../../../assets/images/common/StepperAdd.svg";
import { ReactComponent as StepperSubtract } from "../../../assets/images/common/StepperSubtract.svg";

import "./StyledStepper.css";

const StyledStepper = ({
  value: propValue,
  onChange,
  min = 2,
  max = 1000,
  steps = 1,
  readOnly = false,
}) => {
  const [value, setValue] = useState(propValue);
  const [hasError, setHasError] = useState(false);

  /**
   * Set error state for displaying error styles in input
   */
  useEffect(() => {
    setHasError(value === null || value < min || value > max);
  }, [value, setHasError, min, max]);

  /**
   * Handles change in value when user inputs
   * @param {Number} newValue New updated value
   */
  const handleChange = (newValue) => {
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  /**
   * Handles change in stepper input when +/- button is clicked
   * @param {string} type increment/decrement
   */
  const handleStepperButton = (type = "increment") => {
    const newValue = type === "increment" ? Math.min(max, value + steps) : Math.max(min, value - steps);
    setValue(newValue);
    if (onChange) onChange(newValue);
  };

  return (
    <div className="StyledStepper">
      <button
        type="button"
        className={`StyledStepper__StepperButton ${value <= min ? "disabled" : ""}`}
        disabled={value <= min}
        onClick={() => handleStepperButton("decrement")}
      >
        <StepperSubtract />
      </button>
      <InputNumber
        min={min}
        max={max}
        className={`StyledStepper__InputNumber ${hasError ? "hasError" : ""}`}
        value={value}
        onChange={handleChange}
        readOnly={readOnly}
      />
      <button
        type="button"
        className={`StyledStepper__StepperButton ${value >= max ? "disabled" : ""}`}
        disabled={value >= max}
        onClick={() => handleStepperButton("increment")}
      >
        <StepperAdd />
      </button>
    </div>
  );
};

export default StyledStepper;

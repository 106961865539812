import React, { Component } from "react";
import searchIcon from "../../../assets/images/index/searchIcon.png";
import crossIconInput from "../../../assets/images/crossButton2x.png";
import "./Search.css";

class Search extends Component {
  state = {
    searchValue: "",
  }

  handleSearchChange = (e) => {
    const { updateStateForShowingClearButton, showClearSearch } = this.props;
    if (!showClearSearch) {
      updateStateForShowingClearButton();
    }
    const value = e.target.value;
    // console.log(value)
    if (value.trim() === "") {
      this.handleSearch(value);
    }
    this.setState({
      searchValue: value,
    });
  };

  handleSearch = (value) => {
    const { handleSearchSubmit } = this.props;
    handleSearchSubmit(value);
  };

  render() {
    const {
      readOnly,
      showClearSearch,
      handleClearSearch,
      activeTab,
    } = this.props;

    const { searchValue } = this.state;

    const placeholder = activeTab !== "0" ? "Search by Name or Tag" : "Search by Name, Tag, or Product";

    return (
      <div className="IndexSearch">
        <img
          src={searchIcon}
          className="IndexSearch__Icon"
          alt="searchIcon"
        />
        <input
          className="IndexSearch__Input"
          type="text"
          placeholder={placeholder}
          name="searchProductText"
          readOnly={readOnly}
          onChange={this.handleSearchChange}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              this.handleSearch(searchValue);
            }
          }}
        />
        {showClearSearch && (
          <img
            className="IndexSearch__CancelIcon"
            alt="crossIconInput"
            src={crossIconInput}
            onClick={readOnly ? () => {} : handleClearSearch}
          />
        )}
      </div>
    );
  }
}

export default Search;

import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import "./SelectionTitleSkeleton.css";

export default function SelectionTitleSkeleton({ count = 1 }) {
  return (
    <>
      {[...Array(count)].map((_, index) => <motion.div layout key={`skeleton_${index}`} className="SelectionTitleSkeletonContainer ActiveloadingAnimation" />)}
    </>
  );
}

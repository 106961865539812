import React, {
  useCallback,
  useEffect, useRef, useState,
} from "react";
// import { debounce } from "lodash";
import "./PesticideSampleAutocomplete.css";
import ErrorCheckingAutocompleteInput from "./ErrorCheckingAutocomplete";
import { INVALID_CHARS, INVALID_CHARS_ESCAPED } from "../Constant";
import { ReactComponent as PesticideAutocompleteIcon } from "../../../../assets/images/sample-submission/pesticideAutocompleteIcon.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/images/sample-submission/blueCloseIcon.svg";
import { getDropdownSuggestionsAndTests } from "../../../../actions/sampleSubmission";

const FIELD_NAME = "sample_type";

export default function PesticideSampleAutocomplete(props) {
  const {
    testName,
    testObj,
    disableTestListMod,
    toggleTestChoice,
    sampleIDHasReplicate,
    pesticideSample,
    setPesticideSample,
    setInputValuesRefs,
    // inputValuesRefs,
    // setInvalidInputFound,
    sampleCategory,
  } = props;
  const dropdownsAbortController = useRef();
  const [options, setOptions] = useState([]);

  /** On option click in autocomplete dropdown, set value in parent, auto fill product_name and tests
 * @param {String} selectedOption value of autocomplete option
 */
  const handleSelectOption = (selectedOption) => {
    setPesticideSample(selectedOption.trim());
  };

  /**
   * On change, set value of field in parent
   * @param {string} value on change input value
   */
  const onChange = (value) => {
    setPesticideSample(value);
  };

  /**
   * Calls api /ssautofill/ to get dropdown options
   */
  const handleLoadOptions = useCallback(async () => {
    const newAbortController = new AbortController();
    if (dropdownsAbortController.current) {
      dropdownsAbortController.current.abort();
    }
    dropdownsAbortController.current = newAbortController;
    const { success, result } = await getDropdownSuggestionsAndTests({ }, newAbortController.signal, sampleCategory);
    if (newAbortController.signal.aborted) {
      return null;
    }
    dropdownsAbortController.current = null;
    if (success) {
      return result;
    }
    return {};
  }, [sampleCategory]);

  /**
   * Calls api initially to load sample type
   */
  useEffect(() => {
    (async () => {
      const optionList = await handleLoadOptions();
      if (optionList && optionList[FIELD_NAME]) {
        const values = optionList[FIELD_NAME].map((op) => ({ value: op }));
        setOptions(values);
      }
    })();

    return () => {
      setPesticideSample("");
    };
  }, [handleLoadOptions, setPesticideSample]);

  return (
    <li className="PesticideSampleAutocomplete__Container">
      <div className={`PesticideSampleAutocomplete__TestName ${sampleIDHasReplicate && testObj.replicated ? "PesticideSampleAutocomplete__InvalidTest" : ""}`}>
        {testName}
        {!disableTestListMod && (
          <div className="PesticideSampleAutocomplete__CloseIcon" onClick={() => toggleTestChoice(testName, "deselect")}>
            <CloseIcon width={8} height={8} />
          </div>
        )}
      </div>
      <PesticideAutocompleteIcon />
      <ErrorCheckingAutocompleteInput
        ref={(input) => setInputValuesRefs(FIELD_NAME, input)}
        label=""
        labelDivClassName="pesticide-autocomplete-title"
        inputID="pesticide-autocomplete-input"
        value={pesticideSample}
        suggestions={options}
        invalidChars={[...INVALID_CHARS_ESCAPED]}
        invalidKeys={INVALID_CHARS}
        handleSelectOption={handleSelectOption}
        onChange={(e) => onChange(e)}
        onErrorChange={() => {}}
        customClassName="PesticideSampleAutocomplete__Container"
        placeholderText="Enter sample type"
      />
    </li>
  );
}

import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { toast } from "react-toastify";
import "./IndexCategories.css";
import OutsideClickHandler from "react-outside-click-handler";
import closeIcon from "../../../assets/images/index/closeDarkblue.png";
import closeIconHighlighed from "../../../assets/images/index/crossSquareHighlight.png";
import dotsIcon from "../../../assets/images/index/threeDotsIconLightBlue.png";
import editIcon from "../../../assets/images/index/editPencilMidnight.png";
import deleteIcon from "../../../assets/images/index/deleteIconMidnight.png";
import checkIcon from "../../../assets/images/index/checkIconLightBlue.png";
import checkHightlightIcon from "../../../assets/images/index/checkIconMidnight.png";
import ProdAddNewCategoryIcon from "../../../assets/images/index/plusIconBlue.png";
import exclamationIcon from "../../../assets/images/index/exclamation.png";
// import deleteIconWhite from "../../../assets/images/index/deleteIconWhite.png";

import {
  getIndex,
  addIndex,
  editIndex,
  deleteIndex,
} from "../../../actions/index";

import StyledTooltip from "../../Common/UIComponents/StyledTooltip";
import DeleteIndexConfirmation from "../../Modals/DeleteIndexConfirmation";

class IndexCategories extends Component {
  state = {
    activeTab: "0",
    indices: [],
    newIndex: false,
    newIndexName: "",
    editIndexId: "",
    prevEditIndexName: "",
    newEditIndexName: "",
    dltIndex: null,
    isDeleteIndexModalVisible: false,
    showOptions: false,
    showEditIcon: false,
    closeEditTagIcon: closeIcon,
  }

  componentDidMount() {
    this.initComponent();
  }

  initComponent = async () => {
    const response = await getIndex();
    if (response && response.success) {
      const { indices } = response;
      let activeTabName = window.location.hash.replace("#", "");
      activeTabName = activeTabName.replace(/%20/g, " ");
      let activeTab = activeTabName ? indices.indexOf(activeTabName) : -1;
      if (activeTab === -1) {
        window.location.hash = "#Product";
      }
      activeTab = (activeTab + 1).toString();
      this.setState({ indices, activeTab });
      const { initComponent } = this.props;
      initComponent(activeTab, activeTabName, indices);
    } else {
      toast.error(response.message);
    }
  }

  handleTabSelect = (selectedTab) => {
    const { readOnly } = this.props;
    if (readOnly) {
      return;
    }
    const {
      indices, newIndex, editIndexId, prevEditIndexName, activeTab,
    } = this.state;
    if (selectedTab <= indices.length) {
      if (selectedTab === activeTab) {
        return;
      }
      this.changewindowHash(selectedTab, indices);
      this.setState({ showOptions: false, showEditIcon: false });
    }

    // "edit index name" clicked
    if (editIndexId && prevEditIndexName === indices[selectedTab - 1]) {
      return;
    }

    // tab: create new category
    if (selectedTab === String(indices.length + 1)) {
      // click the tab to start to add new category
      if (!newIndex) {
        if (indices.length < 9) {
          this.setState({
            newIndex: true,
            // activeTab: selectedTab,
            editIndexId: "",
            prevEditIndexName: "",
            newEditIndexName: "",
          });
        } else {
          toast.error("You can add maximum 10 Indices.");
        }
      }
    } else {
      const { updateProductList } = this.props;
      this.setState({ activeTab: selectedTab });
      this.resetIndexRelatedState();
      updateProductList(selectedTab, indices);
    }
  }

  resetIndexRelatedState = () => {
    this.setState({
      dltIndex: null,
      newIndex: false,
      newIndexName: "",
      editIndexId: "",
      prevEditIndexName: "",
      newEditIndexName: "",
      isDeleteIndexModalVisible: false,
    });
  }

  changewindowHash = (selectedTab, indices) => {
    const { onChangeTab } = this.props;
    if (selectedTab === "0") {
      window.location.hash = "#Product";
      onChangeTab("Product");
    } else {
      window.location.hash = `#${indices[selectedTab - 1]}`;
      onChangeTab(indices[selectedTab - 1]);
    }
  }

  onMenuIconClick = () => {
    const { readOnly } = this.props;
    const { showOptions } = this.state;
    if (readOnly) {
      return;
    }
    this.setState({ showOptions: !showOptions });
  }

  onAddIndexInputChange = (e) => {
    this.setState({ newIndexName: e.target.value });
  }

  getPrevTabAfterDelete = (selectedTab) => {
    const { indices } = this.state;
    const { updateProductList } = this.props;
    const search = document.getElementsByName("searchProductText")[0];
    if (search) {
      search.value = "";
    }
    this.resetIndexRelatedState();
    updateProductList(selectedTab, indices);
  }

  handleIndexEditClick = (e, activeTab) => {
    const { indices } = this.state;
    this.setState({
      editIndexId: activeTab,
      prevEditIndexName: indices[activeTab - 1],
      newEditIndexName: indices[activeTab - 1],
      showEditIcon: false,
      showOptions: false,
    });
  }

  handleIndexEditSubmit = (newIndexName) => {
    if (newIndexName && newIndexName.trim().length > 0 && newIndexName.trim().length <= 20) {
      const newIndexNameFormatted = newIndexName.trim();
      this.apiEditIndex(newIndexNameFormatted);
      this.setState({ showEditIcon: false, showOptions: false });
    } else {
      toast.error("title cannot be empty or more than 20 characters");
    }
  }

  handleIndexEditExitSubmit = () => {
    this.setState({
      editIndexId: "",
      prevEditIndexName: "",
      newEditIndexName: "",
      showEditIcon: false,
      showOptions: false,
      closeEditTagIcon: closeIcon,
    });
  }

  handleEnterIndexName = (e, newIndexName, flag) => {
    if (e.charCode === 13) {
      if (newIndexName && newIndexName.trim().length > 0) {
        const newIndexNameFormatted = newIndexName.trim();
        if (flag === "editIndex") {
          this.apiEditIndex(newIndexNameFormatted);
        } else {
          this.apiAddIndex(newIndexNameFormatted);
        }
        this.setState({ showEditIcon: false, showOptions: false });
      } else {
        toast.error("title cannot be empty");
      }
    }
  };

  handleAddNewIndex = (newIndexName) => {
    const { newIndex } = this.state;
    if (newIndex) {
      if (newIndexName && newIndexName.trim().length > 0 && newIndexName.trim().length <= 20) {
        const newIndexNameFormatted = newIndexName.trim();
        this.apiAddIndex(newIndexNameFormatted);
      }
    }
  }

  handleAddNewIndexExit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ closeEditTagIcon: closeIcon });
    this.apiGetIndex("newIndexOutside");
  }

  onToggleDeleteIndexModal = (e, dltIndex) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const { isDeleteIndexModalVisible } = this.state;
    this.setState({
      dltIndex: dltIndex || dltIndex === 0 ? dltIndex : null,
      isDeleteIndexModalVisible: !isDeleteIndexModalVisible,
      showEditIcon: false,
      showOptions: false,
    });
  };

  // api call to edit index
  apiEditIndex = async (newIndexName) => {
    const { prevEditIndexName } = this.state;
    if (prevEditIndexName.toLowerCase() === newIndexName.toLowerCase()) {
      this.setState({
        editIndexId: "",
        prevEditIndexName: "",
        newEditIndexName: "",
      });
      return;
    }
    const params = {
      index: prevEditIndexName,
      new_index: newIndexName,
    };
    const response = await editIndex(params);

    if (response && response.message === "Index name exists") {
      toast.error("Index name exists");
      return;
    }

    if (response && response.success) {
      this.apiGetIndex("editIndex");
    } else {
      toast.error(response.message);
    }
  }

  // api call to add new index
  apiAddIndex = async (newIndexName) => {
    const response = await addIndex({ index: newIndexName });

    if (response && response.message === "Index name exists") {
      toast.error("Index name exists");
      return;
    }

    if (response && response.success) {
      toast.success("Index has been successfully created!");
      this.apiGetIndex("newIndex");
    } else {
      toast.error(response.message);
    }
  }

  handleIndexTitleOutsideClick = () => {
    this.apiGetIndex("newIndexOutside");
  }

  // api call to get all index
  apiGetIndex = async (flag) => {
    // click outside without input text
    if (flag === "newIndexOutside") {
      this.setState({
        // activeTab: activeTabName.toString(),
        newIndexName: "",
        newIndex: false,
        editIndexId: "",
        prevEditIndexName: "",
        newEditIndexName: "",
      });
    } else {
      const response = await getIndex();
      if (response && response.success) {
        const { updateProductList, updateIndices, getAllProducts } = this.props;
        const { activeTab } = this.state;
        // when input box shows
        if (flag === "newIndex") {
          this.setState({
            indices: response.indices,
            activeTab: String(response.indices.length),
            newIndexName: "",
            newIndex: false,
            editIndexId: "",
            prevEditIndexName: "",
            newEditIndexName: "",
          });
          updateProductList(String(response.indices.length), response.indices);
          this.changewindowHash(response.indices.length, response.indices);
        } else if (flag === "editIndex") {
          this.setState({
            indices: response.indices,
            editIndexId: "",
            prevEditIndexName: "",
            newEditIndexName: "",
          });
          updateIndices(response.indices);
          this.changewindowHash(activeTab, response.indices);
        } else if (flag === "deleteIndex") {
          this.changewindowHash("0", response.indices);
          this.setState({
            activeTab: "0",
            indices: response.indices,
            isDeleteIndexModalVisible: false,
            dltIndex: null,
          }, () => {
            this.getPrevTabAfterDelete("0");
          });
        } else {
          this.setState({
            indices: response.indices,
          }, () => {
            this.resetIndexRelatedState();
            getAllProducts(activeTab, true, true);
          });
        }
      } else {
        toast.error(response.message);
      }
    }
  }

  onDeleteIndex = () => {
    const { dltIndex } = this.state;
    if (dltIndex || dltIndex === 0) {
      this.apiDeleteIndex(dltIndex);
      this.setState({ showEditIcon: false, showOptions: false });
    }
  }

  apiDeleteIndex = async (dltIndex) => {
    const { indices } = this.state;
    const index = indices[dltIndex];
    const params = { index };
    const response = await deleteIndex(params);
    if (response && response.success) {
      this.apiGetIndex("deleteIndex", String(dltIndex));
      toast.success(`Index "${index}" deleted`);
    } else {
      toast.error(response.message);
    }
  }

  render() {
    const {
      activeTab,
      indices,
      newIndex,
      newIndexName,
      editIndexId,
      newEditIndexName,
      dltIndex,
      isDeleteIndexModalVisible,
      showOptions,
      showEditIcon,
      closeEditTagIcon,
    } = this.state;

    const indexNameTooLong = newIndexName.length > 20 || newEditIndexName.length > 20;
    let indicesEle = null;
    const indexTabPaddingLeft = "0px";
    const indexTabPaddingRight = "30px";

    if (indices && indices.length > 0) {
      indicesEle = indices.map((item, i) => {
        if (String(i + 1) === editIndexId) {
          return (
            <Tab
              key={i + 1}
              eventKey={i + 1}
              title={(
                <OutsideClickHandler onOutsideClick={this.handleIndexEditExitSubmit}>
                  <div
                    className="IndexCategories__productTabsContainer--editExistingCategory-indexTab indexTab editing"
                    style={{
                      // width: "150px",
                      paddingLeft: indexTabPaddingLeft,
                      paddingRight: indexTabPaddingRight,
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) => e.stopPropagation()}
                  >
                    <input
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      type="text"
                      className={newEditIndexName.trim().length > 20 ? "editInput exceedCharsLimit" : "editInput"}
                      name="newIndexName"
                      placeholder="Index Name"
                      value={newEditIndexName}
                      onKeyPress={(e) => this.handleEnterIndexName(e, newEditIndexName, "editIndex")}
                      onChange={(e) => this.setState({ newEditIndexName: e.target.value })}
                    />
                    <img
                      className="IndexCategories__productTabsContainer--editExistingCategory-closeIcon"
                      src={closeEditTagIcon}
                      alt="closeIcon"
                      onClick={this.handleIndexEditExitSubmit}
                      onMouseEnter={() => this.setState({ closeEditTagIcon: closeIconHighlighed })}
                      onFocus={() => this.setState({ closeEditTagIcon: closeIconHighlighed })}
                      onMouseOut={() => this.setState({ closeEditTagIcon: closeIcon })}
                      onBlur={() => this.setState({ closeEditTagIcon: closeIcon })}
                    />
                    <img
                      src={indexNameTooLong || newEditIndexName.length === 0 ? checkIcon : checkHightlightIcon}
                      alt="checkIcon"
                      className="IndexCategories__productTabsContainer--editExistingCategory-checkIcon"
                      onClick={() => this.handleIndexEditSubmit(newEditIndexName)}
                    />
                    {indexNameTooLong && (
                      <div
                        className="IndexCategories__productTabsContainer--editExistingCategory-longIndexNameErrorContainer"
                      >
                        <img
                          src={exclamationIcon}
                          alt="exclamation"
                          className="IndexCategories__productTabsContainer--editExistingCategory-longIndexNameErrorContainer-exclamationIcon"
                        />
                        {" "}
                        You have reached your maximum limit of 20 characters allowed
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
              )}
            />
          );
        }
        return (
          <Tab
            key={i + 1}
            eventKey={i + 1}
            title={(
              <div
                className="IndexCategories__productTabsContainer--notEditingIndexTab notEditing indexTab"
                style={{
                  // cursor: "pointer",
                  paddingRight: showEditIcon && activeTab === String(i + 1) ? "8px" : "16px",
                  marginRight: showEditIcon && activeTab === String(i + 1) ? "0px" : "24px",
                }}
                onMouseOver={activeTab === String(i + 1) ? () => this.setState({ showEditIcon: true }) : null}
                onMouseLeave={!showOptions ? () => this.setState({ showEditIcon: false }) : null}
              >
                {showEditIcon && activeTab === String(i + 1)
                  ? (
                    <div>
                      {item}
                      <img
                        src={dotsIcon}
                        alt="dotIcon"
                        className="IndexCategories__productTabsContainer--notEditingIndexTab-dotIcon"
                        style={{
                          // cursor: "pointer",
                          // width: "32px",
                          // height: "12px",
                          // padding: "0px 15px",
                        }}
                        onClick={this.onMenuIconClick}
                      />
                      {showOptions
                        ? (
                          <OutsideClickHandler onOutsideClick={activeTab === String(i + 1) ? () => this.setState({ showOptions: false, showEditIcon: false }) : null}>
                            <div
                              className="IndexCategories__productTabsContainer--notEditingIndexTab-options"
                              // style={{
                              //   position: "absolute",
                              //   zIndex: "1",
                              //   right: "-75px",
                              //   top: "8px",
                              // }}
                            >
                              <div className="IndexCategories__productTabsContainer--notEditingIndexTab-dropDownMenu drop-down-menu"/* style={{ height: "64px", width: "100px" }} */>
                                <div
                                  className="IndexCategories__productTabsContainer--notEditingIndexTab-dropDownMenuOption drop-down-menu-option"
                                  // style={{ paddingLeft: "3px" }}
                                  onClick={(e) => this.handleIndexEditClick(e, activeTab)}
                                >
                                  <img
                                    src={editIcon}
                                    alt="editTabIcon"
                                    className="IndexCategories__productTabsContainer--notEditingIndexTab-dropDownMenuOption-editIcon"
                                    // style={{ margin: "auto 10px auto 10px" }}
                                    width={12}
                                  />
                                  <span>Edit</span>
                                </div>
                                <div
                                  className="IndexCategories__productTabsContainer--notEditingIndexTab-dropDownMenuOption drop-down-menu-option"
                                  // style={{ paddingLeft: "3px" }}
                                  onClick={(e) => this.onToggleDeleteIndexModal(e, i)}
                                >
                                  <img
                                    src={deleteIcon}
                                    alt="deleteTabIcon"
                                    className="IndexCategories__productTabsContainer--notEditingIndexTab-dropDownMenuOption-deleteIcon"
                                    // style={{ margin: "auto 9.33px auto 9.33px" }}
                                    width={13.3}
                                  />
                                  <span>Delete</span>
                                </div>
                              </div>
                            </div>
                          </OutsideClickHandler>
                        )
                        : null}
                    </div>
                  )
                  : (
                    <span>
                      {item}
                    </span>
                  )}
              </div>
            )}
          />
        );
      });
    }

    return (
      <div className="IndexCategories__productTabsContainer productTabsContainer"/* style={{ backgroundColor: "#FDFDFD", padding: "20px 78px 0px", marginTop: "-10px" }} */>
        {isDeleteIndexModalVisible && (!!dltIndex || dltIndex === 0) && (
          <DeleteIndexConfirmation
            onDeleteIndex={this.onDeleteIndex}
            onToggleModal={this.onToggleDeleteIndexModal}
            indexName={indices[activeTab - 1]}
          />
        )}
        <div
          className="IndexCategories__productTabsContainer--Header"
          // style={{
          //   fontFamily: "Roboto Medium",
          //   fontStyle: "normal",
          //   fontWeight: "500",
          //   fontSize: "14px",
          //   lineHeight: "24px",
          //   color: "#375393",
          //   marginBottom: "5px",
          // }}
        >
          Index categories
        </div>
        <Tabs
          id="prodTabs"
          className="IndexCategories__productTabsContainer--Tabs customTab"
          // style={{ width: "fit-content", gap: "10px" }}
          activeKey={activeTab}
          onSelect={this.handleTabSelect}
        >
          {!newIndex
            ? (
              <Tab
                eventKey={indices.length + 1}
                title={(
                  <div
                    className="indexTab prodCreateNewIndexText"
                  >
                    <StyledTooltip
                      title="Add a new index Category"
                      placement="top"
                    >
                      <div>
                        Create new category
                        <img
                          className="IndexCategories__productTabsContainer--CreateNewCategory-addIcon"
                          src={ProdAddNewCategoryIcon}
                          alt="add"
                        />
                      </div>
                    </StyledTooltip>
                  </div>
              )}
              />
            )
            : (
              <Tab
                eventKey={indices.length + 1}
                title={(
                  <OutsideClickHandler onClickAway={() => this.handleIndexTitleOutsideClick()}>
                    <div
                      className="IndexCategories__productTabsContainer--EditCreateNewCategory-indexTab indexTab"
                      style={{
                        // width: "175px",
                        paddingLeft: indexTabPaddingLeft,
                        paddingRight: indexTabPaddingRight,
                      }}
                      onKeyDown={(e) => e.stopPropagation()}
                      onChange={(e) => e.stopPropagation()}
                    >
                      <input
                        type="text"
                        className={indexNameTooLong ? "newIndexInput exceedCharsLimit" : "newIndexInput"}
                        name="newIndexName"
                        placeholder="Eg: Product"
                        value={newIndexName}
                        onChange={this.onAddIndexInputChange}
                        onKeyPress={(e) => this.handleEnterIndexName(e, newIndexName)}
                        autoFocus
                      />
                      <img
                        className="IndexCategories__productTabsContainer--EditCreateNewCategory-closeIcon"
                        src={closeEditTagIcon}
                        alt="closeIcon"
                        // style={{
                        //   position: "absolute",
                        //   width: "16px",
                        //   height: "16px",
                        //   right: "38px",
                        // }}
                        onClick={this.handleAddNewIndexExit}
                        onMouseEnter={() => this.setState({ closeEditTagIcon: closeIconHighlighed })}
                        onMouseOut={() => this.setState({ closeEditTagIcon: closeIcon })}
                        onBlur={() => this.setState({ closeEditTagIcon: closeIcon })}
                      />
                      <img
                        src={indexNameTooLong || newIndexName.length === 0 ? checkIcon : checkHightlightIcon}
                        alt="add"
                        className="IndexCategories__productTabsContainer--EditCreateNewCategory-checkIcon"
                        // style={{
                        //   position: "absolute",
                        //   right: "4px",
                        //   width: "20px",
                        //   height: "20px",
                        // }}
                        onClick={() => this.handleAddNewIndex(newIndexName)}
                      />
                      {indexNameTooLong && (
                      <div
                        className="IndexCategories__productTabsContainer--EditCreateNewCategory-longIndexNameErrorContainer"
                        // style={{
                        //   position: "absolute",
                        //   top: "38px",
                        //   height: "18px",
                        //   width: "300%",
                        //   fontFamily: "Roboto",
                        //   fontStyle: "normal",
                        //   fontWeight: "normal",
                        //   fontSize: "12px",
                        //   lineHeight: "18px",
                        //   color: "#E63559",
                        //   backgroundColor: "#FFFFFF",
                        // }}
                      >
                        <img
                          src={exclamationIcon}
                          alt="exclamation"
                          className="IndexCategories__productTabsContainer--EditCreateNewCategory-longIndexNameErrorContainer-exclamationIcon"
                          // style={{ height: "13.33px" }}
                        />
                        {" "}
                        You have reached your maximum limit of 20 characters allowed
                      </div>
                      )}
                    </div>
                  </OutsideClickHandler>
              )}
              />
            )}

          <Tab
            eventKey={0}
            title={(
              <div className="indexTab notEditing">
                Product
              </div>
            )}
          />

          {indicesEle}

        </Tabs>
      </div>
    );
  }
}

export default IndexCategories;

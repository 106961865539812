import React, { Component } from "react";
import { Layout } from "antd";
import { toast } from "react-toastify";
import { Slide } from "react-awesome-reveal";
import "../myAccount.css";
import "./MyProfile.css";
import MyAccountSideMenu from "../MyAccountSideMenu";
import NameAndPhone from "./NameAndPhone";
import Password from "./Password";
import { getUserCredentials, setUserCredentials } from "../../../utils/common";

import {
  signIn,
  changePwd,
} from "../../../actions/login";

import {
  updateProfileInfo,
} from "../../../actions/myAccount";

import { validatePassword, validatePhoneNumber } from "../../Common/utils/validationUtils";
import { AppContext } from "../../../AppContext";

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: "",
      profile: {
        name: "",
        phone: "",
        email: "",
      },
      name: "",
      phone: "",

      passType: "password",
      newPassType: "password",
      confirmedPassType: "password",
      loading: false,
    };
  }

  componentDidMount() {
    // const user = getUserCredentials();
    const myEmail = getUserCredentials().user_email;
    const myName = getUserCredentials().user_name;
    const myPhone = getUserCredentials().user_contact;
    const profile = {
      name: myName ?? "",
      phone: myPhone ?? "",
      email: myEmail ?? "",
    };
    this.setState({ profile });
  }

  handleMenuClick = (event) => {
    const { history } = this.props;
    const key = event.key;
    if (key === "1") {
      history.push("/myaccount/profile");
    } else if (key === "2") {
      history.push("/myaccount/members");
    } else if (key === "3") {
      history.push("/myaccount/help");
      // this.props.history.push(`/myaccount/setting`);
    }
    // else if (key === "4") {
    //   this.props.history.push(`/myaccount/faq`);
    // } else if (key === "5") {
    //   this.props.history.push(`/myaccount/help`);
    // }
  }

  handleTextChange = (event, key) => {
    this.setState({ [key]: event.target.value });
  }

  handleEditClick = (flag) => {
    const { profile } = this.state;
    if (flag === "all") {
      this.setState({
        showEdit: flag,
        name: profile.name,
        phone: profile.phone,
      });
    } else if (flag === "password") {
      this.setState({ showEdit: flag });
    }
  }

  showHidePassword = (key, value) => {
    this.setState({ [key]: value === "text" ? "password" : "text" });
  }

  handleSaveEditClick = () => {
    const {
      showEdit, profile, name, phone,
    } = this.state;
    this.setState({ loading: true });
    if (showEdit === "password") {
      const passwordElm = document.getElementById("myaccountpassword");
      const newpasswordElm = document.getElementById("myaccountnewpassword");
      const confirmedPasswordElm = document.getElementById("myaccountconfirmedPassword");
      if (!passwordElm || !newpasswordElm || !confirmedPasswordElm) { return; }

      const password = passwordElm.value;
      const newpassword = newpasswordElm.value;
      const confirmedPassword = confirmedPasswordElm.value;

      const email = profile.email;

      if (!password) {
        toast.error("Please enter previous password.");
        this.setState({ loading: false });
        return;
      }
      if (newpassword !== confirmedPassword) {
        toast.error("Those new passwords didn't match. Try again.");
        this.setState({ loading: false });
        return;
      }
      if (!newpassword || !confirmedPassword) {
        toast.error("The new password cannot be empty. Try again.");
        this.setState({ loading: false });
        return;
      }
      if (!validatePassword(newpassword)) {
        toast.error("The new password does not meet the requirements.");
        this.setState({ loading: false });
        return;
      }
      const { rememberMe } = getUserCredentials();
      // api call to login the user to check the current psw
      signIn(email, password, rememberMe, this.context, (result) => {
        if (!result.success) {
          this.setState({ loading: false });
          toast.error(result.message.includes("invalid") ? "The old password is invalid." : result.message);
          return;
        }
        changePwd(newpassword, ({ success }) => {
          this.setState({ loading: false });
          if (success) {
            toast.success("Password updated successfully");
            this.setState({
              showEdit: "",
              loading: false,
            });
          } else {
            toast.error("Please try again later");
            this.setState({
              loading: false,
            });
          }
        });
      });
    } else if (showEdit === "all") {
      if (phone.trim() && !validatePhoneNumber(phone.trim())) {
        this.setState({ loading: false });
        toast.error("Phone number is invalid.");
        return;
      }
      const params = {
        name,
        contact: phone.trim(),
        email: profile.email,
      };

      // api call to edit all and get the new profile
      this.apiUpdateMyProfile(params).then((res) => {
        if (res === 200) {
          const user_details = getUserCredentials();
          user_details.user_name = name;
          user_details.user_contact = phone;
          setUserCredentials(null, user_details, this.context);
          profile.phone = phone;
          profile.name = name;
          this.setState({
            showEdit: "",
            phone: "",
            name: "",
            profile,
            loading: false,
          });
        } else {
          toast.error("Unable to update info. Please try again later.");
          this.setState({
            loading: false,
          });
        }
      });
    }
  }

  apiUpdateMyProfile = async (params) => {
    const response = await updateProfileInfo(params);
    if (response && response.success) {
      return 200;
    } return null;
  }

  handleSaveCancelClick = () => {
    this.setState({
      showEdit: "",
      phone: "",
      name: "",
    });
  }

  render() {
    const {
      showEdit,
      profile, name, phone,
      passType, newPassType,
      confirmedPassType,
      loading,
    } = this.state;

    return (
      <div className="myAccountMainContainer">
        <Layout className="my-profile-layout">
          <MyAccountSideMenu
            selectedMenuItem="1"
            handleMenuClick={this.handleMenuClick}
          />
          <div className="myAccountRightMainContainer">
            <div className="myAccountProfileMainContainer">
              <Slide direction="left" triggerOnce>
                <div className="myAccountContainerTitle">
                  My Profile
                </div>
              </Slide>

              <Slide direction="up" triggerOnce>
                <div>
                  <NameAndPhone
                    showEdit={showEdit === "all"}
                    name={name}
                    phone={phone}
                    profile={profile}
                    handleTextChange={this.handleTextChange}
                    handleSaveCancelClick={this.handleSaveCancelClick}
                    handleSaveEditClick={this.handleSaveEditClick}
                    handleEditClick={this.handleEditClick}
                    loading={loading}
                  />
                  <div className="myAccountProfileInputContainer">
                    <div className="myAccountFormTitle">
                      Email
                    </div>
                    <input
                      type="text"
                      placeholder="Email"
                      defaultValue={profile.email}
                      className="myaccountDefaultInput"
                      disabled
                    />
                  </div>
                  <Password
                    showEdit={showEdit === "password"}
                    passType={passType}
                    newPassType={newPassType}
                    confirmedPassType={confirmedPassType}
                    showHidePassword={this.showHidePassword}
                    handleTextChange={this.handleTextChange}
                    handleSaveCancelClick={this.handleSaveCancelClick}
                    handleSaveEditClick={this.handleSaveEditClick}
                    handleEditClick={this.handleEditClick}
                    loading={loading}
                  />
                </div>
              </Slide>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

MyProfile.contextType = AppContext;
export default MyProfile;

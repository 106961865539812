import React, { useEffect, useState } from "react";

import { useLocation } from "react-router";
import { toast } from "react-toastify";

import ButtonWithLoading from "../Common/ButtonWithLoading";
import LoginInput from "./Common/LoginInput";

import firebaseAuth from "../../utils/firebase";
import { validatePassword } from "../Common/utils/validationUtils";

import loginBgIcon from "../../assets/images/loginBgImg.svg";
import loginMainIcon from "../../assets/images/loginMainImg.svg";
import esv_logo from "../../assets/images/logo/esv_logo_2x.png";

import "./login.css";
import "./ResetPassword.css";

export default function ResetPassword({ history }) {
  const [email, setEmail] = useState("");
  const [firebaseCode, setFirebaseCode] = useState(""); // string. A verification code Firebase sends via email to the user when it clicks reset.
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();

  /** componentDidMount - parse firebase code and email from url */
  useEffect(() => {
    const params = new URLSearchParams(search);
    const oobCode = params.get("oobCode");
    if (oobCode) {
      firebaseAuth.verifyPasswordResetCode(oobCode)
        .then((userEmail) => {
          setFirebaseCode(oobCode);
          setEmail(userEmail);
        })
        .catch(() => {
          /** Link expired or was already used, redirect to invalid page */
          history.push("/resetpassword/invalid");
        });
    } else {
      history.push("/resetpassword/invalid");
    }
  }, []); // eslint-disable-line

  const onSubmit = () => {
    let atLeastOneMissing = false;
    if (!password) {
      setPasswordError(true);
      atLeastOneMissing = true;
    }
    if (!confirmedPassword) {
      setConfirmPasswordError(true);
      atLeastOneMissing = true;
    }
    if (atLeastOneMissing) {
      return;
    }

    if (!validatePassword(password)) {
      toast.error("Password does not meet the requirements.");
    } else if (password !== confirmedPassword) {
      toast.error("Those passwords didn't match. Try again.");
    } else {
      setLoading(true);
      firebaseAuth.confirmPasswordReset(firebaseCode, password)
        .then(() => {
          setLoading(false);
          history.push("./resetpassword/success");
        })
        .catch((error) => {
          setLoading(false);
          if (error.code === "auth/expired-action-code" || error.code === "auth/invalid-action-code") {
            history.push("/resetpassword/invalid");
          } else {
            toast.error("Failed to reset password. Try again.");
          }
        });
    }
  };

  const handleChangPsw = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeConfirmPsw = (e) => {
    setConfirmedPassword(e.target.value);
  };

  if (!email) return null;

  return (
    <div className="login-page">
      <img
        src={esv_logo}
        alt="esv"
        className="logoLoginPage"
      />

      <div className="loginMainContainer">
        <form className="login-box">
          <div className="login-header">
            Reset password
          </div>
          <div className="reset-password-subtitle">
            <span>for </span>
            <span className="reset-password-email">{email}</span>
          </div>
          <LoginInput
            label="Password"
            isPassword
            id="reset-password"
            name="password"
            value={password}
            onChange={handleChangPsw}
            error={passwordError}
            clearError={() => setPasswordError(false)}
          />
          <div className="reset-password-require">Must contain: at least 1 number, 1 uppercase, 1 lowercase letter, at least 6 characters, no spaces.</div>
          <LoginInput
            label="Password"
            isPassword
            id="reset-password-confirm"
            name="password"
            value={confirmedPassword}
            onChange={handleChangeConfirmPsw}
            error={confirmPasswordError}
            clearError={() => setConfirmPasswordError(false)}
            onEnter={onSubmit}
          />

          <div className="reset-password-btn-container login-button-container">
            <ButtonWithLoading
              defaultText="Submit"
              loadingText="Submit"
              className="reset-password-btn login-btn"
              onClick={() => onSubmit()}
              disable={loading}
              isLoading={loading}
            />
          </div>
        </form>

        <div className="loginImage">
          <img
            className="loginImageCharacters"
            src={loginMainIcon}
            alt="esv"
          />
          <img
            className="loginImageBubbles"
            src={loginBgIcon}
            alt="esv"
          />
        </div>
      </div>
    </div>
  );
}

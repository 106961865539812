import React from "react";
import CreateReportButton from "./CreateReportButton";
import TemplateSearch from "./TemplateSearch";
import { ReactComponent as BackIcon } from "../../../../../assets/images/common/LeftArrowRounded.svg";
import "../styles/common/header.css";

export default function Header(props) {
  const {
    title,
    handleAddCoa,
    isCreatingCoa,
    showCreateReportButton,
    handleNavigation,
    apiSearchCoas,
    showAddIcon,
    coaView,
  } = props;

  return (
    <div className="headerContainer">
      <div className="headerTitle">
        {isCreatingCoa && (<BackIcon className="TemplateHeader__BackIcon" role="button" title="back" onClick={handleNavigation} />)}
        <div className={coaView ? "HeaderTitle__Subtitle__COA" : "HeaderTitle__Subtitle"}>{title}</div>
      </div>
      {showCreateReportButton && isCreatingCoa
        && (
        <div className="headerRight">
          <div className="searchContainer">
            <TemplateSearch handleSearch={apiSearchCoas} />
          </div>
        </div>
        )}
      {showCreateReportButton && !isCreatingCoa
        && (
        <div className="searchContainer">
          <CreateReportButton handleAddCoa={handleAddCoa} buttonText="Create custom report" showAddIcon={showAddIcon} />
          <TemplateSearch handleSearch={apiSearchCoas} />
        </div>
        )}

    </div>
  );
}

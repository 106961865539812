import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { s3EsvPriv, s3EsvPub } from "../../../../../../../utils/aws";
import { downloadFileFromS3 } from "../../../../../../../utils/helpers";
import { ReactComponent as PDFIcon } from "../../../../../../../assets/images/environment/pdfIcon.svg";

export default function FileCard({ file }) {
  const fileName = file.path ? file.path.split("/").pop() : "";
  const handleDownloadFile = async (path) => {
    // console.log("🚀 ~ handleDownloadFile ~ filePath:", filePath);
    if (!path) {
      toast.error("Unable to download, no valid file path for this report!");
      return;
    }
    const isPrivateS3Bucket = path.includes("COAs/");
    if (isPrivateS3Bucket) {
      const source = path;
      const success = await downloadFileFromS3(source, s3EsvPriv, fileName);
      if (!success) {
        toast.error("Unable to download file");
      }
    } else {
      const source = path;
      const success = await downloadFileFromS3(source, s3EsvPub, fileName);
      if (!success) {
        toast.error("Unable to download file");
      }
    }
  };
  return (
    <div
      className="env-report-history-file-card"
      onClick={() => handleDownloadFile(file.path)}
    >
      <div className="pdf-icon-path">
        <PDFIcon width={14} height={14} />
        <span>{fileName}</span>
      </div>
      <span className="file-card-date">{moment(new Date(file.received_date)).format("DD MMM YYYY")}</span>
    </div>
  );
}

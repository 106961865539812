import React, { useState, useEffect } from "react";
import moment from "moment";

export default function TimeFromNow({ time, dateFormat }) {
  const [timeFromNow, setTimeFromNow] = useState("");

  /** Compares time to current local time and gets timeFromNow.
   * If time is over a week ago, setTimeFromNow to
   * the date formatted with dateFormat instead.
   */
  const calculateTimeFromNow = () => {
    if (time === undefined || time === "") {
      setTimeFromNow("");
    } else {
      const momentTime = moment(new Date(time));
      const _timeFromNow = momentTime.fromNow();
      // console.log(timeFromNow);
      if (_timeFromNow.includes("second")) {
        setTimeFromNow("Just Now");
      } else if (_timeFromNow.includes("week")) {
        setTimeFromNow(moment(momentTime, dateFormat));
      } else {
        /** a min/an hour ago becomes 1 min/hour ago */
        const regex = /^a |^an /;
        setTimeFromNow(_timeFromNow.replace(regex, "1 "));
      }
    }
  };

  /** On mount or when time prop changes, set up the interval.
   * Every second, update timeFromNow state.
   * On unmount or when time prop changes, clear interval.
   */
  useEffect(() => {
    calculateTimeFromNow(); // get initial timeFromNow on mount
    const interval = setInterval(calculateTimeFromNow, 1000);
    return () => clearInterval(interval);
  }, [time]); // eslint-disable-line
  // console.log(time, timeFromNow);
  return (
    <span>{timeFromNow}</span>
  );
}

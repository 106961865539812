import React, { useState, useRef, useEffect } from "react";
import { checkPinIsPinned } from "../pinHelper";
import { QUADRANT_STYLE_MAP } from "../Constant";
import UndraggablePin from "./Pin";

export default function PinsVisibleArea({
  updatePinInfo,
  imageRef,
  imageWidth,
  imageHeight,
  containerRef: visibleArea,
  indexOfQuadrantHoverOver,
  selectedLocationID,
  quadrantSelected,
  isPinningSwab,
  pinMap,
  imageDisplay,
  listViewDisplay,
  state,
  displayTooltipLocationID,
  endPinningSwab,
  setDisplayTooltipLocationID,
  setTransform,
  handleSelectQuadrant,
  updateMinScale,
  setSelectedLocationID,
  isAddingNewSwab,
  isPinningNewSwab,
  currentZoom,
  isClickingQuadrant,
  clickQuadrant,
  index0,
}) {
  const [visibleAreaFlag, setVisibleAreaFlag] = useState(false);
  const [pinRelocatingLocationID, setPinRelocatingLocationID] = useState("");
  const [isRelocatingSwab, setIsRelocatingSwab] = useState(false);//eslint-disable-line
  const [visibleAreaDims, setVisibleAreaDims] = useState({
    height: 0, width: 0, left: 0, top: 0,
  });
  const visibleAreaRef = useRef();

  // console.log("map state", state);

  /**
   * When window resizes, update state of PinsVisibleArea
   * Readjust map and update in scale.
   */
  useEffect(() => {
    function handleResize() {
      let { x, y } = state;
      const { scale } = state;
      if (imageRef.current && visibleArea.current) {
        const imgInfo = imageRef.current.getBoundingClientRect();
        const visibleAreaInfo = visibleArea.current.getBoundingClientRect();
        const {
          height, width, left, top,
        } = visibleAreaInfo;
        setVisibleAreaDims({
          height, width, left, top,
        });
        const adjustRight = visibleAreaInfo.right > imgInfo.right;
        const adjustLeft = visibleAreaInfo.left < imgInfo.left;
        const adjustTop = visibleAreaInfo.top < imgInfo.top;
        const adjustBottom = visibleAreaInfo.bottom > imgInfo.bottom;
        updateMinScale(Math.min(visibleAreaInfo.width / imageWidth, visibleAreaInfo.height / imageHeight));
        // console.log(state);
        // console.log(Math.min(visibleAreaInfo.width / imgInfo.width, visibleAreaInfo.height / imgInfo.height));
        if (!adjustLeft && !adjustRight && !adjustTop && !adjustBottom) {
          setVisibleAreaFlag(!visibleAreaFlag);
        } else {
          let diff = visibleAreaInfo.width - imgInfo.width;
          if (adjustLeft) {
            x = Math.max(diff / 2, 0);
          } else if (adjustRight) {
            x = diff >= 0 ? diff / 2 : diff;
          }
          diff = visibleAreaInfo.height - imgInfo.height;
          if (adjustTop) {
            y = Math.max(diff / 2, 0);
          } else if (adjustBottom) {
            y = diff >= 0 ? diff / 2 : diff;
          }
          setTransform(x, y, scale, 0);
        }
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  /**
   * When content overflows env-main-container, scrollbar will show.
   * When user scrolls this container, update state of PinsVisibleArea.
   */
  useEffect(() => {
    function handleScroll() {
      const visibleAreaInfo = visibleArea.current.getBoundingClientRect();
      const {
        height, width, left, top,
      } = visibleAreaInfo;
      setVisibleAreaDims({
        height, width, left, top,
      });
    }

    document.getElementById("env-main-container").addEventListener("scroll", handleScroll);

    return () => {
      document.getElementById("env-main-container").removeEventListener("scroll", handleScroll);
    };
  });

  const imageInfo = imageRef.current ? imageRef.current.getBoundingClientRect() : "";
  const visibleAreaInfo = visibleArea.current ? visibleArea.current.getBoundingClientRect() : "";

  let hideSwabNumber = true;
  if (!listViewDisplay) {
    hideSwabNumber = false;
  } else if (imageInfo && visibleAreaInfo) {
    // if (imageInfo.width > visibleAreaInfo.width || imageInfo.height > visibleAreaInfo.height) {
    if (currentZoom > 100) {
      hideSwabNumber = false;
    }
  }

  const displayQuadrantColor = indexOfQuadrantHoverOver !== -1;

  const handleClick = (e) => {
    // console.log("click on pinsvisiblearea");
    if ((selectedLocationID && !checkPinIsPinned(pinMap.get(selectedLocationID)) && quadrantSelected !== -1)) {
      const x_coordinate = (e.clientX - imageInfo.left) / imageInfo.width;
      const y_coordinate = (e.clientY - imageInfo.top) / imageInfo.height;
      if (x_coordinate >= 0 && x_coordinate <= 1 && y_coordinate >= 0 && y_coordinate <= 1) {
        updatePinInfo("pinTheLocation", selectedLocationID, { x_coordinate, y_coordinate });
        // if (isPinningNewSwab) {
        //   togglePinningNewSwab();
        // }
      }
    }
    if (pinRelocatingLocationID) {
      setIsRelocatingSwab(true);
      // console.log(pinRelocatingLocationID);
      const x_coordinate = (e.clientX - imageInfo.left) / imageInfo.width;
      const y_coordinate = (e.clientY - imageInfo.top) / imageInfo.height;
      if (x_coordinate >= 0 && x_coordinate <= 1 && y_coordinate >= 0 && y_coordinate <= 1) {
        updatePinInfo("pinTheLocation", pinRelocatingLocationID, { x_coordinate, y_coordinate });
        setPinRelocatingLocationID("");
      }
    }
  };

  const endRelocatingSwab = () => {
    // console.log("end relocating");
    setIsRelocatingSwab(false);
    setPinRelocatingLocationID("");
  };

  // console.log(pinMap, selectedLocationID);
  const pinnedLocations = Array.from(pinMap.values()).filter((pin) => checkPinIsPinned(pin));

  const animationWhenMount = (pin) => (isPinningSwab || isPinningNewSwab) && (selectedLocationID === pin.location_id);
  // const animationWhenMount = (pin) => ((isPinningSwab || isPinningNewSwab) && (selectedLocationID === pin.location_id)) || (!listViewDisplay && isRelocatingSwab && (pinRelocatingLocationID === pin.location_id));

  return (
    <div
      id="pinsVisibleArea"
      style={{
        position: "absolute",
        inset: "42px",
        zIndex: pinRelocatingLocationID || isAddingNewSwab || (selectedLocationID && !checkPinIsPinned(pinMap.get(selectedLocationID))) ? "1" : "auto",
        cursor: (pinRelocatingLocationID || isAddingNewSwab || (selectedLocationID && !checkPinIsPinned(pinMap.get(selectedLocationID)))) ? "default" : "grab",
      }}
      ref={visibleAreaRef}
      onClick={handleClick}
    >
      {displayQuadrantColor && (
      <div
        style={{
          position: "fixed",
          left: indexOfQuadrantHoverOver === 0 || indexOfQuadrantHoverOver === 3 ? `${imageInfo.x}px` : `${imageInfo.x + imageInfo.width / 2}px`,
          top: indexOfQuadrantHoverOver === 0 || indexOfQuadrantHoverOver === 1 ? `${imageInfo.y}px` : `${imageInfo.y + imageInfo.height / 2}px`,
          width: `${imageInfo.width / 2}px`,
          height: `${imageInfo.height / 2}px`,
          backgroundColor: QUADRANT_STYLE_MAP[indexOfQuadrantHoverOver].hover_color,
          mixBlendMode: "multiply",
          borderRadius: "8px",
        }}
      />
      )}
      {(pinnedLocations && pinnedLocations.length > 0)
              && pinnedLocations.map((pin) =>
              // if (!pin.draggable) {
              // console.log(pin.swab_number, "draggable pinned");
                ( //eslint-disable-line

                  <UndraggablePin
                    key={pin.location_id}
                    pin={pin}
                    index0={index0}
                    animationWhenMount={animationWhenMount(pin)}
                    locationID={pin.location_id}
                    isHightLighted={pin.location_id === selectedLocationID}
                    hideSwabNumber={hideSwabNumber}
                    imageRef={imageRef.current}
                    visibleArea={visibleArea.current}
                    endPinningSwab={endPinningSwab}
                    endRelocatingSwab={endRelocatingSwab}
                    displayTooltipLocationID={displayTooltipLocationID}
                    updatePinInfo={updatePinInfo}
                    setDisplayTooltipLocationID={setDisplayTooltipLocationID}
                    listViewDisplay={listViewDisplay}
                    handleSelectQuadrant={handleSelectQuadrant}
                    setSelectedLocationID={setSelectedLocationID}
                    pinRelocatingLocationID={pinRelocatingLocationID}
                    setPinRelocatingLocationID={setPinRelocatingLocationID}
                    clickQuadrant={clickQuadrant}
                    isClickingQuadrant={isClickingQuadrant}
                    mapState={{
                      scale: state.scale,
                      positionX: state.positionX,
                      positionY: state.positionY,
                      width: visibleAreaDims.width,
                      height: visibleAreaDims.height,
                      left: visibleAreaDims.left,
                      top: visibleAreaDims.top,
                    }}
                  />
                )
                ,
                // return null;
              // }
              // eslint-disable-next-line
              )}
      {(selectedLocationID && !checkPinIsPinned(pinMap.get(selectedLocationID)) && quadrantSelected !== -1)
      /* while pinning swab: when user selects quadrant for new pin, create pin on PinsVisibleArea.
      pinMap not updated yet (updated when updatePinInfo called).
      {x, y} for this new pin is null until handleClick calls updatePinInfo with coords from user click */
              && (
              <UndraggablePin
                pin={{ ...pinMap.get(selectedLocationID), quadrant: quadrantSelected }}
                index0={index0}
                animation={false}
                imageRef={imageRef.current}
                visibleArea={visibleArea.current}
                setSelectedLocationID={setSelectedLocationID}
                updatePinInfo={updatePinInfo}
                displayTooltipLocationID={displayTooltipLocationID}
                imageDisplay={imageDisplay}
                listViewDisplay={listViewDisplay}
                pinRelocatingLocationID={pinRelocatingLocationID}
                setPinRelocatingLocationID={setPinRelocatingLocationID}
                endPinningSwab={endPinningSwab}
                handleSelectQuadrant={handleSelectQuadrant}
                mapState={{
                  scale: state.scale,
                  positionX: state.positionX,
                  positionY: state.positionY,
                  width: visibleAreaDims.width,
                  height: visibleAreaDims.height,
                  left: visibleAreaDims.left,
                  top: visibleAreaDims.top,
                }}
              />
              )}
    </div>
  );
}

import React from "react";
import StyledButton from "../../../../Common/UIComponents/StyledButton";
import closeFilterIcon from "../../../../../assets/images/product/lightBlueCancelIcon.png";
import "./FilterButton.css";

export default class FilterButton extends React.Component {
  parseFilter = () => {
    const { handleFilterToggle, filterText } = this.props;

    let parsedText = filterText.split(" ")[1];

    if (parsedText === "Added") {
      parsedText = "Added";
    } else {
      parsedText = "Pending";
    }

    handleFilterToggle(parsedText.toLowerCase());
  }

  render() {
    const {
      isActive,
      filterText,
      // handleCloseFilterClick,
      disableBlock,
      color,
    } = this.props;

    return (
      <StyledButton
        type={isActive ? "primary" : "text"}
        ghost={isActive}
        disabled={disableBlock}
        onClick={this.parseFilter}
        className={`${isActive ? "EnvFilterButton--active" : ""}`}
      >
        <div className="envSpecManagerFilterTile">
          <div className="envSpecManagerFilterSquare" style={{ backgroundColor: color }} />
          <div className="envSpecManagerFilterText">{filterText}</div>
          {isActive && (
            <img
              src={closeFilterIcon}
              alt="closeFilterIcon"
              className="EnvFilterButton__CloseIcon"
            />
          )}
        </div>
      </StyledButton>
    );
  }
}

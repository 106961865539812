import React, { Component } from "react";
import * as XLSX from "xlsx";

// import moment from "moment";
import { toast } from "react-toastify";
import PinList from "./PinList";
import EnvSearch from "./Search/EnvSearch";
import ListViewContentLoader from "./Loader/ListViewContentLoader";

import addFileIcon from "../../../../../assets/images/environment/addIconLightBlue.svg";
import uploadSwabFileIcon from "../../../../../assets/images/environment/uploadIcon.svg";
import pumpkin from "../../../../../assets/images/environment/pumpkin.gif";

import "./AnalyticsListView.css";

export default class AnalyticsListView extends Component {
  state = {
    isSearching: false,
  };

  docUploadInput = React.createRef();

  // clearCalendar = () => {
  //   this.setState({
  //     dateSelected: "",
  //     prevDateSelected: "",
  //     showCalendar: false,
  //   });
  // }

  handleAddFile = () => {
    // console.log("add excel click", this.docUploadInput.current);
    // this.docUploadInput.current.value = ""; // need to clear previous file input
    // this.docUploadInput.current.click();
    const { toggleShowCreateEnvModal } = this.props;
    toggleShowCreateEnvModal(true);
  };

  parseExcelFile = (e) => {
    // console.log("parse excel files", e.target.files);
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        try {
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
          const { addListOfPoints } = this.props;
          addListOfPoints(json);
        } catch (err) {
          toast.error(
            "Fail to upload. Please make sure the file is a spreadsheet",
          );
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  handleStylesWhenSearching = (isSearching, exitSearch) => {
    const { isSearching: currentIsSearching } = this.state;
    if (currentIsSearching !== isSearching) {
      this.setState({ isSearching });
    }
    if (exitSearch) {
      const { handleSearch } = this.props;
      handleSearch();
    }
  };

  handleClickSearchResult = (pin) => {
    const { handleClickSearchResult } = this.props;
    this.setState({ isSearching: false });
    handleClickSearchResult(pin);
  };

  handleClickCreateNewSwab = () => {
    const {
      isSearching, imageSrc, toggleAddingSwab, loading,
    } = this.props;
    if (!imageSrc) {
      toast.error("Must upload map image first");
      return;
    }
    if (!loading && imageSrc === "Not Found") {
      toast.error("Map image must be loaded");
      return;
    }
    if (!isSearching) {
      toggleAddingSwab();
    }
  }

  render() {
    const {
      isSearching,
    } = this.state;

    const {
      pinMap,
      quadrantSelected,
      indexOfQuadrantHoverOver,
      selectedLocationID,
      filterSelected,
      isAddingNewSwab,
      searchResultSelected,
      loading,
      displayNoResultFound,
      toggleAddingSwab,
      setSelectedLocationID,
      updatePinInfo,
      handleAddNewSwab,
      handleHoverQuadrant,
      endPinningSwab,
      handleSelectQuadrant,
      handleSelectFilter,
      handleFilterByDate,
      handleSearch,
      setDisplayTooltipLocationID,
      transformWrapperRef,
      duplicateSwab,
      displayFields,
      isPinningNewSwab,
      togglePinningNewSwab,
      isPinningSwab,
      imageSrc,
      searchRef,
      isClickingQuadrant,
      clickQuadrant,
      toggleReportView,
      showReportView,
      index0,
      delimiter,
      linkPatternFields,
      sampleIDFields,
      getCalendarDates,
      forceReportViewRerender,
      envCalendarRef,
    } = this.props;

    return (
      <>
        {(pinMap.size > 0 // all cases that show EnvSearch (disabled or not)
          || (pinMap.size === 0 && displayNoResultFound)
          || (pinMap.size === 0 && loading)
          ? (
            <div style={{ height: "100%" }}>
              <div style={{ position: "relative" }}>
                <>
                  {/* {" "}
                  {showCalendar && (
                  <CalendarDropdown
                    getSelectedDates={this.getSelectedDates}
                    getCalendarDates={getCalendarDates}
                    displayDateSelected={this.displayDateSelected}
                    handleSearchDate={handleFilterByDate}
                    clearDate={this.handleClearSearchByDate}
                    onToggleModal={this.handleShowCalendar}
                    saveDateHistory={this.saveDateHistory}
                    handleCloseDateClick={this.handleCloseDateClick}
                  />
                  )} */}
                  <div className={`AnalyticsListView__Search ${loading ? "AnalyticsListView__Search--grayOut" : ""}`}>
                    <EnvSearch
                      filterSelected={filterSelected}
                      handleSelectFilter={handleSelectFilter}
                      handleFilterByDate={handleFilterByDate}
                      getCalendarDates={getCalendarDates}
                      searchResultSelected={searchResultSelected}
                      handleStylesWhenSearching={this.handleStylesWhenSearching}
                      handleSearch={handleSearch}
                      handleClickSearchResult={this.handleClickSearchResult}
                      isAddingNewSwab={isAddingNewSwab}
                      isClickingQuadrant={isClickingQuadrant}
                      ref={searchRef}
                      envCalendarRef={envCalendarRef}
                      showReportView={showReportView}
                    />
                  </div>
                </>
                {(loading || isAddingNewSwab || isClickingQuadrant || showReportView) && (<div className="disable-clicking-search" />)}
              </div>
              <div className="environment-analytics-list-view-buttons-and-list">
                <div className="create-and-upload-swab-container">
                  <div
                    className={loading || isSearching || showReportView ? "createNewSwabBtn disable" : "createNewSwabBtn enable"}
                    onClick={loading || isSearching || showReportView ? null : this.handleClickCreateNewSwab}
                  >
                    <img
                      alt="addSwabIcon"
                    />
                    Create New Swab
                  </div>
                  <div
                    className={loading || isSearching || showReportView ? "uploadSwabFileBtn disable" : "uploadSwabFileBtn enable"}
                    onClick={showReportView ? null : this.handleAddFile}
                  >
                    <div className="upload-swab-file-icon-circle">
                      <img
                        alt="upload swab file icon"
                        src={uploadSwabFileIcon}
                      />
                    </div>
                    Upload Swab File
                    {/* <input
                      id="docUploadInput"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      ref={this.docUploadInput}
                      type="file"
                      onChange={this.parseExcelFile}
                    // multiple
                      hidden
                    /> */}
                  </div>
                  {(isAddingNewSwab || isClickingQuadrant || showReportView) && (
                  <div className="create-new-swab-grayed-out" />
                  )}
                </div>
                <div className="AnalyticsListView__PinListContainer">
                  {!loading && pinMap.size > 0 && (
                  <PinList
                    pinMap={pinMap}
                    imageSrc={imageSrc}
                    loading={loading}
                    quadrantSelected={quadrantSelected}
                    indexOfQuadrantHoverOver={indexOfQuadrantHoverOver}
                    selectedLocationID={selectedLocationID}
                    setSelectedLocationID={setSelectedLocationID}
                    setDisplayTooltipLocationID={setDisplayTooltipLocationID}
                    isAddingNewSwab={isAddingNewSwab}
                    isSearching={isSearching}
                    handleAddNewSwab={handleAddNewSwab}
                    toggleAddingSwab={toggleAddingSwab}
                    duplicateSwab={duplicateSwab}
                    updatePinInfo={updatePinInfo}
                    endPinningSwab={endPinningSwab}
                    handleHoverQuadrant={handleHoverQuadrant}
                    handleSelectQuadrant={handleSelectQuadrant}
                    transformWrapperRef={transformWrapperRef}
                    displayFields={displayFields}
                    index0={index0}
                    delimiter={delimiter}
                    linkPatternFields={linkPatternFields}
                    sampleIDFields={sampleIDFields}
                    isPinningNewSwab={isPinningNewSwab}
                    togglePinningNewSwab={togglePinningNewSwab}
                    isPinningSwab={isPinningSwab}
                    clickQuadrant={clickQuadrant}
                    isClickingQuadrant={isClickingQuadrant}
                    searchResultSelected={searchResultSelected}
                    toggleReportView={toggleReportView}
                    showReportView={showReportView}
                    forceReportViewRerender={forceReportViewRerender}
                  />
                  )}
                  {loading && (
                  <div className="environment-analytics-list-view-content-loader">
                    <ListViewContentLoader amount={10} />
                  </div>
                  )}
                </div>
                {!loading && displayNoResultFound && (
                  <div className="AnalyticsListView__DisplayNoResultFound">
                    <img
                      alt="no result"
                      src={pumpkin}
                      className="AnalyticsListView__NoResultPumpkin"
                    />
                    <div className="AnalyticsListView__NoResultText">
                      No Result Found
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : ( // The case when EnvSearch is not shown (pinmap is empty, loading is false, displayNoResults is false)
            <div className="AnalyticsListView--emptyList">
              <div className="envAnalyticsUploadButton" onClick={this.handleAddFile}>
                <span>
                  Upload Swab Files
                </span>
                <img
                  alt="addFileIcon"
                  src={addFileIcon}
                  className="AnalyticsListView__AddFileIcon"
                />
                <input
                  id="docUploadInput"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  ref={this.docUploadInput}
                  type="file"
                  onChange={this.parseExcelFile}
                    // multiple
                  hidden
                />
              </div>
            </div>
          ))}
      </>
    );
  }
}

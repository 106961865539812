import React from "react";
import "./SpecTable.css";
import Scrollbar from "react-scrollbars-custom";
import { ReactComponent as RangeIcon } from "../../../../../assets/images/product/RangeIcon.svg";
import { ReactComponent as QualitativeIcon } from "../../../../../assets/images/product/QualitativeIcon.svg";
import { ReactComponent as QuantitativeIcon } from "../../../../../assets/images/product/QuantitativeIcon.svg";

class SpecTable extends React.Component {
  static getFormattedSpec(specs, testType) {
    let units = specs[2];
    let min = specs[0];
    let max = specs[1];

    if (units === undefined) units = "";
    if (min === undefined) min = "";
    if (max === undefined) max = "";

    /** Empty specs for this test */
    if (!units && !min && !max) {
      return "-";
    }

    if (testType === "quantitative") {
      return `${max} ${units}`;
    }

    if (testType === "range") {
      return `${min} - ${max} ${units}`;
    }

    if (testType === "qualitative") {
      let value = specs[1];
      if (value === undefined) {
        value = "";
      }
      return `${min} ${value} ${units}`;
    }

    return `${min} ${max} ${units}`;
  }

  static getFormattedTestType(testType) {
    const formattedTestType = testType.charAt(0).toUpperCase() + testType.substring(1, testType.length);

    if (testType === "quantitative") {
      return (
        <div className="SpecTable__TestType">
          <QuantitativeIcon />
          <span>{formattedTestType}</span>
        </div>
      );
    }

    if (testType === "qualitative") {
      return (
        <div className="SpecTable__TestType">
          <QualitativeIcon />
          <span>{formattedTestType}</span>
        </div>
      );
    }

    if (testType === "range") {
      return (
        <div className="SpecTable__TestType">
          <RangeIcon />
          <span>{formattedTestType}</span>
        </div>
      );
    }

    return <span>{testType || "-"}</span>;
  }

  renderTests() {
    const { dataOfSpecEditing } = this.props;

    return (
      dataOfSpecEditing.map((item, index) => (
        <tr key={index}>
          <td
            className="SpecTable__Header_Data"
          >
            {item.test}
          </td>
          <td
            className="SpecTable__Header_Data SpecTable__Header_Data--color"
          >
            {SpecTable.getFormattedTestType(item.test_type)}
          </td>
          <td
            className="SpecTable__Header_Data SpecTable__Header_Data--color"
          >
            {SpecTable.getFormattedSpec(item.specs, item.test_type)}
          </td>
        </tr>
      ))
    );
  }

  render() {
    return (
      <Scrollbar
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
        id="specManagerSpecTableScrollBar"
      >
        <table className="specTable">
          <thead>
            <tr>
              <th className="specManagerEditSpecTh1">Test</th>
              <th className="specManagerEditSpecTh2">Test Type</th>
              <th className="specManagerEditSpecTh3">Specs</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTests()}
          </tbody>
        </table>
      </Scrollbar>
    );
  }
}

export default SpecTable;

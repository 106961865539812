import React from "react";

import "../../styles/TitleBlockStyle.css";

export default function TitleBlock(props) {
  const { title, subtext } = props;
  return (
    <div>
      <div className="analyticsTitleBlockContainer">
        <div className="analyticsTitleBlockRectangle" />
        <p className="analyticsTitleBlockHeaderText">{title}</p>
      </div>

      <div className="analyticsTitleBlockSubTextContainer">
        <p className="analyticsTitleBlockSubText">{subtext}</p>
      </div>
    </div>
  );
}

import React from "react";
import Modal from "antd/es/modal/Modal";
import { every } from "lodash";
import { toast } from "react-toastify";
import { checkUser, getUserCredentials } from "../../../utils/common";
import { validateEmail, validatePhoneNumber } from "../../Common/utils/validationUtils";
import { ReactComponent as LoadingDots } from "../../../assets/images/common/LoadingDots.svg";
import "./AddMemberModal.css";
import AddMemberModalInput from "./AddMemberModalInput";

class AddMemberModal extends React.Component {
  inputRefs = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      role: "Member", // set default value for role to Member/Viewer
      disabled: {
        name: false,
        phone: false,
      },
      userCheckInProgress: true, // set to true by default so send invite button is disabled until userCheckInProgress has been called
      isSendingInvite: false,
      user: getUserCredentials(),
    };
    this.inputRefs = {
      name: React.createRef(),
      email: React.createRef(),
      phone: React.createRef(),
    };
  }

/**
   * Use email to check if user exists and is signed up already,
   * if yes, autofill phone and name
   * @param {String} emailVal user input for email
   */
handleUserCheck = async (emailVal) => {
  const {
    email, userCheckInProgress,
  } = this.state;
  if (emailVal !== email || userCheckInProgress) {
    const { user } = this.state;
    const company_domain = user.company_domain;
    const emailWithDomain = `${emailVal.trim()}@${company_domain}`.trim();
    const {
      success, user_name, phone_number,
    } = await checkUser(emailWithDomain);

    if (success) {
      this.setState({
        name: user_name,
        phone: phone_number,
        email: emailVal.trim(),
        disabled: { name: user_name.trim(), phone: phone_number.trim() },
      });
    } else {
      this.setState({
        name: "",
        phone: "",
        role: "Member",
        email: emailVal.trim(),
        disabled: { name: false, phone: false, role: false },
      });
    }
  }

  this.setState({ userCheckInProgress: false });
}

  onConfirm = () => {
    const {
      name: nameRef, phone: phoneRef, email: emailRef,
    } = this.inputRefs;
    const { role } = this.state;
    const name = nameRef.current.input.value.trim();
    const phone = phoneRef.current.input.value.trim();
    const { onConfirm } = this.props;
    const { user } = this.state;
    const company_domain = user.company_domain;
    const companyname = user.company_name;
    if (company_domain === undefined || !company_domain) {
      toast.error("Company domain is undefined");
      return;
    }
    if (companyname === undefined || !companyname) {
      toast.error("Company name is undefined");
      return;
    }
    if (!role) {
      toast.error("Please select a role.");
      return;
    }
    const email = `${emailRef.current.input.value.trim()}@${company_domain}`.trim();
    if (!name || !email) {
      toast.error("Please add name and email.");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email.");
      return;
    }
    if (phone && !validatePhoneNumber(phone)) {
      toast.error("Please enter a valid phone number.");
      return;
    }
    this.setState({ isSendingInvite: true });
    const params = {
      name,
      phone,
      email,
      role,
      // companyname,
      // webBaseURL,
    };
    onConfirm(params, (val) => this.setState({ isSendingInvite: val }));
  }

  render() {
    const {
      name, phone, email, role, isSendingInvite, disabled, userCheckInProgress, user,
    } = this.state;
    const { onClose } = this.props;
    // const webURL = window.location.origin;
    const requiredInputsFilledOut = !!email && !!role && !!name;
    return (
      <Modal
        open
        maskClosable={false}
        closable={false}
        centered
        footer={null}
        width="448px"
        wrapClassName="AddMemberModal"
        className="AddMemberModalContainer"
      >
        <div className={`AddMemberModal ${isSendingInvite ? "AddMemberModal--disabled" : ""}`}>
          <div
            className="addMemberModalHeader"
          >
            New Member
          </div>
          <AddMemberModalInput
            label={(
              <span>
                Email
                {" "}
                <sup>*</sup>
              </span>
              )}
            placeholder="Enter Email Username"
            field="email"
            value={email}
            onChange={(val) => { this.setState({ userCheckInProgress: true, email: val }); }}
            handleOnBlur={this.handleUserCheck}
            innerRef={this.inputRefs.email}
            disabled={false}
            suffix={`@${user.company_domain}`}
          />
          <AddMemberModalInput
            label={(
              <span>
                Role
                {" "}
                <sup>*</sup>
              </span>
              )}
            useDropdown
            placeholder="Select a role"
            field="role"
            value={role}
            onChange={(value) => { this.setState({ role: value }); }}
            disabled={disabled.role}
            dropdownOptions={[
              { value: "Member", label: "Member" },
              { value: "Editor", label: "Editor" },
            ]}
            tooltipTitle={(
              <span>
                <strong>Editor: </strong>
                CAN view/edit data, submit samples, edit specs, but CANNOT invite/delete members.
                <br />
                <strong>Member: </strong>
                CAN view data and submit samples, but CANNOT edit sample tests, edit specs and invite/delete members.
              </span>
            )}
          />
          <AddMemberModalInput
            label={(
              <span>
                Name
                {" "}
                <sup>*</sup>
              </span>
              )}
            placeholder="Enter Name"
            field="name"
            onChange={(val) => this.setState({ name: val })}
            value={name}
            innerRef={this.inputRefs.name}
            disabled={disabled.name}
          />
          <AddMemberModalInput
            label={(
              <span>
                Phone
              </span>
              )}
            placeholder="Enter Phone Number"
            value={phone}
            innerRef={this.inputRefs.phone}
            disabled={disabled.phone}
            onChange={(val) => this.setState({ phone: val })}
          />
          <div className={`AddMemberModal__Buttons${isSendingInvite ? "--loading" : ""}`}>
            <button
              type="button"
              onClick={() => onClose()}
              className={`AddMemberModal__CancelBtn ${isSendingInvite ? "AddMemberModal__CancelBtn--disabled" : ""}`}
              disabled={isSendingInvite}
            >
              Cancel
            </button>

            {isSendingInvite
              ? (
                <button
                  type="submit"
                  value="Invite"
                  className="addMemberSendInviteBtn addMemberSendInviteBtn--loading"
                  onClick={() => {}}
                >
                  Sending Invite
                  <LoadingDots width={16} className="addMemberSendInviteBtn-loading-dots" />
                </button>
              )
              : (
                <button
                  type="button"
                  onClick={() => this.onConfirm()}
                  className="addMemberSendInviteBtn"
                  disabled={userCheckInProgress || !every(requiredInputsFilledOut)}
                >
                  Send Invite
                </button>
              )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddMemberModal;

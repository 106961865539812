import React from "react";
// import "../../../Product.css";
import Scrollbar from "react-scrollbars-custom";
import SpecsRange from "./SpecsRange";
import SpecsQuantitative from "./SpecsQuantitative";
import SpecsQualitative from "./SpecsQualitative";
import { ReactComponent as RangeIcon } from "../../../../../assets/images/product/RangeIcon.svg";
import { ReactComponent as QualitativeIcon } from "../../../../../assets/images/product/QualitativeIcon.svg";
import { ReactComponent as QuantitativeIcon } from "../../../../../assets/images/product/QuantitativeIcon.svg";
import SpecManagerSelect from "./SpecManagerSelect";
import "./EditSpecTable.css";

class EditSpecTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newQualitative: false,
    };
  }

  handleDataChange = (indexOfRowClicked, fieldName, newFieldValue) => {
    const { dataOfSpecEditing, handleDataChange } = this.props;

    if (fieldName === "test_type") {
      if (dataOfSpecEditing[indexOfRowClicked].test_type !== newFieldValue) {
        dataOfSpecEditing[indexOfRowClicked].test_type = newFieldValue;
        dataOfSpecEditing[indexOfRowClicked].specs = [];

        if (newFieldValue === "qualitative") {
          this.setState({
            newQualitative: true,
          });
        }
      }
    } else if (fieldName === "specs") {
      dataOfSpecEditing[indexOfRowClicked][fieldName] = newFieldValue;
    } else {
      dataOfSpecEditing.push({ [fieldName]: newFieldValue, test_type: "", specs: ["", "", ""] });
    }

    handleDataChange(dataOfSpecEditing);
  };

  render() {
    const {
      dataOfSpecEditing,
    } = this.props;

    const { newQualitative } = this.state;
    return (
      <Scrollbar
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
        className="environmentReportEditSpecTableScrollBar"
        id="specManagerEditSpecTableScrollBar"
      >
        <table className="environmentSpecTable" id="environmentReportEditSpecTable">
          <thead>
            <tr>
              <th className="environmentSpecManagerEditSpecTh1">Test</th>
              <th>Test Type</th>
              <th>Specs</th>
            </tr>
          </thead>
          <tbody>

            {dataOfSpecEditing.map((item, i) => (
              <tr key={i}>
                <td className="environmentReportEditSpecTableTest">
                  {item.test}
                </td>
                <td className="environmentReportEditSpecTableTestType">
                  <SpecManagerSelect
                    width="152px"
                    optionSelected={item.test_type || undefined}
                    options={[{ value: "range", icon: <RangeIcon /> },
                      { value: "quantitative", icon: <QuantitativeIcon /> },
                      { value: "qualitative", icon: <QualitativeIcon /> }]}
                    placeholder="Choose Test Type"
                    onSelect={(optionSelected) => this.handleDataChange(i, "test_type", optionSelected)}
                  />
                </td>
                <td className="environmentReportEditSpecTableSpecs">
                  { item.test_type === "qualitative"
                    && (
                    <SpecsQualitative
                      data={item.specs.length === 0 ? ["", "", ""] : item.specs}
                      handleDataChange={(newSpec) => this.handleDataChange(i, "specs", newSpec)}
                      newQualitative={newQualitative}
                    />
                    )}
                  {
                    item.test_type === "range"
                      && (
                      <SpecsRange
                        data={item.specs.length === 0 ? ["", "", ""] : item.specs}
                        handleDataChange={(newSpec) => this.handleDataChange(i, "specs", newSpec)}
                      />
                      )
                  }
                  { item.test_type === "quantitative"
                    && (
                    <SpecsQuantitative
                      data={item.specs.length === 0 ? ["", "", ""] : item.specs}
                      handleDataChange={(newSpec) => this.handleDataChange(i, "specs", newSpec)}
                    />
                    )}

                  {(item.test_type !== "quantitative" && item.test_type !== "qualitative"
                    && item.test_type !== "range")
                    && (
                    <span className="environmentReportEditSpecTableSpecsDefaultValue">
                      Please select test type to view the specs
                    </span>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Scrollbar>
    );
  }
}

export default EditSpecTable;

import React, {
  useEffect, useState, useRef, useContext, useMemo,
} from "react";

import {
  debounce, difference, has, isEmpty, isEqual,
} from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";

// import SubmissionInfoBanner from "./SampleDetailsPage/SubmissionInfoBanner";
import { AppContext } from "../../../AppContext";
import useAbortController from "../../Common/hooks/useAbortController";
import SuccessAnimation from "../../Common/UIComponents/SuccessAnimation";
import SampleFunctionContext from "../SampleFunctionContext";
import SampleSubmissionContext from "../SampleSubmissionContext";
import SampleSubmissionConfirmModal from "../SampleSubmissionModal/SampleSubmissionConfirmModal";
import SubmissionReceiptModal from "../SampleSubmissionModal/SubmissionReceiptModal/SubmissionReceiptModal";
import { CHAR_LIMITS } from "./Constant";
import SampleDetailsPage from "./SampleDetailsPage/SampleDetailsPage";
import SubmissionDetailsAddressPage from "./SubmissionDetailsAddressPage/SubmissionDetailsAddressPage";

import { s3EsvPriv } from "../../../utils/aws";
import { hasFeature } from "../../../utils/common";
import { uploadFileToS3, deleteFileFromS3, getFileNameAndExtensionFromPath } from "../../../utils/helpers";
import { removeReplicateTag } from "./helpers";

import "./SubmissionForm.css";

import {
  addSampleToDraft,
  editSampleInDraft,
  deleteSampleFromDraft,
  addSamplesToSubmission,
  editSampleInSubmission,
  submitForm,
  getSampleID,
  getTestList,
} from "../../../actions/sampleSubmission";

export default function SubmissionForm(props) {
  const {
    setShowSubmissionForm,
    setSampleFieldLoaded,
    draftEditing,
    setDraftEditing,
    submissionEditingSample,
    setSubmissionEditingSample,
    submissionAddingSamples,
    setSubmissionAddingSamples,
    sampleFields,
    sampleFieldsInfo,
    delimiterRegex,
    linkedFields,
    sampleIdFields,
    setAddressPage,
    setSubmissionNameNav,
    setShowExitModal,
  } = props;
  const { isLoggedOut, sampleCategory, thirdParty } = useContext(SampleSubmissionContext);
  const { closeRef, setOnAddBackButton, backRef } = useContext(SampleFunctionContext);
  const [showAddressPage, setShowAddressPage] = useState(!submissionEditingSample && !submissionAddingSamples); // don't show address page when editing/adding samples in a submission
  /** 0: don't show any modals, 1: show unsaved changes submission modal, 2: show regular submission modal, 3: show delete sample modal */
  const [showConfirmationModal, setShowConfirmationModal] = useState(0);
  const [editingSubmittedSampleComposite, setEditingSubmittedSampleComposite] = useState(true);
  const [testInfoMap, setTestInfoMap] = useState(new Map());
  /* After user adds a new sample, saves an edited sample, cancels editing, switches the editing sample to another,
  * "clearTestSearch" will be set from false to true. We will reset the search input to empty and get all test suggestions. */
  const [clearTestSearch, setClearTestSearch] = useState(false);
  const [testsSelected, setTestsSelected] = useState({});
  const [inputValuesSavedForNext, setInputValuesSavedForNext] = useState({});
  const [saveTestsForNext, setSaveTestsForNext] = useState(false);
  const inputValuesRefs = useRef({});
  const imageInfoRef = useRef({ originalUrl: "", editedUrl: "", file: null });
  const [clearSampleDetailsForm, setClearSampleDetailsForm] = useState(false);
  const [loadSampleEditing, setLoadSampleEditing] = useState(false);
  const [compositeSample, setCompositeSample] = useState(null);
  const [loadSampleDeleting, setLoadSampleDeleting] = useState(false);
  const [loadDraftState, setLoadDraftState] = useState(false);
  const [loadSavedState, setLoadSavedState] = useState(false);
  const [isLoadingSampleId, setIsLoadingSampleId] = useState(false);
  const [submissionDetails, setSubmissionDetails] = useState({
    submissionName: "", submissionID: "", poNumber: "",
  });
  const [customerDetails, setCustomerDetails] = useState({ customerID: "", submittedBy: "", userEmail: "" });
  const [sampleList, setSampleList] = useState([]);
  const [savedState, setSavedState] = useState(null);
  // const [sampleFieldsState, setSampleFieldsState] = useState(null);
  const [sampleEditing, setSampleEditing] = useState({});
  const [sampleEditingIdx, setSampleEditingIdx] = useState(-1);
  const [sampleToDeleteIdx, setSampleToDeleteIdx] = useState(-1);
  const [waitingForAddEditAPI, setWaitingForAddEditAPI] = useState(false); // loading state for add/edit sample
  const [loadingImage, setLoadingImage] = useState(false);
  const [submitLoadingState, setSubmitLoadingState] = useState(false);
  const [draftLastSavedTime, setDraftLastSavedTime] = useState("");
  const [invalidInputFound, setInvalidInputFound] = useState(false);
  const [invalidTest, setInvalidTest] = useState(false);
  const [generatedSampleID, setGeneratedSampleID] = useState("");
  const [sampleIDGenerationError, setSampleIDGenerationError] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [pesticideSample, setPesticideSample] = useState("");
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const submissionForReceiptModal = useRef();
  const sampleFromSubmission = useRef();
  const submissionEditingSamplePayload = useRef();
  const sampleListScrollbar = useRef();
  const commentRef = useRef();
  const { setNewAbortController, clearAbortController } = useAbortController(); // for displaysampleid api
  const { userTitle } = useContext(AppContext);

  /** componentDidMount - get list of all tests, convert to map */
  useEffect(() => {
    const apiGetTestList = async () => {
      const { success, testList } = await getTestList("", (isLoggedOut && !thirdParty) ? customerDetails.customerID : undefined, sampleCategory);
      if (success) {
        const test_map = new Map();
        testList.forEach((testObj) => {
          test_map.set(testObj.name, testObj);
        });
        setTestInfoMap(test_map);
      }
    };
    if ((!isLoggedOut || thirdParty) || customerDetails.customerID) {
      apiGetTestList();
    }
  }, [customerDetails]); // eslint-disable-line

  /** when user is editing a draft, set submission details, sample list */
  useEffect(() => {
    if (draftEditing) {
      setLoadDraftState(true);
      const {
        submission_name, po, submission_id,
        submission_date, samples, test_list,
      } = draftEditing;
      setSubmissionDetails({
        submissionName: submission_name, poNumber: po, submissionID: submission_id,
      });
      setSubmissionNameNav(submission_name);
      setDraftLastSavedTime(submission_date);
      setSampleList(generateUUIDs(samples)); // eslint-disable-line
      const disableTestListMod = (!isLoggedOut || thirdParty) && userTitle !== "Admin" && userTitle !== "Editor";
      setSaveTestsForNext(!disableTestListMod && (Boolean(test_list?.length) ?? false)); // true if test_list exists and length is greater than 0 and user can modify test list
    }
  }, [draftEditing]); // eslint-disable-line react-hooks/exhaustive-deps

  /** when user is editing a sample in a submission, set submission details, sample list */
  useEffect(() => {
    if (submissionEditingSample) {
      const {
        submit_name,
        submit_id,
        sample,
        po,
      } = submissionEditingSample;
      setSubmissionNameNav(submit_name);
      setSubmissionDetails({
        submissionName: submit_name, poNumber: po, submissionID: submit_id,
      });
      const formattedSample = { sample_id: sample.sample_id };
      formattedSample.test_list = sample.tests_list.map((testObj) => testObj.Test);
      const emptyState = {
        fields: {}, savedForNext: { ...inputValuesSavedForNext }, tests: { ...testsSelected }, saveTests: false,
      };
      sampleFields.forEach((field) => {
        formattedSample[field] = sample[field] ?? "";
        if (sampleFieldsInfo[field].display === "1") { // fields with display 1 are default selected
          emptyState.fields[field] = "";
        }
      });
      if (sampleCategory === "environment") {
        formattedSample.image_path = sample.image_path;
        emptyState.imageInfoRef = { originalUrl: "", editedUrl: "", file: null };
      }
      sampleFromSubmission.current = formattedSample;
      setSampleList(generateUUIDs([formattedSample])); // eslint-disable-line
      setSavedState(emptyState);
      setLoadSampleEditing(true);
      setSampleEditingIdx(0);
      setSampleEditing(formattedSample);
    }
  }, [submissionEditingSample]); // eslint-disable-line
  /**
   * Update the back button or the back button.
   */
  useEffect(() => {
    if (sampleEditingIdx > -1) {
      setShowExitModal(true);
    } else {
      setShowExitModal(false);
    }
  }, [sampleEditingIdx]); // eslint-disable-line

  /** when user is adding samples to a submission, set submission details */
  useEffect(() => {
    if (submissionAddingSamples) {
      const {
        submit_name,
        submit_id,
        po,
        comment,
      } = submissionAddingSamples;
      setSubmissionNameNav(submit_name);
      setSubmissionDetails({
        submissionName: submit_name, poNumber: po, submissionID: submit_id, comment,
      });
    }
  }, [submissionAddingSamples]); // eslint-disable-line

  /** when loadSavedState is true, and form is mounted, overwrite state with savedState */
  useEffect(() => {
    if (loadSavedState && !showAddressPage && savedState !== null) {
      const {
        savedForNext, tests, saveTests,
      } = savedState;
      setInputValuesSavedForNext(savedForNext);
      setTestsSelected(tests);
      setSaveTestsForNext(saveTests);
    }
  }, [loadSavedState, showAddressPage, savedState]);

  /** Manage the nav for the submission form on address or details page */
  useEffect(() => {
    setAddressPage(showAddressPage);
  }, [showAddressPage]); // eslint-disable-line

  /** Manage the animation for the time */
  useEffect(() => {
    if (showSuccessAnimation) {
      setTimeout(() => {
        setShowSuccessAnimation(false);
        setShowReceiptModal(true);
      }, 1000);
    }
  }, [showSuccessAnimation]); // eslint-disable-line

  /**
   * Set the field's ref
   * @param  {String} field json_field
   * @param  {input DOM element} ref ref for the field's input
   */
  const setInputValuesRefs = (field, ref) => {
    if (inputValuesRefs.current[field] === undefined) {
      inputValuesRefs.current[field] = {};
    }
    inputValuesRefs.current[field].ref = ref;
  };

  /**
   * Set image related info
   * @param  {Object} info { originalUrl, editedUrl, file, crop, rotate }
   */
  const setImageInfoRef = (info) => {
    imageInfoRef.current = { ...info };
  };

  /**
   * Set whether the field's value was autofilled or not
   * @param  {String} field json_field
   * @param  {String} fieldVal value of json_field
   * @param  {Boolean} autofilled if the input was autofilled or not
   * @param  {Boolean} emptyStringAutofilledValue whether empty strings are set to autofilled true or false. empty strings are default set to true so these fields can be autofilled regardless of autofilled value.
   */
  const setInputValueAutofilled = (field, fieldVal, autofilled, emptyStringAutofilledValue = true) => {
    if (inputValuesRefs.current[field] === undefined) {
      inputValuesRefs.current[field] = {};
    }
    inputValuesRefs.current[field].autofilled = fieldVal === "" ? emptyStringAutofilledValue : autofilled;
  };

  /**
   * When the user checks/unchecks save for next, update the saved for next state
   * @param  {String} field json_field
   * @param  {Boolean} overrideValue set value to overrideValue directly
   */
  const toggleSaveForNext = (field, overrideValue = null) => {
    if (overrideValue !== null) {
      inputValuesSavedForNext[field] = overrideValue; // set to the overrideValue
    } else { inputValuesSavedForNext[field] = !inputValuesSavedForNext[field]; }
    setInputValuesSavedForNext({ ...inputValuesSavedForNext });
  };

  /**
   * When the user checks/unchecks save for next, update the saved for next state
   * @param  {String} field json_field
   * @param  {Boolean} overrideValue set value to overrideValue directly
   */
  const clearCompositeSaveForNext = (fields) => {
    if (fields.length) {
      fields.forEach((field) => {
        inputValuesSavedForNext[field] = false;
      });
      setInputValuesSavedForNext({ ...inputValuesSavedForNext });
    }
  };

  /**
   * Update the specified sample
   * @param  {Number} idx index of sample in sampleList
   * @param  {Object} updatedSample updated sample object
   */
  const updateSample = (idx, updatedSample) => {
    sampleList[idx] = updatedSample;
    setSampleList([...sampleList]);
  };

  /**
   * When loading a draft, generate new uuids for each sample for animation purposes
   * @param  {Array} draftSampleList array of samples
   * @return  {Array} array of samples with uuids
   */
  const generateUUIDs = (draftSampleList) => draftSampleList.map((sample) => (
    {
      ...sample,
      uuid: uuid(),
    }));

  /**
   * Call the api to generate the sample id
   * @param {Object} inputValues {json_field: value, ...}
   */
  const generateSampleID = async (inputValues, editingSampleID, sample_list) => {
    setIsLoadingSampleId(true);
    if (!isLoggedOut || thirdParty) {
      const { submissionID } = submissionDetails;
      const sample = { po: submissionDetails.poNumber };
      if (submissionAddingSamples) {
        sample.po = submissionAddingSamples.po;
      } else if (submissionEditingSample) {
        sample.po = submissionEditingSample.po;
      }

      for (let i = 0; i < sampleFields.length; i++) {
        const json_field = sampleFields[i];
        if (inputValues !== undefined) {
          sample[json_field] = removeReplicateTag(inputValues[json_field].trim() ?? "");
        } else {
          const ref = inputValuesRefs.current[json_field]?.ref;
          if (ref !== undefined && ref !== null) {
            const val = ref.value.trim();
            sample[json_field] = val;
          } else {
            sample[json_field] = "";
          }
        }
      }

      const payload = { submission_id: submissionID, sample };
      if (sampleCategory !== "") {
        payload.sample_category = sampleCategory;
      } else if (submissionEditingSample) {
        payload.sample_category = submissionEditingSample.sample_category;
      }
      if (editingSampleID !== undefined) {
        payload.editing_sample_id = editingSampleID;
      }
      if (submissionAddingSamples) {
        if (sample_list) {
          payload.sample_id_list = sample_list.map(({ sample_id }) => sample_id);
        } else {
          payload.sample_id_list = sampleList.map(({ sample_id }) => sample_id);
        }
      }
      const newAbortController = setNewAbortController();
      const { success, sample_id } = await getSampleID(payload, newAbortController.signal).finally(() => setIsLoadingSampleId(false));

      if (newAbortController.signal.aborted) {
        return { canceled: true };
      }

      clearAbortController();

      if (success) {
        setGeneratedSampleID(sample_id);
        setSampleIDGenerationError(false);
        return { success: true, sample_id };
      }
      setGeneratedSampleID("");
      setSampleIDGenerationError(true);
      return { success: false };
    }
    return { success: false };
  };

  /**
   * Debounce generateSampleID, will wait until 500ms have elapsed to call again.
   * useMemo keeps the debounced function instance the same between rerenders (more performant than useCallback),
   * the function will only reinitialize when a dependency array value updates (needed if function uses state/prop that changes i.e. sampleList)
   * @return {Function} debounced generateSampleID
   */
  const debouncedGenerateSampleID = useMemo(() => debounce((inputVals, sampleEditingID) => {
    generateSampleID(inputVals, sampleEditingID);
  }, 500), [sampleList, submissionDetails, sampleFields]); // eslint-disable-line
  /**
   * Cleanup the debounce function on unmount
   */
  useEffect(() => () => {
    debouncedGenerateSampleID.cancel();
  }, []); // eslint-disable-line

  /**
   * Upload the image to the target s3 bucket
   * @param {*} file the current image uploaded from local
   * @returns the aws api response and the file path
   */
  const addImageToAWS = async (file) => {
    if (file) {
      // console.log("file size", `${sizeInMB.toFixed(2)}MB`);
      // const url = window.URL.createObjectURL(new Blob([file], { type: file.type }));
      // if (url) {
      //   const a = document.createElement("a");
      //   a.href = url;
      //   a.download = file.name;
      //   a.click();
      // }
      const { name, ext } = getFileNameAndExtensionFromPath(file.name);
      const fileBlob = await uploadFileToS3({
        file, folderPath: "SampleSubmission/", fileName: `${name}_${Date.now()}${ext}`, type: file.type,
      }, s3EsvPriv);
      if (fileBlob) {
        return { success: true, path: fileBlob.path };
      }
    } return { success: false };
  };

  /**
   * Api POST call to add sample. Gets submission_id on first add.
   * @param {Object} sample the sample being added
   * @param {Object} savedFields {json_field : value} for fields that are saved for next
   * @return  {Boolean} true if successfully added, false otherwise (show toast message)
   * @return  {String} return the generated sampleID
   */
  const apiAddSampleDraft = async (sample, savedFields) => {
    const {
      submissionName, poNumber, submissionID,
    } = submissionDetails;
    const payload = {
      submissionName,
      poNumber,
      submissionID,
      sample,
      savedFields,
      testList: saveTestsForNext ? Array.from(Object.keys(testsSelected)) : [],
      sample_category: sampleCategory,
    };
    const {
      success, message, submissionID: submission_id, sampleID,
    } = await addSampleToDraft(payload);
    if (success) {
      setSubmissionDetails({ ...submissionDetails, submissionID: submission_id });
    } else {
      toast.error(message);
    }
    return { success, sampleID };
  };

  /**
   * Api PATCH call to edit sample. Gets new sample_id for sample.
   * @param oldSampleID the old sample id of the edited sample
   * @param editedSample the edited sample
   * @param {Object} savedFields {json_field : value} for fields that are saved for next
   * @return  {Boolean} true if successfully edited, false otherwise (show toast message)
   * @return  {String} return the generated sampleID
   */
  const apiEditSampleDraft = async (oldSampleID, editedSample, savedFields) => {
    const {
      submissionName, poNumber, submissionID,
    } = submissionDetails;
    const payload = {
      submissionName,
      poNumber,
      submissionID,
      oldSampleID,
      editedSample,
      savedFields,
      testList: saveTestsForNext ? Array.from(Object.keys(testsSelected)) : [],
      sampleCategory,
    };
    const {
      success, message, sampleID,
    } = await editSampleInDraft(payload);
    if (!success) {
      toast.error(message);
    }
    return { success, sampleID };
  };

  /**
   * Api DELETE call to delete sample
   * @param sampleID the sample id of the sample to be deleted
   * @param sampleType the sample type of the sample to be deleted
   * @return  {Boolean} true if successfully deleted, false otherwise (show toast message)
   */
  const apiDeleteSampleDraft = async (sampleID, sampleType) => {
    const {
      submissionName, poNumber, submissionID,
    } = submissionDetails;
    const payload = {
      submissionName,
      poNumber,
      submissionID,
      sampleID,
      sampleType,
      sampleCategory,
    };
    const {
      success, message,
    } = await deleteSampleFromDraft(payload);
    if (!success) {
      toast.error(message);
    }
    return success;
  };

  /**
   * Api POST call to submit form.
   * @return  {Boolean} true if successfully submitted, false otherwise (show toast message)
   */
  const apiSubmitForm = async () => {
    const {
      submissionName, poNumber, submissionID,
    } = submissionDetails;
    const payload = {
      submissionName,
      poNumber,
      submissionID,
      sampleList,
      comment: commentRef.current.resizableTextArea.textArea.value.trim() ?? "",
      sampleCategory,
    };
    const { success, message } = await submitForm(payload, (isLoggedOut && !thirdParty) ? customerDetails : undefined);
    if (success) {
      toast.success("Successfully submitted form");
    } else {
      toast.error(message);
    }
    return success;
  };

  /**
   * Api POST call to add samples to a submission.
   * @param {Object} sample the samples being added
   * @return  {Boolean} true if successfully added, false otherwise (show toast message)
   */
  const apiSubmitAddedSamples = async () => {
    const { submissionID, poNumber } = submissionDetails;
    const { status } = submissionAddingSamples;
    const payload = {
      submissionID,
      sampleList,
      status,
      poNumber,
      sampleCategory,
    };
    const { success, message } = await addSamplesToSubmission(payload);
    if (success) {
      toast.success(`Successfully added sample${sampleList.length > 1 ? "s" : ""}`);
    } else {
      toast.error(message);
    }
    return success;
  };

  /**
   * Api PATCH call to edit a sample in a submission.
   * @param {Object} oldSample the original sample
   * @param {Object} newSample the edited sample
   * @return  {Boolean} true if successfully edited, false otherwise
   */
  const apiSubmitEditedSample = async () => {
    const payload = { ...submissionEditingSamplePayload.current };
    payload.sample_category = sampleCategory;
    delete payload.oldSample; // remove oldSample key, this was needed only for the edits modal
    payload.sample = payload.newSample;
    delete payload.newSample; // remove newSample key (replaced by sample key)
    // paload.comment = commentRef.current.resizableTextArea.textArea.value.trim();
    if (payload) {
      const newSample = sampleList[0];
      const { image_file } = newSample;
      const { image_path: old_image_path } = sampleFromSubmission.current;
      let deleteImagePath;
      /** Upload image to aws and delete old image if it exists */
      if (has(payload, "new_image_src")) {
        if (payload.new_image_src && image_file) { // a new image or crop was created
          if (old_image_path) {
            deleteImagePath = old_image_path;
          }
          const { success, path } = await addImageToAWS(image_file);
          if (!success) {
            toast.error("Image failed to upload");
            return false;
          }
          payload.image_path = path ?? "";
          payload.sample.image_path = path ?? "";
        } else if (!payload.new_image_src) { // the image was removed from the sample
          if (old_image_path) {
            deleteImagePath = old_image_path;
          }
          payload.image_path = "";
          payload.sample.image_path = "";
        }
        delete payload.image_src;
        delete payload.new_image_src;
      }
      /** If image upload successful, call edit submission api */
      const { success, message } = await editSampleInSubmission(payload);
      if (success) {
        toast.success("Successfully edited sample");
        if (deleteImagePath) {
          deleteFileFromS3(deleteImagePath, s3EsvPriv);
        }
      } else {
        toast.error(message);
      }
      return success;
    }
    toast.error("Unable to submit edited sample");
    return false;
  };

  /**
   * Resets state back to default (used when logged out, after submitting)
   */
  const resetSubmissionFormState = () => {
    /** Submission state */
    setCustomerDetails({ customerID: "", submittedBy: "", userEmail: "" });
    setSubmissionDetails({
      submissionName: "", submissionID: "", poNumber: "",
    });
    setDraftLastSavedTime("");
    submissionForReceiptModal.current = null;
    /** Sample Details Inputs state */
    inputValuesRefs.current = {};
    setInputValuesSavedForNext({});
    setImageInfoRef({ originalUrl: "", editedUrl: "", file: "" });
    setClearSampleDetailsForm(false);
    setLoadSavedState(false);
    setSavedState(null);
    setInvalidInputFound(false);
    setGeneratedSampleID("");
    /** Samples state */
    setLoadSampleEditing(false);
    setSampleEditing({});
    setSampleEditingIdx(-1);
    setSampleToDeleteIdx(-1);
    setSampleList([]);
    /** Tests state */
    setTestsSelected({});
    setSaveTestsForNext(false);

    /** Show address page */
    setShowAddressPage(true);
  };

  /**
   * Saves fields, save-for-next values, tests, and tests save-for-next state
   * @param {Object} savedForNext current inputsSavedForNext value
   * @param {Set} tests current selectedTests value
   * @param {Boolean} saveTests current saveTestsForNext value
   */
  const saveCurrentState = (savedForNext, tests, saveTests) => {
    const currState = {
      fields: {}, savedForNext: { ...savedForNext }, tests: { ...tests }, saveTests,
    };
    if (sampleCategory === "environment") {
      currState.imageInfoRef = { ...imageInfoRef.current };
      if (imageInfoRef.current.crop) {
        currState.imageInfoRef.crop = { ...imageInfoRef.current.crop };
      }
    }
    for (let i = 0; i < sampleFields.length; i++) {
      const json_field = sampleFields[i];
      const ref = inputValuesRefs.current[json_field]?.ref;
      const autofilled = inputValuesRefs.current[json_field]?.autofilled;
      if (ref !== undefined && ref !== null) {
        const val = ref.value.trim();
        currState.fields[json_field] = { value: val, autofilled };
      }
    }
    setSavedState(currState);
  };

  /**
   * When user selects clicks back icon from the form page, save current state and show address page.
   */
  const handleGoBackToAddressPage = () => {
    if (sampleEditingIdx === -1) {
      saveCurrentState(inputValuesSavedForNext, testsSelected, saveTestsForNext);
      setLoadSavedState(true);
    }
    setDraftEditing(null);
    setShowConfirmationModal(0);
    setShowAddressPage(true); // go to address form page
  };

  /**
   * When user clicks back icon from the address page, save draft and go back to the main page.
   */
  const handleGoBackToMainPage = async () => {
    if (!isLoggedOut) {
      setShowSubmissionForm(false); // go back to main page
      setSampleFieldLoaded(false);
      if (draftEditing) {
        setDraftEditing(null);
      }
      if (submissionEditingSample) {
        setSubmissionEditingSample(null);
      }
      if (submissionAddingSamples) {
        setSubmissionAddingSamples(null);
      }
    }
    setOnAddBackButton(false);
  };

  /**
   * When user selects Submit on the modal, call submit api, show success message if successful
   * and go back to the main page. If it failed, show error message and stay on the page.
   */
  const handleConfirmSubmit = async () => {
    setSubmitLoadingState(true);
    let success;
    if (submissionEditingSample) {
      success = await apiSubmitEditedSample();
    } else if (submissionAddingSamples) {
      success = await apiSubmitAddedSamples();
    } else {
      success = await apiSubmitForm();
    }
    const comment = commentRef?.current?.resizableTextArea.textArea.value.trim() ?? "";
    setSubmitLoadingState(false);
    if (success) {
      setShowConfirmationModal(0);
      if (isLoggedOut && !thirdParty) {
        submissionForReceiptModal.current = {
          samples_list: sampleList.map((sample) => ({ ...sample, tests_list: sample.test_list })),
          num_samples: sampleList.length,
          submit_date: moment(Date.now()).format(),
          submit_name: submissionDetails.submissionName,
          po: submissionDetails.poNumber,
          comment,
        };
      }
      setShowSuccessAnimation(true);
      // setShowReceiptModal(true);
    }
  };

  /**
   * Handle when submission is successful and user wants to close the receipt modal
   */
  const handleSubmissionDone = () => {
    setShowReceiptModal(false);
    if (!isLoggedOut) {
      setShowSubmissionForm(false);
      setSampleFieldLoaded(false);
      if (draftEditing) {
        setDraftEditing(null);
      }
      if (submissionEditingSample) {
        setSubmissionEditingSample(null);
      }
      if (submissionAddingSamples) {
        setSubmissionAddingSamples(null);
      }
    } else {
      resetSubmissionFormState();
    }
  };

  /**
   * Check the differences between old and edited sample,
   * if there are differences, return payload else null.
   * @return {Object | null} return payload if there are changes, else null
   */
  const generateSubmissionEditingSamplePayload = () => {
    const {
      status,
      sample,
    } = submissionEditingSample;
    const oldSample = sampleFromSubmission.current;
    const { submissionID } = submissionDetails;
    const newSample = sampleList[0];
    oldSample.uuid = newSample.uuid;
    const newSampleCopy = { ...newSample };
    oldSample.num_composite_samples = sample.num_composite_samples ?? 0;
    oldSample.composite_fieldnames = sample.composite_fieldnames ?? [];

    if (sampleCategory === "environment") {
      delete newSampleCopy.image_file; // oldSample does not have this key, remove it for comparison
      newSampleCopy.image_path = oldSample.image_path; // newSample does not have image_path, add for comparison
    }
    if (isEqual(oldSample, newSampleCopy)) {
      return null;
    }

    const payload = {
      submission_id: submissionID,
      submission_status: status,
      sample_id: oldSample.sample_id,
      sample_type: oldSample.sample_type ?? sample.sample_type ?? "", // if sample_type is not part of fields, get the sample_type from the unmodified sample object from backend
      oldSample,
      newSample: newSampleCopy,
      test_list: newSample.test_list,
    };

    /** If Sample ID changed, add new one to payload */
    if (oldSample.sample_id !== newSample.sample_id) {
      payload.new_sample_id = newSample.sample_id;
    }
    /** If Sample Type changed, add new one to payload */
    if ((oldSample.sample_type ?? "") !== (newSample.sample_type ?? "")) {
      payload.new_sample_type = newSample.sample_type ?? "";
    }
    /** Figure out which tests were deleted/added */
    if (!isEqual(oldSample.test_list, newSample.test_list)) {
      const deletedTests = difference(oldSample.test_list, newSample.test_list);
      const addedTests = difference(newSample.test_list, oldSample.test_list);
      if (deletedTests.length > 0) {
        payload.deleted_tests = deletedTests;
      }
      if (addedTests.length > 0) {
        payload.added_tests = addedTests;
      }
    }

    /** If environment, check if image was edited */
    if (sampleCategory === "environment" && (oldSample.image_src ?? "") !== (newSample.image_src ?? "")) {
      payload.image_src = oldSample.image_src;
      payload.new_image_src = newSample.image_src;
    }

    submissionEditingSamplePayload.current = payload;
    return payload;
  };

  /**
   * When user clicks submit button and no samples are added, show toast message.
   * When user clicks submit button and there are unsaved changes, show confirm modal.
   * When user clicks submit button with no unsaved changes, proceed with handleSubmit.
   */
  const handleSubmitButtonClick = () => {
    if (sampleList.length === 0) {
      toast.error("No samples added");
    } else if (submissionEditingSample) {
      const payload = generateSubmissionEditingSamplePayload();
      if (payload) {
        setShowConfirmationModal(2);
        setEditingSubmittedSampleComposite(false);
      } else {
        toast.error("No edits made to sample");
      }
    } else {
      setShowConfirmationModal(2);
    }
  };

  // Check pesticides tests in sample list
  const checkPesticidesTests = () => {
    const containsPesticide = Object.keys(testsSelected).findIndex((test) => test.toLowerCase().includes("pesticide")) !== -1;
    return containsPesticide && sampleCategory !== "environment" && hasFeature("special_pesticides");
  };
  /**
   * Add sample. Loop through sampleFields, grab the ref.
   * If ref exists and has a non-empty input, add field info and value to sample obj.
   * Set the updated list, clear form (keeping the values that are saved for next).
   * Make the api call to add sample.
   */
  const handleAddSample = async () => {
    setWaitingForAddEditAPI(true);
    let atLeastOneSampleIdField = false;
    /** Always add the po in the sample's level */
    const newSample = { po: submissionDetails.poNumber };
    const savedFields = {};
    /** Get field values and composite values from refs */
    const compositeInputValues = backRef?.current?.getInputValues();
    const compositeValues = backRef?.current?.getCompositeData();
    const isMrl = checkPesticidesTests();
    for (let i = 0; i < sampleFields.length; i++) {
      /** InputValues and compositeData in case of composite samples */
      const json_field = sampleFields[i];
      const ref = inputValuesRefs.current[json_field]?.ref?.value;
      const compositeValueRef = compositeInputValues[json_field] ?? "";
      let refValue;

      // Determine refValue based on conditions
      if (isMrl && json_field === "sample_type") {
        refValue = ref; // Use ref if isMrl is true and json_field is "sample_type"
      } else if (compositeValues?.unique) {
        refValue = compositeValueRef; // Use compositeValueRef if compositeValues is unique
      } else {
        refValue = ref; // Default to ref
      } if (refValue !== undefined && refValue !== null) {
        const val = refValue.trim();
        if (val) {
          if (sampleIdFields.has(json_field)) {
            atLeastOneSampleIdField = true;
          }
          if (inputValuesSavedForNext[json_field]) {
            savedFields[json_field] = val;
          }
        }
        newSample[json_field] = val;
      } else {
        newSample[json_field] = "";
      }
    }

    /** Check if at least one sample id field is filled out */
    if (!atLeastOneSampleIdField) {
      toast.error((isLoggedOut && !thirdParty) ? "Sample ID is required" : "At least one Sample ID field is required");
      setWaitingForAddEditAPI(false);
      return;
    }

    /** Because submission editing apis don't regenerate sample id from fields, call generateSampleID to get the most updated sample id before proceeding */
    if (submissionAddingSamples) {
      const { success, sample_id } = await generateSampleID(newSample);
      if (success) {
        if (sample_id.length > CHAR_LIMITS.sample_id) {
          toast.error("Sample ID is too long");
          setWaitingForAddEditAPI(false);
          return;
        }
        newSample.sample_id = sample_id;
      } else {
        toast.error("Unable to generate Sample ID");
        setWaitingForAddEditAPI(false);
        return;
      }
    } else if (!isLoggedOut || thirdParty) {
      newSample.sample_id = generatedSampleID;
    }

    /** Check if at least one test selected */
    if (!isEmpty(testsSelected)) {
      newSample.test_list = Array.from(Object.keys(testsSelected));
    } else {
      toast.error("No tests selected for this sample");
      setWaitingForAddEditAPI(false);
      return;
    }

    /** If this is logged in env sample submission, handle image upload */
    if (sampleCategory === "environment" && (!isLoggedOut || thirdParty)) {
      const { file, editedUrl } = imageInfoRef.current;
      if (file && editedUrl) { // a new image or crop was created
        const { success, path } = await addImageToAWS(file);
        if (!success) {
          toast.error("Image failed to upload");
          setWaitingForAddEditAPI(false);
          return;
        }
        newSample.image_path = path ?? "";
        newSample.image_file = file ?? null;
      } else if (!editedUrl) { // the image was removed from the sample
        newSample.image_path = "";
      }
      if (inputValuesSavedForNext.img) {
        savedFields.image_path = newSample.image_path ?? "";
      }
    }
    /** If sample is composite sample */
    newSample.num_composite_samples = compositeValues?.sample_count ?? 0;
    newSample.composite_fieldnames = compositeValues?.identifier ?? [];
    /** If this is a draft, get the draft api response otherwise use default */
    let response = { success: true, sampleID: newSample.sample_id };
    if ((!isLoggedOut || thirdParty) && !submissionAddingSamples && !isLoadingSampleId) {
      response = await apiAddSampleDraft(newSample, savedFields);
    }

    /** Update the sample list with the new sample, clear relevant state */
    const { success, sampleID } = response;
    if (success) {
      newSample.sample_id = sampleID;
      if (sampleCategory === "environment" && (!isLoggedOut || thirdParty)) {
        newSample.image_path = newSample.image_path ?? "";
        newSample.image_file = newSample.image_file ?? "";
        newSample.image_src = imageInfoRef.current.editedUrl ?? "";
      }
      newSample.uuid = uuid();
      sampleList.push(newSample);
      setSampleList([...sampleList]);
      setSavedState(null);
      setLoadSavedState(false);
      setClearSampleDetailsForm(true);
      setPesticideSample("");
      /* scroll sample list to bottom */
      if (sampleListScrollbar.current) {
        sampleListScrollbar.current.scrollerElement.scrollTo({
          top: sampleListScrollbar.current.scrollerElement.scrollHeight,
          behavior: "smooth",
        });
      }
      if ((!isLoggedOut || thirdParty) && !submissionAddingSamples) {
        setDraftLastSavedTime(moment().format());
      }
      setClearTestSearch(true);
    }
    setWaitingForAddEditAPI(false);
  };

  /**
   * Edit sample. Same flow as add sample, except
   * sample at that index is updated instead of pushing
   * new sample to sample list.
   */
  const handleSaveEditedSample = async (submitEdit = false) => {
    setWaitingForAddEditAPI(true);
    let atLeastOneSampleIdField = false;
    const newSample = { po: submissionDetails.poNumber };
    const savedFields = {};

    /** Get field values and composite values from refs */
    const compositeInputValues = backRef?.current?.getInputValues();
    const compositeValues = backRef?.current?.getCompositeData();
    const isMrl = checkPesticidesTests();

    /** Get field values from refs */
    for (let i = 0; i < sampleFields.length; i++) {
      const json_field = sampleFields[i];
      const ref = inputValuesRefs.current[json_field]?.ref?.value;
      const compositeValueRef = compositeInputValues[json_field] ?? "";
      let refValue;

      // Determine refValue based on conditions
      if (isMrl && json_field === "sample_type") {
        refValue = ref; // Use ref if isMrl is true and json_field is "sample_type"
      } else if (compositeValues?.unique) {
        refValue = compositeValueRef; // Use compositeValueRef if compositeValues is unique
      } else {
        refValue = ref; // Default to ref
      } if (refValue !== undefined && refValue !== null) {
        const val = refValue.trim();
        if (val) {
          if (sampleIdFields.has(json_field)) {
            atLeastOneSampleIdField = true;
          }
          if (inputValuesSavedForNext[json_field]) {
            savedFields[json_field] = val;
          }
        }
        newSample[json_field] = val;
      } else {
        newSample[json_field] = "";
      }
    }
    /** If sample is composite sample */
    newSample.num_composite_samples = compositeValues?.sample_count ?? 0;
    newSample.composite_fieldnames = compositeValues?.identifier ?? [];

    /** Check if at least one sample id field is filled out */
    if (!atLeastOneSampleIdField) {
      toast.error((isLoggedOut && !thirdParty) ? "Sample ID is required" : "At least one Sample ID field is required");
      setWaitingForAddEditAPI(false);
      return;
    }

    /** Because submission editing apis don't regenerate sample id from fields, call generateSampleID to get the most updated sample id before proceeding */
    if (submissionEditingSample || submissionAddingSamples) {
      const { success, sample_id } = await generateSampleID(newSample, submissionEditingSample ? submissionEditingSample.sample.sample_id : sampleEditing.sample_id);
      if (success) {
        if (sample_id.length > CHAR_LIMITS.sample_id) {
          toast.error("Sample ID is too long");
          setWaitingForAddEditAPI(false);
          return;
        }
        newSample.sample_id = sample_id;
      } else {
        toast.error("Unable to generate Sample ID");
        setWaitingForAddEditAPI(false);
        return;
      }
      newSample.sample_id = sample_id;
    } else if (!isLoggedOut || thirdParty) {
      newSample.sample_id = generatedSampleID;
    }

    /** Check if at least one test selected */
    if (!isEmpty(testsSelected)) {
      newSample.test_list = Array.from(Object.keys(testsSelected));
    } else {
      toast.error("No tests selected for this sample");
      setWaitingForAddEditAPI(false);
      return;
    }

    /** If this is logged in env sample submission, handle image upload */
    const oldSample = sampleList[sampleEditingIdx];
    let deleteImagePath = null;
    if (sampleCategory === "environment" && (!isLoggedOut || thirdParty) && !submissionEditingSample) {
      const { file, editedUrl, imageWasEdited } = imageInfoRef.current;
      if (imageWasEdited) {
        if (file && editedUrl) { // a new image or crop was created
          if (oldSample.image_path) {
            deleteImagePath = oldSample.image_path;
          }
          const { success, path } = await addImageToAWS(file);
          if (!success) {
            toast.error("Image failed to upload");
            setWaitingForAddEditAPI(false);
            return;
          }
          newSample.image_path = path ?? "";
          newSample.image_file = file ?? null;
        } else if (!editedUrl) { // the image was removed from the sample
          if (oldSample.image_path) {
            deleteImagePath = oldSample.image_path;
          }
          newSample.image_path = "";
        }
      } else {
        newSample.image_path = oldSample.image_path ?? "";
        newSample.image_file = oldSample.image_file ?? null;
      }

      if (inputValuesSavedForNext.img) {
        savedFields.image_path = newSample.image_path ?? "";
      }
    }

    /** If this is a draft, get the draft api response otherwise use default */
    let response = { success: true, sampleID: newSample.sample_id };
    if ((!isLoggedOut || thirdParty) && !submissionEditingSample && !submissionAddingSamples) {
      response = await apiEditSampleDraft(oldSample.sample_id, newSample, savedFields);
    }

    /** Update the sample list with the edited sample, clear relevant state */
    const { success, sampleID } = response;
    if (success) {
      newSample.sample_id = sampleID;
      if (sampleCategory === "environment" && (!isLoggedOut || thirdParty)) {
        if (!submissionEditingSample) { // for editing a submitted sample, at this point we don't upload the image so the new path is not known, don't add the image_path key
          newSample.image_path = newSample.image_path ?? "";
        }
        newSample.image_file = !submissionEditingSample ? newSample.image_file ?? null : imageInfoRef.current.file;
        newSample.image_src = imageInfoRef.current.editedUrl ?? "";
        if (deleteImagePath) {
          deleteFileFromS3(deleteImagePath, s3EsvPriv);
        }
      }
      newSample.uuid = oldSample.uuid;
      sampleList[sampleEditingIdx] = newSample;
      setSampleList([...sampleList]);
      if (!submitEdit) {
        setClearSampleDetailsForm(true);
      }
      setLoadSampleEditing(false);
      setSampleEditing({});
      setSampleEditingIdx(-1);
      setSavedState(null);
      setLoadSavedState(false);
      imageInfoRef.current.imageWasEdited = false;
      if ((!isLoggedOut || thirdParty) && !submissionEditingSample && !submissionAddingSamples) {
        setDraftLastSavedTime(moment().format());
      }
      if (!submitEdit) {
        setClearTestSearch(true);
      }
    }
    setWaitingForAddEditAPI(false);
    if (submitEdit) handleSubmitButtonClick();
  };

  /**
   * Set sampleEditing and sampleEditingIdx states,
   * select tests from sample's test_list
   */
  const handleEditSample = async (idx) => {
    if (sampleEditingIdx === -1) {
      saveCurrentState(inputValuesSavedForNext, testsSelected, saveTestsForNext);
    }
    setLoadSampleEditing(true);
    setSampleEditing({ ...sampleList[idx] });
    setSampleEditingIdx(idx);
    setSaveTestsForNext(false);
  };

  /** Clear sampleEditing and sampleEditingIdx states */
  const handleCancelEditSample = () => {
    setLoadSampleEditing(false);
    setSampleEditing({});
    setSampleEditingIdx(-1);
    setLoadSavedState(true);
    setClearTestSearch(true);
    setPesticideSample("");
    setCompositeSample({ unique: null, identifier: [], sample_count: null });
    setEditingSubmittedSampleComposite(true);
  };

  /**
   * Delete sample at sampleToDeleteIdx in the sampleList.
   */
  const handleConfirmDeleteSample = async () => {
    setLoadSampleDeleting(true);
    const sample = sampleList[sampleToDeleteIdx];
    // console.log("delete", sample);
    const success = (!isLoggedOut || thirdParty) && !submissionAddingSamples ? await apiDeleteSampleDraft(sample.sample_id, sample.sample_type ?? "") : true;
    if (success) {
      if (sampleEditingIdx === sampleToDeleteIdx) {
        handleCancelEditSample();
      } else if (sampleEditingIdx > sampleToDeleteIdx) {
        setSampleEditingIdx(sampleEditingIdx - 1); // update the sample editing idx if it will change after the delete
      }
      if (sample.image_path) {
        deleteFileFromS3(sample.image_path, s3EsvPriv);
      }
      sampleList.splice(sampleToDeleteIdx, 1); // remove item at that index
      generateSampleID(undefined, sampleEditing?.sample_id, [...sampleList]); // generate sample id, when in normal or editing state
      setSampleList([...sampleList]);
      setSampleToDeleteIdx(-1);
      setShowConfirmationModal(0);
      if (!submissionAddingSamples) {
        setDraftLastSavedTime(moment().format());
      }
    }
    setLoadSampleDeleting(false);
  };

  /**
   * Show delete confirmation modal when user wants to delete sample.
   * @param  {Number} idx index of sample in sampleList array
   */
  const handleDeleteClick = async (idx) => {
    setSampleToDeleteIdx(idx);
    setShowConfirmationModal(3);
  };

  let confirmSubmitText = `Added samples (${sampleList.length}) will be submitted for testing.`;
  if (submissionEditingSample) {
    confirmSubmitText = "Are you sure you want to submit these edits?";
  } else if (submissionAddingSamples) {
    confirmSubmitText = `${sampleList.length} sample(s) will be added to the submission.`;
  }

  const showLoadingDiv = !showAddressPage && (loadDraftState || loadSampleEditing || loadSavedState || clearSampleDetailsForm || waitingForAddEditAPI || loadingImage);

  return (
    <>
      {showSuccessAnimation && <SuccessAnimation />}
      {showReceiptModal && (
        <SubmissionReceiptModal
          maskClosable={false}
          customerId={customerDetails.customerID}
          po={submissionDetails.poNumber}
          comment={submissionDetails.comment}
          submissionId={submissionDetails.submissionID}
          submissionFromParent={(isLoggedOut && !thirdParty) ? submissionForReceiptModal.current : undefined}
          showReceipt={showReceiptModal}
          handleReceipt={handleSubmissionDone}
          headerTitleText=""
        // confirmButtonText={isLoggedOut ? "Submit Another Submission" : "Go To Submissions Page"}
        />
      )}
      {(showConfirmationModal && showConfirmationModal <= 2) // submission confirmation modal
        ? (
          <SampleSubmissionConfirmModal
            headerText="Submission Confirmation"
            bodyText={showConfirmationModal === 1
              ? "You have unsaved changes. Only the added samples will be submitted for testing."
              : confirmSubmitText}
            buttonText={["Cancel", "Submit", "Submitting"]}
            buttonFunctions={[() => setShowConfirmationModal(0), handleConfirmSubmit]}
            loading={submitLoadingState}
            editedSamplePayload={submissionEditingSamplePayload.current}
            sampleFields={sampleFields}
            sampleFieldsInfo={sampleFieldsInfo}
            sampleIdFields={sampleIdFields}
            submissionComment={!submissionAddingSamples && !submissionEditingSample}
            // Allow edit comment when adding a sample
            // submissionComment={!submissionEditingSample}
            // commentDefaultValue={submissionDetails.comment}
            commentRef={commentRef}
            handleEditSample={handleEditSample}
            submissionEditingSample={submissionEditingSample}

          />
        ) : null}
      {(showConfirmationModal === 3) // delete confirmation modal
        ? (
          <SampleSubmissionConfirmModal
            headerText="Delete Sample Confirmation"
            bodyText="Are you sure you want to delete this sample? This cannot be undone."
            buttonText={["Cancel", "Delete", "Deleting"]}
            buttonFunctions={[() => { setShowConfirmationModal(0); setSampleToDeleteIdx(-1); }, () => handleConfirmDeleteSample(sampleToDeleteIdx)]}
            loading={loadSampleDeleting}
            compositeRef={backRef}
          />
        ) : null}
      <div className={`sample-submission-background sample-submission-background__Form ${isLoggedOut ? "logged-out" : ""} ${showAddressPage ? "sample-submission-background-address" : ""}`}>
        {showLoadingDiv && (<div className="SampleSubmissionForm__Loading" />)}
        <div className={`new-submission-pages-container${showAddressPage ? " address-page-container" : ""}`}>
          {showAddressPage
            ? (
              <SubmissionDetailsAddressPage
                handleGoBackToMainPage={handleGoBackToMainPage}
                submissionDetails={submissionDetails}
                setSubmissionDetails={setSubmissionDetails}
                setShowAddressPage={setShowAddressPage}
                setShowSubmissionForm={setShowSubmissionForm}
                setSampleFieldLoaded={setSampleFieldLoaded}
                customerDetails={customerDetails}
                setCustomerDetails={setCustomerDetails}
                sampleCategory={sampleCategory}
                setSubmissionNameNav={setSubmissionNameNav}
              />
            ) : (
              <>
                {/* {(submissionAddingSamples || submissionEditingSample) && (
                <SubmissionInfoBanner submissionName={submissionDetails.submissionName} poNumber={submissionDetails.poNumber} />
                )} */}
                <SampleDetailsPage
                  inputValuesRefs={inputValuesRefs}
                  setInputValuesRefs={setInputValuesRefs}
                  inputValuesSavedForNext={inputValuesSavedForNext}
                  toggleSaveForNext={toggleSaveForNext}
                  clearSampleDetailsForm={clearSampleDetailsForm}
                  setClearSampleDetailsForm={setClearSampleDetailsForm}
                  loadDraftState={loadDraftState}
                  setLoadDraftState={setLoadDraftState}
                  loadSampleEditing={loadSampleEditing}
                  setLoadSampleEditing={setLoadSampleEditing}
                  sampleEditing={sampleEditing}
                  handleGoBackToAddressPage={handleGoBackToAddressPage}
                  setInvalidInputFound={setInvalidInputFound}
                  savedState={savedState}
                  loadSavedState={loadSavedState}
                  setLoadSavedState={setLoadSavedState}
                  setTestsSelected={setTestsSelected}
                  saveTestsForNext={saveTestsForNext}
                  testsSelected={testsSelected}
                  generateSampleID={debouncedGenerateSampleID}
                  setInputValueAutofilled={setInputValueAutofilled}
                  handleGoBackToMainPage={handleGoBackToMainPage}
                  generatedSampleID={generatedSampleID}
                  clearTestSearch={clearTestSearch}
                  setClearTestSearch={setClearTestSearch}
                  compositeSample={compositeSample}
                  setSaveTestsForNext={setSaveTestsForNext}
                  customerDetails={customerDetails}
                  waitingForAddEditAPI={waitingForAddEditAPI}
                  sampleEditingIdx={sampleEditingIdx}
                  handleSaveEditedSample={handleSaveEditedSample}
                  handleAddSample={handleAddSample}
                  handleCancelEditSample={handleCancelEditSample}
                  invalidInputFound={invalidInputFound}
                  sampleList={sampleList}
                  handleEditSample={handleEditSample}
                  handleDeleteClick={handleDeleteClick}
                  sampleListScrollbar={sampleListScrollbar}
                  draftLastSavedTime={draftLastSavedTime}
                  handleSubmitButtonClick={handleSubmitButtonClick}
                  draftEditing={draftEditing}
                  sampleFields={sampleFields}
                  sampleFieldsInfo={sampleFieldsInfo}
                  delimiterRegex={delimiterRegex}
                  linkedFields={linkedFields}
                  imageInfoRef={imageInfoRef}
                  setImageInfoRef={setImageInfoRef}
                  setLoadingImage={setLoadingImage}
                  submissionEditingSample={submissionEditingSample}
                  submissionAddingSamples={submissionAddingSamples}
                  sampleIdFields={sampleIdFields}
                  updateSample={updateSample}
                  invalidTest={invalidTest}
                  setInvalidTest={setInvalidTest}
                  testInfoMap={testInfoMap}
                  sampleFromSubmission={sampleFromSubmission}
                  sampleIDGenerationError={sampleIDGenerationError}
                  submissionDetails={submissionDetails}
                  pesticideSample={pesticideSample}
                  setPesticideSample={setPesticideSample}
                  sampleCategory={sampleCategory}
                  ref={closeRef}
                  setShowExitModal={setShowExitModal}
                  isLoadingSampleId={isLoadingSampleId}
                  editingSubmittedSampleComposite={editingSubmittedSampleComposite}
                  setEditingSubmittedSampleComposite={setEditingSubmittedSampleComposite}
                  clearCompositeSaveForNext={clearCompositeSaveForNext}
                />
              </>

            )}
        </div>
      </div>
    </>
  );
}

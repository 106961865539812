import { useEffect, useRef, useState } from "react";
import {
  showScrollbarStyle,
  hideScrollbarStyle,
} from "../AutoHideScrollbarStyles";

/**
 * Custom hook to handle autohiding scrollbar.
 */
const useAutoHideScrollbar = () => {
  const [scrollbarInUse, setScrollbarInUse] = useState(false);
  const scrollbarRef = useRef();

  const setScrollbarRef = (elem) => {
    scrollbarRef.current = elem;
  };

  /* Add mouse enter and mouse leave event listeners to the Scrollbar div */
  useEffect(() => { // eslint-disable-line consistent-return
    if (scrollbarRef?.current) {
      const scrollbarElem = scrollbarRef.current;
      scrollbarElem.addEventListener("mouseenter", () => setScrollbarInUse(true));
      scrollbarElem.addEventListener("mouseleave", () => setScrollbarInUse(false));
      return () => {
        scrollbarElem.removeEventListener("onmouseenter", () => setScrollbarInUse(true));
        scrollbarElem.removeEventListener("onmouseleave", () => setScrollbarInUse(false));
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [setScrollbarRef, // pass to Scrollbar component's "elementRef" prop
    scrollbarInUse ? showScrollbarStyle : hideScrollbarStyle]; // pass to Scrollbar component's "trackYProps" or "trackXProps" prop
};

export default useAutoHideScrollbar;

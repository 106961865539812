import React from "react";
import NoResults from "../../../../../Common/NoResults";
import emptyStateIcon from "../../../../../../assets/images/EmptyState_V2.png";
import SelectionCard from "../../CommonComponents/SelectionCard";

export default function TestList({
  testData, updateSelectedOptions, disabled, handleTestClicked, selectedTests, searchTest,
}) {
  const { testUnit, testNames } = testData;
  const searchresultsavailable = testNames.length !== 0;

  return (
    <div className="analyticsTestListMainContainer">
      {testUnit && <span className="analyticsTestListHeaderText">{testUnit}</span>}
      <div className="analyticsTestListContainer">
        { searchresultsavailable ? (testNames.map((item) => {
          let active = false;
          if (selectedTests.includes(item)) active = true;
          if (active) return null; // when the test is already selected, don't display the test in the test list again
          return (
            <SelectionCard
              key={item}
              animationKey={item}
              data={item}
              type="test"
              updateSelectedOptions={updateSelectedOptions}
              disabled={disabled}
              handleClick={handleTestClicked}
              active={active}
              searchValue={searchTest}
            />
          );
        })) : <NoResults message="No results found." image={emptyStateIcon} /> }
      </div>
    </div>
  );
}

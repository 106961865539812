import React from "react";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import Scrollbar from "react-scrollbars-custom";
import { LoadingOutlined } from "@ant-design/icons";
import ErrorIcon from "../../assets/images/EmptyState_V2.png";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./PdfPreview.css";
import NoResults from "./NoResultsGIF";

export default function PdfPreview({
  blob,
  height,
  scaleType, // scaleType can be ActualSize, PageFit, PageWidth
  scaleNumber = 1,
  scrollBarId,
  scrollBarBorderRadius,
  showLoading = false,
  showError = false,
  handleLoadingDone = () => {},
}) {
  if (showError || (!showLoading && !blob)) {
    return (
      <div className="PdfPreview__Loading" style={{ height }}>
        <NoResults image={ErrorIcon} message="Failed to load PDF" className="PdfPreview__Error" />
      </div>
    );
  }

  if (showLoading) {
    return (
      <div className="PdfPreview__Loading" style={{ height }}>
        <LoadingOutlined spin className="PdfPreview__LoadingIcon" />
      </div>
    );
  }

  return (
    <Scrollbar
      id={scrollBarId}
      className="pdf-preview-scroll-bar"
      style={{
        height,
        borderRadius: scrollBarBorderRadius || "8px",
      }}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <Viewer
          fileUrl={blob}
          defaultScale={scaleType ? SpecialZoomLevel[scaleType] : scaleNumber}
          onDocumentLoad={handleLoadingDone}
          renderLoader={() => (
            <div className="PdfPreview__Loading" style={{ height }}>
              <LoadingOutlined spin className="PdfPreview__LoadingIcon" />
            </div>
          )}
          renderError={() => {
            handleLoadingDone();
            return (
              <div className="PdfPreview__Loading" style={{ height }}>
                <NoResults image={ErrorIcon} message="Failed to load PDF" className="PdfPreview__Error" />
              </div>
            );
          }}
        />
      </Worker>
    </Scrollbar>
  );
}

import React from "react";
import eyeClosedIcon from "../../../assets/images/ph_eye-closed-fill@2x.png";
import eyeOpenedIcon from "../../../assets/images/ph_eye-opened-fill@2x.png";

export default function Password({
  showEdit,
  passType,
  newPassType,
  confirmedPassType,
  showHidePassword,
  handleSaveCancelClick,
  handleSaveEditClick,
  handleEditClick,
  loading,
}) {
  if (showEdit) {
    return (
      <>
        <div className="myAccountProfileInputContainer">
          <div className="myAccountFormTitle">
            Password
          </div>
          <div className="my-profile-change-password-container">
            <div className="my-profile-old-password-row">
              <div className="myAccountFormTitle">
                Enter Old Password
              </div>
              <input
                id="myaccountpassword"
                type={passType}
                className="myaccountEditInput myaccountEditInputPassWidth"
                placeholder="Old Password"
                required
              />
              <img
                src={passType === "text" ? eyeOpenedIcon : eyeClosedIcon}
                alt="eye icon"
                onClick={() => showHidePassword("passType", passType)}
              />
            </div>

            <div className="my-profile-new-password-row">
              <div className="MyProfile__NewPassword">
                <div className="myAccountFormTitle"> New Password </div>
                <input
                  id="myaccountnewpassword"
                  type={newPassType}
                  className="myaccountEditInput myaccountEditInputPassWidth"
                  placeholder="New Password"
                  required
                />
                <img
                  src={newPassType === "text" ? eyeOpenedIcon : eyeClosedIcon}
                  alt="eye icon"
                  onClick={() => showHidePassword("newPassType", newPassType)}
                />
              </div>
              <div className="MyProfile__NewPassword">
                <div className="myAccountFormTitle">Confirm New Password</div>
                <input
                  id="myaccountconfirmedPassword"
                  type={confirmedPassType}
                  className="myaccountEditInput myaccountEditInputPassWidth"
                  placeholder="Confirm New Password"
                  required
                />
                <img
                  src={confirmedPassType === "text" ? eyeOpenedIcon : eyeClosedIcon}
                  alt="eye icon"
                  onClick={() => showHidePassword("confirmedPassType", confirmedPassType)}
                />
              </div>
            </div>
            <div id="user-error" className="MyProfile__PasswordReqsDiv">
              <span>Must contain: at least 1 number, 1 uppercase, 1 lowercase letter, at least 6 characters, no spaces.</span>
            </div>
          </div>
        </div>
        <div className={loading ? "my-profile-cancel-submit-buttons my-profile-loading" : "my-profile-cancel-submit-buttons"}>
          <button
            type="button"
            onClick={() => handleSaveCancelClick()}
            className="myAccountCancelBtn"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => handleSaveEditClick()}
            className="myAccountEditBtn my-profile-submit-button"
          >
            Submit
          </button>
        </div>
      </>
    );
  }

  return (
    <div className="myAccountProfileInputContainer">
      <div className="myAccountFormTitle">
        Password
      </div>
      <input
        type="text"
        placeholder="***************"
        className="myaccountDefaultInput"
        disabled
      />
      <div
        className="myAccountChangePsw"
        onClick={() => handleEditClick("password")}
      >
        Change password
      </div>
    </div>
  );
}

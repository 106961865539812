import React from "react";
import StyledButton from "../../../Common/UIComponents/StyledButton";
import cycleIcon from "../../../../assets/images/product/circleArrow.png";
import SpecButton from "./SpecButton";
import StyledSwitch from "../../../Common/UIComponents/StyledSwitch";
import "./SpecsBlock.css";

class SpecsBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedFilter: "",
    };
  }

  handleToggleFilter = (index, currSelection) => {
    const { handleFilterBySpec } = this.props;
    const { currentSelectedFilter } = this.state;
    handleFilterBySpec(index);
    this.setState({
      currentSelectedFilter: (currSelection === currentSelectedFilter) ? "" : currSelection,
    });
  }

  render() {
    const {
      specSelected,
      handleSpecManagerToggle,
      handleFilterByRetest,
    } = this.props;
    const { currentSelectedFilter } = this.state;

    return (
      <div className="SpecsBlockContainer">

        <div className="reportSliderContainer">
          <span className="retestSpan"> Retest Requests </span>
          <StyledSwitch
            onClick={handleFilterByRetest}
            className="RetestRequestsSwitch"
            disabled={specSelected === "3"}
            size="small"
          />
        </div>
        <div>
          <div className="specCategory"> Final result</div>
          <div className="SpecsBlock__SpecButtonBlock">
            <SpecButton
              index="0"
              specText="In Process"
              specColor="#E1B226"
              specSelected={specSelected}
              disableFilterButton={false}
              isActive={currentSelectedFilter === "In Process"}
              handleToggleFilter={this.handleToggleFilter}
            />
            <SpecButton
              index="1"
              specText="In Spec"
              specColor="#1EC04A"
              specSelected={specSelected}
              disableFilterButton={false}
              isActive={currentSelectedFilter === "In Spec"}
              handleToggleFilter={this.handleToggleFilter}
            />
            <SpecButton
              index="2"
              specText="Out of Spec"
              specColor="#E63559"
              specSelected={specSelected}
              disableFilterButton={false}
              isActive={currentSelectedFilter === "Out of Spec"}
              handleToggleFilter={this.handleToggleFilter}
            />
          </div>
        </div>
        <div className="productReportSpecManagerButtonContainer">
          <div className="productReportSpecManagerButtonContainerEmptyDiv">{" "}</div>
          <div>
            <StyledButton
              type="primary"
              id="productReportSpecManagerButton"
              className="ProductReport__SpecManagerButton"
              onClick={handleSpecManagerToggle}
            >
              <div>
                <img
                  className="productReportSpecManagerButtonCycleIcon"
                  src={cycleIcon}
                  alt="cycleIcon"
                  width="14px"
                />
                Spec Manager
              </div>
            </StyledButton>
          </div>
        </div>
      </div>
    );
  }
}

export default SpecsBlock;

import React, { useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Form,
  Input,
  Spin,
} from "antd";
import "../CompanyContactForm.css";
import "./AddressSection.css";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { toast } from "react-toastify";

import { getCustomerInfo } from "../../../../../../actions/onboarding";

export default function AddressSection({
  label, namePath = ["address"], disabled = false, addCompany, companyName,
}) {
  const [loading, setLoading] = useState(false);
  const form = useFormInstance();

  const handleCheckAdd = async (e) => {
    // const values = form.getFieldValue(["address"]);
    const checked = e.target.checked;

    if (checked) {
      try {
        setLoading(true);
        const CustomerInfo = await getCustomerInfo();

        if (CustomerInfo.message === "Success") {
          let parsedAddress = {
            city: "", state: "", country: "", zipCode: "",
          };
          let parsedStreet = { street: "", line_2: "" };

          // Parsing for the same address
          if (CustomerInfo?.address_2) {
            const CityState = CustomerInfo.address_2.split(", ") || [];
            const CountryZip = CityState[2]?.split(" ") || [];

            // if country name has more than 1 word.
            if (CountryZip?.length > 2 && typeof CountryZip[1] === "string") {
              for (let i = 0; i <= CountryZip.length - 2; i++) {
                parsedAddress = {
                  country: `${parsedAddress.country} ${CountryZip[i]}`,
                };
              }
            } else {
              parsedAddress = {
                country: CountryZip[0],
              };
            }
            parsedAddress = {
              ...parsedAddress,
              city: CityState[0] || "",
              state: CityState[1] || "",
              // country: CountryZip[0] || "",
              zipCode: CountryZip[CountryZip.length - 1] || "",
            };
          }

          if (CustomerInfo?.address_1) {
            const Street = CustomerInfo.address_1.split(", ") || [];
            parsedStreet = {
              street: Street[0] || "",
              line_2: Street[1] || "",
            };
          }

          // Auto fill the address value same as current company address
          form.setFieldValue(["address"], {
            street: parsedStreet?.street || "",
            line_2: parsedStreet?.line_2 || "",
            zipcode: parsedAddress?.zipCode || "",
            city: parsedAddress?.city || "",
            state: parsedAddress?.state || "",
            country: parsedAddress?.country || "",
          });
          form.validateFields([["address"]], { recursive: true, dirty: true });
        } else {
          form.setFieldValue("same_address", false);
          toast.error("Failed to fetch Address!");
        }
        setLoading(false);
      } catch (error) {
        form.setFieldValue("same_address", false);
        toast.error("Failed to fetch Address!");
        setLoading(false);
      }
    } else {
      form.setFieldValue("same_address", false);
    }
  };

  return (
    <Form.Item
      label={label ? <span className="MyForm__SectionLabel">{label}</span> : undefined}
    >

      {addCompany && (
        <>
          {loading ? (
            <div className="BillingAddressSection__Loading">
              <Spin indicator={<LoadingOutlined className="ReportsTable__Checkbox__Spin" spin />} />
              <span className="BillingAddressSection__Address_label">
                Do you want to use the same Company Address as
                {" "}
                {companyName}
                ?
              </span>
            </div>
          )
            : (
              <Form.Item name="same_address" valuePropName="checked">
                <Checkbox className="BillingAddressSection__Checkbox" onChange={handleCheckAdd}>
                  Do you want to use the same Company Address as
                  {" "}
                  {companyName}
                  ?
                </Checkbox>
              </Form.Item>
            )}
        </>
      )}
      <Form.Item
        name={[...namePath, "street"]}
        label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Street Address</span>}
        rules={[
          {
            required: !disabled, message: "",
          },
        ]}
        className="AddressSection__FieldRow"
      >
        <Input disabled={disabled} placeholder="Enter Street Address" />
      </Form.Item>
      <Form.Item
        name={[...namePath, "line_2"]}
        label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Street Address 2 (optional)</span>}
        className="AddressSection__FieldRow"
      >
        <Input disabled={disabled} placeholder="Building number, apartment number, P.O. box number, etc." />
      </Form.Item>
      <div className="AddressSection__FlexRow">
        <Form.Item
          name={[...namePath, "zipcode"]}
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Zip Code</span>}
          rules={[
            {
              required: !disabled, message: "",
            },
          ]}
          className="AddressSection__ShortInput"
        >
          <Input disabled={disabled} placeholder="Enter ZIP Code" />
        </Form.Item>
        <Form.Item
          name={[...namePath, "city"]}
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">City</span>}
          rules={[
            {
              required: !disabled, message: "",
            },
          ]}
          className="AddressSection__ShortInput"
        >
          <Input disabled={disabled} placeholder="Enter City" />
        </Form.Item>

        <Form.Item
          name={[...namePath, "state"]}
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">State/Province</span>}
          rules={[
            {
              required: !disabled, message: "",
            },
          ]}
          className="AddressSection__ShortInput"
        >
          <Input disabled={disabled} placeholder="Enter State" />
        </Form.Item>

        <Form.Item
          name={[...namePath, "country"]}
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Country</span>}
          rules={[
            {
              required: !disabled, message: "",
            },
          ]}
          className="AddressSection__ShortInput"
        >
          <Input disabled={disabled} placeholder="Enter Country" />
        </Form.Item>
      </div>

    </Form.Item>
  );
}

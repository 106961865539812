import React from "react";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
// import { CSVLink } from "react-csv";
import StyledSwitch from "../../../../../Common/UIComponents/StyledSwitch";
import AnalyticsDatePicker from "../../CommonComponents/AnalyticsDatePicker";
import GraphPill from "./GraphPill";
import "./GraphHeader.css";

export default function GraphHeader({
  selectedData,
  selectedGraph,
  selectedGraphHasSpec,
  showBarGraph,
  setShowBarGraph,
  showDatePicker,
  getSelectedDates,
  setDatePicker,
  updateSelectedGraph,
  searchByDate,
  dateSelected,
  setDateSelected,
  prevDateSelected,
  setPrevDateSelected,
  linkReportFields,
  formattedData,
  fieldsMap,
}) {
  const { selectedProducts, selectedTests } = selectedData;
  const numOfGraphs = selectedProducts.length * selectedTests.length;

  // Returns a formatted version of the dates to be displayed
  const getFormattedDates = () => {
    const dateObj = getSelectedDates();

    if (dateObj?.length === 2) {
      return `${moment(dateObj[0]).format("MMM DD, YYYY")} - ${moment(dateObj[1]).format("MMM DD, YYYY")}`;
    }

    return "No dates selected";
  };

  // Renders a graph pill for each combination of test and product
  const renderGraphPills = () => {
    let counter = -1;
    return selectedProducts.map((product) => (
      selectedTests.map((test) => {
        counter++;
        let selected = false;
        if (selectedGraph.test === test && selectedGraph.product?.product_id === product?.product_id) selected = true;

        return (
          <SwiperSlide className="analyticsGraphPillSwiperSlide">
            <GraphPill
              data={{ product, test }}
              key={counter}
              counter={counter}
              selected={selected}
              updateSelectedGraph={updateSelectedGraph}
              dataIsUndefined={formattedData === undefined}
              linkReportFields={linkReportFields}
              fieldsMap={fieldsMap}
              selectedGraphHasSpec={selectedGraphHasSpec}
            />
          </SwiperSlide>
        );
      })
    ));
  };
  // const getSpecStatus = (flag) => {
  //   if (["1", "5"].includes(flag)) {
  //     return "In Spec";
  //   }
  //   if (["2", "6"].includes(flag)) {
  //     return "Out of Spec";
  //   }
  //   return "";
  // };

  // format data for csv
  // const getExportData = () => {
  //   const { scatterObj } = formattedData;
  //   // console.log(scatterObj, selectedTests, selectedProducts);
  //   const exportData = [];
  //   selectedTests.forEach((testName) => {
  //     selectedProducts.forEach((prodName) => {
  //       const key = [testName, prodName].join("|");
  //       if (key in scatterObj) {
  //         scatterObj[key].forEach(({
  //           date, y, specsFlag, id,
  //         }) => {
  //           exportData.push({
  //             date,
  //             product: prodName,
  //             test: testName,
  //             id,
  //             result: y,
  //             status: getSpecStatus(specsFlag),
  //           });
  //         });
  //       }
  //     });
  //   });
  //   return exportData;
  // };

  // headers for exported csv
  // const headers = [
  //   { label: "Date", key: "date" },
  //   { label: "Product", key: "product" },
  //   { label: "Test", key: "test" },
  //   { label: "ID", key: "id" },
  //   { label: "Result", key: "result" },
  //   { label: "Status", key: "status" },
  // ];

  return (
    <div className="analyticsGraphHeaderContainer">
      <span className="analyticsGraphAmountText">
        {numOfGraphs}
        {" "}
        {showBarGraph ? "Bar graph active" : "Line graph active"}
      </span>
      <div className="analyticsGraphPillMainContainer">
        <Swiper
          slidesPerView="auto"
          modules={[Pagination, Navigation]}
          navigation
          className="analyticsGraphPillSwiper"
        >
          {renderGraphPills()}
        </Swiper>
      </div>

      <div className="analyticsGraphDateBarContainer">
        <div className="analyticsDateContainer">
          <AnalyticsDatePicker
            selectedData={selectedData}
            searchByDate={searchByDate}
            dateSelected={dateSelected}
            setDateSelected={setDateSelected}
            prevDateSelected={prevDateSelected}
            setPrevDateSelected={setPrevDateSelected}
            showDatePicker={showDatePicker}
            setDatePicker={setDatePicker}
          />

          <span className="analyticsDateText">{getFormattedDates()}</span>
        </div>
        {/* {formattedData !== undefined
        && (
        <div className="productAnalyticsExportButton">
          <StyledTooltip
            title="Export data"
            placement="top"
          >
            <CSVLink
              data={getExportData()}
              filename="product_analytics_data.csv"
              headers={headers}
            >
              <svg className="exportDataIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M32 64C32 28.7 60.7 0 96 0H256V128c0 17.7 14.3 32 32 32H416V288H248c-13.3 0-24 10.7-24 24s10.7 24 24 24H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V64zM416 336V288H526.1l-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39H416zm0-208H288V0L416 128z" /></svg>
            </CSVLink>
          </StyledTooltip>
        </div>
        )} */}

        <div className="analyticsBarGraphToggleContainer">
          <span className="analyticsBarGraphToggleText">Bar Data Visualization</span>
          <StyledSwitch
            checked={showBarGraph}
            onChange={() => setShowBarGraph(!showBarGraph)}
            size="small"
            className="ProductAnalytics__Switch"
          />
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function OnboardingWelcomePageCard({
  className = "", Icon, title, description,
}) {
  return (
    <div className={className}>
      {Icon}
      <p className="OnboardingWelcomePage__StepContainer__Title">{title}</p>
      <p className="OnboardingWelcomePage__StepContainer__SubTitle">
        { description }
      </p>
    </div>
  );
}

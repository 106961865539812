import React from "react";
import {
  View, StyleSheet, Image, Text,
} from "@react-pdf/renderer";
import { softHyphenate } from "./pdfUtils";

export default function PDFImageWithText({
  imageFile,
  title,
  subtitle,
  alignLeft,
  hyphenateTitle = true,
}) {
  const styles = StyleSheet.create({
    imageSection: {
      display: "flex",
      flexDirection: alignLeft ? "row" : "column",
      alignItems: "center",
      // width: "auto",
      gap: "21",
      width: "100%",
    },
    imageContainer: {
      minWidth: "150",
      width: "150",
      height: "75",
      borderRadius: "8",
      border: "1px solid #EAF0F5",
      backgroundColor: "#F6F8FA",
      marginTop: "auto",
      marginBottom: "auto",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      // margin: "auto",
    },
    text: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "8",
      width: "auto",
      maxWidth: imageFile ? "222" : "372",
      height: "100%",
      flexGrow: "0",
    },
    title: {
      fontFamily: "Roboto-500",
      fontSize: "14",
      color: "#506375",
      // textTransform: "uppercase",
    },
    subtitle: {
      fontFamily: "Roboto-500",
      fontSize: "11",
      color: "#506375",
    },
    wordWrap: {
      display: "flex",
      flexDirection: "row",
      overflowWrap: "break-word",
    },
  });
  const previewURL = imageFile ? URL.createObjectURL(imageFile) : null;
  return (
    <View style={styles.imageSection}>
      {previewURL && (
      <View style={styles.imageContainer}>
        <Image style={styles.image} src={previewURL} />
      </View>
      ) }
      <View style={styles.text}>
        <Text style={[styles.title]}>
          {(hyphenateTitle ? softHyphenate(title) : title) || " "}
        </Text>
        <Text style={[styles.subtitle]}>
          {softHyphenate(subtitle)}
        </Text>
      </View>
    </View>
  );
}

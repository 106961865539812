import React from "react";
import {
  View, StyleSheet,
} from "@react-pdf/renderer";
import TableHeaderSamples from "./TableHeaderSamples";
import TableRowSamples from "./TableRowSamples";

const styles = StyleSheet.create({
  tableBottomBorder: {
    color: "#505050",
    padding: "11 24 0 24",
    border: "1 solid #E1E1E1",
    borderRadius: "6",
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
    borderTop: "0",
  },
  tableTopBorder: {
    color: "#505050",
    padding: "0 24 14 24",
    border: "1 solid #E1E1E1",
    borderRadius: "6",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    borderBottom: "0",
  },
});
export default function PDFTableSamples({ samples, uniqueTests }) {
  const samplesList = samples.map(({ sample_id, test_results }) => ({ sample_id, test_results }));
  return (
    <>
      <View>
        <View style={styles.tableTopBorder} fixed />
        <TableHeaderSamples uniqueTests={uniqueTests} />
        {samplesList.map((sample, index) => (
          <TableRowSamples
            key={index}
            sample={sample}
            isLast={index === samples.length - 1}
            uniqueTests={uniqueTests}
          />
        ))}
        {samplesList.length === 0 && (
        <TableRowSamples
          key={0}
          spec={{ sample_id: "-", test_results: [] }}
          uniqueTests={uniqueTests}
          isLast
        />
        )}
        <View style={styles.tableBottomBorder} fixed />
      </View>
    </>
  );
}

export const UNIT_OPTIONS = ["None", "CFU/g", "CFU", "mg/kg", "ppb", "ppm", "%", "g", "ml"];

/**
   * Remove commas from string, parseFloat to get a number
   * @param {String} value
   * @returns {Number} return number parse from string
   */
export const parseNumber = (value) => parseFloat(value.split(",").join(""));

/**
   * Check if value is a number
   * @param {String} value
   * @returns {Boolean} true if a number else false
   */
export const isNumber = (value) => !Number.isNaN(parseNumber(value));

/**
 * Returns true if there is an invalid character or there are multiple periods in string.
 * @param {String} string input value
 * @returns {Boolean} true if valid, else false
 */
export const isValid = (string) => {
  const valid = /^([0-9,]|(\.(?![^\\.]*\.+[^\\.]*)))*$/.test(string); // only allow digits, commas, and 0 or 1 periods
  // const anyInvalid = /[^0-9,\\.]|\.(?=[^\\.]*\.+[^\\.]*)/.test(string); // true if any non-digit, non-comma, non-period character or if there's more than 1 period
  return valid;
};

export const formatSpec = (dataOfSpecEditing, skipCountryList = false) => dataOfSpecEditing.map((element) => {
  // const { test, test_type, specs } = element;
  const test_type = element.test_type;
  const specs = element.specs;
  // let test = (this.state.addingSpec ? element.Test : element.test)
  const test = element.test;
  let min = "";
  let max = "";
  let unit = "";
  if (test_type === "range") {
    if ((specs[0] && !specs[1]) || (!specs[0] && specs[1])) {
      return "Range is missing a value";
    }
    if (specs[0] && specs[1] && specs[0].trim() && specs[1].trim()) {
      if (!isValid(specs[0]) || !isValid(specs[1])) {
        return "Invalid input";
      }
      const numFrom = parseNumber(specs[0]);
      const numTo = parseNumber(specs[1]);

      if (isNumber(specs[0]) && isNumber(specs[1])) {
        specs[2] = !specs[2] ? "" : specs[2];
        if (numFrom > numTo) {
          // min = specs[1].trim() + specs[2];
          min = specs[1].trim();
          // max = specs[0].trim() + specs[2];
          max = specs[0].trim();
        } else {
          // min = specs[0].trim() + specs[2];
          min = specs[0].trim();
          // max = specs[1].trim() + specs[2];
          max = specs[1].trim();
        }
      } else {
        return "Range is missing a value";
      }
    }
  } else if (test_type === "quantitative") {
    if (specs[1] && specs[1].trim()) {
      if (!isValid(specs[1])) {
        return "Invalid input";
      }
      // specs[2] = !specs[2] ? UNIT_OPTIONS[0] : specs[2] === "None" ? "" : specs[2];
      if (!specs[2]) {
        specs[2] = UNIT_OPTIONS[0];
      }
      if (isNumber(specs[1])) {
        if (!specs[0] || specs[0] === "Max") {
          // max = "<" + specs[1].trim() + specs[2];
          max = `<${specs[1].trim()}`;
        } else {
          // min = ">" + specs[1].trim() + specs[2];
          min = `>${specs[1].trim()}`;
          max = "";
        }
      }
    }
  } else if (test_type === "qualitative") {
    if (specs[1] && specs[1].trim()) {
      specs[0] = !specs[0] ? "Negative" : specs[0];
      specs[1] = specs[1].trim()[0] === "/" ? specs[1].trim() : `/${specs[1].trim()}`;
      if (!isValid(specs[1].substring(1))) {
        return "Invalid input";
      }
      if (isNumber(specs[1].substring(1))) {
        // max = specs[0] + specs[1] + "g";
        max = specs[0] + specs[1];
      }
    } else {
      max = "Negative";
    }
  }
  if (element.specs[2] === "None") {
    element.specs[2] = "";
  }
  if (!max.includes("/") && test_type === "qualitative") {
    unit = "";
  } else {
    unit = element.specs[2] || "";
  }

  const formattedValue = {
    test, min, max, unit,
  };

  // Pesticide test
  if (element.countries_lst && !skipCountryList) {
    formattedValue.countries_lst = element.countries_lst.filter((c) => c.selected).map((c) => c.country);
  }

  return formattedValue;
});

export const parseSpecDetail = (spec) => {
  let specs = ["", "", ""];
  let test_type = "";
  const test = spec.test ? spec.test : "";

  // return {
  //   test: "test",
  //   test_type: "range",
  //   specs: ["adfadf,12sdf63 K 123", "123", ""],
  // };

  if (spec.min !== undefined && spec.max !== undefined) {
    const specString = spec.min + spec.max;

    if (specString.startsWith("Positive")) {
      test_type = "qualitative";
      // let numberString = specString.substring("Positive".length, specString.length - 1);
      const numberString = specString.split("/")[1];
      specs = ["Positive", numberString, spec.unit];
    } else if (specString.startsWith("Negative")) {
      test_type = "qualitative";
      // let numberString = specString.substring("Negative".length, specString.length - 1);
      const numberString = specString.split("/")[1];
      specs = ["Negative", numberString, spec.unit];
    } else if (specString[0] === "<" || specString[0] === ">") {
      let unit = "";
      let numberString = specString.substring(1, specString.length);
      const maxString = specString[0] === "<" ? "Max" : "Min";
      for (let i = 0; i < UNIT_OPTIONS.length; i++) {
        if (specString.endsWith(UNIT_OPTIONS[i])) {
          unit = UNIT_OPTIONS[i];
          numberString = specString.substring(1, specString.length - unit.length);
          break;
        }
      }
      // unit = unit === "" ? "None" : unit;
      test_type = "quantitative";
      specs = [maxString, numberString, spec.unit];
    } else if (specString === "") {
      test_type = "";
    } else if (specString !== "None") {
      test_type = "range";
      if (specString.endsWith("%")) {
        spec.min = spec.min.substring(0, spec.min.length - 1);
        spec.max = spec.max.substring(0, spec.max.length - 1);
        // specs[2] = "%";
        specs[2] = spec.unit;
      }
      // specs[0] = spec.min === "" ? "0" : spec.min;
      specs[0] = spec.min;
      // specs[1] = spec.max === "" ? "0" : spec.max;
      specs[1] = spec.max;
      specs[2] = spec.unit;
    }
  }
  const parsedValues = {
    test,
    test_type,
    specs,
    removable: spec.removable,
  };
  if (spec.countries_lst) {
    parsedValues.countries_lst = spec.countries_lst;
  }
  return parsedValues;
};

// export const rangeIsMissingValue = (specList) => {
//   let isMissing = false;
//   specList.forEach((elem) => {
//     if (elem === "Range is missing a value") {
//       isMissing = true;
//     }
//   });
//   return isMissing;
// };

export const handleCheckUnit = (specList) => {
  specList.forEach((element) => {
    if (!element.min && !element.max) {
      element.unit = "";
    }
  });
  return specList;
};

export const checkIfSpecsComplete = (specList) => {
  const formatted = formatSpec(specList);
  let complete = true;

  // console.log("formatted", formatted);

  formatted.forEach((item) => {
    if (item === "Range is missing a value") {
      complete = false;
      return;
    }
    if (!item.min.includes(">") && !item.max.includes("<") && !item.max.includes("Negative") && !item.max.includes("Positive")) {
      if (item.min === "" && item.max === "") {
        complete = false;
      }
    }
  });

  return complete;
};

export const checkIfSpecsInvalid = (specList) => {
  const formatted = formatSpec(specList);
  return formatted.some((item) => item === "Range is missing a value" || item === "Invalid input");
};

import React from "react";
import { PINS_SPEC_STYLE_MAP } from "../../Constant";
import { ReactComponent as StatusDotIcon } from "../../../../../../assets/images/environment/statusPingDot.svg";
import "./FilterButtons.css";

const FilterButtons = ({
  handleSelectFilter,
  filterSelected,
  greyout,
}) => (
  <div className="FilterButtons">
    { !greyout && [2, 1, 0].map((spec) => (
      <div
        key={spec}
        className={`FilterButtons__Btn ${filterSelected === spec ? "FilterButtons__Btn--selected" : ""}`}
        onClick={() => handleSelectFilter(spec)}
      >
        <StatusDotIcon width={15} height={15} fill={PINS_SPEC_STYLE_MAP[spec].color} />
        <div className="FilterButtons__Text">{PINS_SPEC_STYLE_MAP[spec].display_name}</div>
      </div>
    ))}
    { greyout && [2, 1, 0].map((spec) => (
      <div
        key={spec}
        className={`FilterButtons__GrayOutBtn ${filterSelected === spec ? "FilterButtons__GrayOutBtn--selected" : ""}`}
        onClick={() => handleSelectFilter(spec)}
      >
        <StatusDotIcon width={15} height={15} fill={PINS_SPEC_STYLE_MAP[spec].color} />
        <div className="FilterButtons__Text">{PINS_SPEC_STYLE_MAP[spec].display_name}</div>
      </div>
    ))}
  </div>
);

export default FilterButtons;

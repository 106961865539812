import React from "react";
import {
  View, StyleSheet, Text,
} from "@react-pdf/renderer";
import { v4 as uuidv4 } from "uuid";
import PDFTable from "./PDFTable";
import PDFTableSamples from "./PDFTableSamples";
import { softHyphenate } from "./pdfUtils";

export default function PDFBody(props) {
  const styles = StyleSheet.create({
    body: {
      width: "100%",
      fontFamily: "Roboto-500",
      gap: "14",
    },
    section: {
      display: "flex",
      flexDirection: "row",
      // gap: 32,
      padding: "5",
      borderLeft: "1 solid #eaf0f5",
      borderRight: "1 solid #eaf0f5",
      borderBottom: "1 solid #eaf0f5",
      borderTop: "1 solid #eaf0f5",
      width: "100%",
      borderRadius: "8",
    },
    sectionPart: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      maxWidth: "50%",
      flex: "1 1 50%",
      gap: "8",
      padding: "10 5 10 10",
      // overflow: "hidden",
    },
    sectionInput: {
      width: "auto",
      maxWidth: "100%",
      wordWrap: "break-word",
      textAlign: "left",
      fontFamily: "Roboto-500",
      fontSize: "12",
    },
    sectionInputFieldName: {
      color: "#afbdca",
    },
    sectionInputValue: {
      color: "#1f2d3b",
    },
    blue: {
      color: "#9dd9f2",
    },
    alignItem: {
      textAlign: "right",
      paddingRight: "29",
    },
    coaPlaceholder: {
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: "30",
      color: "#C4D2DF",
      fontSize: "12",
      fontFamily: "Roboto-400",
    },
  });

  // const renderBlock = (section) => {
  //   let count = 1;
  //   return section.inputs.map((input, index) => {
  //     let styleArray = [];
  //     if (input.type === "largeTextBox") {
  //       count = 1;
  //       return (
  //         <View key={input + index} style={[styles.itemLarge, styles.gap]}>
  //           <Text>
  //             {input.input}
  //           </Text>
  //         </View>
  //       );
  //     }
  //     if (count === 2) {
  //       count = 1;
  //       styleArray = [styles.itemSmall, styles.gap, styles.alignItem];
  //     } else {
  //       count++;
  //       styleArray = [styles.itemSmall, styles.gap];
  //     }
  //     return (
  //       <View key={input + index} style={styleArray}>
  //         <Text>{input.input}</Text>
  //       </View>
  //     );
  //   });
  // };

  const renderInputs = (inputs) => inputs.map(({ fieldName, value, flag }) => {
    const key = uuidv4();
    return (
      <Text style={styles.sectionInput} key={key}>
        {fieldName ? (<Text style={[styles.sectionInputFieldName, flag === "2" ? styles.blue : null]} key={`${key}_field`}>{`${softHyphenate(fieldName)}   `}</Text>) : null}
        {value ? (<Text style={styles.sectionInputValue} key={`${key}_value`}>{softHyphenate(value)}</Text>) : null}
      </Text>
    );
  });

  const { body, isCustomizingCoa, isTemplatePdf } = props;
  const {
    sections, samples, uniqueTests, enableTests,
  } = body;

  return (
    <View style={styles.body}>
      {sections.map((section) => (
        <View key={section.title} style={styles.section} wrap={false}>
          <View key={uuidv4()} style={styles.sectionPart}>
            {renderInputs(section.inputs.left)}
          </View>
          <View key={uuidv4()} style={styles.sectionPart}>
            {renderInputs(section.inputs.right)}
          </View>
        </View>
      ))}
      {!isCustomizingCoa && isTemplatePdf
        ? <Text style={styles.coaPlaceholder}>This space will be filled by test reports and data fields when creating the customized report.</Text>
        : (
          <>
            {enableTests
              ? <PDFTableSamples samples={samples} uniqueTests={uniqueTests} enableTests={enableTests} />
              : samples.map(({ sample_id, test_results, textHeights }, i) => <PDFTable key={i} sample_id={sample_id} test_results={test_results} textHeights={textHeights} uniqueTests={uniqueTests} enableTests={enableTests} />)}
          </>
        )}
    </View>
  );
}

import React from "react";

import StyledButton from "../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../Common/UIComponents/StyledTooltip";

import { ReactComponent as NextArrowIcon } from "../../../assets/images/environment/NextArrowIcon.svg";

export default function CreateSamplingEventModalFooter({
  tabs,
  selectedTab,
  onCancel,
  onSubmit,
  onTabChange,
  disableNext,
  disableAddToCalendar,
  isSubmitLoading,
}) {
  /**
   * Handles Previous button click
   * @param {Number} currentTabIndex current tab index
   */
  const handlePreviousTabChange = (currentTabIndex) => {
    const previousTabIndex = currentTabIndex - 1;
    if (previousTabIndex >= 0) {
      onTabChange(tabs[previousTabIndex]);
    }
  };

  /**
   * Handle Next button click
   * @param {Number} currentTabIndex current tab index
   */
  const handleNextTabChange = (currentTabIndex) => {
    const nextTabIndex = currentTabIndex + 1;
    if (nextTabIndex < tabs.length) {
      onTabChange(tabs[nextTabIndex]);
    }
  };

  if (tabs && tabs.length > 0 && selectedTab) {
    const currentTabIndex = tabs.indexOf(selectedTab);
    const isFirstTab = currentTabIndex === 0;
    const isLastTab = tabs.length - 1 === currentTabIndex;
    return (
      <div className="CreateSamplingEventModal__Footer">
        <StyledButton
          htmlType="button"
          type="default"
          onClick={isFirstTab ? () => onCancel() : () => handlePreviousTabChange(currentTabIndex)}
          disabled={isLastTab && isSubmitLoading}
        >
          {isFirstTab ? "Cancel" : "Previous"}
        </StyledButton>
        <StyledTooltip title={disableNext.status ? disableNext.reason : ""}>
          <StyledButton
            htmlType="button"
            type="primary"
            className="CreateSamplingEventModal__ModalFooter__NextButton"
            onClick={isLastTab ? () => onSubmit() : () => handleNextTabChange(currentTabIndex)}
            disabled={disableNext.status || isSubmitLoading}
            loading={isLastTab && isSubmitLoading}
          >
            {isLastTab ? "Submit" : "Next"}
            {!isLastTab && <NextArrowIcon />}
          </StyledButton>
        </StyledTooltip>
      </div>
    );
  }

  return (
    <div className="CreateSamplingEventModal__Footer">
      <StyledButton htmlType="button" type="default" onClick={onCancel} disabled={isSubmitLoading}>
        Cancel
      </StyledButton>
      <StyledButton htmlType="button" type="primary" onClick={onSubmit} disabled={disableAddToCalendar || isSubmitLoading} loading={isSubmitLoading}>
        Add to Calendar
      </StyledButton>
    </div>
  );
}

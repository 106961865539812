import React from "react";
import "./SenderInfo.css";

export default function SenderInfo({ CUSTOMER_INFO_FIELDS, customerInfo }) {
  return (
    <div className="SubmissionReceiptModal__SenderInfo">
      <div className="SubmissionReceiptModal__SenderInfo__Title">Sender Details</div>
      <div className="SubmissionReceiptModal__SenderInfo__Body">
        {CUSTOMER_INFO_FIELDS.map(({ label, key }) => (
          <div key={key} className="SenderInfo__Field">
            <span className="SenderInfo__Field__Label">{label}</span>
            <span className="SenderInfo__Field__Value">{customerInfo[key] || "-"}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

import React, { useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import BuilderPreview from "../Preview/BuilderPreview";
import COABuilderCenterContent from "./CustomCOABuilderCenter/COABuilderCenterContent";
import EditPreviewButton from "./EditPreviewButton";
import "./TemplateBuilderCenter.css";
import TemplateBuilderCenterContent from "./TemplateBuilderCenter/TemplateBuilderCenterContent";

export default function TemplateBuilderCenter(props) {
  const {
    previewTemplate,
    handlePreviewTemplate,
    dataFields,
    handleSectionSelect,
    sections,
    sectionsFixed,
    sectionRefs,
    setSectionInputs,
    handleBuilderFile,
    handleTemplateDataFieldsChange,
    inputsPayload,
    isCustomizingCoa,
    selectedTemplate,
    selectedReports,
    handleCreateSection,
    handleSectionDelete,
    uniqueTests,
    enableTests,
  } = props;
  const [loadingPreview, setLoadingPreview] = useState(false);
  return (
    <div className="TemplateBuilder__CenterContainer">
      <EditPreviewButton
        previewTemplate={previewTemplate}
        handlePreviewTemplate={handlePreviewTemplate}
        loadingPreview={loadingPreview}
        setLoadingPreview={setLoadingPreview}
      />
      {previewTemplate && (
      <BuilderPreview
        inputs={inputsPayload}
        alignLeft
        isCustomizingCoa={isCustomizingCoa}
        loadingPreview={loadingPreview}
        setLoadingPreview={setLoadingPreview}
        uniqueTests={uniqueTests}
        enableTests={enableTests}
      />
      )}
      <div className={!previewTemplate ? "templateBuilderCenter" : "builderSectionHide"}>
        <Scrollbar>
          {isCustomizingCoa ? (
            <COABuilderCenterContent
              sections={sections}
              setSectionInputs={setSectionInputs}
              handleTemplateDataFieldsChange={handleTemplateDataFieldsChange}
              selectedTemplate={selectedTemplate}
              selectedReports={selectedReports}
              handleBuilderFile={handleBuilderFile}
              handleCreateSection={handleCreateSection}
              handleSectionDelete={handleSectionDelete}
              uniqueTests={uniqueTests}
              enableTests={enableTests}
            />
          ) : (
            <TemplateBuilderCenterContent
              dataFields={dataFields}
              handleSectionSelect={handleSectionSelect}
              sections={sections}
              sectionsFixed={sectionsFixed}
              sectionRefs={sectionRefs}
              setSectionInputs={setSectionInputs}
              handleBuilderFile={handleBuilderFile}
              handleTemplateDataFieldsChange={handleTemplateDataFieldsChange}
            />
          )}
        </Scrollbar>
      </div>
    </div>

  );
}

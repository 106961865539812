import React, { useState } from "react";
import { toast } from "react-toastify";
import SinglePinMapView from "./ReportViewMap/SinglePinMapView";
import ReportViewTitle from "./ReportViewTitle/ReportViewTitle";
import ReportResult from "./ReportViewResult/ReportResult";
import EditSpec from "../EditSpecs/EditSpec";
import {
  parseSpecDetail, formatSpec, handleCheckUnit, checkIfSpecsInvalid,
} from "../EditSpecs/Helper";
import { getEnvSpecsEditData, postEnvSpecsEditData } from "../../../../../actions/envReports";
import "./ReportView.css";
import { ReactComponent as MapIcon } from "../../../../../assets/images/environment/foldedMap.svg";

export default function ReportView({
  pinMap,
  toggleReportView,
  selectedLocationID,
  setSelectedLocationID,
  setDisplayTooltipLocationID,
  imageSrc,
  handlePreviewFile,
  handleAddSpecsButton,
  linkPatternFields,
  index0,
}) {
  const pin = pinMap.get(selectedLocationID);
  const [imagePath, setImagePath] = useState("");
  const [curReportInfo, setCurReportInfo] = useState(null);
  const [showSpecManger, setShowSpecManger] = useState(false);
  const [dataOfSpecEditing, setDataOfSpecEditing] = useState([]);
  const [loadingSpecs, setLoadingSpecs] = useState(false);

  /**
 * Edit specifications based on the show parameter.
 * @param {boolean} show - Flag to determine whether to open or close the spec editor.
 */
  const editSpecs = async (show) => {
    if (show) { // open
      setLoadingSpecs(true);
      const payload = { location_id: selectedLocationID };
      const response = await getEnvSpecsEditData(payload);
      if (!response.success) {
        toast.error("Failed to get specs for this swab!");
      } else {
        const data = response.result;
        data.forEach((spec) => {
          const specDetail = parseSpecDetail(spec);
          spec.test_type = specDetail.test_type;
          spec.specs = specDetail.specs;
        });
        setDataOfSpecEditing(data);
        setLoadingSpecs(false);
        setShowSpecManger(true);
      }
    } else { // close
      setShowSpecManger(false);
      setDataOfSpecEditing([]);
    }
  };

  /**
 * Function to handle saving specs.
 * @returns {boolean} - Returns true if the specs are successfully saved, false otherwise.
 */
  const handleSaveSpec = async () => {
    let specList = formatSpec(dataOfSpecEditing);
    if (checkIfSpecsInvalid(dataOfSpecEditing)) {
      toast.error("Invalid or missing value(s).");
      return false;
    }
    specList = handleCheckUnit(specList);

    const payload = {
      location_id: selectedLocationID,
      specs: specList,
    };

    let result = false;
    const response = await postEnvSpecsEditData(payload);
    if (response && response.success) {
      toast.success("Spec successfully edited.");
      result = true;
      editSpecs(false);
    } else {
      toast.error("Failed to edit spec.");
    }
    return result;
  };

  const handleDataChange = (specList) => {
    setDataOfSpecEditing([...specList]);
  };

  return (
    <div className={showSpecManger ? "env-analytics-report-view-container-spec-manager" : "env-analytics-report-view-container"}>
      {showSpecManger && (
      <EditSpec
        dataOfSpecEditing={dataOfSpecEditing}
        handleDataChange={handleDataChange}
        handleSaveSpec={handleSaveSpec}
        linkPatternFields={linkPatternFields}
        editSpecs={editSpecs}
        index0={index0}
        pin={pin}
      />
      )}
      {!showSpecManger && (
      <>
        <ReportViewTitle
          pin={pin}
          imagePath={imagePath}
          curReportInfo={curReportInfo}
          // setImagePath={setImagePath}
        />
        <SinglePinMapView
          imageSrc={imageSrc}
          pin={pin}
        />
        <ReportResult
          pin={pin}
          pinMap={pinMap}
          handleAddSpecsButton={handleAddSpecsButton}
          handlePreviewFile={handlePreviewFile}
          setImagePath={setImagePath}
          setCurReportInfo={setCurReportInfo}
          editSpecs={editSpecs}
          loadingSpecs={loadingSpecs}
        />
        <button
          className="env-report-view-map-button"
          type="button"
          onClick={() => {
            setSelectedLocationID("");
            setDisplayTooltipLocationID("");
            toggleReportView();
          }}
        >
          <MapIcon width={20} />
        </button>
      </>
      )}
    </div>
  );
}

import React, { useMemo } from "react";

import { limitTextLength, MAX_PDF_CHAR_SAMPLE_ID, splitSamples } from "../../utils";

import "./SamplesList.css";

export default function SamplesList({ submission }) {
  const samples = useMemo(() => {
    if (!submission) return [];
    if (submission.samples_list.length === 0) return [];

    return submission.samples_list.reduce((acc, sample, index) => {
      if (sample?.num_composite_samples && sample?.num_composite_samples > 1) {
        acc.push(...splitSamples(sample, index + 1));
      } else {
        acc.push({ ...sample, sample_id_counter: index + 1 });
      }
      return acc;
    }, []);
  }, [submission]);

  return (
    <div className="SubmissionReceiptModal__SamplesList">
      {samples.map((sample, i) => (
        <div key={i} className="SubmissionReceiptModal__Sample">
          <p className="SubmissionReceiptModal__SampleText">
            <span className="SubmissionReceiptModal__SampleText--Title">
              {`${sample.sample_id_counter}. Sample ID: `}
            </span>
            <span className="SubmissionReceiptModal__SampleText--Data">{limitTextLength(sample.sample_id, MAX_PDF_CHAR_SAMPLE_ID)}</span>
          </p>
          {sample?.sub_sample_id && (
            <p className="SubmissionReceiptModal__SampleText">
              <span className="SubmissionReceiptModal__SampleText--Title">Sub Sample ID:&nbsp;</span>
              <span className="SubmissionReceiptModal__SampleText--Data">{limitTextLength(sample?.sub_sample_id, MAX_PDF_CHAR_SAMPLE_ID)}</span>
            </p>
          )}
          {/* <p className="SubmissionReceiptModal__SampleText">
              <span className="SubmissionReceiptModal__SampleText--Title">Submission date:&nbsp;</span>
              <span className="SubmissionReceiptModal__SampleText--Data">{getFormattedDetailsDate(submission.submit_date)}</span>
            </p>
            <p className="SubmissionReceiptModal__SampleText">
              <span className="SubmissionReceiptModal__SampleText--Title">Submission name:&nbsp;</span>
              <span className="SubmissionReceiptModal__SampleText--Data">{submission.submit_name ? submission.submit_name : ""}</span>
            </p>
            <p className="SubmissionReceiptModal__SampleText">
              <span className="SubmissionReceiptModal__SampleText--Title">PO:&nbsp;</span>
              <span className="SubmissionReceiptModal__SampleText--Data">{sample.po}</span>
            </p> */}
        </div>
      ))}
    </div>
  );
}

import React, { useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { motion } from "framer-motion/dist/framer-motion";
import "./Pin.css";
import PinSimpleToolTip from "./PinSimpleToolTip";

import {
  PINS_SPEC_STYLE_MAP,
} from "../Constant";

import { ReactComponent as PinIcon } from "../../../../../assets/images/environment/pinIconSVG.svg";
import closeIcon from "../../../../../assets/images/environment/deleteIconMidnight.png";

const calculateStyle = (imageInfo, visibleAreaInfo, pin, hideSwabNumber) => {
  const style = {};
  const {
    x: imageX, y: imageY, width: imageWidth, height: imageHeight,
  } = imageInfo;
  const {
    x: visibleAreaX, y: visibleAreaY, width: visibleAreaWidth, height: visibleAreaHeight,
  } = visibleAreaInfo;
  let pinLeft; let
    pinTop;
  if (pin.x_coordinate !== null) {
    [pinLeft, pinTop] = [pin.x_coordinate, pin.y_coordinate];
  } else {
    pinLeft = pin.quadrant === 0 || pin.quadrant === 3 ? 0.25 : 0.75;
    pinTop = pin.quadrant === 0 || pin.quadrant === 1 ? 0.25 : 0.75;
  }
  // console.log("hideSwabNumber", hideSwabNumber);
  const transform = hideSwabNumber
    ? "translate(-50%, 50%)" // want the center of pin to be at position
    : "translate(-50%, -6px)"; // want the bottom center of pin to be at position, -6px to account for the arrow

  const left = imageX + imageWidth * pinLeft;
  const top = imageY + imageHeight * pinTop;
  const bottom = window.innerHeight - top;

  if (left > (visibleAreaX + visibleAreaWidth)
      || left < visibleAreaX
      || top > (visibleAreaY + visibleAreaHeight)
      || top < visibleAreaY) {
    style.display = "none";
  } else {
    style.left = `${left}px`;
    style.bottom = `${bottom}px`;
    style.display = "flex";
    style.transform = transform;
  }
  return { style, position: { left, top, bottom } };
};

const UndraggablePin = (props) => {
  const {
    listViewDisplay,
    locationID,
    pin,
    index0,
    displayTooltipLocationID,
    imageRef,
    visibleArea,
    animationWhenMount,
    updatePinInfo,
    endPinningSwab,
    endRelocatingSwab,
    isHightLighted,
    hideSwabNumber,
    handleSelectQuadrant,
    setSelectedLocationID,
    setDisplayTooltipLocationID,
    pinRelocatingLocationID,
    setPinRelocatingLocationID,
    isClickingQuadrant,
    clickQuadrant,
  } = props;

  // console.log(endPinningSwab);

  let style = {};
  let position = {};
  if (imageRef && visibleArea) {
    const { style: calcStyle, position: calcPosition } = calculateStyle(imageRef.getBoundingClientRect(), visibleArea.getBoundingClientRect(), pin, hideSwabNumber);
    style = { ...calcStyle };
    position = calcPosition;
  } else {
    style.display = "none";
  }
  return (
    <>
      {hideSwabNumber && displayTooltipLocationID === locationID && style.display !== "none" && (
      <PinSimpleToolTip
        pin={pin}
        index0={index0}
        position={position}
      />
      )}
      <div
      // className={pin.x_coordinate ? "" : "bounceanimation"}
        className="pin-location-container"
        style={{ ...style, zIndex: displayTooltipLocationID === locationID || isHightLighted ? 2 : 1 }}
      >
        <Pin
          pin={pin}
          index0={index0}
          locationID={locationID}
          isHightLighted={isHightLighted}
          listViewDisplay={listViewDisplay}
          hideSwabNumber={hideSwabNumber}
          animationWhenMount={animationWhenMount}
          displayTooltipLocationID={displayTooltipLocationID}
          endPinningSwab={endPinningSwab}
          endRelocatingSwab={endRelocatingSwab}
          handleSelectQuadrant={handleSelectQuadrant}
          setSelectedLocationID={setSelectedLocationID}
          setDisplayTooltipLocationID={setDisplayTooltipLocationID}
          updatePinInfo={updatePinInfo}
          pinRelocatingLocationID={pinRelocatingLocationID}
          setPinRelocatingLocationID={setPinRelocatingLocationID}
          clickQuadrant={clickQuadrant}
          isClickingQuadrant={isClickingQuadrant}
        />
      </div>
    </>
  );
};

function Pin({
  listViewDisplay,
  pin,
  index0,
  locationID,
  // isHightLighted,
  hideSwabNumber,
  animationWhenMount,
  displayTooltipLocationID,
  handleSelectQuadrant,
  setSelectedLocationID,
  endPinningSwab,
  endRelocatingSwab,
  setDisplayTooltipLocationID,
  updatePinInfo,
  pinRelocatingLocationID,
  setPinRelocatingLocationID,
  isClickingQuadrant,
  clickQuadrant,
}) {
  // console.log(endPinningSwab);
  useEffect(() => {
    // console.log("timer");
    let timer;
    if (animationWhenMount) {
      // console.log(locationID, animationWhenMount);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        endPinningSwab();
        endRelocatingSwab();
      },
      0);
    }
    return () => clearTimeout(timer);
  });

  const handleClickOnPin = (e) => {
    // console.log("click on pin");
    // console.log(locationID, pinRelocatingLocationID === locationID);
    e.stopPropagation();
    if (!listViewDisplay) {
      updatePinInfo("toggleDraggable", locationID);
      setPinRelocatingLocationID(pinRelocatingLocationID === locationID ? "" : locationID);
    } else if (locationID) {
      // console.log("select swab number", locationID);
      setDisplayTooltipLocationID(locationID);
      setSelectedLocationID(locationID);
    } else if (!locationID) {
      endPinningSwab();
      handleSelectQuadrant(-1);
    }
  };

  const isRelocating = () => (locationID === pinRelocatingLocationID);
  const pinSpecsFlag = pin.specs_flag.toString();

  const getRGBAColor = (rgbVals, opacity) => `rgba(${rgbVals}, ${opacity})`;

  const {
    rgb_values, color, box_shadow_color, border_color,
  } = PINS_SPEC_STYLE_MAP[pin.specs_flag] ?? PINS_SPEC_STYLE_MAP.invalid;

  const pinColor = !pin.x_coordinate || isRelocating() ? "#26ABE1" : color;
  const boxShadow = !pin.x_coordinate || isRelocating() ? "0px 3px 13px rgb(9 30 66 / 20%)" : `0px 16px 8px ${box_shadow_color}`;
  const isSelected = !pin.x_coordinate || isRelocating() || locationID === displayTooltipLocationID;
  let animationClassName = "animationWhenMount"; // eslint-disable-line

  if (pinSpecsFlag === "1") {
    animationClassName = "animationWhenMount inSpec";
  } else if (pinSpecsFlag === "2") {
    animationClassName = "animationWhenMount outOfSpec";
  }

  /** apply pulse animation if pin is out of spec */
  const pinAnimation = pinSpecsFlag === "2" ? { scale: [1, 1.8, 1] } : {};

  if (hideSwabNumber) {
    // small pin icon when display the complete image
    return (
      <>
        <div
          // className={pinSpecsFlag === "2" ? "highlightPinAnimation" : "pinNoAnimation"}
          className="pinNoAnimation circular-pin-container"
          // onClick={isHightLighted && handleSelectQuadrant ? (e) => {
          //   e.preventDefault();
          //   e.stopPropagation();
          //   setSelectedLocationID(locationID); // ?
          //   handleSelectQuadrant(pin.quadrant);
          // } : null}
          onClick={(e) => {
            handleClickOnPin(e);
          }}
        >
          <motion.div
            className="circular-pin-halo"
            animate={pinAnimation}
            transition={{
              duration: 2,
              ease: "easeInOut",
              repeat: 2,
            }}
            style={{
              backgroundColor: getRGBAColor(rgb_values, 0.3),
            }}
          />
          <div
            className="circular-pin-solid-circle"
            style={{
              backgroundColor: getRGBAColor(rgb_values, 0.8),
            }}
          >
            <PinIcon width={9} height={9} fill="white" />
          </div>
        </div>
      </>
    );
  }
  return (
    <OutsideClickHandler onOutsideClick={animationWhenMount ? () => { endPinningSwab(); } : () => {}}>
      <div className="pin-container">
        <div
          // className={!animationWhenMount ? "" : animationClassName} // remove animations for now
          className="swab-number-pin-border"
          style={{
            border: `0.5px solid ${border_color}`,
            backgroundColor: border_color,
          }}
        >
          <div
            className="swab-number-pin-background"
            style={{
              backgroundColor: isSelected ? pinColor : "#FDFDFD",
              boxShadow: displayTooltipLocationID === locationID ? "none" : boxShadow,
            }}
            onClick={(e) => {
              handleClickOnPin(e);
              if (isClickingQuadrant) { // toggle isClickingQuadrant
                clickQuadrant();
              }
            }}
          >
            <div
              className="circular-pin-solid-circle"
              style={{
                backgroundColor: isSelected ? "#FDFDFD" : pinColor,
              }}
            >
              <PinIcon
                width={9}
                height={9}
                fill={isSelected ? pinColor : "white"}
              />
            </div>
            <div
              className="swabNumber"
              style={{
                color: isSelected ? "#FDFDFD" : pinColor,
              }}
            >
              {pin[index0]}

            </div>
            {!pin.x_coordinate && (
            <>
              <div
                className="unpinned-pin-close-icon-background"
                onClick={() => {}}
              />
              <img
                alt="closeIcon"
                className="unpinned-pin-close-icon"
                src={closeIcon}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  endPinningSwab();
                  handleSelectQuadrant(-1);
                }}
              />
            </>
            )}
          </div>
          <div
            className="swab-number-pin-arrow"
            style={{
              borderTop: isSelected ? `7px solid ${pinColor}` : "6px solid #FDFDFD",
              boxShadow: displayTooltipLocationID === locationID ? "none" : boxShadow,
            }}
          />
          {/* {(locationID === undefined || pin.draggable) && <img
            alt="handIcon"
            src={handIcon}
            style={{
              width: "20px",
              height: "25px",
              position: "absolute",
              marginLeft: "50%",
              transform: "translate(-1px, -12px)",
            }}
          />} */}
        </div>
      </div>
    </OutsideClickHandler>
  );
}

const mapStateHasChanged = (prevState, newState) => prevState.scale !== newState.scale
|| prevState.positionX !== newState.positionX
|| prevState.positionY !== newState.positionY
|| prevState.height !== newState.height
|| prevState.width !== newState.width
|| prevState.left !== newState.left
|| prevState.top !== newState.top;

const shouldNotUpdate = (prevProps, nextProps) => {
  if ((prevProps.displayTooltipLocationID !== prevProps.locationID && nextProps.displayTooltipLocationID === nextProps.locationID) // if pin is selected
  || (prevProps.displayTooltipLocationID === prevProps.locationID && nextProps.displayTooltipLocationID !== nextProps.locationID) // if pin is deselected
  || (prevProps.pinRelocatingLocationID !== prevProps.locationID && nextProps.pinRelocatingLocationID === nextProps.locationID) // if pin is selected (full map mode)
  || (prevProps.pinRelocatingLocationID === prevProps.locationID && nextProps.pinRelocatingLocationID !== nextProps.locationID) // if pin is deselected (full map mode)
  || (prevProps.pin.x_coordinate !== nextProps.pin.x_coordinate || prevProps.pin.y_coordinate !== nextProps.pin.y_coordinate) // if pin coordinates change (full map mode)
  || (mapStateHasChanged(prevProps.mapState, nextProps.mapState)) // if pin location changes because of zoom/panning
  || (prevProps.hideSwabNumber !== nextProps.hideSwabNumber) // if pin type changes
  || (prevProps.listViewDisplay !== nextProps.listViewDisplay) // if listviewdisplay changes
  ) {
    return false;
  }
  return true;
};

export default React.memo(UndraggablePin, shouldNotUpdate);

import React from "react";
import Modal from "antd/es/modal/Modal";
import "./ConfirmModal.css";

export default class ConfirmExitModal extends React.Component {
  render() {
    const {
      headerText,
      bodyText,
      buttonText,
      buttonFunctions,
    } = this.props;

    return (
      <Modal
        open
        maskClosable={false}
        closable={false}
        centered
        footer={null}
        width="448px"
        wrapClassName="ConfirmModal"
        className="ConfirmModal"
      >
        <div className="envSpecManagerConfirmDel">
          <div className="envSpecManagerConfirmDelTitle">{headerText}</div>
          <div className="envSpecManagerConfirmDelTitleMessage">{bodyText}</div>
          <div className="envSpecManagerConfirmDelBtn">
            <button type="button" onClick={buttonFunctions[0]} className="envSpecManagerConfirmDelBtnCancel">{buttonText[0]}</button>
            <button type="button" onClick={buttonFunctions[1]} className="envSpecManagerConfirmDelBtnDelete">{buttonText[1]}</button>
          </div>
        </div>
      </Modal>
    );
  }
}

import React from "react";
import {
  Document, Page, View, StyleSheet, Font,
} from "@react-pdf/renderer";
import PDFHeader from "./PDFHeader";
import PDFBody from "./PDFBody";
import PDFSignature from "./PDFSignature";
// import PDFFooterCenter from "./Components/PDFFooterCenter";
// import PDFHeaderCenter from "./Components/PDFHeaderCenter";
import RobotoMedium from "../../../../../assets/fonts/roboto/Roboto-Medium.ttf";
import Roboto from "../../../../../assets/fonts/roboto/Roboto-Regular.ttf";
import PDFFooter from "./PDFFooter";

export default function PDF(props) {
  Font.register({ family: "Roboto-400", src: Roboto });
  Font.register({ family: "Roboto-500", src: RobotoMedium });
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      padding: "16 16 48 16",
      color: "#385387",
      fontFamily: "Roboto-500",
      fontSize: "11",
      position: "relative",
    },
    section: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "24",
      position: "relative",
    },
  });

  if (!props) {
    return <Document />;
  }

  const {
    inputs,
    alignLeft,
    isCustomizingCoa,
    isTemplatePdf,
    uniqueTests,
    enableTests,
  } = props;

  const values = Object.values(inputs).filter((input) => input.length > 0);
  const hasData = values.length > 0;
  if (!props || !inputs || !hasData) {
    return <Document />;
  }

  const {
    sections,
    company_logo,
    company_name,
    company_address,
    phone_number,
    fax_number,
    signed_by,
    designation,
    logo_preview_file,
    signature_preview_file,
    statement_inputs,
  } = inputs;

  let { samples } = inputs;
  if (!samples) {
    samples = new Array(4).fill({
      test: "",
      test_spec: "",
      test_result: "",
      test_spec_flag: "",
      test_method: "",
    });
  }

  const header = {
    logo: company_logo, name: company_name, address: company_address, phone: phone_number, fax: fax_number, logo_preview_file,
  };
  const body = {
    sections,
    samples,
    uniqueTests,
    enableTests,
  };
  const footer = {
    signedBy: signed_by, designation, signature_preview_file, statement_inputs,
  };
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <PDFHeader header={header} alignLeft={alignLeft} />
        <View style={styles.section}>
          <PDFBody body={body} isCustomizingCoa={isCustomizingCoa} isTemplatePdf={isTemplatePdf} />
          {(signed_by || designation || signature_preview_file || statement_inputs.length > 1) ? (
            <PDFSignature footer={footer} alignLeft={alignLeft} />
          ) : null}
        </View>
        <PDFFooter companyName={company_name} />
      </Page>
    </Document>
  );
}

import React from "react";
import "../Product.css";
import "./ReportSearch.css";

import whiteMagnifyIcon from "../../../assets/images/white-magnifying-glass.png";
import DatePicker from "../../Common/DatePicker/DatePicker";

class ReportSearch extends React.Component {
  state = {
    dateSelected: "",
    prevDateSelected: "",
    searchValue: "",
  };

  displayDateSelected = (dateSelected) => {
    this.setState({ dateSelected: dateSelected === "" ? "" : dateSelected });
  }

  handleClearSearchByDate = (e) => {
    const { handleFilterByDate } = this.props;
    e.stopPropagation();
    handleFilterByDate("", "");
    this.setState({ dateSelected: "", prevDateSelected: "" });
  }

  handleSearchChange = (e, buttonClicked) => {
    const { handleSearch } = this.props;
    const { searchValue } = this.state;
    let value = !buttonClicked ? e.target.value : searchValue;

    this.setState({
      searchValue: value.trim() === "" ? "" : value,
    });
    value = value.trim();
    // 1. searchValue become empty 2. enter clicked 3. search icon clicked
    if ((value === "" && searchValue !== "")
        || e.key === "Enter"
        || buttonClicked) {
      handleSearch(value);
    }
  };

  render() {
    const {
      flag,
      handleFilterByDate,
      getReportDates,
    } = this.props;
    const {
      dateSelected, prevDateSelected, searchValue,
    } = this.state;
    let formControlId = "reportSearchInput";
    if (flag === "templates") {
      formControlId = "reportSearchInput2";
    } else if (flag === "analytics") {
      formControlId = "reportSearchInput3";
    }

    return (
      <div
        className="ReportSearchContainer"
        style={{ height: flag === "reports" || flag === "reports_spec" ? "40px" : "80px" }}
      >
        <div className={flag === "analytics" ? "searchFormAnalytics" : "searchForm"}>
          <div className="ReportSearch__SearchBar" id="productReportSearchInputBar">
            <input
              id={formControlId}
              className="searchInput"
              placeholder="Search"
              onChange={(event) => {
                this.handleSearchChange(event, false);
              }}
              value={searchValue}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  this.handleSearchChange(event, false);
                }
              }}
            />
            <div
              className="productSearchBtn2"
              onClick={(event) => {
                this.handleSearchChange(event, true);
              }}
            >
              <img
                className="productSearchBtn2__Img-whiteMagnifyIcon"
                src={whiteMagnifyIcon}
                alt="whiteMagnifyIcon"
              />

            </div>
          </div>
        </div>
        {flag === "reports" && (
          <>
            <div className="productReportSearchDivBetweenSearchBoxandCalender" />
            <div className="productReportSearchCalenderContainer">
              <DatePicker
                handleSearchDate={handleFilterByDate}
                saveDateHistory={this.saveDateHistory}
                getReportDates={getReportDates}
                value={dateSelected}
                onChange={this.displayDateSelected}
                prevValue={prevDateSelected}
                setPrevValue={(val) => this.setState({ prevDateSelected: val })}
                searchOnClear
                datePickerDisplayProps={{
                  iconTooltipTitle: "Sort by date",
                }}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ReportSearch;

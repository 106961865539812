import React from "react";
import moment from "moment";

export default function DateBlock(props) {
  const {
    date, active, index, updateActiveDate, disabled,
  } = props;
  const momentDate = moment(new Date(date));
  const year = momentDate.format("YY");
  const month = momentDate.format("MMM");
  const day = momentDate.format("DD");

  let containerClasses = "dateBlockContainer";
  if (active) {
    containerClasses += " dateBlockContainerActive";
  } else if (disabled) {
    containerClasses += " disabled";
  }

  return (
    <div className={containerClasses} onClick={() => updateActiveDate(date, index)}>
      <div className={active ? "dateBlockDayContainer dateBlockDayContainerActive" : "dateBlockDayContainer"}>
        {(momentDate.isValid()) ? day : date}
      </div>

      <div className={active ? "dateBlockMonthYearContainer dateBlockMonthYearContainerActive" : "dateBlockMonthYearContainer"}>
        {(momentDate.isValid()) ? month : ""}
        {" "}
        {(momentDate.isValid()) ? year : ""}
      </div>
    </div>
  );
}

import React, { useState, useMemo } from "react";

import { Divider, Popover } from "antd";
import Scrollbar from "react-scrollbars-custom";

import StyledButton from "./StyledButton";
import StyledCheckbox from "./StyledCheckbox";

import "./CountriesCheckboxList.css";

export default function CountriesCheckboxList({
  children, values: countries, disabledItems = [], defaultCheckedCountries = [],
  getIsOpen, onClickSave, selectedCountries, setSelectedCountries,
}) {
  const [open, setOpen] = useState(false);

  /**
   * Set status for disabling country
   * @returns {object} Object of countries as key and true/false for disabled status
   */
  const countriesDisabledStatus = useMemo(() => {
    const disabledCountries = disabledItems.map((item) => item.toLowerCase());
    return countries.reduce((obj, country) => {
      obj[country] = disabledCountries.includes(country.toLowerCase()); // true if disabled}
      return obj;
    }, {});
  }, [disabledItems, countries]);

  /**
   * Get countries which are not disabled
   * @returns {Set} List of countries those are not disabled
   */
  const getNonDisabledCountriesList = () => Array.from(new Set([
    ...defaultCheckedCountries,
    ...Object.keys(countriesDisabledStatus).filter((country) => !countriesDisabledStatus[country]),
  ]));

  /**
   * Handle check event for checkbox
   * @param {*} country
   */
  function handleCheck(country) {
    if (!selectedCountries.includes(country)) {
      setSelectedCountries((state) => Array.from(new Set([...state, ...defaultCheckedCountries, country])));
    } else {
      const updatedCountries = [...selectedCountries];
      if (updatedCountries.includes(country)) {
        const idx = updatedCountries.indexOf(country);
        updatedCountries.splice(idx, 1);
        setSelectedCountries(updatedCountries);
      }
    }
  }

  /**
   * Handle select all checkbox event
   * @param {*} e event
   */
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const nonDisabledCountries = getNonDisabledCountriesList();
      setSelectedCountries(Array.from(nonDisabledCountries));
    } else {
      setSelectedCountries([...defaultCheckedCountries]);
    }
  };

  /**
   * Save button click event
   */
  const handleSave = () => {
    if (onClickSave) onClickSave(selectedCountries);
    setOpen(false);
  };

  /**
   * Handle event for open/close list
   * @param {boolean} newOpen is open
   */
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const CountryList = (
    <div className="CountriesCheckboxList__Container">
      <StyledCheckbox
        checked={getNonDisabledCountriesList().length === selectedCountries.length}
        onChange={handleSelectAll}
      >
        Select all
      </StyledCheckbox>
      <Divider className="CountriesCheckboxList__Divider" />
      <Scrollbar className="CountriesCheckboxList__Scrollbar">
        <div className="CountriesCheckboxList__List">
          {countries.map((country, i) => (
            <StyledCheckbox
              key={i}
              checked={selectedCountries.includes(country)}
              onChange={() => handleCheck(country)}
              disabled={countriesDisabledStatus[country]}
              containerClassName="CountriesCheckboxList__Checkbox"
            >
              {country}
            </StyledCheckbox>
          ))}
        </div>
      </Scrollbar>
      <div className="CountriesCheckboxList__Footer">
        <StyledButton
          type="primary"
          size="small"
          onClick={handleSave}
          disabled={selectedCountries.length === 0}
        >
          Save
        </StyledButton>
      </div>
    </div>
  );

  return (
    <Popover
      content={CountryList}
      trigger="click"
      placement="bottomLeft"
      arrow={false}
      afterOpenChange={getIsOpen}
      open={open}
      onOpenChange={handleOpenChange}
    >
      {children}
    </Popover>
  );
}

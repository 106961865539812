import React from "react";
import Modal from "antd/es/modal/Modal";
import "./ConfirmModal.css";
import ButtonWithLoading from "../../../../Common/ButtonWithLoading";

export default class ConfirmExitModal extends React.Component {
  render() {
    const {
      headerText,
      bodyText,
      buttonText,
      buttonFunctions,
      saveLoading,
    } = this.props;

    return (
      <Modal
        open
        maskClosable={false}
        closable={false}
        centered
        footer={null}
        width="448px"
        wrapClassName="ConfirmModal"
        className="ConfirmModal"
      >
        <div className="envSpecManagerConfirmDel">
          <div className="envSpecManagerConfirmDelTitle">{headerText}</div>
          <div className="envSpecManagerConfirmDelTitleMessage">{bodyText}</div>
          <div className="envSpecManagerConfirmDelBtn">
            <button type="button" onClick={buttonFunctions[0]} className="envSpecManagerConfirmDelBtnCancel">{buttonText[0]}</button>
            <ButtonWithLoading
              defaultText={buttonText[1]}
              loadingText={buttonText[1]}
              className="envSpecManagerConfirmSaveBtn"
              onClick={buttonFunctions[1]}
              disable={saveLoading}
              isLoading={saveLoading}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import DragClose from "../../../../../../assets/images/product/template/DragClose.png";
import "./Draggable.css";
import "./SectionInputDraggable.css";
import TemplateBuilderInput from "../Center/TemplateBuilderCenter/Inputs/TemplateBuilderInput";
import DragHandle from "./DragHandle";

export default function SectionInputDraggable(props) {
  const {
    deleteInput = () => { },
    index,
    input,
    setTextBoxRef,
  } = props;

  const [showCloseIcon, setShowCloseIcon] = useState(false);

  const isDataField = input.flag !== "0";

  return (
    <Draggable key={input.id} draggableId={input.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps} //eslint-disable-line
          className={`SectionInputDraggable${input.type === "largeTextBox" ? "--alignStart" : ""}`}
          onFocus={() => setShowCloseIcon(true)}
          onBlur={() => setShowCloseIcon(false)}
          onMouseEnter={() => setShowCloseIcon(true)}
          onMouseLeave={() => setShowCloseIcon(false)}
        >
          <DragHandle dragHandleProps={provided.dragHandleProps} />
          <TemplateBuilderInput
            readOnly={isDataField}
            disabled={isDataField}
            // value={isDataField ? input.fieldName : undefined}
            defaultValue={isDataField ? input.fieldName : input.defaultValue}
            placeholder={!isDataField ? "Enter Text" : undefined}
            maxLength={isDataField ? "250" : "500"}
            setRef={!isDataField ? (inputElem) => setTextBoxRef(index, inputElem) : undefined}
            isTextArea={input.type === "largeTextBox"}
            inputClassName={isDataField ? "SectionInput__Datafield" : ""}
            id={`TemplateBuilderInput_${input.id}`}
          />
          {showCloseIcon && (
            <img
              src={DragClose}
              onClick={() => deleteInput(index)}
              alt="close icon"
              className={`Draggable__CloseIcon${input.type === "largeTextBox" ? "--largeInput" : ""}`}
            />
          )}
        </div>
      )}
    </Draggable>
  );
}

/* eslint-disable import/prefer-default-export */
import inProcessIcon from "../../../assets/images/common/spec-flag-icons/InProcessIcon.svg";
import inSpecIcon from "../../../assets/images/common/spec-flag-icons/InSpecIcon.svg";
import outOfSpecIcon from "../../../assets/images/common/spec-flag-icons/OutOfSpecIcon.svg";
import incompleteIcon from "../../../assets/images/common/spec-flag-icons/IncompleteIcon.svg";

export const SPEC_FLAGS_MAP = {
  invalid: {
    label: "-",
    color: "#19305A",
    icon: incompleteIcon,
  },
  "-3": {
    label: "Deleted by Lab",
    color: "#E63559",
    icon: outOfSpecIcon,
  },
  0: {
    label: "In Process",
    color: "#E1B226",
    icon: inProcessIcon,
  },
  1: {
    label: "In Spec",
    color: "#1EC04A",
    icon: inSpecIcon,
  },
  2: {
    label: "Out of Spec",
    color: "#E63559",
    icon: outOfSpecIcon,
  },
  3: {
    label: "Spec Incomplete",
    altLabel: "View Report",
    color: "#6C7E8E",
    icon: incompleteIcon,
  },
  4: {
    label: "In Process (Retest)",
    color: "#E1B226",
    icon: inProcessIcon,
  },
  5: {
    label: "In Spec (Retest)",
    color: "#1EC04A",
    icon: inSpecIcon,
  },
  6: {
    label: "Out of Spec (Retest)",
    color: "#E63559",
    icon: outOfSpecIcon,
  },
};

import React, { useEffect, useState } from "react";
import {
  Form,
  Radio,
  Select,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { partition } from "lodash";
import MyForm from "../../MyForm";
import FormButtons from "./ReusableComponents/FormButtons";
import useHandleStepChange from "../useHandleStepChange";
import "./LinkReportForm.css";

export default function LinkReportForm({
  initialValues, fieldsData, onNextButton, onBackButton, onFinishFailed, stepChangeInProgress,
  handleFormStepChange, isExistingCustomer,
}) {
  const FORM_NAME = "link_report_form";
  const [form] = useForm();

  /** Hook that handles form saving + step change when step is clicked */
  useHandleStepChange(FORM_NAME, form, stepChangeInProgress, handleFormStepChange);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    layout: "vertical",
  };

  /** Populate dropdown with fields from previous step */
  useEffect(() => {
    const [uneditableFields, editableFieldsEntries] = partition(Object.entries(fieldsData ?? {}), ([key]) => key !== "editable_fields");
    const options = uneditableFields.filter(([json]) => json !== "sample_type" && json !== "date").map(([json, title]) => ({ value: json, label: title }));
    if (editableFieldsEntries?.length && editableFieldsEntries[0][1]) {
      const editableFieldsList = editableFieldsEntries[0][1];
      options.push(...editableFieldsList.map(({ json_field, title_field }) => ({ value: json_field, label: title_field })));
    }

    /** Check if product_link fields are valid fields (user may have removed field in previous step) */
    if (initialValues.product_link) {
      const filteredProductLink = initialValues.product_link.filter((json_field) => options.filter(({ value }) => value === json_field).length); // filter out fields in product_link that are not in options
      form.setFieldValue("product_link", filteredProductLink.length > 0 ? filteredProductLink : undefined); // set field to undefined if filteredProductLink is empty
    }

    setDropdownOptions(options);
  }, [fieldsData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MyForm
      {...layout}// eslint-disable-line react/jsx-props-no-spreading
      form={form}
      initialValues={initialValues}
      requiredMark={false}
      name={FORM_NAME}
      validateTrigger={["onBlur", "onChange"]}
      onFinish={(values) => onNextButton(FORM_NAME, values)}
      onFinishFailed={onFinishFailed}
      className="OnboardingForm"
    >
      <div>

        <Form.Item
          name="product_link"
          label="Please select the field(s) associated with the specification for your products:"
          rules={[
            {
              required: true, message: "",
            },
          ]}
          className="LinkReportForm__ProductSpec"
        >
          <Select
            mode="multiple"
            allowClear
            options={dropdownOptions}
            placeholder="Select field(s)"
          />
        </Form.Item>
        {!isExistingCustomer && (
        <Form.Item
          name="env_supplies"
          label="Do you need supplies for environment testing?"
          rules={[
            {
              required: true, message: "",
            },
          ]}
        >
          <Radio.Group className="Ant__RadioBtn">
            <Radio value="Yes"> Yes </Radio>
            <Radio value="No"> No </Radio>
          </Radio.Group>
        </Form.Item>
        )}
      </div>
      <FormButtons
        handleBack={() => onBackButton(FORM_NAME, form.getFieldValue())}
      />
    </MyForm>

  );
}

import React from "react";
import moment from "moment";
import { ReactComponent as StatusDotIcon } from "../../../../../../../assets/images/environment/statusPingDot.svg";
import { PINS_SPEC_STYLE_MAP } from "../../../Constant";

export default function DateCard({
  report,
  index,
  activeReportIdx,
  setActiveReportIdx,
}) {
  const { color } = PINS_SPEC_STYLE_MAP[report.specs_flag] ?? PINS_SPEC_STYLE_MAP.invalid;

  return (
    <div
      className={activeReportIdx === index ? "env-report-view-date-card selected-date-card" : "env-report-view-date-card"}
      onClick={() => setActiveReportIdx(index)}
    >
      <div className="date-header">{moment(report.received_date).format("DD MMMM")}</div>
      <div className="test-spec-section">
        <div className="test-section">
          <span className="num-tests-text">{`${report.test_total_counts < 10 ? 0 : ""}${report.test_total_counts}`}</span>
          <span>:</span>
          <span>Tests</span>
        </div>
        <div className="vertical-line" />
        <StatusDotIcon width={16} height={16} fill={color} />
      </div>
    </div>
  );
}

import React from "react";
import { Dropdown } from "antd";
import DocOptionRenameIcon from "../../../assets/images/DocOptionRenameIcon.png";
import DocOptionDeleteIcon from "../../../assets/images/DocOptionDeleteIcon.png";
import DocOptionDownloadIcon from "../../../assets/images/DocOptionDownloadIcon.png";
import OptionIcon from "../../../assets/images/DocFileOptionIcon.png";
import "./DocumentsMenuOption.css";

export default function DocumentsMenuOption(props) {
  const {
    handleRenameFile,
    handleDownloadFile,
    handleConfirmDelete,
    handleFileOptionClick,
    onOpenChange,
  } = props;

  const items = [
    {
      label:
  <div className="DocumentsMenu__OptionContainer" onClick={handleRenameFile}>
    <img className="docOptionIcon" src={DocOptionRenameIcon} alt="" />
    <div
      className="docOptionText"
    >
      Rename
    </div>
  </div>,
      key: "0",
    },
    {
      label:
  <div className="DocumentsMenu__OptionContainer" onClick={handleDownloadFile}>
    <img className="docOptionIcon" src={DocOptionDownloadIcon} alt="" />
    <div
      className="docOptionText"
    >
      Download

    </div>
  </div>,
      key: "1",
    },
    {
      label:
  <div className="DocumentsMenu__OptionContainer" onClick={handleConfirmDelete}>
    <img className="docOptionIcon" src={DocOptionDeleteIcon} alt="" />
    <div
      className="docOptionText"
    >
      Delete

    </div>
  </div>,
      key: "2",
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      onOpenChange={onOpenChange}
    >
      <img
        className="docListOptionIcon"
        onClick={(e) => handleFileOptionClick(e)}
        src={OptionIcon}
        alt=""
      />
    </Dropdown>
  );
}

import React, { useState } from "react";
import FieldInput from "./FieldInput";
import DropdownFieldInput from "./DropdownFieldInput";
import { ReactComponent as CloseIcon } from "../../../../assets/images/signup-invite/close.svg";
import "./CustomFieldInput.css";

export default function CustomFieldInput({
  idx,
  type,
  fieldVal,
  setCustomFieldRef,
  removeCustomField,
  isSendingInvite,
  errorsFromParent,
  setFieldError,
  getFieldError,
}) {
  // const [showInTable, setShowInTable] = useState(false);
  const [DBColumnName, setDBColumnName] = useState("");

  /** Set field's ref in SignUpInvite's infoInputRefs  */
  const setRef = (field, input) => {
    if (input !== null) {
      setCustomFieldRef(idx, field, input, type);
    }
  };

  /** Set field error for custom field */
  const setCustomFieldError = (field, error) => {
    setFieldError(field, error, type, idx);
  };

  /** Get error value for custom field */
  const getCustomFieldError = (field) => getFieldError(field, type, idx);

  /** Convert display name to DB Column value. Replace special characters, spaces with an underscore, convert to lower case */
  const converToDBColumnName = (str) => {
    let convertedDBColName = str.replace(/[^\w\s]/gi, " ").replace(/\s+/g, "_").toLowerCase();
    convertedDBColName = convertedDBColName.replace(/(?:__+)/g, "_"); // condense multiple underscores to one
    setDBColumnName(convertedDBColName);
  };

  const dropdownOptions = [["Sample Submission", "0"]];
  if (type === "prod") {
    dropdownOptions.push(["Product", "2"]);
  } else if (type === "env") {
    dropdownOptions.push(["Environment", "2"]);
  }

  return (
    <div className="signup-invite-custom-field-input-row">
      <FieldInput
        type={type}
        label={idx === 0 ? "DB Column" : ""} // only show label for the first custom field
        id={`signup-invite-custom-input-name-${idx}`}
        field="json_field"
        setRef={setRef}
        errorsFromParent={errorsFromParent}
        setFieldError={setCustomFieldError}
        getFieldError={getCustomFieldError}
        ifReadOnly
        readOnlyVal={(fieldVal || DBColumnName) ?? ""}
        isSendingInvite={isSendingInvite}
        errorIconInnerPlacement
      />
      <FieldInput
        type={type}
        label={idx === 0 ? "Display Name" : ""}
        id={`signup-invite-custom-input-display-name-${idx}`}
        field="title_field"
        setRef={setRef}
        errorsFromParent={errorsFromParent}
        setFieldError={setCustomFieldError}
        getFieldError={getCustomFieldError}
        isSendingInvite={isSendingInvite}
        errorIconInnerPlacement
        converToDBColumnName={converToDBColumnName}
      />
      <>
        <DropdownFieldInput
          type={type}
          field="display"
          label={idx === 0 ? "Show In" : ""}
          centerLabel
          setRef={setRef}
          errorsFromParent={errorsFromParent}
          setFieldError={setCustomFieldError}
          getFieldError={getCustomFieldError}
          multiselect
          options={dropdownOptions}
          isSendingInvite={isSendingInvite}
          selectContainerClassName="signup-invite-display-container"
          inputClassName="signup-invite-display-input"
        />
        {/* <div
              className="signup-invite-input signup-invite-display-input"
              style={type === "env" ? { flex: "0 0 40px" } : {}}
            >
              {idx === 0 && (
              <div className="signup-invite-input-label">
                <label
                  htmlFor={`signup-invite-custom-input-display-${idx}`}
                >
                  Show
                </label>
              </div>
              )}
              <div className="signup-invite-checkbox-container">
                <input
                  id={`signup-invite-custom-input-show-${idx}`}
                  type="checkbox"
                  checked={showInTable}
                  required="required"
                  onChange={(e) => setShowInTable(e.target.value)}
                  ref={(input) => setRef("display", input)}
                />
                <span aria-hidden className="signup-invite-checkmark" onClick={() => setShowInTable(!showInTable)} />
              </div>
            </div> */}
      </>
      <div className="signup-invite-input signup-invite-display-input signup-invite-input-remove">
        {idx === 0 && (
        <div className="signup-invite-input-label signup-invite-input-label-remove ">
          <label>
            remove
          </label>
        </div>
        )}
        {fieldVal === undefined && (<CloseIcon height={12} width={12} onClick={() => removeCustomField(idx)} />)}
      </div>
    </div>
  );
}

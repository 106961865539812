import React from "react";
import { Skeleton } from "antd";
import "./COALoadingPage.css";

export default function COALoadingPage({ page }) {
  return (
    <div className="COALoadingPage__Container">
      <div className="COALoadingPage__PlaceHolder">
        <div className="COALoadingPage__Content">
          <div className="COALoadingPage__Header">
            <Skeleton.Input className="COALoadingPage__Search" active />
            {page !== "COAReport" && (<Skeleton.Input className="COALoadingPage__CreateBtn" active />)}
          </div>
          <div className="COALoading ActiveloadingAnimation" />
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import SearchIconSmall from "../../../../../assets/images/product/template/SearchGreyLarge.png";
import CloseIcon from "../../../../../assets/images/product/template/closeGrey.png";

export default function TemplateSectionSearch(props) {
  const {
    toggleShowSearch,
    handleSubmitSearch,
    disableClose,
    isCustomizeCoa,
    searchValuePrent,
  } = props;

  const [searchValue, setSearchValue] = useState(searchValuePrent);
  const onKeyPress = (e) => {
    if (e.charCode === 13) {
      handleSubmitSearch(searchValue);
    }
  };

  const onChange = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      handleSubmitSearch("");
    }
  };

  return (
    <div className={isCustomizeCoa ? "templateSearchBarContainer--full-width" : "templateSearchBarContainer"}>
      <div className={isCustomizeCoa ? "templateSearchBar--full-width" : "templateSearchBar"}>
        <input
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={onChange}
          onKeyPress={onKeyPress}
          className="templateSearchBarInput"
        />
        <img src={SearchIconSmall} alt="SearchIconSmall" onClick={() => handleSubmitSearch(searchValue)} className="searchIconSmall" />
      </div>
      {!disableClose && (<img src={CloseIcon} alt="templateSearchCloseIcon" onClick={toggleShowSearch} className="templateSearchClose" />)}
    </div>
  );
}

import React, { Component } from "react";

import { Layout, Menu } from "antd";

import {
  MyAccountMoreLightblueIcon,
  MyProfileIconActive, MyProfileIconInactive,
  TeamMemberIconActive, TeamMemberIconInactive,
  // SavedLocationIconActive, SavedLocationIconInactive,
  // FaqIconActive, FaqIconInactive,
  HelpIconActive, HelpIconInactive,
} from "./MyAccountIcon";

import "./myAccount.css";

const { Sider } = Layout;

class MyAccountSideMenu extends Component {
  handleMenuClick = (event) => {
    const { handleMenuClick } = this.props;
    handleMenuClick(event);
  }

  render() {
    const {
      selectedMenuItem,
    } = this.props;

    const menuItems = [
      {
        key: "1",
        label: (
          <>
            {selectedMenuItem === "1" ? <MyProfileIconActive /> : <MyProfileIconInactive />}
            My Profile
            {selectedMenuItem === "1" && <MyAccountMoreLightblueIcon />}
          </>
        ),
      },
      {
        key: "2",
        label: (
          <>
            {selectedMenuItem === "2" ? <TeamMemberIconActive /> : <TeamMemberIconInactive />}
            Team Members
            {selectedMenuItem === "2" && <MyAccountMoreLightblueIcon />}
          </>
        ),
      },
      {
        key: "3",
        label: (
          <>
            {selectedMenuItem === "3" ? <HelpIconActive /> : <HelpIconInactive />}
            Help?
            {selectedMenuItem === "3" && <MyAccountMoreLightblueIcon />}
            {/* {selectedMenuItem === "3" ? <SavedLocationIconActive/> : <SavedLocationIconInactive/>}
            Saved Locations
            {selectedMenuItem === "3" && <MyAccountMoreLightblueIcon/>} */}
          </>
        ),
      },
      // {
      //   key: "4",
      //   label: (
      //     <>
      //       {selectedMenuItem === "4" ? <FaqIconActive/> : <FaqIconInactive/>}
      //       FAQs
      //       {selectedMenuItem === "4" && <MyAccountMoreLightblueIcon/>}
      //     </>
      //   ),
      // },
      // {
      //   key: "5",
      //   label: (
      //     <>
      //       {selectedMenuItem === "5" ? <HelpIconActive/> : <HelpIconInactive/>}
      //       Help?
      //       {selectedMenuItem === "5" && <MyAccountMoreLightblueIcon/>}
      //     </>
      //   ),
      // },
    ];

    return (
      <Sider className="site-layout-background my-account-side-menu-sider">
        <Menu
          items={menuItems}
          defaultSelectedKeys={["1"]}
          selectedKeys={[selectedMenuItem]}
          mode="inline"
          theme="light"
          onClick={(event) => this.handleMenuClick(event)}
        />
      </Sider>
    );
  }
}

export default MyAccountSideMenu;

import React, { useContext } from "react";
import maintenanceImg from "../assets/images/under_maintenance.gif";
import { signOut } from "../utils/common";
import "./UnderMaintenance.css";
import { AppContext } from "../AppContext";

const UnderMaintenance = ({ history }) => {
  const appContext = useContext(AppContext);
  const navigateToHome = () => {
    window.location.href = "/";
  };
  return (
    <div className="UnderMaintenanceContainer">
      <div className="">
        <img src={maintenanceImg} alt="under_maintenance" width={450} />
      </div>
      <div className="UnderMaintenance__ContentContainer">
        <h3 className="UnderMaintenance__ContentContainer__h3">
          A ninja stole this page.
        </h3>
        <h6>
          Check back later!!
        </h6>
        <div className="UnderMaintenance__Buttons">
          <button type="button" onClick={() => navigateToHome()} className="UnderMaintenance__SignOutButton">Back To Home</button>
          <button type="button" onClick={() => signOut(history, appContext)} className="UnderMaintenance__SignOutButton">Sign Out</button>
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenance;

import React, { useState } from "react";
import Modal from "antd/es/modal/Modal";
import "../styles/createFieldModal.css";
import CreateFieldModalHeader from "./CreateFieldModalHeader";
import CreateFieldModalRight from "./CreateFieldModalRight";
import CreateFieldModalLeft from "./CreateFieldModalLeft";
import "./CreateFieldModal.css";

export default function CreateFieldModal(props) {
  const { toggleShowCreateFieldModal, dataFieldCategory } = props;
  const [commonDataFieldInEdit, setCommonDataFieldInEdit] = useState(false);
  const [productDataFieldInEdit, setProductDataFieldInEdit] = useState(false);
  return (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      onCancel={toggleShowCreateFieldModal}
      width="802px"
      height="567px"
      wrapClassName="CreateFieldModal"
      className="CreateFieldModal"
    >
      <div className="createFieldModal">
        <CreateFieldModalHeader toggleShowCreateFieldModal={toggleShowCreateFieldModal} />
        <div className="createFieldModalBody">
          <CreateFieldModalLeft
            dataFieldCategory={dataFieldCategory}
            productDataFieldInEdit={productDataFieldInEdit}
            setCommonDataFieldInEdit={setCommonDataFieldInEdit}
          />
          <CreateFieldModalRight
            dataFieldCategory={dataFieldCategory}
            commonDataFieldInEdit={commonDataFieldInEdit}
            setProductDataFieldInEdit={setProductDataFieldInEdit}
          />
        </div>
      </div>
    </Modal>
  );
}

import React from "react";
import {
  Input,
  Select,
  Tooltip,
} from "antd";
import "./AddMemberModalInput.css";
import infoIcon from "../../../assets/images/product/infoCircleBlue.png";

export default function AddMemberModalInput({
  value,
  label,
  field,
  placeholder,
  onChange,
  handleOnBlur,
  innerRef,
  disabled,
  suffix,
  useDropdown,
  dropdownOptions,
  tooltipTitle,
}) {
  const onBlur = () => {
    if (handleOnBlur) {
      handleOnBlur(value);
    }
  };

  const handleOnChange = (e) => {
    let input_value = e.target ? e.target.value : e;
    if (field === "email") {
      input_value = input_value.split("@")[0];
    }
    if (onChange) {
      onChange(input_value);
    }
  };

  return (
    <div className="AddMemberModal__InputParent">
      <div className="myAccountFormTitle">
        {label}
        {tooltipTitle && (
          <Tooltip
            title={tooltipTitle}
            placement="top"
            overlayClassName="TeamMember__Tooltip"
          >
            <img
              className="AddMemberModal__RoleInfo"
              src={infoIcon}
              alt="infoIcon"
            />

          </Tooltip>
        )}
      </div>
      {!useDropdown && (
      <Input
        ref={innerRef}
        className={`${field === "email" ? "AddMemberModalInput AddMemberModalInput--email" : "AddMemberModalInput"} ${disabled ? "AddMemberModalInput--disabled" : ""}`}
        value={value}
        disabled={disabled}
        onChange={handleOnChange}
        placeholder={placeholder}
        onBlur={onBlur}
        addonAfter={suffix}
      />
      )}
      {useDropdown && (
      <Select
        value={value}
        disabled={disabled}
        className="AddMemberModalSelect"
        options={dropdownOptions}
        onChange={handleOnChange}
        placeholder={placeholder}
        onBlur={onBlur}
      />
      )}
    </div>
  );
}

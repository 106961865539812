import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  ResponsiveContainer, CartesianGrid, XAxis, YAxis, ComposedChart, Line, Scatter, Bar, Dot, Brush,
} from "recharts";
// import {
//   valuesArr, scatterObj, specArr, singleDataPointObj,
// } from "../../../../../../assets/MockData";

import ScatterPoint from "./ScatterPoint";
import "../../../styles/GraphContainerStyle.css";
// import ArrowIcon from "../../../../../../assets/images/product/next.svg";

import { colorsAndIconsArray, compareAverageValueToSpec } from "../../../Helper";

export default function ComposedGraph(props) {
  const {
    showGraphData, selectedData, formattedData, selectedGraph, graphKey, yGraphBound, updateSelectedGraph, getProperDataKey, renderReferenceArea, renderToolTip, showBarGraph, updateHoveredItem, graphRef,
  } = props;
  const { valuesArr, scatterObj } = formattedData;
  const DEFAULT_DATES_PER_PAGE = 30;
  const [indexes, setIndexes] = useState({ startIdx: 0, endIdx: Math.min(valuesArr.length, DEFAULT_DATES_PER_PAGE) - 1 });
  const [valuesArrSelected, setValuesArrSelected] = useState(valuesArr);
  const [valuesArrSelectedAndDisplayed, setValuesArrSelectedAndDisplayed] = useState([]);

  useEffect(() => {
    if (selectedGraph !== "") { // Update passed data when select a graph, removing the dates that do not have values from XAxis.
      const valuesArrSelectedCalculation = [];
      valuesArr.forEach((values) => {
        const resultsOnTheDate = values.result;
        const selectedResultsOnTheDate = { date: values.date, result: [] };
        let hasDataOnTheDate = false;

        resultsOnTheDate.forEach((res) => {
          if (selectedGraph.product?.product_id === res.name && res[selectedGraph.test] !== undefined) {
            selectedResultsOnTheDate.result.push(res);
            hasDataOnTheDate = true;
          }
        });

        if (hasDataOnTheDate) {
          valuesArrSelectedCalculation.push(selectedResultsOnTheDate);
        }
      });

      setValuesArrSelected(valuesArrSelectedCalculation);
      setIndexes({ startIdx: 0, endIdx: Math.min(valuesArrSelectedCalculation.length, DEFAULT_DATES_PER_PAGE) - 1 });
    } else {
      setValuesArrSelected(valuesArr);
      // Reset brush indexes after changing selected products and tests, and unselecting graphs
      setIndexes({ startIdx: 0, endIdx: Math.min(valuesArr.length, DEFAULT_DATES_PER_PAGE) - 1 });
    }
  }, [selectedGraph, formattedData, valuesArr]);

  // Update passed data when select a graph (removing the dates that do not have values), change indexes range, or select products/tests.
  useEffect(() => {
    const { startIdx, endIdx } = indexes;
    const startDate = moment(new Date(valuesArrSelected[startIdx]?.date));
    const endDate = moment(new Date(valuesArrSelected[endIdx]?.date));
    const valuesArrDisplayedCalculation = valuesArrSelected.filter(({ date }) => moment(new Date(date)) >= startDate && moment(new Date(date)) <= endDate);
    setValuesArrSelectedAndDisplayed(valuesArrDisplayedCalculation);
  }, [selectedGraph.product, selectedGraph.test, indexes, formattedData, valuesArrSelected]);

  // Pass the proper data when a line is clicked
  const getNewSelectedGraphData = (product, test) => {
    const { selectedProducts, selectedTests } = selectedData;
    let counter = 0;
    selectedProducts.forEach((p) => selectedTests.forEach((t) => {
      if (p.product_id === product?.product_id && test === t) {
        updateSelectedGraph({ product, test, color: colorsAndIconsArray[counter].accentColor });
      }
      counter++;
    }));
  };

  // Renders a scatter plot -- only gets rendered when a graph is selected, and updated as the brush indexes change
  // Colors the individual dots green/red/default depending on in spec/out of spec/incomplete
  const renderScatter = (counter, product, test) => {
    if (showBarGraph) return null;

    let points = [];
    if (scatterObj !== undefined) {
      const uniqueIdentifier = `${test}|${product?.product_id}`;
      if (scatterObj[uniqueIdentifier] !== undefined) points = scatterObj[uniqueIdentifier];
      const { startIdx, endIdx } = indexes;
      const startDate = moment(new Date(valuesArrSelected[startIdx]?.date));
      const endDate = moment(new Date(valuesArrSelected[endIdx]?.date));
      points = points.filter(({ date }) => moment(new Date(date)) >= startDate && moment(new Date(date)) <= endDate);
    }

    return (
      <>
        <Scatter
          key={`${counter}scatter`}
          data={points}
          dataKey={(currObj) => getProperDataKey(currObj, product, test)}
          shape={<ScatterPoint updateHoveredItem={updateHoveredItem} />}
        >
          {/* {points.map((entry, index) => <Cell key={`cell-${index}`} stroke="#D1DCE8" fill="white" strokeWidth={3} r={4} onMouseEnter={() => updateHoveredItem({ data: entry, type: "scatter" })} onMouseLeave={() => updateHoveredItem(null)} />)} */}
          {/* if (entry.specsFlag === "-" || entry.specsFlag === null) {
             return <Cell key={`cell-${index}`} stroke="#19305A" fill="white" strokeWidth={3} r={4} />;
           }
           return <Cell key={`cell-${index}`} stroke={entry.specsFlag === "1" ? "#00BF71" : "#E63559"} fill="white" strokeWidth={3} r={4} />; */}
        </Scatter>
      </>
    );
  };

  // Renders line to be shown on the line graph
  // Loops through products and tests -- returns a line for each
  const renderLines = () => {
    if (!showGraphData) return null;
    if (showBarGraph) return null;

    const { selectedTestType, specBounds } = props;
    const { selectedProducts, selectedTests } = selectedData;
    const { singleDataPointObj } = formattedData;
    let counter = -1;

    return selectedProducts.map((product) => selectedTests.map((test) => {
      counter++;

      const renderDot = singleDataPointObj[`${product?.product_id}|${test}`];
      let dotObj = false;
      let animation = true;

      // Renders a customized dot when there is only one data point on a page
      let countOnThePage = 0;
      valuesArrSelectedAndDisplayed.forEach((resultsOnTheDate) => {
        resultsOnTheDate.result.forEach((res) => {
          if (res.name === product?.product_id && res[test] !== undefined) {
            countOnThePage++;
          }
        });
      });
      if (renderDot === 1 || countOnThePage === 1) {
        animation = false;
        dotObj = {
          r: 4, fill: "#FFF", stroke: colorsAndIconsArray[counter].accentColor, strokeWidth: 3, onClick: () => { getNewSelectedGraphData(product, test); },
        };
      }

      // We have a selected graph
      if (selectedGraph && selectedGraph.product?.product_id === product?.product_id && selectedGraph.test === test) {
        return (
          <>
            {renderReferenceArea()}
            {renderToolTip()}
            <Line
              //   filter="url(#shadow)"
              className="selectedGraphLine"
              key={counter}
              type="monotone"
              // data={selectedGraphData}
              data={valuesArrSelectedAndDisplayed}
              dataKey={(currObj) => getProperDataKey(currObj, product, test)}
              stroke={colorsAndIconsArray[counter].accentColor}
              strokeWidth={4}
              connectNulls
              dot={{
                r: 4,
                fill: "#FFF",
                stroke: colorsAndIconsArray[counter].accentColor,
                strokeWidth: 3,
              }}
              activeDot={(obj) => {
                // Responsible for highlighting the active dot green/red/default depending on the spec
                const resultArr = obj.payload.result;
                let spec;

                // Finds the correct spec value to use
                resultArr.forEach((result) => {
                  if (result[test] !== undefined && result.name === product?.product_id) {
                    spec = result[test];
                  }
                });

                // Renders the active dot obj
                return (
                  <Dot
                    className="selectedGraphDot"
                    cx={obj.cx}
                    cy={obj.cy}
                    r={4}
                    fill="#FFF"
                    stroke={compareAverageValueToSpec(spec, selectedTestType, specBounds)}
                    strokeWidth={3}
                    onClick={() => updateSelectedGraph({ product, test, color: colorsAndIconsArray[counter].accentColor })}
                    onMouseEnter={() => updateHoveredItem({ data: { product, test }, type: "average" })}
                    onMouseOut={() => updateHoveredItem(null)}
                  />
                );
              }}
              onClick={() => updateSelectedGraph({ product, test, color: colorsAndIconsArray[counter].accentColor })}
              animationBegin={200}
              isAnimationActive={animation}
            />
            {renderScatter(counter, product, test)}
          </>
        );
      }

      // No selected graph so render all lines
      if (selectedGraph === "") {
        return (
          <Line
            //   filter="url(#shadow)"
            className="noSelectedGraphLine"
            key={counter}
            type="monotone"
            data={valuesArrSelectedAndDisplayed}
            dataKey={(currObj) => getProperDataKey(currObj, product, test)}
            stroke={colorsAndIconsArray[counter].accentColor}
            strokeWidth={4}
            connectNulls
            dot={dotObj}
            activeDot={false}
            onClick={() => getNewSelectedGraphData(product, test)}
            animationBegin={200}
            isAnimationActive={animation}
          />
        );
      }

      return null;

      // A graph is selected, all other should be a gray line
      // return (
      //   <Line
      //     key={counter}
      //     type="monotone"
      //     data={valuesArr}
      //     dataKey={(currObj) => getProperDataKey(currObj, product, test)}
      //     // stroke="#EAF0F5"
      //     strokeOpacity={0.2}
      //     strokeWidth={3}
      //     connectNulls
      //     dot={false}
      //     activeDot={false}
      //     animationBegin={200}
      //   />
      // );
    }));
  };

  // Renders the bars to be shown on the bar graph
  // Loops through products and tests -- returns a bar for each
  const renderBars = () => {
    const opacity = showBarGraph ? 1 : 0;
    if (!showGraphData) return null;

    const { selectedProducts, selectedTests } = selectedData;

    let counter = -1;
    return selectedProducts.map((product) => selectedTests.map((test) => {
      counter++;
      // We have a selected graph
      if (selectedGraph && selectedGraph.product?.product_id === product?.product_id && selectedGraph.test === test) {
        return (
          <>
            {showBarGraph && renderReferenceArea()}
            {showBarGraph && renderToolTip(false)}
            <Bar
              className="selectedGraphBar"
              key={counter}
              type="monotone"
              // data={selectedGraphData}
              data={valuesArrSelectedAndDisplayed}
              dataKey={(currObj) => getProperDataKey(currObj, product, test)}
              fill={colorsAndIconsArray[counter].accentColor}
              maxBarSize={16}
              minPointSize={5}
              onClick={() => updateSelectedGraph({ product, test, color: colorsAndIconsArray[counter].accentColor })}
              animationBegin={200}
              radius={[8, 8, 0, 0]}
              opacity={opacity}
              onMouseEnter={() => updateHoveredItem({ data: { product, test }, type: "average" })}
              onMouseLeave={() => updateHoveredItem(null)}
            />
          </>
        );
      }

      // No graph selected
      if (selectedGraph === "") {
        return (
          <Bar
            className="noSelectedGraphBar"
            key={counter}
            type="monotone"
            dataKey={(currObj) => getProperDataKey(currObj, product, test)}
            fill={colorsAndIconsArray[counter].accentColor}
            maxBarSize={16}
            minPointSize={5}
            onClick={() => getNewSelectedGraphData(product, test)}
            animationBegin={200}
            radius={[8, 8, 0, 0]}
            opacity={opacity}
          />
        );
      }

      return null;

      // Gray out everything else
      // return (
      //   <Bar
      //     key={counter}
      //     type="monotone"
      //     dataKey={(currObj) => getProperDataKey(currObj, product, test)}
      //     fill="#EAF0F5"
      //     maxBarSize={16}
      //     minPointSize={5}
      //     animationBegin={200}
      //     radius={[8, 8, 0, 0]}
      //     fillOpacity={0.2}
      //   />
      // );
    }));
  };

  let currentMonth = "";
  // Called from graph component
  // Returns the proper date formatted to be plotted on the x-axis
  const formatXAxisTicks = (tickItem) => {
    const date = moment(new Date(tickItem));
    const month = date.format("MMM");
    const day = date.format("DD");
    // console.log(currentMonth, tickItem, valuesArrSelected[indexes.startIdx].date);
    // console.log(indexes.startIdx, valuesArrSelected, valuesArrSelected[indexes.startIdx]);
    if (currentMonth === "" || month !== currentMonth || tickItem === valuesArrSelected[indexes.startIdx]?.date) {
      currentMonth = month;
      return moment(new Date(tickItem)).format("MMM DD");
    }
    return day;
  };

  const handleBrushChange = ({ startIndex, endIndex }) => {
    setIndexes({ startIdx: startIndex, endIdx: endIndex });
  };

  // Returns the proper date formatted for the brush
  // const firstDate = valuesArr[0].date;
  // const lastDate = valuesArr[valuesArr.length - 1].date;
  // if (tickItem === firstDate || tickItem === lastDate) {
  //   return moment(tickItem).format("MMM DD");
  // }
  const formatBrushDate = (tickItem) => moment(new Date(tickItem)).format("MMM DD, YY");
  // const handleClickPrev = () => {
  //   const currentDatesPerPage = idxes.endIdx - idxes.startIdx + 1;
  //   const newStartIdx = Math.max(0, idxes.startIdx - currentDatesPerPage);
  //   const newEndIdx = Math.max(0, idxes.endIdx - currentDatesPerPage);
  //   setIdxes({ startIdx: newStartIdx, endIdx: newEndIdx });
  // };

  // const handleClickNext = () => {
  //   const currentDatesPerPage = idxes.endIdx - idxes.startIdx + 1;
  //   const newStartIdx = Math.min(idxes.startIdx + currentDatesPerPage, valuesArr.length - 1);
  //   const newEndIdx = Math.min(idxes.endIdx + currentDatesPerPage, valuesArr.length - 1);
  //   setIdxes({ startIdx: newStartIdx, endIdx: newEndIdx });
  // };
  // console.log("valuesArrSelected", valuesArrSelected);
  return (
    <ResponsiveContainer height="100%" width="100%">
      <ComposedChart
        data={valuesArrSelected}
        margin={{
          top: 5,
          right: 20,
          left: -20,
          bottom: 5,
        }}
        key={graphKey}
        ref={graphRef}
      >
        <CartesianGrid strokeDasharray="0" stroke="#F2F6FA" vertical={false} />

        <defs>
          <filter id="shadow" height="200%">
            <feDropShadow dx="0" dy="3" stdDeviation="5" floodColor="rgba(32, 5, 5, 0.2)" />
          </filter>

          <defs>
            <linearGradient id="gradientColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={selectedGraph.color} stopOpacity={0.12} />
              <stop offset="95%" stopColor={selectedGraph.color} stopOpacity={0.02} />
            </linearGradient>
          </defs>
        </defs>

        <XAxis
          dataKey="date"
          tickFormatter={(tickItem) => formatXAxisTicks(tickItem)}
          tickLine={false}
          fontSize={11}
          fontFamily="Roboto"
          tick={{ fill: "#AFBDCA" }}
          stroke="#C4D2DF"
          allowDuplicatedCategory={false}
        />
        <YAxis
          domain={[yGraphBound.start, yGraphBound.end]}
          tickCount={10}
          tickLine={false}
          fontSize={12}
          fontFamily="Roboto"
          tick={{ fill: "#506375" }}
          axisLine={false}
          width={70}
          allowDataOverflow
        />
        {renderLines()}
        {renderBars()}
        <Brush
          dataKey="date"
          startIndex={indexes.startIdx}
          endIndex={indexes.endIdx}
          onChange={handleBrushChange}
          fill="#eff2f3"
          stroke="#afbdca"
          height={30}
          tickFormatter={(tickItem) => formatBrushDate(tickItem)}
        />
      </ComposedChart>
    </ResponsiveContainer>
  //  <div className="composed-graph-buttons-container">
  //   {idxes.startIdx > 0 && (
  //   <button onClick={handleClickPrev} type="button" className="composed-graph-prev-button" disabled={idxes.startIdx === 0}>
  //     <img src={ArrowIcon} alt="prev button" />
  //   </button>
  //   )}
  //   {idxes.endIdx < (valuesArr.length - 1) && (
  //   <button onClick={handleClickNext} type="button" className="composed-graph-next-button" disabled={idxes.endIdx === (valuesArr.length - 1)}>
  //     <img src={ArrowIcon} alt="next button" />
  //   </button>
  //   )}
  // </div>
  );
}

/* eslint-disable react/state-in-constructor */
import React, { Component } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import TagInput from "./TagInput";
import "./Tags.css";

export default class Tag extends Component {
  constructor(props) {
    super(props);
    const { isNotAsync } = this.props;
    this.state = {
      isProcessingDelete: false,
      outsideClickable: true,
      isNotAsync: isNotAsync || false,
    };
  }

  handleDoubleClick = (e) => {
    const { handleDoubleClickTag } = this.props;
    e.preventDefault();
    handleDoubleClickTag();
    this.enableOutsideClick();
  }

  handleTagInputOutsideClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { handleTagInputOutsideClick, isNotAsync } = this.props;
    const { outsideClickable } = this.state;
    if (!outsideClickable) {
      return;
    }
    if (isNotAsync) {
      handleTagInputOutsideClick();
      this.setState({ outsideClickable: false });
    } else {
      this.setState({ outsideClickable: false }, () => handleTagInputOutsideClick().then(() => this.setState({ outsideClickable: true })));
    }
  }

  handleDeleteTag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { isProcessingDelete, isNotAsync } = this.state;
    const { handleDeleteTag } = this.props;
    if (isProcessingDelete) {
      return;
    }
    if (isNotAsync) {
      handleDeleteTag(e);
      this.setState({ isProcessingDelete: false });
    } else {
      this.setState({ isProcessingDelete: true }, () => handleDeleteTag(e).then(() => this.setState({ isProcessingDelete: false })));
    }
  }

  enableOutsideClick = () => {
    this.setState({ outsideClickable: true });
  }

  render() {
    const {
      icon,
      label,
      isEditing,
      tagInput,
      setTagInput,
      handleKeyPressAddTag,
    } = this.props;

    return (
      <div className="IndexTag"/* style={{ display: "inline-block", marginRight: "8px", marginBottom: "8px" }} */>
        <div className={`IndexTag__Container ${isEditing ? "IndexTag__Edit" : ""}`}/* style={{ height: "24px" }} */>
          {icon}
          {isEditing
            ? (
              <OutsideClickHandler onOutsideClick={this.handleTagInputOutsideClick}>
                <TagInput
                  tagInput={tagInput}
                  enableOutsideClick={this.enableOutsideClick}
                  setTagInput={setTagInput}
                  handleKeyPressAddTag={handleKeyPressAddTag}
                />
              </OutsideClickHandler>
            )
            : <span className="tagText" onDoubleClick={this.handleDoubleClick}>{label}</span>}
          <img
            className="tagRemoveIcon"
            id="IndexTag__RemoveIcon"
            alt="deleteIcon"
            // style={{ height: "24px", width: "24px", padding: "5.33px" }}
            onClick={this.handleDeleteTag}
          />
        </div>
      </div>
    );
  }
}

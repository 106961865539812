import React, { useEffect, useState } from "react";
import StyledTooltip from "../../../../../Common/UIComponents/StyledTooltip";
import { ReactComponent as CloseIcon } from "../../../../../../assets/images/product/close.svg";
import "./EmailTag.css";

export default function EmailTag(props) {
  const { email, removeEmail, isValid } = props;

  const [tooltipMsg, setTooltipMsg] = useState("");

  useEffect(() => {
    const handleTooltipMsg = () => {
      if (isValid) {
        setTooltipMsg("");
      } else if (!isValid && email.length <= 50) {
        setTooltipMsg("Invalid Email.");
      } else if (email.length > 50) {
        setTooltipMsg("Character limit exceeded.");
      }
    };
    handleTooltipMsg();
  }, [email, isValid]);

  return (
    <StyledTooltip
      title={tooltipMsg}
      placement="top"
    >
      <div className={isValid ? "Invite__EmailTagContainer" : "Invite__EmailTagContainer EmailTagError"}>
        <div className={isValid ? "EmailTagText" : "EmailTagErrorText EmailTagText"}>{email}</div>

        <div onClick={() => removeEmail(email)}>
          <CloseIcon className={isValid ? "EmailTagRemoveIcon" : "EmailTagRemoveIconError EmailTagRemoveIcon"} />
        </div>
      </div>
    </StyledTooltip>
  );
}

import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { Tabs } from "antd";
import { mapValues } from "lodash";
import COA from "./Components/COA";
import TemplateBuilder from "./Components/TemplateBuilder";
import Templates from "./Components/Templates";
import {
  getTemplates, deleteTemplate,
} from "../../../actions/productTemplate";
import "./Components/styles/template.css";
import Header from "./Components/Common/Header";
import { getProductFields } from "../../../actions/reports";

export default function Template() {
  const [isAddingTemplate, setIsAddingTemplate] = useState(false);
  const [isCreatingCoa, setIsCreatingCoa] = useState(false);
  const [indexOfTemplateSelected, setIndexOfTemplateSelected] = useState(-1);
  const [templateList, setTemplateList] = useState([]);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("Template");
  const [randomKey, setRandomKey] = useState(0);
  const [customizeReportData, setCustomizeReportData] = useState({});
  const [templateEditing, setTemplateEditing] = useState(null);
  const [productFieldMap, setProductFieldMap] = useState(null);

  /**
   * Fetch the templates list
   */
  const fetchTemplates = async () => {
    const response = await getTemplates();
    if (!response || !response.success) {
      toast.error("Could not fetch templates.");
      setTemplateList([]);
    } else {
      setTemplateList(response.result);
    }
    setTemplateLoading(false);
  };

  useEffect(() => {
    const fetchFields = async () => {
      const resp = await getProductFields();
      if (!resp || !resp.success) {
        toast.error("Could not fetch common fields.");
      } else {
        setProductFieldMap(mapValues(resp.fieldsMap, (val) => val.title_field));
      }
    };
    setTemplateLoading(true);
    fetchTemplates();
    fetchFields();
  }, []);

  const handleAddTemplateClick = () => {
    setCustomizeReportData({});
    setIsAddingTemplate(true);
  };

  /**
   * Make an api call to get the template information and then redirect to the template builder page
   * @param {*} template_id
   */
  const handleEditTemplate = async (template_id) => {
    const response = await getTemplates({ template_id });
    if (response.success) {
      setTemplateEditing(response.result[0]);
    } else {
      toast.error("Could not fetch template");
    }
    setIsAddingTemplate(true);
  };

  /**
   * Make an api call to delete a template
   * @param {*} template_id
   */
  const handleDeleteTemplate = async (template_id) => {
    const response = await deleteTemplate(template_id);
    if (response.success) {
      toast.success("Successfully deleted template");
      fetchTemplates();
    } else {
      toast.error(response.message);
    }
  };

  const clearTemplateSelect = () => {
    setIndexOfTemplateSelected(-1);
  };

  /**
   * Fetch the selected template information
   * @param {*} templateIndex
   */
  const saveTemplateIdForAddingCoa = async (templateIndex) => {
    if (templateIndex === -1 || templateIndex === indexOfTemplateSelected) {
      clearTemplateSelect();
    } else {
      const params = { template_id: templateList[templateIndex].template_id };
      const response = await getTemplates(params);
      if (response && response.success) {
        setIndexOfTemplateSelected(templateIndex);
      } else if (!response || !response.success) {
        toast.error("Could not fetch templates.");
        clearTemplateSelect();
      }
    }
  };

  const handleNavigation = (route) => {
    setCustomizeReportData({});
    /** From TemplateBuilder back to Custom Report */
    if (route === "coacreate") {
      setIsCreatingCoa(() => {
        clearTemplateSelect();
        setIsAddingTemplate(false);
        return true;
      });
    }

    /** After Saving a Custom COA report */
    if (route === "coabuilder") {
      setIsCreatingCoa(() => {
        clearTemplateSelect();
        setIsAddingTemplate(false);
        return false;
      });
    }

    /** From Edit/New template to the Template tab */
    if (route === "builder") {
      setTemplateLoading(true);
      fetchTemplates();
      setIsAddingTemplate(false);
      setTemplateEditing(false);
    }
  };

  /**
   * Set the selected samples data from the Custom Report page and redirect to the template builder page
   * @param {*} obj
   */
  const handleCustomizeReportTemplate = (obj) => {
    setCustomizeReportData(obj);
    setIsAddingTemplate(true);
  };

  if (isAddingTemplate || !!templateEditing) {
    const { selectedTemplate, selectedReports } = customizeReportData;
    return (
      <TemplateBuilder
        isCustomizingCoa={!!selectedTemplate && !!selectedReports}
        isEditingTemplate={!!templateEditing}
        selectedReports={selectedReports}
        selectedTemplate={templateEditing || selectedTemplate}
        productFieldMap={productFieldMap}
        handleNavigation={handleNavigation}
      />
    );
  }

  const tabItems = [
    {
      key: "Template",
      label: <Header
        title="Template"
        showCreateReportButton={false}
        activeHeaderIcon={activeTabKey === "Template"}
      />,
      children: <Templates
        templateList={templateList}
        reportIsSelected={false}
        indexOfTemplateSelected={indexOfTemplateSelected}
        handleAddTemplateClick={handleAddTemplateClick}
        saveTemplateIdForAddingCoa={saveTemplateIdForAddingCoa}
        handleEditTemplate={handleEditTemplate}
        handleDeleteTemplate={handleDeleteTemplate}
        templateLoading={templateLoading}
      />,
    },
    {
      key: "Report",
      label: <Header
        title="Customized COA"
        showCreateReportButton={false}
        activeHeaderIcon={activeTabKey === "Report"}
      />,
      children: <COA
        handleCustomizeReportTemplate={handleCustomizeReportTemplate}
        handleIsCreatingCoa={(val) => setIsCreatingCoa(val)}
        isCreatingCoa={isCreatingCoa}
      />,
    },
  ];

  /**
   * While switching different tabs
   *  1. update the active key
   *  2. update the random key to force the tab content rerender
   *  4. fetch the templates list if switch to the Template tab
   *  5. Reset the isCreatingCoa state
   * @param {*} key
   */
  const handleTabOnChange = async (key) => {
    setActiveTabKey(key);
    setRandomKey(uuidv4());
    if (key === "Template") {
      setTemplateLoading(true);
      await fetchTemplates();
    }
    setIsCreatingCoa(false);
  };

  return (
    <div className="TemplateTabsContainer">
      <Tabs
        activeKey={activeTabKey}
        className="TemplateTabs"
        items={tabItems}
        type="card"
        onChange={handleTabOnChange}
        key={randomKey}
      />
    </div>
  );
}

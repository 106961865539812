import React from "react";
import { toast } from "react-toastify";
import { Drawer } from "antd";
import OutsideClickHandler from "react-outside-click-handler";
import { motion } from "framer-motion/dist/framer-motion";

import StyledRadioGroup from "../../../../Common/UIComponents/StyledRadioGroup";
import SpecManagerSearch from "./SpecManagerSearch";
import EditSpec from "./EditSpec";
import FilterButton from "./FilterButton";
import ConfirmModal from "./ConfirmModal";
import SpecDisplayTable from "./SpecDisplayTable";
import { getSpecsData } from "../../../../../actions/specManager";
import {
  parseSpecDetail,
  formatSpec,
  handleCheckUnit,
  checkIfSpecsInvalid,
} from "./Helper";
import { multipleLinkReportFieldsObjToObj } from "../../utils";
import "../../../Product.css";
import "./SpecManager.css";
import closeIcon from "../../../../../assets/images/product/crossWhite.png";
import pendingIcon from "../../../../../assets/images/addSpecIcon.png";
import addedIcon from "../../../../../assets/images/specAddedIcon.png";

class SpecManager extends React.Component {
  constructor(props) {
    super(props);
    this.specManagerScrollbar = React.createRef();
    const {
      isEditingSpec, productId, productIdentifierArr, linkReportJsonFields,
    } = this.props;
    this.state = {
      filterFlag: "",
      files: [],
      editingSpec: isEditingSpec !== undefined ? isEditingSpec : false,
      addingSpec: false,
      productId: productId || "",
      dataOfSpecEditing: [],
      confirmExitModalDisplay: false,
      searchValue: "",
      searchField: linkReportJsonFields?.length ? linkReportJsonFields[0] : "",
      productIdentifierArr: productIdentifierArr ?? [],
      valueHasBeenEdited: false,
      loading: true,
      nextPageLink: "",
      loadingNewContent: false,
      loadingEditSpecData: false,
      specDataChanged: false,
      exitingSpecManager: false,
      showFilters: false,
      showUpdateTestList: false,
    };
  }

  componentDidMount = async () => {
    const { fromModal, handleClosingEditSpec, linkReportJsonFields } = this.props;
    const {
      filterFlag, searchValue, searchField, productId,
    } = this.state;

    if (fromModal) {
      const params = {
        filter: "",
        search: "",
        search_field: linkReportJsonFields[0],
        product_id: productId,
        specs: "",
        page: 1,
      };
      this.setState({ loadingEditSpecData: true });
      const response = await getSpecsData(params);

      if (response && response.files) {
        const data = response.files;
        data.forEach((spec) => {
          const specDetail = parseSpecDetail(spec);
          spec.test_type = specDetail.test_type;
          spec.specs = specDetail.specs;
        });
        this.setState({
          dataOfSpecEditing: data,
          loadingEditSpecData: false,
        });
      } else {
        this.setState({ loadingEditSpecData: false });
        toast.error("Failed to get spec data.");
        handleClosingEditSpec();
      }
    } else {
      this.getInitialData(filterFlag, searchValue, searchField, productId);
    }
  };

  getInitialData = (
    filterFlag,
    searchValue,
    searchField,
    productId,
    scrollToTop = false,
  ) => {
    this.setState({ loading: true });
    const resp = this.apiGetSpecsData(
      filterFlag,
      searchValue,
      searchField,
      productId,
      scrollToTop,
    ).then((result) => {
      if (result) {
        if (result.success) {
          const { nextPageLink } = result.pages;
          this.setState({
            files: result.files,
            nextPageLink,
            loading: false,
          });
          return result;
        }
        /** Don't set state if canceled */
        if (result.canceled) {
          return result;
        }
      }
      /** If api failed, show no result */
      this.setState({
        files: [],
        nextPageLink: "",
        loading: false,
      });
      return null;
    });
    return resp;
  };

  handlePageChangeScroll = () => {
    const {
      nextPageLink: reqUrl,
      filterFlag,
      searchValue,
      searchField,
      productId,
      loadingNewContent,
      files: currentFiles,
    } = this.state;
    if (reqUrl !== "" && !loadingNewContent) {
      this.setState({ loadingNewContent: true }, () => this.specManagerScrollbar.current.scrollToBottom());
      const page = reqUrl ? reqUrl.split("page=")[1].split("&")[0] : "1";
      const scrollToTop = false;
      this.apiGetSpecsData(
        filterFlag,
        searchValue,
        searchField,
        productId,
        scrollToTop,
        page,
      ).then((result) => {
        if (result && result.files) {
          const { nextPageLink } = result.pages;
          this.setState({
            files: [...currentFiles, ...result.files],
            nextPageLink,
            loadingNewContent: false,
          });
        } else {
          this.specManagerScrollbar.current.scrollTop = this.specManagerScrollbar.current.scrollTop - 100; // move scrollbar up to prevent scroll api call infinitely firing
          this.setState({ loadingNewContent: false });
        }
      });
    }
  };

  /**
  * Use the AbortController to cancel the last specsmanager/ call if the last call was still in progress.
  * @param {*} controller
  */
  abortPrevApiCall = (controller) => {
    const { apiController } = this.state;
    if (apiController) {
      apiController.abort();
    }
    this.setState({ apiController: controller });
  };

  apiGetSpecsData = async (
    filterFlag,
    searchValue,
    searchField,
    productId = "",
    scrollToTop = false,
    page = "1",
  ) => {
    this.setState({ loading: true });
    if (scrollToTop && this.specManagerScrollbar.current) {
      this.specManagerScrollbar.current.scrollToTop();
    }
    const controller = new AbortController();
    this.abortPrevApiCall(controller);
    const payload = {
      filter: filterFlag,
      search: searchValue,
      search_field: searchField,
      product_id: productId,
      page,
    };
    const result = await getSpecsData(payload, false, controller.signal);
    if (result && result.success) {
      this.setState({ loading: false });

      if (result.files.length > 0 && scrollToTop) {
        if (this.specManagerScrollbar.current) {
          this.specManagerScrollbar.current.scrollToTop();
        }
      }
      return result;
    } if (!result.canceled) {
      toast.error("Failed to get spec data.");
      this.setState({ loading: false });
      return null;
    }
    return result;
  };

  handleSearchChange = async (value, filter) => {
    const {
      filterFlag, productId,
    } = this.state;
    this.setState({ searchValue: value, searchField: filter });
    const scrollToTop = true;
    this.getInitialData(
      filterFlag,
      value,
      filter,
      productId,
      scrollToTop,
    );
  };

  handleCloseFilterClick = (e) => {
    e.stopPropagation();
    this.handleFilterToggle("");
  };

  handleFilterToggle = async (filterFlag) => {
    const {
      filterFlag: filterFlagFromState,
      searchValue,
      searchField,
      productId,
    } = this.state;
    if (filterFlag === filterFlagFromState) {
      filterFlag = "";
    }
    this.setState({ filterFlag });
    const scrollToTop = true;
    this.getInitialData(
      filterFlag,
      searchValue,
      searchField,
      productId,
      scrollToTop,
    );
  };

  apiEditSpecsData = async (params) => {
    const result = await getSpecsData(params, true);
    if (result && result.success) {
      return result;
    }
    return null;
  };

  handleEditSpecClick = (e, i, flag) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = this.state;
    const { fields, linkReportJsonFields } = this.props;

    let productId;
    if (flag === "pending") {
      productId = files[i].product_id;
      this.setState({
        addingSpec: true,
        editingSpec: false,
        productId: files[i].product_id,
        productIdentifierArr: multipleLinkReportFieldsObjToObj(files[i].identifier, fields, linkReportJsonFields),
      });
    } else if (flag === "added") {
      productId = files[i].product_id;
      this.setState({
        addingSpec: false,
        editingSpec: true,
        productId: files[i].product_id,
        productIdentifierArr: multipleLinkReportFieldsObjToObj(files[i].identifier, fields, linkReportJsonFields),
      });
    }

    if (flag === "pending" || flag === "added") {
      this.setState({ loadingEditSpecData: true });
      this.apiGetSpecsData("", "", "", productId).then((result) => {
        let dataOfSpecEditing = result.files;
        dataOfSpecEditing = dataOfSpecEditing.map((spec) => parseSpecDetail(spec));
        this.setState({
          loadingEditSpecData: false,
          dataOfSpecEditing,
        });
      });
    } else {
      toast.error(`Incorrect spec flag: ${flag}`);
    }
  };

  refreshEditSpecDataAfterEdit = () => {
    const { productId } = this.state;
    this.setState({ loadingEditSpecData: true });
    this.apiGetSpecsData("", "", "", productId).then((result) => {
      let dataOfSpecEditing = result.files;
      dataOfSpecEditing = dataOfSpecEditing.map((spec) => parseSpecDetail(spec));
      this.setState({ loadingEditSpecData: false, dataOfSpecEditing });
    });
  };

  confirmExitModalDisplayToggle = () => {
    const { confirmExitModalDisplay } = this.state;

    this.setState({
      confirmExitModalDisplay: !confirmExitModalDisplay,
      exitingSpecManager: false,
    });

    return !confirmExitModalDisplay;
  };

  handleDataChange = (specList) => {
    this.setState({ dataOfSpecEditing: [...specList] });
  };

  handleExitEditSpec = (
    exitSpecManager = false,
    refreshSpecManagerData = false,
  ) => {
    if (exitSpecManager) {
      const { handleSpecManagerToggle, fromModal, handleClosingEditSpec } = this.props;
      if (fromModal) {
        handleClosingEditSpec();
      } else {
        handleSpecManagerToggle();
      }
      this.setState({
        exitingSpecManager: false,
        specDataChanged: false,
      });
    } else {
      if (refreshSpecManagerData) {
        const { filterFlag, searchValue, searchField } = this.state;
        this.apiGetSpecsData(filterFlag, searchValue, searchField).then((result) => {
          if (result && result.files) {
            const { nextPageLink } = result.pages;
            this.setState({
              files: result.files,
              nextPageLink,
            });
          }
        });
      }
      this.setState({
        editingSpec: false,
        addingSpec: false,
        productId: "",
        productIdentifierArr: [],
        confirmExitModalDisplay: false,
        dataOfSpecEditing: [],
        exitingSpecManager: false,
        specDataChanged: false,
      });
    }
  };

  handleSaveSpec = async (isSpecCleared = false) => {
    const { productId, dataOfSpecEditing } = this.state;
    const { toggleSpecsUpdated, fromModal } = this.props;
    let specList = formatSpec(dataOfSpecEditing);
    if (checkIfSpecsInvalid(dataOfSpecEditing)) {
      toast.error("Invalid or missing value(s).");
      return false;
    }
    specList = handleCheckUnit(specList);

    const params = {
      product_id: productId,
      specs: isSpecCleared ? [] : specList,
    };

    let result = false;
    await this.apiEditSpecsData(params).then((response) => {
      this.setState({
        valueHasBeenEdited: true,
      });
      if (response && response.success) {
        toast.success("Spec successfully edited.");
        if (toggleSpecsUpdated) {
          toggleSpecsUpdated(true);
        }

        if (!fromModal) {
          this.refreshEditSpecDataAfterEdit();
        }

        result = true;
      } else {
        toast.error("Failed to edit spec.");
      }
    });

    return result;
  };

  /**
   * Call PATCH /specsmanager/ api to update test list for a product
   * @param {Object} specEditingData Spec data for each test of the product as well as newly added tests with default spec data
   * @returns true if success otherwise false
   */
  handleSaveTestList = async (specEditingData) => {
    const { productId } = this.state;
    this.handleDataChange(specEditingData);
    let specList = formatSpec(specEditingData, true);
    specList = handleCheckUnit(specList);

    const params = {
      product_id: productId,
      specs: specList,
      action: "update_test_list",
    };

    let result = false;
    await this.apiEditSpecsData(params).then((response) => {
      this.setState({
        valueHasBeenEdited: true,
      });
      if (response && response.success) {
        toast.success("Test list successfully updated.");
        this.refreshEditSpecDataAfterEdit();
        result = true;
      } else {
        toast.error("Failed to update test list.");
      }
    });

    return result;
  };

  updateSpecDataChanged = (value) => {
    this.setState({
      specDataChanged: value,
    });
  };

  handleCloseSpecManager = () => {
    const { valueHasBeenEdited, specDataChanged } = this.state;
    const {
      handleSpecManagerToggle,
      handleDataRefreshAfterExitingSpecManager,
      fromModal,
      handleClosingEditSpec,
    } = this.props;

    if (specDataChanged) {
      this.confirmExitModalDisplayToggle();
      this.setState({
        exitingSpecManager: true,
      });
    } else if (fromModal) {
      handleClosingEditSpec();
    } else {
      handleSpecManagerToggle();
    }
    if (valueHasBeenEdited) {
      handleDataRefreshAfterExitingSpecManager();
      this.setState({
        valueHasBeenEdited: false,
      });
    }
  };

  updateShowFilters = (newValue) => {
    this.setState({ showFilters: newValue });
  };

  closeFilters = async () => {
    const { searchValue, showFilters } = this.state;
    if (searchValue || !showFilters) {
      return;
    }
    const { linkReportJsonFields } = this.props;
    this.setState({ showFilters: false, searchField: linkReportJsonFields[0] });
  };

  /**
   * Set value for showUpdateTestList state
   * @param {Boolean} value show update test list panel
   */
  setShowUpdateTestList = (value) => this.setState({ showUpdateTestList: value });

  render() {
    const {
      files,
      filterFlag,
      addingSpec,
      editingSpec,
      dataOfSpecEditing,
      loading,
      productIdentifierArr,
      searchValue,
      searchField,
      confirmExitModalDisplay,
      loadingNewContent,
      exitingSpecManager,
      specDataChanged,
      valueHasBeenEdited,
      loadingEditSpecData,
      showFilters,
      showUpdateTestList,
    } = this.state;
    const {
      linkReportJsonFields,
      fields,
      isEditingSpec,
      fromModal,
      handleClosingEditSpec,
      showSpecManager,
    } = this.props;

    return (
      <Drawer
        open={showSpecManager}
        width={853}
        rootClassName="ProdSpecManagerDrawerWrapper"
        className="ProdSpecManagerDrawer"
        destroyOnClose
        title={(
          <>
            <span>Spec Manager</span>
            <img
              src={closeIcon}
              alt="close icon"
              className="SpecManager__Header--icon"
              onClick={this.handleCloseSpecManager}
            />
          </>
        )}
      >
        <div className="ProdSpecManagerCardBody">
          {addingSpec || editingSpec ? (
            <>
              {!showUpdateTestList && (
                <>
                  {addingSpec && (
                    <div className="SpecManger__SubHeader">
                      Adding specs here will automatically check and update the
                      status of the spec of your reports
                    </div>
                  )}
                  {editingSpec && (
                    <div
                      className="SpecManger__SubHeader--warning"
                    >
                      Note: Editing the specs here will impact the outcome of all
                      the reports associated with these specs.
                    </div>
                  )}
                  <div className="SpecManger__Separator" />
                </>
              )}
              <EditSpec
                fromModal={fromModal}
                addingSpec={addingSpec}
                productIdentifierArr={productIdentifierArr}
                dataOfSpecEditing={dataOfSpecEditing}
                confirmExitModalDisplayToggle={this.confirmExitModalDisplayToggle}
                handleExitEdit={this.handleExitEditSpec}
                handleSaveSpec={this.handleSaveSpec}
                handleDataChange={this.handleDataChange}
                handleSpecChanged={this.updateSpecDataChanged}
                specChanged={specDataChanged}
                editingSpec={isEditingSpec}
                handleClosingEditSpec={handleClosingEditSpec}
                valueHasBeenEdited={valueHasBeenEdited}
                loading={loadingEditSpecData}
                showUpdateTestList={showUpdateTestList}
                setShowUpdateTestList={this.setShowUpdateTestList}
                handleSaveTestList={this.handleSaveTestList}
              />
            </>
          ) : (
            <>
              <div className="SpecManager__FiltersContainer">
                <OutsideClickHandler onOutsideClick={() => this.closeFilters(false)}>
                  <div>
                    <SpecManagerSearch
                      flag="reports_spec"
                      searchValue={searchValue}
                      searchField={searchField}
                      updateShowFilters={this.updateShowFilters}
                      handleSearchChange={this.handleSearchChange}
                    />
                    <motion.div layout>
                      {showFilters && (linkReportJsonFields.length > 1) && (
                        <div className="SpecManager__SearchFilters">
                          <StyledRadioGroup
                            value={searchField}
                            onChange={(e) => this.handleSearchChange(searchValue, e.target.value)}
                            options={fields.slice(0, linkReportJsonFields.length).map((field) => ({
                              label: field.title_field,
                              value: field.json_field,
                            }))}
                          />
                        </div>
                      )}
                    </motion.div>
                  </div>
                </OutsideClickHandler>
                <div className="SpecManager__Filters">
                  <FilterButton
                    isActive={filterFlag === "added"}
                    icon={addedIcon}
                    filterText="Spec Added"
                    handleFilterToggle={this.handleFilterToggle}
                    handleCloseFilterClick={this.handleCloseFilterClick}
                    disable={loading}
                    alt="addedIcon"
                  />
                  <FilterButton
                    isActive={filterFlag === "pending"}
                    icon={pendingIcon}
                    filterText="Spec Incomplete"
                    handleFilterToggle={this.handleFilterToggle}
                    handleCloseFilterClick={this.handleCloseFilterClick}
                    disable={loading}
                    alt="pendingIcon"
                  />
                </div>
              </div>
              <motion.div layout className="SpecManager__TableContainer">
                <SpecDisplayTable
                  tableData={files}
                  handleEditSpecClick={this.handleEditSpecClick}
                  handlePageChangeScroll={this.handlePageChangeScroll}
                  loadingNewContent={loadingNewContent}
                  specManagerScrollbar={this.specManagerScrollbar}
                  loading={loading}
                  searchValue={searchValue}
                  searchField={searchField}
                  fields={fields}
                  linkReportJsonFields={linkReportJsonFields}
                />
              </motion.div>
            </>
          )}
        </div>
        {confirmExitModalDisplay && (
          <ConfirmModal
            headerText="Unsaved Changes"
            bodyText="Are you sure you want to leave without saving?"
            buttonText={["Leave", "Stay"]}
            buttonFunctions={[
              () => this.handleExitEditSpec(exitingSpecManager),
              this.confirmExitModalDisplayToggle,
            ]}
          />
        )}
      </Drawer>
    );
  }
}
export default SpecManager;

import React from "react";
import COABuilderImage from "./COABuilderImage";
import "./COABuilderImageWithText.css";

export default function COABuilderImageWithText({
  titleText,
  subtitleText,
  imageTitle,
  imagePath,
  handleBuilderFile,
}) {
  return (
    <div className="COABuilderImageWithText__Container">
      {imagePath && <COABuilderImage imagePath={imagePath} handleBuilderFile={handleBuilderFile} title={imageTitle} />}
      <div className="COABuilderImageWithText__TextContainer">
        {titleText && <span className="COABuilderImageWithText__Title">{titleText}</span>}
        {subtitleText && <span className="COABuilderImageWithText__Subtitle">{subtitleText}</span>}
      </div>
    </div>
  );
}

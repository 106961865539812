import React from "react";
import "./ReceiptModalActionsBar.css";
import { ReactComponent as DownloadIcon } from "../../../../assets/images/common/DownloadIcon.svg";
import { ReactComponent as PrintIcon } from "../../../../assets/images/common/PrintIcon.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/images/sample-submission/InfoIconSuccess.svg";
import StyledButton from "../../../Common/UIComponents/StyledButton";

export default function ReceiptModalActionsBar({
  loading, handlePrint, handleDownload, closable, headerTitleText,
}) {
  return (
    <div className={`ReceiptModalActionsBar${closable ? "--closable" : ""}`}>
      <div className={`ReceiptModalActionsBar__Text${headerTitleText ? "--with-title" : ""}`}>
        {headerTitleText && <span className="ReceiptModalActionsBar__Title">{headerTitleText}</span>}
        <span className="ReceiptModalActionsBar__Info">
          <InfoIcon fill="#C4D2DF" width={26} height={26} />
          Print out this receipt and include it with the sample contents in your package.
        </span>
      </div>
      <div className="SubmissionReceiptModal__Buttons">
        <StyledButton
          type="primary"
          ghost
          icon={<PrintIcon />}
          disabled={loading}
          onClick={handlePrint}
          className="Print_Button"
        >
          Print
        </StyledButton>
        <StyledButton
          type="primary"
          icon={<DownloadIcon />}
          disabled={loading}
          onClick={handleDownload}
        >
          Download
        </StyledButton>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import Modal from "antd/es/modal/Modal";
import Scrollbar from "react-scrollbars-custom";
import StyledButton from "../../../../Common/UIComponents/StyledButton";
import "./RequestRetestModal.css";

export default class RequestRetestModal extends Component {
  render() {
    const {
      hideRequestRetestModal,
      confirmRetest,
      currNumOfRetests,
      maxNumOfRetests,
      testToRetest,
      awaitingRetestRequest,
    } = this.props;

    const numberMap = ["first", "second", "third and final"];
    const testSet = new Set(testToRetest);

    return (
      <Modal
        open
        maskClosable={false}
        closable={false}
        centered
        footer={null}
        wrapClassName="RequestRetestModal"
        className="RequestRetestModal"
        openAnim={false}
        width="530px"
      >

        <div className="requestRetestModalContainer">
          <span className="requestRetestHeaderText">
            The sample will be retested for the following tests
          </span>
          {testSet.size > 10 ? (
            <div className="requestRetestTestListScrollContainer">
              <Scrollbar>
                <div className="requestRetestTestList">
                  {Array.from(testSet).map((test, index) => (
                    <span key={index} className="requestRetestTestDiv">{test}</span>
                  ))}
                </div>
              </Scrollbar>
            </div>
          ) : (
            <div className="requestRetestTestList">
              {Array.from(testSet).map((test, index) => (
                <span key={index} className="requestRetestTestDiv">{test}</span>
              ))}
            </div>
          )}
          <div className="requestRetestSubInfoText">
            <span>
              {`Please note a maximum of ${maxNumOfRetests} retests allowed.`}
            </span>
            <span>
              {`This is your ${numberMap[currNumOfRetests]} request (${currNumOfRetests + 1}/${maxNumOfRetests}).`}
            </span>
          </div>

          <div className="requestRetestModalButtonDiv">
            <StyledButton
              type="primary"
              className="requestRetestModal__CancelBtn"
              onClick={hideRequestRetestModal}
              disabled={awaitingRetestRequest}
            >
              <div>
                Cancel
              </div>
            </StyledButton>

            <StyledButton
              type="primary"
              className="requestRetestModal__SubmitBtn"
              onClick={confirmRetest}
              loading={awaitingRetestRequest}
            >
              <div>
                Submit
              </div>
            </StyledButton>
          </div>
        </div>
      </Modal>
    );
  }
}

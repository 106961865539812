import React, { createContext, useState } from "react";
import { flexRender } from "@tanstack/react-table";
import "./ReportsTableRow.css";

export const ReportsTableRowContext = createContext();

export default function ReportsTableRow({
  row,
  enableRowSelection,
  handleRowSelection,
}) {
  const [checkboxLoading, setCheckboxLoading] = useState(false);

  const onRowSelect = async () => {
    setCheckboxLoading(true);
    await handleRowSelection(row);
    setCheckboxLoading(false);
  };

  const disableRow = enableRowSelection && row.original.specs_flag === "0";

  return (
    <ReportsTableRowContext.Provider value={{ checkboxLoading, setCheckboxLoading, onRowSelect }}>
      <tr
        key={row.id}
        className={`ReportsTableRow${row.getIsSelected() ? "--selected" : ""} ${enableRowSelection && !disableRow ? "ReportsTableRow--selection-enabled" : ""} ${disableRow ? "ReportsTableRow--selection-disabled" : ""}`}
        onClick={!disableRow && !checkboxLoading ? onRowSelect : null}
      >
        {row.getVisibleCells().map((cell) => {
          const isEmpty = (cell.getValue() === undefined || cell.getValue().trim() === "");
          const {
            className, align, onClick, cell: cellRenderer,
          } = cell.column.columnDef;
          return (
            <td
              key={cell.id}
              className={`${className} ${isEmpty ? "ReportsTable__EmptyCell" : ""}`}
              style={{ textAlign: align }}
              onClick={onClick ? (e) => onClick(row.id, e) : null}
            >
              {flexRender(cellRenderer, cell.getContext())}
            </td>
          );
        })}
      </tr>
    </ReportsTableRowContext.Provider>
  );
}

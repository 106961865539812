import axios from "axios";

import { getUserCredentials } from "../utils/common";
import { API_URL } from "../utils/Constant";
import { getViewAsPerStatusCode, formatDateForDisplay, isNonEmptyField } from "../utils/helpers";

/**
 * get all the fields for product reports
 * @returns {Object} {success, message, data[0].Fields[0]}
 */
export const getProductFields = async (skipMaintenanceRedirect = false) => {
  try {
    const resp = await axios.get(`${API_URL}/productfields/`);
    const {
      fields,
      link_report,
    } = resp?.data?.result;

    if (fields && fields.length > 0) {
      const fieldsMap = {};
      fields.forEach((fieldObj) => {
        fieldsMap[fieldObj.json_field] = fieldObj;
      });
      return {
        success: true, fields, fieldsMap, link_report,
      };
    }
    const message = "No data from API.";
    return { success: false, message };
  } catch (e) {
    if (!skipMaintenanceRedirect) {
      getViewAsPerStatusCode(e);
    }
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * gets all the product reports for the reqUrl or the first page, that are a result of the applied search filters
 * @param {string} reqUrl pagelink for which all the product reports are to be fetched
 * @param {*} params {fromm, to, specs_flag, search, retest}
 * @returns {Object} {files, prevPageLink, nextPageLink, lastPageLink, totalPage, dates}
 */

export const getReports = async (reqUrl, params, signal = null) => {
  try {
    let response;
    if (!reqUrl) {
      response = await axios.get(`${API_URL}/reports/`, {
        params: {
          page: "1",
          fromm: "",
          to: "",
          specs_flag: "",
          search: "",
          retest: "",
          ...params,
        },
        signal,
      });
    } else {
      response = await axios.get(`${API_URL}${reqUrl.substr(4)}`, { signal }); // take substring to remove '/api' at the beginning of the url (it's already incuded in API_URL)
    }
    const data = await response.data;
    const {
      prev_page_link: prevPageLink, next_page_link: nextPageLink, last_page_link: lastPageLink, total_page: totalPage, dates,
    } = data;
    const res = {
      files: [], prevPageLink, nextPageLink, lastPageLink, totalPage, dates,
    };
    if (isNonEmptyField(data, "result") && isNonEmptyField(data.result, 0)) {
      data.result.forEach((report) => {
        if (report.received_date) {
          report.received_date = formatDateForDisplay(report.received_date);
        }
        if (report.report_date) {
          report.report_date = formatDateForDisplay(report.report_date);
        }
      });
      res.files = data.result;
    }
    return { success: true, ...res };
  } catch (e) {
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong.", canceled: axios.isCancel(e) };
  }
};

/**
 * used to rearrange or change the positions of the dynamic table fields
 * @param {*} payload {field, position} the name of the table column to be re-arranged and the new position of the table column.
 * @returns {Object} {success, res} object returning table fields in the new order
 */
export const changePosition = async (payload) => {
  try {
    const params = {
      field: payload.field || "",
      position: payload.position || "",
    };
    const response = await axios.get(`${API_URL}/productfieldindexing/`, { params });
    const data = await response.data;

    return { success: true, fields: data.result };
  } catch (e) {
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Used to change the field names of the product report table
 * @param {} payload {field, new_field} takes the old and new names of the table field
 * @returns {Object} {success, res} returns the edited values of the product table fields
 */
export const editTableTitle = async (payload) => {
  try {
    const params = {
      field: payload.field,
      new_field: payload.new_field,
    };
    const response = await axios.get(`${API_URL}/editproductfields/`, { params });
    const data = await response.data;

    return { success: true, fields: data.result };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * gets the spec card (triggered when clicked on the the status of the results in specification column)
 * @param {*} payload {index, index_id, report_id, sample_id, received_date, sample_type, method_reference}
 * @returns {Object} {success, res={index0, specs_flag, title, report_result, card_id}}
 */
export const getSpec = async (payload, methodReference = false) => {
  try {
    const params = {
      index: payload.index,
      index_id: payload.index_id,
      report_id: payload.report_id,
      sample_id: payload.sample_id,
      received_date: payload.received_date,
      sample_type: payload.sample_type,
      method_reference: methodReference,
    };

    const response = await axios.get(`${API_URL}/reportspecs/`, { params });
    const data = await response.data;
    const res = { spec: {} };
    if (data && data.length > 0) {
      const result = data[0].result;
      if (result && result.length > 0) {
        const spec = result[0];
        res.spec = spec;
      }
    }

    return { success: true, ...res };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * used to send the retest request
 * @param {*} payload {sample_id, received_at, tests, sample_type}
 * @returns {} message
 */
export const requestRetest = async (payload) => {
  try {
    const user = getUserCredentials();
    const params = {
      user_email: user.user_email || "",
      sample_id: payload.sample_id,
      received_at: payload.received_at,
      tests: payload.tests,
      sample_type: payload.sample_type,
    };
    const response = await axios.post(`${API_URL}/requestretest/`, params);
    if (response && response.data && response.data.message === "Success") {
      return { success: true };
    }
    return { success: false, message: response.data.message || "Retest request has failed." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Retest request has failed." };
  }
};

/**
 * Used to share product report via email
 * @param {*} payload {recipients_email_address_lst, email_user_input, sample_id, received_date, reports, index_id}
 * @returns
 * // page not found error
 */
export const sendShareEmails = async (payload) => {
  try {
    const user = getUserCredentials();
    const response = await axios.post(`${API_URL}/sharetestresults/`, {
      recipients_email_address_lst: payload.email_list,
      email_user_input: payload.message,
      user_name: user.user_name || "dev",
      sample_id: payload.sample_id,
      received_date: payload.received_date,
      reports: payload.reports,
      index_id: payload.index_id,
    });

    return { success: true, ...response.data };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Fetches pesticide report detail.
 * @param {object} params Object of sample_id, sample_type, received_date and retest_date
 * @returns {object} pesticide detailed data
 */
export const getReportSpecDetail = async ({
  sample_id, sample_type, received_date, retest_date, test,
}) => {
  try {
    const params = {
      sample_id,
      sample_type,
      received_date,
      retest_date,
      test,
    };
    const response = await axios.get(`${API_URL}/reportspecsdetail/`, { params });
    return { success: true, ...response.data };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Calls api to update countries of specified company
 * @param {string} customer_id Customer id
 * @param {string[]} countries_lst List of country codes
 */
export const updatePesticideCountrySpecDetails = async (countries_lst) => {
  try {
    const payload = { countries_lst };
    const response = await axios.patch(`${API_URL}/reportspecsdetailupdatecountries/`, payload);
    return { success: true, ...response.data };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

import React, { useState, useEffect } from "react";
import { usePDF } from "@react-pdf/renderer";
import { pdfjs } from "react-pdf";
import PdfPreview from "../../../../../Common/PdfPreview";
// import TemplateAlignButton from "./TemplateAlignButton";
import PDF from "../../PDF/PDF";
// import Canvas from "../../PDF/Canvas";
import "./builderPreview.css";
import "./coaPreview.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function BuilderPreview({
  inputs,
  handleAlign,
  alignLeft,
  isCustomizingCoa,
  setLoadingPreview,
  uniqueTests,
  enableTests,
}) {
  // const [alignLeft, setAlignLeft] = useState(true);
  // const [pdf] = usePDF({ document: <PDF inputs={inputs} /> });
  const [pdf, updatePDF] = usePDF({
    document: PDF({
      inputs, alignLeft, isTemplatePdf: true, isCustomizingCoa, uniqueTests, enableTests,
    }),
  });
  const [pdfUrl, setPdfUrl] = useState("");

  // useEffect(() => {
  //   console.log(pdf, props);
  // }, [updatePDF]);

  useEffect(() => {
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      setPdfUrl("");
    }
    updatePDF({
      document: PDF({
        inputs, alignLeft, isTemplatePdf: true, uniqueTests, enableTests,
      }),
    });

    return (() => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    });
  }, [alignLeft]); //eslint-disable-line

  useEffect(() => {
    if (pdf.blob) {
      setPdfUrl(URL.createObjectURL(pdf.blob));
      setLoadingPreview(false);
    }

    if (pdf.error) {
      setLoadingPreview(false);
    }
  }, [pdf]); //eslint-disable-line

  const handleAlignTemplate = () => { // eslint-disable-line
    handleAlign();
  };

  const values = Object.values(inputs).filter((input) => input.length > 0);

  const hasData = values.length > 1;
  if (!hasData) {
    return (
      <div className="builderPreviewSection">
        Add your details in edit mode to customize the COA.
      </div>
    );
  }
  if (hasData) {
    return (
      <div className={`BuilderPreviewContainer ${isCustomizingCoa ? "BuilderPreviewContainer--customizingCoa" : ""}`}>
        {/* <div style={{ height: "100%", padding: "45px 75px 0px 85px" }}> */}
        {/* <Canvas inputs={inputs} /> */}
        <PdfPreview
          blob={pdfUrl}
          height="78vh"
          scaleType={isCustomizingCoa ? undefined : "PageWidth"}
          showLoading={!pdfUrl}
          showError={pdf.error}
          // handleLoadingDone={() => setLoadingPreview(false)}
        />
        {/* </div> */}
        {/* <TemplateAlignButton alignLeft={alignLeft} handleAlignTemplate={handleAlignTemplate} /> */}
      </div>
    );
  }
  return null;
}

import React, { useState } from "react";
import { Modal } from "antd";
import EnvConfirmModal from "../EnvConfirmModal";
import "./CreateEnvModal.css";
import StepCircle from "./StepCircle";
import UploadSwabFiles from "./UploadSwabFiles";
import UploadMap from "./UploadMap";

export default function CreateEnvModal(props) {
  const {
    toggleShowCreateEnvModal,
    addListOfPoints,
    handleUploadImage,
    ifOnlyUploadSwabFile,
    updatePinsList,
    openCreateEnvModalFromTab,
    openFromEmptyPage,
    clearFilterCalendarAndSelected,
    linkPatternFields,
  } = props;
  const [step, setStep] = useState(1);
  const [stepOneStatus, setStepOneStatus] = useState(1); // 0: waiting, 1: processing, 2: finsihed, 3: error
  const [stepTwoStatus, setStepTwoStatus] = useState(0); // 0: waiting, 1: processing, 2: finsihed, 3: error
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const [popupType, setPopupType] = useState("main-modal"); // used to determine whether the cancel confirmation is clicked from the delete file button or the cancel modal btn
  const [currentFile, setCurrentFile] = useState(); // current selected swab file from the UploadSwabFiles component
  const [fileToDelete, setFileToDelete] = useState(); // selected swab file which need to be deleted
  const [emtpyFilesOrMapStatus, setEmptyFileOrMapStatus] = useState(true); // is true if no file is selected or have been saved, users can close the modal without confirmation, vice versa.

  /**
   * A function to handle the Cancel button on the main screen
   * 1. Set the popup type to "main-modal"
   * 2. If there is no files uploaded currently, then close the entire upload modal
   *    else, show the cancel confirmation popup.
   */
  const handleClickCancel = () => {
    setPopupType("main-modal");
    if (emtpyFilesOrMapStatus) {
      toggleShowCreateEnvModal();
    } else {
      setShowCancelConfirmModal(true);
    }
  };

  /**
   * Change the popup wording and set the current selected file
   * @param {*} type the confirmation popup type, either main-modal or file-modal
   * @param {*} status toggle the confirmation popup
   * @param {*} file the current selected file
   */
  const handleDeleteIconClick = (type, showConfirmation, file) => {
    setPopupType(type);
    setShowCancelConfirmModal(showConfirmation);
    setCurrentFile(file);
  };

  /**
   * When the delete file button is confirmed, then
   * 1. Set the fileToDelete variable so that the UploadSwabFiles component will call the handleDeleteFile function.
   * 2. Close the confirmation modal.
   */
  const handleDeleteFileClick = () => {
    setFileToDelete(currentFile);
    setShowCancelConfirmModal(false);
  };

  /**
   * Proceed to the step of upload map
   * 1. Update step one status to "finished"
   * 2. Update step two status to "processing"
   * 3. Set Step to 2 to display the upload map view
   * 4. Set back the confirmation popup type to "main-modal"
   */
  const proceedToUploadMap = () => {
    setStepOneStatus(2);
    setStepTwoStatus(1);
    setStep(2);
    setPopupType("main-modal");
  };

  /**
   * A function to set the status of current upload list: either true (there is some files uploaded) or false (no file uploaded)
   * @param {*} hasData boolean value to detemine if there is any uploaded files or map on the current upload list.
   */
  const setFilesOrMapStatus = (hasData) => {
    setEmptyFileOrMapStatus(hasData);
  };

  /** Convert the size of a file from bytes to proper unit
   * @param {Number} number size of a file
   * @return {String} file size to show on modal
   */
  const returnFileSize = (number) => {
    if (number < 1024) {
      return `${number} bytes`;
    } if (number >= 1024 && number < 1048576) {
      const numberInKB = number / 1024;
      return `${Math.round(numberInKB * 10) / 10} KB`; // round to 1 decimal place.
    } if (number >= 1048576) {
      const numberInMB = number / 1048576;
      return `${Math.round(numberInMB * 10) / 10} MB`; // round to 1 decimal place.
    }
    return "";
  };

  return (
    <>
      <Modal
        open
        maskClosable={false}
        closable={false}
        centered
        destroyOnClose
        footer={null}
        width="55vw"
        wrapClassName="create-env-modal"
      >
        {ifOnlyUploadSwabFile
          ? (
            <>
              {/* Only allow users to see "Upload Swab Files" page */}
              <header className={`create-env-modal-header ${ifOnlyUploadSwabFile ? "create-env-modal-header-only-swab" : ""}`}>
                <h2>Upload Swab Files</h2>
              </header>
              <UploadSwabFiles
                addListOfPoints={addListOfPoints}
                handleClickCancel={handleClickCancel}
                currentFile={fileToDelete}
                handleDeleteIconClick={(type, status, file) => handleDeleteIconClick(type, status, file)}
                proceedToUploadMap={proceedToUploadMap}
                returnFileSize={returnFileSize}
                ifOnlyUploadSwabFile={ifOnlyUploadSwabFile}
                toggleShowCreateEnvModal={toggleShowCreateEnvModal}
                updatePinsList={updatePinsList}
                emptyFilesOrMap={(val) => setFilesOrMapStatus(val)}
                openCreateEnvModalFromTab={openCreateEnvModalFromTab}
                clearFilterCalendarAndSelected={clearFilterCalendarAndSelected}
                linkPatternFields={linkPatternFields}
              />
            </>
          )
          : (
            <>
              {/* Allow user to upload swab files, then upload a map */}
              <header className="create-env-modal-header">
                <h2>Create an Environment</h2>
                <div className="create-env-modal-steps-process">
                  <StepCircle number={1} status={stepOneStatus} openCreateEnvModalFromTab={openCreateEnvModalFromTab} />
                  <div className={stepOneStatus !== 2 ? "create-env-modal-steps-process-blue-bar-step-one" : "create-env-modal-steps-process-blue-bar-step-two"} />
                  <StepCircle number={2} status={stepTwoStatus} />
                </div>
              </header>
              {
                step === 1
                  ? (
                    <UploadSwabFiles
                      addListOfPoints={addListOfPoints}
                      handleClickCancel={handleClickCancel}
                      currentFile={fileToDelete}
                      handleDeleteIconClick={(type, status, file) => handleDeleteIconClick(type, status, file)}
                      proceedToUploadMap={proceedToUploadMap}
                      setStep={setStep}
                      returnFileSize={returnFileSize}
                      ifOnlyUploadSwabFile={ifOnlyUploadSwabFile}
                      updatePinsList={updatePinsList}
                      emptyFilesOrMap={(val) => setFilesOrMapStatus(val)}
                      linkPatternFields={linkPatternFields}
                      openFromEmptyPage={openFromEmptyPage}
                      openCreateEnvModalFromTab={openCreateEnvModalFromTab}
                      clearFilterCalendarAndSelected={clearFilterCalendarAndSelected}
                    />
                  ) : (
                    <UploadMap
                      handleClickCancel={handleClickCancel}
                      toggleShowCreateEnvModal={toggleShowCreateEnvModal}
                      handleUploadImage={handleUploadImage}
                      returnFileSize={returnFileSize}
                      uploadMapStatusControl={(val) => setStepTwoStatus(val)}
                      emptyFilesOrMap={(val) => setFilesOrMapStatus(val)}
                    />
                  )
                }
            </>
          )}
      </Modal>

      {showCancelConfirmModal && (
        <EnvConfirmModal
          headerText={popupType === "main-modal" ? "Are you sure ?" : "Delete File"}
          bodyText={popupType === "main-modal" ? "Closing the application now will result in loss of data. Complete uploading of all files to prevent this." : "Are you sure you want to delete this file?"}
          buttonText={["Cancel", popupType === "main-modal" ? "Exit" : "Delete"]}
          buttonFunctions={[() => setShowCancelConfirmModal(false), popupType === "main-modal" ? () => toggleShowCreateEnvModal() : handleDeleteFileClick]}
        />
      )}
    </>
  );
}

import React from "react";

import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";

class SpecManagerInput extends React.Component {
  static isValid(text) {
    const split = text.split("");
    const allowedChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", ","];
    let currPeriods = 0;

    for (let i = 0; i < split.length; i++) {
      if (split[i] === "." && currPeriods !== 1) {
        currPeriods += 1;
      } else if (split[i] === "." && currPeriods === 1) {
        return {
          status: false,
          errorMessage: null,
        };
      }

      if (i < split.length - 1) {
        if (split[i] === "." && split[i + 1] === ".") {
          return {
            status: false,
            errorMessage: null,
          };
        }
      }

      if (allowedChars.indexOf(split[i]) < 0) {
        let invalidChar = split[i];
        if (split[i] === " ") invalidChar = "space";

        return {
          status: false,
          errorMessage: `Invalid input, remove ${invalidChar}`,
        };
      }
    }

    return {
      status: true,
      errorMessage: null,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      cursorPosition: 0,
      errorMessage: "",
      focused: false,
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { value } = this.props;

    const valid = SpecManagerInput.isValid(value);
    this.updateErrorMessage(valid, false);
  }

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  }

  onKeyDown = (e) => {
    const { onChange } = this.props;
    if (e.key === "Backspace") {
      // console.log("backspace");
      e.preventDefault();
      const selectionPositionStart = e.target.selectionStart;
      const selectionPositionEnd = e.target.selectionEnd;

      const singleSelected = selectionPositionStart === selectionPositionEnd;

      let text = e.target.value;

      if (singleSelected && selectionPositionStart !== 0) {
        text = text.slice(0, selectionPositionStart - 1) + text.slice(selectionPositionStart);
      } else {
        text = text.slice(0, selectionPositionStart) + text.slice(selectionPositionEnd);
      }

      this.setState({
        cursorPosition: singleSelected && selectionPositionStart !== 0 ? selectionPositionStart - 1 : selectionPositionStart,
      }, () => {
        const { cursorPosition } = this.state;
        this.inputRef.current.selectionStart = cursorPosition;
        this.inputRef.current.selectionEnd = cursorPosition;
      });
      onChange(text);

      const valid = SpecManagerInput.isValid(text);
      this.updateErrorMessage(valid);
    } else if (e.key === "Delete") {
      e.preventDefault();
      const selectionPositionStart = e.target.selectionStart;
      const selectionPositionEnd = e.target.selectionEnd;

      const singleSelected = selectionPositionStart === selectionPositionEnd;

      let text = e.target.value;

      if (singleSelected) {
        text = text.slice(0, selectionPositionStart) + text.slice(selectionPositionStart + 1);
      } else {
        text = text.slice(0, selectionPositionStart) + text.slice(selectionPositionEnd);
      }

      this.setState({
        cursorPosition: selectionPositionStart,
      }, () => {
        const { cursorPosition } = this.state;
        this.inputRef.current.selectionStart = cursorPosition;
        this.inputRef.current.selectionEnd = cursorPosition;
      });
      onChange(text);

      const valid = SpecManagerInput.isValid(text);
      this.updateErrorMessage(valid);
    }
  }

  onChange = (e) => {
    const { onChange } = this.props;

    const text = e.target.value;
    const valid = SpecManagerInput.isValid(text);

    if (valid.status) {
      onChange(text);
      this.updateErrorMessage(valid);
    }
  }

  updateErrorMessage(validObj, focus = true) {
    if (validObj.status === true) {
      this.setState({
        errorMessage: "",
      });
      if (focus) window.setTimeout(() => this.inputRef.current.focus(), 0);
    }

    if (validObj.errorMessage !== null) {
      this.setState({
        errorMessage: validObj.errorMessage,
      });
    }
  }

  render() {
    const {
      placeHolder,
      value,
      width,
      height,
      additionalClassName,
    } = this.props;

    const { errorMessage, focused } = this.state;

    return (
      <StyledTooltip
        title={focused ? errorMessage : ""}
        placement="top"
      >
        <input
          type="text"
          className={errorMessage !== "" && focused ? `${additionalClassName} envSpecManagerCustomInput envSpecManagerWarning` : `${additionalClassName} envSpecManagerCustomInput`}
          ref={this.inputRef}
          style={{
            borderRadius: "3px",
            backgroundColor: "#FDFDFD",
            paddingLeft: "5px",
            paddingRight: "5px",
            height: height || "32px",
            width: width || "100px",
          }}
          placeholder={placeHolder}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          onKeyDown={this.onKeyDown}
          value={value || ""}
          onFocus={() => {
            this.setState({
              focused: true,
            });
          }}
          onBlur={() => {
            this.setState({
              focused: false,
            });
          }}
        />
      </StyledTooltip>
    );
  }
}
export default SpecManagerInput;

import React from "react";

import {
  Document, Page, View, StyleSheet, Font,
} from "@react-pdf/renderer";

import ReceiptPDFFooter from "./ReceiptPDFFooter";
import ReceiptPDFHeader from "./ReceiptPDFHeader";
import ReceiptPDFSamplesInfo from "./ReceiptPDFSamplesInfo";
import ReceiptPDFSamplesList from "./ReceiptPDFSamplesList";
import ReceiptPDFSenderInfo from "./ReceiptPDFSenderInfo";

import RobotoMedium from "../../../../../assets/fonts/roboto/Roboto-Medium.ttf";
import Roboto from "../../../../../assets/fonts/roboto/Roboto-Regular.ttf";

export default function ReceiptPDF({
  submission,
  customerInfo,
  CUSTOMER_INFO_FIELDS_BY_ROW,
  FOOTER_TEXT,
  getFileName,
}) {
  Font.register({ family: "Roboto-400", src: Roboto });
  Font.register({ family: "Roboto-500", src: RobotoMedium });
  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundColor: "#FFFFFF",
      padding: "36 36 18 36",
      color: "#000000",
      fontFamily: "Roboto-500",
    },
    section: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
  });

  return (
    <Document title={getFileName()}>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.section}>
          <ReceiptPDFHeader submission={submission} />
          <ReceiptPDFSamplesInfo submission={submission} />
          <ReceiptPDFSenderInfo customerInfo={customerInfo} CUSTOMER_INFO_FIELDS_BY_ROW={CUSTOMER_INFO_FIELDS_BY_ROW} />
        </View>
        <ReceiptPDFFooter FOOTER_TEXT={FOOTER_TEXT} />
      </Page>
      <Page size="LETTER" style={styles.page}>
        <ReceiptPDFSamplesList submission={submission} />
      </Page>
    </Document>
  );
}

import React from "react";
import DataFieldsSection from "./DataFieldsSection";
import TextBoxSection from "./TextBoxSection";
import "./builderComponents.css";

export default function BuilderComponents(props) {
  const {
    previewTemplate,
    commonDataFieldsInUse,
    customizedDataFieldsInUse,
    setAllCustomizedDataFields,
    setAllDataFields,
    allDataFields,
    isCustomizingCoa,
  } = props;
  return (
    <div className="builderComponents">
      {isCustomizingCoa ? (
        <DataFieldsSection
          previewTemplate={previewTemplate}
          commonDataFieldsInUse={commonDataFieldsInUse}
          customizedDataFieldsInUse={customizedDataFieldsInUse}
          setAllCustomizedDataFields={setAllCustomizedDataFields}
          setAllDataFields={setAllDataFields}
          allDataFields={allDataFields}
        />
      ) : (
        <TextBoxSection
          previewTemplate={previewTemplate}
        />
      )}
    </div>
  );
}

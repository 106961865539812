import React from "react";
import pumpkin from "../../../../../assets/images/product/template/pumpkin.png";
import "./NoResultFound.css";

export default function NoResultFound() {
  return (
    <div className="NoResultFoundContainer">
      <img
        alt="no result"
        src={pumpkin}
        className="NoResultFoundContainer__Img"
      />
      <div className="NoResultFoundContainer__Text">
        No Result Found
      </div>
    </div>
  );
}

import React from "react";
import Add from "../../../../../../assets/images/product/template/AddBlue.png";

export default function ContentAddItem(props) {
  const { handleCreateSection } = props;
  return (
    <div
      onClick={handleCreateSection}
      className="templateBuilderContentAddItem"
    >
      <img src={Add} alt="" />
      Add section
    </div>

  );
}

import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import COALoadingPage from "../Common/COALoadingPage";
import NoResultFound from "../Common/NoResultFound";
import LoadingOverlay from "../../../../Common/LoadingOverlay";
import {
  getProductFields,
  getReports,
  getSpec,
} from "../../../../../actions/reports";
import {
  getTemplates,
} from "../../../../../actions/productTemplate";
import Header from "../Common/Header";
import Templates from "../Templates";
import "../styles/coa.css";
import "../styles/templates.css";
import ReportsTable from "../../../Report/ReportsTable/ReportsTable";
import { sortAndFilterProductReportFields } from "../../../Report/utils";
import "./COAReport.css";

export default function COAReport({
  handleAddCoa,
  isCreatingCoa,
  backToChooseCoaReport,
  handleCustomizeReportTemplate,
  linkReportKeys,
  setLinkReportKeys,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [reportSpecsLoading, setReportSpecsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fields, setFields] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [nextPageLink, setNextPageLink] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingNewContent, setLoadingNewContent] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [selectedReports, setSelectedReports] = useState({});
  const coaReportScrollbar = useRef(null);
  const [indexOfTemplateSelected, setIndexOfTemplateSelected] = useState(-1);
  const [, setTemplateApiController] = useState(null);

  /** Reset the template related states */
  const clearTemplateSelect = () => {
    setSelectedReports((prevState) => {
      const newState = { ...prevState };
      delete newState.template;
      setIndexOfTemplateSelected(-1);
      return newState;
    });
  };

  /**
   * Use the AbortController to cancel the last producttemplates/ call if the last call was still in progress.
   * @param {*} controller
   */
  const abortPrevTemplateApiCall = (controller) => {
    setTemplateApiController((prevController) => {
      /** cancel the last api call */
      if (prevController) {
        prevController.abort();
      }
      /** delete the template field to aovid the user click the "Customize Report" button */
      setSelectedReports((prevState) => {
        const newState = { ...prevState };
        delete newState.template;
        return newState;
      });
      return controller;
    });
  };

  /**
   * Make an api call to get the selected template information
   * @param {*} templateIndex
   */
  const saveTemplateIdForAddingCoa = async (templateIndex) => {
    if (templateIndex === -1 || templateIndex === indexOfTemplateSelected) {
      clearTemplateSelect();
    } else {
      const params = { template_id: templateList[templateIndex].template_id };

      /** Cancel the last api call if it is not finished yet */
      const controller = new AbortController();
      abortPrevTemplateApiCall(controller);

      const response = await getTemplates(params, controller.signal);
      if (response && response.success) {
        /** update the selected template index and the report state */
        setIndexOfTemplateSelected(templateIndex);
        setSelectedReports((prevState) => ({
          ...prevState,
          template: response.result[0],
        }));
      } else if ((!response || !response.success) && !response.cancel) {
        toast.error("Could not fetch template.");
        clearTemplateSelect();
      }
    }
  };

  const handleNavigation = () => {
    setIndexOfTemplateSelected(-1);
    backToChooseCoaReport(false);
  };

  useEffect(() => {
    const fetchReportsData = async () => {
      const fieldsData = await getProductFields();
      const filesData = await getReports();
      if (!fieldsData || !fieldsData.success || !filesData || !filesData.success) {
        setFileList([]);
        setIsLoading(false);
        toast.error("Could not fetch reports data.");
        return;
      }

      setLinkReportKeys(fieldsData.link_report);
      const sortedFilteredFields = sortAndFilterProductReportFields(fieldsData.link_report, fieldsData.fields);
      const {
        files: newFiles, nextPageLink: newNextPageLink, totalPage: newTotalPage,
      } = filesData;

      setCurrentPage(1);
      setTotalPage(newTotalPage);
      setNextPageLink(newNextPageLink);
      setFileList([...newFiles]);
      setLoadingNewContent(false);
      setFields(sortedFilteredFields);
      setIsLoading(false);
    };

    const fetchTemplates = async () => {
      setTemplateLoading(true);
      const response = await getTemplates();
      if (!response || !response.success) {
        toast.error("Could not fetch templates.");
        setTemplateList([]);
      } else {
        setTemplateList(response.result);
      }
      setTemplateLoading(false);
    };

    fetchReportsData();
    fetchTemplates();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (loadingNewContent) {
      coaReportScrollbar.current.scrollToBottom();
    }
  }, [loadingNewContent]);

  /**
   * If a user selects a sample, then make an api call to get the sample metadata.
   * @param {*} report
   * @param {*} fileIndex
   * @returns
   */
  const saveReportForAddingCoa = async (report, fileIndex) => {
    setReportSpecsLoading(true);
    const newObj = {};
    const params = {
      index: report.index,
      index_id: report.index_id,
      report_id: report.report_id,
      sample_id: report.sample_id,
      received_date: report.received_date,
      sample_type: report.sample_type,
    };
    const methodReference = true;
    const response = await getSpec(params, methodReference);
    if (response && response.success) {
      const specData = Object.entries(response.spec.report_result);
      // Sort the spec data by oldest to most recent
      const dataSorted = specData.sort((a, b) => Date.parse(a[0]) - Date.parse[b[0]]);
      // dataSorted will have a format of [ [date, [ ]], [date, [ ]] ]
      // since the oldest date will be first index, we choose index 0 and then index 1 for the array containing spec information
      let test_results = [];
      /** Get most recent data for all tests that have been run for this sample */
      if (dataSorted.length > 1) {
      // Filters out test results with a test_spec_flag of "0"
      // and retains only the most recent test result for each unique test name that has been processed
        const seen = new Set();
        const latestTestResults = {};
        dataSorted.forEach(([, testResults]) => {
          testResults.forEach((testObj) => {
            const testName = testObj.test;
            if (!seen.has(testName) && testObj.test_spec_flag !== "0") {
              if (!latestTestResults[testName]) {
                latestTestResults[testName] = testObj;
              }
              seen.add(testName);
            }
          });
        });
        test_results = Object.values(latestTestResults);
      } else {
        // Filters out test results with a test_spec_flag of "0"
        test_results = dataSorted[0][1].filter((testObj) => testObj.test_spec_flag !== "0");
      }

      if (test_results.length === 0) {
        toast.info("This sample does not have any results.");
        setReportSpecsLoading(false);
        return false;
      }

      /** update states and push the selected sample information */
      const {
        sample_id,
        sample_type,
        received_date,
      } = fileList[fileIndex];

      const linkReportValuesObj = {};
      linkReportKeys.forEach((json_field) => { linkReportValuesObj[json_field] = fileList[fileIndex][json_field] ?? ""; }); // populate sample_data with linked fields vals, will be used in COASave
      newObj.sample_data = {
        sample_id,
        sample_type,
        received_date,
        test_results,
        ...linkReportValuesObj,
      };
      newObj.report_index = fileIndex;
      setReportSpecsLoading(false);
      setSelectedReports((prevState) => {
        const newState = { ...prevState };
        if (newObj.sample_data) {
          newState[fileIndex] = newObj;
        }
        return newState;
      });
      return true;
    }
    setReportSpecsLoading(false);
    toast.error("Get sample report failed.");
    return false;
  };

  /**
   * A function which handles the on select action for each sample
   */
  const handleReportSelect = async (row, action) => {
    if (action === "deselect") {
      /** only need to update the report states for unchecking a checkbox */
      setSelectedReports((prevState) => {
        const newState = { ...prevState };
        delete newState[row.index];
        return newState;
      });
      return true;
    }
    if (action === "select") {
      const resp = await saveReportForAddingCoa(fileList[row.index], row.index);
      return resp;
    }
    return false;
  };

  const handlePageChangeScroll = async () => {
    const loadedAllData = currentPage === totalPage;
    if (!loadedAllData && !loadingNewContent) {
      setLoadingNewContent(true);
      const nextPage = nextPageLink ? parseInt(nextPageLink.split("page=")[1].split("&")[0]) : 1;

      const resp = await getReports(nextPageLink);
      if (resp) {
        const {
          files: newFiles, nextPageLink: newNextPageLink, totalPage: newTotalPage,
        } = resp;
        setTotalPage(newTotalPage);
        setNextPageLink(newNextPageLink);
        setFileList([...fileList, ...newFiles]);
        setLoadingNewContent(false);
        setCurrentPage(nextPage);
      }
    }
  };

  const handleSearch = async (value) => {
    const params = {
      fromm: "", // fromm is not a typo
      to: "",
      specs_flag: "",
      search: value,
      retest: "",
    };

    const response = await getReports("", params);
    if (!response || !response.success) {
      toast.error("Could not fetch reports data.");
      return;
    }

    setSelectedReports(() => {
      const newState = {};
      setIndexOfTemplateSelected(-1);
      return newState;
    });
    const {
      files: newFiles, nextPageLink: newNextPageLink, totalPage: newTotalPage,
    } = response;

    if (coaReportScrollbar && coaReportScrollbar.current && coaReportScrollbar.current.scrollToTop()) {
      coaReportScrollbar.current.scrollToTop();
    }
    setTotalPage(newTotalPage);
    setNextPageLink(newNextPageLink);
    setFileList([...newFiles]);
    setLoadingNewContent(false);
    setCurrentPage(1);
    setSearchValue(value);
    handleReportSelect(-1);
  };

  /**
   * Convert the selectedReports to the datastructure { data_fields, samples, link_report_keys } and then pass it to the TemplateBuilder component
   */
  const handleCustomizeReport = () => {
    const data_fileds = {};
    const samples = [];

    Object.entries(selectedReports).forEach(([key, value]) => {
      if (key !== "template") {
        samples.push(value.sample_data);
        const reportInfo = fileList[value.report_index];
        /** push all the field values into the same field key set */
        Object.entries(reportInfo).forEach(([fieldName, fieldValue]) => {
          /** if the field value is valid and the field name does not exist, then set it to an empty array */
          if (typeof fieldValue === "string" && fieldValue.length > 0 && !data_fileds[fieldName]) {
            data_fileds[fieldName] = [];
          }
          /** push the field value if it is not existed in the array */
          if (data_fileds[fieldName] && !data_fileds[fieldName].includes(fieldValue) && fieldValue !== "") {
            data_fileds[fieldName].push(fieldValue);
          }
        });
      }
    });
    const reports = { data_fileds, samples, link_report_keys: linkReportKeys };
    if (Object.keys(reports).length > 0 && selectedReports.template) {
      handleCustomizeReportTemplate({ selectedReports: reports, selectedTemplate: selectedReports.template });
    }
  };

  if (isLoading || templateLoading) {
    return (
      <COALoadingPage page="COAReport" />
    );
  }

  return (
    <div className="coaContainer">
      <div className="CoaReportWrap">
        { reportSpecsLoading && (<LoadingOverlay />)}
        <Header
          title="Your Reports"
          handleAddCoa={handleAddCoa}
          handleNavigation={handleNavigation}
          isCreatingCoa={isCreatingCoa}
          apiSearchCoas={handleSearch}
          showCreateReportButton
        />
        <p className="CoaSubHeader">Apply templates to your reports to create cutomized reports</p>
        <div className="CoaReportAndTemplate">
          <div className="coaReportContainer">
            <p className="CoaSubHeader__Second">Select a report and choose template</p>
            {!isLoading && (
              fileList.length === 0
                ? <NoResultFound />
                : (
                  <ReportsTable
                    wrapperClassName="COATableWrap"
                    fields={fields}
                    linkReportJsonFields={linkReportKeys}
                    setFields={setFields}
                    reports={fileList}
                    searchValToHighlight={searchValue}
                    loadingNewContent={loadingNewContent}
                    handleFetchNextPage={handlePageChangeScroll}
                    scrollbarRef={coaReportScrollbar}
                    loading={isLoading}
                    enableRowSelection
                    onRowSelection={handleReportSelect}
                    disableSampleResultsModalInteraction
                    disableTableHeaderInteraction
                    disableActions
                  />
                )
            )}
          </div>

          <div className="CoaReportAndTemplate__Template">
            <Templates
              templateList={templateList}
              reportIsSelected={Object.keys(selectedReports).length > 0}
              indexOfTemplateSelected={indexOfTemplateSelected}
              saveTemplateIdForAddingCoa={saveTemplateIdForAddingCoa}
              handleCustomizeReport={handleCustomizeReport}
              isReportAndTemplateSelected={Object.keys(selectedReports).length > 1 && selectedReports.template}
              isCreatingCoa
            />
          </div>
        </div>
      </div>
    </div>
  );
}

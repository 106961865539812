import React from "react";
import StyledButton from "../../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../../Common/UIComponents/StyledTooltip";
import closeFilterIcon from "../../../../assets/images/product/lightBlueCancelIcon.png";
import "./SpecButton.css";

class SpecButton extends React.Component {
  render() {
    const toolTipTitle = () => {
      const { disableFilterButton, specText } = this.props;
      if (specText === "Incomplete" && disableFilterButton) {
        return "No reports are available";
      }
      return "";
    };

    const {
      index,
      specText,
      specColor,
      specSelected,
      disableFilterButton,
      isActive,
      handleToggleFilter,
    } = this.props;

    return (
      <StyledTooltip
        title={toolTipTitle()}
        placement="top"
      >
        <StyledButton
          disabled={disableFilterButton}
          type={specSelected === index ? "primary" : "text"}
          ghost={specSelected === index}
          className={`${specSelected === index ? "SpecButton__SpecSelected" : "SpecButton__SpecNotSelected"} ${disableFilterButton ? "SpecButton__Spec--disabled" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleToggleFilter(index, specText);
          }}
        >
          <div
            className="specCategoryIcon"
            style={{ backgroundColor: specColor }}
          />
          <div className="reportStatusCategory">{specText}</div>
          {isActive && (
          <img
            className="reportStatusCategoryCloseFilterIcon"
            src={closeFilterIcon}
            alt="closeFilterIcon"
            width="14px"
          />
          )}
        </StyledButton>
      </StyledTooltip>
    );
  }
}

export default SpecButton;

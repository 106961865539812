import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import SignUpController from "./SignUp/SignUpController";
import ResetPasswordController from "./Login/ResetPasswordController";
import UnderMaintenance from "./UnderMaintenance";
import LoginController from "./Login/LoginController";
import Share from "./Share/Share";
import SampleSubmissionNav from "./SampleSubmission/SampleSubmissionNav";
import OnboardingFormController from "./Onboarding/OnboardingForm/OnboardingFormController";
import InviteFormController from "./Onboarding/InviteForm/InviteFormController";

const LoggedOut = ({ history }) => {
  useEffect(() => {
    // 3rd Party Sample Submission, incase the user navigates to a different route
    // Clear session storage
    sessionStorage.clear();
  }, []);
  return (
    <Switch>
      <Route exact path="/share/report" component={Share} />
      <Route exact path={["/login", "/login/support"]} component={LoginController} />
      <Route
        exact
        path={["/signup/member", "/signup/invite", "/signup/success", "/signup/invite/success"]}
        component={(props) => <SignUpController history={props.history} />}
      />
      <Route exact path={["/invite-customer", "/invite-customer/success"]} component={(props) => <InviteFormController history={props.history} />} />
      <Route path="/onboarding(/success)?" exact render={(props) => <OnboardingFormController history={props.history} />} />
      <Route
        exact
        path={["/resetpassword", "/resetpassword/success", "/resetpassword/invalid"]}
        component={(props) => <ResetPasswordController history={props.history} />}
      />
      <Route path="/sample-submission" render={() => (<SampleSubmissionNav loggedOut history={history} />)} />
      <Route path="/maintenance" component={UnderMaintenance} />
      <Route
        path="/"
        render={({ location }) => {
        /** Check if url is the reset password link from firebase (search parameter "mode" is "resetPassword"), redirect to resetpassword if it is */
          const searchParams = new URLSearchParams(location.search);
          const mode = searchParams.get("mode");
          if (mode && mode === "resetPassword") {
            return <Redirect to={`/resetpassword${location.search}`} />;
          }
          return <Redirect to="/login" />;
        }}
      />
    </Switch>
  );
};

export default LoggedOut;

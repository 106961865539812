import React from "react";

import "./PopoverMenu.css";
import { Popover } from "antd";

export default function PopoverMenu({
  children, menuItems = [], open, setOpen,
}) {
  const content = (
    <ul className="PopoverMenu__MenuList">
      {menuItems.map((item) => {
        const isVisible = typeof item.isVisible === "function" ? item.isVisible() : item.isVisible;
        return (
          <React.Fragment key={item.title}>
            {isVisible ? (
              <li className="PopoverMenu__MenuListItem" onClick={item.onClick}>
                {item.icon}
                <span>{item.title}</span>
              </li>
            ) : null}
          </React.Fragment>
        );
      })}
    </ul>
  );

  return (
    <Popover
      rootClassName="PopoverMenu__Root"
      content={content}
      trigger="click"
      placement="bottomLeft"
      arrow={false}
      // afterOpenChange={getIsOpen}
      open={open}
      onOpenChange={(newOpen) => {
        setOpen(newOpen);
      }}
    >
      {children}
    </Popover>
  );
}

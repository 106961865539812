import React, { useMemo, useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Draggable } from "react-beautiful-dnd";
import { Scrollbar } from "react-scrollbars-custom";
import { toast } from "react-toastify";

import useAutoHideScrollbar from "../../Common/hooks/useAutoHideScrollbar";
import StyledButton from "../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../Common/UIComponents/StyledTooltip";
import EnvConfirmModal from "../Analytics/Components/EnvConfirmModal";
import PopoverMenu from "../components/PopoverMenu";
import LocationDetails, { isMarkingAllowed } from "./LocationDetails";

import { submitSamples } from "../../../actions/envCalendar";

import { ReactComponent as ContextMenuIcon } from "../../../assets/images/environment/ContextMenuIcon.svg";
import { ReactComponent as EditPencilIcon } from "../../../assets/images/environment/EditPencilIcon.svg";
import { ReactComponent as PrinterIcon } from "../../../assets/images/environment/PrinterIcon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/images/environment/TrashIcon.svg";

import "./EventCard.css";

const DefaultSubmitModalObj = { open: false, bodyText: "" };
const MAX_TITLE_LENGTH_FOR_TOOLTIP = 100;

export default function EventCard({
  data,
  index,
  setDeleteEventData,
  setIsDeletingConfirm,
  afterMarkingSample,
  onEdit,
  cardLoading,
  setCardLoading,
  onPrintLabel,
}) {
  const [openPopover, setOpenPopover] = useState(false);
  const [scrollbarRef, scrollbarStyle] = useAutoHideScrollbar();
  const [submitModalObj, setSubmitModalObj] = useState(DefaultSubmitModalObj);
  const [submitLoading, setSubmitLoading] = useState(false);
  const popoverMenuItems = [
    {
      title: "Edit",
      icon: <EditPencilIcon />,
      isVisible: () => data.status === "-2" || data.status === "-1",
      onClick: () => {
        setOpenPopover(false);
        onEdit(data);
      },
    },
    {
      title: "Delete",
      icon: <TrashIcon />,
      isVisible: () => data.status === "-2" || data.status === "-1",
      onClick: () => {
        setOpenPopover(false);
        const { event_id, submission_id, event_frequency } = data;
        setDeleteEventData((state) => ({
          ...state, event_id, submission_id, event_frequency,
        }));
        setIsDeletingConfirm(true);
      },
    },
    {
      title: "Print",
      icon: <PrinterIcon />,
      isVisible: true,
      onClick: () => {
        setOpenPopover(false);
        onPrintLabel(data);
      },
    },
  ];
  const statusCodeToName = {
    "-2": "Draft",
    "-1": "Submitted",
    0: "Samples Received",
    1: "Testing in Progress",
    2: "Testing Complete",
  };
  const showSubmit = useMemo(() => { // show the submit button if the submission has not been submitted yet and there is at least one sample has been sampled
    const { status, samples_list, event_date } = data;
    const atLeastOneSampled = samples_list.filter((sample) => sample.sampled).length > 0;
    return status === "-2" && atLeastOneSampled && isMarkingAllowed(event_date);
  }, [data]);

  /**
   * Show submit confirmation
   */
  const showSubmitConfirmation = () => {
    const notSampled = data.samples_list.filter((sample) => !sample.sampled).length;
    const bodyText = notSampled === 0 ? `Samples (${data.samples_list.length}) will be submitted for testing.` : (
      <div>
        Not all samples have been sampled.
        <br />
        Are you sure want to submit the submission?
      </div>
    );
    setSubmitModalObj({
      open: true,
      bodyText,
    });
  };

  /**
   * Handles sample submit
   */
  const handleSubmitSamples = async () => {
    setSubmitLoading(true);
    const payload = {
      submission_id: data.submission_id,
      action: "Submit",
    };
    const { success, message } = await submitSamples(payload);
    if (success) {
      toast.success(message);
      setSubmitModalObj(DefaultSubmitModalObj);
      await afterMarkingSample();
    } else {
      toast.error(message);
    }
    setSubmitLoading(false);
  };

  return (
    <Draggable key={data.submission_id} index={index} draggableId={data.submission_id} isDragDisabled={data.status !== "-2"}>
      {(provided) => (
        <div
          className="EventGrid__EventCard"
          ref={provided.innerRef}
          {...provided.draggableProps} // eslint-disable-line
          {...provided.dragHandleProps} // eslint-disable-line
        >
          <div className="EventGrid__EventCardHeader">
            {data.submission_name.length > MAX_TITLE_LENGTH_FOR_TOOLTIP ? (
              <StyledTooltip title={data.submission_name} className="EventGrid__EventCardHeader__Tooltip">
                <p>{data.submission_name}</p>
              </StyledTooltip>
            ) : <p>{data.submission_name}</p>}
            <PopoverMenu menuItems={popoverMenuItems} open={openPopover} setOpen={setOpenPopover}>
              <button type="button">
                <ContextMenuIcon />
              </button>
            </PopoverMenu>
          </div>
          {data.scheduled_from === "report-add" && <p className="Event__Location">Created Via Reports</p>}
          {<p className="Event__Location">{statusCodeToName[data.status]}</p>}
          <Scrollbar
            className="Event_LocationsList__Scrollbar"
            elementRef={scrollbarRef}
            trackYProps={scrollbarStyle}
          >
            <ul className="Event_LocationsList">
              {data.samples_list.map((sample) => (
                <LocationDetails
                  key={sample.sample_id}
                  sampleId={sample.sample_id}
                  specsFlag={sample.specs_flag}
                  submissionId={sample.submission_id}
                  submissionStatus={data.status}
                  sampleType={sample.sample_type}
                  sampled={sample.sampled}
                  afterMarkingSample={afterMarkingSample}
                  draggableId={data.submission_id}
                  setCardLoading={setCardLoading}
                  event_date={data.event_date}
                />
              ))}
            </ul>
          </Scrollbar>
          {showSubmit && (
            <StyledButton
              type="text"
              htmlType="button"
              size="small"
              className="EventGrid__EventCard__SubmitBtn"
              onClick={showSubmitConfirmation}
            >
              Submit
            </StyledButton>
          )}
          {submitModalObj.open && (
            <EnvConfirmModal
              width="400px"
              headerText="Submission Confirmation"
              buttonText={["Cancel", "Submit"]}
              loading={submitLoading}
              bodyText={submitModalObj.bodyText}
              buttonFunctions={[() => setSubmitModalObj(DefaultSubmitModalObj),
                handleSubmitSamples]}
            />
          )}
          {cardLoading.isLoading && cardLoading.draggableId === data.submission_id && (
            <div className="Drag_Loading">
              <LoadingOutlined spin className="Drag_Loading__Loader" />
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
}

import React from "react";
import Modal from "antd/es/modal/Modal";
import "./SignoutConfirmation.css";
import StyledButton from "../Common/UIComponents/StyledButton";

const SignOutConfirmation = ({ onConfirm, onCancel }) => (
  <Modal
    open
    closable={false}
    centered
    footer={null}
    onConfirm={onConfirm}
    onCancel={onCancel}
    width="448px"
    wrapClassName="SignoutConfirmation"
    className="SignoutModalAlert"
  >
    <div className="signoutModal">
      <div className="signoutModalTitle">Log Out</div>
      <div className="signoutModalTitleMessage">Are you sure you want to Log Out?</div>
      <div className="signoutModalBtn">
        <StyledButton
          type="primary"
          ghost
          className="signoutModalCancelBtn"
          onClick={onCancel}
        >
          No
        </StyledButton>
        <StyledButton
          type="primary"
          className="signoutModalConfirmBtn"
          onClick={onConfirm}
        >
          Yes
        </StyledButton>

      </div>
    </div>
  </Modal>
);

export default SignOutConfirmation;

import React from "react";
import moment from "moment";
import ReportViewHeader from "../ReportViewHeader";
import { checkPinIsPinned } from "../../pinHelper";
import { PINS_SPEC_STYLE_MAP, QUADRANT_STYLE_MAP } from "../../Constant";
import { ReactComponent as StatusDotIcon } from "../../../../../../assets/images/environment/statusPingDot.svg";

export default function ResultHeader({ report, pin }) {
  if (!report) {
    return null;
  }
  const { color, display_name } = PINS_SPEC_STYLE_MAP[report.specs_flag] ?? PINS_SPEC_STYLE_MAP.invalid;
  const { color: blockColor } = checkPinIsPinned(pin)
    ? QUADRANT_STYLE_MAP[pin.quadrant]
    : { color: undefined }; // red

  return (
    <div className="env-report-view-report-section-header">
      <ReportViewHeader title="Report" subTitle={report.received_date.length > 0 ? moment(report.received_date).format("DD MMMM YYYY") : "-"} subTitleColor="#26ABE1" blockColor={blockColor} />
      <div className="env-report-view-report-section-header-devider" />
      <div className="env-report-view-report-section-header-status-container">
        <StatusDotIcon width={25} height={25} fill={color} />
        <span style={{ fontSize: "14px", color }}>
          {display_name}
        </span>
      </div>
    </div>
  );
}

/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { AES } from "crypto-js";
import { AES_128_BIT_KEY, API_URL } from "../utils/Constant";

/**
 * Get company name
 * @param {String} customerID
 * @return  {Object} {success, contact_name, email, company, address, city, zip, state, telephone}
 */
export const getCustomerInfo = async (customerID) => {
  try {
    const resp = await axios.get(`${API_URL}/customerinfo/`, {
      params: { customer_id: customerID },
    });

    const result = {
      success: resp.data.message === "Success",
      message: resp.data.message,
      ...resp.data.result,
    };

    return result;
  } catch (e) {
    // console.log("error from getCompanyName", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Submit the invite form and send the invitation email to the customer's email address
 * @param {Object} payload
 * @returns {Object} { success, message}
 */
export const sendCustomerInvite = async (payload) => {
  try {
    const {
      sales_email,
      csm_email,
      company_name,
      customer_name,
      customer_email,
      customer_id,
      momentExpirationDate,
      webBaseURL,
    } = payload;
    const url_params = `sales_email=${sales_email}&csm_email=${csm_email}&customer_id=${customer_id ?? ""}&company_name=${company_name}&customer_name=${customer_name}&customer_email=${customer_email}&expiration=${momentExpirationDate.format()}`;
    const encrypted_params = AES.encrypt(url_params, AES_128_BIT_KEY).toString();
    const url = `${webBaseURL}/onboarding?${encrypted_params}`;
    const params = {
      customer_id: customer_id ? decodeURIComponent(customer_id) : undefined,
      customer_name: decodeURIComponent(customer_name),
      customer_email: decodeURIComponent(customer_email),
      company: decodeURIComponent(company_name),
      sales: decodeURIComponent(sales_email),
      csm: decodeURIComponent(csm_email),
      url,
      expires_after: `${momentExpirationDate.format("MM/DD/YYYY")} UTC`,
    };
    const response = await axios.post(`${API_URL}/inviteadmin/`, params);
    const data = await response.data;
    return { success: data.message === "Success", ...data };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Submit onboarding form
 * @param {Object} payload
 * @returns {Object} { success, message}
 */
export const submitOnboardingForm = async (payload) => {
  try {
    const resp = await axios.post(`${API_URL}/usersignuponboard/`, {
      ...payload,
    });

    return { success: true, message: resp.data.message };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Submit request form for environment and product
 * @param {Object} payload
 * @returns {Object} { success, message}
 */

export const submitOnboardingFields = async (payload) => {
  try {
    const resp = await axios.post(`${API_URL}/onboardingfields/`, {
      ...payload,
    });

    return { success: true, message: resp.data.message };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

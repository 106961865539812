import React from "react";
import { toast } from "react-toastify";
import moment from "moment";
import FormPageLayout from "../FormPageLayout";
import FormLayout from "../FormLayout";
import InviteCustomerForm from "./InviteCustomerForm";
import { sendCustomerInvite } from "../../../actions/onboarding";
import "./InviteForm.css";
import "../../../index.css";

export default function InviteForm({ history }) {
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    layout: "vertical",
  };

  /**
   * Make an api call to send the invitation email to the cusotmer
   * @param {object} formData
   * @returns {boolean} true if successful
   */
  const onFinish = async (formData) => {
    const payload = {
      webBaseURL: window.location.origin,
      // timestamp: new Date().getTime(),
    };

    /** Get link expiration date in UTC */
    // const currentUTCDate = moment.utc().format();
    const expirationDate = moment.utc().add(3, "days");
    payload.momentExpirationDate = expirationDate;
    // console.log("current", currentUTCDate, "link expiration", expirationDate);

    // Encode all fileds into url format
    Object.entries(formData).forEach(([key, value]) => {
      payload[key] = encodeURIComponent(value);
    });
    const resp = await sendCustomerInvite(payload);
    if (resp.success) {
      history.push("invite-customer/success");
      return true;
    }
    toast.error(resp.message ?? "Something went wrong");
    return false;
  };

  return (
    <FormPageLayout>
      <FormLayout title="Customer Invite" className="InviteForm">
        <InviteCustomerForm onFinish={onFinish} layout={layout} />
      </FormLayout>
    </FormPageLayout>
  );
}

import React, { useRef } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import NoResultsGIF from "../../../../../Common/NoResultsGIF";
import { checkPinIsPinned } from "../../pinHelper";
import ReportViewPin from "./ReportViewPin";
import ReportViewHeader from "../ReportViewHeader";
import pumpkinGIF from "../../../../../../assets/images/environment/pumpkin.gif";
import { QUADRANT_STYLE_MAP } from "../../Constant";
import "./SinglePinMapView.css";

export default function SinglePinMapView({
  imageSrc,
  pin,
}) {
  const imageRef = useRef();
  const pinElementRef = useRef();

  if (pin === undefined) {
    return (<div className="env-analytics-report-view-section env-report-view-map" />);
  }

  const { color, display_name } = checkPinIsPinned(pin)
    ? QUADRANT_STYLE_MAP[pin.quadrant]
    : { color: "#E63559", display_name: "No location found" }; // red

  return (
    <div className="env-analytics-report-view-section env-report-view-map">
      <ReportViewHeader title="Location" subTitle={display_name} subTitleColor={color} blockColor={color === "#E63559" ? undefined : color} />
      {checkPinIsPinned(pin) ? (
        <TransformWrapper
          disabled
        >
          {({
            zoomToElement,
          }) => (
            <TransformComponent>
              <div>
                <ReportViewPin
                  pin={pin}
                  pinElementRef={pinElementRef}
                  zoomToElement={zoomToElement}
                />
                <img
                  ref={imageRef}
                  alt="swab pin on map"
                  src={imageSrc}
                  onLoad={() => {
                    if (pinElementRef.current !== null) {
                      zoomToElement(pinElementRef.current, 2);
                    }
                  }}
                />
              </div>
            </TransformComponent>
          )}
        </TransformWrapper>
      ) : (<NoResultsGIF image={pumpkinGIF} message="Not pinned on the map" />)}

    </div>
  );
}

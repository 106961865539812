import React from "react";
import { Col, Flex, Row } from "antd";
import "./FormPageLayout.css";
import { ReactComponent as GBLogo } from "../../assets/images/logo/GBLogo.svg";
import { ReactComponent as ESVLogo } from "../../assets/images/logo/ESVLogo.svg";

export default function FormPageLayout({ className, children, addCompany = false }) {
  return (
    <div className={`FormPageLayout ${className ?? ""}`}>
      {!addCompany && (
      <Row className="FormPageLayout__Header" span={24}>
        <Col flex="auto" offset={1} className="FormPageLayout__Header__Logos">
          <GBLogo height={40} width={160} />
          <div className="FormPageLayout__Header__Logos__Right">
            {/* <span>Powered by</span>
            <div className="FormPageLayout__Header__Logos__Right__Divider" /> */}
            <ESVLogo height={40} />
          </div>
        </Col>
      </Row>
      )}
      <Row justify="center" className="FormPageLayout__Body" span={24}>
        <div className="FormPageContainer">
          <Flex className="FormPageLayout__Form">
            {children}
          </Flex>
        </div>
      </Row>
    </div>

  );
}

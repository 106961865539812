import axios from "axios";
import { API_URL } from "../utils/Constant";

/* Not Used */
const getAvailableFiles = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/sharetestresults/${id}`);
    const data = response.data.result[0];
    return { success: true, ...data };
  } catch (e) {
    return { success: false };
  }
};

export default getAvailableFiles;

import React from "react";
import "./DataFieldsDeleteModal.css";

export default function DataFieldsDeleteModal(props) {
  const {
    field, toggleShowDeleteModal, onDelete, refVal,
  } = props;
  return (
    <div ref={refVal} className="deleteModal">
      <div className="dataFieldDeleteModal_spanContainer">
        <span className="dataFieldDeleteModal_text">
          Are you sure you want to delete
          {" "}
          <span className="dataFieldDeleteModal_fieldName">{field}</span>
          {" "}
          ?
        </span>
      </div>
      <div className="dataFieldDeleteModal_buttonContainer">
        <input type="button" value="Cancel" className="dataFieldDeleteModal_cancelButton" onClick={() => toggleShowDeleteModal()} />
        <input type="button" value="Delete" className="dataFieldDeleteModal_deleteButton" onClick={() => onDelete(field)} />
      </div>

    </div>
  );
}

import React from "react";
import { LayoutGroup } from "framer-motion/dist/framer-motion";
import Scrollbar from "react-scrollbars-custom";
import ContentItem from "./ContentItem";
import ContentAddItem from "./ContentAddItem";
import "./builderContent.css";

export default function BuilderContent(props) {
  const {
    previewTemplate, handleCreateSection, handleSectionSelect, handleSectionDelete, sections, sectionsFixed,
  } = props;

  return (
    <div className={previewTemplate ? "templateBuilderContent templateBuilderDisableSection" : "templateBuilderContent"}>
      <div className="templateBuilderContentHeader">Contents</div>
      <div className="templateBuilderContentScrollbar">
        <Scrollbar>
          <div className="templateBuilderContentItems">
            <LayoutGroup>
              <ContentItem item={sectionsFixed[0]} handleSectionSelect={handleSectionSelect} />
              {sections.map((item) => (
                <ContentItem key={item.title} item={item} handleSectionSelect={handleSectionSelect} handleSectionDelete={handleSectionDelete} keyValue={item.title} />
              ))}
              <ContentAddItem handleCreateSection={handleCreateSection} />
              <ContentItem item={sectionsFixed[1]} edit={false} handleSectionSelect={handleSectionSelect} />
              {/* <ContentItem item={sectionsFixed[2]} edit={false} handleSectionSelect={handleSectionSelect} /> */}
            </LayoutGroup>
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import "../styles/common/search.css";

export default function TemplateSearch(props) {
  const [searchValue, setSearchValue] = useState("");

  const { handleSearch } = props;

  const onChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      handleSearch("");
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch(searchValue);
    }
  };

  return (
    <div className="productCoaSearch">
      <input
        type="text"
        className={searchValue === "" ? "invalidSearchValue" : "validSearchValue"}
        value={searchValue}
        placeholder="Search"
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      <div className="searchIconBackground" onClick={() => handleSearch(searchValue)} />
    </div>
  );
}

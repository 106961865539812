import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { checkPinIsPinned } from "../pinHelper";
// import { QUADRANT_NAME } from "../Constant";

const MapBlurb = ({
  listViewDisplay,
  quadrantSelected,
  selectedLocationID,
  deletedLocationID,
  setDeletedLocationID,
  pinMap,
}) => {
  let text = "";
  if (!listViewDisplay) {
    text = "Select a pin and click anywhere on the map to relocate it";
  } else if (deletedLocationID) {
    text = `Swab ${pinMap.get(deletedLocationID).swab_number} has been removed from the map`;
  } else if (quadrantSelected !== -1 && selectedLocationID) {
    // text = (checkPinIsPinned(pinMap.get(selectedLocationID))) ? QUADRANT_NAME[quadrantSelected] : "Click anywhere on the map to pin it";
    text = (checkPinIsPinned(pinMap.get(selectedLocationID))) ? "" : "Click anywhere on the map to pin it";
  }

  // On componentDidMount set the timer
  useEffect(() => {
    let timer = null;
    if (deletedLocationID) {
      timer = setTimeout(() => {
        setDeletedLocationID("");
      }, 3000);
    }
    return () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
    };
  }, [deletedLocationID]); // eslint-disable-line

  return (
    <AnimatePresence>
      {text ? (
        <motion.div
          className="envMapBlurb"
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={!listViewDisplay ? {} : { y: 30, opacity: 0 }} // no exit animation when closing full mode
          transition={{ duration: 0.5 }}
        >
          {text}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

export default MapBlurb;

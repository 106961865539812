import React from "react";
import { Input } from "antd";
import "./LoginInput.css";
import eyeClosedIcon from "../../../assets/images/ph_eye-closed-fill@2x.png";
import eyeOpenedIcon from "../../../assets/images/ph_eye_opened_black_fill@2x.png";

export default function PasswordInput({
  onChange, type, id, name, value, inputRef, error, onKeyDown, autoComplete,
}) {
  return (
    <Input.Password
      id={id}
      name={name}
      type={type}
      className="Login__Input Login__PasswordInput"
      value={value}
      autoComplete={autoComplete}
      status={error ? "error" : undefined}
      onChange={onChange}
      ref={(component) => {
        if (inputRef) {
          inputRef.current = component?.input;
        }
      }}
      onKeyDown={onKeyDown}
      iconRender={(visible) => (visible ? (
        <img
          src={eyeOpenedIcon}
          alt="eye closed icon"
          className="Login__PasswordInput_img"
        />
      ) : (
        <img
          src={eyeClosedIcon}
          alt="eye closed icon"
          className="Login__PasswordInput_img"
        />
      ))}
    />
  );
}

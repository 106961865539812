import React from "react";

import zoomInIcon from "../../../../../assets/images/environment/zoomInIcon.png";
import zoomOutIcon from "../../../../../assets/images/environment/zoomOutIcon.png";
import { checkPinIsPinned } from "../pinHelper";

export default function MapTools({
  handleZoomIn,
  handleZoomOut,
  onZoom,
  refOfWrapper,
  selectedLocationID,
  isAddingNewSwab,
  pinMap,
}) {
  const { minScale, maxScale } = refOfWrapper.current ? refOfWrapper.current.instance.setup : { minScale: null, maxScale: null };
  const { scale } = refOfWrapper.current ? refOfWrapper.current.state : { scale: null };
  // console.log(minScale, maxScale, scale);

  const zoomIn = async () => {
    // console.log(refOfWrapper.current);
    handleZoomIn();
    setTimeout(() => onZoom(refOfWrapper.current), 300);
    // onZoom(refOfWrapper.current);
  };

  const zoomOut = async () => {
    handleZoomOut();
    setTimeout(() => onZoom(refOfWrapper.current), 300);
    // onZoom(refOfWrapper.current);
  };

  /** Disable zoom when user is selecting a quadrant to pin swab in (fixes bug where quadrant overlay overflows the container) */
  const disableZoom = isAddingNewSwab || (selectedLocationID && !checkPinIsPinned(pinMap.get(selectedLocationID)));

  return (
    <div className="tools">
      <button className={disableZoom || (maxScale && scale && (scale >= maxScale)) ? "disableZoom" : ""} type="button" onClick={zoomIn}>
        <div>
          <img src={zoomInIcon} alt="zoomInIcon" />
        </div>
      </button>
      <button className={disableZoom || (minScale && scale && (scale <= minScale)) ? "disableZoom" : ""} type="button" onClick={zoomOut}>
        <div>
          <img src={zoomOutIcon} alt="zoomOutIcon" />
        </div>
      </button>
    </div>
  );
}

import React from "react";

import Scrollbar from "react-scrollbars-custom";

import MRLCountryList from "./MRLCountryList";

import { hasFeature } from "../../../../../utils/common";

import { ReactComponent as MRLIcon } from "../../../../../assets/images/product/MRLIcon.svg";
import { ReactComponent as QualitativeIcon } from "../../../../../assets/images/product/QualitativeIcon.svg";
import { ReactComponent as QuantitativeIcon } from "../../../../../assets/images/product/QuantitativeIcon.svg";
import { ReactComponent as RangeIcon } from "../../../../../assets/images/product/RangeIcon.svg";

import "../../../Product.css";
import "./SpecManager.css";
import "./SpecTable.css";

class SpecTable extends React.Component {
  static getFormattedSpec(specs, testType, test, countries_lst) {
    let units = specs[2];
    let min = specs[0];
    let max = specs[1];
    const isMRLTest = Boolean(countries_lst) && hasFeature("special_pesticides");

    if (units === undefined) units = "";
    if (min === undefined) min = "";
    if (max === undefined) max = "";

    /** Empty specs for this test */
    if (!units && !min && !max && !isMRLTest) {
      return "-";
    }

    if (testType === "quantitative") {
      return `${max} ${units}`;
    }

    if (testType === "range") {
      return `${min} - ${max} ${units}`;
    }

    if (testType === "qualitative") {
      let value = specs[1];
      if (value === undefined) {
        value = "";
      }
      return `${min} ${value} ${units}`;
    }

    if (isMRLTest) {
      return (
        <MRLCountryList
          countriesData={countries_lst}
          editMode={false}
        />
      );
    }

    return `${min} ${max} ${units}`;
  }

  static getFormattedTestType(testType, test, countries_lst) {
    const formattedTestType = testType.charAt(0).toUpperCase() + testType.substring(1, testType.length);

    if (testType === "quantitative") {
      return (
        <div className="SpecTable__TestType">
          <QuantitativeIcon />
          <span>{formattedTestType}</span>
        </div>
      );
    }

    if (testType === "qualitative") {
      return (
        <div className="SpecTable__TestType">
          <QualitativeIcon />
          <span>{formattedTestType}</span>
        </div>
      );
    }

    if (testType === "range") {
      return (
        <div className="SpecTable__TestType">
          <RangeIcon />
          <span>{formattedTestType}</span>
        </div>
      );
    }

    if (countries_lst && hasFeature("special_pesticides")) {
      return (
        <div className="SpecTable__TestType">
          <MRLIcon />
          <span>MRL</span>
        </div>
      );
    }

    return <span>{testType || "-"}</span>;
  }

  renderTests() {
    const { dataOfSpecEditing } = this.props;

    return dataOfSpecEditing.map((item, index) => (
      <tr key={index}>
        <td className="SpecTable__TableContentTest">
          {/* {item.Test}  */}
          {item.test}
        </td>

        <td className="SpecTable__TableContentType">
          {/* {item.Test}  */}
          {SpecTable.getFormattedTestType(item.test_type, item.test, item.countries_lst)}
        </td>

        <td className="SpecTable__TableContentTestValue">
          {SpecTable.getFormattedSpec(item.specs, item.test_type, item.test, item.countries_lst)}
        </td>
      </tr>
    ));
  }

  render() {
    return (
      <Scrollbar
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
        className="SpecTable__Scrollbar"
        id="specManagerSpecTableScrollBarProduct"
      >
        <table className="specTable">
          <thead>
            <tr>
              <th className="specManagerEditSpecTh1">Test</th>
              <th className="specManagerEditSpecTh2">Test Type</th>
              <th className="specManagerEditSpecTh3">Specs</th>
            </tr>
          </thead>
          <tbody>{this.renderTests()}</tbody>
        </table>
      </Scrollbar>
    );
  }
}

export default SpecTable;

import React, { Component } from "react";
import { Modal } from "antd";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import emptyStateIcon from "../../../../../assets/images/EmptyState_V2.png";
import cancelBtn from "../../../../../assets/images/product/crossWhite.png";
import PdfPreview from "../../../../Common/PdfPreview";
import NoResults from "../../../../Common/NoResults";
import PreviewDatePicker from "./PreviewDatePicker";
import "./ReportPreviewModal.css";

export default class ReportPreviewModal extends Component {
  constructor(props) {
    super(props);

    const { previewUrlList } = this.props;
    const sortedUrlList = [...previewUrlList].sort((a, b) => Date.parse(b.date) - Date.parse(a.date));

    this.state = {
      currentSlide: 0,
      sortedUrlList,
      activeDate: sortedUrlList ? sortedUrlList[0].date : "",
      modalIsAnimating: true,
    };
  }

  handleDateChanged = (date, index) => {
    this.setState({
      activeDate: date,
      currentSlide: index,
    });
  }

  handleShowRetest = () => {
    const { showRetest, currentSlide } = this.state;
    this.setState({
      showRetest: !showRetest,
      currentSlide: currentSlide === 0 ? 1 : 0,
    });
  };

  render() {
    const { handleCloseForPreview } = this.props;
    const {
      currentSlide, activeDate, modalIsAnimating, sortedUrlList,
    } = this.state;
    return (
      <Modal
        open
        onCancel={() => handleCloseForPreview()}
        closable={false}
        centered
        destroyOnClose
        footer={null}
        width="40vw"
        wrapClassName="ReportPreviewModal"
        afterOpenChange={() => this.setState({ modalIsAnimating: false })}
      >
        <div
          className="ReportPreviewModal__Header"
        >
          <span>COA</span>
          <img
            src={cancelBtn}
            alt="close"
            className="ReportPreviewModal__Header__CloseIcon"
            onClick={() => handleCloseForPreview()}
          />
        </div>
        <div className="ReportPreviewModal__ReportsContainer">
          {sortedUrlList.length > 1 && <PreviewDatePicker data={sortedUrlList} activeDate={activeDate} updateActiveDate={this.handleDateChanged} /> }

          <Carousel
            showIndicators={false}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            selectedItem={currentSlide}
            style={{ margin: "-40px" }}
          >
            {sortedUrlList.map((item, index) => (
              <div
                className="ReportPreviewModal__ReportView"
                key={index}
              >
                {item.url ? (
                  <PdfPreview
                    blob={item.url}
                    height="70vh"
                    scaleType="PageWidth"
                    scrollBarBorderRadius="8px"
                    showLoading={modalIsAnimating}
                  />
                ) : (
                  <div className="ReportPreviewModal__NoResults">
                    <NoResults image={emptyStateIcon} message="Preview not available." />
                  </div>
                )}
              </div>
            ))}
            {/* {!previewUrlList[currentSlide] && !previewUrlList[currentSlide].url ? (
                <PdfPreview
                  blob={previewUrlList[currentSlide].url}
                  height="70vh"
                  scaleType="PageWidth"
                  scrollBarBorderRadius="8px"
                />
              ) : (

              )} */}
          </Carousel>
        </div>
      </Modal>
    );
  }
}

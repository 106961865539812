// GraphComponent.jsx

import React, {
  useState, useMemo, useEffect, useCallback,
} from "react";

import { isArray, uniq, debounce } from "lodash";
import moment from "moment";
import { Scrollbar } from "react-scrollbars-custom";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Brush,
  ResponsiveContainer,
  Scatter,
  Cell,
  Dot,
} from "recharts";

import NoResultsGif from "../../../../Common/NoResultsGIF";
// import GraphTooltipCursor from "../../../../Product/Analytics/Containers/GraphContainer/Components/GraphTooltipCursor";
import { colorsForBar, colorsAndIconsArray } from "../../../helper";
import GraphTestPill from "./GraphTestPill";
import CustomTooltip from "./GraphTooltipRender";
import ScatterPoint from "./Scatterpoint";

import { ReactComponent as TestGraphPillSvg } from "../../../../../assets/images/analytics/graphPillTest_svg.svg";
import pumpkinGif from "../../../../../assets/images/environment/pumpkin.gif";

import "./ComposedGraphStyle.css";

const ComposedGraphComponent = ({ graphType, graphData = [] }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [lineGraphModData, setLineGraphModData] = useState([]);
  const [brushSelection, setBrushSelection] = useState([0, 1]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [visibleTests, setVisibleTests] = useState(5); // For limiting visible tests
  const [debouncedFilteredData, setDebouncedFilteredData] = useState([]);

  const greyColor = "#ccc";

  /**
   * Transforms test data by grouping it by date for line graphs.
   *
   * @param {Array} data - The array of test objects.
   * @returns {Array} - The transformed array grouped by date.
   */
  const groupDataByDate = (data) => {
    const dateMap = {};

    data.forEach((test) => {
      const testName = test.test_name;
      const details = test.details;

      Object.keys(details).forEach((date) => {
        if (!dateMap[date]) {
          dateMap[date] = { date: moment(date).format("YYYY-MM-DD") };
        }
        dateMap[date][testName] = details[date];

        // Initialize or update the total for the date
        if (!dateMap[date].total) {
          dateMap[date].total = 0;
        }
        dateMap[date].total += details[date];
      });
    });

    // Convert the dateMap to an array and sort by date
    const groupedData = Object.values(dateMap).sort(
      (a, b) => new Date(a.date) - new Date(b.date),
    );
    return groupedData;
  };

  /**
   * Transforms test data for bar graphs, aggregating total counts per test.
   *
   * @param {Array} data - The array of test objects.
   * @returns {Array} - The transformed array for bar charts.
   */
  const transformDataForBar = (data = []) => {
    if (!data || data.length === 0) {
      return []; // Return an empty array if data is undefined or empty
    }
    return data.map((test, index) => ({
      test_name: test.test_name,
      total: test.total,
      color: colorsForBar[index % colorsForBar.length] || greyColor, // Cycle colors
    }));
  };
  /**
 * Initializes selected tests and graph data based on the graph type:
 * for "line" graphs, sets unique tests limited by visibleTests; for "bar" graphs, transforms data and sets initial selected tests.
 */
  useEffect(() => {
    if (!graphData || graphData?.length === 0) {
      return;
    }
    if (graphType === "line") {
      const uniqueTests = uniq(graphData.map((item) => item.test_name));
      setSelectedTests(uniqueTests.slice(0, visibleTests));
    }
    if (graphType === "bar") {
      const transformedBarData = transformDataForBar(graphData);
      setLineGraphModData(transformedBarData);
      setSelectedTests(
        graphData.map((item) => item.test_name).slice(0, visibleTests),
      );
    }
  }, []); //eslint-disable-line

  // Effect hook to manage graph data updates and transformations based on props.
  useEffect(() => {
    if (!graphData || graphData?.length === 0) {
      setLineGraphModData([]);
      setSelectedTests([]);
      return;
    }

    if (graphType === "line") {
      const groupedData = groupDataByDate(graphData) || [];

      setBrushSelection([0, groupedData.length - 1]); // Set brush selection range correctly
      setLineGraphModData(groupedData);
    } else if (graphType === "bar") {
      const transformedBarData = transformDataForBar(graphData);
      setLineGraphModData(transformedBarData);
    }
  }, [graphData, visibleTests, graphType]);

  // Handles selection and deselection of test names in the UI.
  const handleTestSelection = (testName) => {
    setSelectedTests((prev) => (prev.includes(testName)
      ? prev.filter((name) => name !== testName)
      : [...prev, testName]));
  };

  // Memoized computation of all unique test names for pill rendering.
  const allTestNames = useMemo(() => {
    if (!graphData || graphData.length === 0) {
      return []; // Return empty array when graphData is empty
    }
    return uniq(graphData.map((item) => item.test_name));
  }, [graphData]);

  // Modified `filteredData` with debounce for smooth operation**
  const updateFilteredData = useCallback(
    debounce((data) => {
      setDebouncedFilteredData(data);
    }, 300), // Adjust the debounce delay as needed
    [graphType, lineGraphModData, brushSelection, selectedTests],
  );
  /**
 * useEffect to filter and update data based on graph type, brush selection, and selected tests
 */
  useEffect(() => {
    let data = lineGraphModData;

    if (graphType === "line") {
    // Apply brush selection if applicable
      if (isArray(brushSelection) && brushSelection.length === 2) {
        const [startIdx, endIdx] = brushSelection;
        data = data.slice(startIdx, endIdx + 1);
      }

      // Map the data to include only selected tests
      const mappedData = data.map((entry) => {
        const filteredEntry = { date: entry.date, total: entry.total };
        selectedTests.forEach((test) => {
          filteredEntry[test] = entry[test] || null; // Use null for missing values
        });
        return filteredEntry;
      });

      // Filter out entries where none of the selected tests are present
      const partiallyFilteredData = mappedData.filter((entry) => selectedTests.some((test) => entry[test] !== null && entry[test] !== undefined));

      updateFilteredData(partiallyFilteredData);
    }

    if (graphType === "bar") {
      const filteredBarData = lineGraphModData.filter((item) => selectedTests.includes(item.test_name));
      updateFilteredData(filteredBarData);
    }
  }, [lineGraphModData, brushSelection, selectedTests, graphType, updateFilteredData]);

  let currentMonth = "";
  let currentYear = "";

  // Function to format x-axis ticks for line and bar graphs based on date or label length
  const formatXAxisTicks = (tickItem) => {
    if (tickItem === "auto" || tickItem === "Invalid date") {
      return ""; // Return blank for "auto" and "Invalid date"
    }

    if (graphType === "line") {
      const date = moment(tickItem);
      if (!date.isValid()) return ""; // Return blank for invalid dates

      const formattedDate = date.format("DD MMM");
      const month = date.format("MMM");
      const year = date.format("YYYY");

      // Always show year for the first tick
      if (!currentYear || year !== currentYear || tickItem === debouncedFilteredData[brushSelection[0]]?.date) {
        currentYear = year;
        currentMonth = month;
        // Show full date if the month changes or it's the first tick of the brush selection
        if (!currentMonth || month !== currentMonth) {
          currentMonth = month;
          return formattedDate; // Return full formatted date (e.g., '23 Oct')
        }
        return `${formattedDate} ${year}`; // Return full formatted date with year (e.g., '23 Oct 2024')
      }

      // Show full date if the month changes or it's the first tick of the brush selection
      if (!currentMonth || month !== currentMonth) {
        currentMonth = month;
        return formattedDate; // Return full formatted date (e.g., '23 Oct')
      }

      // Otherwise, just show the day (e.g., '23')
      return date.format("DD");
    }

    if (graphType === "bar") {
      const maxLength = 12;
      return tickItem.length > maxLength
        ? `${tickItem.slice(0, maxLength)}...`
        : tickItem;
    }

    return tickItem;
  };
  // Function to format y-axis ticks for line and bar graphs based on value or label length
  const formatYAxisTicks = (tickItem) => (tickItem === "auto" ? "" : tickItem);

  // Formats brush tick date to display as 'DD MMM' (e.g., '23 Oct')

  const formatBrushDate = (tickItem) => moment(tickItem).format("DD MMM");

  /**
   *
   * Renders a list of test pills with dynamic colors and icons, and a 'Show More' button for additional tests
   */
  const renderPills = () => {
    const testsToShow = allTestNames.slice(0, visibleTests);
    return (
      <>
        {testsToShow.map((testName, index) => {
          const colorIndex = index % colorsAndIconsArray.length;
          const { accentColor, borderColor, testIcon } = colorsAndIconsArray[colorIndex] || {};
          const pillColor = accentColor || greyColor;
          const pillBorderColor = borderColor || greyColor;
          const pillIcon = testIcon || TestGraphPillSvg;

          return (
            <GraphTestPill
              key={`pill-${testName} ${index}`}
              testName={testName}
              color={pillColor}
              borderColor={pillBorderColor}
              isSelected={selectedTests.includes(testName)}
              onClick={handleTestSelection}
              icon={pillIcon}
            />
          );
        })}
        {visibleTests < allTestNames.length && (
          <button
            type="button"
            className="EnvAnalyticsGraph__ShowMoreButton"
            onClick={() => setVisibleTests((prev) => prev + 10)}
          >
            Show More
          </button>
        )}
      </>
    );
  };
  /**
 *
 * @returns Renders scatter points for each selected test in line graphs, with colors based on test names, and updates hovered item on interaction

 */
  const renderScatterPoints = () => {
    if (graphType !== "line" || selectedTests.length === 0) return null;
    return selectedTests.map((testName, index) => {
      const colorIndex = allTestNames.indexOf(testName) % colorsAndIconsArray.length;
      const fillColor = colorsAndIconsArray[colorIndex]?.borderColor || greyColor;
      return (
        <Scatter
          key={`scatter-${testName} ${index}`}
          dataKey={testName}
          name={testName}
          shape={<ScatterPoint updateHoveredItem={setHoveredItem} />}
          fill={fillColor}
        />
      );
    });
  };
  /**
 *
 * @returns Rendered lines for each selected test in line graphs with dynamic colors and custom tooltips,
 * along with hover effects on line dots and embedded scatter points
 */
  const renderLines = () => {
    if (graphType !== "line" || selectedTests.length === 0) return null;
    return selectedTests.map((testName, index) => {
      const colorIndex = allTestNames.indexOf(testName) % colorsAndIconsArray.length;
      const strokeColor = colorsAndIconsArray[colorIndex]?.borderColor || greyColor;
      return (
        <React.Fragment key={`line-and-scatter-${testName}-${index}`}>
          <Tooltip
            key={`tooltip-${testName} ${index}`}
            content={<CustomTooltip graphType={graphType} hoveredItem={hoveredItem} />}
            cursor={false}
            position="top"
          />
          <Line
            key={`line-${testName} ${index}`}
            type="monotone"
            dataKey={testName}
            name={testName}
            stroke={strokeColor}
            strokeWidth={2}
            connectNulls
            dot={{
              r: 4,
              fill: "#FFF",
              strokeWidth: 2,
              stroke: strokeColor,
            }}
            activeDot={(props) => {
              const {
                cx,
                cy,
              } = props;
              const isHovered = hoveredItem && hoveredItem.data && hoveredItem.data.cx === cx && hoveredItem.data.cy === cy;
              return (
                <Dot
                  key={`dot-${testName} ${index}`}
                  className="selectedGraphDot"
                  cx={cx}
                  cy={cy}
                  r={isHovered ? 6 : 4} // Increase radius when the dot is hovered
                  fill="#FFF"
                  strokeWidth={2}
                  stroke={isHovered ? "#cccc" : strokeColor}
                />
              );
            }}
            animationBegin={200}
            isAnimationActive
          />
          {renderScatterPoints()}
        </React.Fragment>
      );
    });
  };

  const renderBars = () => {
    if (graphType !== "bar" || selectedTests.length === 0) return null;
    return (
      <>
        <Tooltip
          content={(
            <CustomTooltip
              graphType={graphType}
              hoveredItem={hoveredItem}
            />
          )}
          cursor={false}
          position="top"
        />
        <Bar
          type="monotone"
          dataKey="total"
          radius={[10, 10, 10, 10]}
          barSize={20}
          minPointSize={2}
          onMouseMove={(val) => setHoveredItem({ data: val.payload, type: "average" })}
          onMouseOut={() => setHoveredItem(null)}
        >
          {debouncedFilteredData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color || greyColor} />
          ))}
        </Bar>
      </>
    );
  };

  return (
    <div className="EnvAnalyticsGraph__GraphMainContainer">
      <div className="EnvAnalyticsGraph__GraphContainerPlaceholderText">
        {graphType === "bar" ? "Test By Volume" : "Test Volume Trend over Time"}
      </div>
      {allTestNames?.length > 0 && (
        <Scrollbar>
          <div className="EnvAnalyticsGraph__TestPills--Container">
            {renderPills()}
          </div>
        </Scrollbar>
      )}
      <div className="EnvAnalyticsGraph__GraphContainer">
        {/* Graph Rendering */}
        {(graphData?.length > 0) ? (
          <ResponsiveContainer height="100%" width="100%">
            {debouncedFilteredData ? (
              <ComposedChart
                data={
                graphType === "line" && selectedTests.length === 0
                  ? [{ date: "", total: 0 }]
                  : debouncedFilteredData
              }
                margin={{
                  top: 5,
                  right: 20,
                  left: -40,
                  bottom: 30, // Unified bottom margin for consistency
                }}
                animationDuration={500}
              >
                <XAxis
                  dataKey={graphType === "bar" ? "test_name" : "date"}
                  tickFormatter={formatXAxisTicks}
                  tickLine={false}
                  fontSize={11}
                  fontFamily="Roboto"
                  tick={{
                    fill: "#AFBDCA",
                    display: selectedTests.length > 0 ? "block" : "none",
                  }}
                  stroke="#C4D2DB"
                  padding={{
                    left: 10, right: 10,
                  }}
                  tickClassName="xAxisTickLabel"
                  axisLine={false}
                  domain={["auto", "auto"]}
                  interval={0}
                />
                <YAxis
                  tickLine={false}
                  tickFormatter={formatYAxisTicks}
                  padding={{
                    left: 10, right: 10, top: 10, bottom: 10,
                  }}
                  tick={{
                    fill: "#AFBDCA",
                    display: selectedTests.length > 0 ? "block" : "none",
                  }}
                  stroke="#C4D2DB"
                  fontSize={11}
                  fontFamily="Roboto"
                  domain={selectedTests.length > 0 ? [0, "auto"] : [0, 0]}
                  axisLine={false}
                />
                {renderBars()}
                {renderLines()}
                {selectedTests.length > 0 && (
                <Brush
                  dataKey={graphType === "line" ? "date" : "test_name"}
                  fill="#eff2f3"
                  stroke="#afbdca"
                  height={30}
                  travellerWidth={10}
                  tickFormatter={graphType === "line" ? formatBrushDate : null}
                />
                )}
              </ComposedChart>
            ) : <></>}
          </ResponsiveContainer>
        ) : (
          <NoResultsGif message="No Data" image={pumpkinGif} className="EnvAnalyticsGraph__NoResults" />
        )}
        {selectedTests.length === 0 && graphData?.length > 0 && (
        <div
          className="EnvAnalyticsGraph__Placeholder"
          role="alert"
          aria-live="assertive"
        >
          Please select at least one test to display the graph.
        </div>
        )}

      </div>
    </div>
  );
};

export default ComposedGraphComponent;

import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import StyledButton from "../../Common/UIComponents/StyledButton";
import { getTabsList } from "../../../utils/common";
import "./CreateSampleModal.css";
import { ReactComponent as OnboardingClose } from "../../../assets/images/OnboardingClose.svg";
import StyledTooltip from "../../Common/UIComponents/StyledTooltip";

export default function CreateSampleModal({
  onCancel, setShowSubmissionForm, setSampleCategory, heading, loadingFields,
}) {
  const [tabsList, setTabsList] = useState([]);
  const [environment, setEnvironment] = useState();
  const [product, setProduct] = useState();
  const [loadingButton, setLoadingButton] = useState("");

  const history = useHistory();

  //  Handling tabs to redirect to onBoarding or create a new sample
  useEffect(() => {
    const tabs = getTabsList();
    const userDetail = sessionStorage.getItem("user_details");
    const userType = JSON.parse(userDetail).user_title === "Admin";
    setTabsList(tabs);

    // Disable Product btn if user is non Admin.
    if (tabs.includes("product") || userType) {
      setProduct(true);
    } else {
      setProduct(false);
    }

    // Disable Environment btn if user is non Admin.
    if (tabs.includes("environment") || userType) {
      setEnvironment(true);
    } else {
      setEnvironment(false);
    }
  }, []);

  // Handle environment specific actions
  const handleProductClick = () => {
    if (tabsList.includes("product")) {
      setShowSubmissionForm(true);
      setSampleCategory("product");
      setLoadingButton("product");
      // handleShareSubmissionInfo("product");
    } else {
      history.push("/onboarding/product");
    }
  };

  // Handle environment specific actions
  const handleEnvironmentClick = () => {
    if (tabsList.includes("environment")) {
      setShowSubmissionForm(true);
      setSampleCategory("environment");
      setLoadingButton("environment");
      // handleShareSubmissionInfo("environment");
    } else {
      history.push("/onboarding/environment");
    }
  };

  return (
    <Modal
      open
      closable
      centered
      footer={null}
      onCancel={onCancel}
      width="452px"
      closeIcon={<OnboardingClose />}
      className="CreateSampleAlert"
    >
      <div className="CreateSampleTitle">{heading}</div>
      <div className="CreateSampleBtn">

        <StyledTooltip
          title={!product ? "Please contact your admin to avail Product Services." : ""}
          className="MyForm__Section_Tooltip Onboarding__Tooltip"
        >
          <StyledButton
            type="primary"
            disabled={!product}
            onClick={handleProductClick}
            className="CreateSample_SubmitBtn"
            loading={loadingButton === "product" ? loadingFields : false}
          >
            Product
          </StyledButton>
        </StyledTooltip>

        <StyledTooltip
          title={!environment ? "Please contact your admin to avail Environment Services." : ""}
          className="MyForm__Section_Tooltip Onboarding__Tooltip"
        >
          <StyledButton
            type="primary"
            disabled={!environment}
            onClick={handleEnvironmentClick}
            className="CreateSample_SubmitBtn"
            loading={loadingButton === "environment" ? loadingFields : false}
          >
            Environment
          </StyledButton>
        </StyledTooltip>
      </div>
    </Modal>
  );
}

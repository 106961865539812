import React from "react";
import "./builderHeader.css";

import BuilderBackButton from "./BuilderBackButton";

export default function BuilderHeader(props) {
  const {
    toggleSaveModal,
    handleBackButton,
    showSaveModal,
    isCustomizingCoa,
    // checkTemplateValidation,
  } = props;

  const handleSave = () => {
    // const isValid = checkTemplateValidation();
    // if (isValid) {
    toggleSaveModal();
    // }
  };

  return (
    <div className="templateBuilderHeader">
      <BuilderBackButton handleBackButton={handleBackButton} isCustomizingCoa={isCustomizingCoa} />
      {!showSaveModal ? <div onClick={handleSave} className="templateBuilderHeaderSaveButton">{`Save ${isCustomizingCoa ? "report" : "template"}`}</div> : <div className="templateBuilderHeaderSaveButtonHidden" /> }
    </div>
  );
}

import React from "react";
import "./SpecManagerInput.css";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";

class SpecManagerInput extends React.Component {
  /**
 * Searches for invalid character or multiple periods in string. Returns appropriate error message if found. Else, returns that string is valid with null error message.
 * @param {String} string input value
 * @returns {Object} { isValid: true | false, errorMessage: String | null } isValid is true and errorMessage is null if string is valid
 */
static getInputError = (string) => {
  if (!string) { // empty string is valid
    return { isValid: true, errorMessage: null };
  }

  const firstMatch = string.match(/[^0-9,\\.]/); // matches the first invalid (any character other than digit, comma, period) character
  if (firstMatch !== null) { // invalid char found
    let invalidChar = firstMatch[0];

    if (firstMatch[0] === " ") {
      invalidChar = "space";
    }
    return { isValid: false, errorMessage: `Invalid input, remove ${invalidChar}` };
  }

  const moreThanOnePeriod = /\.(?=[^\\.]*\.+[^\\.]*)/.test(string); // true if there's more than one period
  if (moreThanOnePeriod) {
    return { isValid: false, errorMessage: "Invalid input, multiple '.'" };
  }

  return { isValid: true, errorMessage: null };
};

constructor(props) {
  super(props);

  this.state = {
    // cursorPosition: 0,
    errorMessage: "",
    focused: false,
  };

  this.inputRef = React.createRef();
}

componentDidMount() {
  const { value } = this.props;

  const valid = SpecManagerInput.getInputError(value);
  this.updateErrorMessage(valid, false);
}

// onKeyPress = (e) => {
//   if (e.key === "Enter") {
//     e.preventDefault();
//     e.target.blur();
//   }
// }

// onKeyDown = (e) => {
//   const { onChange } = this.props;
//   if (e.key === "Backspace") {
//     // console.log("backspace");
//     e.preventDefault();
//     const selectionPositionStart = e.target.selectionStart;
//     const selectionPositionEnd = e.target.selectionEnd;

//     const singleSelected = selectionPositionStart === selectionPositionEnd;

//     let text = e.target.value;

//     if (singleSelected && selectionPositionStart !== 0) {
//       text = text.slice(0, selectionPositionStart - 1) + text.slice(selectionPositionStart);
//     } else {
//       text = text.slice(0, selectionPositionStart) + text.slice(selectionPositionEnd);
//     }

//     this.setState({
//       cursorPosition: singleSelected && selectionPositionStart !== 0 ? selectionPositionStart - 1 : selectionPositionStart,
//     }, () => {
//       const { cursorPosition } = this.state;
//       this.inputRef.current.selectionStart = cursorPosition;
//       this.inputRef.current.selectionEnd = cursorPosition;
//     });
//     onChange(text);

//     const valid = getInputError(text);
//     this.updateErrorMessage(valid);
//   } else if (e.key === "Delete") {
//     e.preventDefault();
//     const selectionPositionStart = e.target.selectionStart;
//     const selectionPositionEnd = e.target.selectionEnd;

//     const singleSelected = selectionPositionStart === selectionPositionEnd;

//     let text = e.target.value;

//     if (singleSelected) {
//       text = text.slice(0, selectionPositionStart) + text.slice(selectionPositionStart + 1);
//     } else {
//       text = text.slice(0, selectionPositionStart) + text.slice(selectionPositionEnd);
//     }

//     this.setState({
//       cursorPosition: selectionPositionStart,
//     }, () => {
//       const { cursorPosition } = this.state;
//       this.inputRef.current.selectionStart = cursorPosition;
//       this.inputRef.current.selectionEnd = cursorPosition;
//     });
//     onChange(text);

//     const valid = getInputError(text);
//     this.updateErrorMessage(valid);
//   }
// }

  onKeyDown = (e) => {
    const { value } = this.props; // curr value in parent
    /* User inputted a character. Delete, Backspace, Tab, Shift, Cmd/Ctrl + v etc. won't go in this if condition. Shift/Caps Lock + char will go into this condition. */
    if (e.key.length === 1 && !(e.altKey || e.ctrlKey || e.metaKey)) {
      const validChar = e.key.match(/[0-9,\\.]/);
      let invalid = validChar === null;
      /* If the char is a period and there's already a period in value, it's invalid */
      if (validChar !== null && validChar[0] === "." && value.includes(".")) {
        invalid = true;
      }
      if (invalid) {
        e.preventDefault();
      }
    }

    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  }

  onChange = (e) => {
    const { onChange } = this.props;

    const text = e.target.value;
    const valid = SpecManagerInput.getInputError(text);

    onChange(text);
    this.updateErrorMessage(valid);
  }

  updateErrorMessage(validObj, focus = true) {
    if (validObj.isValid === true) {
      this.setState({
        errorMessage: "",
      });
      if (focus) window.setTimeout(() => this.inputRef.current.focus(), 0);
    }

    if (validObj.errorMessage !== null) {
      this.setState({
        errorMessage: validObj.errorMessage,
      });
    }
  }

  render() {
    const {
      placeHolder,
      value,
      width,
      height,
      additionalClassName,
    } = this.props;

    const { errorMessage, focused } = this.state;

    return (
      <StyledTooltip
        title={focused ? errorMessage : ""}
        placement="top"
      >
        <input
          id="environmentReportSpecManagerInput"
          type="text"
          className={errorMessage !== "" ? `${additionalClassName} SpecManagerCustomInput rangeValueMissing` : `${additionalClassName} SpecManagerCustomInput`}
          ref={this.inputRef}
          style={{
            height: height || "32px",
            width: width || "100px",
          }}
          placeholder={placeHolder}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          onKeyDown={this.onKeyDown}
          value={value || ""}
          onFocus={() => {
            this.setState({
              focused: true,
            });
          }}
          onBlur={() => {
            this.setState({
              focused: false,
            });
          }}
        />
      </StyledTooltip>
    );
  }
}
export default SpecManagerInput;

import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Radio,
  Space,
} from "antd";
import { toast } from "react-toastify";
import MyForm from "../MyForm";
import "./InviteForm.css";
import "../../../index.css";
import { validateEmail, validateEmailDomain, validateEmailUsername } from "../../Common/utils/validationUtils";
import { getCustomerInfo } from "../../../actions/onboarding";
import { parseDistributionList } from "../helpers";
import { checkUser } from "../../../utils/common";

export default function InviteCustomerForm({ layout, onFinish }) {
  const [form] = Form.useForm();
  const [customerStatus, setCustomerStatus] = useState("new");
  const [existingUser, setExistingUser] = useState(false);
  const [customerCheckInProgress, setCustomerCheckInProgress] = useState(false);
  const [customerInfoLoading, setCustomerInfoLoading] = useState(false);
  const [userCheckInProgress, setUserCheckInProgress] = useState(true); // set default true so submit button is disabled until check user has been called
  const [checkUserLoading, setCheckUserLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  /**
   * A validator which validates an email address format
   * @param {Rule} _ antd form Rule
   * @param {String} email
   * @returns {Promise}
   */
  const validateEmailHandler = (_, email) => {
    // console.log("validate email", _, email);
    if (email && !validateEmail(email)) {
      return Promise.reject(new Error("Invalid email"));
    }
    return Promise.resolve();
  };

  /**
   * Validate email length is less than or equal to 500
   * @param {Rule} _ antd form Rule
   * @param {String} email
   * @returns {Promise}
   */
  const validateEmailLengthHandler = (_, email) => {
    if (email && email.length > 500) {
      return Promise.reject(new Error("Customer email cannot be longer than 500 characters"));
    }
    return Promise.resolve();
  };

  /**
   * Validate email username format
   * @param {Rule} _ antd form Rule
   * @param {String} username
   * @returns {Promise}
   */
  const validateEmailUsernameHandler = (_, username) => {
    if (username && !validateEmailUsername(username)) {
      return Promise.reject(new Error("Invalid email username"));
    }
    return Promise.resolve();
  };

  /**
   * Validate email domain format
   * @param {Rule} _ antd form Rule
   * @param {String} domain
   * @returns {Promise}
   */
  const validateEmailDomainHandler = (_, domain) => {
    if (domain && !validateEmailDomain(domain)) {
      return Promise.reject(new Error("Invalid email domain"));
    }
    return Promise.resolve();
  };

  /**
   * Validate the current form and make an api call to send out the information.
   */
  const handleSubmit = async () => {
    try {
      const validateForm = await form.validateFields();
      if (!validateForm.errorFields) {
        setSubmitting(true);
        const values = { ...form.getFieldsValue() };
        if (customerStatus === "existing") {
          values.customer_email = `${values.customer_email.username.trim()}@${values.customer_email.domain.trim()}`; // concatenate username and domain
        }
        const submitSuccess = await onFinish(values);
        if (!submitSuccess) {
          setSubmitting(false);
        }
      }
    } catch (e) {
      toast.error("Invalid input");
    }
  };

  /** Clear form when switching between new and existing */
  useEffect(() => {
    if (form) {
      form.resetFields();
      setExistingUser(false);
    }
  }, [customerStatus, form]);

  /**
   * Parse company domain from first email of the email list
   * @param {String} emailListStr
   * @returns {String} company domain
   */
  const parseCompanyDomain = (emailListStr) => {
    const emailsArr = parseDistributionList(emailListStr);
    if (emailsArr.length) {
      const firstEmail = emailsArr[0];
      const firstEmailSplit = firstEmail.split("@");
      if (firstEmailSplit.length === 2 && firstEmailSplit[1].trim()) {
        return firstEmailSplit[1].trim();
      }
    }
    return "";
  };

  /**
   * Check if user exists. If user exists, autofill customer name.
   * @param {Object} customerEmailDomain (only for customer status "existing") customer_email.domain
   */
  const handleCheckUser = async (customerEmailDomain) => { // customerEmailDomain will only be passed as a param after handleAutofillCustomerInfo (at this point, customer_email value may not have been updated due to async set state, so directly pass as param)
    let customerEmail = form.getFieldValue("customer_email") ?? "";
    if (customerStatus === "existing") {
      const username = customerEmail.username?.trim() ?? "";
      const domain = customerEmailDomain ?? customerEmail.domain ?? "";
      customerEmail = `${username}@${domain}`; // concatenate username and domain
    }
    customerEmail = customerEmail.trim();
    if (customerEmail && (userCheckInProgress || customerEmailDomain !== undefined)) { // after domain autofill and handleCheckUser is called, userCheckInProgress is false but we still want to call checkUser, check if this was called after domain autofill
      setCheckUserLoading(true);
      const { success, user_name } = await checkUser(customerEmail);

      if (success) {
        form.setFieldsValue({ customer_name: user_name });
        setExistingUser(!!user_name);
      } else {
        form.setFieldsValue({ customer_name: "" });
        setExistingUser(false);
      }
    }
    if (!customerEmail) {
      form.setFieldsValue({ customer_name: "" });
      setExistingUser(false);
    }
    // form.validateFields(["customer_name"]);
    setCheckUserLoading(false);
    setUserCheckInProgress(false);
  };

  /**
   * Fetch company name and domain with customer id, autofill company name + user email suffix
   */
  const handleAutofillCustomerInfo = async () => {
    const customerIDVal = form.getFieldValue("customer_id");
    if (customerCheckInProgress) {
      setCustomerInfoLoading(true);
      if (customerIDVal.trim()) {
        const { success, company, email } = await getCustomerInfo(customerIDVal.trim());
        if (success) {
          const domain = parseCompanyDomain(email);
          form.setFieldValue("company_name", company);
          form.setFieldValue(["customer_email", "domain"], domain);
          form.validateFields(["customer_email", "company_name"], { recursive: true, dirty: true });
          await handleCheckUser(domain);
        } else {
          toast.error("Customer ID not found");
          form.setFieldValue("company_name", "");
          form.setFieldValue(["customer_email", "domain"], "");
          form.validateFields(["customer_email", "company_name"], { recursive: true, dirty: true });
          await handleCheckUser("");
        }
      } else {
        form.setFieldValue("company_name", "");
        form.setFieldValue(["customer_email", "domain"], "");
        form.validateFields(["customer_email", "company_name"], { recursive: true, dirty: true });
        await handleCheckUser("");
      }
    }
    setCustomerCheckInProgress(false);
    setCustomerInfoLoading(false);
  };

  /**
   * Get list of errors for customer email field when customer status is existing
   * @param {Function} getFieldError get list of errors for a form item
   * @returns {Array}
   */
  const getExistingCustomerEmailErrors = (getFieldError) => {
    const userNameErrors = getFieldError(["customer_email", "username"]);
    const domainErrors = getFieldError(["customer_email", "domain"]);
    const errorsToShow = [];
    let maxLengthError = false;
    userNameErrors.forEach((message) => {
      if (message) {
        errorsToShow.push(message);
        if (message === "Customer email cannot be longer than 500 characters") {
          maxLengthError = true;
        }
      }
    });
    domainErrors.forEach((message) => {
      if (message && (message !== "Customer email cannot be longer than 500 characters" || !maxLengthError)) { // don't add max length error if already added
        errorsToShow.push(message);
      }
    });
    return errorsToShow;
  };

  const disableInteraction = customerInfoLoading || checkUserLoading || submitting;

  return (
    <div className="OnboardInviteForm__MainContainer">
      <MyForm
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...layout}
        form={form}
        requiredMark={false}
        name="invite_form"
        className="InviteForm"
        validateTrigger={["onChange", "onBlur"]}
        disabled={disableInteraction}
      >

        <Form.Item>
          <div className="InviteForm__HeaderContainer">
            <span className="InviteForm__Header">Customer Invite</span>
            <span className="InviteForm__HeaderInfo">Enter the customer&apos;s information to send an invite to a new or existing customer</span>
          </div>
        </Form.Item>

        <Radio.Group
          className="InviteForm__CustomerStatusToggle"
          defaultValue="new"
          onChange={(e) => setCustomerStatus(e.target.value)}
        >
          <Radio.Button value="new">New</Radio.Button>
          <Radio.Button value="existing">Existing</Radio.Button>
        </Radio.Group>

        {customerStatus === "existing" && (
          <Form.Item
            name="customer_id"
            label="Customer ID"
            rules={[
              { required: true, message: "" },
            ]}
          >
            <Input onChange={() => setCustomerCheckInProgress(true)} onBlur={handleAutofillCustomerInfo} onPressEnter={handleAutofillCustomerInfo} />
          </Form.Item>
        )}

        {customerStatus === "existing" && (
        <Form.Item
          name="company_name"
          label="Company Name"
          rules={[
            { required: true, message: "" },
            { max: 100, message: "Company name cannot be longer than 100 characters" },
          ]}
        >
          <Input disabled={disableInteraction || customerStatus === "existing"} />
        </Form.Item>
        )}

        {customerStatus === "new" ? (
          <Form.Item
            name="customer_email"
            label="Customer Email"
            rules={[
              { required: true, message: "" },
              { validator: validateEmailHandler },
              { validator: validateEmailLengthHandler },
            ]}
          >
            <Input onChange={() => setUserCheckInProgress(true)} onBlur={() => handleCheckUser()} />
          </Form.Item>
        ) : (
          <Form.Item
            label="Customer Email"
            dependencies={[["customer_email", "username"], ["customer_email", "domain"]]}
            help="" // hide error messages shown by antd (duplicates will cause warnings, error messages handled below)
          >
            {({ getFieldError }) => (
              <>
                <Space.Compact>
                  <Form.Item
                    noStyle
                    dependencies={[["customer_email", "domain"]]}
                    name={["customer_email", "username"]}
                    rules={[
                      { required: true, message: "" },
                      { validator: validateEmailUsernameHandler },
                      ({ getFieldValue }) => ({
                        validator: (rule, value) => validateEmailLengthHandler(rule,
                          `${value?.trim() ?? ""}@${getFieldValue(["customer_email", "domain"])?.trim() ?? ""}`), // concatenate username and domain for email validation
                      }),
                    ]}
                  >
                    <Input placeholder="Enter email username" onChange={() => setUserCheckInProgress(true)} onBlur={() => handleCheckUser()} />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    dependencies={[["customer_email", "username"]]}
                    name={["customer_email", "domain"]}
                    rules={[
                      { required: true, message: "" },
                      { validator: validateEmailDomainHandler },
                      ({ getFieldValue }) => ({
                        validator:
                        (rule, value) => validateEmailLengthHandler(rule,
                          `${getFieldValue(["customer_email", "username"])?.trim() ?? ""}@${value?.trim() ?? ""}`), // concatenate username and domain for email validation
                      }),
                    ]}
                  >
                    <Input placeholder="Enter company domain" prefix="@" onChange={() => setUserCheckInProgress(true)} onBlur={() => handleCheckUser()} />
                  </Form.Item>
                </Space.Compact>
                <div className="InviteForm__CustomErrorsContainer">
                  <div className="ant-form-item-explain ant-form-item-explain-connected">
                    {getExistingCustomerEmailErrors(getFieldError).map((message, i) => {
                      if (message) {
                        return <div key={i} className="ant-form-item-explain-error">{message}</div>;
                      }
                      return null;
                    })}
                  </div>
                </div>
              </>
            )}
          </Form.Item>
        )}

        <Form.Item
          name="customer_name"
          label="Customer Name"
          rules={[
            { required: true, message: "" },
            { max: 50, message: "Customer name cannot be longer than 50 characters" },
          ]}
        >
          <Input disabled={disableInteraction || existingUser} />
        </Form.Item>

        {customerStatus === "new" && (
        <Form.Item
          name="company_name"
          label="Company Name"
          rules={[
            { required: true, message: "" },
            { max: 100, message: "Company name cannot be longer than 100 characters" },
          ]}
        >
          <Input disabled={disableInteraction || customerStatus === "existing"} />
        </Form.Item>
        )}

        <Form.Item
          name="sales_email"
          label="Sales Email"
          rules={[
            { required: true, message: "" },
            { validator: validateEmailHandler },
            { max: 500, message: "Sales email cannot be longer than 500 characters" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="csm_email"
          label="CSM Email"
          rules={[
            { required: true, message: "" },
            { validator: validateEmailHandler },
            { max: 500, message: "CSM email cannot be longer than 500 characters" },
          ]}
        >
          <Input />
        </Form.Item>
      </MyForm>
      <div className="InviteForm__BtnContainer__RightAlign">
        <Button
          type="primary"
          htmlType="submit"
          className="Ant__SubmitBtn"
          onClick={() => handleSubmit()}
          disabled={disableInteraction || customerCheckInProgress || userCheckInProgress}
          loading={submitting}
        >
          Send Invite
        </Button>
      </div>
    </div>

  );
}

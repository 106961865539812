import React from "react";

import { motion } from "framer-motion/dist/framer-motion";

import { INVALID_CHARS, INVALID_CHARS_ESCAPED } from "../../Constant";
import ErrorCheckingAutocompleteInput from "../../ReusableComponents/ErrorCheckingAutocomplete";

import { removeReplicateTag } from "../../helpers";

import MinusIconPng from "../../../../../assets/images/sample-submission/FieldsMinusIcon.png";

export default function SampleDetailsAutocompleteInput({
  setFieldValue,
  handleDeselectField,
  inputValuesSavedForNext,
  inputValues,
  setInputValuesRefs,
  json_field,
  idx,
  toggleSaveForNext,
  sampleFieldsInfo,
  delimiterRegex,
  setFieldInputInvalid,
  charLimit,
  dropdownSuggestionsObj,
  sanitizeDropdown,
  hideSaveForNext,
  sampleIdFields,
  isCompositeSample,
}) {
  const invalidRegexArr = [...INVALID_CHARS_ESCAPED];
  if (delimiterRegex && json_field !== "sample_id" && sampleIdFields.has(json_field)) { // if not empty string
    invalidRegexArr.push(delimiterRegex);
  }

  /** On option click in autocomplete dropdown, set value in parent, auto fill product_name and tests
   * @param {String} selectedOption value of autocomplete option
   */
  const handleSelectOption = (selectedOption) => {
    setFieldValue(json_field, selectedOption.trim(), true);
  };

  /**
   * On change, set value of field in parent
   */
  const onChange = (value) => {
    if (value !== inputValues[json_field]) {
      setFieldValue(json_field, value); // Don't use the trimmed value here to avoid the bad ux of whitespace being removed when the user is in the middle of typing. The input value will be trimmed before api calls (except for autofill)
    }
  };

  /** Get field's suggestions for the dropdown list */
  const getDropdownList = () => {
    if (dropdownSuggestionsObj[json_field] && dropdownSuggestionsObj[json_field].length) {
      return sanitizeDropdown(dropdownSuggestionsObj[json_field], true);
    }
    return [];
  };

  return (
    <motion.div layout key={json_field} className="input-checkbox-row">
      <motion.div className="sample-details-input">
        <ErrorCheckingAutocompleteInput
          ref={(input) => setInputValuesRefs(json_field, input)}
          label={sampleFieldsInfo[json_field].title_field}
          labelDivClassName="sample-details-input-title"
          inputID={`${json_field}-input`}
          value={(inputValues[json_field]) ?? ""}
          suggestions={getDropdownList()}
          invalidChars={invalidRegexArr}
          invalidKeys={INVALID_CHARS}
          handleSelectOption={handleSelectOption}
          onChange={onChange}
          onErrorChange={(inputErrorSet) => setFieldInputInvalid(json_field, inputErrorSet.size > 0)}
          charLimit={charLimit}
          handleSanitizeInputOnAutoFill={(val) => removeReplicateTag(val)}
        />
        <img
          onClick={() => handleDeselectField(idx, json_field)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleDeselectField(idx, json_field);
            }
          }}
          tabIndex={0} //eslint-disable-line
          src={MinusIconPng}
          alt="field minus icon"
          className="sample-field-minus-icon"
        />
      </motion.div>
      {!hideSaveForNext && (
        !isCompositeSample && (
        <motion.div className="sample-submission-checkbox-container">
          <input type="checkbox" checked={inputValuesSavedForNext[json_field] ?? false} onChange={() => { toggleSaveForNext(json_field); }} />
          <span className="sample-submission-checkmark" onClick={() => { toggleSaveForNext(json_field); }} />
        </motion.div>
        )
      )}
    </motion.div>
  );
}

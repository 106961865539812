import axios from "axios";
import { API_URL } from "../utils/Constant";

export const getSpecsData = async (payload, isUpdating = false, signal) => { /* eslint-disable-line import/prefer-default-export */
  try {
    const params = {
      filter: payload.filter,
      search_value: payload.search,
      product_id: payload.product_id,
      specs: payload.specs,
      page: payload.page,
      search_field: payload.search_field,
    };
    let response;
    if (isUpdating) {
      params.action = payload.action ? payload.action : "update_specs";
      response = await axios.patch(`${API_URL}/specsmanager/`, params);
    } else {
      response = await axios.get(`${API_URL}/specsmanager/`, { params, signal });
    }

    const {
      prev_page_link: prevPageLink, next_page_link: nextPageLink, last_page_link: lastPageLink, total_page: totalPage,
    } = response.data;
    const res = {
      prevPageLink, nextPageLink, lastPageLink, totalPage,
    };

    if (response.data.message === "Success" || response.data.message === "success") {
      return { success: true, files: response.data.result, pages: res };
    } if (response.data.message === "Specs and status updated, reports from last 30 days updated") {
      return { success: true };
    }

    const message = "Something went wrong.";
    return { success: false, message };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message, canceled: e.code === "ERR_CANCELED" };
  }
};

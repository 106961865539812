import React from "react";
import Highlighter from "react-highlight-words";
import Scrollbar from "react-scrollbars-custom";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import { ReactComponent as LoadingDots } from "../../../../../assets/images/common/LoadingDots.svg";
import watermelon from "../../../../../assets/images/EmptyState_V2.png";

import "./TestSuggestionList.css";

export default function TestSuggestionList(props) {
  const {
    testSuggestionsScrollbar, testSuggestions, addedTestSet, searched, isLoading, loading, handleAddNewTest,
  } = props;

  /**
 * Add enable or disable class name to list item
 * @param {Boolean} disableSelection whether item should be disabled or not
 * @returns {String} className
 */
  const disabledClassName = (disableSelection) => {
    let className = "";
    if (disableSelection) {
      className = "TestSuggestionList__DisabledSuggestion";
    } else {
      className = "TestSuggestionList__UnselectedSuggestion";
    }
    return className;
  };

  return (
    <div className="TestSuggestionList__TestList">
      {isLoading && (
        <div className="TestSuggestionList__Loading">
          <LoadingDots width={28} height={28} fill="#19305A" />
        </div>
      )}
      {!isLoading && (
        testSuggestions.length > 0 ? (
          <Scrollbar id="TestSuggestionList__ScrollBar" ref={testSuggestionsScrollbar}>
            <ul className="TestSuggestionList__SmartSuggestionsList">
              {testSuggestions.map(({ test_display_name }) => {
                const disableTest = addedTestSet.current.has(test_display_name.toLowerCase());
                return (
                  <StyledTooltip
                    title={test_display_name}
                    placement="right"
                    key={test_display_name}
                  >
                    <li
                      key={test_display_name}
                      onClick={disableTest || loading ? null : () => handleAddNewTest(test_display_name)}
                      className={disabledClassName(disableTest)}
                    >
                      <Highlighter
                        highlightClassName="searchHighlight"
                        searchWords={[searched]}
                        autoEscape
                        textToHighlight={test_display_name}
                      />
                    </li>
                  </StyledTooltip>
                );
              })}
            </ul>
          </Scrollbar>
        ) : (
          <div className="TestSuggestionList__NoResult">
            <img
              className="TestSuggestionList__NoResultWatermelonImg"
              alt="no result"
              src={watermelon}
            />
            <div className="TestSuggestionList__NoResultErrorMessage">
              No Tests Found
            </div>
          </div>
        ))}
    </div>
  );
}

import React from "react";
import BuilderSection from "./BuilderSection";
import COAPlaceholderSection from "./COAPlaceholderSection";
import "../TemplateBuilderCenter.css";

export default function TemplateBuilderCenterContent(props) {
  const {
    dataFields,
    handleSectionSelect,
    sections,
    sectionsFixed,
    sectionRefs,
    setSectionInputs,
    handleBuilderFile,
    handleTemplateDataFieldsChange,
  } = props;
  return (
    <form className="templateBuilderCenterContent">
      <BuilderSection
        sectionIndex={0}
        section={sectionsFixed[0]}
        handleSectionSelect={handleSectionSelect}
        id={sectionsFixed[0].title}
        handleBuilderFile={handleBuilderFile}
        sectionRefs={sectionRefs}
        setSectionInputs={setSectionInputs}
      />
      {sections.map((section, index) => (
        <BuilderSection
          key={index}
          handleSectionSelect={handleSectionSelect}
          sectionIndex={index}
          section={section}
          dataFields={dataFields}
          id={section.title}
          sectionRefs={sectionRefs}
          handleTemplateDataFieldsChange={handleTemplateDataFieldsChange}
          setSectionInputs={setSectionInputs}
        />
      ))}
      <COAPlaceholderSection />
      <BuilderSection
        sectionIndex={1}
        section={sectionsFixed[1]}
        handleSectionSelect={handleSectionSelect}
        id={sectionsFixed[1].title}
        handleBuilderFile={handleBuilderFile}
        setSectionInputs={setSectionInputs}
        sectionRefs={sectionRefs}
      />
    </form>
  );
}

import React from "react";

import { ReactComponent as CutIcon } from "../../../../assets/images/sample-submission/CutIcon.svg";

import "./SamplePageHeader.css";

export default function SamplePageHeader() {
  return (
    <div className="SamplePageHeader__Container">
      <CutIcon className="SamplePageHeader__Icon" />
      <p className="SamplePageHeader__Title">Print and cut the labels for use</p>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Scrollbar from "react-scrollbars-custom";
import NoResults from "../../../../../../Common/NoResults";
import { getAllFilePaths } from "../../../../../../../actions/envAnalytics";
import { ReactComponent as CloseButton } from "../../../../../../../assets/images/environment/WhiteCloseBlueBackground.svg";
import { ReactComponent as LoadingDots } from "../../../../../../../assets/images/environment/3-dots-scale.svg";
import pumpkin from "../../../../../../../assets/images/environment/pumpkin.gif";
import FileCard from "./FileCard";
import "./ReportHistory.css";

export default function ReportHistory({ locationID, setShowReportHistory }) {
  const [filePaths, setFilePaths] = useState([]);
  const [loadingNewContent, setLoadingNewContent] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [paginationInfo, setPaginationInfo] = useState({ currentPage: 1, totalPages: 1 });

  /**
   * Get file paths by page
   */
  async function apiGetAllFilePaths(page = 1) {
    setLoadingHistory(true);
    if (locationID) {
      const {
        success,
        message,
        paths,
        total_page,
      } = await getAllFilePaths({ location_id: locationID, page });
      if (success) {
        filePaths.push(...paths);
        setFilePaths(filePaths);
        setPaginationInfo({ currentPage: page, totalPages: total_page });
      } else {
        toast.error(message);
      }
    }
    setLoadingHistory(false);
  }

  /**
   * componentDidMount - make api call to get all report paths
   */
  useEffect(() => {
    apiGetAllFilePaths();
  }, []); // eslint-disable-line

  /**
   * On scroll stop, if scroll bar is at the bottom, load the next page of rows
   * @param  {Event} e on scroll stop event
   */
  const handleScrollToBottom = (e) => {
    const handlePageChangeScroll = async () => {
      const {
        currentPage, totalPages,
      } = paginationInfo;
      const nextPage = currentPage < totalPages ? currentPage + 1 : 0;
      if (!loadingNewContent && nextPage) {
        setLoadingNewContent(true);
        await apiGetAllFilePaths(nextPage);
        setLoadingNewContent(false);
      }
    };
    const tolerance = 3;
    const bottom = Math.abs(Math.ceil(e.contentScrollHeight - e.scrollTop) - Math.ceil(e.clientHeight)) <= tolerance;
    if (bottom) {
      handlePageChangeScroll();
    }
  };

  const messageStyle = {
    position: "absolute",
    fontSize: "16px",
    color: "rgb(117, 138, 185)",
    fontFamily: "Roboto",
    marginTop: "144px",
  };

  return (
    <div className="env-report-history-container">
      <div className="env-report-history-header">
        <span>Report History</span>
        <CloseButton
          height={18}
          width={18}
          onClick={() => setShowReportHistory(false)}
        />
      </div>
      <div className="env-report-history-table">
        {!loadingHistory && filePaths.length > 0
        && (
        <Scrollbar
          onScrollStop={(e) => handleScrollToBottom(e)}
        >
          {filePaths.map((file, index) => (
            <FileCard
              key={index}
              file={file}
            />
          ))}
          {loadingNewContent && (<LoadingDots fill="#B3BFDB" width={20} />)}
        </Scrollbar>
        )}
        {!loadingHistory && filePaths.length === 0 && <NoResults messageStyle={messageStyle} message="No reports found" image={pumpkin} />}
      </div>
    </div>
  );
}

import React, { useState } from "react";
import BackArrow from "../../../../../../assets/images/product/template/BackArrow.png";
import BackArrowWhite from "../../../../../../assets/images/product/template/BackArrowWhite.png";

export default function BuilderBackButton(props) {
  const [hover, setHover] = useState(false);
  const { handleBackButton, isCustomizingCoa } = props;
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleBackButton}
      className="templateBuilderHeaderBackButton"
    >
      <img src={hover ? BackArrowWhite : BackArrow} alt="back arrow" />
      <span className="templateBuilderHeaderBackSpan">
        {isCustomizingCoa ? "Custom reports" : "Templates" }
      </span>
    </div>
  );
}

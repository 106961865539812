import React from "react";
import Scrollbar from "react-scrollbars-custom";
import { LayoutGroup } from "framer-motion/dist/framer-motion";

import SelectionTitleSkeleton from "../../CommonComponents/SelectionTitleSkeleton";
import NoResults from "../../../../../Common/NoResults";
import emptyStateIcon from "../../../../../../assets/images/EmptyState_V2.png";
import ProductCard from "./ProductCard";

export default function ProductList({
  title,
  updateSelectedOptions,
  disabled,
  productList,
  selectedProducts,
  loading,
  linkReportFields,
  fieldsMap,
  search,
  searchByField,
}) {
  const renderSelectedTiles = () => {
    if (selectedProducts && selectedProducts.length !== 0) {
      return selectedProducts.map((item, index) => (
        <ProductCard
          key={index}
          product={item}
          type="product"
          linkReportFields={linkReportFields}
          fieldsMap={fieldsMap}
          updateSelectedOptions={updateSelectedOptions}
          disabled={disabled}
          active
          search={search}
          searchByField={searchByField}
        />
      ));
    }

    return null;
  };

  // Renders all of the products and ignores any that are selected
  // The selected tiles will be rendered in a different component above
  const renderProductTiles = () => {
    if (loading) {
      return (<SelectionTitleSkeleton key="skeleton" />);
    }
    if (productList && productList.length !== 0) {
      return productList.map((item, index) => {
        let active = false;
        if (selectedProducts.some((selectedItem) => selectedItem.product_id === item.product_id)) active = true;

        if (active) return null;
        return (
          <ProductCard
            key={index}
            product={item}
            type="product"
            linkReportFields={linkReportFields}
            fieldsMap={fieldsMap}
            updateSelectedOptions={updateSelectedOptions}
            disabled={disabled}
            active={active}
            search={search}
            searchByField={searchByField}
          />
        );
      });
    }

    return (
      <NoResults message="No results found." image={emptyStateIcon} />
    );
  };

  return (
    <div className="analyticsProductListContainer">
      {title && <span className="analyticsProductListHeaderText">{title}</span>}

      <Scrollbar id="analyticsScrollbar">
        <div className="analyticsProductListContainerScrollable">
          <LayoutGroup>
            {renderSelectedTiles()}
            {renderProductTiles()}
          </LayoutGroup>
        </div>
      </Scrollbar>
    </div>
  );
}

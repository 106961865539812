import React, { useEffect, useState } from "react";
import CloseLightBlue from "../../../../../../assets/images/product/template/CloseLightBlue.png";
import BuilderSaveSuccess from "./BuilderSaveSuccess";
import SubmitButton from "./SubmitButton";
import { tagColorMap } from "./Constant";
import "./TemplateSave.css";

export default function TemplateSave({
  handleSaveTemplate,
  selectedTemplate,
  toggleSaveModal,
  setSaveStatus,
  saveStatus,
}) {
  const [input, setInput] = useState(selectedTemplate ? selectedTemplate.name : "");
  const tagArray = [
    { tag: "red", color: "#E63559" },
    { tag: "blue", color: "#3874FF" },
    { tag: "yellow", color: "#E1B226" },
    { tag: "orange", color: "#F98006" },
    { tag: "green", color: "#1EC04A" }];
  const [activeTag, setActiveTag] = useState(selectedTemplate ? selectedTemplate.tags[0] : null);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const onChange = (value) => {
    if (!value.includes("/")) {
      setInput(value);
    }
  };

  const handleTag = (tag) => {
    setActiveTag(tag);
  };

  const checkInputs = () => activeTag && input.trim();

  const handleCheckAndSaveTemplate = () => {
    const validInputs = checkInputs();
    if (validInputs) {
      setSaveStatus("loading");
      handleSaveTemplate({ name: input, tags: [activeTag] });
    }
  };

  useEffect(() => {
    setDisableSubmit(!checkInputs());
  }, [activeTag, input]); // eslint-disable-line

  return (
    <div className="templateSavePopup">
      {
        saveStatus === null && (
        <img
          className="TemplateSave__DeleteButton"
          onClick={() => toggleSaveModal(true)}
          src={CloseLightBlue}
          alt="close icon"
        />
        )
      }
      {saveStatus === "success" ? (
        <div className="TemplateSaveSuccess">
          <div
            className="TemplateSaveSuccess__TemplateBlock"
            style={{ backgroundColor: tagColorMap[activeTag] }}
          >
            <span>{input.trim()}</span>
          </div>
          <BuilderSaveSuccess
            text="Template Saved"
            textWidth="fit-content"
          />
        </div>
      ) : (
        <>
          <div className="templateSavePopupBody">
            <label className="saveInputSectionTop">
              <span className="saveInputSectionInputTitle">Template name</span>
              <input
                className="templateBuilderInput"
                type="text"
                placeholder="Text"
                value={input}
                onChange={((e) => onChange(e.target.value))}
                autoComplete="off"
              />
            </label>

            <label className="saveInputSectionBottom">
              <span className="saveInputSectionInputTags">Tags</span>
              <div className="saveInputSectionTags">
                {tagArray.map(({ tag, color }) => (
                  <div
                    key={tag}
                    onClick={() => handleTag(tag)}
                    className={tag === activeTag ? "saveInputSectionColorTag saveInputSectionColorTagActive" : "saveInputSectionColorTag"}
                    style={{ backgroundColor: color }}
                  />
                ))}
              </div>
            </label>
          </div>
          <div className="templateSavePopupFooter">
            <SubmitButton
              loading={saveStatus === "loading"}
              disabled={disableSubmit}
              handleSaveTemplate={handleCheckAndSaveTemplate}
              text="Save Template"
            />
          </div>
        </>
      )}
    </div>
  );
}

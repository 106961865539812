import React, { useEffect, useState } from "react";
import { Slider } from "antd";
import { ReactComponent as PlusIcon } from "../../../assets/images/sample-submission/plusSliderIcon.svg";
import { ReactComponent as MinusIcon } from "../../../assets/images/sample-submission/minusSliderIcon.svg";
import "./SliderWithButtons.css";

export default function SliderWithButtons(props) {
  const {
    setScale,
    scale,
  } = props;
  const MIN_SLIDER = 0;
  const MAX_SLIDER = 100;
  const MIN_SCALE = 1;
  const MAX_SCALE = 10;
  const STEP = 5;

  const convertSliderValToScale = (val) => MIN_SCALE + (val / MAX_SLIDER) * (MAX_SCALE - MIN_SCALE);
  const convertScaleToSliderVal = (_scale) => ((_scale - MIN_SCALE) * MAX_SLIDER) / (MAX_SCALE - MIN_SCALE);

  const [value, setValue] = useState(convertScaleToSliderVal(scale));

  useEffect(() => {
    setScale(convertSliderValToScale(value));
  }, [value]); // eslint-disable-line

  return (
    <div className="SampleSubmission__ImageUpload__Slider">
      <MinusIcon
        className="SampleSubmission__SliderButtons"
        onClick={() => setValue(Math.min(100, Math.max(0, value - STEP)))}
        width={20}
        height={20}
      />
      <Slider
        min={MIN_SLIDER}
        max={MAX_SLIDER}
        onChange={setValue}
        value={value}
        tooltip={{ open: false }}
      />
      <PlusIcon
        className="SampleSubmission__SliderButtons"
        onClick={() => setValue(Math.min(100, Math.max(0, value + STEP)))}
        width={20}
        height={20}
      />
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import DataFieldsSearch from "./DataFieldsSearch";
import "./DataFieldsModal.css";
import { manageDataFields, sortAndSearchDataFields } from "../../../../../actions/productTemplate";
import DataFieldsDeleteModal from "./DataFieldsDeleteModal";
import DataFieldsEditModal from "./DataFieldsEditModal";
import DataFieldsAdd from "./DataFieldsAdd";
import NoResults from "../../../../Common/NoResults";

import emptyStateIcon from "../../../../../assets/images/EmptyState_V2.png";
import SearchIcon from "../../../../../assets/images/product/template/SearchBlueIcon.png";
import DataFieldsMenu from "../../../../../assets/images/product/template/threeDotsIconGrey.png";
import DataFieldsEdit from "../../../../../assets/images/product/template/EditPencilWhite.png";
import DataFieldsDelete from "../../../../../assets/images/product/template/DeleteDataFieldsWhite.png";

export default function DataFieldsModal({ dataFieldCategory }) {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [fieldsList, setFieldsList] = useState([]);
  const [dataField, setDataField] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [elemToBeDeleted, setElemToBeDeleted] = useState("");
  const [elemToBeEdited, setElemToBeEdited] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [selectedField, setSelectedField] = useState("");
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const editRef = useRef(null);
  const deleteRef = useRef(null);

  const assignIds = (fieldsArray) => fieldsArray.map((fieldsObj) => ({ ...fieldsObj, id: uuidv4() }));
  const data = fieldsList.reduce((r, e) => {
    const group = e[0].toUpperCase();
    if (!r[group]) r[group] = { group, children: [e] };
    else r[group].children.push(e);
    return r;
  }, {});
  const result = Object.values(data);

  const apiSearchDataFields = async (params, issearching) => {
    setSearching(issearching);
    setLoading(true);
    const response = await sortAndSearchDataFields(params);
    if (response && response.success) {
      const withIds = assignIds(response.result);
      setFieldsList(withIds.map((ele) => ele.value));
    } else {
      toast.error("Failed to search data fields.");
    }
    setLoading(false);
  };

  const handleSubmitSearch = (value, issearching) => {
    setSearchValue(value);
    setSelectedField("");
    setShowMenu(false);
    setSearching(issearching);
    apiSearchDataFields({ sort_by: "Alphabetical order", search: value, category: dataFieldCategory }, issearching);
  };

  const apiGetDataFields = async (value) => {
    setLoading(true);
    const response = await sortAndSearchDataFields({ sort_by: "Alphabetical order", search: value || "", category: dataFieldCategory });
    if (response && response.success) {
      const withIds = assignIds(response.result);
      setFieldsList(withIds.map((ele) => ele.value));
    } else {
      toast.error("Failed to get data fields.");
    }
    setLoading(false);
  };

  const apiAddDataField = async (value) => {
    setLoading(true);
    const params = {
      field_name: value,
      action: "add",
      method: "POST",
      category: dataFieldCategory,
    };
    const response = await manageDataFields(params);
    setLoading(false);
    if (response && response.success) {
      if (response.message === "Success") {
        setSearchValue("");
        setDataField("");
        apiGetDataFields();
        return true;
      }
      toast.error(response.message);
    } else {
      toast.error(response.message);
    }
    return false;
  };

  const toggleShowEditModal = (elem) => {
    if (elem) {
      setElemToBeEdited(elem);
    } else {
      setSelectedField("");
      setShowMenu(false);
    }
    setShowEditModal(!showEditModal);
  };

  const toggleShowDeleteModal = (elem) => {
    if (elem) {
      setElemToBeDeleted(elem);
    } else {
      setSelectedField("");
      setShowMenu(false);
    }
    setShowDeleteModal(!showDeleteModal);
  };

  const apiDeleteDataField = async (field) => {
    const params = {
      field_name: field,
      field_in_use: "0",
      action: "delete",
      method: "PATCH",
      category: dataFieldCategory,
    };
    const response = await manageDataFields(params);
    toggleShowDeleteModal();
    if (response && response.success) {
      setShowMenu(false);
      setSelectedField("");
      if (response.message === "Success") {
        if (searchValue && searching) apiGetDataFields(searchValue);
        else apiGetDataFields();
        return true;
      }
      toast.error(response.message);
    } else {
      setShowMenu(false);
      toast.error("Failed to delete data field.");
    }
    return false;
  };

  const apiEditDataFieldTitle = async (prevName, newName) => {
    const params = {
      field_name: newName,
      field_name_pre: prevName,
      action: "edit",
      method: "PATCH",
      category: dataFieldCategory,
    };
    const response = await manageDataFields(params);
    toggleShowEditModal();
    if (response && response.success) {
      setShowMenu(false);
      setSelectedField("");
      if (response.message === "Success") {
        if (searchValue && searching) apiGetDataFields(searchValue);
        else apiGetDataFields();
        return true;
      }
    } else {
      setShowMenu(false);
      if (response.message !== "Failed to update. Field name already exists.") { toast.error(response.message); }
      if (response.message === "Failed to update. Field name already exists." && prevName !== newName) { toast.error(response.message); }
    }
    return false;
  };

  useEffect(() => {
    apiGetDataFields();
  }, []); // eslint-disable-line

  const toggleShowSearchBar = () => {
    setShowSearchBar((prevState) => {
      if (prevState) {
        if (searchValue) apiGetDataFields();
      }
      return !prevState;
    });
    setSearchValue("");
    setSearching(false);
  };

  const handleShowMenu = (value) => {
    setSelectedField(value);
    setShowMenu(true);
  };

  const handleOutsideClick = () => {
    setSelectedField("");
    setShowMenu(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if ((editRef.current && !editRef.current.contains(event.target)) && (deleteRef.current && !deleteRef.current.contains(event.target))) {
        setShowEditModal(false);
        setShowDeleteModal(false);
        handleOutsideClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editRef, deleteRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if ((editRef.current && !editRef.current.contains(event.target)) && (deleteRef.current && !deleteRef.current.contains(event.target))) {
          handleOutsideClick();
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div>
      <div className="dataFieldsModal">
        <DataFieldsAdd
          showDeleteModal={showDeleteModal}
          showEditModal={showEditModal}
          apiAddDataField={apiAddDataField}
        />
        <div className="dataFields_listSection">
          <div className={`${(showDeleteModal || showEditModal) ? "dataFields_listHeader Disabled" : "dataFields_listHeader"}`} onClick={handleOutsideClick}>
            <div className="dataFields_listLabel">Added Data fields</div>
            <div className="dataFields_searchIcon">
              {!showSearchBar
                && (
                <img
                  src={SearchIcon}
                  alt="SearchIcon"
                  onClick={() => toggleShowSearchBar(true)}
                  className="searchIcon"
                />
                ) }
            </div>
            {showSearchBar && (
            <div className="dataFields_searchContainer">
              <DataFieldsSearch
                handleSubmitSearch={handleSubmitSearch}
                toggleShowSearch={toggleShowSearchBar}
              />
            </div>
            )}
          </div>
          <motion.div layoutScroll className={`${(showDeleteModal || showEditModal) ? "dataFields_list Disabled" : "dataFields_list"}`}>
            {!loading && fieldsList.length === 0 && (!searching ? <div className="dataFields_listEmptyMessage">No data fields added. Add them here to later edit their values while customizing your reports</div> : <NoResults message="No results found" image={emptyStateIcon} />)}
            {!loading && fieldsList && result.map((field, i) => (
              <div className="dataFields_listItems" key={i}>
                <div onClick={handleOutsideClick} className="dataFields_group">{field.group.toUpperCase()}</div>
                {field.children.map((child, index) => (
                  <div className={`dataFields_listcards ${showMenu && selectedField === child ? "listcardsMenu" : ""}`} key={index}>
                    <motion.div
                      className="dataFields_listcard"
                      layout
                      key={child}
                      exit={{ opacity: 0 }}
                      transition={{ layout: { duration: 0.3 } }}
                      ref={ref}
                    >
                      <div className="dataFields_listcard_name">{child}</div>
                      <span className={selectedField !== child ? "dataFields_menu" : "dataFields_menu--hidden"} onClick={() => handleShowMenu(child)}><img src={DataFieldsMenu} alt="dataFieldsMenu" /></span>
                    </motion.div>
                    {selectedField === child && showMenu && <div ref={editRef} className="dataFields_edit" onClick={() => toggleShowEditModal(child)}><img src={DataFieldsEdit} alt="dataFieldsEdit" /></div>}
                    {selectedField === child && showMenu && <div ref={deleteRef} className="dataFields_delete" onClick={() => toggleShowDeleteModal(child)}><img src={DataFieldsDelete} alt="dataFieldsDelete" /></div>}
                  </div>
                ))}
              </div>
            ))}
            {loading && (<div className="dataFields_Loading ActiveloadingAnimation" />)}
          </motion.div>
          {showEditModal && <DataFieldsEditModal refVal={editRef} newField={dataField} setDataField={setDataField} field={elemToBeEdited} toggleShowEditModal={toggleShowEditModal} handleEdit={apiEditDataFieldTitle} />}
          {showDeleteModal && <DataFieldsDeleteModal refVal={deleteRef} field={elemToBeDeleted} toggleShowDeleteModal={toggleShowDeleteModal} onDelete={apiDeleteDataField} />}
        </div>
      </div>
    </div>
  );
}

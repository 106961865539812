import React, { Component } from "react";
import "./Documents.css";
import { toast } from "react-toastify";
// import async from "async";

import moment from "moment";
import { s3EsvPriv } from "../../utils/aws";
import {
  downloadFileFromS3, uploadFileToS3, deleteFileFromS3,
} from "../../utils/helpers";
// import successIcon from "../../assets/images/index/checkIconTrans.png";
import AddFileIcon from "../../assets/images/AddFileIcon_2x.png";
import DownloadIcon from "../../assets/images/DocDownloadIcon.png";
import DownloadHoverIcon from "../../assets/images/DocDownloadHoverIcon.png";
import CloseIcon from "../../assets/images/UploadFileModalCloseIcon.png";
import TrashHoverIcon from "../../assets/images/DocTrashHoverIcon.png";
import TrashIcon from "../../assets/images/DocTrashIcon.png";
import SearchIcon from "../../assets/images/DocSearchIcon.png";
import SearchIconActive from "../../assets/images/DocSearchIconActive.png";
import DocSearchIconClose from "../../assets/images/DocSearchIconClose.png";
import CardViewIcon from "../../assets/images/CardViewIcon.png";
import ListViewIcon from "../../assets/images/ListViewIcon.png";
import CardViewIconInactive from "../../assets/images/CardViewIconInactive.png";
import ListViewIconInactive from "../../assets/images/ListViewIconInactive.png";
import DocumentsCardView from "./Components/DocumentsCardView";
import DocumentsListView from "./Components/DocumentsListView";
import DocumentsConfirmDelete from "./Components/DocumentsConfirmDelete";

import { postFiles, getFiles, searchFiles } from "../../actions/documents";

export default class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardView: false,
      files: [],
      selectAll: false,
      fileSelected: false,
      numOfSelectedFiles: 0,
      showSearchIcon: true,
      searchValue: "",
      confirmDelete: false,
      batch: false,
      deleteItem: null,
    };
    this.docUploadInput = React.createRef();
    this.preventCheckbox = false;
  }

  componentDidMount() {
    this.apiGetFiles();
  }

  apiGetFiles = async () => {
    const response = await getFiles();
    if (response && response.success) {
      let results = response.result;
      const data = [];
      if (results.length < 1) {
        this.setState({ files: data });
      } else {
        results = results[0];
        results.files.forEach((file, index) => {
          data.push({
            key: index,
            name: file.name,
            extension: file.type,
            uploadDate: moment(file.uploaded).format("lll"),
            fileSize: file.size,
            file: file.file_path,
            checked: false,
            renameDisable: true,
            nubOfSelectedFile: 0,
            renameValue: file.name,
          });
        });
        this.setState({ files: data });
      }
    } else {
      toast.error(response.message);
    }
  };

  handleUploadedFiles = (event) => {
    const filesUploaded = Array.from(event.target.files);
    let { files } = this.state;
    let key = files.length;
    let name = "";
    let extension = "";
    let uploadDate = "";
    let fileSize = "";
    let filesArr = [];
    const expXLSX = new RegExp("sheet|spreadsheet/g");
    const expXLS = new RegExp("excel");
    const expDocX = new RegExp("officedocument");
    const expDoc = new RegExp("msword");
    let filesSet = [];
    let duplicateKeys = [];
    let dotIndex;
    const supportedExtensions = ["xlsx", "xls", "docx", "doc", "jpg", "jpeg", "pdf", "png"];

    filesUploaded.forEach((file) => {
      name = file.name;
      for (let i = name.length - 1; i >= 0; i--) {
        if (name[i] === ".") {
          dotIndex = i;
          break;
        }
      }
      name = name.substring(0, dotIndex);
      extension = file.type.split("/").pop();
      uploadDate = moment(Date.now()).format("YYYY-MM-DD hh:mm:ss a");
      fileSize = `${Math.ceil(file.size / 1024)}kb`;

      if (extension.match(expXLSX)) extension = "xlsx";
      if (extension.match(expXLS)) extension = "xls";
      if (extension.match(expDocX)) extension = "docx";
      if (extension.match(expDoc)) extension = "doc";

      if (supportedExtensions.includes(extension)) {
        filesArr.push({
          key,
          name,
          extension,
          uploadDate,
          fileSize,
          file,
          checked: false,
          renameDisable: true,
          nubOfSelectedFile: 0,
          renameValue: name,
        });
      } else {
        toast.error(`File type not supported: ${extension}`);
      }
      key++;
    });

    if (files.length < 1) {
      files = [...filesArr, ...files];
    } else {
      filesArr.forEach((uploadedFile, i) => {
        files.forEach((currentFile) => {
          if (uploadedFile.name === currentFile.name) {
            duplicateKeys.push(i);
          }
        });
      });

      if (duplicateKeys.length < 1) {
        // files = [...filesArr, ...files];
        files = [...filesArr];
      } else {
        duplicateKeys.forEach((keyItem) => {
          filesArr.forEach((file, index) => {
            if (index === keyItem) {
              toast.error("Duplicate file");
            } else {
              filesSet.push(file);
            }
          });
        });
        // files = [...filesSet, ...files];
        files = [...filesSet];
      }
    }
    filesArr = [];
    duplicateKeys = [];
    filesSet = [];
    if (files.length > 0) {
      this.apiPostFiles(files, "Add");
    }
  };

  apiPostFiles = async (files, category, key) => {
    let file;
    let payloadObj = {};
    const payloadArray = [];
    if (category === "Edit") {
      file = files[key];
      payloadObj = {
        old_name: file.name,
        new_name: file.renameValue,
      };

      if (payloadObj.old_name === payloadObj.new_name) {
        this.setState({ files });
        return;
      } if (payloadObj.new_name.trim() === "") {
        toast.error("File name cannot be empty");
        file.renameValue = file.name;
        files[key] = file;
        this.setState({ files });
        return;
      }
      payloadArray.push(payloadObj);
      // this.addFilesToAWS([file], async (error, response) => {
      const res = await postFiles(payloadArray, category);
      if (res && res.success) {
        if (res.message === "success") {
          toast.success("File renamed successfully");
          file.name = file.renameValue;
          files[key] = file;
          this.setState({ files });
        } else {
          toast.error(res.message);
          file.renameValue = file.name;
          files[key] = file;
          this.setState({ files });
        }
      } else {
        toast.error("Fail to rename");
        file.renameValue = file.name;
        files[key] = file;
        this.setState({ files });
      }
      // });
    } else {
      this.addFilesToAWS(files).then(async (filePaths) => {
        const successFilePaths = filePaths.filter((path) => path !== null);
        const res = await postFiles(successFilePaths, category);
        if (res && res.success) {
          if (res.message !== "duplicate value") {
            let results = res.result;
            const data = [];
            if (results.length < 1) {
              this.setState({ files: data, fileSelected: false });
            } else {
              results = results[0];
              results.files.map((file, index) => { // eslint-disable-line
                data.push({
                  key: index,
                  name: file.name,
                  extension: file.type,
                  uploadDate: moment(file.uploaded).format("LLL"),
                  fileSize: file.size,
                  file: file.file_path,
                  checked: false,
                  renameDisable: true,
                  nubOfSelectedFile: 0,
                  renameValue: file.name,
                });
              });
              this.setState({ files: data, fileSelected: false });
              toast.success("Files uploaded successfully");
            }
          }
        } else {
          toast.error("Files upload failed");
        }
      });
    }
  };

  addFilesToAWS = (files) => Promise.all(files.map(
    (file) => this.addAssetToAWS(file.file).then((response) => {
      if (response) {
        return {
          name: file.name,
          type: file.extension,
          size: file.fileSize,
          uploaded: file.uploadDate,
          file: response.path,
        };
      }
      return null;
    }),
  ))
  ;

  addAssetToAWS = async (file) => {
    if (file) {
      const uploadObj = await uploadFileToS3({
        file, folderPath: "Documents/", fileName: file.name, type: file.type,
      }, s3EsvPriv);
      return uploadObj;
    } return null;
  };

  handleAddFile = () => {
    this.docUploadInput.current.click();
    this.docUploadInput.current.addEventListener(
      "change",
      this.handleUploadedFiles,
    );
  };

  handleRenameFile = (event, item) => {
    /** The event.stopPropagation() does not close the menu,
     * so we need hte preventCheckbox here to prevent calling the handleCheckbox().
    */
    if (event) {
      event.stopPropagation();
    }
    this.preventCheckbox = true;
    const { files } = this.state;
    files.forEach((file) => {
      if (file.key === item.key) {
        file.renameDisable = !file.renameDisable;
      } else {
        file.renameDisable = true;
      }
    });

    this.setState({ files });
  };

  handleCancelRename = (e, key) => {
    e.stopPropagation();
    const { files } = this.state;
    files.forEach((file) => {
      if (file.key === key) {
        file.renameDisable = true;
        file.renameValue = file.name;
      }
    });
    this.setState({ files });
  }

  handleDeleteFile = async (event, item, batch) => {
    const { confirmDelete } = this.state;
    event.stopPropagation();
    let { files } = this.state;
    const awsFiles = [];
    if (batch) {
      const tempArray = files.filter((file) => file.checked === true);
      files = tempArray.map((file) => {
        awsFiles.push(file.file);
        return { name: file.name };
      });
    } else {
      awsFiles.push(item.file);
      files = [{ name: item.name }];
    }
    const response = await postFiles(files, "Delete");
    if (response && response.success) {
      this.apiGetFiles();
      // this.deleteFileAWS(awsFiles);
      toast.success("Files Deleted successfully");
    } else {
      toast.error("Files Delete failed");
    }

    this.setState({
      fileSelected: false,
      selectAll: false,
      confirmDelete: !confirmDelete,
    });
  };

  deleteFileAWS = (files) => {
    files.forEach((file) => {
      deleteFileFromS3(file, s3EsvPriv);
    });
  };

  handleConfirmDelete = (event, item, batch) => {
    const { confirmDelete } = this.state;
    /** The event.stopPropagation() does not close the menu,
     * so we need hte preventCheckbox here to prevent calling the handleCheckbox().
    */
    if (event) {
      event.stopPropagation();
    }
    this.preventCheckbox = true;
    this.setState({
      confirmDelete: !confirmDelete,
      batch,
      deleteItem: item,
    });
  };

  toggleConfirmDelete = () => {
    const { confirmDelete } = this.state;
    this.setState({ confirmDelete: !confirmDelete });
  };

  handleDownloadFile = (event, file, batch) => {
    /** The event.stopPropagation() does not close the menu,
     * so we need hte preventCheckbox here to prevent calling the handleCheckbox().
    */
    if (event) {
      event.stopPropagation();
    }
    this.preventCheckbox = true;
    const { files } = this.state;
    if (batch) {
      files.forEach((fileItem) => {
        if (fileItem.checked === true) {
          const fileName = fileItem.file.split("/").pop();
          downloadFileFromS3(fileItem.file, s3EsvPriv, fileName);
          fileItem.checked = false;
        }
      });
      toast.success("Files Download successfully");
    } else {
      const fileName = file.file.split("/").pop();
      downloadFileFromS3(file.file, s3EsvPriv, fileName);
      toast.success("Files Download successfully");
      // this.downloadFileAWSPreSignedUrl(file)
    }

    this.setState({
      fileSelected: false, selectAll: false,
    });
  };

  // downloadFileAWSPreSignedUrl = async (file) => {
  //   const params = { Bucket: s3EsvPriv.config.bucket, Key: file.file, Expires: 60 };
  //   s3EsvPriv.getSignedUrl("getObject", params, (err, url) => {
  //     console.log("PreSigned URL:", url);
  //   });
  // }

  handleChangeView = () => {
    const { cardView } = this.state;
    this.setState({
      cardView: !cardView,
    });
  };

  handleCheckbox = (event, key) => {
    // preventCheckbox = true when Rename/Download/Delete is click
    if (this.preventCheckbox) {
      this.preventCheckbox = false;
      return;
    }
    event.stopPropagation();
    const { files } = this.state;
    let selected = false;
    let counter = 0;
    files.forEach((file) => {
      if (file.key === key) {
        file.checked = !file.checked;
      }
    });

    files.forEach((file) => {
      if (file.checked === true) {
        selected = true;
        counter++;
      }
    });

    this.setState({
      files,
      fileSelected: selected,
      numOfSelectedFiles: counter,
    });

    if (counter === 0) {
      this.setState({ selectAll: false });
    } else if (counter === files.length) {
      this.setState({ selectAll: true });
    }
  };

  handleCheckboxAll = () => {
    let selected = false;
    const { files, selectAll } = this.state;
    let counter = 0;

    if (!selectAll) {
      files.forEach((file) => {
        file.checked = true;
      });
    } else {
      files.forEach((file) => {
        file.checked = false;
      });
    }

    files.forEach((file) => {
      if (file.checked === true) {
        selected = true;
        counter++;
      }
    });

    this.setState({
      files,
      selectAll: !selectAll,
      fileSelected: selected,
      numOfSelectedFiles: counter,
    });
  };

  handleClearAll = () => {
    const { files } = this.state;
    files.forEach((file) => {
      file.checked = false;
    });
    this.setState({
      files,
      selectAll: false,
      numOfSelectedFiles: 0,
      fileSelected: false,
    });
  };

  handleFileOptionClick = (event) => {
    event.stopPropagation();
  };

  handleFileOptionClose = (event) => {
    event.stopPropagation();
  };

  handleNameChange = (event, key) => {
    const { files } = this.state;
    const { value } = event.target;

    const invalidChars = ["/", "\\", ";", ","];
    const invalidString = invalidChars.some((char) => value.includes(char));

    if (!invalidString) {
      files.forEach((file) => {
        if (file.key === key) {
          file.renameValue = value;
        }
      });
      this.setState({ files });
    } else {
      toast.error("Invalid character entered.");
    }
  };

  handleNameSubmit = (event, key) => {
    const { files } = this.state;
    if (event.key === "Enter") {
      files.forEach((file) => {
        if (file.key === key) {
          file.renameDisable = true;
        }
      });
      // this.setState({ files: files });
      this.apiPostFiles(files, "Edit", key);
    }
  };

  handleSearchChange = async (value) => {
    this.setState({
      searchValue: value,
    });
    const response = await searchFiles(value);
    if (response && response.success) {
      let results = response.result;
      const data = [];
      if (results.length < 1) {
        this.setState({ files: data });
      } else {
        results = results[0];
        results.files.forEach((file, index) => {
          data.push({
            key: index,
            name: file.name,
            extension: file.type,
            uploadDate: moment(file.uploaded).format("lll"),
            fileSize: file.size,
            file: file.file_path,
            checked: false,
            renameDisable: true,
            nubOfSelectedFile: 0,
            renameValue: file.name,
          });
        });
        this.setState({ files: data });
      }
    } else {
      this.setState({ files: [] });
      toast.error(response.message);
    }
  };

  render() {
    const {
      cardView,
      // showAddFileModal,
      files,
      selectAll,
      fileSelected,
      numOfSelectedFiles,
      showSearchIcon,
      searchValue,
      confirmDelete,
      batch,
      deleteItem,
    } = this.state;
    const numFiles = files.length;

    return (
      <>
        <div className="docHeaderSection">
          <div className="docHeaderTitle"> My Documents</div>
          <div className="docHeaderStatus">
            <div>
              <div> Total Files: </div>
              <div>
                {numFiles}
              </div>
            </div>
            <div>
              <div> Last upload on: </div>
              <div>
                {numFiles > 0 ? files[numFiles - 1].uploadDate : "-"}
              </div>
            </div>
          </div>
        </div>
        <div className="docMainContainer">
          <div className="docTableWrapper">
            <div className="docFileTableHeadCard">
              {cardView ? (
                <div>
                  <input
                    onChange={() => this.handleCheckboxAll()}
                    checked={selectAll}
                    className=""
                    type="checkbox"
                    name=""
                    id=""
                  />
                </div>
              ) : (
                <div />
              )}

              <div className="docHeaderStatus">
                {fileSelected ? (
                  <div className="docEditIcons">
                    <div>
                      {numOfSelectedFiles}
                      {" "}
                      file selected
                      <img
                        className="docEditIconClose"
                        onClick={() => this.handleClearAll()}
                        src={CloseIcon}
                        alt=""
                      />
                    </div>

                    <img
                      onClick={(e) => this.handleDownloadFile(e, null, true)}
                      onMouseEnter={(e) => {
                        e.currentTarget.src = DownloadHoverIcon;
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.src = DownloadIcon;
                      }}
                      src={DownloadIcon}
                      alt=""
                    />
                    <img
                      onClick={(e) => this.handleConfirmDelete(e, null, true)}
                      onMouseEnter={(e) => {
                        e.currentTarget.src = TrashHoverIcon;
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.src = TrashIcon;
                      }}
                      src={TrashIcon}
                      alt=""
                    />
                  </div>
                ) : (
                  <>
                    <div className="docSearchInputIcon">
                      {showSearchIcon ? (
                        <img
                          onClick={() => this.setState({ showSearchIcon: !showSearchIcon })}
                          className="docSearchIcon"
                          src={SearchIcon}
                          onMouseEnter={(e) => {
                            e.currentTarget.src = SearchIconActive;
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.src = SearchIcon;
                          }}
                          alt=""
                        />
                      ) : (
                        <div className="docSearchBar">
                          <input
                            autoFocus
                            onChange={(e) => this.handleSearchChange(e.target.value)}
                            className="docSearchInput"
                            type="text"
                            placeholder="Search"
                            value={searchValue}
                            name=""
                            id=""
                          />
                          <img
                            className="docSearchIconActive"
                            src={SearchIconActive}
                            alt=""
                          />
                          <img
                            onClick={() => {
                              this.handleSearchChange("");
                              this.setState({ showSearchIcon: !showSearchIcon });
                            }}
                            className="docSearchIconClear"
                            src={DocSearchIconClose}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                    {/* <div className="docSortByFilter">
                      Sort By
                      <select className="sortByFilterOptions" name="" id="">
                        <option value="Name">Name</option>
                        <option value="Most Recent">Most Recent</option>
                        <option value="Oldest">Oldest</option>
                      </select>
                    </div> */}
                    <div
                      className="docViewButton"
                      onClick={() => this.handleChangeView()}
                    >
                      <div className="cardListViewBtns">
                        <img
                          className={
                            cardView ? "cardViewActive" : "cardViewInactive"
                          }
                          src={cardView ? CardViewIcon : CardViewIconInactive}
                          alt="card view icon"
                        />
                        <img
                          className={
                            !cardView ? "cardViewActive" : "cardViewInactive"
                          }
                          src={!cardView ? ListViewIcon : ListViewIconInactive}
                          alt="list view icon"
                        />
                        {/* <img src={ListViewIcon}/>  */}
                      </div>
                      {/* {cardView ? "List View" : "Card View"} */}
                    </div>
                  </>
                )}
              </div>
            </div>
            {cardView && (
              <DocumentsCardView
                files={files}
                handleCheckbox={this.handleCheckbox}
                handleFileOptionClick={this.handleFileOptionClick}
                // handleCheckbox={this.handleCheckbox}
                handleNameChange={this.handleNameChange}
                handleNameSubmit={this.handleNameSubmit}
                handleRenameFile={this.handleRenameFile}
                handleCancelRename={this.handleCancelRename}
                handleConfirmDelete={this.handleConfirmDelete}
                handleDownloadFile={this.handleDownloadFile}
              />
            )}
            {!cardView
                && (
                <DocumentsListView
                  files={files}
                  handleCheckbox={this.handleCheckbox}
                  handleCheckboxAll={this.handleCheckboxAll}
                  selectAll={selectAll}
                  handleFileOptionClick={this.handleFileOptionClick}
                  handleNameChange={this.handleNameChange}
                  handleNameSubmit={this.handleNameSubmit}
                  handleRenameFile={this.handleRenameFile}
                  handleConfirmDelete={this.handleConfirmDelete}
                  handleDownloadFile={this.handleDownloadFile}
                />
                )}
          </div>
          <input
            id="docUploadInput"
            ref={this.docUploadInput}
            type="file"
            multiple
            hidden
          />
          <button
            onClick={() => this.handleAddFile()}
            className="docAddFileButton"
            type="button"
          >
            <span>
              <img src={AddFileIcon} alt="add file icon" />
            </span>
            Add File
          </button>
          {confirmDelete && (
            <DocumentsConfirmDelete
              numOfSelectedFiles={numOfSelectedFiles}
              batch={batch}
              deleteItem={deleteItem}
              toggleConfirmDelete={this.toggleConfirmDelete}
              handleDeleteFile={this.handleDeleteFile}
            />
          )}
        </div>
      </>
    );
  }
}

import React from "react";

import { Modal } from "antd";

import StyledButton from "../../Common/UIComponents/StyledButton";

import { ReactComponent as ModalClose } from "../../../assets/images/OnboardingClose.svg";
import "./SampleShareModal.css";

export default function SampleShareModal({ onConfirm, onCancel, handleShareSubmissionInfo }) {
  return (
    <Modal
      open
      closable
      centered
      footer={null}
      onConfirm={onConfirm}
      onCancel={onCancel}
      width="452px"
      closeIcon={<ModalClose />}
      className="SampleShareAlert"
    >
      <div className="SampleShareTitle">Select external sharing for</div>
      <div className="SampleShareBtn">
        <StyledButton
          type="primary"
          onClick={() => handleShareSubmissionInfo("product")}
          className="SampleShareBtn_Prod"
        >
          Product
        </StyledButton>

      </div>
    </Modal>
  );
}

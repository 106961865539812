import React from "react";
import { Select } from "antd";
import { ReactComponent as ChevronDown } from "../../../assets/images/common/ChevronDown.svg";
import "./SortBySelect.css";

export default function SortBySelect({
  options,
  selectedOptionIdx,
  onSelect,
  disabled,
}) {
  const selectOptions = options.map((item) => ({ value: item, label: item }));
  return (
    <Select
      bordered={false}
      placeholder="Sort By"
      value={options[selectedOptionIdx]}
      options={selectOptions}
      onSelect={onSelect}
      suffixIcon={<ChevronDown className="SortBySelect__Chevron" />}
      className="SortBySelect"
      popupClassName="SortBySelect__Dropdown"
      disabled={disabled}
    />
  );
}

import React from "react";
import "./MaxCharacterCount.css";

export default function MaxCharacterCount({
  charCount,
  charLimit,
  customMaxCharClassName,
}) {
  return (
    <span className={`SampleSubmission__CharacterCountText${charCount > charLimit ? "--OverLimit" : ""} ${customMaxCharClassName}`}>
      {
      `${charCount}/${charLimit}`
      }
    </span>
  );
}

import React, { Component } from "react";
import PDFileICon from "../../../assets/images/PDF_FileIcon.png";
import ImageFileICon from "../../../assets/images/Image_FileIcon.png";
import XlSXFileIcon from "../../../assets/images/XlSX_FileIcon.png";
import closeIcon from "../../../assets/images/index/closeCircleBlue.png";
import "./DocumentsCard.css";
import DocumentsMenuOption from "./DocumentsMenuOption";

export default class DocumentsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHover: false,
      menuOpen: false,
    };
  }

  handleCardEnter = () => {
    this.setState({
      cardHover: true,
    });
  };

  handleCardLeave = () => {
    this.setState({
      cardHover: false,
    });
  };

  renderFileIcon = (extension) => {
    switch (extension) {
      case "pdf":
        return PDFileICon;
      case "xlsx":
        return XlSXFileIcon;
      default:
        return ImageFileICon;
    }
  }

  onOpenChange = (isOpen) => {
    this.setState({ menuOpen: isOpen, cardHover: isOpen });
  }

  render() {
    const { cardHover, menuOpen } = this.state;
    const {
      file,
      readOnly,
      index,
      handleFileOptionClick,
      handleCheckbox,
      handleNameChange,
      handleNameSubmit,
      handleCancelRename,
      handleRenameFile,
      handleConfirmDelete,
      handleDownloadFile,
    } = this.props;

    return (
      <div className="docCardFileAndName">
        <div
          onMouseEnter={menuOpen ? null : this.handleCardEnter}
          onMouseLeave={menuOpen ? null : this.handleCardLeave}
          className={
            file.checked ? "docFileCard docFileCardSelected" : "docFileCard"
          }
        >
          {cardHover && (
            <div className="docCardHoverInputs">
              <label className="DocCardHoverInputs__CheckboxContainer">
                <input
                  onClick={(e) => e.stopPropagation()}
                  type="checkbox"
                  onChange={(e) => handleCheckbox(e, file.key)}
                  checked={file.checked}
                  name={file.name}
                  id={index}
                />
                <span className="smallCheckmark" style={{ top: readOnly ? "0px" : "-5px" }} />
              </label>
              {!readOnly && (
              <div
                onClick={(e) => handleFileOptionClick(e, file)}
                className="docDotIconWrapper"
              >
                <DocumentsMenuOption
                  handleRenameFile={() => handleRenameFile(null, file)}
                  handleConfirmDelete={() => handleConfirmDelete(null, file, false)}
                  handleDownloadFile={() => handleDownloadFile(null, file, false)}
                  handleFileOptionClick={(e) => handleFileOptionClick(e)}
                  onOpenChange={this.onOpenChange}
                />
              </div>
              )}
            </div>
          )}

          <div className="docCardFileIconName">
            <img
              className="docCardFileIcon"
              src={this.renderFileIcon(file.extension)}
              alt=""
            />
          </div>
        </div>
        <div
          // onDoubleClick={(e) => handleRenameFile(e, file)}
          className="docCardFileName"
        >
          {file.renameDisable ? (
            file.name
          ) : (
            <div className="DocCardFileName--relative-position">
              <input
                disabled={file.renameDisable}
                className={
                  !file.renameDisable
                    ? "docFileNameInputCard docFileNameInputEdit"
                    : "docFileNameInputCard"
                }
                onChange={(e) => handleNameChange(e, file.key)}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => handleNameSubmit(e, file.key)}
                // onDoubleClick={(e) => handleRenameFile(e, file)}
                type="text"
                value={file.renameValue}
              />
              <img
                src={closeIcon}
                alt="closeIcon"
                className="DocFileNameInputCard__CloseIcon"
                onClick={(e) => handleCancelRename(e, file.key)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

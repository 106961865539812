import React, { Component } from "react";
import Scrollbar from "react-scrollbars-custom";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion/dist/framer-motion";
import CreateNewPin from "./CreateNewPin";
import PinDetailBlock from "./PinDetailBlock";

import tooltip from "../../../../../assets/images/environment/messageBlock.png";
import "./PinList.css";

export default class PinList extends Component {
  state = {
    displayTooltip: false,
    tooltipStyle: { display: "none" },
  };

  toggleTooltip = (position) => {
    if (position) {
      this.setState({
        displayTooltip: true,
        tooltipStyle: {
          display: "inline-block",
          left: position.x + 30,
          top: position.y,
        },
      });
    } else {
      this.setState({
        displayTooltip: false,
        tooltipStyle: { display: "none" },
      });
    }
  };

  render() {
    const { displayTooltip, tooltipStyle } = this.state;

    const {
      pinMap,
      quadrantSelected,
      selectedLocationID,
      indexOfQuadrantHoverOver,
      handleAddNewSwab,
      toggleAddingSwab,
      handleHoverQuadrant,
      handleSelectQuadrant,
      endPinningSwab,
      isAddingNewSwab,
      isSearching,
      setSelectedLocationID,
      updatePinInfo,
      setDisplayTooltipLocationID,
      transformWrapperRef,
      displayFields,
      index0,
      delimiter,
      linkPatternFields,
      sampleIDFields,
      isPinningNewSwab,
      togglePinningNewSwab,
      isPinningSwab,
      imageSrc,
      loading,
      clickQuadrant,
      isClickingQuadrant,
      searchResultSelected,
      toggleReportView,
      showReportView,
      forceReportViewRerender,
    } = this.props;

    return (
      <div className="PinList">
        {displayTooltip && (
          <div style={{ ...tooltipStyle }} className="PinList__ToolTip">
            <img
              alt="tooltip"
              src={tooltip}
              className="PinList__ToolTipImg"
            />
            <span
              className="PinList__ToolTipText"
            >
              Click here to pin swab on map
            </span>
          </div>
        )}
        <Scrollbar className="PinList__Scrollbar">
          <LayoutGroup>
            <AnimatePresence>
              {isAddingNewSwab && (
              <CreateNewPin
                handleAddNewSwab={handleAddNewSwab}
                handleHoverQuadrant={handleHoverQuadrant}
                handleSelectQuadrant={handleSelectQuadrant}
                toggleAddingSwab={toggleAddingSwab}
                setSelectedLocationID={setSelectedLocationID}
                updatePinInfo={updatePinInfo}
                transformWrapperRef={transformWrapperRef}
                index0={index0}
                delimiter={delimiter}
                linkPatternFields={linkPatternFields}
                sampleIDFields={sampleIDFields}
                togglePinningNewSwab={togglePinningNewSwab}
              />
              )}
            </AnimatePresence>
            {Array.from(pinMap.values()).map((pin) => (
              <motion.div layout key={pin.location_id}>
                <PinDetailBlock
                  imageSrc={imageSrc}
                  pin={pin}
                  index0={index0}
                  key={pin.location_id}
                  locationID={pin.location_id}
                  selectedLocationID={selectedLocationID}
                  indexOfQuadrantHoverOver={indexOfQuadrantHoverOver}
                  quadrantSelected={quadrantSelected}
                  isAddingNewSwab={isAddingNewSwab}
                  isSearching={isSearching}
                  isLocatingSwab={quadrantSelected !== -1}
                  setSelectedLocationID={setSelectedLocationID}
                  endPinningSwab={endPinningSwab}
                  updatePinInfo={updatePinInfo}
                  handleHoverQuadrant={handleHoverQuadrant}
                  handleSelectQuadrant={handleSelectQuadrant}
                  toggleTooltip={this.toggleTooltip}
                  setDisplayTooltipLocationID={setDisplayTooltipLocationID}
                  clickQuadrant={clickQuadrant}
                  isClickingQuadrant={isClickingQuadrant}
                  transformWrapperRef={transformWrapperRef}
                  displayFields={displayFields}
                  isPinningNewSwab={isPinningNewSwab}
                  isPinningSwab={isPinningSwab}
                  loading={loading}
                  searchResultSelected={searchResultSelected}
                  toggleReportView={toggleReportView}
                  showReportView={showReportView}
                  forceReportViewRerender={forceReportViewRerender}
                />
              </motion.div>
            ))}
          </LayoutGroup>
        </Scrollbar>
      </div>
    );
  }
}

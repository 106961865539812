import React, { useState, useEffect, useRef } from "react";
import Scrollbar from "react-scrollbars-custom";
import FieldInput from "./FieldInput";
import "./InfoInputSection.css";
import CustomFieldInput from "./CustomFieldInput";
import add from "../../../../assets/images/signup-invite/addFieldIcon.svg";
import DropdownFieldInput from "./DropdownFieldInput";

export default function InfoInputSection({
  type,
  setFixedFieldRef,
  setCustomFieldRef,
  deleteCustomFieldRef,
  errorsFromParent,
  setFieldError,
  getFieldError,
  isSendingInvite,
}) {
  const requiredFields = ["sample_type", "received_date"];
  // const [numCustomFields, setNumCustomFields] = useState(0);
  const [customFieldKeys, setCustomFieldKeys] = useState([]);
  const [addingField, setAddingField] = useState(false);
  const scrollbarRef = useRef();

  /**
   * Scroll to bottom of InfoInputSection when user add new fields, i.e. numCustomFields increases and is also larger than 0
   */
  useEffect(() => {
    if (addingField) {
      scrollbarRef.current.scrollerElement.scrollTo({ top: scrollbarRef.current.scrollerElement.scrollHeight, behavior: "smooth" });
      setAddingField(false);
    }
  }, [addingField]);

  /**
   * Add custom field
   */
  const addCustomField = () => {
    setAddingField(true);
    if (customFieldKeys.length) {
      customFieldKeys.push(parseInt(customFieldKeys.slice(-1)) + 1);
    } else {
      customFieldKeys.push(0);
    }
    setCustomFieldKeys([...customFieldKeys]);
  };

  /**
   * Decrement numCustomFields and remove ref from SignUpInvite's infoInputRefs
   * @param {Number} idx
   */
  const removeCustomField = (idx) => {
    customFieldKeys.splice(idx - requiredFields.length, 1);
    setCustomFieldKeys([...customFieldKeys]);
    deleteCustomFieldRef(idx, type);
  };

  /** Get error value field */
  const getFixedFieldError = (field) => getFieldError(field, type);

  return (
    <div className={`info-input-section-container ${type === "env" ? "info-input-section-container-env" : "info-input-section-container-prod"}`}>
      <div className="signup-invite-title">
        {`${type === "env" ? "Environment" : "Product"} Info`}
      </div>
      <Scrollbar
        ref={scrollbarRef}
      >
        <div className="info-input-section-inputs">
          {type === "env" ? (
            <FieldInput
              type={type}
              label="Spec Link Pattern"
              field="location_link"
              setRef={setFixedFieldRef}
              isSendingInvite={isSendingInvite}
              errorsFromParent={errorsFromParent}
              setFieldError={setFieldError}
              getFieldError={getFixedFieldError}
            />
          ) : (
            <DropdownFieldInput
              type={type}
              label="Spec Link"
              field="product_link"
              setRef={setFixedFieldRef}
              isSendingInvite={isSendingInvite}
              errorsFromParent={errorsFromParent}
              setFieldError={setFieldError}
              getFieldError={getFixedFieldError}
              disableCheckboxes
              options={["item", "name"]}
            />
          )}
          {type === "env" && (
            <FieldInput
              type={type}
              label="Swab Title Field"
              field="title"
              setRef={setFixedFieldRef}
              isSendingInvite={isSendingInvite}
              errorsFromParent={errorsFromParent}
              setFieldError={setFieldError}
              getFieldError={getFixedFieldError}
            />
          )}
          <FieldInput
            type={type}
            label="Delimiter"
            field="delimeter"
            setRef={setFixedFieldRef}
            isSendingInvite={isSendingInvite}
            errorsFromParent={errorsFromParent}
            setFieldError={setFieldError}
            getFieldError={getFixedFieldError}
          />
          <FieldInput
            type={type}
            label="Sample ID Format"
            field="sample_id_format"
            setRef={setFixedFieldRef}
            isSendingInvite={isSendingInvite}
            errorsFromParent={errorsFromParent}
            setFieldError={setFieldError}
            getFieldError={getFixedFieldError}
          />
          <FieldInput
            type={type}
            label="Default Sample ID"
            field="default_sample_id"
            setRef={setFixedFieldRef}
            isSendingInvite={isSendingInvite}
            errorsFromParent={errorsFromParent}
            setFieldError={setFieldError}
            getFieldError={getFixedFieldError}
          />
          <FieldInput
            type={type}
            label="Default Sample Type"
            field="default_sample_type"
            setRef={setFixedFieldRef}
            isSendingInvite={isSendingInvite}
            errorsFromParent={errorsFromParent}
            setFieldError={setFieldError}
            getFieldError={getFixedFieldError}
          />
          {requiredFields.map((json_field, idx) => (
            <CustomFieldInput
              key={idx}
              idx={idx}
              type={type}
              fieldVal={json_field}
              setCustomFieldRef={setCustomFieldRef}
              removeCustomField={removeCustomField}
              isSendingInvite={isSendingInvite}
              errorsFromParent={errorsFromParent}
              setFieldError={setFieldError}
              getFieldError={getFieldError}
            />
          )) }
          {customFieldKeys.map((key, idx) => (
            <CustomFieldInput
              key={key}
              idx={idx + requiredFields.length}
              type={type}
              setCustomFieldRef={setCustomFieldRef}
              removeCustomField={removeCustomField}
              isSendingInvite={isSendingInvite}
              errorsFromParent={errorsFromParent}
              setFieldError={setFieldError}
              getFieldError={getFieldError}
            />
          ))}
          <button
            className={`signup-invite-add-field-button ${isSendingInvite ? "InfoInputSection__AddFieldBtn--disabled" : ""}`}
            type="button"
            disabled={isSendingInvite}
            onClick={addCustomField}
          >
            <img src={add} alt="add field" />
            Add Field
          </button>
        </div>
      </Scrollbar>
    </div>
  );
}

import React, { useContext } from "react";
import Highlighter from "react-highlight-words";
import { Checkbox, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import "./ReportsTableFieldCell.css";
import { ReportsTableRowContext } from "./ReportsTableRow";

export default function ReportsTableFieldCell({
  rowData,
  jsonField,
  searchValToHighlight,
  showCheckbox,
}) {
  const { onRowSelect, checkboxLoading } = useContext(ReportsTableRowContext);
  /**
   * Get value for the cell.
   * @param {Object} item row data
   * @param {String} jsonField
   * @returns {String}
   */
  const getJsonFieldDataForDisplay = (val) => {
    if (jsonField === "received_date") {
      return moment(new Date(val)).format("MM/DD/YYYY");
    }

    if (val) {
      return val;
    }

    return "";
  };

  const isEmpty = rowData.getValue() === undefined || rowData.getValue().trim() === "";
  const enableRowCheckBox = showCheckbox && rowData.row.original.specs_flag !== "0"; // Allow row selection if sample is not "in-process"
  return (
    <div className={`ReportsTable__FieldCellContent${showCheckbox ? "--show-checkbox" : ""}`}>
      {showCheckbox && (
        <>
          {checkboxLoading && (<Spin indicator={<LoadingOutlined className="ReportsTable__Checkbox__Spin" spin />} />)}
          {!checkboxLoading && (
          <Checkbox
            className={`ReportsTable__Checkbox${enableRowCheckBox ? "--enabled" : "--disabled"}`}
            onChange={enableRowCheckBox ? onRowSelect : null}
            onClick={(e) => { e.stopPropagation(); }}
            checked={rowData.row.getIsSelected()}
          />
          )}
        </>
      )}
      {isEmpty ? (
        <div className="ReportsTable__EmptyCell__Content">
          <div className="ReportsTable__EmptyCell__Values">
            {" "}
          </div>
        </div>
      ) : (
        <Highlighter
          highlightClassName="searchHighlight"
          searchWords={[searchValToHighlight]}
          autoEscape
          textToHighlight={getJsonFieldDataForDisplay(rowData.getValue())}
        />
      )}
    </div>
  );
}

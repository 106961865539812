import React, { Component } from "react";
import "./StatusPage.css";
import { Result, Button } from "antd";
import esv_logo from "../../assets/images/logo/esv_logo_2x.png";

export default class StatusPage extends Component {
  onGotoLogin = () => {
    const { history } = this.props;
    history.push("/login");
  };

  onGoToSalesInvite = () => {
    const { history } = this.props;
    history.push("/invite-customer");
  }

  showExtraButton = () => {
    const { showInviteButton } = this.props;

    let onClickFn = this.onGotoLogin;
    let btnText = "Login";
    if (showInviteButton) {
      onClickFn = this.onGoToSalesInvite;
      btnText = "Invite Another Customer";
    }
    return (
      <Button
        type="primary"
        key="console"
        onClick={onClickFn}
        className="go-to-login-btn"
      >
        {btnText}
      </Button>
    );
  }

  render() {
    const {
      status,
      title,
      subtitle,
      showLoginButton,
      showInviteButton,
      className = "",
    } = this.props;

    const showExtra = showLoginButton || showInviteButton;
    return (
      <div className="auth-wrapper login-page">
        <div className="logoImgWrapper">
          <img
            src={esv_logo}
            alt="logo"
            className="logoImg"
          />
        </div>
        <div
          className="auth-inner signup-success"
        >
          <Result
            status={status}
            title={title}
            subTitle={subtitle}
            extra={showExtra ? [
              this.showExtraButton(),
            ] : []}
            className={`StatusPage__Result ${className}`}
          />
        </div>
      </div>
    );
  }
}

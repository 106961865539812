import React, { useContext } from "react";
import { Droppable } from "react-beautiful-dnd";
import BuilderDragDropContext from "./BuilderDragDropContext";

export default function TemplateBuilderDroppable({
  className,
  droppableId,
  direction = "vertical",
  // disabled = false,
  showPlaceholder = true,
  getDroppableStyle,
  children,
}) {
  const { disabledDroppablesSet } = useContext(BuilderDragDropContext);
  const disabled = (droppableId.includes("dynamic") && disabledDroppablesSet.has("dynamic")) || disabledDroppablesSet.has(droppableId);
  return (
    <Droppable
      droppableId={droppableId}
      isDropDisabled={disabled}
      direction={direction}
    >
      {(provided, snapshot) => (
        <div
          className={className}
          {...provided.droppableProps} //eslint-disable-line
          ref={provided.innerRef}
          style={getDroppableStyle ? { ...getDroppableStyle(snapshot) } : { background: snapshot.isDraggingOver ? "#dff5fd" : "unset" }}
        >
          {children}
          {showPlaceholder ? provided.placeholder : null}
        </div>
      )}
    </Droppable>
  );
}

import { useEffect } from "react";

/**
 * Custom hook to handle click on step. Save current form values.
 * @param {String} FORM_NAME current form name
 * @param {import("antd").FormInstance} form ant design form instance
 * @param {Object} stepChangeInProgress { diff: Number }
 * @param {Function} handleFormStepChange updates state with current form data, changes step
 * @param {Function} formatValues function to format values before saving
 */
const useHandleStepChange = (FORM_NAME, form, stepChangeInProgress, handleFormStepChange, formatValues) => {
  useEffect(() => {
    if (stepChangeInProgress && form) {
      const { diff } = stepChangeInProgress;
      const formData = form.getFieldValue();
      handleFormStepChange(FORM_NAME, formatValues ? formatValues(formData) : formData, diff);
    }
  }, [stepChangeInProgress, form]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useHandleStepChange;

import React from "react";
import { range } from "lodash";
import "./MonthYearSelect.css";
import { Select } from "antd";
import { ReactComponent as CalendarDownArrow } from "../../../assets/images/product/CalendarDownArrow.svg";
import { MONTHS } from "./utils";

export default function MonthYearSelect({
  currentSelectedMonth,
  setCurrentSelectedMonth,
  currentSelectedYear,
  setCurrentSelectedYear,
  startYear,
  endYear,
  flatpickrRef,
}) {
  /**
 * Update the flatpickr instance when month is selected.
 * @param {Number} value zero-indexed month number
 */
  const handleMonthSelect = (value) => {
    // const monthsArr = [
    //   "January", "February", "March", "April", "May", "June",
    //   "July", "August", "September", "October", "November", "December",
    // ];
    // console.log("handleMonthChange", monthsArr[value]);
    const instance = flatpickrRef.current.flatpickr;
    setCurrentSelectedMonth(value);
    instance.changeMonth(value, false);
  };

  /**
 * Update the flatpickr instance when year is selected.
 * @param {Number} value year (YYYY format)
 */
  const handleYearSelect = (value) => {
    // console.log("handleYearChange", value);
    const instance = flatpickrRef.current.flatpickr;
    setCurrentSelectedYear(value);
    instance.changeYear(value);
  };

  const years = range(endYear, startYear - 1, -1); // get list of years from endYear (most recent) to startYear (least recent) inclusive in desc order

  return (
    <div className="MonthYearSelect">
      <Select
        className="MonthYearSelect__MonthSelect"
        popupClassName="MonthYearSelectDropdown"
        value={currentSelectedMonth}
        options={MONTHS}
        onSelect={handleMonthSelect}
        listItemHeight={32}
        listHeight={190}
        suffixIcon={<CalendarDownArrow />}
      />
      <Select
        className="MonthYearSelect__YearSelect"
        popupClassName="MonthYearSelectDropdown"
        value={currentSelectedYear}
        options={years.map((year) => ({ value: year, label: year }))}
        onSelect={handleYearSelect}
        listItemHeight={32}
        listHeight={190}
        suffixIcon={<CalendarDownArrow />}
      />
    </div>
  );
}

import React from "react";

import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import Scrollbar from "react-scrollbars-custom";

import CheckboxInput from "./CheckboxInput";
import DetailedPesticideReport from "./DetailedPesticideReport";

import "./SampleTestResultTable.css";

export default class SampleTestResultTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasSpecs: true,
      testCheckboxVals: [],
    };
  }

  componentDidMount() {
    const { tableData, isPickingRetests } = this.props;
    /** Parse the tableData and check if at least one test has specs. Initialize checkboxes to false. */
    const testCheckboxVals = [];
    let hasSpecs = false;
    if (tableData.length > 0) {
      for (let i = 0; i < tableData.length; i++) {
        testCheckboxVals.push(false);
        if (tableData[i].specs !== "-") {
          hasSpecs = true;
          if (!isPickingRetests) {
            break;
          }
        }
      }
    }

    this.setState({
      hasSpecs,
      testCheckboxVals,
    });
  }

  /**
   * Set all checkboxes to false,
   * clears selection for next time
   * user is picking tests
   */
  clearCheckboxes = () => {
    const testCheckboxVals = [];
    const { tableData } = this.props;
    if (tableData.length > 0) {
      for (let i = 0; i < tableData.length; i++) {
        testCheckboxVals.push(false);
      }
    }
    this.setState({
      testCheckboxVals,
    });
  }

  /**
   * Set test as checked in state,
   * add test to list of selected tests.
   * @param {Number} idx index of test in tableData
   */
  handleCheckClicked = (idx) => {
    const { tableData, checkBoxOnChange } = this.props;
    const { testCheckboxVals } = this.state;
    const checked = !testCheckboxVals[idx];
    /** add or remove test from test set */
    const item = tableData[idx];
    checkBoxOnChange(item.test, checked);
    /** set checked state */
    testCheckboxVals[idx] = checked;
    this.setState({ testCheckboxVals: [...testCheckboxVals] });
  };

  /**
   * Handle detailed pesticide view toggle
   * @param {boolean} isOpen
   */
  handleDetailToggle = (isOpen, test) => {
    const { pesticideDetailsToggle } = this.props;
    pesticideDetailsToggle(isOpen, test);
  }

  /**
   * Handle detailed pesticide report close button event
   * @param {boolean} hasCountryListChanged checks if country list in detailed report has changed
   */
  handlePesticideDetailClose = (hasCountryListChanged) => {
    const { reportDataRefresh } = this.props;
    if (hasCountryListChanged) {
      reportDataRefresh();
    }
    this.handleDetailToggle(false, null);
  };

  renderSpecColumn(type, data, style) {
    const { hasSpecs } = this.state;
    if (type === "header" && hasSpecs) {
      return (
        <th style={style} className="TruncateText" title={data}>
          {data}
        </th>
      );
    } if (type === "row" && hasSpecs) {
      const value = data.specs ? data.specs : "-";
      return (
        <td title={value} className="TruncateText">{value}</td>
      );
    }

    return null;
  }

  render() {
    const {
      tableData, isPickingRetests, disabledTests, reportData,
      showDetailedPesticideReport, pesticideDetailTest, activeDate, testDate,
    } = this.props;
    const {
      testCheckboxVals,
    } = this.state;
    const headerStyle = {
      backgroundColor: "#26A1D2",
      color: "white",
    };

    return (
      <div>
        <div className="SampleTestResultTable__TestInfo">
          <div className="SampleTestResultTable__PesticideDetailTitle">
            {showDetailedPesticideReport && "Detailed pesticide report"}
          </div>
          {!showDetailedPesticideReport && (
            <span className="normalWeight SampleTestResultTable__TotalTestContainer">
              <span className="SampleTestResultTable__TotalTestTitle">
                Total test
              </span>
              <span className="SampleTestResultTable__TotalTestValue">
                {tableData.length}
              </span>
            </span>
          )}
        </div>
        <div className="SampleTestResultTable__ScrollContainer">
          <Scrollbar
            disableTrackYWidthCompensation
            disableTracksWidthCompensation
          >
            <AnimatePresence mode="wait">
              {showDetailedPesticideReport ? (
                <motion.div
                  key="DetailedPesticideReport"
                  initial={{ x: "100%" }}
                  animate={{ x: 0 }}
                  exit={{ x: "100%" }}
                  transition={{ duration: 0.1 }}
                >
                  {testDate === activeDate && (
                    <DetailedPesticideReport
                      reportData={reportData}
                      onClose={this.handlePesticideDetailClose}
                      activeDate={activeDate}
                      pesticideDetailTest={pesticideDetailTest}
                    />
                  )}
                </motion.div>
              ) : (
                <table className="SampleTestResultTable">
                  <thead className="sampleTestResultTableHeader">
                    <tr>
                      <th style={headerStyle}>Test</th>
                      {this.renderSpecColumn("header", "Specs", headerStyle)}
                      <th style={headerStyle}>Results</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.length > 0 && tableData.map((item, i) => {
                      const enableTestSelection = isPickingRetests && !disabledTests.has(item.test);
                      const resultColor = item.test_spec_flag === "-" ? item.color.color : item.color;

                      return (
                        <tr
                          key={i}
                          onClick={
                            enableTestSelection
                              ? () => this.handleCheckClicked(i)
                              : null
                          }
                          className={enableTestSelection ? "tempClass" : ""}
                          style={{
                            cursor: enableTestSelection
                              ? "pointer"
                              : "default",
                          }}
                        >
                          <td>
                            <div className="sampleTestResultTableTestColumn">
                              {isPickingRetests && (
                                <div className="sampleTestResultTableCheckBoxContainer">
                                  <CheckboxInput
                                    disabled={!enableTestSelection}
                                    index={i}
                                    checked={testCheckboxVals[i]}
                                    handleCheckClicked={
                                      this.handleCheckClicked
                                    }
                                  />
                                </div>
                              )}
                              <div
                                className="sampleTestResultTableTestText TruncateText"
                                title={item.test}
                              >
                                {item.test}
                              </div>
                            </div>
                          </td>
                          {this.renderSpecColumn("row", item)}
                          <td style={{ color: resultColor }}>
                            <div
                              className="sampleTestResultTableTestColumn"
                              title={item.result ? item.result : "-"}
                            >
                              {item.result ? (
                                <>
                                  {item.detail ? (
                                    <span
                                      className={`${!isPickingRetests ? "SampleTestResultTable__PesticideResult" : ""} TruncateText`}
                                      onClick={
                                        !isPickingRetests
                                          ? () => {
                                            this.handleDetailToggle(true, item.test);
                                          }
                                          : undefined
                                      }
                                    >
                                      {item.result}
                                    </span>
                                  ) : (
                                    item.result
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </AnimatePresence>
          </Scrollbar>
        </div>
      </div>
    );
  }
}

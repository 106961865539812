import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
// import AddFileIcon from "../../../../../assets/images/product/plusMidnight.png";
import AddFileIcon from "../../../../../../../assets/images/product/template/PlusBlue.png";
import Remove from "../../../../../../../assets/images/product/template/Remove.png";
import RemoveDark from "../../../../../../../assets/images/product/template/RemoveDark.png";
import { s3EsvPriv } from "../../../../../../../utils/aws";
import { getFileFromS3 } from "../../../../../../../utils/helpers";
import "./UploadImage.css";

export default function UploadImage({
  handleBuilderFile, title, id, imagePath,
}) {
  const [image, setImage] = useState(null);
  const [hover, setHover] = useState(null);
  const companyLogoInput = useRef();

  /**
   * if imagePath exists, load from aws and handleBuilderFile (needed for edit template)
   */
  useEffect(() => {
    const loadImage = async () => {
      if (imagePath) {
        const name = imagePath.split("/").pop();
        const blob = await getFileFromS3(imagePath, s3EsvPriv);
        const previewURL = URL.createObjectURL(blob);
        setImage(previewURL);
        const file = new File([blob], name);
        handleBuilderFile({
          file, name, title, wasEdited: false,
        });
      }
    };

    loadImage();
  }, [imagePath]); // eslint-disable-line

  const handleUploadedFiles = (e) => {
    const filesUploaded = Array.from(e.target.files);
    const file = filesUploaded[0];
    const supportedExtensions = ["jpg", "jpeg", "png"];
    const extension = file.type.split("/").pop();
    let fileName = file.name;
    if (supportedExtensions.includes(extension)) {
      let dotIndex;
      for (let i = fileName.length - 1; i >= 0; i--) {
        if (fileName[i] === ".") {
          dotIndex = i;
          break;
        }
      }
      fileName = fileName.substring(0, dotIndex);
      const previewURL = URL.createObjectURL(file);
      setImage(previewURL);
      const fileObj = {
        file, name: fileName, title, wasEdited: true,
      };
      handleBuilderFile(fileObj);
    } else {
      toast.error(`File type not supported: ${extension}`);
    }
  };

  const handleAddFile = () => {
    companyLogoInput.current.click();
    companyLogoInput.current.addEventListener("change", handleUploadedFiles);
  };

  const removeImage = (e) => {
    e.stopPropagation();
    const fileObj = {
      file: null, name: "", title, wasEdited: true,
    };
    setImage(null);
    handleBuilderFile(fileObj);
  };

  return (
    <div>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={`TemplateBuilder__LogoContainer${image ? "" : "--noImage"}`}
        onClick={!image ? handleAddFile : null}
      >
        {image && (
          <div>
            <img
              className="TemplateBuilder__ImageContainer"
              src={image}
              alt="uploaded file"
            />
            <img className="TemplateBuilder__ImageContainer__RemoveIcon" src={hover ? RemoveDark : Remove} alt="remove icon" onClick={(e) => removeImage(e)} />
          </div>
        )}
        {!image
        && (
        <>
          <input id={id} ref={companyLogoInput} type="file" hidden />
          <img
            className="TemplateBuilder__LogoImage"
            src={AddFileIcon}
            alt="add file icon"
          />
          <span className="TemplateBuilder__LogoText">{title}</span>
        </>
        )}
      </div>
    </div>
  );
}

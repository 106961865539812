// Helper functions related to pin info
export const checkPinIsPinned = (pin) => ((pin.title !== undefined) && (pin.title !== "")) && (pin.x_coordinate !== null && pin.y_coordinate !== null);
export const calculateQuadrantGivenPin = (pin) => {
  let quadrant = -1;
  if (pin.x_coordinate <= 0.5) {
    if (pin.y_coordinate <= 0.5) {
      quadrant = 0;
    } else {
      quadrant = 3;
    }
  } else if (pin.y_coordinate <= 0.5) {
    quadrant = 1;
  } else {
    quadrant = 2;
  }
  return quadrant;
};
export const calculateQuadrantGivenXYCoord = ({ x_coordinate, y_coordinate }) => {
  let quadrant = -1;
  if (x_coordinate <= 0.5) {
    if (y_coordinate <= 0.5) {
      quadrant = 0;
    } else {
      quadrant = 3;
    }
  } else if (y_coordinate <= 0.5) {
    quadrant = 1;
  } else {
    quadrant = 2;
  }
  return quadrant;
};

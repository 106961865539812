import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Form,
  Input,
} from "antd";
import "../CompanyContactForm.css";
import "./BillingAddressSection.css";
// import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

import AddressSection from "./AddressSection";

import { ReactComponent as CloseIcon } from "../../../../../../assets/images/onboarding/clearfield_2.svg";

export default function BillingAddressSection({
  initialValues,
  emailValidator,
  phoneValidator,
  addressData,
}) {
  const form = useFormInstance();
  const [sameAsAddress, setSameAsAddress] = useState(initialValues?.same_as_address);
  // const addressValues = useWatch(["address"]);
  const [addressValues] = useState(addressData);

  /**
  * On address values change, update billing address if it's the same as address
  */
  useEffect(() => {
    if (form && sameAsAddress) {
      form.setFieldValue(["billing", "address"], addressValues);
      if (addressValues) {
        Object.keys(addressValues).forEach((key) => {
          if (addressValues[key] !== undefined) {
            form.validateFields([["billing", "address", key]]);
          }
        });
      }
    }
  }, [form, addressValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckboxOnChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      form.setFieldValue(["billing", "address"], addressValues); // autofill address values
      form.validateFields([["billing", "address"]], { recursive: true, dirty: true });
    } else {
      form.setFieldValue(["billing", "address"], { // clear autofill
        street: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      });
    }
    setSameAsAddress(checked);
  };

  return (
    <div>
      <Form.Item>
        <div className="OnboardingFormSubTitle">
          <span />
          <p className="OnboardingFormSubTitleText">Setup your billing information</p>
        </div>

        <Form.Item
          name={["billing", "bill_to"]}
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Bill To</span>}
          rules={[
            {
              required: true, message: "",
            },
          ]}
          className="CompanyContactForm__ContactInfoSection_Fields"
        >
          <Input placeholder="Enter Bill to" allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }} />
        </Form.Item>
        <Form.Item
          name={["billing", "ap_contact"]}
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">A/P Contact</span>}
          rules={[
            {
              required: true, message: "",
            },
          ]}
          className="CompanyContactForm__ContactInfoSection_Fields"
        >
          <Input placeholder="Enter AP contact" allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }} />
        </Form.Item>
        <Form.Item
          name={["billing", "email"]}
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Email Address</span>}
          rules={[
            {
              required: true, message: "",
            },
            {
              validator: emailValidator,
            },
            { max: 500, message: "Email cannot be longer than 500 characters" },
          ]}
          className="CompanyContactForm__ContactInfoSection_Fields"
        >
          <Input type="email" placeholder="Enter email" allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }} />
        </Form.Item>
        <Form.Item
          name={["billing", "phone_number"]}
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Phone Number</span>}
          rules={[
            {
              validator: phoneValidator,
            },
            { max: 100, message: "Phone number cannot be longer than 100 characters" },
          ]}
          className="CompanyContactForm__ContactInfoSection_Fields"
        >
          <Input type="tel" placeholder="Enter Phone Number" allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }} />
        </Form.Item>
      </Form.Item>

      <div className="OnboardingFormSubTitle">
        <span />
        <p className="OnboardingFormSubTitleText">Billing Information</p>
      </div>

      {/* <p className="BillingAddressText"> Is your billing address same as address</p> */}

      <Form.Item name="same_as_address" valuePropName="checked">
        <Checkbox onChange={handleCheckboxOnChange} className="BillingAddressSection__Checkbox">Billing address same as company address</Checkbox>
      </Form.Item>
      <AddressSection namePath={["billing", "address"]} disabled={sameAsAddress} />
    </div>
  );
}

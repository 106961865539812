import React from "react";
import "./COAPlaceholderSection.css";

export default function COAPlaceholderSection() {
  return (
    <div className="TemplateBuilder__COAPlaceholder">
      <span className="TemplateBuilder__COAPlaceholder__Title">Certificate of Analysis</span>
      <span className="TemplateBuilder__COAPlaceholder__Subtitle">This space will be filled by test reports and data fields when creating the customized report.</span>
    </div>
  );
}

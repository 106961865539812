import moment from "moment";

import productGraphPillBlue from "../../../assets/images/analytics/graphPillProduct_blue.png";
import productGraphPillGreen from "../../../assets/images/analytics/graphPillProduct_green.png";
import productGraphPillOrange from "../../../assets/images/analytics/graphPillProduct_orange.png";
import productGraphPillPurple from "../../../assets/images/analytics/graphPillProduct_purple.png";
import testGraphPillBlue from "../../../assets/images/analytics/graphPillTest_blue.png";
import testGraphPillGreen from "../../../assets/images/analytics/graphPillTest_green.png";
import testGraphPillOrange from "../../../assets/images/analytics/graphPillTest_orange.png";
import testGraphPillPurple from "../../../assets/images/analytics/graphPillTest_purple.png";

// Colors used to highlight scatter plots and color for the tooltip text
export const colorFromSpecFlag = ["#19305A", "#00BF71", "#E63559"];

// Colors and icons array used to color the graphs with their respective colors
export const colorsAndIconsArray = [
  {
    productIcon: productGraphPillPurple,
    testIcon: testGraphPillPurple,
    backgroundColor: "#F4EAFF",
    accentColor: "#A766EC",
    hoverColor: "#ECDBFF",
  },
  {
    productIcon: productGraphPillOrange,
    testIcon: testGraphPillOrange,
    backgroundColor: "#FEF7EC",
    accentColor: "#FF9F3F",
    hoverColor: "#FFEED2",
  },
  {
    productIcon: productGraphPillBlue,
    testIcon: testGraphPillBlue,
    backgroundColor: "#EDF2FF",
    accentColor: "#215BE3",
    hoverColor: "#E2EAFF",
  },
  {
    productIcon: productGraphPillGreen,
    testIcon: testGraphPillGreen,
    backgroundColor: "#EFFDFF",
    accentColor: "#7AC5CF",
    hoverColor: "#D9F7FC",
  },
];

/**
 * Concatenate linked field values separated by a dot
 * @param {Object | Array} values ex {item: "", best_by: ""} or list of values
 * @param {Array | undefined} linkedFields if values is an object of fields, this is an array of linked fields ["item", "best_by"] else undefined
 * @returns {String} identifier
 */
export const getProductIdentifier = (values, linkedFields) => {
  let _values;
  if (values.constructor === Array) { // if array
    _values = values;
  } else if (typeof values === "object" && linkedFields) { // if object and linkedFields is not undefined
    _values = linkedFields.map((json_field) => values[json_field]);
  }
  return _values.join(" \u2022 "); // concatenate fields separated by a dot
};

/**
 * Get linked field values as array from product identifier string
 * @param {String} identifier string of values separated by a dot
 * @returns {Object} array of values (IMPORTANT: empty strings are removed, so length may not be the same as linkReportFields)
 */
export const parseProductIdentifierIntoArrForDisplay = (identifier) => identifier.split(" \u2022 ").filter((val) => Boolean(val));

/**
 * Get linked field values as object from product identifier string
 * @param {String} identifier string of values separated by a dot
 * @param {Array} linkedFields array of linked fields ["item", "best_by"]
 * @returns {Object} object of linked fields and values ex {item: "", best_by: ""}
 */
export const parseProductIdentifierIntoObj = (identifier, linkedFields) => {
  const parsedFields = identifier.split(" \u2022 ");
  const productObj = {};
  linkedFields.forEach((json_field, i) => {
    productObj[json_field] = parsedFields[i];
  });
  return productObj;
};

// Given an array of multiple values for a date
// returns them in format { "test_name|product_name": [ {date, y, specFlag}, ...] }
// Used to render a scatter plot
export const getFormattedPointsForScatter = (data) => {
  const dynamicTooltipField = "time";
  const finalObj = {};
  if (data !== undefined) {
    // Loop through the data
    data.forEach((item) => {
      // Loop through all of the unique tests
      item.result.forEach((test) => {
        // For that particular test loop through all of the reports
        test.allReports.forEach((value) => {
          const result = value.test_result_number;
          const rawResultNumber = value.test_result;

          // Create an object containing date(x), y, and the spec flag
          const obj = {
            date: moment(value.received_date).format("MM/DD/YYYY"),
            y: parseFloat(result.toString().replace(",", "").replace("%", "").replace(" ", "")),
            rawResult: rawResultNumber,
            specsFlag: value.specs_flag === "-" ? null : value.specs_flag,
            id: value[dynamicTooltipField],
          };

          // Create a unique identifier of format test_name|product_name
          // If the object at this unique identifier exists --- push this new object into the array
          // Else, create a new array with this object
          const unique = `${value.test_name}|${test.name}`;
          if (finalObj[unique] !== undefined) {
            finalObj[unique].push(obj);
          } else {
            finalObj[unique] = [obj];
          }
        });
      });
    });

    return finalObj;
  }

  return null;
};

// Given all of the test data, formats the data so the graph library can process it
// Three data structures are created: valuesArr, specArr, scatterObj, singleDataPointObj
// valuesArr [{ date, result: [{ test_name: result, allReports: [] }, ...]}] --- used to render the lines and bars
// specArr [{ max, min, name, spec: { max, min, test, unit} }] --- used to determine the bounds of the reference areas/lines
// scatterObj is an object created by getFormattedPointsForScatter() --- used to plot the scatter graph
// singleDataPointObj has a running count of how many data point we have for a given test/product
export const formatData = (data, fixedColumn, date) => {
  const valuesArr = [];
  const specArr = [];

  const valuesObj = {};
  const specsObj = {};
  const singleDataPointObj = {};

  // Loop through the entire dataset
  for (let i = 0; i < data.length; i++) {
    const reportList = data[i]?.report_list;

    // If we have a report list, loop through that
    if (reportList !== undefined) {
      const reportListKeys = Object.keys(reportList);

      for (let j = 0; j < reportListKeys.length; j++) {
        // console.log(reportList[reportListKeys[j]]);
        const report = reportList[reportListKeys[j]];
        const received_date = moment(reportListKeys[j]).format("MM/DD/YYYY");

        // Sort the reports by test_result value, in descending order
        report.reports.sort((a, b) => b.test_result.replace("<", "") - a.test_result.replace("<", ""));

        // Check if our valuesObj has the received date as the key
        // If not add a new object as the value
        // If it does, append a new object as the value for the given date
        // Unique dates should be added to this object
        const reportAverage = typeof report.average === "string" ? 0 : report.average;
        const productIdentifier = report.reports[0].index_id; // use product id as identifier

        if (valuesObj[received_date] === undefined) {
          valuesObj[received_date] = {
            date: received_date,
            result: [
              {
                [report.reports[0].test_name]: reportAverage,
                [`${report.reports[0].test_name}|raw`]: report.average,
                name: productIdentifier,
                allReports: report.reports,
              },
            ],
          };
        } else {
          valuesObj[received_date] = {
            ...valuesObj[received_date],
          };

          const obj = {
            [report.reports[0].test_name]: reportAverage,
            [`${report.reports[0].test_name}|raw`]: report.average,
            name: productIdentifier,
            allReports: report.reports,
          };
          valuesObj[received_date].result.push(obj);
        }

        // Create our specsObj with the uniqueIdentifier --- "product_name|test_name" as the key
        // Append the corresponding spec information as the value
        // Only unique product test combinations should be added to this object
        const unique = `${productIdentifier}|${report.reports[0].test_name}`;
        specsObj[unique] = {
          spec: data[i].spec,
          max: data[i].max_value,
          min: data[i].min_value,
          name: productIdentifier,
        };

        // Create our object to store product test combinations that will only have 1 data point
        // Key is unique identifier as declared above
        if (singleDataPointObj[unique] !== undefined) {
          // delete singleDataPointObj[unique];
          singleDataPointObj[unique] += 1;
        } else {
          singleDataPointObj[unique] = 1;
        }
      }
    }
  }

  // Make sure all selected 7 days are present in our object
  // Also store a counter to see how many days we had to add, if we added all 7 we can return undefined
  // let startDate = moment(date[0]).format("MM/DD/YYYY");
  // let addedDates = 0;
  // // Loop to check 7 days
  // for (let i = 0; i <= 7; i++) {
  //   // console.log(startDate);
  //   if (valuesObj[startDate] === undefined) {
  //     valuesObj[startDate] = {
  //       date: startDate,
  //       result: [],
  //     };
  //     addedDates++;
  //   }

  //   startDate = moment(startDate).add(1, "days").format("MM/DD/YYYY");
  // }

  // Convert our values object we created above into an array
  let objectKeys = Object.keys(valuesObj);
  for (let j = 0; j < objectKeys.length; j++) {
    valuesArr.push(valuesObj[objectKeys[j]]);
  }

  // Sort the values array by date
  valuesArr.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

  // Convert out specs object we create above into an array
  objectKeys = Object.keys(specsObj);
  for (let j = 0; j < objectKeys.length; j++) {
    specArr.push(specsObj[objectKeys[j]]);
  }

  // Get the scatter object
  const scatterObj = getFormattedPointsForScatter(valuesArr);

  // addedDates === 8 is REQUIRED if we need to show all dates on the x-axis
  // if ((valuesArr.length === 0 && specArr.length === 0) || addedDates === 8) return undefined;
  if ((valuesArr.length === 0 && specArr.length === 0)) return undefined;

  // console.log("Analytics", {
  //   valuesArr, specArr, scatterObj, singleDataPointObj,
  // });
  return {
    valuesArr, specArr, scatterObj, singleDataPointObj,
  };
};

// Given a selected graph, check if we have any data for that given graph
// The scatterObj stores data with "test_name|product_name" as the key -- if that exists we have data
export const checkIfDataExists = (scatterObj, selectedGraph) => {
  if (selectedGraph === "") return true;
  const uniqueIdentifier = `${selectedGraph.test}|${selectedGraph.product?.product_id}`;
  if (scatterObj[uniqueIdentifier] !== undefined) return true;

  return false;
};

// Function that checks if two given arrays are equal to each other
export const arrayEquals = (a, b) => Array.isArray(a)
      && Array.isArray(b)
      && a.length === b.length
      && a.every((val, index) => val === b[index]);

// Given a date in format "START_DATE-END_DATE"
// Returns an array of [START_DATE, END_DATE] -- required for the api call
export const getStartEndDate = (date) => {
  const dateSplit = date.split("-");

  return [moment(new Date(dateSplit[0])).format("YYYY/MM/DD"), moment(new Date(dateSplit[1])).format("YYYY/MM/DD")];
};

// Function to check the average value based on the spec of the test
export const compareAverageValueToSpec = (specValue, testType, bounds) => {
  let specColor = colorFromSpecFlag[0];
  // 3 different test types -- max, min, range
  if (testType === "max") {
    // specValue must be lower than the bound end
    if (specValue < bounds.end) {
      specColor = colorFromSpecFlag[1]; // 1 = in spec
    } else {
      specColor = colorFromSpecFlag[2]; // 2 = out of spec
    }
  } else if (testType === "min") {
    // specValue must be higher than bound start
    if (specValue > bounds.start) {
      specColor = colorFromSpecFlag[1];
    } else {
      specColor = colorFromSpecFlag[2];
    }
  } else if (testType === "range") {
    // specValue must be between bound start and end
    if (specValue > bounds.start && specValue < bounds.end) {
      specColor = colorFromSpecFlag[1];
    } else {
      specColor = colorFromSpecFlag[2];
    }
  }

  return specColor;
};

// Function to group scatter plots that overlap together -- to be shown in a tooltip
export const groupScatterDataInRange = (scatterObj, yGraphBound, selectedGraph, graphRef) => {
  if (graphRef.current === null) return null;
  const { product, test } = selectedGraph;

  // Logic to determine the difference based on the yGraphBound given
  let difference = 0;
  if (yGraphBound.end !== "auto") {
    const graphHeight = graphRef.current.state.prevHeight;

    difference = (yGraphBound.end / (graphHeight / 8)) * 2;
    // console.log("final difference", difference);
  }

  // Create a new object for date
  // Each new value will be another key inside that object, which contain arrays of the grouped values
  const uniqueIdentifier = `${test}|${product?.product_id}`;
  const groupedScatterObj = { };

  if (scatterObj[uniqueIdentifier] !== undefined) {
    // Need two pointers, current and next
    let next = 1;
    let currDateCounter = 1;
    for (let current = 0; current < scatterObj[uniqueIdentifier].length; current++) {
      // Reset the next pointer back to the beginning of the date
      next = currDateCounter;

      const currSpecObj = scatterObj[uniqueIdentifier][current];
      let nextSpecObj;
      if (next < scatterObj[uniqueIdentifier].length) {
        nextSpecObj = scatterObj[uniqueIdentifier][next];
      }

      // Check/create a new date object for each unique date
      if (groupedScatterObj[currSpecObj.date] === undefined) {
        currDateCounter = current;

        // Since we update the currDateCounter, also update the next obj and counter accordingly
        next = current;
        nextSpecObj = scatterObj[uniqueIdentifier][next];

        groupedScatterObj[currSpecObj.date] = {};
      }

      // Add each unique number as a key to the object
      groupedScatterObj[currSpecObj.date] = {
        ...groupedScatterObj[currSpecObj.date],
        [currSpecObj.rawResult]: [`${currSpecObj.id}|${currSpecObj.rawResult}`],
      };

      // Check the difference between the curr and the next
      while ((currSpecObj.date === nextSpecObj.date) && (next < scatterObj[uniqueIdentifier].length - 1)) {
        if ((Math.abs(currSpecObj.y - nextSpecObj.y) < difference) && next !== current) {
          const itemToPush = `${nextSpecObj.id}|${nextSpecObj.rawResult}`;
          groupedScatterObj[currSpecObj.date][currSpecObj.rawResult].push(itemToPush);
        }

        // Increment the next counter and update the next specObj
        next++;
        nextSpecObj = scatterObj[uniqueIdentifier][next];
      }

      // Sort the array in descending order
      const array = groupedScatterObj[currSpecObj.date][currSpecObj.rawResult];
      array.sort((a, b) => {
        const dataOne = a.split("|")[1];
        const dataTwo = b.split("|")[1];

        return dataTwo - dataOne;
      });
    }
  }

  // console.log("ToolTip Grouping", groupedScatterObj);
  return groupedScatterObj;
};

import React from "react";
import "./ReportsTableSpecCell.css";
import { VALID_SPECS_SET } from "../Components/ReportSpecs/helper";
import { SPEC_FLAGS_MAP } from "../../../Common/constants/SpecFlagConstants";

export default function ReportsTableSpecCell({
  rowData,
}) {
  const specs_flag = rowData.getValue();
  const isValidSpec = specs_flag?.toString() && VALID_SPECS_SET.has(specs_flag.toString());
  if (isValidSpec) {
    return (
      <div>
        <img
          className="ReportsTableSpecCell__Icon"
          src={SPEC_FLAGS_MAP[specs_flag].icon}
          alt="specFlagIcon"
        />
        <span
          className="ReportsTableSpecCell__Status"
          style={{
            color: isValidSpec ? SPEC_FLAGS_MAP[specs_flag].color : SPEC_FLAGS_MAP[0].color,
          }}
        >
          {SPEC_FLAGS_MAP[specs_flag].altLabel ?? SPEC_FLAGS_MAP[specs_flag].label}
        </span>
      </div>
    );
  }
  return null;
}

import React from "react";

import { Checkbox } from "antd";
import "./StyledCheckbox.css";

export default function StyledCheckbox({
  checked,
  onChange,
  disabled,
  children,
  containerClassName,
}) {
  return (
    <Checkbox
      className={`StyledCheckbox__Checkbox ${containerClassName}`}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    >
      {children}
    </Checkbox>
  );
}

import React, { useState } from "react";

import { Input, Modal } from "antd";
import { has } from "lodash";

import StyledButton from "../../Common/UIComponents/StyledButton";

import { ReactComponent as OnboardingClose } from "../../../assets/images/OnboardingClose.svg";
// import { hasFeature } from "../../../utils/common";

import "./AddTestModal.css";

export default function AddTestModal({
  onCancel,
  testsSelected,
  toggleTestChoice,
  searchValue = "",
  // sampleCategory,
}) {
  const [value, setValue] = useState(searchValue);
  const [invalid, setInvalid] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("");

  /**
   * Add test to selected test list
   */
  const addTest = () => {
    const trimmed = value.trim();
    // const hasPesticideTest = Object.keys(testsSelected).findIndex((key) => key.toLowerCase().includes("pesticide")) !== -1;
    // const hasPesticideText = trimmed.toLowerCase().includes("pesticide");
    // const hasPesticide = hasPesticideTest && hasPesticideText && sampleCategory !== "environment" && hasFeature("special_pesticides");
    if (!trimmed || has(testsSelected, trimmed)) {
      if (!trimmed) setInvalidMessage("Invalid test name");
      if (has(testsSelected, trimmed)) setInvalidMessage("Test already added");
      setInvalid(!trimmed || has(testsSelected, trimmed));
    } else {
      setInvalid(false);
      setInvalidMessage("");
    }
    // Skipping pesticide check as MRL is added - && !hasPesticide
    if (trimmed && !has(testsSelected, trimmed)) {
      toggleTestChoice(trimmed);
      setValue("");
      onCancel();
    }
  };
  return (
    <Modal
      open
      closable
      centered
      footer={null}
      onCancel={onCancel}
      width="452px"
      closeIcon={<OnboardingClose />}
      className="AddTestAlert"
    >
      <div className="AddTestTitle">Add Test Name</div>
      <div className="AddTestInput">
        <Input
          placeholder="Enter Test Name"
          onChange={(e) => setValue(e.target.value)}
          defaultValue={value}
          style={{ borderColor: invalid ? "#E63559" : undefined }}
        />
        {invalid && <small className="AddTestInput__Error">{invalidMessage}</small>}
      </div>
      <div className="AddTestBtn">
        <StyledButton
          type="primary"
          ghost
          onClick={onCancel}
          className="AddTestButton_Cancel"
        >
          Cancel
        </StyledButton>
        <StyledButton
          type="primary"
          onClick={addTest}
          className="AddTestButton_Add"
        >
          Add
        </StyledButton>

      </div>
    </Modal>
  );
}

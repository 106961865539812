import React from "react";
import { ReactComponent as LoadingDots } from "../../../../../assets/images/common/LoadingDots.svg";
import "./LoadingConfirmBtn.css";

export default function LoadingConfimBtn() {
  return (
    <button type="button" onClick={() => {}} className="EnvLoadingBtn">
      <LoadingDots width={16} />
    </button>
  );
}

import React, { Component } from "react";
import Modal from "antd/es/modal/Modal";
import PDFMerger from "pdf-merger-js/browser";
import { toast } from "react-toastify";
import { Carousel } from "react-responsive-carousel";
import { s3EsvPub, s3EsvPriv } from "../../../../../utils/aws";
import { getFileFromS3 } from "../../../../../utils/helpers";
import "./ReportPreviewModal.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import emptyStateIcon from "../../../../../assets/images/EmptyState_V2.png";
import cancelBtn from "../../../../../assets/images/product/crossWhite.png";
import PdfPreview from "../../../../Common/PdfPreview";
import NoResults from "../../../../Common/NoResults";
import PreviewDatePicker from "./PreviewDatePicker";
import HorizontalScroll from "../../../../Common/HorizontalScroll/HorizontalScroll";

export default class ReportPreviewModal extends Component {
  constructor(props) {
    super(props);

    const { filePaths } = props;
    const recentEntries = this.getSortedFileEntries(filePaths);
    const categories = this.setCategories(recentEntries);
    const activeDate = recentEntries[0][0];
    const selectedCategory = categories[activeDate][0];
    const reportDates = recentEntries.map((entry) => ({ date: entry[0] }));

    this.state = {
      currentSlide: 0,
      loadingFiles: true,
      previewUrlList: [{ url: null }],
      activeDate,
      modalIsAnimating: true,
      categories,
      selectedCategory,
      dateCategoryList: [],
      reportDates,
    };
  }

  componentDidMount() {
    this.handleFetchAndMergeFiles();
  }

  setCategories = (fileEntries) => fileEntries.reduce((acc, entry) => {
    acc[entry[0]] = Object.keys(entry[1]);
    return acc;
  }, {});

  handleFetchAndMergeFiles = () => {
    const { filePaths } = this.props;
    if (!filePaths || Object.keys(filePaths).length < 1) {
      toast.error("Unable to preview, no valid file path for this report!");
      return;
    }

    this.setState({ loadingFiles: true });
    const recentEntries = this.getSortedFileEntries(filePaths);
    const files = recentEntries.map((entry) => {
      const [date, data] = entry;
      return Object.entries(data).map((f) => [date, ...f]);
    }).flat();

    Promise.all(files.map(async (fileData) => {
      const [date, category, filepaths] = fileData;
      const fileTempArr = Array.from(new Set(filepaths));
      return Promise.all(fileTempArr.map(async (fileTemp) => this.getFileFromAWS(fileTemp))).then(async (allPreviewFiles) => {
        const successfullyFetched = allPreviewFiles.filter((file) => file !== null); // null means file failed to fetch, don't merge it
        return this.mergePDFs(successfullyFetched, date, category);
      });
    })).then((mergedPreviewFiles) => {
      const previewUrlList = mergedPreviewFiles;
      const dateCategoryList = mergedPreviewFiles.map((fileData) => `${fileData.date}-${fileData.category}`);
      this.setState({
        loadingFiles: false,
        previewUrlList,
        currentSlide: 0,
        dateCategoryList,
      });
    }).catch(() => {
      this.setState({
        loadingFiles: false,
      });
    });
  };

  getSortedFileEntries = (filePaths) => {
    const entriesByDate = Object.entries(filePaths);
    return entriesByDate.sort((a, b) => Date.parse(b[0]) - Date.parse(a[0]));
  }

    /* Fetches file from correct (private or public) s3 bucket. Returns blob if successful, null otherwise. */
    getFileFromAWS = async (fileTemp) => {
      const privateS3Bucket = fileTemp.includes("COAs/");
      const fileName = fileTemp.split("/").pop();
      const file = await getFileFromS3(fileTemp, privateS3Bucket ? s3EsvPriv : s3EsvPub);
      if (file) {
        return { fileName, file };
      }
      return null;
    };

  /* if there are multiple pdfs for a single date in preview, merge into one pdf */
  mergePDFs = async (fileBlobs, date, category) => {
    /** Merge */
    if (fileBlobs.length > 1) {
      const merger = new PDFMerger();
      const fileNames = [];
        return Promise.all(fileBlobs.map(async ({ fileName, file }) => { // eslint-disable-line
        fileNames.push(fileName);
        return merger.add(file);
        })).then(async () => { // eslint-disable-line
        const mergedPdf = await merger.saveAsBlob();
        const FileTemp = new File([mergedPdf], "name");
        const pdfURL = window.URL.createObjectURL(mergedPdf);
        const filesObj = {
          url: pdfURL,
          privateS3: true,
          file: FileTemp,
          blob: mergedPdf,
          name: fileNames.join(),
          date,
          category,
        };
        return filesObj;
      });
    }

    /** Don't need to merge if there's only one pdf */
    if (fileBlobs.length === 1) {
      const { fileName, file } = fileBlobs[0];
      const FileTemp = new File([file], "name");
      const pdfURL = window.URL.createObjectURL(file);
      const filesObj = {
        url: pdfURL,
        privateS3: true,
        file: FileTemp,
        blob: file,
        name: fileName,
        date,
        category,
      };
      return filesObj;
    }

    const filesObj = {
      url: "",
      date,
    };

    return filesObj;
  };

  handleDateChanged = (date) => {
    this.setState((state) => {
      const { dateCategoryList } = state;
      const category = state.categories[date][0];
      const slideIndex = dateCategoryList.indexOf(`${date}-${category}`);
      return {
        activeDate: date,
        currentSlide: slideIndex,
        selectedCategory: state.categories[date][0],
      };
    });
  }

  handleCategoryChange = (category) => {
    const { activeDate, dateCategoryList } = this.state;
    const slideIndex = dateCategoryList.indexOf(`${activeDate}-${category}`);
    this.setState({
      currentSlide: slideIndex,
      selectedCategory: category,
    });
  }

  render() {
    const { handleCloseForPreview } = this.props;
    const {
      currentSlide, activeDate, modalIsAnimating, previewUrlList, loadingFiles,
      categories, selectedCategory, reportDates,
    } = this.state;
    const carouselStyle = { margin: "-40px" };

    return (
      <Modal
        open
        closable={false}
        centered
        footer={null}
        onCancel={() => handleCloseForPreview()}
        className="ReportPreviewModal"
        width="40vw"
        afterOpenChange={() => this.setState({ modalIsAnimating: false })}
      >
        <div className="ProductReportPreviewModal__Container">
          <span>COA</span>
          <img
            src={cancelBtn}
            alt="close"
            className="ProductReportPreviewModal__Container__CloseIcon"
            onClick={() => handleCloseForPreview()}
          />
        </div>
        <div className="ProductReportPreviewModal__ReportContainer">
          {Object.keys(categories).length > 1 && <PreviewDatePicker data={reportDates} activeDate={activeDate} updateActiveDate={this.handleDateChanged} /> }

          {!loadingFiles && Object.keys(categories).length > 0 && categories[activeDate].length > 1 && (
            <HorizontalScroll containerClassName="ProductReportPreviewModal__CategoryList">
              {categories[activeDate].map((category) => (
                <span
                  key={category}
                  onClick={() => this.handleCategoryChange(category)}
                  className={`ProductReportPreviewModal__CategoryListItem ${category === selectedCategory ? "ProductReportPreviewModal__CategoryListItem__Selected" : ""}`}
                >
                  {category}
                </span>
              ))}
            </HorizontalScroll>
          )}

          <Carousel
            showIndicators={false}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            selectedItem={currentSlide}
            swipeable={Object.keys(previewUrlList).length > 1}
            style={carouselStyle}
          >
            {previewUrlList.map((item, index) => (
              <div
                className="ProductReportPreviewModal__ReportView"
                key={index}
              >
                {item.url || loadingFiles ? (
                  <PdfPreview
                    blob={item.url}
                    height="70vh"
                    scaleType="PageWidth"
                    scrollBarBorderRadius="8px"
                    showLoading={modalIsAnimating || loadingFiles}
                  />
                ) : (
                  <div className="ProductReportPreviewModal__NoResults">
                    <NoResults image={emptyStateIcon} message="Preview not available." />
                  </div>
                )}
              </div>
            ))}
            {/* {!previewUrlList[currentSlide] && !previewUrlList[currentSlide].url ? (
                <PdfPreview
                  blob={previewUrlList[currentSlide].url}
                  height="70vh"
                  scaleType="PageWidth"
                  scrollBarBorderRadius="8px"
                />
              ) : (

              )} */}
          </Carousel>
        </div>
      </Modal>
    );
  }
}

import axios from "axios";

import { setUserCredentials } from "../utils/common";
import { API_URL_AUTH } from "../utils/Constant";
import firebaseAuth from "../utils/firebase";

/* BUG
* As of August 1, 2023, the Firebase authentication method is not always reliable.
* For new users, the response is often empty. However, it works fine for existing users.

* Manually check if an email exists with the fetchSignInMethodsForEmail method.
* This method checks if the given email already has an account associated with it.
*/
export const validEmail = async (email, callback) => {
  firebaseAuth
    .fetchSignInMethodsForEmail(email)
    .then((signInMethods) => {
      if (signInMethods && signInMethods.length > 0) {
        callback({ success: true });
      } else {
        callback({ success: false });
      }
    })
    .catch((error) => {
      callback({
        success: false,
        message: error.message || "something went wrong",
      });
    });
};

export const getUserDetailsByToken = async (idToken) => {
  try {
    const params = {
      token: idToken,
    };
    const result = await axios.get(`${API_URL_AUTH}/login/`, { params });
    const data = result.data;
    return {
      success: data.message === "Success",
      data: (data && data.result) || {},
      message: data.message,
    };
  } catch (e) {
    const message = e.response.data.message ?? e.message;
    return {
      success: false,
      message: message || "Something went wrong",
    };
  }
};

export const signIn = async (email, password, rememberMe, appContext, callback) => {
  firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(async (response) => {
      const { user } = response;
      const idToken = response.user._lat;
      const result = await getUserDetailsByToken(idToken);
      if (result.success) {
        const data = {
          ...result.data,
          company_name: result.data.company_name,
          uid: user.uid || "",
          email: user.email || "",
          phoneNumber: user.phoneNumber || "",
          displayName: user.displayName || "",
          photoURL: user.photoURL || "",
          rememberMe,
          idToken,
        };
        setUserCredentials(rememberMe ? "local" : "session", data, appContext);
        callback({ success: true, data });
      } else {
        callback(result);
      }
    })
    .catch((error) => {
      callback({
        success: false,
        message: error.message || "something went wrong",
      });
    });
};

export const sendForgotPasswordEmail = async (email, callback) => {
  firebaseAuth
    .sendPasswordResetEmail(email)
    .then(async (response) => {
      callback({ success: true, data: response });
    })
    .catch((error) => {
      callback({
        success: false,
        message: error.message || "Something went wrong",
      });
    });
};

export const changePwd = async (password, callback) => {
  const user = firebaseAuth.currentUser;
  user
    .updatePassword(password)
    .then(async () => {
      callback({ success: true });
    })
    .catch((error) => {
      callback({
        success: false,
        message: error.message || "something went wrong",
      });
    });
};

/**
 * If companyDomain and companyName empty, return list of company names grouped by domain, otherwise return user_payload
 * @param {String} companyDomain
 * @param {String} companyName
 * @returns {Object} { success, user_payload }
 */
export const esvSupportLogin = async (companyDomain, companyName) => {
  try {
    const params = {
      company_domain: companyDomain,
      company_name: companyName,
    };

    const result = await axios.get(`${API_URL_AUTH}/getpayload/`, { params });

    if (result.data.message === "Success") {
      return {
        success: true,
        data: result.data,
      };
    }
    return { success: false, data: result.data.message };
  } catch (e) {
    return { success: false, data: "Something went wrong." };
  }
};

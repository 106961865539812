import React from "react";
import { ReactComponent as EditIcon } from "../../../../../assets/images/sample-submission/edit.svg";
import { ReactComponent as UploadImageIcon } from "../../../../../assets/images/sample-submission/uploadImageIcon.svg";
import "./UploadImageInput.css";

export default function UploadImageInput({
  inputValuesSavedForNext,
  toggleSaveForNext,
  imageSrc,
  setShowUploadImageModal,
  hideSaveForNext,
}) {
  return (
    <>
      <div className="SampleSubmission__ImageSectionHeaderContainer">
        <span className="SampleSubmission__ImageSectionHeader">Sample Image</span>
        {!hideSaveForNext && (
          <div className="sample-submission-checkbox-container SampleSubmission__UploadImage">
            <div className="SampleDetails__ImageCheckboxParent">
              <input type="checkbox" checked={inputValuesSavedForNext.img ?? false} onChange={() => { toggleSaveForNext("img"); }} />
              <span className="sample-submission-checkmark" onClick={() => { toggleSaveForNext("img"); }} />
            </div>
            <label>Use in Next Sample</label>
          </div>
        )}
      </div>
      <div
        key="img"
        className="SampleSubmission__UploadImageRow"
      >
        <div
          className={`SampleSubmission__UploadImageInputDiv ${imageSrc ? "imageWithSrc" : ""}`}
          onClick={imageSrc ? null : () => setShowUploadImageModal(true)}
        >
          {imageSrc ? (
            <img src={imageSrc} alt="sample" />
          ) : (
            <UploadImageIcon />
          )}
          {imageSrc && (
          <div
            className="SampleSubmission__ImageEdit"
            onClick={() => setShowUploadImageModal(true)}
          >
            <EditIcon />
          </div>
          )}
        </div>
      </div>
    </>
  );
}

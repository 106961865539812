import React from "react";
import { Tabs } from "antd";
import "./NavTabs.css";

export default function NavTabs({
  activeKey,
  addIcon,
  animated,
  centered,
  className,
  defaultActiveKey,
  hideAdd,
  indicatorSize = 0,
  items,
  moreIcon,
  popupClassName,
  renderTabBar,
  size,
  tabBarExtraContent,
  tabBarGutter = 20,
  tabBarStyle,
  tabPosition,
  destroyInactiveTabPane = true,
  type = "list",
  onChange,
  onEdit,
  onTabClick,
  onTabScroll,
}) {
  return (
    <Tabs
      activeKey={activeKey}
      addIcon={addIcon}
      animated={animated}
      centered={centered}
      className={`NavTabs ${className}`}
      defaultActiveKey={defaultActiveKey}
      hideAdd={hideAdd}
      indicatorSize={indicatorSize}
      items={items}
      moreIcon={moreIcon}
      popupClassName={`NavTabs__Menu ${popupClassName}`}
      renderTabBar={renderTabBar}
      size={size}
      tabBarExtraContent={tabBarExtraContent}
      tabBarGutter={tabBarGutter}
      tabBarStyle={tabBarStyle}
      tabPosition={tabPosition}
      destroyInactiveTabPane={destroyInactiveTabPane}
      type={type}
      onChange={onChange}
      onEdit={onEdit}
      onTabClick={onTabClick}
      onTabScroll={onTabScroll}
    />
  );
}

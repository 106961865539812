import React, { Component } from "react";

import Report from "./Report/Report";
import Analytics from "./Analytics/Analytics";
import NavTabs from "../Common/UIComponents/NavTabs";
import Template from "./Template/Template";
import { ReactComponent as TemplateIcon } from "../../assets/images/feature-icons/TemplateOutlined.svg";
import { ReactComponent as ReportIcon } from "../../assets/images/feature-icons/ReportOutlined.svg";
import { ReactComponent as AnalyticsIcon } from "../../assets/images/feature-icons/AnalyticsOutlined.svg";

import "./Product.css";

class Reports extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const { onChangeReport } = this.props;
    this._isMounted = true;
    if (this._isMounted) {
      const url = window.location.href;
      let activeTab = "1";
      let subUrl = "report";

      if (url.includes("/report")) {
        activeTab = "1";
        subUrl = "report";
      } else if (url.includes("/template")) {
        activeTab = "2";
        subUrl = "template";
      } else if (url.includes("/analytics")) {
        activeTab = "3";
        subUrl = "analytics";
      }
      if (this._isMounted) {
        this.setState({ activeTab });
      }
      onChangeReport(subUrl);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSelect(selectedTab) {
    const { history } = this.props;
    if (this._isMounted) {
      this.setState({ activeTab: selectedTab }, () => {
        if (selectedTab === "1") {
          history.push("/product/report");
        } else if (selectedTab === "2") {
          history.push("/product/template");
        } else if (selectedTab === "3") {
          history.push("/product/analytics");
        }
      });
    }
  }

  render() {
    const { history } = this.props;
    const { activeTab } = this.state;

    return (
      <div className="prodMainContainer">
        {this._isMounted
          ? (
            <NavTabs
              activeKey={activeTab}
              onChange={this.handleSelect}
              className={activeTab === "1" ? "ProductTabs--overflow-auto" : ""}
              items={[
                {
                  key: "1",
                  label: "Report",
                  icon: <ReportIcon />,
                  children: <Report history={history} />,
                },
                {
                  key: "2",
                  label: "Template",
                  icon: <TemplateIcon />,
                  children: <Template history={history} />,
                },
                {
                  key: "3",
                  label: "Analytics",
                  icon: <AnalyticsIcon />,
                  children: <Analytics history={history} />,
                },
              ]}
            />
          ) : null}
      </div>
    );
  }
}

export default Reports;

import React, { useContext, useRef, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import ReportsTableDraggableHeaderCell from "./ReportsTableDraggableHeaderCell";
import { changePosition, editTableTitle } from "../../../../actions/reports";
import "./ReportsTableHeader.css";
import { AppContext } from "../../../../AppContext";

export default function ReportsTableHeader({
  headerId,
  headers,
  fields,
  setFields,
  linkReportJsonFields,
  disableInteraction,
}) {
  const [editTableTitleIdx, setEditTableTitleIdx] = useState(-1);
  const [reorderLoading, setReorderLoading] = useState(false);
  const currentDraggableInfo = useRef(null); // used to style draggables when dragging { width, height, originIdx}
  const { userTitle } = useContext(AppContext);

  /**
   * Record the width, height, and origin index of the dragged field (used to style draggables)
   * @param {Object} _ { draggableId: String, source: { index: Number }}
   */
  const onBeforeDragStart = ({ draggableId, source }) => {
    const draggedElem = document.querySelector(`[data-rbd-drag-handle-draggable-id=${draggableId}]`);
    const { width, height } = draggedElem.getBoundingClientRect();
    currentDraggableInfo.current = { width, height, originIdx: source.index };
  };

  /**
   * Handle column reordering. Call the changePosition to update fields array order.
   * @param {Object} result {source: { index: Number }, destination: {index: Number }}
   * @returns {void}
   */
  const onDragEnd = async (result) => {
    currentDraggableInfo.current = null;
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const startIndex = source.index;
    const endIndex = destination.index;
    if (startIndex === endIndex) {
      return;
    }
    /** Get new index for field in the unsorted array */
    const newIndexInOriginalArray = fields[endIndex].originalIdx;

    /** Reorder the sorted array */
    const changedFields = fields[startIndex].title_field;
    const fieldsCopy = [...fields];
    const [removed] = fieldsCopy.splice(startIndex, 1);
    fieldsCopy.splice(endIndex, 0, removed);

    /** Update fields before api call. If the call fails, revert to previous. */
    setFields(fieldsCopy);
    setReorderLoading(true);
    const { success, fields: updatedFields, message } = await changePosition({
      field: changedFields,
      position: String(newIndexInOriginalArray),
    });
    if (success) {
      setFields(updatedFields, true); // set sort to true
    } else {
      toast.error(message);
      setFields(fields);
    }
    setReorderLoading(false);
  };

  /**
   * Call the api to update the field title.
   * @param {React.KeyPressEvent<HTMLInputElement>} e
   * @param {String} flag
   * @param {Number} idx index of field
   */
  const handleSaveTableTitle = async (value, flag, idx) => {
    if (value.trim()) {
      const oldTableTitle = fields[idx].title_field;
      if (flag === "editTitle") {
        const params = {
          field: oldTableTitle,
          new_field: value.trim(),
        };
        const response = await editTableTitle(params);
        if (response.success && response.fields) {
          setFields(response.fields, true); // set sort to true
        } else {
          toast.error(response.message);
        }
        setEditTableTitleIdx(-1);
      }
    } else {
      toast.error("Title cannot be empty");
    }
  };

  const admin = userTitle === "Admin" || userTitle === "Editor";

  return (
    <DragDropContext key={headerId} onBeforeDragStart={onBeforeDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId="ReportsTableHeader" direction="horizontal">
        {(droppableProvided, droppableSnapshot) => (
          <tr
            key={headerId}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
          >
            {headers.map((header, idx) => (
              <ReportsTableDraggableHeaderCell
                key={header.id}
                id={header.id}
                title={header.column.columnDef.header}
                align={header.column.columnDef.align}
                fields={fields}
                linkReportJsonFields={linkReportJsonFields}
                idx={idx}
                isDraggable={!disableInteraction && idx >= linkReportJsonFields.length && idx < fields.length}
                isEditable={!disableInteraction && admin && idx < fields.length}
                reorderLoading={reorderLoading}
                handleSaveTableTitle={handleSaveTableTitle}
                editTableTitleIdx={editTableTitleIdx}
                setEditTableTitleIdx={setEditTableTitleIdx}
                droppableSnapshot={droppableSnapshot}
                currentDraggableInfo={currentDraggableInfo}
              />
            ))}
          </tr>
        )}
      </Droppable>
    </DragDropContext>
  );
}

import { Dropdown, Tooltip } from "antd";
import React from "react";
import { ReactComponent as EditIcon } from "../../../../assets/images/sample-submission/edit.svg";
import { ReactComponent as GarbageCan } from "../../../../assets/images/sample-submission/hoveredGarbageCan.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/images/sample-submission/addSamplePlusIcon.svg";
import { ReactComponent as MoreIcon } from "../../../../assets/images/sample-submission/moreIcon.svg";
import "./ActionsMenu.css";

export default function ActionsMenu({
  handleEdit,
  handleDelete,
  handleAdd,
  disableAdd,
  disableDelete,
  disabled,
  disabledTooltipText,
}) {
  const items = [];

  if (handleEdit) {
    items.push({
      label:
  <div
    className="SampleSubmission__ActionsMenuItem"
    onClick={handleEdit}
  >
    <EditIcon className="SampleSubmission__ActionsMenuEditIcon" />
    <span>Edit</span>
  </div>,
      key: "0",
    });
  }

  if (handleAdd) {
    items.push({
      label:
  <Tooltip
    title={disableAdd ? "Cannot add samples after Samples Received" : ""}
    overlayClassName="SampleSubmission__ActionsMenu__Tooltip"
  >
    <div
      className={`SampleSubmission__ActionsMenuItem--add ${disableAdd ? "SampleSubmission__ActionsMenuItem--disabled" : ""}`}
      onClick={disableAdd ? null : handleAdd}
    >
      <PlusIcon
        className="SampleSubmission__ActionsMenuAddIcon"
        width={18}
        height={18}
      />
      <span>Add Samples</span>
    </div>
  </Tooltip>,
      key: "2",
      disabled: disableAdd ?? false,
    });
  }

  if (handleDelete) {
    items.push({
      label:
  <Tooltip
    title={disableDelete ? "Cannot delete samples after Samples Received" : ""}
    overlayClassName="SampleSubmission__ActionsMenu__Tooltip"
    placement="right"
  >
    <div
      className={`SampleSubmission__ActionsMenuItem ${disableDelete ? "SampleSubmission__ActionsMenuItem--disabled" : ""}`}
      onClick={disableDelete ? null : handleDelete}
    >
      <GarbageCan className="SampleSubmission__ActionsMenuDeleteIcon" />
      <span>Delete</span>
    </div>
  </Tooltip>,
      key: "1",
      disabled: disableDelete ?? false,
    });
  }

  return (
    <Tooltip
      title={disabled ? disabledTooltipText ?? "" : ""}
      placement="right"
      overlayClassName="SampleSubmission__ActionsMenu__Tooltip"
    >
      <Dropdown
        menu={{ items }}
        disabled={disabled ?? false}
        trigger={["click"]}
        overlayClassName="SampleSubmission__ActionsMenu"
      >
        <MoreIcon
          className={`SampleSubmission__ActionsMenuMoreIcon${disabled ? "--disabled" : ""}`}
          fill={disabled ? "#D7DAE1" : "#19305A"}
          width={20}
          height={20}
        />
      </Dropdown>
    </Tooltip>

  );
}

import React from "react";
import "./UploadSwabFiles.css";
import "./SwabFileExample.css";
import Scrollbar from "react-scrollbars-custom";

export default function SwabFileExample(props) {
  const {
    linkPatternFields,
  } = props;

  return (
    <div className="swabFileExampleContainer">
      <div className="swabFileExample__Header">
        Example
      </div>
      <div
        className="swabFileExample__content"
      >
        <Scrollbar>
          <table className="swabFileExample__table">
            <thead>
              <tr>
                {linkPatternFields.map(({ title_field }, idx) => <th key={idx}>{title_field}</th>)}
              </tr>
            </thead>
            <tbody>
              {Array(2).fill().map((item, index) => (
                <tr key={index}>
                  {linkPatternFields.map(({ json_field }) => <td key={json_field} className="uploaded-files-item-swab-error swabFileExample__tableContent" />)}
                </tr>
              ))}
              <tr>
                {linkPatternFields.map(({ title_field }) => <td key={title_field} className="uploaded-files-item-swab-error swabFileExample__tableContent--fade" />)}
              </tr>
            </tbody>
          </table>
        </Scrollbar>
      </div>
    </div>
  );
}

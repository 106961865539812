import React, { useState } from "react";
import SearchIconSmall from "../../../../../assets/images/product/template/SearchGreyLarge.png";
import CloseIcon from "../../../../../assets/images/product/template/closeGrey.png";

export default function DataFieldsSearch(props) {
  const {
    toggleShowSearch,
    handleSubmitSearch,
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const onKeyPress = (e) => {
    if (e.charCode === 13) {
      handleSubmitSearch(searchValue, true);
    }
  };

  const onChange = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      handleSubmitSearch("", false);
    }
  };

  return (
    <div className="templateSearchBarContainer">
      <div className="templateSearchBar">
        <input
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={onChange}
          onKeyPress={onKeyPress}
          className="templateSearchBarInput"
        />
        <img src={SearchIconSmall} alt="SearchIconSmall" onClick={() => handleSubmitSearch(searchValue, true)} className="searchIconSmall" />
      </div>
      <img src={CloseIcon} alt="templateSearchCloseIcon" onClick={toggleShowSearch} className="templateSearchClose" />
    </div>
  );
}

import React, { useState } from "react";
import COABuilderDroppableSection from "./COABuilderDroppableSection";
import { ReactComponent as DeleteIcon } from "../../../../../../../assets/images/product/template/deleteSectionIcon.svg";
import "./COABuilderSection.css";

export default function COABuilderSection(props) {
  const {
    section,
    sectionIndex,
    setSectionInputs,
    handleTemplateDataFieldsChange,
    selectedReports,
    handleSectionDelete,
  } = props;
  const { inputs } = section;

  const [showDelete, setShowDelete] = useState(false);

  /**
   * Delete input from section
   * @param {Number} index index of input in section part
   * @param {String} sectionPart "left" or "right"
   */
  const deleteInput = (index, sectionPart) => {
    const inputsObject = { ...inputs };
    let removedDataField = null;
    if (sectionPart === "left") {
      removedDataField = inputsObject.left.splice(index, 1)[0];
    } else {
      removedDataField = inputsObject.right.splice(index, 1)[0];
    }
    setSectionInputs(sectionIndex, inputsObject, false, { action: "delete", flag: removedDataField.flag, value: removedDataField.value }, false);
    if (removedDataField.flag !== "0") {
      handleTemplateDataFieldsChange("delete", removedDataField.flag, removedDataField.fieldName);
    }
  };

  /**
   * Update section's inputs object with DOM node of input
   * @param {String} sectionPart "left" or "right"
   * @param {Number} index index of input in section part
   * @param {HTMLElement} input
   */
  const setRef = (sectionPart, index, input) => {
    const inputsObject = { ...inputs };
    inputsObject[sectionPart][index].ref = input;
    setSectionInputs(sectionIndex, inputsObject);
  };

  return (
    <div
      className="COABuilder__Section"
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      {showDelete && (<DeleteIcon className="COABuilder__Section__DeleteIcon" onClick={(e) => handleSectionDelete(e, section)} />)}
      <COABuilderDroppableSection
        inputs={inputs.left}
        dataFieldToValuesMap={selectedReports.data_fileds ?? {}}
        sectionPart="left"
        droppableId={`dynamic_${sectionIndex}_left`}
        deleteInput={deleteInput}
        setRef={setRef}
      />
      <COABuilderDroppableSection
        inputs={inputs.right}
        dataFieldToValuesMap={selectedReports.data_fileds ?? {}}
        sectionPart="right"
        droppableId={`dynamic_${sectionIndex}_right`}
        deleteInput={deleteInput}
        setRef={setRef}
      />
      {inputs.left.length < 1 && inputs.right.length < 1 && (
      <div className="COABuilderSection__PlaceHolderText">
        <span> It’s looking empty! </span>
        <span> Drag and drop fields here to add details </span>
      </div>
      )}
    </div>
  );
}

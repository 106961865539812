/* eslint-disable no-nested-ternary */
import React from "react";
import Scrollbar from "react-scrollbars-custom";
import { PINS_SPEC_STYLE_MAP } from "../../Constant";

export default function ResultTable({
  data,
}) {
  /** display the spec data only if there are spec values */
  const renderTableRow = (rowData, index, notemptyspec) => {
    const {
      test, test_spec, test_result, test_spec_flag,
    } = rowData;
    const { color, box_shadow_color } = PINS_SPEC_STYLE_MAP[test_spec_flag] ?? PINS_SPEC_STYLE_MAP.invalid;
    const outOfSpec = test_spec_flag === "2" || test_spec_flag === "6";
    return (
      <tr style={{ backgroundColor: outOfSpec ? box_shadow_color : "#EFF2F3" }} key={index}>
        <td><div>{test}</div></td>
        { notemptyspec
          && (
          <td>
            <div className="test-spec">{test_spec}</div>
          </td>
          )}
        <td style={{ color }}><div>{test_result}</div></td>
      </tr>
    );
  };

  if (data !== undefined) {
    const notemptyspec = (data.map((item) => (item.test_spec === "-" ? "true" : "false"))).includes("false");
    return (
      <Scrollbar>
        <table className="env-report-view-report-section-results-table">
          <thead className="env-report-view-report-section-results-table-header">
            <tr>
              <th>Test</th>
              {notemptyspec && (<th>Specs</th>) }
              <th>Results</th>
            </tr>
          </thead>
          <tbody className="env-report-view-report-section-results-table-body">
            {data.map((item, idx) => (
              renderTableRow(item, idx, notemptyspec)
            ))}
          </tbody>
        </table>
      </Scrollbar>
    );
  } return (
    <table className="env-report-view-report-section-results-table" />
  );
}

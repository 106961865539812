import axios from "axios";

import { version } from "../../package.json";
import { API_URL, ESV_DETAILS } from "./Constant";

const ESV_STORAGE_KEY = "user_details";

const checkIfRememberMe = () => !sessionStorage.getItem(ESV_STORAGE_KEY);

// Check if session or local storage is available (https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API)
// Safari's strict privacy settings, which can block access to localStorage in certain contexts
function storageAvailable(type) {
  let storage;
  try {
    storage = window[type]; // Access the actual storage object
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Get user info stored in local or session storage.
 * Checks session storage first.
 * @returns {Object} user info object if found, null otherwise
 */
export const getUserCredentials = () => {
  let userInfoAsString = sessionStorage.getItem(ESV_STORAGE_KEY);
  if (!userInfoAsString) {
    userInfoAsString = localStorage.getItem(ESV_STORAGE_KEY);
  }
  const userInfo = JSON.parse(userInfoAsString) || null;

  return userInfo;
};

/**
 * Store user info in session or local storage.
 * If neither one is specified, store in session storage if user_details exist there, otherwise store in local storage.
 * @param {String} _storageType "local" or "session"
 * @param {Object} data user info
 * @param {Object} appContext app-level state
 */
export const setUserCredentials = (_storageType, data, appContext) => {
  let storageType = _storageType;
  if (!storageType) {
    storageType = checkIfRememberMe() ? "local" : "session";
  }

  const storage = (storageType === "local" && storageAvailable(localStorage)) ? localStorage : sessionStorage;

  /** Update user info in app-level state */
  const { setUserTitle } = appContext;
  setUserTitle(data.user_title);
  storage.setItem(ESV_STORAGE_KEY, JSON.stringify(data));
};

/**
 * Check if user is logged in (checks local and session storage)
 * @returns {Boolean} true if user is logged in, false if not
 */
export const isUser = () => {
  const user_details = getUserCredentials();
  return !!(user_details && user_details.uid);
};

/**
 * Returns fields to be added to every api payload
 * @returns {Object} returns { user_payload, company_name, company_domain } if user is not null
 */
export const getMandatoryUserData = () => {
  const user = getUserCredentials();
  return user === null ? {} : { user_payload: user.user_payload, company_name: user.company_name, company_domain: user.company_domain };
};

/**
 * Sign out user by clearing storage
 * @param {Object} history react-router history object
 * @param {Object} appContext app-level state
 */
export const signOut = (history, appContext) => {
  const userType = getUserCredentials()?.uid;
  if (checkIfRememberMe()) {
    localStorage.removeItem(ESV_STORAGE_KEY); // needed to detect signOut in handleLocalStorageChange
    sessionStorage.removeItem(ESV_DETAILS); // needed to clear esv_details
    localStorage.clear();
  } else {
    sessionStorage.clear();
  }
  /** Clear user title in state */
  const { setUserTitle } = appContext;
  setUserTitle("");

  if (userType === "support") {
    history.replace("/login/support");
  } else {
    history.replace("/login");
  }
};

/**
 * When local storage is updated, sync changes across browsing contexts
 * @param {StorageEvent} event = {key, oldValue, newValue} key in local storage (only concerned w/ user_details), oldValue of key, newValue of key
 * @param {Object} history react-router history object
 * @param {Object} appContext app-level state
 */
export const handleLocalStorageChange = ({ key, oldValue, newValue }, history, appContext) => {
  if (key === ESV_STORAGE_KEY && checkIfRememberMe()) {
    if (!newValue) { // other tab was signed out, sign out here
      signOut(history, appContext);
    } else if (!oldValue // other tab was signed in, reload page to sign in here
      || JSON.parse(oldValue).company_name !== JSON.parse(newValue).company_name // other tab switched companies, refresh to switch here
      || JSON.parse(oldValue).user_title !== JSON.parse(newValue).user_title) { // user role updated in other tab, refresh to update here
      /** Reload the window */
      window.location.reload(false);
    }
  }
};

/**
 * Check if user exists, return user info if yes
 * @param {String} email
 * @return  {Object} { success, user_name, phone_number }
 */
export const checkUser = async (email, companyName) => {
  try {
    const params = { email, company_name: companyName };
    if (isUser()) {
      params.disableInterceptor = true;
    }
    const resp = await axios.get(`${API_URL}/checkuser/`, {
      params,
    });
    const {
      status, user_name, user_contact, user_title, firebase_exist_status,
    } = resp.data.result;
    const result = {
      success: resp.data.message === "Success",
      message: resp.data.message,
      status,
      user_name,
      phone_number: user_contact,
      user_title,
      firebase_exist_status,
    };

    return result;
  } catch (e) {
    const message = e.response?.data?.message || "Something went wrong.";
    return { success: false, message, userNotFound: message === "User not found" };
  }
};

/**
 * When page is loaded, call the check user api and update user_title
 * @param {Object} appContext app-level state
 */
export const handlePageLoad = async (appContext) => {
  if (isUser()) {
    const userInfo = getUserCredentials();
    if (userInfo.uid !== "support") { // user_title will always be admin if logged in through support login
      const { success, user_title } = await checkUser(userInfo.user_email, userInfo.company_name);
      if (success && user_title !== userInfo.user_title) {
        setUserCredentials(null, { ...userInfo, user_title }, appContext);
      }
    }
  }
};

/**
 * Get list of tabs
 * @returns Array of tabs
 */
export const getTabsList = () => {
  const config = sessionStorage.getItem(ESV_DETAILS);
  if (!config) return [];
  const features = JSON.parse(config)?.tabsList;
  return features;
};

/**
 * Get list of features
 * @returns Array of features
 */
export const getFeatures = () => {
  const config = sessionStorage.getItem(ESV_DETAILS);
  if (!config) return [];
  const features = JSON.parse(config)?.featuresList;
  return features;
};

/**
 * Checks if company has a specific feature.
 * @param {string} feature name of the feature
 * @returns {boolean} if feature exists returns true otherwise false
 */
export const hasFeature = (feature) => {
  const features = getFeatures();
  return features.indexOf(feature) !== -1;
};

/**
 * Get app version
 * @returns {string} app version
 */
export const getAppVersion = () => version;

/* Fade-In Effect */
export const showScrollbarStyle = {
  style: {
    visibility: "visible", opacity: 1, transition: "opacity 0.1s linear",
  },
};

/* Fade-Out Effect */
export const hideScrollbarStyle = {
  style: {
    visibility: "hidden", opacity: 0, transition: "visibility 0s 0.3s, opacity 0.3s linear",
  },
};

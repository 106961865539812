import axios from "axios";
import { getUserCredentials, setUserCredentials } from "../utils/common";
import { API_URL } from "../utils/Constant";
import { getViewAsPerStatusCode } from "../utils/helpers";

/**
 * Switch the current company, set local or session storage, and reload the page
 * @param {String} selectedCompany one of the companies under the current domain
 * @param {String} userTitle user's role for selectedCompany
 * @param {Object} appContext app-level state
 */
export const switchCompany = async (selectedCompany, userTitle, appContext) => {
  let user_data;
  try {
    user_data = getUserCredentials();
    setUserCredentials(null, {
      ...user_data,
      company_name: selectedCompany,
      user_title: user_data.uid === "support" ? "Admin" : userTitle,
    }, appContext);
  } catch (error) {
    return false;
  }

  /* Update last logged in company if not support */
  if (user_data.uid !== "support") {
    try {
      await axios.post(`${API_URL}/switchcompany/`);
    } catch (e) {
      return true;
    }
  }

  return true;
};

/**
 * Get company list
 * @returns {Object} { success, message, company_list }
 */
export const getCompanyList = async () => {
  try {
    const resp = await axios.get(`${API_URL}/useraccounts/`);
    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      company_list: resp.data.result,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Retrieves the tabs list for a specific company (current logged in users company).
 * @returns {Object} Object containing the tabs list.
 */
export const getTabsForCompany = async () => {
  try {
    const { company_name } = getUserCredentials();
    const resp = await axios.get(`${API_URL}/useraccounts/`);
    const { result } = resp.data;
    const companyData = result.find((item) => item.company_name === company_name);
    const tabsObj = companyData.tabs;
    const featuresObj = companyData.features;
    const tabsList = Object.keys(tabsObj).filter((tab) => tabsObj[tab]);
    const featuresList = Object.keys(featuresObj).filter((key) => featuresObj[key]);
    return { tabsList, featuresList };
  } catch (e) {
    getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

import React, { useContext, useEffect, useState } from "react";
import maintenanceImg from "../assets/images/under_maintenance.gif";
import { signOut } from "../utils/common";
import "./AuthFailedPage.css";
import { AppContext } from "../AppContext";

const AuthFailedPage = ({ history }) => {
  const [timeRemaining, setTimeRemaining] = useState(10);
  const appContext = useContext(AppContext);

  /**
   * Set countdown timer.
   */
  useEffect(() => {
    if (!timeRemaining) {
      signOut(history, appContext);
      return;
    }
    const intervalID = setInterval(() => setTimeRemaining(timeRemaining - 1), 1000);

    return () => clearInterval(intervalID); // eslint-disable-line
  }, [timeRemaining]); // eslint-disable-line

  return (
    <div className="AuthFailedPage">
      <img src={maintenanceImg} alt="under_maintenance" width={450} />
      <span className="AuthFailedPage__Header">{`Signing out in ${timeRemaining}s`}</span>
      <span className="AuthFailedPage__SubHeader">Authentication failed.</span>
      <button type="button" onClick={() => signOut(history, appContext)} className="AuthFailedPage__SignOutButton">Sign Out</button>
    </div>
  );
};

export default AuthFailedPage;

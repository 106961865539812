import React from "react";

export default function TypeToggle({ types, selectedType, onChange }) {
  return (
    <div className="SetFrequencyForm__TypeToggle">
      {types.map((type) => (
        <button
          key={type}
          type="button"
          className={`SetFrequencyForm__ToggleButton ${selectedType === type ? "SetFrequencyForm__ToggleButton--selected" : ""}`}
          onClick={() => onChange(type)}
        >
          {type}
        </button>
      ))}
    </div>
  );
}

import React from "react";

import { uniqBy, orderBy } from "lodash";
import moment from "moment";

import { colorsAndIconsArray } from "../../../helper";

import "./ComposedGraphStyle.css";

// Custom Tooltip
const GraphTooltipRender = React.memo(({
  active, payload, label, graphType, hoveredItem,
}) => {
  const Env_analyticsGraphTooltipFooter_Table = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "4px",
  };
    // const Env_analyticsGraphTooltipFooter_Total = {
    //   display: "flex",
    //   justifyContent: "space-between",
    //   fontFamily: "Roboto medium",
    // };

  const Env_analyticsGraphTooltipTest = (entry) => ({
    maxWidth: "120px",
    marginRight: "8px",
    color: entry.color,
  });

  if (active && payload && payload.length) {
    if (graphType === "line") {
      if (!hoveredItem) return null;
      let sortedPayload = uniqBy(payload, "name");
      sortedPayload = sortedPayload.filter((test) => test?.value === hoveredItem?.data?.y);
      return (
        <div className="EnvAnalyticsGraph__GraphTooltip--Container">
          <div className="EnvAnalyticsGraph__GraphTooltipHeader">
            <div>{moment(label).format("DD MMM, YYYY")}</div>
          </div>
          <div className="EnvAnalyticsGraph-GraphTooltipDivider" />
          <div className="EnvAnalyticsGraph__GraphTooltipFooter">
            {orderBy(sortedPayload, ["value"], ["desc"]).map((entry) => (
              <div
                key={entry.name}
                className="EnvAnalyticsGraph__GraphTooltipFooter--Table"
                style={Env_analyticsGraphTooltipFooter_Table}
              >
                <span
                  style={Env_analyticsGraphTooltipTest(entry)}
                  className="EnvAnalyticsGraph-GraphTooltipTest"
                >
                  {entry.name}
                </span>
                <span
                  style={{ color: entry.color }}
                  className="EnvAnalyticsGraph__GraphTooltipValue"
                >
                  {entry.value}
                </span>
              </div>
            ))}
            {/* <div style={Env_analyticsGraphTooltipFooter_Total}>
              <span>Total</span>
              <span>{payload[0].payload.total || 0}</span>
            </div> */}
          </div>
        </div>
      );
    }
    if (graphType === "bar") {
      if (!hoveredItem) return null;
      const entry = payload[0].payload;
      const tooltipColor = colorsAndIconsArray.filter((item) => item?.borderColor === entry.color)?.[0].hoverColor;
      const Env_analyticsGraphTooltipContainer = {
        minWidth: "150px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        backgroundColor: tooltipColor,
        opacity: 1,
      };
      const Env_analyticsGraphTooltipBar_Footer = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      };
      return (
        <div
          className="EnvAnalyticsGraph__GraphTooltip--Container EnvAnalyticsGraph__TooltipContainer--Text"
          style={Env_analyticsGraphTooltipContainer}
        >
          <div className="EnvAnalyticsGraph__GraphTooltipHeader">
            <div className="EnvAnalyticsGraph__TooltipContainer--Text">{entry.test_name}</div>
          </div>
          <div className="EnvAnalyticsGraph-GraphTooltipDivider" />
          <div
            style={Env_analyticsGraphTooltipBar_Footer}
            className="EnvAnalyticsGraph__GraphTooltipBar--Footer"
          >
            <div>Total</div>
            <div>{entry.total}</div>
          </div>
        </div>
      );
    }
  }
  return null;
});

export default GraphTooltipRender;

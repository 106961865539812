import React from "react";
import TemplateBuilderDroppable from "../../DragAndDrop/TemplateBuilderDroppable";
import SectionInputDraggable from "../../DragAndDrop/SectionInputDraggable";
import StatementSignatureDraggable from "../../DragAndDrop/StatementSignatureDraggable";
import "./StatementCardContent.css";

export default function StatementCardContent(props) {
  const {
    handleBuilderFile,
    inputs,
    sectionIndex,
    setSectionInputs,
  } = props;

  const deleteInput = (index) => {
    const inputsObject = [...inputs];
    inputsObject.splice(index, 1);
    setSectionInputs(sectionIndex, inputsObject, true, null, false);
  };

  /**
   * Update section's inputs object with DOM node of input
   * @param {Number} index index of input in section
   * @param {HTMLElement} input
   */
  const setTextBoxRef = (index, input) => {
    const inputsObject = [...inputs];
    inputsObject[index].ref = input;
    setSectionInputs(sectionIndex, inputsObject, true, null, false);
  };

  const getDroppableStyle = (snapshot) => ({
    background: snapshot.isDraggingOver ? "#dff5fd" : "unset",
  });

  return (
    <div className="TemplateBuilder__StatementCard">
      <TemplateBuilderDroppable
        droppableId={`fixed_${sectionIndex}__statement`}
        className="StatementCard__Droppable"
        getDroppableStyle={getDroppableStyle}
      >
        {inputs.map((input, index) => {
          if (input.isSignature) {
            return (
              <StatementSignatureDraggable
                key="statement_signature"
                index={index}
                handleBuilderFile={handleBuilderFile}
                inputs={inputs}
                sectionIndex={sectionIndex}
                setSectionInputs={setSectionInputs}
              />
            );
          }
          return (
            <SectionInputDraggable
              key={input.id}
              input={input}
              index={index}
              deleteInput={deleteInput}
              setTextBoxRef={setTextBoxRef}
            />
          );
        })}
      </TemplateBuilderDroppable>
    </div>
  );
}

import React from "react";

export default function CreateSamplingEventModalTabs({
  tabs = [],
  selectedTab,
  onTabChange,
  disableSwitchFromTab = false,
}) {
  const enabledTabsIndex = tabs.indexOf(selectedTab);

  return (
    <div className="CreateSamplingEventModal__ModalTabs">
      {tabs.map((tab, i) => (
        <div
          key={tab}
          className={`CreateSamplingEventModal__ModalTabItem ${i <= enabledTabsIndex ? "CreateSamplingEventModal__ModalTabItem--selected" : ""}`}
          onClick={disableSwitchFromTab ? null : () => onTabChange(tab)}
        >
          <div className="CreateSamplingEventModal__ModalTabLine" />
          <p className="CreateSamplingEventModal__ModalTabTitle">{tab}</p>
        </div>
      ))}
    </div>
  );
}

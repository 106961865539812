// Map calculations
export const calculateTransformOnImageLoad = (containerRef, imageRef, imageWidth, imageHeight, selectedLocationID) => {
  let imageInfo = null;
  let transformInfo = null;
  if (!imageWidth) {
    // console.log("loading");
    const { width: wrapperWidth, height: wrapperHeight } = containerRef.current.getBoundingClientRect();
    const { width: _imageWidth, height: _imageHeight } = imageRef.current.getBoundingClientRect();
    // console.log(imageWidth, imageHeight);
    imageInfo = { imageWidth: _imageWidth, imageHeight: _imageHeight };
    const scale1 = wrapperWidth / _imageWidth;
    const scale2 = wrapperHeight / _imageHeight;

    if (scale1 > scale2) {
      transformInfo = {
        initialX: (wrapperWidth - _imageWidth * scale2) / 2, initialY: 0, minScale: scale2, scale: scale2,
      };
    } else {
      transformInfo = {
        initialX: 0, initialY: (wrapperHeight - _imageHeight * scale1) / 2, minScale: scale1, scale: scale1,
      };
    }
  } else if (!selectedLocationID) {
    // console.log("transforming");
    const { width: wrapperWidth, height: wrapperHeight } = containerRef.current.getBoundingClientRect();
    const scale1 = wrapperWidth / imageWidth;
    const scale2 = wrapperHeight / imageHeight;

    if (scale1 > scale2) {
      transformInfo = {
        initialX: (wrapperWidth - imageWidth * scale2) / 2, initialY: 0, minScale: scale2, scale: scale2,
      };
    } else {
      transformInfo = {
        initialX: 0, initialY: (wrapperHeight - imageHeight * scale1) / 2, minScale: scale1, scale: scale1,
      };
    }
  }
  return { imageInfo, transformInfo };
};

const getComponentsSizes = (wrapperRef, containerRef, scale) => {
  const wrapperComponent = wrapperRef.current;
  const contentComponent = containerRef.current;
  const wrapperWidth = wrapperComponent.offsetWidth;
  const wrapperHeight = wrapperComponent.offsetHeight;
  const contentWidth = contentComponent.offsetWidth;
  const contentHeight = contentComponent.offsetHeight;
  const newContentWidth = contentWidth * scale;
  const newContentHeight = contentHeight * scale;
  const newDiffWidth = wrapperWidth - newContentWidth;
  const newDiffHeight = wrapperHeight - newContentHeight;
  return {
    wrapperWidth,
    wrapperHeight,
    newContentWidth,
    newDiffWidth,
    newContentHeight,
    newDiffHeight,
  };
};
const getBounds = (wrapperRef, imageRef, scale) => {
  const {
    wrapperWidth,
    wrapperHeight,
    newContentWidth,
    newDiffWidth,
    newContentHeight,
    newDiffHeight,
  } = getComponentsSizes(wrapperRef, imageRef, scale);
  const scaleWidthFactor = wrapperWidth > newContentWidth
    ? newDiffWidth * (0.5)
    : 0;
  const scaleHeightFactor = wrapperHeight > newContentHeight
    ? newDiffHeight * (0.5)
    : 0;
  const minPositionX = wrapperWidth - newContentWidth - scaleWidthFactor;
  const maxPositionX = scaleWidthFactor;
  const minPositionY = wrapperHeight - newContentHeight - scaleHeightFactor;
  const maxPositionY = scaleHeightFactor;
  return {
    minPositionX, maxPositionX, minPositionY, maxPositionY,
  };
};
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const boundLimiter = (wrapperRef, imageRef, scale, minScale, positionX, positionY, maxScale = 8) => {
  const boundedScale = clamp(scale, minScale, maxScale);
  const {
    minPositionX, maxPositionX, minPositionY, maxPositionY,
  } = getBounds(wrapperRef, imageRef, boundedScale);
  // console.log("X info", minPositionX, maxPositionX, positionX);
  // console.log("Y info", minPositionY, maxPositionY, positionY);
  const boundedX = clamp(positionX, minPositionX, maxPositionX);
  const boundedY = clamp(positionY, minPositionY, maxPositionY);
  return { boundedX, boundedY, boundedScale };
};

export const zoomToQuadrant = (wrapperRef, quadrantSelected, mapComponentRef) => {
  // console.log("zoom to quad");
  const image = wrapperRef.current.instance.contentComponent.firstChild;
  const { imageHeight, imageWidth } = { imageHeight: image.clientHeight, imageWidth: image.clientWidth };
  // console.log(wrapperRef.current, wrapperRef.current.instance.contentComponent, image);

  const { initialScale, initialPositionX, initialPositionY } = wrapperRef.current.instance.props;

  const newPos = { x: null, y: null };
  const newScale = initialScale * 2; // zoom for quadrant

  if (quadrantSelected === 0) {
    // console.log("quad 0");
    newPos.x = initialPositionX; // set to initial position
    newPos.y = initialPositionY;
  } else if (quadrantSelected === 3) {
    // console.log("quad 3");
    newPos.x = initialPositionX; // pan to correct quadrant
    newPos.y = initialPositionY - imageHeight * initialScale;
  } else if (quadrantSelected === 1) {
    // console.log("quad 1");
    newPos.x = initialPositionX - imageWidth * initialScale; // pan to correct quadrant
    newPos.y = initialPositionY;
  } else {
    // console.log("quad 2");
    newPos.x = initialPositionX - imageWidth * initialScale;
    newPos.y = initialPositionY - imageHeight * initialScale;
  }
  const setTransform = wrapperRef.current.setTransform;
  setTransform(newPos.x, newPos.y, newScale);
  mapComponentRef.current.updateZoom(newScale / initialScale);
};

export const resetZoom = (wrapperRef, mapComponentRef) => {
  // zoom out to 100%
  // console.log("reset zoom");
  if (wrapperRef.current) {
    const resetTransform = wrapperRef.current.resetTransform;
    resetTransform();
    mapComponentRef.current.updateZoom(1);
  }
};

import React, {
  useEffect, useState, useRef, useContext,
} from "react";

import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import StyledButton from "../../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../../Common/UIComponents/StyledTooltip";
import SampleSubmissionContext from "../../SampleSubmissionContext";
import { CHAR_LIMITS } from "../Constant";
import ErrorCheckingAutocompleteInput from "../ReusableComponents/ErrorCheckingAutocomplete";
import MaxCharacterCount from "../ReusableComponents/MaxCharacterCount";

import { getCustomerAddress, saveDraft, getDropdownSuggestionsAndTests } from "../../../../actions/sampleSubmission";

import { ReactComponent as Arrow } from "../../../../assets/images/sample-submission/blueRightArrow.svg";
import { ReactComponent as ExclamationIcon } from "../../../../assets/images/sample-submission/exclamationIcon.svg";

import "./SubmissionDetailsAddressPage.css";

export default function SubmissionDetailsAddressPage({
  handleGoBackToMainPage,
  submissionDetails,
  setSubmissionDetails,
  setShowAddressPage,
  customerDetails,
  setCustomerDetails,
  sampleCategory,
  setSubmissionNameNav,
}) {
  const [customerAddress, setCustomerAddress] = useState({});
  const [submissionName, setSubmissionName] = useState(submissionDetails.submissionName || "");
  const [poNumber, setPONumber] = useState(submissionDetails.poNumber || "");
  const [customerIDInternal, setCustomerIDInternal] = useState(customerDetails.customerID || "");
  const [submittedBy, setSubmittedBy] = useState(customerDetails.submittedBy || "");
  const [userEmail, setUserEmail] = useState(customerDetails.userEmail || "");
  const submissionNameRef = useRef();
  const customerIDRef = useRef();
  const poRef = useRef();
  const [poOptions, setPoOptions] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [invalidCharsSet, setInvalidCharsSet] = useState(new Set());
  const { isLoggedOut, thirdParty } = useContext(SampleSubmissionContext);

  async function apiGetCustomerAddress(customer_id) {
    const result = await getCustomerAddress(customer_id, sampleCategory);
    if (result.success) {
      const addressArr = [];
      if (result.address.address_1) {
        addressArr.push(result.address.address_1);
      }
      if (result.address.address_2) {
        addressArr.push(result.address.address_2);
      }
      result.address.address_concat = addressArr.join(", ");
      setCustomerAddress(result.address);
      if (isLoggedOut && !thirdParty) {
        setDisableButton(false);
      }
    } else {
      setCustomerAddress({});
      if (isLoggedOut && !thirdParty) {
        toast.error(result.message);
        setDisableButton(true);
      }
    }
    if (!isLoggedOut || thirdParty) {
      setDisableButton(false);
    }
    return result;
  }

  /**
   * Make an api call to get the po options list.
   */
  async function apiGetPoOptions() {
    const { result } = await getDropdownSuggestionsAndTests({}, null, sampleCategory);
    if (result.po && result.po.length > 0) {
      setPoOptions(result.po.map((item) => ({ value: item })));
    }
  }

  /** componentDidMount - get customer address */
  useEffect(() => {
    if ((!isLoggedOut || thirdParty) || customerIDInternal !== "") {
      apiGetCustomerAddress((isLoggedOut && !thirdParty) ? customerIDInternal : undefined);
      apiGetPoOptions();
    }
  }, []); // eslint-disable-line

  /**
   * When submission details are set in the parent (when editing a draft),
   * set this component's submission name and po number
   */
  useEffect(() => {
    setSubmissionName(submissionDetails.submissionName);
    setSubmissionNameNav(submissionDetails.submissionName);
    setPONumber(submissionDetails.poNumber);
  }, [submissionDetails]); // eslint-disable-line

  /**
   * When user clicks continue, check if submission name filled out.
   * If it is filled out, set submission details in parent and
   * show the sample form page.
   */
  const handleClickContinue = async () => {
    const nameTrimmed = submissionName.trim();
    const poTrimmed = poNumber.trim();
    const customerIDTrimmed = customerIDInternal.trim();
    const inputsWithError = [];

    const submissionNameCharLimitExceeded = nameTrimmed.length > CHAR_LIMITS.submission_name;
    const poCharLimitExceeded = poTrimmed.length > CHAR_LIMITS.po;

    // Handle missing inputs or invalid characters
    if (((isLoggedOut && !thirdParty) && !customerIDTrimmed) || !nameTrimmed || invalidCharsSet.size) {
      if ((isLoggedOut && !thirdParty) && !customerIDTrimmed) {
        toast.error("Customer ID is required");
        customerIDRef.current.classList.add("sample-submission-input-error");
        inputsWithError.push(customerIDRef.current);
      }
      if (!nameTrimmed) {
        toast.error("Submission name is required");
        submissionNameRef.current.classList.add("sample-submission-input-error");
        inputsWithError.push(submissionNameRef.current);
      } else if (invalidCharsSet.size) {
        toast.error("Invalid submission name");
        inputsWithError.push(submissionNameRef.current);
      }
      if (inputsWithError.length > 0) {
        inputsWithError[0].focus();
      }
      return;
    }

    // Handle character limit errors
    if (submissionNameCharLimitExceeded || poCharLimitExceeded) {
      if (submissionNameCharLimitExceeded) {
        toast.error("Submission Name Character Limit Exceeded");
      }
      if (poCharLimitExceeded) {
        toast.error("PO Character Limit Exceeded");
      }
      return;
    }

    // Proceed with submission if no errors
    if ((nameTrimmed !== submissionDetails.submissionName || poTrimmed !== submissionDetails.poNumber)) {
      const { submissionID } = (!isLoggedOut || thirdParty) ? await saveDraft({
        submissionName: nameTrimmed,
        poNumber: poTrimmed,
        submissionID: submissionDetails.submissionID,
        sample_category: sampleCategory,
      }) : { submissionID: undefined };

      /** We need to use the poHasChanged in the SampleDetails component
         * for the handleSetDropdownsAndTests().
         * We use this vaiable to determine if the current selected test list should be overwritten or not.
         * If the current company has a po dropdown options and user changes the po value from draft, we need to overwrite
         *  the selected test list based on the new ssautofill/ response.
        */
      const oldPo = submissionDetails.poNumber ?? poTrimmed;
      const poHasChanged = oldPo !== poTrimmed && poOptions.length > 0;
      setSubmissionDetails({
        submissionName: nameTrimmed,
        poNumber: poTrimmed,
        submissionID: submissionID ?? submissionDetails.submissionID,
        poHasChanged,
      });
    }
    setCustomerDetails({ customerID: customerIDTrimmed, submittedBy, userEmail });
    setShowAddressPage(false);
  };

  /**
   * When user clicks back button, check if submission name is valid.
   * If it is valid and submission name and po have changed from parent,
   * call handleGoBackToMainPage
   */
  const handleClickBackButton = async () => {
    const nameTrimmed = submissionName.trim();
    const poTrimmed = poNumber.trim();
    if (invalidCharsSet.size) {
      toast.error("Invalid submission name");
      submissionNameRef.current.focus();
      return;
    }
    if (nameTrimmed && (nameTrimmed !== submissionDetails.submissionName || poTrimmed !== submissionDetails.poNumber)) {
      await saveDraft({
        submissionName: nameTrimmed,
        poNumber: poTrimmed,
        submissionID: submissionDetails.submissionID,
        sample_category: sampleCategory,
      });
    }
    handleGoBackToMainPage();
  };

  /**
   * On change of submission name input, check if the name is invalid
   * @param {Event} e get value of input from on change event
   */
  const onSubmissionNameChange = (e) => {
    const invalidCharsArr = ["\\/", "\\\\", "\\.json"];
    const invalidRegex = new RegExp(invalidCharsArr.join("|"), "g");
    const name = e.target.value;
    const matches = name.match(invalidRegex);
    if (matches !== null) {
      setInvalidCharsSet(new Set(matches));
      submissionNameRef.current.classList.add("sample-submission-input-error");
    } else {
      setInvalidCharsSet(new Set());
      submissionNameRef.current.classList.remove("sample-submission-input-error");
    }
    setSubmissionName(name);
    setSubmissionNameNav(name);
  };

  /**
   * On change of customer id, update state and remove error border
   * @param {Event} e get value of input from on change event
   */
  const onCustomerIDChange = (e) => {
    customerIDRef.current.classList.remove("sample-submission-input-error");
    setCustomerIDInternal(e.target.value);
    if (!disableButton) {
      setDisableButton(true);
    }
  };

  /**
   * When user clicks away from customerID input, make api call to customer info
   */
  const onCustomerIDBlur = async () => {
    const trimmedCustomerIDInternal = customerIDInternal.trim();
    if (trimmedCustomerIDInternal) {
      const result = await apiGetCustomerAddress(trimmedCustomerIDInternal);
      if (result.success) {
        setSubmittedBy(result.address.contact_name);
        setUserEmail(result.address.email);
        customerIDRef.current.classList.remove("sample-submission-input-error");
        if (document.activeElement !== submissionNameRef.current && document.activeElement !== poRef.current) {
          submissionNameRef.current.focus();
        }
        return;
      }
      if (document.activeElement !== submissionNameRef.current && document.activeElement !== poRef.current) {
        customerIDRef.current.focus();
      }
      customerIDRef.current.classList.add("sample-submission-input-error");
    } else {
      setCustomerAddress({});
    }
    setSubmittedBy("");
    setUserEmail("");
  };

  /** on key down in input, check if key is invalid, if yes, don't let user input key
   * @param {Event} e KeyDown event
   */
  const onKeyDown = (e) => {
    const invalidKeys = ["/", "\\"];
    if (invalidKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="new-submission-pages-col submission-details-column">
        <div className="new-submission-pages-card submission-details">
          <div className="submission-details-container">
            {!isLoggedOut && (
            <Arrow
              role="button"
              tabIndex={0}
              title="back button"
              width="14.67"
              height="14.67"
              onClick={handleClickBackButton}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleClickBackButton(submissionName, poNumber);
                }
              }}
              className="sample-submission-form-back-button SubmissionDetailsAddressForms-back"
            />
            )}
            <div>
              <div className="new-submission-pages-title-container withBlock submission-details-header">
                <div className="sample-submission-title-block" />
                <span>Submission Details</span>
              </div>
              <div className="submission-details-input-boxes">
                {(isLoggedOut && !thirdParty) && (
                <div>
                  <label htmlFor="customer_id">Customer ID *</label>
                  <div className="SubmissionDetails__InputDiv">
                    <input
                      type="text"
                      id="customer_id"
                      value={customerIDInternal}
                      ref={customerIDRef}
                      onChange={onCustomerIDChange}
                      onBlur={onCustomerIDBlur}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          customerIDRef.current.blur();
                        }
                      }}
                      className="submission-address-short-input"
                    />
                  </div>
                </div>
                )}
                <div>
                  <div className="submission-name-input-title">
                    <label htmlFor="submission-name">Submission Name *</label>
                    {invalidCharsSet.size > 0 && (
                    <StyledTooltip
                      title={(
                        <div className="SampleSubmission__InvalidCharsTooltipTitle">
                          <span>Invalid input:</span>
                          {Array.from(invalidCharsSet).map((char, i) => (
                            <li key={i} className="SampleSubmission__InvalidChar">{char}</li>
                          ))}
                        </div>
                    )}
                      placement="right"
                    >
                      <ExclamationIcon width={13} height={13} />
                    </StyledTooltip>
                    )}
                  </div>
                  <div className="SubmissionDetails__InputDiv">
                    <input
                      type="text"
                      id="submission-name"
                      ref={submissionNameRef}
                      value={submissionName}
                      onChange={onSubmissionNameChange}
                      onKeyDown={onKeyDown}
                      className={`submission-address-short-input ${submissionName.length > CHAR_LIMITS.submission_name && "submission-address-short-input--OverLimit"}`}
                      maxLength={CHAR_LIMITS.submission_name}
                    />
                    {CHAR_LIMITS.submission_name && (
                    <MaxCharacterCount
                      charCount={submissionName.length}
                      charLimit={CHAR_LIMITS.submission_name}
                    />
                    )}
                  </div>
                </div>
                <div className="submission-po-select-title">
                  <label htmlFor="PO-no">#PO</label>
                  <div className="SubmissionDetails__InputDiv">
                    <ErrorCheckingAutocompleteInput
                      defaultRef
                      ref={() => poRef}
                      value={poNumber}
                      charLimit={CHAR_LIMITS.po}
                      handleSelectOption={(value) => setPONumber(value)}
                      onChange={(value) => setPONumber(value)}
                      customClassName="submission-address-po-input"
                      customPopupClassName="submission-address-po-input-popup"
                      customArrowClassName="submission-address-po-input-dropdown-arrow"
                      customMaxCharClassName="submission-address-po-input-max-char"
                      suggestions={poOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`new-submission-pages-card address ${isLoggedOut ? "logged-out" : ""}`}>
          <div className="new-submission-pages-title-container withBlock">
            <div className="sample-submission-title-block" />
            <span>Address</span>
          </div>
          <Scrollbar>
            <div className="submission-address-input-boxes">
              <div>
                <label htmlFor="first-name">Name</label>
                <input
                  type="text"
                  id="first-name"
                  value={customerAddress.user_name ?? customerAddress.contact_name ?? ""}
                  className="submission-address-long-input"
                  readOnly
                />
              </div>
              <div>
                <label htmlFor="email-address">Email Address</label>
                <input
                  type="text"
                  id="email-address"
                  value={customerAddress.user_email ?? customerAddress.email ?? ""}
                  className="submission-address-long-input"
                  readOnly
                />
              </div>
              <div>
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  value={customerAddress.company ?? ""}
                  className="submission-address-long-input"
                  readOnly
                />
              </div>
              <div>
                <label htmlFor="street-line-1">Address</label>
                <input
                  id="street-line-1"
                  type="text"
                  value={customerAddress.address_concat ?? ""}
                  className="submission-address-long-input"
                  readOnly
                />
              </div>
              <div>
                <label htmlFor="phone-number">Phone Number</label>
                <input
                  id="phone-number"
                  type="text"
                  value={customerAddress.telephone ?? ""}
                  className="submission-address-long-input"
                  readOnly
                />
              </div>
            </div>
          </Scrollbar>
        </div>
      </div>
      <StyledButton
        type="primary"
        ghost
        className={disableButton ? "new-submission-pages-button continue disable" : "new-submission-pages-button continue"}
        onClick={handleClickContinue}
      >
        <span>Continue</span>
        <Arrow aria-hidden width="13.33" height="13.33" />
      </StyledButton>
    </>
  );
}

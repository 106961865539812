import React from "react";
import { ReactComponent as LoadingDots } from "../../assets/images/common/LoadingDots.svg";
import "./ButtonWithLoading.css";

export default function ButtonWithLoading({
  isLoading,
  defaultText,
  loadingText,
  onClick,
  loadingDotsWidth = 16,
  loadingDotsColor = "#f9f9f9",
  className = "",
  typeIsSubmit,
  disable,
}) {
  return (
    <button
      type={typeIsSubmit ? "submit" : "button"}
      onClick={!isLoading ? onClick : () => {}}
      disabled={isLoading || disable}
      className={`ButtonWithLoading ${isLoading || disable ? "ButtonWithLoading--loading" : ""} ${className}`}
    >
      {
        isLoading ? (
          <>
            {loadingText && <span>{loadingText}</span>}
            <LoadingDots width={loadingDotsWidth} fill={loadingDotsColor} className="ButtonWithLoading__LoadingDots" />
          </>
        ) : (
          <>
            {defaultText && <span>{defaultText}</span>}
          </>
        )
      }
    </button>
  );
}

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Scrollbar from "react-scrollbars-custom";

import DataFieldEditCard from "./DataFieldEditCard";
import PlusIcon from "../../../../../assets/images/product/template/PlusBlue.png";
import ProfileIcon from "../../../../../assets/images/product/template/userProfileLightBlue.png";
import "../styles/createFieldModal.css";

import {
  getDataField,
  manageDataFields,
} from "../../../../../actions/productTemplate";

export default function CreateFieldModalLeft(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [fieldTitleInEdit, setFieldTitleInEdit] = useState("");
  const [hasFields, setHasFields] = useState(false);
  const [fieldsTitlesList, setFieldsTitlesList] = useState([]);

  const { productDataFieldInEdit, setCommonDataFieldInEdit, dataFieldCategory } = props;

  const apiGetDataFields = async () => {
    const response = await getDataField({ category: dataFieldCategory });
    setIsLoading(false);
    if (response && response.success) {
      let fieldsNames = [];
      if (response.result && response.result.length) {
        fieldsNames = response.result;
      }

      setFieldsTitlesList(fieldsNames);
      setHasFields(fieldsNames.length > 0);
    } else {
      toast.error("Failed to get data fields.");
    }
  };

  useEffect(() => {
    apiGetDataFields();
  }, []); // eslint-disable-line

  const resetAfterEdit = () => {
    setFieldTitleInEdit("");
    setIsAdding(false);
    setCommonDataFieldInEdit(false);
  };

  const apiAddDataFieldTitle = async (value) => {
    const params = {
      field_name: value,
      action: "add",
      method: "POST",
      category: dataFieldCategory,
    };
    const response = await manageDataFields(params);
    if (response && response.success) {
      if (response.message === "Success") {
        return true;
      }
      toast.error(response.message);
    } else {
      toast.error("Failed to add data field.");
    }
    return false;
  };

  const apiEditDataFieldTitle = async (prevName, newName) => {
    const params = {
      field_name: newName,
      field_name_pre: prevName,
      action: "edit",
      method: "PATCH",
      category: dataFieldCategory,
    };
    const response = await manageDataFields(params);
    if (response && response.success) {
      if (response.message === "Success") {
        return true;
      }
      toast.error(response.message);
    } else {
      toast.error("Failed to edit data field name.");
    }
    return false;
  };

  const apiDeleteDataFieldTitle = async () => {
    const params = {
      field_name: fieldTitleInEdit,
      field_in_use: "0",
      action: "delete",
      method: "PATCH",
      category: dataFieldCategory,
    };
    const response = await manageDataFields(params);
    if (response && response.success) {
      if (response.message === "Success") {
        return true;
      }
      toast.error(response.message);
    } else {
      toast.error("Failed to delete data field.");
    }
    return false;
  };

  const handleSaveFieldTitle = async (value) => {
    let success = false;
    if (fieldTitleInEdit) {
      if (fieldTitleInEdit !== value) {
        // we're editing existing field
        success = await apiEditDataFieldTitle(fieldTitleInEdit, value);
      } else {
        success = true;
      }
    } else {
      // we're adding new field
      success = await apiAddDataFieldTitle(value);
    }
    if (success) {
      resetAfterEdit();
      apiGetDataFields();
    }
  };

  const handleDeleteFieldTitle = async () => {
    const success = await apiDeleteDataFieldTitle();
    if (success) {
      resetAfterEdit();
      apiGetDataFields();
    }
  };

  const handleAddNewDataField = () => {
    if (productDataFieldInEdit) {
      toast.error("Please save the current change first");
    } else {
      setIsAdding(true);
      setCommonDataFieldInEdit(true);
    }
  };

  const handleEditDataField = (titleName) => {
    if (productDataFieldInEdit) {
      toast.error("Please save the current change first");
    } else {
      setFieldTitleInEdit(titleName);
      setCommonDataFieldInEdit(true);
    }
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="createFieldModalBodyLeft">
      {!isAdding && !fieldTitleInEdit && (
      <div className="leftSideHomeDiv" style={{ justifyContent: hasFields ? "unset" : "space-between" }}>

        <div className="leftSideHomeDivHeader">
          <div className="leftSideHomeDivTitle">
            <img src={ProfileIcon} alt="ProfileIcon" />
            <span> All data fields </span>
          </div>
          <span className="leftSideHomeDivBodySpan"> Data fields created here will be added to all products automatically </span>
        </div>

        {!hasFields && (
          <div className="addingDivContainer">
            <div className="leftSideAddingDivLarge" onClick={handleAddNewDataField}>
              <img src={PlusIcon} alt="PlusIcon" className="leftSidePlusIcon" />
              <span className="leftSideAddingSpan"> Add new data field </span>
            </div>
          </div>
        )}
        {hasFields && (
          <Scrollbar
            id="createFieldScrollBar"
            disableTrackYWidthCompensation
            disableTracksWidthCompensation
            style={{
              height: "100%",
              marginTop: "32px",
              width: "calc(100% + 20px)",
            }}
          >
            <div className="addingDivContainerSmall">
              <div className="leftSideAddingDivSmall" onClick={handleAddNewDataField} style={{ width: "139px" }}>
                <img src={PlusIcon} alt="PlusIconSmall" className="leftSidePlusIconSmall" />
                <span> Add new data field </span>
              </div>
              {fieldsTitlesList && fieldsTitlesList.map((fieldName, i) => (
                <div
                  className="leftSideTitleDivSmall"
                  key={i}
                  onClick={() => handleEditDataField(fieldName)}
                  style={{ width: "fit-content", paddingLeft: "10px", paddingRight: "10px" }}
                >
                  {fieldName}
                </div>
              ))}
            </div>
          </Scrollbar>
        )}

        <div className="fillerDiv" />
      </div>
      )}

      {(isAdding || fieldTitleInEdit) && (
      <div className="addingFieldContainer">
        <span className="addingFieldHeaderSpan">
          { isAdding ? "Create new data field" : "Edit" }
        </span>
        <DataFieldEditCard
          isCommonDataField
          prevTitleValue={fieldTitleInEdit}
          prevDetailsValue="Details vary per product and can only be edited inside each product"
          onSaveClick={handleSaveFieldTitle}
          onCancelClick={resetAfterEdit}
          onDeleteClick={handleDeleteFieldTitle}
        />
      </div>
      )}
    </div>
  );
}

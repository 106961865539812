import React from "react";
import Scrollbar from "react-scrollbars-custom";
import Person from "../../../../../../assets/images/sample-submission/Person.svg";
import pumpkinIcon from "../../../../../../assets/images/environment/pumpkin.gif";
import { ReactComponent as LoadingDots } from "../../../../../../assets/images/common/LoadingDots.svg";
import { ReactComponent as GarbageCan } from "../../../../../../assets/images/sample-submission/hoveredGarbageCan.svg";
import NoResults from "../../../../../Common/NoResultsGIF";
import "./InvitedUsers.css";

export default function InvitedUsers({ invitedUsers, loadingData, handleDeleteModal }) {
  return (
    <div className="InviteModal__SharedListContainer">
      <div className="InviteModal__Title">Invited Users</div>
      <div className="InviteModal__SharedList">
        {invitedUsers.length === 0 && (
        <div className="InviteModal__NoResults">
          {!loadingData
            ? <NoResults image={pumpkinIcon} message="No users found!" className="InviteModal__NoResults__GIF" />
            : <LoadingDots width={30} height={30} fill="#C4D2DF" />}
        </div>
        )}
        {invitedUsers.length > 0
      && (
      <Scrollbar id="InviteModal_Scrollbar">
        <div className="InviteModal_ListContainer">
          {invitedUsers.map((user) => (
            <div key={user.user_email} className="InviteModal__EmailContainer">
              <div className="InviteModal__Email">
                <img
                  src={Person}
                  alt="person"
                  className="InviteModal__PersonIcon"
                />
                <span>{user.user_email}</span>
              </div>
              <GarbageCan onClick={() => handleDeleteModal(true, user.user_email)} className="InviteModal__DeleteIcon" width="16" height="16">
                <title>delete icon</title>
              </GarbageCan>
            </div>
          )) }
        </div>
      </Scrollbar>
      )}
      </div>
    </div>
  );
}

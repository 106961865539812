import React from "react";
import { Select } from "antd";
import { ReactComponent as ChevronDown } from "../../../../../assets/images/common/ChevronDown.svg";
import "./SpecManagerSelect.css";

export default function SpecManagerSelect({
  options, // [{value, label, icon}, ...] if label is not given, value is used as the displayed label
  optionSelected,
  className = "",
  popupClassName = "",
  width,
  onSelect,
  placeholder,
}) {
  const selectOptions = options.map((item) => (
    {
      value: typeof item === "string" ? item : item.value,
      label: (
        <>
          {typeof item === "string" ? null : item.icon || null}
          {typeof item === "string" ? item : (item.label ?? item.value)}
        </>),
    }
  ));
  return (
    <Select
      listItemHeight={32}
      listHeight={160}
      bordered={false}
      placeholder={placeholder}
      value={optionSelected}
      options={selectOptions}
      style={{ width }}
      onSelect={onSelect}
      suffixIcon={<ChevronDown className="SpecManagerSelect__Chevron" />}
      className={`SpecManagerSelect ${className}`}
      popupClassName={`SpecManagerSelect__Dropdown ${popupClassName}`}
      getPopupContainer={(trigger) => trigger.parentElement}
    />
  );
}

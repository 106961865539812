const checkStatusEditable = (submissionStatus, sampleSpecFlag = null, submissionEventId = "") => {
  // If submissionEventId is provided and not an empty string, disable actions
  if (submissionEventId) {
    return false;
  }

  const submissionEditable = submissionStatus && parseInt(submissionStatus) === -1;
  if (sampleSpecFlag !== null) {
    const sampleEditable = sampleSpecFlag && (parseInt(sampleSpecFlag) <= -1 && parseInt(sampleSpecFlag) >= -2);
    return submissionEditable && sampleEditable;
  }
  return submissionEditable;
};

export default checkStatusEditable;

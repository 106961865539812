import React from "react";
import { Draggable } from "react-beautiful-dnd";
import BuilderElement from "../BuilderComponents/BuilderElement";

export default function BuilderElementDraggable(props) {
  const {
    id,
    type,
    value,
    flag,
    jsonField,
    disable = false,
    index,
  } = props;
  const draggableId = `${type}${flag ? `_${flag}` : ""}${id ? `_${id}` : ""}`;
  return (
    <Draggable
      key={id ?? type}
      draggableId={draggableId}
      index={index}
      isDragDisabled={disable}
    >
      {(provided, snapshot) => (
        <>
          <BuilderElement
            id={draggableId}
            provided={provided}
            snapshot={snapshot}
            type={type}
            value={value}
            jsonField={jsonField}
            flag={flag}
            disable={disable}
          />
          {snapshot.isDragging && (
          <BuilderElement
            provided={provided}
            snapshot={snapshot}
            type={type}
            value={value}
            jsonField={jsonField}
            flag={flag}
            disable
            isClone
          />
          )}
        </>
      )}
    </Draggable>
  );
}

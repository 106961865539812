import React, { useRef, useState, useEffect } from "react";
import ReactCrop from "react-image-crop";
import {
  canvasPreviewCrop,
} from "./ImageEditHelper";
import { ReactComponent as GarbageBinIcon } from "../../../assets/images/environment/garbageBin.svg";
import "./UploadImage.css";
import "./UploadImageEditor.css";
import "react-image-crop/dist/ReactCrop.css";

export default function UploadImageEditor(props) {
  const {
    previewEditedImage,
    cropPreviewSrc,
    handleDeleteImage,
    scale,
    imageRef,
    canvasRef,
    rotatingInProcess,
    setRotatingInProcess,
    initialCrop,
    cropObjectRef,
  } = props;

  const [imageDims, setImageDims] = useState(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const uploadImageContainerRef = useRef();

  /**
   * Scale the image to fit inside the UploadImageContainer div while maintaining aspect ratio
   * @param {Number} _imgWidth width of image in pixels
   * @param {Number} _imgHeight height of image in pixels
   * @returns {Object} { adjustedHeight, adjustedWidth }
   */
  const handleImageFitToContainer = (_imgWidth, _imgHeight) => {
    let imgWidth = _imgWidth;
    let imgHeight = _imgHeight;
    if (!_imgHeight || !_imgWidth) {
      if (imageRef.current) {
        const {
          width: imgWidthFromRef, height: imgHeightFromRef,
        } = imageRef.current.getBoundingClientRect();
        imgWidth = imgWidthFromRef;
        imgHeight = imgHeightFromRef;
      }
    }
    let adjustedWidth;
    let adjustedHeight;
    if (uploadImageContainerRef.current) {
      const {
        width: containerWidth,
        height: containerHeight,
      } = uploadImageContainerRef.current.getBoundingClientRect();
      const widthScale = containerWidth / imgWidth;
      const heightScale = containerHeight / imgHeight;
      const scaleToUse = Math.min(widthScale, heightScale);
      adjustedWidth = imgWidth * scaleToUse;
      adjustedHeight = imgHeight * scaleToUse;
    }
    setImageDims({ width: adjustedWidth, height: adjustedHeight });
    return { adjustedWidth, adjustedHeight };
  };

  /** Use the current crop parameters, imageRef, and canvasRef to update the canvas preview */
  const updateCanvas = () => {
    if (
      completedCrop?.width
      && completedCrop?.height
      && imageRef.current
      && canvasRef.current
    ) {
      canvasPreviewCrop(
        imageRef.current,
        canvasRef.current,
        completedCrop,
        scale,
      );
    }
  };

  /**
   * On image load, resize the image to fit the container.
   * Set crop to the width and height of the image.
   * @param {Image Load Event} e
   */
  const onImageLoad = (e) => {
    const { width: imgWidth, height: imgHeight } = e.currentTarget;
    handleImageFitToContainer(imgWidth, imgHeight);
    if (initialCrop) {
      setCrop({ ...initialCrop });
      cropObjectRef.current = { ...initialCrop };
    } else {
      setCrop({
        unit: "%",
        x: 0,
        y: 0,
        width: 100,
        height: 100,
      });
      cropObjectRef.current = {
        unit: "%",
        x: 0,
        y: 0,
        width: 100,
        height: 100,
      };
    }

    if (rotatingInProcess) {
      setRotatingInProcess(false);
    }
  };

  /**
   * When window resizes, update image dimensions and crop box.
   */
  useEffect(() => {
    const handleResize = () => {
      handleImageFitToContainer();
      updateCanvas();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  /** When crop parameters and/or scale change, update the canvas */
  useEffect(
    () => {
      updateCanvas();
      cropObjectRef.current = { ...completedCrop };
    },
    [completedCrop, scale], // eslint-disable-line
  );

  /** Set image style, if imageDims null let image take up its natural size so resize can be calculated */
  const imageStyle = { transform: `scale(${scale})` };
  if (imageDims !== null) {
    const { width, height } = imageDims;
    imageStyle.width = `${width}px`;
    imageStyle.height = `${height}px`;
  }

  return (
    <>
      <div
        className="SampleSubmission__UploadImagePreviewContainer"
        ref={uploadImageContainerRef}
      >
        {cropPreviewSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(_, percentCrop) => setCompletedCrop(percentCrop)}
          className={previewEditedImage ? "hideReactCrop" : ""}
          keepSelection
        >
          <img
            src={cropPreviewSrc}
            ref={imageRef}
            onLoad={onImageLoad}
            alt="selected file preview"
            style={imageStyle}
            className="SampleSubmission__ReactCropImage"
          />
        </ReactCrop>
        )}
        {completedCrop && (
        <div className={`SampleSubmission__CropPreviewContainer ${previewEditedImage ? "" : "hideCropPreviewContainer"}`}>
          {!!completedCrop && (
          <canvas
            ref={canvasRef}
            style={{
              width: (completedCrop.width / 100) * (imageDims.width),
              height: (completedCrop.height / 100) * (imageDims.height),
            }}
          />
          )}
        </div>
        )}
      </div>
      <div
        onClick={() => {
          setCrop(undefined);
          handleDeleteImage();
        }}
        className="SampleSubmission__UploadImageDelete"
      >
        <GarbageBinIcon />
      </div>
    </>
  );
}

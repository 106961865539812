import React, { Component } from "react";
import StatusPage from "./StatusPage";

class SignUpSuccessController extends Component {
  render() {
    const { history, path } = this.props;

    if (path === "/signup/success") {
      return (
        <StatusPage
          history={history}
          status="success"
          title="Sign Up Done"
          subtitle="Thank you! Sign Up has been done sucessfully. You can start exploring."
          showLoginButton
        />
      );
    } if (path === "/signup/invite/success") {
      return (
        <StatusPage
          history={history}
          status="success"
          title="Invite Email Sent"
          subtitle="Thank you! Customer has been successfully invited."
          showLoginButton
        />
      );
    }
    return null;
  }
}

export default SignUpSuccessController;

/* eslint-disable react/state-in-constructor */
import React, { Component } from "react";
import moment from "moment";
import Scrollbar from "react-scrollbars-custom";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import Tag from "./Tag";
import "./ProductCard.css";
import editPencilLight from "../../../assets/images/index/editPencilBlue.png";
import editPencilDark from "../../../assets/images/index/editPencilMidnight.png";
import deleteIcon from "../../../assets/images/index/deleteIconMidnight.png";
import dotsOptionIconDark from "../../../assets/images/index/threeDotsIconMidnight.png";
import dotsOptionIcon from "../../../assets/images/index/threeDotsIconWhite.png";
import lightBlueAddIcon from "../../../assets/images/index/addCircleIconBlue.png";
import darkBlueAddIcon from "../../../assets/images/index/addCircleIconMidnight.png";
import checkIconWhite from "../../../assets/images/index/checkIconWhite.png";
import checkIconMidnight from "../../../assets/images/index/checkIconMidnight.png";

class ProductCard extends Component {
  state = {
    showOptions: false,
    tagInput: "",
    editTag: "",
    editTagIndex: -1,
    tagsSet: null,
    addTagIcon: lightBlueAddIcon,
    menuIcon: dotsOptionIcon,
    editPencilIcon: editPencilLight,
    saveIcon: checkIconWhite,
    saveReminder: false,
    scrollBarHeight: "150px",
  }

  addTagInputRef = React.createRef();

  isEditingTags = () => {
    const { item, activeTab, editingTagsProductId } = this.props;
    const productId = activeTab === "0" ? item.product_id : item.index_card_id;
    return editingTagsProductId === productId;
  }

  handleProductNameClick = (e) => {
    e.stopPropagation();
    const editingTags = this.isEditingTags();
    const {
      readOnly, editingTagsProductId, item, handleProductNameClick,
    } = this.props;
    if (editingTags) {
      this.setState({ saveReminder: true });
    } else if (!readOnly && !editingTagsProductId) {
      handleProductNameClick(e, item);
    }
  }

  onEditTagsButtonClick = (e) => {
    e.stopPropagation();
    const {
      readOnly, editingTagsProductId, item, itemIndex, onEditTagsClick,
    } = this.props;
    if (!readOnly && !editingTagsProductId) {
      const productId = item.product_id || item.index_card_id;
      const tagsSet = new Set(item.tags.map((tag) => tag.tag.toLowerCase()));
      this.setState({ tagsSet, editPencilIcon: editPencilLight });
      onEditTagsClick(itemIndex, productId);
    }
  }

  onAddIconClick = () => {
    const { editTagIndex } = this.props;
    if (this.addTagInputRef.current.value.trim()) {
      this.handleAddTag();
    } else if (editTagIndex === -1) {
      toast.error("Tag cannot be empty");
    }
  }

  handleDeleteTag = async (tag) => {
    const { item, handleDeleteTag } = this.props;
    const productId = item.product_id || item.index_card_id;
    const success = await handleDeleteTag(tag, productId);
    if (success) {
      const { tagsSet } = this.state;
      tagsSet.delete(tag.toLowerCase());
      this.setState(tagsSet);
      this.handleCancelEditTag();
      return true;
    }
    return false;
  }

  handleCancelEditTag = () => {
    this.setState({ tagInput: "", editTagIndex: -1, editTag: "" });
  }

  handleOptionsMenu = (e) => {
    e.stopPropagation();
    const { showOptions } = this.state;
    const {
      readOnly, editingTagsProductId,
    } = this.props;
    if (!readOnly && !editingTagsProductId) {
      this.setState({ showOptions: !showOptions, menuIcon: dotsOptionIcon });
    }
  }

  handleKeyPressAddTag = (e) => {
    if (e.charCode === 13) {
      this.handleAddTag();
    }
  }

  handleAddTag = async () => {
    const {
      tagsSet, editTag, editTagIndex, tagInput,
    } = this.state;
    const { item, handleAddTag } = this.props;
    const productId = item.product_id || item.index_card_id;
    let newTag = tagInput.trim().toLowerCase();
    if (!newTag) {
      toast.error("Tag cannot be empty");
      return false;
    }
    if (tagsSet.has(newTag) && editTagIndex === -1) {
      toast.error("Please add a unique tag.");
      return false;
    }
    if (editTag && editTag.toLowerCase() === newTag) {
      this.handleCancelEditTag();
      return true;
    }
    newTag = newTag[0].toUpperCase() + newTag.substr(1);
    // update tag in DOM and backend
    const success = await handleAddTag(newTag, productId, editTag);
    if (success) {
      if (editTag) {
        tagsSet.delete(editTag.toLowerCase());
      }
      tagsSet.add(newTag.toLowerCase());
      this.setState({ tagsSet });
      // this function is not triggered by doubleclick on another tag
      const { editTagIndex: currentEditTagIndex } = this.state;
      if (editTagIndex === currentEditTagIndex) {
        // resetting the values
        this.addTagInputRef.current.value = "";
        this.handleCancelEditTag();
      }
      return true;
    }
    toast.error("Fail to add or update the tag.");
    return false;
  }

  handleEditTag = (editTagIndex, editTag) => {
    this.setState({ editTag, editTagIndex, tagInput: editTag });
    this.addTagInputRef.current.value = "";
  }

  handleTextTagChange = (tagInput) => {
    this.setState({ tagInput });
  }

  onSaveClick = async () => {
    const { tagInput } = this.state;
    const { onCancelEditTagsClick } = this.props;
    this.setState({ saveReminder: false });

    if (tagInput.trim()) {
      const success = await this.handleAddTag();
      if (!success) {
        return;
      }
    }
    this.addTagInputRef.current.value = "";
    this.handleCancelEditTag();
    this.setState({ tagsSet: null, saveIcon: checkIconWhite });
    onCancelEditTagsClick();
  }

  render() {
    const {
      editPencilIcon,
      addTagIcon,
      saveIcon,
      tagInput,
      editTag,
      editTagIndex,
      menuIcon,
      showOptions,
      saveReminder,
      scrollBarHeight,
    } = this.state;

    const {
      activeTab,
      item,
      itemIndex,
      isMostRight,
      onEdit,
      editingTagsProductId,
      tagsEditing,
      onToggleModal,
      linkReportFields,
    } = this.props;

    const isProduct = activeTab === "0";
    const productId = item.product_id || item.index_card_id;
    const productName = item.product_name || item.card_name;
    const editingTags = editingTagsProductId === productId;
    const keys = isProduct ? linkReportFields : [{ json_field: "created_by", title_field: "Created by" }];

    const handleClickAway = () => {
      this.setState({ saveReminder: true });
    };

    return (
      <OutsideClickHandler onOutsideClick={editingTags ? handleClickAway : () => {}}>
        <div className="product-card" onClick={editingTags ? () => this.setState({ saveReminder: false }) : () => {}}>
          <div className="product-card-title">
            <div className="product-card__Title--Row">
              <div className="product-card__Title--Col">
                <div
                  className="productNameHover"
                  onClick={this.handleProductNameClick}
                >
                  {productName}
                </div>
              </div>
              {editingTags
                ? (
                  <div className="product-card__Title__EditingTags">
                    <img
                      className="product-card__Title__EditingTags-saveIcon"
                      src={saveIcon}
                      alt="saveIcon"
                      onClick={() => {
                        if (editTag === "") {
                          this.onSaveClick();
                        }
                      }}
                      onMouseEnter={() => this.setState({ saveIcon: checkIconMidnight })}
                      onFocus={() => this.setState({ saveIcon: checkIconMidnight })}
                      onMouseOut={() => this.setState({ saveIcon: checkIconWhite })}
                      onBlur={() => this.setState({ saveIcon: checkIconWhite })}
                    />
                    {saveReminder
                    && (
                    <div className={isMostRight ? "saveReminderLeft" : "saveReminderRight"}>
                      <div className="triangleForReminder" />
                      <div className="saveReminder">You have unsaved changes, please save the card before proceeding</div>
                    </div>
                    )}
                  </div>
                )
                : (
                  <div className="product-card__Title">
                    {showOptions
                      ? (
                        <OutsideClickHandler onOutsideClick={() => this.setState({ showOptions: false, menuIcon: dotsOptionIcon })}>
                          <div className="drop-down-menu" id="product-card__Title--dropDownMenu" style={{ right: isMostRight ? "-50px" : "-105px" }}>
                            <div
                              className="drop-down-menu-option"
                              onClick={() => {
                                this.setState({ showOptions: false, menuIcon: dotsOptionIcon });
                                onEdit(itemIndex, productId);
                              }}
                            >
                              <img
                                className="product-card__Title--dropDownMenuOption-editIcon"
                                src={editPencilDark}
                                alt="editCardIcon"
                                width={12}
                              />
                              <label>Edit</label>
                            </div>
                            <div
                              className="drop-down-menu-option"
                              onClick={() => {
                                this.setState({ showOptions: false });
                                onToggleModal(itemIndex);
                              }}
                            >
                              <img
                                className="product-card__Title--dropDownMenuOption-deleteIcon"
                                src={deleteIcon}
                                alt="deleteCardIcon"
                                width={13.3}
                              />
                              <label>Delete</label>
                            </div>
                          </div>
                        </OutsideClickHandler>
                      )
                      : (
                        <img
                          className="product-card__Title--MenuIcon"
                          alt="menuIcon"
                          src={menuIcon}
                          onClick={this.handleOptionsMenu}
                          onMouseEnter={() => this.setState({ menuIcon: dotsOptionIconDark })}
                          onFocus={() => this.setState({ menuIcon: dotsOptionIconDark })}
                          onMouseOut={() => this.setState({ menuIcon: dotsOptionIcon })}
                          onBlur={() => this.setState({ menuIcon: dotsOptionIcon })}
                        />
                      )}
                  </div>
                )}
            </div>
          </div>
          <div className="product-card__CardBody">
            <Scrollbar className="ProductCard__LinkedFieldsScrollbar">
              <div className="ProductCard__LinkedFields">
                {keys.map(({ json_field, title_field }, idx) => (
                  <div className="ProductCard__LinkedFieldRow" key={json_field}>
                    <p>
                      <span className="product-card__Body--itemNum ProductCard__LinkedFieldRow__Field">{`${title_field}: `}</span>
                      {isProduct ? item.item[json_field] : item[json_field]}
                    </p>
                    {idx === 0 && <p className="product-card__Body--createdOn ProductCard__Date">{moment(new Date(item.created_on)).format("MMM DD, YYYY")}</p>}
                  </div>
                ))}
              </div>
            </Scrollbar>
            <div className="ProductCard__Body__TagsSection">
              <div className="product-card__Body__TagsContainer">
                <p className="product-card__Body--Tags">
                  Tags
                </p>
                {!editingTags
                  ? (
                    <img
                      className="product-card__Body--Tags-EditIcon"
                      src={editPencilIcon}
                      alt="edit"
                      onClick={this.onEditTagsButtonClick}
                      onMouseEnter={() => this.setState({ editPencilIcon: editPencilDark })}
                      onFocus={() => this.setState({ editPencilIcon: editPencilDark })}
                      onMouseOut={() => this.setState({ editPencilIcon: editPencilLight })}
                      onBlur={() => this.setState({ editPencilIcon: editPencilLight })}
                    />
                  )
                  : (
                    <>
                      <input
                        className="product-card__Body--Tags-inputTagName"
                        type="text"
                        placeholder="Enter tag name"
                        id={`editTag${productId}`}
                        onChange={(e) => this.handleTextTagChange(e.target.value)}
                        onKeyPress={this.handleKeyPressAddTag}
                        ref={this.addTagInputRef}
                      />
                      <img
                        className="product-card__Body--Tags-addTagIcon"
                        src={addTagIcon}
                        alt="add"
                        onClick={this.onAddIconClick}
                        onMouseEnter={() => this.setState({ addTagIcon: darkBlueAddIcon })}
                        onMouseLeave={() => this.setState({ addTagIcon: lightBlueAddIcon })}
                      />
                    </>
                  )}
              </div>
              <div className={editingTags ? "" : "readOnlyMode"}>
                <Scrollbar
                  className="product-card__Body--Tags-ScrollBar"
                  style={{
                    height: scrollBarHeight,
                    background: editingTags ? "#F9F9F9" : "transparent",
                  }}
                >
                  {(editingTags && tagsEditing[productId])
                    ? tagsEditing[productId].map((itemTag, tagIndex) => (
                      <Tag
                        key={tagIndex}
                        label={itemTag}
                        isEditing={tagIndex === editTagIndex}
                        tagInput={tagInput}
                        setTagInput={this.handleTextTagChange}
                        handleDoubleClickTag={() => this.handleEditTag(tagIndex, itemTag)}
                        handleKeyPressAddTag={this.handleKeyPressAddTag}
                        handleDeleteTag={() => this.handleDeleteTag(itemTag)}
                        handleTagInputOutsideClick={this.handleAddTag}
                      />
                    ))
                    : item.tags.map((itemTag, tagIndex) => (
                      <Tag
                        key={tagIndex}
                        label={itemTag.tag}
                        isEditing={tagIndex === editTagIndex}
                        tagInput={tagInput}
                        setTagInput={editingTags ? this.handleTextTagChange : () => {}}
                        handleDoubleClickTag={editingTags ? () => this.handleEditTag(tagIndex, itemTag.tag) : () => {}}
                        handleKeyPressAddTag={editingTags ? this.handleKeyPressAddTag : () => {}}
                        handleDeleteTag={editingTags ? (e) => this.handleDeleteTag(itemTag.tag, e) : () => {}}
                        handleTagInputOutsideClick={this.handleAddTag}
                      />
                    ))}
                </Scrollbar>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

export default ProductCard;

import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { calculateWidths } from "./pdfUtils";

export default function TableHeaderSamples({ uniqueTests }) {
  const styles = StyleSheet.create({
    tableHeader: {
      display: "flex",
      flexDirection: "column",
    },
    tableHeaderRow: {
      display: "flex",
      flexDirection: "row",
      color: "#506375",
      textAlign: "center",
      minHeight: "32",
      padding: "0 15 4 15",
      borderLeft: "1 solid #E1E1E1",
      borderRight: "1 solid #E1E1E1",
    },
    rowItem: {
      display: "flex",
      flexDirection: "row",
      width: "15%",
      minHeight: "32",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#C4D2DF",
      borderRadius: "4px",
      padding: "2",
    },
    rowItemSampleID: {
      width: "25%",
    },
    gap: {
      marginRight: "4",
    },
  });

  return (
    <View
      style={styles.tableHeaderRow}
      wrap={false}
      fixed
    >
      <View style={[styles.rowItem, styles.rowItemSampleID, styles.gap, { width: `${calculateWidths(uniqueTests).sampleWidth}%` }]}><Text>Sample ID</Text></View>
      {uniqueTests.filter(({ selected }) => selected).map(({ test }, i, arr) => (
        <View key={i} style={[styles.rowItem, i === arr.length - 1 ? null : styles.gap, { width: `${calculateWidths(uniqueTests).resultWidth}%` }]}>
          <Text>{ test }</Text>
        </View>
      ))}
    </View>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { ReactComponent as UploadIcon } from "../../../../../assets/images/environment/upload.svg";
import "./DragDrop.css";

export default function DragDrop(props) {
  const {
    uploadTarget, getRootProps, getInputProps,
  } = props;

  return (
    <div {...getRootProps({ className: "env-drag-drop" })}>
      <input
        {...getInputProps()}
      />
      <UploadIcon />
      <div className="env-drag-drop-header">
        Drag and drop to upload
        {" "}
        {uploadTarget}
      </div>
      <button type="button" className="drag-drop-browse">Browse</button>
    </div>
  );
}

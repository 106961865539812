import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import OutsideClick from "../../../Report/OutsideClick";

import { testSearch } from "../../../../../actions/productAnalytics";

import magnifyingGlassBlue from "../../../../../assets/images/analytics/magnifyingGlass_midnight.png";
import magnifyingGlassWhite from "../../../../../assets/images/white-magnifying-glass.png";

import "./Search.css";

export default function SearchTests(props) {
  const {
    updateTestList, setLoading, selectedProducts, updateSearch,
  } = props;

  const [search, setSearch] = useState("");
  const [searchBarActive, setSearchBarActive] = useState(false);
  const searchAbortController = useRef(null);

  // API call to get a list of tests based on search and products
  async function getTests() {
    setLoading(true);
    const payload = {
      search,
      products: selectedProducts,
    };

    const newController = new AbortController();
    /** If prev search api still in progress, abort it */
    if (searchAbortController.current) {
      searchAbortController.current.abort();
    }
    searchAbortController.current = newController;

    const response = await testSearch(payload, newController.signal);
    if (newController.signal.aborted) {
      return;
    }
    if (response.success) {
      updateTestList(response.result);
    } else {
      toast.error("Failed to search tests");
      updateTestList([]);
    }
    searchAbortController.current = null;
    setLoading(false);
  }

  // When user hits enter or removes the product
  const searchForTests = (e, type) => {
    if (e.key === "Enter" || (e.key === "Backspace" && e.target.value === "") || type === "clicked") {
      getTests();
      updateSearch(search);
    }
  };

  const toggleSelectSearchBar = (bool) => {
    setSearchBarActive(bool);
  };

  let backgroundColor = "";
  let placeholder = "";
  let icon = magnifyingGlassWhite;
  if (search && search.length > 0) {
    backgroundColor = "#26A1D2";
  } else if (searchBarActive) {
    backgroundColor = "#DEF2FB";
  } else {
    backgroundColor = "#E1E9F1";
    placeholder = "Search a test";
    icon = magnifyingGlassBlue;
  }

  return (
    <OutsideClick
      onCancelEdit={() => toggleSelectSearchBar(false)}
    >
      <div className="Analytics__Search__InputContainer" onClick={() => toggleSelectSearchBar(true)}>
        <input className="Analytics__Search__Input" style={{ borderColor: backgroundColor }} type="text" placeholder={placeholder} onChange={(e) => setSearch(e.target.value)} value={search} onKeyUp={(e) => searchForTests(e)} />
        <div
          className="Analytics__Search__MagnifyingGlassContainer"
          onClick={(e) => searchForTests(e, "clicked")}
          style={{
            backgroundColor,
          }}
        >
          <img src={icon} alt="search" className="Analytics__Search__MagnifyingGlass" />
        </div>
      </div>
    </OutsideClick>
  );
}

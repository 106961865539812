import React, { useState } from "react";
import Modal from "antd/es/modal/Modal";
import PdfPreview from "../../../../Common/PdfPreview";
import closeIconWhite from "../../../../../assets/images/product/template/Close.png";
import "./TemplatePreview.css";

export default function TemplatePreview(props) {
  const {
    closePreviewTemplate, pdfObj,
  } = props;
  const [modalIsAnimating, setModalIsAnimating] = useState(true);
  return (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      onCancel={closePreviewTemplate}
      width="40vw"
      wrapClassName="TemplatePreview"
      className="TemplatePreview"
      afterOpenChange={() => setModalIsAnimating(false)}
    >
      <div className="templatePreviewContainer">
        <div className="templatePreviewHeader">
          <div />
          <img
            alt="closeIcon"
            src={closeIconWhite}
            onClick={closePreviewTemplate}
          />
        </div>
        <div className="templatePdfPreview">
          <PdfPreview
            blob={pdfObj.url}
            height="70vh"
            scaleType="PageWidth"
            showLoading={modalIsAnimating}
          />
        </div>
      </div>
    </Modal>
  );
}

import React, { useState } from "react";

export default function DataFieldEditCard(props) {
  const {
    isCommonDataField,
    prevTitleValue,
    prevDetailsValue,
    onSaveClick,
    onDeleteClick,
    onCancelClick,
  } = props;
  const [titleValue, setTitleValue] = useState(prevTitleValue || "");
  const [detailsValue, setDetailsValue] = useState(prevDetailsValue || "");

  const onTitleChange = (e) => {
    setTitleValue(e.target.value);
  };

  const onDetailsChange = (e) => {
    setDetailsValue(e.target.value);
  };

  return (
    <div className="DataFieldEditCardContainer">
      <div className="createFieldInputsContainer">
        <div className="spanContainer">
          <span className="inputTitleSpan"> Title* </span>
        </div>
        <input
          className="titleInput"
          value={titleValue}
          placeholder="Enter title"
          onChange={onTitleChange}
          disabled={!isCommonDataField}
        />

        <div className="spanContainer">
          <span className="inputTitleSpan"> Details </span>
        </div>
        <textarea
          className="detailsInput"
          value={detailsValue}
          placeholder="Enter details"
          onChange={onDetailsChange}
          disabled={isCommonDataField}
        />
      </div>
      <div className="dataFieldEditButtonContainer">
        {(isCommonDataField && !prevTitleValue)
          ? (
            <button
              type="button"
              className="cancelButton"
              onClick={onCancelClick}
            >
              Cancel
            </button>
          )
          : (
            <button
              type="button"
              className="deleteButton"
              onClick={onDeleteClick}
            >
              Delete
            </button>
          )}
        <button
          type="button"
          disabled={titleValue === ""}
          className="saveButton"
          onClick={() => onSaveClick(isCommonDataField ? titleValue : detailsValue)}
        >
          Save
        </button>
      </div>
    </div>
  );
}

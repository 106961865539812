import React, { Component } from "react";
import moment from "moment";
import { Tag } from "antd";
import { toast } from "react-toastify";
import Scrollbar from "react-scrollbars-custom";
import { s3EsvPriv } from "../../../utils/aws";
import DocumentAddModal from "./DocumentAddModal";
import backArrowIcon from "../../../assets/images/index/backArrowIconMidnight.png";
import addIcon from "../../../assets/images/index/addCircleIconWhite.png";
import closeIcon from "../../../assets/images/index/closeMidnight.png";
import DownloadIcon from "../../../assets/images/index/downloadIconLightBlue.png";
import DownloadHoverIcon from "../../../assets/images/index/downloadIconBlue.png";
import TrashHoverIcon from "../../../assets/images/index/deleteIconBlue.png";
import TrashIcon from "../../../assets/images/index/deleteIconLightBlue.png";
import CardViewIcon from "../../../assets/images/index/cardViewIcon.png";
import ListViewIcon from "../../../assets/images/index/listViewIcon.png";
import CardViewIconInactive from "../../../assets/images/index/cardViewIconInactive.png";
import ListViewIconInactive from "../../../assets/images/index/listViewIconInactive.png";
import { downloadFileFromS3 } from "../../../utils/helpers";
import "./productDocument.css";

import DocumentsCardView from "../../Documents/Components/DocumentsCardView";
import DocumentsListView from "../../Documents/Components/DocumentsListView";
import DocumentsConfirmDelete from "../../Documents/Components/DocumentsConfirmDelete";
import { postFiles, getFiles } from "../../../actions/documents";
import StyledButton from "../../Common/UIComponents/StyledButton";

export default class ProductDocuments extends Component {
  constructor(props) {
    super();
    const {
      productListIndex, product,
    } = props;
    const isProduct = productListIndex === "Product";
    this.state = {
      addingFile: false,
      numberOfFilesSelected: 1,
      cardView: false,
      isProduct,
      productListIndex,
      productId: isProduct ? product.product_id : product.index_card_id,
      productName: isProduct ? product.product_name : product.card_name,
      productFiles: [],
      selectAll: false,
      fileSelected: null,
      confirmDelete: false,
      batch: false,
      deleteItem: null,
    };
    this.preventCheckbox = false;
  }

  componentDidMount() {
    this.initComponent();
  }

  initComponent = async () => {
    await this.apiGetProductFiles();
  }

  handleChangeView = () => {
    const { cardView } = this.state;
    this.setState({ cardView: !cardView });
  };

  handleCheckbox = (event, key) => {
    if (this.preventCheckbox) {
      this.preventCheckbox = false;
      return;
    }
    const { productFiles } = this.state;
    event.stopPropagation();
    const files = productFiles;
    let counter = 0;
    files.forEach((file) => {
      if (file.key === key) {
        file.checked = !file.checked;
      }
      if (file.checked === true) {
        // eslint-disable-next-line no-plusplus
        counter++;
      }
    });

    this.setState({
      fileSelected: counter > 0,
      numberOfFilesSelected: counter,
      productFiles: files,
    });

    if (counter === 0) {
      this.setState({ selectAll: false });
    } else if (counter === files.length) {
      this.setState({ selectAll: true });
    }
  };

  handleCheckboxAll = () => {
    const { productFiles } = this.state;
    const files = productFiles;
    const { selectAll } = this.state;

    files.forEach((file) => {
      file.checked = !selectAll;
    });

    this.setState({
      selectAll: !selectAll,
      fileSelected: selectAll ? false : files.length > 0,
      numberOfFilesSelected: selectAll ? 0 : files.length,
      productFiles: files,
    });
  };

  handleClearAll = () => {
    const { productFiles: files } = this.state;
    files.forEach((file) => {
      file.checked = false;
    });
    this.setState({
      selectAll: false,
      numberOfFilesSelected: 0,
      fileSelected: false,
      productFiles: files,
    });
  };

  switchAddFileModal = () => {
    const { addingFile } = this.state;
    this.setState({ addingFile: !addingFile });
  }

  handleDownloadFile = (event, file, batch) => {
    /** The event.stopPropagation() does not close the menu,
     * so we need hte preventCheckbox here to prevent calling the handleCheckbox().
    */
    if (event) {
      event.stopPropagation();
    }
    this.preventCheckbox = true;
    const { productFiles: files, numberOfFilesSelected } = this.state;
    if (batch) {
      let downloadFile = null;
      files.forEach((element) => {
        if (element.checked === true) {
          const fileName = element.file.split("/").pop();
          downloadFileFromS3(element.file, s3EsvPriv, fileName);
          element.checked = false;
          downloadFile = element;
        }
      });
      const toastMessage = numberOfFilesSelected === 1
        ? `${downloadFile.name}.${downloadFile.extension} downloaded`
        : `${numberOfFilesSelected} files downloaded`;
      toast.success(toastMessage);
    } else {
      const fileName = file.file.split("/").pop();
      downloadFileFromS3(file.file, s3EsvPriv, fileName);
      toast.success(`${file.name}.${file.extension} downloaded`);
    }

    this.setState({
      fileSelected: false, selectAll: false, numberOfFilesSelected: 0,
    });
  };

  handleConfirmDelete = (event, item, batch) => {
    /** The event.stopPropagation() does not close the menu,
     * so we need hte preventCheckbox here to prevent calling the handleCheckbox().
    */
    if (event) {
      event.stopPropagation();
    }
    this.preventCheckbox = true;
    const { confirmDelete } = this.state;
    this.setState({
      confirmDelete: !confirmDelete,
      batch,
      deleteItem: item,
    });
  };

  toggleConfirmDelete = () => {
    const { confirmDelete } = this.state;
    this.setState({ confirmDelete: !confirmDelete });
  };

  handleDeleteFile = async (event, item, batch) => {
    event.stopPropagation();
    let { productFiles: files } = this.state;
    const { productId, confirmDelete } = this.state;
    let toastMessage = "";
    if (batch) {
      const tempArray = files.filter((file) => file.checked === true);
      if (tempArray.length === 1) {
        toastMessage = `${tempArray[0].name}.${tempArray[0].extension} delete`;
      } else {
        toastMessage = `${tempArray.length} files delete`;
      }
      files = tempArray.map((file) => ({ name: file.name }));
    } else {
      files = [{ name: item.name }];
      toastMessage = `${item.name}.${item.extension} delete`;
    }
    const response = await postFiles(files, "Delete", productId);
    if (response && response.success) {
      this.apiGetProductFiles();
      toast.success(toastMessage);
    } else {
      toast.error("Files Delete failed");
    }

    this.setState({
      fileSelected: false,
      selectAll: false,
      confirmDelete: !confirmDelete,
    });
  };

  apiGetProductFiles = async () => {
    const { productId } = this.state;
    const response = await getFiles(productId);

    if (response && response.success) {
      if (response.result && response.result.length < 1) {
        this.setState({ productFiles: [] });
      } else {
        const results = response.result[0];
        const data = results.files.map((file, index) => {
          const obj = {};
          obj.key = index;
          obj.name = file.name;
          obj.extension = file.type;
          obj.uploadDate = file.uploaded;
          obj.fileSize = file.size;
          obj.file = file.file_path;
          obj.renameDisable = true;
          obj.numOfSelectedFile = 0;
          obj.renameValue = file.name;
          return obj;
        });
        this.setState({ productFiles: [...data] });
      }
    }
  }

  handleAddFile = () => {
    this.switchAddFileModal();
  }

  handleAddFileConfirm = async (files) => {
    const filesToStore = files.map((file) => {
      const obj = {};
      obj.file = file.file;
      obj.name = file.name;
      obj.type = file.extension;
      obj.size = `${Math.ceil(parseInt(file.fileSize) / 1024)}mb`;
      obj.uploaded = moment(file.uploadDate).format("YYYY-MM-DD hh:mm:ss a");
      return obj;
    });
    const success = await this.apiPostFiles(filesToStore, "Add");
    if (success) {
      this.setState({ addingFile: false, selectAll: false, fileSelected: false });
    }
  }

  handleFileOptionClick = (event) => {
    event.stopPropagation();
  };

  handleFileOptionClose = (event) => {
    event.stopPropagation();
  };

  handleRenameFile = (event, item) => {
    /** The event.stopPropagation() does not close the menu,
     * so we need hte preventCheckbox here to prevent calling the handleCheckbox().
    */
    if (event) {
      event.stopPropagation();
    }
    this.preventCheckbox = true;
    const { productFiles: files } = this.state;
    files.forEach((file) => {
      if (file.key === item.key) {
        file.renameDisable = !file.renameDisable;
      } else {
        file.renameDisable = true;
      }
    });
    this.setState({ productFiles: files });
  };

  handleNameChange = (event, key) => {
    const { productFiles: files } = this.state;
    const { value } = event.target;
    files.forEach((file) => {
      if (file.key === key) {
        file.renameValue = value;
      }
    });
    this.setState({ productFiles: files });
  };

  handleNameSubmit = (event, key) => {
    const { productFiles: files } = this.state;
    if (event.key === "Enter") {
      files.forEach((file) => {
        if (file.key === key) {
          file.renameDisable = true;
        }
      });
      this.apiPostFiles(files, "Edit", key);
    }
  };

  handleCancelRename = (e, key) => {
    e.stopPropagation();
    const { productFiles: files } = this.state;
    files.forEach((file) => {
      if (file.key === key) {
        file.renameDisable = true;
        file.renameValue = file.name;
      }
    });
    this.setState({ productFiles: files });
  }

  apiPostFiles = async (files, category, key) => {
    let file;
    let payloadObj = {};
    const { productId } = this.state;
    const payloadArray = [];
    if (category === "Edit") {
      file = files[key];
      payloadObj = {
        old_name: file.name,
        new_name: file.renameValue,
      };
      if (payloadObj.new_name.trim() === "") {
        toast.error("File name cannot be empty");
        file.renameValue = file.name;
        files[key] = file;
        this.setState({ productFiles: files });
        return false;
      }
      payloadArray.push(payloadObj);
      const res = await postFiles(payloadArray, category, productId);
      if (res && res.success) {
        if (res.message === "success") {
          toast.success("File renamed successfully");
          file.name = file.renameValue;
          files[key] = file;
          this.setState({ productFiles: files });
          return true;
        }
        toast.error(res.message);
        file.renameValue = file.name;
        files[key] = file;
        this.setState({ productFiles: files });
        return false;
      }
      toast.error("File renamed failed");
      return false;
    }
    const res = await postFiles(files, category, productId);
    if (res && res.success) {
      if (res.message === "success") {
        toast.success("Files added successfully");
      } else {
        toast.error(res.message);
      }
      if (res.result) {
        const results = res.result;
        const data = [];
        if (results.length < 1) {
          this.setState({ productFiles: data, fileSelected: false });
        } else {
          const filesRec = results[0].files;
          filesRec.forEach((fileEl, index) => {
            data.push({
              key: index,
              name: fileEl.name,
              extension: fileEl.type,
              uploadDate: fileEl.uploaded,
              fileSize: fileEl.size,
              file: fileEl.file_path,
              checked: false,
              renameDisable: true,
              nubOfSelectedFile: 0,
              renameValue: fileEl.name,
            });
          });
          this.setState({ productFiles: data, fileSelected: false, numberOfFilesSelected: 0 });
        }
      } else {
        await this.apiGetProductFiles();
        this.setState({ numberOfFilesSelected: 0 });
      }
      return true;
    }
    toast.error("Files add failed");
    return false;
  };

  render() {
    const { product } = this.props;
    const {
      tags,
      created_on: createTime,
    } = product;

    const {
      addingFile,
      confirmDelete,
      productName,
      isProduct,
      productListIndex,
      numberOfFilesSelected,
      batch,
      deleteItem,
      cardView,
      productFiles,
      selectAll,
      fileSelected,
    } = this.state;

    const { handleBackArrowClick, linkReportFields } = this.props;

    const keys = isProduct ? linkReportFields : [{ json_field: "created_by", title_field: "Created By" }];

    return (
      <>
        {confirmDelete && (
          <DocumentsConfirmDelete
            numOfSelectedFiles={numberOfFilesSelected}
            batch={batch}
            deleteItem={deleteItem}
            toggleConfirmDelete={this.toggleConfirmDelete}
            handleDeleteFile={this.handleDeleteFile}
          />
        )}
        {addingFile
          && (
          <DocumentAddModal
            handleCloseAddFileModal={this.switchAddFileModal}
            handleAddFileConfirm={this.handleAddFileConfirm}
          />
          )}
        <div className="displayProductDetails productDocuments">
          <div
            className="productDocuments__HeaderContainer"
          >
            <img
              className="productDocuments__HeaderContainer--backArrowIcon"
              src={backArrowIcon}
              alt="backArrowIcon"
              onClick={handleBackArrowClick}
            />
            <div
              className="productDocuments__HeaderContainer--NameSection"
            >
              <div
                className="mediumFont normalWeight threeLines productDocuments__HeaderContainer--NameContainer"
              >
                {productName}
              </div>
            </div>
            <div
              className="productDocuments__HeaderContainer--productDetailsSection"
            >
              <Scrollbar className="ProductDocuments__LinkedFieldsScrollbar">
                {
                keys.map(({ json_field, title_field }) => (
                  <div key={json_field} className="smallFont normalWeight midnight">
                    <span className="mediumWeight">{`${title_field}: `}</span>
                    {isProduct ? product.item[json_field] : product[json_field]}
                  </div>
                ))
              }
              </Scrollbar>
              <div className="smallFont normalWeight midnight">{moment(new Date(createTime)).format("MMM DD, YYYY")}</div>

              <div
                className="normalWeight productDocuments__HeaderContainer--productDetailsSection-IndexCategory"
              >
                <p className="mediumFont darkBlue">Index category:</p>
                <p className="smallFont midnight">{productListIndex}</p>
              </div>
            </div>
            <div className="productDocuments__HeaderContainer--tagsSection">
              <div className="mediumFont normalWeight darkBlue productDocuments__HeaderContainer--tagsSection-tags">
                Tags:
              </div>

              <Scrollbar
                className="productDocuments__HeaderContainer--tagsSection-scrollbar"
              >
                {tags.map((item, index) => (
                  <Tag
                    key={index}
                    bordered
                    className="ProductDocuments__Tag"
                  >
                    {item.tag}
                  </Tag>
                ))}
              </Scrollbar>
            </div>
          </div>
          <div
            className="productDocuments__filesContainer"
          >
            <div className="productDocuments__filesContainer--filesBlock"/* style={{ position: "relative" }} */>

              {cardView && (
                <>
                  <div className="docFileTableHeadCard">
                    <label className="checkboxContainer">
                      <input
                        type="checkbox"
                        onChange={this.handleCheckboxAll}
                        checked={selectAll && !addingFile}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <DocumentsCardView
                    files={productFiles}
                    handleCheckbox={this.handleCheckbox}
                    readOnly={false}
                    handleNameChange={this.handleNameChange}
                    handleNameSubmit={this.handleNameSubmit}
                    handleRenameFile={this.handleRenameFile}
                    handleFileOptionClick={this.handleFileOptionClick}
                    handleCancelRename={this.handleCancelRename}
                  />
                </>
              )}
              {!cardView && (
                <div
                  className="productDocuments__filesContainer--filesBlock-listView"
                >
                  <DocumentsListView
                    files={productFiles}
                    readOnly={false}
                    handleCheckbox={this.handleCheckbox}
                    handleCheckboxAll={this.handleCheckboxAll}
                    selectAll={selectAll && !addingFile}
                    handleFileOptionClick={this.handleFileOptionClick}
                    handleNameChange={this.handleNameChange}
                    handleNameSubmit={this.handleNameSubmit}
                    handleRenameFile={this.handleRenameFile}
                    handleConfirmDelete={this.handleConfirmDelete}
                    handleDownloadFile={this.handleDownloadFile}
                  />
                </div>
              )}
            </div>
            <div
              className="productDocuments__filesBlock--viewType"
            >
              {!fileSelected && (
              <div
                className="docViewButton"
                onClick={this.handleChangeView}
              >
                <div className="productDocuments__filesBlock--viewType-viewBtns cardListViewBtns">
                  <img
                    alt="cardViewIcon"
                    className={
                      cardView ? "cardViewActive" : "cardViewInactive"
                    }
                    id="productDocuments__filesBlock--viewBtns-cardViewIcon"
                    src={cardView ? CardViewIcon : CardViewIconInactive}
                  />
                  <img
                    alt="listViewIcon"
                    className={
                      !cardView ? "cardViewActive" : "cardViewInactive"
                    }
                    src={!cardView ? ListViewIcon : ListViewIconInactive}
                  />
                </div>
              </div>
              )}
              {fileSelected
                && (
                <div className="docHeaderStatus">
                  {fileSelected
                    && (
                    <div className="docEditIcons">
                      <div>
                        {numberOfFilesSelected === 1 ? "1 file selected" : `${numberOfFilesSelected} files selected`}
                        <img
                          className="docEditIconClose"
                          onClick={this.handleClearAll}
                          src={closeIcon}
                          alt=""
                        />
                      </div>

                      <img
                        onClick={(e) => this.handleDownloadFile(e, null, true)}
                        onMouseEnter={(e) => {
                          e.currentTarget.src = DownloadHoverIcon;
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.src = DownloadIcon;
                        }}
                        src={DownloadIcon}
                        alt=""
                      />
                      <img
                        onClick={(e) => this.handleConfirmDelete(e, null, true)}
                        onMouseEnter={(e) => {
                          e.currentTarget.src = TrashHoverIcon;
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.src = TrashIcon;
                        }}
                        src={TrashIcon}
                        alt=""
                      />
                    </div>
                    )}
                </div>
                )}
            </div>
            <StyledButton
              type="primary"
              onClick={this.handleAddFile}
              className="productDocuments__filesBlock--addFilesButton"
            >
              <img
                className="productDocuments__filesBlock--addFilesButton-addIcon"
                src={addIcon}
                alt="addIcon"
              />
              Add file
            </StyledButton>
          </div>
        </div>
      </>
    );
  }
}

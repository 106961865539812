import React, { useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";
import Header from "../Common/Header";
import COAListItem from "./COAListItem";
import COADeleteModal from "./COADeleteModal";
import TemplatePreview from "../Templates/TemplatePreview";
import {
  manageCoa,
  getCoas,
} from "../../../../../actions/productTemplate";
import { createAndAppendIdentifierList } from "../../helper";
import NoResultFound from "../Common/NoResultFound";
import "./COAList.css";

export default function COAList(props) {
  const {
    handleAddCoa,
    coaList,
    setCoaList,
    linkReportKeys,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [pdfObjForPreview, setPdfObjForPreivew] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [elemToBeDeleted, setElemToBeDeleted] = useState(null);
  const [searchedValue, setSearchedValue] = useState("");
  const fetchCoas = async () => {
    const params = { category: "fetchList" };
    const response = await getCoas(params);

    setIsLoading(false);
    if (response && response.message === "Success") {
      const result = createAndAppendIdentifierList(response, linkReportKeys);
      setCoaList(result);
    } else {
      toast.error("Failed to get COA list.");
      setCoaList([]);
    }
  };

  const apiDeleteCoa = async () => {
    if (!elemToBeDeleted) {
      toast.error("Invalid COA.");
      return;
    }
    const params = {
      coa_id: elemToBeDeleted.coa_id, action: "Delete", method: "PATCH",
    };
    const response = await manageCoa(params);
    if (response && response.message === "Success") {
      fetchCoas();
      setElemToBeDeleted(null);
      setShowDeleteModal(false);
      toast.success("Successfully deleted COA.");
    } else {
      toast.error("Failed to delete COA.");
    }
  };

  /**
   * Make api calls to get the coa list and the link_report key.
   * @param {*} searchValue
   */
  const apiSearchCoas = async (searchValue) => {
    const params = { search: searchValue, category: "Search" };
    const response = await getCoas(params);

    if (response && response.success) {
      const result = createAndAppendIdentifierList(response, linkReportKeys);
      setSearchedValue(searchValue);
      setCoaList(result);
    } else {
      setSearchedValue("");
      toast.error("Failed to search COAs.");
    }
  };

  const toggleShowDeleteModal = (elem) => {
    if (elem) {
      setElemToBeDeleted(elem);
    }
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleShowCoaPreview = () => {
    if (pdfObjForPreview.url) {
      URL.revokeObjectURL(pdfObjForPreview.url);
    }
    setPdfObjForPreivew(null);
  };

  return (
    <div className="coaListContainer">
      <Header
        title="Your Reports"
        showCreateReportButton
        createButtonEnabled
        showAddIcon
        apiSearchCoas={apiSearchCoas}
        handleAddCoa={handleAddCoa}
        coaView
      />
      {!isLoading && (
        coaList.length === 0
          ? <div className="COAList__NoResultFound"><NoResultFound /></div>
          : (
            <Scrollbar
              className="COAList__ScrollBar"
              id="coaListContainerScrollbar"
            >
              <div className="coaListItems">
                {coaList.map((elem, index) => (
                  <COAListItem
                    key={index}
                    elem={elem}
                    toggleShowDeleteModal={toggleShowDeleteModal}
                    setPdfObjForPreview={setPdfObjForPreivew}
                    searchedValue={searchedValue}
                  />
                ))}
              </div>
            </Scrollbar>
          )
      )}
      {showDeleteModal && <COADeleteModal toggleShowDeleteModal={toggleShowDeleteModal} apiDeleteCoa={apiDeleteCoa} />}
      {pdfObjForPreview && (
        <TemplatePreview
          pdfObj={pdfObjForPreview}
          closePreviewTemplate={toggleShowCoaPreview}
        />
      )}
    </div>
  );
}

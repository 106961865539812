import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "../OnboardingFormLayout.css";
import OnboardingFormLayout from "../OnboardingFormLayout";
import InitialFormValue from "./InitialValues";
import { submitOnboardingFields } from "../../../../../actions/onboarding";
import { getTabsForCompany } from "../../../../../actions/switchCompany";
import { ESV_DETAILS } from "../../../../../utils/Constant";
import OnboardingProductForm from "./OnboardingProductForm";
import OnboardingFormBtn from "./OnboardingFormBtn";
import OnboardingEnvironmentForm from "./OnboardingEnvironmentForm";

const initialValues = InitialFormValue.fields_form;

export default function OnboardingForm({ formStep, setDisplayForm, setSuccess }) {
  const [productDropdownOptions, setProductDropdownOptions] = useState([]);
  const [envDropdownOptions, setEnvDropdownOptions] = useState([]);
  const [formValues, setFormValues] = useState({ ...initialValues });
  const [submitBtnValidation, setSubmitBtnValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const history = useHistory();

  const onboardingType = formStep === 2 ? "Environment" : "Product";

  const FORM_NAME = "fields_form";

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    layout: "vertical",
  };

  /** Convert display name to DB Column value. Replace special characters, spaces with an underscore, convert to lower case */
  const getJsonFieldName = (str) => {
    if (!str) {
      return "";
    }
    let jsonFieldName = str.trim().replace(/[^\w\s]/gi, " ").replace(/\s+/g, "_").toLowerCase();
    jsonFieldName = jsonFieldName.replace(/(?:__+)/g, "_"); // condense multiple underscores to one
    jsonFieldName = jsonFieldName.replace(/^_+|_+$/g, ""); // trim leading + trailing underscores
    return jsonFieldName;
  };

  /**
     * @param {Array} fieldList
     * On change of fieldList in editableFields component
     * update the fields for product details and environment details.
     */
  const handleEditableFields = (fieldList) => {
    if (fieldList) {
      const currentStep = formStep === 1 ? "product_info" : "environment_info";
      const newFields = fieldList.filter((field) => field !== undefined);

      // Check if any fields have been added or removed
      const fieldsToAdd = newFields.filter((newField) => !formValues[currentStep].editable_fields.some((oldField) => oldField.json_field === newField));
      const fieldsToRemove = formValues[currentStep].editable_fields.filter((oldField) => !newFields.includes(oldField.json_field));

      // Check if any fields have been changed
      const fieldsToChange = newFields.filter((newField, index) => {
        const oldField = formValues[currentStep].editable_fields[index];
        return oldField && newField !== oldField.json_field;
      });

      const fieldCheck = fieldList.map((field) => ({ json_field: getJsonFieldName(field), title_field: field }));
      // Update editable_fields and productDropdownOptions
      setFormValues({
        ...formValues,
        [currentStep]: {
          ...formValues[currentStep],
          editable_fields: fieldCheck,
        },
      });

      if (fieldsToAdd.length > 0 || fieldsToRemove.length > 0 || fieldsToChange.length > 0) {
        const newFieldsValues = newFields.map((field) => ({ value: field, label: field }));
        const updatedFieldsValues = newFieldsValues.filter((field) => !fieldsToRemove.includes(field.value));

        const dropdownOptions = currentStep === "product_info" ? productDropdownOptions : envDropdownOptions;
        const newOptionsToAdd = updatedFieldsValues.filter((option) => !dropdownOptions.some((existingOption) => existingOption.value === option.value));

        if (currentStep === "product_info") {
          const filteredProductDropdownOptions = productDropdownOptions.filter((option) => {
            const fieldToRemove = fieldsToRemove.find((field) => field.json_field === option.value);
            return !fieldToRemove;
          });
          setProductDropdownOptions([...filteredProductDropdownOptions, ...newOptionsToAdd]);
          if (fieldsToRemove.length > 0) {
            form.setFieldValue("product_link", []);
          }
        }
      }
    }
  };

  const handleUpdateSessionStorage = async () => {
    const { tabsList } = await getTabsForCompany();
    sessionStorage.setItem(ESV_DETAILS, JSON.stringify(tabsList));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formData = form.getFieldsValue();
    const payload = {};
    const data = formValues;
    const {
      product_info,
      environment_info,
    } = data;
    if (product_info.sample_supply === "Yes" && formStep === 1) {
      const nonEditableFields = [{ json_field: "sample_type", title_field: "Sample Type" }, { json_field: "received_date", title_field: "Received Date" }];
      const fields = [...nonEditableFields, ...(product_info.editable_fields ?? [])];
      payload.product_info = {
        fields,
        product_link: formData.product_link,
      };
    }
    if (environment_info.env_sample_supply === "Yes" && formStep === 2) {
      const nonEditableFields_env = [
        { json_field: "sample_type", title_field: "Sample Type" },
        { json_field: "received_date", title_field: "Received Date" },
        { json_field: "swab_number", title_field: "Swab Number" },
        { json_field: "zone", title_field: "Zone" },
        { json_field: "section", title_field: "Section" },
      ];
      const field_env = [...nonEditableFields_env, ...(environment_info.editable_fields ?? [])];
      payload.environment_info = {
        fields: field_env,
        environment_link: environment_info.environment_link,
        environment_supplies_needed: environment_info.environment_supplies_needed,
      };
    }
    const { success, message } = await submitOnboardingFields(payload);
    if (success) {
      handleUpdateSessionStorage();
      // history.push("/sample-submission");

      // Display success screen with success message.
      setDisplayForm(false);
      setSuccess(onboardingType);
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  const handleFormValidation = (e) => {
    e.preventDefault();
    const formData = form.getFieldsValue();
    if (formStep === 1) {
      const fieldData = formValues.product_info.editable_fields.filter((field) => field.json_field === "");
      if (formData.product_link) {
        if (!formData.product_link.length) {
          form.validateFields(["product_link"]);
          toast.error("At least one field required!");
          return;
        }
        if (fieldData.length > 0) {
          form.validateFields();
          toast.error("Invalid inputs!");
          return;
        }
      }
      if (formData.sample_supply === "Yes") {
        if (formData.editable_fields.length < 1) {
          form.validateFields();
          toast.error("At least one field required!");
          return;
        }
      }

      setFormValues({
        ...formValues,
        product_info: {
          ...formValues.product_info,
          sample_supply: "Yes",
          product_link: formData.product_link ?? [],
        },
        environment_info: {
          ...formValues.environment_info,
        },
      });
    } else {
      const fieldData = formValues.environment_info.editable_fields.filter((field) => field?.json_field === "");
      if (fieldData.length > 0) {
        form.validateFields();
        toast.error("Invalid inputs!");
        return;
      }
      if (formData.environment_supplies_needed === "") {
        form.validateFields(["environment_supplies_needed"]);
        toast.error("Invalid input");
        return;
      }
      formValues.environment_info.environment_link = formData.environment_link ?? [];
      formValues.environment_info.environment_supplies_needed = formData.environment_supplies_needed ?? "No";
    }
    handleSubmit();
  };

  /**
     * @param {String} formName
     * Handling the initialValues for form
     */
  const handleInitialValues = (formName) => {
    if (formName && Object.keys(formValues).length) {
      const editable_fields = formValues[formName].editable_fields ? formValues[formName].editable_fields.map(({ title_field }) => title_field) : undefined;
      return { ...formValues[formName], editable_fields };
    }
    return {};
  };

  /**
     * @param {Object} fieldObj
     * @param {Array} exceptionFields*
     * Creating option array for associated fields
     */
  const createFieldArray = (fieldObj, exceptionFields = []) => {
    if (!fieldObj) {
      return [];
    }

    const getFieldArrayItem = (key, value) => {
      if (Array.isArray(value) && key === "editable_fields") {
        return value.map((item) => {
          const jsonFieldName = getJsonFieldName(item.json_field);
          const titleField = item.title_field;
          return { value: jsonFieldName, label: titleField ? titleField.trim() : "" };
        });
      }
      return { value: getJsonFieldName(key), label: value ? value.trim() : "" };
    };

    const fieldArray = Object.entries(fieldObj)
      // eslint-disable-next-line no-unused-vars
      .filter(([key, value]) => !exceptionFields.includes(key))
      .map(([key, value]) => getFieldArrayItem(key, value));

    return fieldArray.flat().filter((item) => item.label !== "");
  };

  /**
     * @dependency [formValues, formStep]
     * Handling the form data on page change
     */
  useEffect(() => {
    const formData = form.getFieldsValue();
    if (formStep === 1) {
      if (formData?.product_link?.length === 0) {
        setSubmitBtnValidation(false);
      } else if (formData.sample_supply === "" || formData.sample_supply === null) {
        setSubmitBtnValidation(false);
      } else {
        setSubmitBtnValidation(true);
      }
      if (Object.keys(formValues).length) {
        const options = createFieldArray(
          formValues.product_info,
          [
            "sample_type",
            "date",
            "product_link",
            "sample_supply",
          ],
        );
        setProductDropdownOptions(options);
        form.setFieldsValue({
          editable_fields: formValues.product_info.editable_fields
            ? formValues.product_info.editable_fields.map((data) => (data.title_field)) : [],
        });
        if (formValues.product_info.sample_supply) {
          form.setFieldsValue({
            sample_supply: formValues.product_info.sample_supply,
          });
          form.setFieldsValue({
            product_link: formValues.product_info.product_link ?? [],
          });
        }
      }
    } else if (formStep === 2) {
      if (formData.environment_link.length === 0) {
        setSubmitBtnValidation(false);
      } else if (formData.env_sample_supply === "" || formData.env_sample_supply === null) {
        setSubmitBtnValidation(false);
      } else {
        setSubmitBtnValidation(true);
      }
      if (Object.keys(formValues).length) {
        form.setFieldsValue({
          editable_fields: formValues.environment_info.editable_fields.map((data) => (data.title_field)) ?? [],
        });
        /**
         * Adding Non-editable and pre-defined fields
         */
        if (envDropdownOptions.length === 0) {
          const options = createFieldArray(
            initialValues.environment_info,
            [
              "sample_type",
              "date",
              "environment_link",
              "env_sample_supply",
              "environment_supplies_needed",
              "editable_fields",
            ],
          );
          setEnvDropdownOptions(options);
        }
      }
    }
  }, [formValues, formStep, form]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OnboardingFormLayout>
      {formStep === 1 && (
        <OnboardingProductForm
          layout={layout}
          form={form}
          FORM_NAME={FORM_NAME}
          handleInitialValues={handleInitialValues}
          getJsonFieldName={getJsonFieldName}
          handleEditableFields={handleEditableFields}
          setSubmitBtnValidation={setSubmitBtnValidation}
          productDropdownOptions={productDropdownOptions}
        />
      )}
      {formStep === 2
        && (
          <OnboardingEnvironmentForm
            layout={layout}
            form={form}
            FORM_NAME={FORM_NAME}
            handleInitialValues={handleInitialValues}
            getJsonFieldName={getJsonFieldName}
            handleEditableFields={handleEditableFields}
            setSubmitBtnValidation={setSubmitBtnValidation}
            envDropdownOptions={envDropdownOptions}
          />
        )}
      <OnboardingFormBtn
        handleFormValidation={handleFormValidation}
        submitBtnValidation={submitBtnValidation}
        history={history}
        loading={loading}
      />
    </OnboardingFormLayout>
  );
}

import React from "react";

import { LoadingOutlined } from "@ant-design/icons";

import StyledTooltip from "../../../../../Common/UIComponents/StyledTooltip";

import "./ReportActionButton.css";

export default function ReportActionButton({
  title,
  description,
  icon,
  onClick,
  isActionComplete = false,
  showHoverStyles = true,
  loading = false,
}) {
  return (
    <div className={`ReportActionButton ${(showHoverStyles && !loading) ? "HoverEnabled" : ""}`} onClick={onClick}>
      {loading ? (
        <div className="ReportActionButton__LoadingIconContainer">
          <LoadingOutlined spin className="ReportActionButton__LoadingIcon" />
        </div>
      ) : (
        <>
          <div className="ReportActionButton__Content">
            {isActionComplete ? (
              <StyledTooltip title={title}>
                <h4 className="ReportActionButton__ActionCompleteTitle truncated-text">
                  {title}
                </h4>
              </StyledTooltip>
            ) : (
              <h4 className="ReportActionButton__Title">
                {title}
              </h4>
            )}
            {description && <p className="ReportActionButton__Description">{description}</p>}
          </div>
          <div className="ReportActionButton__Icon">
            {icon}
          </div>
        </>
      )}
    </div>
  );
}

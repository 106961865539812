import React, { useState } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import OutsideClickHandler from "react-outside-click-handler";
import { getUserCredentials, getTabsList } from "../../../../utils/common";
import { ReactComponent as AddIcon } from "../../../../assets/images/sample-submission/add.svg";
import { ReactComponent as ShareIcon } from "../../../../assets/images/sample-submission/Share.svg";
import { ReactComponent as TubeIcon } from "../../../../assets/images/sample-submission/Tube.svg";
import "./FloatingButton.css";

const FloatingButton = ({ setCreateSubmission, setShareSubmission }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);
  const { user_title: userTitle } = getUserCredentials();
  const isAdmin = userTitle === "Admin" || userTitle === "Editor";
  const tabsList = getTabsList();
  const showShare = tabsList?.includes("product");

  const handleMouseOver = () => setIsOpen(true);
  const handleMouseLeave = () => setHoveredOption(null);
  const handleOutsideClick = () => {
    setIsOpen(false);
    setHoveredOption(null);
  };

  const handleOptionClick = (option) => {
    if (option === "share") setShareSubmission(true);
    if (option === "create") setCreateSubmission(true);
    setIsOpen(false);
    setHoveredOption(null);
  };

  const handleOptionHover = (option) => setHoveredOption(option);

  // const handleButtonClick = () => {
  //   if (isOpen) {
  //     setIsOpen(false);
  //   }
  //   // setHoveredOption(null);
  // };

  const options = [
    { icon: <ShareIcon width={18} height={18} title="share icon" />, text: "Share", option: "share" },
    { icon: <TubeIcon width={18} height={18} title="tube icon" />, text: "Create new submission", option: "create" },
  ];

  const renderOption = ({ text, option, icon }) => {
    const isHovered = hoveredOption === option;
    const baseClassName = icon ? "FloatingButton__Option" : "FloatingButton";
    const hoverClassName = icon ? "FloatingButton__Option--hover" : "FloatingButton__Option_Create--hover";
    const className = `${baseClassName} ${isHovered ? hoverClassName : ""}`;

    return (
      <motion.div
        key={option}
        className={className}
        onMouseOver={() => handleOptionHover(option)}
        onFocus={() => handleOptionHover(option)}
        onMouseLeave={handleMouseLeave}
        onBlur={handleMouseLeave}
        onClick={() => handleOptionClick(option)}
        layout
        transition={{ duration: icon ? 0.14 : 0.08, ease: "easeOut" }}
      >
        {isHovered && <span>{text}</span>}
        {icon || <AddIcon width={24} height={24} title="add icon" />}
      </motion.div>
    );
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      {!isAdmin && renderOption({ text: options[1].text, option: options[1].option })}
      {!showShare && isAdmin && renderOption({ text: options[1].text, option: options[1].option })}
      {isAdmin && showShare && (
        <motion.div
          // onClick={handleButtonClick}
          onMouseOver={handleMouseOver}
          onFocus={handleMouseOver}
          className={`FloatingButton ${isOpen ? "FloatingButton--hover" : ""}`}
          initial={{ rotate: 0 }}
          animate={{ rotate: isOpen ? -45 : 0 }}
          transition={{ duration: 0.2, ease: "easeOut" }}
        >
          <AddIcon width={24} height={24} title="add icon" />
        </motion.div>
      )}
      {isOpen && (
        <motion.div
          className="FloatingButton__Options"
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          exit={{ y: 50 }}
          transition={{ duration: 0.2, ease: "easeOut" }}
        >
          {options.map((option) => renderOption(option))}
        </motion.div>
      )}
    </OutsideClickHandler>
  );
};

export default FloatingButton;

import React, {
  useState, useEffect, useCallback, useRef,
} from "react";

import moment from "moment";
import { Carousel } from "react-responsive-carousel";
import { toast } from "react-toastify";

import HorizontalScroll from "../../../../Common/HorizontalScroll/HorizontalScroll";
import CountriesCheckboxList from "../../../../Common/UIComponents/CountriesCheckboxList";
import { getColorForPesticideResults, getCountriesSpecsFlagColors } from "./helper";

import { getReportSpecDetail, updatePesticideCountrySpecDetails } from "../../../../../actions/reports";

import AddIconBlue from "../../../../../assets/images/AddIconBlue.png";
import BackArrowIcon from "../../../../../assets/images/backArrowIcon.png";
import { ReactComponent as LoadingDots } from "../../../../../assets/images/common/LoadingDots.svg";
import watermelon from "../../../../../assets/images/EmptyState_V2.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./DetailedPesticideReport.css";

function DetailedPesticideReport({
  enableTestSelection = false,
  onClose,
  reportData,
  activeDate,
  pesticideDetailTest,
}) {
  const [loadingData, setLoadingData] = useState(true);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectCountryTab, setSelectCountryTab] = useState(selectedCountries[0]);
  const [reportDetail, setReportDetail] = useState(null);
  const [countryColor, setCountryColor] = useState(new Map());
  const isMounted = useRef(false);
  const hasCountryListChanged = useRef(false);

  /**
   * Extract countries data for CountriesCheckboxList
   * @param {Array} countries_lst Array of countries_lst
   */
  const getCountriesDataForPesticideTest = (countries_lst = []) => {
    const countriesSpecData = {
      countriesList: [],
      selectedCountries: [],
    };
    countries_lst.forEach((countryData) => {
      countriesSpecData.countriesList.push(countryData.country);
      if (countryData.selected) {
        countriesSpecData.selectedCountries.push(countryData.country);
      }
    });
    return countriesSpecData;
  };

  /**
   * Makes api call to fetch detailed report
   */
  const apiGetReportDetail = useCallback(async () => {
    const {
      sample_id, sample_type, received_date: receivedDate, report_date,
    } = reportData;
    const received_date = moment(new Date(receivedDate)).format("YYYY/MM/DD");
    const fReportData = report_date ? moment(new Date(report_date)).format("YYYY/MM/DD") : received_date;
    const fActiveDate = moment(new Date(activeDate)).format("YYYY/MM/DD");
    const retest_date = fReportData !== fActiveDate ? fActiveDate : null;
    const test = pesticideDetailTest;
    const response = await getReportSpecDetail({
      sample_id, sample_type, received_date, retest_date, test,
    });
    return response;
  }, [reportData, activeDate, pesticideDetailTest]);

  /**
   * Set detailed report to state
   */
  const fetchReportDetails = useCallback(async () => {
    setLoadingData(true);
    const data = await apiGetReportDetail();
    if (data.success) {
      if (data.result) {
        const details = data.result;
        if (details.pesticides_specs_details.length > 0) {
          const report = details.pesticides_specs_details[0];
          const specData = getColorForPesticideResults(report.pesticides_compound_specs);
          setReportDetail(specData);
        }
        // Set country tab text color from api
        setCountryColor(getCountriesSpecsFlagColors(details.countries_specs_flag));

        // Set commodity as Sample type
        // setPesticideCommodity(details.pesticide_commodity); //NOTE: value not being used, kept for future iteration

        // Set countries list for CountriesCheckboxList
        const countryList = getCountriesDataForPesticideTest(details.countries_lst);
        setCountries(countryList.countriesList);
        setSelectedCountries(countryList.selectedCountries);
        setSelectCountryTab(countryList.selectedCountries[0]);
      }
    } else {
      toast.error(data.message);
    }
    setLoadingData(false);
  }, [apiGetReportDetail]);

  useEffect(() => {
    if (!isMounted.current) {
      fetchReportDetails();
    }
    isMounted.current = true;
  }, [fetchReportDetails]);

  /**
   * Handle country dropdown save event
   */
  const handleCountryDropdownSave = async (countries_lst) => {
    setLoadingData(true);
    const result = await updatePesticideCountrySpecDetails(countries_lst);
    setLoadingData(false);
    if (result && result.success) {
      fetchReportDetails();
      hasCountryListChanged.current = true;
    } else {
      toast.error("Unable to update");
    }
  };

  /**
   * Select country to show detailed report table
   * @param {string} country country name
   */
  const selectMRLWithCountry = (country) => {
    if (!selectedCountries.includes(country)) return;
    setSelectCountryTab(country);
  };

  if (loadingData) {
    return (
      <div className="DetailedPesticideReport__Loading">
        <LoadingDots width={28} height={28} fill="#19305A" />
      </div>
    );
  }

  return (
    <>
      <div className="DetailedPesticideReport__ReportHeader">
        <button type="button" onClick={() => onClose(hasCountryListChanged.current)} className="DetailedPesticideReport__BackButton">
          <img src={BackArrowIcon} alt="Back Arrow" />
        </button>
        <CountriesCheckboxList
          values={countries}
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          onClickSave={handleCountryDropdownSave}
        >
          <button type="button" className="DetailedPesticideReport__AddButton">
            <img src={AddIconBlue} alt="Add symbol" />
          </button>
        </CountriesCheckboxList>
        <HorizontalScroll containerClassName="DetailedPesticideReport__CountriesTabs">
          {selectedCountries.map((country, i) => (
            <span
              key={i}
              className={`DetailedPesticideReport__CountriesTabItem ${country === selectCountryTab ? "DetailedPesticideReport__CountriesTabItem__Selected" : ""}`}
              style={{ color: countryColor.get(country) }}
              onClick={() => selectMRLWithCountry(country)}
            >
              {country}
            </span>
          ))}
        </HorizontalScroll>
      </div>
      {!selectCountryTab || (reportDetail && !reportDetail[selectCountryTab]) ? (
        <div className="DetailedPesticideReport__SpecNoResult">
          <img
            className="DetailedPesticideReport__SpecNoResultWatermelonImg"
            alt="no result"
            src={watermelon}
          />
          <div className="DetailedPesticideReport__SpecNoResultErrorMessage">
            No Tests Found
          </div>
        </div>
      ) : reportDetail && (
        <Carousel
          showIndicators={false}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          selectedItem={selectedCountries.indexOf(selectCountryTab)}
          swipeable={selectedCountries.length > 1}
        >
          {selectedCountries.map((country) => (
            <table className="DetailedPesticideReport__ResultTable" key={country}>
              <thead className="DetailedPesticideReport__ResultTableHeader">
                <tr>
                  <th className="DetailedPesticideReport__ResultTableHead">Parameter</th>
                  <th className="DetailedPesticideReport__ResultTableHead">MRL</th>
                  <th className="DetailedPesticideReport__ResultTableHead">Results</th>
                </tr>
              </thead>
              <tbody>
                {reportDetail[selectCountryTab] && reportDetail[selectCountryTab].map((detail) => (
                  <tr
                    key={detail.compound_name}
                    className={enableTestSelection ? "tempClass" : ""}
                    style={{ cursor: enableTestSelection ? "pointer" : "default" }}
                  >
                    <td className="DetailedPesticideReport__ResultTableColumn">
                      <div className="DetailedPesticideReport__ResultTableText TruncateText" title={detail.compound_name}>
                        {detail.compound_name}
                      </div>
                    </td>
                    <td title={detail.compound_mrl ? detail.compound_mrl : "-"}>
                      {detail.compound_mrl ? detail.compound_mrl : "-"}
                    </td>
                    <td className="DetailedPesticideReport__ResultTableColumn" style={{ color: detail.color }} title={detail.compound_result}>
                      <div>{detail.compound_result}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </Carousel>
      )}
    </>
  );
}
export default DetailedPesticideReport;

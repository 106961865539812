import React from "react";

import Scrollbar from "react-scrollbars-custom";

import MRLCountryList from "./MRLCountryList";
import SpecManagerSelect from "./SpecManagerSelect";
import SpecsQualitative from "./SpecsQualitative";
import SpecsQuantitative from "./SpecsQuantitative";
import SpecsRange from "./SpecsRange";

import { hasFeature } from "../../../../../utils/common";

import { ReactComponent as MRLIcon } from "../../../../../assets/images/product/MRLIcon.svg";
import { ReactComponent as QualitativeIcon } from "../../../../../assets/images/product/QualitativeIcon.svg";
import { ReactComponent as QuantitativeIcon } from "../../../../../assets/images/product/QuantitativeIcon.svg";
import { ReactComponent as RangeIcon } from "../../../../../assets/images/product/RangeIcon.svg";

import "./EditSpecTable.css";
import "../../../Product.css";

class EditSpecTable extends React.Component {
  // handleChooseDropDown = (optionSelected) => {
  //   this.setState({
  //     dropDownSelection: optionSelected,
  //   });
  // }
  constructor(props) {
    super(props);

    this.state = {
      newQualitative: false,
    };
  }

  handleDataChange = (indexOfRowClicked, fieldName, newFieldValue) => {
    const { dataOfSpecEditing, handleDataChange } = this.props;

    // console.log('dataOfSpecEditing, before: ', dataOfSpecEditing);
    if (fieldName === "test_type") {
      if (dataOfSpecEditing[indexOfRowClicked].test_type !== newFieldValue) {
        dataOfSpecEditing[indexOfRowClicked].test_type = newFieldValue;
        dataOfSpecEditing[indexOfRowClicked].specs = [];

        if (newFieldValue === "qualitative") {
          this.setState({
            newQualitative: true,
          });
        }
      }
    } else if (fieldName === "specs") {
      dataOfSpecEditing[indexOfRowClicked][fieldName] = newFieldValue;
      // console.log('dataOfSpecEditing, after: ', dataOfSpecEditing);
    } else if (fieldName === "countries_lst") {
      // Data update for pesticides
      dataOfSpecEditing.forEach((specEditingData, i) => {
        if (specEditingData.countries_lst) {
          dataOfSpecEditing[i].test_type = "";
          dataOfSpecEditing[i].specs = [];
          dataOfSpecEditing[i][fieldName] = newFieldValue;
        }
      });
    } else {
      dataOfSpecEditing.push({ [fieldName]: newFieldValue, test_type: "", specs: ["", "", ""] });
    }

    handleDataChange(dataOfSpecEditing);
  };

  render() {
    const {
      addingSpec,
      dataOfSpecEditing,
    } = this.props;

    const { newQualitative } = this.state;

    return (
      <Scrollbar
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
        className="productReportEditSpecTableScrollBar"
        id="specManagerEditSpecTableScrollBar"
      >
        <table className="specTable" id="productReportEditSpecTable">
          <thead>
            <tr>
              <th className="specManagerEditSpecTh1">Test</th>
              <th>Test Type</th>
              <th>Specs</th>
            </tr>
          </thead>
          <tbody>
            {dataOfSpecEditing.map((item, i) => (
              <tr key={i}>
                {addingSpec ? (
                  <td className="productReportEditSpecTableTest">
                    {item.test}
                  </td>
                ) : (
                  <td className="productReportEditSpecTableTest">
                    {item.test}
                  </td>
                )}
                <td className="productReportEditSpecTableTestType">
                  {item.countries_lst && hasFeature("special_pesticides") ? (
                    <div className="productReportEditSpecMRL">
                      <MRLIcon />
                      <span className="productReportEditSpecMRLText">MRL</span>
                    </div>
                  ) : (
                    <SpecManagerSelect
                      width="152px"
                      optionSelected={item.test_type || undefined}
                      options={[
                        { value: "range", icon: <RangeIcon /> },
                        { value: "quantitative", icon: <QuantitativeIcon /> },
                        { value: "qualitative", icon: <QualitativeIcon /> },
                      ]}
                      placeholder="Choose Test Type"
                      onSelect={(optionSelected) => this.handleDataChange(i, "test_type", optionSelected)}
                    />
                  )}
                </td>
                <td className="productReportEditSpecTableSpecs">
                  {item.countries_lst && hasFeature("special_pesticides") ? (
                    <MRLCountryList
                      countriesData={item.countries_lst}
                      onChange={(values) => this.handleDataChange(i, "countries_lst", values)}
                      editMode
                    />
                  ) : (
                    <>
                      {item.test_type === "qualitative" && (
                        <SpecsQualitative
                          data={
                            item.specs.length === 0 ? ["", "", ""] : item.specs
                          }
                          handleDataChange={(newSpec) => this.handleDataChange(i, "specs", newSpec)}
                          newQualitative={newQualitative}
                        />
                      )}
                      {item.test_type === "range" && (
                        <SpecsRange
                          data={
                            item.specs.length === 0 ? ["", "", ""] : item.specs
                          }
                          handleDataChange={(newSpec) => this.handleDataChange(i, "specs", newSpec)}
                        />
                      )}
                      {item.test_type === "quantitative" && (
                        <SpecsQuantitative
                          data={
                            item.specs.length === 0 ? ["", "", ""] : item.specs
                          }
                          handleDataChange={(newSpec) => this.handleDataChange(i, "specs", newSpec)}
                        />
                      )}
                    </>
                  )}
                  {item.test_type !== "quantitative"
                    && item.test_type !== "qualitative"
                    && item.test_type !== "range"
                    && !(
                      !!item.countries_lst && hasFeature("special_pesticides")
                    ) && (
                      <span className="productReportEditSpecTableSpecsDefaultValue">
                        Please select test type to view the specs
                      </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Scrollbar>
    );
  }
}

export default EditSpecTable;

import { replicateTagRegexGlobal } from "./Constant";

/**
   * Takes in string and removes replicate tag
   * @param {String} val string that may contain replicate tag
   * @returns {String} string with replicate tag removed
   */
export const removeReplicateTag = (val) => val.replace(replicateTagRegexGlobal, "");

/**
 * Turn an array of keys into an object with keys set to defaultValue
 * @param {Array<String>} keys
 * @param {*} defaultValue any value (i.e Boolean, String)
 * @returns {Object}
 */
export const initializeObjectWithDefaultValue = (keys, defaultValue) => {
  const obj = {};
  keys.forEach((key) => {
    obj[key] = defaultValue;
  });
  return obj;
};

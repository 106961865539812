import React from "react";

import { motion } from "framer-motion/dist/framer-motion";

import TimeFromNow from "../../../../Common/TimeFromNow";
import StyledButton from "../../../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import { CHAR_LIMITS } from "../../Constant";
import AddedSamplesList from "./AddedSamplesList";

import { ReactComponent as Arrow } from "../../../../../assets/images/sample-submission/blueRightArrow.svg";

import "../../SubmissionForm.css";

export default function SubmissionCol({
  sampleList,
  draftLastSavedTime,
  sampleFields,
  sampleFieldsInfo,
  handleEditSample,
  handleDeleteSample,
  sampleEditingIdx,
  sampleListScrollbar,
  handleSubmitButtonClick,
  setClearTestSearch,
  submissionEditingSample,
  updateSample,
  isMaximized,
  compositeRef,
}) {
  /**
   * Checks for errors in the sample list, such as exceeding character limits or missing tests.
   * @return {boolean} True if any sample has an error, false otherwise
   */
  const checkForSampleErrors = () => {
    if (sampleList.length === 0) return true;
    if (sampleEditingIdx > -1) return true;

    return sampleList.some((sample) => {
      const sampleIDCharLimitExceeded = sample.sample_id.length > CHAR_LIMITS.sample_id;
      const sampleTypeCharLimitExceeded = sample.sample_type.length > CHAR_LIMITS.sample_type;
      const sampleTestsMissing = sample.test_list.length === 0;

      return sampleIDCharLimitExceeded || sampleTypeCharLimitExceeded || sampleTestsMissing;
    });
  };

  /**
   * Returns an error message if the submission is in an invalid state.
   * @return {string} An error message if the submission cannot be submitted, otherwise an empty string.
   */
  const getSubmissionErrorText = () => {
    if (!sampleList.length > 0) {
      return "No samples added yet.";
    }
    if (sampleEditingIdx > -1) {
      return "Cannot submit while editing";
    }
    if (checkForSampleErrors()) {
      return "Please correct the invalid sample inputs before proceeding.";
    }
    return "";
  };

  /**
   * Returns the class name for the submit button based on its state.
   * @return {string} The class name for the submit button, including a disabled class if the button is disabled.
   */
  const getSubmitButtonClass = () => {
    const isDisabled = !sampleList.length > 0 || sampleEditingIdx > -1 || checkForSampleErrors(sampleList);
    return `sample-submission-submit-button ${isDisabled ? "sample-submission-submit-button-disabled" : ""}`;
  };

  return (
    <>
      {!isMaximized && (
        <motion.div
          className="new-submission-pages-col submission-column"
        >
          <div className="new-submission-pages-card">
            <div className="new-submission-pages-title-container withBlock">
              <div className="sample-submission-title-block" />
              <span>Submission</span>
            </div>
            <div className="grayDivider" />
            <div className={sampleList.length === 0 ? "num-samples-added-time-div zero-samples" : "num-samples-added-time-div"}>
              <span className="num-samples-added-span">{submissionEditingSample ? "Editing Sample" : `${sampleList.length} Samples added`}</span>
              {sampleList.length > 0 && (<TimeFromNow time={draftLastSavedTime} dateFormat="DD MMM YYYY" />)}
            </div>
            {sampleList.length > 0 && (
              <AddedSamplesList
                sampleList={sampleList}
                sampleFields={sampleFields}
                sampleFieldsInfo={sampleFieldsInfo}
                handleEditSample={handleEditSample}
                handleDeleteSample={handleDeleteSample}
                sampleEditingIdx={sampleEditingIdx}
                sampleListScrollbar={sampleListScrollbar}
                setClearTestSearch={setClearTestSearch}
                submissionEditingSample={submissionEditingSample}
                updateSample={updateSample}
                compositeRef={compositeRef}
              />
            )}
            <StyledTooltip
              title={getSubmissionErrorText()}
              placement="top"
            >
              <StyledButton
                type="primary"
                className={getSubmitButtonClass()}
                onClick={checkForSampleErrors() ? null : handleSubmitButtonClick}
              >
                <span>Submit</span>
                <Arrow aria-hidden width="12" height="12" />
              </StyledButton>
            </StyledTooltip>
          </div>
        </motion.div>
      )}
    </>
  );
}

import React,
{
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";

// import { useRouteMatch } from "react-router";
import { AES, enc } from "crypto-js";

import NoResults from "../Common/NoResults";
import SampleSubmission from "./SampleSubmission";

import { AES_128_BIT_KEY } from "../../utils/Constant";

import { checkValidLink } from "../../actions/sampleSubmission";

import "./SampleSubmissionNav.css";
import pumpkinIcon from "../../assets/images/environment/pumpkin.gif";
import { ReactComponent as EnvironmentIcon } from "../../assets/images/feature-icons/EnvironmentOutlined.svg";
import { ReactComponent as ProductIcon } from "../../assets/images/feature-icons/ProductOutlined.svg";

import { setUserCredentials } from "../../utils/common";

import { AppContext } from "../../AppContext";
import StyledTooltip from "../Common/UIComponents/StyledTooltip";

import { ReactComponent as Close } from "../../assets/images/sample-submission/blueCloseIcon.svg";
import { ReactComponent as Arrow } from "../../assets/images/sample-submission/blueRightArrow.svg";

import SampleFunctionContext from "./SampleFunctionContext";

export default function SampleSubmissionNav({ loggedOut, history }) {
  const isLoggedOut = loggedOut ?? false;
  // const { url, path } = useRouteMatch();
  // const [tabs, setTabs] = useState([]);
  // const [activeTab, setActiveTab] = useState("0");
  const [authorize, setAuthorize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [thirdPartyPayload, setThirdPartyPayload] = useState({});
  const [addressPage, setAddressPage] = useState(true);
  const [showExitModal, setShowExitModal] = useState(false);
  const [exitModal, setExitModal] = useState(false);
  const [onAddBackButton, setOnAddBackButton] = useState(false);
  const [submissionNameNav, setSubmissionNameNav] = useState("");
  const [category, setCategory] = useState(null);
  const appContext = useContext(AppContext);
  const backRef = useRef();
  const closeRef = useRef();

  /**
 * Handles checking the validity of a link and setting user credentials accordingly
 * @param {Object} params - Parameters for checking the link validity
 */
  async function handleCheckValidLink(params) {
    setLoading(true);
    const { success } = await checkValidLink(params);
    if (success) {
      setUserCredentials("session", params, appContext);
      setThirdPartyPayload(params);
      setAuthorize(true);
      setLoading(false);
      // setTabs([params.type]);
    } else {
      setAuthorize(false);
      setLoading(false);
    }
  }

  /** Set tabs */
  useEffect(() => {
    if (isLoggedOut) {
      const encrypted = history.location.search.slice(1);
      const decrypted = AES.decrypt(encrypted, AES_128_BIT_KEY).toString(enc.Utf8);
      const searchParams = new URLSearchParams(decrypted);
      if (searchParams.size > 0) { // Invited link
        const params = {
          company_name: searchParams.get("company_name"),
          company_domain: searchParams.get("company_domain"),
          user_payload: searchParams.get("user_payload"),
          user_email: searchParams.get("invited_email"),
          user_name: searchParams.get("invited_email"),
          invited_email: searchParams.get("invited_email"),
          type: searchParams.get("type"),
          sample_category: searchParams.get("type"),
          mrl: searchParams.get("mrl"),
          link: window.location.href,
          user_title: "Editor",
          thirdParty: true,
        };
        sessionStorage.clear();
        handleCheckValidLink(params);
      }
    }
  }, []); // eslint-disable-line

  if (loading) {
    return (
      <div className="SampleSubmission__InvalidLInk">
        <NoResults image={pumpkinIcon} message="Loading..." />
      </div>
    );
  }

  if (isLoggedOut && !authorize) {
    return (
      <div className="SampleSubmission__InvalidLInk">
        <NoResults image={pumpkinIcon} message="You do not have permission to access this page." />
      </div>
    );
  }
  /**
   * useImperativeHandle to back function
   */
  const triggerBackFunction = () => {
    if (backRef.current) {
      backRef.current.backFunction();
    }
  };
  /**
   * useImperativeHandle to exit function
   */
  const triggerCloseFunction = () => {
    if (closeRef.current) {
      closeRef.current.closeFunction();
    }
  };

  return (
    <SampleFunctionContext.Provider value={{
      backRef,
      closeRef,
      setShowExitModal,
      setOnAddBackButton,
    }}
    >
      <div className={`SampleSubmission__ParentContainer ${loggedOut ? "logged-out" : ""}`}>
        {(!addressPage)
          && (
          <div className="SampleSubmissionForm__Nav">
            <div className="SampleSubmissionForm__Nav_title">
              { (onAddBackButton ? !onAddBackButton : (!showExitModal || thirdPartyPayload.thirdParty))
              && (
              <StyledTooltip
                title="Go Back"
                placement="top"
              >
                <Arrow
                  role="button"
                  tabIndex={0}
                  // title="back button"
                  width="14.67"
                  height="14.67"
                  className="sample-submission-form-back-button-nav "
                  onClick={triggerBackFunction}
                  onKeyDown={(e) => {
                    if ((e.key === "Enter" || e.key === " ")) {
                      triggerBackFunction();
                    }
                  }}
                />
              </StyledTooltip>
              )}
              <span>
                {category === "product" && <ProductIcon width="16" height="16" />}
                {category === "environment" && <EnvironmentIcon width="16" height="16" /> }
                &nbsp;
                Submission Name:&nbsp;
                {submissionNameNav}
              </span>
            </div>
            { !thirdPartyPayload.thirdParty
            && (
            <StyledTooltip
              title="Go to Main Page"
              placement="top"
            >
              <Close
                role="button"
                tabIndex={0}
                // title="back button"
                width="14.67"
                height="14.67"
                className="sample-submission-form-close-button"
                onClick={() => {
                  if (!showExitModal) {
                    triggerCloseFunction();
                  } else {
                    setExitModal(true);
                  }
                }}
                onKeyDown={(e) => {
                  if ((e.key === "Enter" || e.key === " ")) {
                    if (!showExitModal) {
                      triggerCloseFunction();
                    } else {
                      setExitModal(true);
                    }
                  }
                }}
              />
            </StyledTooltip>
            )}
          </div>
          )}
        <SampleSubmission
          history={history}
          isLoggedOut={isLoggedOut}
          thirdPartyPayload={thirdPartyPayload}
          setAddressPage={setAddressPage}
          setSubmissionNameNav={setSubmissionNameNav}
          setShowExitModal={setShowExitModal}
          exitModal={exitModal}
          setExitModal={setExitModal}
          setCategory={setCategory}
        />
      </div>
    </SampleFunctionContext.Provider>
  );
}

import React from "react";
import Modal from "antd/es/modal/Modal";
import "./DeleteMemberModal.css";

const DeleteMemberModal = ({
  onConfirm,
  onClose,
}) => (
  <Modal
    open
    closable={false}
    centered
    footer={null}
    onCancel={() => onClose()}
    width="448px"
    wrapClassName="DeleteMemberModel"
    className="DeleteMemberModelContainer"
  >
    <div className="deleteMemberModal">
      <div
        className="deleteMemberModalHeader"
      >
        Delete Member Confirmation
      </div>
      <div
        className="deleteMemberModalText"
      >
        All data related to the member will be removed from this company. Are you sure you want to proceed?
      </div>
      <div className="deleteMemberModalBtns">
        <button
          type="button"
          onClick={() => onClose()}
          className="deleteMemberCancelBtn"
        >
          No
        </button>
        <button
          type="button"
          onClick={() => onConfirm()}
          className="deleteMemberConfirmBtn"
        >
          Yes
        </button>
      </div>
    </div>
  </Modal>
);

export default DeleteMemberModal;

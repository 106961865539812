import React from "react";
import "./ReportsTableActionsCell.css";
import ReportButtons from "../Components/ReportButtons/ReportButtons";

export default function ReportsTableActionsCell({
  item,
}) {
  return (
    <div className="ReportsTable__ActionsCellDiv">
      <ReportButtons
        item={item}
      />
    </div>
  );
}

import React from "react";

import StyledButton from "../../Common/UIComponents/StyledButton";
import CustomDateFrequencyForm from "./CustomDateFrequencyForm";
import MonthFrequencyForm from "./MonthFrequencyForm";
import WeekFrequencyForm from "./WeekFrequencyForm";

import { ReactComponent as CheckCircleIcon } from "../../../assets/images/environment/CheckCircleIcon.svg";

import "./FrequencyForm.css";

export default function FrequencyForm({
  updateSamplingData,
  selectedRecurringType,
  setSelectedRecurringType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  weekDays,
  setWeekDays,
  dayOfMonth,
  setDayOfMonth,
  selectedCustomDatesList,
  setSelectedCustomDatesList,
}) {
  const TABSDATA = {
    weekly: {
      label: "Weekly",
      content: (
        <WeekFrequencyForm
          allowMultiple
          updateSamplingData={updateSamplingData}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          weekDays={weekDays}
          setWeekDays={setWeekDays}
        />)
      ,
    },
    monthly: {
      label: "Monthly",
      content: (
        <MonthFrequencyForm
          allowMultiple
          updateSamplingData={updateSamplingData}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          dayOfMonth={dayOfMonth}
          setDayOfMonth={setDayOfMonth}
        />
      ),
    },
    custom: {
      label: "Custom Dates",
      content: (
        <CustomDateFrequencyForm
          updateSamplingData={updateSamplingData}
          selectedDatesList={selectedCustomDatesList}
          setSelectedDatesList={setSelectedCustomDatesList}
        />
      ),
    },
  };

  /**
   * Handle frequency form tab change
   * @param {String} tabname tab name
   */
  const handleTabChange = (tabname) => {
    setSelectedRecurringType(tabname);
  };

  return (
    <div className="FrequencyForm__Container">
      <div className="FrequencyForm__TabButtonContainer">
        {Object.entries(TABSDATA).map(([key, tabdata]) => (
          <StyledButton
            key={key}
            htmlType="button"
            type={selectedRecurringType === key ? "primary" : "default"}
            onClick={() => handleTabChange(key)}
          >
            {selectedRecurringType === key && <CheckCircleIcon />}
            {tabdata.label}
          </StyledButton>
        ))}
      </div>
      <div className="FrequencyForm__Content">
        {TABSDATA[selectedRecurringType].content}
      </div>
    </div>
  );
}

import React from "react";

export default function NameAndPhone({
  showEdit,
  name,
  phone,
  profile,
  handleTextChange,
  handleSaveCancelClick,
  handleSaveEditClick,
  handleEditClick,
  loading,
}) {
  if (showEdit) {
    return (
      <>
        <div className="myAccountProfileInputContainer">
          <div className="myAccountFormTitle">
            name
          </div>
          <div className="my-profile-edit-name">
            <input
              type="text"
              placeholder="Name"
              className="myaccountEditInput myaccountEditInputWidth"
              defaultValue={name}
              onChange={(event) => handleTextChange(event, "name")}
            />
          </div>
          <div className="myAccountFormTitle">
            PHONE
          </div>
          <div className="my-profile-edit-phone">
            <input
              type="text"
              placeholder="Phone"
              className="myaccountEditInput myaccountEditInputWidth"
              defaultValue={phone}
              onChange={(event) => handleTextChange(event, "phone")}
            />
          </div>
        </div>
        <div className={loading ? "my-profile-cancel-submit-buttons my-profile-loading" : "my-profile-cancel-submit-buttons"}>
          <button
            type="button"
            onClick={() => handleSaveCancelClick()}
            className="myAccountCancelBtn"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => handleSaveEditClick()}
            className="myAccountEditBtn my-profile-submit-button"
          >
            Submit
          </button>
        </div>
      </>
    );
  }

  return (
    <div className="myAccountProfileInputContainer">
      <div className="myAccountFormTitle">
        name
      </div>
      <input
        type="text"
        placeholder="Name"
        defaultValue={profile.name}
        className="myaccountDefaultInput my-profile-name-input-disabled"
        disabled
      />
      <div
        className="myAccountEditProfile"
        onClick={() => handleEditClick("all")}
      >
        Edit profile
      </div>
      <div className="myAccountFormTitle">
        PHONE
      </div>
      <input
        type="text"
        placeholder="Phone"
        defaultValue={profile.phone}
        className="myaccountDefaultInput"
        disabled
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import CustomMuiSelect from "../../../Common/CustomMuiSelect/CustomMuiSelect";
import "../SignUpInvite.css";
import "./FieldInput.css";
import "./DropdownFieldInput.css";
import "./CustomFieldInput.css";

export default function DropdownFieldInput({
  type,
  field,
  label,
  inputClassName,
  selectContainerClassName,
  setRef,
  errorsFromParent,
  setFieldError,
  getFieldError,
  disableCheckboxes,
  multiselect,
  options,
  id,
  isSendingInvite,
}) {
  const [error, setError] = useState(getFieldError(field, type));

  useEffect(() => {
    setError(getFieldError(field, type));
  }, [errorsFromParent]); // eslint-disable-line

  const handleOptionSelect = () => {
    if (error) {
      setError(false);
      setFieldError(field, false, type);
    }
  };

  return (
    <div className={`signup-invite-input ${inputClassName ?? ""}`}>
      <div className="signup-invite-input-label">
        {label && (
        <label
          htmlFor={id ?? `signup-invite-fixed-input-${label}`}
        >
          {label}
        </label>
        ) }
      </div>
      <div className={selectContainerClassName ?? "FieldInputParentDiv DropdownFieldInput"}>
        <CustomMuiSelect
          disable={isSendingInvite}
          disableCheckboxes={disableCheckboxes}
          multiselect={multiselect}
          options={options}
          ref={(input) => setRef(field, input, type)}
          handleOptionSelect={handleOptionSelect}
        />
      </div>
    </div>
  );
}

import React from "react";
import "flatpickr/dist/themes/material_green.css";
import { ReactComponent as CalendarIcon } from "../../../../../../assets/images/environment/calendarIcon.svg";
import "./EnvCalendar.css";
import DatePicker from "../../../../../Common/DatePicker/DatePicker";

class EnvCalendar extends React.Component {
  state = {
    dateSelected: "",
    prevDateSelected: "",
  };

  clearCalendar = () => {
    this.setState({
      dateSelected: "",
      prevDateSelected: "",
    });
  }

  displayDateSelected = (dateSelected) => {
    this.setState({ dateSelected: dateSelected === "" ? "" : dateSelected });
  }

  render() {
    const {
      dateSelected,
      prevDateSelected,
    } = this.state;

    const {
      handleFilterByDate,
      getCalendarDates,
      hideFilterButton,
    } = this.props;

    return (
      <DatePicker
        handleSearchDate={handleFilterByDate}
        getReportDates={getCalendarDates}
        value={dateSelected}
        onChange={this.displayDateSelected}
        prevValue={prevDateSelected}
        setPrevValue={(val) => this.setState({ prevDateSelected: val })}
        searchOnClear
        popoverPlacement="bottomRight"
        popoverClassName="EnvCalendarPopover"
        datePickerDisplayProps={{
          displayClassName: `ListViewCalendar${hideFilterButton ? "--hidden" : ""}`,
          iconTooltipTitle: "Sort by date",
          customCalendarIcon: <CalendarIcon width={24} height={24} />,
          displayTextPosition: "left",
        }}
      />
    );
  }
}

export default EnvCalendar;

import React, { useState } from "react";
import { useLocation } from "react-router";
// import OnboardingForm from "./OnboardingForm";
import StatusPage from "../StatusPage";
import "./OnboardingFormController.css";
import OnboardingForm from "./OnboardingForm";

export default function OnboardingFormController({ history }) {
  const { pathname } = useLocation();
  const [statusMessage, setStatusMessage] = useState("");

  if (pathname === "/onboarding/success") {
    return (
      <StatusPage
        className="OnboardingForm__StatusPage"
        status="success"
        title="Signup Successful"
        subtitle={statusMessage}
        showLoginButton
        history={history}
      />
    );
  }

  return (<OnboardingForm history={history} statusMessage={statusMessage} setStatusMessage={setStatusMessage} />);
}

import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
// import DragClose from "../../../../../../assets/images/product/template/DragClose.png";
import "./Draggable.css";
import "./COABuilderDraggable.css";
import COABuilderInput from "../Center/CustomCOABuilderCenter/Inputs/COABuilderInput";
import COABuilderSelect from "../Center/CustomCOABuilderCenter/Inputs/COABuilderSelect";

export default function COABuilderDraggable({
  deleteInput = () => { },
  dataFieldOptions = [],
  index,
  input,
  setRef,
}) {
  const [isEditing, setIsEditing] = useState(false);

  const isDataField = input.flag !== "0";

  const handleDelete = (e) => {
    if (e) {
      e.stopPropagation();
    }
    deleteInput(index);
  };

  const toggleEdit = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setIsEditing(!isEditing);
  };

  return (
    <Draggable key={input.id} draggableId={input.id} index={index} isDragDisabled={isEditing}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps} //eslint-disable-line
          {...provided.dragHandleProps} //eslint-disable-line
          className={`COABuilderDraggable${isEditing ? "--disabled" : ""}`}
        >
          {input.flag === "1" ? (
            <COABuilderSelect
              id={input.id}
              placeholder="Select Value"
              setRef={(inputElem) => setRef(index, inputElem)}
              options={dataFieldOptions.map((val) => ({ label: val, value: val }))}
              defaultValue={input.defaultValue || undefined}
              label={input.fieldName}
              handleDelete={handleDelete}
              isEditing={isEditing}
              toggleEdit={toggleEdit}
            />
          ) : (
            <COABuilderInput
              id={input.id}
              placeholder="Enter Text"
              setRef={(inputElem) => setRef(index, inputElem)}
              defaultValue={input.defaultValue || undefined}
              label={isDataField ? input.fieldName : undefined}
              handleDelete={handleDelete}
              isEditing={isEditing}
              toggleEdit={toggleEdit}
              isTextArea={input.type === "largeTextBox"}
            />
          )}
        </div>
      )}
    </Draggable>
  );
}

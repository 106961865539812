import React, {
  useCallback, useEffect, useMemo, useRef,
} from "react";

import { pick } from "lodash";
import { Scrollbar } from "react-scrollbars-custom";
import {
  Table,
  AutoSizer,
  CellMeasurer,
  Column,
  CellMeasurerCache,
} from "react-virtualized";

import NoResults from "../../Common/NoResultsGIF";
import StyledTooltip from "../../Common/UIComponents/StyledTooltip";
import CustomCheckbox from "./CustomCheckbox";
import ExtraFieldsAutoComplete from "./ExtraFieldAutoComplete";
import SelectLocationFormSearch from "./SelectLocationFormSearch";

import { ExtraFieldValueType, populateObjectValues } from "../utils";

import { getSamplingLocationData } from "../../../actions/envCalendar";

import pumpkinGIF from "../../../assets/images/environment/pumpkin.gif";

import "./SelectLocationForm.css";
import "react-virtualized/styles.css";

export default function SelectLocationForm({
  searchValueRef,
  markedLocations,
  setMarkedLocations,
  locationData,
  extraFields,
  linkPatternFields,
  showSearchbar = true,
  selectLocationFormLoader,
  isEditing,
  locationId,
  getExtraFieldData,
  updateExtraField,
  locationCheckboxLoading,
  setLocationCheckboxLoading,
  fetchLocationEnvData,
}) {
  const isEditExtraFieldDataFetched = useRef(false);
  const tableRef = useRef();
  const controller = useRef();

  const cache = useMemo(() => new CellMeasurerCache({
    defaultHeight: 65,
    fixedHeight: true,
  }), []);

  /**
   * Handle scrolling of grid
   * @param {Event} e event
   */
  const handleScroll = (e) => {
    const { scrollTop, scrollLeft } = e;
    const table = tableRef.current;
    table.handleScrollEvent({ scrollTop, scrollLeft });
  };

  /**
   * Calls ssautofill/ api and returns extra field options and test list
   * @param {*} sampleData sample data
   * @param {*} location location data
   */
  const apiSSAutofill = async (sampleData, location) => {
    const abortController = new AbortController();
    if (controller.current) {
      controller.current.abort();
    }
    controller.current = abortController;

    const resp = await getSamplingLocationData(sampleData, location.location_id, "environment", controller.current.signal);

    if (controller.current?.signal?.aborted) {
      controller.current = null;
      return { fieldOptions: {}, test_list: [] };
    }

    if (resp && resp.success) {
      controller.current = null;
      const { test_list, ...rest } = resp.result;
      return { fieldOptions: rest, test_list: test_list ?? [] };
    }
    controller.current = null;
    return { fieldOptions: {}, test_list: [] };
  };

  /**
   * Set extra field dropdown data initially when it's editing or in the reports tab when locationId is passed to the component.
   * Calls the ssautofill/ api and updates -
   *  1. Selected locations sample_id_fields and test_list valuess
   *  2. Extra field options for selected locations initially, and if api doesn't return options,
   *      it doesn't update the options field.
   *  3. Update the test list for each row, which was fetched from ssautofill/ api
   */
  const setExtraFieldsDropdown = useCallback(async (location) => {
    if ((isEditing || locationId) && markedLocations.has(location.location_id)) {
      const updatedMarkedLocations = new Map(Array.from(markedLocations));

      const linkFieldsToPick = linkPatternFields.map((f) => f.json_field);
      const linkFieldsValues = pick(location.sample_id_fields, linkFieldsToPick);

      const extraFieldKeys = extraFields.map((f) => f.json_field);
      const extraFieldValues = getExtraFieldData(ExtraFieldValueType.VALUES, location.location_id);
      const extras = populateObjectValues(extraFieldKeys, extraFieldValues);

      setLocationCheckboxLoading(location.location_id);
      const { fieldOptions, test_list: fieldTestList } = await apiSSAutofill({
        ...linkFieldsValues, ...extras,
      }, location);
      setLocationCheckboxLoading(null);

      // Update marked locations
      updatedMarkedLocations.set(location.location_id, {
        sample_id_fields: {
          ...linkFieldsValues, ...extras,
        },
        test_list: Array.from(new Set([...location.test_list, ...(fieldTestList ?? [])])),
        location_id: location.location_id,
      });

      // Update all extra fields options in a row, but skips if ssautofill/ doesn't return any value or fails
      if (Object.keys(fieldOptions).length > 0) {
        updateExtraField(ExtraFieldValueType.OPTIONS, location.location_id, fieldOptions);
      }

      // Updates test list for a row
      updateExtraField(ExtraFieldValueType.TEST_LIST, location.location_id, fieldTestList);

      setMarkedLocations(updatedMarkedLocations);
    }
  }, [isEditing, locationId, linkPatternFields, getExtraFieldData]); // eslint-disable-line

  /**
   * Initially call the setExtraFieldsDropdown() for calling the ssautofill/ api and setting dropdown values
   */
  useEffect(() => {
    if ((isEditing || locationId) && markedLocations.size && linkPatternFields.length > 0 && extraFields.length > 0 && !isEditExtraFieldDataFetched.current) {
      const promiseArr = [];
      markedLocations.forEach((location) => {
        promiseArr.push(setExtraFieldsDropdown(location));
      });
      Promise.all(promiseArr);
      isEditExtraFieldDataFetched.current = true;
    }
  }, [markedLocations, isEditing, locationId, linkPatternFields, extraFields]);  // eslint-disable-line

  /**
   * Calls ssautofill/ and updates extra fields and test list on change of extra fields
   * @param {*} location location data
   * @param {*} extraFieldValues extra field values
   */
  const fetchAndUpdateExtraFields = async (location, linkFields, extraFieldValues, updateSelectedLocations = true) => {
    const linkFieldsToPick = linkFields.map((f) => f.json_field);
    const linkFieldsFromLocation = pick(location, linkFieldsToPick);

    const extraFieldKeys = extraFields.map((f) => f.json_field);
    const extras = populateObjectValues(extraFieldKeys, extraFieldValues);

    setLocationCheckboxLoading(location.location_id);
    const { fieldOptions, test_list: fieldTestList } = await apiSSAutofill({
      ...linkFieldsFromLocation, ...extras,
    }, location);
    setLocationCheckboxLoading(null);

    // Update extra field options and test list
    updateExtraField(ExtraFieldValueType.OPTIONS, location.location_id, fieldOptions);
    updateExtraField(ExtraFieldValueType.TEST_LIST, location.location_id, fieldTestList);

    const updatedMarkedLocations = new Map(markedLocations);
    /**
     * Update the map when
     * 1. Checkbox is on change (updateSelectedLocations = true)
     * 2. Checkbox is checked but other fields are changing (updateSelectedLocations = false and updatedMarkedLocations.has(location.location_id))
     */
    if (updateSelectedLocations || updatedMarkedLocations.has(location.location_id)) {
      updatedMarkedLocations.set(location.location_id, {
        sample_id_fields: {
          ...linkFieldsFromLocation, ...extras,
        },
        test_list: Array.from(new Set([...location.test_list, ...(fieldTestList ?? [])])),
        location_id: location.location_id,
      });
    }
    setMarkedLocations(updatedMarkedLocations);
  };

  /**
   * Handle checking and selecting location
   * 1. When checked, select location row and calls ssautofill/ api for updating extra fields
   * 2. When unchecked, disselect location and reset extra field options and test list for the row
   * @param {*} e Event
   * @param {*} location Location
   */
  const handleCheck = async (e, location) => {
    const updatedMarkedLocations = new Map(markedLocations);
    if (e.target.checked) {
      const extraFieldValues = getExtraFieldData(ExtraFieldValueType.VALUES, location.location_id);

      await fetchAndUpdateExtraFields(location, linkPatternFields, extraFieldValues);
    } else {
      updatedMarkedLocations.delete(location.location_id);
      setMarkedLocations(updatedMarkedLocations);
      updateExtraField(ExtraFieldValueType.OPTIONS, location.location_id, {});
      updateExtraField(ExtraFieldValueType.TEST_LIST, location.location_id, null);
    }
  };

  /**
   * Handle input/select in extra field on change
   * 1. Updates extra field value
   * 2. Calls ssautofill/ api with updates extra values
   * @param {*} value Value
   * @param {*} json_field json_field
   */
  const handleExtraFieldSelect = async (value, location, json_field) => {
    const extraFieldValues = getExtraFieldData(ExtraFieldValueType.VALUES, location.location_id);
    const extraFieldKeys = extraFields.map((f) => f.json_field);
    const updated = populateObjectValues(extraFieldKeys, extraFieldValues);
    if (!value) {
      updated[json_field] = "";
    } else {
      updated[json_field] = value;
    }
    updateExtraField(ExtraFieldValueType.VALUES, location.location_id, updated);

    await fetchAndUpdateExtraFields(location, linkPatternFields, updated, false);
  };

  const rowGetter = useCallback(({ index }) => locationData[index], [locationData]);

  return (
    <div className="SelectLocationForm">
      {showSearchbar && (
        <SelectLocationFormSearch
          searchValueRef={searchValueRef}
          fetchLocationEnvData={fetchLocationEnvData}
        />
      )}
      {selectLocationFormLoader && <div className="SelectLocationForm__Loader ActiveloadingAnimation" />}
      {!selectLocationFormLoader && (
        <AutoSizer>
          {(({ height, width }) => (
            <Scrollbar
              style={{ height, width }}
              className="SelectLocationForm__TableScrollbar"
              onScroll={handleScroll}
              native={false}
            >
              <Table
                ref={tableRef}
                width={width}
                height={height - 40}
                headerHeight={65}
                rowHeight={cache.defaultHeight}
                rowCount={locationData.length}
                rowGetter={rowGetter}
                className="SelectLocationForm__Table"
                overscanRowCount={5}
              >
                <Column
                  label={markedLocations.size || "#"}
                  dataKey="#"
                  width={40}
                  className="SelectLocationForm__TableCheckboxCell"
                  headerClassName="SelectLocationForm__TableCheckboxHeader"
                  cellRenderer={({
                    dataKey, rowIndex, parent, rowData: location, columnIndex,
                  }) => (
                    <CellMeasurer
                      cache={cache}
                      columnIndex={columnIndex}
                      key={dataKey}
                      parent={parent}
                      rowIndex={rowIndex}
                    >
                      <CustomCheckbox
                        handleCheck={handleCheck}
                        location={location}
                        locationCheckboxLoading={locationCheckboxLoading}
                        markedLocations={markedLocations}
                      />
                    </CellMeasurer>
                  )}
                />
                {linkPatternFields.map((field, i) => (
                  <Column
                    key={field.json_field}
                    label={field.title_field}
                    dataKey={field.json_field}
                    width={cache.defaultWidth}
                    className={i === 0 ? "SelectLocationForm__TableZone" : ""}
                    headerClassName={i === 0 ? "SelectLocationForm__TableZone" : ""}
                    cellRenderer={({
                      dataKey, rowIndex, parent, cellData, columnIndex,
                    }) => (
                      <CellMeasurer
                        cache={cache}
                        columnIndex={columnIndex}
                        key={dataKey}
                        parent={parent}
                        rowIndex={rowIndex}
                      >
                        <StyledTooltip title={cellData} placement="topLeft">
                          <div className="SelectLocationForm__TableContent">
                            {cellData}
                          </div>
                        </StyledTooltip>
                      </CellMeasurer>
                    )}
                  />
                ))}
                {extraFields.map((field) => (
                  <Column
                    key={field.json_field}
                    label={field.title_field}
                    dataKey={field.json_field}
                    width={cache.defaultWidth}
                    minWidth={120}
                    cellRenderer={({
                      dataKey, rowIndex, parent, rowData: location, columnIndex,
                    }) => {
                      const extraFieldOptions = getExtraFieldData(ExtraFieldValueType.OPTIONS, location.location_id);
                      const options = extraFieldOptions[field.json_field] ?? [];
                      // ? extraFieldOptions[field.json_field].map((op) => ({ label: op, value: op }))
                      // : [];
                      const extraFieldValues = getExtraFieldData(ExtraFieldValueType.VALUES, location.location_id);
                      return (
                        <CellMeasurer
                          cache={cache}
                          columnIndex={columnIndex}
                          key={dataKey}
                          parent={parent}
                          rowIndex={rowIndex}
                        >
                          {/* <div className="SelectLocation__ExtraField__Autocomplete"> */}
                          {/* <ErrorCheckingAutocompleteInput
                              ref={() => { }}
                              label=""
                              labelDivClassName="SelectLocation__ExtraField__AutocompleteLabel"
                              inputID={`extra-autocomplete-input-${field.json_field}-${rowIndex}`}
                              value={extraFieldValues[field.json_field]}
                              suggestions={options}
                              handleSelectOption={(value) => handleExtraFieldSelect(value.trim(), location, field.json_field)}
                              onChange={(value) => handleExtraFieldSelect(value, location, field.json_field)}
                              onErrorChange={() => { }}
                              customClassName="SelectLocation__ExtraField__AutocompleteContainer"
                              placeholderText={field.title_field}
                              alwaysShowDropdownIcon={false}
                            /> */}
                          <AutocompleteExtraField
                            extraFieldValues={extraFieldValues}
                            field={field}
                            options={options}
                            location={location}
                            handleExtraFieldSelect={handleExtraFieldSelect}
                            markedLocations={markedLocations}
                          />
                          {/* </div> */}
                        </CellMeasurer>
                      );
                    }}
                  />
                ))}
                <Column
                  label="Total Tests"
                  dataKey="num_tests"
                  width={cache.defaultWidth}
                  headerClassName="SelectLocationForm__TableContentTotalTests"
                  cellRenderer={({
                    dataKey, rowIndex, parent, rowData: location, columnIndex,
                  }) => {
                    const testListFromSSAutoFill = getExtraFieldData(ExtraFieldValueType.TEST_LIST, location.location_id);
                    const total = markedLocations.has(location.location_id) ? markedLocations.get(location.location_id).test_list.length : testListFromSSAutoFill ? testListFromSSAutoFill.length : location.num_tests; // eslint-disable-line
                    return (
                      <CellMeasurer
                        cache={cache}
                        columnIndex={columnIndex}
                        key={dataKey}
                        parent={parent}
                        rowIndex={rowIndex}
                      >
                        <StyledTooltip title={total}>
                          <div className="SelectLocationForm__TableContentTotalTests SelectLocationForm__TableContent">
                            {total}
                          </div>
                        </StyledTooltip>
                      </CellMeasurer>
                    );
                  }}
                />
              </Table>
              {locationData.length === 0 && (
                <NoResults
                  className="SelectLocationForm__NoResult"
                  image={pumpkinGIF}
                  message="No Result Found"
                />
              )}
            </Scrollbar>
          ))}
        </AutoSizer>
      )}
    </div>
  );
}

function AutocompleteExtraField({
  extraFieldValues,
  field,
  options,
  location,
  handleExtraFieldSelect,
  markedLocations,
}) {
  const handle = (value) => {
    handleExtraFieldSelect(value, location, field.json_field);
  };

  return (
    <ExtraFieldsAutoComplete
      className="SelectLocation__ExtraField__AutocompleteContainer"
      value={extraFieldValues[field.json_field]}
      suggestions={options}
      onSelect={handle}
      onSearch={handle}
      allowClear
      placeholderText={field.title_field}
      markedLocations={markedLocations}
    />
  );
}

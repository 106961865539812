import axios from "axios";
import { API_URL } from "../utils/Constant";
import { getUserCredentials } from "../utils/common";

// http://127.0.0.1:8000/api/editprofile/?
// user_payload=uBYMoUVAAMhznC0N0gGV&
// name=Arun&
// email=arun@eatsafeverified.com&
// contact=4084114789
export const updateProfileInfo = async (payload) => {
  try {
    const response = await axios.get(`${API_URL}/editprofile/`, {
      params: {
        name: payload.name || "",
        email: payload.email || "",
        contact: payload.contact || "",
      },
    });
    const data = await response.data;
    return { success: true, ...data };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

// http://127.0.0.1:8000/api/deletemember/?
// user_payload=uBYMoUVAAMhznC0N0gGV&
// email=harshini@eatsafeverified.com
export const deleteTeamMember = async (payload) => {
  try {
    const user = await getUserCredentials();
    const response = await axios.patch(`${API_URL}/membereditdelete/`, {
      disableInterceptor: true,
      company_name: user.company_name,
      member_user_payload: payload.user_payload, // member's user_payload not the logged in user's (admin)
      email: payload.email,
      action_category: "delete",
    });
    const data = await response.data;
    if (data.message === "Success") {
      return { success: true, ...data };
    }
    return { success: false, ...data };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const editMemberRole = async (payload) => {
  try {
    const user = await getUserCredentials();
    const response = await axios.patch(`${API_URL}/membereditdelete/`, {
      disableInterceptor: true,
      company_name: user.company_name,
      member_user_payload: payload.user_payload, // member's user_payload not the logged in user's (admin)
      email: payload.email,
      action_category: "edit",
      new_title: payload.new_title,
    });
    const data = await response.data;
    // console.log("==== deleteTeamMember data", data);

    if (data.message === "Success") {
      return { success: true, ...data };
    }
    return { success: false, ...data };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

// http://127.0.0.1:8000/api/companyuser/?
// company_domain=eatsafeverified.com&
// user_payload=dRYqmmTgLvzicql
export const getMembersInfo = async () => {
  try {
    const response = await axios.get(`${API_URL}/companyuser/`);
    const data = response.data;
    let res = { members: [] };

    if (data?.result?.user_list?.length > 0) {
      const { user_list } = data.result;
      const temp = [];
      for (let i = 0; i < user_list.length; i++) {
        if (!user_list[i].user_email.includes("esv-")) {
          temp.push(user_list[i]);
        }
      }
      res = { members: temp };
    }

    return { success: true, ...res };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

// get card list card
export const getSettingInfo = async () => {
  try {
    // const user = await getUserCredentials();
    // const response = await axios.get(`${API_URL}/cardlibrary/`, {
    //   user_payload: user.user_payload || "",
    //   category: "Add",
    //   list: [{
    //     card_name: payload.name,
    //     description: payload.description,
    //     data_link: payload.data_link,
    //     table: {
    //       content_type: "22",
    //       title: "",
    //       sub_category: payload.table
    //     },
    //     linked_list: payload.linked_list,
    //     tags: payload.tags
    //   }]
    // });
    // const data = await response.data;

    // var res = { cardList: [] };
    // if (data && data.result && data.result.length > 0) {
    //   res = { cardList: data.result };
    // }

    const res = {
      setting: {
        address: "",
      },
    };
    // if (data && data.length > 0) {
    //   var result = data[0].result;
    // }

    res.setting = {
      address: "Golden Gate Bridge, San Francisco, CA",
    };

    return { success: true, ...res };
  } catch (e) {
    // console.log("error from updateProduct", e);
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

// http://127.0.0.1:8000/api/help/
// {
// "user_payload" : "uBYMoUVAAMhznC0N0gGV",
// "query" : "How to un link a report on product reports tab"
// }
export const sendHelp = async (payload) => {
  try {
    const params = {
      query: payload,
    };
    const response = await axios.post(`${API_URL}/help/`, params);
    const data = await response.data;

    return { success: true, ...data };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

import { useRef } from "react";

/**
 * Custom hook to handle cancelling api calls.
 */
const useAbortController = () => {
  const abortController = useRef(null);

  /**
   * If there is a current abort controller, a call is in progress so abort it.
   * Initialize a new controller. Set abortController to this new controller.
   * @return {AbortController} the new abort controller
   */
  const setNewAbortController = () => {
    const newAbortController = new AbortController();
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = newAbortController;
    return newAbortController;
  };

  /**
   * When the current api call is finished, set abortController back to null.
   */
  const clearAbortController = () => {
    abortController.current = null;
  };

  return {
    abortController, setNewAbortController, clearAbortController,
  };
};

export default useAbortController;

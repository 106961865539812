import React from "react";
import {
  View, StyleSheet, Text,
} from "@react-pdf/renderer";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { softHyphenate } from "./pdfUtils";

const styles = StyleSheet.create({
  presenceAheadView: {
    marginTop: "-14",
  },
  table: {
    // borderLeft: "1 solid #E1E1E1",
    // borderRight: "1 solid #E1E1E1",
    // borderBottom: "1 solid #E1E1E1",
    // borderTop: "1 solid #E1E1E1",
    // borderRadius: "6",
  },
  sampleID: {
    color: "#1f2d3b",
    fontSize: "12",
    fontFamily: "Roboto-500",
    width: "100%",
    wordWrap: "break-word",
    textAlign: "center",
    padding: "23 75 14 75",
    borderLeft: "1 solid #E1E1E1",
    borderRight: "1 solid #E1E1E1",
  },
  tableBottomBorder: {
    color: "#505050",
    padding: "11 24 0 24",
    border: "1 solid #E1E1E1",
    borderRadius: "6",
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
    borderTop: "0",
  },
  tableTopBorder: {
    color: "#505050",
    padding: "0 24 14 24",
    border: "1 solid #E1E1E1",
    borderRadius: "6",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    borderBottom: "0",
  },
  minPresText: {
    position: "absolute",
    top: "10",
    left: "0",
    color: "red",
    fontSize: "12",
    fontFamily: "Roboto-500",
  },
});
export default function PDFTable({
  sample_id,
  test_results,
}) {
  function getNumRowsAndHeight(text, font, maxWidth) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    context.wordWrap = "break-word";
    const words = text.split(" ");
    const lineHeight = parseInt(font.split(" ")[0], 10);

    let line = "";
    let numLines = 1;
    for (let i = 0; i < words.length; i++) {
      const testLine = `${line + words[i]} `;
      const testWidth = context.measureText(testLine).width;
      if (testWidth > maxWidth) {
        line = `${words[i]} `;
        numLines++;
      } else {
        line = testLine;
      }
    }
    const height = numLines * lineHeight;
    return { numLines, height };
  }

  function getSampleIDandRowTextLines() {
    const sampleIDFont = "12px Roboto Medium";
    const tableRowFont = "10px Roboto";
    const maxWidthSampleID = 430;
    const maxWidthRow = 95;
    const sampleIDNumLines = getNumRowsAndHeight(sample_id, sampleIDFont, maxWidthSampleID);
    let tableRowNumLines = {
      numLines: 1,
      height: 10,
    };
    if (test_results.length) {
      const firstRowVals = Object.values(test_results[0]);
      const longestRowString = firstRowVals.reduce((acc, val) => {
        if (val.length > acc.length) {
          return val;
        }
        return acc;
      }, "");
      tableRowNumLines = getNumRowsAndHeight(longestRowString, tableRowFont, maxWidthRow);
    }

    return { sampleIDNumLines, tableRowNumLines };
  }
  /**
   * Calculate minPresenceAhead based on the number of lines of text expected.
   * minPresenceAhead is the space needed for sample id + header row + first table row.
   * If minPresenceAhead exceeds the remaining space on the page, break whole table header onto the next page.
   * This prevents widowed headers (headers that are by themselves at the bottom of a page).
   * @returns {Number} minPresenceAhead in pts
   */
  const calculateMinPresenceAhead = () => {
    let minPresence = 136; // height minus sample_id and table row text (minus 3 to better match real minPresenceAhead)
    const NumOfLines = getSampleIDandRowTextLines();
    // console.log("🚀 ~ file: PDFTable.js:111 ~ calculateMinPresenceAhead ~ NumOfLines:", NumOfLines);
    minPresence += NumOfLines.sampleIDNumLines.height;
    minPresence += NumOfLines.tableRowNumLines.height;

    // console.log("calculated minPresence: ", minPresence);
    return minPresence;
  };

  return (
    <>
      {/* empty view used to make sure header moves to new page with children */}
      <View style={styles.presenceAheadView} minPresenceAhead={calculateMinPresenceAhead()} />
      <View style={styles.table}>
        {/* <Text style={styles.minPresText}>{`minPresenceAhead: ${calculateMinPresenceAhead()}`}</Text> */}
        <View style={styles.tableTopBorder} fixed />
        <Text style={styles.sampleID}>{`Sample ID : ${softHyphenate(sample_id)}`}</Text>
        <TableHeader />
        {test_results.map((spec, index) => (
          <TableRow key={index} spec={spec} isLast={index === test_results.length - 1} />
        ))}
        {test_results.length === 0 && (
        <TableRow
          key={0}
          spec={{
            test: "-", test_method: "-", test_spec: "-", test_result: "-",
          }}
          isLast
        />
        )}
        <View style={styles.tableBottomBorder} fixed />
      </View>
    </>
  );
}

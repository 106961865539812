import React from "react";
import trashIcon from "../../../../../assets/images/environment/whiteTrashCan.svg";

const RemoveSwabBtn = ({ onClick }) => (
  <div
    className="env-remove-swab-from-map-div"
    onClick={onClick}
  >
    <img
      alt="trash icon"
      src={trashIcon}
    />
    <div>
      Remove Swab Pin from Map
    </div>
  </div>
);

export default RemoveSwabBtn;

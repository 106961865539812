import React from "react";
import { ReactComponent as LoadingDots } from "../../../../../../assets/images/common/LoadingDots.svg";
import "./SubmitButton.css";

export default function SubmitButton({
  loading,
  disabled,
  handleSaveTemplate,
  text,
}) {
  return (
    <button
      type="button"
      className={`BuilderSave__ConfirmButton${disabled ? "--disabled" : ""}`}
      onClick={handleSaveTemplate}
      disabled={disabled || loading}
    >
      {loading ? (
        <>
          <span>Saving</span>
          <LoadingDots width={16} fill="white" />
        </>
      ) : (<span>{text}</span>)}
    </button>
  );
}

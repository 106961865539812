import React from "react";
import { Tabs } from "antd";
import MapTitleTab from "./MapTitleTab";
import "./MapTabs.css";
import { ReactComponent as PlusIcon } from "../../../../../../assets/images/environment/addMapIconMidnight.svg";

const MapTabs = ({
  tabsInMapView,
  loading,
  // addingMapTitle,
  editingMapTitle,
  activeTabIndex,
  listViewDisplay,
  editMapTitleToggle,
  handleTabSelect,
  handleEditMapTitle,
  toggleDeleteTitleConfirm,
  toggleShowCreateEnvModal,
}) => {
  const tabItems = tabsInMapView.map((tab, index) => {
    const isSelected = activeTabIndex === (index + 1).toString();
    const isEditing = editingMapTitle && isSelected;
    return ({
      label: <MapTitleTab
        mapTitle={tab.title}
        editingMapTitle={editingMapTitle}
        editMapTitleToggle={editMapTitleToggle}
        isSelected={activeTabIndex === (index + 1).toString()}
        listViewDisplay={listViewDisplay}
        handleEditMapTitle={handleEditMapTitle}
        toggleDeleteTitleConfirm={toggleDeleteTitleConfirm}
      />,
      children: "",
      key: index,
      closable: isEditing,
      disabled: loading || !listViewDisplay,
    });
  });

  const onChange = (key) => {
    if (!editingMapTitle) {
      handleTabSelect((parseInt(key) + 1).toString());
    }
  };

  const onEdit = (key, action) => {
    if (action === "add") {
      toggleShowCreateEnvModal(false, true);
    } else {
      const mapTitle = tabsInMapView[key].title;
      toggleDeleteTitleConfirm(mapTitle);
    }
  };

  return (
    <Tabs
      type="editable-card"
      hideAdd={!listViewDisplay || tabsInMapView.length > 11 || loading} // max 12 maps
      addIcon={<PlusIcon stroke="#19305a" />}
      onChange={!loading ? onChange : null}
      activeKey={tabsInMapView.length ? parseInt(activeTabIndex) - 1 : null}
      onEdit={onEdit}
      tabBarGutter={8}
      items={tabItems}
      popupClassName="MapTabs__Dropdown"
    />
  );
};
export default MapTabs;

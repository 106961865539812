import React from "react";
import { Button, Input } from "antd";
import { CHAR_LIMITS } from "../SubmissionForm/Constant";
import { ReactComponent as CloseIcon } from "../../../assets/images/sample-submission/close.svg";
import { ReactComponent as SubmitIcon } from "../../../assets/images/sample-submission/SubmitBlue.svg";

import "./Comment.css";

export default function Comment({
  commentRef,
  defaultValue,
  showButtons,
  handleCancelClick,
  handleSubmitClick,
  loading,
}) {
  return (
    <div className="SubmissionFrom__CommentContainer">
      <div className="SubmissionFrom__CommentButtonTitle">
        {showButtons ? <span className="SubmissionForm_Comment--title">Comments:</span> : ""}
        {showButtons && (
        <div className="SubmissionFrom__Comment__Buttons">
          <Button
            ghost
            onClick={handleSubmitClick}
            loading={loading}
            disabled={loading}
            className="SubmissionFrom__Comment__Button"
          >
            <SubmitIcon className="SubmissionFrom__Comment__SubmitBtn" />
          </Button>
          <Button
            ghost
            onClick={handleCancelClick}
            disabled={loading}
            className="SubmissionFrom__Comment__Button"
          >
            <CloseIcon className="SubmissionFrom__Comment__SubmitBtn" />
          </Button>
        </div>
        )}
      </div>
      <Input.TextArea
        ref={commentRef}
        id="submission-comment"
        rows={4}
        maxLength={CHAR_LIMITS.comment}
        showCount
        className={showButtons ? "SubmissionFrom__Comment" : "SubmissionFrom__Comment_Add"}
        defaultValue={defaultValue}
        placeholder="Enter your comments here"
        disabled={loading}
      />
    </div>
  );
}

import React from "react";
import { Form } from "antd";
import "./MyForm.css";

export default function MyForm(props) {
  const { children, className, ...restProps } = props;
  return (
    <Form
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      className={`MyForm ${className}`}
    >
      {children}
    </Form>
  );
}

import React from "react";

import "./SampleSubmissionConfirmModal.css";
import { Modal } from "antd";

import StyledButton from "../../Common/UIComponents/StyledButton";
import Comment from "./Comment";
import SampleSubmissionModalEditsInfo from "./SampleSubmissionModalEditsInfo";

export default function SampleSubmissionConfirmModal({
  headerText,
  bodyText,
  buttonText,
  buttonFunctions,
  loading,
  editedSamplePayload,
  sampleFields,
  sampleFieldsInfo,
  sampleIdFields,
  submissionComment,
  commentRef,
  commentDefaultValue,
  handleEditSample,
  submissionEditingSample,
}) {
  const handleCancel = () => {
    if (submissionEditingSample) {
      handleEditSample(0);
    }
    buttonFunctions[0]();
  };
  return (
    <Modal
      title=""
      open
      maskClosable={false}
      closable={false}
      centered
      destroyOnClose
      footer={null}
      width={editedSamplePayload ? "800px" : "391px"}
      wrapClassName={`SampleSubmission__ConfirmModal ${submissionComment ? "SampleSubmission__ConfirmModal_Add" : ""}`}
    >
      <div className="sampleSubmissionConfirmDel">
        <div className="sampleSubmissionConfirmDelTitle">{headerText}</div>
        <div className={`sampleSubmissionConfirmDelTitleMessage ${submissionComment ? "sampleSubmissionConfirmDelTitleMessage_Add" : ""}`}>{bodyText}</div>
        {submissionComment && <Comment commentRef={commentRef} defaultValue={commentDefaultValue} /> }
        {editedSamplePayload && (
          <SampleSubmissionModalEditsInfo
            editedSamplePayload={editedSamplePayload}
            sampleFields={sampleFields}
            sampleFieldsInfo={sampleFieldsInfo}
            sampleIdFields={sampleIdFields}
          />
        )}
        <div className="sampleSubmissionConfirmDelBtn">
          <StyledButton
            type="default"
            onClick={handleCancel}
            className="sampleSubmissionModalCancelBtn"
          >
            {buttonText[0]}
          </StyledButton>

          <StyledButton type="primary" onClick={buttonFunctions[1]} loading={loading}>
            {buttonText[1]}
          </StyledButton>

        </div>
      </div>
    </Modal>
  );
}

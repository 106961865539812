import React, { useEffect, useState } from "react";

import { Popover } from "antd";

import Calendar from "./Calendar";
import DatePickerDisplay from "./DatePickerDisplay";

import "./DatePicker.css";

export default function DatePicker({
  showCalendar,
  setShowCalendar = () => { },
  prevValue,
  setPrevValue = () => { },
  value,
  onChange = () => { },
  handleSearchDate = () => { },
  getReportDates,
  searchOnClear = false,
  useLocalTime = false,
  popoverPlacement = "bottomLeft",
  popoverClassName = "",
  calendarClassName = "",
  disableSingleDaySelection = false, // only allow ranges of at least 2 days
  disableClickAway = false,
  getPopupContainer,
  autoAdjustOverflow = true,
  datePickerDisplayProps = {
    displayClassName: "",
    showText: true,
    displayTextPosition: "right",
    iconTooltipTitle: "",
    displayElemId: undefined,
  },
  children,
}) {
  const [showCalendarInternal, setShowCalendarInternal] = useState(false);
  const [dateSelected, setDateSelected] = useState(value ?? "");
  const [prevDateSelected, setPrevDateSelected] = useState(prevValue ?? "");

  /** Set showCalendarInternal when value from parent change. */
  useEffect(() => {
    if (showCalendar !== undefined) { // control from parent
      if (showCalendar !== showCalendarInternal) {
        setShowCalendarInternal(showCalendar);
      }
    }
  }, [showCalendar]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Set dateSelected when value from parent change. */
  useEffect(() => {
    if (value !== undefined) { // control from parent
      if (value !== dateSelected) {
        setDateSelected(value);
      }
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Set prevDateSelected when value from parent change. */
  useEffect(() => {
    if (prevValue !== undefined) { // control from parent
      if (prevValue !== prevDateSelected) {
        setPrevDateSelected(prevValue);
      }
    }
  }, [prevValue]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Update showCalendar value in parent */
  useEffect(() => {
    setShowCalendar(showCalendarInternal);
  }, [showCalendarInternal]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Call onDateChange when dateSelectedInternal changes */
  useEffect(() => {
    onChange(dateSelected);
  }, [dateSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Update prevValue in parent */
  useEffect(() => {
    setPrevValue(prevDateSelected);
  }, [prevDateSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = () => {
    setDateSelected(prevDateSelected);
  };

  /** Handle open/closing calendar popover w/ and w/out clickaway. */
  const onOpenChange = (isOpen) => {
    if (!isOpen && !disableClickAway) {
      handleCancel();
      setShowCalendarInternal(isOpen);
    } else if (isOpen) {
      setShowCalendarInternal(isOpen);
    }
  };

  return (
    <Popover
      title=""
      arrow={false}
      autoAdjustOverflow={autoAdjustOverflow}
      placement={popoverPlacement}
      getPopupContainer={getPopupContainer}
      trigger="click"
      open={showCalendarInternal}
      onOpenChange={onOpenChange}
      destroyTooltipOnHide
      overlayClassName={`CalendarPopover ${popoverClassName}`}
      content={(
        <Calendar
          setShowCalendarInternal={setShowCalendarInternal}
          handleSearchDate={handleSearchDate}
          handleCancel={handleCancel}
          getReportDates={getReportDates}
          dateSelected={dateSelected}
          setDateSelected={setDateSelected}
          setPrevDateSelected={setPrevDateSelected}
          searchOnClear={searchOnClear}
          calendarClassName={calendarClassName}
          disableSingleDaySelection={disableSingleDaySelection}
          useLocalTime={useLocalTime}
        />
      )}
    >
      {children ?? (
        <>
          <DatePickerDisplay
            dateSelected={dateSelected}
            showCalendarInternal={showCalendarInternal}
            setDateSelected={setDateSelected}
            setPrevDateSelected={setPrevDateSelected}
            handleSearchDate={handleSearchDate}
            {...datePickerDisplayProps} // eslint-disable-line react/jsx-props-no-spreading
          />
        </>
      )}
    </Popover>
  );
}

import React, { Component } from "react";
import { toast } from "react-toastify";
import "../Product.css";
import "./Report.css";
import ReportSearch from "./ReportSearch";
import SpecsBlock from "./Components/SpecsBlock";
import SpecManager from "./Components/SpecManager/SpecManager";
import {
  getReports,
  getProductFields,
} from "../../../actions/reports";
import ReportsTable from "./ReportsTable/ReportsTable";
import { sortAndFilterProductReportFields } from "./utils";

class Reports extends Component {
  _isMounted = false;

  searchAbortController = null;

  constructor(props) {
    super(props);
    this.productScrollbar = React.createRef();
    this.state = {
      loading: true,
      fields: [],
      linkReportJsonFields: "",
      files: [],
      filterBySpecFlag: "",
      filterByDateFrom: "",
      filterByDateTo: "",
      retest: "",
      searchVal: "", // set before search api is called
      searchValToHighlight: "", // set after the search api call is completed (so the new search value isn't highlighted until after the results are displayed)
      showSpecManager: false,
      currentPage: "1",
      totalPage: "1",
      nextPageLink: "",
      loadingNewContent: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.apiGetFields().then(({ fields, link_report }) => {
      getReports().then((response) => {
        if (this._isMounted && response) {
          const {
            success, files, nextPageLink, totalPage,
          } = response;
            /** Filter out fields that are display "0", sort linked fields to the front */
          const sortedFieldsToDisplay = sortAndFilterProductReportFields(link_report, fields);
          let fieldsError = false;
          if (!link_report || link_report.length === 0 || fields.length === 0) {
            toast.error("Missing fields and/or link report field(s)");
            fieldsError = true;
          } else if (fields.length > 0 && sortedFieldsToDisplay.length === 0) {
            toast.error("No fields to display");
            fieldsError = true;
          } else if (fields.length > 0 && sortedFieldsToDisplay.filter(({ json_field }) => link_report.includes(json_field)).length !== link_report.length) { // check if all linked fields in sortedFields
            toast.error("Link report field(s) missing from fields");
            fieldsError = true;
          }
          if (!success) {
            toast.error("Unable to fetch reports");
          }
          this.setState({
            files: files ?? [],
            fields: fieldsError ? [] : sortedFieldsToDisplay,
            linkReportJsonFields: link_report,
            loading: false,
            nextPageLink,
            currentPage: "1",
            totalPage: totalPage || "1",
          });
        }
      });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Update fields array. If sort is true, sort and filter fields before updating.
   * @param {Array} fields
   * @param {Function} callback
   */
  setFields = (fields, sort = false, callback = () => {}) => {
    const { linkReportJsonFields } = this.state;
    let _fields = fields;
    if (sort) {
      _fields = sortAndFilterProductReportFields(linkReportJsonFields, fields);
    }
    this.setState({ fields: _fields }, callback);
  };

  apiGetFields = async () => {
    const result = await getProductFields();

    if (result && result.success) {
      return result;
    }
    return { fields: [], link_report: [] };
  };

  getReportDates = async () => {
    const { filterBySpecFlag, searchVal, retest } = this.state;
    const params = {
      specs_flag: filterBySpecFlag, search: searchVal, retest,
    };
    const response = await getReports("", params);
    if (response && response.success) {
      const dates = response.dates;
      return dates ?? [];
    }
    return [];
  }

  /**
   * Fetch and filter reports
   * @param {Object} params { search, specs_flag, retest, fromm, to } if any of the filter values are undefined, the value in state will be used. If params is undefined, default values will be used.
   */
    fetchReports = async (params = {
      search: "", specs_flag: "", retest: "", fromm: "", to: "",
    }) => {
      this.setState({ loading: true });
      const {
        searchVal, filterBySpecFlag, retest, filterByDateFrom, filterByDateTo,
      } = this.state;
      const payload = {
        search: params.search ?? searchVal,
        specs_flag: params.specs_flag ?? filterBySpecFlag,
        retest: params.retest ?? retest,
        fromm: params.fromm ?? filterByDateFrom,
        to: params.to ?? filterByDateTo,
        page: "1",
      };

      const newController = new AbortController();
      /** If prev search api still in progress, abort it */
      if (this.searchAbortController) {
        this.searchAbortController.abort();
      }
      this.searchAbortController = newController;

      const {
        success, message, files, nextPageLink, totalPage,
      } = await getReports("", payload, newController.signal);
      if (newController.signal.aborted) {
        return;
      }
      if (success) {
        this.setState({
          files,
          loading: false,
          nextPageLink,
          currentPage: "1",
          totalPage: totalPage || "1",
          searchVal: payload.search,
          searchValToHighlight: payload.search,
          filterBySpecFlag: payload.specs_flag,
          retest: payload.retest,
          filterByDateFrom: payload.fromm,
          filterByDateTo: payload.to,

        }, () => {
          if (files?.length > 0 && this.productScrollbar.current) {
            this.productScrollbar.current.scrollToTop();
          }
        });
      } else {
        toast.error(message);
        this.setState({
          loading: false,
          files: [],
          searchVal: payload.search,
          filterBySpecFlag: payload.specs_flag,
          retest: payload.retest,
          filterByDateFrom: payload.fromm,
          filterByDateTo: payload.to,
        });
      }
      this.searchAbortController = null;
    }

    /**
     * Fetch next page of reports, append to existing list
     */
    fetchNextReports = async () => {
      const {
        currentPage: currentPageNum, totalPage, loadingNewContent, nextPageLink, files: currentFiles,
      } = this.state;
      const lastPageLoaded = parseInt(currentPageNum) === parseInt(totalPage);
      if (!lastPageLoaded && !loadingNewContent) {
        this.setState({ loadingNewContent: true }, () => this.productScrollbar.current.scrollToBottom());
        const currentPage = nextPageLink ? nextPageLink.split("page=")[1].split("&")[0] : "1";
        const response = await getReports(nextPageLink);
        if (response.files && response.files.length) {
          const {
            files, nextPageLink: nextLink, totalPage: totalPages,
          } = response;
          const newFiles = files ?? [];
          this.setState({
            files: [...currentFiles, ...newFiles],
            loadingNewContent: false,
            currentPage,
            totalPage: totalPages || "1",
            nextPageLink: nextLink,
          });
        } else {
          toast.error(response.message);
          this.productScrollbar.current.scrollTop = this.productScrollbar.current.scrollTop - 100; // prevent infinitely firing scroll api call
          this.setState({ loadingNewContent: false });
        }
      }
    }

  /**
   * Search by value
   * @param {String} value
   */
  handleSearch = (value) => {
    this.fetchReports({ search: value });
  };

  /**
   * Filter by date
   * @param {String} dateStart format ex: 2023-11-16
   * @param {String} dateEnd format ex: 2023-11-16
   */
  handleFilterByDate = async (dateStart, dateEnd) => {
    this.fetchReports({ fromm: dateStart, to: dateEnd });
  }

  /**
   * Filter by spec flag. If specFlag equals the value in state, clear spec flag filter.
   * @param {String} specFlag "0", "1", or "2"
   */
  handleFilterBySpec = (specFlag) => {
    const { filterBySpecFlag } = this.state;
    const specFlagParam = specFlag === filterBySpecFlag ? "" : specFlag;
    this.fetchReports({ specs_flag: specFlagParam });
  };

  /**
   * Filter by retest.
   * @param {Boolean} isActive
   */
  handleFilterByRetest = (isActive) => {
    this.fetchReports({ retest: isActive ? "retest" : "" });
  }

  /**
   * Open or close spec manager
   * @returns {void}
   */
  handleSpecManagerToggle = () => {
    const { showSpecManager, fields } = this.state;
    if (!showSpecManager && (fields.length === 0)) {
      toast.error("Cannot open Spec Manager when fields are missing or link report field(s) error");
      return;
    }
    this.setState({
      showSpecManager: !showSpecManager,
    });
  }

  /**
   * If specs were updated, refresh reports when closing spec manager.
   * @param {Array} list list of new reports
   */
  handleDataRefreshAfterExitingSpecManager = (list) => {
    if (list) {
      this.setState({
        files: [...list],
      });
    } else {
      this.fetchReports({}); // refresh data with current filters applied
    }
  }

  /**
   * Update spec flag for the row on exit of SampleResultsModal after requesting a retest.
   */
  handleUpdateRowSpecFlag = (rowID, specs_flag) => {
    const { filterBySpecFlag, files } = this.state;
    const filesArray = [...files];
    if (rowID !== undefined && filesArray[rowID].specs_flag !== undefined) {
      filesArray[rowID].specs_flag = specs_flag;
    }
    if (!Number.isNaN(parseInt(filterBySpecFlag))) { // if filterBySpecFlag is not undefined or empty string
      const specFilterToSpecFlagMap = {
        0: ["0", "4"],
        1: ["1", "5"],
        2: ["2", "6"],
      };
      const specFlagArr = specFilterToSpecFlagMap[filterBySpecFlag];
      if (specFlagArr !== undefined && !specFlagArr.includes(specs_flag)) {
        filesArray.splice(rowID, 1); // file should not appear under the current spec flag filter, so delete
      }
    }
    this.setState({ files: filesArray });
  }

  render() {
    const {
      loading,
      fields,
      files,
      loadingNewContent,
      searchValToHighlight,
      filterBySpecFlag,
      showSpecManager,
      linkReportJsonFields,
    } = this.state;

    return (
      <div className="ProductReport__MainContainer">
        {
        loading && (
          <div className="ProductReport__LoadingContainer" />
        )
      }
        <div className="productReportSearchDiv">
          <ReportSearch
            flag="reports"
            handleSearch={this.handleSearch}
            handleFilterByDate={this.handleFilterByDate}
            getReportDates={this.getReportDates}
          />
        </div>

        <div className="productReportSpecsBlockDiv">
          <SpecsBlock
            specSelected={filterBySpecFlag}
            handleFilterBySpec={this.handleFilterBySpec}
            handleFilterByRetest={this.handleFilterByRetest}
            handleSpecManagerToggle={this.handleSpecManagerToggle}
          />
        </div>
        {showSpecManager && (
          <SpecManager
            showSpecManager
            handleSpecManagerToggle={this.handleSpecManagerToggle}
            handleDataRefreshAfterExitingSpecManager={this.handleDataRefreshAfterExitingSpecManager}
            fields={fields}
            linkReportJsonFields={linkReportJsonFields}
          />
        )}
        <ReportsTable
          fields={fields}
          linkReportJsonFields={linkReportJsonFields}
          setFields={this.setFields}
          reports={files}
          searchValToHighlight={searchValToHighlight}
          loadingNewContent={loadingNewContent}
          handleUpdateRowSpecFlag={this.handleUpdateRowSpecFlag}
          handleFetchNextPage={this.fetchNextReports}
          scrollbarRef={this.productScrollbar}
          handleDataRefreshAfterExitingSpecManager={this.handleDataRefreshAfterExitingSpecManager}
          loading={loading}
        />
      </div>
    );
  }
}

export default Reports;

import React, { Component } from "react";
// import SpecManagerToggleButton from "../SpecManager/SpecManagerToggleButton";
// import QuadrantList from './QuadrantList';
import Map from "./Map";
import MapTabs from "./MapTabs/MapTabs";
// import MapTitleTab from "./MapTabs/MapTitleTab";
// import AddMapTitleTab from "./MapTabs/AddMapTitleTab";
// import UploadImage from "./UploadImage";
import "./Map.css";
import pumpkin from "../../../../../assets/images/environment/pumpkin.png";
import "./AnalyticsMapView.css";

import { checkPinIsPinned } from "../pinHelper";

export default class AnalyticsMapView extends Component {
  state = {
    // addingMapTitle: false,
    editingMapTitle: false,
  };

  editMapTitleToggle = () => {
    // console.log("editmaptitletoggle");
    const { editingMapTitle } = this.state;
    this.setState({ editingMapTitle: !editingMapTitle });
  }

  // addMapTitleToggle = () => {
  //   // console.log("addmaptitletoggle");
  //   const { addingMapTitle } = this.state;
  //   this.setState({ addingMapTitle: !addingMapTitle });
  // }

  render() {
    const {
      tabsInMapView,
      activeTabIndex,
      listViewDisplay,
      pinMap,
      mapKey,
      selectedLocationID,
      indexOfQuadrantHoverOver,
      quadrantSelected,
      displayTooltipLocationID,
      imageSrc,
      imageDisplay,
      draggingOverMap,
      setDisplayTooltipLocationID,
      isPinningSwab,
      updatePinInfo,
      endPinningSwab,
      handleSelectQuadrant,
      toggleListViewDisplay,
      // handleUploadImage,
      setSelectedLocationID,
      handleTabSelect,
      loading,
      // keepCurrentTransform,
      isAddingNewSwab,
      transformWrapperRef,
      togglePinningNewSwab,
      isPinningNewSwab,
      mapComponentRef,
      isClickingQuadrant,
      clickQuadrant,
      displayNoResultFound,
      // handleAddMapTitle,
      handleEditMapTitle,
      toggleDeleteTitleConfirm,
      toggleShowCreateEnvModal,
      index0,
      // handleSpecManagerToggle,
    } = this.props;

    const {
      // addingMapTitle,
      editingMapTitle,
    } = this.state;
    // console.log("addingmaptitle", addingMapTitle);
    const pinnedLocations = Array.from(pinMap.values()).filter((pin) => checkPinIsPinned(pin));
    return (
      <>
        <div className="MapView__TabBar">
          <MapTabs
            tabsInMapView={tabsInMapView}
            loading={loading}
            editingMapTitle={editingMapTitle}
            activeTabIndex={activeTabIndex}
            listViewDisplay={listViewDisplay}
            editMapTitleToggle={this.editMapTitleToggle}
            handleTabSelect={handleTabSelect}
            handleEditMapTitle={handleEditMapTitle}
            toggleDeleteTitleConfirm={toggleDeleteTitleConfirm}
            toggleShowCreateEnvModal={toggleShowCreateEnvModal}
          />
          {/* <SpecManagerToggleButton handleSpecManagerToggle={handleSpecManagerToggle} /> */}
        </div>
        <div
          className="mapViewContainer-container"
          // ref={this.mapContainerRef}
          // onDragEnter={isDragging ? e => updateTemplate(e, 2, "enter") : null}
          // onDragLeave={isDragging ? e => updateTemplate(e, 2, "leave") : null}
        >
          {!loading && imageSrc && imageSrc !== "Not Found" && (
          <>
            <div
              id="mapViewContainer"
            >
              <Map
                ref={mapComponentRef}
                imageSrc={imageSrc}
                imageDisplay={imageDisplay}
                pinMap={pinMap}
                index0={index0}
                selectedLocationID={selectedLocationID}
                indexOfQuadrantHoverOver={indexOfQuadrantHoverOver}
                mapKey={mapKey}
                isAddingNewSwab={isAddingNewSwab}
                isPinningSwab={isPinningSwab}
                displayTooltipLocationID={displayTooltipLocationID}
                quadrantSelected={quadrantSelected}
                listViewDisplay={listViewDisplay}
                endPinningSwab={endPinningSwab}
                updatePinInfo={updatePinInfo}
                toggleListViewDisplay={toggleListViewDisplay}
                draggingOverMap={draggingOverMap}
                handleSelectQuadrant={handleSelectQuadrant}
                setSelectedLocationID={setSelectedLocationID}
                setDisplayTooltipLocationID={setDisplayTooltipLocationID}
                transformWrapperRef={transformWrapperRef}
                togglePinningNewSwab={togglePinningNewSwab}
                isPinningNewSwab={isPinningNewSwab}
                clickQuadrant={clickQuadrant}
                isClickingQuadrant={isClickingQuadrant}
                displayNoResultFound={displayNoResultFound}
                editMapTitleToggle={this.editMapTitleToggle}
                editingMapTitle={editingMapTitle}
              />
              {!displayNoResultFound && pinnedLocations.length === 0 && !selectedLocationID && (
                <div
                  style={{
                    position: "absolute",
                    background: "rgba(255, 255, 255, 0.5)",
                    bottom: "0px",
                    width: "100%",
                    height: "100%",
                    zIndex: "3",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    textAlign: "center",
                    transform: "translate(-50%, -50%)",
                    fontSize: "16px",
                  }}
                  >
                    Select a swab ID to drop a pin on map
                  </div>
                </div>
              )}
            </div>
          </>
          )}
          {(!imageSrc || imageSrc === "Not Found") && (
          <div style={{
            width: "100%", height: "100%", backgroundColor: "#F9F9F9", borderRadius: "8px", position: "relative",
          }}
          >
            {(!loading && !imageSrc && tabsInMapView.length > 0) && (
              <div className="addMapTitleMessage">
                <span>Map is missing.</span>
                <span>Please delete the current tab and reupload the map by clicking on the plus (+) icon.</span>
              </div>
            // <UploadImage
            //   handleUploadImage={handleUploadImage}
            //   imageTitle={activeTabIndex === "0" ? tabsInMapView[activeTabIndex].title : tabsInMapView[activeTabIndex - 1].title}
            // />
            )}
            {(!loading && !imageSrc && !tabsInMapView.length) && (
            <div className="addMapTitleMessage">
              <span>Click on the plus (+) icon above to add a map</span>
            </div>
            )}
            {!loading && imageSrc
              && (
              <div
                style={{
                  height: "251px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "20px",
                  fontFamily: "Roboto Medium",
                }}
              >
                <img
                  alt="pumpkin"
                  src={pumpkin}
                  style={{
                    height: "251px",
                    width: "251px",
                  }}
                />
                <div style={{ marginTop: "-100px", textAlign: "center" }}>Failed to load the image</div>
              </div>
              )}
          </div>
          )}
        </div>
      </>
    );
  }
}

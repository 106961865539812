import React from "react";
import fullScreen from "../../../../../assets/images/environment/fullScreenIcon.svg";
import exitFullScreen from "../../../../../assets/images/environment/exitFullScreenIcon.svg";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";

const FullMapSwitch = ({
  toggleListViewDisplay, listViewDisplay, editMapTitleToggle, editingMapTitle,
}) => {
  const handleFullScreen = () => {
    if (editingMapTitle) editMapTitleToggle();
    toggleListViewDisplay();
  };
  return (

    <div
      className="fullscreenButton"
      onClick={handleFullScreen}
    >
      <StyledTooltip
        className="full-map-switch-tooltip"
        title={listViewDisplay ? "Update pin location" : "View pin info"}
        placement="top"
      >
        <img
          alt="fullScreenSwitch"
          src={listViewDisplay ? fullScreen : exitFullScreen}
          style={{
            width: "15px",
            height: "15px",
          }}
        />
      </StyledTooltip>
    </div>
  );
};

export default FullMapSwitch;

import React from "react";
import PreviewDateBlock from "./PreviewDateBlock";

import "./PreviewDatePicker.css";

export default function PreviewDatePicker(props) {
  const { data, updateActiveDate, activeDate } = props;

  return (
    <div className="previewDatePickerMainContainer">
      <div className="previewDatePickerContainer">
        {data.map((item, index) => {
          if (item === null) return null;
          const date = item.date;
          let active = false;
          if (date === activeDate) active = true;

          return <PreviewDateBlock key={index} index={index} date={date} active={active} updateActiveDate={updateActiveDate} />;
        })}
      </div>

      {/* <div className="previewDatePickerHorizontalLine" /> */}
    </div>
  );
}

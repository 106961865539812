import React from "react";
import searchIcon from "../../../../../assets/images/environment/zoomDisplayIconDarkBlue.svg";

const ZoomDisplay = ({ currentZoom }) => (
  <div className="envAnalyticsZoomDisplay">
    <img
      className="envZoomDisplayIcon"
      alt="searchIcon"
      src={searchIcon}
    />
    <div>
      {`${currentZoom}%`}
    </div>
  </div>
);

export default ZoomDisplay;

import React, { Component } from "react";
import SignUp from "./SignUp/SignUp";
import SignUpInvite from "./SignUpInvite/SignUpInvite";
import SignUpSuccessController from "./SignUpSuccessController";

class SignUpController extends Component {
    state = {
      path: "",
    }

    async componentDidMount() {
      // Check if we are in navigating to the correct route
      this.setState({
        path: window.location.pathname,
      });
    }

    render() {
      const { path } = this.state;
      const { history } = this.props;

      if (path === "/signup/member") {
        return <SignUp userType="member" history={history} />;
      } if (path === "/signup/invite") {
        return <SignUpInvite history={history} />;
      } if (path.includes("success")) {
        return <SignUpSuccessController path={path} history={history} />;
      }
      return null;
    }
}

export default SignUpController;

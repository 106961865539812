import React from "react";
import { calculateWidths } from "../../../PDF/pdfUtils";
import "./COABuilderSampleReportSection.css";
import "./COABuilderSection.css";

export default function COABuilderSampleReportSection({ samples, uniqueTests }) {
  const samplesList = samples.map(({ sample_id, test_results }) => ({
    sample_id,
    test_results,
  }));

  return (
    <div className="COABuilder__Section COABuilder__SampleReportContainer">
      <table className="COABuilder__SampleReportTable">
        <tbody>
          <tr className="COABuilder__SampleReportTable__Header">
            <th style={{ width: `${calculateWidths(uniqueTests).sampleWidth}%` }}>Sample ID</th>
            {uniqueTests.filter(({ selected }) => selected).map(({ test }, i) => (
              <th key={i} style={{ width: `${calculateWidths(uniqueTests).resultWidth}%` }}>{test}</th>
            ))}
          </tr>
          {samplesList.map(({ sample_id, test_results }, i) => (
            <tr key={i} className="COABuilder__SampleReportTable__Row">
              <td className="COABuilder__SampleReportTable__SampleID" style={{ width: `${calculateWidths(uniqueTests).sampleWidth}%` }}>
                <span>{sample_id}</span>
              </td>
              {uniqueTests.filter(({ selected }) => selected).map(({ test }, j) => {
                const matchingTestResult = test_results.find((result) => result.test === test);
                return (
                  <td key={j} style={{ width: `${calculateWidths(uniqueTests).resultWidth}%` }}>
                    <span>{matchingTestResult ? matchingTestResult.test_result : "-"}</span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

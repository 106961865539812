import React from "react";
import TemplateBuilderDroppable from "../../DragAndDrop/TemplateBuilderDroppable";
import SectionInputDraggable from "../../DragAndDrop/SectionInputDraggable";

export default function DroppableSection(props) {
  const {
    inputs,
    deleteInput,
    droppableId,
    sectionPart,
    setTextBoxRef,
  } = props;

  const getDroppableStyle = (snapshot) => ({
    background: snapshot.isDraggingOver ? "#dff5fd" : "unset",
    padding: "10px",
    borderRadius: "4px",
  });

  const renderDraggable = (input, index) => {
    let type = input.id.includes("TextBox") ? "TextBox" : "datafield";
    if (type === "TextBox") {
      type = input.id.includes("large") ? "largeTextBox" : "smallTextBox";
    }

    return (
      <SectionInputDraggable
        key={input.id}
        input={input}
        deleteInput={(i) => deleteInput(i, sectionPart)}
        type={type}
        index={index}
        setTextBoxRef={(i, inputElem) => setTextBoxRef(sectionPart, i, inputElem)}
      />
    );
  };
  const list = inputs[sectionPart];
  const classNameSection = sectionPart === "left" ? "sectionContentInputsOne" : "sectionContentInputsTwo";
  return (
    <div className={classNameSection}>
      <TemplateBuilderDroppable
        droppableId={droppableId}
        className="builderDropInputSectionContainer"
        getDroppableStyle={getDroppableStyle}
      >
        {list.map((input, index) => renderDraggable(input, index))}
      </TemplateBuilderDroppable>
    </div>
  );
}

import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import Scrollbar from "react-scrollbars-custom";
import OutsideClickHandler from "react-outside-click-handler";
import { isEqual, mapValues } from "lodash";
import StyledButton from "../../Common/UIComponents/StyledButton";
import Tag from "./Tag";
import lightBlueAddIcon from "../../../assets/images/index/addCircleIconBlue.png";
import darkBlueAddIcon from "../../../assets/images/index/addCircleIconMidnight.png";
import "./productEditCard.css";

function ProductEditCard(props) {
  const {
    activeTab, item, tagsEditing,
    linkReportFields,
  } = props;
  const productId = activeTab === "0" ? item.product_id : item.index_card_id;
  const productName = activeTab === "0" ? item.product_name : item.card_name;
  const itemOrCreateByVal = activeTab === "0" ? item.item : item.created_by;
  // const {editProductId} = props;

  const [saveReminderDisplay, setSaveReminderDisplay] = useState(false);
  const [inputItemName, setInputItemName] = useState(productName);
  const [addTagIcon, setAddTagIcon] = useState(lightBlueAddIcon);
  const [tagInput, setTagInput] = useState("");
  const [editTag, setEditTag] = useState("");
  const [editTagIndex, setEditTagIndex] = useState(-1);
  const [tagsSet, setTagsSet] = useState(new Set(tagsEditing[productId].map((tag) => tag.toLowerCase())));
  const itemNameRef = useRef(null);
  const fieldInputsRef = useRef(Array(linkReportFields.length));
  const itemTagRef = useRef(null);
  const scrollbarHeight = "95px";

  const setFieldInputsRef = (elem, idx) => {
    fieldInputsRef.current[idx] = elem;
  };

  const handleKeyPressInputProductNameChange = (e) => {
    if (e.charCode === 13) {
      if (activeTab === "0") {
        fieldInputsRef.current[0].focus();
      } else {
        itemTagRef.current.focus();
      }
    }
  };

  const handleKeyPressInputItemNumberChange = (e, idx) => {
    if (e.charCode === 13) {
      if (idx === linkReportFields.length - 1) {
        itemTagRef.current.focus();
      } else {
        fieldInputsRef.current[idx + 1].focus();
      }
    }
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleDeleteTag = async (tag, e) => {
    const { handleDeleteTagParent } = props;
    const tags = tagsSet;
    tags.delete(tag.toLowerCase());
    setTagsSet(tags);
    // delete tag in backend and DOM
    await handleDeleteTagParent(tag, productId, e);
    return true;
  };

  const handleCancelEditTag = () => {
    setTagInput("");
    setEditTagIndex(-1);
    setEditTag("");
  };

  const handleAddTag = async () => {
    const tags = tagsSet;
    const curEditTagIndex = editTagIndex;
    let newTag = tagInput.trim().toLowerCase();
    if (!newTag) {
      toast.error("Please type in the tag name before add.");
      return false;
    }
    if (tags.has(newTag) && curEditTagIndex === -1) {
      toast.error("Please add a unique tag.");
      return false;
    }
    if (editTag && editTag.toLowerCase() === newTag) {
      handleCancelEditTag();
      return true;
    }
    newTag = newTag[0].toUpperCase() + newTag.substr(1);
    // update tag in DOM and backend
    const { handleAddTagParent } = props;
    const success = await handleAddTagParent(newTag, productId, editTag);
    if (success) {
      if (editTag) {
        tags.delete(editTag.toLowerCase());
      }
      tags.add(newTag.toLowerCase());
      setTagsSet(tags);
      // resetting the values
      if (curEditTagIndex === editTagIndex) {
        itemTagRef.current.value = "";
        handleCancelEditTag();
      }
      return true;
    }
    toast.error("Fail to add or update the tag.");
    return false;
  };

  const handleKeyPressAddTag = (e) => {
    if (e.charCode === 13) {
      handleAddTag();
    }
  };

  const handleEditTag = (tagIndx, itemTag) => {
    setEditTag(itemTag);
    setEditTagIndex(tagIndx);
    setTagInput(itemTag);
    itemTagRef.current.value = "";
  };

  const handleSaveButtonClick = async () => {
    setSaveReminderDisplay(false);
    const linkReportFieldsObj = {};
    if (activeTab === "0") {
      if (!inputItemName.trim()) {
        toast.error("Please add Item name");
        return;
      }
      for (let i = 0; i < fieldInputsRef.current.length; i++) {
        const fieldInput = fieldInputsRef.current[i];
        const value = fieldInput.value.trim();
        const { json_field, title_field } = linkReportFields[i];
        if (!value) {
          toast.error(`Please add ${title_field}`);
          return;
        }
        linkReportFieldsObj[json_field] = value;
      }
    } else if (!inputItemName.trim()) {
      toast.error("Please add Item name");
      return;
    }
    if (tagInput.trim()) {
      const success = await handleAddTag();
      if (!success) {
        return;
      }
    }
    const { onCancelEdit, onSaveEdit } = props;
    if (((activeTab === "0" && isEqual(mapValues(linkReportFieldsObj, (val) => val.trim().toLowerCase()), mapValues(itemOrCreateByVal, (val) => val.trim().toLowerCase()))) // check if all fields equal (case insensitive), if yes cancel edit
        || activeTab !== "0") && inputItemName.trim().toLowerCase() === productName.toLowerCase()) {
      onCancelEdit();
    } else {
      onSaveEdit(item, inputItemName.trim(), linkReportFieldsObj);
    }
  };

  const { onCancelEdit, isMostRight } = props;

  return (
    <OutsideClickHandler onOutsideClick={() => { setSaveReminderDisplay(true); }}>
      <div className="product-card" onClick={() => { setSaveReminderDisplay(false); }}>
        <div className="editCard">
          <div className="product-card-title">
            <div
              className="productEditCard__Title"
            >
              Edit Item Card
            </div>
          </div>
          <div className="ProductEditCard__CardBody">
            <Scrollbar className="ProductEditCard__InputsScrollbar">
              <div>
                <label htmlFor="name" className="ProductEditCard__Body__ItemNameLabel">Item Name*</label>
                <textarea
                  className="productEditCard__Body--itemName"
                  ref={itemNameRef}
                  id="name"
                  type="text"
                  maxLength="250"
                  rows="2"
                  defaultValue={productName}
                  placeholder=""
                  onKeyPress={handleKeyPressInputProductNameChange}
                  onChange={(e) => setInputItemName(e.target.value)}
                />
                <div className={`charsCount ${activeTab !== "0" ? "ProductEditCard__Body__CharsCount--is-last" : ""}`}>
                  {inputItemName.length}
                  /250
                </div>
              </div>

              {activeTab === "0"
                ? (
                  <div className="ProductEditCard__LinkedFieldInputs">
                    {linkReportFields.map((link_item, link_idx) => (
                      <div key={link_idx}>
                        <label htmlFor="number">
                          {link_item.title_field}
                          *
                        </label>
                        <input
                          type="text"
                          placeholder=""
                          id="number"
                          defaultValue={itemOrCreateByVal[link_item.json_field]}
                          onKeyPress={(e) => handleKeyPressInputItemNumberChange(e, link_idx)}
                          ref={(input) => setFieldInputsRef(input, link_idx)}
                        />
                      </div>
                    ))}
                  </div>
                )
                : null}
            </Scrollbar>

            <div className="tagInput">
              <label htmlFor="tags">Add & edit tags</label>
              <div className="productEditCard__TabsLabelInput">
                <input
                  className="productEditCard__tagName--Input"
                  type="text"
                  id="tags"
                  placeholder="Enter tag name"
                  onChange={handleTagInputChange}
                  onKeyPress={handleKeyPressAddTag}
                  ref={itemTagRef}
                />
                <img
                  className="productEditCard__tagName--addTagIcon"
                  src={addTagIcon}
                  alt="addTagIcon"
                  onMouseEnter={() => setAddTagIcon(darkBlueAddIcon)}
                  onMouseLeave={() => setAddTagIcon(lightBlueAddIcon)}
                  onClick={() => {
                    if (editTag === "") {
                      handleAddTag();
                    }
                  }}
                />
              </div>
            </div>
            <Scrollbar
              className="productEditCard__Scrollbar"
              style={{
                height: scrollbarHeight,
              }}
            >
              {tagsEditing[productId] && tagsEditing[productId].map((itemTag, tagIndex) => (
                <Tag
                  key={tagIndex}
                  label={itemTag}
                  isEditing={tagIndex === editTagIndex}
                  tagInput={tagInput}
                  setTagInput={setTagInput}
                  handleDoubleClickTag={() => handleEditTag(tagIndex, itemTag)}
                  handleKeyPressAddTag={handleKeyPressAddTag}
                  handleDeleteTag={(e) => handleDeleteTag(itemTag, e)}
                  handleTagInputOutsideClick={handleAddTag}
                />
              ))}
            </Scrollbar>
            <div
              className="productEditCard__Buttons"
            >
              <StyledButton
                onClick={onCancelEdit}
                type="primary"
                ghost
              >
                Cancel
              </StyledButton>
              <StyledButton
                onClick={handleSaveButtonClick}
                disabled={editTag !== ""}
                type="primary"
                className="editSaveBtn"
              >
                Save
              </StyledButton>
              {saveReminderDisplay
                && (
                <div className={isMostRight ? "saveReminderLeft" : "saveReminderRight"}>
                  <div className="triangleForReminder" />
                  <div className="saveReminder">You have unsaved changes, please save the card before proceeding</div>
                </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
}

export default ProductEditCard;

import React, { useEffect, useState } from "react";
import { s3EsvPriv } from "../../../../../../../utils/aws";
import { getFileFromS3 } from "../../../../../../../utils/helpers";
import "./COABuilderImage.css";

export default function COABuilderImage({ title, imagePath, handleBuilderFile }) {
  const [imageSrc, setImageSrc] = useState("");
  const loadImage = async () => {
    if (imagePath) {
      const name = imagePath.split("/").pop();
      const blob = await getFileFromS3(imagePath, s3EsvPriv);
      const previewURL = URL.createObjectURL(blob);
      setImageSrc(previewURL);
      const file = new File([blob], name);
      handleBuilderFile({ file, name, title });
    }
  };
  useEffect(() => {
    loadImage();
  }, []); // eslint-disable-line

  return (
    <div className="COABuilderImage__Container">
      <img
        alt="logo"
        src={imageSrc}
        className="COABuilderImage"
      />
    </div>
  );
}

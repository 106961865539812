import React, { useEffect, useState } from "react";

// import { useRouteMatch, Redirect, useLocation } from "react-router";
import { toast } from "react-toastify";

import MainPage from "./MainPage/MainPage";
import SampleSubmissionContext from "./SampleSubmissionContext";
import SampleExitModal from "./SampleSubmissionModal/SampleExitModal";
import "./SampleSubmission.css";
import SubmissionForm from "./SubmissionForm/SubmissionForm";

import { getViewAsPerStatusCode } from "../../utils/helpers";
import { getSortedFields } from "./utils";

import { getSampleFields } from "../../actions/sampleSubmission";

/** MAKE SURE ANY CHANGES MADE TO SAMPLE SUBMISSION WORK FOR/DON’T BREAK THE LOGGED OUT VERSION */
export default function SampleSubmission({
  // setActiveTab,
  // setSampleSubLink,
  // tabs,
  isLoggedOut,
  thirdPartyPayload,
  setAddressPage,
  setSubmissionNameNav,
  setShowExitModal,
  exitModal,
  setExitModal,
  setCategory,
}) {
  /** Grab type (environment or product) from the url */
  const thirdParty = thirdPartyPayload.thirdParty ?? false;
  const mrl = thirdPartyPayload.mrl === "true";

  const [showSubmissionForm, setShowSubmissionForm] = useState(isLoggedOut);
  const [draftEditing, setDraftEditing] = useState(null);
  const [submissionEditingSample, setSubmissionEditingSample] = useState(null);
  const [submissionAddingSamples, setSubmissionAddingSamples] = useState(null);
  const [fieldsError, setFieldsError] = useState(false);
  const [sampleFields, setSampleFields] = useState([]);
  const [sampleFieldsInfo, setSampleFieldsInfo] = useState({});
  const [delimiterRegex, setDelimiterRegex] = useState("");
  const [linkedFields, setLinkedFields] = useState([]);
  const [sampleIdFields, setSampleIdFields] = useState(new Set());
  const [sampleCategory, setSampleCategory] = useState(thirdPartyPayload.sample_category || "");
  const [sampleFieldLoaded, setSampleFieldLoaded] = useState(false);
  const [loadingFields, setLoadingFields] = useState(false);

  /** Get sample fields, used for sample creating form */
  useEffect(() => {
    async function apiGetSampleFields(category) {
      setLoadingFields(true);
      const result = await getSampleFields(category);
      if (result.success) {
        if (result.fields && result.fields.length) {
          const fieldsMap = {};
          result.fields.forEach((fieldObj) => {
            fieldsMap[fieldObj.json_field] = fieldObj;
          });
          // const linked_fields_env =  result.link_pattern_fields;
          const linked_fields = category === "environment" ? result.link_pattern_fields : result.link_report;
          /** Only show fields that are not display "2" */
          const sampleSubmissionFields = result.fields.filter(({ display }) => display !== "2");
          const sampleSubmissionFieldsSorted = getSortedFields(sampleSubmissionFields.map(({ json_field }) => json_field), linked_fields);
          setSampleFieldsInfo(fieldsMap || {});
          setSampleFields(sampleSubmissionFieldsSorted || []);
          setSampleIdFields(new Set(result.sampleid_fields ?? []));
          setDelimiterRegex(result.delimiterRegex);
          setLinkedFields(linked_fields ?? []);
        } else {
          toast.error("Fields are missing or empty");
          setFieldsError(true);
          setSampleFieldLoaded(false);
        }
      } else {
        toast.error(result.message);
        setFieldsError(true);
        getViewAsPerStatusCode(result.status);
      }
      setSampleFieldLoaded(true);
      setLoadingFields(false);
    }
    if (isLoggedOut && !thirdParty) {
      setSampleFieldsInfo({
        sample_id: {
          title_field: "Sample ID",
          display: "1",
        },
        sample_type: {
          title_field: "Sample Type",
          display: "1",
        },
      });
      setSampleFields(["sample_id", "sample_type"]);
      setSampleIdFields(new Set(["sample_id"]));
      setSampleFieldLoaded(true);
    } else if (sampleCategory !== "" && showSubmissionForm) {
      apiGetSampleFields(sampleCategory);
      setCategory(sampleCategory);
    }
  }, [sampleCategory, showSubmissionForm]); // eslint-disable-line

  /**
   * set the exit modal for editing
   */
  useEffect(() => {
    setShowExitModal(true);
  }, [exitModal]); // eslint-disable-line
  /**
   * SubmissionFrom navbar exit and back functionality edit for the dra
   */
  useEffect(() => {
    if (draftEditing) {
      setSampleCategory(draftEditing.sample_category);
      setShowExitModal(false);
    }
  }, [draftEditing]); // eslint-disable-line

  const handleGoBackToMainPage = async () => {
    if (!isLoggedOut) {
      setShowSubmissionForm(false); // go back to main page
      setSampleFieldLoaded(false);
      if (submissionEditingSample) {
        setSubmissionEditingSample(null);
      }
      if (draftEditing) {
        setDraftEditing(null);
      }
      if (submissionAddingSamples) {
        setSubmissionAddingSamples(null);
      }
    }
  };

  return (
    <SampleSubmissionContext.Provider value={{
      isLoggedOut, sampleCategory, thirdParty, setSampleCategory, mrl,
    }}
    >
      { exitModal && <SampleExitModal bodyText="Are you sure you want to leave without saving?" headerText="Unsaved Changes" setExitModal={setExitModal} handleGoBackToMainPage={handleGoBackToMainPage} />}
      {(showSubmissionForm && sampleFieldLoaded) ? (
        <SubmissionForm
          setShowSubmissionForm={setShowSubmissionForm}
          setSampleFieldLoaded={setSampleFieldLoaded}
          draftEditing={draftEditing}
          submissionEditingSample={submissionEditingSample}
          setSubmissionEditingSample={setSubmissionEditingSample}
          submissionAddingSamples={submissionAddingSamples}
          setSubmissionAddingSamples={setSubmissionAddingSamples}
          setDraftEditing={setDraftEditing}
          sampleFields={sampleFields}
          sampleFieldsInfo={sampleFieldsInfo}
          delimiterRegex={delimiterRegex}
          linkedFields={linkedFields}
          sampleIdFields={sampleIdFields}
          sampleCategory={sampleCategory}
          setAddressPage={setAddressPage}
          setSubmissionNameNav={setSubmissionNameNav}
          setShowExitModal={setShowExitModal}
        />

      )
        : (
          <MainPage
            setShowSubmissionForm={setShowSubmissionForm}
            setSampleFieldLoaded={setSampleFieldLoaded}
            setDraftEditing={setDraftEditing}
            setSubmissionEditingSample={setSubmissionEditingSample}
            setSubmissionAddingSamples={setSubmissionAddingSamples}
            fieldsError={fieldsError}
            setSampleCategory={setSampleCategory}
            loadingFields={loadingFields}
          />
        )}
    </SampleSubmissionContext.Provider>
  );
}

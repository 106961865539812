import React, { useEffect, useRef, useState } from "react";
import { Select } from "antd";
import { ReactComponent as EditIcon } from "../../../../../../../../assets/images/sample-submission/edit.svg";
import DeleteIcon from "../../../../../../../../assets/images/product/template/DragClose.png";
import { ReactComponent as Checkmark } from "../../../../../../../../assets/images/product/template/inputCheckmark.svg";
import "./COABuilderSelect.css";
import "../../../DragAndDrop/COABuilderDraggable.css";

export default function COABuilderSelect({
  id,
  placeholder,
  setRef,
  options,
  defaultValue,
  label,
  onChange,
  handleDelete,
  isEditing,
  toggleEdit,
}) {
  const [value, setValue] = useState(defaultValue ?? "");
  const [isHovered, setIsHovered] = useState();
  const inputRef = useRef();

  /**
   * On mount, set ref of input
   */
  useEffect(() => {
    if (setRef) {
      setRef(inputRef.current);
    }
  }, []); // eslint-disable-line

  const onSelect = (option) => {
    setValue(option);
    if (onChange) {
      onChange(option);
    }
  };

  const handleToggleEdit = () => {
    if (isEditing) {
      setIsHovered(false);
    }
    toggleEdit();
  };

  return (
    <div
      className={`COABuilder__SelectParent${isEditing ? "--isEditing" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {label && (
      <label
        className="COABuilder__SelectLabel"
        htmlFor={id}
      >
        {label}
      </label>
      )}
      <div className="COABuilder__SelectDiv">
        {/* Used to keep track of select value, ant design select doesn't except a ref */}
        <input
          className="COABuilder__RefInput"
          id={id}
          readOnly
          ref={inputRef}
          value={value}
        />
        <Select
          className={`COABuilder__Select${isEditing ? "--isEditing" : ""}`}
          placeholder={placeholder}
          defaultValue={defaultValue}
          optionFilterProp="children"
          filterOption={(inputValue, option) => option.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1}
          options={options}
          onSelect={(val) => onSelect(val)}
          popupClassName="COABuilder__Select__Dropdown"
        />
        {!isEditing && (<span className="COABuilder__SelectSpan">{inputRef.current?.value}</span>)}
      </div>
      {isEditing && (
      <Checkmark
        className="COABuilder__CheckmarkIcon"
        onClick={handleToggleEdit}
      />
      )}
      {!isEditing && (
        <>
          <div
            className="COABuilderSelect__EditIconContainer"
            onClick={handleToggleEdit}
          >
            <EditIcon
              className="COABuilder__EditIcon"
            />
          </div>
          {isHovered && (
            <div
              className="COABuilder__DeleteIconContainer"
              onClick={handleDelete}
            >
              <img
                src={DeleteIcon}
                alt="close icon"
                className="COABuilder__DeleteIcon"
              />
            </div>
          )}
        </>
      )}
    </div>

  );
}

import React from "react";
import Scrollbar from "react-scrollbars-custom";
import NoResults from "../../../../../Common/NoResults";
import emptyStateIcon from "../../../../../../assets/images/EmptyState_V2.png";
import Loading from "../Loading";
import SpecStatusItem from "./SpecStatusItem";

export default class SpecStatusItemList extends React.Component {
  render() {
    const {
      tableData,
      handleEditSpecClick,
      loadingNewContent,
      specManagerScrollbar,
      loading,
      searchValue,
    } = this.props;
    // console.log("tableData", tableData[0]);

    // console.log("spec manager data", tableData);

    const handleScrollToBottom = (e) => {
      const { handlePageChangeScroll } = this.props;
      const tolerance = 3;
      const bottom = Math.abs(Math.ceil(e.contentScrollHeight - e.scrollTop) - Math.ceil(e.clientHeight)) <= tolerance;
      if (bottom) {
        handlePageChangeScroll();
      }
    };

    if (!loading && tableData.length === 0) {
      return (
        <Scrollbar
          id="noResultsScrollbar"
          disableTrackYWidthCompensation
          disableTracksWidthCompensation
          ref={specManagerScrollbar}
          style={{
            position: "",
            width: "100%",
            height: "100%",
          }}
        >
          <NoResults message="No results found" image={emptyStateIcon} />
        </Scrollbar>
      );
    }

    let items = tableData;
    if (loading && !loadingNewContent) {
      items = [...Array(7).keys()];
    }

    return (
      <Scrollbar
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
        id="specManagerDisplayTableScrollBar"
        ref={specManagerScrollbar}
        style={{
          position: "",
          width: "100%",
          height: "calc(100% - 700px)",
          minHeight: "384px",
          backgroundColor: "#FAFAFA",
        }}
        onScrollStop={(e) => handleScrollToBottom(e)}
      >
        <table
          className="envSpecTable"
          style={{ paddingRight: "10px" }}
        >
          <thead>
            <tr>
              {/* <th>Product ID</th> */}
              {/* <th>Item</th> */}
              {/* <th>Product Name</th>
                      <th>Spec State</th> */}
            </tr>
          </thead>
          <tbody>
            {items.map((item, i) => (
              <SpecStatusItem
                key={i}
                index={i}
                item={item}
                handleEditSpecClick={handleEditSpecClick}
                loadingNewContent={loadingNewContent}
                loading={loading}
                searchValue={searchValue}
              />
            ))}
          </tbody>
        </table>
        {loadingNewContent && <Loading />}
      </Scrollbar>
    );
  }
}

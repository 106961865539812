import React from "react";
import {
  View, StyleSheet, Text,
} from "@react-pdf/renderer";
import moment from "moment";
import { convertUtcToLocal } from "../../../../Common/utils/dateUtils";
import softHyphenate from "./pdfUtils";

export default function ReceiptPDFHeader({ submission }) {
  const styles = StyleSheet.create({
    block: {
      width: "12",
      height: "27",
      backgroundColor: "#26abe1",
      borderRadius: "4",
      marginRight: "10",
    },
    header: {
      padding: "0 5 24 5",
      borderBottom: "1 solid #e0e0e0",
    },
    title: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "24",
      fontSize: "16",
    },
    table: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "13",
    },
    tableHeader: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "36",
    },
    tableHeaderCell: {
      textAlign: "left",
      display: "flex",
      flex: "1 1 33%",
      color: "#afbdca",
      fontSize: "12",
      verticalAlign: "middle",
    },
    tableRow: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "36",

    },
    tableRowCell: {
      textAlign: "left",
      display: "flex",
      flex: "1 1 33%",
      color: "#111a24",
      fontSize: "12",
      verticalAlign: "top",
    },
    commentTitle: {
      display: "flex",
      color: "#afbdca",
      marginTop: "16",
      marginBottom: "8",
      fontSize: "12",
    },
    commentBody: {
      display: "flex",
      color: "#111a24",
      fontSize: "12",
    },
  });
  const formattedDate = submission.submit_date ? moment(new Date(convertUtcToLocal(submission.submit_date))).format("MMM DD, YYYY | hh:mm a") : "-";

  return (
    <View style={styles.header}>
      <View style={styles.title}>
        <View style={styles.block} />
        <Text>Sample Submission Receipt</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <View style={styles.tableHeaderCell}><Text>Submission Name</Text></View>
          <View style={styles.tableHeaderCell}><Text>Submission Date</Text></View>
          <View style={styles.tableHeaderCell}><Text>PO#</Text></View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableRowCell}><Text>{softHyphenate(submission.submit_name || "")}</Text></View>
          <View style={styles.tableRowCell}><Text>{formattedDate}</Text></View>
          <View style={styles.tableRowCell}><Text>{softHyphenate(submission.po || "-")}</Text></View>
        </View>
      </View>
      <View>
        <View style={styles.commentTitle}><Text>Comments</Text></View>
        <View style={styles.commentBody}><Text>{softHyphenate(submission.comment || "-")}</Text></View>
      </View>
    </View>
  );
}

/* eslint-disable import/prefer-default-export */

/**
 * Parse email list string into array of emails
 * @param {String} emailListStr comma or semicolon separated list of emails
 * @returns {Array} array of emails
 */
export const parseDistributionList = (emailListStr) => {
  if (emailListStr?.trim()) {
    return emailListStr.trim().split(/[;|,|\s]+/g); // split by any number of consecutive commas, semicolons, and spaces
  }
  return [];
};

import React from "react";
import "./EnvAnalyticsHeader.css";

/**
 * EnvAnalyticsHeader Component
 * Renders headers for specifications with customizable colors.
 * @param {Object} specDetails - The specification details containing counts.
 * @param {string} mainHeading - The main heading for the section.
 * @param {string} sampleHeading - The heading for the sample count.
 * @param {string} retestHeading - The heading for the retest count.
 * @param {string} textColor - The text color for the headings and sample counts.
 * @param {string} borderColor - The border color for the boxes.
 * @param {string} backgroundColor - The background color for the boxes.
 */
const EnvAnalyticsHeader = ({
  specDetailsNum,
  specDetailsRetest,
  mainHeading,
  sampleHeading,
  retestHeading,
  textColor,
  borderColor,
  backgroundColor,
}) => (
  <div className="EnvAnalyticsHeader__Header">
    <span
      className="EnvAnalyticsHeader__MainHeading"
      style={{ color: textColor }}
    >
      {mainHeading}
    </span>
    <div className="EnvAnalyticsHeader__Dashboard">
      <div
        className="EnvAnalyticsHeader__Spec"
        style={{ borderColor, backgroundColor }}
      >
        <span className="EnvAnalyticsHeader__Heading">{sampleHeading}</span>
        <span
          className="EnvAnalyticsHeader__Samples"
          style={{ color: textColor }}
        >
          {specDetailsNum}
        </span>
      </div>
      <div
        className="EnvAnalyticsHeader__Spec"
        style={{ borderColor, backgroundColor }}
      >
        <span className="EnvAnalyticsHeader__Heading">{retestHeading}</span>
        <span
          className="EnvAnalyticsHeader__Samples"
          style={{ color: textColor }}
        >
          {specDetailsRetest}
        </span>
      </div>
    </div>
  </div>
);

export default EnvAnalyticsHeader;

import React from "react";
import { Form, Input, Select } from "antd";
import MyForm from "../../../../Onboarding/MyForm";
import { ReactComponent as Info } from "../../../../../assets/images/onboarding/Info.svg";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import EditableFields from "../../../../Onboarding/OnboardingForm/SubForms/FieldsForm/Components/EditableFields";

export default function OnboardingProductForm({
  layout, form, FORM_NAME, handleInitialValues, getJsonFieldName, handleEditableFields, setSubmitBtnValidation, productDropdownOptions,
}) {
  return (
    <MyForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...layout}
      form={form}
      requiredMark={false}
      name={FORM_NAME}
      validateTrigger={["onChange", "onBlur"]}
      initialValues={handleInitialValues("product_info")}
      className="Onboarding__SampleSubmission__Form MyForm OnboardingForm"
    >
      <div className="productDetail">
        <div className="OnboardingFormTitle">Product Details</div>
        <div>
          <Form.Item label={<span className="MyForm__SectionLabel FieldsForm__LabelText">Please enter the details you would like to include while submitting product samples</span>}>
            <Form.Item label={(
              <span className="MyForm__SectionLabel Onboarding__LabelText_Fields">
                Product Sample Submission Form Fields
                <StyledTooltip
                  title="Info to track the samples you send to the lab."
                  className="MyForm__Section_Tooltip"
                >
                  <Info />
                </StyledTooltip>
              </span>
                        )}
            >
              <Form.Item
                name="sample_type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <EditableFields getJsonFieldName={getJsonFieldName} onGetData={handleEditableFields} removableFieldIndex={2} />
              <div className="FormsContainer_Divider" />
              <Form.Item
                name="product_link"
                label={<span className="MyForm__SectionLabel FieldsForm__LabelText">Please select the field(s) associated with the specification for your products:</span>}
                rules={[
                  {
                    required: true, message: "",
                  },
                ]}
                className="LinkReportForm__ProductSpec"
              >
                <Select
                  mode="multiple"
                  allowClear
                  options={productDropdownOptions}
                  placeholder="Select field(s)"
                  onChange={() => {
                    const fieldValue = form.getFieldValue("product_link");
                    const editableFieldsValue = form.getFieldValue("editable_fields");
                    if (fieldValue.length === 0 || editableFieldsValue.includes("")) {
                      setSubmitBtnValidation(false);
                    } else {
                      setSubmitBtnValidation(true);
                    }
                  }}
                />
              </Form.Item>
            </Form.Item>
          </Form.Item>
        </div>
      </div>
    </MyForm>
  );
}

import React, { useEffect, useState } from "react";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  // Button,
  Form,
  Input,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

import "./EditableFields.css";
import StyledButton from "../../../../../Common/UIComponents/StyledButton";

import { ReactComponent as CloseIcon } from "../../../../../../assets/images/onboarding/clearField.svg";

const INVALID_CHARS = ["\u2022", "\u007C"];
const INVALID_CHARS_ESCAPED = ["\u{2022}", "\u{007C}"];

export default function EditableFields({
  getJsonFieldName,
  onGetData = () => {},
  removableFieldIndex = undefined,
  fieldLimit = 6,
  disabled,

}) {
  const form = useFormInstance();
  const fieldsList = useWatch(["editable_fields"]);
  const [validating, setValidating] = useState(false);

  const handleValidateOnListChange = async () => {
    setValidating(true);
    form.validateFields(["editable_fields"], { recursive: true }).then(() => { // run validation on all items in array (need to recheck for duplicates when a field is removed)
      setValidating(false);
    })
      .catch(() => {
      /*
      errorInfo:
        {
          values: {
            username: 'username',
            password: 'password',
          },
          errorFields: [
            { name: ['password'], errors: ['Please input your Password!'] },
          ],
          outOfDate: false,
        }
      */
        setValidating(false);
      });
  };

  /** Validate on change, needed for duplicate checking */
  useEffect(() => {
    if (form && fieldsList) {
      handleValidateOnListChange();
    }
    // eslint-disable-next-line no-unused-expressions
    onGetData?.(fieldsList);
  }, [form, fieldsList]); // eslint-disable-line react-hooks/exhaustive-deps

  /** on key down in input, check if key is an invalid char, if yes, don't let user input key
   * call onKeyDown from props
   * @param {Event} e KeyDown event
   */
  const onKeyDown = (e) => {
    if (INVALID_CHARS.includes(e.key)) {
      e.preventDefault();
    }
  };

  const escapeRegExp = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string

  return (
    <Form.List
      name="editable_fields"
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              required={false}
              key={field.key}
              className="EditableFields__DynamicItem"
            >
              <Form.Item
                {...field} // eslint-disable-line react/jsx-props-no-spreading
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      // console.log("duplicate validator", rule, value);
                      if (value) {
                        const _fields = getFieldValue("editable_fields");
                        const currJsonField = getJsonFieldName(value);
                        /** Check if duplicate of an uneditable field */
                        if (["sample_type", "received_date"].includes(currJsonField)) {
                          return Promise.reject(new Error("Duplicate field"));
                        }

                        /** Check for invalid characters */
                        const escapedInvalidChars = INVALID_CHARS_ESCAPED.map(escapeRegExp);
                        const regex = new RegExp(escapedInvalidChars.join("|"));
                        const firstMatch = value.match(regex);

                        if (firstMatch) {
                          return Promise.reject(new Error(`Remove invalid character: ${firstMatch}`));
                        }

                        /** Check if duplicate of earlier (editable) field */
                        for (let i = 0; i < index; i++) {
                          const _field = _fields[i];
                          if (_field) {
                            const json_field = getJsonFieldName(_field);
                            if (currJsonField === json_field) {
                              return Promise.reject(new Error("Duplicate field"));
                            }
                          }
                        }
                        return Promise.resolve();
                      }

                      /** Return empty error only if validating is true */
                      if (validating) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(`Please input field name${index > 2 ? " or remove this field." : ""}`));
                    },
                    validateTrigger: "onBlur",
                  }),
                ]}
                noStyle
              >
                <Input
                  placeholder="Field name"
                  allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }}
                  onKeyDown={onKeyDown}
                  disabled={disabled}
                />
              </Form.Item>
              {removableFieldIndex || removableFieldIndex === 0 ? (
                (index > removableFieldIndex) && (
                  <MinusCircleOutlined
                    className="EditableFields__DeleteIcon"
                    onClick={() => remove(field.name)}
                  />
                )
              ) : (
                <MinusCircleOutlined
                  className="EditableFields__DeleteIcon"
                  onClick={() => remove(field.name)}
                />
              )}
            </Form.Item>
          ))}
          {fields.length < fieldLimit && (
          <Form.Item>
            <StyledButton
              onClick={() => add()}
              icon={<PlusOutlined />}
              ghost
            >
              Add field
            </StyledButton>
            <Form.ErrorList errors={errors} />
          </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );
}

import React, { useEffect, useRef } from "react";

import moment from "moment";
import Scrollbar from "react-scrollbars-custom";

import CustomCalendar from "./CustomCalendar";

import { sortDates } from "../utils";

import "./CustomDateFrequencyForm.css";

export default function CustomDateFrequencyForm({
  updateSamplingData,
  selectedDatesList,
  setSelectedDatesList,
}) {
  const flatpickrRef = useRef();

  const options = {
    inline: true,
    mode: "multiple",
    minDate: moment().format("MM/DD/YYYY"),
    // maxDate: moment().add(1, "year").format("MM/DD/YYYY"),
  };

  useEffect(() => {
    updateSamplingData(selectedDatesList, "custom");
  }, []); // eslint-disable-line

  /**
   * Handles date change and sort before updating state
   * @param {Date[]} selectedDates selected dates
   */
  const onDateChange = (selectedDates) => {
    const fromDatePicker = (typeof selectedDates[0]) === "object";
    let sorted = sortDates(Array.from(selectedDates));
    if (fromDatePicker) { // if the selected dates are from the DatePicker event, then we need to convert the dates from object to a string
      sorted = sorted.map((dateObj) => moment(dateObj).format("MM/DD/YYYY"));
    }
    setSelectedDatesList(sorted);
    updateSamplingData(sorted ?? [], "custom");
  };

  /**
   * Remove invalid date
   * @param {Number} index index for date
   */
  const removeInvalidDate = (index) => {
    const datelist = [...selectedDatesList];
    datelist.splice(index, 1);
    onDateChange(datelist);
  };

  const isSelectedDateEmpty = selectedDatesList.length === 0;

  return (
    <div className="CustomDateFrequencyForm">
      <div className="CustomDateFrequencyForm__CalendarField">
        <span className="FieldTitle">Select dates to repeat testing</span>
        <div>
          <CustomCalendar
            value={selectedDatesList}
            ref={flatpickrRef}
            options={options}
            onChange={onDateChange}
          />
        </div>
      </div>
      <Scrollbar className="CustomDateFrequencyForm__Scrollbar">
        <div className="CustomDateFrequencyForm__SelectedDates">
          <span className="FieldTitle">Dates selected</span>
          <ul className={`CustomDateFrequencyForm__SelectedDatesList ${!isSelectedDateEmpty ? "CustomDateFrequencyForm__SelectedDatesList--non-empty" : ""}`}>
            {!isSelectedDateEmpty ? selectedDatesList.map((date, index) => {
              const invalid = moment(date, "MM/DD/YYYY").isBefore(moment(), "day");
              return (
                <li key={date} className={`CustomDateFrequencyForm__ListItem ${invalid ? "Invalid" : ""}`}>
                  {date}
                  {invalid && (
                    <span
                      className="CustomDateFrequencyForm__Cancel"
                      onClick={() => removeInvalidDate(index)}
                    >
                      &times;
                    </span>
                  )}
                </li>
              );
            }) : <li className="CustomDateFrequencyForm__EmptyListItem">-</li>}
          </ul>
        </div>
      </Scrollbar>
    </div>
  );
}

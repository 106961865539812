import React, { useCallback, useEffect, useState } from "react";

import moment from "moment";

import CustomCalendar from "./CustomCalendar";
import WeekMonthCheckbox from "./WeekMonthCheckbox";

import "./MonthFrequencyForm.css";

export default function MonthFrequencyForm({
  allowMultiple = false,
  updateSamplingData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dayOfMonth,
  setDayOfMonth,
}) {
  const [recurrences, setRecurrences] = useState(0);

  const flatPickerOptions = {
    plugins: [],
    dateFormat: "m/d/Y",
    mode: "single",
    minDate: moment().format("MM/DD/YYYY"),
    // maxDate: moment().add(1, "year").format("MM/DD/YYYY"),
  };

  /**
   * Find no. of recurrences
   */
  const getRecurrences = useCallback(() => {
    const start = moment(startDate, "MM/DD/YYYY");
    const end = moment(endDate, "MM/DD/YYYY");
    if (start.isAfter(end)) {
      return { recurrences: 0, dates: [] };
    }
    let count = 0;
    const dates = [];
    while (start <= end) {
      if (dayOfMonth.includes(start.date().toString().toLowerCase())) {
        dates.push(start.format("MM/DD/YYYY"));
        count++;
      }
      start.add(1, "day");
    }
    return { recurrences: count, dates };
  }, [endDate, startDate, dayOfMonth]);

  useEffect(() => {
    const { recurrences: recur, dates } = getRecurrences();
    setRecurrences(recur);
    updateSamplingData(dates, "monthly");
  }, [getRecurrences]); // eslint-disable-line

  /**
   * Handles change of month
   * @param {string[]} value Array of selected month dates
   */
  const handleChange = (value) => {
    setDayOfMonth(value);
  };

  return (
    <div className="MonthFrequencyForm">
      <div className="Calendar__Grid">
        <span className="FieldTitle">Start Day of repeat</span>
        <WeekMonthCheckbox
          type="month"
          allowMultiple={allowMultiple}
          onChange={handleChange}
          containerStyle="MonthFrequencyForm__MonthGrid"
          selectedDay={dayOfMonth}
        />
      </div>
      <div>
        <div className="Field No_Border">
          <span className="FieldTitle ExtraWidth">Start Date</span>
          <CustomCalendar
            value={startDate}
            onChange={([date]) => {
              setStartDate(date);
              if (endDate && moment(endDate, "MM/DD/YYYY").isBefore(moment(date, "MM/DD/YYYY"), "day")) {
                setEndDate(date);
              }
            }}
            options={flatPickerOptions}
          />
        </div>
        <div className="Field">
          <span className="FieldTitle ExtraWidth">End Date</span>
          <CustomCalendar
            value={endDate}
            onChange={([date]) => {
              if (startDate && moment(startDate, "MM/DD/YYYY").isAfter(moment(date, "MM/DD/YYYY"), "day")) return;
              setEndDate(date);
            }}
            options={{
              ...flatPickerOptions,
              minDate: startDate,
            }}
          />
        </div>
        <div className="Field">
          <span className="FieldTitle MonthFrequencyForm__RecurrenceText">No of recurrences</span>
          <div className="Recurrences">
            {recurrences}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Scrollbar } from "react-scrollbars-custom";
import FormLayout from "../../../Onboarding/FormLayout";
import "./OnboardingFormLayout.css";

export default function OnboardingFormLayout({ children }) {
  return (
    <FormLayout className="OnboardingFormLayout__SampleSubmission">
      <Scrollbar
        id="OnboardingscrollBar"
        className="Onboarding__Scrollbar"
      >
        {children}
      </Scrollbar>
    </FormLayout>
  );
}

import React from "react";

import StyledButton from "../../../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import { CHAR_LIMITS } from "../../Constant";

import { ReactComponent as PlusIcon } from "../../../../../assets/images/sample-submission/addSamplePlusIcon.svg";

import "../../SubmissionForm.css";

export default function AddUpdateSampleButton({
  waitingForAddEditAPI,
  sampleEditingIdx,
  handleSaveEditedSample,
  handleAddSample,
  handleCancelEditSample,
  invalidInputFound,
  invalidTest,
  disableAddSample,
  sampleIDGenerationError,
  generatedSampleID,
  isPesticideSampleEmpty,
  submissionEditingSample,
  isLoading,
  setEditingSubmittedSampleComposite,
}) {
  const invalidSampleIDLength = generatedSampleID.length > CHAR_LIMITS.sample_id;
  const disable = waitingForAddEditAPI || invalidInputFound || invalidTest || invalidSampleIDLength || sampleIDGenerationError || isPesticideSampleEmpty;
  let message = "";
  if (sampleIDGenerationError) {
    message = "Failed to generate Sample ID";
  } else if (invalidInputFound || invalidTest) {
    message = "Invalid inputs and/or tests";
  }
  /**
   * Update the edit sample or Edit the submitted sample
   */
  const handleUpdateEdit = async () => {
    if (submissionEditingSample) {
      const submitEdit = true;
      setEditingSubmittedSampleComposite(false);
      await handleSaveEditedSample(submitEdit);
    } else {
      handleSaveEditedSample();
    }
  };

  const onClick = sampleEditingIdx > -1 ? handleUpdateEdit : handleAddSample;

  return (
    <div className="new-submission-pages-button-div">
      <StyledTooltip
        title={message}
        placement="top"
      >
        <StyledButton
          type="primary"
          ghost={sampleEditingIdx > -1 || disableAddSample ? null : true}
          className={disable ? `new-submission-pages-button ${sampleEditingIdx > -1 || disableAddSample ? "update-sample" : "add-sample"} disabled` : `new-submission-pages-button ${sampleEditingIdx > -1 || disableAddSample ? "update-sample" : "add-sample"}`}
          onClick={disable ? null : onClick}
          loading={isLoading}
          icon={<PlusIcon className="new-submission-plus-icon" title="add icon" width={20} height={20} />}
        >
          <span>{sampleEditingIdx > -1 || disableAddSample ? "Update Sample" : "Add Sample"}</span>
        </StyledButton>
      </StyledTooltip>

      {(!submissionEditingSample && sampleEditingIdx > -1) && (
      <StyledButton
        type="primary"
        ghost
        className="new-submission-pages-button add-sample"
        onClick={handleCancelEditSample}
        loading={isLoading}
      >
        Cancel
      </StyledButton>
      )}
    </div>
  );
}

import axios from "axios";
import { API_URL } from "../utils/Constant";

export const getTemplates = async (payload, signal = null) => {
  try {
    const params = {
      template_id: payload?.template_id || "",
    };
    const response = await axios.get(`${API_URL}/producttemplates/`, {
      params,
      signal,
    });
    return { success: true, ...response.data };
  } catch (e) {
    return { success: false, data: "Something went wrong.", cancel: e.code === "ERR_CANCELED" };
  }
};

export const addTemplate = async (payload) => {
  try {
    const params = {
      name: payload.name,
      tags: payload.tags,
      logo_image_url: payload.logo_image_url,
      company_name: payload.company_name,
      company_address: payload.company_address,
      phone_number: payload.phone_number,
      fax_number: payload.fax_number,
      sections: payload.sections_list,
      statement_inputs: payload.statement_inputs,
      signature_image_url: payload.signature_image_url,
      signed_by: payload.signed_by,
      designation: payload.designation,
      preview_pdf_url: payload.preview_pdf_url,
      alignment: payload.alignment,
      // company_details_shared: false,
      // test_report_shared: false,
      // statement_card_shared: false,
      // test_report: [],
    };
    const response = await axios.post(`${API_URL}/producttemplates/`, params);
    return { success: true, ...response.data };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const editTemplate = async (template_id, payload) => {
  try {
    const params = {
      action: "edit",
      template_id,
      name: payload.name,
      tags: payload.tags,
      logo_image_url: payload.logo_image_url,
      company_name: payload.company_name,
      company_address: payload.company_address,
      phone_number: payload.phone_number,
      fax_number: payload.fax_number,
      sections: payload.sections_list,
      statement_inputs: payload.statement_inputs,
      signature_image_url: payload.signature_image_url,
      signed_by: payload.signed_by,
      designation: payload.designation,
      preview_pdf_url: payload.preview_pdf_url,
      alignment: payload.alignment,
      // company_details_shared: false,
      // test_report_shared: false,
      // statement_card_shared: false,
      // test_report: [],
    };
    const response = await axios.patch(`${API_URL}/producttemplates/`, params);
    return { success: true, ...response.data };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const deleteTemplate = async (template_id) => {
  try {
    const params = {
      action: "delete",
      template_id,
    };
    const response = await axios.patch(`${API_URL}/producttemplates/`, params);
    return { success: true, ...response.data };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const getDataField = async (payload) => {
  try {
    const response = await axios.get(`${API_URL}/datafields/`, {
      params: {
        category: payload.category,
      },
    });
    const data = await response.data;
    if (data.message === "Success") {
      return { success: true, result: data.result };
    }
    const message = "Something went wrong.";
    return { success: false, message };
  } catch (e) {
    // console.log("error from getDataField", e);
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

export const manageDataFields = async (payload) => {
  try {
    const response = await axios({
      method: payload.method,
      url: `${API_URL}/datafields/`,
      data: {

        action: payload.action,
        field_name: payload.field_name,
        old_field_name: payload?.field_name_pre || "",
        field_details: payload?.field_details || "",
        category: payload?.category || "",
        // field_in_use:  payload?.field_in_use || "",
      },
    });
    const data = await response.data;
    return { success: true, ...data };
  } catch (e) {
    // console.log("error from addDataField", e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

export const sortAndSearchDataFields = async (payload) => {
  try {
    const response = await axios.get(`${API_URL}/datafields/`, {
      params: {
        sort_by: payload.sort_by,
        search: payload.search,
        category: payload.category,
      },
    });
    const data = await response.data;
    if (data && data.result) {
      return { success: true, result: data.result };
    } return null;
  } catch (e) {
    // console.log("error from sortAndSearchDataFields", e);
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

export const getCoas = async (payload) => {
  try {
    const paramsObj = {
      search: payload.search || "",
    };

    const response = await axios.get(`${API_URL}/productcoas/`, {
      params: paramsObj,
    });
    const data = await response.data;
    return { success: true, ...data };
  } catch (e) {
    // console.log("error from getCoas", e);
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

export const manageCoa = async (payload) => {
  try {
    const response = await axios({
      method: payload.method,
      url: `${API_URL}/productcoas/`,
      data: {
        name: payload?.name || "",
        sample_list: payload?.sample_list,
        template_id: payload.template_id || "",
        coa_id: payload?.coa_id || "",
        pdf_path: payload?.pdf_path || "",
      },
    });
    const data = await response.data;
    return { success: true, ...data };
  } catch (e) {
    // console.log("error from manageCoa", e);
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

export const getProducts = async (payload) => {
  try {
    const response = await axios.get(`${API_URL}/getproductname/`, {
      params: {
        search: payload?.search || "",
      },
    });
    const data = await response.data;
    return { success: true, ...data };
  } catch (e) {
    // console.log("error from getProducts", e);
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

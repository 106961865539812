import React, { useEffect, useMemo } from "react";

import moment from "moment";
import Flatpickr from "react-flatpickr";

import "flatpickr/dist/themes/light.css";
import "./CustomCalendar.css";

function CustomCalendar({
  options,
  containerClassName = "",
  pickerClassName = "",
  value,
  ...rest
}, ref) {
  const isMounted = React.useRef(false);

  const defaultOptions = {
    plugins: [],
    inline: false,
    dateFormat: "m/d/Y",
    mode: "single",
  };

  useEffect(() => {
    if (!isMounted.current) {
      document.body.classList.add("CustomCalendar__Calendar");
      isMounted.current = true;
    }

    return () => {
      document.body.classList.remove("CustomCalendar__Calendar");
    };
  }, []);

  const hasError = useMemo(() => value && moment(value, "MM/DD/YYYY").isBefore(moment(), "day"), [value]);

  const flatpickrOptions = { ...defaultOptions, ...options };

  return (
    <div
      className={`CustomCalendar ${flatpickrOptions.inline ? "CustomCalendar--is-inline" : ""} ${hasError ? "CustomCalendar--hasError" : ""} ${containerClassName}`}
    >
      <Flatpickr
        ref={ref}
        options={flatpickrOptions}
        className={pickerClassName}
        value={value}
        {...rest} // eslint-disable-line
      />
    </div>
  );
}

export default React.forwardRef(CustomCalendar);

import React from "react";
import { ReactComponent as AddIcon } from "../../../../../../../assets/images/sample-submission/addTestIcon.svg";
import "./COABuilderAddSection.css";

export default function COABuilderAddSection({ handleAddSection }) {
  return (
    <div className="COABuilderAddSection__Container">
      <div className="COABuilderAddSection__Line" />
      <div className="COABuilder__AddSectionButton" onClick={handleAddSection}>
        <AddIcon className="COABuilder__AddIcon" />
        <span>Add Section</span>
      </div>
      <div className="COABuilderAddSection__Line" />
    </div>
  );
}

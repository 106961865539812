import React from "react";
import AddIcon from "../../../../assets/images/sample-submission/add.svg";
import "./CreateNewSubmissionButton.css";

export default function CreateNewSubmissionButton({ setShowSubmissionForm }) {
  return (
    <button type="button" className="create-new-submission-button" onClick={() => setShowSubmissionForm(true)}>
      <img src={AddIcon} alt="" />
      Create New Sample Submission
    </button>
  );
}

import React, { useContext, useEffect, useState } from "react";

import { pdf } from "@react-pdf/renderer";
import Modal from "antd/es/modal/Modal";
import printJS from "print-js";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import StyledButton from "../../../Common/UIComponents/StyledButton";
import SampleFunctionContext from "../../SampleFunctionContext";
import SampleSubmissionContext from "../../SampleSubmissionContext";
import ReceiptModalActionsBar from "./ReceiptModalActionsBar";
import ReceiptModalHeader from "./ReceiptModalHeader";
import ReceiptPDF from "./ReceiptPDF/ReceiptPDF";
import SamplePageHeader from "./SamplePageHeader";
import SamplesInfo from "./SamplesInfo";
import SamplesList from "./SamplesList";
import SenderInfo from "./SenderInfo";

import { getCustomerAddress, getSubmittedSubmissionsInfo } from "../../../../actions/sampleSubmission";

import { ReactComponent as CloseIcon } from "../../../../assets/images/sample-submission/close.svg";
// import PdfPreview from "../../../Common/PdfPreview";

import "./SubmissionReceiptModal.css";

const FOOTER_TEXT = [
  "© 2023 ESV, All rights reserved",
  "Genista Biosciences, Inc. 5500 Hellyer Avenue, San Jose, CA. 95138 P. 1.408.934.6575",
];

const CUSTOMER_INFO_FIELDS = [
  { label: "Attn", key: "user_name" },
  { label: "Company", key: "company" },
  { label: "Phone", key: "telephone" },
  { label: "Fax", key: "fax" },
  { label: "Email", key: "user_email" },
  { label: "Address", key: "address_concat" },
];

const CUSTOMER_INFO_FIELDS_BY_ROW = [
  [{ label: "Attn", key: "user_name" },
    { label: "Company", key: "company" }],
  [{ label: "Phone", key: "telephone" },
    { label: "Fax", key: "fax" }],
  [{ label: "Email", key: "user_email" },
    { label: "Address", key: "address_concat" }],
];

export default function SubmissionReceiptModal({
  showReceipt,
  handleReceipt,
  submissionFromParent = null,
  submissionId,
  customerId,
  closable = true,
  maskClosable = true,
  headerTitleText,
  po, // needed because po is not included in getSubmission api response
}) {
  const { sampleCategory, isLoggedOut, thirdParty } = useContext(SampleSubmissionContext);
  const { setOnAddBackButton } = useContext(SampleFunctionContext);
  const [loadingCustomerInfo, setLoadingCustomerInfo] = useState(true);
  const [customerInfo, setCustomerInfo] = useState({});
  const [submission, setSubmission] = useState(submissionFromParent);
  const [loadingSubmission, setLoadingSubmission] = useState(!submissionFromParent);
  const [generatingPDF, setGeneratingPDF] = useState(false);
  // const [blobUrl, setBlobUrl] = useState("");

  const getCustomerInfo = async () => {
    const result = await getCustomerAddress((isLoggedOut && !thirdParty) ? customerId : undefined, sampleCategory);
    if (result.success) {
      const addressArr = [];
      if (result.address.address_1) {
        addressArr.push(result.address.address_1);
      }
      if (result.address.address_2) {
        addressArr.push(result.address.address_2);
      }
      result.address.address_concat = addressArr.join(", ");
      setCustomerInfo(result.address);
    } else {
      toast.error(result.message);
    }
    setLoadingCustomerInfo(false);
  };

  const getSubmission = async () => {
    setLoadingSubmission(true);
    const payload = {
      submission_id: submissionId,
      page: 1,
      sample_search: "",
      submission_search: "",
      search_by: "",
      date_from: "",
      date_to: "",
      user_selected_date_from: "",
      user_selected_date_to: "",
      submitted_by: "",
      sample_category: sampleCategory,
    };

    const {
      success,
      message,
      result,
    } = await getSubmittedSubmissionsInfo(payload);

    if (success) {
      setSubmission({ ...result, submit_id: submissionId, po });
    } else {
      toast.error(message);
    }
    setLoadingSubmission(false);
  };

  useEffect(() => {
    getCustomerInfo();
    if (!submissionFromParent) {
      getSubmission();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getFileName = () => {
    const companyName = customerInfo.company;
    const submissionName = submission.submit_name;
    const timestamp = Date.now();
    const fileName = `${companyName}_${submissionName}_${timestamp}.pdf`;
    const sanitizedFileName = fileName.replace(/\s|\//g, "_");
    return sanitizedFileName;
  };

  const generatePDFBlob = async () => {
    try {
      setGeneratingPDF(true);
      const pdfBlob = await pdf(ReceiptPDF({
        submission, customerInfo, CUSTOMER_INFO_FIELDS_BY_ROW, FOOTER_TEXT, getFileName,
      })).toBlob();
      setGeneratingPDF(false);
      return pdfBlob;
    } catch (e) {
      setGeneratingPDF(false);
      toast.error("Failed to generate pdf");
      return null;
    }
  };

  const handlePrint = async () => {
    const pdfBlob = await generatePDFBlob();
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      printJS(url);
      // setBlobUrl(url);
    }
    setOnAddBackButton(false);
  };

  const handleDownload = async () => {
    const pdfBlob = await generatePDFBlob();
    if (pdfBlob) {
      const url = window.URL.createObjectURL(pdfBlob);
      if (url) {
        const a = document.createElement("a");
        a.href = url;
        a.download = getFileName();
        a.click();
      }
    }
    setOnAddBackButton(false);
  };

  const handleOnCancel = () => {
    handleReceipt();
    setOnAddBackButton(false);
  };
  return (
    <Modal
      title={(
        <ReceiptModalActionsBar
          loading={loadingCustomerInfo || loadingSubmission || generatingPDF}
          headerTitleText={headerTitleText}
          handlePrint={handlePrint}
          handleDownload={handleDownload}
          closable={closable}
        />
        )}
      maskClosable={maskClosable}
      closeIcon={<CloseIcon />}
      open={showReceipt}
      onCancel={handleOnCancel}
      centered
      destroyOnClose
      footer={closable ? null : <StyledButton onClick={handleOnCancel} type="primary">Done</StyledButton>}
      wrapClassName="SubmissionReceiptModal"
    >
      <Scrollbar className="SubmissionReceiptModal__Scrollbar">
        <div className="SubmissionReceiptModal__Content">
          <div className="SubmissionReceiptModal__Container">
            <ReceiptModalHeader submission={submission} />
            <SamplesInfo submission={submission} customerInfo={customerInfo} />
            <SenderInfo CUSTOMER_INFO_FIELDS={CUSTOMER_INFO_FIELDS} customerInfo={customerInfo} />
          </div>
          <div className="SubmissionReceiptModal__Footer">
            {FOOTER_TEXT.map((text) => (
              <span key={text}>{text}</span>
            ))}
          </div>
        </div>
        <div className="SubmissionReceiptModal__Content">
          <div className="SubmissionReceiptModal__Container">
            <SamplePageHeader />
            <SamplesList submission={submission} />
          </div>
        </div>
      </Scrollbar>
      {/* {blobUrl && ( // for debugging pdf
        <Modal
          open
          width="800px"
          onCancel={() => setBlobUrl("")}
          onOk={() => setBlobUrl("")}
        >
          <PdfPreview
            blob={blobUrl}
            height="70vh"
            scaleNumber={1}
          />
        </Modal>
      )} */}
    </Modal>
  );
}

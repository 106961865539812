import React from "react";

import {
  View, StyleSheet, Text, Image,
} from "@react-pdf/renderer";

export default function ReceiptPDFSamplesInfo({ submission }) {
  const styles = StyleSheet.create({
    samplesInfoSection: {
      padding: "16 0 24 5",
      borderBottom: "1 solid #e0e0e0",
      display: "flex",
      flexDirection: "row",
      gap: "10",
      justifyContent: "space-between",
    },
    table: {
      display: "flex",
      flexDirection: "column",
      marginTop: "16",
    },
    tableHeader: {
      display: "flex",
      flexDirection: "row",
      gap: "36",
    },
    tableHeaderCell: {
      textAlign: "left",
      display: "flex",
      color: "#afbdca",
      fontSize: "12",
      verticalAlign: "middle",
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
      gap: "36",
      paddingTop: "13",
    },
    tableRowCell: {
      textAlign: "left",
      display: "flex",
      color: "#111a24",
      fontSize: "14",
      verticalAlign: "top",
    },
    first: {
      width: "200",
    },
    qrCode: {
      width: "180",
      height: "180",
      padding: "12",
      marginRight: "10",
      marginTop: "4",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  });

  const qrCodeSrc = document.getElementById("sample_submission_receipt_qrcode").querySelector("canvas").toDataURL();

  return (
    <View style={styles.samplesInfoSection}>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <View style={[styles.tableHeaderCell, styles.first]}><Text>Total Quantity of</Text></View>
          <View style={styles.tableHeaderCell}><Text>Qty</Text></View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableRowCell, styles.first]}><Text>Samples</Text></View>
          <View style={styles.tableRowCell}><Text>{submission.num_samples}</Text></View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableRowCell, styles.first]}><Text>Tests</Text></View>
          <View style={styles.tableRowCell}><Text>{submission.samples_list.reduce((numTestsSoFar, { tests_list }) => numTestsSoFar + tests_list.length, 0)}</Text></View>
        </View>
      </View>
      <View style={styles.qrCode}><Image src={qrCodeSrc} /></View>
    </View>
  );
}

import React, { useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import { LayoutGroup } from "framer-motion/dist/framer-motion";
import TitleBlock from "../CommonComponents/TitleBlock";
import TestList from "./Components/TestList";
import SearchTests from "../CommonComponents/SearchTests";
import SelectionTitleSkeleton from "../CommonComponents/SelectionTitleSkeleton";
import NoResults from "../../../../Common/NoResults";
import emptyStateIcon from "../../../../../assets/images/EmptyState_V2.png";
import "../../styles/TestContainerStyle.css";
import SelectionCard from "../CommonComponents/SelectionCard";

export default function TestContainer({
  updateSelectedOptions,
  selectedData,
  updateTestList,
  updateSearch,
  parentLoading,
  disabledColumns,
  testList,
  searchTest,
}) {
  const { selectedTests, selectedProducts } = selectedData;
  const [loading, setLoading] = useState(false);

  // To display the selected tests on the top of the list
  const renderSelectedTiles = () => {
    let disabled = false;
    if (disabledColumns.includes("test")) disabled = true;
    if (selectedTests && selectedTests.length !== 0) {
      return selectedTests.map((item) => (
        <SelectionCard
          key={item}
          animationKey={item}
          data={item}
          type="test"
          updateSelectedOptions={updateSelectedOptions}
          disabled={disabled}
          active
          searchValue={searchTest}
        />
      ));
    }

    return null;
  };

  // Renders a test list component for each unique unit
  const renderTestList = () => {
    let disabled = false;
    if (disabledColumns.includes("test")) disabled = true;
    if (loading || parentLoading) {
      return (<SelectionTitleSkeleton />);
    }
    if (testList.units !== undefined && testList.tests !== undefined && testList.units.length !== 0) {
      const units = testList.units;
      return units.map((item, index) => {
        const testData = {
          testUnit: item,
          testNames: testList.tests[item],
        };
        return (!loading && !parentLoading && (
        <TestList
          testData={testData}
          key={index}
          updateSelectedOptions={updateSelectedOptions}
          disabled={disabled}
          selectedTests={selectedTests}
          searchTest={searchTest}
        />
        ));
      });
    }
    return <NoResults message="No results found." image={emptyStateIcon} />;
  };

  // renders the test list and selected tests
  const renderTests = () => { // eslint-disable-line
    return (
      <Scrollbar id="analyticsScrollbar">
        <div className="analyticsTestListContainerScrollable">
          <LayoutGroup>
            {renderSelectedTiles()}
            {renderTestList()}
          </LayoutGroup>
        </div>
      </Scrollbar>
    );
  };

  // renders title and search box
  return (
    <div className="analyticsColumnContainer">
      <TitleBlock title="Tests" subtext="Select Tests to view the graph with respective Product(s)" />
      <SearchTests updateTestList={updateTestList} setLoading={setLoading} selectedProducts={selectedProducts} updateSearch={updateSearch} />
      {renderTests()}
    </div>
  );
}

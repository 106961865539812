import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { pdf } from "@react-pdf/renderer";
import BuilderHeader from "./Builder/Header/BuilderHeader";
import BuilderContent from "./Builder/Contents/BuilderContent";
import BuilderTests from "./Builder/BuilderTests/BuilderTests";
import BuilderConfirmSave from "./Builder/ConfirmSave/BuilderConfirmSave";
import PDF from "./PDF/PDF";
import "./templatebuilder.css";
import { addTemplate, editTemplate, manageCoa } from "../../../../actions/productTemplate";
import { s3EsvPriv } from "../../../../utils/aws";
import { deleteFileFromS3, uploadFileToS3 } from "../../../../utils/helpers";
import BuilderDragDropWrapper from "./Builder/DragAndDrop/BuilderDragDropWrapper";

export default function TemplateBuilder({
  handleNavigation,
  isCustomizingCoa,
  isEditingTemplate,
  selectedTemplate,
  selectedReports,
  productFieldMap,
}) {
  const [previewTemplate, setPreviewTemplate] = useState(false);
  const [sections, setSections] = useState([]);
  const sectionRefs = useRef({});
  const [inputsPayload, setInputsPayload] = useState(null);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null); // null, "loading", "success"
  const [logo, setLogo] = useState({});
  const [signature, setSignature] = useState({});
  const [alignLeft, setAlignLeft] = useState(true);
  const [commonDataFieldsInUse, setCommonDataFieldsInUse] = useState([]);
  const [customizedDataFieldsInUse, setCustomizedDataFieldsInUse] = useState([]);
  const [allCustomizedDataFields, setAllCustomizedDataFields] = useState([]);
  const [enableTests, setEnableTests] = useState(false);
  // Extract unique test values from the test_results nested within the samples array of selectedReports, ensuring that each test value appears only once in the final array
  // while setting the selected property to false for each unique test value
  const [uniqueTests, setUniqueTests] = useState([...new Set(selectedReports?.samples.flatMap((item) => item.test_results.map((result) => result.test)))].map((test) => ({ test, selected: false })));
  const [sectionsFixed, setSectionsFixed] = useState([
    {
      title: "My details",
      key: "details",
      active: true,
      edit: false,
      focus: false,
      expanded: true,
      inputs: {
        company_name: {}, company_address: {}, phone_number: {}, fax_number: {},
      },
    },
    // {
    //   title: "Test reports",
    //   key: "test",
    //   active: false,
    //   edit: false,
    //   focus: false,
    //   expanded: true,
    //   ref: useRef(),
    // },
    {
      title: "Statement card",
      key: "statement",
      active: false,
      edit: false,
      focus: false,
      expanded: true,
      inputs: [{ isSignature: true, signed_by: {}, designation: {} }],
    },
  ]);

  /* * Used in Customized COA to toggle the test view */
  const handleTestToggle = (value) => [
    setEnableTests(value),
  ];

  /** Format section inputs for builder, return sections array */
  const parseTemplateSections = (_sections) => (
    _sections.map(({ section_name, fields }) => {
      const inputs = { left: [], right: [] };
      const dataFields = { 1: new Set(), 2: new Set() };
      fields.forEach(({
        value, size, position, flag,
      }) => {
        const sectionPart = position[0] === 1 ? "left" : "right";
        let _fieldName;
        let _jsonField;
        let _value;
        let _type = "datafield";
        if (flag === "0") {
          _value = value;
          _type = `${size}TextBox`;
        } else if (flag === "1") {
          _jsonField = value;
          _fieldName = productFieldMap[value];
          if (isCustomizingCoa) {
            const options = selectedReports.data_fileds;
            if (options && options[_jsonField] && options[_jsonField].length === 1) {
              _value = options[_jsonField][0];
            }
          }
          dataFields["1"].add(_fieldName);
          handleTemplateDataFieldsChange("add", "1", _fieldName); // eslint-disable-line
        } else {
          _fieldName = value;
          dataFields["2"].add(_fieldName);
          handleTemplateDataFieldsChange("add", "2", _fieldName); // eslint-disable-line
        }
        inputs[sectionPart].push({
          id: `${_type}_${uuidv4()}`,
          defaultValue: _value,
          fieldName: _fieldName,
          jsonField: _jsonField,
          type: _type,
          flag,
        });
      });
      return ({
        title: section_name,
        active: false,
        edit: true,
        focus: false,
        expanded: false,
        dataFields,
        inputs,
      });
    })
  );
  /** On mount if customizing coa, set state */
  useEffect(() => {
    if (isCustomizingCoa && selectedTemplate && selectedReports) {
      setSections(parseTemplateSections(selectedTemplate.sections));
    }
  }, [isCustomizingCoa, selectedTemplate, selectedReports]); // eslint-disable-line

  /** On mount if editing template, set state */
  useEffect(() => {
    if (isEditingTemplate && selectedTemplate) {
      setSections(parseTemplateSections(selectedTemplate.sections));
      const {
        logo_image_url, company_name, company_address, phone_number,
        fax_number, signature_image_url, signed_by, designation, statement_inputs,
      } = selectedTemplate;
      const _sectionsFixed = [];
      sectionsFixed.forEach((section) => {
        const _section = { ...section };
        const { inputs } = _section;
        if (section.title === "My details") {
          _section.inputs = {
            company_name: { defaultValue: company_name, ref: inputs.company_name.ref },
            company_address: { defaultValue: company_address, ref: inputs.company_address.ref },
            phone_number: { defaultValue: phone_number, ref: inputs.phone_number.ref },
            fax_number: { defaultValue: fax_number, ref: inputs.fax_number.ref },
            logo_image_url,
          };
        } else { // Statement card
          _section.inputs = statement_inputs.map(({ title, value, size }) => {
            if (title === "signature") {
              return ({
                isSignature: true,
                signed_by: { defaultValue: signed_by, ref: inputs[0].signed_by.ref },
                designation: { defaultValue: designation, ref: inputs[0].designation.ref },
                signature_image_url,
              });
            }
            const type = `${size}TextBox`;
            return ({
              isSignature: false,
              id: `${type}_${uuidv4()}`,
              defaultValue: value,
              type,
              flag: "0",
            });
          });
        }
        _sectionsFixed.push(_section);
      });
      setSectionsFixed(_sectionsFixed);
    }
  }, [isEditingTemplate, selectedTemplate]); // eslint-disable-line

  /**
   * This useEffect handles the scroll behavior of the sections.
   * Whatever element is selected, it gets center on the screen.
  */
  useEffect(() => {
    if (!isCustomizingCoa) {
      const sectionsArray = [...sections];
      const fixedArray = [...sectionsFixed];
      let scrolled = false;
      for (let index = 0; index < sectionsArray.length; index++) {
        const section = sectionsArray[index];
        if (section.active) {
          sectionRefs.current[sectionsArray[index].title].scrollIntoView({ behavior: "smooth", block: "center" });
          scrolled = true;
          break;
        }
      }
      if (!scrolled) {
        for (let index = 0; index < sectionsFixed.length; index++) {
          const section = sectionsFixed[index];
          if (section.active) {
            sectionRefs.current[fixedArray[index].title].scrollIntoView({ behavior: "smooth", block: "center" });
            break;
          }
        }
      }
    }
  }, [sections, sectionsFixed, isCustomizingCoa]);

  /**
   * Update fields in use arrays when inputs added/deleted
   * @param {String} op "add" or "delete"
   * @param {String} flag  "0" (textbox) "1" (common/default field) or "2" (customer/user-created field)
   * @param {String} value field value
   */
  const handleTemplateDataFieldsChange = (op, flag, value) => {
    if (flag !== "0") {
      const setFieldsFunc = flag === "1" ? setCommonDataFieldsInUse : setCustomizedDataFieldsInUse;
      if (op === "add") {
        setFieldsFunc((pre) => [...pre, value]);
      } else if (op === "delete") {
        setFieldsFunc((pre) => pre.filter((ele) => ele !== value));
      }
    }
  };

  /**
   * Set the inputs for a section
   * @param {Number} sectionIndex index of section in sections array
   * @param {Object} inputs {left: [{ id, value, fieldName, flag, json_field }, ...], right: []}
   * @param {String} dataFieldAction { action: "add" | "delete", flag: "1", "2", value }
   */
  const setSectionInputs = (sectionIndex, inputs, isFixed = false, dataFieldChange = null, autoFocus = true) => {
    // console.log("setSectionInputs", "isFixed", isFixed, "sectionIndex", sectionIndex, "inputs", inputs);
    const copy = [...(isFixed ? sectionsFixed : sections)];
    const section = copy[sectionIndex];
    section.inputs = inputs;
    /** Handle add/remove data field from section */
    if (dataFieldChange) {
      const { action, flag, value } = dataFieldChange;
      if (flag !== "0" && !isFixed) {
        const fieldsSet = section.dataFields[flag];
        if (action === "add") {
          fieldsSet.add(value);
        } else {
          fieldsSet.delete(value);
        }
      }
    }
    /** Focus section if not already focused */
    if (!isCustomizingCoa && !section.active && autoFocus) {
      const prevActiveIdx = sections.findIndex(({ active }) => active);
      const prevActiveIdxFixed = sectionsFixed.findIndex(({ active }) => active);

      if (prevActiveIdx > -1) {
        if (isFixed) {
          sections[prevActiveIdx].active = false;
          setSections([...sections]);
        } else {
          copy[prevActiveIdx].active = false;
        }
      }
      if (prevActiveIdxFixed > -1) {
        if (isFixed) {
          copy[prevActiveIdxFixed].active = false;
        } else {
          sectionsFixed[prevActiveIdxFixed].active = false;
          setSectionsFixed([...sectionsFixed]);
        }
      }
      section.active = true;
    }
    if (isFixed) {
      setSectionsFixed(copy);
    } else {
      setSections(copy);
    }
  };

  const handleCreateSection = () => {
    const sectionsArray = [...sections];
    const sectionsFixedArray = [...sectionsFixed];
    let title = "New section";
    sectionsArray.forEach((section) => {
      section.active = false;
    });
    sectionsFixedArray.forEach((section) => {
      section.active = false;
    });

    if (sectionsArray.length > 0) {
      let count = parseInt(sectionsArray[sectionsArray.length - 1].title.split(" ")[2]);
      if (!count) {
        count = 1;
      } else {
        count += 1;
      }
      title = `New Section ${count}`;
    }
    sectionsArray.push({
      title,
      active: true,
      edit: true,
      focus: false,
      expanded: true,
      dataFields: { 1: new Set(), 2: new Set() },
      inputs: { left: [], right: [] },
    });

    setSections(sectionsArray);
  };

  const handleSectionClick = (item) => {
    const fixedArray = [...sectionsFixed];
    const itemsArray = [...sections];
    fixedArray.forEach((value, index) => {
      if (value.title === item.title) {
        fixedArray[index].active = true;
      } else {
        fixedArray[index].active = false;
      }
    });
    itemsArray.forEach((value, index) => {
      if (value.title === item.title) {
        itemsArray[index].active = true;
      } else {
        itemsArray[index].active = false;
      }
    });
    setSections(itemsArray);
    setSectionsFixed(fixedArray);
  };

  /**
   * Handles the deletion of user created sections
   * @param {e} e
   * @param {item} item
   */
  const handleSectionDelete = (e, item) => {
    e.stopPropagation();
    const itemArray = [...sections];
    const itemArrayFixed = [...sectionsFixed];
    const itemArrayFilter = [];

    itemArray.forEach((element, index) => {
      if (element.title === item.title) {
        if (index === 0) {
          itemArrayFixed[0].active = true;
        } else {
          itemArray[index - 1].active = true;
        }
        const sectionDataFields = element.dataFields;
        setCommonDataFieldsInUse((pre) => pre.filter((ele) => !sectionDataFields["1"].has(ele)));
        setCustomizedDataFieldsInUse((pre) => pre.filter((ele) => !sectionDataFields["2"].has(ele)));
      } else {
        itemArrayFilter.push(element);
      }
    });
    setSectionsFixed([...itemArrayFixed]);
    setSections([...itemArrayFilter]);
  };

  const handleSectionSelect = (item) => {
    if (!item.active) {
      handleSectionClick(item);
    }
  };

  const checkTemplateValidation = () => {
    for (let i = 0; i < customizedDataFieldsInUse.length; i++) {
      const dataField = customizedDataFieldsInUse[i];
      if (allCustomizedDataFields.indexOf(dataField) === -1) {
        toast.error("Please remove invalid data field before save the current template.");
        return false;
      }
    }
    return true;
  };

  /**
   * Open/close the save template modal
   * @param {Boolean} closing if closing the modal, make sure to set saveUpdatedPDF to false if it is still true
   */
  const toggleSaveModal = (closing = false) => {
    setShowSaveModal(!showSaveModal);
    if (closing) {
      setSaveStatus(null);
    }
  };

  /**
   * Add file to AWS
   * @param {File} file
   * @param {String} type "logo_image", "signature_image", "preview_pdf"
   * @returns {Promise} resolves { blob, type }
   */
  const addAssetToAWS = ({ file, type }) => new Promise((resolve) => {
    const splitByDot = file.name.trim().split(".");
    const fileEnding = splitByDot.pop();
    const fileName = splitByDot.join();

    uploadFileToS3({
      folderPath: "ProductTemplate/Template/", fileName: `${fileName}_${Date.now()}.${fileEnding}`, file, type: file.type,
    }, s3EsvPriv).then((fileBlob) => {
      if (fileBlob) {
        return resolve({ blob: fileBlob, type });
      } return resolve({ blob: "", type });
    });
  });

  /**
   * Uploads files to AWS in parallel
   * @param {Array} files
   * @returns {Promise} resolves array of { blob, type } (each corresponding to a file)
   */
  const addFilesToAWS = (files) => Promise.all(files.map((file) => addAssetToAWS(file)));

  /**
   * Deletes files from AWS in parallel
   * @param {Array} filePaths
   * @returns {Promise} resolves boolean array (whether file was successfully deleted or not)
   */
  const deleteFilesFromAWS = (filePaths) => Promise.all(filePaths.map((path) => new Promise(() => deleteFileFromS3(path, s3EsvPriv))));

  const handleBackButton = () => {
    handleNavigation(isCustomizingCoa ? "coacreate" : "builder");
  };

  /**
   * Calls the /addtemplate action (api Call)
   * @param {payload} payload
   */
  const addTemplateAPI = async (payload) => {
    const response = await addTemplate(payload);
    if (response.success) {
      if (response.message === "name should be unique") {
        setSaveStatus(null);
        return toast.error(response.message);
      }
      setSaveStatus("success");
    } else {
      setSaveStatus(null);
      toast.error(response.message);
    }
    return null;
  };

  /**
   * PATCH /producttemplates
   * @param {Object} payload
   * @param {Array} filesToDelete array of file paths to delete if api call successful
   */
  const editTemplateAPI = async (payload, filesToDelete) => {
    const { template_id } = selectedTemplate;
    const response = await editTemplate(template_id, payload);
    if (response.success) {
      if (response.message === "name should be unique") {
        setSaveStatus(null);
        return toast.error(response.message);
      }
      setSaveStatus("success");
      deleteFilesFromAWS(filesToDelete);
    } else {
      setSaveStatus(null);
      toast.error(response.message);
    }
    return null;
  };

  /**
   * POST /productcoas
   * @param {String} pdfPath
   */
  const addCustomCoaAPI = async (pdfPath, name) => {
    const params = {
      sample_list: selectedReports.samples.map(({ sample_id, sample_type, received_date }) => ({ sample_id, sample_type, received_date })),
      name,
      template_id: selectedTemplate.template_id,
      pdf_path: pdfPath,
      action: "Add",
      method: "POST",
    };
    const response = await manageCoa(params);
    if (response.success) {
      setSections([]);
      setSaveStatus("success");
    } else {
      setSaveStatus(null);
      toast.error(response.message);
    }
    return null;
  };

  /**
   * Gets inputs values from the template builder based on refs.
   * There are two types of sections, fixed and dynamic (user created).
   * For the fixed section there are two types of inputs (input, textarea).
   * For the dynamic section there is only one type of input (textarea).
   * @param {template} template (template title and color from the save modal)
   * @return {payload} payload (Formatted data for the api and pdf)
   */
  const getInputsData = (template) => {
    let payload = {};
    const sectionsArray = [];
    const sectionsList = []; // API Array
    if (isCustomizingCoa) {
      const {
        company_name, company_address, phone_number, fax_number, signed_by, designation, statement_inputs,
      } = selectedTemplate;
      payload = {
        company_name, company_address, phone_number, fax_number, signed_by, designation, statement_inputs,
      };
    } else {
      sectionsFixed.forEach(({ title, inputs }) => {
        if (title.includes("Statement")) {
          const statement_inputs = [];
          inputs.forEach(({
            ref, isSignature, signed_by, designation, type,
          }) => {
            if (isSignature) {
              const signedByVal = signed_by.ref.value.trim();
              const designationVal = designation.ref.value.trim();
              payload.signed_by = signedByVal;
              payload.designation = designationVal;
              statement_inputs.push({ title: "signature", value: "", size: "" });
            } else if (!isSignature && ref.value.trim()) {
              statement_inputs.push({ title: "", value: ref.value, size: type === "smallTextBox" ? "small" : "large" });
            }
          });
          payload.statement_inputs = statement_inputs;
        } else { // My Details section
          // const {
          //   company_name, company_address, phone_number, fax_number, logo_image_url
          // } = inputs;
          Array.from(Object.entries(inputs)).forEach(([id, { ref }]) => {
            if (ref) {
              payload[id] = ref.value.trim();
            }
          });
        }
      });
    }

    sections.forEach((section) => {
      const inputsObject = {};
      const inputLeft = [];
      const inputRight = [];
      const key = section.title;
      const obj = {};
      const objList = { fields: [] }; // API Obj
      ["left", "right"].forEach((sectionPart) => {
        const sectionPartInputs = section.inputs[sectionPart];
        sectionPartInputs.forEach(({
          id, flag, fieldName, jsonField, ref,
        }) => {
          if (flag !== "0" || ref.value.trim()) {
            const sectionPartArr = sectionPart === "left" ? inputLeft : inputRight;
            let refVal = ref?.value.trim() ?? "";
            if (isCustomizingCoa) {
              refVal = refVal || "-";
            }
            sectionPartArr.push({
              type: id, fieldName: flag !== "0" ? fieldName : "", value: refVal, flag,
            });
            objList.fields.push({
              value: jsonField || (flag === "2" ? fieldName : ref.value),
              size: id.split("_")[0] === "largeTextBox" ? "large" : "small",
              position: sectionPart === "left" ? [1, 0] : [0, 1], // [1,0] = left, [0,1] = right
              flag, // 0 = Text box, 1 = Data field, 2 = Custom data field
            });
          }
        });
      });
      if (inputLeft.length > 0 || inputRight.length > 0) {
        inputsObject.left = inputLeft;
        inputsObject.right = inputRight;
        obj.title = key;
        obj.inputs = inputsObject;
        sectionsArray.push(obj);
      }
      objList.section_name = key;
      sectionsList.push(objList);
    });

    if (isCustomizingCoa) {
      payload.samples = selectedReports.samples;
    }

    payload.sections = sectionsArray;
    payload.sections_list = sectionsList;
    payload.logo_preview_file = logo?.file || "";
    payload.signature_preview_file = signature?.file || "";
    // payload.alignment = alignLeft ? "Left" : "Center";
    payload.alignment = "Left";
    payload = {
      ...payload,
      ...template,
    };
    return payload;
  };

  /**
   * Generate the template pdf and upload the pdf, logo, and signature to AWS
   * @param {React.FC} pdfDocument react-pdf/renderer <Document /> instance
   * @param {Object} payload inputsPayload
   * @returns {Object} { success, filesToDelete }
   */
  const handleGeneratePDFAndUploadFiles = async (pdfDocument, payload) => {
    let pdfBlob;
    try {
      pdfBlob = await pdf(pdfDocument).toBlob();
    } catch (e) {
      toast.error("Failed to generate the pdf");
      setSaveStatus(null);
      return { success: false };
    }
    const pdfFile = new File([pdfBlob], `${payload.name}.pdf`, { type: pdfBlob.type });
    const filesToUpload = [{ file: pdfFile, type: "preview_pdf" }];
    const filesToDelete = [];
    if (!isCustomizingCoa) {
      const images = [{ fileObj: logo, type: "logo_image" },
        { fileObj: signature, type: "signature_image" }];

      images.forEach(({ fileObj, type }) => {
        const { file, wasEdited } = fileObj;
        if (!isEditingTemplate && file) { // adding template
          filesToUpload.push({ file, type });
        } else if (isEditingTemplate) { // editing template
          if (wasEdited) { // image was replaced or removed
            filesToDelete.push(selectedTemplate[`${type}_url`]);
            if (file) { // replaced, upload new image
              filesToUpload.push({ file, type });
            } else { // removed, set image path to empty
              payload[`${type}_url`] = "";
            }
          } else { // image wasn't edited, use existing image path
            payload[`${type}_url`] = selectedTemplate[`${type}_url`];
          }
        }
      });
    }
    const paths = await addFilesToAWS(filesToUpload);
    let success = true;
    paths.forEach(({ blob, type }) => {
      if (blob) {
        payload[`${type}_url`] = blob.path;
      } else {
        success = false;
      }
    });
    if (!success) {
      setSaveStatus(null);
      toast.error("PDF file failed to save to cloud");
    }
    return { success, filesToDelete };
  };

  /**
   * Handle save
   * 1. update the payload with most updated input values
   * 2. generate pdf
   * 3. upload pdf and images to aws
   * 4. if the above is successful, make appropriate api call
   * @param {Object} templateInfo { name, tags }
   */
  const handleSaveTemplate = async (templateInfo = {}) => {
    const payload = getInputsData(templateInfo);
    const { success, filesToDelete } = await handleGeneratePDFAndUploadFiles(PDF({
      inputs: payload, alignLeft, isTemplatePdf: true, isCustomizingCoa, uniqueTests, enableTests,
    }), payload);
    if (success) {
      if (isCustomizingCoa) {
        addCustomCoaAPI(payload.preview_pdf_url, payload.name);
      } else if (isEditingTemplate) {
        editTemplateAPI(payload, filesToDelete);
      } else {
        addTemplateAPI(payload);
      }
    }
  };

  /**
   * Update the inputs payload with most updated input values
   */
  const handlePreviewTemplate = () => {
    const payload = getInputsData();
    setInputsPayload(payload);
    setPreviewTemplate(!previewTemplate);
  };

  const handleBuilderFile = (file) => {
    if (file.title === "Add Company Logo") {
      setLogo(file);
    } else {
      setSignature(file);
    }
  };

  const handleAlign = () => { // eslint-disable-line
    setAlignLeft(!alignLeft);
  };

  return (
    <div className="TemplateBuilder__Parent">
      <BuilderHeader
        handlePreviewTemplate={handlePreviewTemplate}
        previewTemplate={previewTemplate}
        toggleSaveModal={toggleSaveModal}
        checkTemplateValidation={checkTemplateValidation}
        handleBackButton={handleBackButton}
        showSaveModal={showSaveModal}
        isCustomizingCoa={isCustomizingCoa}
      />
      <div className="TemplateBuilderContainer">
        {!isCustomizingCoa ? (
          <BuilderContent
            previewTemplate={previewTemplate}
            handleCreateSection={handleCreateSection}
            handleSectionSelect={handleSectionSelect}
            handleSectionDelete={handleSectionDelete}
            sections={sections}
            sectionsFixed={sectionsFixed}
          />
        ) : (
          <BuilderTests
            previewTemplate={previewTemplate}
            enableTests={enableTests}
            handleTestToggle={handleTestToggle}
            uniqueTests={uniqueTests}
            setUniqueTests={setUniqueTests}
          />
        )}
        <BuilderDragDropWrapper
          previewTemplate={previewTemplate}
          handlePreviewTemplate={handlePreviewTemplate}
          allCustomizedDataFields={allCustomizedDataFields}
          handleSectionSelect={handleSectionSelect}
          handleCreateSection={handleCreateSection}
          handleSectionDelete={handleSectionDelete}
          sections={sections}
          sectionsFixed={sectionsFixed}
          sectionRefs={sectionRefs}
          setSectionInputs={setSectionInputs}
          handleBuilderFile={handleBuilderFile}
          handleTemplateDataFieldsChange={handleTemplateDataFieldsChange}
          commonDataFieldsInUse={commonDataFieldsInUse}
          customizedDataFieldsInUse={customizedDataFieldsInUse}
          setAllCustomizedDataFields={setAllCustomizedDataFields}
          inputsPayload={inputsPayload}
          isCustomizingCoa={isCustomizingCoa}
          selectedTemplate={selectedTemplate}
          selectedReports={selectedReports}
          uniqueTests={uniqueTests}
          enableTests={enableTests}
        />
      </div>
      {showSaveModal && (
      <BuilderConfirmSave
        handleSaveTemplate={handleSaveTemplate}
        toggleSaveModal={toggleSaveModal}
        isCustomizingCoa={isCustomizingCoa}
        selectedReports={selectedReports}
        selectedTemplate={selectedTemplate}
        handleNavigation={handleNavigation}
        saveStatus={saveStatus}
        setSaveStatus={setSaveStatus}
      />
      )}
    </div>
  );
}

import React from "react";
import COABuilderAddSection from "./COABuilderAddSection";
import "./COABuilderCenterContent.css";
import COABuilderHeader from "./COABuilderHeader";
import COABuilderSection from "./COABuilderSection";
import COABuilderSignature from "./COABuilderSignature";
import COABuilderTestReportSection from "./COABuilderTestReportSection";
import COABuilderSampleReportSection from "./COABuilderSampleReportSection";

export default function COABuilderCenterContent({
  sections,
  setSectionInputs,
  handleBuilderFile,
  handleTemplateDataFieldsChange,
  selectedTemplate,
  selectedReports,
  handleCreateSection,
  handleSectionDelete,
  uniqueTests,
  enableTests,
}) {
  return (
    <div className="COABuilderCenter">
      <div className="COABuilderPage">
        <COABuilderHeader selectedTemplate={selectedTemplate} handleBuilderFile={handleBuilderFile} />
        <span className="COABuilder__COATitle">Certificate of Analysis</span>
        <div className="COABuilder__BodyAndSignature">
          <div className="COABuilder__Body">
            {sections.map((section, sectionIndex) => (
              <COABuilderSection
                key={sectionIndex}
                section={section}
                sectionIndex={sectionIndex}
                setSectionInputs={setSectionInputs}
                handleTemplateDataFieldsChange={handleTemplateDataFieldsChange}
                selectedReports={selectedReports}
                handleSectionDelete={handleSectionDelete}
              />
            ))}
            <COABuilderAddSection handleAddSection={handleCreateSection} />
            {enableTests && <COABuilderSampleReportSection samples={selectedReports.samples} uniqueTests={uniqueTests} />}
            {!enableTests && selectedReports.samples.map((sample, i) => (
              <COABuilderTestReportSection key={i} sample={sample} />
            ))}
          </div>
          <COABuilderSignature selectedTemplate={selectedTemplate} handleBuilderFile={handleBuilderFile} />
        </div>
      </div>
    </div>
  );
}

import React from "react";

import moment from "moment";
import Highlighter from "react-highlight-words";

import ActionsMenu from "./ActionsMenu";

import { convertUtcToLocal } from "../../../Common/utils/dateUtils";

import { ReactComponent as EnvironmentIcon } from "../../../../assets/images/feature-icons/EnvironmentIconFilled.svg";
import { ReactComponent as ProductIcon } from "../../../../assets/images/feature-icons/ProductIconFilled.svg";

import "./SubmittedSubmissionRow.css";

export default function SubmittedSubmissionRow(props) {
  const {
    submission,
    handleClickSubmission,
    handleDeleteSubmission,
    handleAddSamples,
    hideActionsWhenDisabled,
    hideActions,
    search,
    searchField,
    showType,
  } = props;
  const statusCodeToName = {
    "-1": "Submitted",
    0: "Samples Received",
    1: "Testing in Progress",
    2: "Testing Complete",
  };
  const statusToColorClassName = {
    "-1": "submitted-submissions-status-text-submitted",
    0: "submitted-submissions-status-text-samples-received",
    1: "submitted-submissions-status-text-progress",
    2: "submitted-submissions-status-text-complete",
  };
  const productSpecToColorClassName = {
    Added: "submitted-submissions-product-spec-color-added",
    Pending: "submitted-submissions-product-spec-color-incomplete",
  };
  const productSpecCodeToName = {
    Added: "Spec Added",
    Pending: "Spec Incomplete",
  };
  const showActionMenu = !hideActionsWhenDisabled && !hideActions;
  const eventTypeStyle = { textAlign: "center" };
  return (
    <tr className="submitted-submission-row">
      <td
        onClick={
          handleClickSubmission
            ? () => handleClickSubmission(submission)
            : () => { }
        }
        className="submitted-submissions-status-td"
      >
        {statusCodeToName[submission.status] && (
          <div>
            {submission.status && (
              (submission.sample_category === "product" ? <ProductIcon className={`${statusToColorClassName[submission.status]} submitted-submissions-status-icons`} /> : <EnvironmentIcon className={`${statusToColorClassName[submission.status]} submitted-submissions-status-icons`} />)
              // <img src={statusToIcon[submission.status]} alt="" />
            )}
            <span className={`${statusToColorClassName[submission.status]}`}>
              {statusCodeToName[submission.status]}
            </span>
          </div>
        )}
      </td>
      <td
        onClick={
          handleClickSubmission
            ? () => handleClickSubmission(submission)
            : () => { }
        }
      >
        <div title={submission.submit_name} className="submitted-submission-name">
          {searchField === "sample_id" ? (submission.submit_name ?? "")
            : (
              <Highlighter
                highlightClassName="PinDetailBlock__SearchHighlight"
                searchWords={[search]}
                autoEscape
                textToHighlight={submission.submit_name ?? ""}
              />
            )}
        </div>
      </td>
      <td
        onClick={
          handleClickSubmission
            ? () => handleClickSubmission(submission)
            : () => { }
        }
        className="submitted-submissions-submitted-on-td"
      >
        <div>
          {submission.submit_date
            && moment(new Date(convertUtcToLocal(submission.submit_date))).format(
              "DD MMM YYYY | hh:mm a",
            )}
        </div>
      </td>
      <td
        onClick={
          handleClickSubmission
            ? () => handleClickSubmission(submission)
            : () => { }
        }
        className="submitted-submissions-submitted-by-td"
      >
        {submission.submitted_by || "-"}
      </td>
      <td
        onClick={
          handleClickSubmission
            ? () => handleClickSubmission(submission)
            : () => { }
        }
        className="submitted-submissions-table-samples-number-td"
      >
        {submission.num_samples}
      </td>
      <td
        onClick={
          handleClickSubmission
            ? () => handleClickSubmission(submission)
            : () => { }
        }
        className="submitted-submissions-product-spec-td"
      >
        <div>
          <span
            className={`submitted-submissions-product-spec-color ${productSpecToColorClassName[submission.spec_status]}`}
          />
          <span className="submitted-submissions-product-spec-text">
            {productSpecCodeToName[submission.spec_status]}
          </span>
        </div>
      </td>
      {showActionMenu && (
        <td className="submitted-submissions-table-actions-td">
          <ActionsMenu
            disabled={hideActionsWhenDisabled}
            handleDelete={() => handleDeleteSubmission(submission)}
            handleAdd={() => handleAddSamples(submission)}
            disabledTooltipText="Cannot delete or add to submission at this stage"
          />
        </td>
      )}
      {showType && (<td style={eventTypeStyle}>Event</td>)}
    </tr>
  );
}

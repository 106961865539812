import React, { useState } from "react";

import { motion } from "framer-motion/dist/framer-motion";
import moment from "moment";
import { toast } from "react-toastify";

import StyledButton from "../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../Common/UIComponents/StyledTooltip";
import EVENT_SPEC_STYLE_MAP from "../constants";

import { marksampled } from "../../../actions/envCalendar";

export const isMarkingAllowed = (event_date) => moment(event_date).isBetween(
  moment().clone().subtract(5, "days").startOf("day"),
  moment().endOf("day"),
  "day",
  "[]",
); // Can be marked if submission date is within past 5 days

export default function LocationDetails({
  sampleId,
  specsFlag,
  submissionId,
  sampleType,
  sampled,
  afterMarkingSample,
  draggableId,
  setCardLoading,
  event_date,
  submissionStatus,
}) {
  const [confirmMarkAsScheduled, setConfirmMarkAsScheduled] = useState(false);
  const [confirmUnmarkAsScheduled, setConfirmUnmarkAsScheduled] = useState(false);

  /**
   * Handles marking samples
   * @param {String} action
   */
  const markSample = async (action) => {
    setCardLoading({ isLoading: true, draggableId });
    const response = await marksampled(submissionId, sampleId, sampleType, action);
    if (response.success) {
      toast.success(`Sample ${action.toLowerCase()}ed`);
    } else {
      toast.success(response.message);
    }
    setConfirmMarkAsScheduled(false);
    setConfirmUnmarkAsScheduled(false);
    await afterMarkingSample(); // Refetch data
    setCardLoading({ isLoading: false, draggableId: "" });
  };

  /**
   * Show confirmation when status has to update
   * @param {Boolean} isMarkAsScheduled
   */
  const statusUpdateConfirm = (isMarkAsScheduled = true) => {
    const text = isMarkAsScheduled ? "Do you want to mark this location as sampled?" : "Unmark sample?";
    const confirmAction = isMarkAsScheduled ? () => markSample("Mark") : () => markSample("Unmark");
    const cancelAction = isMarkAsScheduled ? () => setConfirmMarkAsScheduled(false) : () => setConfirmUnmarkAsScheduled(false);
    return (
      <motion.li
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="Event_LocationDetails Event_LocationConfirmUpdateStatus"
        style={{ background: isMarkAsScheduled ? "#FFF8EB" : "#FFFFFF" }}
      >
        <p>{text}</p>
        <div className="Event_LocationConfirmUpdateStatus__Buttons">
          <StyledButton
            htmlType="button"
            type="primary"
            className="Event_LocationConfirmUpdateStatus__Button"
            onClick={confirmAction}
          >
            Yes
          </StyledButton>
          <StyledButton
            htmlType="button"
            type="primary"
            ghost
            className="Event_LocationConfirmUpdateStatus__Button Event_LocationConfirmUpdateStatus__Button--ghost"
            onClick={cancelAction}
          >
            No
          </StyledButton>
        </div>
      </motion.li>
    );
  };

  /**
   * Renders button to mark/unmark
   * @param {Boolean} isMarkAsScheduled
   */
  const updateSampledStatusButton = (isMarkAsScheduled = true) => (
    <button
      type="button"
      className={isMarkAsScheduled ? "EventCard__MarkAsSampledButton" : "EventCard__UnMarkAsSampledButton"}
      onClick={isMarkAsScheduled ? () => setConfirmMarkAsScheduled(true) : () => setConfirmUnmarkAsScheduled(true)}
    >
      {isMarkAsScheduled ? "Mark as sampled" : "Unmark Sample"}
    </button>
  );

  if (confirmMarkAsScheduled) return statusUpdateConfirm();

  if (confirmUnmarkAsScheduled) return statusUpdateConfirm(false);

  let specMapKey = specsFlag;
  if (parseInt(submissionStatus) >= 0) { // if Testing in Process or Testing Complete
    specMapKey = specsFlag;
  } else if (submissionStatus === "-2" && sampled) { // If submission is draft and it is sampled, then always used the "-1" (orange) color
    specMapKey = -1;
  } else if (submissionStatus === "-1" && !sampled) { // If submitted, the not sampled, still show "-2"
    specMapKey = -2;
  }

  const color = EVENT_SPEC_STYLE_MAP[specMapKey];
  const styles = {
    background: color.sample_bg_color,
    borderColor: color.sample_border_color,
    color: color.text_color,
  };

  const isUnmarked = !sampled;
  const isMarked = sampled && submissionStatus === "-2"; // Only allow mark sample in draft status and it's sampled
  return (
    <StyledTooltip
      title={specsFlag === "-3" ? "Deleted by lab" : ""}
      placement="topLeft"
    >

      <li className="Event_LocationDetails" style={styles}>
        <span className="truncated-text">
          {sampleId}
        </span>
        {(isMarkingAllowed(event_date) && isUnmarked && parseInt(submissionStatus) < 0) && updateSampledStatusButton(true)}
        {(isMarkingAllowed(event_date) && isMarked && parseInt(submissionStatus) < 0) && updateSampledStatusButton(false)}
      </li>
    </StyledTooltip>
  );
}

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import TemplateSectionSearch from "../../Common/TemplateSectionSearch";
import CreateFieldModal from "../../CreateFieldModal/CreateFieldModal";
import DataFieldSort from "./DataFieldSort";
import DataFields from "./DataFields";

// import userProfileIcon from "../../../../../../assets/images/product/template/userProfileBlue.png";
// import SearchIcon from "../../../../../../assets/images/product/template/SearchBlueIcon.png";

import {
  sortAndSearchDataFields,
} from "../../../../../../actions/productTemplate";

export default function DataFieldsSection(props) {
  const {
    previewTemplate,
    commonDataFieldsInUse,
    customizedDataFieldsInUse,
    setAllCustomizedDataFields,
    setAllDataFields,
    allDataFields,
  } = props;
  const [showCreateFieldModal, setShowCreateFieldModal] = useState(false);
  const [showSorting, setShowSorting] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [activeSort, setActiveSort] = useState("by Type");
  const [searchValue, setSearchValue] = useState("");
  const sortParam = {
    // "by Most common": "Most common",
    "by Alphabet": "Alphabetical order",
    "by Type": "Type",
  };

  const assignIds = (fieldsArray) => fieldsArray.map((fieldsObj) => ({ ...fieldsObj, id: uuidv4() }));

  const apiSortAndSearchDataFields = async (params) => {
    const response = await sortAndSearchDataFields(params);
    if (response && response.success) {
      const withIds = assignIds(response.result);
      setAllDataFields(withIds);
      setAllCustomizedDataFields(withIds.filter((ele) => ele.flag === "2").map((ele) => ele.value));
    } else {
      toast.error("Failed to sort data fields.");
    }
  };

  useEffect(() => {
    apiSortAndSearchDataFields({ sort_by: "Type", search: "", category: "builder" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleShowCreateFieldModal = () => {
    if (showCreateFieldModal) {
      apiSortAndSearchDataFields({ sort_by: sortParam[activeSort || "by Type"], search: "", category: "builder" });
    } else {
      setShowSearch(false);
      setShowSorting(false);
      setSearchValue("");
    }
    setShowCreateFieldModal(!showCreateFieldModal);
  };

  const toggleShowSorting = () => {
    setShowSorting(!showSorting);
  };

  const toggleShowSearch = () => {
    if (showSearch && searchValue) {
      setSearchValue("");
      apiSortAndSearchDataFields({ sort_by: sortParam[activeSort || "by Type"], search: "", category: "builder" });
    }
    setShowSearch(!showSearch);
  };

  const handleApplySort = (sortValue) => {
    apiSortAndSearchDataFields({ sort_by: sortParam[sortValue || "by Type"], search: searchValue, category: "builder" });
    toggleShowSorting();
    setActiveSort(sortValue);
  };

  const handleSubmitSearch = (value) => {
    setSearchValue(value);
    apiSortAndSearchDataFields({ sort_by: sortParam[activeSort || "by Type"], search: value, category: "builder" });
  };

  return (
    <div
      className={
        previewTemplate
          ? "templateBuilderComponents templateBuilderDisableSection builderComponentDataField"
          : "templateBuilderComponents builderComponentDataField"
      }
    >
      <div className="templateBuilderComponentsHeader">
        <span> Data Fields </span>
        {/* {!showSearch && <img src={SearchIcon} alt="SearchIcon" onClick={toggleShowSearch} className="searchIcon" /> } */}
      </div>
      <div className="templateBuilderComponentsDataFields">
        {/* {showSearch && (
          <TemplateSectionSearch toggleShowSearch={toggleShowSearch} handleSubmitSearch={handleSubmitSearch} />
        )}
        <DataFieldSort
          activeSort={activeSort}
          showSorting={showSorting}
          handleApplySort={handleApplySort}
          toggleShowSorting={toggleShowSorting}
        /> */}

        <div className="TemplateBuilder__SearchAndSort">
          {!showSorting && (
          <TemplateSectionSearch
            toggleShowSearch={toggleShowSearch}
            handleSubmitSearch={handleSubmitSearch}
            searchValuePrent={searchValue}
            disableClose
            isCustomizeCoa
          />
          )}
          <DataFieldSort
            activeSort={activeSort}
            showSorting={showSorting}
            handleApplySort={handleApplySort}
            toggleShowSorting={toggleShowSorting}
            isCustomizeCoa
          />
        </div>
        <DataFields
          showSorting={showSorting}
          activeSort={activeSort}
          dataFields={allDataFields}
          commonDataFieldsInUse={commonDataFieldsInUse}
          customizedDataFieldsInUse={customizedDataFieldsInUse}
        />

        {/* <div className="templateBuilderComponentsCreateButton" onClick={toggleShowCreateFieldModal}>
          <img src={userProfileIcon} alt="" className="templateBuilderProfileIcon" />
          <span> Create New Field </span>
        </div> */}
      </div>
      {showCreateFieldModal && (
        <CreateFieldModal
          dataFieldCategory="builder"
          toggleShowCreateFieldModal={toggleShowCreateFieldModal}
        />
      )}
    </div>
  );
}

import React, { Component } from "react";

import Login from "./Login";
import SupportLogin from "./SupportLogin";

class LoginController extends Component {
    state = {
      currentPage: "",
    }

    async componentDidMount() {
      // Check if we are in navigating to the correct route
      if (window.location.pathname === "/login/support") {
        this.setState({
          currentPage: "support",
        });
      } else {
        this.setState({
          currentPage: "login",
        });
      }
    }

    render() {
      const { currentPage } = this.state;
      const { history } = this.props;

      if (currentPage === "support") {
        return <SupportLogin history={history} />;
      } if (currentPage === "login") {
        return <Login history={history} />;
      }
      return null;
    }
}

export default LoginController;

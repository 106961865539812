import React from "react";
import {
  Form,
} from "antd";
import "./FormButtons.css";
import StyledButton from "../../../../Common/UIComponents/StyledButton";

export default function FormButtons({
  nextButtonText = "Next", handleBack, showBack = true, loading = false,
}) {
  return (
    <Form.Item className="FormButtons">
      {showBack && (
      <StyledButton type="default" onClick={handleBack} disabled={loading}>
        Previous
      </StyledButton>
      )}
      <StyledButton type="primary" htmlType="submit" className="Ant__SubmitBtn" loading={loading}>
        {nextButtonText}
      </StyledButton>
    </Form.Item>
  );
}

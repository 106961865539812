import React from "react";
import {
  View, StyleSheet, Text,
} from "@react-pdf/renderer";
import PDFImageWithText from "./PDFImageWithText";
import { softHyphenate } from "./pdfUtils";

export default function PDFSignature(props) {
  const { footer, alignLeft } = props;

  const styles = StyleSheet.create({
    footer: {
      display: "flex",
      flexDirection: "column",
      alignItems: alignLeft ? "left" : "center",
      gap: "22",
      padding: "22 35 0 35",
      color: "#506375",
      fontFamily: "Roboto-500",
      fontSize: "11",
    },
    gap: {
      marginBottom: 14,
    },
    signImg: {
      width: "132",
      height: "70",
      objectFit: "contain",
      objectPosition: "left center",
    },
    wordWrap: {
      display: "flex",
      flexDirection: "row",
    },
  });

  const {
    signedBy, designation, signature_preview_file, statement_inputs,
  } = footer;

  return (
    <View style={styles.footer}>
      {statement_inputs.map(({ value, title }, i) => {
        if (title === "signature" && (signature_preview_file || signedBy || designation)) {
          return (<PDFImageWithText key={i} imageFile={signature_preview_file} title={signedBy} subtitle={designation} alignLeft={alignLeft} />);
        }
        return (<Text key={i} style={styles.wordWrap}>{softHyphenate(value)}</Text>);
      })}
    </View>
  );
}

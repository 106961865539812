import testGraphPillBlue from "../../assets/images/analytics/graphPillTest_blue.png";
import testGraphPillGreen from "../../assets/images/analytics/graphPillTest_green.png";
import testGraphPillOrange from "../../assets/images/analytics/graphPillTest_orange.png";
import testGraphPillPink from "../../assets/images/analytics/graphPillTest_pink.png";
import testGraphPillPurple from "../../assets/images/analytics/graphPillTest_purple.png";

// Colors used to highlight scatter plots and color for the tooltip text
export const colorsForBar = ["#8A1CFF", "#FF9F3F", "#215BE3", "#E597F2", "#0093A7"];

// Colors and icons array used to color the graphs with their respective colors
export const colorsAndIconsArray = [
  {
    testIcon: testGraphPillPurple,
    borderColor: "#8A1CFF",
    accentColor: "#D0A3FF42",
    hoverColor: "#f6ecfe",
  },
  {
    testIcon: testGraphPillOrange,
    borderColor: "#FF9F3F",
    accentColor: "#FF9F3F33",
    hoverColor: "#FFEED2",
  },
  {
    testIcon: testGraphPillBlue,
    borderColor: "#215BE3",
    accentColor: "#215BE333",
    hoverColor: "#dde4fb",
  },
  {
    testIcon: testGraphPillPink,
    borderColor: "#E597F2",
    accentColor: "#E597F230",
    hoverColor: "#fcf0fe",
  },
  {
    testIcon: testGraphPillGreen,
    borderColor: "#0093A7",
    accentColor: "#5BBAC854",
    hoverColor: "#d5eef1",
  },
];

const parsePinQuadrant = (x, y, mapTitle) => {
  let quadrant;
  if (!Number.isNaN(parseFloat(x)) && !Number.isNaN(parseFloat(y)) && (mapTitle !== undefined) && (mapTitle !== "")) {
    if (x <= 0.5) {
      if (y <= 0.5) {
        quadrant = 0;
      } else {
        quadrant = 3;
      }
    } else if (y <= 0.5) {
      quadrant = 1;
    } else {
      quadrant = 2;
    }
    return { x, y, quadrant };
  }
  return { x: null, y: null };
};

export default parsePinQuadrant;

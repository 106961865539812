import React from "react";
import { Input } from "antd";
import "./LoginInput.css";
import PasswordInput from "./PasswordInput";
import { ReactComponent as CloseIcon } from "../../../assets/images/onboarding/clearField.svg";

export default function LoginInput({
  type, onChange, id, name, value, inputRef, error, autoComplete, label, isPassword, clearError = () => {}, onEnter,
}) {
  const onChangeInternal = (e) => {
    if (error && e.target.value) {
      clearError();
    }
    onChange(e);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter" && onEnter) {
      e.preventDefault();
      e.stopPropagation();
      onEnter();
    }
  };

  return (
    <div className="login-input-row">
      <label htmlFor={id} className="Login__Input__Label">{label}</label>
      {isPassword ? (
        <PasswordInput
          id={id}
          name={name}
          type={type}
          value={value}
          error={error}
          onChange={onChangeInternal}
          inputRef={inputRef}
          autoComplete={autoComplete}
          onKeyDown={onKeyDown}
        />
      )
        : (
          <Input
            id={id}
            name={name}
            type={type}
            className="Login__Input"
            value={value}
            status={error ? "error" : undefined}
            onChange={onChangeInternal}
            ref={(component) => {
              if (inputRef) {
                inputRef.current = component?.input;
              }
            }}
            autoComplete={autoComplete}
            // allowClear={{ clearIcon: <img className="Login__Input__ClearIcon" alt="clear" /> }}
            allowClear={{ clearIcon: <CloseIcon /> }}
            onKeyDown={onKeyDown}
          />
        )}
    </div>
  );
}

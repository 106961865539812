import React, { Component } from "react";
import { Layout, Row, Col } from "antd";
import { Slide } from "react-awesome-reveal";
import { toast } from "react-toastify";
import "./MyAccountHelp.css";

import myAccountHelpPlaceholder from "../../assets/images/myAccountHelpPlaceholder.svg";
import MyAccountSideMenu from "./MyAccountSideMenu";

import {
  sendHelp,
} from "../../actions/myAccount";

class MyAccountHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputDesc: "",
    };
  }

  handleMenuClick = (event) => {
    const { history } = this.props;
    const key = event.key;
    if (key === "1") {
      history.push("/myaccount/profile");
    } else if (key === "2") {
      history.push("/myaccount/members");
    } else if (key === "3") {
      history.push("/myaccount/help");
      // this.props.history.push(`/myaccount/setting`);
    }
    // else if (key === "4") {
    //   this.props.history.push(`/myaccount/faq`);
    // } else if (key === "5") {
    //   this.props.history.push(`/myaccount/help`);
    // }
  }

  handleInputDescChange = (value) => {
    this.setState({ inputDesc: value });
  }

  handleSenderClick = () => {
    const { inputDesc } = this.state;
    if (!inputDesc) {
      toast.error("Please enter some content for help.");
      return;
    }
    this.apiSendHelp(inputDesc);
  }

  apiSendHelp = async (params) => {
    const result = await sendHelp(params);
    if (result && result.success) {
      this.setState({ inputDesc: "" });
      toast.success("Help sent successfully");
    }
  };

  render() {
    const {
      inputDesc,
    } = this.state;

    return (
      <div className="myAccountMainContainer">
        <Layout className="MyAccountHelp__Layout">
          <MyAccountSideMenu
            selectedMenuItem="3"
            handleMenuClick={this.handleMenuClick}
          />
          <div className="myAccountRightMainContainer MyAccountHelp__Layout__Body">
            <div className="myAccountFaqMainContainer">
              <Slide direction="left" triggerOnce>
                <div className="myAccountContainerTitle MyAccountHelp__Layout__Body__Title">
                  Help
                </div>
              </Slide>

              <Slide direction="up" triggerOnce>
                <div>
                  <Row gutter={[40, 40]} className="MyAccountHelp__Layout__Body__Row">
                    <Col xxl={10} xl={12} lg={12} md={24} sm={24} className="MyAccountHelp__Layout__Body__ColContent">
                      <div>
                        <div className="myAccountContainerDesc">
                          Got Stuck? Don’t worry we are here for you!
                        </div>
                        <div className="MyAccountHelp__Layout__Body__ColContent__Second">
                          Tell us the issue you are facing! We will get back to you ASAP!
                        </div>
                        <textarea
                          className="textarea-grayborder"
                          value={inputDesc}
                          onChange={(e) => this.handleInputDescChange(e.target.value)}
                          placeholder="Enter your issue here"
                        />
                        <button
                          type="button"
                          onClick={() => this.handleSenderClick()}
                          className="myAccountEditBtn MyAccountHelp__Layout__Body__ColContent__SendBtn"
                        >
                          Send
                        </button>
                      </div>
                    </Col>
                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={24}
                      sm={24}
                      className="MyAccountHelp__Layout__Body__ColImg"
                    >
                      <div>
                        <img
                          src={myAccountHelpPlaceholder}
                          alt=""
                          className="MyAccountHelp__Layout__Body__ColImg__Image"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Slide>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default MyAccountHelp;

// src/HorizontalScroll.js
import React, { useEffect, useRef, useState } from "react";
import "./HorizontalScroll.css";
import { ReactComponent as ArrowButton } from "../../../assets/images/arrowTagButton2x.svg";

const HorizontalScroll = ({ children, containerClassName = "" }) => {
  const containerRef = useRef(null);
  const [canScroll, setCanScroll] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    const ref = containerRef.current;
    const checkScroll = () => {
      if (ref) {
        const { scrollLeft, scrollWidth, clientWidth } = ref;
        setCanScroll(scrollWidth > clientWidth);
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(Math.ceil(scrollLeft + clientWidth) < scrollWidth);
      }
    };

    checkScroll();

    window.addEventListener("resize", checkScroll);
    ref.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("resize", checkScroll);
      ref.removeEventListener("scroll", checkScroll);
    };
  }, [children]);

  const scroll = (direction) => {
    const { current } = containerRef;
    if (current) {
      const scrollAmount = 200; // Adjust this value as needed
      current.scrollBy({ left: direction * scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className={`scroll-container ${containerClassName}`}>
      {canScrollLeft && canScroll && (
        <button type="button" className="scroll-button rotate-180" onClick={() => scroll(-1)}>
          <ArrowButton />
        </button>
      )}
      <div className="scroll-content" ref={containerRef}>
        {children}
      </div>
      {canScrollRight && canScroll && (
        <button type="button" className="scroll-button" onClick={() => scroll(1)}>
          <ArrowButton />
        </button>
      )}
    </div>
  );
};

export default HorizontalScroll;

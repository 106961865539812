import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import deleteIcon from "../../../../../../assets/images/environment/binDeleteIcon.svg";

const MapTitleTab = ({
  mapTitle, editMapTitleToggle, editingMapTitle, isSelected, listViewDisplay, handleEditMapTitle, toggleDeleteTitleConfirm,
}) => (
  <>
    {listViewDisplay && editingMapTitle && isSelected ? (
      <OutsideClickHandler onOutsideClick={editMapTitleToggle}>
        <div className="editMapTitleDiv" onKeyDown={(e) => e.stopPropagation()}>
          <input
            autoFocus
            defaultValue={mapTitle}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                if (event.target.value) {
                  handleEditMapTitle(event.target.value);
                }
                editMapTitleToggle();
              }
            }}
          />
          <img className="mapTitleDeleteIcon" alt="delete" src={deleteIcon} onClick={() => toggleDeleteTitleConfirm(mapTitle)} />
        </div>
      </OutsideClickHandler>
    ) : (
      <div
        className="MapViewTabTitle"
        onDoubleClick={!editingMapTitle && listViewDisplay ? (e) => { e.stopPropagation(); editMapTitleToggle(); } : () => {}}
      >
        {mapTitle}
      </div>
    )}
  </>
);
export default MapTitleTab;

import React, { Component } from "react";
import "./NoResults.css";

class NoResults extends Component {
  render() {
    const {
      image, message, messageStyle, className,
    } = this.props;
    return (
      <div className={`no-results ${className ?? ""}`}>
        <div className="no-results__img">
          <img
            src={image}
            alt=""
            className="no-results__img--fit-object"
          />
        </div>
        <div
          className={messageStyle ? "" : "no-results__message"}
          style={messageStyle}
        >
          {message}
        </div>
      </div>
    );
  }
}

export default NoResults;

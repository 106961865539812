import moment from "moment";

export const MONTHS = [
  { value: 0, label: "January" },
  { value: 1, label: "February" },
  { value: 2, label: "March" },
  { value: 3, label: "April" },
  { value: 4, label: "May" },
  { value: 5, label: "June" },
  { value: 6, label: "July" },
  { value: 7, label: "August" },
  { value: 8, label: "September" },
  { value: 9, label: "October" },
  { value: 10, label: "November" },
  { value: 11, label: "December" },
];

/**
 * Get full year from date
 * @param {Date} value date object (prints date in this format: Fri Sep 08 2023 11:44:06 GMT-0700 (Pacific Daylight Time))
 * @returns {Number} 4-digit year
 */
export const getYear = (value) => {
  const date = moment(value).format("DD/MM/YYYY").split("/");
  return parseInt(date[2], 10);
};

/**
 * Get 0-indexed month number from date
 * @param {Date} value date object (prints date in this format: Fri Sep 08 2023 11:44:06 GMT-0700 (Pacific Daylight Time))
 * @returns {Number} 0-indexed month number (i.e. 0 = January, 1 = February, etc.)
 */
export const getMonth = (value) => {
  const date = moment(value).format("DD/MM/YYYY").split("/");
  return parseInt(date[1], 10) - 1; // get zero-indexed month number
};

/**
 * Gets Date object or array of Date objects from dateSelected string
 * @param {String} dateSelected date in "Sep 3, 2023" format or range of dates in "Sep 3, 2023 - Sep 29, 2023" format
 * @param {String} rangeSelectionComplete if start and end date both are selected (important for single day selection)
 * @returns {Array<Date>} array with one or two Date obj represented by dateSelected string
 */
export const getSelectedDates = (dateSelected, rangeSelectionComplete = false) => {
  let date = [];
  if (dateSelected !== "") {
    // two dates exist
    if (dateSelected.includes("-")) { // start and end date are different
      const dates = dateSelected.split("-");

      const dateOne = new Date(dates[0].trim());
      const dateTwo = new Date(dates[1].trim());

      date.push(dateOne);
      date.push(dateTwo);
    } else if (rangeSelectionComplete) { // single day selection
      date = [new Date(dateSelected), new Date(dateSelected)]; // return array with start and end date as the same day to end the single day selection
    } else if (!rangeSelectionComplete) {
      date = [new Date(dateSelected)]; // this date is the start of the range (range selection has not been completed)
    }
  } else {
    date = undefined;
  }

  return date;
};

/**
 * Compute a date range string from today to x days ago
 * @param {*} dateFrom a Date() object
 * @param {*} dateTo a Date() object
 * @returns a string 'Sep 1, 2023 - Sep 7, 2023'
 */
export const getFormattedDaysAgoString = (dateFrom, dateTo) => {
  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDateRange = `${dateFrom.toLocaleDateString("en-US", options)} - ${dateTo.toLocaleDateString("en-US", options)}`;

  return formattedDateRange;
};

import React, {
  useEffect, useState, forwardRef, useRef,
  useCallback,
} from "react";

import { AutoComplete, Input } from "antd";

import StyledTooltip from "../../../Common/UIComponents/StyledTooltip";
import MaxCharacterCount from "./MaxCharacterCount";

import { ReactComponent as ExclamationIcon } from "../../../../assets/images/sample-submission/exclamationIcon.svg";

import "./ErrorCheckingInput.css";

const ErrorCheckingInput = forwardRef(({
  label,
  labelDivClassName,
  inputID,
  value,
  invalidChars, // special chars are escaped
  invalidKeys, // special chars are not escaped
  onChange,
  onKeyDown,
  onErrorChange,
  onClickAway,
  onFocus,
  charLimit,
  letInputExceedCharLimit,
  customClassName = "",
  placeholderText,
  hasError = false,
  showLabel = false,
  remainingLimit = 0,
}, ref) => {
  const [inputErrorSet, setInputErrorSet] = useState(new Set());
  const [internalValue, setInternalValue] = useState("");
  const [touched, setTouched] = useState({ touched: false, dirty: false });
  const inputRef = useRef();
  const isMounted = useRef(false);

  /**
   * During the time of edit, if value is already there,
   * setting touched and dirty to true for showing input error state
   */
  useEffect(() => {
    if (!isMounted.current && value) {
      if (value.length > 0) {
        setTouched({ touched: true, dirty: true });
      }
      isMounted.current = true;
    }
  }, [value]);

  /** Update value when it changes from the parent */
  useEffect(() => {
    if (value !== undefined && value !== internalValue) {
      setInternalValue(value);
      inputRef.current.value = value;
    }
    if (value === undefined) {
      setInternalValue("");
    }
  }, [value]); // eslint-disable-line

  /** Check if value contains any invalid characters */
  useEffect(() => {
    if (ref !== undefined && ref !== null && invalidChars?.length > 0) {
      const regex = new RegExp(invalidChars.join("|"), "g");
      const invalidMatches = internalValue?.match(regex);
      if (invalidMatches) {
        setInputErrorSet(new Set(invalidMatches));
      } else {
        setInputErrorSet(new Set());
      }
    }
  }, [internalValue]); // eslint-disable-line

  /** Call onErrorChange if errors change */
  useEffect(() => {
    if (onErrorChange) {
      onErrorChange(inputErrorSet);
    }
  }, [inputErrorSet.size]); // eslint-disable-line

  /** Update internal value and call onChange */
  const onChangeInternal = (val) => {
    setInternalValue(val);
    if (val.length > 0) {
      setTouched({ touched: true, dirty: true });
    }
    if (onChange) {
      onChange(val);
    }
  };

  /** Handle focus */
  const onFocusInternal = () => {
    setTouched((state) => ({ ...state, touched: true }));
    if (onFocus) {
      onFocus();
    }
  };

  /** Handle blur and trim input */
  const onBlurInternal = () => {
    if (onClickAway) {
      setInternalValue(internalValue.trim());
      onClickAway(internalValue.trim());
    }
  };

  /** Prevent entry of invalid keys */
  const onKeyDownInternal = (e) => {
    if (invalidKeys && invalidKeys.includes(e.key)) {
      e.preventDefault();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const getClassName = useCallback((val) => {
    const showExternalError = touched.touched && touched.dirty && val.length === 0 && hasError;
    return `${customClassName} ${inputErrorSet.size || showExternalError ? "sample-submission-input-error" : ""}`;
  }, [customClassName, inputErrorSet, touched, hasError]);

  return (
    <>
      <div className={labelDivClassName}>
        {showLabel && (
          <label htmlFor={inputID}>
            {label}
          </label>
        )}
        {inputErrorSet.size !== 0 && (
          <StyledTooltip
            title={(
              <div className="SampleSubmission__InvalidCharsTooltipTitle">
                <span>Invalid input:</span>
                {Array.from(inputErrorSet).map((char, i) => (
                  <li key={i} className="SampleSubmission__InvalidChar">
                    {char === " " ? (
                      <span className="SampleSubmission__InvalidChar__SpaceHighlight">{"\u23B5"}</span>
                    ) : (
                      <span>{char}</span>
                    )}
                  </li>
                ))}
              </div>
            )}
            placement="right"
          >
            <ExclamationIcon width={13} height={13} />
          </StyledTooltip>
        )}
      </div>
      <div className="SampleDetailsInput">
        <AutoComplete
          allowClear
          value={internalValue ?? ""}
          placeholder={placeholderText}
          onFocus={onFocusInternal}
          onBlur={onBlurInternal}
          maxLength={charLimit && !letInputExceedCharLimit ? charLimit : undefined}
          onKeyDown={onKeyDownInternal}
          onClear={() => onChangeInternal("")}
        >
          <Input
            ref={(component) => {
              ref(component?.input);
              inputRef.current = component;
            }}
            className={getClassName(internalValue ?? "")}
            onChange={(e) => onChangeInternal(e.target.value)}
          />
        </AutoComplete>
        {charLimit && (
          <MaxCharacterCount
            customMaxCharClassName="SampleDetailsInput__MaxChar"
            charCount={remainingLimit}
            charLimit={charLimit}
          />
        )}
      </div>
    </>
  );
});

export default ErrorCheckingInput;

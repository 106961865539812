import React, { useEffect } from "react";
import {
  PINS_SPEC_STYLE_MAP,
} from "../../Constant";

import { ReactComponent as StatusDotIcon } from "../../../../../../assets/images/environment/statusPingDot.svg";

const ReportViewPin = (props) => {
  const {
    pin,
    pinElementRef,
    zoomToElement,
  } = props;

  /** Zoom to pin */
  const handleZoomToPin = () => {
    if (pinElementRef.current !== null) {
      zoomToElement(pinElementRef.current, 2);
    }
  };

  /** When pin changes, re-zoom to pin */
  useEffect(() => {
    handleZoomToPin();
  }, [pin]); // eslint-disable-line

  /** When window size changes, re-zoom to pin */
  useEffect(() => {
    window.addEventListener("resize", handleZoomToPin);

    return () => {
      window.removeEventListener("resize", handleZoomToPin);
    };
  });

  const { color } = PINS_SPEC_STYLE_MAP[pin.specs_flag] ?? PINS_SPEC_STYLE_MAP.invalid;

  return (
    <div
      className="report-pin-location-container"
      ref={pinElementRef}
      style={{ left: `calc(${(pin.x_coordinate * 100)}% - 18px)`, top: `calc(${(pin.y_coordinate * 100)}% - 18px)` }}
    >
      <div
        className="report-pin-container"
      >
        <StatusDotIcon width={36} height={36} fill={color} />
      </div>
    </div>
  );
};

export default ReportViewPin;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Row } from "antd";
import "./LandingPage.css";
import StyledButton from "../../../Common/UIComponents/StyledButton";

export default function LandingPage({
  heading, description, image, setDisplayForm,
}) {
  const [userTypeAdmin, setUserTypeAdmin] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const userDetail = sessionStorage.getItem("user_details");
    const userType = JSON.parse(userDetail).user_title === "Admin";
    setUserTypeAdmin(userType);
  }, []);

  return (
    <Row justify="center" className="LandingPage__Div" span={24}>
      <Flex className="LandingPage">
        <div className="LandingPage__Container">
          <p className="LandingPage__Text">Begin your</p>
          <p className="LandingPage__Heading">
            {heading}
          </p>
          <p className="LandingPage__Description">
            {description}
          </p>
          {userTypeAdmin ? (
            <StyledButton type="primary" className="LandingPage__Btn" onClick={() => setDisplayForm(true)}>
              Get started
            </StyledButton>
          ) : (
            <>
              <p className="LandingPage__RequestText">Request your administer</p>
              <StyledButton type="primary" className="LandingPage__Btn" onClick={() => history.push("/sample-submission")}>
                Back
              </StyledButton>
            </>
          )}
        </div>
        <div className="LandingPage__Image">
          <img src={image} alt="" />
        </div>
      </Flex>
    </Row>
  );
}

import React from "react";
import { Form } from "antd";
import { useForm } from "antd/es/form/Form";
// import AddressSection from "./Components/AddressSection";
import BillingAddressSection from "./Components/BillingAddressSection";
import MyForm from "../../../MyForm";
import ContactInfoSection from "./Components/ContactInfoSection";
import FormButtons from "../ReusableComponents/FormButtons";
import useHandleStepChange from "../../useHandleStepChange";

export default function CompanyContactForm({
  initialValues,
  companyName,
  emailValidator,
  phoneValidator,
  onNextButton,
  onBackButton,
  onFinishFailed,
  stepChangeInProgress,
  handleFormStepChange,
  addressData,
}) {
  const FORM_NAME = "company_contact_form";
  const [form] = useForm();

  /** Hook that handles form saving + step change when step is clicked */
  useHandleStepChange(FORM_NAME, form, stepChangeInProgress, handleFormStepChange);

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 24,
    },
    layout: "vertical",
  };

  return (
    <MyForm
      {...layout}// eslint-disable-line react/jsx-props-no-spreading
      form={form}
      initialValues={{ ...initialValues, company_name: companyName }}
      requiredMark={false}
      name={FORM_NAME}
      validateTrigger={["onBlur", "onChange"]}
      onFinish={(values) => onNextButton(FORM_NAME, values)}
      onFinishFailed={onFinishFailed}
      className="OnboardingForm"
    >
      <div>
        <ContactInfoSection
          emailValidator={emailValidator}
          phoneValidator={phoneValidator}
        />
        <BillingAddressSection
          initialValues={initialValues}
          emailValidator={emailValidator}
          phoneValidator={phoneValidator}
          addressData={addressData.address}
        />
      </div>
      <Form.Item>
        <FormButtons
          handleBack={() => onBackButton(FORM_NAME, form.getFieldValue())}
        />
      </Form.Item>
    </MyForm>
  );
}

import React, { useState, useRef, useEffect } from "react";

import CountriesCheckboxList from "../../../../Common/UIComponents/CountriesCheckboxList";

import { ReactComponent as ChevronDown } from "../../../../../assets/images/common/ChevronDown.svg";

// import SpecManagerSelect from "./SpecManagerSelect";
import "./MRLCountryList.css";

function MRLCountryList({
  countriesData, editMode = false, onChange,
}) {
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef();

  const getCountriesDataForPesticideTest = (countriesLst) => {
    const countriesSpecData = {
      countriesList: [],
      selectedCountries: [],
    };
    countriesLst.forEach((countryData) => {
      countriesSpecData.countriesList.push(countryData.country);
      if (countryData.selected) {
        countriesSpecData.selectedCountries.push(countryData.country);
      }
    });
    return countriesSpecData;
  };

  useEffect(() => {
    const { countriesList, selectedCountries: selected } = getCountriesDataForPesticideTest(countriesData ?? []);
    setCountries(countriesList);
    setSelectedCountries(Array.from(new Set([...selected])));
  }, [countriesData]);

  /**
   * Handle on change event
   * @param {string} values selected country
   */
  const handleOnChange = (values) => {
    setSelectedCountries(values);
    if (onChange) {
      const updated = countries.map((country) => ({
        country,
        selected: values.includes(country),
      }));
      onChange(updated);
    }
  };

  /**
   * Handle clicking on list
   */
  const handleListClick = () => {
    setIsDropdownVisible(true);
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
  };

  const list = (
    <div
      className="MRLList__Container"
      onClick={handleListClick}
    >
      <div className={`MRLList__CountryList ${editMode ? "MRLList__CountryList__EditMode" : ""}`}>
        {selectedCountries.length > 0 ? selectedCountries.map((country) => (
          <span className="MRLList__CountryTab" key={country}>
            {country}
          </span>
        )) : editMode && <span className="MRLList__CountryList__PlaceholderText">Please select countries</span>}
      </div>
      {editMode && <ChevronDown className="MRLList__ChevronDownIcon" />}
    </div>
  );

  if (editMode) {
    return (
      <CountriesCheckboxList
        values={countries}
        selectedCountries={selectedCountries}
        setSelectedCountries={setSelectedCountries}
        onChange={handleOnChange}
        defaultCheckedCountries={[]}
        disabledItems={[]}
        getIsOpen={(visible) => setIsDropdownVisible(visible)}
        onClickSave={handleOnChange}
      >
        {isDropdownVisible ? (
          <div className="MRLList__DropdownButtonContainer" ref={dropdownRef}>
            <div className="MRLList__DropdownButton">
              <span>Select countries</span>
              <ChevronDown className="MRLList__ChevronDownIcon" />
            </div>
          </div>
        ) : list}
      </CountriesCheckboxList>
    );
  }

  return list;
}

export default MRLCountryList;

import React from "react";
import {
  View, StyleSheet, Text,
} from "@react-pdf/renderer";
import PDFImageWithText from "./PDFImageWithText";
import { softHyphenate } from "./pdfUtils";

export default function PDFHeader(props) {
  const { header, alignLeft } = props;

  const styles = StyleSheet.create({
    header: {
      paddingRight: "19",
      minHeight: header.logo_preview_file ? "94" : "0",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: alignLeft ? "space-between" : "center",
      borderBottom: "border: 1 solid #B3BFDB",
      marginBottom: "16",
      position: "relative",
      paddingBottom: "9",
    },
    headerLeftSection: {
      maxWidth: "70%",
    },
    noImage: {
      paddingLeft: "21",
    },
    companyAddress: {
      maxWidth: 250,
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      alignItems: alignLeft ? "left" : "center",
      textAlign: alignLeft ? "left" : "center",
    },
    companyNumbers: {
      position: alignLeft ? "unset" : "absolute",
      right: "0",
      bottom: alignLeft ? "0" : "10",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "auto",
      maxWidth: "30%",
      gap: "7",
      color: "#506375",
    },
    companyNumbersRow: {
      display: "flex",
      flexDirection: "row",
      gap: "6",
      width: "auto",
      justifyContent: "space-between",
      maxWidth: alignLeft ? "100%" : "200",
    },
    phoneNumber: {
      maxWidth: "108",
      wordBreak: "break-word",
    },
    faxNumber: {
      maxWidth: "125",
      wordBreak: "break-word",
    },
    coaSection: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    coa: {
      fontFamily: "Roboto-500",
      fontSize: "16",
      color: "#506375",
      padding: "0 16 16 16",
    },
    gap: {
      marginBottom: 6,
    },
    wordWrap: {
      display: "flex",
      flexDirection: "row",
      overflowWrap: "break-word",
    },
  });
  const {
    name, address, phone, fax, logo_preview_file,
  } = header;

  return (
    <>
      <View style={styles.header} fixed>
        <View style={[styles.headerLeftSection, !logo_preview_file ? styles.noImage : ""]}>
          <PDFImageWithText
            imageFile={logo_preview_file}
            title={name.toUpperCase()}
            subtitle={address}
            alignLeft={alignLeft}
            hyphenateTitle={false}
          />
        </View>
        <View style={styles.companyNumbers}>
          {phone && (
            <View style={styles.companyNumbersRow}>
              <Text style={styles.wordWrap}>
                {"PHONE : "}
              </Text>
              <Text
                style={[styles.wordWrap, styles.phoneNumber]}
              >
                {softHyphenate(phone)}
              </Text>
            </View>
          )}
          {fax && (
            <View style={styles.companyNumbersRow}>
              <Text style={styles.wordWrap}>
                {"FAX : "}
              </Text>
              <Text
                style={[styles.wordWrap, styles.faxNumber]}
              >
                {softHyphenate(fax)}
              </Text>
            </View>
          )}
        </View>
      </View>
      <View style={styles.coaSection}>
        <Text style={[styles.coa, styles.wordWrap]}>Certificate of Analysis</Text>
      </View>
    </>
  );
}

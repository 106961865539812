import axios from "axios";
import { API_URL } from "../utils/Constant";

/**
 * Based on selected tests and product search value, return list of products
 * @param {Object} payload { tests: Array, search: String }
 * @param {AbortSignal} signal
 * @returns {Object} { success, message, result }
 */
export const productSearch = async ({ tests, search, search_by }, signal) => {
  try {
    const response = await axios.post(`${API_URL}/searchproductfield/`, {
      search_value: search,
      search_by,
      test_list: tests,
    }, { signal });
    return {
      success: true,
      ...response.data,
    };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Based on selected products, test search value, return list of tests grouped by unit
 * @param {Object} payload { linkReportFields: Array, search: String, products: Array}
 * @param {AbortSignal} signal
 * @returns {Object} { success, message, result } returns a list of tests grouped by unit that has the search value as substring.
 */
export const testSearch = async ({ search, products }, signal) => {
  try {
    const response = await axios.post(`${API_URL}/gettestpairs/`, {
      product_id_list: products.map((product) => product.product_id),
      search_value: search,
    }, { signal });

    return { success: true, ...response.data };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Based on the selected product and test pair, return list of reports and related analytics metadata
 * @param {Object} payload { product: Object, test: String, dateFrom: String, dateTo: String, linkReportFields: Array }
 * @returns {Object} { success, message, result }
 */
export const getTestData = async ({
  product, test, dateFrom, dateTo,
}) => {
  try {
    const response = await axios.post(`${API_URL}/gettestreports/`, {
      product_id: product.product_id,
      test,
      range_from: dateFrom,
      range_to: dateTo,
    });

    return { success: true, ...response.data };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Based on selected products and tests, return list of dates that have reports
 * @param {Object} payload { product: Array, test: Array, linkReportFields: Array }
 * @returns {Object} { success, message, result }
 */
export const getDates = async ({ products, tests }) => {
  try {
    const response = await axios.post(`${API_URL}/getdateshasreport/`, {
      product_id_list: products.map((product) => product.product_id),
      test_list: tests,
    });

    return { success: true, ...response.data };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

import React, { useEffect, useState, useContext } from "react";

import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import "../../SampleSubmission.css";
import "../MainPage.css";
import "./Submissions.css";
import useAbortController from "../../../Common/hooks/useAbortController";
import Loading from "../../../Common/Loading";
import NoResultsGIF from "../../../Common/NoResultsGIF";
import SampleFunctionContext from "../../SampleFunctionContext";
import SampleShareModal from "../../SampleSubmissionModal/SampleShareModal";
import SampleSubmissionConfirmModal from "../../SampleSubmissionModal/SampleSubmissionConfirmModal";
import FloatingButton from "../Common/FloatingButton";
import InviteModal from "../Common/Invite/InviteModal";
import SubmissionDetailsModal from "./SubmissionDetailsModal";
import SubmissionsSearch from "./SubmissionsSearch";
import SubmittedSubmissionRow from "./SubmittedSubmissionRow";

import { getTabsList } from "../../../../utils/common";
import { convertUtcToLocal } from "../../../Common/utils/dateUtils";
import { getSortedFields } from "../../utils";

import {
  getSubmittedSubmissionsInfo,
  deleteSubmission,
  getSampleFields,
} from "../../../../actions/sampleSubmission";

import pumpkinGIF from "../../../../assets/images/environment/pumpkin.gif";

export default function Submissions({
  setShowSubmissionForm,
  setSubmissionEditingSample,
  setSubmissionAddingSamples,
  loadingSubmissions,
  setLoadingSubmissions,
  submittedSubmissions,
  setSubmittedSubmissions,
  isInitialData,
  setSampleCategory,
  setCreateSubmission,
}) {
  const submittedSubmissionsScrollbarRef = React.useRef();
  const [search, setSearch] = useState("");
  const [searchByField, setSearchByField] = useState("all");
  const [dateFilter, setDateFilter] = useState({
    dateFromUTC: "", dateToUTC: "", dateFromLocal: "", dateToLocal: "",
  });
  const [submittedByLst, setSubmittedByLst] = useState([]);
  const [loadingNewContent, setLoadingNewContent] = useState(false);
  const [showSubmissionDetailsModal, setShowSubmissionDetailsModal] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({
    prevPageLink: "", nextPageLink: "", currentPage: "1", totalPages: "1",
  });
  const [submissionClicked, setSubmissionClicked] = useState({});
  const [submissionToDelete, setSubmissionToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sampleFilterCategory, setSampleFilterCategory] = useState("");
  const [submitBy, setSubmitBy] = useState("");
  const [shareSubmission, setShareSubmission] = useState(false);
  const [sampleLinkFields, setSampleLinkFields] = useState([]);
  const [sampleFields, setSampleFields] = useState([]);
  const [sampleFieldsInfo, setSampleFieldsInfo] = useState({});
  const [loadingFields, setLoadingFields] = useState(true);
  const [shareSampleInfo, setShareSampleInfo] = useState(false);
  const [shareSampleType, setShareSampleType] = useState("");
  const { setNewAbortController, clearAbortController } = useAbortController();
  const { setOnAddBackButton } = useContext(SampleFunctionContext);

  /** Get list of submitted submissions and pagination info.
     * Each submitted submission has a different status code string which represents its status:
     * "-1": "Submitted", "0": "Samples Received", "1": "Testing in Progress", "2": "Testing Complete".
     * Each submitted submission has a product spec string to represent its displayed product spec:
     * "Added": "Spec Added", "Pending": "Spec Incomplete".
     * Each sample has a specs flag to represent its status:
     * 0: "In Process", "1": "In Spec", "2": "Out of Spec", "3": "Spec Incomplete", "4": "In Process (Retest)", "5": "In Spec (Retest)", "6": "Out of Spec (Retest)"
     * @param {Number} page page being fetched
     * @param {String} searchValue
     * @param {string} searchBy one of all/submission_name/sample_id
     * @param {Object} datesObj { dateFromUTC, dateFromLocal, dateToUTC, dateToLocal }
     * @param {Boolean} isInitial if this is the first call to fetch submissions
     * */
  const getSubmissions = async (page = 1, searchValue = "", searchBy = "all", datesObj = {}, isInitial = false, submittedBy = "", field = "", sampleCategory = "") => { // eslint-disable-line
    if (page > 1) {
      setLoadingNewContent(true);
    } else {
      setLoadingSubmissions(true);
      submittedSubmissionsScrollbarRef.current.scrollToTop();
    }

    const newAbortController = setNewAbortController();
    const {
      dateFromUTC, dateFromLocal, dateToUTC, dateToLocal,
    } = datesObj;
    const payload = {
      submission_id: "",
      page,
      search_value: searchValue,
      search_by: searchBy,
      date_from: dateFromUTC ?? "",
      date_to: dateToUTC ?? "",
      user_selected_date_from: dateFromLocal ?? "",
      user_selected_date_to: dateToLocal ?? "",
      submitted_by: submittedBy ?? "",
      sample_category: sampleCategory,
    };
    const {
      success: submittedSuccess,
      message: submittedMessage, next_page_link, prev_page_link,
      total_page, result: submitted_submissions, submitted_by_lst,
    } = await getSubmittedSubmissionsInfo(payload, newAbortController.signal);

    if (newAbortController.signal.aborted) {
      /* Set pagination loading state to false (the current call in progress won't set this to false when finished) */
      if (page > 1) {
        setLoadingNewContent(false);
      }
      return;
    }

    if (submittedSuccess) {
      setPaginationInfo({
        prevPageLink: prev_page_link, nextPageLink: next_page_link, totalPages: total_page, currentPage: page,
      });
      setSubmittedSubmissions(page > 1 ? [...submittedSubmissions, ...submitted_submissions] : submitted_submissions);
      setSubmittedByLst(submitted_by_lst);
    } else {
      toast.error(submittedMessage);
      if (page > 1) {
        submittedSubmissionsScrollbarRef.current.scrollTop -= 100; // prevent infinitely firing scroll api call
      } else {
        setSubmittedSubmissions([]);
      }
    }

    /** Used to distinguish between if a company actually has no submissions or search result is empty */
    if (isInitialData) {
      isInitialData.current = isInitial;
    }

    clearAbortController();

    if (page > 1) {
      /* Fix safari bug where api is called repeatedly.
        Scroll scrollbar 4 pixels from bottom so it's outside of the tolerance range (3 pixels) and doesn't trigger the api call */
      submittedSubmissionsScrollbarRef.current.scrollTop -= 4;
      setLoadingNewContent(false);
    } else {
      setLoadingSubmissions(false);
      if (submittedSubmissionsScrollbarRef.current) {
        submittedSubmissionsScrollbarRef.current.scrollToTop();
      }
    }
  };

  /** on component mount - get submitted submissions lists */
  useEffect(() => {
    const tabsList = getTabsList();
    if (tabsList?.includes("product") && tabsList?.includes("environment")) {
      getSubmissions(1, "", searchByField, {}, true);
    } else if (tabsList?.includes("product") && !tabsList?.includes("environment")) {
      getSubmissions(1, "", searchByField, {}, true, "", "", "product");
      setSampleFilterCategory("product");
    } else if (!tabsList?.includes("product") && tabsList?.includes("environment")) {
      getSubmissions(1, "", searchByField, {}, true, "", "", "environment");
      setSampleFilterCategory("environment");
    }
    setLoadingFields(false);
  }, []); // eslint-disable-line

  const apiGetSampleFields = async (category) => {
    const result = await getSampleFields(category);
    if (result.success) {
      if (result.fields && result.fields.length) {
        const fieldsMap = {};
        result.fields.forEach((fieldObj) => {
          fieldsMap[fieldObj.json_field] = fieldObj;
        });
        /** Only show fields that are not display "2" */
        const sampleSubmissionFields = result.fields.filter(({ display }) => display !== "2");
        const sampleSubmissionFieldsSorted = getSortedFields(sampleSubmissionFields.map(({ json_field }) => json_field));
        setSampleFieldsInfo(fieldsMap || {});
        setSampleFields(sampleSubmissionFieldsSorted || []);
        setSampleLinkFields(category === "environment" ? result.link_pattern_fields : result.link_report);
      } else {
        toast.error("Fields are missing or empty");
      }
    } else {
      toast.error(result.message);
    }
  };
  /** Update id of the clicked submissiom, open submission details modal, pass the id to the modal */
  const handleClickSubmission = async (submission) => {
    apiGetSampleFields(submission.sample_category);
    setSubmissionClicked(submission);
    setSampleCategory(submission.sample_category);
    setShowSubmissionDetailsModal(true);
  };

  /** If we should load the next page, scroll to bottom */
  useEffect(() => {
    if (loadingNewContent) {
      submittedSubmissionsScrollbarRef.current.scrollToBottom();
    }
  }, [loadingNewContent]);

  /**
   * On scroll stop, if scroll bar is at the bottom, load the next page of rows
   * @param  {Event} e on scroll stop event
   */
  const handleScrollToBottom = (e) => {
    const handlePageChangeScroll = async () => {
      const {
        nextPageLink, currentPage, totalPages,
      } = paginationInfo;
      const isLastPage = parseInt(currentPage) === parseInt(totalPages);

      if (!isLastPage && !loadingNewContent && !loadingSubmissions) {
        const nextPage = nextPageLink ? nextPageLink.split("page=")[1].split("&")[0] : "1";
        getSubmissions(nextPage, search, searchByField, dateFilter, false, submitBy, searchByField, sampleFilterCategory);
      }
    };

    const tolerance = 3;
    const bottom = Math.abs(Math.ceil(e.contentScrollHeight - e.scrollTop) - Math.ceil(e.clientHeight)) <= tolerance;
    if (bottom) {
      handlePageChangeScroll();
    }
  };

  /** Refresh the submissions list (get the first page and scroll the table to the top) */
  const refreshSubmissionsList = async () => {
    await getSubmissions();
    submittedSubmissionsScrollbarRef.current.scrollToTop();
  };

  /**
   * Set submissionEditingSample object in parent, show submission form
   * @param {Object} submission { submit_id, submit_name, submit_date, po, status }
   * @param {Object} sample { [fields], sample_id, tests_list}
   */
  const handleEditSample = async (submission, sample) => {
    const {
      submit_id, submit_name, submit_date, po, status,
    } = submission;

    setSubmissionEditingSample({
      submit_id,
      submit_name,
      status,
      submission_date: convertUtcToLocal(submit_date),
      sample: { ...sample },
      po: po ?? "",
      sample_category: submission.sample_category,
    });
    setShowSubmissionForm(true);
  };
  /**
   * Set submissionAddingSamples object in parent, show submission form
   * @param {Object} submission { submit_id, submit_name, submit_date, po, status }
   */
  const handleAddSamples = async (submission) => {
    const {
      submit_id, submit_name, submit_date, po, status,
      // comment,
    } = submission;
    setSubmissionAddingSamples({
      submit_id,
      submit_name,
      status,
      submission_date: convertUtcToLocal(submit_date),
      po: po ?? "",
      // comment,
    });
    setOnAddBackButton(true);
    setShowSubmissionForm(true);
  };

  /**
   * Set submissionToDelete and show confirmation modal
   * @param {Object} submission { submit_id, submit_name, submit_date, po, status }
   */
  const handleDeleteSubmission = async (submission) => {
    setSubmissionToDelete(submission);
  };

  const handleSearchByChange = (field) => {
    setSearchByField(field);
  };

  const handleSubmitByChange = (value) => {
    setSubmitBy(value);
  };

  const handleSampleCategory = (value) => {
    setSampleFilterCategory(value);
  };

  /**
   * Call the delete submission api, if successful close the confirm modal and refresh the submissions list.
   * If submissions detail modal is open, close it.
   * @param {Object} submission { submit_id, submit_name, submit_date, po, status }
   */
  const handleConfirmDeleteSubmission = async (submission, fromDeleteSample = false) => {
    setDeleteLoading(true);
    const { success, message } = await deleteSubmission(submission.submit_id, submission.status, submission.sample_category);
    if (success) {
      toast.success(`Successfully deleted${fromDeleteSample ? " sample and" : ""} submission`);
      if (showSubmissionDetailsModal) {
        setShowSubmissionDetailsModal(false);
      }
      refreshSubmissionsList();
      setSubmissionToDelete(null);
      setDeleteLoading(false);
      return true;
    }
    toast.error(fromDeleteSample ? "Failed to delete sample" : message);
    setDeleteLoading(false);
    return false;
  };

  const handleShareSubmission = () => {
    setShareSubmission(false);
  };

  const handleShareSubmissionInfo = (SampleType) => {
    setShareSampleInfo(true);
    setShareSampleType(SampleType);
    setShareSubmission(false);
  };

  const handleShareSubmissionModal = () => {
    setShareSampleInfo(false);
  };

  return (
    <>
      {(submissionToDelete) // delete draft confirmation modal
        && (
          <SampleSubmissionConfirmModal
            headerText="Delete Submission Confirmation"
            bodyText="Are you sure you want to delete this submission? This cannot be undone."
            buttonText={["Cancel", "Delete", "Deleting"]}
            buttonFunctions={[() => setSubmissionToDelete(null), () => handleConfirmDeleteSubmission(submissionToDelete)]}
            loading={deleteLoading}
          />
        )}
      <div className="submitted-submissions-container">
        <div className="SubmissionsHeader">
          <div className="sample-submission-title-block" />
          <h2>Submissions</h2>
          <SubmissionsSearch
            search={search}
            setSearch={setSearch}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            getSubmissions={getSubmissions}
            setSubmittedByLst={setSubmittedByLst}
            submittedByLst={submittedByLst}
            setSearchField={handleSearchByChange}
            loadingState={(loadingFields || loadingSubmissions)}
            setSubmitBy={handleSubmitByChange}
            setSampleFilterCategory={handleSampleCategory}
            searchByField={searchByField}
            setSearchByField={setSearchByField}
          />
        </div>
        <Scrollbar
          ref={submittedSubmissionsScrollbarRef}
          disableTrackYWidthCompensation
          disableTracksWidthCompensation
          onScrollStop={(e) => handleScrollToBottom(e)}
        >
          <div className="submitted-submissions-table-container">
            <table>
              <thead className="SubmissionsTable__Header">
                <tr>
                  <th>Status</th>
                  <th>Submission Name</th>
                  <th className="submitted-submissions-table-submitted-on-th">Submitted on</th>
                  <th className="submitted-submissions-table-submitted-on-th">Submitted by</th>
                  <th className="submitted-submissions-table-samples-number-th">
                    No of Samples
                  </th>
                  {/* {type === "product" && <th>Product Spec</th>} */}
                  {/* {type === "environment" && <th>Environment Spec</th>} */}
                  <th className="submitted-submissions-table-submitted-on-th">Spec</th>
                  {/* <th className="submitted-submissions-table-samples-number-th">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {!(loadingFields || loadingSubmissions) && submittedSubmissions.map((submission, idx) => (
                  <SubmittedSubmissionRow
                    submission={submission}
                    key={idx}
                    search={search}
                    handleClickSubmission={handleClickSubmission}
                    handleDeleteSubmission={handleDeleteSubmission}
                    handleAddSamples={handleAddSamples}
                    hideActions
                    searchField={searchByField}
                  />
                ))}
              </tbody>
            </table>
            {!(loadingFields || loadingSubmissions) && submittedSubmissions.length === 0 && (<NoResultsGIF image={pumpkinGIF} className="submitted-submissions-no-results" message="No Data" />)}
            {(loadingFields || loadingSubmissions) && (<div className="submitted-submissions-table-skeleton ActiveloadingAnimation" />)}
          </div>
          {loadingNewContent && !(loadingFields || loadingSubmissions) && <Loading />}
        </Scrollbar>
        <div className="Submissions_FloatingButton">
          <FloatingButton
            setCreateSubmission={setCreateSubmission}
            setShareSubmission={setShareSubmission}
          />
        </div>
      </div>
      {showSubmissionDetailsModal && (
        <SubmissionDetailsModal
          showSubmissionDetailsModal={showSubmissionDetailsModal}
          setShowSubmissionDetailsModal={setShowSubmissionDetailsModal}
          sampleLinkFields={sampleLinkFields}
          sampleFields={sampleFields}
          sampleFieldsInfo={sampleFieldsInfo}
          submissionClicked={submissionClicked}
          handleDeleteSubmission={handleDeleteSubmission}
          handleEditSample={handleEditSample}
          handleAddSamples={handleAddSamples}
          handleConfirmDeleteSubmission={handleConfirmDeleteSubmission}
          refreshSubmissionsList={refreshSubmissionsList}
        />
      )}
      {
        shareSampleInfo && (
          <InviteModal handleShowInvite={handleShareSubmissionModal} type={shareSampleType} />
        )
      }
      {
        shareSubmission && (
          <SampleShareModal handleShareSubmissionInfo={handleShareSubmissionInfo} onCancel={handleShareSubmission} />
        )
      }
    </>
  );
}

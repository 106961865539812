import moment from "moment";

import { getUserCredentials } from "./common";
import { AWS_BASE_PATH } from "./Constant";

export const getViewAsPerStatusCode = (error) => {
  try {
    if (
      error.request.status === 404
      || (error.request.status >= 500 && error.request.status <= 599)
    ) {
      window.location.pathname = "/maintenance";
    }
  } catch (e) {
    window.location.pathname = "/maintenance";
  }
};

export const formatDateForDisplay = (date) => moment(new Date(date)).format("YYYY/MM/DD");

export const getValueOfObj = (objName, ...fields) => {
  let objValue = { ...objName };
  if (objValue !== undefined) {
    for (let i = 0; i < fields.length; i++) {
      if (objValue[fields[i]] !== undefined) {
        objValue = objValue[fields[i]];
      } else {
        return undefined;
      }
    }
    return objValue;
  }
  return undefined;
};

export const isNonEmptyField = (arr, field) => (arr !== undefined) && (arr[field] !== undefined);

/**
 * Gets filename from path and returns the name and extension.
 * @param {String} path file path/filename
 * @returns {object} { name, ext }
 */
export const getFileNameAndExtensionFromPath = (path) => {
  const basename = path.split("/").pop(); // extract file name from full path
  const pos = basename.lastIndexOf("."); // get last position of "."

  if (basename === "" || pos < 1) { // if file name is empty or "." not found (-1) or comes first (0)
    return { name: basename, ext: "" };
  }

  return { name: basename.slice(0, pos), ext: basename.slice(pos) }; // extract filename and extension (with ".")
};

/**
 * Remove timestamp from filename (preserves extension). Filename must in the form of "something_TIMESTAMP.ext" (.ext is optional).
 * @param {String} path file path/filename
 * @returns {String} filename with timestamp removed
 */
export const getFileNameWithTimestampRemoved = (path) => {
  const { name, ext } = getFileNameAndExtensionFromPath(path);
  const nameArr = name.split("_");
  nameArr.pop();
  return `${nameArr.join("_")}${ext}`;
};

/**
 * Fetch file from given S3 bucket
 * @param {String} filePath path of file in aws
 * @param {S3} s3 one of the s3 objects initialized in aws.js
 * @returns {Promise} resolves to a Blob if success, else "" if there's an error
 */
export const getFileFromS3 = (filePath, s3) => new Promise((resolve) => {
  s3.getObject(
    { Bucket: s3.config.bucket, Key: filePath },
    (error, data) => {
      if (error != null) {
        return resolve("");
      }
      const fileBlob = new Blob([data.Body], { type: data.ContentType ? data.ContentType : "" });
      if (fileBlob) {
        return resolve(fileBlob);
      }
      return resolve("");
    },
  );
});

/**
 * Fetch file from given S3 bucket, then downloads file.
 * @param {String} filePath path of file in aws
 * @param {S3} s3 one of the s3 objects initialized in aws.js
 * @param {String} fileName name of the downloaded file (what the user sees). If not provided, filePath is used.
 * @returns {Boolean} true if successful, else false
 */
export const downloadFileFromS3 = async (filePath, s3, fileName = "") => {
  const fileBlob = await getFileFromS3(filePath, s3);
  if (fileBlob) {
    const url = window.URL.createObjectURL(fileBlob);
    if (url) {
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || filePath;
      a.click();
    }
    return true;
  }
  return false;
};

/**
 * Upload file to given S3 bucket. Sanitizes file name, constructs file key, and determines part size before uploading.
 * @param {Object} fileInfo { file: File, folderPath: String, fileName: String, type: String }
 * @param {S3} s3
 * @returns {Promise} resolves { path, type } if successful else ""
 */
export const uploadFileToS3 = (fileInfo, s3) => new Promise((resolve) => {
  const {
    file, folderPath, fileName, type, // folderPath ex: "ProductTemplate/Template/"
  } = fileInfo;
  if (!file) {
    return resolve({ path: "", type });
  }

  const sizeInMB = file.size / (1024 * 1024);
  const partSizeInMB = Math.max(5, Math.ceil(sizeInMB));

  const user = getUserCredentials();
  const sanitizedFileName = fileName.replace(/\s|\//g, "_");
  const key = `${AWS_BASE_PATH}media/${user.company_domain}/${user.company_name}/${folderPath}${sanitizedFileName}`;

  s3.upload(
    {
      Bucket: s3.config.bucket,
      Key: key,
      Body: file,
      ContentType: file.type,
      // ACL: s3.config.ACL, // ACL (access control list) defaults to that of the bucket
    },
    { partSize: partSizeInMB * 1024 * 1024 },
    (err, success) => {
      if (err) return resolve("");
      const location = success.Key;
      return resolve({ path: location, type: file.type ? file.type : "" });
    },
  );
  return "";
});

export const deleteFileFromS3 = async (file, s3) => {
  s3.deleteObject(
    { Bucket: s3.config.bucket, Key: file },
    (err) => {
      if (err) {
        return false;
      } return true;
    },
  );
};

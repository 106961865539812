/**
 * Generate the cropped and scaled canvas preview for the image
 * @param {HTMLImageElement} image image elem
 * @param {HTMLCanvasElement} canvas canvas element
 * @param {Object} crop { unit: %, x, y, width, height }
 * @param {Number} scale user-specified scale for the image
 */
export async function canvasPreviewCrop(
  image,
  canvas,
  crop,
  scale = 1,
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  /** Convert crop parameters from percent to pixel */
  const pixelCrop = {};
  pixelCrop.x = (crop.x / 100) * image.width;
  pixelCrop.y = (crop.y / 100) * image.height;
  pixelCrop.width = (crop.width / 100) * image.width;
  pixelCrop.height = (crop.height / 100) * image.height;

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio; // devicePixelRatio slightly increases sharpness on retina devices

  /** Maximum canvas size supported by most browsers */
  const maxCanvasSize = 4096;
  /**
   * Calculates the maximum scale factor based on the height/width of the cropped image.
   * This ensures that the canvas height does not exceed the maximum canvas size.
   */
  const scaleFactor = Math.min(1, maxCanvasSize / (pixelCrop.width * scaleX * pixelRatio), maxCanvasSize / (pixelCrop.height * scaleY * pixelRatio));
  canvas.width = Math.floor(pixelCrop.width * scaleX * pixelRatio * scaleFactor);
  canvas.height = Math.floor(pixelCrop.height * scaleY * pixelRatio * scaleFactor);

  /** Draw in a white background before drawing the image so when PNG is converted to JPEG, transparency will appear white instead of black. */
  ctx.fillStyle = "#fff";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  /** Scale the image to fit the canvas */
  ctx.scale(pixelRatio * scaleFactor, pixelRatio * scaleFactor);
  ctx.imageSmoothingQuality = "high";

  const cropX = pixelCrop.x * scaleX;
  const cropY = pixelCrop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  /** Move the crop origin to the canvas origin (0,0) */
  ctx.translate(-cropX, -cropY);
  /** Move the origin to the center of the original position */
  ctx.translate(centerX, centerY);
  /** Scale the image by the user-specified scale */
  ctx.scale(scale, scale);
  /** Move the center of the image to the origin (0,0) */
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();
}

/**
 * Helper function for generating the rotated canvas preview for the image
 * @param {HTMLImageElement} img image elem
 * @param {HTMLCanvasElement} canvas canvas elem
 * @param {CanvasRenderingContext2D} ctx 2D context of the canvas elem
 * @param {Number} numRotations number of 90deg rotations [0, 3]
 */
function drawRotatedImage(img, canvas, ctx, numRotations = 1) {
  const w = img.naturalWidth;
  const h = img.naturalHeight;

  /** Determine the direction of the x-axis */
  let xAxisX = Math.cos(numRotations * (Math.PI / 2));
  let xAxisY = Math.sin(numRotations * (Math.PI / 2));

  /** Find width and height of rotated image */
  const tw = Math.abs(w * xAxisX - h * xAxisY);
  const th = Math.abs(w * xAxisY + h * xAxisX);

  /** Scale the image to fit the canvas */
  const scale = Math.min(canvas.width / tw, canvas.height / th);
  xAxisX *= scale;
  xAxisY *= scale;

  /** Set transform */
  ctx.setTransform(xAxisX, xAxisY, -xAxisY, xAxisX, canvas.width / 2, canvas.height / 2);

  /** Draw image so it's centered at the canvas center */
  ctx.drawImage(img, -w / 2, -h / 2, w, h);
}

/**
 * Generate the rotated canvas preview for the image
 * @param {HTMLImageElement} image image elem
 * @param {HTMLCanvasElement} canvas canvas elem
 * @param {Number} rotate degrees of rotation (-90, 0, 90, 180)
 */
export async function canvasPreviewRotateOnly(
  image,
  canvas,
  rotate = 90,
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  let canvasWidth = image.naturalWidth;
  let canvasHeight = image.naturalHeight;

  /** Set the canvas dimensions based on the rotation */
  switch (rotate) {
    case 90:
      canvasWidth = image.naturalHeight;
      canvasHeight = image.naturalWidth;
      break;
    case -90:
      canvasWidth = image.naturalHeight;
      canvasHeight = image.naturalWidth;
      break;
    default:
      break;
  }

  /** Maximum canvas size supported by most browsers */
  const maxCanvasSize = 4096;

  /**
   * Calculates the maximum scale factor based on the height/width of the cropped image.
   * This ensures that the canvas height does not exceed the maximum canvas size.
   */
  const scaleFactor = Math.min(1, maxCanvasSize / canvasWidth, maxCanvasSize / canvasHeight);
  canvas.width = Math.floor(canvasWidth * scaleFactor);
  canvas.height = Math.floor(canvasHeight * scaleFactor);

  /** Rotate the image */
  const degToUnit = {
    0: 0, 90: 1, 180: 2, "-90": 3,
  };

  /** Draw in a white background before drawing the image so when PNG is converted to JPEG, transparency will appear white instead of black. */
  ctx.fillStyle = "#fff";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  drawRotatedImage(image, canvas, ctx, degToUnit[rotate]);
}

/**
 * Canvas to Blob object
 * @param {HTMLCanvasElement} canvas canvas elem
 * @param {String} fileType file type ("image/png", "image/jpg", or "image/jpeg")
 * @param {Number} quality image quality, between 0 and 1 inclusive
 * @returns {Blob} blob object with the canvas contents
 */
export function toBlob(canvas, fileType, quality) {
  return new Promise((resolve) => {
    if (canvas) {
      if (quality) {
        canvas.toBlob(resolve, fileType ?? "", quality);
      } else {
        canvas.toBlob(resolve, fileType ?? "");
      }
    } else {
      resolve("");
    }
  });
}

/**
 * Rotate the image in a canvas, generate Blob from canvas, return the url generated for the blob
 * @param {HTMLImageElement} image image elem
 * @param {Number} rotate degrees of rotation (-90, 0, 90, 180)
 * @returns {String} url for the image file blob
 */
export async function generateRotatedImageUrl(
  image,
  rotate,
  type,
  quality,
) {
  const canvas = document.createElement("canvas");
  canvasPreviewRotateOnly(image, canvas, rotate);

  const blob = await toBlob(canvas, type, quality);
  // console.log("rotated", "quality", quality, "size", `${blob.size}B`, `${(blob.size / 1048576).toFixed(2)}MB`);

  if (!blob) {
    // console.error("Failed to create blob");
    return "";
  }

  return URL.createObjectURL(blob);
}

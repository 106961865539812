import React from "react";
import { Button } from "antd";
import Scrollbar from "react-scrollbars-custom";
// import Header from "./Common/Header";
// import TemplateHeader from "./TemplateHeader";
import CreateTemplate from "./Common/CreateTemplate";
import TemplateCard from "./Templates/TemplateCard";

import "./styles/templates.css";

export default function Templates(props) {
  const {
    indexOfTemplateSelected,
    templateList,
    isCreatingCoa,
    reportIsSelected,
    saveTemplateIdForAddingCoa,
    handleAddTemplateClick,
    handleEditTemplate,
    handleDeleteTemplate,
    handleCustomizeReport,
    isReportAndTemplateSelected,
    templateLoading,
  } = props;
  return (
    <div className={isCreatingCoa ? "templatesContainer--COA" : "templatesContainer"}>
      <div className={isCreatingCoa ? "templatesContainerChild--COA" : "templatesContainerChild"}>
        <div className="templatesHeaderContainer">
          <span>
            {isCreatingCoa ? "Templates" : "Your templates"}
          </span>
          {!isCreatingCoa && (<p>Create custom templates for your COAs </p>)}
        </div>
        {/* eslint-disable-next-line */}
        <Scrollbar style={{ height: isCreatingCoa ? "calc(100vh - 531px)" : "calc(100vh - 355px)", marginBottom: "11px" }} id="templatesScrollbar">
          <div className="templateBody">
            {!isCreatingCoa && <CreateTemplate handleAddTemplateClick={handleAddTemplateClick} />}
            {!templateLoading && templateList.map((item, index) => (
              <TemplateCard
                key={index}
                index={index}
                item={item}
                isSelected={indexOfTemplateSelected === index}
                reportIsSelected={reportIsSelected}
                isCreatingCoa={isCreatingCoa}
                saveTemplateIdForAddingCoa={saveTemplateIdForAddingCoa}
                handleEditTemplate={handleEditTemplate}
                handleDeleteTemplate={handleDeleteTemplate}
              />
            ))}
            {templateLoading && <div className="TemplateLoading ActiveloadingAnimation" />}
          </div>
        </Scrollbar>
        {isCreatingCoa && (<Button disabled={!isReportAndTemplateSelected} className="Template__ReportBtn" onClick={handleCustomizeReport}>Customize Report</Button>)}
      </div>
    </div>
  );
}

/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component } from "react";

export default class TagInput extends Component {
  inputRef = React.createRef();

  componentDidMount() {
    this.inputRef.current.select();
  }

  handleInputChange = (e) => {
    const { setTagInput, enableOutsideClick } = this.props;
    setTagInput(e.target.value);
    enableOutsideClick();
  }

  render() {
    const {
      tagInput,
      handleKeyPressAddTag,
    } = this.props;

    return (
      <div className="IndexTag__InputContainer IndexTag__TagInputContainer"/* style={{ position: "relative" }} */>
        <span className="IndexTag__TagInputContainer-dummyText">{tagInput || " "}</span>
        <input
          className="IndexTag__TagInputContainer-Input"
          ref={this.inputRef}
          autoFocus
          value={tagInput}
          onChange={this.handleInputChange}
          onKeyPress={handleKeyPressAddTag}
        />
      </div>

    );
  }
}

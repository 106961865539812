import React, { useMemo } from "react";

import {
  View, StyleSheet, Text, Svg, Path,
} from "@react-pdf/renderer";

import softHyphenate from "./pdfUtils";

import {
  /* getFormattedDetailsDate, */ limitTextLength, MAX_PDF_CHAR_SAMPLE_ID, splitSamples,
} from "../../../utils";

export default function ReceiptPDFSamplesList({ submission }) {
  const styles = StyleSheet.create({
    samplesListSection: {
      padding: "24 5 0 5",
      display: "flex",
      flexDirection: "Column",
      gap: "10",
    },
    title: {
      color: "#000",
      fontSize: "16",
    },
    sample: {
      color: "#1f2d3b",
      fontSize: "12",
      fontFamily: "Roboto-400",
      paddingTop: "6",
      paddingBottom: "6",
      wordBreak: "break-word",
    },
    sampleCounter: {
      fontFamily: "Roboto-500",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "row",
      marginBottom: 20,
    },
    icon: {
      width: 20,
      height: 20,
      marginRight: 10,
    },
    sampleBox: {
      border: "1 dashed #afbdca",
      padding: 8,
      wordBreak: "break-all",
    },
    sampleTextTitle: {
      color: "#afbdca",
      flexShrink: 0,
      wordBreak: "break-word",
    },
    samplesContainer: {
      flexDirection: "row",
      marginBottom: 10,
    },
    column: {
      flex: 1,
    },
    columnLeft: {
      marginRight: 5,
    },
    columnRight: {
      marginLeft: 5,
    },
  });

  /**
   * Split array into chunks and fill irregular chunks with empty string items.
   * @param {Array} array Array to split
   * @param {Number} chunkSize Chunk size
   * @returns Array of chunks
   */
  function chunkArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const arr = array.slice(i, i + chunkSize);
      if (arr.length < chunkSize) {
        const remainingLength = chunkSize - arr.length;
        arr.push(...Array(remainingLength).fill(""));
      }
      chunks.push(arr);
    }
    return chunks;
  }

  const CutIconSvgComponent = () => (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
    >
      <Path
        fill="#182534"
        d="M7.64 5.64C7.87 5.14 8 4.59 8 4c0-2.21-1.79-4-4-4S0 1.79 0 4s1.79 4 4 4c.59 0 1.14-.13 1.64-.36L8 10l-2.36 2.36C5.14 12.13 4.59 12 4 12c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4c0-.59-.13-1.14-.36-1.64L10 12l7 7h3v-1L7.64 5.64ZM4 6a2 2 0 1 1-.001-3.999A2 2 0 0 1 4 6Zm0 12a2 2 0 1 1-.001-3.999A2 2 0 0 1 4 18Zm6-7.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5.5.22.5.5-.22.5-.5.5ZM17 1l-6 6 2 2 7-7V1h-3Z"
      />
    </Svg>
  );

  /**
   * Create sample id title with counter value
   * @param {*} sample sample
   * @returns {string} <counter> Sample Id :
   */
  const getSampleIdTitle = (sample) => `${sample.sample_id_counter}. Sample ID: `;

  const renderText = (title, data) => (
    <Text style={styles.sample} wrap>
      <Text style={styles.sampleTextTitle} wrap>
        {softHyphenate(`${title}: `)}
      </Text>
      {softHyphenate(limitTextLength(data, MAX_PDF_CHAR_SAMPLE_ID))}
    </Text>
  );

  const samplesList = useMemo(() => {
    if (!submission) return [];
    if (submission.samples_list.length === 0) return [];

    const samples = submission.samples_list.reduce((acc, sample, index) => {
      if (sample?.num_composite_samples && sample?.num_composite_samples > 1) {
        acc.push(...splitSamples(sample, index + 1));
      } else {
        acc.push({ ...sample, sample_id_counter: index + 1 });
      }
      return acc;
    }, []);

    return chunkArray(samples, 2);
  }, [submission]);

  return (
    <View style={styles.samplesListSection}>
      <View style={styles.header}>
        <View style={styles.icon}>
          <CutIconSvgComponent />
        </View>
        <Text style={styles.title}>Print and cut the labels for use</Text>
      </View>
      <View>
        {samplesList.map((chunk, i) => (
          <View style={styles.samplesContainer} key={i} wrap={false}>
            {chunk.map((sample, j) => {
              const marginStyle = j === 0 ? styles.columnLeft : styles.columnRight;

              if (!sample) {
                return <View style={[styles.column, marginStyle, { padding: 8 }]} key={`${i}${j}`} wrap={false} />;
              }

              // const date = getFormattedDetailsDate(submission.submit_date);
              return (
                <View style={[styles.sampleBox, styles.column, marginStyle]} key={`${i}${j}`} wrap={false}>
                  {renderText(getSampleIdTitle(sample), sample.sample_id)}
                  {sample?.sub_sample_id && renderText("Sub Sample ID", sample.sub_sample_id)}
                  {/* {renderText("Submission date", date)}
                  {renderText("Submission name", submission.submit_name ? submission.submit_name : "")}
                  {renderText("PO", sample.po)} */}
                </View>
              );
            })}
          </View>
        ))}
      </View>
    </View>
  );
}

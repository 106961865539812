import React from "react";
import DroppableSection from "./DroppableSection";

export default function SectionContent(props) {
  const {
    dataFields, sectionIndex, inputs, setSectionInputs, handleTemplateDataFieldsChange,
  } = props;

  /**
   * Delete input from section
   * @param {Number} index index of input in section part
   * @param {String} sectionPart "left" or "right"
   */
  const deleteInput = (index, sectionPart) => {
    const inputsObject = { ...inputs };
    let removedDataField = null;
    removedDataField = inputsObject[sectionPart].splice(index, 1)[0];
    setSectionInputs(sectionIndex, inputsObject, false, { action: "delete", flag: removedDataField.flag, value: removedDataField.value }, false);
    if (removedDataField.flag !== "0") {
      handleTemplateDataFieldsChange("delete", removedDataField.flag, removedDataField.fieldName);
    }
  };

  /**
   * Update section's inputs object with DOM node of input
   * @param {String} sectionPart "left" or "right"
   * @param {Number} index index of input in section part
   * @param {HTMLElement} input
   */
  const setTextBoxRef = (sectionPart, index, input) => {
    const inputsObject = { ...inputs };
    inputsObject[sectionPart][index].ref = input;
    setSectionInputs(sectionIndex, inputsObject, false, null, false);
  };
  return (
    <div>
      <div className="sectionContentContainer">
        <DroppableSection
          inputs={inputs}
          dataFields={dataFields}
          sectionPart="left"
          droppableId={`dynamic_${sectionIndex}_left`}
          deleteInput={deleteInput}
          setTextBoxRef={setTextBoxRef}
        />
        <DroppableSection
          inputs={inputs}
          dataFields={dataFields}
          sectionPart="right"
          droppableId={`dynamic_${sectionIndex}_right`}
          deleteInput={deleteInput}
          setTextBoxRef={setTextBoxRef}
        />
        {inputs.left.length < 1 && inputs.right.length < 1 && (
          <div className="sectionContentPlaceHolderText">
            <span> It’s looking empty! </span>
            <span> Drag and drop fields here to add details </span>
          </div>
        )}
      </div>
    </div>
  );
}

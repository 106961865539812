import React from "react";
import CloseIcon from "../../../../../assets/images/product/template/CloseIconBlue.png";

export default function CreateFieldModalHeader(props) {
  const { toggleShowCreateFieldModal } = props;
  return (
    <div className="createFieldModalHeader">
      <div className="createFieldModalHeaderInner">
        <div className="createFieldHeaderBar" />
        <span className="createFieldHeaderSpan"> Data fields </span>
      </div>
      <img
        src={CloseIcon}
        alt="CloseIcon"
        className="createFieldHeaderCloseIcon"
        onClick={toggleShowCreateFieldModal}
      />
    </div>
  );
}

import React, { useState, useRef, useEffect } from "react";

import { motion } from "framer-motion/dist/framer-motion";
import Scrollbar from "react-scrollbars-custom";

import {
  showScrollbarStyle,
  hideScrollbarStyle,
} from "../../../../Common/AutoHideScrollbarStyles";
import { CHAR_LIMITS } from "../../Constant";

import { s3EsvPriv } from "../../../../../utils/aws";
import { getFileFromS3, getFileNameWithTimestampRemoved } from "../../../../../utils/helpers";

import { ReactComponent as EditIcon } from "../../../../../assets/images/sample-submission/edit.svg";
import { ReactComponent as GarbageCan } from "../../../../../assets/images/sample-submission/hoveredGarbageCan.svg";

import "../../../MainPage/Submissions/SampleCard.css";
import "./AddedSampleCard.css";

export default function AddedSampleCard({
  sample,
  sampleEditingIdx,
  idx,
  handleEditSample,
  handleDeleteSample,
  sampleFields,
  sampleFieldsInfo,
  setClearTestSearch,
  submissionEditingSample,
  updateSample,
  compositeRef,
}) {
  const [showTestView, setShowTestView] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageSrc, setImageSrc] = useState(sample.image_src ?? "");
  const [scrollbarInUse, setScrollbarInUse] = useState();
  const sampleCardRef = useRef();

  /**
   * Load image from AWS
   * @param {String} image_path path to image file in aws
   */
  const loadImageFromAWS = async (image_path) => {
    setLoadingImage(true);
    if (image_path) {
      const imageBlob = await getFileFromS3(image_path, s3EsvPriv);
      if (imageBlob) {
        const image_src = window.URL.createObjectURL(imageBlob);
        setImageSrc(image_src);
        if (!submissionEditingSample) {
          updateSample(idx, {
            ...sample,
            image_src,
            image_file: new File([imageBlob], getFileNameWithTimestampRemoved(image_path), { type: imageBlob.type }),
          });
        }
      }
    }
    setLoadingImage(false);
  };

  useEffect(() => {
    if (sample.image_path && !sample.image_src) {
      loadImageFromAWS(sample.image_path);
    } else if (sample.image_src) {
      setImageSrc(sample.image_src);
    } else {
      setImageSrc("");
    }
  }, [sample.image_path, sample.image_src]); // eslint-disable-line

  /** when sample is being edited, scroll sample into view */
  useEffect(() => {
    if (sampleEditingIdx === idx && sampleCardRef.current !== null) {
      sampleCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [sampleEditingIdx]); // eslint-disable-line

  /** Enable editing mode only when user is not editing that sample */
  const handleClickEdit = () => {
    if (idx !== sampleEditingIdx) {
      compositeRef.current.clearCompositeData();
      handleEditSample(idx);
      setClearTestSearch(true);
    }
  };

  // Excel uploads can create drafts where both Sample ID and Sample Type character limits are not enforced by the API along with the test list.
  // Therefore, added samples may have errors that users must resolve before submitting.
  const sampleIDCharLimitExceeded = sample.sample_id.length > CHAR_LIMITS.sample_id;
  const sampleTypeCharLimitExceeded = sample.sample_type.length > CHAR_LIMITS.sample_type;
  const sampleTestsMissing = sample.test_list.length === 0;

  return (
    <>
      <motion.div
        ref={sampleCardRef}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.3 } }}
        className={`sample-card sample-card-added 
        ${sampleEditingIdx === idx && "highlighted-editing-sample-card"} 
        ${(sampleIDCharLimitExceeded || sampleTypeCharLimitExceeded || sampleTestsMissing) && "highlighted-sample-card--error"}`}
      >
        {loadingImage && <div className="SampleSubmission__SampleCardLoading" />}
        {showTestView ? (
          <Scrollbar
            trackYProps={scrollbarInUse ? showScrollbarStyle : hideScrollbarStyle}
            onMouseEnter={() => setScrollbarInUse(true)}
            onMouseLeave={() => setScrollbarInUse(false)}
          >
            <div className="sample-card-details sample-test-ul-container">
              <ul className="sample-test-ul">
                {sample.test_list.map((testName, testIdx) => (
                  <li key={testIdx}>{testName}</li>
                ))}
              </ul>
            </div>
          </Scrollbar>
        ) : (
          <>
            {(sample.num_composite_samples > 0) && (
            <div className="added-sample-card-composite-header">
              <div>Composite Sample</div>
              <div>{`${sample.num_composite_samples} Samples`}</div>
            </div>
            )}
            <Scrollbar
              trackYProps={scrollbarInUse ? showScrollbarStyle : hideScrollbarStyle}
              onMouseEnter={() => setScrollbarInUse(true)}
              onMouseLeave={() => setScrollbarInUse(false)}
            >
              <div className="sample-card-details">
                <table>
                  <tbody>
                    <tr>
                      <th>Sample ID</th>
                      <td>{sample.sample_id}</td>
                    </tr>
                    {sampleFields.map((json_field) => {
                      if (
                        sample[json_field] !== ""
                    && sample[json_field] !== undefined
                    && json_field !== "sample_id"
                      ) {
                        return (
                          <tr key={json_field}>
                            <th>{sampleFieldsInfo[json_field].title_field}</th>
                            <td>{sample[json_field]}</td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                    {imageSrc !== "" && (
                    <tr key="image">
                      <th>Image</th>
                      <td>
                        <div className="SampleSubmission__SampleCardImageDiv">
                          <img
                            src={imageSrc}
                            alt="sample"
                            className="SampleSubmission__SampleCardImage"
                          />
                        </div>
                      </td>
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Scrollbar>
          </>
        )}

        <div className="added-sample-delete-number">
          <div className="added-sample-edit-delete-icons">
            <EditIcon
              onClick={handleClickEdit}
              className={`added-sample-edit-icon ${
                sampleEditingIdx !== idx
                  ? "hover-added-sample-edit-icon"
                  : "disable-added-sample-edit-icon"
              }`}
              role="img"
            >
              <title>edit icon</title>
            </EditIcon>
            {!submissionEditingSample && (
            <GarbageCan
              onClick={() => handleDeleteSample(idx)}
              className="added-sample-delete-icon"
            >
              <title>delete icon</title>
            </GarbageCan>
            )}
          </div>
          <div
            className={`added-sample-tests-number ${
              sampleEditingIdx === idx ? "highlighted-added-sample-card" : ""
            }`}
            onClick={() => setShowTestView(!showTestView)}
          >
            <div>
              {showTestView
                ? "Sample Details"
                : `${sample.test_list.length} Tests added`}
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import DataFieldsIcon from "../../../../assets/images/product/template/DataFieldsIcon.svg";
import "./DataFieldsButton.css";

export default function DataFieldsButton(props) {
  const { toggleDataFieldsModal, showDataFieldsModal } = props;
  return (
    <motion.div layout tranform={{ duration: 0.2 }} className={showDataFieldsModal ? "DataFieldsButtonActive" : "DataFieldsButton"} onClick={() => toggleDataFieldsModal()}>
      <img
        className="DataFieldsIcon"
        src={DataFieldsIcon}
        alt="dataFieldsIcon"
      />
      <span className="DataFieldsLabel">Data Fields</span>
    </motion.div>
  );
}

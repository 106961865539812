import React, {
  useState, useRef, useMemo, useEffect,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import "./UpdateTestList.css";
import { getTestList } from "../../../../../actions/sampleSubmission";

import searchIcon from "../../../../../assets/images/sample-submission/searchIcon.svg";
import { ReactComponent as LoadingDots } from "../../../../../assets/images/common/LoadingDots.svg";
import StyledButton from "../../../../Common/UIComponents/StyledButton";
import TestList from "./TestList";
import TestSuggestionList from "./TestSuggestionList";

export default function UpdateTestList(props) {
  const { dataOfSpecEditing, setShowUpdateTestList, handleSaveTestList } = props;
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(""); // used for highlighted text after search
  const [testSuggestions, setTestSuggestions] = useState([]);
  const [removableTests, setRemovableTests] = useState([]);
  const [nonRemovableTests, setNonRemovableTests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const testSuggestionsScrollbar = useRef();
  const addedTestSet = useRef(new Set([]));
  const specEditingData = useRef({});

  /**
   * Call api for getting test suggestions
   */
  const apiGetTestSuggestions = useCallback(async (searchInput) => {
    setIsLoading(true);
    const { success, message, testList } = await getTestList(searchInput, undefined, "product", true);
    if (success) {
      setTestSuggestions(testList);
      setSearched(searchInput);
      if (testSuggestionsScrollbar.current) {
        testSuggestionsScrollbar.current.scrollerElement.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      toast.error(message);
    }
    setIsLoading(false);
  }, []);

  /**
   * Debounce search input
   */
  const debouncedSearch = useMemo(() => debounce(async (searchInput) => {
    await apiGetTestSuggestions(searchInput);
  }, 500), [apiGetTestSuggestions]);

  /**
   * Calls /testsugessions/ api to fetch all the test display names
   * Triggers: on component mount
   */
  useEffect(() => {
    apiGetTestSuggestions("");
  }, [apiGetTestSuggestions]);

  /**
   * Split test specs data into removable and non-removable test names array for TestList
   * Add all the test names to addedTestSet for maintaining non-duplicate values and disable state checks
   * Add test spec data to specEditingData object for efficient spec data retrival when saving
   * Triggers: on component mount
   */
  useEffect(() => {
    addedTestSet.current.clear();
    const testData = dataOfSpecEditing.reduce((acc, spec) => {
      if (spec.removable) acc.removableTests.push(spec.test);
      else acc.nonRemovableTests.push(spec.test);
      // Test name set
      addedTestSet.current.add(spec.test.toLowerCase());
      // Spec data
      specEditingData.current[spec.test] = spec;
      return acc;
    }, { removableTests: [], nonRemovableTests: [] });
    setRemovableTests(testData.removableTests);
    setNonRemovableTests(testData.nonRemovableTests);
  }, [dataOfSpecEditing]);

  /**
   * OnChange handler for search box and debounced search
   */
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  /**
   * Key handler for immediate search
   * @param {Event} e event
   */
  const handleHitEnter = (e) => {
    if (e.key === "Enter") {
      apiGetTestSuggestions(e.target.value);
    }
  };

  /**
   * Handle adding new test to list
   * @param {String} testName test name
   */
  const handleAddNewTest = (testName) => {
    setRemovableTests((state) => [...state, testName]);
    addedTestSet.current.add(testName.toLowerCase());
  };

  /**
   * Handler to remove removable tests
   * @param {String} testName Test name
   */
  const handleRemoveAddedTest = (testName) => {
    const index = removableTests.findIndex((item) => item.toLowerCase() === testName.toLowerCase());
    if (index === -1) return;
    const updatedItems = [...removableTests];
    updatedItems.splice(index, 1);
    addedTestSet.current.delete(testName.toLowerCase());
    setRemovableTests(updatedItems);
  };

  /**
   * Handler for Clear All button
   */
  const handleClearAll = () => {
    setRemovableTests([]);
    addedTestSet.current.clear();
  };

  /**
   * Get updated spec editing data
   * @returns Array of spec data
   */
  const getSpecEditingData = () => {
    const existingTests = [];
    const newTests = [];
    [...removableTests, ...nonRemovableTests].forEach((test) => {
      if (specEditingData.current[test]) {
        existingTests.push(specEditingData.current[test]);
      } else {
        newTests.push({
          test, removable: true, specs: ["", "", ""], test_type: "",
        });
      }
    });
    return [...existingTests, ...newTests];
  };

  /**
   * Handler for save button
   */
  const handleSave = async () => {
    const updatedSpecData = getSpecEditingData();
    setIsSaveLoading(true);
    await handleSaveTestList(updatedSpecData);
    setIsSaveLoading(false);
    setShowUpdateTestList(false);
  };

  const loading = isLoading || isSaveLoading;
  const disableSave = [...removableTests, ...nonRemovableTests].length === 0 || isLoading;

  return (
    <div className="UpdateTestList__Container">
      <div className="UpdateTestList__TestListContainer">
        <div className="UpdateTestList__Header">
          <h3 className="UpdateTestList__SectionTitle">Select to add test</h3>
          <div className="UpdateTestList__SearchBox">
            <img alt="search icon" src={searchIcon} />
            <input
              placeholder="Search in Test Directory"
              type="text"
              onChange={handleChangeSearch}
              value={search}
              onKeyUp={handleHitEnter}
            />
          </div>
        </div>
        <TestSuggestionList
          testSuggestionsScrollbar={testSuggestionsScrollbar}
          testSuggestions={testSuggestions}
          addedTestSet={addedTestSet}
          searched={searched}
          isLoading={isLoading}
          loading={loading}
          handleAddNewTest={handleAddNewTest}
        />
      </div>
      <div className="UpdateTestList__SelectedListContainer">
        <div className="UpdateTestList__SelectedListBox">
          <div className="UpdateTestList__Header">
            <h3 className="UpdateTestList__SectionTitle">Test list</h3>
            <button type="button" className="UpdateTestList__ClearButton" disabled={removableTests.length === 0 || loading} onClick={handleClearAll}>
              Clear All
            </button>
          </div>
          <TestList
            removableTests={removableTests}
            nonRemovableTests={nonRemovableTests}
            loading={loading}
            handleRemoveAddedTest={handleRemoveAddedTest}
          />
        </div>
        <div className="UpdateTestList__ButtonPanel">
          <StyledButton type="primary" ghost onClick={() => setShowUpdateTestList(false)} disabled={loading}>
            Cancel
          </StyledButton>
          <StyledButton
            type="primary"
            className="UpdateTestList__SaveButton"
            onClick={loading ? null : handleSave}
            disabled={disableSave}
          >
            Save
            {isSaveLoading && <LoadingDots width={28} height={28} fill="#ffffff" />}
          </StyledButton>
        </div>
      </div>
    </div>
  );
}

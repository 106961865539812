import axios from "axios";
import { AES } from "crypto-js";
import { getUserCredentials } from "../utils/common";
import { API_URL, AES_128_BIT_KEY } from "../utils/Constant";

/**
 * Get company name
 * @param {String} customerID
 * @return  {Object} {success, contact_name, email, company, address, city, zip, state, telephone}
 */
export const getCompanyName = async (customerID) => {
  try {
    const resp = await axios.get(`${API_URL}/customerinfo/`, {
      params: { customer_id: customerID },
    });

    const result = {
      success: resp.data.message === "Success",
      message: resp.data.message,
      company_name: resp.data.result.company,
    };

    return result;
  } catch (e) {
    // console.log("error from getCompanyName", e);
    // getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/** Signup for admin, or member
 * @param {Object} payload
 * @returns {Object} {success, message}
 */
export const signupAdminOrMember = async (payload) => {
  try {
    const resp = await axios.post(`${API_URL}/usersignup/`, { ...payload });

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
    };
  } catch (e) {
    const message = e.response?.data?.message || "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Send invite to admin
 * @param  {Object} payload
 * @return  {Object} {success, message}
 */
export const inviteAdmin = async (payload) => {
  try {
    const resp = await axios.post(`${API_URL}/adminsignupinvite/`, {
      ...payload,
    });

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Send invite to member
 * @param  {Object} payload { name, email, phone }
 * @return  {Object} {success, message}
 */
export const inviteMember = async (payload) => {
  try {
    const user = getUserCredentials();
    const companyName = encodeURIComponent(user.company_name);
    const username = encodeURIComponent(payload.name);
    const useremail = encodeURIComponent(payload.email);
    const phone = encodeURIComponent(payload.phone);
    const queryStrings = `company_name=${companyName}&user_name=${username}&email_address=${useremail}&phone=${phone}`;
    const encryptedQueryStrings = AES.encrypt(queryStrings, AES_128_BIT_KEY).toString();
    const link = `${window.location.origin}/signup/member?${encryptedQueryStrings}`;

    const params = {
      name: payload.name,
      email: payload.email,
      contact: payload.phone,
      user_title: payload.role,
      link,
    };

    const response = await axios.post(`${API_URL}/invitemember/`, { ...params });
    return {
      success: response.data.message === "Success",
      message: response.data.message,
    };
  } catch (e) {
    const message = e.response?.data?.message || "Unable to add member.";
    return { success: false, message };
  }
};

import React, { Component } from "react";
// import moment from "moment";
// import Scrollbar from "react-scrollbars-custom";
import OutsideClickHandler from "react-outside-click-handler";
import searchIconBlue from "../../../../../../assets/images/environment/docSearchIconBlue.png";
import closeIcon from "../../../../../../assets/images/environment/closeDarkblue.png";
import "./EnvSearch.css";
import EnvCalendar from "./EnvCalendar";
import FilterButtons from "./FilterButtons";

export default class EnvSearch extends Component {
  constructor(props) {
    super(props);
    const { searchResultSelected } = this.props;
    // console.log("searchresultselected", searchResultSelected);
    this.state = {
      searchValue: searchResultSelected || "",
      hideFilterButton: false,
    };
  }

  componentDidMount() {
    // console.log("mount");
    const { searchResultSelected } = this.props;
    this.setState({
      searchValue: searchResultSelected || "",
      hideFilterButton: !!searchResultSelected,
    });
  }

  clearSearch = () => {
    // console.log("clearing search");
    this.setState({
      searchValue: "",
      hideFilterButton: false,
    });
  }

  onChange = (e) => {
    const { handleStylesWhenSearching } = this.props;
    const { searchValue: prevSearchValue } = this.state;
    const searchValue = e.target.value;
    if (prevSearchValue && !searchValue) {
      this.setState({
        hideFilterButton: false,
      });
      handleStylesWhenSearching(false, true);
    }
    this.setState({ searchValue });
  }

  onKeyDown = async (e) => {
    // console.log("key down", e.key);
    const { searchValue } = this.state;
    const { handleSearch, handleStylesWhenSearching } = this.props;
    const value = searchValue.trim();
    if (e.key === "Enter" && value) {
      e.preventDefault();
      // this.props.handleSearchChange(e);
      await handleSearch(value);
      this.setState({
        hideFilterButton: false,
      });
      handleStylesWhenSearching(false);
    } else if (e.key === "Enter" && !value) {
      this.setState({
        searchValue: "",
        hideFilterButton: false,
      });
      handleStylesWhenSearching(false, true);
    } else {
      this.setState({ hideFilterButton: true });
      handleStylesWhenSearching(true, false);
    }
  }

  handleSearch = async (e) => {
    // console.log("handle search");
    e.preventDefault();
    e.stopPropagation();
    const { searchValue } = this.state;
    const { handleSearch, handleStylesWhenSearching } = this.props;
    const value = searchValue.trim();
    await handleSearch(value);
    this.setState({
      hideFilterButton: false,
    });
    handleStylesWhenSearching(false);
  }

  handleClearSearch = (e) => {
    // console.log("handle clear search");
    const { handleStylesWhenSearching } = this.props;
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      searchValue: "",
      hideFilterButton: false,
    });
    handleStylesWhenSearching(false, true);
  }

  handleInputClick = () => {
    const { handleStylesWhenSearching } = this.props;
    this.setState({ hideFilterButton: true });
    handleStylesWhenSearching(true, false);
  }

  handleClickAway = () => {
    const { handleStylesWhenSearching } = this.props;
    this.setState({ hideFilterButton: false });
    handleStylesWhenSearching(false, false);
  }

  // handleOptionClick = (option) => {
  //   const { optionClicked } = this.state;
  //   if (optionClicked !== option) {
  //     this.setState({ optionClicked: option });
  //   } else {
  //     this.setState({ optionClicked: "" });
  //   }
  // }

  // handleClickSearchResult = (pin) => {
  //   const { handleClickSearchResult } = this.props;
  //   const { swab_number, zone, section } = pin;
  //   this.setState({ searchRes: [], searchValue: `Swab ID: ${swab_number} zone: ${zone} section: ${section}` });
  //   handleClickSearchResult(pin);
  // }

  render() {
    const {
      searchValue,
      hideFilterButton,
    } = this.state;

    const {
      filterSelected,
      handleSelectFilter,
      isAddingNewSwab,
      isClickingQuadrant,
      showReportView,
      handleFilterByDate,
      getCalendarDates,
      envCalendarRef,
    } = this.props;

    return (
      <div className={`EnvSearch ${(isAddingNewSwab || isClickingQuadrant || showReportView) ? "EnvSearch--grayout" : ""}`}>
        <div className="search-calendar-container">
          <OutsideClickHandler onOutsideClick={hideFilterButton ? this.handleClickAway : () => {}}>
            <div className={`search-container ${hideFilterButton ? "searching-border" : ""}`}>
              <input
                className="searchInput"
                placeholder="Search"
                onChange={this.onChange}
                value={searchValue}
                onKeyDown={this.onKeyDown}
                onClick={() => this.handleInputClick(true, false)}
              />
              {hideFilterButton && (
                <img
                  alt="closeIcon"
                  src={closeIcon}
                  onClick={this.handleClearSearch}
                />
              )}
              <div className="searchButton-container">
                <div>
                  <img
                    className="searchButton"
                    alt="searchIcon"
                    src={searchIconBlue}
                    onClick={this.handleSearch}
                  />
                </div>
              </div>
            </div>
          </OutsideClickHandler>
          <EnvCalendar
            ref={envCalendarRef}
            handleFilterByDate={handleFilterByDate}
            getCalendarDates={getCalendarDates}
            hideFilterButton={hideFilterButton}
          />
          {/* {displayOption && (
            <div className="searchOption">
              <button type="button" className={optionClicked === "swab_number" ? "selected" : ""} onClick={() => this.handleOptionClick("swab_number")}>Swab ID</button>
              <button type="button" className={optionClicked === "zone" ? "selected" : ""} onClick={() => this.handleOptionClick("zone")}>Area ID</button>
              <button type="button" className={optionClicked === "section" ? "selected" : ""} onClick={() => this.handleOptionClick("section")}>Swab Area</button>
            </div>
            )} */}
          {/* {searchRes && searchRes.length > 3 && (
            <div className="searchResult">
              <Scrollbar style={{ height: "155px" }}>
                {searchRes.map((pin, index) => (
                  <div
                    className="searchResultItemContainer"
                    key={index}
                    onClick={() => this.handleClickSearchResult(pin)}
                  >
                    <span className={optionSearched === "swab_number" ? "selected" : ""}>
                      Swab ID:
                      {pin.swab_number}
                    </span>
                    <span className={optionSearched === "zone" ? "selected" : ""}>
                      {" "}
                      Area ID:
                      {pin.zone}
                    </span>
                    <span className={optionSearched === "section" ? "selected" : ""}>
                      {" "}
                      Swab Area:
                      {pin.section}
                    </span>
                    <label>{moment(pin.swab_timestamp).format("DD MMM YYYY")}</label>
                  </div>
                ))}
              </Scrollbar>
            </div>
            )} */}
          {/* {displayOption && searchRes && searchRes.length <= 3 && searchRes.length > 0 && (
            <div className="searchResult">
              {searchRes.map((pin, index) => (
                <div
                  className="searchResultItemContainer"
                  key={index}
                  onClick={() => this.handleClickSearchResult(pin)}
                >
                  <span className={optionSearched === "swab_number" ? "selected" : ""}>
                    Swab ID:
                    {pin.swab_number}
                  </span>
                  <span className={optionSearched === "zone" ? "selected" : ""}>
                    {" "}
                    Area ID:
                    {pin.zone}
                  </span>
                  <span className={optionSearched === "section" ? "selected" : ""}>
                    {" "}
                    Swab Area:
                    {pin.section}
                  </span>
                  <label>{moment(pin.swab_timestamp).format("DD MMM YYYY")}</label>
                </div>
              ))}
            </div>
            )} */}
        </div>
        <FilterButtons
          handleSelectFilter={hideFilterButton ? () => null : handleSelectFilter}
          greyout={hideFilterButton}
          filterSelected={filterSelected}
        />
      </div>

    );
  }
}

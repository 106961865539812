/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { getMandatoryUserData } from "../utils/common";

export const axiosInterceptor = () => {
  axios.interceptors.request.use((request) => {
    const { user_payload, company_name, company_domain } = getMandatoryUserData();

    if (user_payload && company_name) {
      /* If the url directly contains the mandatory fields, remove them
      (they will be added in the params or data obj of the request) */
      const urlArr = request.url.split("?");
      if (urlArr.length > 1 && urlArr[0].length > 0) {
        const queryParams = new URLSearchParams(urlArr[1]);
        queryParams.delete("user_payload");
        queryParams.delete("company_name");
        queryParams.delete("company_domain");
        const newQueryParamsString = queryParams.toString();
        request.url = `${urlArr[0]}?${newQueryParamsString}`;
      }
      /* Replace fields in params or data obj based on API method */
      const key = request.method === "get" ? "params" : "data";
      if (!request[key]) {
        request[key] = {};
      }

      if (request[key].disableInterceptor) { // don't add to payload if disableInterceptor is true
        delete request[key].disableInterceptor;
      } else {
        request[key].user_payload = user_payload;
        request[key].company_name = company_name;
        request[key].company_domain = company_domain;
      }
    }
    return request;
  }, (error) => {
    console.log("request error", error);
    /** Navigate to maintenance page if request error */
    window.location.pathname = "/maintenance";
  });

  axios.interceptors.response.use((response) => response,
    (error) => {
      console.log("response error", error);
      const statusCode = error.response?.status;
      const loggedIn = !!getMandatoryUserData().user_payload;

      /** Navigate to auth-failed page if status 403 */
      if (loggedIn && statusCode === 403) {
        window.location.pathname = "/auth-failed";
      }

      throw error;
    });
};

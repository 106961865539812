import React, { useState } from "react";
import { Radio } from "antd";
import SortIconBlue from "../../../../../../assets/images/product/template/SortIconBlue.png";
import SortIcon from "../../../../../../assets/images/product/template/SortIcon.png";
import CloseIcon from "../../../../../../assets/images/product/template/CloseIconBlue.png";

export default function DataFieldSort(props) {
  const {
    activeSort,
    showSorting,
    toggleShowSorting,
    handleApplySort,
    isCustomizeCoa,
  } = props;

  const [sortValue, setSortValue] = useState(activeSort);
  const sortByArray = [
    "by Alphabet",
    "by Type",
    // "by Most common",
  ];

  const handleChangeSort = (sortSelected) => {
    if (sortSelected === sortValue) {
      setSortValue("");
    } else {
      setSortValue(sortSelected);
    }
  };

  const handleCloseSort = () => {
    setSortValue(activeSort);
    toggleShowSorting();
  };

  const getSortClassName = () => {
    if (isCustomizeCoa) {
      return "templateBuilderComponentsDataFieldsHeader marginless";
    }
    if (activeSort) {
      return "templateBuilderComponentsDataFieldsHeader compact";
    }
    return "templateBuilderComponentsDataFieldsHeader";
  };

  if (showSorting) {
    return (
      <div className="sortingDiv">
        <div className="sortingDivHeader">
          <div className="sortIconContainerSecondary">
            <img src={SortIconBlue} alt="SortIcon" className="templateBuilderComponentsSortIcon" />
            <span className="sortSpan"> Sort </span>
          </div>
          <img src={CloseIcon} alt="closeIcon" onClick={handleCloseSort} className="closeIcon" />
        </div>
        <div className="radioButtonContainer">
          <Radio.Group className="radioButtonContainer" onChange={(elem) => handleChangeSort(elem.target.value)} value={sortValue}>
            {sortByArray.map((elem, i) => (
              <Radio key={i} value={elem}>{elem}</Radio>
            ))}
          </Radio.Group>
        </div>
        <div className="applyButtonContainer">
          <div
            className="applyButton"
            style={{ opacity: sortValue ? 1 : 0.3 }}
            onClick={() => handleApplySort(sortValue)}
          >
            Apply
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={getSortClassName()}>
      <span className="templateBuilderComponentsDataFieldsSpan">{activeSort ? "" : "All data fields"}</span>
      <div className="sortIconContainer" onClick={toggleShowSorting}>
        <img src={SortIcon} alt="SortIcon" className="templateBuilderComponentsSortIcon" />
        <span className="sortSpan greyTextColor">{activeSort ? `( ${activeSort} )` : "Sort"}</span>
      </div>
    </div>
  );
}

import React from "react";
import Modal from "antd/es/modal/Modal";
import "./COADeleteModal.css";

export default function COADeleteModal(props) {
  const { toggleShowDeleteModal, apiDeleteCoa } = props;
  return (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      onCancel={toggleShowDeleteModal}
      width="368px"
      height="200px"
      wrapClassName="COADeleteModal"
      className="COADeleteModal"
    >

      <div className="deleteModal">
        <div className="deleteModalSpanContainer">
          <span className="deleteModalHeader"> Delete report </span>
          <span className="deleteModalBody"> Are you sure you want to delete this report? </span>
        </div>
        <div className="deleteModalButtonContainer">
          <div className="deleteModalButton deleteModalCancel" onClick={toggleShowDeleteModal}> Cancel </div>
          <div className="deleteModalButton deleteModalDelete" onClick={apiDeleteCoa}> Delete </div>
        </div>

      </div>
    </Modal>
  );
}

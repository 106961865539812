const initialValues = {
  fields_form: {
    product_info: {
      sample_type: "Sample Type",
      date: "Received Date",
      editable_fields: [
        { json_field: "Item", title_field: "Item" },
        { json_field: "Lot", title_field: "Lot" },
        { json_field: "Description", title_field: "Description" },
      ],
      sample_supply: "Yes",
      product_link: [],
    },
    environment_info: {
      sample_type: "Sample Type",
      date: "Received Date",
      swab_number: "Swab Number",
      zone: "Zone",
      section: "Section",
      editable_fields: [],
      env_sample_supply: "Yes",
      environment_link: [],
      environment_supplies_needed: "",
    },
  },
};

export default initialValues;

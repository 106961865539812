import React, { useEffect, useState } from "react";
import InviteForm from "./InviteForm";
import StatusPage from "../StatusPage";

export default function InviteFormController({ history }) {
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  if (path === "/invite-customer") {
    return <InviteForm history={history} />;
  } if (path.includes("success")) {
    return (
      <StatusPage
        status="success"
        title="Invite Successful"
        history={history}
        showInviteButton
      />
    );
  }
  return null;
}

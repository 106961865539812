import React, { useState } from "react";
import PlusIcon from "../../../../../assets/images/product/template/PlusWhite.png";
import "./DataFieldsAdd.css";

export default function DataFieldsAdd(props) {
  const {
    showDeleteModal,
    showEditModal,
    apiAddDataField,
  } = props;

  const [inputVal, setInputVal] = useState("");
  const handleHitEnter = async (e) => {
    if (e.key === "Enter") {
      const addSuccess = await apiAddDataField(inputVal.trim());
      if (addSuccess) {
        setInputVal("");
      }
    }
  };

  const handleAddOnClick = async () => {
    const addSuccess = await apiAddDataField(inputVal.trim());
    if (addSuccess) {
      setInputVal("");
    }
  };

  return (
    <div className={`"dataFieldsAdd_formSection" ${(showDeleteModal || showEditModal) ? "Disabled" : ""}`}>
      <div className="dataFieldsAdd_newFieldForm">
        <div className="dataFields_formLabel">Create New Fields</div>
        <input
          className="dataFieldsAdd_formInput"
          onKeyUp={(e) => handleHitEnter(e)}
          type="text"
          placeholder="Enter title"
          name="datafield"
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
        />
        <div className={inputVal.trim() ? "dataFieldsAdd_addButton" : "dataFieldsAdd_addButtonDisabled"} onClick={() => handleAddOnClick()}>
          <img src={PlusIcon} alt="PlusIconSmall" className="dataFieldsAdd_addButtonIcon" />
          <span>Add</span>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { softHyphenate } from "./pdfUtils";

// let prev = 0;
export default function TableRow(props) {
  const styles = StyleSheet.create({
    row: {
      display: "flex",
      flexDirection: "row",
      color: "#505050",
      textAlign: "center",
      padding: "0 75 0 75",
      borderLeft: "1 solid #E1E1E1",
      borderRight: "1 solid #E1E1E1",
    },
    rowItem: {
      minHeight: "40",
      width: "25%", // if test method is there
      // width: "33%",
      padding: "14 4 14 4",
      backgroundColor: "#F2F6FA",
      borderRadius: "4px",
    },
    gap: {
      marginRight: "6",
    },
    rowGap: {
      // marginBottom: "6",
      paddingBottom: "6",
    },
    rowMarginTop: {
      marginTop: "40",
    },
    rowText: {
      display: "flex",
      flexDirection: "row",
      flexGrow: "1",
      fontFamily: "Roboto-400",
      color: "#505050",
      fontSize: "10",
      maxWidth: "99",
    },
  });

  const { spec, isLast } = props;
  if (spec.test === "Test") {
    return null;
  }

  // function handleNewPage(pageNumber, totalPages) {
  //   if (totalPages) {
  //     if (prev !== pageNumber) {
  //       prev = pageNumber;
  //       return (
  //         <View style={{ backgroundColor: "red" }}>
  //           <View style={styles.rowItem}><Text>{spec.test}</Text></View>
  //           <View style={styles.rowItem}><Text>{spec.result}</Text></View>
  //           <View style={styles.rowItem}><Text>{spec.report_result}</Text></View>
  //           <View style={styles.rowItem}><Text>{spec.test_spec_flag}</Text></View>
  //         </View>
  //         // <Text>Hello </Text>
  //       );
  //     }

  //     return (
  //       <View style={styles.row}>
  //         <View style={styles.rowItem}><Text>{spec.test}</Text></View>
  //         <View style={styles.rowItem}><Text>{spec.result}</Text></View>
  //         <View style={styles.rowItem}><Text>{spec.report_result}</Text></View>
  //         <View style={styles.rowItem}><Text>{spec.test_spec_flag}</Text></View>
  //       </View>
  //     );
  //   }
  //   return null;
  // }

  // function checkRow(pageNumber, totalPages) {
  //   console.log(pageNumber, totalPages);
  // }

  /** Bottom border: 14 (padding) + 1 (border), only apply to row if it is the last in the table */
  const minPresence = isLast ? 15 : undefined;

  return (
    <View wrap={false} style={[styles.row, styles.rowGap]} minPresenceAhead={minPresence}>
      <View style={[styles.rowItem, styles.gap]}><Text style={styles.rowText}>{softHyphenate(spec.test_method)}</Text></View>
      <View style={[styles.rowItem, styles.gap]}><Text style={styles.rowText}>{softHyphenate(spec.test)}</Text></View>
      <View style={[styles.rowItem, styles.gap]}><Text style={styles.rowText}>{softHyphenate(spec.test_spec)}</Text></View>
      <View style={styles.rowItem}><Text style={styles.rowText}>{softHyphenate(spec.test_result)}</Text></View>
    </View>
  );
}

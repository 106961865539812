import React, { forwardRef } from "react";
import { colorFromSpecFlag, compareAverageValueToSpec } from "../../../Helper";

const GraphToolTip = forwardRef(({
  payload, selectedGraph, hoveredItem, selectedSpecData, groupedScatterPoints,
}, ref) => {
  // Function to render all of the values of the scatter points
  // Loops through the data and matches the test and product
  // Loops through the corresponding reports -- and changes the color depending on spec flag
  const renderAllValues = () => {
    if (payload !== null && payload.length !== 0) {
      const data = payload[0]?.payload?.result;

      if (data === undefined) return null;

      return data.map((item) => {
        if (item[selectedGraph.test] !== undefined && item.name === selectedGraph.product?.product_id) {
          return item.allReports.map((value, index) => {
            let color = "";

            if (value.specs_flag === "1") color = "#00BF71";
            else if (value.specs_flag === "2") color = "#E63559";
            else color = "#19305A";

            return (
              <div className="analyticsGraphTooltipMultipleValuesContainer" key={index}>
                <span className="analyticsGraphTooltipText" style={{ color: "#6C7E8E" }}>{value.time}</span>
                <span className="analyticsGraphTooltipText" style={{ color }}>{value.test_result}</span>
              </div>
            );
          });
        }

        return null;
      });
    }

    return null;
  };

  // Function to render the average value with the proper color
  const renderColoredAverageSpec = (specValueRaw, specValue) => {
    const { testType, bounds } = selectedSpecData;

    return (
      <span className="analyticsGraphTooltipText" style={{ color: compareAverageValueToSpec(specValue, testType, bounds) }}>
        {specValueRaw}
      </span>
    );
  };

  // Function to render the average value
  // Loops through the payload and checks item.stroke or item.color -- only one should have a color that is not grayed out
  const renderAverage = () => {
    // const grayedOutColor = "#EAF0F5";
    // let accentColor = "";
    if (payload !== null && payload.length !== 0) {
      // payload.forEach((item) => {
      //   if (item.stroke !== undefined && item.stroke !== grayedOutColor) {
      //     accentColor = item.stroke;
      //   }

      //   if (item.color !== undefined && item.color !== grayedOutColor) {
      //     accentColor = item.color;
      //   }
      // });

      const data = payload[0]?.payload?.result;

      if (data === undefined) return null;

      return data?.map((item, index) => {
        if (item[selectedGraph.test] !== undefined && item.name === selectedGraph.product?.product_id) {
          return (
            <React.Fragment key={index}>
              <span className="analyticsGraphTooltipText">Average Result Value: </span>
              {renderColoredAverageSpec(item[`${selectedGraph.test}|raw`], item[`${selectedGraph.test}`])}
            </React.Fragment>
          );
        }

        return null;
      });
    }

    return null;
  };

  const renderNumberOfSamples = () => {
    let counter = 0;
    if (payload !== null && payload.length !== 0) {
      const data = payload[0]?.payload?.result;

      if (data === undefined) return null;

      data.forEach((item) => {
        if (item[selectedGraph.test] !== undefined && item.name === selectedGraph.product?.product_id) {
          counter = item.allReports.length;

          if (counter <= 9) counter = `0${counter}`;
        }
      });
    }

    return counter;
  };

  if (hoveredItem === null) return null;
  if (payload[0]?.payload?.result === undefined) return null;

  if (hoveredItem.type === "scatter" && groupedScatterPoints !== null) {
    const specFlag = hoveredItem.data.specsFlag;
    const toolTipValueTextColor = specFlag === null ? colorFromSpecFlag[0] : colorFromSpecFlag[specFlag];
    return (
      <div className="analyticsGraphTooltipContainer" ref={ref}>
        {/* Display tooltip WITHOUT grouping the data points */}
        <div className="analyticsGraphTooltipScatterContainer" ref={ref}>
          <div className="analyticsGraphTooltipText" style={{ color: "#6C7E8E" }}>{hoveredItem.data.id}</div>
          <div className="analyticsGraphTooltipText" style={{ color: toolTipValueTextColor }}>{hoveredItem.data.rawResult}</div>

          {/* Display tooltip WITH grouping the data points */}
          {/* <div className="analyticsGraphTooltipGroupedContainer">
            {groupedScatterPoints[hoveredItem.data.date][hoveredItem.data.rawResult].map((item, index) => {
              const toolTipData = item.split("|");
              return (
                <div className="analyticsGraphSingleGroupContainer" key={index}>
                  <div
                    className="analyticsGraphTooltipText"
                    style={{ 
                      color: "#6C7E8E",
                      fontFamily: toolTipData[1].toString() === hoveredItem.data.rawResult.toString() ? "Roboto Bold" : "Roboto"
                    }}
                  >
                    {toolTipData[0]}
                  </div>

                  <div
                    className="analyticsGraphTooltipText"
                    style={{
                      color: toolTipValueTextColor,
                      fontFamily: toolTipData[1].toString() === hoveredItem.data.rawResult.toString() ? "Roboto Bold" : "Roboto"
                    }}
                  >
                    {toolTipData[1]}
                  </div>
                </div>
              )
            })}
          </div> */}
        </div>
      </div>
    );
  }

  if (hoveredItem.type === "average") {
    return (
      <div className="analyticsGraphTooltipContainer" ref={ref}>
        {renderAllValues()}

        <div className="analyticsHorizontalLine" />

        <div className="analyticsGraphTooltipAverageValueContainer">
          {renderAverage()}
        </div>
        {/* <div className="analyticsGraphTooltipProductSelectedContainer">
          <span className="analyticsGraphTooltipText" style={{ color: "#111A24" }}>{selectedGraph.product}</span>
        </div> */}

        <div className="analyticsGraphTooltipProductSelectedContainer">
          <span className="analyticsGraphTooltipText" style={{ color: "#111A24" }}>
            Total no of Samples:
          </span>

          <span className="analyticsGraphTooltipText" style={{ color: "#111A24", fontFamily: "Roboto Medium" }}>
            {renderNumberOfSamples()}
          </span>
        </div>
      </div>
    );
  }

  return null;
  // return (
  //   <div className="analyticsGraphTooltipContainer" ref={ref}>
  //     {renderAllValues()}

  //     <div className="analyticsGraphTooltipAverageValueContainer">
  //       {renderAverage()}
  //     </div>

  //     {/* <div className="analyticsGraphTooltipProductSelectedContainer">
  //       <span className="analyticsGraphTooltipText" style={{ color: "#111A24" }}>{selectedGraph.product}</span>
  //     </div> */}

  //     <div className="analyticsGraphTooltipProductSelectedContainer">
  //       <span className="analyticsGraphTooltipText" style={{ color: "#111A24" }}>
  //         Total no of Samples:
  //       </span>

  //       <span className="analyticsGraphTooltipText" style={{ color: "#111A24" }}>
  //         {renderNumberOfSamples()}
  //       </span>
  //     </div>
  //   </div>
  // );
});

export default GraphToolTip;

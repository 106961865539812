import React from "react";

import { Tooltip } from "antd";
import moment from "moment";

const EnvAnalyticsTableRow = ({ row, headers }) => {
  const progressWidth = row.ratio;
  const inSpec = row.in_spec_num;
  const outOfSpec = row.out_of_spec_num;
  const noSpec = row.no_spec_num;
  const total = row.total;

  return (
    <tr className="EnvAnalyticsTableRow__TableRow">
      {headers.map((header, index) => {
        let cellContent;

        if (header === "Status") {
          cellContent = (
            // Tooltip applied to the entire progress bar container
            <Tooltip
              title={(
                <div className="EnvAnalyticsTableRow__CustomTooltip">
                  <p className="EnvAnalyticsTableRow__TooltipTotal">
                    {`Total Sample: ${total}`}
                  </p>
                  <p className="EnvAnalyticsTableRow__TooltipOutOfSpec">
                    {`Out of Spec: ${outOfSpec}`}
                  </p>
                  <p className="EnvAnalyticsTableRow__TooltipInSpec">
                    {`In Spec: ${inSpec}`}
                  </p>
                  { noSpec > 0 && (
                  <p className="EnvAnalyticsTableRow__TooltipNoSpec">
                    {`No Spec: ${noSpec}`}
                  </p>
                  )}
                </div>
              )}
              placement="topLeft"
              overlayClassName="EnvAnalyticsTableRow__CustomTooltipOverlay"
            >
              <div className="EnvAnalyticsTableRow__ProgressBar">
                {/* Always visible progress bar */}
                <div
                  className="EnvAnalyticsTableRow__ProgressBarRed"
                  style={{
                    width: `${progressWidth}%`,
                    backgroundColor:
                      inSpec === 0 && outOfSpec === 0 ? "#C4D2DF" : "#e11903",
                  }}
                />
                <div
                  className="EnvAnalyticsTableRow__ProgressBarGreen"
                  style={{
                    width: `${100 - progressWidth}%`,
                    backgroundColor:
                      inSpec === 0 && outOfSpec === 0 ? "#C4D2DF" : "#1fc04a",
                  }}
                />
              </div>
            </Tooltip>
          );
        } else if (header === "Out of Spec Samples") {
          cellContent = (
            <span
              className={`EnvAnalyticsTableRow__Row ${
                row.out_of_spec_num === 0 && row.in_spec_num === 0
                  ? "EnvAnalyticsTableRow__Row--NoRatio"
                  : ""
              }`}
            >
              {row.out_of_spec_num === 0 && row.in_spec_num === 0
                ? "-"
                : `${((row.out_of_spec_num / total) * 100).toFixed(0)}%`}
            </span>
          );
        } else if (header === "Swab ID") {
          cellContent = (
            <div className="EnvAnalyticsTableRow__SwabID">
              {row.swab}
            </div>
          );
        } else if (header === "Last submitted on") {
          cellContent = (
            <span className="EnvAnalyticsTableRow__TextRow">
              {moment(row.last_submitted).format("MMM DD YYYY")}
            </span>
          );
        } else if (header === "No of days since last submission") {
          cellContent = (
            <span className="EnvAnalyticsTableRow__TextRow">
              {row.day_till_now}
              {" "}
              Days
            </span>
          );
        } else {
          cellContent = row[header];
        }

        return (
          <td
            key={index}
            className={
              header !== "Swab ID"
                ? "EnvAnalyticsTableRow__TableData"
                : ""
            }
          >
            {cellContent}
          </td>
        );
      })}
    </tr>
  );
};

export default EnvAnalyticsTableRow;

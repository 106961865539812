import React, { useState } from "react";
import { Select } from "antd";
import "./CustomMuiSelect.css";

function CustomMuiSelect({
  options, // ["option1", "option2", "option3"] or [["option1", "value1"], ["option2", "value2"], "option3, value3"]
  multiselect,
  disable,
  innerRef,
  handleOptionSelect,
  maxHeight,
}) {
  const useOptionAsValue = options.length && (typeof options[0] === "string");
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedIdx, setSelectedIdx] = useState(new Set());

  const handleSelect = (item, idx) => {
    const option = useOptionAsValue ? item : item[0];
    const value = useOptionAsValue ? item : item[1];
    if (multiselect) {
      if (selectedIdx.has(idx)) {
        delete selectedItems[option];
        selectedIdx.delete(idx);
      } else {
        selectedItems[option] = value;
        selectedIdx.add(idx);
        if (handleOptionSelect) {
          handleOptionSelect(option, value, idx);
        }
      }
      setSelectedItems({ ...selectedItems });
      setSelectedIdx(new Set([...selectedIdx]));
    } else {
      setSelectedItems({ [option]: value });
      setSelectedIdx(new Set([idx]));
      if (handleOptionSelect) {
        handleOptionSelect(option, value, idx);
      }
    }
  };

  const getSelectValue = (selected) => {
    if (selectedIdx.size === 0) {
      return multiselect ? [] : "";
    }
    return multiselect ? Array.from(Object.values(selected))
      : Array.from(Object.values(selected))[0];
  };

  const MenuProps = {
    disableAutoFocusItem: true,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  if (maxHeight) {
    MenuProps.PaperProps = { style: { maxHeight: typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight } };
  }

  return (
    <div
      className="customSelectContainer"
    >
      <select
        value={getSelectValue(selectedItems)}
        ref={innerRef}
        multiple={multiselect}
        readOnly
        aria-hidden
      >
        <option className="NativeSelect__Option--disabled" disabled value="">
          {" "}
        </option>
        {options.map((item, idx) => (
          <option
            key={idx}
            value={useOptionAsValue ? item : item[1]}
            readOnly
          >
            {useOptionAsValue ? item : item[0]}
          </option>
        ))}
      </select>
      <Select
        mode={multiselect ? "multiple" : "single"}
        showSearch={false}
        autoClearSearchValue={false}
        className="customSelectInput"
        popupClassName={multiselect ? "customSelectInput__Menu" : ""}
        disabled={disable}
        onSelect={(_, data) => handleSelect(options[data.idx], data.idx)}
        options={options.map((item, index) => ({ value: useOptionAsValue ? item : item[0], idx: index }))}
      />
    </div>
  );
}

export default React.forwardRef((props, ref) => (
  <CustomMuiSelect
    innerRef={ref}
    {...props} // eslint-disable-line
  />
));

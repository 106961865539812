import React, { useEffect, useState } from "react";

import { Modal } from "antd";
import { toast } from "react-toastify";

import StyledButton from "../../../../Common/UIComponents/StyledButton";
import StyledCheckbox from "../../../../Common/UIComponents/StyledCheckbox";
import StyledRadioGroup from "../../../../Common/UIComponents/StyledRadioGroup";
import StyledStepper from "../../../../Common/UIComponents/StyledStepper";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import SampleSubmissionConfirmModal from "../../../SampleSubmissionModal/SampleSubmissionConfirmModal";

import { ReactComponent as QuestionIcon } from "../../../../../assets/images/question-mark-pop-up-filled.svg";

import "./CompositeModal.css";

export default function CompositeModal({
  showCompositeModal, setShowCompositeModal, sampleFieldsInfo, compositeData, setCompositeData, setCompositeToggle, handleCompositeSave,
}) {
  const [composite, setComposite] = useState({ sample_count: null, unique: null, identifier: [] });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");

  useEffect(() => {
    if (compositeData) {
      setComposite(compositeData);
    }
  }, [compositeData]);

  /**
   * Handle changes to the composite sample input fields.
   * @param {Object} value The new value of the input field.
   * @param {string} action The name of the input field that changed.
   */
  const handleCompositeChange = (value, action) => {
    setComposite((prev) => {
      switch (action) {
        case "sample_count":
          return { ...prev, sample_count: value };
        case "unique":
          // If unique is set to false, reset the identifier array
          if (value.target.value === false) {
            return { ...prev, unique: value.target.value, identifier: [] };
          }
          return { ...prev, unique: value.target.value };
        case "identifier":
          return {
            ...prev,
            identifier: prev.identifier.includes(value)
              ? prev.identifier.filter((item) => item !== value)
              : [...prev.identifier, value],
          };
        default:
          return prev;
      }
    });
  };

  const handleSubmit = () => {
    if (composite.sample_count < 2) {
      toast.error("Composite sample must include at least 2 samples");
      return;
    }
    if (composite.unique === null) {
      toast.error("Please select whether all samples are unique or not");
      return;
    }
    if (composite.unique === true && composite.identifier.length === 0) {
      toast.error("Please select at least one identifier");
      return;
    }
    if (composite.sample_count < compositeData.sample_count && !showConfirmationModal) {
      setShowConfirmationModal(true); // Open confirmation modal
      setConfirmMessage(
        `This action will result in the loss of data for the last ${compositeData.sample_count - composite.sample_count} ${compositeData.identifier.join(", ")} field(s). Do you wish to continue?`,
      );
    } else {
      setCompositeData(composite);
      setCompositeToggle(true);
      setShowCompositeModal(false);
      setShowConfirmationModal(false);
      handleCompositeSave(composite.sample_count, composite.identifier);
    }
  };

  const handleClose = () => {
    setComposite(compositeData);
    setShowCompositeModal(false);
  };

  return (
    <>
      <Modal
        open={showCompositeModal}
        onCancel={handleClose}
        centered
        footer={null}
        destroyOnClose
        width="540px"
      >
        <div className="CompositeModal__Container">
          <div className="CompositeModal__Header">
            <span>Composite Sample</span>
          </div>
          <div className="CompositeModal__Body">
            <div className="CompositeModal__InputContainer">
              <div className="CompositeModal__InputQuestion__Container">
                <span className="CompositeModal__InputQuestion">
                  How many samples do you have in this composite?
                </span>
                <StyledTooltip title="A composite sample must include at least 2 samples" placement="top">
                  <QuestionIcon width={20} height={20} />
                </StyledTooltip>
              </div>
              <div className="CompositeModal--MarginLeft">
                <StyledStepper
                  value={composite.sample_count}
                  onChange={(value) => handleCompositeChange(value, "sample_count")}
                  max={150}
                />
              </div>
            </div>
            <div className="CompositeModal__InputContainer">
              <span className="CompositeModal__InputQuestion">
                Is there any unique identifier(s) for each sample in the composite?
              </span>
              <div className="CompositeModal--MarginLeft">
                <StyledRadioGroup
                  value={composite.unique}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  onChange={(value) => handleCompositeChange(value, "unique")}
                />
              </div>
            </div>
            {composite.unique && (
              <div className="CompositeModal__SelectionContainer">
                <span className="CompositeModal__SelectionInputQuestion">Select the unique identifier(s):</span>
                <div className="CompositeModal__SelectionInputContainer CompositeModal--MarginLeft">
                  {Object.keys(sampleFieldsInfo).map((field, idx) => {
                    // Check if the display value is "1"
                    if (sampleFieldsInfo[field].display !== "1") {
                      return null; // Skip rendering this field
                    }
                    return (
                      <div key={`${idx}--${field}`} className="CompositeModal__SelectionInput">
                        <StyledCheckbox
                          checked={composite.identifier.includes(field)}
                          onChange={() => handleCompositeChange(field, "identifier")}
                          containerClassName="CompositeModal__Checkbox"
                        >
                          <span>{sampleFieldsInfo[field].title_field}</span>
                        </StyledCheckbox>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="CompositeModal__Footer">
            <StyledButton type="default" className="CompositeModal__Footer__Button" onClick={handleClose}>
              Cancel
            </StyledButton>
            <StyledButton type="primary" className="CompositeModal__Footer__Button" onClick={handleSubmit}>
              Save
            </StyledButton>
          </div>
        </div>
      </Modal>
      {showConfirmationModal && (
        <SampleSubmissionConfirmModal
          headerText="Unsaved Changes"
          bodyText={confirmMessage}
          buttonText={["No", "Yes"]}
          buttonFunctions={[() => { setShowConfirmationModal(false); }, () => handleSubmit()]}
        />
      )}
    </>
  );
}

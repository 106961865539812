import React from "react";
import SpecManagerInput from "./SpecManagerInput";
import { isNumber, UNIT_OPTIONS } from "./Helper";
import "./SpecsRange.css";
import SpecManagerSelect from "./SpecManagerSelect";

class SpecsRange extends React.Component {
  onRangeFromChange = (value) => {
    const { data, handleDataChange } = this.props;
    handleDataChange([value, data[1], data[2]]);
  }

  onRangeToChange = (value) => {
    const { data, handleDataChange } = this.props;
    handleDataChange([data[0], value, data[2]]);
  }

  onOptionClick = (optionSelected) => {
    const { data, handleDataChange } = this.props;
    handleDataChange([data[0], data[1], optionSelected]);
  }

  render() {
    const { data } = this.props;
    const [rangeFrom, rangeTo, percentage] = data;

    return (
      <div
        className="productReportSpecManagerSpecsContainer"
        id="productReportSpecManagerSpecsRange"
      >
        <SpecManagerInput
          placeHolder="eg: 0.75"
          onChange={this.onRangeFromChange}
          value={rangeFrom}
          width="72px"
          additionalClassName={(!rangeFrom && rangeTo) || (rangeFrom && !isNumber(rangeFrom)) ? "rangeValueMissing" : ""} // mark invalid if empty and end value is not empty OR if not empty and is not a number
        />
        -
        <SpecManagerInput
          placeHolder="eg: 2.0"
          onChange={this.onRangeToChange}
          value={rangeTo}
          width="72px"
          additionalClassName={(!rangeTo && rangeFrom) || (rangeTo && !isNumber(rangeTo)) ? "rangeValueMissing" : ""} // mark invalid if empty and start value is not empty OR if not empty and is not a number
        />
        <SpecManagerSelect
          width="134px"
          optionSelected={percentage === "%" ? "%" : percentage || "None"}
          options={UNIT_OPTIONS}
          onSelect={this.onOptionClick}
        />
      </div>
    );
  }
}

export default SpecsRange;

import React, { Component } from "react";
import { toast } from "react-toastify";
import { every, some } from "lodash";
import { motion } from "framer-motion/dist/framer-motion";
import { AutoComplete, Input } from "antd";
import errorIcon from "../../../../../assets/images/environment/infoIconRed.png";
import { getAreaLists } from "../../../../../actions/envAnalytics";
import "./CreateNewPin.css";

export default class CreateNewPin extends Component {
  state = {
    inputValues: {},
    swabDesc: "",
    chosenQuadrant: -1,
    duplicate: false,
    area_lists: {},
    errors: {},
  };

  createNewSwabCardRef = React.createRef();

  componentDidMount() {
    this.createNewSwabCardRef.current.scrollIntoView({ block: "nearest" });
    this.apiGetAreaLists();
  }

  /**
   * Get options for dropdowns
   */
  apiGetAreaLists = async () => {
    const { toggleAddingSwab } = this.props;
    const { success, area_lists, message } = await getAreaLists();
    if (success) {
      this.setState({ area_lists });
    } else {
      toast.error(message);
      toggleAddingSwab(); // close create new swab card
    }
  }

  /**
   * Set input value for given field
   * @param {String} json_field
   * @param {String} val
   */
  setFieldValue = (json_field, val) => {
    const { inputValues } = this.state;
    this.setState({ inputValues: { ...inputValues, [json_field]: val } });
  }

  /**
   * Set error status for given field
   * @param {String} json_field
   * @param {String} error
   */
  setFieldError = (json_field, error) => {
    const { errors } = this.state;
    this.setState({ errors: { ...errors, [json_field]: error } });
  }

  handleCancelClick = () => {
    const { toggleAddingSwab } = this.props;
    toggleAddingSwab();
  }

  handleConfirmClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {
      index0, linkPatternFields, delimiter, sampleIDFields,
    } = this.props;
    const { swabDesc, inputValues } = this.state;
    const inputErrors = {};
    const inputs = { swabDesc: swabDesc?.trim() ?? "" };

    /** Check for errors (missing swab_number or delimiter in value) */
    linkPatternFields.forEach(({ json_field }) => {
      const value = inputValues[json_field]?.trim() ?? "";
      if (sampleIDFields.includes(json_field)) {
        const regex = new RegExp(delimiter, "g"); // global flag to find all matches and not just the first
        const invalidMatches = value.match(regex);
        if (invalidMatches) {
          inputErrors[json_field] = "invalid";
        } else {
          inputErrors[json_field] = "";
        }
      }
      if (json_field === "swab_number" && !value) {
        inputErrors.swab_number = "required";
      }
      inputs[json_field] = value;
    });
    this.setState({ errors: inputErrors });
    const { chosenQuadrant } = this.state;
    if (inputs[index0] && chosenQuadrant !== -1 && every(inputErrors, (val) => !val)) { // eslint-disable-line
      const {
        toggleAddingSwab, togglePinningNewSwab, handleAddNewSwab, setSelectedLocationID, handleSelectQuadrant,
      } = this.props;
      const { success, duplicate, newPin } = await handleAddNewSwab(inputs.swab_number, inputs.zone, inputs.section, inputs.swabDesc);
      if (success) {
        this.setState({ duplicate });
        setSelectedLocationID(newPin.location_id); // new pin's id, simulate clicking on pin icon to pin swab
        handleSelectQuadrant(chosenQuadrant); // simulate selecting quadrant when pinning swab
        toggleAddingSwab(); // close create new swab card
        togglePinningNewSwab();
      } else if (duplicate) {
        this.setState({ duplicate });
      }
    } else if (!inputs[index0] || chosenQuadrant === -1) {
      toast.error(`${linkPatternFields.filter(({ json_field }) => json_field === index0)[0].title_field} and/or quadrant is required.`);
    }
  }

  handleAutocompleteOptionClick = (json, value) => {
    const { errors } = this.state;
    this.setFieldValue(json, value);
    if ((errors[json] === "required" && value) || errors[json] === "invalid") {
      this.setFieldError(json, "");
    }
  }

  handleAutocompleteInputChange = (json, value) => {
    const { errors } = this.state;
    this.setFieldValue(json, value);
    if ((errors[json] === "required" && value) || errors[json] === "invalid") {
      this.setFieldError(json, "");
    }
  }

  chooseQuadrant = (quad) => {
    const { chosenQuadrant } = this.state;
    const { handleHoverQuadrant } = this.props;
    if (quad === chosenQuadrant) {
      this.setState({ chosenQuadrant: -1 });
      handleHoverQuadrant(-1);
    } else {
      this.setState({ chosenQuadrant: quad });
      handleHoverQuadrant(quad);
    }
  }

  render() {
    const {
      chosenQuadrant,
      duplicate,
      area_lists,
      errors,
    } = this.state;
    const {
      linkPatternFields,
      handleHoverQuadrant,
    } = this.props;

    const quadrantClassName = ["TopLeft", "TopRight", "BottomRight", "BottomLeft"];

    return (
      <motion.div
        ref={this.createNewSwabCardRef}
        className="CreateNewPin"
        layout
        key="create-new-swab"
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: "auto", opacity: 1, transition: { height: { duration: 0.5 }, opacity: { duration: 0.5 } } }}
        exit={{ height: 0, opacity: 0, transition: { height: { duration: 0.4 }, opacity: { duration: 0.4 } } }}
      >
        <div className="CreateNewPin__Header">
          <div>
            <span>Swab Details</span>
          </div>
        </div>
        <div className="CreateNewPin__Body">
          {duplicate && (
          <div key="duplicate" className="CreateNewPin__DuplicateErrorDiv">
            <img
              className="CreateNewPin__DuplicateSwabErrorIcon"
              src={errorIcon}
              alt="error icon"
            />
            <div className="CreateNewPin__DuplicateSwabErrorText">Swab already exists.</div>
          </div>
          )}
          {some(errors, (val) => val === "invalid") && (
          <div key="invalid" className="CreateNewPin__DuplicateErrorDiv">
            <img
              className="CreateNewPin__DuplicateSwabErrorIcon"
              src={errorIcon}
              alt="error icon"
            />
            <div className="CreateNewPin__DuplicateSwabErrorText">Invalid character.</div>
          </div>
          )}
          {linkPatternFields.map((field) => {
            const json = field.json_field;
            const title = field.title_field;
            return (
              <div key={json} className="CreateNewPin__FieldInput">
                <div className="CreateNewPin__FieldInputInner">
                  <AutoComplete
                    className="CreateNewPin__Autocomplete"
                    allowClear
                    filterOption={(inputValue, option) => option.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1}
                    options={area_lists[json]?.map((val) => ({ value: val, title: val }))}
                    onSelect={(value) => this.handleAutocompleteOptionClick(json, value)}
                    onSearch={(value) => this.handleAutocompleteInputChange(json, value)}
                    notFoundContent={area_lists[json] > 0 ? "Not Found" : undefined}
                    popupClassName="CreateNewPin__Autocomplete__Dropdown"
                  >
                    <Input placeholder={title} className={errors[json] ? "CreateNewPin__Input--error" : ""} />
                  </AutoComplete>
                </div>
              </div>
            );
          })}
          <div className="CreateNewPin__ChooseQuadrant">
            <div>
              Choose a quadrant
            </div>
            <div className="CreateNewPin__ChooseQuadrantColorBlocks">
              {quadrantClassName.map((className, indexOfQuadrant) => (
                <div
                  key={indexOfQuadrant}
                  className={`CreateNewPin__QuadrantColorBlock CreateNewPin__QuadrantColorBlock${className} 
                  ${chosenQuadrant === indexOfQuadrant ? "CreateNewPin__QuadrantColorBlock--selected" : ""}`}
                  onClick={() => this.chooseQuadrant(indexOfQuadrant)}
                  onMouseEnter={(chosenQuadrant === -1) ? () => handleHoverQuadrant(indexOfQuadrant) : () => {}}
                  onMouseLeave={(chosenQuadrant === -1) ? () => handleHoverQuadrant(-1) : () => {}}
                />
              ))}
            </div>
          </div>
          <textarea
            type="text"
            className="CreateNewPin__SwabDescription"
            rows="2"
            placeholder="Swab Description"
            onChange={(e) => this.setState({ swabDesc: e.target.value })}
          />
          <div className="CreateNewPin__CancelConfirm">
            <button
              type="button"
              className="CreateNewPin__Cancel"
              onClick={this.handleCancelClick}
            >
              Cancel
            </button>
            <button
              type="button"
              className="CreateNewPin__Confirm"
              onClick={this.handleConfirmClick}
            >
              Confirm
            </button>
          </div>
        </div>
      </motion.div>
    );
  }
}

import React from "react";

import Modal from "antd/es/modal/Modal";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import moment from "moment";
import { Carousel } from "react-responsive-carousel";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import { AppContext } from "../../../../../AppContext";
import { SPEC_FLAGS_MAP } from "../../../../Common/constants/SpecFlagConstants";
import NoResults from "../../../../Common/NoResults";
import StyledButton from "../../../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import DatePicker from "./DatePicker";
import { MAX_RETESTS_ALLOWED, VALID_SPECS_SET, getColorForTestResult } from "./helper";
import RequestRetestModal from "./RequestRetestModal";
import SampleTestResultTable from "./SampleTestResultTable";

import { getUserCredentials } from "../../../../../utils/common";

import { getEnvSpec } from "../../../../../actions/envAnalytics";
import { getSpec, requestRetest } from "../../../../../actions/reports";

import { ReactComponent as LoadingDots } from "../../../../../assets/images/common/LoadingDots.svg";
import emptyStateIcon from "../../../../../assets/images/EmptyState_V2.png";
import closeIcon from "../../../../../assets/images/product/crossWhite.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../../Product.css";
import "./SampleResultsModal.css";

class SampleResultsModal extends React.Component {
  testResultTableRefs = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      sampleResultsData: null,
      showRetestModal: false,
      keyOfTestResultTable: true, // this key is used to trigger a re-render of SampleTestResultTable component
      currentSlide: 0,
      totalSlides: 0,
      awaitingRetestRequest: false,
      support: getUserCredentials().uid === "support",
      isPickingRetests: false,
      testToRetest: [],
      activeDate: "",
      isInProcess: false,
      disabledTests: new Set(),
      loadingUpdatedData: false,
      loadingData: true,
      showDetailedPesticideReport: false,
      pesticideDetailTest: null,
      // pesticideCommodity: "", //NOTE: value not being shown in current cycle
      // isRetest: false,
      // isOutOfSpec: false,
    };
  }

  componentDidMount() {
    this.setState({ loadingData: true });
    const { sampleCategory } = this.props;
    if (sampleCategory === "environment") {
      this.envGetSampleResults().then((result) => {
        if (result) {
          const { sampleResultsData, isInProcess, totalSlides } = result;
          this.setState({
            sampleResultsData,
            isInProcess,
            totalSlides,
            activeDate: sampleResultsData.data[0][0],
          });
        }
        this.setState({ loadingData: false });
      });
    } else {
      this.getSampleResults().then((result) => {
        if (result) {
          const { sampleResultsData, isInProcess, totalSlides } = result;
          // const hasPesticideTestByDate = this.f(); //NOTE: Used for Sample Type. Kept for future use

          this.setState({
            sampleResultsData,
            isInProcess,
            totalSlides,
            activeDate: sampleResultsData.data[0][0],
          });
        }
        this.setState({ loadingData: false });
      });
    }
  }

  /**
   * Refresh report data by calling getSampleResutls
   */
  reportDataRefresh = () => {
    this.setState({ loadingData: true });
    const { sampleCategory } = this.props;
    if (sampleCategory === "environment") {
      this.envGetSampleResults().then((result) => {
        if (result) {
          const { sampleResultsData, isInProcess, totalSlides } = result;
          this.setState((state) => ({
            sampleResultsData,
            isInProcess,
            totalSlides,
            activeDate: state.activeDate,
          }));
        }
        this.setState({ loadingData: false });
      });
    } else {
      this.getSampleResults().then((result) => {
        if (result) {
          const { sampleResultsData, isInProcess, totalSlides } = result;
          // const hasPesticideTestByDate = this.checkPesticideTestByDate(); //NOTE: Used for Sample Type. Kept for future use

          this.setState((state) => ({
            sampleResultsData,
            isInProcess,
            totalSlides,
            activeDate: state.activeDate,
          }));
        }
        this.setState({ loadingData: false });
      });
    }
  }

  /**
  * Fetch sample data (spec flag, link report field values, test results)
  */
  getSampleResults = async () => {
    const { reportData } = this.props;
    if (reportData) {
      const {
        index_id, index, report_id, sample_id, product_type, sample_type, received_date,
      } = reportData;
      const params = {
        type: "Report",
        index,
        index_id,
        report_id,
        sample_id,
        product_type,
        received_date: moment(new Date(received_date)).format("YYYY/MM/DD"),
        sample_type,
      };
      if (params.index === null || params.index === undefined) {
        params.index = "Product";
      }

      const response = await getSpec(params);
      if (response && response.success) {
        const result = response.spec;
        if (Object.values(result).length === 0) {
          toast.error(
            "Oops, something went wrong. Please try again later.",
          );
          return null;
        }
        const isInProcess = Object.values(result.report_result)
          .flat()
          .every((item) => item.test_spec_flag.toString() === "0");
          // const isRetest = specs_flag && specs_flag.includes("Retest");
          // const isInProcess = specs_flag && specs_flag.includes("In Process");
          // const isOutOfSpec = specs_flag && specs_flag.includes("Out of Spec");

        const dataObj = Object.entries(result.report_result);
        dataObj.forEach((data) => {
          const coloredData = getColorForTestResult(data[1]);
          data[1] = coloredData;
        });

        const sampleResultsData = { ...result, data: dataObj };
        sampleResultsData.data.sort((a, b) => Date.parse(b[0]) - Date.parse(a[0])); // sorts in place
        this.setTestsToDisable(sampleResultsData);
        return { sampleResultsData, isInProcess, totalSlides: Object.keys(sampleResultsData.data).length };
      }
      toast.error("Failed to load report specs");
      return null;
    }
    return null;
  };

  /**
  * Fetch sample data (spec flag, link report field values, test results)
  */
  envGetSampleResults = async () => {
    const { reportData } = this.props;
    if (reportData) {
      const {
        sample_id, location_id, sample_type, received_date,
      } = reportData;
      const params = {
        sample_id,
        location_id,
        received_date: moment(new Date(received_date)).format("YYYY/MM/DD"),
        sample_type,
      };

      const response = await getEnvSpec(params);
      if (response && response.success) {
        const result = response.spec;
        if (Object.values(result).length === 0) {
          toast.error(
            "Oops, something went wrong. Please try again later.",
          );
          return null;
        }
        const isInProcess = Object.values(result.report_result)
          .flat()
          .every((item) => item.test_spec_flag.toString() === "0");

        const dataObj = Object.entries(result.report_result);
        dataObj.forEach((data) => {
          const coloredData = getColorForTestResult(data[1]);
          data[1] = coloredData;
        });

        const sampleResultsData = { ...result, data: dataObj };
        sampleResultsData.data.sort((a, b) => Date.parse(b[0]) - Date.parse(a[0])); // sorts in place
        this.setTestsToDisable(sampleResultsData);
        return { sampleResultsData, isInProcess, totalSlides: Object.keys(sampleResultsData.data).length };
      }
      toast.error("Failed to load report specs");
      return null;
    }
    return null;
  };

  /**
   * If at least one instance of a test out of
   * all tables has spec flag equal to 0, disable
   * the test so the user cannot request a retest
   * on that test.
   * @param {Object} results results.data = [[date, testResults], [date, testResults], ...]
   */
  setTestsToDisable = (results) => {
    const disabledTests = new Set();
    results.data.forEach(([date, testResults]) => { // eslint-disable-line
      testResults.forEach(({ test, test_spec_flag }) => {
        if (test_spec_flag === "0") {
          disabledTests.add(test);
        }
      });
    });
    this.setState({ disabledTests });
  };

  handleRetestRequest = async () => {
    const { testToRetest } = this.state;
    const { reportData } = this.props;

    this.setState({ awaitingRetestRequest: true });

    const date = moment(new Date(reportData.received_date)).format("YYYY-MM-DD");
    const payload = {
      sample_id: reportData.sample_id,
      received_at: date,
      tests: Array.from(new Set(testToRetest)),
      sample_type: reportData.sample_type,
    };

    const response = await requestRetest(payload);
    if (response && response.success) {
      toast.success("Retest request is successful.");
      const result = await this.getSampleResults();
      if (result) {
        const { sampleResultsData, isInProcess, totalSlides } = result;
        this.setState({
          sampleResultsData,
          isInProcess,
          isPickingRetests: false,
          showRetestModal: false,
          awaitingRetestRequest: false,
          testToRetest: [],
          currentSlide: 0,
          totalSlides,
          activeDate: sampleResultsData.data[0][0],
        });
      }
    } else {
      toast.error(response.message || "Retest request has failed.");
      this.setState({ awaitingRetestRequest: false });
    }
  };

  getDateString = (currDate, monthFirst) => {
    const momentDate = moment(new Date(currDate));
    if (!momentDate.isValid()) {
      return currDate;
    }
    if (monthFirst) {
      return `${momentDate.format("MM/DD/YY")}`;
    }
    return `${momentDate.format("DD MMM YYYY")}`;
  };

  handleDateChanged = (date, index, change = 0) => {
    const { currentSlide, totalSlides, sampleResultsData } = this.state;
    if (date) {
      this.setState({
        activeDate: date,
        currentSlide: index,
      });
    } else {
      const newValue = currentSlide + change;
      if (newValue < totalSlides && newValue >= 0) {
        const newDate = sampleResultsData.data[newValue][0];
        this.setState({
          activeDate: newDate,
          currentSlide: currentSlide + change,
        });
      }
    }
  };

  addTestToRetest = (test, isChecked) => {
    const { testToRetest } = this.state;

    // Need to add to the list
    if (isChecked) {
      testToRetest.push(test);
    } else {
      // Need to remove from the list (don't filter all in case there are duplicates)
      const idxToRemove = testToRetest.findIndex(
        (existingTest) => existingTest === test,
      );
      testToRetest.splice(idxToRemove, 1);
    }

    this.setState({
      testToRetest: [...testToRetest], // make a shallow copy to trigger rerender
    });
  };

  /**
   * If entering request retest mode, refresh spec data.
   * @param {Boolean} isPickingRetests true if entering request retest mode, false if canceling out of it
   */
  updateRequestRetestMode = async (isPickingRetests) => {
    const { testToRetest } = this.state;

    let updatedTests = testToRetest;

    if (isPickingRetests) {
      this.setState({ loadingUpdatedData: true });
      const result = await this.getSampleResults();
      if (result) {
        const { sampleResultsData, isInProcess, totalSlides } = result;
        const { activeDate, keyOfTestResultTable } = this.state;
        const sortedDates = sampleResultsData.data.map(([date, _]) => date); // eslint-disable-line no-unused-vars
        const activeDateIndex = sortedDates.indexOf(activeDate);
        const newSlide = activeDateIndex > -1 ? activeDateIndex : 0;
        const newActiveDate = sortedDates[newSlide];
        this.setState({
          sampleResultsData,
          totalSlides,
          isInProcess,
          // isOutOfSpec,
          // isRetest,
          isPickingRetests: true,
          currentSlide: newSlide,
          activeDate: newActiveDate,
          keyOfTestResultTable: !keyOfTestResultTable,
          loadingUpdatedData: false,
        });
      } else {
        // if not successful, we need to update state here
        this.setState({
          isPickingRetests: true,
          testToRetest: updatedTests,
          loadingUpdatedData: false,
        });
      }
      this.setState({ loadingUpdatedData: false });
    } else {
      // User is exiting the selection mode so reset the state
      updatedTests = [];
      /** For each table, clear selected tests */
      if (this.testResultTableRefs.current) {
        this.testResultTableRefs.current.forEach((table) => table?.clearCheckboxes());
      }
      this.setState({
        isPickingRetests: false,
        testToRetest: updatedTests,
      });
    }
  };

  showRequestRetestModal = () => {
    const { awaitingRetestRequest } = this.state;
    if (awaitingRetestRequest) {
      return;
    }
    this.setState({
      showRetestModal: true,
    });
  };

  hideRequestRetestModal = () => {
    this.setState({
      showRetestModal: false,
    });
  };

  // eslint-disable-next-line consistent-return
  handleCloseModal = () => {
    const {
      handleExitSampleResultsModal, reportData, sampleSubmission, onCancel,
    } = this.props;
    if (sampleSubmission) return onCancel();
    const { sampleResultsData } = this.state;
    const specsFlagHasChanged = sampleResultsData?.specs_flag && sampleResultsData.specs_flag !== reportData.specs_flag;
    handleExitSampleResultsModal(specsFlagHasChanged ? sampleResultsData.specs_flag : undefined);
  }

  /**
   * Show and hide pesticide details toggle
   * @param {boolean} isOpen is open
   */
  handlePesticideDetailsToggle = (isOpen, test) => {
    this.setState((state) => ({ ...state, showDetailedPesticideReport: isOpen, pesticideDetailTest: test }));
  }

  /**
   * Checks whether a date has a pesticide report
   * @param {*} sampleResultsData Sample result data
   * @returns Object containing date as key and value as true/false.
   */
  checkPesticideTestByDate = (sampleResultsData) => Object.keys(sampleResultsData.report_result).reduce((acc, key) => {
    const testList = sampleResultsData.report_result[key];
    let testHasPesticide = false;
    testList.forEach((test) => {
      if (test.test.toLowerCase().includes("pesticide")) {
        testHasPesticide = true;
      }
    });
    acc[key] = testHasPesticide;
    return acc;
  }, {});

  /**
   * Set pesticide commodity value
   * @param {*} value commodity value
   * //NOTE: Kept for future as value is not being shown
   */
  // setPesticideCommodity = (value) => this.setState({ pesticideCommodity: value });

  handleRenderingRequestRetestButton = () => {
    const {
      awaitingRetestRequest,
      isPickingRetests,
      testToRetest,
      totalSlides,
      isInProcess,
      support,
      loadingUpdatedData,
      showDetailedPesticideReport,
      // admin,
      // isRetest,
      // isOutOfSpec,
    } = this.state;
    if (isPickingRetests) {
      // If user is picking retests we need to render cancel & submit button
      return (
        <div className="SampleResultsModal__PickingRetestsContainer">
          <StyledButton
            type="primary"
            className="SampleResultsModal__CancelRetestRequestButton"
            onClick={() => this.updateRequestRetestMode(false)}
          >
            <div>Cancel</div>
          </StyledButton>
          <StyledTooltip
            title={awaitingRetestRequest ? "Awaiting request" : ""}
            placement="right"
          >
            <StyledButton
              type="primary"
              disabled={testToRetest.length === 0 || awaitingRetestRequest || showDetailedPesticideReport}
              onClick={() => this.showRequestRetestModal()}
            >
              <div>
                Request Retest (
                {new Set(testToRetest).size}
                )
              </div>
            </StyledButton>
          </StyledTooltip>
        </div>
      );
    }

    const atMaxRetests = totalSlides - 1 >= MAX_RETESTS_ALLOWED;
    const showButton = support;
    // && !(isInProcess && !isRetest); // only show for admin, and when not In Process (spec flag === 0)
    if (showButton) {
      const disableButton = awaitingRetestRequest
        || atMaxRetests
        || isInProcess
        || loadingUpdatedData
        || showDetailedPesticideReport;
      // || (isInProcess && isRetest);
      let tooltipTitle = "";
      if (awaitingRetestRequest) {
        tooltipTitle = "Awaiting request";
      } else if (atMaxRetests) {
        tooltipTitle = "Max amount of retests (3) reached";
      } else if (isInProcess) {
        tooltipTitle = "Cannot request retest when testing in process";
      }
      return (
        <AnimatePresence initial={false}>
          <StyledTooltip
            title={tooltipTitle}
            placement="right"
          >
            <StyledButton
              key="request retest button"
              type="primary"
              className="SampleResultsModal__RetestRequestButton"
              disabled={disableButton}
              onClick={awaitingRetestRequest || loadingUpdatedData ? () => { } : () => this.updateRequestRetestMode(true)}
            >
              <div>
                Request Retest
                {totalSlides > 1 ? ` (${totalSlides - 1}/3)` : null}
              </div>
            </StyledButton>
          </StyledTooltip>
        </AnimatePresence>
      );
    }

    return null;
  };

  renderAddSpecsButton() {
    const { reportData, handleAddSpecsButton } = this.props;
    const { isPickingRetests, sampleResultsData } = this.state;
    const { userTitle } = this.context;
    const admin = userTitle === "Admin" || userTitle === "Editor";
    if (
      sampleResultsData.specs_flag?.toString() === "3"
      && reportData.index_id !== ""
      && admin
      && !isPickingRetests
    ) {
      return (
        <StyledButton
          onClick={() => handleAddSpecsButton(reportData.index_id, reportData)}
          className="SampleResultsModal__AddSpecsButton"
        >
          Add Specs
        </StyledButton>
      );
    }

    return null;
  }
  /**
  *Sample Submission view report modal
  */

  renderReportDetails() {
    const {
      reportData,
      sampleCategory,
      sampleSubmission,
      sampleFieldsData,
      linkReportJsonFields,
      fields,
    } = this.props;
    let filteredFieldsObj;
    if (!sampleFieldsData && linkReportJsonFields) {
      filteredFieldsObj = Object.keys(reportData).filter((key) => linkReportJsonFields.includes(key)).map((key) => ({
        key: fields.filter((item) => item.json_field === key)[0].title_field,
        value: reportData[key],
      }));
    }
    const { activeDate } = this.state;
    if (sampleSubmission) {
      if (sampleCategory === "environment") {
        return (
          <div className="SampleResultsModal__SubHeader">
            <div className="SampleResultsModal__SubHeader__Top">
              <div className="SampleResultsModal__SubHeader__Entry">
                <span className="SampleResultsModal__SubHeader__Label">
                  Location
                </span>
                <Scrollbar className="SampleResultsModal__SampleIDScrollBar">
                  <span className="LinkedFieldsSpan SampleResultsModal__Product">
                    {sampleFieldsData
                      .filter((item) => item.value !== "")
                      .map((item, index) => (
                        <React.Fragment key={item.key}>
                          <span className="LinkedFieldTitle">{`${item.key}: `}</span>
                          <span className="LinkedFieldValue">{item.value}</span>
                          {index < sampleFieldsData.filter((i) => i.value !== "").length - 1 && <span className="LinkedFieldsDelimiterDot" />}
                        </React.Fragment>
                      ))}
                    {sampleFieldsData.filter((item) => item.value !== "").length === 0 && <span className="LinkedFieldValue">-</span>}
                  </span>
                </Scrollbar>
              </div>
              <span className="SampleResultsModal__SubHeader__Entry">
                <span className="SampleResultsModal__SubHeader__Label--date">
                  Date
                </span>
                {this.getDateString(activeDate, true)}
              </span>
            </div>
            <hr className="SampleResultsModal__SubHeader__Divider" />
            <div className="SampleResultsModal__SubHeader__Bottom SampleResultsModal__SubHeader__Entry">
              <span className="SampleResultsModal__SubHeader__Label">
                Sample ID
              </span>
              <Scrollbar className="SampleResultsModal__SampleIDScrollBar">
                <div className="SampleResultsModal__SampleID">
                  <span>{reportData.sample_id}</span>
                </div>
              </Scrollbar>
            </div>
          </div>
        );
      }
      if (sampleCategory === "product") {
        return (
          <div className="SampleResultsModal__SubHeader">
            <div className="SampleResultsModal__SubHeader__Top">
              <div className="SampleResultsModal__SubHeader__Entry">
                <span className="SampleResultsModal__SubHeader__Label">
                  Product
                </span>
                <Scrollbar className="SampleResultsModal__SampleIDScrollBar">
                  <span className="LinkedFieldsSpan SampleResultsModal__Product">
                    {sampleFieldsData
                      .filter((item) => item.value !== "")
                      .map((item, index) => (
                        <React.Fragment key={item.key}>
                          <span className="LinkedFieldTitle">{`${item.key}: `}</span>
                          <span className="LinkedFieldValue">{item.value}</span>
                          {index < sampleFieldsData.filter((i) => i.value !== "").length - 1 && <span className="LinkedFieldsDelimiterDot" />}
                        </React.Fragment>
                      ))}
                    {sampleFieldsData.filter((item) => item.value !== "").length === 0 && <span className="LinkedFieldValue">-</span>}
                  </span>
                </Scrollbar>
              </div>
              <span className="SampleResultsModal__SubHeader__Entry">
                <span className="SampleResultsModal__SubHeader__Label--date">
                  Date
                </span>
                {this.getDateString(activeDate, true)}
              </span>
            </div>
            <hr className="SampleResultsModal__SubHeader__Divider" />
            <div className="SampleResultsModal__SubHeader__Bottom SampleResultsModal__SubHeader__Entry">
              <span className="SampleResultsModal__SubHeader__Label">
                Sample ID
              </span>
              <Scrollbar className="SampleResultsModal__SampleIDScrollBar">
                <div className="SampleResultsModal__SampleID">
                  <span>{reportData.sample_id}</span>
                </div>
              </Scrollbar>
            </div>
            {/* {(hasPesticideTest && showDetailedPesticideReport && pesticideCommodity) && (
              <div className="SampleResultsModal__SubHeader__Entry SampleResultsModal__SubHeader__Bottom">
                <span className="SampleResultsModal__SubHeader__Label">
                  Sample Type
                </span>
                <span>{pesticideCommodity}</span>
              </div>
            )} */}
          </div>
        );
      }
    } else {
      return (
        <div className="SampleResultsModal__SubHeader">
          <div className="SampleResultsModal__SubHeader__Top">
            <div className="SampleResultsModal__SubHeader__Entry">
              <span className="SampleResultsModal__SubHeader__Label">
                Product
              </span>
              <Scrollbar className="SampleResultsModal__SampleIDScrollBar">
                <span className="LinkedFieldsSpan SampleResultsModal__Product">
                  {filteredFieldsObj
                    .filter((item) => item.value !== "")
                    .map((item, index) => (
                      <React.Fragment key={item.key}>
                        <span className="LinkedFieldTitle">{`${item.key}: `}</span>
                        <span className="LinkedFieldValue">{item.value}</span>
                        {index < filteredFieldsObj.filter((i) => i.value !== "").length - 1 && <span className="LinkedFieldsDelimiterDot" />}
                      </React.Fragment>
                    ))}
                  {filteredFieldsObj.filter((item) => item.value !== "").length === 0 && <span className="LinkedFieldValue">-</span>}
                </span>
              </Scrollbar>
            </div>
            <span className="SampleResultsModal__SubHeader__Entry">
              <span className="SampleResultsModal__SubHeader__Label--date">
                Date
              </span>
              {this.getDateString(activeDate, true)}
            </span>
          </div>
          <hr className="SampleResultsModal__SubHeader__Divider" />
          <div className="SampleResultsModal__SubHeader__Bottom SampleResultsModal__SubHeader__Entry">
            <span className="SampleResultsModal__SubHeader__Label">
              Sample ID
            </span>
            <Scrollbar className="SampleResultsModal__SampleIDScrollBar">
              <div className="SampleResultsModal__SampleID">
                <span>{reportData.sample_id}</span>
              </div>
            </Scrollbar>
          </div>
          {/* {(hasPesticideTest && showDetailedPesticideReport && pesticideCommodity) && (
            <div className="SampleResultsModal__SubHeader__Entry SampleResultsModal__SubHeader__Bottom">
              <span className="SampleResultsModal__SubHeader__Label">
                Sample Type
              </span>
              <span>{pesticideCommodity}</span>
            </div>
          )} */}
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      sampleResultsData,
      showRetestModal,
      keyOfTestResultTable,
      currentSlide,
      totalSlides,
      activeDate,
      isPickingRetests,
      testToRetest,
      disabledTests,
      loadingData,
      awaitingRetestRequest,
      showDetailedPesticideReport,
      pesticideDetailTest,
      // isRetest,
    } = this.state;
    const {
      disableInteraction,
      reportData,
      sampleSubmission = false,
    } = this.props;

    const specFlagInfo = sampleResultsData?.specs_flag && VALID_SPECS_SET.has(sampleResultsData.specs_flag) ? SPEC_FLAGS_MAP[sampleResultsData.specs_flag] : SPEC_FLAGS_MAP.invalid;

    return (
      <Modal
        open
        closable={false}
        centered
        footer={null}
        onCancel={this.handleCloseModal}
        wrapClassName="SampleResultsModal"
        className="SampleResultsModal"
        width="743px"
      >
        <div className="SampleResultsModal__Header">
          {[this.getDateString(activeDate, false), "Report"].join(" ")}
          <img
            src={closeIcon}
            alt="close icon"
            onClick={this.handleCloseModal}
            className="SampleResultsModal__CloseIcon"
          />
        </div>
        <div className="SampleResultsModal__Body">
          {!loadingData && this.renderReportDetails()}
          {(loadingData && !sampleSubmission) && <div className={`SampleResultsModal__Loading${["4", "5", "6"].includes(reportData.specs_flag) ? "--retest" : ""}`}><LoadingDots width={28} height={28} fill="#19305A" /></div>}
          {(loadingData && sampleSubmission) && <div className={`SampleResultsModal__Loading${["4", "5", "6"].includes(reportData.specs_flag) ? "--retest" : ""}`}><LoadingDots width={28} height={28} fill="#19305A" /></div>}
          {!loadingData && !sampleResultsData && (
            <NoResults image={emptyStateIcon} message="No Result Found" className="ReportsTable__NoResults" />
          )}
          {!loadingData && sampleResultsData && (
            <>
              <Carousel
                showIndicators={false}
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                selectedItem={currentSlide}
                swipeable={Object.keys(sampleResultsData.data).length > 1}
              >
                {Object.keys(sampleResultsData.data).length > 0
                && sampleResultsData.data.map((data, idx) => (
                  <SampleTestResultTable
                    ref={(el) => {
                      if (this.testResultTableRefs.current === null) {
                        this.testResultTableRefs.current = [];
                      }
                      this.testResultTableRefs.current[idx] = el;
                    }}
                    key={`${idx}${keyOfTestResultTable}`}
                    tableData={data[1]}
                    specs_flag={specFlagInfo.label}
                    isPickingRetests={isPickingRetests}
                    checkBoxOnChange={this.addTestToRetest}
                    disabledTests={disabledTests}
                    reportData={reportData}
                    showDetailedPesticideReport={showDetailedPesticideReport}
                    pesticideDetailTest={pesticideDetailTest}
                    pesticideDetailsToggle={this.handlePesticideDetailsToggle}
                    activeDate={activeDate}
                    testDate={data[0]}
                    reportDataRefresh={this.reportDataRefresh}
                  />
                ))}
              </Carousel>

              <div className="SampleResultsModal__Footer">
                <div>
                  {showRetestModal && (
                  <RequestRetestModal
                    hideRequestRetestModal={this.hideRequestRetestModal}
                    confirmRetest={this.handleRetestRequest}
                    maxNumOfRetests={MAX_RETESTS_ALLOWED}
                    currNumOfRetests={Object.keys(sampleResultsData.data).length - 1}
                    testToRetest={testToRetest}
                    awaitingRetestRequest={awaitingRetestRequest}
                  />
                  )}

                  {Object.keys(sampleResultsData.data).length > 1 && (
                  <div className="SampleResultsModal__CarouselControls">
                    <DatePicker
                      data={sampleResultsData.data}
                      activeDate={activeDate}
                      currentSlide={currentSlide}
                      totalSlides={totalSlides}
                      updateActiveDate={this.handleDateChanged}
                      disabled={showDetailedPesticideReport}
                    />
                  </div>
                  )}

                  <div className="SampleResultsModal__Footer__SpecsFlagAndButtons">
                    <div className="SampleResultsModal__Footer__FinalResult">
                      <span className="SampleResultsModal__Footer__Label">
                        Final Result
                      </span>
                      <span
                        className="SampleResultsModal__Footer__SpecsFlag"
                        style={{ color: specFlagInfo.color }}
                      >
                        <img
                          src={specFlagInfo.icon}
                          alt=""
                        />
                        <span>{specFlagInfo.label}</span>
                      </span>
                    </div>
                    {!disableInteraction && (
                    <div className="SampleResultsModal__Footer__Buttons">
                      {!sampleSubmission && this.renderAddSpecsButton()}
                      {!sampleSubmission && this.handleRenderingRequestRetestButton()}
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  }
}

SampleResultsModal.contextType = AppContext;
export default SampleResultsModal;

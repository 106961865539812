import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import Close from "../../../../../../assets/images/product/template/CloseIconBlue.png";

export default function ContentItem(props) {
  const {
    item, handleSectionSelect, handleSectionDelete, keyValue,
  } = props;
  return (
    <motion.div
      key={keyValue}
      layoutScroll
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={() => handleSectionSelect(item)}
      className={item.active ? "templateBuilderContentItem templateBuilderContentItemActive" : "templateBuilderContentItem "}
    >
      {item.active ? (
        <motion.div
          layoutId="background"
          className="TemplateBuilder__ContentItem__Background"
        />
      ) : null}
      <span>{item.title}</span>
      {item.edit && item.active
        && <img onClick={(e) => handleSectionDelete(e, item)} src={Close} alt="close icon" />}
    </motion.div>

  );
}

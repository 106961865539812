import React, { Component } from "react";
import StatusPage from "../SignUp/StatusPage";
import ResetPassword from "./ResetPassword";

class ResetPasswordController extends Component {
    state = {
      path: "",
    }

    async componentDidMount() {
      this.setState({
        path: window.location.pathname,
      });
    }

    render() {
      const { path } = this.state;
      const { history } = this.props;

      if (path === "/resetpassword") {
        return (<ResetPassword history={history} />);
      }
      if (path === "/resetpassword/success") {
        return (
          <StatusPage
            history={history}
            status="success"
            title="Password successfully changed"
            subtitle="You can now login with your new password."
            showLoginButton
          />
        );
      } if (path === "/resetpassword/invalid") {
        return (
          <StatusPage
            history={history}
            status="warning"
            title="Invalid reset password link"
            subtitle="Your link has expired, has already been used, or is otherwise invalid."
          />
        );
      }
      return null;
    }
}

export default ResetPasswordController;

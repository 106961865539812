import React from "react";
import BuilderElementDraggable from "../DragAndDrop/BuilderElementDraggable";
import TemplateBuilderDroppable from "../DragAndDrop/TemplateBuilderDroppable";

export default function TextBoxSection(props) {
  const { previewTemplate } = props;
  return (
    <div
      className={
        previewTemplate
          ? "templateBuilderComponents templateBuilderDisableSection builderComponentTextField"
          : "templateBuilderComponents builderComponentTextField"
      }
    >
      <div className="templateBuilderComponentsHeader">
        <span> Text box </span>
      </div>
      <TemplateBuilderDroppable
        className="templateBuilderComponentsTextbox"
        droppableId="builder_textbox"
        disabled
        showPlaceholder={false}
      >
        <BuilderElementDraggable
          key="smallTextBox"
          index={0}
          type="smallTextBox"
          value="Small text box"
        />
        <BuilderElementDraggable
          key="largeTextBox"
          index={1}
          type="largeTextBox"
          value="Large text box"
        />
      </TemplateBuilderDroppable>
    </div>

  );
}

import React from "react";

import { render } from "react-dom";
import { Switch, Route } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import "dotenv/config";
import AppUpdate from "./Containers/Common/AppUpdate/AppUpdate";
import * as serviceWorker from "./serviceWorker";

import "./index.css";

render(
  <>
    <Router>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </Router>
    <AppUpdate />
  </>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

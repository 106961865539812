import React, { Component } from "react";

import { Modal, Input } from "antd";
import { Slide } from "react-awesome-reveal";
import { toast } from "react-toastify";

import { AppContext } from "../../AppContext";
import StyledButton from "../Common/UIComponents/StyledButton";
import LoginInput from "./Common/LoginInput";

import { checkUser } from "../../utils/common";

import { sendForgotPasswordEmail, signIn } from "../../actions/login";

import loginBgIcon from "../../assets/images/loginBgImg.svg";
import loginMainIcon from "../../assets/images/loginMainImg.svg";
import esv_logo from "../../assets/images/logo/esv_logo_2x.png";

import "./login.css";
import "./Common/LoginInput.css";

class Login extends Component {
  state = {
    email: "",
    password: "",
    forgotEmail: "",
    isForgotPwd: false,
    isLinkSent: false,
    emailError: false,
    passwordError: false,
    slideOut: false,
    rememberMe: true,
    loginLoading: false,
    forgotPwdLoading: false,
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = () => {
    const { email, password, rememberMe } = this.state;
    const emailTrimmed = email.trim();
    if (!emailTrimmed && !password) {
      this.setState({ emailError: true, passwordError: true });
      return;
    }
    if (!emailTrimmed) {
      this.setState({ emailError: true, passwordError: false });
      return;
    }
    if (!password) {
      this.setState({ emailError: false, passwordError: true });
      return;
    }

    this.setState({ loginLoading: true }, async () => {
      signIn(emailTrimmed, password, rememberMe, this.context, (result) => {
        const { history } = this.props;
        if (!result.success) {
          this.setState({ loginLoading: false });
          toast.error(result.message);
          return;
        }
        this.setState({ slideOut: true }, () => {
          window.setTimeout(() => {
            this.setState({ email: "", password: "", loginLoading: false });
            history.push(("/sample-submission"));
          }, 500);
        });
      });
    });
  };

  sendResetPwdLink = async () => {
    const { forgotEmail } = this.state;
    const forgotEmailTrimmed = forgotEmail.trim();

    if (!forgotEmailTrimmed) {
      toast.error("Please enter a valid email address");
      return;
    }

    this.setState({ forgotPwdLoading: true }, async () => {
      // validEmail(forgotEmailTrimmed, (result) => {
      const { success, firebase_exist_status } = await checkUser(forgotEmailTrimmed);
      if (success && firebase_exist_status) {
        sendForgotPasswordEmail(forgotEmailTrimmed, (_result) => {
          if (!_result.success) {
            this.setState({ forgotPwdLoading: false });
            toast.error(_result.message);
            return;
          }
          this.setState({
            isForgotPwd: false,
            isLinkSent: true,
            forgotEmail: "",
            forgotPwdLoading: false,
          });
        });
      } else {
        this.setState({ forgotPwdLoading: false });
        toast.error("Failed to send the reset password email. Please try again later.");
      }
    //   });
    });
  };

  toggleForgotPwd = () => {
    const { isForgotPwd } = this.state;
    this.setState({
      isForgotPwd: !isForgotPwd,
      forgotEmail: "",
    });
  };

  toggleLinkSent = () => {
    const { isLinkSent } = this.state;
    this.setState({
      isLinkSent: !isLinkSent,
    });
  };

  renderForgotPwdModal = () => {
    const { forgotEmail, forgotPwdLoading } = this.state;
    return (
      <Modal
        open
        maskClosable={false}
        closable={false}
        centered
        footer={null}
        onConfirm={this.sendResetPwdLink}
        width="480px"
        className="forgotPasswordModal"
      >

        <div className="forgotPasswordDel">
          <div className="forgotPasswordDelTitle">Forgot Password? </div>
          <div className="forgotPasswordDelTitleMessage">Enter the email address associated with your account</div>
          <Input
            className="Login__Input ForgotPassword__Input"
            type="text"
            name="forgotEmail"
            value={forgotEmail || ""}
            placeholder="Email"
            onChange={(e) => this.onChange(e)}
          />
          <div className="forgotPasswordDelBtn">
            <StyledButton
              type="primary"
              ghost
              onClick={this.toggleForgotPwd}
            >
              Cancel
            </StyledButton>
            <StyledButton
              type="primary"
              loading={forgotPwdLoading}
              disable={forgotPwdLoading}
              onClick={() => this.sendResetPwdLink()}
            >
              Submit
            </StyledButton>
          </div>
        </div>
      </Modal>
    );
  };

  renderLinkSentModal = () => (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      onConfirm={this.toggleLinkSent}
      onCancel={this.toggleLinkSent}
      width="456px"
      wrapClassName="login"
      className="linkSentModal"
    >
      <div className="forgotPasswordDel">
        <div className="forgotPasswordDelTitle">Link Sent</div>
        <div className="forgotPasswordDelTitleMessage">Follow the link in your email to reset your password.</div>
        <div className="forgotPasswordDelBtn">
          <StyledButton
            type="primary"
            onClick={this.toggleLinkSent}
          >
            Okay
          </StyledButton>
        </div>
      </div>
    </Modal>
  );

  toggleRememberMe = () => {
    const { rememberMe } = this.state;
    this.setState({ rememberMe: !rememberMe });
  }

  onSignUp = () => {
    const { history } = this.props;
    history.push("/signup");
  };

  render() {
    const {
      isForgotPwd,
      isLinkSent,
      email,
      password,
      emailError,
      passwordError,
      slideOut,
      rememberMe,
      loginLoading,
    } = this.state;

    return (
      <Slide direction="left" triggerOnce reverse={slideOut}>
        <div className="login-page">
          {isForgotPwd && this.renderForgotPwdModal()}
          {isLinkSent && this.renderLinkSentModal()}
          <img
            src={esv_logo}
            alt="esv"
            className="logoLoginPage"
          />
          <form className="loginMainContainer">
            <div className="login-box">
              <div className="login-header">
                Log in
              </div>
              <div className="login-header-subtitle">
                Log in with your data that you entered during your Sign Up process
              </div>
              <LoginInput
                label="Email ID"
                id="login-email-inputid"
                type="text"
                name="email"
                autoComplete="username"
                value={email}
                onChange={(e) => this.onChange(e)}
                error={emailError}
                clearError={() => this.setState({ emailError: false })}
              />
              <LoginInput
                label="Password"
                id="login-password"
                type="password"
                name="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => this.onChange(e)}
                inputRef={this.passwordInput}
                isPassword
                error={passwordError}
                clearError={() => this.setState({ passwordError: false })}
                onEnter={this.onSubmit}
              />

              <div className="login-remember-forgot-row">
                {/* <Checkbox
                      className="register-link"
                      // checked={selectedTestIdx === i}
                      // onChange={event => this.onChangeCheckbox(event, i, "sub2")}
                    >
                      Remember Me
                    </Checkbox> */}
                <label className="loginCheckboxContainer register-link">
                  <input
                    name="all"
                    id="all"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={this.toggleRememberMe}
                  />
                  <span className="loginSmallCheckmark" />
                  <span className="login-remember-me-text">Remember Me</span>
                </label>
                <button type="button" className="forgot-link" onClick={this.toggleForgotPwd}> Forgot password? </button>
              </div>

              <div className="login-button-container">
                <StyledButton
                  type="primary"
                  className="Login__Button"
                  loading={loginLoading}
                  disable={loginLoading || (!password.length || !email.length)}
                  onClick={() => this.onSubmit()}
                >
                  Log In
                </StyledButton>
              </div>

              {/* <div className="Login__SignupLinkDiv">
                <span>
                  Don’t have an account?
                </span>
                <button type="button" className="signUp-link" onClick={this.onSignUp}>Sign up</button>
              </div> */}
            </div>

            <div className="loginImage">
              <img
                className="loginImageCharacters"
                src={loginMainIcon}
                alt="esv"
              />
              <img
                className="loginImageBubbles"
                src={loginBgIcon}
                alt="esv"
              />
            </div>
          </form>
        </div>
      </Slide>
    );
  }
}

Login.contextType = AppContext; // allows class component to consume context, access via this.context
export default Login;

import React from "react";
import { Modal } from "antd";
// eslint-disable-next-line import/no-extraneous-dependencies
import Lottie from "lottie-react";
import SuccessAnimationData from "../../../assets/images/sample-submission/animation/SuccessfulSubmissionAnimation.json";
import "./SuccessAnimation.css";

export default function SuccessAnimation({ onConfirm, onCancel }) {
  return (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      onConfirm={onConfirm}
      onCancel={onCancel}
      width="452px"
      className="SuccessSampleAlert"
    >
      <div className="SampleShareBtn">
        <Lottie animationData={SuccessAnimationData} />
      </div>
      <div className="SampleShareTitle">Successfully submitted!</div>

    </Modal>
  );
}

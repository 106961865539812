import React, { useRef, useState } from "react";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Select,
  Tag,
  // Tooltip,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { debounce } from "lodash";

import MyForm from "../../MyForm";
import AddressSection from "./CompanyContactForm/Components/AddressSection";
import FormButtons from "./ReusableComponents/FormButtons";

import { checkUser } from "../../../../utils/common";
import { validateEmail } from "../../../Common/utils/validationUtils";

import { ReactComponent as CloseIcon } from "../../../../assets/images/onboarding/clearfield_2.svg";

import "./CompanyInfoForm.css";

export default function CompanyInfoForm({
  initialValues, onNextButton, onFinishFailed, isExistingCustomer, addCompany, isSupport, userCredential,
  // stepChangeInProgress,
  // handleFormStepChange,
}) {
  const FORM_NAME = "company_info_form";
  const [form] = useForm();
  const emailValidationCache = useRef(new Map()); // store strings that we've validated before to cut down on computation time
  const [loading, setLoading] = useState(false);
  // /** Hook that handles form saving + step change when step is clicked */
  // useHandleStepChange(FORM_NAME, form, stepChangeInProgress, handleFormStepChange);

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    layout: "vertical",
  };

  /**
   * First check if string is in emailValidationCache. If it is, return the cached value. Else, run validateEmail and store the result in cache.
   * @param {String} string email input
   * @returns {Boolean} true if valid else false
   */
  const cachedValidateEmail = (string) => {
    if (emailValidationCache.current.has(string)) { // check if string is in cache already
      return emailValidationCache.current.get(string);
    }
    const isValid = validateEmail(string); // if not in cache, call validateEmail and save email in cache
    emailValidationCache.current.set(string, isValid);
    return isValid;
  };

  /**
   * If removed value is the admin's email, add it back and revalidate (to get rid of empty error)
   * @param {String} value removed value
   */
  const onDeselect = (value) => {
    const isAdminEmail = value === initialValues.distribution_list[0];
    if (isAdminEmail) {
      form.setFieldValue("distribution_list", [value]);
      form.validateFields(["distribution_list"]);
    }
  };

  const tagRender = ({ label, onClose }) => {
    const onPreventMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const isAdminEmail = (addCompany && isSupport) ? (label === form.getFieldValue("customer_email")) : (label === initialValues.distribution_list[0]);
    const validEmail = cachedValidateEmail(label);
    return (
    // <Tooltip title={validEmail ? "" : "Email is invalid"}>
      <Tag
        color={validEmail ? "default" : "error"}
        icon={validEmail ? undefined : <ExclamationCircleOutlined />}
        onMouseDown={onPreventMouseDown}
        onClose={onClose}
        closable={!isAdminEmail}
        className={isAdminEmail ? "CompanyInfoForm__Tag_Admin" : "CompanyInfoForm__Tag"}
        closeIcon={<CloseIcon />}
        iconSize="small"
      >
        {label}
      </Tag>
    // </Tooltip>
    );
  };

  const distributionListValidator = async (_, emails) => {
    if (!emails?.length || emails.every((email) => cachedValidateEmail(email))) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please remove invalid email(s)."));
  };

  const maxLengthValidator = async (_, emails) => {
    if (emails?.length) {
      const emailsString = emails.map((email) => (email ? email.trim() : "")).join(",");
      if (emailsString.length > 500) {
        return Promise.reject(new Error("Distribution list character limit exceeded. Please remove email(s)."));
      }
    }
    return Promise.resolve();
  };

  const updatingDistributions = async (email) => {
    const currentDistributions = form.getFieldValue("distribution_list");
    const updatedDistributions = [...currentDistributions];
    if (addCompany && isSupport) {
      updatedDistributions[0] = email; // update the first email with the new admin email
      form.setFieldsValue({ distribution_list: updatedDistributions });
    }
    return Promise.resolve();
  };

  const debouncedUpdatingDistributions = debounce(updatingDistributions, 500);

  const userExistsValidation = async (_, email) => {
    setLoading(true);
    try {
      const userExists = await checkUser(email);
      if (userExists.success) {
        form.setFieldsValue({ customer_name: userExists.user_name });
        setLoading(false);
        return Promise.resolve();
      }
      form.setFieldsValue({ customer_name: "" });
      setLoading(false);
      if (userExists.message === "Something went wrong.") {
        return Promise.reject(new Error("Failed to check user existence."));
      }
      return Promise.reject(new Error("User does not exist. Please use a existing email."));
    } catch (error) {
      setLoading(false);
      return Promise.reject(new Error("Failed to check user existence."));
    }
  };

  const nextStepHandler = (values) => {
    onNextButton(FORM_NAME, values);
  };

  return (
    <MyForm
      {...layout}// eslint-disable-line react/jsx-props-no-spreading
      form={form}
      initialValues={initialValues}
      requiredMark={false}
      validateTrigger={["onChange", "onBlur"]}
      name={FORM_NAME}
      onFinish={(values) => nextStepHandler(values)}
      onFinishFailed={onFinishFailed}
      className="OnboardingForm"
    >
      <div className="CompanyContactForm__ContactInfoSection">
        <div className="OnboardingFormTitle">Customer Info</div>

        <Form.Item
          name="company_name"
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Company name</span>}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input placeholder="Enter Company Name" allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }} disabled={addCompany ? false : isExistingCustomer} />
        </Form.Item>
        {(isExistingCustomer || addCompany) && (
        <Form.Item
          name="customer_email"
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">{addCompany ? "Admin Email" : "Email"}</span>}
          rules={[
            {
              required: true,
              message: "",
            },
            {
              validator: isExistingCustomer ? undefined : userExistsValidation,
              // validateTrigger: "onBlur",
            },
          ]}
        >
          <Input disabled={!(addCompany && isSupport)} type="email" allowClear={{ clearIcon: <CloseIcon className="Clear__Button" /> }} onChange={(e) => debouncedUpdatingDistributions(e.target.value)} placeholder="Enter Admin Email" />
        </Form.Item>
        )}
        <Form.Item
          name="customer_name"
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Admin Name</span>}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input disabled placeholder="Enter Admin Name" />
        </Form.Item>

        {(!isExistingCustomer || addCompany) && (
        <Form.Item
          name="distribution_list"
          label={<span className="CompanyContactForm__ContactInfoSection_FieldsText">Distribution List</span>}
          rules={[
            {
              required: true,
              message: "",
            },
            {
              validator: distributionListValidator,
            },
            {
              validator: maxLengthValidator,
            },
          ]}
        >
          <Select
            mode="tags"
            open={false}
            placeholder="Enter list of emails separated by commas. Ex: email@domain.com,email2@domain.com"
            suffixIcon={null}
            tokenSeparators={[",", " ", ";"]}
            tagRender={tagRender}
            onDeselect={onDeselect}
            onChange={(value) => {
              if (form.getFieldValue("distribution_list").length === 0 && value.length === 0) {
                form.setFieldsValue({ distribution_list: [form.getFieldValue("customer_email")] });
              }
            }}
            className="CompanyInfoForm__TagSelect"
          />
        </Form.Item>
        )}
        { (!isExistingCustomer || addCompany) && (
          <>
            <div className="OnboardingFormSubTitle">
              <span />
              <p className="OnboardingFormSubTitleText">Company address</p>
            </div>
            <AddressSection namePath={["address"]} disabled={false} addCompany={addCompany} companyName={userCredential.company_name} />
          </>
        )}

      </div>
      <Form.Item>
        <FormButtons showBack={false} loading={loading} />
      </Form.Item>
    </MyForm>
  );
}

import React from "react";
import Modal from "antd/es/modal/Modal";
import "./DeleteProductConfirmation.css";
import { toast } from "react-toastify";

const DeleteProductConfirmation = ({
  cardName,
  onDeleteProduct,
  onToggleModal,
  isInquiry,
}) => {
  const onDeleteButtonClick = async () => {
    const success = await onDeleteProduct();
    if (success) {
      toast.success(`"${cardName}" has been deleted successfully`);
      onToggleModal();
    }
  };

  return (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      onConfirm={onDeleteButtonClick}
      onCancel={onToggleModal}
      width="448px"
      className="DeleteProductConfirmation"
    >
      <div className="docConfirmDel">
        {!isInquiry
        && (
        <div className="docConfirmDelTitle">
          Delete &quot;
          {cardName}
          &quot;
          {" "}
        </div>
        )}
        <div className="docConfirmDelTitleMessage">
          Are you sure you want to delete this
          {`${isInquiry ? " Inquiry" : " Card"}`}
          ?
        </div>
        <div className="docConfirmDelBtn">
          <button type="button" onClick={onToggleModal} className="docConfirmDelBtnCancel">Cancel</button>
          <button type="button" onClick={onDeleteButtonClick} className="docConfirmDelBtnDelete">Delete</button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteProductConfirmation;

/** Fixes the random wrapping on the PDF */
export const softHyphenate = (text) => (text.split("").join("\u{00AD}"));

/** calculates the widths for sample_id and result based on the number of selected items. */
export const calculateWidths = (uniqueTests) => {
  const numSelected = uniqueTests.filter((item) => item.selected).length;
  let sampleWidth;
  let resultWidth;

  switch (numSelected) {
    case 0:
    case 1:
      sampleWidth = 50;
      resultWidth = 50;
      break;
    case 2:
      sampleWidth = 33.33;
      resultWidth = 33.33;
      break;
    case 3:
      sampleWidth = 25;
      resultWidth = 25;
      break;
    case 4:
      sampleWidth = 25;
      resultWidth = 18.75;
      break;
    case 5:
      sampleWidth = 25;
      resultWidth = 15;
      break;
    default:
      sampleWidth = 25;
      resultWidth = 15;
  }
  return { sampleWidth, resultWidth };
};

import React, { useEffect, useRef, useState } from "react";

import "./OnboardingForm.css";
import { Steps } from "antd";
import { AES, enc } from "crypto-js";
import { mapValues } from "lodash";
import moment from "moment";
import { useLocation } from "react-router";
import { toast } from "react-toastify";

import AddCompanySuccessLandingPage from "../../AddCompany/AddCompanySuccessLandingPage";
import NoResults from "../../Common/NoResultsGIF";
import StyledButton from "../../Common/UIComponents/StyledButton";
import FormLayout from "../FormLayout";
import FormPageLayout from "../FormPageLayout";
import OnboardingWelcomePage from "../OnboardingWelcomePage";
import {
  RenderIconOnPreviousClick, RenderIconOnNextClick, RenderIconOnPreviousClickForCustomerExist, RenderIconOnNextClickForCustomerExist,
} from "./RenderStepsIcon";
import CompanyContactForm from "./SubForms/CompanyContactForm/CompanyContactForm";
import CompanyInfoForm from "./SubForms/CompanyInfoForm";
import FieldsForm from "./SubForms/FieldsForm/FieldsForm";
import LinkReportForm from "./SubForms/LinkReportForm";
import SignupForm from "./SubForms/SignupForm";

import { getUserCredentials } from "../../../utils/common";
import { AES_128_BIT_KEY } from "../../../utils/Constant";
import { validateEmail, validatePhoneNumber } from "../../Common/utils/validationUtils";

import { submitOnboardingForm } from "../../../actions/onboarding";

import pumpkinIcon from "../../../assets/images/environment/pumpkin.gif";
import { ReactComponent as AccountCreation } from "../../../assets/images/onboarding/AccountCreation.svg";
import { ReactComponent as ContactInfo } from "../../../assets/images/onboarding/ContactInfo.svg";
import { ReactComponent as CustomerInfo } from "../../../assets/images/onboarding/CustomerInfo.svg";
import { ReactComponent as ESVLogo } from "../../../assets/images/onboarding/ESVLogo.svg";
import { ReactComponent as Main } from "../../../assets/images/onboarding/main.svg";

export default function OnboardingForm({
  history, statusMessage, setStatusMessage, addCompany = false,
}) {
  const { search } = useLocation();
  const [stepItems, setStepItems] = useState([{
    title: <CustomerInfo />,
    description: "Customer Info",
    disabled: false,
    formName: "company_info_form",
    icon: <Main />,
  },

  {
    description: "Contact Info",
    title: <ContactInfo fill="#6C7E8E" />,
    disabled: true,
    formName: "company_contact_form",
    icon: <Main />,
  },
  {
    title: <ESVLogo fill="#6C7E8E" />,
    description: "ESV",
    disabled: true,
    formName: "fields_form",
    icon: <Main />,
  },
  {
    title: <AccountCreation fill="#6C7E8E" />,
    description: "Account Creation",
    disabled: true,
    formName: "signup_form",
    icon: <Main />,
  },
  ]);
  const [stepChangeInProgress, setStepChangeInProgress] = useState(null); // { diff: Number } if step change in progress set diff (ex: -2, -1, 1, 2 etc.) else null
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    company_info_form: {
      // company_name: "",
      customer_name: "",
      customer_email: "",
      distribution_list: null,
      same_address: false,
    },
    company_contact_form: {
      same_as_address: false,
    },
    fields_form: {
      product_info: {
        sample_type: "Sample Type",
        date: "Received Date",
        editable_fields: [
          { json_field: "item", title_field: "Item" },
          { json_field: "lot", title_field: "Lot" },
          { json_field: "description", title_field: "Description" },
        ],
        sample_supply: "",
        product_link: [],
        add_company: "",
      },
      environment_info: {
        sample_type: "Sample Type",
        date: "Received Date",
        swab_number: "Swab Number",
        zone: "Zone",
        section: "Section",
        editable_fields: [],
        env_sample_supply: "",
        environment_link: [],
        environment_supplies_needed: "",
        env_add_company: "",
      },
    },
    link_report_form: {},
    signup_form: {},
  });
  const [currentFormIdx, setCurrentFormIdx] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [existingUser, setExistingUser] = useState(false);
  const [userMail, setUserMail] = useState("");
  const [display, setDisplay] = useState(true);
  const [isCustomerExist, setIsCustomerExist] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  const defaultPayloadValues = useRef({ sales_email: "", csm_email: "" });
  const [displaySuccessScreen, setDisplaySuccessScreen] = useState(false);
  const [userCredential, setUserCredentials] = useState("");
  const onStepChange = (value) => {
    if (value >= currentFormIdx) {
      return;
    }
    for (let i = value + 1; i <= 3; i++) {
      setStepItems((prevStepItems) => prevStepItems.map((stepItem, stepIdx) => {
        if (i === stepIdx) {
          return { ...stepItem, title: isCustomerExist && !addCompany ? <RenderIconOnPreviousClickForCustomerExist Inx={i} /> : <RenderIconOnPreviousClick Inx={i} /> };
        }
        return stepItem;
      }));
    }
    setStepChangeInProgress({ diff: value - currentFormIdx });
  };

  /**
   * Save current form data and go to new step
   * @param {String} formName name of form
   * @param {Object} formValues values of form
   * @param {Number} _newIdx index of new step
   */
  const handleFormStepChange = (formName, formValues, diff) => {
    setFormData({ ...formData, [formName]: formValues });
    if (stepChangeInProgress) {
      setStepChangeInProgress(null);
    }
    setCurrentFormIdx(currentFormIdx + diff);
  };

  const onBackButton = (formName, formValues) => {
    setStepItems(
      (prevStepItems) => prevStepItems.map((stepItem, stepIdx) => {
        if (currentFormIdx === stepIdx) {
          return { ...stepItem, title: <RenderIconOnPreviousClick Inx={currentFormIdx} /> };
        }
        return stepItem;
      }),
    );
    handleFormStepChange(formName, formValues, -1); // save form, go to prev step
  };

  const onNextButton = (formName, formValues) => {
    handleFormStepChange(formName, formValues, 1); // save form, go to next step
  };

  const handleSubmit = (formName, formValues) => {
    handleFormStepChange(formName, formValues, 0); // save form, stay on current step
    setSubmitting(true);
  };

  /* Update which steps are disabled when currentFormIdx changes */
  useEffect(() => {
    setStepItems((prevStepItems) => prevStepItems.map((stepItem, stepIdx) => {
      if (currentFormIdx === stepIdx) {
        return { ...stepItem, title: isCustomerExist && !addCompany ? <RenderIconOnNextClickForCustomerExist Inx={currentFormIdx} /> : <RenderIconOnNextClick Inx={currentFormIdx} /> };
      }
      return stepItem;
    }));
    setStepItems((prevStepItems) => prevStepItems.map((stepItem, stepIdx) => ({ ...stepItem, disabled: submitting || stepIdx > currentFormIdx })));
  }, [currentFormIdx, submitting, isCustomerExist]); // eslint-disable-line

  /** Verify link and load form */
  useEffect(() => {
    async function handleParseLink() {
      try {
        const encrypted = search.slice(1); // removes "?" at the beginning of string
        if (search) {
          const decrypted = AES.decrypt(encrypted, AES_128_BIT_KEY).toString(enc.Utf8);
          const searchParams = new URLSearchParams(decrypted);
          const salesEmail = searchParams.get("sales_email");
          const csmEmail = searchParams.get("csm_email");
          const companyName = searchParams.get("company_name");
          const customerName = searchParams.get("customer_name");
          const customerEmail = searchParams.get("customer_email");
          const customerID = searchParams.get("customer_id");
          const expirationDate = searchParams.get("expiration"); // format: 2023-09-30T23:38:13Z (Z means UTC time)

          if (!salesEmail || !csmEmail || !companyName || !customerName || !customerEmail || !expirationDate) {
            if (!addCompany) {
              setLoading(false);
              setInvalidMessage("Invalid link");
              return;
            }
          }

          /** Make sure the current day is not after expiration date */
          const momentExpirationDate = moment.utc(expirationDate).endOf("day");
          if (!momentExpirationDate.isValid()) {
            setLoading(false);
            setInvalidMessage("Invalid link");
            return;
          }

          const currentUTCDate = moment.utc();
          const isExpired = currentUTCDate.isAfter(momentExpirationDate);

          if (isExpired) {
            setLoading(false);
            setInvalidMessage("Link has expired");
            return;
          }

          const initialFormValues = {
            ...formData,
            company_info_form: {
              company_name: companyName,
              customer_name: customerName,
              customer_email: customerEmail,
              distribution_list: [customerEmail],
            },
          };

          setUserMail(customerEmail);

          const default_payload_values = { sales_email: salesEmail, csm_email: csmEmail };
          if (customerID) {
            setIsCustomerExist(true);
            default_payload_values.company_info = { distribution_list: [] };
            default_payload_values.customer_id = customerID;
            setStepItems(stepItems.filter(({ formName }) => formName !== "company_contact_form"));
            delete initialFormValues.company_contact_form;
          }
          setFormData(initialFormValues);
          defaultPayloadValues.current = default_payload_values;
          setLoaded(true);

          /* In case of the add Company we set data for the company from the session storage. */
        } else if (addCompany) {
          const userCredentials = await getUserCredentials();
          setUserCredentials(userCredentials);
          setIsSupport(userCredentials?.uid === "support");
          const emails = userCredentials.user_email || "";
          const initialFormValues = {
            ...formData,
            company_info_form: {
              company_name: "",
              customer_name: userCredentials.user_name,
              customer_email: emails,
              distribution_list: [emails] ?? [],
            },
          };
          setUserMail(emails);
          /* In case of the support  */
          const default_payload_values = {
            company_info: { distribution_list: emails || "" },
            // customer_id: customerData.customer_id,
            company_name: userCredentials.company_name,
          };

          setStepItems(stepItems.filter(({ formName }) => formName !== "signup_form"));

          defaultPayloadValues.current = default_payload_values;
          setIsCustomerExist(true);
          setFormData(initialFormValues);
          setLoaded(true);
        } else {
          setLoading(false);
          setInvalidMessage("Invalid link");
        }
      } catch (e) {
        setLoading(false);
        setInvalidMessage("Invalid link");
      }
    }

    handleParseLink();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** Temp Fix for UI issues on mount, setTimeout() fixes the issue */
  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoading(false);
        setShowForm(true);
      }, 200);
    }
  }, [loaded]);

  /** Submit form */
  useEffect(() => {
    function trimValues(dataObj) {
      return mapValues(dataObj, (fieldVal) => { // trim all vals
        if (typeof fieldVal === "string") {
          return fieldVal.trim();
        }
        if (typeof fieldVal === "object" && fieldVal.constructor === Array) {
          return fieldVal.map((val) => val.trim()); // trim all vals in array
        }
        if (typeof fieldVal === "object") {
          return trimValues(fieldVal); // recursively trim
        }
        return "";
      });
    }

    async function handleSubmitForm() {
      const payload = {};
      Object.keys(formData).forEach((formName) => {
        const data = formData[formName];
        if (formName === "company_info_form") {
          const {
            customer_name,
            company_name,
            distribution_list,
            address,
            customer_email,
          } = data;
          const company_info = trimValues({
            customer_name,
            company_name,
            distribution_list,
          });
          if (address) {
            const contact = trimValues({
              address: {
                city: address.city,
                country: address.country,
                line_2: address.line_2,
                state: address.state,
                street: address.street,
                zipcode: address.zipcode,
              },
            });
            payload.contact = contact;
          }
          payload.company_info = company_info;
          if (customer_email || addCompany) payload.company_info.customer_email = customer_email;
        } else if (formName === "company_contact_form") {
          const {
            contact_name, position, email, phone_number, billing, same_as_address,
          } = data;
          const contact = trimValues({
            ...payload.contact,
            contact_name,
            position,
            email,
            phone_number,
            billing: {
              bill_to: billing.bill_to,
              ap_contact: billing.ap_contact,
              email: billing.email,
              phone_number: billing.phone_number,
              address: billing.address,
            },
          });
          if (same_as_address) {
            contact.billing.address = contact.address;
          }
          payload.contact = contact;
          if (!addCompany) payload.company_info.customer_email = contact.email;
        } else if (formName === "fields_form") {
          const {
            product_info,
            environment_info,
          } = data;
          if (product_info.sample_supply === "Yes") {
            const nonEditableFields = [{ json_field: "sample_type", title_field: "Sample Type" }, { json_field: "received_date", title_field: "Received Date" }];
            const fields = [...nonEditableFields, ...(product_info.editable_fields ?? [])];
            payload.product_info = {
              fields,
              product_link: product_info.product_link,
            };
          }
          if (environment_info.env_sample_supply === "Yes") {
            const nonEditableFields_env = [
              { json_field: "sample_type", title_field: "Sample Type" },
              { json_field: "received_date", title_field: "Received Date" },
              { json_field: "swab_number", title_field: "Swab Number" },
              { json_field: "zone", title_field: "Zone" },
              { json_field: "section", title_field: "Section" },
            ];
            const field_env = [...nonEditableFields_env, ...(environment_info.editable_fields ?? [])];
            payload.environment_info = {
              fields: field_env,
              environment_link: environment_info.environment_link,
              environment_supplies_needed: environment_info.environment_supplies_needed,
            };
          }
        } else if (formName === "signup_form") {
          const {
            company_name, email, phone_number, password, customer_name,
          } = data;
          const signup = trimValues({
            company_name, email, phone_number, customer_name,
          });
          payload.signup = { ...signup, password }; // don't trim password
          if (addCompany) {
            payload.signup.company_name = formData.company_info_form.company_name;
          }
        }
      });

      /** Send base url */
      payload.base_url = window.location.origin;

      /** Send sales and csm emails. If existing customer, send customer id and empty list for distribution_list. */
      const {
        sales_email, csm_email, customer_id, company_info,
      } = defaultPayloadValues.current;
      payload.sales_email = sales_email;
      payload.csm_email = csm_email;
      if (customer_id && !addCompany) {
        payload.customer_id = customer_id;
        payload.company_info.distribution_list = company_info.distribution_list;
      }

      /** send a boolian value indictaing if the user already exists  */
      payload.existing_user = addCompany ? "true" : existingUser;

      /**
       * Remove existing customer details from payload
       * @param {string} customer_id
       * @param {string} company_domain
       * @param {string} sales_email
       * @param {string} cms_email
       * set values for the form SignUp from the existing values and session storage
       * disabling the interceptor to remove unnecessary values from the payload
       */
      if (addCompany) {
        // Delete extra fields
        Object.keys(payload).forEach((key) => {
          if (["company_domain", "customer_id", "company_name", "sales_email", "csm_email"].includes(key)) {
            delete payload[key];
          }
        });
        payload.signup.customer_name = payload.company_info.customer_name;
        payload.signup.email = payload.company_info.customer_email;
        payload.current_company_name = await getUserCredentials()?.company_name;
        payload.disableInterceptor = true;
      }

      const { success, message } = await submitOnboardingForm(payload);
      setSubmitting(false);
      if (success) {
        const newMessage = message === "Success" ? "Signup successful" : `Warning: ${message}`;
        if (addCompany) {
          setDisplaySuccessScreen(true);
        } else if (statusMessage !== newMessage) {
          setStatusMessage(newMessage);
          setSubmitSuccess(true);
          toast.success(newMessage);
          history.push("/login");
        } else {
          history.push("/login");
        }
      } else if (addCompany) {
        toast.error(message);
      } else {
        setStatusMessage("Something went wrong!");
        setSubmitSuccess(false);
        toast.error(message);
      }
    }

    if (submitting) {
      handleSubmitForm();
    }
  }, [formData, submitting]); // eslint-disable-line react-hooks/exhaustive-deps

  /** After status message is updated, go to success page */
  useEffect(() => {
    if (submitSuccess) {
      history.push("/login");
    }
  }, [statusMessage, submitSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Show toast error when validation fails on next/submit */
  const onFinishFailed = () => {
    toast.error("Invalid input");
  };

  const emailValidator = async (_, value) => {
    if (!value || validateEmail(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Invalid email"));
  };

  const phoneValidator = async (_, value) => {
    if (!value || validatePhoneNumber(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Invalid phone number"));
  };

  if (loading) {
    return (
      <FormPageLayout addCompany={addCompany}>
        {/* <div className="OnboardingForm__Loading">
          <LoadingDots width={32} />
        </div> */}
      </FormPageLayout>
    );
  }

  if (!showForm) {
    return (
      <FormPageLayout addCompany={addCompany}>
        <div className="OnboardingForm__InvalidLink">
          <NoResults message={invalidMessage} image={pumpkinIcon} />
        </div>
      </FormPageLayout>
    );
  }

  if (displaySuccessScreen) {
    const companyName = formData.company_info_form.company_name;
    return (
      <AddCompanySuccessLandingPage newCompanyName={companyName} user_Title={userCredential?.user_title} currCompanyName={userCredential?.company_name} />
    );
  }
  return (
    <FormPageLayout addCompany={addCompany} className={addCompany && "OnboardingForm__AddCompany__FormPageLayout"}>
      {display ? (
        <OnboardingWelcomePage setDisplay={setDisplay} display={display} history={history} statusMessage={statusMessage} setStatusMessage={setStatusMessage} customerName={formData.company_info_form.customer_name} CustomerExist={isCustomerExist} addCompany={addCompany} />
      )
        : (
          <>
            <div className="OnboardingForm__StepsContainer">
              <Steps
                items={stepItems.map(({
                  title, disabled, description, icon,
                }) => ({
                  title, disabled, description, icon,
                }))}
                current={currentFormIdx}
                className="OnboardingForm__Steps"
                direction="vertical"
                labelPlacement="vertical"
                onChange={onStepChange}
              />
              {
                addCompany && (<StyledButton type="default" className="OnboardingForm__AddCompany__BackBtn" onClick={() => history.push("/sample-submission")}>Exit</StyledButton>)
              }
            </div>
            <FormLayout title={stepItems[currentFormIdx].title} className="OnboardingFormLayout">
              {stepItems[currentFormIdx].formName === "company_info_form" && (
              <CompanyInfoForm
                initialValues={formData.company_info_form}
                onNextButton={onNextButton}
                emailValidator={emailValidator}
                onFinishFailed={onFinishFailed}
                stepChangeInProgress={stepChangeInProgress}
                handleFormStepChange={handleFormStepChange}
                isExistingCustomer={defaultPayloadValues.current.customer_id}
                addCompany={addCompany || false}
                isSupport={isSupport}
                userCredential={userCredential}
              />
              )}
              {stepItems[currentFormIdx].formName === "company_contact_form" && (
              <CompanyContactForm
                initialValues={formData.company_contact_form}
                addressData={formData.company_info_form}
              // email={formData.company_info_form?.customer_email ?? ""}
                companyName={formData.company_info_form?.company_name ?? ""}
                emailValidator={emailValidator}
                phoneValidator={phoneValidator}
                onNextButton={onNextButton}
                onBackButton={onBackButton}
                onFinishFailed={onFinishFailed}
                stepChangeInProgress={stepChangeInProgress}
                handleFormStepChange={handleFormStepChange}
              />
              )}
              {stepItems[currentFormIdx].formName === "fields_form" && (
              <FieldsForm
                initialValues={formData.fields_form}
                onNextButton={onNextButton}
                onBackButton={onBackButton}
                onFinishFailed={onFinishFailed}
                stepChangeInProgress={stepChangeInProgress}
                handleFormStepChange={handleFormStepChange}
                isExistingCustomer={defaultPayloadValues.current.customer_id}
                existingUser={existingUser}
                addCompany={addCompany || false}
                companyName={defaultPayloadValues.current.company_name || ""}
                handleSubmit={handleSubmit}
                submitting={submitting}
              />
              )}
              {stepItems[currentFormIdx].formName === "link_report_form" && (
              <LinkReportForm
                initialValues={formData.link_report_form}
                fieldsData={formData?.fields_form}
                onNextButton={onNextButton}
                onBackButton={onBackButton}
                onFinishFailed={onFinishFailed}
                stepChangeInProgress={stepChangeInProgress}
                handleFormStepChange={handleFormStepChange}
                isExistingCustomer={defaultPayloadValues.current.customer_id}
              />
              )}
              {stepItems[currentFormIdx].formName === "signup_form" && (
              <SignupForm
                initialValues={formData.signup_form}
                email={userMail ?? ""}
                company_name={formData.company_info_form?.company_name ?? ""}
                customer_name={formData.company_info_form?.customer_name ?? ""}
                existingUser={existingUser}
                setExistingUser={setExistingUser}
                handleSubmit={handleSubmit}
                onBackButton={onBackButton}
                emailValidator={emailValidator}
                phoneValidator={phoneValidator}
                onFinishFailed={onFinishFailed}
                stepChangeInProgress={stepChangeInProgress}
                handleFormStepChange={handleFormStepChange}
                submitting={submitting}
              />
              )}
            </FormLayout>
          </>
        )}
    </FormPageLayout>
  );
}

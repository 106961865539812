import React from "react";
import { Switch } from "antd";
import "./StyledSwitch.css";

export default function StyledSwitch({
  autoFocus,
  checked,
  checkedChildren,
  className,
  defaultChecked,
  defaultValue,
  disabled,
  loading,
  size,
  unCheckedChildren,
  value,
  onChange,
  onClick,
}) {
  return (
    <Switch
      autoFocus={autoFocus}
      checked={checked}
      checkedChildren={checkedChildren}
      className={`StyledSwitch ${className}`}
      defaultChecked={defaultChecked}
      defaultValue={defaultValue}
      disabled={disabled}
      loading={loading}
      size={size}
      unCheckedChildren={unCheckedChildren}
      value={value}
      onChange={onChange}
      onClick={onClick}
    />
  );
}

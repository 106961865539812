import React, { useEffect, useState } from "react";

import "./WeekMonthCheckbox.css";

const INITIAL_WEEKDAYS = {
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
};

const INITIAL_MONTH_NUMBERS = Array(31).fill().reduce((acc, _, i) => {
  acc[(i + 1).toString()] = false;
  return acc;
}, {});

export default function WeekMonthCheckbox({
  type = "day", // day | month
  onChange,
  allowMultiple = false,
  containerStyle = "",
  selectedDay,
  setSelectedDay,
}) {
  const [values, setValues] = useState(type === "day" ? INITIAL_WEEKDAYS : INITIAL_MONTH_NUMBERS);

  useEffect(() => {
    if (allowMultiple && Array.isArray(selectedDay)) {
      const copy = { ...values };
      selectedDay.forEach((d) => {
        if (d in copy) {
          copy[d] = true;
        }
      });
      setValues(copy);
    }
  }, []); // eslint-disable-line

  /**
   * Handle check/select day
   * @param {Event} e Event
   * @param {string} day selected day
   */
  const handleSelectDay = (e, day) => {
    if (allowMultiple) {
      const valuesCopy = { ...values };
      valuesCopy[day] = e.target.checked;
      setValues(valuesCopy);
      const selectedValues = Object.entries(valuesCopy).reduce((acc, [value, checked]) => {
        if (checked) acc.push(value);
        return acc;
      }, []);
      onChange(selectedValues);
    } else if (selectedDay !== "" && selectedDay === day) {
      setSelectedDay("");
      onChange([]);
    } else {
      setSelectedDay(day);
      onChange([day]);
    }
  };

  const renderCheckbox = () => Object.entries(values).map(([day, checked]) => {
    const displayValue = type === "day" ? day[0].toUpperCase() : day;
    return (
      <div key={day}>
        <input
          type="checkbox"
          name={day}
          id={`check-${day}`}
          className="WeekMonthCheckbox__WeekDayInput"
          checked={checked || selectedDay === day}
          onChange={(e) => handleSelectDay(e, day)}
        />
        <label
          key={day}
          htmlFor={`check-${day}`}
          className="WeekMonthCheckbox__WeekDay"
        >
          {displayValue}
        </label>
      </div>
    );
  });

  return <div className={`WeekMonthCheckbox ${containerStyle}`}>{renderCheckbox()}</div>;
}

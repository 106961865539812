import { Modal } from "antd";
import React from "react";
import "./FailedEmailModal.css";

export default function FailedEmailModal({ handleCloseFailedEmailModal, failedEmails }) {
  return (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      onCancel={() => handleCloseFailedEmailModal()}
      width="600px"
      wrapClassName="FailedEmailModal"
      className="FailedEmailModalContainer"
    >
      <div className="FailedEmailModal">
        <div
          className="FailedEmailModalHeader"
        >
          Failed to invite the fallowing users.
        </div>
        <div
          className="FailedEmailModalEmailsContainer"
        >
          {failedEmails.map((email, i) => <span key={i} className="failedEmails">{email}</span>)}
        </div>
        <div className="FailedEmailModalBtns">
          <button
            type="button"
            onClick={() => handleCloseFailedEmailModal()}
            className="FailedEmailModalCancelBtn"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}

import axios from "axios";
// eslint-disable-next-line
import { partition } from "lodash";
import parsePinQuadrant from "../Containers/Env/helper";

import { API_URL } from "../utils/Constant";
import { getValueOfObj, getViewAsPerStatusCode } from "../utils/helpers";

/**
 * Get fields for environment
 * @param   {Boolean} type environment or product
 * @return  {Object} {success, sampleFields: {} }
 */
export const getEnvironmentFields = async (skipMaintenanceRedirect = false) => {
  try {
    const resp = await axios.get(`${API_URL}/environmentfields/`);
    const {
      fields,
      index0,
      link_pattern_fields,
      sampleid_fields,
      delimeter,
      link_pattern,
    } = resp?.data?.result;

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      fields,
      sampleid_fields,
      link_pattern_fields,
      index0,
      delimiter: delimeter,
      link_pattern,
    };
  } catch (e) {
    if (!skipMaintenanceRedirect) {
      getViewAsPerStatusCode(e);
    }
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Gets map titles and link pattern (defines company's dynamic fields) for given company
 * @return {Object} { success, mapTitles: [{ title, image_height, image_width, image_link }, ...]}
 */
export const getMapTitles = async () => {
  try {
    const resp = await axios.get(`${API_URL}/envmaps/`);
    const message = getValueOfObj(resp, "data", "message");

    let allMapTitles = [];
    if (message === "Success") {
      const allMaps = getValueOfObj(resp, "data", "result", "map_info");
      if (allMaps && allMaps.length > 0) {
        allMapTitles = allMaps;
      }
    }
    return { success: true, mapTitles: allMapTitles };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong.", mapTitles: [] };
  }
};

/**
 * Edit map title
 * @param {Object} payload { oldTitle, newTitle }
 * @returns {Object} { success, message }
 */
export const editMapTitle = async ({
  oldTitle, newTitle,
}) => {
  try {
    const resp = await axios.patch(`${API_URL}/envmaps/`, {
      new_title: newTitle,
      old_title: oldTitle,
    });
    return { success: resp.data.message === "Success", message: resp.data.message };
  } catch (e) {
    return { success: false, message: e.response?.data?.message };
  }
};

/**
 * Delete map title
 * @param {Object} payload { title }
 * @returns {Object} { success, message }
 */
export const deleteMapTitle = async ({ title }) => {
  try {
    const resp = await axios.delete(`${API_URL}/envmaps/`, {
      data: {
        title,
      },
    });

    if (resp.data.message !== "Success") {
      return { success: false, message: resp.data.message };
    }

    return { success: true, message: resp.data.message };
  } catch (e) {
    return { success: false, message: e.response?.data?.message };
  }
};

/**
 * Initial upload of map image
 * @param {Object} payload { image, title, height, width }
 * @returns {Object} { success, message }
 */
export const addMapImage = async ({
  image, title, height, width,
}) => {
  try {
    const resp = await axios.post(`${API_URL}/envmaps/`, {
      title,
      image,
      height,
      width,
    });
    if (resp.data.message !== "Success") {
      return { success: false, message: resp.data.message };
    }
    return { success: true, message: resp.data.message };
  } catch (e) {
    // getViewAsPerStatusCode(e);
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Gets zone/section lists for company
 * @return {Object} {success, message?, area_lists?}
 */
export const getAreaLists = async () => {
  try {
    const resp = await axios.get(`${API_URL}/getareainfo/`);
    const message = getValueOfObj(resp, "data", "message");
    if (message === "Success") {
      return { success: true, area_lists: resp.data.result };
    }
    return { success: false, message: resp.data.message };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
  * Get swabs for map, handles search and filter as well
  * @param {Object} payload { mapTitle, searchValue, specFlag, dateStart, dateEnd }
  * @param {AbortSignal} signal AbortController signal for current api call
  * @returns {Object} { success, message?, data?: { pinMap, dates } }
  */
export const getSwabs = async (payload, signal = null) => {
  try {
    const resp = await axios.get(`${API_URL}/getpoints/`, {
      params: {
        title: payload.mapTitle,
        search: payload.searchValue || "",
        spec_flag: payload.specFlag ?? -1,
        date_from: payload.dateStart || "",
        date_to: payload.dateEnd || "",
      },
      signal,
    });
    const message = getValueOfObj(resp, "data", "message");
    if (message === "Success") {
      const pinMap = new Map(); // a hashmap that preserves insertion order
      const points = resp.data.result; // list of sorted pins

      if (points) {
        for (let i = 0; i < points.length; i++) {
          const pin = points[i];
          const { x, y, quadrant } = parsePinQuadrant(pin.x_coordinate, pin.y_coordinate, pin.title);
          pin.x_coordinate = x;
          pin.y_coordinate = y;
          pin.quadrant = quadrant;
          pinMap.set(pin.location_id, pin);
        }
      }
      return {
        success: true,
        data: {
          pinMap,
          dates: resp.data.dates,
        },
      };
    }
    return { success: true, message };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Add swab to map. Unpinned swabs will have null coordinates.
 * @param {Object} payload { pointsList }
 * @returns {Object} { success, message?, successSwabs?, failedSwabs? }
 */
export const addSwab = async (payload) => {
  const convertSwab = (swab) => {
    const { x, y, quadrant } = parsePinQuadrant(swab.x_coordinate, swab.y_coordinate);

    swab.x_coordinate = x;
    swab.y_coordinate = y;
    swab.quadrant = quadrant;
  };

  try {
    const resp = await axios.post(`${API_URL}/envpoints/`,
      {
        swab_list: payload.pointsList,
      });
    const message = resp.data.message;
    if (message === "Success") {
      const successSwabs = [];
      const [success_swabs, failedSwabs] = partition(resp.data.result, (swab) => swab.message === "Success");

      for (let i = 0; i < success_swabs.length; i++) {
        const swab = success_swabs[i];
        convertSwab(swab);
        successSwabs.push({ swab });
      }

      return {
        success: true, message, successSwabs, failedSwabs,
      };
    }

    return { success: false, message };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Edits swab when user pins an unpinned swab, relocates a pinned swab, or removes swab
 * @param {Object} payload { category: "Edit" or "Delete", title, location_id, section, zone, swab_number, description, x_coordinate, y_coordinate }
 * @returns {Object} { success, message }
 */
export const editSwab = async (payload) => {
  try {
    const resp = await axios.patch(`${API_URL}/envpoints/`, {
      category: payload.category || "Edit",
      title: payload.title,
      location_id: payload.location_id,
      section: payload.section,
      sub_section: payload.sub_section,
      zone: payload.zone,
      swab_number: payload.swab_number,
      frequency: payload.frequency,
      description: payload.description,
      x_coordinate: payload.x_coordinate.toString(),
      y_coordinate: payload.y_coordinate.toString(),
    });

    const message = getValueOfObj(resp, "data", "message");
    const success = message === "Success";
    return { success, message };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Get list of samples/reports per swab
 * @param {Object} payload { location_id }
 * @returns {Object} { success, message?, reports?, default_index? } list of samples/reports, index for default sample
 */
export const getSwabReports = async (payload = {}) => {
  try {
    const response = await axios.get(`${API_URL}/envreports/`, {
      params: {
        // page: parseInt(payload.page) || 1,
        location_id: payload.location_id,
        action: "history_reports",
      },
    });

    const {
      message, result,
    } = response.data;
    const {
      report_history_list, default_sample_index, specs_status,
    } = result;

    return {
      success: message === "Success",
      reports: report_history_list,
      default_index: default_sample_index ?? 0,
      specs_status,
      // next_page_link: report_history_list.next_page_link,
      // total_page: report_history_list.total_page,
    };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Get all file paths for a swab
 * @param {Object} payload { location_id, page }
 * @returns {Object} { success, message, paths?, next_page_link?, total_page? }
 */
export const getAllFilePaths = async (payload = {}) => {
  try {
    const response = await axios.get(`${API_URL}/envreports/`, {
      params: {
        page: parseInt(payload.page) || 1,
        location_id: payload.location_id,
        action: "none",
      },
    });

    const {
      message, result,
    } = response.data;
    const {
      next_page_link, total_page, result: paths,
    } = result;
    return {
      success: message === "Success",
      message,
      paths,
      next_page_link,
      total_page,
    };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Get result for specified swab and received_date
 * @param {Object} payload { received_date, sample_id, sample_type, location_id }
 * @returns {Object} { success, message?, spec?: { identifier, index0, path, report_result, specs_flag } }
 */
export const getEnvSpec = async (payload) => {
  try {
    const params = {
      received_date: payload.received_date,
      sample_id: payload.sample_id,
      sample_type: payload.sample_type,
      location_id: payload.location_id,
    };

    const response = await axios.get(`${API_URL}/envreportspecs/`, { params });
    const data = response.data;
    const res = { spec: {} };

    if (data && data.result && data.result.length > 0) {
      res.spec = data.result[0];
    }
    return { success: true, ...res };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Get result for specified swab and received_date
 * @param {Object} payload { received_date, sample_id, sample_type, location_id }
 * @returns {Object} { success, message?, spec?: { identifier, index0, path, report_result, specs_flag } }
 */
export const updateEnvSampleImage = async (payload) => {
  try {
    const params = {
      received_date: payload.received_date,
      sample_id: payload.sample_id,
      sample_type: payload.sample_type,
      location_id: payload.location_id,
      image_path: payload.image_path,
    };

    const response = await axios.patch(`${API_URL}/envreportspecs/`, params);
    const data = response.data;
    const res = { spec: {} };

    if (data && data.result && data.result.length > 0) {
      res.spec = data.result[0];
    }
    return { success: true, ...res };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Get all the data for the environment analytics
 * @param {dates}
 * @param {string} category
 */
export const envAnalyticsDataFetch = async (payload) => {
  try {
    const params = {
      date_to: payload.date_to,
      date_from: payload.date_from,
    };
    if (!payload.date_to && !payload.date_from) {
      delete params.date_to;
      delete params.date_from;
    }

    const response = await axios.get(`${API_URL}/envanalyticsdata/`, { params });
    const data = response.data;

    return { success: true, ...data };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

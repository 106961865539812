import React, {
  useState, useRef, useEffect, useCallback,
} from "react";

import { debounce } from "lodash";
import Scrollbar from "react-scrollbars-custom";

import { ReactComponent as DropdownArrow } from "../../../assets/images/DropDownChevron.svg";

import "./ExtraFieldAutoComplete.css";

export default function ExtraFieldsAutoComplete({
  allowClear,
  suggestions,
  value,
  onSelect,
  onSearch,
  onFocus,
  onBlur,
  maxLength,
  placeholder,
  charLimit,
  letInputExceedCharLimit,
  className,
  customArrowClassName,
  markedLocations,
}) {
  const [inputValue, setInputValue] = useState(value || "");
  const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);
  const debouncedSearch = useCallback(debounce((event) => onSearch(event.target.value), 500), [markedLocations]);

  useEffect(() => {
    setFilteredSuggestions(
      suggestions.filter((suggestion) => suggestion.toLowerCase().includes(inputValue.toLowerCase())),
    );
  }, [inputValue, suggestions]);

  /**
   * Handles input change
   * @param {Event} e event
   */
  const handleInputChange = (e) => {
    e.persist();
    const newValue = e.target.value;
    if (!charLimit || letInputExceedCharLimit || newValue.length <= charLimit) {
      setInputValue(newValue);
      if (onSearch) debouncedSearch(e);
    }
  };

  /**
   * Handles focus on input
   */
  const handleFocus = () => {
    setShowDropdown(true);
    if (onFocus) onFocus();
  };

  /**
   * Handle input blur
   */
  const handleBlur = () => {
    setTimeout(() => setShowDropdown(false), 200); // Delay for selection handling
    if (onBlur) onBlur();
  };

  /**
   * Handle select from suggestion dropdown
   * @param {string} suggestion selected suggestion
   */
  const handleSelect = (suggestion) => {
    setInputValue(suggestion);
    debouncedSearch.cancel();
    if (onSelect) onSelect(suggestion);
    setShowDropdown(false);
  };

  /**
   * Clear input field
   */
  const handleClear = () => {
    setInputValue("");
    debouncedSearch.cancel();
    if (onSearch) onSearch("");
  };

  return (
    <div className={`auto-complete-replacement ${className}`}>
      <input
        type="text"
        ref={inputRef}
        value={inputValue}
        placeholder={placeholder}
        maxLength={maxLength && !letInputExceedCharLimit ? maxLength : undefined}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="auto-complete-replacement-input"
      />
      {allowClear && inputValue && (
        <button type="button" onClick={handleClear} className="clear-button">×</button>
      )}
      {!inputValue && filteredSuggestions.length > 0 && (
        <DropdownArrow
          className={`auto-complete-replacement-dropdown-arrow ${customArrowClassName}`}
          transform={`rotate(${showDropdown ? "180 0 -0.5" : 0})`}
        />
      )}
      {showDropdown && filteredSuggestions.length > 0 && (
        <div className="auto-complete-replacement-dropdown">
          <Scrollbar id="AutocompleteInputScrollbar">
            {filteredSuggestions.map((suggestion, index) => (
              <div
                key={index}
                className="dropdown-item"
                onMouseDown={() => handleSelect(suggestion)}
              >
                {suggestion}
              </div>
            ))}
          </Scrollbar>
        </div>
      )}
    </div>
  );
}

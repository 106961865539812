/** Characters not allowed in Sample Details input */
export const INVALID_CHARS = ["/u2022"];

export const COMPOSITE_FIELD_DELIMITER = "_:_";

/** Regex special chars are escaped i.e. "\\(", "\\)" */
export const INVALID_CHARS_ESCAPED = [
  " \\(Replicate-[\\d]+\\)",
  "\u{2022}",
  COMPOSITE_FIELD_DELIMITER,
  "_:",
  ":_",
];
/** json fields that stand for product name */
export const PRODUCT_NAME_FIELDS_SET = new Set(["name", "description"]);

/** Character limits for certain fields */
export const CHAR_LIMITS = {
  sample_id: 2000,
  analysis_request_search: 100,
  po: 200,
  comment: 500,
  sample_type: 100,
  submission_name: 200,
};

/**
 * Finds all matches, keeps track of lastIndex.
 * Any subsequent tests on the same string will start from lastIndex and might give "wrong" result
 * Use with match or replace (when you want all matches, this is slower than test)
 */
export const replicateTagRegexGlobal = new RegExp(/ *\(Replicate-[\d]+\)/, "g"); // looks for "(Replicate-#)" with any number of spaces before

/**
 * Finds first match, use with test, will return the same value every time
 */
export const replicateTagRegexFirstMatch = new RegExp(/ *\(Replicate-[\d]+\)/); // looks for "(Replicate-#)" with any number of spaces before

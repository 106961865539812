import React, { useEffect, useRef, useState } from "react";

import CreateSampleModal from "../CreateSampleModal/CreateSampleModal";
import CreateNewSubmissionButton from "./Common/CreateNewSubmissionButton";
import Drafts from "./Drafts/Drafts";
import Submissions from "./Submissions/Submissions";

import { ReactComponent as ChevronDown } from "../../../assets/images/common/ChevronDown.svg";

import "../SampleSubmission.css";
import "./MainPage.css";

// import MainPageHeader from "./MainPageHeader";

export default function MainPage(props) {
  const {
    setShowSubmissionForm,
    setDraftEditing,
    setSubmissionEditingSample,
    setSubmissionAddingSamples,
    fieldsError,
    setSampleCategory,
    setSampleFieldLoaded,
    loadingFields,
  } = props;

  const [submittedSubmissions, setSubmittedSubmissions] = useState([]);
  const [draftSubmissions, setDraftSubmissions] = useState([]);
  const [loadingSubmissions, setLoadingSubmissions] = useState(true);
  const [loadingDrafts, setLoadingDrafts] = useState(true);
  const [createSubmission, setCreateSubmission] = useState(false);
  const [isDraftDrawerOpen, setIsDraftDrawerOpen] = useState(false);

  const isInitialData = useRef(true);

  useEffect(() => {
    if (fieldsError) {
      setLoadingDrafts(false);
      setLoadingSubmissions(false);
    }
  }, [fieldsError]);

  const loadingBothLists = loadingSubmissions || loadingDrafts;
  const noSubmissionAndDraftData = !loadingBothLists && isInitialData.current && submittedSubmissions.length === 0 && draftSubmissions.length === 0;

  const handleCreateSubmission = () => {
    setCreateSubmission(false);
  };

  return (
    <>
      <div className="sample-submission-background main">
        <div className={`sample-submission-main-container ${noSubmissionAndDraftData ? "sample-submission-main-container-flex" : ""}`}>
          {noSubmissionAndDraftData ? (
            <div className="sample-submission-create-new-in-center">
              <CreateNewSubmissionButton setShowSubmissionForm={setCreateSubmission} />
            </div>
          ) : (
            <>
              {/* <MainPageHeader setShowSubmissionForm={setShowSubmissionForm} loadingFields={loadingFields} /> */}
              <div className="submissions-list">
                <Submissions
                  setShowSubmissionForm={setShowSubmissionForm}
                  setSampleFieldLoaded={setSampleFieldLoaded}
                  setSubmissionEditingSample={setSubmissionEditingSample}
                  setSubmissionAddingSamples={setSubmissionAddingSamples}
                  loadingSubmissions={loadingSubmissions}
                  setLoadingSubmissions={setLoadingSubmissions}
                  submittedSubmissions={submittedSubmissions}
                  setSubmittedSubmissions={setSubmittedSubmissions}
                  isInitialData={isInitialData}
                  setSampleCategory={setSampleCategory}
                  setCreateSubmission={setCreateSubmission}
                  createSubmission={createSubmission}
                />

                <Drafts
                  setShowSubmissionForm={setShowSubmissionForm}
                  setSampleFieldLoaded={setSampleFieldLoaded}
                  setDraftEditing={setDraftEditing}
                  loadingDrafts={loadingDrafts}
                  setLoadingDrafts={setLoadingDrafts}
                  draftSubmissions={draftSubmissions}
                  setDraftSubmissions={setDraftSubmissions}
                  isDraftDrawerOpen={isDraftDrawerOpen}
                  closeDraftDrawer={() => setIsDraftDrawerOpen(false)}
                />

                <button
                  className="MainPage_DraftToggle"
                  type="button"
                  onClick={() => setIsDraftDrawerOpen(true)}
                >
                  <ChevronDown className="MainPage_DraftToggleIcon" />
                  <span>Draft</span>
                </button>
              </div>
            </>
          )}
          {
            createSubmission && (
              <CreateSampleModal
                setShowSubmissionForm={setShowSubmissionForm}
                onCancel={handleCreateSubmission}
                setSampleCategory={setSampleCategory}
                heading="Create New Sample Submission "
                loadingFields={loadingFields}
              />
            )
          }
        </div>
      </div>
    </>
  );
}

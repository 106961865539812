import React, {
  useState, useEffect, useMemo, useRef,
} from "react";

import { motion, LayoutGroup } from "framer-motion/dist/framer-motion";
import debounce from "lodash/debounce";
import Highlighter from "react-highlight-words";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import StyledButton from "../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../Common/UIComponents/StyledTooltip";
import EnvConfirmModal from "../Analytics/Components/EnvConfirmModal";
import ModalPageTitle from "./ModalPageTitle";
import SelectionListItem from "./SelectionListItem";

import { getTestList } from "../../../actions/sampleSubmission";

import addIconBlue from "../../../assets/images/environment/addIconBlue.png";
import { ReactComponent as CloseIcon } from "../../../assets/images/sample-submission/blueCloseIcon.svg";
import searchIcon from "../../../assets/images/sample-submission/searchIcon.svg";

import "./AddEditTestsForm.css";

export default function AddEditTestsForm({
  markedLocations,
  setMarkedLocations,
  extraFields,
  linkPatternFields,
}) {
  const [selected, setSelected] = useState(Array.from(markedLocations.keys())[0]);
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(""); // used for highlighted text after search
  const [testSuggestions, setTestSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clearTestSearch, setClearTestSearch] = useState(false);
  const [testsSelected, setTestsSelected] = useState({});
  // const [testInfoMap, setTestInfoMap] = useState(new Map());
  const [isSuggestionListVisible, setIsSuggestionListVisible] = useState(false);

  const [isDeletingConfirm, setIsDeletingConfirm] = useState({ isDeleting: false, location_id: null });

  const testSuggestionsScrollbar = useRef();

  const SEARCH_CHAR_LIMIT = 100;

  /** Get test suggestions based on search input */
  const apiGetTestSuggestions = async (searchInput) => {
    const { success, message, testList } = await getTestList(searchInput, undefined, "environment");
    if (success) {
      if (clearTestSearch) {
        setSearch("");
        setClearTestSearch(false);
      }
      const test_map = new Map();
      testList.forEach((testObj) => {
        test_map.set(testObj.name, testObj);
      });
      // setTestInfoMap(test_map);
      setTestSuggestions(testList);
      setSearched(searchInput);
      if (testSuggestionsScrollbar.current) {
        testSuggestionsScrollbar.current.scrollerElement.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      toast.error(message);
    }
    setIsLoading(false);
  };

  /**
   * Set selected location's test list initially
   */
  useEffect(() => {
    if (markedLocations.size === 0) return;
    const testByLocationIds = {};
    Array.from(markedLocations).forEach(([k, v]) => {
      testByLocationIds[k] = v.test_list;
    });
    setTestsSelected(testByLocationIds);
    if (selected && selected in testByLocationIds) {
      setSelected(selected);
    } else {
      setSelected(Array.from(markedLocations.keys())[0]);
    }
  }, [markedLocations]); // eslint-disable-line

  /** Get test suggestions when it mounted */
  useEffect(() => {
    apiGetTestSuggestions("");
  }, []); // eslint-disable-line

  /** Add debouncing feature for the apiGetTestSuggestions function
   * @return {Function} apiGetTestSuggestions function with debouncing feature,
   * invoking apiGetTestSuggestions only after 500ms has passed after the last invocation.
   */
  const debouncedSearch = useMemo(() => debounce(async (searchInput) => {
    await apiGetTestSuggestions(searchInput);
  }, 500), []);  // eslint-disable-line

  /** Cancel debouncing so after the component is unmounted, the debouncedSearch function will not be executed,
  and state will not be set causing an error. */
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // eslint-disable-next-line consistent-return
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]); // eslint-disable-line

  /** Call debounced search when search input is changed */
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  /** Search immediately after the user hits enter key */
  const handleHitEnter = (e) => {
    if (e.key === "Enter") {
      apiGetTestSuggestions(e.target.value);
    }
  };

  /**
* Get text for test tooltip
* @param {Array} display list of display values ex: [{ title: "Method", value: ""}]
* @param {Boolean} replicated if test is replicated
* @returns Text containing test method, reporting limit, turnaround time if they exist and the reason for why the button is disabled
*/
  const toolTipText = (display, replicated) => {
    const textLines = [];
    if (replicated) { // disable test
      textLines.push("Disabled: Cannot add a replicate test to a sample that is replicated");
    }
    display.forEach(({ title, value }) => {
      if (value) {
        textLines.push(`${title}: ${value}`);
      }
    });
    return textLines.join("\n");
  };

  /**
   * Update marked locations test list
   * @param {*} testsSelected
   */
  const updatedMarkedLocations = (selectedTests) => {
    const updated = new Map(Array.from(markedLocations));
    Object.entries(selectedTests).forEach(([location_id, tests]) => {
      const obj = updated.get(location_id);
      updated.set(location_id, {
        ...obj,
        test_list: tests,
      });
    });
    setMarkedLocations(updated);
  };

  /**
 * Add or remove a test from the selected tests
 * @param {String} test test name or test obj
 * @param {String} action If action is "deselect", then the test will be removed.
 * Otherwise, the test is added or removed depending on if the selected test set contains that test.
 * Clear all the selected tests
 */
  const toggleTestChoice = (_test, action) => {
    const testsSelectedCopy = { ...testsSelected };

    // Modified for Add/Edit test in Create Sampling Event Modal
    let test = _test;
    if (typeof test === "object") {
      test = _test.name;
    }
    if (action === "deselect" || (testsSelectedCopy[selected] ?? []).indexOf(test) > -1) {
      testsSelectedCopy[selected] = (testsSelectedCopy[selected] ?? []).filter((t) => t !== test);
    } else if (action === "clear") {
      testsSelectedCopy[selected] = [];
    } else {
      // eslint-disable-next-line no-lonely-if
      if (testsSelectedCopy[selected]) {
        testsSelectedCopy[selected] = [...testsSelectedCopy[selected], test];
      } else {
        testsSelectedCopy[selected] = [];
      }
    }

    setTestsSelected(testsSelectedCopy);
    updatedMarkedLocations(testsSelectedCopy);
  };

  /**
   * Handle delete associated test from selected location
   * @param {string} location_id
   */
  const handleDelete = (location_id) => {
    const updated = new Map(markedLocations);
    updated.delete(location_id);
    setMarkedLocations(updated);
    setIsDeletingConfirm({ isDeleting: false, location_id: null });
  };

  /**
   * Handler for onClicking delete location button
   * @param {string} location_id
   */
  const onPressDeleteSample = (location_id) => {
    if (markedLocations.size === 1) {
      toast.error("There must be atleast one location present");
      return;
    }
    setIsDeletingConfirm({ isDeleting: true, location_id });
  };

  /**
   * Set test className
   * @param {*} disableSelection
   * @param {*} name
   * @returns
   */
  const analysisClassName = (disableSelection, name) => {
    let className = "";
    if (disableSelection) {
      className = "disabled-test-suggestion";
    } else if ((testsSelected[selected] ?? []).includes(name)) {
      className = "selected-test-suggestion";
    } else {
      className = "unselected-test-suggestion";
    }
    return className;
  };

  return (
    <div className="AddEditTestsForm">
      <div className="AddEditTestsForm__SelectionListContainer">
        <ModalPageTitle>{`Selections(${markedLocations && markedLocations.size})`}</ModalPageTitle>
        <Scrollbar className="AddEditTestsForm__SelectionListScrollbar">
          <ul className="AddEditTestsForm__SelectionList">
            {markedLocations && markedLocations.size > 0 && Array.from(markedLocations).map(([location_id, location]) => (
              <SelectionListItem
                key={location_id}
                extraFields={extraFields}
                linkPatternFields={linkPatternFields}
                location={location}
                selected={selected === location_id}
                onClick={() => setSelected(location_id)}
                onDelete={(e) => {
                  e.stopPropagation();
                  onPressDeleteSample(location_id);
                }}
              />
            ))}
          </ul>
        </Scrollbar>
      </div>
      <div className="AddEditTestsForm__AnalysisRequestContainer">
        <ModalPageTitle>Analysis Request</ModalPageTitle>
        <div className="AddEditTestsForm__AnalysisRequest">
          {isSuggestionListVisible && (
            <>
              <div className="analysis-request-search-box">
                <img alt="search icon" src={searchIcon} />
                <input
                  placeholder="Search in Test Directory"
                  type="text"
                  onChange={handleChangeSearch}
                  value={search}
                  onKeyUp={handleHitEnter}
                  maxLength={SEARCH_CHAR_LIMIT}
                />
                <button
                  type="button"
                  className="AddEditTestsForm__HideSuggestionsList"
                  onClick={() => setIsSuggestionListVisible(false)}
                >
                  &times;
                </button>
              </div>

              <h4 className="analysis-request-smart-suggestions-header">
                Suggestions
              </h4>
              <div className="analysis-request-smart-suggestions-container">
                {!isLoading && (
                  testSuggestions.length > 0 ? (
                    <Scrollbar ref={testSuggestionsScrollbar}>
                      <ul className="analysis-request-smart-suggestions-list">
                        {testSuggestions.map(({
                          name, replicated, display,
                        }) => {
                          const disableTest = false; // no need to check the replicated flag here
                          return (
                            <StyledTooltip
                              title={toolTipText(display, false)}
                              placement="right"
                              key={name}
                            >
                              <li
                                key={name}
                                onClick={disableTest ? null : () => toggleTestChoice({ name, replicated, display })}
                                className={analysisClassName(disableTest, name)}
                              >
                                <Highlighter
                                  highlightClassName="searchHighlight"
                                  searchWords={[searched]}
                                  autoEscape
                                  textToHighlight={name}
                                />
                              </li>
                            </StyledTooltip>
                          );
                        })}
                      </ul>
                    </Scrollbar>
                  ) : (
                    <div className="AnalysisRequest__NoSuggestionsContainer">
                      <span className="analysis-request-no-tests-found">No tests found</span>
                    </div>
                  ))}
              </div>
            </>
          )}
          <LayoutGroup>
            <motion.div layout className="analysis-request-tests-divider" />
            <motion.div layout className="analysis-request-tests-selected-and-checkbox-header">
              <div className="analysis-request-tests-selected-header-div">
                <h4 className="analysis-request-tests-selected-header">{`Tests Selected (${testsSelected[selected] ? testsSelected[selected].length : 0})`}</h4>
                <h4
                  className="analysis-request-tests-selected-clear"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleTestChoice("", "clear");
                  }}
                >
                  Clear All
                </h4>
              </div>
              {!isSuggestionListVisible && (
                <StyledButton
                  htmlType="button"
                  type="default"
                  className="AddEditTestsForm__OpenSuggestionButton"
                  onClick={() => setIsSuggestionListVisible(true)}
                >
                  <img src={addIconBlue} alt="Plus icon" />
                  <span>Add Tests</span>
                </StyledButton>
              )}
            </motion.div>
            <motion.div layout="position" className="analysis-request-tests-selected-container">
              {/* {!isLoading && (
                <> */}
              {testsSelected[selected] && testsSelected[selected].length > 0
                ? (
                  <Scrollbar>
                    <motion.ul layoutScroll="position" className="analysis-request-tests-selected-list">
                      {(testsSelected[selected] ?? []).map((name) => (
                        <motion.div layout="position" key={name}>
                          <StyledTooltip
                            placement="top"
                          >
                            <li key={name} className="analysis-request-tests-selected-list-item">
                              {name}
                              <div className="AnalysisRequest__CloseIcon" onClick={() => toggleTestChoice(name, "deselect")}>
                                <CloseIcon width={8} height={8} />
                              </div>
                            </li>
                          </StyledTooltip>
                        </motion.div>
                      ))}
                    </motion.ul>
                  </Scrollbar>
                )
                : <div className="no-tests-added">No tests added</div>}
              {/* </>
              )} */}
            </motion.div>
          </LayoutGroup>
        </div>
      </div>
      {isDeletingConfirm.isDeleting && (
        <EnvConfirmModal
          width="520px"
          headerText="Delete Sample Confirmation"
          bodyText="Are you sure you want to delete this sample?"
          buttonText={["Cancel", "Delete"]}
          buttonFunctions={[
            () => setIsDeletingConfirm({ isDeleting: false, location_id: null }),
            () => handleDelete(isDeletingConfirm.location_id),
          ]}
        />
      )}
    </div>
  );
}

import React from "react";
import "./COABuilderTestReportSection.css";
import "./COABuilderSection.css";

export default function COABuilderTestReportSection({ sample }) {
  const { sample_id, test_results } = sample;
  const testResults = test_results.length ? test_results : [{
    test: "-", test_method: "-", test_spec: "-", test_result: "-",
  }];
  return (
    <div className="COABuilder__Section COABuilder__TestReportContainer">
      <span className="COABuilder__TestReport__SampleID">{`Sample ID : ${sample_id}`}</span>
      <table className="COABuilder__TestReportTable">
        <tbody>
          <tr className="COABuilder__TestReportTable__Header">
            <th>Test Method</th>
            <th>Test</th>
            <th>Specification</th>
            <th>Result</th>
          </tr>
          {testResults.map(({
            test, test_result, test_spec, test_method,
          }, i) => (
            <tr key={i} className="COABuilder__TestReportTable__Row">
              <td><span>{test_method ?? ""}</span></td>
              <td><span>{test}</span></td>
              <td><span>{test_spec}</span></td>
              <td><span>{test_result}</span></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

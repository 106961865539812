import React, { Component } from "react";
import { Modal } from "antd";
import "./DocumentsConfirmDelete.css";

export default class DocumentsConfirmDelete extends Component {
  render() {
    const {
      toggleConfirmDelete, numOfSelectedFiles, handleDeleteFile, batch, deleteItem,
    } = this.props;
    return (

      <Modal
        open
        closable={false}
        centered
        footer={null}
        wrapClassName="DocumentsConfirmDelete"
        onCancel={toggleConfirmDelete}
        className="DocConfirmDeleteAlert"
      >
        <div className="docConfirmDel">
          <div className="docConfirmDelTitle">
            Delete
            {" "}
            {batch ? numOfSelectedFiles : 1}
            {" "}
            {batch && numOfSelectedFiles > 1 ? "files" : "file"}
          </div>
          <div className="docConfirmDelTitleMessage">These files will no longer be available for use. </div>
          <div className="docConfirmDelBtn">
            <button type="button" onClick={toggleConfirmDelete} className="docConfirmDelBtnCancel">Cancel</button>
            <button type="button" onClick={(e) => handleDeleteFile(e, deleteItem, batch)} className="docConfirmDelBtnDelete">Delete</button>
          </div>
        </div>
      </Modal>
    );
  }
}

import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext } from "react-beautiful-dnd";
import TemplateBuilderCenter from "../Center/TemplateBuilderCenter";
import BuilderComponents from "../BuilderComponents/BuilderComponents";
import BuilderDragDropContext from "./BuilderDragDropContext";

export default function BuilderDragDropWrapper({
  previewTemplate,
  handlePreviewTemplate,
  allCustomizedDataFields,
  handleSectionSelect,
  sections,
  sectionsFixed,
  sectionRefs,
  setSectionInputs,
  handleBuilderFile,
  handleTemplateDataFieldsChange,
  commonDataFieldsInUse,
  customizedDataFieldsInUse,
  setAllCustomizedDataFields,
  inputsPayload,
  isCustomizingCoa,
  selectedTemplate,
  selectedReports,
  handleCreateSection,
  handleSectionDelete,
  uniqueTests,
  enableTests,
}) {
  const [allDataFields, setAllDataFields] = useState([]);
  const ALWAYS_DISABLED_DROPPABLES = ["builder_datafields", "builder_datafields_user", "builder_datafields_default", "builder_textbox"];
  const [disabledDroppablesSet, setDisabledDroppablesSet] = useState(new Set(ALWAYS_DISABLED_DROPPABLES));

  const getSectionInputs = (sectionIdx) => ({ ...sections[sectionIdx].inputs });
  const getFixedSectionInputs = (sectionIdx) => ([...sectionsFixed[sectionIdx].inputs]);
  /**
   * When a input is dragged into a custom section. UseDrop hook calls this function
   * which adds to the left or right array
   * if section is sectionContentInputsOne, adds the input to the left array
   * else adds the input to the right array
   * @param {Number} sectionIdx index of section in sections/sectionsFixed array
   * @param {Object} sectionPart "left" | "right" | undefined
   * @param {Number} destIdx index of new item in list
   * @param {Object} item = { id, flag, type, jsonField, fieldName }
   * @param {Boolean} isFixed adding to fixed section (statement card) or not
   */
  const addInputToInputs = (sectionIdx, sectionPart, destIdx, item, isFixed = false) => {
    const inputs = isFixed ? getFixedSectionInputs(sectionIdx) : getSectionInputs(sectionIdx);
    // console.log("addInputToInputs", inputs);
    const inputID = `${item.id}_${uuidv4()}`;
    const newInput = {
      ...item, id: inputID,
    };
    const items = sectionPart ? inputs[sectionPart] : inputs;
    items.splice(destIdx, 0, newInput);
    setSectionInputs(sectionIdx, inputs, isFixed, { action: "add", flag: item.flag, value: item.fieldName });
    if (!isFixed) {
      handleTemplateDataFieldsChange("add", item.flag, item.fieldName);
    }
  };

  /**
   * Handles sorting internally in a array
   */
  const reorder = (sectionIdx, sectionPart, startIndex, endIndex, isFixed = false) => {
    const resultObject = isFixed ? getFixedSectionInputs(sectionIdx) : getSectionInputs(sectionIdx);
    if (sectionPart) {
      const [removed] = resultObject[sectionPart].splice(startIndex, 1);
      resultObject[sectionPart].splice(endIndex, 0, { ...removed, defaultValue: removed.ref?.value });
    } else {
      const [removed] = resultObject.splice(startIndex, 1);
      resultObject.splice(endIndex, 0, { ...removed, defaultValue: removed.ref?.value });
    }
    setSectionInputs(sectionIdx, resultObject, isFixed);
  };

  /**
   * Handles sorting between left and right arrays
   */
  const move = (source, destination) => {
    // console.log("move", source, destination);
    /** Within section move */
    if (source.sectionIdx === destination.sectionIdx) {
      const resultObject = source.isFixed ? getFixedSectionInputs(source.sectionIdx) : getSectionInputs(source.sectionIdx);
      const sourceClone = source.sectionPart ? resultObject[source.sectionPart] : resultObject;
      const destClone = destination.sectionPart ? resultObject[destination.sectionPart] : resultObject;
      const [removed] = sourceClone.splice(source.index, 1);
      destClone.splice(destination.index, 0, { ...removed, defaultValue: removed.ref?.value });
      setSectionInputs(source.sectionIdx, resultObject, source.isFixed);
    /** Between sections */
    } else {
      const sourceSection = source.isFixed ? getFixedSectionInputs(source.sectionIdx) : getSectionInputs(source.sectionIdx);
      const destSection = destination.isFixed ? getFixedSectionInputs(destination.sectionIdx) : getSectionInputs(destination.sectionIdx);
      const sourceClone = source.sectionPart ? sourceSection[source.sectionPart] : sourceSection;
      const destClone = destination.sectionPart ? destSection[destination.sectionPart] : destSection;
      const [removed] = sourceClone.splice(source.index, 1);
      destClone.splice(destination.index, 0, { ...removed, defaultValue: removed.ref?.value });
      setSectionInputs(source.sectionIdx, sourceSection, source.isFixed, { action: "delete", flag: removed.flag, value: removed.fieldName }, false);
      setSectionInputs(destination.sectionIdx, destSection, destination.isFixed, { action: "add", flag: removed.flag, value: removed.fieldName });
    }
  };

  /**
   * Returns object with action and info required to handle action
   * @param {Object} source { droppableId, index }
   * @param {Object} destination { droppableId, index }
   * @param {String} draggableId id of dragged elem
   * @returns {Object} {action, ...action related info }
   */
  const parseActionInfo = (source, destination, draggableId) => {
    const { droppableId: sourceDroppableId, index: sourceIndex } = source;
    const { droppableId: destDroppableId, index: destIndex } = destination;
    // item = { id, value, flag, jsonField }
    const sourceParsed = sourceDroppableId.split("_");
    const destParsed = destDroppableId.split("_");
    const sourceSectionIdx = parseInt(sourceParsed[1]); // will be NaN if builder component, sectionIdx otherwise
    const destSectionIdx = parseInt(destParsed[1]);
    const info = {};
    /** Draggable is from Builder Components (data field or textbox) */
    if (sourceParsed[0] === "builder") {
      info.action = "ADD_INPUT";
      const destIsFixed = destParsed[0] === "fixed";
      info.isFixed = destIsFixed;
      info.sectionIdx = destSectionIdx;
      info.sectionPart = destParsed[2];
      info.destIdx = destIndex;
      info.item = {};
      if (sourceParsed[1] === "datafields") {
        const sortedByType = sourceParsed.length === 3;
        const [id, flag, uuid] = draggableId.split("_"); // eslint-disable-line
        info.item = { id, flag, type: "datafield" };
        if (flag === "1") { // default field
          const { value, json_field } = allDataFields[sourceIndex];
          info.item.fieldName = value;
          info.item.jsonField = json_field;
          if (isCustomizingCoa) { // if there is only one option for this field, make it the defaultValue
            const options = selectedReports.data_fileds;
            if (options && options[json_field] && options[json_field].length === 1) {
              info.item.defaultValue = options[json_field][0];
            }
          }
        } else if (sortedByType) { // custom fields in separate list, sourceIndex is w.r.t allCustomizedDataFields arr
          info.item.fieldName = allCustomizedDataFields[sourceIndex];
        } else { // default and custom fields in same list
          const { value } = allDataFields[sourceIndex];
          info.item.fieldName = value;
        }
      } else { // "textbox"
        info.item.flag = "0";
        info.item.type = draggableId;
        info.item.id = draggableId;
      }
    /** Draggable is from a section */
    } else {
      /** Reordering within a list */
      if (sourceDroppableId === destDroppableId) { // eslint-disable-line
        info.action = "REORDER";
        const isFixed = sourceParsed[0] === "fixed";
        info.isFixed = isFixed;
        info.sectionIdx = sourceSectionIdx;
        info.sectionPart = sourceParsed[2]; // left or right
        info.sourceIndex = sourceIndex;
        info.destIndex = destIndex;
      } else { /** Movement between left + right lists */
        info.action = "MOVE";
        info.sourceInfo = {
          sectionIdx: sourceSectionIdx, sectionPart: sourceParsed[2], index: sourceIndex, isFixed: sourceParsed[0] === "fixed",
        };
        info.destInfo = {
          sectionIdx: destSectionIdx, sectionPart: destParsed[2], index: destIndex, isFixed: destParsed[0] === "fixed",
        };
      }
    }
    return info;
  };

  /**
   * Before the draggable is measured, add a margin underneath to make sure the dropping animation is smooth.
   * When the drag ends, remove the margin.
   * @param {String} draggableId id of drag elem
   * @param {String} action "add", "remove"
   */
  const adjustDimsOfDraggable = (draggableId, action) => {
    if (!isCustomizingCoa) { // template builder
      const parsed = draggableId.split("_");
      if (["datafield", "smallTextBox", "largeTextBox"].includes(parsed[0])
      && (!parsed[1] || ["1", "2"].includes(parsed[1]))) { // checks if datafield is from Builder Element and not from a section
        const elem = document.getElementById(draggableId);
        const { width, height } = elem.getBoundingClientRect();
        elem.style.margin = action === "add" ? "0 25px 32px 25px" : "0";
        elem.style.width = action === "add" ? `${width}px` : "unset";
        elem.style.height = action === "add" ? `${height}px` : "unset";
      }
    } else { // coa builder
      const elem = document.getElementById(draggableId);
      const { width, height } = elem.getBoundingClientRect();
      elem.style.marginBottom = action === "add" ? "8px" : "0";
      elem.style.width = action === "add" ? `${width}px` : "unset";
      elem.style.height = action === "add" ? `${height}px` : "unset";
    }
  };

  /**
   * Disable certain droppables based on the source droppableId
   * @param {String} sourceDroppableId
   */
  const conditionallyDisableDroppables = (sourceDroppableId) => {
    /** Reset to default, nothing is dragging */
    if (!sourceDroppableId) {
      setDisabledDroppablesSet(new Set([...ALWAYS_DISABLED_DROPPABLES]));
      return;
    }
    /** Conditionally disable droppables */
    const [sectionType, sectionIdx, sectionPart, fixedSectionName] = sourceDroppableId.split("_"); // eslint-disable-line
    let droppablesToDisable = [];
    const fixedDroppables = sectionsFixed.map(({ key }, i) => `fixed_${i}__${key}`);
    if (!(sectionType === "builder" && sectionIdx === "textbox")) { // all droppables enabled for builder textboxes
      if (sectionType === "fixed") { // dragging from fixed section
        droppablesToDisable = [...fixedDroppables.filter((id) => id !== sourceDroppableId)]; // don't allow dropping into other fixed sections
        if (fixedSectionName !== "test") {
          droppablesToDisable.push("dynamic"); // for any fixed section except test reports, don't allow dropping into dynamic sections
        }
      } else { // dragging from datafields component or dynamic section
        droppablesToDisable = fixedDroppables.filter((id) => !id.includes("test")); // don't allow dropping into my details, statement card
      }
      setDisabledDroppablesSet(new Set([...ALWAYS_DISABLED_DROPPABLES, ...droppablesToDisable]));
    }
  };

  /**
   * Before the draggable is measured, add a margin underneath to make sure the dropping animation is smooth.
   * @param {String} draggableId
   */
  const onBeforeCapture = ({ draggableId }) => {
    adjustDimsOfDraggable(draggableId, "add");
  };

  /**
   * onDragStart, disable droppables based on source droppable id
   * @param {Object} source { droppableId, draggableId }
   */
  const onDragStart = ({ source }) => {
    conditionallyDisableDroppables(source.droppableId);
  };

  /**
   * Handles all drag actions onDragEnd
   * @param {Object} result { source, destination, draggableId }
   */
  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    adjustDimsOfDraggable(draggableId, "remove");
    /** Reset disabled droppables to default */
    conditionallyDisableDroppables();
    if (!destination) {
      return;
    }

    const actionInfo = parseActionInfo(source, destination, draggableId);
    // console.log("action", actionInfo);
    if (!actionInfo) {
      return;
    }

    switch (actionInfo.action) {
      case "ADD_INPUT": {
        const {
          sectionIdx, sectionPart, destIdx, item, isFixed,
        } = actionInfo;
        addInputToInputs(sectionIdx, sectionPart, destIdx, item, isFixed);
        break;
      }
      case "REORDER": {
        const {
          sectionIdx, sectionPart, sourceIndex, destIndex, isFixed,
        } = actionInfo;
        reorder(sectionIdx, sectionPart, sourceIndex, destIndex, isFixed);
        break;
      }
      case "MOVE": {
        const { sourceInfo, destInfo } = actionInfo;
        move(sourceInfo, destInfo);
        break;
      }
      default:
    }
  };

  return (
    <BuilderDragDropContext.Provider value={{ disabledDroppablesSet }}>
      <DragDropContext
        onBeforeCapture={onBeforeCapture}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <TemplateBuilderCenter
          previewTemplate={previewTemplate}
          handlePreviewTemplate={handlePreviewTemplate}
          dataFields={allCustomizedDataFields}
          handleSectionSelect={handleSectionSelect}
          sections={sections}
          sectionsFixed={sectionsFixed}
          sectionRefs={sectionRefs}
          setSectionInputs={setSectionInputs}
          handleBuilderFile={handleBuilderFile}
          handleTemplateDataFieldsChange={handleTemplateDataFieldsChange}
          inputsPayload={inputsPayload}
          isCustomizingCoa={isCustomizingCoa}
          selectedTemplate={selectedTemplate}
          selectedReports={selectedReports}
          handleCreateSection={handleCreateSection}
          handleSectionDelete={handleSectionDelete}
          uniqueTests={uniqueTests}
          enableTests={enableTests}
        />
        <BuilderComponents
          previewTemplate={previewTemplate}
          commonDataFieldsInUse={commonDataFieldsInUse}
          customizedDataFieldsInUse={customizedDataFieldsInUse}
          setAllCustomizedDataFields={setAllCustomizedDataFields}
          allDataFields={allDataFields}
          setAllDataFields={setAllDataFields}
          isCustomizingCoa={isCustomizingCoa}
        />
      </DragDropContext>
    </BuilderDragDropContext.Provider>
  );
}

import React from "react";
import NumberInput from "./Inputs/NumberInput";
import UploadImage from "./UploadImage";
import TemplateBuilderInput from "./Inputs/TemplateBuilderInput";
// import AcrossAllInput from "./AcrossAllInput";
import "./DetailsContent.css";

export default function DetailsContent({
  handleBuilderFile, sectionIndex, setSectionInputs, inputs,
}) {
  const {
    logo_image_url, company_name, company_address, phone_number, fax_number,
  } = inputs;
  const setRef = (id, input) => {
    const inputsObject = { ...inputs };
    inputsObject[id].ref = input;
    setSectionInputs(sectionIndex, inputsObject, true, null, false);
  };
  return (
    <div className="DetailsContentContainer">
      <div className="DetailsContent__ImageContainer">
        <UploadImage
          id="company_logo"
          title="Add Company Logo"
          handleBuilderFile={handleBuilderFile}
          imagePath={logo_image_url}
        />
      </div>
      <div className="DetailsContent__Inputs">
        <div className="DetailsContent__InputsLeft">
          <span className="DetailsContent__InputsSectionLabel">Company details</span>
          <TemplateBuilderInput
            id="company_name"
            placeholder="Enter Company name"
            maxLength={250}
            inputClassName="DetailsContent__CompanyNameInput"
            defaultValue={company_name.defaultValue}
            setRef={(input) => setRef("company_name", input)}
          />
          <TemplateBuilderInput
            id="company_address"
            placeholder="Enter Company Address"
            maxLength={500}
            isTextArea
            inputClassName="DetailsContent__CompanyAddressInput"
            defaultValue={company_address.defaultValue}
            setRef={(input) => setRef("company_address", input)}
          />
        </div>
        <div className="DetailsContent__InputsRight">
          <span className="DetailsContent__InputsSectionLabel">Contact details</span>
          <NumberInput
            id="phone_number"
            placeholder="Enter Phone Number"
            label="PHONE"
            defaultValue={phone_number.defaultValue}
            setRef={(input) => setRef("phone_number", input)}
          />
          <NumberInput
            id="fax_number"
            placeholder="Enter Fax Number"
            label="FAX"
            defaultValue={fax_number.defaultValue}
            setRef={(input) => setRef("fax_number", input)}
          />
        </div>
        {/* <AcrossAllInput /> */}
      </div>
    </div>
  );
}

import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import Scrollbar from "react-scrollbars-custom";
import Highlighter from "react-highlight-words";
import { ReactComponent as CloseIcon } from "../../../../../assets/images/sample-submission/blueCloseIcon.svg";
// import "../../../styles/SelectionTileStyle.css";
import "./SelectionCard.css";
import useAutoHideScrollbar from "../../../../Common/hooks/useAutoHideScrollbar";

export default function SelectionCard({
  data, animationKey, type, updateSelectedOptions, disabled, active, children, searchValue,
}) {
  const [scrollbarRef, scrollbarStyle] = useAutoHideScrollbar();
  const selectionTileClicked = () => {
    updateSelectedOptions(data, type);
  };

  let statusClassName = "";
  if (active) {
    statusClassName = "--focused";
  } else if (disabled) {
    statusClassName = "--disabled";
  }

  return (
    <motion.div
      className={`ProductAnalytics__SelectionCard${statusClassName}`}
      onClick={selectionTileClicked}
      key={animationKey}
      layout
      layoutId={animationKey}
    >
      <Scrollbar
        className="ProductAnalytics__SelectionCard__Scrollbar"
        elementRef={scrollbarRef}
        trackYProps={scrollbarStyle}
      >
        <div className="ProductAnalytics__SelectionCard__Content">
          {typeof data === "string" ? (
            <Highlighter
              key={animationKey}
              className="Analytics__SelectionCard__ValueText"
              highlightClassName="Analytics__SelectionCard__ValueText--highlighted"
              searchWords={[searchValue]}
              autoEscape
              textToHighlight={data}
            />
          ) : children }
        </div>
      </Scrollbar>
      {active && (
      <CloseIcon className="ProductAnalytics__SelectionCard__CloseIcon" />
      )}
    </motion.div>
  );
}

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "antd";

import { getInviteEmails, deleteInviteEmail } from "../../../../../actions/sampleSubmission";

import InviteHeader from "./Components/InviteHeader";
import InvitedUsers from "./Components/InvitedUsers";
import AddUser from "./Components/AddUser";
import DeleteInvite from "./Components/DeleteInvite";

import "./InviteModal.css";

export default function InviteModal({ handleShowInvite, type }) {
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [deleteEmail, setDeleteEmail] = useState("");

  const getEmails = async () => {
    setLoadingData(true);
    const response = await getInviteEmails(type);
    if (response.success) {
      setInvitedUsers(response.result);
    } else {
      toast.error(response.message);
    }
    setLoadingData(false);
  };

  // onMount get all invited user
  useEffect(() => {
    getEmails();
  }, []); //eslint-disable-line

  const handleDeleteModal = (show, email = "") => {
    setShowDeleteModal(show);
    setDeleteEmail(email);
  };

  const handleDeleteInvite = async () => {
    setLoadingData(true);
    const response = await deleteInviteEmail(deleteEmail);
    if (response.success) {
      getEmails();
      handleDeleteModal(false);
      toast.success("User Deleted Successfully!");
    } else {
      toast.error(response.message);
    }
    setLoadingData(false);
  };

  return (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      className="SampleSubmission__InviteModal"
      width="40vw"
    >
      <InviteHeader handleShowInvite={handleShowInvite} />
      <div className="InviteModal__BodyContainer">
        <InvitedUsers invitedUsers={invitedUsers} loadingData={loadingData} handleDeleteModal={handleDeleteModal} />
        <AddUser
          type={type}
          getEmails={getEmails}
        />
      </div>
      {showDeleteModal && (
        <DeleteInvite handleDeleteModal={handleDeleteModal} handleDeleteInvite={handleDeleteInvite} loadingData={loadingData} />
      )}
    </Modal>
  );
}

import axios from "axios";

// eslint-disable-next-line
import { getUserCredentials } from "../utils/common";
import { API_URL } from "../utils/Constant";
import { getViewAsPerStatusCode } from "../utils/helpers";

/**
 * Get a list of all submitted submissions (when submission_id is ""),
 * or detailed info of a submitted submission (when a non-empty submission_id is given)
 * @param {Number} page page number for the submitted submissions list
 * @param {String} submission_id unique id of each submission
 * @return {Object} {success, message, next_page_link, prev_page_link, total_page, result}
 */
export const getSubmittedSubmissionsInfo = async ({
  submission_id, page, search_value = "", search_by = "all", date_from, date_to, user_selected_date_from, user_selected_date_to, submitted_by, sample_category = "",
}, signal = null) => {
  try {
    const resp = await axios.get(`${API_URL}/samplesubmission/`, {
      params: {
        page,
        search_value,
        search_by,
        date_from,
        date_to,
        user_selected_date_from,
        user_selected_date_to,
        submission_id,
        submitted_by,
        sample_category,
      },
      signal,
    });
    const {
      message, next_page_link, prev_page_link, total_page, result, dates, submitted_by_lst,
    } = resp?.data;
    return {
      success: resp.data.message === "Success",
      message,
      next_page_link,
      prev_page_link,
      total_page,
      dates,
      result, // either list of submissions or detailed info (including all samples) of a submission
      submitted_by_lst,
    };
  } catch (e) {
    if (!submission_id) getViewAsPerStatusCode(e);
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Get a list of all drafts (when submission_id is ""),
 * or detailed info of a draft submission (when a non-empty submission_id is given)
 * @param  {String} submission_id Selector for the element
 * @return {Object} {success, message, result}
 */
export const getDraftSubmissionsInfo = async (submission_id = "", signal = null) => {
  try {
    const resp = await axios.get(`${API_URL}/submissiondraft/`, {
      params: {
        submission_id,
      },
      signal,
    });
    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      result: resp.data.result, // either list of draft submissions, or single submission
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Get the customer address for the submission form
 * @param {String} customerID if logged out, get customer address from customerID
 * @return  {Object} {success, contact_name, email, company, address, city, zip, state, telephone}
 */
export const getCustomerAddress = async (customerID, sample_category) => {
  try {
    const user = await getUserCredentials();

    const params = { side: sample_category };
    if (customerID !== undefined) {
      params.customer_id = customerID;
    }

    const resp = await axios.get(`${API_URL}/customerinfo/`, {
      params,
    });

    const result = {
      success: resp.data.message === "Success",
      message: resp.data.message,
    };

    result.address = { ...resp.data.result };

    if (customerID === undefined) {
      result.address.user_email = user.user_email ?? "";
      result.address.user_name = user.user_name ?? "";
    }

    return result;
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Get the sample field options for the submission form, includes {json_field, title_field, display} for each field
 * @param   {Boolean} type environment or product
 * @return  {Object} {success, sampleFields: {} }
 */
export const getSampleFields = async (type = "product") => {
  try {
    const resp = await axios.get(`${API_URL}/${type}fields/`);
    const {
      fields, delimeter,
      link_report,
      link_pattern_fields,
      sampleid_fields,
    } = resp?.data?.result;

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      fields,
      sampleid_fields,
      delimiterRegex: delimeter,
      link_report,
      link_pattern_fields,
    };
  } catch (e) {
    getViewAsPerStatusCode(e);
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Get dropdown suggestions and tests to autofill for diff input value combinations
 * @param {Object} sample {item: "", time: "", ...}
 * @param {AbortSignal} signal abort controller signal
 * @return  {Object} {success, message, result: { item: [], time: [], best_by: [], tests: [], ...} }
 */
export const getDropdownSuggestionsAndTests = async (sample, signal, sample_category) => {
  try {
    const resp = await axios.post(`${API_URL}/ssautofill/`, { sample_category, sample }, { signal });

    return {
      success: resp.data.message === "Success",
      ...resp.data,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/** Get test suggestions for the analysis request part of submission form
* @param {String} search the search input used to find suggestions
* @param {String} customerID customer's id
* @param {String} sampleCategory product/environment
* @param {Boolean} displayNameOnly show only display names
* @return {Object} {success, message, testList: [""]}
*/
export const getTestList = async (search, customerID, sampleCategory, displayNameOnly = false) => {
  try {
    const params = { search, side: sampleCategory, display_name_only: displayNameOnly ? "True" : "False" };
    if (customerID !== undefined) {
      params.customer_id = customerID;
    }

    const resp = await axios.get(`${API_URL}/testsuggestions/`, {
      params,
    });

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      testList: resp.data.result,
    };
  } catch (e) {
    getViewAsPerStatusCode(e);
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/** Get generated sample id based on the current field values
* @param {Object} payload {submission_id, sample, editing_sample_id (if editing sample)}
* @return {Object} {success, message, sample_id}
*/
export const getSampleID = async (payload, signal) => {
  try {
    const resp = await axios.post(`${API_URL}/displaysampleid/`, {
      ...payload,
    }, { signal });

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      sample_id: resp.data.result,
    };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/**
 * Saves submission draft outside of sample list changes
 * @param  {Object} payload {submissionName, poNumber, submissionID (except for the first save)}
 * @return  {Object} {success, message, submissionID}
 */
export const saveDraft = async (payload) => {
  try {
    const user = await getUserCredentials();
    const resp = await axios.post(`${API_URL}/submissiondraft/`, {
      submitted_by: user.user_name || "",
      submission_name: payload.submissionName,
      po: payload.poNumber,
      submission_id: payload.submissionID || "",
      adding_sample: false,
      sample_category: payload.sample_category,
    });

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      submissionID: resp.data.submission_id,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Adds sample for that submission draft
 * @param  {Object} payload {submissionName, poNumber, submissionID (except for the first save), sample}
 * @return  {Object} {success, message, submissionID, sampleID}
 */
export const addSampleToDraft = async (payload) => {
  try {
    const user = await getUserCredentials();
    const resp = await axios.post(`${API_URL}/submissiondraft/`, {
      submitted_by: user.user_name || "",
      submission_name: payload.submissionName,
      po: payload.poNumber,
      submission_id: payload.submissionID || "",
      sample: payload.sample,
      test_list: payload.testList,
      fields: payload.savedFields,
      adding_sample: true,
      sample_category: payload.sample_category,
    });

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      submissionID: resp.data.submission_id,
      sampleID: resp.data.result,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Edits existing sample for that submission draft
 * @param  {Object} payload {submissionName, poNumber, submissionID, oldSampleID, editedSample}
 * @return  {Object} {success, message, submissionID, sampleID}
 */
export const editSampleInDraft = async (payload) => {
  try {
    const user = await getUserCredentials();
    const resp = await axios.patch(`${API_URL}/submissiondraft/`, {
      submitted_by: user.user_name || "",
      submission_name: payload.submissionName,
      po: payload.poNumber,
      submission_id: payload.submissionID,
      sample_id: payload.oldSampleID,
      sample: payload.editedSample,
      test_list: payload.testList,
      fields: payload.savedFields,
      sample_category: payload.sampleCategory,
    });

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      submissionID: resp.data.submission_id,
      sampleID: resp.data.result,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Deletes sample from submission draft
 * @param  {Object} payload {submissionName, poNumber, submissionID, sampleID}
 * @return  {Object} {success, message}
 */
export const deleteSampleFromDraft = async (payload) => {
  try {
    const resp = await axios.delete(`${API_URL}/submissiondraft/`, {
      data: {
        submission_name: payload.submissionName,
        po: payload.poNumber,
        submission_id: payload.submissionID || "",
        sample_id: payload.sampleID,
        sample_type: payload.sampleType,
        sample_category: payload.sampleCategory,
      },
    });

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Deletes a draft from submission drafts list
 * @param  {Object} submissionID draft's submission id
 * @return  {Object} {success, message}
 */
export const deleteDraft = async (submissionID, sampleCategory) => {
  try {
    const resp = await axios.delete(`${API_URL}/submissiondraft/`, {
      data: {
        submission_id: submissionID || "",
        sample_id: "", // use the same api as deleteSample(), but should provide an empty sample_id here
        sample_category: sampleCategory,
      },
    });

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Adds submission
 * @param  {Object} payload {submissionName, poNumber, submissionID, sampleList}
 * @param {String} customerDetails if logged out, customerDetails will be {customerID, submittedBy, userEmail}, otherwise undefined
 * @return  {Object} {success, message}
 */

export const submitForm = async (payload, customerDetails) => {
  try {
    const user = await getUserCredentials();

    const _payload = {
      submission_name: payload.submissionName,
      po: payload.poNumber,
      submission_id: payload.submissionID || "",
      samples: payload.sampleList,
      comment: payload.comment,
      sample_category: payload.sampleCategory,
    };

    if (customerDetails === undefined) {
      _payload.submitted_by = user.user_name || "";
      _payload.user_email = user.user_email || "";
    } else {
      const { customerID, submittedBy, userEmail } = customerDetails;
      _payload.customer_id = customerID || "";
      _payload.submitted_by = submittedBy || "";
      const firstEmail = userEmail ? userEmail.split(",")[0] : "";
      _payload.user_email = firstEmail.trim();
    }

    const resp = await axios.post(`${API_URL}/samplesubmission/`, {
      ..._payload,
    });

    return { success: resp.data.message === "Success", message: resp.data.message };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Adds samples to submission
 * @param  {Object} payload {submissionID, sampleList, status, poNumber}
 * @return  {Object} {success, message}
 */
export const addSamplesToSubmission = async (payload) => {
  try {
    const resp = await axios.patch(`${API_URL}/samplesubmissioneditdelete/`, {
      submission_id: payload.submissionID ?? "",
      po: payload.poNumber ?? "",
      submission_status: payload.status ?? "",
      samples: payload.sampleList ?? [],
      action: "add-sample",
      sample_category: payload.sampleCategory,
    });

    return {
      success: resp.data.message === "Success",
      message: "Failed to add samples to submission",
    };
  } catch (e) {
    return { success: false, message: "Failed to add samples to submission" };
  }
};

/**
 * Edits sample in submission
 * @param  {Object} payload {submission_id, submission_status, sample_id, sample_type, new_sample_id?, new_sample_type?, added_tests?, deleted_tests?, image_path?}
 * @return  {Object} {success, message}
 */
export const editSampleInSubmission = async (payload) => {
  try {
    const resp = await axios.patch(`${API_URL}/samplesubmissioneditdelete/`, {
      ...payload,
      action: "edit-sample",
    });

    return {
      success: resp.data.message === "Success",
      message: "Failed to edit sample",
    };
  } catch (e) {
    return { success: false, message: "Failed to edit sample" };
  }
};

/**
 * Delete sample from submission
 * @param  {String} submissionId
 * @param  {String} status status flag for submission
 * @param  {Object} sample
 * @return  {Object} {success, message}
 */
export const deleteSampleFromSubmission = async (submissionID, status, sample, sample_category) => {
  try {
    const resp = await axios.patch(`${API_URL}/samplesubmissioneditdelete/`, {
      submission_id: submissionID,
      submission_status: status,
      sample_type: sample.sample_type,
      sample_id: sample.sample_id,
      action: "delete-sample",
      sample_category: sample.sample_category || sample_category,
    });

    return {
      success: resp.data.message === "Success",
      message: "Failed to delete sample",
    };
  } catch (e) {
    return { success: false, message: "Failed to delete sample" };
  }
};

/**
 * Delete submission
 * @param  {String} submissionId
 * @param  {String} status status flag for submission
 * @return  {Object} {success, message}
 */
export const deleteSubmission = async (submissionID, status, sample_category) => {
  try {
    const resp = await axios.patch(`${API_URL}/samplesubmissioneditdelete/`, {
      submission_id: submissionID,
      submission_status: status,
      action: "delete-submission",
      sample_category,
    });

    return {
      success: resp.data.message === "Success",
      message: "Failed to delete submission",
    };
  } catch (e) {
    return { success: false, message: "Failed to delete submission" };
  }
};

/**
 * Update comment to submission
 * @param  {Object} payload {submissionID, sampleList, status, poNumber}
 * @return  {Object} {success, message}
 */
export const editCommentToSubmission = async (payload) => {
  try {
    const resp = await axios.patch(`${API_URL}/samplesubmissioneditdelete/`, {
      submission_id: payload.submission_id ?? "",
      submission_status: payload.submission_status ?? "",
      comment: payload.comment,
      action: "edit-comment",
      sample_category: payload.sample_category,
    });

    return {
      success: resp.data.message === "Success",
      message: "Failed to add samples to submission",
    };
  } catch (e) {
    return { success: false, message: "Failed to add samples to submission" };
  }
};

/**
 * Get a list of invited emails
 * @param  {Object} payload
 * @param  {String} type type of submission (env or product)
 * @return  {Object} {success, message}
 */
export const getInviteEmails = async () => {
  try {
    const resp = await axios.get(`${API_URL}/thirdpartyinvitation/`);
    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      result: resp.data.result,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Invite 3rd party
 * @param  {Object} payload
 * @param  {String} type type of submission (env or product)
 * @return  {Object} {success, message}
 */
export const sendInviteEmails = async (payload) => {
  try {
    const resp = await axios.post(`${API_URL}/thirdpartyinvitation/`, {
      emails: payload.emails,
      message: payload.message,
      sample_category: payload.sample_category,
    });

    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      result: resp.data.result,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};
/**
 * Invite 3rd party
 * @param  {Object} payload
 * @param  {String} type type of submission (env or product)
 * @return  {Object} {success, message}
 */
export const deleteInviteEmail = async (email) => {
  try {
    const resp = await axios.patch(`${API_URL}/thirdpartyinvitation/`, {
      email,
      action: "delete",
    });
    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Check if the invited link is valid or not
 * @param  {Object} payload
 * @return  {Object} {success, message}
 */
export const checkValidLink = async (payload) => {
  try {
    const resp = await axios.get(`${API_URL}/thirdpartyvalidation/`, {
      params: {
        link: payload.link,
        email: payload.invited_email,
        user_payload: payload.user_payload,
        company_name: payload.company_name,
      },
    });
    return {
      success: resp.data.message === "Success",
      message: resp.data.message,
      result: resp.data.result,
    };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

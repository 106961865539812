const TEMPLATE_TAGS_COLOR = {
  red: {
    backgroundColor: "#E63559",
    borderColor: "#F28DA1",
    boxShadow: "#e9e8e8 1px 2px 3px 3px",
  },
  blue: {
    backgroundColor: "#3874FF",
    borderColor: "#26ABE1",
    boxShadow: "#e9e8e8 1px 2px 3px 3px",
  },
  yellow: {
    backgroundColor: "#E1B226",
    borderColor: "rgb(244 222 155)",
    boxShadow: "#e9e8e8 1px 2px 3px 3px",
  },
  orange: {
    backgroundColor: "#F98006",
    borderColor: "rgb(251 196 140)",
    boxShadow: "#e9e8e8 1px 2px 3px 3px",
  },
  green: {
    backgroundColor: "#1EC04A",
    borderColor: "#52E378",
    boxShadow: "#e9e8e8 1px 2px 3px 3px",
  },
};

export default TEMPLATE_TAGS_COLOR;

/**
 * Get linked field vals as an array and as a string delimited by a dot
 * @param {Object} valuesObj linked field: value obj i.e. {item: "test", best_by: ""}
 * @param {Array} linkReportFields linked field json fields i.e. [item, best_by]
 * @returns {Object} linked field vals as an array and as a string (delimited by a dot)
 */
const getProductIdentifier = (valuesObj, linkReportFields) => {
  const identifierArr = [];
  linkReportFields.forEach((json_field) => {
    const value = valuesObj[json_field];
    if (value) {
      identifierArr.push(value);
    }
  });
  return { identifierArr, identifierStr: identifierArr.join(" \u2022 ") };
};

/**
 * Get list of distinct identfiers for samples in sampleList
 * @param {Array} sampleList
 * @param {Array} linkReportKeys linked field json fields i.e. [item, best_by]
 */
export const getDistinctIdentifiersForSampleList = (sampleList, linkReportKeys) => {
  const identifiersList = [];
  const productsSeenSoFar = new Set();
  sampleList.forEach(
    (sample) => {
      const { identifierArr, identifierStr } = getProductIdentifier(sample, linkReportKeys);
      if (!productsSeenSoFar.has(identifierStr)) {
        productsSeenSoFar.add(identifierStr);
        identifiersList.push(identifierArr);
      }
    },
  );
  return identifiersList;
};

export function createAndAppendIdentifierList(response, linkReportKeys) {
  const result = response.result;
  if (linkReportKeys && linkReportKeys.length) {
    result.forEach(
      (report) => {
        report.all_identifiers = getDistinctIdentifiersForSampleList(report.sample_list, linkReportKeys);
      },
    );
  }
  return result;
}

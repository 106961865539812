import React from "react";
import moment from "moment";

export default function PreviewDateBlock(props) {
  const {
    date, active, updateActiveDate,
  } = props;

  const year = moment(new Date(date)).format("YY");
  const month = moment(new Date(date)).format("MMM");
  const day = moment(new Date(date)).format("DD");

  return (
    <div className={active ? "previewDateBlockContainer previewDateBlockContainerActive" : "previewDateBlockContainer"} onClick={() => updateActiveDate(date)}>
      <div className={active ? "previewDateBlockDayContainer previewDateBlockDayContainerActive" : "previewDateBlockDayContainer"}>
        {day}
      </div>

      <div className={active ? "previewDateBlockMonthYearContainer previewDateBlockMonthYearContainerActive" : "previewDateBlockMonthYearContainer"}>
        {month}
        {" "}
        {year}
      </div>
    </div>
  );
}

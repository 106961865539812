import React from "react";
import datePickerIcon from "../../../../../assets/images/analytics/datePickerIcon.png";
import DatePicker from "../../../../Common/DatePicker/DatePicker";
import {
  getDates,
} from "../../../../../actions/productAnalytics";
import "./AnalyticsDatePicker.css";

export default function AnalyticsDatePicker({
  selectedData,
  showDatePicker,
  setDatePicker,
  searchByDate,
  dateSelected,
  setDateSelected,
  prevDateSelected,
  setPrevDateSelected,
}) {
  const { selectedProducts, selectedTests } = selectedData;

  const getReportDates = async () => {
    const payload = {
      products: selectedProducts,
      tests: selectedTests,
    };
    const response = await getDates(payload);
    if (response && response.success) {
      const dates = response.result;
      return dates ?? [];
    }
    return [];
  };

  return (
    <DatePicker
      showCalendar={showDatePicker}
      setShowCalendar={setDatePicker}
      handleSearchDate={searchByDate}
      value={dateSelected}
      onChange={setDateSelected}
      prevValue={prevDateSelected}
      setPrevValue={setPrevDateSelected}
      getReportDates={getReportDates}
      getPopupContainer={() => document.getElementById("AnalyticsDatePickerPopupContainer")}
      autoAdjustOverflow={false}
      popoverClassName="AnalyticsDatePickerPopover"
      calendarClassName="AnalyticsCalendar"
      disableClickAway
      disableSingleDaySelection
      datePickerDisplayProps={{
        displayElemId: "AnalyticsDatePickerPopupContainer",
        displayClassName: "analyticsDateIconContainer",
        showText: false,
        customCalendarIcon: <img src={datePickerIcon} alt="datepicker" className="analyticsDatePickerIcon" />,
      }}
    >
      {/* <div className="analyticsDateIconContainer" id="AnalyticsDatePickerPopupContainer">
        <img src={datePickerIcon} alt="datepicker" className="analyticsDatePickerIcon" />
      </div> */}
    </DatePicker>
  );
}

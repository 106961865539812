import React from "react";
import { ReactComponent as Arrow } from "../../../../assets/images/common/LeftArrowRounded.svg";
import "./InviteButton.css";
import StyledButton from "../../../Common/UIComponents/StyledButton";

export default function InviteButton({
  isSendingInvite, handleSubmit,
}) {
  return (
    <StyledButton
      htmlType="submit"
      onClick={isSendingInvite ? null : handleSubmit}
      loading={isSendingInvite}
      className="signup-invite-invite-btn"
      icon={<Arrow className="SignupInvite__InviteButton__Arrow" />}
    >
      {isSendingInvite ? "Sending Invite" : "Send Invite"}
    </StyledButton>
  );
}

import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import OutsideClick from "../../../Report/OutsideClick";
import { productSearch } from "../../../../../actions/productAnalytics";
// import { ReactComponent as FilterIcon } from "../../../../../assets/images/analytics/FilterIcon.svg";
// import { ReactComponent as CloseIcon } from "../../../../../assets/images/sample-submission/blueCloseIcon.svg";
import magnifyingGlassBlue from "../../../../../assets/images/analytics/magnifyingGlass_midnight.png";
import magnifyingGlassWhite from "../../../../../assets/images/white-magnifying-glass.png";
import "./Search.css";
import StyledRadioGroup from "../../../../Common/UIComponents/StyledRadioGroup";

export default function Search({
  updateProductList,
  setLoading,
  selectedTests,
  updateSearch,
  linkReportFields,
  fieldsMap,
  search,
  searchByField,
  setSearchByField,
}) {
  const [searchInternal, setSearchInternal] = useState("");
  const [searchBarActive, setSearchBarActive] = useState(false);
  const searchAbortController = useRef(null);

  // API call to get a list of products based on search and tests
  async function getProducts(search_by) {
    setLoading(true);
    const payload = {
      search: searchInternal,
      search_by,
      tests: selectedTests,
      linkReportFields,
    };
    const newController = new AbortController();
    /** If prev search api still in progress, abort it */
    if (searchAbortController.current) {
      searchAbortController.current.abort();
    }
    searchAbortController.current = newController;

    const response = await productSearch(payload, newController.signal);
    if (newController.signal.aborted) {
      return;
    }
    if (response.success) {
      updateProductList(response.result);
    } else {
      toast.error("Failed to search products");
      updateProductList([]);
    }
    searchAbortController.current = null;
    setLoading(false);
  }

  // When user hits enter or removes the text
  const searchForProducts = (e, type) => {
    if (e.key === "Enter" || (e.key === "Backspace" && e.target.value === "") || type === "clicked") {
      getProducts(searchByField);
      updateSearch(searchInternal);
    }
  };

  /** When user changes the search by field, call product search api */
  const handleSearchByChange = (field) => {
    setSearchByField(field);
    getProducts(field);
  };

  const toggleSelectSearchBar = (bool) => {
    setSearchBarActive(bool);
  };

  const onOutsideClick = () => {
    if (!search) {
      toggleSelectSearchBar(false);
    }
  };

  let backgroundColor = "";
  let placeholder = "";
  let icon = magnifyingGlassWhite;
  if (searchInternal?.length > 0) {
    backgroundColor = "#26A1D2";
  } else if (searchBarActive) {
    backgroundColor = "#DEF2FB";
  } else {
    backgroundColor = "#E1E9F1";
    placeholder = "Search a product";
    icon = magnifyingGlassBlue;
  }

  const showFilters = linkReportFields.length > 1 && searchBarActive;

  return (
    <OutsideClick
      className="Analytics__Search__OutsideClickContainer"
      onCancelEdit={onOutsideClick}
    >
      <div className={`Analytics__ProductSearch${showFilters ? "--show-filters" : ""}`}>
        <div className="Analytics__Search__InputContainer" onClick={() => toggleSelectSearchBar(true)}>
          <input
            className="Analytics__Search__Input"
            style={{ borderColor: backgroundColor }}
            type="text"
            placeholder={placeholder}
            onChange={(e) => setSearchInternal(e.target.value)}
            value={searchInternal}
            onKeyUp={(e) => searchForProducts(e)}
          />
          <div
            className="Analytics__Search__MagnifyingGlassContainer"
            onClick={(e) => searchForProducts(e, "clicked")}
            style={{
              backgroundColor,
            }}
          >
            <img src={icon} alt="search" className="Analytics__Search__MagnifyingGlass" />
          </div>
        </div>

        {/* {!showFilters && linkReportFields.length > 1
        && (
          <FilterIcon className="Analytics__ProductSearch__FilterIcon" title="Show filters" role="button" onClick={() => setShowFilters(true)} />
        ) } */}
        <AnimatePresence>
          {showFilters
        && (
          <motion.div
            initial={{
              height: 0, scaleY: 0, opacity: 0, y: "-100%",
            }}
            animate={{
              height: "auto", scaleY: 1, opacity: 1, y: "0", transformOrigin: "top",
            }}
            exit={{
              height: 0, scaleY: 0, opacity: 0, y: "-100%",
            }}
            transition={{ type: "tween", ease: "linear" }}
            className="Analytics__ProductSearch__Filters"
          >
            {/* <CloseIcon className="Analytics__ProductSearch__Filters__CloseIcon" title="Close filters" role="button" onClick={() => setSearchBarActive(false)} /> */}
            <StyledRadioGroup
              value={searchByField}
              onChange={(e) => handleSearchByChange(e.target.value)}
              options={linkReportFields.map((json_field) => ({ label: fieldsMap[json_field].title_field, value: json_field }))}
            />
          </motion.div>
        )}
        </AnimatePresence>

      </div>
    </OutsideClick>
  );
}

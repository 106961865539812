import React, { useEffect, useState } from "react";

import { Flex, Row } from "antd";
import { useHistory } from "react-router-dom";

import "./LandingPage.css";
import StyledButton from "../../../Common/UIComponents/StyledButton";

import arrow from "../../../../assets/images/Arrow 7.png";

export default function SuccessLandingPage({ GroupPNG }) {
  const [count, setCount] = useState(5);
  const history = useHistory();

  const handleReloadScreen = () => {
    history.push("/sample-submission");
    window.location.reload(false);
  };

  useEffect(() => {
    // If count is 0, Redirect to sample submission page and make reload.
    if (count === 0) { history.push("/sample-submission"); window.location.reload(false); }

    // Set up a timer to update the count every second
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    // Clear timer when component unmounts or when count changes
    return () => clearInterval(timer);
  }, [count, history]);

  return (
    <Row justify="center" className="LandingPage__Div" span={24}>
      <Flex className="LandingPage">
        <div className="LandingPage__Container">
          <p className="SuccessLandingPage__Text">
            Successfully set up fields.
          </p>
          <p className="SuccessLandingPage__Heading">
            Taking you to sample submission in
            {" "}
            {count}
            s.
          </p>

          <StyledButton type="primary" className="SuccessLandingPage__Btn" onClick={handleReloadScreen}>
            Sample Submission
            <img src={arrow} alt="" className="SuccessLandingPage__BackArrow" />
          </StyledButton>
        </div>
        <div className="LandingPage__Image">
          <img src={GroupPNG} alt="" />
        </div>
      </Flex>
    </Row>
  );
}

import React from "react";
import { ReactComponent as AddIconCircleBlue } from "../../../../assets/images/environment/addIconCircleBlue.svg";
import "./EmptyPage.css";

export default function EmptyPage(props) {
  const { toggleShowCreateEnvModal } = props;

  const handleClick = () => {
    toggleShowCreateEnvModal();
  };

  return (
    <>
      <div className="env-empty-page">
        <div className="env-upload-body">
          <AddIconCircleBlue><title>add icon</title></AddIconCircleBlue>
          <div className="env-upload-body top-label" onClick={handleClick}>Upload Files to create an Environment</div>
          <span>Max. File Size 50 MB</span>
        </div>
      </div>
    </>
  );
}

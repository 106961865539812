import React from "react";
import SpecManagerInput from "./SpecManagerInput";
import SpecManagerSelect from "../../../../Product/Report/Components/SpecManager/SpecManagerSelect";
import "./SpecsRange.css";

export const UNIT_OPTIONS = ["None", "CFU/g", "CFU", "mg/kg", "ppb", "ppm", "%", "g", "ml"];
class SpecsRange extends React.Component {
  onRangeFromChange = (value) => {
    const { data, handleDataChange } = this.props;
    handleDataChange([value, data[1], data[2]]);
  }

  onRangeToChange = (value) => {
    const { data, handleDataChange } = this.props;
    handleDataChange([data[0], value, data[2]]);
  }

  onOptionClick = (optionSelected) => {
    const { data, handleDataChange } = this.props;
    handleDataChange([data[0], data[1], optionSelected]);
  }

  render() {
    const { data } = this.props;
    const [rangeFrom, rangeTo, percentage] = data;
    return (
      <div
        className="EnvSpecsRangeContainer"
      >
        <SpecManagerInput
          placeHolder="eg: 0.75"
          onChange={this.onRangeFromChange}
          value={rangeFrom}
          width="72px"
          additionalClassName={(!rangeFrom) ? "envRangeValueMissing" : ""}
        />
        -
        <SpecManagerInput
          placeHolder="eg: 2.0"
          onChange={this.onRangeToChange}
          value={rangeTo}
          width="72px"
          additionalClassName={(!rangeTo) ? "envRangeValueMissing" : ""}
        />
        <SpecManagerSelect
          width="134px"
          optionSelected={percentage === "%" ? "%" : percentage || "None"}
          options={UNIT_OPTIONS}
          onSelect={this.onOptionClick}
        />
      </div>
    );
  }
}

export default SpecsRange;

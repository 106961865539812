import moment from "moment";
import React, { Component } from "react";
import Scrollbar from "react-scrollbars-custom";
import DocumentsMenuOption from "./DocumentsMenuOption";
import "./DocumentsListView.css";

export default class DocumentsListView extends Component {
  render() {
    const {
      files,
      readOnly,
      handleCheckbox,
      handleCheckboxAll,
      selectAll,
      handleFileOptionClick,
      handleNameChange,
      handleNameSubmit,
      handleRenameFile,
      handleDownloadFile,
      handleConfirmDelete,
      selectedFile,
    } = this.props;

    return (
      <Scrollbar
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
        className="DocumentsListViewScrollbar"
      >
        <table className="docFileTable">
          <thead className="DocFileTable__Thead">
            <tr className="DocFileTableHeadInside">
              <th className="docCheckBoxCol">
                <label className="checkboxContainer">
                  <input
                    name="all"
                    id="all"
                    type="checkbox"
                    onChange={handleCheckboxAll}
                    checked={selectAll}
                  />
                  <span className="checkmark DocFileTable-CheckMark" />
                </label>
              </th>
              <th className="DocListView__TableNameCol">Name</th>
              <th className="DocListView__TableTypeCol">Type</th>
              <th className="DocListView__TableSizeCol">Size</th>
              <th className="DocListView__TableDateCol">Date Uploaded</th>
              {/* <th className="docSortByFilter">
                Sort by
                <select className="sortByFilterOptions" name="" id="">
                  <option value="Name">Name</option>
                  <option value="Most Recent">Most Recent</option>
                  <option value="Oldest">Oldest</option>
                </select>
              </th>
              <th>
                <div
                  className="docViewButton"
                  onClick={() => this.handleChangeView()}
                >
                  Card View
                </div>
              </th> */}
            </tr>
          </thead>

          <tbody className="docFileTableBody">
            {files.map((file) => (
              <tr
                onClick={(e) => handleCheckbox(e, file.key)}
                key={file.key}
                className={file.checked ? "docSelectedFile DocFileTableBody__tr" : "DocFileTableBody__tr"}
              >
                <td className="DocFileTableBody__td">
                  <label className="checkboxContainer">
                    <input
                      onChange={(e) => handleCheckbox(e, file.key)}
                      className="DocFileTableBody__CheckboxInput"
                      type="checkbox"
                      checked={file.checked}
                      name={file.name}
                      id={file.key}
                    />
                    <span className="checkmark DocFileTable-CheckMark" />
                  </label>
                </td>
                <td className="DocListView__TableNameCol">
                  {file.renameDisable ? (
                    file.renameValue
                  ) : (
                    <input
                      disabled={file.renameDisable}
                      className={
                          !file.renameDisable
                            ? " docFileNameInput docFileNameInputEdit"
                            : "docFileNameInput"
                        }
                      onChange={(e) => handleNameChange(e, file.key)}
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(e) => handleNameSubmit(e, file.key)}
                      type="text"
                      value={file.renameValue}
                    />
                  )}
                </td>
                <td className="DocListView__TableTypeCol">{file.extension.toUpperCase()}</td>
                <td className="DocListView__TableSizeCol">{file.fileSize}</td>
                <td className="DocListView__TableDateCol">
                  <div className="DocListView__TableDateCol__Content">
                    <div className="DocListView__TableDateCol__Content__Text">{moment(new Date(file.uploadDate)).format("lll")}</div>
                    {!readOnly && (
                      <DocumentsMenuOption
                        optionFile={file}
                        selectedFile={selectedFile}
                        handleRenameFile={() => handleRenameFile(null, file)}
                        handleConfirmDelete={() => handleConfirmDelete(null, file, false)}
                        handleDownloadFile={() => handleDownloadFile(null, file, false)}
                        handleFileOptionClick={(e) => handleFileOptionClick(e)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Scrollbar>
    );
  }
}

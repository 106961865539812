import React from "react";
import "./StyledRadioGroup.css";
import { Radio } from "antd";

export default function StyledRadioGroup({ // same props as AntD Radio.Group
  buttonStyle,
  defaultValue,
  disabled,
  name,
  options,
  optionType,
  size,
  value,
  onChange,
  className = "",
  children,
}) {
  return (
    <Radio.Group
      buttonStyle={buttonStyle}
      defaultValue={defaultValue}
      name={name}
      options={options}
      optionType={optionType}
      size={size}
      onChange={onChange}
      disabled={disabled}
      value={value}
      className={`StyledRadioGroup ${className}`}
    >
      {children}
    </Radio.Group>
  );
}

import React, { Component } from "react";

import PDFMerger from "pdf-merger-js/browser";
import { toast } from "react-toastify";

// import PinsDragLayer from "./Components/MapView/PinsDragLayer";
// import SpecManagerToggleButton from "./Components/SpecManager/SpecManagerToggleButton";
import CreateEnvModal from "./Components/CreateEnvModal/CreateEnvModal";
import EmptyPage from "./Components/EmptyPage";
import EnvConfirmModal from "./Components/EnvConfirmModal";
import AnalyticsListView from "./Components/ListView/AnalyticsListView";
import AnalyticsMapView from "./Components/MapView/AnalyticsMapView";
import { zoomToQuadrant, resetZoom } from "./Components/MapView/mapHelper";
import { calculateQuadrantGivenXYCoord } from "./Components/pinHelper";
import ReportPreviewModal from "./Components/Preview/ReportPreviewModal";
import ReportView from "./Components/ReportView/ReportView";
import SpecManagerDrawer from "./Components/SpecManager/SpecManagerDrawer";

import { s3EsvPriv, s3EsvPub } from "../../../utils/aws";
import { getFileFromS3, deleteFileFromS3 } from "../../../utils/helpers";

import {
  getEnvironmentFields,
  addSwab,
  editSwab,
  getSwabs,
  getMapTitles,
  editMapTitle,
  deleteMapTitle,
} from "../../../actions/envAnalytics";

import "./analytics.css";

export default class Analytics extends Component {
  transformWrapperRef = React.createRef();

  envCalendarRef = React.createRef();

  searchRef = React.createRef();

  mapComponentRef = React.createRef();

  getPointsAbortController = null;

  constructor(props) {
    super(props);
    this.state = {
      listViewDisplay: true,
      pinMap: new Map(),
      activeTabIndexInMapView: "0", // set default to "0" to handle the no map case
      tabsInMapView: [], // list of objects {image_link: "", image_height: 0, image_width: 0, title: "title"}
      indexOfQuadrantHoverOver: -1,
      selectedLocationID: "", // locationID for selected pin (may or may not match displayTooltipLocationID. i.e: when searching, a pin is not selected but tooltip should still be displayed)
      displayTooltipLocationID: "", // locationID for pin that should be displaying a tooltip
      mapKey: true, // used as key in TransformWrapper --> used to force re-init of map when toggling full map mode (reloads image with correct dimensions)
      quadrantSelected: -1, // not selected: -1, or 0, 1, 2, 3
      imageSrc: null,
      filterSelected: -1, // -1: no filter selected, 0: in process, 1: in spec, 2: out of spec
      filterByDateFrom: "",
      filterByDateTo: "",
      isAddingNewSwab: false,
      isPinningSwab: false,
      isPinningNewSwab: false, // needed for pindetailblock to not display choosing quadrants when pinning new swab
      searchResultSelected: "", // search input keyword
      loadingSwabs: true, // loading state for apiGetSwabs
      loadingMap: true, // loading state for loading map
      displayNoResultFound: false,
      // area_lists: {}, // depending on company link pattern will be zone/section lists used in create new swab
      fields: [], // from envreportfields, ex: [{title_field: "Swab Number", json_field: "swab_number"},...] already in order
      index0: "", // title field for the swab card
      linkPatternFields: [],
      sampleIDFields: [],
      delimiter: "", // delimiter for sample id
      isClickingQuadrant: false, // needed for the grayed out effect over the list view except the selected pin detail block when clicking a quadrant to pin a swab
      ifDeleteTitleClicked: false, // when clicked, show delete confirm modal
      titleToDelete: "",
      showReportView: false,
      dataArrvied: false, // used to determined if the both pin list and pin map data are arrived
      showCreateEnvModal: false,
      ifOnlyUploadSwabFile: false, // when click "Upload Swab File" from the top of swab list, set it to true
      showPreview: false,
      previewUrlList: [],
      showEmptyPage: false, // used to determine to show the landing empty page (when there is no any swab, and map)
      openCreateEnvModalFromTab: false,
      showSpecManager: false,
      showSpecManagerEdit: false, // whether to show edit spec page on mount of spec manager
      specManagerEditIdentifier: "", // identifier of swab whose spec is being edited in spec manager
      reportViewKey: false,
    };
  }

  componentDidMount() {
    // get all image titles of the current user
    const {
      activeTabIndexInMapView,
    } = this.state;
    this.apiGetFields().then(({
      allFields, index0, delimiter, linkPatternFields, sampleIDFields,
    }) => {
      if (!index0 || allFields.length === 0 || !linkPatternFields || linkPatternFields.length === 0) {
        toast.error("Missing fields, index0, and/or link pattern fields");
      } else if (allFields.length > 0 && allFields.filter((field) => field.json_field === index0).length === 0) {
        toast.error("index0 is missing from fields");
      }
      this.setState({
        index0, fields: allFields, delimiter, linkPatternFields, sampleIDFields,
      });
      this.apiGetMapTitles().then(({ success, mapTitles }) => {
        // this.setFields(linkPattern, allFields);
        if (!success) {
          toast.error("Unable to fetch maps.");
        }
        this.setState({ tabsInMapView: mapTitles });
        if (mapTitles?.length === 0) {
          this.setState({ loadingMap: false });
        }
        if (activeTabIndexInMapView === "0" && mapTitles.length > 0) { // if there is a map, then set the active tab to index 1
          this.setState({ activeTabIndexInMapView: "1" }, () => this.updatePinsList(mapTitles.length > 0 ? mapTitles[0].title : ""));
        } else {
          this.updatePinsList(mapTitles.length > 0 ? mapTitles[activeTabIndexInMapView - 1].title : "");
        }
      });
    });
    // const tabsInMapView = await this.apiGetMapTitles();
    // // const tabsInMapView = ["Food Processing Area testtesttest", "Packaging Area test", "Packaging Area testtest", "Testing Area"];
    // this.setState({ tabsInMapView });
    // // fetch the swabs on the current image
    // this.updatePinsList(tabsInMapView[this.state.activeTabIndexInMapView - 1]);
  }

  // /* Given a link pattern and a json-title mapping of all report table fields,
  //   returns a list of company's dynamic fields with json-title mapping */
  // setFields = (linkPattern, allFields) => {
  //   const fields = linkPattern.split(".");
  //   const fieldsWithMap = allFields.filter((field) => fields.includes(field.json_field)); // envreportfields returns all fields in reports table, we just want the fields given in link pattern
  //   this.setState({ fields: fieldsWithMap });
  // }

  // api call to get dynamic fields in order
  apiGetFields = async () => {
    const result = await getEnvironmentFields();
    // console.log(result);

    if (result && result.success) {
      const {
        fields, index0, delimiter, link_pattern_fields, sampleid_fields,
      } = result;
      const fieldsMap = {};
      fields.forEach((field) => { fieldsMap[field.json_field] = field; });
      const linkPatternFieldsArray = link_pattern_fields.map((json_field) => (fieldsMap[json_field]));
      return {
        allFields: fields, index0, delimiter, linkPatternFields: linkPatternFieldsArray, sampleIDFields: sampleid_fields,
      };
    }
    toast.error(result.message);

    return {
      allFields: [], index0: "", delimiter: "", linkPatternFields: [], sampleIDFields: [],
    };
  }

  apiGetMapTitles = async () => {
    const resp = await getMapTitles();
    // console.log(resp, "maptitles");
    if (resp.success) {
      return resp;
    }
    return resp;
  }

  endPinningSwab = () => {
    // console.log("end pinning");
    this.setState({
      isPinningSwab: false, selectedLocationID: "", isPinningNewSwab: false,
    });
  }

  handleTabSelectInMapView = (newActiveTabIndex, status) => {
    // console.log("handle select", newActiveTabIndex);
    const { tabsInMapView, activeTabIndexInMapView } = this.state;
    if (newActiveTabIndex !== "addMapTitleTabActive" && newActiveTabIndex !== "addMapTitleTabInactive"
    && (newActiveTabIndex !== activeTabIndexInMapView || status === "delete")) { // status is "delete" when deleting a title, used to handle the case of deleting the first title
      this.setState({
        activeTabIndexInMapView: newActiveTabIndex,
        indexOfQuadrantHoverOver: -1,
        displayTooltipLocationID: "",
        selectedLocationID: "",
        quadrantSelected: -1,
        filterSelected: -1,
        listViewDisplay: true,
        displayNoResultFound: false,
        isAddingNewSwab: false,
        isPinningSwab: false,
        imageSrc: null,
        loadingSwabs: true,
        loadingMap: true,
        searchResultSelected: "",
      });

      this.updatePinsList(tabsInMapView.length > 0 ? tabsInMapView[newActiveTabIndex - 1].title : "");
    }
  }

  handleUploadImage = ({
    title, image_height, image_width, path,
  }) => {
    const { tabsInMapView } = this.state;
    tabsInMapView[tabsInMapView.length] = {
      title, image_link: path, image_height, image_width,
    };
    const updatedActiveTabIndexInMapView = tabsInMapView.length.toString();

    this.setState({ tabsInMapView: [...tabsInMapView] }, () => {
      this.handleTabSelectInMapView(updatedActiveTabIndexInMapView);
    });
  }

  handleSpecManagerToggle = () => {
    const { showSpecManager } = this.state;
    this.setState({
      showSpecManager: !showSpecManager,
    });
  }

  setSelectedLocationID = (locationID) => {
    const { selectedLocationID } = this.state;
    if (selectedLocationID === locationID) {
      this.setState({ selectedLocationID: "", quadrantSelected: -1 });
      return;
    }
    this.setState({ selectedLocationID: locationID, quadrantSelected: -1, indexOfQuadrantHoverOver: -1 });
    // if (index !== -1) {
    //   this.setState({ mapKey: !mapKey });
    // }
  }

  handleHoverQuadrant = (index) => {
    this.setState({ indexOfQuadrantHoverOver: index });
  }

  setDisplayTooltipLocationID = (locationID) => {
    const { displayTooltipLocationID } = this.state;
    if (displayTooltipLocationID === locationID) {
      this.setState({ displayTooltipLocationID: "" });
      return;
    }
    this.setState({ displayTooltipLocationID: locationID });
  }

  toggleReportView = () => {
    const { showReportView } = this.state;
    this.setState({ showReportView: !showReportView });
  }

  /**
   * Set a new key to force the ReportView to rerender
   */
  forceReportViewRerender = () => {
    const { reportViewKey } = this.state;
    this.setState({ reportViewKey: !reportViewKey });
  }

  handleSearch = async (searchValue = "") => { // default clears search
    const {
      searchResultSelected,
      filterSelected,
      filterByDateFrom,
      filterByDateTo,
    } = this.state;
    if (searchValue !== searchResultSelected) { // only make the api call if necessary
      this.setState({ searchResultSelected: searchValue });
      const success = await this.updatePinsList(null, searchValue, filterSelected, filterByDateFrom, filterByDateTo);
      if (!success) {
        if (success !== null) { // null means the call was cancelled
          toast.error("Failed to fetch swabs");
          this.setState({
            pinMap: new Map(),
            displayNoResultFound: true,
            selectedLocationID: "",
            displayTooltipLocationID: "",
          });
        }
      }
      this.handleSelectQuadrant(-1); // zoom out
    }
  }

  handleSelectFilter = async (filterSelected) => {
    const {
      filterSelected: currentFilterSelected,
      searchResultSelected,
      filterByDateFrom,
      filterByDateTo,
    } = this.state;

    let _filter = filterSelected;

    if (filterSelected === -1 || currentFilterSelected === filterSelected) { // if clear filter
      _filter = -1;
    }
    this.setState({ filterSelected: _filter });
    const success = await this.updatePinsList(null, searchResultSelected, _filter, filterByDateFrom, filterByDateTo);
    if (!success) {
      if (success !== null) { // null means the call was cancelled
        toast.error("Failed to fetch swabs");
        this.setState({
          pinMap: new Map(),
          displayNoResultFound: true,
          selectedLocationID: "",
          displayTooltipLocationID: "",
        });
      }
    }
    this.handleSelectQuadrant(-1); // zoom out
  }

  handleFilterByDate = async (dateStart, dateEnd) => {
    const {
      filterSelected,
      searchResultSelected,
      filterByDateFrom,
      filterByDateTo,
    } = this.state;

    let date_from = dateStart;
    let date_to = dateEnd;

    if ((dateStart === undefined || dateEnd === undefined)) { // if clear dates
      if (filterByDateFrom === "" && filterByDateTo === "") { // don't call api if date filter already cleared
        return;
      }
      date_from = "";
      date_to = "";
    }
    this.setState({
      filterByDateFrom: date_from, filterByDateTo: date_to,
    });
    const success = await this.updatePinsList(null, searchResultSelected, filterSelected, date_from, date_to);
    if (!success) {
      if (success !== null) { // null means the call was cancelled
        toast.error("Failed to fetch swabs");
        this.setState({
          pinMap: new Map(),
          displayNoResultFound: true,
          selectedLocationID: "",
          displayTooltipLocationID: "",
        });
      }
    }
    this.handleSelectQuadrant(-1); // zoom out
  }

  toggleAddingSwab = () => {
    const {
      isAddingNewSwab, searchResultSelected, filterSelected, filterByDateFrom, filterByDateTo,
    } = this.state;
    if (!isAddingNewSwab) { // if we are toggling addingnewswab to be true, need to reset zoom
      resetZoom(this.transformWrapperRef, this.mapComponentRef);
      if (searchResultSelected || filterSelected > -1 || filterByDateFrom || filterByDateTo) { // only make api call if any filters applied
        this.updatePinsList();
      }
      this.setState({
        selectedLocationID: "",
        quadrantSelected: -1,
        indexOfQuadrantHoverOver: -1,
        filterSelected: -1,
        searchResultSelected: "",
        filterByDateFrom: "",
        filterByDateTo: "",
      });
      this.envCalendarRef.current.clearCalendar();
      this.searchRef.current.clearSearch();
    }
    this.setState({
      isAddingNewSwab: !isAddingNewSwab, displayTooltipLocationID: "", indexOfQuadrantHoverOver: -1,
    });
  }

  /** Clear filters, selected dates on calendar, and selected swab, if there are any */
  clearFilterCalendarAndSelected = () => {
    const {
      searchResultSelected, filterSelected, filterByDateFrom, filterByDateTo, displayTooltipLocationID, selectedLocationID,
    } = this.state;
    // if any filters applied, clear them
    if (searchResultSelected || filterSelected > -1 || filterByDateFrom || filterByDateTo) {
      this.setState({
        filterSelected: -1,
        searchResultSelected: "",
        filterByDateFrom: "",
        filterByDateTo: "",
      });
      this.envCalendarRef.current.clearCalendar();
      this.searchRef.current.clearSearch();
    }
    // unselect a swab if there was a selected one, and reset zoom to original
    if (displayTooltipLocationID !== "" || selectedLocationID !== "") {
      resetZoom(this.transformWrapperRef, this.mapComponentRef);
      this.setState({
        displayTooltipLocationID: "",
        selectedLocationID: "",
      });
    }
  }

  togglePinningNewSwab = () => {
    const { isPinningNewSwab } = this.state;
    this.setState({ isPinningNewSwab: !isPinningNewSwab });
  }

  updatePinInfo = async (flag, pinRelocatingLocationID, newPosition) => {
    const {
      pinMap,
      selectedLocationID,
      listViewDisplay,
      isPinningNewSwab,
      filterSelected,
      searchResultSelected,
      filterByDateFrom,
      filterByDateTo,
    } = this.state;

    let success;
    let locationID = selectedLocationID;
    if (pinRelocatingLocationID) {
      locationID = pinRelocatingLocationID;
    }

    if (pinMap.has(locationID)) {
      const pin = pinMap.get(locationID);
      if (flag === "pinTheLocation") {
        const quadrant = calculateQuadrantGivenXYCoord(newPosition);
        this.setState({ isPinningSwab: true });
        const editedPin = { ...pin, ...newPosition, quadrant };
        success = await this.apiEditSwab(editedPin);
        if (success) {
          const mapCopy = new Map(pinMap); // deep copy to trigger rerender
          mapCopy.set(locationID, editedPin);
          this.setState({ pinMap: mapCopy });
          if (listViewDisplay) { // not full map mode, getswabs so pindetailblock sorts correctly
            if (isPinningNewSwab) {
              await this.updatePinsList();
              this.setState({ filterSelected: -1, searchResultSelected: "" });
            } else {
              await this.updatePinsList(null, searchResultSelected, filterSelected, filterByDateFrom, filterByDateTo);
            }
          } else {
            this.setState({ isPinningSwab: false });
          } // don't need to getpoints again on fullmapmode, too slow
        }
      } else if (flag === "removePin") {
        const editedPin = { ...pin };
        editedPin.x_coordinate = null;
        editedPin.y_coordinate = null;
        editedPin.quadrant = null;
        success = await this.apiEditSwab({
          ...pin, x_coordinate: "", y_coordinate: "", category: "Delete",
        });
        if (success) {
          const mapCopy = new Map(pinMap);
          mapCopy.set(locationID, editedPin);
          this.setState({ pinMap: mapCopy, selectedLocationID: "", displayTooltipLocationID: "" });
        }
      } else if (flag === "toggleDraggable") {
        const givenPin = pinMap.get(locationID);
        givenPin.draggable = !givenPin.draggable;
        pinMap.set(locationID, givenPin);
        this.setState({
          selectedLocationID,
        });
      }
    }
    if (success) {
      this.setState({
        displayTooltipLocationID: "",
        indexOfQuadrantHoverOver: -1,
      });
      this.handleSelectQuadrant(-1, false);
    }
    return success;
  }

  handleAddNewSwab = async (swab_number, zone, section, swabDesc) => {
    const {
      pinMap,
    } = this.state;

    const resp = await this.apiAddSwab({
      swab_number, zone, section, description: swabDesc,
    });
    if (resp.success) {
      const newPin = {
        ...resp.swab,
        x_coordinate: null,
        y_coordinate: null,
      };
      // add new pin block to top of map
      const newPinMap = new Map([[newPin.location_id, newPin], ...pinMap]);
      this.setState({
        pinMap: newPinMap,
      });

      return { success: true, duplicate: false, newPin };
    }
    if (resp.message === "Duplicate") {
      return { success: false, duplicate: true };
    }
    toast.error(resp.message);
    return { success: false, duplicate: false };
  }

  toggleListViewDisplay = () => {
    let { listViewDisplay, mapKey } = this.state;
    listViewDisplay = !listViewDisplay;
    mapKey = !mapKey;
    const { pinMap } = this.state;
    if (listViewDisplay) {
      let shouldUpdatePin = false;
      pinMap.forEach((pin) => {
        if (pin.draggable) {
          delete pin.draggable;
          shouldUpdatePin = true;
        }
      });
      if (shouldUpdatePin) {
        this.setState({ pinMap });
      }
    }
    this.setState({
      listViewDisplay, mapKey, selectedLocationID: "", quadrantSelected: -1, indexOfQuadrantHoverOver: -1, displayTooltipLocationID: "",
    });
  }

  handleSelectQuadrant = (quadrantSelected, resetTheZoom = true) => {
    if (quadrantSelected > -1) {
      zoomToQuadrant(this.transformWrapperRef, quadrantSelected, this.mapComponentRef);
    } else if (resetTheZoom) { // when quadrant is deselected, sometimes we want to zoom out (cancelling pinning a swab), and sometimes we don't (click on map)
      resetZoom(this.transformWrapperRef, this.mapComponentRef);
    }
    this.setState({ quadrantSelected });
  }

  /** Updates pins when mapTitle changes (click on new tab), or after editing/adding point
   * @param {String} title title of the map. If it is null, refresh the swabs list without loading the map image
   * @param {String} searchValue search input
   * @param {String} filter out of spec, in spec, in process filters
   * @param {String} dateStart start date selected in calendar
   * @param {String} dateEnd end date selected in calendar
   */
  updatePinsList = async (title = null, searchValue = "", filter = "", dateStart = "", dateEnd = "") => {
    const noFiltersApplied = searchValue === "" && filter === "" && dateStart === "" && dateEnd === "";
    const { tabsInMapView, activeTabIndexInMapView, selectedLocationID } = this.state;
    let imageTitle = title;
    if (imageTitle === null) { // Do not load map image, just refresh swab list
      imageTitle = activeTabIndexInMapView === "0" ? "" : tabsInMapView[activeTabIndexInMapView - 1].title;
    }
    this.setState({ loadingSwabs: true });
    const loadSwabSuccess = await this.apiGetSwabs(imageTitle, searchValue, filter, dateStart, dateEnd)
      .then(async ({ success, cancelled, pinMap }) => {
        if (cancelled) { // don't do anything if call was cancelled
          return null;
        }
        if (title) { // only set new image src if landing on the page or switching maps
          await this.loadMapImage();
        } else if (title === "") { // no map titles, set source to null
          this.setState({ imageSrc: null, loadingMap: false });
        }
        if (success) {
          if (!pinMap.size || !pinMap.has(selectedLocationID)) {
            this.setState({
              selectedLocationID: "", displayTooltipLocationID: "",
            });
          }
          this.setState({
            pinMap: pinMap || new Map(),
            showEmptyPage: pinMap.size === 0 && tabsInMapView.length === 0 && noFiltersApplied,
            displayNoResultFound: title === null && pinMap.size === 0, // onMount and when switching maps, show upload swab file instead of no result
          });
        } else if (title) { // if the api call failed and we are switching to new map, set pinmap to empty
          toast.error("Failed to fetch swabs");
          this.setState({
            pinMap: new Map(),
            showEmptyPage: tabsInMapView.length === 0 && noFiltersApplied,
            displayNoResultFound: true,
            selectedLocationID: "",
            displayTooltipLocationID: "",
          });
        }
        this.setState({
          loadingSwabs: false,
          dataArrvied: true,
        });
        return success;
      });

    return loadSwabSuccess;
  }

  loadMapImage = async () => {
    this.setState({ loadingMap: true });
    const { tabsInMapView, activeTabIndexInMapView } = this.state;
    const imageInfo = tabsInMapView[activeTabIndexInMapView - 1];
    let imageSrc = null;
    if (imageInfo && imageInfo.image_link) {
      const imageBlob = await getFileFromS3(imageInfo.image_link, s3EsvPriv);
      if (imageBlob) {
        imageSrc = window.URL.createObjectURL(imageBlob);
      } else {
        imageSrc = "Not Found";
      }
      this.setState({ imageSrc });
    }
    this.setState({ loadingMap: false });
  };

  /** Upload swabs parsed from file to backend
   * @param pointsList
   */
  // addListOfPoints = async (pointsList) => {
  //   // const { tabsInMapView, activeTabIndexInMapView } = this.state;
  //   // const { title } = tabsInMapView[activeTabIndexInMapView - 1];
  //   // if (!title) {
  //   //   toast.error("Fail to upload swabs. No map title is selected.");
  //   //   return;
  //   // }
  //   const failedSwabNumbers = [];
  //   let nSwabsNoSwabNumber = 0;
  //   const failedSwabRecord = [];
  //   Promise.all(
  //     pointsList.map(async (point) => {
  //       const resp = await addSwab({ ...point }); // api already changed, should comment out this function
  //       if (resp.message !== "success") {
  //         failedSwabRecord.push(point);
  //         if (point.swab_number === "" || point.swab_number === undefined) {
  //           nSwabsNoSwabNumber++;
  //         } else {
  //           failedSwabNumbers.push(point.swab_number);
  //         }
  //       }
  //       return Promise.resolve(resp.message);
  //     }),
  //   ).then(async () => {
  //     if (failedSwabNumbers.length || nSwabsNoSwabNumber) {
  //       if (failedSwabNumbers.length === pointsList.length) {
  //         toast.error("Failed to add swabs");
  //       } else if (failedSwabNumbers.length <= 10) {
  //         toast.error(`Failed to add some swabs: ${failedSwabNumbers.join(", ")}`);
  //       } else {
  //         toast.error(`Failed to add some swabs: ${failedSwabNumbers.slice(0, 10).join(", ")}, ...`);
  //       }
  //     } else {
  //       toast.success("Successfully added swabs");
  //     }
  //     const { success, pinMap } = await this.apiGetSwabs();
  //     // console.log(success, pinMap);
  //     if (success) {
  //       this.setState({
  //         pinMap: pinMap || new Map(),
  //       });
  //     }
  //   });
  // }

  apiAddSwab = async (swab) => {
    let resp = await addSwab({ pointsList: [swab] });
    if (resp && resp.success && resp.successSwabs.length === 1 && !resp.failedSwabs.length) {
      resp = { success: true, ...resp.successSwabs[0] };
    } else {
      resp = {
        success: false,
        message: resp?.failedSwabs?.length === 1
          ? resp.failedSwabs[0].message ?? "Failed to add swab" : "Failed to add swab",
      };
    }
    return resp;
  }

  apiEditSwab = async (payload) => {
    const { activeTabIndexInMapView, tabsInMapView } = this.state;
    payload.title = tabsInMapView[activeTabIndexInMapView - 1].title;
    const resp = await editSwab(payload);
    if (!resp.success) {
      toast.error(resp.message);
    }
    return resp.success;
  }

  apiGetSwabs = async (mapTitle = "", searchValue = "", specFlag = -1, dateStart = "", dateEnd = "") => { // get swabs, including filtered swabs
    const params = {
      mapTitle, searchValue, specFlag, dateStart, dateEnd,
    };

    const newController = new AbortController();
    if (this.getPointsAbortController) {
      this.getPointsAbortController.abort();
    }
    this.getPointsAbortController = newController;

    const resp = await getSwabs(params, newController.signal);

    if (newController.signal.aborted) {
      return { cancelled: true };
    }

    if (resp && resp.success) {
      this.getPointsAbortController = null;
      return { success: resp.success, ...resp.data }; // pinMap, dates
    }
    this.getPointsAbortController = null;
    return { success: false };
  }

  getCalendarDates = async () => {
    const {
      tabsInMapView,
      activeTabIndexInMapView,
      searchResultSelected,
      filterSelected,
    } = this.state;
    const mapTitle = activeTabIndexInMapView === "0" ? "" : tabsInMapView[activeTabIndexInMapView - 1].title;
    const { success, dates } = await this.apiGetSwabs(mapTitle, searchResultSelected, filterSelected);
    if (success) {
      return dates ?? [];
    }
    return [];
  }

  // Comment out because add map work flow changes
  // handleAddMapTitle = async (newTitle) => {
  //   const { tabsInMapView } = this.state;
  //   const { success, message } = await addMapTitle({ newTitle });
  //   // console.log(newTitle, success);
  //   if (success) {
  //     this.setState({
  //       tabsInMapView: [...tabsInMapView, {
  //         image_link: "", image_height: 0, image_width: 0, title: newTitle,
  //       }],
  //     });
  //     if (!tabsInMapView.length) {
  //       this.updatePinsList(newTitle);
  //     }
  //   } else {
  //     if (message) {
  //       toast.error(message);
  //       return;
  //     }
  //     toast.error("Unable to add map title.");
  //   }
  // }

  handleEditMapTitle = async (newTitle) => {
    const { tabsInMapView, activeTabIndexInMapView } = this.state;
    const { success, message } = await editMapTitle({ oldTitle: tabsInMapView[activeTabIndexInMapView - 1].title, newTitle });
    // console.log(newTitle, success);
    if (success) {
      tabsInMapView[activeTabIndexInMapView - 1].title = newTitle;
      this.setState({ tabsInMapView: [...tabsInMapView] });
    } else {
      if (message) {
        toast.error(message);
        return;
      }
      toast.error("Unable to edit map title.");
    }
  }

  handleDeleteMapTitle = async (mapTitle) => {
    const { tabsInMapView, activeTabIndexInMapView } = this.state;
    const imageInfo = tabsInMapView[activeTabIndexInMapView - 1];
    const { success, message } = await deleteMapTitle({ title: mapTitle });
    if (success) {
      deleteFileFromS3(imageInfo.image_link, s3EsvPriv);
      const { mapTitles } = await this.apiGetMapTitles();
      if (activeTabIndexInMapView === "1") { // if this is the last image to delete
        this.setState({ tabsInMapView: mapTitles, activeTabIndexInMapView: "0" }, () => {
          this.handleTabSelectInMapView("0", "delete");
        });
      } else {
        this.setState({ tabsInMapView: mapTitles, activeTabIndexInMapView: "1" }, () => {
          this.handleTabSelectInMapView("1", "delete");
        });
      }
    } else {
      if (message) {
        toast.error(message);
        return;
      }
      toast.error("Unable to delete map title.");
    }
  }

  toggleDeleteTitleConfirm = (title) => {
    const { ifDeleteTitleClicked } = this.state;
    this.setState({ ifDeleteTitleClicked: !ifDeleteTitleClicked, titleToDelete: title });
  }

  /**
   * Toggle create env modal
   * @param {Boolean} onlyUploadSwabFile is true when user clicks on "Upload Swab File", to render a modal that only allows to upload swab files.
   * @param {Boolean} openCreateEnvModalFromTab is true when user clicks on "+" tab.
   */
  toggleShowCreateEnvModal = (ifOnlyUploadSwabFile = false, openCreateEnvModalFromTab = false) => {
    const { showCreateEnvModal } = this.state;
    this.setState({ showCreateEnvModal: !showCreateEnvModal, ifOnlyUploadSwabFile, openCreateEnvModalFromTab });
  }

  handleCloseForPreview = () => {
    this.setState({ showPreview: false });
  };

  handlePreviewFile = (filePath) => {
    // console.log("🚀 ~ handlePreviewFile ~ filePath:", filePath);
    this.setState({ previewUrlList: [] }, async () => {
      const path = filePath;
      if (!path || Object.keys(path).length < 1) {
        toast.error("Unable to preview, no valid file path for this report!");
        return;
      }
      const files = Object.entries(path);
      Promise.all(files.map(async (fileData) => {
        const fileTempSet = new Set(fileData[1]);
        const fileTempArr = Array.from(fileTempSet);
        return Promise.all(fileTempArr.map(async (fileTemp) => this.getFileFromAWS(fileTemp))).then(async (allPreviewFiles) => {
          const successfullyFetched = allPreviewFiles.filter((file) => file !== null); // null means file failed to fetch, don't merge it
          return this.mergePDFs(successfullyFetched, fileData[0]);
        });
      })).then((mergedPreviewFiles) => {
        this.setState({
          previewUrlList: mergedPreviewFiles,
          showPreview: true,
        });
      });
    });
  };

  /* Downloads file from correct (private or public) s3 bucket. Returns blob if successful, null otherwise. */
  getFileFromAWS = async (fileTemp) => {
    const privateS3Bucket = fileTemp.includes("COAs/");
    const fileName = fileTemp.split("/").pop();
    const file = await getFileFromS3(fileTemp, privateS3Bucket ? s3EsvPriv : s3EsvPub);
    if (file) {
      return { fileName, file };
    }
    return null;
  };

  /* if there are multiple pdfs for a single date in preview, merge into one pdf */
  mergePDFs = async (fileBlobs, date) => {
    if (fileBlobs.length > 1) {
      const merger = new PDFMerger();
      const fileNames = [];
      return Promise.all(fileBlobs.map(async ({ fileName, file }) => { // eslint-disable-line
        fileNames.push(fileName);
        return merger.add(file);
      })).then(async () => { // eslint-disable-line
        const mergedPdf = await merger.saveAsBlob();
        const FileTemp = new File([mergedPdf], "name");
        const pdfURL = window.URL.createObjectURL(mergedPdf);
        const filesObj = {
          url: pdfURL,
          privateS3: true,
          file: FileTemp,
          blob: mergedPdf,
          name: fileNames.join(),
          date,
        };
        return filesObj;
      });
    }

    if (fileBlobs.length === 1) {
      const { fileName, file } = fileBlobs[0];
      const FileTemp = new File([file], "name");
      const pdfURL = window.URL.createObjectURL(file);
      const filesObj = {
        url: pdfURL,
        privateS3: true,
        file: FileTemp,
        blob: file,
        name: fileName,
        date,
      };
      return filesObj;
    }

    const filesObj = {
      url: "",
      date,
    };

    return filesObj;
  }

  /**
   * Refresh pins list (keeping current filters)
   */
  handleDataRefreshAfterExitingSpecManager = () => {
    const {
      searchResultSelected,
      filterSelected,
      filterByDateFrom,
      filterByDateTo,
    } = this.state;
    this.updatePinsList(null, searchResultSelected, filterSelected, filterByDateFrom, filterByDateTo);
  }

  /**
   * When Add Specs is clicked in Report View,
   * open Spec Manager with Edit Spec page loaded
   */
  toggleEditSpec = (identifier) => {
    const { showSpecManager, showSpecManagerEdit } = this.state;
    this.setState({
      showSpecManager: !showSpecManager,
      showSpecManagerEdit: !showSpecManagerEdit,
      specManagerEditIdentifier: identifier ?? "",
    });
  }

  render() {
    const {
      listViewDisplay,
      activeTabIndexInMapView,
      tabsInMapView,
      indexOfQuadrantHoverOver,
      pinMap,
      isPinningSwab,
      quadrantSelected,
      imageSrc,
      loadingSwabs,
      loadingMap,
      displayNoResultFound,
      displayTooltipLocationID,
      selectedLocationID,
      mapKey,
      filterSelected,
      isAddingNewSwab,
      searchResultSelected,
      fields,
      isPinningNewSwab,
      isClickingQuadrant,
      ifDeleteTitleClicked,
      titleToDelete,
      showReportView,
      dataArrvied,
      showCreateEnvModal,
      ifOnlyUploadSwabFile,
      showPreview,
      previewUrlList,
      showEmptyPage,
      index0,
      delimiter,
      linkPatternFields,
      sampleIDFields,
      openCreateEnvModalFromTab,
      showSpecManager,
      showSpecManagerEdit,
      specManagerEditIdentifier,
      reportViewKey,
    } = this.state;

    const displayFields = fields.filter(({ display }) => display !== "0");

    return (
      <>
        {showCreateEnvModal
        && (
        <CreateEnvModal
          toggleShowCreateEnvModal={this.toggleShowCreateEnvModal}
          addListOfPoints={this.addListOfPoints}
          handleUploadImage={this.handleUploadImage}
          ifOnlyUploadSwabFile={ifOnlyUploadSwabFile}
          updatePinsList={this.updatePinsList}
          openFromEmptyPage={showEmptyPage}
          openCreateEnvModalFromTab={openCreateEnvModalFromTab}
          clearFilterCalendarAndSelected={this.clearFilterCalendarAndSelected}
          linkPatternFields={linkPatternFields}
        />
        )}
        {dataArrvied && showEmptyPage && <EmptyPage toggleShowCreateEnvModal={this.toggleShowCreateEnvModal} />}
        {!showEmptyPage
        && (
          <div className="EnvMainContainerFlexLayout">
            <SpecManagerDrawer
              showSpecManager={showSpecManager}
              locationId={selectedLocationID}
              handleSpecManagerToggle={this.handleSpecManagerToggle}
              handleDataRefreshAfterExitingSpecManager={this.handleDataRefreshAfterExitingSpecManager}
              fields={fields}
              excludedField={index0}
              isEditingSpec={showSpecManagerEdit}
              fromModal={specManagerEditIdentifier && showSpecManagerEdit}
              toggleEditSpec={this.toggleEditSpec}
              identifier={specManagerEditIdentifier}
            />
              {listViewDisplay && (
              <div className="envAnalyticsListViewBlock">
                <AnalyticsListView
                  envCalendarRef={this.envCalendarRef}
                  key={activeTabIndexInMapView}
                  imageSrc={imageSrc}
                  transformWrapperRef={this.transformWrapperRef}
                  pinMap={pinMap}
                  selectedLocationID={selectedLocationID}
                  setSelectedLocationID={this.setSelectedLocationID}
                  setDisplayTooltipLocationID={this.setDisplayTooltipLocationID}
                  quadrantSelected={quadrantSelected}
                  indexOfQuadrantHoverOver={indexOfQuadrantHoverOver}
                  filterSelected={filterSelected}
                  isAddingNewSwab={isAddingNewSwab}
                  searchResultSelected={searchResultSelected}
                  loading={loadingSwabs}
                  displayNoResultFound={displayNoResultFound}
                  handleSearch={this.handleSearch}
                  updatePinInfo={this.updatePinInfo}
                  handleAddNewSwab={this.handleAddNewSwab}
                  endPinningSwab={this.endPinningSwab}
                  handleHoverQuadrant={this.handleHoverQuadrant}
                  handleSelectQuadrant={this.handleSelectQuadrant}
                  handleSelectFilter={this.handleSelectFilter}
                  handleFilterByDate={this.handleFilterByDate}
                  toggleAddingSwab={this.toggleAddingSwab}
                  handleClickSearchResult={this.handleClickSearchResult}
                  addListOfPoints={this.addListOfPoints}
                  displayFields={displayFields}
                  index0={index0}
                  delimiter={delimiter}
                  linkPatternFields={linkPatternFields}
                  sampleIDFields={sampleIDFields}
                  isPinningNewSwab={isPinningNewSwab}
                  togglePinningNewSwab={this.togglePinningNewSwab}
                  isPinningSwab={isPinningSwab}
                  searchRef={this.searchRef}
                  clickQuadrant={() => this.setState({ isClickingQuadrant: !isClickingQuadrant })}
                  isClickingQuadrant={isClickingQuadrant}
                  toggleReportView={this.toggleReportView}
                  showReportView={showReportView}
                  toggleShowCreateEnvModal={this.toggleShowCreateEnvModal}
                  getCalendarDates={this.getCalendarDates}
                  forceReportViewRerender={this.forceReportViewRerender}
                />
              </div>
              )}
            <div className="envAnalyticsMapViewBlock">
              {showReportView ? (
                <>
                  <div className="MapView__TabBar">
                    {/* <SpecManagerToggleButton handleSpecManagerToggle={this.handleSpecManagerToggle} /> */}
                  </div>
                  <ReportView
                    pinMap={pinMap}
                    selectedLocationID={selectedLocationID}
                    setSelectedLocationID={this.setSelectedLocationID}
                    setDisplayTooltipLocationID={this.setDisplayTooltipLocationID}
                    toggleReportView={this.toggleReportView}
                    handlePreviewFile={this.handlePreviewFile}
                    imageSrc={imageSrc}
                    handleAddSpecsButton={this.toggleEditSpec}
                    key={reportViewKey}
                    linkPatternFields={linkPatternFields}
                    index0={index0}
                  />
                </>
              ) : (
                <AnalyticsMapView
                  tabsInMapView={tabsInMapView}
                  activeTabIndex={activeTabIndexInMapView}
                  transformWrapperRef={this.transformWrapperRef}
                  listViewDisplay={listViewDisplay}
                  pinMap={pinMap}
                  index0={index0}
                  selectedLocationID={selectedLocationID}
                  setSelectedLocationID={this.setSelectedLocationID}
                  displayTooltipLocationID={displayTooltipLocationID}
                  setDisplayTooltipLocationID={this.setDisplayTooltipLocationID}
                  mapKey={mapKey}
                  indexOfQuadrantHoverOver={indexOfQuadrantHoverOver}
                  quadrantSelected={quadrantSelected}
                  isAddingNewSwab={isAddingNewSwab}
                  isPinningSwab={isPinningSwab}
                  imageSrc={imageSrc}
                  loading={loadingMap}
                  endPinningSwab={this.endPinningSwab}
                  handleTabSelect={this.handleTabSelectInMapView}
                  updatePinInfo={this.updatePinInfo}
                  toggleListViewDisplay={this.toggleListViewDisplay}
                  handleUploadImage={this.handleUploadImage}
                  handleSelectQuadrant={this.handleSelectQuadrant}
                  isPinningNewSwab={isPinningNewSwab}
                  togglePinningNewSwab={this.togglePinningNewSwab}
                  mapComponentRef={this.mapComponentRef}
                  clickQuadrant={() => this.setState({ isClickingQuadrant: !isClickingQuadrant })}
                  isClickingQuadrant={isClickingQuadrant}
                  displayNoResultFound={displayNoResultFound}
                  handleAddMapTitle={this.handleAddMapTitle}
                  handleEditMapTitle={this.handleEditMapTitle}
                  toggleDeleteTitleConfirm={this.toggleDeleteTitleConfirm}
                  toggleShowCreateEnvModal={this.toggleShowCreateEnvModal}
                  handleSpecManagerToggle={this.handleSpecManagerToggle}
                />
              )}
            </div>
              {ifDeleteTitleClicked
            && (
              <EnvConfirmModal
                width="424px"
                headerText={`Delete Map: ${titleToDelete}`}
                bodyText="The map title and map will be removed and any swabs pinned on the map will be unpinned."
                buttonText={["Cancel", "Delete"]}
                buttonFunctions={[() => this.toggleDeleteTitleConfirm(""),
                  () => { this.handleDeleteMapTitle(titleToDelete); this.toggleDeleteTitleConfirm(""); }]}
              />
            )}

              {showPreview
              && <ReportPreviewModal previewUrlList={previewUrlList} handleCloseForPreview={this.handleCloseForPreview} />}
          </div>
        )}
      </>
    );
  }
}

import React, { useEffect, useState } from "react";

import { isEmpty } from "lodash";
import Highlighter from "react-highlight-words";
import Scrollbar from "react-scrollbars-custom";

import StyledTooltip from "../../Common/UIComponents/StyledTooltip";
import ModalPageTitle from "./ModalPageTitle";

import { getRecurringEventReadableText, SAMPLE_SUBMISSION_MAXLENGTH } from "../utils";

import "./SummaryForm.css";

export default function SummaryForm({
  newSamplingEventData,
  setNewSamplingEventData,
  markedLocations,
  // linkPatternFields,
  sampleIDFields,
  hideSubmissionInput = false,
}) {
  const [readableEventText, setReadableEventText] = useState({
    wordsToMark: [],
    text: "",
  });

  const setName = (text) => {
    setNewSamplingEventData((state) => ({ ...state, event_name: text }));
  };

  useEffect(() => {
    if (!isEmpty(newSamplingEventData) && !isEmpty(newSamplingEventData.schedule)) {
      const { dates, frequency } = newSamplingEventData.schedule;
      const { wordsToMark, text } = getRecurringEventReadableText(dates, frequency);
      setReadableEventText({ wordsToMark, text });
    }
  }, [newSamplingEventData]);

  return (
    <div className="SummaryForm">
      <div className="SummaryForm__StatusPanel">
        {!hideSubmissionInput && (
          <>
            <span className="SummaryForm__Name">Name</span>
            <div className="SummaryForm__NameInput" id="summaryFormInputBar">
              <input
                className={`searchInput ${newSamplingEventData.event_name.length > SAMPLE_SUBMISSION_MAXLENGTH ? "invalid" : ""}`}
                placeholder="Enter Submission Name"
                onChange={(e) => setName(e.target.value)}
                value={newSamplingEventData.event_name}
                maxLength={SAMPLE_SUBMISSION_MAXLENGTH}
              />
              {newSamplingEventData.event_name !== "" && (
                <button
                  type="button"
                  className="SummaryForm__NameInputCancel"
                  onClick={() => setName("")}
                >
                  &times;
                </button>
              )}
            </div>
          </>
        )}
        <StyledTooltip title={readableEventText.text}>
          <p className="SummaryForm__Frequency truncated-text">
            <Highlighter
              highlightClassName="SummaryForm__Frequency--highlighted"
              searchWords={readableEventText.wordsToMark}
              autoEscape
              textToHighlight={readableEventText.text}
            />
          </p>
        </StyledTooltip>
      </div>
      <div className="SummaryForm__LocationData">
        <div className="SummaryForm__LocationStatus">
          <ModalPageTitle>Swab Locations</ModalPageTitle>
          <p>{`Added Locations (${markedLocations.size}) will be scheduled for testing`}</p>
        </div>
        <Scrollbar className="SummaryForm__TableScrollbar">
          <table className="SummaryForm__Table">
            <thead>
              <tr>
                {(sampleIDFields ?? []).map((field) => (
                  <th key={field.json_field}>{field.title_field}</th>
                ))}
                <th className="SummaryForm__TableTestsHeader">Tests</th>
              </tr>
            </thead>
            <tbody>
              {Array.from(markedLocations).map(([location_id, sample]) => (
                <tr key={location_id} className="SummaryForm__TableRow">
                  {(sampleIDFields ?? []).map((field) => (
                    <td key={field.json_field}>{sample.sample_id_fields[field.json_field]}</td>
                  ))}
                  <td className="SummaryForm__TableTestsCell">
                    {sample.test_list && sample.test_list.length > 0 ? sample.test_list.join(", ") : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Scrollbar>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";

import { AES } from "crypto-js";
import { toast } from "react-toastify";

import EmailTag from "./EmailTag";

import { getUserCredentials, hasFeature } from "../../../../../../utils/common";
import { AES_128_BIT_KEY } from "../../../../../../utils/Constant";

import { sendInviteEmails } from "../../../../../../actions/sampleSubmission";

import "./AddUser.css";
import FailedEmailModal from "./FailedEmailModal";
import StyledButton from "../../../../../Common/UIComponents/StyledButton";

export default function AddUser({ type, getEmails }) {
  const [loading, setLoading] = useState(false);
  const [emailList, setEmailList] = useState(new Map());
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [failedEmails, setFailedEmails] = useState([]);
  const [disableSendBtn, setDisableSendBtn] = useState(false);

  /**
 * Handles the email input field behavior, adding emails to the list when a comma is entered or on a specified action.
 * @param {Event} e - The event object triggered by the input field.
 * @param {string} action - Optional parameter to specify the action triggering the method.
 */
  const handleEmailInput = (e, action = null) => {
    e.stopPropagation();

    const currKeyPressed = e.key;
    const inputText = e.currentTarget.value;

    // If we see a comma, add input text to the email list and reset the input field
    if (currKeyPressed === "," || action === "leave") {
      const newEmailToAdd = action === "leave" ? inputText : inputText.substring(0, inputText.length - 1); // Removing the added comma
      const newEmailList = new Map(emailList);

      // Check if we have a duplicate
      if (emailList.has(newEmailToAdd)) {
        setErrorMessage("Duplicate Email entered.");
        e.currentTarget.value = newEmailToAdd;

        // Reset the state back to an empty string, so the message disappears
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      } else if (newEmailToAdd.length > 0 && newEmailToAdd.length <= 50) {
        const isValidEmail = newEmailToAdd.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
        );
        newEmailList.set(newEmailToAdd, !!isValidEmail);
        e.currentTarget.value = "";
        setEmailList(newEmailList);
      } else if (newEmailToAdd.length > 50) {
        newEmailList.set(newEmailToAdd, false);
        e.currentTarget.value = "";
        setEmailList(newEmailList);
      }
    }
  };

  /**
   * Removes the given email from the array
   * @param { * } email
   */
  const removeEmail = (email) => {
    const newEmailList = new Map(emailList);
    newEmailList.delete(email);
    setEmailList(newEmailList);
  };

  /**
   * Creates a shareable link for inviting users to submit samples based on the provided email and user credentials.
   * @param { * } email
   */
  const createLink = (email) => {
    const user = getUserCredentials();
    const companyName = encodeURIComponent(user.company_name);
    const companyDomain = encodeURIComponent(user.company_domain);
    const userPayload = encodeURIComponent(user.user_payload);
    const invitedEmail = encodeURIComponent(email);
    const tabType = encodeURIComponent(type);
    const mrl = hasFeature("special_pesticides");
    const queryStrings = `company_name=${companyName}&company_domain=${companyDomain}&invited_email=${invitedEmail}&type=${tabType}&user_payload=${userPayload}&mrl=${mrl}`;
    const encryptedQueryStrings = AES.encrypt(queryStrings, AES_128_BIT_KEY).toString();
    return { email, link: `${window.location.origin}/sample-submission/${type}?${encryptedQueryStrings}` };
  };

  /**
   * Sends share details, including invitation emails and messages, to multiple recipients for sample submission
   */
  const sendShareDetails = async () => {
    const emailValid = Array.from(emailList, ([, value]) => (value));
    const falseIndex = emailValid.indexOf(false);
    if (falseIndex !== -1) {
      toast.error("Please Remove All Invalid Emails.");
    } else {
      const emails = Array.from(emailList, ([key]) => (key));
      const emailLinks = emails.map((email) => createLink(email));
      const payload = {
        emails: emailLinks,
        message,
        sample_category: type,
      };
      setLoading(true);
      const response = await sendInviteEmails(payload, type);
      if (response.success) {
        getEmails();
        setEmailList(new Map());
        setMessage("");
        if (response.result.fail.length === 0) {
          toast.success("User Invited Successfully!");
        } else {
          setFailedEmails(response.result.fail);
        }
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }
  };

  const handleCloseFailedEmailModal = () => {
    setFailedEmails([]);
  };

  // Disable Send button if email length is zero.
  useEffect(() => {
    if (emailList.size <= 0) {
      setDisableSendBtn(false);
    } else {
      setDisableSendBtn(true);
    }
  }, [emailList]);

  // Variable to check if the send button should be disabled
  // const enableSendButton = (emailList.size > 0);

  // Converts our email map into an array to be iterated
  const emails = Array.from(emailList, ([key, value]) => ({ email: key, isValid: value }));

  return (
    <>
      <div className="InviteModal__AddUserContainer">
        <div className="InviteModal__Title">Add User</div>
        <div className="InviteModal__EmailInputContainer">
          {emails.map((item, index) => <EmailTag key={index} email={item.email} isValid={item.isValid} removeEmail={removeEmail} />)}
          <input className="InviteModal__Input" placeholder={!emails.length && "Separate Email addresses with commas"} onKeyUp={(e) => handleEmailInput(e)} onBlur={(e) => handleEmailInput(e, "leave")} />
          {errorMessage !== "" && (
            <div className="InviteModal__ErrorMessage">{errorMessage}</div>
          )}
        </div>
        <div className="InviteModal__MessageContainer">
          <textarea className="InviteModal__MessageInput" placeholder="Add a message" value={message} onChange={(e) => setMessage(e.target.value)} />
        </div>
        <div className="InviteModal__SendButtonContainer">
          <StyledButton
            type="primary"
            htmlType="submit"
            className="Ant__SubmitBtn InviteModal__SubmitBtn"
            onClick={sendShareDetails}
            loading={loading}
            disabled={!disableSendBtn}
          >
            Send Invite
          </StyledButton>
        </div>
      </div>
      {failedEmails.length > 0 && <FailedEmailModal handleCloseFailedEmailModal={handleCloseFailedEmailModal} failedEmails={failedEmails} /> }
    </>
  );
}

import React from "react";
import Modal from "antd/es/modal/Modal";
import "./ConfirmModal.css";
import { ReactComponent as LoadingDots } from "../../../../../assets/images/common/LoadingDots.svg";

export default class ConfirmExitModal extends React.Component {
  render() {
    const {
      headerText,
      bodyText,
      buttonText,
      buttonFunctions,
      disableConfirmButton,
      loadingConfirm,
    } = this.props;

    return (
      <Modal
        open
        maskClosable={false}
        closable={false}
        centered
        footer={null}
        width="448px"
        wrapClassName="ConfirmModal"
        className="ConfirmModal"
      >
        <div className="specManagerConfirmDel">
          <div className="specManagerConfirmDelTitle">{headerText}</div>
          <div className="specManagerConfirmDelTitleMessage">{bodyText}</div>
          <div className="specManagerConfirmDelBtn">
            <button type="button" onClick={buttonFunctions[0]} className="specManagerConfirmDelBtnCancel ConfirmButton__Btn loading" disabled={disableConfirmButton}>
              {buttonText[0]}
            </button>
            <button type="button" onClick={buttonFunctions[1]} className="specManagerConfirmDelBtnDelete ConfirmButton__Btn loading" disabled={disableConfirmButton}>
              {buttonText[1]}
              {loadingConfirm && <LoadingDots width={16} />}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

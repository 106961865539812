import React, { useRef, useState } from "react";
import OutsideClick from "../OutsideClick";
import StyledTooltip from "../../../Common/UIComponents/StyledTooltip";
import "./ReportsTableHeaderCell.css";
import editIcon from "../../../../assets/images/product/editPencilLightBlue.png";
import infoIcon from "../../../../assets/images/product/infoCircleBlue.png";

export default function ReportsTableHeaderCell({
  title,
  id,
  linkReportJsonFields,
  idx,
  align,
  isEditable,
  handleSaveTableTitle,
  editTableTitleIdx,
  setEditTableTitleIdx,
  innerRef,
  getDraggableStyle = () => ({}),
  draggableProps = {},
  dragHandleProps = {},
}) {
  const [isHovering, setIsHovering] = useState(false);
  const headerCellRef = useRef();
  const tappedTwice = useRef(false);
  const editingWidth = useRef();

  /**
   * Measure the width of the current cell, set editTableTitleIdx to current idx
   */
  const handleEdit = () => {
    const { width } = headerCellRef.current.getBoundingClientRect();
    editingWidth.current = width;
    setEditTableTitleIdx(idx);
  };

  /**
   * Handles double tap.
   * @returns {Boolean}
   */
  const handleDoubleTap = () => {
    if (!tappedTwice.current) {
      tappedTwice.current = true;
      setTimeout(() => { tappedTwice.current = false; }, 300);
      return false;
    }
    handleEdit();
    tappedTwice.current = false;
    return true;
  };

  /**
   * Cancel edit: clear editingWidth, set editTableTitleIdx back to -1
   */
  const handleCancelEdit = () => {
    editingWidth.current = null;
    setEditTableTitleIdx(-1);
  };

  /**
   * While editing, if user hits Enter, submit the edit. If user hits Tab or Escape, cancel the edit.
   * @param {KeyDownEvent} e
   * @param {String} flag
   */
  const handleKeyDown = (e, flag) => {
    switch (e.key) {
      case "Enter":
        handleSaveTableTitle(e.target.value, flag, idx);
        break;
      case "Tab":
        handleCancelEdit();
        break;
      case "Escape":
        handleCancelEdit();
        break;
      default:
        break;
    }
  };

  const isLinkedField = idx < linkReportJsonFields.length;
  const isEditing = editTableTitleIdx === idx;
  const textAlignToJustifyMap = { left: "flex-start", center: "center", right: "flex-end" };

  return (
    <th
      className={`ReportsTableHeaderCell${isLinkedField ? "--linked-field" : ""} ${isEditable ? "ReportsTableHeaderCell--editable" : ""} ${isEditing ? "ReportsTableHeaderCell--editing" : ""}`}
      ref={(elem) => {
        if (innerRef) {
          innerRef(elem);
        }
        headerCellRef.current = elem;
      }}
      {...draggableProps} // eslint-disable-line react/jsx-props-no-spreading
      {...dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
      style={{
        ...getDraggableStyle(),
        width: isEditing ? `${editingWidth.current}px` : draggableProps?.style?.width,
        textAlign: align,
      }}
      onDoubleClick={isEditable ? handleEdit : null}
      onTouchStart={isEditable ? handleDoubleTap : null}
      onMouseEnter={isEditable ? () => setIsHovering(true) : null}
      onMouseLeave={isEditable ? () => setIsHovering(false) : null}
    >
      {isEditing ? (
        <OutsideClick
          flag="editIndex"
          onCancelEdit={handleCancelEdit}
          className="ReportsTable__OutsideClick"
        >
          <input
            type="text"
            placeholder={title}
            defaultValue={title}
            onKeyDown={(e) => handleKeyDown(e, "editTitle")}
            autoFocus
          />
        </OutsideClick>
      ) : (
        <div
          className="ReportTableHeaderCell__TitleDiv"
          style={{ justifyContent: textAlignToJustifyMap[align] }}
        >
          <span title={title}>{title}</span>
          {id === "specs_flag" && (
          <StyledTooltip
            title="The status of the final result is determined by the specification you add"
            placement="top"
          >
            <img
              className="ReportsTableHeaderCell__InfoIcon"
              onClick={isEditable ? handleEdit : null}
              src={infoIcon}
              alt="infoIcon"
            />
          </StyledTooltip>
          )}
          {isEditable && !isEditing && isHovering && (
          <img
            src={editIcon}
            alt="edit icon"
            onClick={isEditable ? handleEdit : null}
          />
          )}
        </div>
      )}
    </th>
  );
}

import React, { useState } from "react";
import { toast } from "react-toastify";
import FieldInput from "./FieldInput";
import "./CustomerInfoSection.css";
import "../SignUpInvite.css";
import { getCompanyName } from "../../../../actions/signup";

export default function CustomerInfoSection({
  setCustomerFieldRef,
  showInfoInputSection,
  setShowInfoInputSection,
  isSendingInvite,
  errorsFromParent,
  setFieldError,
  getFieldError,
  resetInfoInputSectionErrors,
}) {
  const [companyName, setCompanyName] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [autofillApiInProgress, setAutofillApiInProgress] = useState(false);
  /**
   * Handle showing/hiding the info input section
   * @param {String} type "prod" or "env"
   */
  const handleInfoInputSectionButtonClick = (type) => {
    const currentVal = showInfoInputSection[type];
    setShowInfoInputSection({ ...showInfoInputSection, [type]: !currentVal });
    if (currentVal) { // hide section
      resetInfoInputSectionErrors(type);
    }
  };

  /** Get error value field */
  const getCustomerFieldError = (field) => getFieldError(field, "customer");

  /**
   * Fetch company name with customer id, autofill company name input
   * @param {String} customerIDVal user input for customer id
   */
  const handleAutofillCompanyName = async (customerIDVal) => {
    if (customerIDVal !== customerID || autofillApiInProgress) {
      if (customerIDVal.trim()) {
        const { success, company_name } = await getCompanyName(customerIDVal.trim());
        if (success) {
          setCompanyName(company_name);
          setFieldError("company_name", false, "customer");
        } else {
          toast.error("Customer ID not found");
          setCompanyName("");
          setFieldError("company_name", true, "customer");
        }
      } else {
        setCompanyName("");
        setFieldError("company_name", true, "customer");
      }

      setCustomerID(customerIDVal.trim());
    }
    setAutofillApiInProgress(false);
  };

  return (
    <div className="signup-invite-customer-info-container">
      <div className="signup-invite-title"> Customer Info </div>
      <div className="signup-invite-customer-info-main">
        <div className="signup-invite-customer-info-main-partial signup-invite-customer-info-main-partial-left">
          <FieldInput
            type=""
            label="Customer ID"
            field="customer_id"
            valueInParent={customerID}
            setRef={setCustomerFieldRef}
            errorsFromParent={errorsFromParent}
            setFieldError={setFieldError}
            getFieldError={getCustomerFieldError}
            isSendingInvite={isSendingInvite}
            handleOnBlur={handleAutofillCompanyName}
            handleOnChange={() => setAutofillApiInProgress(true)}
          />
          <FieldInput
            type=""
            label="Company Name"
            field="company_name"
            setRef={setCustomerFieldRef}
            errorsFromParent={errorsFromParent}
            setFieldError={setFieldError}
            getFieldError={getCustomerFieldError}
            isSendingInvite={isSendingInvite}
            handleOnBlur={(val) => setCompanyName(val)}
            // disabled
            valueInParent={companyName}
          />
        </div>

        <div className="signup-invite-customer-info-main-partial">
          <FieldInput
            type=""
            label="Customer Email"
            field="email_address"
            setRef={setCustomerFieldRef}
            errorsFromParent={errorsFromParent}
            setFieldError={setFieldError}
            getFieldError={getCustomerFieldError}
            isSendingInvite={isSendingInvite}
          />
          <div className="button-group-pills">
            <div className="signup-invite-customer-info-type-label">Type</div>
            <div className="customer-info-section-prod-env-type-choices">
              <label
                htmlFor="customer-info-section-product-label"
                className={`${showInfoInputSection.prod ? "customer-info-section-type-active" : ""} customer-info-section-prod-env-type 
                ${isSendingInvite ? "CustomerInfoSection__ProductLabel--disabled" : ""}`}
              >
                <input
                  id="customer-info-section-product-label"
                  type="checkbox"
                  name="product"
                  className="customer-info-section-prod-env-checkbox"
                  disabled={isSendingInvite}
                  checked={showInfoInputSection.prod}
                  onChange={() => handleInfoInputSectionButtonClick("prod")}
                />
                <span>Product</span>
              </label>
              <label
                htmlFor="customer-info-section-environment-label"
                className={`${showInfoInputSection.env ? "customer-info-section-type-active" : ""} customer-info-section-prod-env-type 
                ${isSendingInvite ? "CustomerInfoSection__EnvLabel--disabled" : ""}`}
              >
                <input
                  id="customer-info-section-environment-label"
                  type="checkbox"
                  name="environment"
                  className="customer-info-section-prod-env-checkbox"
                  disabled={isSendingInvite}
                  checked={showInfoInputSection.env}
                  onChange={() => handleInfoInputSectionButtonClick("env")}
                />
                <span>Environment</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import axios from "axios";
import { API_URL } from "../utils/Constant";
import { getViewAsPerStatusCode } from "../utils/helpers";

/**
 * Get environment fields
 * @returns {Object} { success, message?, fields?, index0? }
 */
export const getEnvTableFields = async () => {
  try {
    const response = await axios.get(`${API_URL}/envreportfields/`);
    const data = response.data;

    if (data && data.fields.length > 0) {
      return { success: true, ...data };
    }
    const message = "No data from API.";
    return { success: false, message };
  } catch (e) {
    getViewAsPerStatusCode(e);
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

/* NOT USED */
// // http://127.0.0.1:8000/api/envreports/?
// // user_payload=uBYMoUVAAMhznC0N0gGV&
// // company_domain=eatsafeverified.com&
// // input=dried
// export const getEnvFiles = async (reqUrl, payload = {}) => {
//   try {
//     let response;
//     if (!reqUrl) {
//       const user = await getUserCredentials();
//       response = await axios.get(`${API_URL}/envreports/`, {
//         params: {
//           user_payload: user.user_payload || "",
//           search: payload.search || "",
//           specs_flag: payload.specs_flag || "",
//           retest: payload.retest || "",
//           page: parseInt(payload.page) || 1,
//           date_from: payload.fromm || "",
//           date_to: payload.to || "",
//         },
//       });
//     } else {
//       response = await axios.get(`${API_URL}${reqUrl.substr(4)}`);
//     }
//     const data = response.data;
//     const {
//       prev_page_link: prevPageLink, next_page_link: nextPageLink, last_page_link: lastPageLink, total_page: totalPage,
//     } = data;
//     const res = {
//       files: [], prevPageLink, nextPageLink, lastPageLink, totalPage,
//     };
//     res.files = data.result;
//     // console.log(res.files);
//     return { success: true, ...res };
//   } catch (e) {
//     // getViewAsPerStatusCode(e);
//     const message = "Something went wrong.";
//     return { success: false, message };
//   }
// };

/* NOT USED */
// export const getEnvDates = async () => {
//   try {
//     const user = await getUserCredentials();
//     const response = await axios.get(`${API_URL}/envreportdates/`, {
//       params: {
//         user_payload: user.user_payload || "",
//       },
//     });
//     const data = await response.data;
//     if (data && data.result) {
//       return { success: true, dates: data.result };
//     }
//     return { success: false, message: "Failed to get env dates." };
//   } catch (e) {
//     // getViewAsPerStatusCode(e);
//     const message = "Something went wrong.";
//     return { success: false, message };
//   }
// };

/* NOT USED */
// export const getEnvSpec = async (payload) => {
//   try {
//     const user = await getUserCredentials();
//     const params = {
//       user_payload: user.user_payload || "",
//       received_date: payload.received_date,
//       sample_id: payload.sample_id,
//       location_id: payload.location_id,
//     };

//     const response = await axios.get(`${API_URL}/envreportspecs/`, { params });
//     const data = response.data;
//     const res = { spec: {} };

//     if (data && data.result && data.result.length > 0) {
//       res.spec = data.result[0];
//     }

//     return { success: true, ...res };
//   } catch (e) {
//     // getViewAsPerStatusCode(e);
//     const message = "Something went wrong.";
//     return { success: false, message };
//   }
// };

/**
 * Get all locations for spec manager if location_id is empty, also handles search.
 * Get specs for specified location_id if non-empty.
 * Update specs if isUpdating is true.
 * @param {Object} payload { filter, search, location_id, page }
 * @param {Boolean} isUpdating true if adding/editing specs
 * @returns {Object} { success, message?, files?, pattern?, pages? }
 */
export const getEnvSpecsData = async (payload, isUpdating = false) => {
  try {
    let params = {
      filter: payload.filter || "",
      search: payload.search || "",
      location_id: payload.location_id || "",
      page: payload.page,
    };
    let response;
    if (isUpdating) {
      params = { // filter, search not needed for patch
        location_id: payload.location_id || "",
        specs: payload.specs || "",
        page: payload.page,
      };
      response = await axios.patch(`${API_URL}/envspecmanager/`, params);
    } else {
      response = await axios.get(`${API_URL}/envspecmanager/`, { params });
    }

    if (params.location_id) {
      const res = {
        prevPageLink: "", nextPageLink: "", lastPageLink: "", totalPage: "",
      };
      if (response.data.message === "success" || response.data.message === "Success") {
        return {
          success: true, files: response.data.result, pattern: "", pages: res,
        };
      } if (response.data.message === "Specs and status updated, reports from last 30 days updated") {
        return { success: true };
      }
      const message = "Something went wrong.";
      return { success: false, message };
    }
    const {
      prev_page_link: prevPageLink, next_page_link: nextPageLink, last_page_link: lastPageLink, total_page: totalPage,
    } = response.data.result.info;
    const res = {
      prevPageLink, nextPageLink, lastPageLink, totalPage,
    };

    if (response.data.result.info.message === "Success") {
      return {
        success: true, files: params.location_id ? response.data.result : response.data.result.info.result, pattern: response.data.result.pattern, pages: res,
      };
    } if (response.data.message === "Specs and status updated, reports from last 30 days updated") {
      return { success: true };
    }

    const message = "Something went wrong.";
    return { success: false, message };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};
export const getEnvSpecsEditData = async (payload) => {
  try {
    const params = { location_id: payload.location_id };
    const response = await axios.get(`${API_URL}/envspecmanager/`, { params });

    if (response.data.message.toLowerCase() === "success") {
      return { success: true, result: response.data.result };
    }
    const message = "Something went wrong.";
    return { success: false, message };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};
export const postEnvSpecsEditData = async (payload) => {
  try {
    const params = {
      location_id: payload.location_id,
      specs: payload.specs,
    };
    const response = await axios.patch(`${API_URL}/envspecmanager/`, params);

    if (response.data.message === "Specs and status updated, reports from last 30 days updated") {
      return { success: true };
    }
    const message = "Something went wrong.";
    return { success: false, message };
  } catch (e) {
    const message = "Something went wrong.";
    return { success: false, message };
  }
};

// search files in Env reports page
// export const searchEnvReports = async (payload) => {
//   try {
//     const user = await getUserCredentials();
//     const params = {
//       company_domain: user.company_domain || "",
//       user_payload: user.user_payload || "",
//       search: payload.search,
//       page: payload.page || "",
//     };
//     const response = await axios.get(`${API_URL}/envreports/`, { params });
//     const data = await response.data;

//     return { success: true, ...data };
//   } catch (e) {
//     // getViewAsPerStatusCode(e);
//     const message = "Something went wrong.";
//     return { success: false, message };
//   }
// };

import { Tooltip } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as RepeatIcon } from "../../../assets/images/my-account/RepeatIcon.svg";
import "./ResendInviteButton.css";

export default function ResendInviteButton({
  handleResendInvite,
  userInfo,
  loading,
  disabled,
}) {
  return (
    <Tooltip
      title={loading || disabled ? "" : "Resend invite"}
      overlayClassName="TeamMember__Tooltip"
    >
      {loading
        ? <LoadingOutlined spin className="ResendInviteButton__LoadingIcon" />
        : (
          <RepeatIcon
            width="16"
            height="16"
            className={`ResendInviteButton__RepeatIcon${disabled ? "--disabled" : ""}`}
            onClick={disabled ? null : () => handleResendInvite(userInfo)}
          />
        )}
    </Tooltip>
  );
}

import React, { useCallback } from "react";

import { LoadingOutlined } from "@ant-design/icons";
// import { Checkbox } from "antd";

import "./CustomCheckbox.css";

export default function CustomCheckbox({
  location,
  markedLocations,
  locationCheckboxLoading,
  handleCheck,
  children,
}) {
  const checked = markedLocations.has(location.location_id);

  const handleCheckbox = useCallback((e) => handleCheck(e, location), [handleCheck, location]);

  if (locationCheckboxLoading === location.location_id) {
    return <LoadingOutlined spin className="SelectLocationForm__TableRowCheckboxLoading" />;
  }

  return (
    <div className="SelectLocationForm__LocationTableCheckbox__Container">
      <input
        type="checkbox"
        className="SelectLocationForm__LocationTableCheckbox"
        onChange={handleCheckbox}
        checked={checked}
        id={`SelectLocationForm__Checkbox--${location.location_id}`}
      />
      <label htmlFor={`SelectLocationForm__Checkbox--${location.location_id}`}>{children}</label>
    </div>
  );
}

/*
    <Checkbox
      className="SelectLocationForm__LocationTableCheckbox"
      onChange={handleCheckbox}
      checked={checked}
    />
*/

import React, {
  useEffect, useRef, useState,
} from "react";
import { ReferenceArea, ReferenceLine, Tooltip } from "recharts";
import { v4 as uuidv4 } from "uuid";

import { checkIfDataExists, groupScatterDataInRange } from "../../../Helper";
import GraphTooltip from "./GraphTooltip";
import ComposedGraph from "./ComposedGraph";
import GraphTooltipCursor from "./GraphTooltipCursor";

export default function Graph(props) {
  const {
    formattedData, selectedData, selectedGraph, showBarGraph, updateSelectedGraph,
  } = props;
  const { valuesArr, specArr, scatterObj } = formattedData;

  const [yReferenceBound, setYReferenceBound] = useState({ start: 0, end: "auto" });
  const [yGraphBound, setYGraphBound] = useState({ start: 0, end: "auto" });
  const [selectedSpecTestType, setSelectedSpecTestType] = useState("default");
  const [selectedGraphHasData, setSelectedGraphHasData] = useState(true);
  const [graphKey, setGraphKey] = useState(uuidv4());

  const [hoveredItem, setHoveredItem] = useState(null);
  const [groupedScatterPoints, setGroupedScatterPoints] = useState(null);

  // const [selectedGraphData, setSelectedGraphData] = useState(valuesArr);

  const [showGraphData, setShowGraphData] = useState(true);

  const toolTipRef = useRef();
  const graphRef = useRef();

  // Use effect to get the bounds for the reference background/lines when a selected graph changes
  useEffect(() => {
    // Update the values arr to only contain dates that the selected test/product has data for
    // This eliminates any x-axis marks with no data
    // function getUpdatedGraphDataArray() {
    //   const updatedArr = [];
    //   valuesArr.forEach((value) => {
    //     const results = value.result;

    //     results.forEach((result) => {
    //       if (result[selectedGraph.test] !== undefined && selectedGraph.product === result.name) {
    //         updatedArr.push(value);
    //       }
    //     });
    //   });

    //   return updatedArr;
    // }

    // Given a spec containing a "<" or ">" and a type of "min" or "max"
    // Removes all the chars that could hinder parsing, and returns
    function parseSpecValue(valueToParse, type) {
      let parsedSpec = "";
      if (type === "max") {
        if (valueToParse.includes("<")) parsedSpec = valueToParse.split("<")[1].replace(",", "").replace("%", "");
        else parsedSpec = valueToParse.replace(",", "").replace("%", "");

        return parsedSpec;
      }

      if (type === "min") {
        if (valueToParse.includes(">")) parsedSpec = valueToParse.split(">")[1].replace(",", "").replace("%", "");
        else parsedSpec = valueToParse.replace(",", "").replace("%", "");

        return parsedSpec;
      }

      return null;
    }

    // Gets the bounds for the y-axis of the graph
    // The bound is based off the higher value between the spec max and the result max
    // Depending on the type of spec -- reference bounds are set accordingly
    function getYBounds() {
      let specFound = false;
      for (let i = 0; i < specArr.length; i++) {
        const item = specArr[i];

        // Selected graph data found
        if (item.name === selectedGraph.product?.product_id && item.spec.test === selectedGraph.test) {
          specFound = true;

          // Decide if it is range or quantitative(max/min)
          let valueToParse = "";
          let type = "no spec";

          // Range found
          // set the start and end to min/max respectively -- reference bound
          if (item.spec.min !== "" && item.spec.max !== "") {
            type = "range";

            const min = item.spec.min.replace(",", "").replace("%", "").trim();
            const max = item.spec.max.replace(",", "").replace("%", "").trim();
            const yBoundMax = Math.max(max, item.max);

            return {
              testType: type, yReferenceBoundStart: min, yReferenceBoundEnd: max, yGraphBoundStart: 0, yGraphBoundEnd: yBoundMax, specFound,
            };
          }

          // Max found
          // set the end to the spec max value -- reference bound
          if (item.spec.min === "" && item.spec.max !== "") {
            type = "max";
            valueToParse = item.spec.max;

            if (valueToParse !== "None" && !valueToParse.includes("Negative") && !valueToParse.includes("Positive")) {
              const parsedSpec = parseSpecValue(valueToParse.split(" ")[0], type);
              const yBoundMax = Math.max(parsedSpec, item.max);

              return {
                testType: type, yReferenceBoundStart: 0, yReferenceBoundEnd: parseFloat(parsedSpec), yGraphBoundStart: 0, yGraphBoundEnd: yBoundMax, specFound,
              };
            }

            return {
              specFound, yGraphBoundStart: 0, yGraphBoundEnd: "auto",
            };
          }

          // Min found
          // set the start to the spec max value -- reference bound
          if (item.spec.min !== "" && item.spec.max === "") {
            type = "min";
            valueToParse = item.spec.min;

            if (valueToParse !== "None" && !valueToParse.includes("Negative") && !valueToParse.includes("Positive")) {
              const parsedSpec = parseSpecValue(valueToParse.split(" ")[0], type);
              const yBoundMax = Math.max(parsedSpec, item.max);

              return {
                testType: type, yReferenceBoundStart: parseFloat(parsedSpec), yReferenceBoundEnd: "auto", yGraphBoundStart: 0, yGraphBoundEnd: yBoundMax, specFound,
              };
            }

            return {
              specFound, yGraphBoundStart: 0, yGraphBoundEnd: "auto",
            };
          }

          // Error or no spec
          return {
            testType: type, yReferenceBoundStart: 0, yReferenceBoundEnd: "auto", yGraphBoundStart: 0, yGraphBoundEnd: "auto", specFound,
          };
        }
      }

      return {
        specFound, yGraphBoundStart: 0, yGraphBoundEnd: "auto",
      };
    }

    // When a selected graph changes, make sure the data exists
    // Otherwise need to show a message accordingly
    const hasData = checkIfDataExists(formattedData.scatterObj, selectedGraph);
    setSelectedGraphHasData(hasData);

    // Make sure a graph is selected
    if (selectedGraph !== "") {
      if (hasData) {
        // const updatedValuesArr = getUpdatedGraphDataArray();

        const yBounds = getYBounds();
        const {
          testType, yReferenceBoundStart, yReferenceBoundEnd, yGraphBoundStart, yGraphBoundEnd, specFound,
        } = yBounds;

        // If we don't find a spec -- set the test type and bounds to auto
        // If we did find a spec -- set the test type, reference bounds(x/y) and graph bounds(y)
        if (specFound === false) {
          setSelectedSpecTestType("no spec");
          setYReferenceBound({ start: 0, end: "auto" });
          setYGraphBound({ start: yGraphBoundStart, end: yGraphBoundEnd });

          // setSelectedGraphData(updatedValuesArr);
        } else {
          setSelectedSpecTestType(testType);
          setYReferenceBound({ start: yReferenceBoundStart, end: yReferenceBoundEnd });
          setYGraphBound({ start: yGraphBoundStart, end: yGraphBoundEnd });

          // setSelectedGraphData(updatedValuesArr);
        }
      }
    } else {
      setYGraphBound({ start: 0, end: "auto" });
    }
  }, [selectedGraph.product, selectedGraph.test]); // eslint-disable-line

  // Fired when the selected data changes, hides the graph until the format function runs
  useEffect(() => {
    setShowGraphData(false);
  }, [selectedData.selectedProducts, selectedData.selectedTests]);

  // When the formatted data's spec arr changes, aka a new product/test has been added and data has been formatted, re-render the graph
  // Also make sure we update our grouped scatter points
  useEffect(() => {
    setShowGraphData(true);
    setGraphKey(uuidv4());

    if (selectedGraph !== "") setGroupedScatterPoints(groupScatterDataInRange(scatterObj, yGraphBound, selectedGraph, graphRef));
  }, [formattedData.specArr, selectedGraph.product, selectedGraph.test, yGraphBound, graphRef]); // eslint-disable-line

  // Called from line/bar components
  // Returns the proper value to be plotted
  const getProperDataKey = (currObj, product, test) => {
    if (currObj.result !== undefined) {
      for (let i = 0; i < currObj.result.length; i++) {
        if (currObj.result[i].name === product?.product_id && currObj.result[i][test] !== undefined) {
          return currObj.result[i][test];
        }
      }
    }

    return currObj.y;
  };

  // Renders the background colors of green/red -- also renders green line marking the spec
  // Depending on the spec type (range, max, min, no spec) -- multiple reference areas or lines need to be drawn
  // If we have the same date for the xBounds, don't pass those values to the reference areas
  const renderReferenceArea = () => {
    const colors = {
      green: "#E9FEE5", greenAccent: "#00BF71", red: "#FFEDED", redAccent: "#E63559",
    };

    if (selectedGraph !== "") {
      // Color the background for a range
      if (selectedSpecTestType === "range") {
        return (
          <>
            <ReferenceArea y1={yReferenceBound.end} fill={colors.red} isFront={false} />
            <ReferenceLine stroke={colors.redAccent} y={yReferenceBound.end} />
            <ReferenceArea y1={yReferenceBound.start} y2={yReferenceBound.end} fill={colors.green} isFront={false} />
            <ReferenceLine stroke={colors.redAccent} y={yReferenceBound.start} />
            <ReferenceArea y1={0} y2={yReferenceBound.start} fill={colors.red} isFront={false} />
          </>
        );
      }

      // Color the background for a max
      if (selectedSpecTestType === "max") {
        if (Number.isNaN(yReferenceBound.start) || Number.isNaN(yReferenceBound.end)) return null;
        return (
          <>
            <ReferenceArea y1={yReferenceBound.end} fill={colors.red} isFront={false} />
            <ReferenceLine stroke={colors.redAccent} y={yReferenceBound.end} />
            <ReferenceArea y1={yReferenceBound.start} y2={yReferenceBound.end} fill={colors.green} isFront={false} />
          </>
        );
      }

      // Color the background for a min
      if (selectedSpecTestType === "min") {
        if (Number.isNaN(yReferenceBound.start) || Number.isNaN(yReferenceBound.end)) return null;
        return (
          <>
            <ReferenceArea y1={yReferenceBound.start} fill={colors.green} isFront={false} />
            <ReferenceLine stroke={colors.redAccent} y={yReferenceBound.start} />
            <ReferenceArea y1={0} y2={yReferenceBound.start} fill={colors.red} isFront={false} />
          </>
        );
      }
    }

    return null;
  };

  // Renders a custom tool tip when graph is selected and hovered
  const renderToolTip = () => (
    <Tooltip
      content={<GraphTooltip selectedGraph={selectedGraph} ref={toolTipRef} hoveredItem={hoveredItem} selectedSpecData={{ testType: selectedSpecTestType, bounds: yReferenceBound }} groupedScatterPoints={groupedScatterPoints} />}
      cursor={<GraphTooltipCursor xAxisTicks={valuesArr.length} showBarGraph={showBarGraph} />}
      wrapperStyle={{ outline: "none" }}
    />
  );

  // No data
  if (!selectedGraphHasData) {
    return (
      <div className="analyticsLineBarGraphContainer">
        <span className="analyticsGraphContainerPlaceholderText">No data found for the selected date range</span>
      </div>
    );
  }

  return (
    <ComposedGraph
      showGraphData={showGraphData}
      selectedData={selectedData}
      formattedData={formattedData}
      selectedGraph={selectedGraph}
        // selectedGraphData={selectedGraphData}
      graphKey={graphKey}
      yGraphBound={yGraphBound}
      updateSelectedGraph={updateSelectedGraph}
      getProperDataKey={getProperDataKey}
      renderReferenceArea={renderReferenceArea}
      renderToolTip={renderToolTip}
      showBarGraph={showBarGraph}
      updateHoveredItem={setHoveredItem}
      selectedTestType={selectedSpecTestType}
      specBounds={yReferenceBound}
      graphRef={graphRef}
    />
  );
}

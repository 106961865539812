import React from "react";
import Scrollbar from "react-scrollbars-custom";
import Highlighter from "react-highlight-words";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

import { has } from "lodash";
import { Skeleton } from "antd";
import SkeletonButton from "antd/es/skeleton/Button";
import StyledButton from "../../../../Common/UIComponents/StyledButton";
import NoResults from "../../../../Common/NoResults";
import Loading from "../../../../Common/Loading";

import pendingIcon from "../../../../../assets/images/product/plusMidnight.png";
import emptyStateIcon from "../../../../../assets/images/EmptyState_V2.png";

import "./SpecDisplayTable.css";

export default class SpecDisplayTable extends React.Component {
  render() {
    const {
      tableData,
      handleEditSpecClick,
      loadingNewContent,
      specManagerScrollbar,
      loading,
      searchValue,
      fields,
      linkReportJsonFields,
      searchField,
    } = this.props;

    const linkReportFields = fields.slice(0, linkReportJsonFields.length);

    const handleScrollToBottom = (e) => {
      const { handlePageChangeScroll } = this.props;
      const tolerance = 3;
      const bottom = Math.abs(
        Math.ceil(e.contentScrollHeight - e.scrollTop)
            - Math.ceil(e.clientHeight),
      ) <= tolerance;
      if (bottom) {
        handlePageChangeScroll();
      }
    };

    if (!loading && tableData.length === 0) {
      return (
        <Scrollbar
          id="noResultsScrollbar"
          className="productReportSpecManagerDisplayTableNoResultsScrollBar"
          disableTrackYWidthCompensation
          disableTracksWidthCompensation
          ref={specManagerScrollbar}
        >
          <NoResults message="No results found" image={emptyStateIcon} />
        </Scrollbar>
      );
    }

    return (
      <Scrollbar
        disableTrackYWidthCompensation
        disableTracksWidthCompensation
        id="specManagerDisplayTableScrollBar"
        className="productReportSpecManagerDisplayTableScrollBar"
        ref={specManagerScrollbar}
        onScrollStop={(e) => handleScrollToBottom(e)}
      >
        <table className="specTable" id="productReportSpecManagerSpecTable">
          <tbody>
            {loading && !loadingNewContent && (
            <tr key="skeleton">
              <td>
                <div className="specTableRow SpecTableRow__Skeleton">
                  <Skeleton active paragraph={false} title={{ width: 120 }} />
                  <SkeletonButton active width={120} />
                </div>
              </td>
            </tr>
            )}
            <AnimatePresence mode="popLayout" initial={false}>
              {!(loading && !loadingNewContent) && tableData.map((item, i) => (
                <motion.tr
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  // exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.15 }}
                  layout
                  key={i}
                >
                  <td>
                    <div
                      className="specTableRow"
                    >
                      <div className="specTableRowLeftCol">
                        <span className="LinkedFieldsSpan">
                          {
                            linkReportFields.map(({ json_field, title_field }, index) => (has(item.identifier, json_field) ? (
                              <React.Fragment key={json_field}>
                                <span className="LinkedFieldTitle">{`${title_field}:`}</span>
                                <Highlighter
                                  highlightClassName="searchHighlight"
                                  searchWords={json_field === searchField
                                    ? [searchValue]
                                    : []}
                                  autoEscape
                                  textToHighlight={item.identifier[json_field]}
                                  key={`${item.identifier[json_field]}_${index}`}
                                  className="LinkedFieldValue"
                                />
                                {index < Object.keys(item.identifier).length - 1 && (
                                <span className="LinkedFieldsDelimiterDot" />
                                )}
                              </React.Fragment>
                            ) : null))
                          }
                        </span>
                      </div>
                      <div className="specTableRowRightCol">
                        {item.status !== "added" && item.status !== "pending" && (
                        <div> </div>
                        )}
                        {item.status === "added" && (
                        <StyledButton
                          className={
                            loading
                              ? "specEditBtn specStateBtn buttonDisableSection"
                              : "specEditBtn specStateBtn"
                          }
                          onClick={(e) => handleEditSpecClick(e, i, "added")}
                          id="productReportSpecManagerSpecEditButton"
                        >
                          <img
                            className="specAdded"
                            alt="addedIcon"
                            id="productReportSpecManagerSpecAddedIcon"
                          />
                          Spec Added
                        </StyledButton>
                        )}
                        {item.status === "pending" && (
                        <StyledButton
                          className={
                            loading
                              ? "specAddBtn specStateBtn buttonDisableSection"
                              : "specAddBtn specStateBtn"
                          }
                          onClick={(e) => handleEditSpecClick(e, i, "pending")}
                          id="productReportSpecManagerSpecAddButton"
                        >
                          <div>
                            <img
                              src={pendingIcon}
                              alt="pendingIcon"
                              id="productReportSpecManagerPendingIcon"
                            />
                            Add Specs
                          </div>
                        </StyledButton>
                        )}
                      </div>
                    </div>
                  </td>
                </motion.tr>
              ))}
            </AnimatePresence>
          </tbody>
        </table>
        {loadingNewContent && <Loading />}
      </Scrollbar>
    );
  }
}

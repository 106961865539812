import React, {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useImperativeHandle,
  useState,
  useRef,
} from "react";

import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { isEmpty } from "lodash";

import SampleFunctionContext from "../../SampleFunctionContext";
import SampleSubmissionContext from "../../SampleSubmissionContext";
import AnalysisRequest from "./AnalysisRequest/AnalysisRequest";
import AddUpdateSampleButton from "./Buttons/AddUpdateSampleButton";
import SampleDetails from "./SampleDetails/SampleDetails";
import SampleID from "./SampleID";
import SubmissionCol from "./SubmissionCol/SubmissionCol";
// import ExitButton from "./Buttons/ExitButton";

import { hasFeature } from "../../../../utils/common";

import "./SampleDetailsPage.css";

const SampleDetailsPage = forwardRef((props, ref) => {
  const {
    inputValuesRefs,
    setInputValuesRefs,
    inputValuesSavedForNext,
    toggleSaveForNext,
    clearSampleDetailsForm,
    setClearSampleDetailsForm,
    loadDraftState,
    setLoadDraftState,
    loadSampleEditing,
    setLoadSampleEditing,
    sampleEditing,
    handleGoBackToAddressPage,
    setInvalidInputFound,
    savedState,
    loadSavedState,
    setLoadSavedState,
    setTestsSelected,
    saveTestsForNext,
    testsSelected,
    generateSampleID,
    sampleIDGenerationError,
    setInputValueAutofilled,
    handleGoBackToMainPage,
    generatedSampleID,
    clearTestSearch,
    setClearTestSearch,
    setSaveTestsForNext,
    customerDetails,
    waitingForAddEditAPI,
    sampleEditingIdx,
    handleSaveEditedSample,
    handleAddSample,
    handleCancelEditSample,
    invalidInputFound,
    sampleList,
    handleEditSample,
    handleDeleteClick,
    sampleListScrollbar,
    draftLastSavedTime,
    handleSubmitButtonClick,
    draftEditing,
    sampleFields,
    sampleFieldsInfo,
    delimiterRegex,
    linkedFields,
    imageInfoRef,
    setImageInfoRef,
    setLoadingImage,
    submissionEditingSample,
    submissionAddingSamples,
    sampleIdFields,
    updateSample,
    invalidTest,
    setInvalidTest,
    testInfoMap,
    sampleFromSubmission,
    submissionDetails,
    pesticideSample,
    setPesticideSample,
    sampleCategory,
    setShowExitModal,
    isLoadingSampleId,
    setIsLoadingSampleId,
    compositeSample,
    editingSubmittedSampleComposite,
    setEditingSubmittedSampleComposite,
    clearCompositeSaveForNext,
  } = props;
  const [sampleIDLoaded, setSampleIDLoaded] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [isLoadingTest, setIsLoadingTest] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const { isLoggedOut, thirdParty, mrl } = useContext(SampleSubmissionContext);
  const { backRef } = useContext(SampleFunctionContext);
  const disableAddSample = submissionEditingSample && sampleEditingIdx === -1; // disables inputs, tests, and hides (not just disables) add sample button
  const analysisRequestRef = useRef();
  /** Close ref for the sample submission details */
  useImperativeHandle(ref, () => ({
    closeFunction() {
      if (!isLoggedOut) {
        handleGoBackToMainPage();
      }
    },
  }));

  /**
   * Check if pesticide sample type field is empty for pesticide
   */
  const isPesticideSampleEmpty = useMemo(() => {
    if (isEmpty(testsSelected)) {
      return false;
    }
    const addedMRLTest = Object.values(testsSelected).find((test) => test.test_category === "MRL");
    return addedMRLTest && !pesticideSample.trim() && sampleCategory !== "environment" && (hasFeature("special_pesticides") || mrl);
  }, [testsSelected, pesticideSample, sampleCategory]);  // eslint-disable-line 

  useEffect(() => {
    if (sampleEditing && Object.keys(sampleEditing).length > 0) {
      if (sampleCategory !== "environment" && (hasFeature("special_pesticides") || mrl)) {
        setPesticideSample(sampleEditing.sample_type);
      }
    }
  }, [sampleEditing, setPesticideSample, sampleCategory]); // eslint-disable-line 

  useEffect(() => {
    if (isLoadingSampleId || isLoadingTest) {
      setAddLoading(true);
    } else {
      setAddLoading(false);
    }
  }, [isLoadingSampleId, isLoadingTest]);

  /**
   *Customized button for add or updatung the sample submission details
   */
  const addSampleButton = () => (
    <AddUpdateSampleButton
      waitingForAddEditAPI={waitingForAddEditAPI}
      sampleEditingIdx={sampleEditingIdx}
      handleSaveEditedSample={handleSaveEditedSample}
      handleAddSample={handleAddSample}
      handleCancelEditSample={handleCancelEditSample}
      invalidInputFound={invalidInputFound}
      invalidTest={invalidTest}
      sampleIDGenerationError={sampleIDGenerationError}
      disableAddSample={disableAddSample}
      generatedSampleID={generatedSampleID}
      submissionEditingSample={submissionEditingSample}
      isPesticideSampleEmpty={isPesticideSampleEmpty}
      isLoading={addLoading}
      setEditingSubmittedSampleComposite={setEditingSubmittedSampleComposite}
    />
  );

  return (
    <>
      <SampleDetails
        inputValuesRefs={inputValuesRefs}
        setInputValuesRefs={setInputValuesRefs}
        inputValuesSavedForNext={inputValuesSavedForNext}
        toggleSaveForNext={toggleSaveForNext}
        sampleFields={sampleFields}
        sampleFieldsInfo={sampleFieldsInfo}
        clearSampleDetailsForm={clearSampleDetailsForm}
        setClearSampleDetailsForm={setClearSampleDetailsForm}
        draftEditing={draftEditing}
        loadDraftState={loadDraftState}
        setLoadDraftState={setLoadDraftState}
        loadSampleEditing={loadSampleEditing}
        setLoadSampleEditing={setLoadSampleEditing}
        sampleEditing={sampleEditing}
        handleClickBackButton={handleGoBackToAddressPage}
        delimiterRegex={delimiterRegex}
        setInvalidInputFound={setInvalidInputFound}
        savedState={savedState}
        loadSavedState={loadSavedState}
        setLoadSavedState={setLoadSavedState}
        linkedFields={linkedFields}
        setTestsSelected={setTestsSelected}
        saveTestsForNext={saveTestsForNext}
        testsSelected={testsSelected}
        generateSampleID={generateSampleID}
        setInputValueAutofilled={setInputValueAutofilled}
        compositeSample={compositeSample}
        imageInfoRef={imageInfoRef}
        setImageInfoRef={setImageInfoRef}
        setLoadingImage={setLoadingImage}
        submissionEditingSample={submissionEditingSample}
        submissionAddingSamples={submissionAddingSamples}
        sampleIdFields={sampleIdFields}
        disableAddSample={disableAddSample}
        testInfoMap={testInfoMap}
        sampleFromSubmission={sampleFromSubmission}
        submissionDetails={submissionDetails}
        sampleCategory={sampleCategory}
        ref={backRef}
        setShowExitModal={setShowExitModal}
        setIsLoadingTest={setIsLoadingTest}
        setIsLoadingSampleId={setIsLoadingSampleId}
        clearTestSearch={clearTestSearch}
        editingSubmittedSampleComposite={editingSubmittedSampleComposite}
        setEditingSubmittedSampleComposite={setEditingSubmittedSampleComposite}
        analysisRequestRef={analysisRequestRef}
        clearCompositeSaveForNext={clearCompositeSaveForNext}
      />
      <div className="new-submission-pages-col samplesubmission-exit-button">
        {/* {!isLoggedOut && (
          <ExitButton
            handleGoBackToMainPage={handleGoBackToMainPage}
          />
        )} */}
      </div>
      <AnimatePresence>
        <motion.div
          key="SampleDetailPage-MotionDiv"
          className={`new-submission-pages-col ${!submissionEditingSample ? "analysis-request-column" : "analysis-request-column-edit"} ${isMaximized ? "analysis-request-maximized" : ""}`}
          layout
        >
          {(!isLoggedOut || thirdParty) && (
            <SampleID
              sampleID={generatedSampleID}
              disableAddSample={disableAddSample}
              sampleIDGenerationError={sampleIDGenerationError}
              submissionEditingSample={submissionEditingSample}
              onLoad={() => {
                setSampleIDLoaded(true);
              }}
            />
          )}
          {sampleIDLoaded && (
            <AnalysisRequest
              clearTestSearch={clearTestSearch}
              setClearTestSearch={setClearTestSearch}
              saveTestsForNext={saveTestsForNext}
              setSaveTestsForNext={setSaveTestsForNext}
              testsSelected={testsSelected}
              setTestsSelected={setTestsSelected}
              customerDetails={customerDetails}
              submissionEditingSample={submissionEditingSample}
              sampleID={generatedSampleID}
              disableAddSample={disableAddSample}
              setInvalidTest={setInvalidTest}
              testInfoMap={testInfoMap}
              pesticideSample={pesticideSample}
              setPesticideSample={setPesticideSample}
              inputValuesRefs={inputValuesRefs}
              setInputValuesRefs={setInputValuesRefs}
              setInvalidInputFound={setInvalidInputFound}
              sampleCategory={sampleCategory}
              AddUpdateSampleButton={addSampleButton}
              setShowExitModal={setShowExitModal}
              isMaximized={isMaximized}
              setIsMaximized={setIsMaximized}
              setEditingSubmittedSampleComposite={setEditingSubmittedSampleComposite}
              ref={analysisRequestRef}
            />
          )}
        </motion.div>

        {!submissionEditingSample
          && (
            <SubmissionCol
              sampleList={sampleList}
              sampleFields={sampleFields}
              sampleFieldsInfo={sampleFieldsInfo}
              handleEditSample={handleEditSample}
              handleDeleteSample={handleDeleteClick}
              sampleEditingIdx={sampleEditingIdx}
              sampleListScrollbar={sampleListScrollbar}
              draftLastSavedTime={draftLastSavedTime}
              handleSubmitButtonClick={handleSubmitButtonClick}
              setClearTestSearch={setClearTestSearch}
              submissionEditingSample={submissionEditingSample}
              updateSample={updateSample}
              isMaximized={isMaximized}
              compositeRef={backRef}
            />
          )}
      </AnimatePresence>
    </>
  );
});

export default SampleDetailsPage;

import React, { useState } from "react";
import TitleBlock from "../CommonComponents/TitleBlock";
import Search from "../CommonComponents/Search";
import ProductList from "./Components/ProductList";
import "../../styles/ProductContainerStyle.css";

export default function ProductContainer({
  updateSelectedOptions,
  disabledColumns,
  productList,
  updateProductList,
  selectedData,
  updateSearch,
  parentLoading,
  linkReportFields,
  fieldsMap,
  searchByField,
  setSearchByField,
  search,
}) {
  const { selectedProducts, selectedTests } = selectedData;
  const [loading, setLoading] = useState(false);

  let disabled = false;
  if (disabledColumns.includes("product")) disabled = true;
  return (
    <div className="analyticsColumnContainer">
      <TitleBlock title="Products" subtext="Select Products to view the graph" />
      <Search
        updateProductList={updateProductList}
        setLoading={setLoading}
        selectedTests={selectedTests}
        updateSearch={updateSearch}
        linkReportFields={linkReportFields}
        fieldsMap={fieldsMap}
        search={search}
        searchByField={searchByField}
        setSearchByField={setSearchByField}
      />

      {/* {selectedProducts && selectedProducts.length !== 0 && (
        <SelectedList
          title="Selected"
          updateSelectedOptions={updateSelectedOptions}
          selectedProducts={selectedProducts}
        />
      )} */}

      <ProductList
        // title="Suggestions"
        updateSelectedOptions={updateSelectedOptions}
        disabled={disabled}
        productList={productList}
        selectedProducts={selectedProducts}
        loading={loading || parentLoading}
        linkReportFields={linkReportFields}
        fieldsMap={fieldsMap}
        search={search}
        searchByField={searchByField}
      />
    </div>
  );
}

import React, { useState } from "react";
import Modal from "antd/es/modal/Modal";
import UploadImage from "./UploadImage";
import { ReactComponent as CloseIcon } from "../../../assets/images/sample-submission/circularCloseIcon.svg";
import "./UploadImageModal.css";

export default function UploadImageModal(props) {
  const {
    setShowUploadImageModal,
    showUploadImageModal,
    setDisplayImageSrc,
    displayImageSrc,
    imageInfoRef,
    setImageInfoRef,
    imageFileType,
    editMode,
    updateNewSampleImage,
  } = props;

  const [modalHeader, setModalHeader] = useState("Upload Image");

  return (
    <Modal
      title={modalHeader}
      open={showUploadImageModal}
      maskClosable={false}
      closeIcon={<CloseIcon />}
      onCancel={() => setShowUploadImageModal(false)}
      centered
      destroyOnClose
      footer={null}
      wrapClassName="SampleSubmission_UploadImageModal"
    >
      <UploadImage
        setShowUploadImageModal={setShowUploadImageModal}
        setDisplayImageSrc={setDisplayImageSrc}
        displayImageSrc={displayImageSrc}
        imageInfoRef={imageInfoRef}
        setImageInfoRef={setImageInfoRef}
        setModalHeader={setModalHeader}
        imageFileType={imageFileType}
        editMode={editMode}
        updateNewSampleImage={updateNewSampleImage}
      />
    </Modal>
  );
}

import React, { useState } from "react";
import { Dot } from "recharts";
import { colorFromSpecFlag } from "../../../Helper";

export default function ScatterPoint(props) {
  const {
    cx, cy, specsFlag, updateHoveredItem, payload,
  } = props;

  const [hovered, setHovered] = useState();

  const handleDotHover = (hoverState) => {
    setHovered(hoverState);

    if (hoverState) {
      updateHoveredItem({ data: payload, type: "scatter" });
    } else {
      updateHoveredItem(null);
    }
  };

  let opacity = 0.8;
  let strokeColor = "#D1DCE8";
  if (hovered) {
    strokeColor = specsFlag === null ? colorFromSpecFlag[0] : colorFromSpecFlag[specsFlag];
    opacity = 1;
  }

  return (
    <Dot cx={cx} cy={cy} stroke={strokeColor} fill="white" strokeWidth={3} r={4} onMouseEnter={() => handleDotHover(true)} onMouseLeave={() => handleDotHover(false)} opacity={opacity} />
  );
}

import React, { useEffect, useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Radio,
  Select,
  Spin,
  // ConfigProvider,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { toast } from "react-toastify";

import StyledButton from "../../../../Common/UIComponents/StyledButton";
import StyledRadioGroup from "../../../../Common/UIComponents/StyledRadioGroup";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import MyForm from "../../../MyForm";
import useHandleStepChange from "../../useHandleStepChange";
import EditableFields from "./Components/EditableFields";

import { getTabsList } from "../../../../../utils/common";

import { getEnvironmentFields } from "../../../../../actions/envAnalytics";
import { getProductFields } from "../../../../../actions/reports";

import { ReactComponent as Info } from "../../../../../assets/images/onboarding/Info.svg";

import "../LinkReportForm.css";

export default function FieldsForm({
  initialValues, onNextButton, onBackButton, onFinishFailed, stepChangeInProgress,
  handleFormStepChange, existingUser, addCompany, companyName, handleSubmit, submitting = false,
}) {
  const [formStep, setFormStep] = useState(1);
  const [productSampling, setProductSampling] = useState(null);
  const [environmentSampling, setEnvironmentSampling] = useState(null);
  const [productDropdownOptions, setProductDropdownOptions] = useState([]);
  const [envDropdownOptions, setEnvDropdownOptions] = useState([]);
  const [stepValues, setStepValues] = useState({ ...initialValues });
  const [nextButtonValid, setNextButtonValid] = useState(false);
  const [showEnvQuestion, setShowEnvQuestion] = useState(false);
  const [showProdQuestion, setShowProdQuestion] = useState(false);
  const [showProdQuesForm, setShowProdQuesForm] = useState(false);
  const [showEnvQuesForm, setShowEnvQuesForm] = useState(false);
  const [hasSelectedNoProd, setHasSelectedNoProd] = useState(false);
  const [hasSelectedNoEnv, setHasSelectedNoEnv] = useState(false);
  const [loading, setLoading] = useState(false);
  const FORM_NAME = "fields_form";
  const [form] = useForm();
  const tabs = getTabsList();

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
    layout: "vertical",
  };

  /**
 * Filters the array of objects based on the "json_field" key and returns a new array with only the "json_field" and "title_field" keys, excluding the provided fields.
 *
 * @param {Array<{json_field, title_field}>} fields - The input array of objects of all the fields.
 * @param {Array<string>} excludeFields - The values to exclude in the "json_field" key, fields which are non editable.
 * @returns {Array<{json_field, title_field}>} A new array with only the "json_field" and "title_field" keys, excluding the provided fields, only editable fields are returned here.
 */
  function filterJsonField(fields, excludeFields) {
    return fields.filter((item) => !excludeFields.includes(item.json_field)).map(({ json_field, title_field }) => ({ json_field, title_field }));
  }

  /** Convert display name to DB Column value. Replace special characters, spaces with an underscore, convert to lower case */
  const getJsonFieldName = (str) => {
    if (!str) {
      return "";
    }
    let jsonFieldName = str.trim().replace(/[^\w\s]/gi, " ").replace(/\s+/g, "_").toLowerCase();
    jsonFieldName = jsonFieldName.replace(/(?:__+)/g, "_"); // condense multiple underscores to one
    jsonFieldName = jsonFieldName.replace(/^_+|_+$/g, ""); // trim leading + trailing underscores
    return jsonFieldName;
  };

  /**
   * @param {Object} fieldObj
   * @param {Array} exceptionFields*
   * Creating option array for associated fields
   */
  const createFieldArray = (fieldObj, exceptionFields = []) => {
    if (!fieldObj) {
      return [];
    }

    const getFieldArrayItem = (key, value) => {
      if (Array.isArray(value) && key === "editable_fields") {
        return value.map((item) => {
          const jsonFieldName = getJsonFieldName(item.json_field);
          const titleField = item.title_field;
          return { value: jsonFieldName, label: titleField ? titleField.trim() : "" };
        });
      }
      return { value: getJsonFieldName(key), label: value ? value.trim() : "" };
    };

    const fieldArray = Object.entries(fieldObj)
      .filter(([key]) => !exceptionFields.includes(key))
      .map(([key, value]) => getFieldArrayItem(key, value));

    return fieldArray.flat().filter((item) => item.label !== "");
  };
    /**
   * Reset the form to its initial state or default values
   */
  const resetFieldsForProdForm = async () => {
    setLoading(false);
    form.resetFields();
    form.setFieldValue("product_link", []);
    form.setFieldsValue({
      sample_supply: "Yes",
      add_company: "No",
      sample_type: initialValues?.product_info?.sample_type,
      date: initialValues?.product_info?.date,
      product_link: [],
      editable_fields: [
        "Item",
        "Lot",
        "Description",
      ] ?? undefined,
    });
    setNextButtonValid(false);
    setShowProdQuesForm(true);
    setHasSelectedNoProd(true);
  };
    /**
     * Reset the form to its initial state or default values
     */
  const resetFieldsForEnvForm = async () => {
    setLoading(false);
    form.resetFields();
    form.setFieldValue("environment_link", []);
    form.setFieldsValue({
      env_sample_supply: "Yes",
      env_add_company: "No",
      sample_type: initialValues?.environment_info?.sample_type,
      section: initialValues?.environment_info?.section,
      swab_number: initialValues?.environment_info?.swab_number,
      zone: initialValues?.environment_info?.zone,
      date: initialValues?.environment_info?.date,
      environment_link: [],
      editable_fields: [] ?? undefined,
    });
    setShowEnvQuesForm(true);
    setNextButtonValid(false);
    setHasSelectedNoEnv(true);
  };
  /**
   * if user wants to get field details from previous company for product
   * Set fields and other form data with received results
   */
  const getFieldsForProdForm = async () => {
    try {
      setLoading(true);
      const result = await getProductFields(true);
      const formData = form.getFieldsValue();
      const { fieldsMap, link_report: linkReport } = result;
      if (result && result.success) {
        if (formData.add_company === "Yes") {
          if (!linkReport || linkReport.length === 0) {
            toast.error("Link report field(s) missing");
          }
          const link_report_fields = linkReport.map((json_field) => fieldsMap[json_field]).filter((field) => field !== "");
          if (link_report_fields.some((elem) => !elem)) {
            toast.error("Link report field(s) missing from fields");
          }
          const nonEditables = ["sample_type", "received_date"];
          const editableFields = filterJsonField(result.fields, nonEditables);
          form.setFieldsValue({ product_link: linkReport.filter((elem) => elem !== "") });
          form.setFieldValue("editable_fields", editableFields.map(({ title_field }) => title_field) ?? []);
          setStepValues({
            ...stepValues,
            product_info: {
              ...stepValues.product_info,
              product_link: linkReport.filter((elem) => elem !== "") ?? [],
            },
            environment_info: {
              ...stepValues.environment_info,
            },
          });
          setNextButtonValid(true);
          setShowProdQuesForm(true);
        }
        setLoading(false);
        return null;
      }
      if (hasSelectedNoProd) {
        form.setFieldValue("add_company", "No");
        resetFieldsForProdForm();
      } else {
        form.setFieldValue("add_company", "");
        setShowProdQuesForm(false);
      }
      toast.error("Could not fetch product fields!");
      setLoading(false);
      return null;
    } catch (error) {
      toast.error("Failed to fetch product fields");
      setLoading(false);
      return null;
    }
  };

  /**
   * if user wants to get field details from previous company for environments
   * Set fields and other form data with received results
   */
  const getFieldsForEnvForm = async () => {
    try {
      setLoading(true);
      const result = await getEnvironmentFields(true);
      const formData = form.getFieldsValue();
      if (result && result.success) {
        if (formData.env_add_company === "Yes") {
          const { fields, link_pattern_fields } = result;
          if (!link_pattern_fields || link_pattern_fields.length === 0) {
            toast.error("Link report field(s) missing");
          }
          const link_report_fields = link_pattern_fields.map((json_field) => fields.filter((field) => json_field === field.json_field));
          if (link_report_fields.some((elem) => !elem)) {
            toast.error("Link report field(s) missing from fields");
          }
          const nonEditables = ["sample_type", "received_date", "swab_number", "zone", "section", "env_add_company"];
          const dynamicFields = result?.fields.filter((item) => nonEditables.includes(item.json_field)) ?? [];
          const editableFields = filterJsonField(result.fields, nonEditables);
          dynamicFields.forEach((field) => {
            form.setFieldsValue({ [field.json_field]: field.title_field });
          });
          form.setFieldsValue({ editable_fields: editableFields.map(({ title_field }) => title_field) ?? [], environment_link: result.link_pattern_fields.filter((item) => item !== "") ?? [] });
          setNextButtonValid(true);
          setShowEnvQuesForm(true);
        }
        setLoading(false);
        return;
      }
      if (hasSelectedNoEnv) {
        form.setFieldValue("env_add_company", "No");
        resetFieldsForEnvForm();
      } else {
        form.setFieldValue("env_add_company", "");
        setShowEnvQuesForm(false);
      }
      toast.error("Could not fetch environment fields!");
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };
  /**
   * @param {Array} fieldList
   * On change of fieldList in editableFields component
   * update the fields for product details and environment details.
   */
  const handleEditableFields = (fieldList) => {
    if (fieldList) {
      const currentStep = formStep === 1 ? "product_info" : "environment_info";
      const newFields = fieldList.filter((field) => field !== undefined);

      // Check if any fields have been added or removed
      const fieldsToAdd = newFields.filter((newField) => !stepValues[currentStep].editable_fields.some((oldField) => oldField.json_field === newField));
      const fieldsToRemove = stepValues[currentStep].editable_fields.filter((oldField) => !newFields.includes(oldField.json_field));

      // Check if any fields have been changed
      const fieldsToChange = newFields.filter((newField, index) => {
        const oldField = stepValues[currentStep].editable_fields[index];
        return oldField && newField !== oldField.json_field;
      });

      const fieldCheck = fieldList.map((field) => ({ json_field: getJsonFieldName(field), title_field: field }));
      // Update editable_fields and productDropdownOptions
      setStepValues({
        ...stepValues,
        [currentStep]: {
          ...stepValues[currentStep],
          editable_fields: fieldCheck,
        },
      });

      if (fieldsToAdd.length > 0 || fieldsToRemove.length > 0 || fieldsToChange.length > 0) {
        const newFieldsValues = newFields.map((field) => ({ value: field, label: field }));
        const updatedFieldsValues = newFieldsValues.filter((field) => !fieldsToRemove.includes(field.value));

        const dropdownOptions = currentStep === "product_info" ? productDropdownOptions : envDropdownOptions;
        const newOptionsToAdd = updatedFieldsValues.filter((option) => !dropdownOptions.some((existingOption) => existingOption.label === option.value));
        if (currentStep === "product_info") {
          const filteredProductDropdownOptions = productDropdownOptions.filter((option) => {
            const fieldToRemove = fieldsToRemove.find((field) => field.json_field === option.value);
            return !fieldToRemove;
          });
          setProductDropdownOptions([...filteredProductDropdownOptions, ...newOptionsToAdd]);
          if (fieldsToRemove.length > 0) {
            const options = [...filteredProductDropdownOptions, ...newOptionsToAdd];
            const fieldsReports = form.getFieldValue("product_link");
            const allFieldsMatch = options.every((option) => fieldsReports.includes(option.value));
            if (!allFieldsMatch) {
              form.setFieldValue("product_link", []);
              setStepValues((prevState) => ({
                ...prevState,
                product_info: {
                  ...prevState.product_info,
                  product_link: [],
                },
              }));
            }
            // form.setFieldValue("product_link", []);
          }
        }
      }
    }
  };

  /** Format values before saving */
  const formatValues = () => {
    const values = Object.keys(stepValues).length ? stepValues : initialValues;
    if (values.product_info?.editable_fields && values.environment_info?.editable_fields) {
      const valuesCopy = { ...values };
      const editableFieldsProduct = valuesCopy.product_info?.editable_fields ?? [];
      valuesCopy.product_info.editable_fields = editableFieldsProduct.map((displayName) => ({
        json_field: getJsonFieldName(displayName?.json_field ?? ""),
        title_field: displayName?.title_field?.trim() ?? "",
      }));
      const editableFieldsEnv = valuesCopy.environment_info?.editable_fields ?? [];
      valuesCopy.environment_info.editable_fields = editableFieldsEnv.map((displayName) => ({
        json_field: getJsonFieldName(displayName?.json_field ?? ""),
        title_field: displayName?.title_field?.trim() ?? "",
      }));
      if (addCompany) {
        const formData = form.getFieldsValue();
        if (formStep === 1) {
          valuesCopy.product_info.add_company = formData.add_company ?? "";
          valuesCopy.product_info.product_link = formData.product_link ?? [];
        } else {
          valuesCopy.environment_info.env_add_company = formData.env_add_company ?? "";
          valuesCopy.environment_info.environment_link = formData.environment_link ?? [];
          valuesCopy.environment_info.environment_supplies_needed = formData.environment_supplies_needed ?? "";
        }
      }
      return valuesCopy;
    }
    if (addCompany) {
      const formData = form.getFieldsValues();
      if (formStep === 1) {
        values.product_info.add_company = formData.add_company ?? "";
        values.product_info.product_link = formData.product_link ?? [];
      } else {
        values.environment_info.env_add_company = formData.env_add_company ?? "";
        values.environment_info.environment_link = formData.environment_link ?? [];
        values.environment_info.environment_supplies_needed = formData.environment_supplies_needed ?? "";
      }
    }
    return values;
  };

  const handleNextButton = () => {
    if (addCompany) {
      handleSubmit(FORM_NAME, formatValues());
    } else {
      onNextButton(FORM_NAME, formatValues());
    }
  };

  const handleBackButton = () => {
    onBackButton(FORM_NAME, formatValues());
  };

  /**
   * @param {String} formName
   * Handling the initialValues for form
   */
  const handleInitialValues = (formName) => {
    if (formName && Object.keys(stepValues).length) {
      const editable_fields = stepValues[formName].editable_fields ? stepValues[formName].editable_fields.map(({ title_field }) => title_field) : undefined;
      return { ...stepValues[formName], editable_fields };
    }
    return {};
  };

  /**
   * @param {*} e
   * Handling the product form data for next button from
   * product details to environment details page
   */
  const handleFormStepNext = (e) => {
    e.preventDefault();
    const formData = form.getFieldsValue();
    const fieldsErrors = form.getFieldsError();
    const hasErrors = fieldsErrors.some((field) => field.errors.length > 0);
    if (hasErrors) {
      form.validateFields();
      toast.error("Invalid inputs!");
      return;
    }
    if (formStep === 1) {
      const fieldData = stepValues.product_info.editable_fields.filter((field) => field.json_field === "");
      if (addCompany && formData?.add_company === "") {
        form.validateFields();
        toast.error("Invalid inputs!");
        return;
      }
      if (formData?.product_link && formData?.sample_supply === "Yes") {
        if (!formData.product_link.length) {
          form.validateFields(["product_link"]);
          toast.error("At least one field required!");
          return;
        }
        if (fieldData?.length > 0) {
          form.validateFields();
          toast.error("Invalid inputs!");
          return;
        }
      }
      if (formData?.sample_supply === "Yes") {
        if (formData?.editable_fields?.length < 1) {
          form.validateFields();
          toast.error("At least one field required!");
          return;
        }
      }

      form.setFieldValue("editable_fields", stepValues.environment_info.editable_fields.map(({ title_field }) => title_field) ?? []);
      setStepValues({
        ...stepValues,
        product_info: {
          ...stepValues.product_info,
          sample_supply: formData.sample_supply,
          product_link: formData.product_link ?? [],
          add_company: formData.add_company ?? "",
          // editable_fields: formData.editable_fields ?? [],
        },
        environment_info: {
          ...stepValues.environment_info,
        },
      });
      setFormStep(2);
    } else {
      const fieldData = stepValues.environment_info.editable_fields.filter((field) => field?.json_field === "");
      if (addCompany && formData?.env_add_company === "") {
        form.validateFields();
        toast.error("Invalid inputs!");
        return;
      }
      if (fieldData.length > 0) {
        form.validateFields();
        toast.error("Invalid inputs!");
        return;
      }
      if (formData?.environment_link && formData?.env_sample_supply === "Yes") {
        if (!formData.environment_link.length) {
          form.validateFields(["environment_link"]);
          toast.error("At least one field required!");
          return;
        }
        if (fieldData?.length > 0) {
          form.validateFields();
          toast.error("Invalid inputs!");
          return;
        }
      }
      if (formData.environment_supplies_needed === "" && formData.env_sample_supply === "Yes") {
        form.validateFields(["environment_supplies_needed"]);
        toast.error("Invalid input");
        return;
      }
      if (!productSampling && !environmentSampling) {
        form.validateFields();
        toast.error("Please provide Product or Environment details");
        return;
      }
      stepValues.product_info.sample_supply = productSampling ? "Yes" : "No";
      stepValues.environment_info.env_sample_supply = environmentSampling ? "Yes" : "No";
      stepValues.environment_info.environment_link = formData.environment_link ?? [];
      stepValues.environment_info.environment_supplies_needed = formData.environment_supplies_needed ?? "No";
      handleNextButton();
    }
  };

  /**
   * @param {*} e
   * Handling the product form data for back button from
   * environment details to product details page
   */
  const handleFormStepBack = (e) => {
    e.preventDefault();
    const fieldsData = stepValues;
    const formData = form.getFieldsValue();
    if (formStep === 2) {
      form.setFieldValue("editable_fields", stepValues.product_info.editable_fields);
      form.setFieldValue("sample_supply", stepValues.product_info.sample_supply);
      setStepValues({
        ...stepValues,
        product_info: { ...stepValues.product_info },
        environment_info: {
          ...stepValues.environment_info,
          editable_fields: stepValues.environment_info.editable_fields ?? [],
          environment_link: formData.environment_link ?? [],
          environment_supplies_needed: formData.environment_supplies_needed ?? "",
          env_sample_supply: formData.env_sample_supply,
          env_add_company: formData.env_add_company ?? "",
        },
      });
      setFormStep(1);
    } else {
      fieldsData.product_info.sample_supply = productSampling ? "Yes" : "No";
      fieldsData.environment_info.env_sample_supply = environmentSampling ? "Yes" : "No";
      fieldsData.product_info.product_link = formData.product_link;
      fieldsData.product_info.add_company = formData.add_company ?? "";
      handleBackButton();
    }
  };

  /**
   * Handling the product form data onload
   */
  useEffect(() => {
    if (initialValues.product_info.sample_supply) {
      setProductSampling(initialValues.product_info.sample_supply === "Yes");
      if (addCompany) {
        setShowProdQuestion(initialValues.product_info.sample_supply === "Yes");
        setShowProdQuesForm(initialValues.product_info.add_company !== "");
      }
    }
    if (initialValues.environment_info.env_sample_supply) {
      setEnvironmentSampling(initialValues.environment_info.env_sample_supply === "Yes");
      if (addCompany) {
        setShowEnvQuestion(initialValues.environment_info.env_sample_supply === "Yes");
        setShowEnvQuesForm(initialValues.environment_info.env_add_company !== "");
      }
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  /**
   * @dependency [stepValues, formStep]
   * Handling the form data on page change
   */
  useEffect(() => {
    const formData = form.getFieldsValue();
    if (formStep === 1) {
      if (productSampling && stepValues?.product_info.product_link?.length === 0) {
        setNextButtonValid(false);
      } else if (formData.sample_supply === "" || formData.sample_supply === null) {
        setNextButtonValid(false);
      } else {
        setNextButtonValid(true);
      }
      if (productSampling && formData?.product_info?.product_link?.length !== 0) {
        setNextButtonValid(true);
      }
      if (Object.keys(stepValues).length) {
        const options = createFieldArray(
          stepValues.product_info,
          [
            "sample_type",
            "date",
            "product_link",
            "sample_supply",
            "add_company",
          ],
        );
        setProductDropdownOptions(options);
        form.setFieldsValue({
          editable_fields: stepValues.product_info.editable_fields
            ? stepValues.product_info.editable_fields.map((data) => (data.title_field)) : [],
        });
        if (stepValues.product_info.sample_supply) {
          form.setFieldsValue({
            sample_supply: stepValues.product_info.sample_supply,
            product_link: stepValues.product_info.product_link ?? [],
          });
        }
      }
    } else if (formStep === 2) {
      if ((environmentSampling && formData?.environment_link?.length === 0) || (environmentSampling && formData?.environment_link === undefined)) {
        setNextButtonValid(false);
      } else if (formData.env_sample_supply === "" || formData.env_sample_supply === null) {
        setNextButtonValid(false);
      } else {
        setNextButtonValid(true);
      }
      if (addCompany && formData?.environment_info?.environment_link?.length !== 0) {
        setNextButtonValid(true);
      }
      if (environmentSampling && formData?.environment_info?.environment_link?.length !== 0) {
        setNextButtonValid(true);
      }
      if (Object.keys(stepValues).length) {
        form.setFieldsValue({
          editable_fields: stepValues.environment_info.editable_fields.map((data) => (data.title_field)) ?? [],
        });
        /**
       * Adding Non-editable and pre-defined fields
       */
        if (envDropdownOptions.length === 0) {
          const options = createFieldArray(
            initialValues.environment_info,
            [
              "sample_type",
              "date",
              "environment_link",
              "env_sample_supply",
              "environment_supplies_needed",
              "editable_fields",
              "env_add_company",
            ],
          );
          setEnvDropdownOptions(options);
        }
      }
    }
  }, [stepValues, formStep, form]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => () => {
    // const fieldsData = stepValues;
    const formData = form.getFieldsValue();
    if (formStep === 2) {
      setStepValues({
        ...stepValues,
        product_info: { ...stepValues.product_info },
        environment_info: {
          ...stepValues.environment_info,
          editable_fields: stepValues.environment_info.editable_fields ?? [],
          environment_link: formData.environment_link ?? [],
          environment_supplies_needed: formData.environment_supplies_needed ?? "",
          env_sample_supply: formData.env_sample_supply,
          env_add_company: formData.env_add_company ?? "",
        },
      });
    } else {
      setStepValues({
        ...stepValues,
        product_info: {
          ...stepValues.product_info,
          sample_supply: formData.sample_supply,
          product_link: formData.product_link,
          add_company: formData.add_company,
          editable_fields: stepValues.product_info.editable_fields ?? [],
        },
        environment_info: { ...stepValues.environment_info },
      });
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  /** Hook that handles form saving + step change when step is clicked */
  useHandleStepChange(FORM_NAME, form, stepChangeInProgress, handleFormStepChange, formatValues);

  return (
    <div className="FormsContainer">
      {formStep === 1 && (
      <MyForm
        {...layout}// eslint-disable-line react/jsx-props-no-spreading
        form={form}
        requiredMark={false}
        name={FORM_NAME}
        validateTrigger={["onChange", "onBlur"]}
        initialValues={handleInitialValues("product_info")}
        onFinish={handleNextButton}
        onFinishFailed={onFinishFailed}
        className="OnboardingForm"
      >
        {/* Product Details Form */}
        <div className="productDetail">
          <div className="OnboardingFormTitle">Product Details</div>
          <Form.Item
            name="sample_supply"
            label={<span className="FieldsForm_Custom_TextLabel MyForm__SectionLabel">Are you planning to send us product samples for testing?</span>}
            rules={[
              {
                required: true, message: "",
              },
            ]}
          >
            <StyledRadioGroup
              onChange={(e) => {
                form.setFieldValue("sample_supply", e.target.value);
                setProductSampling(e.target.value === "Yes");
                setStepValues({ product_info: { ...stepValues.product_info, sample_supply: e.target.value }, environment_info: { ...stepValues.environment_info } });
                if (e.target.value === "Yes") {
                  setShowProdQuestion(true);
                } else {
                  setShowProdQuestion(false);
                  setShowProdQuesForm(false);
                  setHasSelectedNoProd(false);
                  setLoading(false);
                  form.setFieldValue("add_company", "");
                }
              }}
            >
              <Radio value="Yes">
                Yes
              </Radio>
              <Radio value="No">
                No
              </Radio>
            </StyledRadioGroup>
          </Form.Item>

          { addCompany && showProdQuestion
          && (
          <Form.Item
            name="add_company"
            label={<span className="FieldsForm_Custom_TextLabel MyForm__SectionLabel">{`Do you want to use the same product details as ${companyName} (Makes things faster!)?`}</span>}
            rules={[
              {
                required: true, message: "Please provide details for the product",
              },
            ]}
          >
            <StyledRadioGroup
              onChange={(e) => {
                form.setFieldValue("add_company", e.target.value);
                if (e.target.value === "Yes") {
                  getFieldsForProdForm();
                } else {
                  resetFieldsForProdForm();
                }
              }}
            >
              {
                loading ? (
                  <>
                    <div className="FieldForm__Loading">
                      <Spin indicator={<LoadingOutlined className="FieldForm__Checkbox__Spin" spin />} />
                      <span className="FieldForm_Radio_label">Yes</span>
                      <Radio value="No" disabled={loading}>
                        No
                      </Radio>
                    </div>
                  </>
                )
                  : (
                    <>
                      <StyledTooltip title={!tabs.includes("product") ? `Do not have product details for ${companyName}` : undefined}>
                        <Radio value="Yes" disabled={!tabs.includes("product")}>
                          Yes
                        </Radio>
                      </StyledTooltip>
                      <Radio value="No">
                        No
                      </Radio>
                    </>
                  )
              }
            </StyledRadioGroup>
          </Form.Item>
          )}

          {(addCompany ? showProdQuesForm : productSampling)
                  && (
                    <div>
                      <Form.Item label={<span className="MyForm__SectionLabel FieldsForm__LabelText">Please enter the details you would like to include while submitting product samples</span>}>
                        <Form.Item label={(
                          <span className="MyForm__SectionLabel FieldsForm__LabelText_Fields">
                            Product Sample Submission Form Fields
                            <StyledTooltip
                              title="Info to track the samples you send to the lab."
                              className="MyForm__Section_Tooltip"
                            >
                              <Info />
                            </StyledTooltip>
                          </span>
                          )}
                        >
                          <Form.Item
                            name="sample_type"
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                          >
                            <Input disabled />
                          </Form.Item>
                          <Form.Item
                            name="date"
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                          >
                            <Input disabled />
                          </Form.Item>
                          <EditableFields getJsonFieldName={getJsonFieldName} onGetData={handleEditableFields} removableFieldIndex={2} />
                          <div className="FormsContainer_Divider" />
                          <Form.Item
                            name="product_link"
                            label={<span className="MyForm__SectionLabel FieldsForm__LabelText">Please select the field(s) associated with the specification for your products:</span>}
                            rules={[
                              {
                                required: true, message: "",
                              },
                            ]}
                            className="LinkReportForm__ProductSpec"
                          >
                            <Select
                              mode="multiple"
                              // disabled={prodDetailsCopy}
                              allowClear
                              options={productDropdownOptions}
                              placeholder="Select field(s)"
                              onChange={() => {
                                const fieldValue = form.getFieldValue("product_link");
                                const editableFieldsValue = form.getFieldValue("editable_fields");
                                if (fieldValue.length === 0 || editableFieldsValue.includes("")) {
                                  setNextButtonValid(false);
                                } else {
                                  setNextButtonValid(true);
                                }
                              }}
                            />
                          </Form.Item>
                        </Form.Item>
                      </Form.Item>
                    </div>
                  )}
        </div>
      </MyForm>// eslint-disable-next-line func-call-spacing
      )}
      {formStep === 2
        && (
        <MyForm
          {...layout}// eslint-disable-line react/jsx-props-no-spreading
          form={form}
          requiredMark={false}
          name={FORM_NAME}
          validateTrigger={["onChange", "onBlur"]}
          initialValues={handleInitialValues("environment_info")}
          onFinish={handleNextButton}
          onFinishFailed={onFinishFailed}
          className="OnboardingForm"
        >
          {/* Environment Details Form */}
          <div className="productDetail">
            <div className="OnboardingFormTitle">Environment Details</div>
            <Form.Item>
              <Form.Item
                name="env_sample_supply"
                label={<span className="FieldsForm_Custom_TextLabel MyForm__SectionLabel">Are you planning to send us environment samples for testing?</span>}
                rules={[
                  {
                    required: true, message: "",
                  },
                ]}
                className="custom-radio"
              >
                <StyledRadioGroup
                  // defaultValue="No"
                  onChange={(e) => {
                    form.setFieldValue("env_sample_supply", e.target.value);
                    setEnvironmentSampling(e.target.value === "Yes");
                    setStepValues({ product_info: { ...stepValues.product_info }, environment_info: { ...stepValues.environment_info, env_sample_supply: e.target.value } });
                    if (e.target.value === "Yes") {
                      setShowEnvQuestion(true);
                    } else {
                      form.setFieldValue("env_add_company", "");
                      setHasSelectedNoEnv(false);
                      setShowEnvQuestion(false);
                      setLoading(false);
                      setShowEnvQuesForm(false);
                    }
                  }}
                >
                  <Radio value="Yes">
                    Yes
                  </Radio>
                  <Radio value="No">
                    No
                  </Radio>
                </StyledRadioGroup>
                {/* </ConfigProvider> */}
              </Form.Item>
              { addCompany && showEnvQuestion
                && (
                <Form.Item
                  name="env_add_company"
                  label={<span className="FieldsForm_Custom_TextLabel MyForm__SectionLabel">{`Do you want to use the same environment details as ${companyName} (Makes things faster!)?`}</span>}
                  rules={[
                    {
                      required: true, message: "Please provide the details for the environment",
                    },
                  ]}
                  className="custom-radio"
                >
                  <StyledRadioGroup
                  // defaultValue="No"
                    onChange={(e) => {
                      form.setFieldValue("env_add_company", e.target.value);
                      if (e.target.value === "Yes") {
                        getFieldsForEnvForm();
                      } else {
                        resetFieldsForEnvForm();
                      }
                    }}
                  >
                    {
                      loading ? (
                        <>
                          <div className="FieldForm__Loading">
                            <Spin indicator={<LoadingOutlined className="FieldForm__Checkbox__Spin" spin />} />
                            <span className="FieldForm_Radio_label">Yes</span>
                            <Radio value="No" disabled={loading}>
                              No
                            </Radio>
                          </div>
                        </>
                      )
                        : (
                          <>
                            <StyledTooltip title={!tabs.includes("environment") ? `Do not have environment details for ${companyName}` : undefined}>
                              <Radio value="Yes" disabled={!tabs.includes("environment")}>
                                Yes
                              </Radio>
                            </StyledTooltip>
                            <Radio value="No">
                              No
                            </Radio>
                          </>
                        )
              }
                  </StyledRadioGroup>
                  {/* </ConfigProvider> */}
                </Form.Item>
                )}
              {(addCompany ? showEnvQuesForm : environmentSampling)
                  && (
                    <div>
                      <div className="" />
                      <Form.Item label={<span className="MyForm__SectionLabel FieldsForm__LabelText">Please enter the details you would like to include while submitting Environment samples</span>}>
                        <Form.Item label={(
                          <span className="MyForm__SectionLabel FieldsForm__LabelText_Fields">
                            Environment Sample Submission Form Fields
                            <StyledTooltip
                              title="Info to track the samples you send to the lab."
                              className="MyForm__Section_Tooltip"
                            >
                              <Info />
                            </StyledTooltip>
                          </span>
                          )}
                        >
                          <Form.Item
                            name="sample_type"
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                          >
                            <Input disabled />
                          </Form.Item>
                          <Form.Item
                            name="date"
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                          >
                            <Input disabled />
                          </Form.Item>
                          <Form.Item
                            name="swab_number"
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                          >
                            <Input disabled />
                          </Form.Item>
                          <Form.Item
                            name="zone"
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                          >
                            <Input disabled />
                          </Form.Item>
                          <Form.Item
                            name="section"
                            rules={[
                              {
                                required: true,
                                message: "",
                              },
                            ]}
                          >
                            <Input disabled />
                          </Form.Item>
                          <EditableFields getJsonFieldName={getJsonFieldName} onGetData={handleEditableFields} fieldLimit={3} />
                          <div className="FormsContainer_Divider" />
                          <Form.Item
                            name="environment_link"
                            rules={[
                              {
                                required: true, message: "",
                              },
                            ]}
                            label={<span className="MyForm__SectionLabel FieldsForm__LabelText">Please select the field(s) associated with the specification for your environment:</span>}
                          >
                            <Select
                              mode="multiple"
                              // disabled={envDetailsCopy}
                              loading={loading}
                              allowClear
                              options={envDropdownOptions}
                              placeholder="Select field(s)"
                              onChange={() => {
                                const fieldValue = form.getFieldValue("environment_link");
                                const editablefielsdValue = form.getFieldValue("editable_fields").filter((field) => field?.json_field === "");
                                if (fieldValue.length === 0 || editablefielsdValue.length > 0) {
                                  setNextButtonValid(false);
                                } else {
                                  setNextButtonValid(true);
                                }
                              }}
                            />
                          </Form.Item>
                          {!existingUser && <div className="FormsContainer_Divider" />}
                          {!existingUser && (
                          <Form.Item
                            name="environment_supplies_needed"
                            label={<span className="MyForm__SectionLabel FieldsForm__LabelText">Do you need supplies for environment testing?</span>}
                            rules={[
                              {
                                required: true, message: "Please provide an answer",
                              },
                            ]}
                          >
                            <StyledRadioGroup disabled={loading}>
                              <Radio value="Yes"> Yes </Radio>
                              <Radio value="No"> No </Radio>
                            </StyledRadioGroup>
                          </Form.Item>
                          )}
                        </Form.Item>
                      </Form.Item>
                    </div>
                  )}
            </Form.Item>
          </div>
        </MyForm>
        )}
      <div className="FormButtons">
        <StyledButton
          type="default"
          onClick={handleFormStepBack}
        >
          Previous
        </StyledButton>
        <StyledButton
          type="primary"
          htmlType={formStep === 1 ? "button" : "submit"}
          className="Ant__SubmitBtn"
          onClick={handleFormStepNext}
          disabled={!nextButtonValid}
          loading={submitting || false}
        >
          {(addCompany && formStep === 2) ? "Create Company" : `Next ${formStep}/2`}
        </StyledButton>
      </div>
    </div>

  );
}

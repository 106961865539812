import React from "react";
import { Draggable } from "react-beautiful-dnd";
import ReportsTableHeaderCell from "./ReportsTableHeaderCell";

export default function ReportsTableDraggableHeaderCell({
  title,
  id,
  linkReportJsonFields,
  idx,
  align,
  isDraggable,
  isEditable,
  reorderLoading,
  handleSaveTableTitle,
  editTableTitleIdx,
  setEditTableTitleIdx,
  droppableSnapshot,
  currentDraggableInfo,
}) {
  /**
   * Get the style for the current draggable.
   * @param {Object} draggableSnapshot { isDragging: Boolean }
   * @param {Object} draggableStyle
   * @returns {Object}
   */
  const getDraggableStyle = (draggableSnapshot, draggableStyle) => {
    const { isDraggingOver } = droppableSnapshot;
    const { isDragging } = draggableSnapshot;
    let transform = draggableStyle.transform;
    /**
     * Fix transform value (without this fix, there is extra space left behind when cell is dragged).
     * If user is dragging and the current cell is to the right of the dragged cell,
     * subtract the dragged cell's width from the translate x value.
     * */
    if (isDraggingOver && !isDragging && currentDraggableInfo.current) {
      const { originIdx, width } = currentDraggableInfo.current;
      if (originIdx < idx) {
        const getTranslateXRegex = /translate\((-?\d*\.?\d*).*/;
        const translateX = parseFloat(getTranslateXRegex.exec(draggableStyle.transform ?? "translate(0px)")[1]);
        transform = `translateX(${translateX - width}px)`;
      }
    }
    return ({
      ...draggableStyle,
      transform,
      opacity: isDragging ? "0.7" : draggableStyle.opacity,
      height: isDraggingOver ? currentDraggableInfo.current?.height : undefined,
      userSelect: "none",
    });
  };

  if (isDraggable) {
    return (
      <Draggable
        key={id}
        draggableId={id}
        index={idx}
        isDragDisabled={reorderLoading || editTableTitleIdx > -1}
      >
        {({ innerRef, draggableProps, dragHandleProps }, draggableSnapshot) => (
          <ReportsTableHeaderCell
            title={title}
            id={id}
            linkReportJsonFields={linkReportJsonFields}
            idx={idx}
            align={align}
            isEditable={isEditable}
            handleSaveTableTitle={handleSaveTableTitle}
            editTableTitleIdx={editTableTitleIdx}
            setEditTableTitleIdx={setEditTableTitleIdx}
            innerRef={innerRef}
            getDraggableStyle={() => getDraggableStyle(draggableSnapshot, draggableProps.style)}
            draggableProps={draggableProps}
            dragHandleProps={dragHandleProps}
          />
        )}
      </Draggable>
    );
  }
  return (
    <ReportsTableHeaderCell
      title={title}
      id={id}
      linkReportJsonFields={linkReportJsonFields}
      idx={idx}
      align={align}
      isEditable={isEditable}
      handleSaveTableTitle={handleSaveTableTitle}
      editTableTitleIdx={editTableTitleIdx}
      setEditTableTitleIdx={setEditTableTitleIdx}
    />
  );
}

import React, { useState, useEffect } from "react";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";
import DataFieldEditCard from "./DataFieldEditCard";
import "../styles/createFieldModal.css";

// import ModalButtons from "./ModalButtons";
import TemplateSectionSearch from "../Common/TemplateSectionSearch";
import SearchIcon from "../../../../../assets/images/product/template/SearchBlueIcon.png";
import BackIcon from "../../../../../assets/images/product/template/BackArrowLightBlue.png";
import EditIcon from "../../../../../assets/images/product/template/EditPencilLightBlue.png";

import {
  getProducts,
  getDataField,
  manageDataFields,
} from "../../../../../actions/productTemplate";

export default function CreateFieldModalRight(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [fieldsTitlesAndDetailsList, setFieldsTitlesAndDetailsList] = useState([]);
  const [productInEdit, setProductInEdit] = useState(null);
  const [fieldTitleInEdit, setFieldTitleInEdit] = useState("");
  const [fieldDetailsInEdit, setFieldDetailsInEdit] = useState("");

  const { commonDataFieldInEdit, setProductDataFieldInEdit, dataFieldCategory } = props;

  const apiGetProducts = async () => {
    const response = await getProducts();
    setIsLoading(false);
    if (response && response.success) {
      let products = [];
      if (response.result && response.result.length > 0) {
        products = response.result;
      }

      setProductsList(products);
    } else {
      toast.error("Failed to get products.");
    }
  };

  useEffect(() => {
    apiGetProducts("");
  }, []);

  const apiSearchProducts = async (searchValue) => {
    const params = { search: searchValue };
    const response = await getProducts(params);
    if (response && response.success) {
      setProductsList(response.result);
    } else {
      toast.error("Failed to search products.");
    }
  };

  const apiGetDataFieldDetails = async (productId) => {
    if (!productId) {
      toast.error("Invalid product ID sent.");
      return null;
    }
    const params = { product_id: productId, category: dataFieldCategory };
    const response = await getDataField(params);
    if (response && response.success) {
      if (response.result) {
        return response.result;
      }
      toast.error(response.message);
    } else {
      toast.error("Failed to get data field details.");
    }
    return false;
  };

  const apiEditDataFieldDetails = async (detailsValue) => {
    const params = {
      field_name: fieldTitleInEdit,
      field_details: detailsValue,
      product_id: productInEdit.product_id,
      action: "edit",
      method: "PATCH",
      category: dataFieldCategory,
    };
    const response = await manageDataFields(params);
    if (response && response.success) {
      if (response.message.startsWith("Success")) {
        return true;
      }
      toast.error(response.message);
    } else {
      toast.error("Failed to edit product.");
    }
    return false;
  };

  const apiDeleteDataField = async () => {
    const params = {
      field_name: fieldTitleInEdit,
      product_id: productInEdit.product_id,
      action: "delete",
      method: "PATCH",
      category: dataFieldCategory,
    };
    const response = await manageDataFields(params);
    if (response && response.success) {
      if (response.message === "Success") {
        return true;
      }
      toast.error(response.message);
    } else {
      toast.error("Failed to delete data field.");
    }
    return false;
  };

  const toggleShowSearchBar = (show) => {
    if (!show && showSearchBar) {
      apiSearchProducts("");
    }
    setShowSearchBar(show);
  };

  const handleSubmitSearch = (value) => {
    apiSearchProducts(value);
  };

  const handleViewFieldsForProduct = async (product) => {
    if (commonDataFieldInEdit) {
      toast.error("Please save the current change first");
    } else {
      setProductDataFieldInEdit(true);
      const fields = await apiGetDataFieldDetails(product.product_id);
      if (fields) {
        setFieldsTitlesAndDetailsList(fields);
        setProductInEdit(product);
      }
    }
  };

  const resetAfterEdit = () => {
    setFieldTitleInEdit("");
    setFieldDetailsInEdit("");
  };

  const handleEditFieldDetail = (field) => {
    setFieldTitleInEdit(field.field_name);
    setFieldDetailsInEdit(field.field_details);
  };

  const handleGoBack = () => {
    setProductDataFieldInEdit(false);
    setProductInEdit(null);
    resetAfterEdit();
    setFieldsTitlesAndDetailsList([]);
  };

  const handleSaveFieldDetails = async (detailsValue) => {
    const success = await apiEditDataFieldDetails(detailsValue);
    if (success) {
      resetAfterEdit();
      const fields = await apiGetDataFieldDetails(productInEdit.product_id);
      if (fields) {
        setFieldsTitlesAndDetailsList(fields);
      }
    }
  };

  const handleDeleteField = async () => {
    const success = await apiDeleteDataField();
    if (success) {
      resetAfterEdit();
      const fields = await apiGetDataFieldDetails(productInEdit.product_id);
      if (fields) {
        setFieldsTitlesAndDetailsList(fields);
      }
    }
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="createFieldModalBodyRight">
      {
        !productInEdit && (
        <div className="bodyRightHomePage">
          <div className="createFieldModalBodyRightTop">
            <div className="rightTopHeader">
              <span className="bodyRightProductsSpan"> Products </span>
              {!showSearchBar
              && (
              <img
                src={SearchIcon}
                alt="SearchIcon"
                onClick={() => toggleShowSearchBar(true)}
                className="searchIcon"
              />
              ) }
            </div>

            <span className="bodyRightProductsSpan2"> View and edit data fields corresponding to the product </span>
          </div>
          {showSearchBar && (
            <div className="modalSearchContainer">
              <TemplateSectionSearch
                handleSubmitSearch={handleSubmitSearch}
                toggleShowSearch={() => toggleShowSearchBar(false)}
              />
            </div>
          )}
          <div className="createFieldModalBodyRightMid">
            <Scrollbar
              id="createFieldScrollBar"
              disableTrackYWidthCompensation
              disableTracksWidthCompensation
              style={{
                minHeight: showSearchBar ? "298px" : "345px",
              }}
            >
              <div className="productsContainer">
                {productsList && productsList.map((product, i) => (
                  <div className="productDiv" onClick={() => handleViewFieldsForProduct(product)} key={i}>
                    {product.product_name}
                  </div>
                ))}
              </div>
            </Scrollbar>
          </div>
        </div>
        )
      }

      {productInEdit
        && (
        <div className="rightSideEditPage">
          <div className="rightSideEditPageHeader">
            <img src={BackIcon} alt="BackIcon" onClick={handleGoBack} className="editDetailBackIcon" />
            <span className="productTitleSpan">
              {productInEdit.product_name}
            </span>
          </div>

          <Scrollbar
            id="createFieldScrollBar"
            disableTrackYWidthCompensation
            disableTracksWidthCompensation
            style={{
              minHeight: fieldTitleInEdit ? "30px" : "320px",
              marginTop: "35px",
            }}
          >
            {fieldTitleInEdit && (
              <>
                <DataFieldEditCard
                  key={fieldTitleInEdit}
                  isCommonDataField={false}
                  prevTitleValue={fieldTitleInEdit}
                  prevDetailsValue={fieldDetailsInEdit}
                  onSaveClick={handleSaveFieldDetails}
                  onCancelClick={resetAfterEdit}
                  onDeleteClick={handleDeleteField}
                />
                <div style={{ width: "100%", height: "28px" }} />
              </>
            )}
            <div className="fieldsForProductContainer">
              {fieldsTitlesAndDetailsList.map((field, i) => {
                if (field.field_name === fieldTitleInEdit) {
                  return null;
                }
                return (
                  <div className="fieldsForProduct" key={i} onClick={() => handleEditFieldDetail(field)}>
                    <div className="fieldsForProductHeader">
                      <span className="fieldsForProductHeaderContent">
                        {field.field_name}
                      </span>
                      <img src={EditIcon} alt="pencilIcon" className="pencilIcon" />

                    </div>
                    <div className="fieldsForProductBody">
                      <div className="fieldsForProductBodyContent">{ field.field_details }</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Scrollbar>
        </div>
        )}
    </div>
  );
}

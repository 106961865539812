import React, { useEffect, useState } from "react";
import moment from "moment";

import { toast } from "react-toastify";
import Graph from "./Components/Graph";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../../styles/GraphContainerStyle.css";

import { formatData, getStartEndDate } from "../../Helper";
import { getTestData, getDates } from "../../../../../actions/productAnalytics";
import GraphHeader from "./Components/GraphHeader";
// import Loading from "./Components/Loading";

export default function GraphContainer({
  selectedData, linkReportFields, setDatePicker, showDatePicker, fieldsMap,
}) {
  const { selectedProducts, selectedTests } = selectedData;

  const [showBarGraph, setShowBarGraph] = useState(false);
  const [selectedGraph, setSelectedGraph] = useState("");
  const [formattedData, setFormattedData] = useState();
  const [selectedGraphHasSpec, setSelectedGraphHasSpec] = useState(true);
  const [dateSelected, setDateSelected] = useState();
  const [prevDateSelected, setPrevDateSelected] = useState();
  const [loading, setLoading] = useState();

  // calculating the start date based on number of items returned by the datesList array.
  const getDefaultDateSelected = (datesList) => {
    const lastDate = datesList && datesList.length ? datesList[datesList.length - 1] : undefined;
    const endDate = lastDate ? moment(lastDate) : moment(new Date()); // if endDate is undefined, use today
    let startDate;
    if (!datesList || !lastDate || datesList.length < 2) {
      startDate = moment(endDate).subtract(30, "days");
    } else if (datesList && datesList.length > 1 && datesList.length <= 30) {
      startDate = moment(datesList[0]);
    } else if (datesList && datesList.length > 30) {
      startDate = moment(datesList[datesList.length - 30]);
    }
    return endDate && startDate ? `${startDate.format("MM/DD/YYYY")}-${endDate.format("MM/DD/YYYY")}` : undefined;
  };

  // get the dates for which the selected product and test combinations have results.
  useEffect(() => {
    async function apiGetEndDate() {
      const payload = {
        products: selectedProducts,
        tests: selectedTests,
      };
      setLoading(true);
      const response = await getDates(payload);
      const dateslist = response.result;
      const newDateSelected = getDefaultDateSelected(dateslist);
      if (newDateSelected) {
        setDateSelected(newDateSelected);
        setPrevDateSelected(newDateSelected);
      }
      setLoading(false);
    }
    apiGetEndDate();

  }, [selectedProducts, selectedTests]);  //eslint-disable-line

  // Returns a date array, if two dates exist -- [dateOne, dateTwo]
  // If one date -- returns single date
  // Else -- undefined
  // Passed down as props to DatePicker component
  const getSelectedDates = () => {
    if (!dateSelected?.trim()) {
      return undefined;
    }
    const dates = dateSelected.split("-");
    return dates.length === 2
      ? [new Date(dates[0].trim()), new Date(dates[1].trim())]
      : new Date(dateSelected.trim());
  };

  // Given a test and a product, makes an api call
  // Gets data for that product and test, for the given date range
  const fetchTestData = async (test, product) => {
    const dates = getStartEndDate(dateSelected);
    const payload = {
      product,
      test,
      dateFrom: dates[0],
      dateTo: dates[1],
    };
    const response = await getTestData(payload);
    if (response.success) {
      return response.result;
    }
    return undefined;
  };

  // Loops through all the selected products and tests, and for each combinations calls fetchTestData
  // Calls a helper function (formatData()) to format all of the combined test data into the proper format for the graph
  const loopAllTests = async () => {
    if (selectedProducts.length !== 0 && selectedTests.length !== 0) {
      const combinedData = [];
      for (let i = 0; i < selectedProducts.length; i++) {
        for (let j = 0; j < selectedTests.length; j++) {
            const response = await fetchTestData(selectedTests[j], selectedProducts[i]); // eslint-disable-line
          if (response === undefined) {
            toast.error("Failed to get test data.");
            break;
          }

          combinedData.push(response);
        }
      }

      try {
        const formatted = formatData(combinedData, linkReportFields, getSelectedDates());
        setFormattedData(formatted);
      } catch (e) {
        toast.error("Something went wrong formatting the data");
        // console.log("error when formatting the data:", e);
      }
    }
  };

  // Gets fired after selected products or selected tests changed and endDate has been updated
  useEffect(() => {
    async function getData() {
      await loopAllTests();

      setSelectedGraph("");
      setDatePicker(false);
    }
    if (!loading) {
      getData();
    }
  }, [loading]); // eslint-disable-line

  // Gets fired when the selectedGraph changes
  // Responsible for showing a tooltip when on the graph pill when we do not have a spec
  useEffect(() => {
    if (formattedData !== undefined) {
      const specArr = formattedData.specArr;

      let specFound = false;

      for (let i = 0; i < specArr.length; i++) {
        const spec = specArr[i];
        if (spec.name === selectedGraph.product?.product_id && Object.keys(spec.spec).length > 0 && spec.spec.test === selectedGraph.test) {
          specFound = true;
          if (spec.spec.max === "None" || spec.spec.min === "None" || (spec.spec.max === "" && spec.spec.min === "")) {
            setSelectedGraphHasSpec(false);
          } else {
            setSelectedGraphHasSpec(true);
          }
        } else if (spec.name === selectedGraph.product?.product_id && Object.keys(spec.spec).length === 0) {
          specFound = true;
          setSelectedGraphHasSpec(false);
        }
      }

      if (!specFound) {
        setSelectedGraphHasSpec(true);
      }
    }
  }, [selectedGraph.test, selectedGraph.product]); //eslint-disable-line

  // Called when a graph pill or graph line is selected -- passed down as props
  // Updates state so we know what the currently selected graph is
  const updateSelectedGraph = (selectedGraphObj) => {
    if (selectedGraphObj.test === selectedGraph.test && selectedGraphObj.product?.product_id === selectedGraph.product?.product_id) {
      setSelectedGraph("");
    } else {
      setSelectedGraph(selectedGraphObj);
    }
  };

  // Apply button clicked on date picker -- passed down as props
  // Update the prev state, and make an api call for new data
  const searchByDate = async () => {
    setPrevDateSelected(dateSelected);
    await loopAllTests();
    setSelectedGraph("");
  };

  if (selectedProducts.length === 0 && selectedTests.length === 0) {
    return <span className="analyticsGraphContainerPlaceholderText">Select a product or a test to view its analytics</span>;
  }

  if (selectedProducts.length === 0) {
    return <span className="analyticsGraphContainerPlaceholderText">Select a product to view its analytics</span>;
  }

  if (selectedTests.length === 0) {
    return <span className="analyticsGraphContainerPlaceholderText">Select a test to view its analytics</span>;
  }

  return (
    <div className="analyticsGraphMainContainer">

      <GraphHeader
        selectedData={selectedData}
        selectedGraph={selectedGraph}
        selectedGraphHasSpec={selectedGraphHasSpec}
        showBarGraph={showBarGraph}
        setShowBarGraph={setShowBarGraph}
        showDatePicker={showDatePicker}
        getSelectedDates={getSelectedDates}
        setDatePicker={setDatePicker}
        updateSelectedGraph={updateSelectedGraph}
        searchByDate={searchByDate}
        dateSelected={dateSelected}
        setDateSelected={setDateSelected}
        prevDateSelected={prevDateSelected}
        setPrevDateSelected={setPrevDateSelected}
        linkReportFields={linkReportFields}
        formattedData={formattedData}
        fieldsMap={fieldsMap}
      />

      {formattedData === undefined ? (
        <div className="analyticsLineBarGraphContainer">
          <span className="analyticsGraphContainerPlaceholderText">No data found for the selected date range</span>
        </div>
      ) : (
        <div className="analyticsLineBarGraphContainer">
          {/* {!loading && !loadingTestPairs && ( )} */}
          <Graph
            formattedData={formattedData}
            selectedData={selectedData}
            updateSelectedGraph={updateSelectedGraph}
            selectedGraph={selectedGraph}
            dateSelected={getStartEndDate(dateSelected)}
            showBarGraph={showBarGraph}
          />
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoggedIn from "./Containers/LoggedIn";
import LoggedOut from "./Containers/LoggedOut";
import {
  handleLocalStorageChange, handlePageLoad, isUser, getUserCredentials,
} from "./utils/common";
import { axiosInterceptor } from "./actions/interceptor";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min";
import "bootstrap/dist/js/bootstrap.min";
import { AppContext } from "./AppContext";

axiosInterceptor();

const App = ({ history }) => {
  const [userTitle, setUserTitle] = useState(isUser() ? getUserCredentials().user_title : "");
  const appContext = { userTitle, setUserTitle };
  /**
   * On App mount, add Storage Event and on load listeners.
   */
  useEffect(() => {
    /** Check for local storage changes to keep browsing contexts in sync */
    window.addEventListener("storage", (e) => handleLocalStorageChange(e, history, appContext)); // Needed here and not just in LoggedIn in the case that current tab is logged out and another tab logs in, we want to be listening to refresh + login in current tab)
    /** Call checkuser on page load and update userTitle */
    window.addEventListener("load", () => handlePageLoad(appContext)); // Call checkuser on page load
    return () => {
      window.removeEventListener("storage", (e) => handleLocalStorageChange(e, history, appContext));
      window.removeEventListener("load", () => handlePageLoad(appContext));
    };
  }, []); // eslint-disable-line

  return (
    <AppContext.Provider value={appContext}>
      <div style={{ height: "100%", width: "100%" }}>
        <ToastContainer
          autoClose={3500}
          newestOnTop
          hideProgressBar={false}
          theme="colored"
          closeButton
          closeOnClick={false}
          pauseOnFocusLoss={false}
          draggable={false}
        />
        {isUser() ? (
          <Route path="/" component={LoggedIn} />
        ) : (
          <Route path="/" component={LoggedOut} />
        )}
      </div>
    </AppContext.Provider>
  );
};
export default App;

import React, { useEffect, useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import EventCard from "./EventCard";

import { getWeekDays } from "../utils";

import "./EventGrid.css";
import { patchDragUpdateSchedule } from "../../../actions/envCalendar";

export default function EventGrid({
  selectedDate,
  scheduleData,
  setDeleteEventData,
  setIsDeletingConfirm,
  afterMarkingSample,
  onEdit,
  onPrintLabel,
  calendarLoading,
}) {
  const [weekDays, setWeekDays] = useState([]);
  const [columnData, setColumnData] = useState({});
  const [loading, setLoading] = useState({
    isLoading: false,
    draggableId: "",
  });

  useEffect(() => {
    const { datesInWeek, startDate } = getWeekDays(selectedDate);
    if (weekDays.length === 0 || (weekDays.length > 0 && weekDays[0] !== startDate)) {
      setWeekDays(datesInWeek);
    }
  }, [selectedDate, scheduleData, weekDays]);

  // Setting calendar data when week changes
  useEffect(() => {
    if (!("scheduled_submission" in scheduleData)) {
      setColumnData({});
      return;
    }

    const data = scheduleData.scheduled_submission;
    if (data.length === 0) {
      setColumnData({});
      return;
    }

    const dataGroupedByDate = data.reduce((acc, submissionData) => {
      const d = moment(submissionData.event_date).format("MM/DD/YYYY");
      acc[d] = acc[d] ? [...acc[d], submissionData] : [submissionData];
      return acc;
    }, {});

    // Fill state with in between dates that are not present in the scheduled data
    const allDates = Object.keys(dataGroupedByDate);
    const allMomentDates = allDates.map((d) => moment(d, "MM/DD/YYYY"));
    const minDate = moment.min(allMomentDates).clone().startOf("week");
    const maxDate = moment.max(allMomentDates).clone().endOf("week");
    while (minDate < maxDate) {
      const current = minDate.format("MM/DD/YYYY");
      if (!allDates.includes(current)) {
        dataGroupedByDate[current] = [];
      }
      minDate.add(1, "day");
    }
    setColumnData(dataGroupedByDate);
  }, [weekDays, scheduleData]);

  /**
   * Move dragged item and call api to update
   * @param {*} source Source
   * @param {*} destination Destination
   * @param {*} draggableId Draggable id
   */
  const moveItem = async (source, destination, draggableId) => {
    setLoading({ isLoading: true, draggableId });
    const columnDataCopy = { ...columnData };
    const restorePoint = JSON.parse(JSON.stringify(columnDataCopy));
    const sourceList = columnDataCopy[source.droppableId];
    const destinationList = columnDataCopy[destination.droppableId];
    const [draggedItem] = sourceList.splice(source.index, 1);
    destinationList.splice(destination.index, 0, draggedItem);
    columnDataCopy[source.droppableId] = sourceList;
    columnDataCopy[destination.droppableId] = destinationList;
    setColumnData(columnDataCopy);

    // Call api
    const resp = await patchDragUpdateSchedule(draggedItem.event_id, draggedItem.submission_id, destination.droppableId);
    if (!resp.success) {
      setColumnData(restorePoint);
      toast.error(resp.message);
    }
    setLoading({ isLoading: false, draggableId: "" });
    await afterMarkingSample();
  };

  /**
   * Handles drag end on Draggable component
   */
  const handleDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId) return;

    await moveItem(source, destination, result.draggableId);
  };

  /**
   * Render column header
   * @param {Date} date Date
   */
  const renderColumnHeader = (date) => {
    const day = moment(date, "MM/DD/YYYY");
    return (
      <>
        <p className="EventGrid__EventDay">{day.format("ddd")}</p>
        <p className="EventGrid__EventDate">{day.date()}</p>
      </>
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Scrollbar id="EventGrid__Scrollbar" className="EventGrid__Container">
        <div className="EventGrid">
          {weekDays.map((date) => {
            const isSelected = moment(selectedDate, "MM/DD/YYYY").isSame(moment(date, "MM/DD/YYYY"), "date");
            return (
              <div key={date} className={`EventGridColumn ${isSelected ? "EventGridColumn--selected" : ""}`}>
                <div className="EventGrid__ColumnHeader">
                  {renderColumnHeader(date)}
                </div>
                <Droppable droppableId={date.toString()}>
                  {(provided) => (
                    <div
                      className="EventGrid__EventColumn"
                      ref={provided.innerRef}
                      {...provided.droppableProps} // eslint-disable-line
                    >
                      {columnData[date] && columnData[date].map((data, index) => (
                        <EventCard
                          key={index}
                          data={data}
                          index={index}
                          setDeleteEventData={setDeleteEventData}
                          setIsDeletingConfirm={setIsDeletingConfirm}
                          afterMarkingSample={afterMarkingSample}
                          onEdit={onEdit}
                          cardLoading={loading}
                          setCardLoading={setLoading}
                          onPrintLabel={onPrintLabel}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            );
          })}
          {calendarLoading && (
            <div className="EventGrid__Loader">
              <LoadingOutlined spin className="EventGrid__LoaderIcon" />
            </div>
          )}
        </div>
      </Scrollbar>
    </DragDropContext>
  );
}

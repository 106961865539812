import React from "react";
import COABuilderImageWithText from "./COABuilderImageWithText";
import "./COABuilderSignature.css";

export default function COABuilderSignature({ selectedTemplate, handleBuilderFile }) {
  const {
    designation, signed_by, signature_image_url, statement_inputs,
  } = selectedTemplate;
  return (
    <div className="COABuilder__Statement">
      {statement_inputs.map(({ title, value }, i) => {
        if (title === "signature") {
          return (
            <COABuilderImageWithText
              key="signature"
              titleText={signed_by}
              subtitleText={designation}
              imagePath={signature_image_url}
              handleBuilderFile={handleBuilderFile}
              imageTitle="Add Signature"
            />
          );
        }
        return (<span key={i} className="COABuilder__StatementInput">{value}</span>);
      })}
    </div>
  );
}

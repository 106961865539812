import React, {
  useContext, useEffect, useState, useRef,
} from "react";

import Modal from "antd/es/modal/Modal";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import StyledButton from "../../../Common/UIComponents/StyledButton";
import SampleResultsModal from "../../../Product/Report/Components/ReportSpecs/SampleResultsModal";
import SampleFunctionContext from "../../SampleFunctionContext";
import SampleSubmissionContext from "../../SampleSubmissionContext";
import Comment from "../../SampleSubmissionModal/Comment";
import SampleSubmissionConfirmModal from "../../SampleSubmissionModal/SampleSubmissionConfirmModal";
import SubmissionReceiptModal from "../../SampleSubmissionModal/SubmissionReceiptModal/SubmissionReceiptModal";
import checkStatusEditable from "../helper";
import SampleCard from "./SampleCard";
import SubmissionDetailsModalFooterComment from "./SubmissionDetailsModalFooterComment";
import SubmittedSubmissionRow from "./SubmittedSubmissionRow";

import {
  deleteSampleFromSubmission,
  getSubmittedSubmissionsInfo,
  editCommentToSubmission,
} from "../../../../actions/sampleSubmission";

import "./SubmissionDetailsModal.css";
import { ReactComponent as LoadingDots } from "../../../../assets/images/common/LoadingDots.svg";
import { ReactComponent as QRIcon } from "../../../../assets/images/common/QRIcon.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/images/sample-submission/close.svg";

export default function SubmissionDetailsModal(props) {
  const {
    setShowSubmissionDetailsModal,
    sampleFields,
    sampleFieldsInfo,
    submissionClicked,
    showSubmissionDetailsModal,
    handleDeleteSubmission,
    handleEditSample,
    handleAddSamples,
    handleConfirmDeleteSubmission,
    refreshSubmissionsList,
    sampleLinkFields,
  } = props;

  const [loadingSubmission, setLoadingSubmission] = useState(true);
  const [submission, setSubmission] = useState({});
  const [sampleToDelete, setSampleToDelete] = useState(null);
  const [submissionModified, setSubmissionModified] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [loadSampleDeleting, setLoadSampleDeleting] = useState(false);
  const [loadCommentUpdating, setLoadCommentUpdating] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [sampleCardViewData, setSampleCardViewData] = useState(null);
  const [sampleFieldsData, setSampleFieldsData] = useState(null);
  const commentRef = useRef();
  const { sampleCategory } = useContext(SampleSubmissionContext);
  const { setOnAddBackButton } = useContext(SampleFunctionContext);

  /** Get info of the clicked submission
     * @param {string} submissionId - the unique id of a submission
     * */
  const apiGetSubmittedSubmission = async (submissionId) => {
    setLoadingSubmission(true);
    const payload = {
      submission_id: submissionId,
      page: 1,
      sample_search: "",
      submission_search: "",
      search_by: "",
      date_from: "",
      date_to: "",
      user_selected_date_from: "",
      user_selected_date_to: "",
      submitted_by: "",
      sample_category: sampleCategory,
    };
    const {
      success: submittedSuccess,
      message: submittedMessage, result: submitted_submissions,
    } = await getSubmittedSubmissionsInfo(payload);

    if (submittedSuccess) {
      setSubmission({ ...submitted_submissions, submit_id: submissionId, po: submissionClicked.po });
    } else {
      toast.error(submittedMessage);
    }
    setLoadingSubmission(false);
  };

  useEffect(() => {
    apiGetSubmittedSubmission(submissionClicked.submit_id);
  }, [setShowSubmissionDetailsModal, submissionClicked]); // eslint-disable-line

  const closeSubmissionDetailsModal = () => {
    if (submissionModified) {
      refreshSubmissionsList();
    }
    setShowSubmissionDetailsModal(false);
  };

  /**
   * Set sampleToDelete, show confirmation modal
   * @param {Object} sample { [fields], sample_id, tests_list}
   */
  const handleDeleteSample = async (idx) => {
    setSampleToDelete(idx);
  };

  /**
   * If not last sample, call delete sample api.
   * If last sample, call delete submission api.
   * If successful, close the modal.
   * @param {Object} sample { [fields], sample_id, tests_list}
   */
  const handleConfirmDeleteSample = async (sample) => {
    setLoadSampleDeleting(true);
    if (submission.samples_list.length === 1) {
      const deleteSuccess = await handleConfirmDeleteSubmission(submissionClicked, true, submissionClicked.sample_category);
      if (!deleteSuccess) {
        setLoadSampleDeleting(false);
      }
    } else {
      const { success, message } = await deleteSampleFromSubmission(submission.submit_id, submission.status, sample, submissionClicked.sample_category);
      if (success) {
        toast.success("Successfully deleted sample");
        setSubmissionModified(true);
        setSampleToDelete(null);
        apiGetSubmittedSubmission(submissionClicked.submit_id);
      } else {
        toast.error(message);
      }
      setLoadSampleDeleting(false);
    }
  };

  /**
   * Make an api call to update the comment value.
   */
  const handleSubmitComment = async () => {
    setLoadCommentUpdating(true);
    const { submit_id, status } = submission;
    const payload = {
      submission_id: submit_id,
      submission_status: status,
      comment: commentRef?.current?.resizableTextArea.textArea.value.trim() ?? "",
      sample_category: sampleCategory,
    };
    const { success, message } = await editCommentToSubmission(payload);
    if (success) {
      toast.success("Successfully updated comment");
      setSubmissionModified(true);
      setEditComment(false);
      apiGetSubmittedSubmission(submissionClicked.submit_id);
    } else {
      toast.error(message);
    }
    setLoadCommentUpdating(false);
  };

  /**
   * Before edit and add samples, make api call to check status
   */
  const refreshDataAndCheckStatus = async (sampleID, sampleType) => {
    const payload = {
      submission_id: submissionClicked.submit_id,
      page: 1,
      sample_search: "",
      submission_search: "",
      search_by: "",
      date_from: "",
      date_to: "",
      user_selected_date_from: "",
      user_selected_date_to: "",
      submitted_by: "",
      sample_category: sampleCategory,
    };

    const {
      success,
      result,
    } = await getSubmittedSubmissionsInfo(payload);
    if (success) {
      const submissionStatus = result.status;
      let sampleSpecFlag;
      if (sampleID) {
        const sampleList = result.samples_list.filter(({ sample_id, sample_type }) => sample_id === sampleID && sample_type === sampleType); // sample_id & sample_type combo is unique
        if (!sampleList.length) {
          toast.error("This sample was deleted");
          setSubmission({ ...result, submit_id: submissionClicked.submit_id, po: submissionClicked.po });
          return false;
        }
        const sample = sampleList[0];
        sampleSpecFlag = sample.specs_flag;
      }
      const isEditable = checkStatusEditable(submissionStatus, sampleSpecFlag);
      if (!isEditable) {
        toast.error(`The submission ${sampleID ? "and/or sample " : ""}status does not allow for edits`);
        setSubmission({ ...result, submit_id: submissionClicked.submit_id, po: submissionClicked.po });
      }
      return isEditable;
    }
    return true;
  };

  /**
   * Handle add sample with status check beforehand
   */
  const handleAddSamplesWithCheck = async () => {
    const isEditable = await refreshDataAndCheckStatus();
    if (isEditable) {
      handleAddSamples(submission);
    }
  };

  /**
   * Handle edit sample with status check beforehand
   */
  const handleEditSampleWithCheck = async (sample) => {
    const isEditable = await refreshDataAndCheckStatus(sample.sample_id, sample.sample_type);
    if (isEditable) {
      handleEditSample(submission, sample);
    }
  };
  /**
   * Handle view report with data for tests and status
   */
  const handleViewReportData = (sampleId) => {
    const clickedSampleCard = submission.samples_list.filter((sample) => sample.sample_id === sampleId);
    if (clickedSampleCard.length) {
      const clickedSample = clickedSampleCard[0];
      // const { tests_list } = clickedSample;
      const result = sampleLinkFields.map((key) => ({ key: sampleFieldsInfo[key].title_field, value: clickedSample[key] }));
      setSampleFieldsData(result);
      setShowReport(true);
      setSampleCardViewData(clickedSample);
    }
  };

  const handleReceipt = () => {
    setShowReceipt(!showReceipt);
    setOnAddBackButton(false);
  };
  const handleReportView = () => {
    setShowReport(!showReport);
  };

  const disableActions = !checkStatusEditable(submission.status, null, submission.event_id);
  const showType = submission.status === "-1" && submission.event_id;
  const scrollbarStyle = { height: "100%" };
  const eventTypeStyles = { textAlign: "center" };

  return (
    <>
      {showReceipt && (
        <SubmissionReceiptModal handleReceipt={handleReceipt} showReceipt={showReceipt} submissionFromParent={submission} />
      )}
      {showReport && (
        <SampleResultsModal
          reportData={sampleCardViewData}
          onCancel={handleReportView}
          sampleSubmission
          sampleCategory={submissionClicked.sample_category}
          sampleFieldsData={sampleFieldsData}
        />
      )}
      <Modal
        title=""
        open={showSubmissionDetailsModal}
        closeIcon={<CloseIcon />}
        onCancel={closeSubmissionDetailsModal}
        centered
        destroyOnClose
        footer={null}
        wrapClassName="SubmissionDetailsModal"
      >
        <div className="submission-details-modal-container">
          {sampleToDelete !== null && (
            <SampleSubmissionConfirmModal
              headerText="Delete Sample Confirmation"
              bodyText={`Are you sure you want to delete this sample?${submission.samples_list.length === 1 ? " Deleting this sample will also delete the submission." : ""} This cannot be undone.`}
              buttonText={["Cancel", "Delete", "Deleting"]}
              buttonFunctions={[() => setSampleToDelete(null), () => handleConfirmDeleteSample(submission.samples_list[sampleToDelete])]}
              loading={loadSampleDeleting}
            />
          )}
          <div className="SubmissionDetailsModal__SubmissionTableContainer">
            <table className="submission-details-modal-submission-table">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Submission Name</th>
                  <th>Submitted on</th>
                  <th>Submitted by</th>
                  <th className="submitted-submissions-table-samples-number-th">No of Samples</th>
                  {sampleCategory === "product" && <th>Product Spec</th>}
                  {sampleCategory === "environment" && <th>Environment Spec</th>}
                  {!disableActions && (<th className="submitted-submissions-table-samples-number-th">Actions</th>)}
                  {showType && (<th style={eventTypeStyles}>Type</th>)}
                </tr>
              </thead>
              <tbody>
                <SubmittedSubmissionRow
                  submission={submission}
                  handleDeleteSubmission={handleDeleteSubmission}
                  handleAddSamples={handleAddSamplesWithCheck}
                  hideActionsWhenDisabled={disableActions}
                  showType={showType}
                />
              </tbody>
            </table>
          </div>
          <div className="SubmissionDetailsModal__Body">
            {loadingSubmission ? (
              <div className="SubmissionDetailsModal__Loading">
                <LoadingDots width={30} height={30} fill="#C4D2DF" />
              </div>
            ) : (
              <Scrollbar style={scrollbarStyle}>
                <div className="submission-details-modal-sample-cards">
                  {submission.samples_list && submission.samples_list.map((sample, idx) => (
                    <SampleCard
                      sample={sample}
                      key={idx}
                      sampleFields={sampleFields}
                      sampleFieldsInfo={sampleFieldsInfo}
                      submissionStatus={submissionClicked.status}
                      handleEditSample={() => handleEditSampleWithCheck(sample)}
                      handleDeleteSample={() => handleDeleteSample(idx)}
                      handleReportView={handleReportView}
                      handleViewReportData={() => (handleViewReportData(sample.sample_id))}
                      submissionEventId={submissionClicked.event_id || ""}
                    />
                  ))}
                </div>
              </Scrollbar>
            )}
          </div>

          {/* {!disableAddSamples && (
          <div className="SubmissionDetailsModal__Footer">
            <Tooltip
              title={disableAddSamples ? "Cannot add samples after Samples Received" : ""}
              overlayClassName="SampleSubmission__ActionsMenu__Tooltip"
            >
              <button
                type="button"
                disabled={disableAddSamples}
                className={`SubmissionDetailsModal__AddSampleButton${disableAddSamples ? "--disabled" : ""}`}
                onClick={disableAddSamples ? null : () => handleAddSamples({ submit_id: submissionClicked.submit_id, ...submission })}
              >
                <span>Add Samples</span>
              </button>
            </Tooltip>
          </div>
        )} */}
          {!loadingSubmission && (
            <div className="SubmissionDetailsModal__Footer">
              {editComment && (
                <Comment
                  showButtons
                  commentRef={commentRef}
                  defaultValue={submission.comment}
                  handleCancelClick={() => setEditComment(false)}
                  handleSubmitClick={handleSubmitComment}
                  loading={loadCommentUpdating}
                />
              )}
              <div className="SubmissionDetailsModal__FooterButtonPanel">
                <div className="SubmissionDetailsModal__FooterCommentButton">
                  {!editComment && (
                    <SubmissionDetailsModalFooterComment
                      comment={submission.comment}
                      allowEdit={checkStatusEditable(submissionClicked.status)}
                      handleEditComment={() => setEditComment(true)}
                    />
                  )}
                </div>
                <StyledButton
                  type="primary"
                  onClick={handleReceipt}
                  className="SubmissionDetailsModal__ReceiptButton"
                  icon={<QRIcon className="SubmissionDetailsModal__ReceiptButton__QRIcon" />}
                >
                  Print Receipt
                </StyledButton>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

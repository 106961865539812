import { SPEC_FLAGS_MAP } from "../../../../Common/constants/SpecFlagConstants";

export const MAX_RETESTS_ALLOWED = 3;

export const VALID_SPECS_SET = new Set(["0", "1", "2", "3", "4", "5", "6"]);

export const getColorForTestResult = (specList) => {
  const parsedList = [];

  specList.forEach((element) => {
    const parsedElement = {};
    const {
      test_result: result,
      test_spec: specs,
      test,
      test_spec_flag,
      test_detail,
    } = element;
    let color;

    parsedElement.result = result;
    parsedElement.specs = specs;
    parsedElement.test = test;
    parsedElement.detail = test_detail;
    parsedElement.test_spec_flag = test_spec_flag;

    if (element.test_spec_flag && element.test_spec_flag === "-") {
      color = SPEC_FLAGS_MAP.invalid;
      parsedElement.color = color;
    } else {
      color = SPEC_FLAGS_MAP[element.test_spec_flag].color;
      parsedElement.color = color;
    }
    parsedList.push(parsedElement);
  });
  return parsedList;
};

export const getColorForPesticideResults = (compoundSpecs) => {
  const specData = {};

  Object.keys(compoundSpecs).forEach((country) => {
    const specs = compoundSpecs[country];
    specData[country] = specs.map((spec) => {
      spec.color = SPEC_FLAGS_MAP[spec.compound_spec] ? SPEC_FLAGS_MAP[spec.compound_spec].color : SPEC_FLAGS_MAP.invalid.color;
      return spec;
    });
  });

  return specData;
};

export const getCountriesSpecsFlagColors = (countriesSpecsFlags) => {
  const entries = Object.entries(countriesSpecsFlags).map(([code, specFlag]) => {
    let color;
    if (specFlag) {
      color = specFlag === "3" ? "#000000" : SPEC_FLAGS_MAP[specFlag].color;
    } else {
      color = SPEC_FLAGS_MAP.invalid.color;
    }
    return [code, color];
  });
  return new Map(entries);
};

import React from "react";

import moment from "moment";

import CustomCalendar from "./CustomCalendar";
import FrequencyForm from "./FrequencyForm";
import ModalPageTitle from "./ModalPageTitle";
import TypeToggle from "./TypeToggle";

import "./SetFrequenceForm.css";

const FREQUENCY_TYPES = ["recurring", "one-time"];

export default function SetFrequenceForm({
  updateSamplingData,
  oneTimeDate,
  setOneTimeDate,
  selectedType,
  setSelectedType,
  selectedRecurringType,
  setSelectedRecurringType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  weekDays,
  setWeekDays,
  dayOfMonth,
  setDayOfMonth,
  selectedCustomDatesList,
  setSelectedCustomDatesList,
  // selectedDay,
  // setSelectedDay,
}) {
  const flatPickerOptions = {
    plugins: [],
    dateFormat: "m/d/Y",
    mode: "single",
    minDate: moment().format("MM/DD/YYYY"),
    // maxDate: moment().add(1, "year").format("MM/DD/YYYY"),
  };

  /**
   * Toggle type of frequency
   * @param {String} type recurring/one-time
   */
  const typeToggleOnChange = (type) => {
    setSelectedType(type);
    if (type === "one-time") {
      const today = new Date();
      setOneTimeDate(today);
      updateSamplingData([today], "one-time");
    }
  };

  return (
    <div className="SetFrequencyForm">
      <ModalPageTitle>Set up schedule for testing</ModalPageTitle>
      <div className="SetFrequencyForm__ToggleContainer">
        <TypeToggle
          types={FREQUENCY_TYPES}
          selectedType={selectedType}
          onChange={typeToggleOnChange}
        />
      </div>
      {selectedType === "recurring" && (
        <FrequencyForm
          updateSamplingData={updateSamplingData}
          selectedRecurringType={selectedRecurringType}
          setSelectedRecurringType={setSelectedRecurringType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          weekDays={weekDays}
          setWeekDays={setWeekDays}
          dayOfMonth={dayOfMonth}
          setDayOfMonth={setDayOfMonth}
          selectedCustomDatesList={selectedCustomDatesList}
          setSelectedCustomDatesList={setSelectedCustomDatesList}
        />
      )}
      {selectedType === "one-time" && (
        <div className="SetFrequencyForm__OneTime">
          <span className="FieldTitle">Select Date</span>
          <CustomCalendar
            value={oneTimeDate}
            onChange={([selectedDate]) => {
              setOneTimeDate(selectedDate);
              updateSamplingData([selectedDate], "one-time");
            }}
            options={flatPickerOptions}
          />
        </div>
      )}
    </div>
  );
}

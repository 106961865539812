import React from "react";
import {
  View, StyleSheet, Text,
} from "@react-pdf/renderer";
import softHyphenate from "./pdfUtils";

export default function ReceiptPDFSenderInfo({
  CUSTOMER_INFO_FIELDS_BY_ROW,
  customerInfo,
}) {
  const styles = StyleSheet.create({
    senderInfoSection: {
      padding: "24 5 24 5",
      // borderBottom: "1 solid #e0e0e0",
    },
    title: {
      color: "#000",
      fontSize: "16",
      paddingBottom: "14",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      gap: "20",
      maxWidth: "100%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      gap: "50",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      gap: "2",
      flex: "1 1 50%",
    },
    label: {
      color: "#afbdca",
      fontFamily: "Roboto-400",
      fontSize: "12",
    },
    value: {
      color: "#1f2d3b",
      fontFamily: "Roboto-400",
      fontSize: "14",
      maxHeight: "100",
    },
  });

  return (
    <View style={styles.senderInfoSection}>
      <View style={styles.title}><Text>Sender Details</Text></View>
      <View style={styles.body}>
        {CUSTOMER_INFO_FIELDS_BY_ROW.map((row, i) => (
          <View key={i} style={styles.row}>
            {row.map(({ label, key }) => (
              <View key={key} style={styles.field}>
                <Text style={styles.label}>{label}</Text>
                <Text style={styles.value}>{softHyphenate(customerInfo[key] || "-")}</Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
}

import React from "react";

import {
  PINS_SPEC_STYLE_MAP,
} from "../Constant";

export default function PinSimpleToolTip(props) {
  const {
    pin,
    index0,
    position,
  } = props;

  const {
    color, box_shadow_color, border_color,
  } = PINS_SPEC_STYLE_MAP[pin.specs_flag] ?? PINS_SPEC_STYLE_MAP.invalid;

  return (
    <div
      className="pin-simple-tooltip"
      style={{
        border: `0.5px solid ${border_color}`,
        boxShadow: `0px 16px 8px ${box_shadow_color}`,
        bottom: `${position.bottom + 14}px`,
        left: `${position.left}px`,
        color,
        // width: `${width}px`,
      }}
    >
      {pin[index0]}
    </div>
  );
}

import React, { useState } from "react";

import { toast } from "react-toastify";

import ReportPreviewModal from "../Preview/ReportPreviewModal";
import { VALID_SPECS_SET } from "../ReportSpecs/helper";

import { s3EsvPub, s3EsvPriv } from "../../../../../utils/aws";
import { downloadFileFromS3 } from "../../../../../utils/helpers";

import { ReactComponent as DownloadIcon } from "../../../../../assets/images/DownloadIcon.svg";
// import { ReactComponent as ShareIcon } from "../../../../../assets/images/ShareIcon.svg";
import { ReactComponent as PreviewIcon } from "../../../../../assets/images/PreviewIconDefault.svg";
import loadingIcon from "../../../../../assets/images/product/refreshCircleBlack.png";

import "./ReportButtons.css";

export default function ReportButtons({
  item,
  // toggleShareModal,
}) {
  const [showPreview, setShowPreview] = useState(false);

  /**
   *  Handles the download of files for a report.
   *  Checks if a valid file path exists for the report, then proceeds to download
   *  all files associated with the report. It keeps track of the total number of
   *  files and the number of files successfully downloaded, displaying a success
   *  or warning message accordingly.
   *  @param {none}
   *  @return {none}
   */
  const handleDownloadFile = () => {
    if (!item.path || Object.keys(item.path).length < 1) {
      toast.error("Unable to download, no valid file path for this report!");
      return;
    }

    const files = Object.entries(item.path);
    let totalFiles = 0;
    let filesDownloaded = 0;
    Promise.all(files.map(async (fileData) => {
      const fileTempSet = Object.values(fileData[1]);
      const fileTempArr = Array.from(fileTempSet);

      return Promise.all(fileTempArr.map(async (fileTemp) => {
        totalFiles += fileTemp.length;

        return Promise.all(fileTemp.map(async (filePath) => {
          const fileName = filePath.split("/").pop();
          const isPrivateS3Bucket = filePath.includes("COAs/");
          const s3Bucket = isPrivateS3Bucket ? s3EsvPriv : s3EsvPub;

          const success = await downloadFileFromS3(filePath, s3Bucket, fileName);
          if (success) {
            filesDownloaded++;
          }
        }));
      }));
    })).then(() => {
      if (filesDownloaded === totalFiles) {
        toast.success(`Successfully downloaded ${filesDownloaded}/${totalFiles} files.`);
      } else {
        toast.warning(`Downloaded ${filesDownloaded}/${totalFiles} files.`);
      }
    });
  };

  if (item.specs_flag === "0") {
    return (
      <div className="ProductReportTableActions__InProcess">
        <img
          src={loadingIcon}
          alt="loadingIcon"
        />
        <span>
          Test In Process
        </span>
      </div>
    );
  }

  const enableButtons = VALID_SPECS_SET.has(item.specs_flag.toString()) && item.path && Object.keys(item.path).length > 0;

  return (
    <>
      {showPreview && <ReportPreviewModal filePaths={item.path} handleCloseForPreview={() => setShowPreview(false)} />}
      <div className="ProductReportTableActions">
        <PreviewIcon
          className={`ProductReportTableActions__PreviewIcon${!enableButtons ? "--disabled" : ""}`}
          onClick={enableButtons ? () => setShowPreview(true) : null}
        />
        <DownloadIcon
          className={`ProductReportTableActions__DownloadIcon${enableButtons ? "" : "--disabled"}`}
          onClick={enableButtons ? handleDownloadFile : null}
        />
        {/* <ShareIcon
        className={`ProductReportTableActions__ShareIcon${enableButtons ? "" : "--disabled"}`}
        onClick={enableButtons ? () => toggleShareModal(i, true) : null}
      /> */}
      </div>
    </>

  );
}

import React from "react";

// images
import moreLightblue from "../../assets/images/moreLightblue.svg";
import myProfileIconActive from "../../assets/images/myProfileIconActive.svg";
import myProfileIconInactive from "../../assets/images/myProfileIconInactive.svg";
import teamMemberIconActive from "../../assets/images/teamMemberIconActive.svg";
import teamMemberIconInactive from "../../assets/images/teamMemberIconInactive.svg";
import savedLocationIconActive from "../../assets/images/savedLocationIconActive.svg";
import savedLocationIconInactive from "../../assets/images/savedLocationIconInactive.svg";
import faqIconActive from "../../assets/images/faqIconActive.svg";
import faqIconInactive from "../../assets/images/faqIconInactive.svg";
import helpIconActive from "../../assets/images/helpIconActive.svg";
import helpIconInactive from "../../assets/images/helpIconInactive.svg";
import plusIcon from "../../assets/images/plus-lightblue.svg";
import minusIcon from "../../assets/images/minus-lightblue.svg";

export const MyAccountMoreLightblueIcon = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={moreLightblue} alt="" className="myAccountSubmenuIcon" />
  </div>
);

export const MyProfileIconActive = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={myProfileIconActive} alt="" className="myAccountSubmenuLeftIcon" />
  </div>
);
export const MyProfileIconInactive = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={myProfileIconInactive} alt="" className="myAccountSubmenuLeftIcon" />
  </div>
);

export const TeamMemberIconActive = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={teamMemberIconActive} alt="" className="myAccountSubmenuLeftIcon" />
  </div>
);
export const TeamMemberIconInactive = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={teamMemberIconInactive} alt="" className="myAccountSubmenuLeftIcon" />
  </div>
);

export const SavedLocationIconActive = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={savedLocationIconActive} alt="" className="myAccountSubmenuLeftIcon" />
  </div>
);
export const SavedLocationIconInactive = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={savedLocationIconInactive} alt="" className="myAccountSubmenuLeftIcon" />
  </div>
);

export const FaqIconActive = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={faqIconActive} alt="" className="myAccountSubmenuLeftIcon" />
  </div>
);
export const FaqIconInactive = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={faqIconInactive} alt="" className="myAccountSubmenuLeftIcon" />
  </div>
);

export const HelpIconActive = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={helpIconActive} alt="" className="myAccountSubmenuLeftIcon" />
  </div>
);
export const HelpIconInactive = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={helpIconInactive} alt="" className="myAccountSubmenuLeftIcon" />
  </div>
);

export const PlusIcon = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={plusIcon} alt="" className="myAccountFaqOpenCloseIcon" />
  </div>
);
export const MinusIcon = () => (
  <div className="myAccountSubmenuWrapper">
    <img src={minusIcon} alt="" className="myAccountFaqOpenCloseIcon" />
  </div>
);

/**
 * Returns if string is a valid email or not
 * @param {String} string string to validate
 * @return {Boolean} true if valid email, false if not
 */
export const validateEmail = (string) => {
  const emailRegex = /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[A-Za-z0-9_!#$%&'*+/=?`{|}~^-]+)*@[A-Za-z0-9-]+(?:\.[A-Za-z0-9-]+)+$/;
  return emailRegex.test(string);
};

/**
 * Returns if string is a valid email username or not
 * @param {String} string string to validate
 * @return {Boolean} true if valid email username, false if not
 */
export const validateEmailUsername = (string) => {
  const emailUsernameRegex = /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[A-Za-z0-9_!#$%&'*+/=?`{|}~^-]+)*$/;
  return emailUsernameRegex.test(string);
};

/**
 * Returns if string is a valid email domain or not
 * @param {String} string string to validate
 * @return {Boolean} true if valid email domain, false if not
 */
export const validateEmailDomain = (string) => {
  const emailDomainRegex = /^[A-Za-z0-9-]+(?:\.[A-Za-z0-9-]+)+$/;
  return emailDomainRegex.test(string);
};

/**
 * Returns if string is a valid phone number or not
 * @param {String} string string to validate
 * @return {Boolean} true if valid phone number, false if not
 */
export const validatePhoneNumber = (string) => {
  const phoneRegex = /^(?:[+(])*(?=[0-9])(?:[.0-9 +()-])+$/;
  return phoneRegex.test(string);
};

/**
 * Returns if password meets requirements
 * @param {String} password string to check
 * @return {Boolean} true if meets requirements, false if not
 */
export const validatePassword = (password) => {
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,}$/;
  return passwordRegex.test(password);
};
